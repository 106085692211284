import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBHistoryWeb from "./ppobHistoryWeb";
import PPOBHistoryMobile from "./ppobHistoryMobile";

const PPOBHistory = () => {
  return (
    <>
      <BrowserView>
        <PPOBHistoryWeb></PPOBHistoryWeb>
      </BrowserView>
      <MobileView>
        <PPOBHistoryMobile></PPOBHistoryMobile>
      </MobileView>
    </>
  );
};

export default PPOBHistory;
