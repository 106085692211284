/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useState } from "react";
import { zeroPad } from "react-countdown";
import { BrowserView, MobileView } from "react-device-detect";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useAppDispatch } from "../../../core/feature/hooks";
import * as API_BALANCE from "../../../core/service/api_balance";
import TopUpDetailMobile from "./topUpDetailMobile";
import TopUpDetailWeb from "./topUpDetailWeb";
import { useTranslation } from "react-i18next";

const TopUpDetail = () => {
  let dispatch = useAppDispatch();
  let location: any = useLocation();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(0);
  const [bankData, setBankData] = React.useState<any>({});
  const [data, setData] = React.useState<any>({});
  const [type, setType] = React.useState("");
  const [vANumber, setVANumber] = React.useState("");
  const [isCloseVA, setIsCloseVA] = React.useState(false);
  const [timer, setTimer] = React.useState(0);
  const [isProvider, setIsProfider] = React.useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [charge, setCharge] = useState<any>("");

  const paymentInstructions = [
    {
      id: "1",
      type: "ATM",
      title: t("topup.titleInstructions"),
      child: [
        {
          id: 1,
          detail: t("topup.insertAtm"),
        },
        {
          id: 2,
          detail: t("topup.selectLanguage"),
        },
        {
          id: 3,
          detail: t("topup.insertPin"),
        },
        {
          id: 4,
          detail: t("topup.selectOtherMenu"),
        },
        {
          id: 5,
          detail: t("topup.selectTransfer"),
        },
        {
          id: 6,
          detail: t("topup.selectVirtual"),
        },
        {
          id: 7,
          detail: `${t("topup.insertVirtual")} ${
            vANumber === "" ? "(VA)" : vANumber
          }`,
        },
        {
          id: 8,
          detail: t("topup.insertNote"),
        },
        {
          id: 9,
          detail: t("topup.confirmTransfer"),
        },
      ],
    },
    {
      id: "2",
      type: "M Banking / I Banking",
      title: t("topup.titleInstructionsMobile"),
      child: [
        {
          id: 1,
          detail: t("topup.login"),
        },
        {
          id: 2,
          detail: t("topup.selectTransferMobile"),
        },
        {
          id: 3,
          detail: `${t("topup.in")} ${
            vANumber === "" ? t("topup.noVA") : vANumber
          } ${t("topup.selectBank")}`,
        },
        {
          id: 4,
          detail: t("topup.insertNominal"),
        },
        {
          id: 5,
          detail: t("topup.confirmTopUp"),
        },
      ],
    },
    {
      id: "3",
      type: "Bank Lain",
      title: "Petunjuk Top Up melalui Bank lain",
      child: [
        {
          id: 1,
          detail: "Login ke Aplikasi Mobile Banking Anda",
        },
        {
          id: 2,
          detail:
            "Pilih menu Transfer Antar Bank (sesuaikan nama menu dengan menu yang ada di MBanking Anda)",
        },
        {
          id: 3,
          detail: `Pilih metode transfer Online (6.500) atau SKN (2.900). Transfer VA tidak bisa menggunakan metode BI-Fast`,
        },
        {
          id: 4,
          detail: `Pilih Bank ${location.state.bankData.bank}`,
        },
        {
          id: 5,
          detail: `Masukkan ${vANumber === "" ? "Nomor (VA)" : vANumber}`,
        },
        {
          id: 6,
          detail: `Pilih rekening sumber Anda kemudian klik 'Lanjut'.`,
        },
        {
          id: 7,
          detail: `Masukkan jumlah Top Up.`,
        },
        {
          id: 8,
          detail: `Di halaman konfirmasi, masukkan Password Mobile Banking Anda`,
        },
      ],
    },
  ];

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const createVA = async () => {
    dispatch(setLoading(true));
    let data = {
      balanceCode: "Closepay",
      bank: location.state.bankData.bank,
      provider: location.state.provider,
    };
    await API_BALANCE.createVABank(data)
      .then((res) => {
        dispatch(setLoading(false));
        setVANumber(res.data.data.virtualAccountNumber);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCharge = async () => {
    dispatch(setLoading(true));
    let data = {
      balanceCode: "Closepay",
      bank: location.state.bankData.bank,
      provider: location.state.provider,
    };
    await API_BALANCE.getChargeVABank(data)
      .then((res) => {
        dispatch(setLoading(false));
        setCharge(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const copyVa = async () => {
    await navigator.clipboard.writeText(vANumber);
    toast.success("Nomor VA disalin");
  };

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <p className="md:text-lg text-base text-[#DC3545] font-medium mb-0">
          Waktu telah habis
        </p>
      );
    } else {
      return (
        <p className="md:text-lg text-base text-[#DC3545] font-medium mb-0">
          {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
        </p>
      );
    }
  };

  React.useEffect(() => {
    setBankData(location.state.bankData);
    if (
      location?.state?.provider === "XENDIT" ||
      location?.state?.provider === "DURIAN" ||
      location?.state?.provider === "FAZZ" ||
      location?.state?.provider === "LINKQU"
    ) {
      setIsProfider(true);
    } else {
      setIsProfider(false);
    }
    setType(location.state.type);
    if (location.state.isCloseVA) {
      setVANumber(location.state.data.vaNumber);
      setData(location.state.data);
      setIsCloseVA(location.state.isCloseVA);
      let expTime =
        Date.parse(
          moment.utc(location.state.data.expiredDate).local().format()
        ) - Date.now();
      setTimer(Date.now() + expTime);
    } else {
      createVA();
      getCharge();
    }
  }, []);

  return (
    <>
      <BrowserView>
        <TopUpDetailWeb
          handleOpen={(value: any) => handleOpen(value)}
          copyVa={() => copyVa()}
          paymentInstructions={paymentInstructions}
          open={open}
          bankData={bankData}
          data={data}
          type={type}
          vANumber={vANumber}
          isCloseVA={isCloseVA}
          timer={timer}
          renderer={renderer}
          isProvider={isProvider}
          openDetail={openDetail}
          setOpenDetail={setOpenDetail}
          charge={charge}
        />
      </BrowserView>
      <MobileView>
        <TopUpDetailMobile
          handleOpen={(value: any) => handleOpen(value)}
          copyVa={() => copyVa()}
          paymentInstructions={paymentInstructions}
          open={open}
          bankData={bankData}
          data={data}
          type={type}
          vANumber={vANumber}
          isCloseVA={isCloseVA}
          timer={timer}
          renderer={renderer}
          isProvider={isProvider}
          openDetail={openDetail}
          setOpenDetail={setOpenDetail}
          charge={charge}
        />
      </MobileView>
    </>
  );
};

export default TopUpDetail;
