/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SummaryMobile from "./summaryMobile";
import SummaryWeb from "./summaryWeb";
import { useLocation, useNavigate } from "react-router-dom";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import Company from "../../../core/models/company";
import { findIndex } from "lodash";
import Token from "../../../core/models/token";
import { MarketplaceConfig } from "../../../core/models/user";
import { loadState } from "../../../core/feature/browser-storage/browser-storage";
import { saveState } from "../../../core/feature/browser-storage/browser-storage";
import { removeState } from "../../../core/feature/browser-storage/browser-storage";

const Summary = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const marketplaceConfig: MarketplaceConfig = useAppSelector(
    (state) => state.marketplaceConfig.marketplaceConfig
  );
  const [data, setData] = React.useState<any[]>([]);
  const [productList, setProductList] = React.useState<any[]>([]);
  const [dataOrder, setDataOrder] = React.useState<any>({});
  const [expand, setExpand] = React.useState<any>({});
  const [expandAll, setExpandAll] = React.useState<any>({});
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [orderTotal, setOrderTotal] = React.useState(0);
  const [shipTotal, setShipTotal] = React.useState(0);
  const [chargeTotal, setChargeTotal] = React.useState(0);
  const [selectedProductNotes, setSelectedProductNotes] = React.useState<any>(
    {}
  );
  const [cartIds, setCartIds] = React.useState<string[]>([]);
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let redirectCustom = window.location.hostname;
  let redirectCO = loadState("redirectCO");


  const checkout = async (transactionId: string) => {
    dispatch(setLoading(true));
    let data = {
      transactionId,
      description: "",
    };
    await API_COMMERCE.checkout(data)
      .then((res) => {
        dispatch(setLoading(false));
        let indexToken = findIndex(token, { cid: company.initial });
        let tempToken = token[indexToken].token;
        saveState("redirectCO", `/${company.initial}`);
        if (company.initial === "ITSTOKO" || company.initial === "HSI") {
          window.location.href = `https://${res.data.data.checkoutLink}?access_token=${tempToken}&successRedirectUrl=${redirectCustom}/${company.initial}&backRedirectUrl=${redirectCustom}/${company.initial}`;
        } else {
          window.location.href = `https://${res.data.data.checkoutLink}?access_token=${tempToken}`;
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOpen = (productId: string) => {
    if (expand[productId] !== undefined) {
      setExpand({ ...expand, [productId]: !expand[productId] });
    }
  };

  const selectForm = (merchantId: string, productId: string) => {
    let product = data
      .find((el: any) => el.merchant.id === merchantId)
      .products.find((i: any) => i.productId === productId);
    if (product !== undefined) setSelectedProductNotes(product);
  };

  const handleClose = () => {
    setSelectedProductNotes({});
  };

  const handleOnClick = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.createOrder(dataOrder)
      .then((res) => {
        dispatch(setLoading(false));
        let findTrxId = res.data.data.orderList.find(
          (el: any) => el.status !== "ON_REVIEW"
        );
        if (findTrxId === undefined) {
          navigate(`/${company?.initial}/commerce/history`, {
            replace: true,
            state: {
              navigateTo: `/${company?.initial}/commerce`,
            },
          });
        } else {
          checkout(res.data.data.transactionId[0]);
        }
        if(cartIds.length > 0){
          let newData = {
            cartIds
          };
          API_COMMERCE.deleteProductFromCart2(newData)
            .then((res) => {
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(setLoading(false));
            });
        }
      })
      .catch((err) => dispatch(setLoading(false)));
  };

  React.useEffect(() => {
    if (location.state !== undefined) {
      console.log(location.state);
      setData(location.state.data);
      setDataOrder(location.state.dataOrder);
      let tempTotalPrice = 0;
      location.state.data.map((i: any) => (tempTotalPrice += i.amountTotal));
      let tempExpandData = expand;
      let tempExpandAll = expandAll;
      let tempOrderTotal = 0;
      let tempShipTotal = 0;
      let tempChargeTotal = 0;
      let tempProductList: any = [];
      location.state.data.map((item: any) => {
        let tempProduct: any = [];
        item.products.map((el: any) => {
          tempExpandData = { ...tempExpandData, [el.productId]: false };
          let index = tempProduct.findIndex((i: any) => i.productId === el.productId);
          if(index >= 0){
            tempProduct[index].item.push(el);
          } else {
            tempProduct.push({
              productId: el.productId,
              item: [el]
            })
          }
        })
        let merchantIndex = tempProductList.findIndex((i: any) => i.merchantId === item.merchant.id);
        if(merchantIndex >= 0){
          tempProductList[merchantIndex].products.push(tempProduct);
        } else {
          tempProductList.push({
            merchantId: item.merchant.id,
            products: tempProduct
          })
        }
        tempExpandAll = { ...tempExpandAll, [item.merchant.id]: false };
        tempOrderTotal += item.amountProduct;
        tempShipTotal += item.amountDelivery;
        tempChargeTotal += item.amountCharge;
      })
      setProductList(tempProductList);
      setExpand(tempExpandData);
      setExpandAll(tempExpandAll);
      setTotalPrice(tempTotalPrice);
      setOrderTotal(tempOrderTotal);
      setShipTotal(tempShipTotal);
      setChargeTotal(tempChargeTotal);

      if(location.state.cartIds !== undefined) setCartIds(location.state.cartIds);
    }
  }, []);

  React.useEffect(() => {
    if (redirectCO) {
      navigate(`${redirectCO}?redirect=false`);
      removeState(`redirectCO`);
    }
  }, [redirectCO]);
  
  return (
    <>
      <BrowserView>
        <SummaryWeb
          handleOpen={(productId: string) => handleOpen(productId)}
          selectForm={(merchantId: string, productId: string) => selectForm(merchantId, productId)}
          handleClose={() => handleClose()}
          handleOnClick={() => handleOnClick()}
          marketplaceConfig={marketplaceConfig}
          data={data}
          productList={productList}
          dataOrder={dataOrder}
          expand={expand}
          expandAll={expandAll}
          totalPrice={totalPrice}
          orderTotal={orderTotal}
          shipTotal={shipTotal}
          chargeTotal={chargeTotal}
          company={company}
          selectedProductNotes={selectedProductNotes}
        />
      </BrowserView>
      <MobileView>
        <SummaryMobile
          handleOpen={(productId: string) => handleOpen(productId)}
          selectForm={(merchantId: string, productId: string) => selectForm(merchantId, productId)}
          handleClose={() => handleClose()}
          handleOnClick={() => handleOnClick()}
          marketplaceConfig={marketplaceConfig}
          data={data}
          productList={productList}
          dataOrder={dataOrder}
          expand={expand}
          expandAll={expandAll}
          totalPrice={totalPrice}
          orderTotal={orderTotal}
          shipTotal={shipTotal}
          chargeTotal={chargeTotal}
          company={company}
          selectedProductNotes={selectedProductNotes}
        />
      </MobileView>
    </>
  );
};

export default Summary;
