/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InvoiceWeb from "./invoiceWeb";
import InvoiceMobile from "./invoiceMobile";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company.js";
import { getConfigInvoice } from "../../core/service/api_invoice.js";
import { ConfigColor } from "../../config/global.js";

const Invoice = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const query = useLocation();
  const color = ConfigColor();
  const [showSentInvoice, setShowSentInvoice] = React.useState(false);
  const [openTab, setOpenTab] = React.useState(1);
  const [configColor, setConfigColor] = React.useState<any>({});

  const getConfigSent = () => {
    getConfigInvoice()
      .then((res) => {
        setShowSentInvoice(res.data.data.showSentInvoice);
      })
      .catch((err) => {});
  };

  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#015f48";

    setConfigColor({ color1: color1, color2: color2 });
  };

  React.useEffect(() => {
    getConfigColor();
    getConfigSent();
    let state: any = query.state;
    if (state && state.openTab) setOpenTab(state.openTab);
  }, []);
  
  return (
    <>
      <BrowserView>
        <InvoiceWeb
          color={color}
          company={company}
          showSentInvoice={showSentInvoice}
          openTab={openTab}
          configColor={configColor}
          setOpenTab={(value: any) => setOpenTab(value)}
        />
      </BrowserView>
      <MobileView>
        <InvoiceMobile
          color={color}
          company={company}
          showSentInvoice={showSentInvoice}
          openTab={openTab}
          configColor={configColor}
          setOpenTab={(value: any) => setOpenTab(value)}
        />
      </MobileView>
    </>
  );
};

export default Invoice;
