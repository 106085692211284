import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import LessonTimetableDetailMobile from "./lessonTimetableDetailMobile";
import LessonTimetableDetailWeb from "./lessonTimetableDetailWeb";

const LessonTimetableDetail = () => {
  return (
    <>
      <BrowserView>
        <LessonTimetableDetailWeb></LessonTimetableDetailWeb>
      </BrowserView>
      <MobileView>
        <LessonTimetableDetailMobile></LessonTimetableDetailMobile>
      </MobileView>
    </>
  );
};

export default LessonTimetableDetail;
