import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ConfirmOtpMobile from "./confirmOtpMobile";
import ConfirmOtpWeb from "./confirmOtpWeb";

const ConfirmOtp = () => {
  return (
    <>
      <BrowserView>
        <ConfirmOtpWeb></ConfirmOtpWeb>
      </BrowserView>
      <MobileView>
        <ConfirmOtpMobile></ConfirmOtpMobile>
      </MobileView>
    </>
  );
};

export default ConfirmOtp;
