import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";

type InitialState = {
  pin: number
}
const type = 'pin'

const initialState: InitialState = {
  pin: loadState(type)
}

export const pinSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setPin: (state, action: PayloadAction<number>) => {
      state.pin = action.payload;
      saveState(type, action.payload)
    },
    removePin: (state) => {
      state.pin = 0;
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPin, removePin } = pinSlice.actions;

export default pinSlice.reducer;
