/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ProductDetailMobile from "./productDetailMobile";
import ProductDetailWeb from "./productDetailWeb";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { TimeZone } from "../../../config/global";
import { toast } from "react-hot-toast";
import Company from "../../../core/models/company";
import User, { MarketplaceConfig } from "../../../core/models/user";
import Token from "../../../core/models/token";
import { find } from "lodash";
import { useTranslation } from "react-i18next";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const ProductDetail = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const marketplaceConfig: MarketplaceConfig = useAppSelector(
    (state) => state.marketplaceConfig.marketplaceConfig
  );
  const { t } = useTranslation();
  const [data, setData] = React.useState<any>({});
  const [selectedVariant, setSelectedVariant] = React.useState<any>({});
  const [workHour, setWorkHour] = React.useState<any[]>([]);
  const [isWishlist, setIsWishlist] = React.useState(false);
  const [stock, setStock] = React.useState(0);
  const [sold, setSold] = React.useState(0);
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(0);
  const [quantity, setQuantity] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [isBuyNow, setIsBuyNow] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [isSelectedVariant, setIsSelectedVariant] = React.useState("");
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [isLogin, setIsLogin] = React.useState(user && newToken);
  const time = TimeZone();

  const daysOfWeek = [
    t("merchants.monday"),
    t("merchants.tuesday"),
    t("merchants.wednesday"),
    t("merchants.thursday"),
    t("merchants.friday"),
    t("merchants.saturday"),
    t("merchants.sunday"),
  ];

  const getProductDetail = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.getProductDetail(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        setIsWishlist(res.data.data.wishlistStatus);
        getMerchantWorkHour(res.data.data.merchantId);
        let amountStock = 0;
        let amountSold = 0;
        let price: any = [];
        if (res.data.data.items.length > 0) {
          res.data.data.items.map((el: any) => {
            amountStock += el.stock;
            amountSold += el.marketplaceSold;
            return price.push(el.marketplacePrice);
          });
          setStock(amountStock);
          setSold(amountSold);
          setMinPrice(Math.min(...price));
          setMaxPrice(Math.max(...price));
          setIsSelectedVariant(res.data.data.items[0].id);
          setSelectedVariant(res.data.data.items[0]);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProductDetailNoLogin = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.productDetailNoLogin(company.id, params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        setIsWishlist(res.data.data.wishlistStatus);
        getMerchantWorkHourNoLogin(res.data.data.merchantId);
        let amountStock = 0;
        let amountSold = 0;
        let price: any = [];
        if (res.data.data.items.length > 0) {
          res.data.data.items.map((el: any) => {
            amountStock += el.stock;
            amountSold += el.marketplaceSold;
            return price.push(el.marketplacePrice);
          });
          setStock(amountStock);
          setSold(amountSold);
          setMinPrice(Math.min(...price));
          setMaxPrice(Math.max(...price));
          setIsSelectedVariant(res.data.data.items[0].id);
          setSelectedVariant(res.data.data.items[0]);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const addToCart = async () => {
    dispatch(setLoading(true));
    let newData = {
      productId: data.id,
      itemId: selectedVariant.id,
      quantity,
    };
    await API_COMMERCE.addToCart(newData)
      .then((res) => {
        dispatch(setLoading(false));
        setOpen(false);
        toast.success(t("whishlist.toastSuccesAddCart"));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const addToWishlist = async () => {
    if (isLogin) {
      dispatch(setLoading(true));
      let data = {
        productId: params.id,
      };
      await API_COMMERCE.addWishlist(data)
        .then((res) => {
          dispatch(setLoading(false));
          if (res.data.data.productIds.includes(params.id)) {
            setIsWishlist(true);
            toast.success(t("whishlist.toastSuccesAddWishlist"));
          } else {
            setIsWishlist(false);
            toast.success(t("whishlist.toastSuccesDeleteWishlist"));
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    } else {
      navigate(`/${company.initial}/login`);
    }
  };

  const getMerchantWorkHour = async (mId: string) => {
    dispatch(setLoading(true));
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await API_COMMERCE.getComboMerchantWorkHour(mId, timezone)
      .then((res) => {
        dispatch(setLoading(false));
        setWorkHour(res.data.data.dayList);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getMerchantWorkHourNoLogin = async (mId: string) => {
    dispatch(setLoading(true));
    let timezone = time;
    await API_COMMERCE.cekOperationalTimeNoLogin(mId, timezone)
      .then((res) => {
        dispatch(setLoading(false));
        setWorkHour(res.data.data.dayList);
        console.log(res, "cekkk");
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    if (isLogin) {
      getProductDetail();
    } else {
      getProductDetailNoLogin();
    }
    setIsLogin(user && newToken);
  }, []);
  
  return (
    <>
      <BrowserView>
        <ProductDetailWeb
          addToCart={() => addToCart()}
          addToWishlist={() => addToWishlist()}
          setOpen={(value: any) => setOpen(value)}
          setIsSelectedVariant={(value: any) => setIsSelectedVariant(value)}
          setSelectedVariant={(value: any) => setSelectedVariant(value)}
          setQuantity={(value: any) => setQuantity(value)}
          setShowModal2={(value: any) => setShowModal2(value)}
          setIsBuyNow={(value: any) => setIsBuyNow(value)}
          daysOfWeek={daysOfWeek}
          data={data}
          selectedVariant={selectedVariant}
          workHour={workHour}
          isWishlist={isWishlist}
          stock={stock}
          sold={sold}
          minPrice={minPrice}
          maxPrice={maxPrice}
          open={open}
          isBuyNow={isBuyNow}
          showModal2={showModal2}
          isSelectedVariant={isSelectedVariant}
          isLogin={isLogin}
          marketplaceConfig={marketplaceConfig}
          quantity={quantity}
          responsive={responsive}
        />
      </BrowserView>
      <MobileView>
        <ProductDetailMobile
          addToCart={() => addToCart()}
          addToWishlist={() => addToWishlist()}
          setOpen={(value: any) => setOpen(value)}
          setIsSelectedVariant={(value: any) => setIsSelectedVariant(value)}
          setSelectedVariant={(value: any) => setSelectedVariant(value)}
          setQuantity={(value: any) => setQuantity(value)}
          setShowModal2={(value: any) => setShowModal2(value)}
          setIsBuyNow={(value: any) => setIsBuyNow(value)}
          daysOfWeek={daysOfWeek}
          data={data}
          selectedVariant={selectedVariant}
          workHour={workHour}
          isWishlist={isWishlist}
          stock={stock}
          sold={sold}
          minPrice={minPrice}
          maxPrice={maxPrice}
          open={open}
          isBuyNow={isBuyNow}
          showModal2={showModal2}
          isSelectedVariant={isSelectedVariant}
          isLogin={isLogin}
          marketplaceConfig={marketplaceConfig}
          quantity={quantity}
          responsive={responsive}
        />
      </MobileView>
    </>
  );
};

export default ProductDetail;
