import Header from "../../_common/headerComponent";
import cash from "../../../assets/images/fnb/cash.svg";

const CashMobile = (props: any) => {
  return (
    <div className="min-h-screen bg-white font-montserrat relative pb-32">
      <div className="bg-mobile pb-5">
        <Header navbarText="Petunjuk Pembayaran" />
        <div>
          <img src={cash} alt="" className="flex mx-auto mt-4" />
        </div>
      </div>
      <div className="p-4">
        <p className="text-[16px] text-gray-500 font-semibold">
          Panduan Untuk Bayar Cash/Kartu. (Offline)
        </p>
        <hr />
        {props.tutor.map((res: any) => {
          return (
            <div key={res.no} className="gap-3 flex">
              <div className="w-3 text-left"> {res.no}. </div>
              <div className=" mb-2 text-left">{res.text}</div>
            </div>
          );
        })}
      </div>
      <div className="w-full bg-white p-4 bg-fixed absolute bottom-0">
        <div className="mb-6">
          <button
            onClick={() => {
              props.navigate(`detail`);
            }}
            className="flex mx-auto text-green text-[16px]"
          >
            Lihat Detail Pesanan
          </button>
        </div>
        {/* <button
          //   onClick={() => props.createOrder()}
          className="py-2 text-white font-semibold rounded-lg bg-green w-full"
        >
          Oke
        </button> */}
      </div>
    </div>
  );
};

export default CashMobile;
