/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import * as API_BALANCE from "../../../core/service/api_balance";
import Header from "../../_common/headerComponent";
import Company from "../../../core/models/company";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";

const ConfirmOtpMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const location: any = useLocation();
  const [data] = useState<any>({});
  const [dataOrder] = useState<any>({});
  const [isOtpForRegistration] = useState(false);
  const [securityCode, setSecurityCode] = useState("");
  const [timer, setTimer] = useState(0);

  const resendOtp = async () => {
    dispatch(setLoading(true));
    if (isOtpForRegistration) {
      await API_BALANCE.registrationBankDKI(dataOrder)
        .then((res) => {
          dispatch(setLoading(false));
          setTimer(Date.now() + 300000);
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    } else {
      await API_BALANCE.createOtpActivationBankDKI(dataOrder)
        .then((res) => {
          dispatch(setLoading(false));
          setTimer(Date.now() + 300000);
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const renderer = (data: any) => {
    if (data.completed) {
      setSecurityCode("");
      return (
        <button
          style={{
            color:
              company?.color?.color1 !== undefined
                ? company?.color?.color1
                : "#26A69A",
          }}
          className="text-[#039F79] font-medium md:text-base text-sm mt-3"
          type="button"
          onClick={() => resendOtp()}
        >
          Kirim Ulang Kode OTP
        </button>
      );
    } else {
      return (
        <span className="font-medium md:text-base text-sm mt-3">
          Kode otp akan kadaluwarsa dalam{" "}
          <span className="font-bold">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };

  const handleOnClick = async () => {
    if (isOtpForRegistration) {
      dispatch(setLoading(true));
      let dataOrder = {
        otp: securityCode,
      };
      await API_BALANCE.confirmRegistrationBankDKI(dataOrder)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`/${company?.initial}/balance/connect-account/success`, {
            state: {
              data,
              successRegistration: true,
            },
            replace: true,
          });
          console.log(res.data);
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    } else {
      switch (data.code) {
        case "BANK_DKI":
          dispatch(setLoading(true));
          let newData = {
            otp: securityCode,
          };
          await API_BALANCE.validateOtpActivationBankDKI(newData)
            .then((res) => {
              dispatch(setLoading(false));
              navigate(`/${company?.initial}/balance/connect-account/success`, {
                state: {
                  data,
                },
                replace: true,
              });
              console.log(res.data);
            })
            .catch((err) => {
              dispatch(setLoading(false));
            });
          break;

        case "LINK_AJA":
          dispatch(setLoading(true));
          console.log("coming soon");
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    // if(location.state.isOtpForRegistration !== undefined){
    //   setIsOtpForRegistration(true);
    // }
    // setTimer(Date.now() + 300000);
    // setData(location.state.data);
    // setDataOrder(location.state.dataOrder);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />
      <div className="container md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto mb-10">
          <p className="md:text-lg text-base text-customBlack font-medium mb-0 text-center">
            Masukkan Kode OTP yang telah dikirimkan ke email yang didaftarkan.
          </p>
          <div className="flex flex-col items-center gap-2 mt-5 mb-4 relative">
            <OtpInput
              value={securityCode}
              onChange={(code: React.SetStateAction<string>) => {
                setSecurityCode(code);
              }}
              numInputs={6}
              separator={<span>&nbsp;</span>}
              isInputNum
              isInputSecure
              inputStyle={{
                width: 60,
                height: 63,
                margin: "0 0.5rem",
                fontSize: "2rem",
                borderRadius: 12,
                border: "0.5px solid #848383",
              }}
            />
            <Countdown key={timer} date={timer} renderer={renderer} />
          </div>
          <div
            className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <button
              type="button"
              onClick={handleOnClick}
              disabled={securityCode.length < 6}
              style={{
                backgroundColor:
                  securityCode.length < 6
                    ? "#9A9999"
                    : company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
              }}
              className={`${
                securityCode.length < 6 ? "bg-gray-500" : "bg-green"
              } text-white md:text-base text-sm font-bold rounded-lg w-full md:p-[1rem] p-[12px]`}
            >
              Konfirmasi
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmOtpMobile;
