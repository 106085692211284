import { useEffect, useState } from "react";
import Header from "../../_common/headerComponent";
import card2 from "../../../assets/images/fnb/cardAntrian.svg";
import { formatCurrency } from "../../../config/global";

const PesananDetailWeb = () => {
  const [dataSum, setDataSum] = useState<any>({});

  useEffect(() => {
    var data: any = localStorage?.getItem("orderData");
    setDataSum(JSON.parse(data));
  }, []);

  return (
    <div className="min-h-screen w-full bg-mobile">
      <Header navbarText="Detail Pesanan" />
      <div className="w-full container px-5">
        {dataSum?.customer?.fnbType === "DINE_IN" && (
          <div className="text-white mb-20">
            <img src={card2} className="flex mx-auto mt-5" alt="" />
            <div className=" text-center flex flex-col -mt-[90px]">
              <span className="text-[12px] font-semibold">No Meja</span>
              <span className="font-semibold text-3xl mt-2">
                {dataSum?.customer?.tableNumber}
              </span>
            </div>
          </div>
        )}
        <div className="w-full p-4">
          <p className="font-semibold text-lg">Ringkasan</p>
          <div className="flex justify-between border-b py-2 px-2">
            <span className="text-secondary font-semibold">Nama</span>
            <span>{dataSum?.customer?.name}</span>
          </div>
          <div className="flex justify-between border-b py-2 px-2">
            <span className="text-secondary font-semibold">Metode</span>
            <span>
              {dataSum?.customer?.fnbType === "DINE_IN"
                ? "Dine In"
                : "Take Away"}
            </span>
          </div>
          {dataSum?.productList?.map((res: any, i: any) => {
            return (
              <div key={i} className="flex justify-between border-b py-2 px-2">
                <div className="flex flex-col">
                  <span className="text-secondary font-semibold">
                    {res.name}
                  </span>
                  <span className="text-secondary">
                    {" "}
                    {res.variantKey} : {res.itemName}
                  </span>
                  <span className="text-secondary">
                    {res?.quantity} x {res?.finalPrice}
                  </span>
                </div>
                <div className="flex flex-col mt-4">
                  {formatCurrency(res?.totalFinalPrice)}
                </div>
              </div>
            );
          })}
          <div className="flex justify-between bg-gray-50 py-2 px-2 text-[#26A69A] font-semibold">
            <span>Total</span>
            <span>{formatCurrency(dataSum?.totalProductPrice)}</span>
          </div>
          <div className="w-full">
            <p className="font-semibold text-lg">Catatan Member</p>
            <textarea
              className="w-full border rounded-xl p-3 outline-none"
              placeholder="Tidak Ada Catatan"
              value={dataSum?.customer?.note}
              disabled
            ></textarea>
          </div>
          <div className="w-full">
            <p className="font-semibold text-lg">Catatan Merchat</p>
            <textarea
              className="w-full border rounded-xl p-3 outline-none"
              placeholder="Tidak Ada Catatan"
              value={dataSum?.merchantNote}
              disabled
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PesananDetailWeb;
