import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SuccessCheckinWeb from "./successCheckinWeb";
import SuccessCheckinMobile from "./successCheckinMobile";

const SuccessCheckin = () => {
  return (
    <>
      <BrowserView>
        <SuccessCheckinWeb></SuccessCheckinWeb>
      </BrowserView>
      <MobileView>
        <SuccessCheckinMobile></SuccessCheckinMobile>
      </MobileView>
    </>
  );
};

export default SuccessCheckin;
