import { useEffect, useState, Fragment } from "react";
import Header from "../_common/headerComponent";
import QrScanner from "qr-scanner";
import { useLocation, useNavigate } from "react-router-dom";
import BottomLogo from "../_common/bottomLogo";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import * as API_ABSENSI from "../../core/service/api_absensi";
import AbsenResult from "../absenResultComponent";
import { ConfigColor } from "../../config/global";
import arrow from "../../assets/images/arrow-left-ic.svg";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { formatCurrency } from "../../config/global";
import { useTranslation } from "react-i18next";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";

const FeatureScanBarcodePayGateWeb = () => {
  const [btnScan, setBtnScan] = useState(true);
  const color = ConfigColor();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});
  let stopScan = false;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);

  const scanNow = async (isScan: any) => {
    setBtnScan(isScan);
    if (isScan === false) stopScan = true;
    if (btnScan === false) return;
    // stopScan = false;
    await new Promise((r) => setTimeout(r, 100));
    const videoElement: any = document.getElementById("scanView");
    const scanner = new QrScanner(
      videoElement,
      async (result: any) => {
        // setHasilScan(JSON.stringify(result.data));
        setBtnScan(true);
        stopScan = true;

        let query = {
          qrId: result.data,
        };
        dispatch(setLoading(true));
        await API_ABSENSI.payGateScan(query)
          .then(async (res) => {
            dispatch(setLoading(false));
            setData({ ...res.data.data, qrId: result.data });
            setShow(true);
            scanner.stop();
            scanner.destroy();
          })
          .catch((err) => {
            scanner.stop();
            scanner.destroy();
            navigate("result", { replace: true });
            dispatch(setLoading(false));
          });
      },
      {
        onDecodeError: (error) => {
          console.error(error);
        },
        maxScansPerSecond: 1,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        returnDetailedScanResult: true,
      }
    );
    await scanner.start();
    while (stopScan === false) await new Promise((r) => setTimeout(r, 100));
    scanner.stop();
    scanner.destroy();
  };

  const Payment = () => {
    dispatch(setLoading(true));
    const query = {
      qrId: data.qrId,
    };
    API_ABSENSI.payGatePayment(query)
      .then(async (res) => {
        dispatch(setLoading(false));
        setShow(false);
        localStorage.setItem("result", JSON.stringify(res.data));
        setTimeout(() => {
          navigate("result", { replace: true });
          dispatch(setLoading(false));
        }, 500);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setShow(false);
        navigate("result", { replace: true });
      });
  };

  useEffect(() => {
    scanNow(true);
  }, []);

  return (
    <div>
      <div className="bg-mobile min-h-screen">
        <section className="flex flex-tow items-center z-50 px-4 py-3 mb-7">
          <div
            onClick={async () => {
              await scanNow(false);
              navigate(-1);
            }}
            className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
          >
            <img
              src={arrow}
              style={{ width: 6, height: 12 }}
              className="m-auto"
              alt=""
            />
          </div>

          <h1 className={`font-montserrat font-bold text-base m-auto relative`}>
            {t("accessibility.scan_barcode")}
          </h1>
        </section>
        <div className="container">
          <div className="w-1/2 mx-auto">
            <div className="w-full bg-transparent text-black flex absolute z-50 p-3"></div>
            <div className="w-full font-montserrat mt-10">
              {/* {btnScan === false && ( */}
              <video
                id="scanView"
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  height: "100%",
                  minHeight: "100%",
                  borderStyle: "dotted",
                }}
              ></video>
              {/* )} */}
              {/* {btnScan == false && ( */}
              {!show && (
                <h3 className="text-center text-xl px-4 mb-4 mt-5">
                  {t("accessibility.message_barcode")}
                </h3>
              )}
            </div>
          </div>
        </div>
        <div className="w-full p-4 container pb-10">
          <button
            onClick={async () => {
              console.log("klik");
              await scanNow(false);
              navigate(-1);
              localStorage.removeItem("result");
            }}
            className={
              btnScan
                ? "py-2 px-4 rounded-lg w-full bg-gradient-to-b border-1 relative z-10 text-white border-white"
                : ""
            }
            style={{ backgroundColor: `${color.color1}` }}
          >
            {t("accessibility.cancel")}
          </button>
          {/* <BottomLogo /> */}
          {company?.showFtpLogo && <BottomLogo />}
        </div>
        {/* <BottomLogo /> */}
      </div>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  {/* <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-center leading-6 text-gray-900"
                  >
                    Filter
                  </Dialog.Title> */}
                  <div className="p-4 bg-white rounded-xl">
                    <div>
                      <div className="pb-2 border-dotted border-b-[1px] border-[#728F9E]">
                        <span className="text-xl text-[#26A69A] text-[600] block">
                          {t("accessibility.summary")}
                        </span>
                        <span className="text-sm text-[#728F9E]">
                          {moment
                            .utc(data?.date)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2 gap-2 p-2">
                        <div className="text-sm text-[#43A49B] font-[700]">
                          {t("accessibility.balance")}
                        </div>
                        <div className="text-right text-sm text-[#43A49B] font-[700]">
                          {formatCurrency(data?.balance)}
                        </div>
                        <div className="text-sm text-[#263339]">
                          {t("accessibility.paygate_vehicle")}
                        </div>
                        <div className="text-right text-sm text-[#728F9E]">
                          {formatCurrency(data?.price)}
                        </div>
                        <div className="text-sm font-[700]">
                          {t("accessibility.remaining_balance")}
                        </div>
                        <div className="text-right text-sm font-[700]">
                          {formatCurrency(data?.balanceLeft)}
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full gap-4 mt-10">
                      <button
                        type="button"
                        onClick={() => Payment()}
                        className="w-full p-2 rounded-lg text-white focus:outline-none"
                        style={{ backgroundColor: `${color.color1}` }}
                      >
                        {t("accessibility.next")}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default FeatureScanBarcodePayGateWeb;
