import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TipAndTourDetailWeb from "./tripAndTourDetailWeb";
import TipAndTourDetailMobile from "./tripAndTourDetailMobile";
import * as API from "../../core/service/api_commerce";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import { setLoading } from "../../core/feature/config/configSlice";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

const TripAndTourDetail = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [jml, setJml] = useState(1);
  const [category, setCategory] = useState<any>("Itinerari");
  const [showDay, setShowDay] = useState<any>("");
  const listCategory = ["Itinerari", "Fasilitas", "Deskripsi"];
  const [data, setData] = useState<any>("");

  const getData = () => {
    dispatch(setLoading(true));
    API.getTourProductById(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOpen = (value: any) => {
    setShowDay(showDay === value ? 0 : value);
  };

  const add = () => {
    if (jml > 0) {
      setJml(jml + 1);
    }
  };

  const min = () => {
    if (jml > 1) {
      setJml(jml - 1);
    }
  };

  const order = () => {
    if(data.stock < 1) return toast.error("Produk tidak tersedia")
    navigate(`/${company.initial}/trip-tour/payment`, {
      state: { data: { ...data, quantity: jml } },
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <BrowserView>
        <TipAndTourDetailWeb
          data={data}
          category={category}
          listCategory={listCategory}
          setCategory={setCategory}
          showDay={showDay}
          setShowDay={setShowDay}
          handleOpen={handleOpen}
          jml={jml}
          add={add}
          min={min}
          order={order}
        ></TipAndTourDetailWeb>
      </BrowserView>
      <MobileView>
        <TipAndTourDetailMobile
          data={data}
          category={category}
          listCategory={listCategory}
          setCategory={setCategory}
          showDay={showDay}
          setShowDay={setShowDay}
          handleOpen={handleOpen}
          jml={jml}
          add={add}
          min={min}
          order={order}
        ></TipAndTourDetailMobile>
      </MobileView>
    </>
  );
};

export default TripAndTourDetail;
