const JsonData: any = {
  SubjectOtp: {
    NewDevice: "Notifikasi Keamanan Akun",
    NoEmail: "",
    SELF_REGISTER: "Verifikasi Data Registrasi Mandiri Member",
    // TRANSFER_BANK: "OTP Transfer Bank",
    ADD_BANK_ACCOUNT: "Verifikasi Penambahan Akun Bank",
  },
  daysArray :[
    { value: 'Sunday', label: 'Minggu' },
    { value: 'Monday', label: 'Senin' },
    { value: 'Tuesday', label: 'Selasa' },
    { value: 'Wednesday', label: 'Rabu' },
    { value: 'Thursday', label: 'Kamis' },
    { value: 'Friday', label: 'Jumat' },
    { value: 'Saturday', label: 'Sabtu' }
  ],
  dayConvert :[
   { value:1,label:"pertama",},
   { value:2,label:"kedua",},
   { value:3,label:"ketiga",},
   { value:4,label:"keempat",},
   { value:5,label:"kelima",},
   { value:6,label:"keenam",},
   { value:7,label:"ketujuh",},
   { value:8,label:"kedelapan",},
   { value:9,label:"kesembilan",},
   { value:10,label:"kesepuluh",},
  ]
};

export default JsonData;
