import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPascabayarSummaryWeb from "./ppobPascabayarSummaryWeb";
import PPOBPascabayarSummaryMobile from "./ppobPascabayarSummaryMobile";

const PPOBPascabayarSummary = () => {
  return (
    <>
      <BrowserView>
        <PPOBPascabayarSummaryWeb></PPOBPascabayarSummaryWeb>
      </BrowserView>
      <MobileView>
        <PPOBPascabayarSummaryMobile></PPOBPascabayarSummaryMobile>
      </MobileView>
    </>
  );
};

export default PPOBPascabayarSummary;
