import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowNav from "../../assets/images/arrow-left-ic.svg";
import Exit from "../../assets/images/icon_features/add.png";
import Arrow from "../../assets/images/icon_features/arrow.png";
import Bell from "../../assets/images/icon_features/bell.svg";
import Check from "../../assets/images/icon_features/check.png";
import Bg from "../../assets/images/icon_features/subriptionBgdetail.svg";
import { ConfigColor, formatCurrency } from "../../config/global";
import LoadMoreButton from "../_common/loadMoreButton";

const ParkingFeaturesSubcriptionMobile = (props: any) => {
  const color = ConfigColor();
  const [filter, setFilter] = useState(false);
  const navigate = useNavigate();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      {props.active.status ? (
        <div
          className="bg-cover"
          style={{
            // Set tinggi minimal untuk mencakup seluruh layar
            backgroundImage: `url(${Bg})`, // Gradient dari atas ke bawah dari hijau ke putih
          }}
        >
          <div className="px-4 py-3 flex">
            <div
              className="rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300 flex items-center justify-center bg-white"
              onClick={() => navigate(-1)}
            >
              <img src={arrowNav} alt="" />
            </div>
            <h1 className="font-montserrat font-bold text-base m-auto relative">
              Langganan
            </h1>
          </div>
          <div className="text-center text-[#263339] font-[700] text-[28px] mt-4 leading-[32px]">
            Berlangganan jadi <br />
            nyaman !!
          </div>
          <div className="text-center mt-3">
            <span className="text-[#728F9E] text-center text-sm">
              Tidak perlu lagi khawatir tentang parkir! <br />
              Langganan sekarang dan nikmati kenyamanan <br />
              parkir yang tanpa repot
            </span>
          </div>
        </div>
      ) : (
        <div
          className="to-white px-4 pt-16 bg-cover"
          style={{
            // Set tinggi minimal untuk mencakup seluruh layar
            backgroundImage: `url(${Bg})`, // Gradient dari atas ke bawah dari hijau ke putih
          }}
        >
          <div className="flex justify-end" onClick={() => navigate(-1)}>
            <img src={Exit} alt="" />
          </div>
          <div className="text-center text-[#263339] font-[700] text-[28px] mt-4 leading-[32px]">
            Berlangganan jadi <br />
            nyaman !!
          </div>
          <div className="text-center mt-3">
            <span className="text-[#728F9E] text-center text-sm">
              Tidak perlu lagi khawatir tentang parkir! <br />
              Langganan sekarang dan nikmati kenyamanan <br />
              parkir yang tanpa repot
            </span>
          </div>
        </div>
      )}
      <div className="px-4 pb-[10rem]">
        {!props.active.status && (
          <div className="flex mt-4">
            <div className="bg-[#F3F3F3] flex mx-auto gap-2 p-2 rounded-[24px]">
              <button
                className={`${
                  props.barActive === "profit"
                    ? "text-white bg-[#26A69A] rounded-[24px]"
                    : "text-[#728F9E]"
                } text-xs font-[500] p-2 cursor-pointer `}
                onClick={() => {
                  props.setBarActive("profit");
                }}
              >
                Keuntungan
              </button>
              <button
                className={`${
                  props.barActive === "flow"
                    ? "text-white bg-[#26A69A] rounded-[24px]"
                    : "text-[#728F9E]"
                } text-xs font-[500] p-2 cursor-pointer`}
                onClick={() => {
                  props.setBarActive("flow");
                }}
              >
                Alur Pembayaran
              </button>
            </div>
          </div>
        )}
        <div className="flex mt-6">
          <div
            className={`transition-all ${
              props.barActive === "profit"
                ? "left-0 w-full opacity-100"
                : "left-full w-0 opacity-0"
            } mx-auto gap-2 p-0 rounded-[24px]`}
          >
            {/* Konten yang ingin ditampilkan di div pertama */}
            <div className="flex flex-col">
              <div className="flex justify-between items-center">
                <span className="text-4xl font-[600]">
                  {formatCurrency(props.active.price || props.config.price)}
                </span>
                {props.active.status && (
                  <div className="bg-[#408D86] font-[600] text-white p-2 rounded-[10px] text-[10px] px-4">
                    Active
                  </div>
                )}
              </div>
              <span className="text-sm mt-4">
                Keuntungan yang dapat anda nikmati layanan Langganan adalah
              </span>
              <div className="mt-2 flex flex-col gap-2">
                <span className="font-[500]">Benefit</span>
                <div className="flex items-center gap-2">
                  <img src={Check} alt="" />
                  <span className="text-xs">
                    Cukup bayar 1 kali Anda bisa parkir kapan saja
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Check} alt="" />
                  <span className="text-xs">
                    Tidak perlu khawatir saldo habis saat parkir
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <img src={Check} alt="" />
                  <span className="text-xs">Lebih hemat</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`transition-all ${
              props.barActive === "flow"
                ? "left-0 w-full opacity-100"
                : "left-full w-0 opacity-0"
            } mx-auto gap-2 rounded-[24px]`}
          >
            {/* Konten yang ingin ditampilkan di div kedua */}
            <div className="mt-2 flex flex-col gap-2">
              <span className="font-[500]">Langkah-langkah</span>
              <div className="flex items-center gap-2">
                <img src={Check} alt="" />
                <span className="text-xs">Klik Langganan</span>
              </div>
              <div className="flex items-center gap-2">
                <img src={Check} alt="" />
                <span className="text-xs">
                  Bayar sesuai metode pembayaran yang akan dilakukan
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2 font-[500] mt-4">
          *Langganan tidak dapat dihentikan ketika masih berlangsung
        </div>
        {props.data.length > 0 && (
          <div className="mt-4">
            <span className="text-[600] text-[#728F9E]">Riwayat</span>
            {props.data.map((item: any) => {
              return (
                <div
                  className="border-b py-2 cursor-pointer"
                  onClick={() => navigate("detail", { state: item })}
                >
                  <div className="flex justify-between">
                    <div className="flex gap-2 items-center">
                      <img src={Bell} alt="" />
                      <div className="flex flex-col">
                        <span className="font-[600]">
                          {moment
                            .utc(item.createdDate)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                        <span className="text-xs text-[#728F9E]">
                          tanggal kadaluarsa |{" "}
                          {moment
                            .utc(item.expiredDate)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center items-end">
                      <img src={Arrow} alt="" className="w-4" />
                      <span className="font-[700] text-[#28A745]">Sukses</span>
                    </div>
                  </div>
                </div>
              );
            })}
            {props.totalElements > props.size * props.page && (
              <LoadMoreButton addMore={props.addMore} />
            )}
          </div>
        )}
      </div>
      {!props.active.status && (
        <div className="p-4 bg-white fixed-bottom">
          <button
            className="bg-[#26A69A] text-[16px] font-[500] text-white w-full p-2 rounded-[10px]"
            onClick={() => props.checkout()}
          >
            Langganan
          </button>
        </div>
      )}
    </section>
  );
};

export default ParkingFeaturesSubcriptionMobile;
