/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SelfRegisterWeb from "./selfRegisterWeb";
import SelfRegisterMobile from "./selfRegisterMobile";
import {
  setOtpCompanyId,
  setOtpData,
  setOtpEmail,
  setOtpMessage,
  setOtpRedirect,
  setOtpTransactonType,
} from "../../core/feature/otp/otpSlice";
import JsonData from "../../config/JsonData";
import ToastConfig from "../../config/Toast";
import {
  setDataDestination,
  setShowDestinationOtp,
} from "../../core/feature/otp/destinationSlice";
import * as API_USER from "../../core/service/api_user";
import Company from "../../core/models/company";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { toast } from "react-hot-toast";
import moment from "moment";
import { useTranslation } from "react-i18next";

const SelfRegister = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const [metadata, setMetadata] = React.useState<any[]>([]);
  const [tagList, setTagList] = React.useState<any[]>([]);
  const requestMeta: any = {};
  const [customDate, setCustomDate] = React.useState<any>({});
  const [selectedData, setSelectedData] = React.useState<any>({});
  const [extraDatas, setExtraDatas] = React.useState<any>({});
  const [dataOrder, setDataOrder] = React.useState<any>({});
  const [isShowDestination, setIsShowDestination] = React.useState(false);

  const identityType = [
    {
      value: "ktp",
      label: t('identityType.ktp'),
    },
    {
      value: "sim",
      label: t('identityType.sim'),
    },
    {
      value: "passport",
      label: t('identityType.passport'),
    },
  ];

  const genderList = [
    {
      value: "male",
      label: t('genderList.male'),
    },
    {
      value: "female",
      label: t('genderList.female'),
    },
  ];

  const languageList = [
    {
      value: "id",
      label: t('languageList.id'),
    },
    {
      value: "en",
      label: t('languageList.en'),
    },
  ];

  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const getMetadata = async () => {
    API_USER.getMetaDataSelfRegister(company.id).then((res: any) => {
      let tempMetaData = metadata.concat(
        res.data.data.items.filter((el: any) => el.data.tag === "BasicData")
      );
      let allData = tempMetaData.concat(
        res.data.data.items.filter(
          (el: any) => el.data.tag === "AdditionalData"
        )
      );
      // let tempMetaData = res.data.data.items.reverse();
      setMetadata(allData);
      requestMeta.additionalData = {};
      let tempExtraData = extraDatas;
      let tempCustomDate = customDate;
      let tempSelectedData = selectedData;
      allData.map((meta: any) => {
        if (meta.data.isVisible) {
          var a = meta.data.key;
          if (meta.data.tag === "BasicData") {
            if (meta.data.key === "tags") {
              requestMeta[a] = [];
            } else {
              requestMeta[a] = "";
            }
          } else {
            if (meta.data.typeData === "Flags") {
              requestMeta.additionalData[a] = [];
            } else {
              requestMeta.additionalData[a] = "";
            }
          }
          requestMeta.companyId = company.id;
          requestMeta.confirmPassword = "";
          requestMeta.otp = "";

          if (
            meta.data.typeData === "Date" ||
            meta.data.typeData === "Datetime" ||
            meta.data.key === "dateOfBirth"
          ) {
            if (meta.data.tag === "BasicData") {
              if (meta.data.typeData === "Datetime") {
                requestMeta[a] = moment().format("YYYY-MM-DD hh:mm:ss");
              } else {
                requestMeta[a] = moment().format("YYYY-MM-DD");
              }
            } else {
              if (meta.data.typeData === "Datetime") {
                requestMeta.additionalData[a] = moment().format(
                  "YYYY-MM-DD hh:mm:ss"
                );
              } else {
                requestMeta.additionalData[a] = moment().format("YYYY-MM-DD");
              }
            }
          }

          if (
            meta.data.key === "dateOfBirth" ||
            meta.data.typeData === "Date" ||
            meta.data.typeData === "Datetime"
          ) {
            tempCustomDate = { ...tempCustomDate, [meta.data.key]: new Date() };
          }

          if (
            meta.data.key === "lang" ||
            meta.data.key === "gender" ||
            meta.data.typeData === "Enum"
          ) {
            tempSelectedData = { ...tempSelectedData, [meta.data.key]: "" };
          }

          if (meta.data.typeData === "Enum") {
            let newExtraData: any = [];
            if (meta.data.extraData.length > 0) {
              meta.data.extraData.map((el: any) =>
                newExtraData.push({
                  label: el,
                  value: el,
                })
              );
            }
            tempExtraData = { ...tempExtraData, [meta.data.key]: newExtraData };
          }
        }
      });
      console.log("tempExtraData", tempExtraData);
      console.log("tempCustomDate", tempCustomDate);
      console.log(requestMeta);
      setDataOrder(requestMeta);
      setExtraDatas(tempExtraData);
      setCustomDate(tempCustomDate);
    });
  };

  const getMetaDataTags = async () => {
    await API_USER.getMetaDataTags(company.id)
      .then((res) => {
        let filteredData = res.data.data.filter(
          (el: any) => el.isSelfRegisterSupported
        );
        if (filteredData.length > 0) {
          let tempTagList: any = [];
          filteredData.map((el: any) =>
            tempTagList.push({
              value: el.name,
              label: el.name,
            })
          );
          setTagList(tempTagList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeSelectedData = (value: any, key: string) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  const changeValue = async (
    val: any,
    key: string,
    tag: string = "",
    type: string = ""
  ) => {
    if (type === "Date" || type === "Datetime" || key === "dateOfBirth")
      if (val === "Invalid date") val = "";
    if (key.toLowerCase() === "password") {
      let tempDataOrder = dataOrder;
      dataOrder["confirmPassword"] = val;
      setDataOrder(tempDataOrder);
    }
    if (tag === "BasicData") {
      setDataOrder({ ...dataOrder, [key]: val });
    } else {
      if (type === "Flags") {
        setDataOrder({
          ...dataOrder,
          additionalData: {
            ...dataOrder.additionalData,
            [key]: [...dataOrder.additionalData[key], val],
          },
        });
      } else {
        setDataOrder({
          ...dataOrder,
          additionalData: {
            ...dataOrder.additionalData,
            [key]: val,
          },
        });
      }
    }
  };

  const confirm = async () => {
    console.log(dataOrder);
    const regexUsn = /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
    const regexPass =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,30}$/;
    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const regexPhone = /^\d{8,13}$/;

    metadata.map((res: any) => {
      if (res.data.isRequired === true) {
        if (res.data.key === "username" && dataOrder[res.data.key] !== "") {
          if (!dataOrder[res.data.key].match(regexUsn))
            toast(t('messages.usernameMin'), ToastConfig.warning);
        }
        if (res.data.key === "password" && dataOrder[res.data.key] !== "") {
          if (!dataOrder[res.data.key].match(regexPass))
            toast(
              t('messages.passwordMin'),
              ToastConfig.warning
            );
        }
        if (res.data.key === "email" && dataOrder[res.data.key] !== "") {
          if (!dataOrder[res.data.key].match(regexEmail))
            toast(t('messages.invalidEmail'), ToastConfig.warning);
        }
        if (res.data.key === "phone" && dataOrder[res.data.key] !== "") {
          if (!dataOrder[res.data.key].match(regexPhone))
            toast(t('messages.invalidPhone'), ToastConfig.warning);
        }
        if (res.data.typeData === "Email" && dataOrder[res.data.key] !== "") {
          if (!dataOrder.additionalData[res.data.key].match(regexEmail))
            toast(t('messages.invalidEmail'), ToastConfig.warning);
        }
        if (res.data.key === "tags" && dataOrder[res.data.key].length <= 0) {
          toast(res.data.display + t(' messages.fillRequired'), ToastConfig.warning);
        }
        if (res.data.tag === "BasicData") {
          if (dataOrder[res.data.key] === "") {
            toast(res.data.display + t(' messages.fillRequired'), ToastConfig.warning);
          }
        } else {
          if (
            dataOrder.additionalData[res.data.key] === "" ||
            dataOrder.additionalData[res.data.key].length === 0 ||
            dataOrder.additionalData[res.data.key] === null
          ) {
            toast(res.data.display + t(' messages.fillRequired'), ToastConfig.warning);
          }
        }
      }
    });

    let filteredData = metadata.filter((el: any) => el.data.isRequired);
    let emptyValue: any = [];
    if (filteredData.length > 0) {
      filteredData.map((item: any) => {
        if (item.data.tag === "BasicData") {
          let findData = dataOrder[item.data.key];
          if (findData !== undefined) {
            if (item.data.key === "username") {
              if (!findData.match(regexUsn)) emptyValue.push(item.data);
            } else if (item.data.key === "password") {
              if (!findData.match(regexPass)) emptyValue.push(item.data);
            } else if (item.data.key === "email") {
              if (!findData.match(regexEmail)) emptyValue.push(item.data);
            } else if (item.data.key === "phone") {
              if (!findData.match(regexPhone)) emptyValue.push(item.data);
            } else {
              if (findData === "" || findData.length === 0)
                emptyValue.push(item.data);
            }
          }
        } else {
          let findData = dataOrder.additionalData[item.data.key];
          if (findData !== undefined) {
            if (item.data.typeData === "Email") {
              if (!findData.match(regexEmail)) emptyValue.push(item.data);
            } else {
              if (findData === "" || findData.length === 0)
                emptyValue.push(item.data);
            }
          }
        }
      });
      console.log("filteredData: ", filteredData);
      console.log("emptyValue: ", emptyValue);
      if (emptyValue.length === 0) {
        let visibleData = metadata.filter((el: any) => el.data.isVisible);
        let tempDataOrder = dataOrder;
        if (visibleData.length > 0) {
          let checkData = visibleData.filter((el: any) => {
            if (el.data.tag === "BasicData") {
              return dataOrder[el.data.key] === "";
            } else if (el.data.typeData === "Flags") {
              return dataOrder.additionalData[el.data.key].length === 0;
            } else {
              return dataOrder.additionalData[el.data.key] === "";
            }
          });
          console.log(checkData);
          if (checkData.length > 0) {
            checkData.map((el: any) => {
              if (el.data.tag === "BasicData") {
                tempDataOrder = { ...tempDataOrder, [el.data.key]: null };
                console.log(tempDataOrder);
              } else {
                tempDataOrder = {
                  ...tempDataOrder,
                  additionalData: {
                    ...tempDataOrder.additionalData,
                    [el.data.key]: null,
                  },
                };
                console.log(tempDataOrder);
              }
            });
          }
        }
        console.log(tempDataOrder);
        setDataOrder(tempDataOrder);
        dispatch(setDataDestination({ ...tempDataOrder, phone: null }));
        setIsShowDestination(true);
      }
    }
  };

  React.useEffect(() => {
    if (isShowDestination) {
      setIsShowDestination(false);
      dispatch(setOtpTransactonType("SELF_REGISTER"));
      dispatch(setOtpMessage(JsonData.SubjectOtp["SELF_REGISTER"]));
      dispatch(setOtpData(dataOrder));
      dispatch(setOtpEmail(dataOrder.email));
      dispatch(setOtpCompanyId(company?.id));
      dispatch(setOtpRedirect(`/${company?.initial}/login`));
      // dispatch(setShowOtp(true));
      dispatch(setShowDestinationOtp(true));
    }
  }, [isShowDestination]);


  React.useEffect(() => {
    getMetadata();
    getMetaDataTags();
    const loop = setInterval(() => {
      sessionStorage.setItem("loader", JSON.stringify(false));
    }, 100);
    return () => clearInterval(loop);
  }, []);

  return (
    <>
      <BrowserView>
        <SelfRegisterWeb
          changeSelectedData={(value: any, key: string) =>
            changeSelectedData(value, key)
          }
          changeValue={(val: any, key: string, tag: string, type: string) =>
            changeValue(val, key, tag, type)
          }
          filterPassedTime={(time: any) => filterPassedTime(time)}
          setCustomDate={(value: any) => setCustomDate(value)}
          confirm={() => confirm()}
          company={company}
          metadata={metadata}
          tagList={tagList}
          customDate={customDate}
          selectedData={selectedData}
          extraDatas={extraDatas}
          dataOrder={dataOrder}
          identityType={identityType}
          genderList={genderList}
          languageList={languageList}
        />
      </BrowserView>
      <MobileView>
        <SelfRegisterMobile
          changeSelectedData={(value: any, key: string) =>
            changeSelectedData(value, key)
          }
          changeValue={(val: any, key: string, tag: string, type: string) =>
            changeValue(val, key, tag, type)
          }
          filterPassedTime={(time: any) => filterPassedTime(time)}
          setCustomDate={(value: any) => setCustomDate(value)}
          confirm={() => confirm()}
          company={company}
          metadata={metadata}
          tagList={tagList}
          customDate={customDate}
          selectedData={selectedData}
          extraDatas={extraDatas}
          dataOrder={dataOrder}
          identityType={identityType}
          genderList={genderList}
          languageList={languageList}
        />
      </MobileView>
    </>
  );
};

export default SelfRegister;
