import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InputNominalWeb from "./inputNominalWeb";
import InputNominalMobile from "./inputNominalMobile";

const InputNominal = () => {
  return (
    <>
      <BrowserView>
        <InputNominalWeb></InputNominalWeb>
      </BrowserView>
      <MobileView>
        <InputNominalMobile></InputNominalMobile>
      </MobileView>
    </>
  );
};

export default InputNominal;
