/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import Refunded from "../../assets/images/security_refunded.png";
import Success from "../../assets/images/security_success.png";
import Failed from "../../assets/images/security-fail.png";
import Pending from "../../assets/images/Pending.png";
import { formatCurrency } from "../../config/global";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import * as API_BALANCE from "../../core/service/api_balance";
import Header from "../_common/headerComponent";
import arrow_down from "../../assets/images/DropDown.svg";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const MutationDetailWeb = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<any>("");
  // const [read, setRead] = useState("");
  const location: any = useLocation();
  const [currentIndex, setCurrentIndex] = useState<any>([]);
  const { t } = useTranslation();

  const collapseProduct = async (productId: string) => {
    setCurrentIndex({ ...currentIndex, [productId]: !currentIndex[productId] });
  };

  const getMutation = async () => {
    dispatch(setLoading(true));
    let query = {
      idMutation: location?.state.mutationId,
      balanceCode: location?.state.balanceCode,
    };
    await API_BALANCE.getMutationById(query)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        // navigate(-1);
      });
  };

  useEffect(() => {
    getMutation();
  }, []);

  return (
    <section className=" relative font-montserrat ">
      <div className="flex flex-col justify-between min-h-screen  bg-mobile">
        <div>
          <Header />
          <div className="container flex flex-col justify-center items-center gap-4 md:mt-[64px] mt-[0px] px-4">
            <div className="md:w-3/5 sm:w-full w-full mb-20 ">
              <div className="flex-grow">
                {data.status === "PAID" ||
                  data.status === "DONE" ||
                  data.status === "SUKSES" ? (
                  <div className="flex flex-col gap-2">
                    <img src={Success} alt="" className="mx-auto" />
                    <span className="text-xl text-[#26A69A] font-[600] text-center">
                      {t("ppob.success")}
                    </span>
                  </div>
                ) : data.status === "GAGAL" || data.status === "UNPAID" ? (
                  <div className="flex flex-col gap-2">
                    <img src={Failed} alt="" className="mx-auto w-32" />
                    <span className="text-xl font-[600] text-center text-red">
                      {data.status === "GAGAL" ? t("ppob.failed") : t("history.notYetPaid")}
                    </span>
                  </div>
                ) : data.status === "PROCESSING" ? (
                  <div className="flex flex-col gap-2">
                    <img src={Pending} alt="" className="mx-auto w-32" />
                    <span className="text-xl text-amber-400 font-[600] text-center">
                      {t("history.being_processed")}
                    </span>
                  </div>
                ) : data.status === "REFUNDED" ? (
                  <div className="flex flex-col gap-2">
                    <img src={Refunded} alt="" className="mx-auto" />
                    <span className="text-xl text-[#728F9E] font-[600] text-center">
                      {t("history.funds_refunded")}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {location.state.transactionType === "TRANSFER MEMBER" ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.createTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {location.state.transactionType}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.delivery")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.userSourceName}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.id_delivery")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.userSourceNoId}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.no_telp")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.userSourcePhone || "-"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("donation.recipient")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.userDestName}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("invoiceCreate.userId")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.userDestNoId}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("invoiceCreate.recipient_phone_number")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.userDestPhone || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("inputDataWeb.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.total)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.adminFee")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.totalCharges)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.transaction_total")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amount)}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.total)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType ===
                  "TOP UP VIRTUAL ACCOUNT MEMBER" ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.createTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {location.state.transactionType}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("inputDataWeb.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.total)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.adminFee")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.totalCharges)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.transaction_total")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amount)}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.total)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType === "TOP UP MERCHANT" ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.createTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {location.state.transactionType}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("virtual_card.member_name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.memberName}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("profile.member_id")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.memberNoId}
                        </span>
                      </div>

                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("barcodeWeb.merchantName")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.merchantName}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("barcodeWeb.merchant_id")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.merchantNoId}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("barcodeWeb.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.total)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.adminFee")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.totalCharges)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.transaction_total")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amount)}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.total)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType === "TOP UP ADMIN" ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("create_order.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.createTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {location.state.transactionType}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.nameOfUser || "-"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          Id
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.noId || "-"}
                        </span>
                      </div>

                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.admin_name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.adminName || "-"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          Id Admin
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.adminId || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("pln.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.total)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.adminFee")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.totalCharges)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.transaction_total")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amount)}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.total)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType === "TRANSAKSI BARCODE" ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("create_order.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.createTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {location.state.transactionType}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("virtual_card.member_name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.memberName}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          Id Member
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.memberNoId}
                        </span>
                      </div>

                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("barcodeWeb.merchantName")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.merchantName}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("barcodeWeb.merchant_id")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.merchantNoId}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("barcodeWeb.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amount)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.adminFee")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.totalCharges)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.transaction_total")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.total)}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.total)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType.includes("TRANSFER BANK") ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("create_order.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.createTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {location.state.transactionType}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("virtual_card.member_name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.name}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          Id Member
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.noId}
                        </span>
                      </div>

                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.destination_account_name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.refObject?.destinationAccountName}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.no_account")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.refObject?.destinationAccountNumber}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.bank_name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.destBank}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amount)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.adminFee")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.totalCharges)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.transaction_total")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.total)}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.total)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType.includes("DONASI") ||
                  location.state.transactionType.includes("ZAKAT") ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("create_order.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.createTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {location.state.transactionType}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.nameOfUser || "******"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          Email
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.emailOfUser || "-"}
                        </span>
                      </div>

                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("donation.message")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data?.message || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amount)}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType === "DISBURSEMENT ADMIN" ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("create_order.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.createTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {location.state.transactionType}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.nameOfUser || "******"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          Id
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.noId || "-"}
                        </span>
                      </div>

                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.admin_name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data?.adminName || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("pln.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.total)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.adminFee")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.totalCharges)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.transaction_total")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amount)}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.total)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType.includes("INVOICE") ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("invoiceCreate.invoiceName")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.invoiceTitle || "-"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.createTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {location.state.transactionType}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.delivery_name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.accountSenderName || "-"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.id_delivery")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.accountSenderNoId || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amount)}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.amount)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType.includes("MARKETPLACE") &&
                  data.type === "FnB" ? (
                  <div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("create_order.transaction_date")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {moment
                            .utc(data.orderDate)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.type_transaction")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.type}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("ppobHistory.name")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.customer?.name || "-"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.phone")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.customer?.phoneNumber || "-"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          Email
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.customer?.email || "-"}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("history.note")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {data.customer?.note || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 flex flex-col gap-3">
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("pln.nominal")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.totalProductPrice)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.adminFee")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.amountCharge)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className="text-sm font-[500] break-words">
                          {t("withdraw_funds.transaction_total")}
                        </span>
                        <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                          {formatCurrency(data.totalPayment) || "-"}
                        </span>
                      </div>
                    </div>
                    {location.state.mutationType === "DEBT" ? (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_decreased")}{" "}
                        {formatCurrency(data.totalPayment)}
                      </div>
                    ) : (
                      <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                        {t("withdraw_funds.balance_increased")}{" "}
                        {formatCurrency(data.total)}
                      </div>
                    )}
                  </div>
                ) : location.state.transactionType.includes("MARKETPLACE") &&
                  data.type === "MARKETPLACE" ? (
                  <div className="flex flex-col gap-4">
                    {_.map(data?.orders, (item) => (
                      <div>
                        <div className="text-end">
                          <span
                            className={`font-medium md:text-sm text-[10px] ${props.getStatus(item.status).bgColor
                              } py-1.5 px-2 rounded-md ${props.getStatus(item.status).textColor
                              }`}
                          >
                            {props.getStatus(item.status).text}
                          </span>
                        </div>
                        <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3 mt-2">
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("transfer_bank.date_order")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {moment
                                .utc(item.orderCreatedDate)
                                .local()
                                .format("DD MMMM YYYY")}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("history.type_transaction")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {location.state.transactionType}
                            </span>
                          </div>
                        </div>
                        <div className="py-3 border-b border-[#728F9E] flex flex-col gap-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.snacks")}
                          </span>
                          {item?.products?.map((item: any, index: string) => (
                            <div key={index}>
                              <div className="flex flex-row justify-between items-center">
                                <span className="font-semibold md:text-sm text-xs text-[#728F9E]">
                                  {item.productName}
                                </span>

                                <div
                                  className="flex flex-row gap-1 items-center cursor-pointer"
                                  onClick={() => collapseProduct(item.itemId)}
                                >
                                  <span className="font-light text-[10px] text-[#728F9E]">
                                    {t("transfer_bank.more")}{" "}
                                    {currentIndex[item.itemId]
                                      ? t("transfer_bank.complete")
                                      : t("transfer_bank.litte")}
                                  </span>
                                  <img
                                    src={arrow_down}
                                    alt=""
                                    className={
                                      currentIndex[item.itemId]
                                        ? "transform rotate-180 w-2 h-2"
                                        : "w-2 h-2"
                                    }
                                  />
                                </div>
                              </div>
                              <ul
                                className="list-disc"
                                hidden={!currentIndex[item.itemId]}
                              >
                                <li>
                                  <div className="flex flex-row justify-between items-end md:text-sm text-xs font-light text-[#9A9999]">
                                    <span>
                                      {item.quantity}X{" "}
                                      {formatCurrency(item.price)}
                                    </span>
                                    <span>
                                      {" "}
                                      {formatCurrency(
                                        item.price * item.quantity
                                      )}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ))}
                        </div>
                        <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("transfer_bank.stall")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {item.merchant?.name || "-"}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("transfer_bank.phone_stall")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {item.merchant?.phone || "-"}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("transfer_bank.shop_email")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {item.merchant?.email || "-"}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("transfer_bank.shop_address")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {item.merchant?.address || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="py-3 flex flex-col gap-3">
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("history.optionDelivery")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {item.ship.name}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("pln.nominal")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {formatCurrency(item.amountProduct)}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("withdraw_funds.adminFee")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {formatCurrency(item.amountCharge)}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              {t("history.shippingCost")}
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {formatCurrency(item.amountDelivery)}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-sm font-[500] break-words">
                              Total
                            </span>
                            <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                              {formatCurrency(item.amountTotal) || "-"}
                            </span>
                          </div>
                        </div>
                        {location.state.mutationType === "DEBT" ? (
                          <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                            {t("withdraw_funds.balance_decreased")}{" "}
                            {formatCurrency(item.amountTotal)}
                          </div>
                        ) : (
                          <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                            {t("withdraw_funds.balance_increased")}{" "}
                            {formatCurrency(item.amountTotal)}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : location.state.transactionType.includes("PPOB") ? (
                  <div className="flex flex-col gap-4">
                    <div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.transaction_date")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {moment
                              .utc(data.transactionDate)
                              .local()
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.type_transaction")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {location.state.transactionType}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.customer_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.customerName || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.customer_phone")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.customerNumber || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.product_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.productName || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.product_code")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.productCode || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.nominal")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(
                              data?.desc ? data?.desc[0]?.value : "0"
                            ) || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.administrative_costs")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(
                              _.sumBy(
                                _.filter(data?.desc, {
                                  key: t("transfer_bank.administrative_costs"),
                                }),
                                `value`
                              )
                            ) || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.transaction_total")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.totalPrice) || "-"}
                          </span>
                        </div>
                      </div>
                      {location.state.mutationType === "DEBT" ? (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_decreased")}{" "}
                          {formatCurrency(data.totalPrice)}
                        </div>
                      ) : (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_increased")}{" "}
                          {formatCurrency(data.totalPrice)}
                        </div>
                      )}
                    </div>
                  </div>
                ) : location.state.transactionType === "TOUR" ? (
                  <div className="flex flex-col gap-4">
                    <div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.transaction_date")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {moment
                              .utc(data.createdDate)
                              .local()
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.type_transaction")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {location.state.transactionType}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.product_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.tourProductName || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.product_id")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.tourProductId || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.nominal")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.quantity} x{" "}
                            {formatCurrency(data.price) || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            Total
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.amountTotal) || "-"}
                          </span>
                        </div>
                      </div>
                      {location.state.mutationType === "DEBT" ? (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_decreased")}{" "}
                          {formatCurrency(data.amountTotal)}
                        </div>
                      ) : (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_increased")}{" "}
                          {formatCurrency(data.total)}
                        </div>
                      )}
                    </div>
                  </div>
                ) : location.state.transactionType.includes(
                  "PENARIKAN SALDO KARTU"
                ) ||
                  location.state.transactionType.includes(
                    "TOP UP SALDO KARTU"
                  ) ? (
                  <div className="flex flex-col gap-4">
                    <div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.transaction_date")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {moment
                              .utc(data.createdTime)
                              .local()
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.type_transaction")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {location.state.transactionType}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("virtual_card.card_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.cardName || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.user_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.user?.name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.nominal")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.total)}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("topup.adminFee")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.totalCharges)}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            Total
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.amount) || "-"}
                          </span>
                        </div>
                      </div>
                      {location.state.mutationType === "DEBT" ? (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_decreased")}{" "}
                          {formatCurrency(data.amount)}
                        </div>
                      ) : (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_increased")}{" "}
                          {formatCurrency(data.total)}
                        </div>
                      )}
                    </div>
                  </div>
                ) : location.state.transactionType.includes(
                  "PENGEMBALIAN TRANSAKSI BARCODE"
                ) ? (
                  <div className="flex flex-col gap-4">
                    <div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.transaction_date")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {moment
                              .utc(data.createTime)
                              .local()
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.type_transaction")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {location.state.transactionType}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.memberName || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            Id
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.memberNoId || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.shop_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.merchantName || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.shop_id")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data.merchantNoId || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.nominal")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.amount)}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.adminFee")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.totalCharges)}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            Total
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.total) || "-"}
                          </span>
                        </div>
                      </div>
                      {location.state.mutationType === "DEBT" ? (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_decreased")}{" "}
                          {formatCurrency(data.amount)}
                        </div>
                      ) : (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_increased")}{" "}
                          {formatCurrency(data.total)}
                        </div>
                      )}
                    </div>
                  </div>
                ) : location.state.transactionType.includes(
                  "PEMBAYARAN KARTU MERCHANT"
                ) ? (
                  <div className="flex flex-col gap-4">
                    <div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.transaction_date")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {moment
                              .utc(data.createdTime)
                              .local()
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.type_transaction")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {location.state.transactionType}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.member?.name || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            Id
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.member?.noId || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.shop_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.merchant?.name || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.shop_id")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.merchant?.noId || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.nominal")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.amount)}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.adminFee")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(
                              data?.refOfObject?.totalChargesMember
                            ) || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            Total
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data?.refOfObject?.totalMember) ||
                              "-"}
                          </span>
                        </div>
                      </div>
                      {location.state.mutationType === "DEBT" ? (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_decreased")}{" "}
                          {formatCurrency(data?.refOfObject?.totalMember) ||
                            "-"}
                        </div>
                      ) : (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_increased")}{" "}
                          {formatCurrency(data.total)}
                        </div>
                      )}
                    </div>
                  </div>
                ) : location.state.transactionType.includes("FNB") ? (
                  <div className="flex flex-col gap-4">
                    <div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.transaction_date")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {moment
                              .utc(data.billsDate)
                              .local()
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.type_transaction")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {location.state.transactionType}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.customer_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.customer?.name || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.phone")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.customer?.phoneNumber || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("virtual_card.paymentMethod")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.paymentMethod || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.note")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.customer?.note || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.snacks")}
                        </span>
                        {data?.productList?.map((item: any, index: string) => (
                          <div key={index}>
                            <div className="flex flex-row justify-between items-center">
                              <span className="font-semibold md:text-sm text-xs text-[#728F9E]">
                                {item.name}
                              </span>

                              <div
                                className="flex flex-row gap-1 items-center cursor-pointer"
                                onClick={() => collapseProduct(item.itemId)}
                              >
                                <span className="font-light text-[10px] text-[#728F9E]">
                                  {t("transfer_bank.more")}{" "}
                                  {currentIndex[item.itemId]
                                    ? t("transfer_bank.complete")
                                    : t("transfer_bank.litte")}
                                </span>
                                <img
                                  src={arrow_down}
                                  alt=""
                                  className={
                                    currentIndex[item.itemId]
                                      ? "transform rotate-180 w-2 h-2"
                                      : "w-2 h-2"
                                  }
                                />
                              </div>
                            </div>
                            <ul
                              className="list-disc"
                              hidden={!currentIndex[item.itemId]}
                            >
                              <li>
                                <div className="flex flex-row justify-between items-end md:text-sm text-xs font-light text-[#9A9999]">
                                  <span>
                                    {item.quantity}X{" "}
                                    {formatCurrency(item.price)}
                                  </span>
                                  <span>
                                    {" "}
                                    {formatCurrency(item.price * item.quantity)}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        ))}
                      </div>
                      <div className="py-3 flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.nominal")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.totalProductPrice)}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.adminFee")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data?.totalCharge) || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.transaction_total")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data?.totalPayment) || "-"}
                          </span>
                        </div>
                      </div>
                      {location.state.mutationType === "DEBT" ? (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_decreased")}{" "}
                          {formatCurrency(data?.totalPayment) || "-"}
                        </div>
                      ) : (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_increased")}{" "}
                          {formatCurrency(data.totalProductPrice)}
                        </div>
                      )}
                    </div>
                  </div>
                ) : location.state.transactionType.includes("POS") ? (
                  <div className="flex flex-col gap-4">
                    <div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.transaction_date")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {moment
                              .utc(data.billsDate)
                              .local()
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.type_transaction")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {location.state.transactionType}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.customer_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.customer?.name || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.phone")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.customer?.phoneNumber || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.note")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.customer?.note || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-2">
                        <span className="text-sm font-[500] break-words">
                          {t("transfer_bank.snacks")}
                        </span>

                        {data?.productList?.map((item: any, index: string) => (
                          <div key={index}>
                            <div className="flex flex-row justify-between items-center">
                              <span className="font-semibold md:text-sm text-xs text-[#728F9E]">
                                {item.name}
                              </span>

                              <div
                                className="flex flex-row gap-1 items-center cursor-pointer"
                                onClick={() => collapseProduct(item.itemId)}
                              >
                                <span className="font-light text-[10px] text-[#728F9E]">
                                  {t("transfer_bank.more")}{" "}
                                  {currentIndex[item.itemId]
                                    ? t("transfer_bank.complete")
                                    : t("transfer_bank.litte")}
                                </span>
                                <img
                                  src={arrow_down}
                                  alt=""
                                  className={
                                    currentIndex[item.itemId]
                                      ? "transform rotate-180 w-2 h-2"
                                      : "w-2 h-2"
                                  }
                                />
                              </div>
                            </div>
                            <ul
                              className="list-disc"
                              hidden={!currentIndex[item.itemId]}
                            >
                              <li>
                                <div className="flex flex-row justify-between items-end md:text-sm text-xs font-light text-[#9A9999]">
                                  <span>
                                    {item.quantity}X{" "}
                                    {formatCurrency(item.price)}
                                  </span>
                                  <span>
                                    {" "}
                                    {formatCurrency(item.price * item.quantity)}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        ))}
                      </div>
                      <div className="py-3 flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.nominal")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.totalProductPrice)}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.adminFee")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data?.totalCharge) || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.transaction_total")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data?.totalPayment) || "-"}
                          </span>
                        </div>
                      </div>
                      {location.state.mutationType === "DEBT" ? (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_decreased")}{" "}
                          {formatCurrency(data?.totalPayment) || "-"}
                        </div>
                      ) : (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_increased")}{" "}
                          {formatCurrency(data.totalProductPrice)}
                        </div>
                      )}
                    </div>
                  </div>
                ) : location.state.transactionType.includes(
                  "PEMBUATAN KARTU"
                ) ? (
                  <div className="flex flex-col gap-4">
                    <div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.transaction_date")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {moment
                              .utc(data.billsDate)
                              .local()
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.type_transaction")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {location.state.transactionType}
                          </span>
                        </div>
                      </div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.user?.name || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("virtual_card.card_name")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.cardName || "-"}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.card_id")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {data?.cardId || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="py-3 flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.nominal")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.amount)}
                          </span>
                        </div>
                      </div>
                      {location.state.mutationType === "DEBT" ? (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_decreased")}{" "}
                          {formatCurrency(data?.amount) || "-"}
                        </div>
                      ) : (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_increased")}{" "}
                          {formatCurrency(data.amount)}
                        </div>
                      )}
                    </div>
                  </div>
                ) : location.state.transactionType.includes("PARKING") ? (
                  <div className="flex flex-col gap-4">
                    <div>
                      <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("create_order.transaction_date")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {moment
                              .utc(data.createdDate)
                              .local()
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("history.type_transaction")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {location.state.transactionType}
                          </span>
                        </div>
                      </div>

                      <div className="py-3 flex flex-col gap-3">
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.nominal")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.amountPrice)}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.adminFee")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.totalCharges)}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-sm font-[500] break-words">
                            {t("transfer_bank.transaction_total")}
                          </span>
                          <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                            {formatCurrency(data.amountPayment)}
                          </span>
                        </div>
                      </div>

                      {location.state.mutationType === "DEBT" ? (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_decreased")}{" "}
                          {formatCurrency(data?.amountPayment) || "-"}
                        </div>
                      ) : (
                        <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                          {t("withdraw_funds.balance_increased")}{" "}
                          {formatCurrency(data.amountPrice)}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container flex flex-col justify-center items-center gap-4 px-4 py-4">
          <div className="md:w-3/5 sm:w-full w-full">
            <button
              className="font-[500] text-white rounded-[10px] bg-[#26A69A] p-2 w-full"
              onClick={() => navigate(-1)}
            >
              {t("transfer_bank.home")}
            </button>
          </div>
        </div>
      </div>
      {/* MODAL */}
    </section>
  );
};

export default MutationDetailWeb;
