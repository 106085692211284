/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Company from "../../../../core/models/company";
import Header from "../../../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
// import { useNavigate } from "react-router-dom";
import * as API_BALANCE from "../../../../core/service/api_balance";
import { setLoading } from "../../../../core/feature/config/configSlice";
// import BottomLogo from "../../../_common/bottomLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faTimes } from "@fortawesome/free-solid-svg-icons";
import LogoBNI from "../../../../assets/images/icon_bank/logo_bni.png";
import LogoBSI from "../../../../assets/images/icon_bank/logo_bsi.png";
import LogoBRI from "../../../../assets/images/icon_bank/logo_bri.png";
import LogoBCA from "../../../../assets/images/icon_bank/logo_bca.png";
import LogoMandiri from "../../../../assets/images/icon_bank/logo_mandiri.png";
import LogoBTN from "../../../../assets/images/icon_bank/logo_btn.png";
import LogoCIMBNiaga from "../../../../assets/images/icon_bank/cimb.png";
import LogoPermata from "../../../../assets/images/icon_bank/Permata.svg";
import LogoDanamon from "../../../../assets/images/icon_bank/Danamon.svg";
import LogoBankJatim from "../../../../assets/images/icon_bank/Bank Jatim Syriah BJS.svg";
import {
  setOtpData,
  // setOtpNavigateState,
  setOtpNavigateTo,
  setOtpRequested,
  setOtpTransactonType,
  setOtpUrl,
} from "../../../../core/feature/otp/otpSlice";
import {
  setDataDestination,
  setIsEmailVerifiedDestination,
  setIsPhoneVerifiedDestination,
  setShowDestinationOtp,
  setUsernameDestination,
} from "../../../../core/feature/otp/destinationSlice";
// import Config from "../../../../config/Config";
import { cekEmailPhone } from "../../../../core/service/api_auth";
import User from "../../../../core/models/user";
// import { setShowAddDestinationOtp } from "../../../../core/feature/otp/addDestinationSlice";
import bankNotFound from "../../../../assets/images/bank_not_found.svg";
import { useTranslation } from "react-i18next";

const bankLogo = [
  {
    name: "BNI",
    logo: LogoBNI,
    code: "009",
  },
  {
    name: "BSI",
    logo: LogoBSI,
    code: "451",
  },
  {
    name: "BRI",
    logo: LogoBRI,
    code: "002",
  },
  {
    name: "BCA",
    logo: LogoBCA,
    code: "014",
  },
  {
    name: "Mandiri",
    logo: LogoMandiri,
    code: "008",
  },
  {
    name: "BTN",
    logo: LogoBTN,
    code: "200",
  },
  {
    name: "CIMB Niaga",
    logo: LogoCIMBNiaga,
    code: "022",
  },
  {
    name: "Permata",
    logo: LogoPermata,
    code: "013",
  },
  {
    name: "Danamon",
    logo: LogoDanamon,
    code: "011",
  },
  {
    name: "Jatim Syariah",
    logo: LogoBankJatim,
    code: "114",
  },
];

const AddBankAccountMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [listBank, setListBank] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [errorBankName, setErrorBankName] = useState("");
  const [errorAccountNumber, setErrorAccountNumber] = useState("");
  const [errorAccountName, setErrorAccountName] = useState("");
  const [searchBank, setSearchBank] = useState("");

  const getComboMasterBank = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getComboMasterBank()
      .then((res) => {
        dispatch(setLoading(false));
        setListBank(res.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = async () => {
    if (bankName === "" && false) {
      setErrorBankName(t("addBankAccount.errorBankName"));
    } else if (accountNumber === "" && false) {
      setErrorAccountNumber(t("addBankAccount.errorAccountNumber"));
    } else {
      let data = {
        accountNumber,
        accountName: accountName,
        bankCode: bankCode,
      };
      dispatch(setLoading(true));
      dispatch(setUsernameDestination(user.username));
      cekEmailPhone(company.id, user.username)
        .then((res) => {
          console.log(res);
          dispatch(setLoading(false));
          dispatch(
            setDataDestination({
              email: res.data.data.email,
              phone: res.data.data.phone,
            })
          );
          dispatch(setOtpData(data));
          dispatch(
            setIsEmailVerifiedDestination(
              user?.emailVerified || user?.verifiedEmail
            )
          );
          dispatch(
            setIsPhoneVerifiedDestination(
              user?.phoneVerified || user?.verifiedPhone
            )
          );
          dispatch(setOtpRequested(true));
          dispatch(setOtpUrl(`balance/member/bank_account/v3`));
          dispatch(setShowDestinationOtp(true));
          dispatch(setOtpTransactonType("ADD_BANK_ACCOUNT"));
          dispatch(
            setOtpNavigateTo(`${company.initial}/transfer/bank/input-data`)
          );
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
      // await API_BALANCE.AddBankAccountV2(data)
      //   .then((res) => {
      //     dispatch(setLoading(false));
      //     navigate(`/${company?.initial}/transfer/bank/input-data`, {
      //       replace: true,
      //       state: res.data.data,
      //     });
      //   })
      //   .catch((err) => {
      //     dispatch(setLoading(false));
      //   });
    }
  };

  // const [configColor, setConfigColor] = useState<any>({});
  // const getConfigColor = async () => {
  //   var color1 =
  //     company?.color?.color1 != undefined ? company?.color?.color1 : "#006F54";
  //   var color2 =
  //     company?.color?.color2 != undefined ? company?.color?.color2 : "#087B5E";

  //   setConfigColor({ color1: color1, color2: color2 });
  // };

  // useEffect(() => {
  //   getConfigColor();
  // }, []);

  useEffect(() => {
    getComboMasterBank();
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      {/* MODAL */}
      <div
        className={`${showModal ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 p-3">
              <div className="space-between md:px-[0px] px-[0.5rem]">
                <h4 className="md:text-lg text-base text-customBlack font-medium mb-0">
                  {t("addBankAccount.bankList")}
                </h4>
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} className="fa-xl" />
                </button>
              </div>
              <hr />
              <div className="relative flex items-center rounded-lg border-[1px] border-newColor bg-white mb-3">
                <span className="p-2">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </span>
                <input
                  type="text"
                  value={searchBank}
                  placeholder={t("addBankAccount.searchPlaceholder")}
                  onChange={(e) => setSearchBank(e.target.value)}
                  className="bg-white w-full rounded-tr-lg rounded-br-lg p-2 md:text-base text-sm focus:outline outline-[1px] outline-newColor"
                />
              </div>
              <ul className="px-3 mb-0">
                {listBank.filter((el) =>
                  el.bankName.toLowerCase().includes(searchBank.toLowerCase())
                ).length > 0 ? (
                  listBank
                    .filter((el) =>
                      el.bankName
                        .toLowerCase()
                        .includes(searchBank.toLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <li
                          className="list-none cursor-pointer hover:bg-teal-50"
                          key={index}
                          onClick={() => {
                            setBankName(item.bankName);
                            setBankCode(item.bankCode);
                            setErrorBankName("");
                            setShowModal(false);
                          }}
                        >
                          <div className="flex flex-row items-center gap-3 px-2 pt-2">
                            <img
                              src={
                                bankLogo.find((i) => i.code === item.bankCode)
                                  ?.logo
                              }
                              alt=""
                              width={48}
                              height={48}
                            />
                            <p className="md:text-base text-sm font-semibold mb-0 text-start">
                              {item.bankName}
                            </p>
                          </div>
                          <hr className="mt-2" />
                        </li>
                      );
                    })
                ) : (
                  <li className="w-full pb-10">
                    <img src={bankNotFound} className="flex mx-auto" />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Header navbarText={t("getAllBankAccount.title")}></Header>
      <div className="flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0 pb-[64px]">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto">
          <h5 className="md:text-lg text-base text-customBlack font-semibold pl-1 mb-3 px-4">
            {t("addBankAccount.addRecipient")}
          </h5>
          <div className="bg-white p-7 md:mb-10 mb-6 shadow-sm">
            <div className="text-start w-full mb-3">
              <label className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0">
                {t("addBankAccount.bank")}
              </label>
              <input
                defaultValue={bankName}
                readOnly
                onClick={() => setShowModal(true)}
                placeholder={t("addBankAccount.bankPlaceholder")}
                className="w-full bg-white border-newColor rounded-[6px] md:p-[1rem] p-[12px] border-[1px] border-solid focus:outline outline-newColor cursor-pointer"
              />
              <p className="md:text-base text-sm text-red pl-1 mb-0">
                {errorBankName}
              </p>
            </div>
            <div className="text-start w-full mb-3">
              <label className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0">
                {t("addBankAccount.accountNumber")}
              </label>
              <input
                value={accountNumber}
                type="number"
                onChange={(e) => {
                  setAccountNumber(e.target.value);
                  setErrorAccountNumber("");
                }}
                placeholder={t("addBankAccount.accountNumberPlaceholder")}
                className="w-full bg-white border-newColor rounded-[6px] md:p-[1rem] p-[12px] border-[1px] border-solid focus:outline outline-newColor"
              />
              <p className="md:text-base text-sm text-red pl-1 mb-0">
                {errorAccountNumber}
              </p>
            </div>
            <div className="text-start w-full mb-3">
              <label className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0">
                {t("addBankAccount.accountName")}{" "}
                <span className="font-light text-sm italic">
                  ({t("addBankAccount.optional")})
                </span>
              </label>
              <input
                value={accountName}
                type="text"
                onChange={(e) => {
                  setAccountName(e.target.value);
                  setErrorAccountName("");
                }}
                placeholder={t("addBankAccount.accountNamePlaceholder")}
                className="w-full bg-white border-newColor rounded-[6px] md:p-[1rem] p-[12px] border-[1px] border-solid focus:outline outline-newColor"
              />
              <p className="md:text-base text-sm text-red pl-1 mb-0">
                {errorAccountName}
              </p>
            </div>
          </div>
          <div
            className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <button
              type="button"
              style={{
                backgroundColor:
                  company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
              }}
              onClick={handleOnClick}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              {t("addBankAccount.next")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddBankAccountMobile;
