import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useLocation } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import { formatCurrency, formatDate } from "../../config/global.js";
import Header from "../_common/headerComponent";
import CustomTooltip from "../_common/tooltip";
import { ConfigColor } from "../../config/global.js";

const ZakatHistoryDetailWeb = () => {
  const color = ConfigColor();
  const [data, setdata] = useState<any>({});

  const query: any = useLocation();

  const getDatas = () => {
    console.log(query.state.data);
    setdata(query.state.data);
  };

  useEffect(() => {
    getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header></Header>
      <section className="h-full min-h-screen font-poppins bg-mobile">
        <div className="h-full m-auto w-2/5 lg:w-1/2  min-w-[30rem] font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100">
              <div className="flex flex-col gap-2 my-4">
                {/* {_.map(data, (item) => ( */}
                <div className="flex flex-col w-full h-fit rounded-lg bg-white shadow-sm shadow-gray-700 gap-2 p-4">
                  <div
                    className="text-accentGreen text-base font-semibold pb-7 border-b-[.3px] border-b-[#9A9999] "
                    style={{ color: color.color1 }}
                  >
                    Ringkasan
                  </div>
                  <div className="text-sm flex justify-between">
                    <span className="text-[#252525] font-medium">Judul</span>
                    <CustomTooltip
                      // className={"grow max-w-[calc(100%-7rem)] "}
                      content={
                        <div className="max-w-full whitespace-pre-line">
                          {data.zakatTitle}
                        </div>
                      }
                      direction="bottom"
                      children={
                        <span className="text-[#9A9999] font-light whitespace-nowrap overflow-hidden truncate ">
                          {data.zakatTitle && data.zakatTitle.length > 50
                            ? data.zakatTitle.toString().substring(0, 50) +
                              "..."
                            : data.zakatTitle}
                        </span>
                      }
                    ></CustomTooltip>
                    {/* <span className="text-[#9A9999] font-light">
                      {data.zakatTitle}
                    </span> */}
                  </div>
                  {/* <div className="text-sm flex justify-between">
                    <span className="text-[#252525] font-medium">Kategori</span>
                    <span className="text-[#9A9999] font-light">
                      {data.transactionType}
                    </span>
                  </div> */}
                  <div className="text-sm flex justify-between">
                    <span className="text-[#252525] font-medium">
                      Waktu Donasi
                    </span>
                    <span className="text-[#9A9999] font-light">
                      {formatDate(data.createdTime, "dddd, DD MMMM YYYY")}
                    </span>
                  </div>
                  <div className="text-sm flex justify-between">
                    <span className="text-[#252525] font-medium">
                      Uang Donasi
                    </span>
                    <span className="text-[#9A9999] font-light">
                      {formatCurrency(data.amount)}
                    </span>
                  </div>
                </div>
                {/* ))} */}
              </div>
            </div>{" "}
          </div>
        </div>
      </section>
    </>
  );
};

export default ZakatHistoryDetailWeb;
