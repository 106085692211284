/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import iconSuccess from "../../assets/images/iconSuccess.png";
import Header from "../_common/headerComponent";
import Company from "../../core/models/company";
import * as API_USER from "../../core/service/api_user";
import * as API_AUTH from "../../core/service/api_auth";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import Countdown, { zeroPad } from "react-countdown";
import User from "../../core/models/user";
import { toast } from "react-hot-toast";
import Token from "../../core/models/token";
import _ from "lodash";
import { StatefulPinInput } from "react-input-pin-code";
import { ConfigColor } from "../../config/global";

const ForgetSecurityCodeWeb = () => {
  const token: Token[] = useAppSelector((state) => state.token.token);
  const user: User = useAppSelector((state) => state.user.user);
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let location: any = useLocation();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [newSecurityCode, setNewSecurityCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [confirmNewSecurityCode, setConfirmNewSecurityCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [errorOtp, setErrorOtp] = useState("");
  const [errorNewSecurityCode, setErrorNewSecurityCode] = useState("");
  const [errorConfirmNewSecurityCode, setErrorConfirmNewSecurityCode] =
    useState("");
  const [updateSuccessFully, setUpdateSuccessFully] = useState(false);
  const [timer, setTimer] = useState(0);
  const color = ConfigColor();
  const [otpWa, setOtpWa] = useState("");
  const [max, setMax] = React.useState<any>();
  const [max2, setMax2] = React.useState<any>();
  const [max3, setMax3] = React.useState<any>();

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <button
          className="text-red font-medium md:text-lg text-base"
          type="button"
          onClick={() => resendOtp()}
        >
          Kirim Ulang Kode OTP
        </button>
      );
    } else {
      return (
        <span className="font-medium md:text-lg text-base">
          Kode otp akan expired dalam{" "}
          <span className="font-bold">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };

  const resendOtp = async () => {
    dispatch(setLoading(true));
    setOtp(["", "", "", "", "", ""]);
    setNewSecurityCode(["", "", "", "", "", ""]);
    setConfirmNewSecurityCode(["", "", "", "", "", ""]);

    let data = {
      destinationOtp: user?.email,
      companyId: company?.id,
      userType: "MEMBER",
      name: user?.name,
      otpType: "FORGOT_SECURITY_CODE",
    };

    await API_AUTH.requestOtp(data)
      .then((res) => {
        dispatch(setLoading(false));
        toast.success("Kode OTP telah dikirim ke email Anda");
        setTimer(Date.now() + res.data.data.expiredMillisecond);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = async () => {
    if (otp.join("").length < 6 && location?.state?.otp === null) {
      setErrorOtp("Otp harap diisi");
    } else if (newSecurityCode.join("").length < 6) {
      setErrorNewSecurityCode("Pin baru harap diisi");
    } else if (confirmNewSecurityCode.join("").length < 6) {
      setErrorConfirmNewSecurityCode("Konfirmasi pin baru harap diisi");
    } else if (newSecurityCode.join("") !== confirmNewSecurityCode.join("")) {
      setErrorConfirmNewSecurityCode("Konfirmasi pin baru tidak sesuai");
    } else {
      dispatch(setLoading(true));
      let data = {
        otp: otpWa === undefined ? otp.join("") : otpWa,
        securityCode: newSecurityCode.join(""),
        otpSource: otpWa === undefined ? "email" : "wa",
      };

      await API_USER.forgetSecurityCode(data)
        .then((res) => {
          dispatch(setLoading(false));
          setUpdateSuccessFully(true);
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let link = query.get(`link`);

  const navigateTo = () => {
    if (link) {
      let dataToken: any = _.find(token, function (o) {
        return o.cid === company.initial;
      });
      window.location.href = `https://${link}?access_token=${dataToken?.token}`;
    } else {
      navigate(`/${company?.initial}/profile`, { replace: true });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleOnClick();
  };

  useEffect(() => {
    if (location.state.time !== null) {
      setTimer(Date.now() + location.state.time);
    }
    setOtpWa(location?.state?.otp);
  }, []);

  return (
    <section className="h-full min-h-screen font-poppins bg-mobile pb-28">
      <Header></Header>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] pb-4 px-4">
        <div
          className={`${
            updateSuccessFully ? "hidden" : "flex flex-col h-[85vh] items-start"
          }`}
        >
          <div className="w-100 text-center mb-3">
            <h1 className=" font-poppins font-bold md:text-3xl text-xl">
              Lupa Kode Keamanan
            </h1>
            <h5 className=" font-poppins font-normal md:text-lg text-base">
              Masukan data yang diperlukan untuk mengganti pin Anda
            </h5>
          </div>
          <div className="flex-grow flex flex-col justify-center items-center w-full text-left md:mb-[1.5rem] mb-[10px]">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-1 md:my-[1rem] my-[10px]"
            >
              <h1 className="md:text-lg text-base leading-5 font-normal mb-1">
                Kode OTP
              </h1>
              {otpWa !== undefined ? (
                <h4>{location?.state?.otp}</h4>
              ) : (
                // <StatefulPinInput
                //   length={6}
                //   placeholder=""
                //   initialValue={otp}
                //   onChange={(value, index, values) => {
                //     setOtp(values);
                //     setErrorOtp("");
                //   }}
                //   type="number"
                //   mask={true}
                //   size="lg"
                //   focusBorderColor="#26A69A"
                //   validBorderColor="#26A69A"
                // />
                <>
                  <div className="flex flex-row gap-3 relative z-10 mx-auto">
                    {otp.map((res, i) => {
                      return (
                        <div
                          className={
                            otp[i] === "undefined" || otp[i] === ""
                              ? "border outline-none text-center rounded-xl w-[70px] h-[70px]"
                              : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-xl w-[70px] h-[70px]"
                          }
                          key={i}
                        ></div>
                      );
                    })}
                  </div>
                  <div className="w-full -mt-16 relative z-20">
                    <input
                      style={{
                        letterSpacing: "75px",
                        caretColor: max?.length === 6 ? "transparent" : "#000",
                      }}
                      className="w-full bg-transparent border-none pl-8 text-transparent focus:outline-none py-2 text-2xl [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      type="number"
                      value={max}
                      onChange={(e) => {
                        if (e.target.value.length > 6) {
                          setMax(max);
                        } else {
                          const value: any = e.target.value.split("");
                          const newValue: any = otp;
                          const x: any = [
                            `${(newValue[0] = value[0])}`,
                            `${(newValue[1] = value[1])}`,
                            `${(newValue[2] = value[2])}`,
                            `${(newValue[3] = value[3])}`,
                            `${(newValue[4] = value[4])}`,
                            `${(newValue[5] = value[5])}`,
                          ];
                          setOtp(x);
                          setMax(e.target.value);
                        }
                      }}
                    ></input>
                  </div>
                </>
              )}
              <p className="text-base text-red pl-1 mb-0">{errorOtp}</p>
            </form>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-1 md:my-[1rem] my-[10px]"
            >
              <h1 className="md:text-lg text-base leading-5 font-normal mb-1">
                Pin Baru
              </h1>
              {/* <StatefulPinInput
                length={6}
                placeholder=""
                initialValue={newSecurityCode}
                onChange={(value, index, values) => {
                  setNewSecurityCode(values);
                  setErrorNewSecurityCode("");
                }}
                type="number"
                mask={true}
                size="lg"
                focusBorderColor="#26A69A"
                validBorderColor="#26A69A"
              /> */}
              <div className="flex flex-row gap-3 relative z-10 mx-auto">
                {newSecurityCode.map((res, i) => {
                  return (
                    <div
                      className={
                        newSecurityCode[i] === "undefined" ||
                        newSecurityCode[i] === ""
                          ? "border outline-none text-center rounded-xl w-[70px] h-[70px]"
                          : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-xl w-[70px] h-[70px]"
                      }
                      key={i}
                    ></div>
                  );
                })}
              </div>
              <div className="w-full -mt-16 relative z-20">
                <input
                  style={{
                    letterSpacing: "75px",
                    caretColor: max2?.length === 6 ? "transparent" : "#000",
                  }}
                  className="w-full bg-transparent border-none pl-8 text-transparent focus:outline-none py-2 text-2xl [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  value={max2}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      setMax2(max2);
                    } else {
                      const value: any = e.target.value.split("");
                      const newValue: any = newSecurityCode;
                      const x: any = [
                        `${(newValue[0] = value[0])}`,
                        `${(newValue[1] = value[1])}`,
                        `${(newValue[2] = value[2])}`,
                        `${(newValue[3] = value[3])}`,
                        `${(newValue[4] = value[4])}`,
                        `${(newValue[5] = value[5])}`,
                      ];
                      setNewSecurityCode(x);
                      setMax2(e.target.value);
                    }
                  }}
                ></input>
              </div>
              <p className="text-base text-red pl-1 mb-0">
                {errorNewSecurityCode}
              </p>
            </form>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-1 md:my-[1rem] my-[10px]"
            >
              <h1 className="md:text-lg text-base leading-5 font-normal mb-1">
                Konfirmasi Pin Baru
              </h1>
              {/* <StatefulPinInput
                length={6}
                placeholder=""
                initialValue={confirmNewSecurityCode}
                onChange={(value, index, values) => {
                  setConfirmNewSecurityCode(values);
                  setErrorConfirmNewSecurityCode("");
                }}
                type="number"
                mask={true}
                size="lg"
                focusBorderColor="#26A69A"
                validBorderColor="#26A69A"
              /> */}
              <div className="flex flex-row gap-3 relative z-10 mx-auto">
                {confirmNewSecurityCode.map((res, i) => {
                  return (
                    <div
                      className={
                        confirmNewSecurityCode[i] === "undefined" ||
                        confirmNewSecurityCode[i] === ""
                          ? "border outline-none text-center rounded-xl w-[70px] h-[70px]"
                          : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-xl w-[70px] h-[70px]"
                      }
                      key={i}
                    ></div>
                  );
                })}
              </div>
              <div className="w-full -mt-16 relative z-20">
                <input
                  style={{
                    letterSpacing: "75px",
                    caretColor: max3?.length === 6 ? "transparent" : "#000",
                  }}
                  className="w-full bg-transparent border-none pl-8 text-transparent focus:outline-none py-2 text-2xl [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  value={max3}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      setMax3(max3);
                    } else {
                      const value: any = e.target.value.split("");
                      const newValue: any = confirmNewSecurityCode;
                      const x: any = [
                        `${(newValue[0] = value[0])}`,
                        `${(newValue[1] = value[1])}`,
                        `${(newValue[2] = value[2])}`,
                        `${(newValue[3] = value[3])}`,
                        `${(newValue[4] = value[4])}`,
                        `${(newValue[5] = value[5])}`,
                      ];
                      setConfirmNewSecurityCode(x);
                      setMax3(e.target.value);
                    }
                  }}
                ></input>
              </div>
              <p className="text-base text-red pl-1 mb-0">
                {errorConfirmNewSecurityCode}
              </p>
            </form>
            <Countdown key={timer} date={timer} renderer={renderer} />
          </div>
          <div className="md:w-3/4 lg:w-1/2 w-full mx-auto">
            <button
              type="button"
              style={{
                backgroundColor: `${color?.color1}`,
              }}
              onClick={() => handleOnClick()}
              className="w-full md:p-[1rem] p-[12px] rounded-full text-white font-semibold md:text-lg text-base"
            >
              Konfirmasi
            </button>
          </div>
        </div>
        <div
          className={`${
            updateSuccessFully
              ? "flex flex-col h-[85vh] items-start justify-center"
              : "hidden"
          }`}
        >
          <div className="w-full relative items-center mt-4 mb-3">
            <img src={iconSuccess} alt="user" className="mx-auto" />
          </div>
          <div className="flex-grow w-100 text-center mb-3">
            <h1 className="font-bold md:text-2xl text-xl">
              PIN Berhasil Diganti
            </h1>
          </div>
          <div className="w-full mt-2 text-center">
            <button
              type="button"
              style={{
                backgroundColor: `${color?.color1}`,
              }}
              className="w-full md:p-[1rem] p-[12px] font-semibold rounded-full text-white text-base"
              onClick={navigateTo}
            >
              Selesai
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetSecurityCodeWeb;
