/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import WishlistMobile from "./wishlistMobile";
import WishlistWeb from "./wishlistWeb";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useAppDispatch } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Wishlist = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [priceList, setPriceList] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const dispatch = useAppDispatch();

  var temp: any = [];

  const getProductDetail = async (id: string) => {
    dispatch(setLoading(true));
    await API_COMMERCE.getProductDetail(id)
      .then((res) => {
        dispatch(setLoading(false));
        temp.push(res.data.data);
        if (res.data.data.items.length > 0) {
          let price: any = [];
          res.data.data.items.map((el: any) => {
            return price.push(el.marketplacePrice);
          });
          let newData = {
            id: res.data.data.id,
            minPrice: Math.min(price),
            maxPrice: Math.max(price),
          };
          setPriceList(priceList.concat(newData));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
    setData(temp);
  };

  const getWishlist = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.getWishlist()
      .then((res) => {
        dispatch(setLoading(false));
        if (res.data.data.productIds.length > 0) {
          res.data.data.productIds.map((el: any) => getProductDetail(el));
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const addToCart = async (item: any) => {
    dispatch(setLoading(true));
    let newData = {
      productId: item.productId,
      itemId: item.id,
      quantity,
    };
    await API_COMMERCE.addToCart(newData)
      .then((res) => {
        dispatch(setLoading(false));
        setOpen(false);
        toast.success(t('whishlist.toastSuccesAddCart'));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const deleteFromWishlist = async (id: string) => {
    dispatch(setLoading(true));
    let data = {
      productId: id,
    };
    await API_COMMERCE.addWishlist(data)
      .then((res) => {
        dispatch(setLoading(false));
        getWishlist();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    getWishlist();
  }, []);

  return (
    <>
      <BrowserView>
        <WishlistWeb
          addToCart={(item: any) => addToCart(item)}
          deleteFromWishlist={(id: string) => deleteFromWishlist(id)}
          setOpen={(value: any) => setOpen(value)}
          setQuantity={(value: any) => setQuantity(value)}
          data={data}
          open={open}
          quantity={quantity}
        />
      </BrowserView>
      <MobileView>
        <WishlistMobile
          addToCart={(item: any) => addToCart(item)}
          deleteFromWishlist={(id: string) => deleteFromWishlist(id)}
          setOpen={(value: any) => setOpen(value)}
          setQuantity={(value: any) => setQuantity(value)}
          data={data}
          open={open}
          quantity={quantity}
        />
      </MobileView>
    </>
  );
};

export default Wishlist;
