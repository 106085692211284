import Header from "../../_common/headerComponent";
import DataNotFound from "../../_common/dataNotFound";
import ProductComponent from "../../_common/productComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faTimes } from "@fortawesome/free-solid-svg-icons";
import FilterIcon from "../../../assets/images/filterIcon.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import LoadMoreButton from "../../_common/loadMoreButton";
import ArrowIcon from "../../../assets/images/arrow_left_new.svg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ListProductsMobile = (props: any) => {
  const location: any = useLocation();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header
        navbarText={
          props.isLogin
            ? `Data ${props.pName === "" ? "Produk" : props.pName}`
            : `Data ${props.pNameNoLogin}`
        }
      />

      {/* MODAL */}
      <BottomSheet
        open={props.showModal}
        onDismiss={() => {
          props.setShowModal(false);
        }}
        className="relative z-50 font-montserrat"
      >
        <div className="mx-4 mt-1 mb-10">
          <div className="space-between md:px-[0px] px-[0.5rem] mb-3">
            <h4 className="md:text-xl text-lg text-customBlack font-medium mb-0">
              {t('category.category')}
            </h4>
            <button
              type="button"
              onClick={() => {
                props.setDir(1);
                props.setShowModal(false);
                if (location.state !== null) {
                  props.setCategoryNames(location.state.category_names);
                } else {
                  props.setCategoryNames(props.tempCategoryNames);
                }
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="fa-xl" />
            </button>
          </div>
          {props.isLogin ? (
            <div className="mt-1 mb-3 w-full">
              {props.categories.map((item: any) => (
                <div key={item.id}>
                  <div className="mb-3">
                    <div className="flex items-center gap-3 mb-2">
                      <img
                        src={ArrowIcon}
                        alt=""
                        onClick={() => props.getCategories(item.name, 2, item.id)}
                        className="cursor-pointer"
                      />
                      <h6 className="text-start md:text-base text-sm font-medium text-customBlack mb-0 w-full">
                        {item.name}
                      </h6>
                    </div>
                    {props.isActive === item.id && (
                      <div className="accordion-content">
                        {props.level2Categories?.length > 0 &&
                          props.level2Categories.map((el: any) => (
                            <div key={el?.id}>
                              <div className="flex items-center gap-3 mb-2 ml-8">
                                <img
                                  src={ArrowIcon}
                                  alt=""
                                  onClick={() =>
                                    props.getCategories(el?.name, 3, el?.id)
                                  }
                                  className="cursor-pointer"
                                />
                                <h6 className="text-start md:text-base text-sm font-medium text-customBlack mb-0 w-full">
                                  {el?.name}
                                </h6>
                              </div>
                              {props.isActive2 === el?.id &&
                                props.level3Categories.map((el2: any) => (
                                  <div
                                    className="flex items-center gap-3 ml-11"
                                    key={el2?.id}
                                  >
                                    <h6 className="text-start md:text-base text-sm font-medium text-customBlack mb-0 w-full">
                                      {el2?.name}
                                    </h6>
                                    <input
                                      type="radio"
                                      name="category"
                                      id="category"
                                      value=""
                                      onChange={() => {
                                        props.setSelectedCategory(el2);
                                        props.setCategoryNames({
                                          ...props.categoryNames,
                                          level3: el2.name,
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-1 mb-3 w-full">
              {props.categoryNoLogin.map((item: any, i: any) => (
                <div key={i}>
                  <div className="mb-3">
                    <div className="flex items-center gap-3 mb-2">
                      <img
                        src={ArrowIcon}
                        alt=""
                        onClick={() => {
                          props.getCategoryNoLogin(item.name, 2, item.id, i);
                        }}
                        className="cursor-pointer"
                      />
                      <h6 className="text-start md:text-base text-sm font-medium text-customBlack mb-0 w-full">
                        {item.name}
                      </h6>
                    </div>
                    {props.isActive === item.id && (
                      <div className="accordion-content">
                        {props.level2Categories.length > 0 &&
                          props.level2Categories.map((el: any, index: any) => (
                            <div key={i}>
                              <div className="flex items-center gap-3 mb-2 ml-8">
                                <img
                                  src={ArrowIcon}
                                  alt=""
                                  onClick={() => {
                                    props.setSelectedCategory(el);
                                    props.getCategoryNoLogin(
                                      el.name,
                                      3,
                                      el.id,
                                      i,
                                      index
                                    );
                                    props.setId(index);
                                  }}
                                  className="cursor-pointer"
                                />
                                <h6 className="text-start md:text-base text-sm font-medium text-customBlack mb-0 w-full">
                                  {el.name}
                                </h6>
                              </div>
                              {props.isActive2 && props.id === index
                                ? props.level3Categories.map((el2: any) => {
                                  return (
                                    <div
                                      className="flex items-center gap-3 ml-11 mb-2"
                                      key={el2?.id}
                                    >
                                      <h6 className="text-start md:text-base text-sm font-medium text-customBlack mb-0 w-full">
                                        {el2?.name}
                                      </h6>
                                      <input
                                        type="radio"
                                        name="category"
                                        id="category"
                                        value=""
                                        onChange={() => {
                                          props.setSelectedCategory(el2);
                                          props.setCategoryNames({
                                            ...props.categoryNames,
                                            level3: el2.name,
                                          });
                                        }}
                                      />
                                    </div>
                                  );
                                })
                                : null}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  {/* <CategoryListNoLogin data={item} title={item.title} id={i} /> */}
                </div>
              ))}
            </div>
          )}
          <div className="mb-4">
            <h6 className="md:text-base text-sm text-customBlack font-bold text-start">
              {t('category.sort')}
            </h6>
            <div className="flex items-center text-start w-full">
              <input
                type="radio"
                value="Terbaru"
                checked={props.dir === 1}
                onChange={() => props.setDir(1)}
                id="Terbaru"
              />
              <label
                htmlFor="Terbaru"
                className="form-check-label font-medium inline-block mx-3"
              >
                {t('category.newProduct')}
              </label>
            </div>
            <div className="flex items-center text-start w-full">
              <input
                type="radio"
                value="Terlama"
                checked={props.dir === -1}
                onChange={() => props.setDir(-1)}
                id="Terlama"
              />
              <label
                htmlFor="Terlama"
                className="form-check-label font-medium inline-block mx-3"
              >
                {t('category.oldProduct')}
              </label>
            </div>
          </div>
          <button
            type="button"
            style={{
              backgroundColor: `${props.company?.color?.color1 !== undefined
                ? props.company?.color?.color1
                : "#26A69A"
                }`,
            }}
            className="bg-green text-white md:text-base text-sm p-2 w-full rounded-[8px]"
            onClick={() => {
              if (props.isLogin) {
                if (props.selectedCategory.name !== undefined) {
                  props.setCategoryId(props.selectedCategory.id);
                  props.getAllProducts(props.selectedCategory.id, 1);
                  props.setPage(1);
                } else {
                  props.getAllProducts(props.categoryId, 1);
                  props.setPage(1);
                }
              } else {
                if (props.selectedCategory.name !== undefined) {
                  props.setcategoryIdNoLogin(props.selectedCategory.id);
                  props.getProductNoLogin(props.selectedCategory.id, 1);
                  props.setPage(1);
                } else {
                  props.getProductNoLogin(props.categoryIdNoLogin, 1);
                  props.setPage(1);
                }
              }
              props.setShowModal(false);
            }}
          >
            {t('category.confirm')}
          </button>
        </div>
      </BottomSheet>

      <div className="container flex flex-col items-center md:mt-[64px] mt-[0px] px-4">
        <div className="md:w-4/5 w-full mx-auto mb-5">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-full">
              <div className="relative flex items-center text-[#D9D9D9]">
                <button
                  type="button"
                  onClick={() =>
                    props.isLogin
                      ? props.getAllProducts(props.categoryId, 1, props.keyword)
                      : props.getProductNoLogin(props.categoryId, 1, props.keyword)
                  }
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    color="#848383"
                    className="absolute ml-3 top-[11px]"
                  />
                </button>
                <input
                  type="text"
                  value={props.keyword}
                  onChange={(e) => props.setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      props.isLogin
                        ? props.getAllProducts(props.categoryId, 1, props.keyword)
                        : props.getProductNoLogin(props.categoryId, 1, props.keyword);
                      props.setPage(1);
                    }
                  }}
                  name="search"
                  className="w-full text-[#D9D9D9] font-medium placeholder-[#D9D9D9] rounded-[14px] border-[1px] border-[#D9D9D9] focus:outline-2 focus:outline-gray-300 text-sm py-2 pr-3 pl-10"
                />
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                props.setShowModal(true);
                if (props.isLogin) {
                  props.getCategories();
                } else {
                  props.getCategoryNoLogin();
                }
              }}
              className="bg-white px-2 py-[0.625rem] rounded-[10px]"
              style={{
                border: "solid",
                borderColor: "#D9D9D9",
                borderWidth: "1px",
              }}
            >
              <img src={FilterIcon} alt="" />
            </button>
          </div>
          {props.categoryNames !== undefined ? (
            props.categoryNames?.level1 !== "" && (
              <h6 className="text-[#D9D9D9] md:text-base text-sm font-semibold mb-3">
                {props.categoryNames?.level1}
                {props.categoryNames?.level2 !== "" &&
                  " > " + props.categoryNames?.level2 + " > "}
                {props.categoryNames?.level3 !== "" && props.categoryNames?.level3}
              </h6>
            )
          ) : props.isLogin ? (
            <h6 className="text-[#D9D9D9] md:text-base text-sm font-semibold mb-3">
              {t('category.list')} {props.pName === "" ? "Toko" : props.pName}
            </h6>
          ) : (
            <h6 className="text-[#D9D9D9] md:text-base text-sm font-semibold mb-3">
              {t('category.list')} {props.pNameNoLogin === "" ? "Toko" : props.pNameNoLogin}
            </h6>
          )}
          {props.isLogin ? (
            <div>
              {props.products?.length > 0 ? (
                <div className="w-full grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-3">
                  {props.products.map((item: any) => (
                    <div key={item.id}>
                      <ProductComponent item={item} type="P" />
                    </div>
                  ))}
                </div>
              ) : (
                <DataNotFound title={t('category.noData')} />
              )}
            </div>
          ) : (
            <div>
              {props.productNoLogin?.length > 0 ? (
                <div className="w-full grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-3">
                  {props.productNoLogin.map((item: any) => (
                    <div key={item.id}>
                      <ProductComponent item={item} type="P" />
                    </div>
                  ))}
                </div>
              ) : (
                <DataNotFound title={t('category.noData')} />
              )}
            </div>
          )}

          {props.isLogin ? (
            <>
              {props.currentSize() >= 10 && props.totalElements > 10 ? (
                <LoadMoreButton addMore={props.addMore} />
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {props.currentSizeNoLogin() >= 10 && props.totalElementsNoLogin > 10 ? (
                <LoadMoreButton addMore={props.addMoreNoLogin} />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ListProductsMobile;