import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Success from "../../assets/images/security_success.png";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import ForgetPasswordMobile from "./forgetPasswordMobile";
import ForgetPasswordWeb from "./forgetPasswordWeb";

const ForgetPassword = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const [createSuccessFully, setCreateSuccessFully] = useState(false);

  return (
    <>
      <Transition appear show={createSuccessFully} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setCreateSuccessFully(!createSuccessFully)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[20rem] md:w-[25rem] max-w-2xl rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-center leading-6 text-gray-900"
                  >
                    <img src={Success} alt="" className="mx-auto md:w-[7rem]" />
                  </Dialog.Title>
                  <div className="bg-white rounded-xl mt-3">
                    <div className="flex flex-col items-center w-full gap-4">
                      <span className="text-xl font-[500] md:text-[22px] text-[#263339]">Password Berhasil Diubah</span>
                      <span className="text-sm text-center md:text-base [#263339]">
                        Silakan login ulang menggunakan
                        <br /> password yang baru saja dibuat
                      </span>
                    </div>
                    <div className="bg-white rounded-xl">
                      <div className="flex w-full gap-4">
                        <button
                          onClick={() =>
                            navigate(`/${company?.initial}/login`, {
                              replace: true,
                            })
                          }
                          className="rounded-[8px] text-white font-semibold text-base bg-[#26A69A] px-4 py-2 text-sm font-[500] mx-auto mt-12 focus:outline-none md:text-base"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <BrowserView>
        <ForgetPasswordWeb setCreateSuccessFully={setCreateSuccessFully}></ForgetPasswordWeb>
      </BrowserView>
      <MobileView>
        <ForgetPasswordMobile setCreateSuccessFully={setCreateSuccessFully}></ForgetPasswordMobile>
      </MobileView>
    </>
  );
};

export default ForgetPassword;
