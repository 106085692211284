/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Header from "../../_common/headerComponent";
import UserDefault from "../../../assets/images/userDefault.png";
import CurrencyInput from "react-currency-input-field";
import * as API_BALANCE from "../../../core/service/api_balance";
import { onlyNumber } from "../../../config/global";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Company from "../../../core/models/company";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const InputNominalWeb = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [data, setData] = React.useState<any>({});
  const [amount, setAmount] = React.useState("");
  const [hiddenErrorText, setHiddenErrorText] = React.useState(true);

  const handleOnClick = async () => {
    console.log(amount);
    if (amount === "" || amount === undefined) {
      setHiddenErrorText(false);
    } else if (parseInt(amount) === 0) {
      toast.error(t('barcodeWeb.toastErrorPay'));
    } else {
      dispatch(setLoading(true));
      let barcode = location.state.barcode;
      let dataOrder = {
        amount,
      };
      await API_BALANCE.inquiryStaticBarcode(barcode, dataOrder)
        .then((res) => {
          dispatch(setLoading(false));
          if (res.data.data.inquiryIdOpen) {
            navigate(`/${company?.initial}/barcode/summary`, {
              state: {
                data: {
                  ...res.data.data.detailTransaction,
                  merchantName: data.name,
                },
                inquiryId: res.data.data.inquiryIdOpen,
                type: "static",
                balanceCode: location.state.balanceCode,
              },
            });
          } else {
            toast.error(
              t('barcodeWeb.toastErrorConnection')
            );
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  React.useEffect(() => {
    setData(location.state.data);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div className="md:w-3/5 w-full mx-auto">
          <div className="w-full">
            <h6 className="md:text-base text-sm font-medium">
              {t('barcodeWeb.infoMerch')}
            </h6>
            <div className="bg-gradient-to-b from-[#16C894] to-[#05A87F] space-between gap-4 p-4 rounded-lg">
              <img src={UserDefault} alt="" className="bg-white" />
              <div className="w-full flex flex-col justify-between gap-1 text-start">
                <h6 className="md:text-xl text-base text-white font-bold mb-0">
                  {data?.name}
                </h6>
                <p className="md:text-xl text-base text-white font-light mb-0">
                  {data?.noId}
                </p>
                <p className="md:text-xl text-base text-white font-light mb-0">
                  {data?.phone}
                </p>
              </div>
            </div>
            <div className="my-4">
              <label className="md:text-base text-sm text-black font-medium mb-2">
                {t('barcodeWeb.nominal')}
              </label>
              <CurrencyInput
                className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-md focus:outline outline-[#D9D9D9]"
                defaultValue=""
                value={amount}
                prefix={"Rp"}
                placeholder="Masukkan nominal pembayaran"
                onValueChange={(value) => {
                  setAmount(value as string);
                  setHiddenErrorText(true);
                }}
                onKeyPress={onlyNumber}
              />
              {!hiddenErrorText && (
                <p className="md:text-base text-sm text-red mb-0">
                  {t('barcodeWeb.errorInputNominal')}
                </p>
              )}
            </div>
          </div>
          <div className="w-full mb-6 mt-5">
            <button
              type="button"
              onClick={() => handleOnClick()}
              style={{
                backgroundColor:
                  company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
              }}
              className="text-white font-bold rounded-xl w-full md:p-[1rem] p-[12px]"
            >
              {t('barcodeWeb.continue')}
            </button>
          </div>
        </div>
      </div>
      {/* <BottomLogo /> */}
    </section>
  );
};

export default InputNominalWeb;
