/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch } from "../../core/feature/hooks";
import * as API_COMMERCE from "../../core/service/api_commerce";
import MerchantDetailMobile from "./merchantDetailMobile";
import MerchantDetailWeb from "./merchantDetailWeb";

const MerchantDetail = () => {
  const [data, setData] = useState<any>({});
  const [products, setProducts] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [dir, setDir] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [showDescription, setShowDescription] = useState(false);
  const dispatch = useAppDispatch();
  const params = useParams();

  const getShopDetail = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.getTourMerchantDetail(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        getAllProducts();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getAllProducts = async (currentPage: number = page) => {
    let query = {
      // timezone: time,
      page: currentPage,
      size: 10,
      sortBy: dir === 1 ? "-createdDate" : "createdDate",
      merchantId: params.id,
    };
    dispatch(setLoading(true));
    await API_COMMERCE.getTourProducts(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setProducts(products.concat(res.data.data));
        } else {
          setProducts(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  function currentSize() {
    let a = products.length;
    let b = a / page;

    return b;
  }

  const addMore = async () => {
    setPage(page + 1);
    getAllProducts(page + 1);
  };

  React.useEffect(() => {
    getShopDetail();
  }, []);

  return (
    <>
      <BrowserView>
        <MerchantDetailWeb
          getShopDetail={() => getShopDetail()}
          getAllProducts={(currentPage: number) => getAllProducts(currentPage)}
          addMore={() => addMore()}
          currentSize={currentSize}
          data={data}
          products={products}
          totalElements={totalElements}
          setDir={(value: any) => setDir(value)}
          setPage={(value: any) => setPage(value)}
          setShowDescription={(value: any) => setShowDescription(value)}
        />
      </BrowserView>
      <MobileView>
        <MerchantDetailMobile
          getShopDetail={() => getShopDetail()}
          getAllProducts={(currentPage: number) => getAllProducts(currentPage)}
          addMore={() => addMore()}
          currentSize={currentSize}
          data={data}
          products={products}
          totalElements={totalElements}
          setDir={(value: any) => setDir(value)}
          setPage={(value: any) => setPage(value)}
          setShowDescription={(value: any) => setShowDescription(value)}
        />
      </MobileView>

      {/* DESCRIPTION MODAL */}
      <BottomSheet
        open={showDescription}
        onDismiss={() => setShowDescription(false)}
        className="relative z-50 font-montserrat"
      >
        <div className="mx-4 mb-4">
          <h6 className="md:text-base text-sm text-customBlack font-medium border-b-[1px] border-b-[#CCCCCC] mb-0 pb-2">
            Deskripsi
          </h6>
          <p className="md:text-sm text-xs text-thirdy font-light mt-2 mb-0">
            {data.merchantDescription !== undefined
              ? data.merchantDescription
              : "-"}
          </p>
        </div>
      </BottomSheet>
    </>
  );
};

export default MerchantDetail;
