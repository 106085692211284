/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import * as API_BALANCE from "../../core/service/api_balance";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";
import Header from "../_common/headerComponent";
import { formatCurrency } from "../../config/global";
import DropDown from "../../assets/images/DropDown.svg";
import DataNotFound from "../_common/dataNotFound";
import LoadMoreButton from "../_common/loadMoreButton";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Company from "../../core/models/company";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MutationDataWeb = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate()
  let dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [newStartDate, setNewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");
  const [balanceCode, setBalanceCode] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [comboTransaction, setComboTransaction] = useState<any>([]);
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  // const [read, setRead] = useState("");

  const getMainBalance = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getMainBalanceMember()
      .then((res) => {
        dispatch(setLoading(false));
        setBalanceCode(res.data.data.balanceCode);
        getAllTransactions(res.data.data.balanceCode);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getAllTransactions = async (
    currentBalanceCode: string = balanceCode,
    startDate: any = "",
    endDate: any = "",
    currentPage: number = page,
    transactionType: string = ""
  ) => {
    dispatch(setLoading(true));
    let query = {
      startDate,
      endDate,
      page: currentPage,
      size: 10,
      sortBy: "createTime",
      dir: -1,
      transactionType,
    };
    console.log(currentBalanceCode);
    await API_BALANCE.getAllMemberMutations(currentBalanceCode, query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 0) {
          setData(data.concat(res.data.data));
        } else {
          setData(res.data.data);
        }
        setTotalElements(res.data.total);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getComboTransaction = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getComboTransactionType("Closepay")
      .then((res) => {
        dispatch(setLoading(false));
        setComboTransaction(res.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const resetDate = () => {
    setShowDate(false);
    setSelectedTransaction("");
    setStartDate(null);
    setEndDate(null);
    setNewStartDate("");
    setNewEndDate("");
    getAllTransactions(balanceCode, "", "", page, "");
  };

  const confirmDate = () => {
    setShowDate(false);
    getAllTransactions(
      balanceCode,
      newStartDate,
      newEndDate,
      0,
      selectedTransaction
    );
  };

  function currentSize() {
    let a = data.length;
    let b = a / (page + 1);

    return b;
  }

  const addMore = async () => {
    setPage(page + 1);
    if (selectedTransaction) {
      getAllTransactions(
        balanceCode,
        newStartDate,
        newEndDate,
        page + 1,
        selectedTransaction
      );
    } else {
      getAllTransactions(balanceCode, newStartDate, newEndDate, page + 1);
    }
  };

  const formatDateTimeUTC = (date: any) => {
    return moment.utc(date).local().format("DD MMM YYYY, HH:mm");
  };

  useEffect(() => {
    if (balanceCode !== "") getAllTransactions();
  }, [startDate, endDate, newStartDate, newEndDate]);

  useEffect(() => {
    getMainBalance();
  }, []);

  return (
    <section className="h-full min-h-screen relative font-montserrat bg-mobile">
      {/* MODAL */}
      <div
        className={`${showDate ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-auto sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="space-between md:px-[0px] px-[0.5rem]">
                <div className="text-start">
                  <h4 className="md:md:text-xl text-lg text-black font-medium mb-0">
                    {t("mutation.dateTitle")}
                  </h4>
                  <p className="md:md:text-sm text-xs text-[#848383] font-normal mb-0">
                    {t("mutation.descriptionTitle")}
                  </p>
                </div>
                <button type="button" onClick={() => resetDate()}>
                  <FontAwesomeIcon icon={faTimes} className="fa-xl" />
                </button>
              </div>
              <div className="grid grid-cols-2 mt-3">
                <div className="pr-1">
                  <p className="text-customBlack font-light text-start md:text-sm text-xs mb-0">
                    {t("mutation.startDate")}
                  </p>

                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date: Date) => {
                      setStartDate(date);
                      setNewStartDate(moment(date).format("YYYY-MM-DD"));
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    placeholderText={t("mutation.placeholderStartDate")}
                    className="border-[1px] border-[#C4C4C4] p-2 rounded-md w-[100%] md:text-sm text-xs"
                  />
                </div>
                <div className="pl-1">
                  <p className="text-customBlack font-light text-start md:text-sm text-xs mb-0">
                    {t("mutation.endDate")}
                  </p>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date: Date) => {
                      setEndDate(date);
                      setNewEndDate(moment(date).format("YYYY-MM-DD"));
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    placeholderText={t("mutation.placeholderEndDate")}
                    className="border-[1px] border-[#C4C4C4] p-2 rounded-md w-[100%] md:text-sm text-xs"
                  />
                </div>
              </div>
              <div className="flex flex-col my-4">
                <h6 className="md:text-xl text-lg text-black text-medium text-start">
                  {t("mutation.transactions")}
                </h6>
                {/* {Combo.listTransactionType
                  .filter((el: any) => comboTransaction?.includes(el.code))
                  .map((item: any, index: any) => (
                    <div
                      className="form-check form-check-inline text-start"
                      key={index}
                    >
                      <input
                        checked={selectedTransaction === item.code}
                        onChange={(e) => setSelectedTransaction(e.target.value)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                        type="radio"
                        value={item.code}
                      />
                      <label
                        className="form-check-label inline-block text-customBlack font-light md:text-base text-sm"
                        htmlFor="inlineRadio10"
                      >
                        {item.name}
                      </label>
                    </div>
                  ))} */}
                {comboTransaction.map((item: any, index: any) => {
                  return (
                    <div
                      className="form-check form-check-inline text-start"
                      key={index}
                    >
                      <input
                        checked={selectedTransaction === item.transactionType}
                        onChange={(e) => {
                          // setRead(item.readableName);
                          setSelectedTransaction(e.target.value);
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                        type="radio"
                        value={item.transactionType}
                      />
                      <label
                        className="form-check-label inline-block text-customBlack font-light md:text-base text-sm"
                        htmlFor="inlineRadio10"
                      >
                        {item.readableName}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col items-center justify-end gap-3">
                <button
                  type="button"
                  style={{
                    backgroundColor:
                      company?.color?.color1 !== undefined
                        ? company?.color?.color1
                        : "#26A69A",
                  }}
                  className="bg-green text-white font-medium rounded-[8px] p-[10px] w-full"
                  onClick={() => {
                    confirmDate();
                    setPage(0);
                  }}
                >
                  {t("mutation.buttonApply")}
                </button>
                <button
                  type="button"
                  className="border-[#EC1A1A] border-[1px] text-[#EC1A1A] font-medium bg-white rounded-[8px] p-[10px] w-full"
                  onClick={() => resetDate()}
                >
                  {t("mutation.buttonReset")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />

      <div className="container flex flex-col justify-center items-center gap-4 md:mt-[64px] mt-[0px] px-4">
        <div className="md:w-3/5 sm:w-full w-full mb-20">
          <div className="md:col-span-2 col-span-3 md:mb-16 mb-7">
            <button
              type="button"
              onClick={() => {
                setShowDate(true);
                getComboTransaction();
              }}
              className="w-full border-[1px] text-customBlack font-medium centered-items gap-4 bg-[#f4f4f4] border-[#dddada] rounded-md py-[10px]"
            >
              <span>{t('mutation.filterTitle')}</span>
              <img src={DropDown} alt="" />
            </button>
          </div>
          {data.length > 0 ? (
            data.map((item, index) => (
              <div
                key={index}
                className="flex flex-col"
                onClick={() =>
                  navigate(`detail`, {
                    state: {
                      balanceCode: balanceCode,
                      mutationId: item._id,
                      transactionType: item.transactionType,
                      currentBalance: item.currentBalance,
                      mutationType:item.mutationType,
                    },
                  })
                }
              >
                <div className="flex flex-row justify-between items-center gap-3 cursor-pointer">
                  <div className="w-full">
                    <h3 className="text-base font-medium text-black mb-0 w-full">
                      {/* {
                        Combo.listTransactionType.find(
                          (el: any) => el.code === item.transactionType
                        )?.name
                      } */}
                      {item.transactionType}
                    </h3>
                    <h6 className="md:text-sm text-sm text-[#6B6B6B] font-light text-left mb-1">
                      {/* {moment(item.createTime).format("DD MMM YYYY, HH:mm")} */}
                      {formatDateTimeUTC(item.createTime)}
                    </h6>
                  </div>
                  <div className="w-full">
                    {item.mutationType === "DEBT" ? (
                      <h6 className="text-base text-end text-red font-medium mb-0 w-full">
                        -{formatCurrency(item?.amount)}
                      </h6>
                    ) : (
                      <h6 className="text-base text-end text-green font-medium mb-0 w-full">
                        +{formatCurrency(item?.amount)}
                      </h6>
                    )}
                    <h6 className="md:text-sm text-sm font-light mb-1 text-end break-words text-[#263339]">
                      {t('mutation.theRemainingBalance')} : {formatCurrency(item?.currentBalance)}
                    </h6>
                  </div>
                </div>
                <hr />
              </div>
            ))
          ) : (
            <DataNotFound title={t('mutation.noData')} />
          )}

          {currentSize() >= 10 && totalElements > 10 ? (
            <LoadMoreButton addMore={addMore} />
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};

export default MutationDataWeb;
