/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import EditProfileWeb from "./editProfileWeb";
import EditProfileMobile from "./editProfileMobile";
import { toast } from "react-hot-toast";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import User from "../../core/models/user";
import * as API_USER from "../../core/service/api_user";
import moment from "moment";
import userDefault from "../../assets/images/userDefault.png";
import ToastConfig from "../../config/Toast";
import { ConfigColor } from "../../config/global";
import { setUser } from "../../core/feature/user/userSlice";
import "./index.scss";
import { useTranslation } from "react-i18next";

const identityType = [
  {
    value: "ktp",
    label: "KTP",
  },
  {
    value: "sim",
    label: "SIM",
  },
  {
    value: "passport",
    label: "PASSPORT",
  },
];

const genderList = [
  {
    value: "male",
    label: "Laki-laki",
  },
  {
    value: "female",
    label: "Perempuan",
  },
];

const languageList = [
  {
    value: "id",
    label: "Bahasa Indonesia",
  },
  {
    value: "en",
    label: "Bahasa Inggris",
  },
];

const EditProfile = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  let dispatch = useAppDispatch();
  const [metadata, setMetadata] = React.useState<any[]>([]);
  const requestMeta: any = {};
  const [customDate, setCustomDate] = React.useState<any>({});
  const [selectedData, setSelectedData] = React.useState<any>({});
  const [extraDatas, setExtraDatas] = React.useState<any>({});
  const [flagDatas, setFlagDatas] = React.useState<any>({});
  const [dataOrder, setDataOrder] = React.useState<any>({});
  const [image, setImage] = React.useState<any>();
  const [previewImage, setPreviewImage] = React.useState<any>();
  const [previewImageCurrent, setPreviewImageCurret] = React.useState<any>();
  const [tags, setTags] = React.useState<any[]>(() => []);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const color = ConfigColor();
  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language);

  const handleTags = (tags: any) => {
    setTags(tags);
  };

  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const getMetadata = async () => {
    dispatch(setLoading(true));
    await API_USER.getProfile()
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setUser(res.data.data));
        let tempExtraData = extraDatas;
        let tempFlagData = flagDatas;
        let tempCustomDate = customDate;
        let tempSelectedData = selectedData;
        let tempMetaData = metadata.concat(
          res.data.data.metadata.filter(
            (el: any) =>
              el.data.tag === "BasicData" && el.data.key !== "password"
          )
        );
        let allData = tempMetaData.concat(
          res.data.data.metadata.filter(
            (el: any) => el.data.tag === "AdditionalData"
          )
        );
        requestMeta.additionalData = {};
        res.data.data.metadata.map((meta: any) => {
          if (meta.data.isVisible && meta.data.key !== "password") {
            var a = meta.data.key;
            if (meta.data.tag === "BasicData") {
              if (res.data.data[a] !== null) {
                requestMeta[a] = res.data.data[a];
              } else if (a === "dateOfBirth") {
                requestMeta.additionalData[a] = moment().format("YYYY-MM-DD");
              } else {
                requestMeta[a] = null;
              }
            } else {
              if (
                res.data.data.additionalData[a] !== undefined &&
                res.data.data.additionalData[a] !== null
              ) {
                requestMeta.additionalData[a] = res.data.data.additionalData[a];
              } else {
                if (meta.data.typeData === "Flags") {
                  requestMeta.additionalData[a] = [];
                } else if (meta.data.typeData === "Datetime") {
                  requestMeta.additionalData[a] = moment().format(
                    "YYYY-MM-DD hh:mm:ss"
                  );
                } else if (meta.data.typeData === "Date") {
                  requestMeta.additionalData[a] = moment().format("YYYY-MM-DD");
                } else {
                  requestMeta.additionalData[a] = null;
                }
              }
            }
            requestMeta.companyId = company.id;

            if (
              meta.data.key === "dateOfBirth" ||
              meta.data.typeData === "Date" ||
              meta.data.typeData === "Datetime"
            ) {
              if (meta.data.tag === "BasicData") {
                if (res.data.data[a] !== null) {
                  tempCustomDate = {
                    ...tempCustomDate,
                    [meta.data.key]: new Date(res.data.data[a]),
                  };
                } else {
                  tempCustomDate = {
                    ...tempCustomDate,
                    [meta.data.key]: new Date(),
                  };
                }
              } else {
                if (
                  res.data.data.additionalData[a] !== null &&
                  res.data.data.additionalData[a] !== undefined
                ) {
                  tempCustomDate = {
                    ...tempCustomDate,
                    [meta.data.key]: new Date(res.data.data.additionalData[a]),
                  };
                } else {
                  tempCustomDate = {
                    ...tempCustomDate,
                    [meta.data.key]: new Date(),
                  };
                }
              }
            }

            if (meta.data.key === "tags") {
              if (res.data.data[a] !== null) {
                setTags(res.data.data[a]);
              }
            }

            if (meta.data.key === "gender") {
              let findData = genderList.find(
                (el) => el.value === res.data.data[a]
              );
              if (findData !== undefined)
                tempSelectedData = {
                  ...tempSelectedData,
                  [meta.data.key]: findData,
                };
            } else if (meta.data.key === "lang") {
              let findData = languageList.find(
                (el) => el.value === res.data.data[a]
              );
              if (findData !== undefined)
                tempSelectedData = {
                  ...tempSelectedData,
                  [meta.data.key]: findData,
                };
            } else if (meta.data.key === "tipeNik") {
              let findData = identityType.find(
                (el) => el.value === res.data.data[a]
              );
              if (findData !== undefined)
                tempSelectedData = {
                  ...tempSelectedData,
                  [meta.data.key]: findData,
                };
            } else if (meta.data.typeData === "Flags") {
              let newFlagData: any = {};
              if (meta.data.extraData.length > 0) {
                meta.data.extraData.map((el: any) => {
                  if (
                    res.data.data.additionalData[a] === undefined ||
                    res.data.data.additionalData[a] === null
                  ) {
                    newFlagData = { ...newFlagData, [el]: false };
                  } else {
                    let findData = res.data.data.additionalData[a].find(
                      (i: any) => i === el
                    );
                    newFlagData = {
                      ...newFlagData,
                      [el]: findData !== undefined ? true : false,
                    };
                  }
                });
              }
              tempFlagData = { ...tempFlagData, [meta.data.key]: newFlagData };
            } else if (meta.data.typeData === "Enum") {
              let newExtraData: any = [];
              if (meta.data.extraData.length > 0) {
                meta.data.extraData.map((el: any) =>
                  newExtraData.push({
                    label: el,
                    value: el,
                  })
                );
              }
              tempExtraData = {
                ...tempExtraData,
                [meta.data.key]: newExtraData,
              };
              let findData = tempExtraData[a].find(
                (el: any) => el.value === res.data.data.additionalData[a]
              );
              if (findData !== undefined)
                tempSelectedData = {
                  ...tempSelectedData,
                  [meta.data.key]: findData,
                };
            }
          }
        });
        setDataOrder(requestMeta);
        setMetadata(allData);
        setCustomDate(tempCustomDate);
        setSelectedData(tempSelectedData);
        setExtraDatas(tempExtraData);
        setFlagDatas(tempFlagData);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProfileImage = React.useMemo(() => {
    if (user?.profileImage === "") {
      return userDefault;
    }
    return user?.profileImage;
  }, [user]);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      console.error("Select a file");
      const enteredName = event?.currentTarget?.files[0];
      setImage(enteredName);
      setPreviewImageCurret(URL.createObjectURL(enteredName));
      return;
    }
  };

  const changeSelectedData = (value: any, key: string) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  const changeValue = async (
    val: any,
    key: string,
    tag: string = "",
    type: string = ""
  ) => {
    if (type === "Date" || type === "Datetime" || key === "dateOfBirth")
      if (val === "Invalid date") val = "";
    if (key.toLowerCase() === "password") {
      let tempDataOrder = dataOrder;
      setDataOrder(tempDataOrder);
    }
    if (tag === "BasicData") {
      setDataOrder({ ...dataOrder, [key]: val });
    } else {
      if (type === "Flags") {
        setDataOrder({
          ...dataOrder,
          additionalData: {
            ...dataOrder.additionalData,
            [key]: [...dataOrder.additionalData[key], val],
          },
        });
      } else {
        setDataOrder({
          ...dataOrder,
          additionalData: {
            ...dataOrder.additionalData,
            [key]: val,
          },
        });
      }
    }
  };

  const confirm = async () => {
    const regexPhone = /^\d{8,13}$/;
    metadata.map((res: any) => {
      if (res.data.isRequired === true) {
        if (res.data.tag === "BasicData") {
          if (dataOrder[res.data.key] === "") {
            toast(res.data.display + " Harap Diisi!", ToastConfig.warning);
          }
        } else {
          if (res.data.typeData === "Phone" && dataOrder[res.data.key] !== "") {
            if (!dataOrder.additionalData[res.data.key].match(regexPhone))
              toast(
                "Nomor telepon harus terdiri dari 8-13 karakter",
                ToastConfig.warning
              );
          }
          if (
            dataOrder.additionalData[res.data.key] === "" ||
            dataOrder.additionalData[res.data.key] === null
          ) {
            toast(res.data.display + " Harap Diisi!", ToastConfig.warning);
          } else if (dataOrder.additionalData[res.data.key].length === 0) {
            toast(res.data.display + " Harap Diisi!", ToastConfig.warning);
          }
        }
      }
    });

    let filteredData = metadata.filter((el: any) => el.data.isRequired);
    let emptyValue: any = [];
    if (filteredData.length > 0) {
      filteredData.map((item: any) => {
        if (
          item.data.tag === "BasicData" &&
          item.data.key !== "tags" &&
          item.data.key !== "email" &&
          item.data.key !== "tipeNik"
        ) {
          let findData = dataOrder[item.data.key];
          if (findData !== undefined) {
            if (findData === "" || findData === null) {
              emptyValue.push(item.data);
            } else if (findData.length === 0) {
              emptyValue.push(item.data);
            }
          }
        } else {
          let findData = dataOrder.additionalData[item.data.key];
          if (findData !== undefined) {
            if (item.data.typeData === "Phone") {
              if (!findData.match(regexPhone)) emptyValue.push(item.data);
            } else {
              if (findData === "" || findData === null) {
                emptyValue.push(item.data);
              } else if (findData.length === 0) {
                emptyValue.push(item.data);
              }
            }
          }
        }
      });
      if (emptyValue.length === 0) {
        dispatch(setLoading(true));
        API_USER.updateProfile(dataOrder)
          .then((res) => {
            dispatch(setLoading(false));
            setUser({ ...user, lang: res.data.data.lang });
            i18n.changeLanguage(res.data.data.lang)
            toast.success("Profile Berhasil Diperbarui");
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    }
  };

  const handleLanguageChange = (e: any, key: string, tag: string) => {
    setSelectedLanguage(e.value);
    changeValue(e.value, key, tag);
    changeSelectedData(e, key);
  };

  const confirmChanges = () => {
    confirm();
  };

  React.useEffect(() => {
    if (image !== undefined) {
      dispatch(setLoading(true));
      API_USER.uploadPhotoProfile(image)
        .then((res) => {
          dispatch(setLoading(false));
          setPreviewImage(previewImageCurrent)
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    } else {
      dispatch(setLoading(false));
    }
  }, [image]);

  React.useEffect(() => {
    getMetadata();
  }, []);

  return (
    <>
      <BrowserView>
        <EditProfileWeb
          handleTags={(tags: any) => handleTags(tags)}
          filterPassedTime={(time: any) => filterPassedTime(time)}
          inputHandler={(event: any) => inputHandler(event)}
          changeSelectedData={(value: any, key: string) => changeSelectedData(value, key)}
          changeValue={(val: any, key: string, tag: string = "", type: string = "") => changeValue(val, key, tag, type)}
          setCustomDate={(value: any) => setCustomDate(value)}
          setFlagDatas={(value: any) => setFlagDatas(value)}
          handleClick={() => handleClick()}
          confirm={() => confirmChanges()}
          handleLanguageChange={(e: any, key: string, tag: string) => handleLanguageChange(e, key, tag)}
          getProfileImage={getProfileImage}
          metadata={metadata}
          customDate={customDate}
          selectedData={selectedData}
          extraDatas={extraDatas}
          flagDatas={flagDatas}
          dataOrder={dataOrder}
          previewImage={previewImage}
          tags={tags}
          inputRef={inputRef}
          color={color}
          identityType={identityType}
          genderList={genderList}
          languageList={languageList}
          i18n={i18n}
          t={t}
          selectedLanguage={selectedLanguage}
        />
      </BrowserView>
      <MobileView>
        <EditProfileMobile
          handleTags={(tags: any) => handleTags(tags)}
          filterPassedTime={(time: any) => filterPassedTime(time)}
          inputHandler={(event: any) => inputHandler(event)}
          changeSelectedData={(value: any, key: string) => changeSelectedData(value, key)}
          changeValue={(val: any, key: string, tag: string = "", type: string = "") => changeValue(val, key, tag, type)}
          setCustomDate={(value: any) => setCustomDate(value)}
          setFlagDatas={(value: any) => setFlagDatas(value)}
          handleClick={() => handleClick()}
          confirm={() => confirmChanges()}
          handleLanguageChange={(e: any, key: string, tag: string) => handleLanguageChange(e, key, tag)}
          getProfileImage={getProfileImage}
          metadata={metadata}
          customDate={customDate}
          selectedData={selectedData}
          extraDatas={extraDatas}
          flagDatas={flagDatas}
          dataOrder={dataOrder}
          previewImage={previewImage}
          tags={tags}
          inputRef={inputRef}
          color={color}
          identityType={identityType}
          genderList={genderList}
          languageList={languageList}
          i18n={i18n}
          t={t}
          selectedLanguage={selectedLanguage}
        />
      </MobileView>
    </>
  );
};

export default EditProfile;
