/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CartMobile from "./cartMobile";
import CartWeb from "./cartWeb";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useNavigate } from "react-router-dom";
import Company from "../../../core/models/company";
import { toast } from "react-hot-toast";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const Cart = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const [productList, setProductList] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [selectedItemId, setSelectedItemId] = React.useState<any>({});
  const [selectedMerchantId, setSelectedMerchantId] = React.useState<any>({});
  const [isSelectedAll, setIsSelectedAll] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  const [page, setPage] = useState(1);

  const getCart = async (currentPage: number = page) => {
    dispatch(setLoading(true));
    let query = {
      page: currentPage,
      size: 10,
      sortBy: "-createdDate",
    };
    await API_COMMERCE.getCart(query)
      .then((res) => {
        dispatch(setLoading(false));
        let tempProductList: any = [];
        let tempSelectedItemId: any = [];
        if (res.data.data.length > 0) {
          res.data.data.map((el: any) => {
            let newProduct: any = [];
            el.cartList.map((el2: any) => {
              let findProduct = newProduct.find(
                (i: any) => i.productId === el2.productId
              );
              let newItem: any = [];
              if (findProduct === undefined) {
                newItem.push({
                  ...el2.product.item,
                  quantity: el2.quantity,
                  cartId: el2.id,
                });
                newProduct.push({
                  productType: el2.product.productType,
                  merchantId: el2.product.merchantId,
                  productId: el2.productId,
                  name: el2.product.name,
                  description: el2.product.description,
                  noteVOS: el2.product.noteVOS,
                  item: newItem,
                });
              } else {
                findProduct.item.push({
                  ...el2.product.item,
                  quantity: el2.quantity,
                  cartId: el2.id,
                });
              }
              return (tempSelectedItemId = {
                ...tempSelectedItemId,
                [el2.id]: false,
              });
            });
            let newData = {
              merchant: el.cartList[0].merchant,
              merchantId: el.merchantId,
              product: newProduct,
            };
            return tempProductList.push(newData);
          });
        }
        setProductList(tempProductList);
        let tempSelectedMerchantId = {};
        tempProductList.map(
          (i: any) =>
          (tempSelectedMerchantId = {
            ...tempSelectedMerchantId,
            [i.merchantId]: false,
          })
        );
        setSelectedMerchantId(tempSelectedMerchantId);
        setSelectedItemId(tempSelectedItemId);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const selectAll = () => {
    let tempSelectedMerchantId = selectedMerchantId;
    let tempSelectedItemId = selectedItemId;
    if (isSelectedAll) {
      setIsSelectedAll(false);
      setSelectedProducts([]);
      getTotalAmount([]);
      productList.map((i: any) => {
        tempSelectedMerchantId[i.merchantId] = false;
        return i.product.map((j: any) =>
          j.item.map((k: any) => (tempSelectedItemId[k.cartId] = false))
        );
      });
    } else {
      setIsSelectedAll(true);
      setSelectedProducts(productList);
      getTotalAmount(productList);
      productList.map((i: any) => {
        tempSelectedMerchantId[i.merchantId] = true;
        return i.product.map((j: any) =>
          j.item.map((k: any) => (tempSelectedItemId[k.cartId] = true))
        );
      });
    }
    setSelectedMerchantId(selectedMerchantId);
  };

  const selectMerchant = (item: any) => {
    let tempSelectedProducts = selectedProducts;
    let tempSelectedItemId = selectedItemId;
    let tempSelectedMerchantId = selectedMerchantId;

    if (tempSelectedMerchantId[item.merchantId]) {
      setSelectedMerchantId({
        ...selectedMerchantId,
        [item.merchantId]: false,
      });
      item.product.map((el: any) =>
        el.item.map((el2: any) => (tempSelectedItemId[el2.cartId] = false))
      );
      let tempSelectedProductsFilter = tempSelectedProducts.filter(
        (j: any) => j.merchantId !== item.merchantId
      );
      setSelectedProducts(tempSelectedProductsFilter);
      getTotalAmount(tempSelectedProductsFilter);
      setIsSelectedAll(false);
    } else {
      setSelectedMerchantId({ ...selectedMerchantId, [item.merchantId]: true });
      let indexMerchant = tempSelectedProducts.findIndex(
        (i: any) => i.merchantId === item.merchantId
      );

      if (indexMerchant < 0) {
        tempSelectedProducts.push(item);
      } else {
        tempSelectedProducts[indexMerchant].product = item.product;
      }

      item.product.map((el: any) =>
        el.item.map((el2: any) => (tempSelectedItemId[el2.cartId] = true))
      );
      setSelectedProducts(tempSelectedProducts);
      getTotalAmount(tempSelectedProducts);
      if (tempSelectedProducts.length === productList.length)
        setIsSelectedAll(true);
    }
    setSelectedItemId(tempSelectedItemId);
  };

  const selectProduct = (item: any, product: any) => {
    let tempSelectedProducts = selectedProducts;
    setSelectedItemId({
      ...selectedItemId,
      [product.cartId]: !selectedItemId[product.cartId],
    });

    let tempProducts: any = [];
    if (selectedItemId[product.cartId]) {
      let findMerchant = {
        ...tempSelectedProducts.find(
          (i: any) => i.merchantId === item.merchantId
        ),
      };

      let findProduct = findMerchant.product.find(
        (j: any) => j.productId === product.productId
      );

      if (findMerchant.product.length > 1) {
        // Jika produk yang terpilih dalam merchant tersebut lebih dari 1
        if (findProduct.item.length > 1) {
          let filteredItems = findProduct.item.filter(
            (j: any) => j.id !== product.id
          );
          let filteredProduct = {
            ...findProduct,
            item: filteredItems,
          };
          let filteredMerchant = findMerchant.product.filter(
            (j: any) => j.productId !== product.productId
          );
          filteredMerchant.push(filteredProduct);
          let filteredData = selectedProducts.filter(
            (j: any) => j.merchantId !== item.merchantId
          );
          filteredData.push({
            ...findMerchant,
            product: filteredMerchant,
          });
          tempProducts = filteredData;
          setSelectedProducts(filteredData);
        } else {
          //filter untuk product yang masih terselect
          var tempSelectedProductFilter = _.filter(
            findMerchant.product,
            function (o: any) {
              return o.productId !== product.productId;
            }
          );

          // set product yg terselect dengan data product hasil dari filter
          let newData = {
            ...findMerchant,
            product: tempSelectedProductFilter,
          };
          let tempSelectedProductFilter2 = selectedProducts.filter(
            (j: any) => j.merchantId !== item.merchantId
          );
          tempSelectedProductFilter2.push(newData);
          tempProducts = tempSelectedProductFilter2;
          setSelectedProducts(tempSelectedProductFilter2);
        }
      } else {
        // Jika produk yang terpilih dalam merchant tersebut hanya 1
        if (findProduct.item.length > 1) {
          // findProduct.item.splice(findItem, 1);
          let filteredItems = findProduct.item.filter(
            (j: any) => j.id !== product.id
          );
          let filteredProduct = {
            ...findProduct,
            item: filteredItems,
          };
          let filteredMerchant = findMerchant.product.filter(
            (j: any) => j.productId !== product.productId
          );
          filteredMerchant.push(filteredProduct);
          let filteredData = selectedProducts.filter(
            (j: any) => j.merchantId !== item.merchantId
          );
          filteredData.push({
            ...findMerchant,
            product: filteredMerchant,
          });
          tempProducts = filteredData;
          setSelectedProducts(filteredData);
        } else {
          // tempSelectedProducts.splice(findMerchant, 1);
          let tempSelectedProductsFilter = tempSelectedProducts.filter(
            (j: any) => j.merchantId !== item.merchantId
          );
          tempProducts = tempSelectedProductsFilter;
          setSelectedProducts(tempSelectedProductsFilter);
        }
      }
      setSelectedMerchantId({
        ...selectedMerchantId,
        [item.merchantId]: false,
      });
      setIsSelectedAll(false);
    } else {
      // Jika produk id belum terselected (expected result: add to selectedProducts)
      let findMerchant = tempSelectedProducts.find(
        (i: any) => i.merchantId === item.merchantId
      );
      let findProduct = productList
        .find((el: any) => el.merchantId === product.merchantId)
        .product.find((el2: any) => el2.productId === product.productId);

      if (findMerchant === undefined) {
        // Jika belum ada merchant id yang sama
        tempSelectedProducts.push({
          merchant: item.merchant,
          merchantId: item.merchantId,
          product: [{ ...findProduct, item: [product] }],
        });
        tempProducts = tempSelectedProducts;
        setSelectedProducts(tempSelectedProducts);
        checkId(item.merchantId, item.product);
      } else {
        // Jika sudah ada merchant id yang sama
        let tempProduct = findMerchant.product.find(
          (el: any) => el.productId === product.productId
        );
        if (tempProduct === undefined) {
          // Jika belum ada product id yang sama
          findMerchant.product.push({ ...findProduct, item: [product] });
        } else {
          tempProduct.item.push(product);
        }
        tempProducts = tempSelectedProducts;
        setSelectedProducts(tempSelectedProducts);
        checkId(item.merchantId, item.product);
      }
    }
    getTotalAmount(tempProducts);
  };

  const checkId = (merchantId: any, products: any) => {
    let tempSelectedMerhantId = selectedMerchantId;
    let checkProduct: any = [];

    products.map((el: any) => {
      let findProduct = selectedProducts
        .find((i: any) => i.merchantId === el.merchantId)
        .product.find((j: any) => j.productId === el.productId);
      if (findProduct === undefined) {
        checkProduct.push(0);
      } else {
        if (el.item.length === findProduct.item.length) {
          checkProduct.push(1);
        } else {
          checkProduct.push(0);
        }
      }
    });
    if (checkProduct.find((i: any) => i === 0) !== undefined) {
      tempSelectedMerhantId = { ...tempSelectedMerhantId, [merchantId]: false };
    } else {
      tempSelectedMerhantId = { ...tempSelectedMerhantId, [merchantId]: true };
    }
    setSelectedMerchantId(tempSelectedMerhantId);
  };

  const changeQuantity = async (cart: any, item: any, key: any) => {
    let findMerchant = productList.find(
      (el: any) => el.merchantId === cart.merchantId
    );
    if (findMerchant !== undefined) {
      let findProduct = findMerchant.product.find(
        (el: any) => el.productId === item.productId
      );
      let findItem = findProduct.item.find((el: any) => el.id === item.id);
      if (findItem !== undefined) {
        dispatch(setLoading(true));
        if (key === "substraction") {
          let data = {
            quantity: findItem.quantity - 1,
            deleted: false,
          };
          await API_COMMERCE.deleteProductFromCart(item.cartId, data)
            .then((res) => {
              dispatch(setLoading(false));
              getCart();
            })
            .catch((err) => {
              dispatch(setLoading(false));
            });
        } else if (key === "addition") {
          let data = {
            quantity: findItem.quantity + 1,
            deleted: false,
          };
          await API_COMMERCE.deleteProductFromCart(item.cartId, data)
            .then((res) => {
              dispatch(setLoading(false));
              getCart();
            })
            .catch((err) => {
              dispatch(setLoading(false));
            });
        } else {
          let data = {
            quantity: 1,
            deleted: false,
          };
          await API_COMMERCE.deleteProductFromCart(item.cartId, data)
            .then((res) => {
              dispatch(setLoading(false));
              getCart();
            })
            .catch((err) => {
              dispatch(setLoading(false));
            });
        }
        setSelectedProducts([]);
        setTotalAmount(0);
      }
    }
  };

  const deleteItem = async (id: string) => {
    dispatch(setLoading(true));
    let data = {
      quantity: 0,
      deleted: true,
    };
    await API_COMMERCE.deleteProductFromCart(id, data)
      .then((res) => {
        dispatch(setLoading(false));
        getCart();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = async () => {
    let filteredItems: any = [];
    selectedProducts.map((el: any) =>
      el.product.map((i: any) => {
        i.item.map((j: any) => {
          if (j.quantity < j.minOrder) {
            filteredItems.push(j);
          }
        });
      })
    );
    if (filteredItems.length > 0) {
      toast.error(
        `${t('cart.minOrder')} ${filteredItems[0].productName} - ${filteredItems[0].variantValue} ${filteredItems[0].minOrder}`
      );
    } else {
      navigate(`/${company?.initial}/commerce/create-order`, {
        state: {
          data: selectedProducts,
          isCart: true,
        },
      });
    }
  };

  function currentSize() {
    let totalProduct = 0;
    if (productList.length > 0) {
      productList.map((el: any) =>
        el.product.map((el2: any) => (totalProduct += el2.item.length))
      );
    }
    let a = totalProduct;
    let b = a / page;
    return b;
  }

  const addMore = async () => {
    setPage(page + 1);
    getCart(page + 1);
  };

  React.useEffect(() => {
    getCart();
  }, []);

  const getTotalAmount = async (products: any = selectedProducts) => {
    if (products.length !== 0) {
      var tempTotal = 0;
      products.map((product: any) => {
        product.product.map((list: any) =>
          list.item.map((el: any) => {
            let temp = el.marketplacePrice * el.quantity;
            return (tempTotal = tempTotal + temp);
          })
        );
      });
      setTotalAmount(tempTotal);
    } else {
      setTotalAmount(0);
    }
  };

  return (
    <>
      <BrowserView>
        <CartWeb
          selectMerchant={(item: any) => selectMerchant(item)}
          selectProduct={(item: any, product: any) =>
            selectProduct(item, product)
          }
          changeQuantity={(cart: any, item: any, key: any) =>
            changeQuantity(cart, item, key)
          }
          deleteItem={(id: string) => deleteItem(id)}
          handleOnClick={() => handleOnClick()}
          currentSize={() => currentSize()}
          addMore={() => addMore()}
          selectAll={() => selectAll()}
          company={company}
          totalAmount={totalAmount}
          productList={productList}
          selectedMerchantId={selectedMerchantId}
          selectedItemId={selectedItemId}
          selectedProducts={selectedProducts}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <CartMobile
          selectMerchant={(item: any) => selectMerchant(item)}
          selectProduct={(item: any, product: any) =>
            selectProduct(item, product)
          }
          changeQuantity={(cart: any, item: any, key: any) =>
            changeQuantity(cart, item, key)
          }
          deleteItem={(id: string) => deleteItem(id)}
          handleOnClick={() => handleOnClick()}
          currentSize={() => currentSize()}
          addMore={() => addMore()}
          selectAll={() => selectAll()}
          company={company}
          totalAmount={totalAmount}
          productList={productList}
          selectedMerchantId={selectedMerchantId}
          selectedItemId={selectedItemId}
          selectedProducts={selectedProducts}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default Cart;
