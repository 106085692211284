import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import success from "../../assets/images/icon_features/success.svg";
import failed from "../../assets/images/icon_features/failed.svg";
import bg from "../../assets/images/icon_features/bg_detail.svg";
import moment from "moment";
import BottomLogo from "../_common/bottomLogo";
import User from "../../core/models/user";
import { useAppSelector } from "../../core/feature/hooks";
import arrow from "../../assets/images/arrow-left-ic.svg";
import { ConfigColor } from "../../config/global";
import "moment/locale/id";
import { useTranslation } from "react-i18next";
import Company from "../../core/models/company";

const AbsenResultMobile = () => {
  const [result, setResult] = useState<any>({});
  const [resultEror, setResultEror] = useState<any>({});
  const [resultParking, setResultParking] = useState<any>({});
  const color = ConfigColor();
  const { t } = useTranslation();
  const parse = localStorage.getItem("result");
  const parseEror = localStorage.getItem("resultEror");
  const parseParking = localStorage.getItem("resultParking");
  const user: User = useAppSelector((state) => state.user.user);
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();

  const backButton = () => {
    navigate(-1);
    localStorage.removeItem("result");
  };
  useEffect(() => {
    setResult(JSON.parse(parse as any));
    setResultEror(JSON.parse(parseEror as any));
    setResultParking(JSON.parse(parseParking as any));
  }, [parse, parseEror]);

  return (
    <div className="w-full h-full block min-h-screen bg-mobile">
      <div className="bg-white w-full bg-fixed p-4 flex">
        <div
          onClick={() => backButton()}
          className="flex bg-white rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>
        <h1 className="font-montserrat font-bold text-base text-black m-auto relative text-center -left-6">
          {result?.data?.type === "ATTENDANCE_SUCCESS" ||
          resultParking?.data?.data?.status === "VERIFIED" ||
          result?.data?.data?.status === "VERIFIED"
            ? t("accessibility.success")
            : t("accessibility.failed")}
        </h1>
      </div>
      <div className="w-full p-4 font-montserrat">
        {result?.data?.type === "ATTENDANCE_SUCCESS" ||
        resultParking?.data?.data?.status === "VERIFIED" ||
        result?.data?.data?.status === "VERIFIED" ? (
          <div>
            <img src={success} alt="" className="flex mx-auto" />
            <div className="text-center mt-4">
              {resultParking?.data?.data?.status === "VERIFIED"
                ? t("accessibility.success_parking")
                : t("accessibility.success_absence")}
              {!resultParking && (
                <div>
                  <p
                    className="font-semibold text-2xl "
                    style={{ color: color.color1 }}
                  >
                    {moment().format("HH : mm : ss")}
                  </p>
                  <span className="text-sm">
                    {moment().locale("id").format("dddd, DD-MM-YYYY")}
                  </span>
                </div>
              )}
            </div>
            {result?.data?.type === "ATTENDANCE_SUCCESS" && (
              <div
                style={{
                  backgroundImage: `url(${bg})`,
                  backgroundColor: color.color1,
                }}
                className="bg-cover rounded-xl p-3 flex"
              >
                <div
                  style={{ backgroundImage: `url(${user?.profileImage})` }}
                  className="rounded-xl bg-white min-w-[100px] min-h-[100px] bg-cover"
                ></div>
                <div className="flex flex-col ml-3 text-white">
                  <span className="text-sm">
                    {moment(result?.data?.data?.attendanceTime).format(
                      "dddd, DD-MM-YYYY"
                    )}
                  </span>
                  <span className="text-[18px] font-semibold mt-2">
                    {result?.data?.data.name}
                  </span>
                  <span className="text-[15px]">{user?.tipeNik}</span>
                  <span className="text-[15px]">{user?.nik}</span>
                </div>
              </div>
            )}
            <h4 className="text-center text-gray-400 mt-4 text-sm">
              {t("accessibility.message")}
            </h4>
            <button
              onClick={() => {
                localStorage.removeItem("result");
                navigate(-1);
                localStorage.removeItem("resultEror");
                localStorage.removeItem("resultParking");
              }}
              className="py-2 px-4 mb-2 mt-20 rounded-lg w-full border-1 text-white border-white"
              style={{ backgroundColor: color.color1 }}
            >
              <span>Oke</span>
            </button>
          </div>
        ) : (
          <div className="text-center">
            <img src={failed} alt="" className="flex mx-auto" />
            <div className="text-center mt-4 font-semibold text-xl text-red">
              {t("accessibility.failed_absence")}
            </div>
            <span>
              {resultEror?.type === "QR_NOT_FOUND"
                ? t("accessibility.qr_not_found")
                : resultEror?.type === "ATTENDANCE_RULE_NOT_PASSED"
                ? t("accessibility.required_absence")
                : resultEror?.type === "ACTIVITY_NOT_FOUND"
                ? t("accessibility.activity_absence_not_found")
                : t("accessibility.error")}
              , {t("accessibility.please_check_in_again")}
            </span>
            <button
              onClick={() => {
                localStorage.removeItem("result");
                navigate(-1);
                localStorage.removeItem("resultEror");
              }}
              className="py-2 px-4 mb-2 mt-20 rounded-lg w-full border-1 text-white border-white"
              style={{ backgroundColor: color.color1 }}
            >
              <span>Oke</span>
            </button>
          </div>
        )}
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </div>
  );
};

export default AbsenResultMobile;
