import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { FC } from "react";

interface DonationTransactionSkeletonProps {
  type?: string;
}

const DonationTransactionSkeleton: FC<DonationTransactionSkeletonProps> = (
  props
): JSX.Element => {
  return (
    <div className="flex w-full max-h-[100px] bg-white rounded-md shadow-sm shadow-gray-700 gap-1 p-2">
      <div className="w-32 flex justify-center items-center">
        {/* <div className="flex justify-center items-center rounded-full w-12 h-12 bg-[#D9D9D9]"> */}
        <Skeleton circle width={48} height={48} />
        {/* </div> */}
      </div>
      <div className="flex flex-col flex-grow justify-between">
        <div className="w-48">
          <Skeleton />
        </div>
        {/* <div className="flex gap"> */}
        <div className="w-40 text-[10px] font-light">
          <Skeleton />
        </div>
        {/* </div>
        <div className="flex"> */}
        <div className="w-12 text-[10px] font-light">
          <Skeleton />
        </div>
        {/* </div>
        <div className="flex"> */}
        <div className="w-20 text-[10px] font-light">
          <Skeleton />
        </div>
        {/* </div> */}
      </div>
    </div>
    // <di
  );
};

export default DonationTransactionSkeleton;
