import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useLocation } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import { formatCurrency, formatDate } from "../../config/global.js";
// import { useAppSelector } from "../../core/feature/hooks";
// import Company from "../../core/models/company.js";
import Header from "../_common/headerComponent";
import { ConfigColor } from "../../config/global.js";
import { useTranslation } from "react-i18next";

const DonationHistoryDetailMobile = () => {
  const color = ConfigColor();
  const { t } = useTranslation();
  // const company: Company = useAppSelector((state) => state.company.company);
  // const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);
  const [data, setdata] = useState<any>({});

  const query: any = useLocation();

  const getDatas = () => {
    console.log(query.state.data);
    setdata(query.state.data);
  };

  useEffect(() => {
    getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <Header navbarText="Riwayat Donasi"></Header>
        <div className="h-full m-auto font-Montserrat">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100">
              <div className="flex flex-col gap-2 my-4">
                {/* {_.map(data, (item) => ( */}
                <div className="flex flex-col w-full h-fit bg-white shadow-sm shadow-gray-700 gap-2 p-4">
                  <div
                    className="text-accentGreen text-base font-semibold pb-7 border-b-[.3px] border-b-[#9A9999] "
                    style={{ color: color.color1 }}
                  >
                    {t("donation.summary")}
                  </div>
                  <div className="text-sm flex justify-between">
                    <span className="text-[#252525] font-medium min-w-[120px]">
                      {t("donation.title")}
                    </span>
                    <span className="text-[#9A9999] font-light">
                      {data.donationTitle}
                    </span>
                  </div>
                  <div className="text-sm flex justify-between">
                    <span className="text-[#252525] font-medium min-w-[120px]">
                      {t("donation.category")}
                    </span>
                    <span className="text-[#9A9999] font-light">
                      {data.transactionType}
                    </span>
                  </div>
                  <div className="text-sm flex justify-between">
                    <span className="text-[#252525] font-medium min-w-[120px]">
                      {t("donation.donationTime")}
                    </span>
                    <span className="text-[#9A9999] font-light">
                      {formatDate(data.createdTime, "dddd, DD MMMM YYYY")}
                    </span>
                  </div>
                  <div className="text-sm flex justify-between">
                    <span className="text-[#252525] font-medium min-w-[120px]">
                      {t("donation.donationMoney")}
                    </span>
                    <span className="text-[#9A9999] font-light">
                      {formatCurrency(data.amount)}
                    </span>
                  </div>
                </div>
                {/* ))} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DonationHistoryDetailMobile;
