/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { BalanceCard } from "../../../_common/BalanceCard";
import CurrencyInput from "react-currency-input-field";
import * as API_BALANCE from "../../../../core/service/api_balance";
import { setLoading } from "../../../../core/feature/config/configSlice";
import LogoBNI from "../../../../assets/images/icon_bank/logo_bni.png";
import LogoBRI from "../../../../assets/images/icon_bank/logo_bri.png";
import LogoBSI from "../../../../assets/images/icon_bank/logo_bsi.png";
import LogoBTN from "../../../../assets/images/icon_bank/logo_btn.png";
import LogoCIMBNiaga from "../../../../assets/images/icon_bank/cimb.png";
import LogoPermata from "../../../../assets/images/icon_bank/Permata.svg";
import LogoDanamon from "../../../../assets/images/icon_bank/Danamon.svg";
import LogoMandiri from "../../../../assets/images/icon_bank/logo_mandiri.png";
import LogoBCA from "../../../../assets/images/icon_bank/logo_bca.png";
// import ProfileImage from "../../../../assets/images/Profile.png";
// import BottomLogo from "../../../_common/bottomLogo";
// import {
//   setOtpNavigateState,
//   setOtpNavigateTo,
// } from "../../../../core/feature/otp/otpSlice";
import Company from "../../../../core/models/company";
import { useTranslation } from "react-i18next";

const bankNames = [
  {
    bankName: "BNI",
    logo: LogoBNI,
    bankCode: "009",
  },
  {
    bankName: "BSI",
    logo: LogoBSI,
    bankCode: "451",
  },
  {
    bankName: "BRI",
    logo: LogoBRI,
    bankCode: "002",
  },
  {
    bankName: "BCA",
    logo: LogoBCA,
    bankCode: "014",
  },
  {
    bankName: "Mandiri",
    logo: LogoMandiri,
    bankCode: "008",
  },
  {
    bankName: "BTN",
    logo: LogoBTN,
    bankCode: "200",
  },
  {
    bankName: "CIMB Niaga",
    logo: LogoCIMBNiaga,
    bankCode: "022",
  },
  {
    bankName: "Permata",
    logo: LogoPermata,
    bankCode: "013",
  },
  {
    bankName: "Danamon",
    logo: LogoDanamon,
    bankCode: "011",
  },
];

const InputDataMobile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  let location: any = useLocation();
  const [dataOrder, setDataOrder] = useState<any>({});
  const [nominal, setNominal] = useState("");
  const [note, setNote] = useState("");
  const [errorNominal, setErrorNominal] = useState("");
  const { t } = useTranslation();

  const handleOnClick = async () => {
    if (nominal === "0" || nominal === "") {
      setErrorNominal(t('transfer_bank.errorNomnimal'));
    } else {
      dispatch(setLoading(true));
      let data = {
        amount: nominal,
        idMemberBankAccount: dataOrder?._id,
        remark: note,
      };
      // dispatch(
      //   setOtpNavigateTo(`${company.initial}/transfer/bank/input-data/summary`)
      // );
      await API_BALANCE.inquiryPaymentTransferBank(data)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`summary`, {
            state: {
              data: res.data.data,
            },
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  // const [configColor, setConfigColor] = useState<any>({});
  // const getConfigColor = async () => {
  //   var color1 =
  //     company?.color?.color1 != undefined ? company?.color?.color1 : "#006F54";
  //   var color2 =
  //     company?.color?.color2 != undefined ? company?.color?.color2 : "#087B5E";

  //   setConfigColor({ color1: color1, color2: color2 });
  // };

  // useEffect(() => {
  //   getConfigColor();
  // }, []);

  useEffect(() => {
    setDataOrder(location.state);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={t('getAllBankAccount.title')}></Header>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto mb-20">
          <div className="px-4">
            <BalanceCard />
          </div>
          <h5 className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0 px-4">
            {t('getAllBankAccount.title')}
          </h5>
          <p className="md:text-base text-sm text-customBlack font-normal pl-1 md:mb-4 mb-3 px-4">
            {t('transfer_bank.desc_input_data')}
          </p>
          <div className="bg-white p-7 md:mb-10 mb-8 shadow-sm">
            <div className="text-start w-full mb-3">
              <p className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0">
                {t('transfer_bank.to')}
              </p>
              <div className="flex flex-row items-center gap-3 w-full bg-white border-newColor rounded-[6px] px-[12px] py-[5px] border-[1px] border-solid">
                <img
                  src={
                    bankNames.find((el) => el.bankCode === dataOrder?.bankCode)
                      ?.logo
                  }
                  alt=""
                  className="max-w-[45px] max-h-[45px]"
                />
                <div className="flex flex-col text-start w-full">
                  <h3 className="md:text-lg text-base font-medium text-customBlack mb-0">
                    {dataOrder?.accountName}
                  </h3>
                  <div className="flex items-center divide-solid divide-x divide-[#C4C4C4]">
                    <h6 className="md:text-sm text-xs font-medium text-[#9A9999] mb-0 pr-3">
                      {
                        bankNames.find(
                          (el) => el.bankCode === dataOrder?.bankCode
                        )?.bankName
                      }
                    </h6>
                    <p className="md:text-sm text-xs font-medium text-[#9A9999] mb-0 pl-3">
                      {dataOrder?.accountNumber}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-start w-full mb-3">
              <p className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0">
                Nominal
              </p>
              <CurrencyInput
                className="w-full bg-white border-newColor rounded-[6px] md:p-[1rem] p-[12px] border-[1px] border-solid focus:outline outline-[#9A9999]"
                defaultValue={1000}
                decimalsLimit={2}
                value={nominal}
                prefix={"Rp"}
                onValueChange={(value, name) => {
                  setNominal(value || "");
                  setErrorNominal("");
                }}
              />
              <p className="md:text-base text-sm text-red pl-1 mb-0">
                {errorNominal}
              </p>
            </div>
            <div className="text-start w-full mb-3">
              <p className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0">
                {t('transfer_bank.message')}{" "}
                <span className="italic font-light text-sm">{t('transfer_bank.optional')}</span>
              </p>
              <textarea
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                className="w-full bg-white border-newColor rounded-[6px] md:p-[1rem] p-[12px] border-[1px] border-solid focus:outline outline-[#9A9999]"
              />
            </div>
          </div>
          <div
            className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <button
              type="button"
              style={{
                backgroundColor:
                  company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
              }}
              onClick={handleOnClick}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              {t('transfer_bank.next')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InputDataMobile;
