import Config from "../../config/Config";
import { bearer } from "../../config/global";

const axios = require("axios");
const baseUrlDev = Config.BaseUrlDev;

export const reauthenticate = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}authentication/member/security-code/validation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const reauthenticateCardSecurityCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}authentication/member/security-code/card/validation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const requestOtp = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}authentication/guest/otp/request`, data, {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const requestOtpMember = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}authentication/member/otp/request`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const requestOtpGuest = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}authentication/guest/otp/request`, data, {
        headers: {
          // Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendOtp = (url, data, otp = "000000") => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}${url}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          "otp-security-code": otp,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}authentication/member/logout`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Auth Custom Menu

export const authCustomMenu = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}authentication/one-time-access-token/auth/request/${id}`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Auth otp

export const requestDestinationOtp = (data) => {
  let url = `${baseUrlDev}authentication/non-transaction/otp/request`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Send OTP

export const choseOtpSender = (data, company) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}authentication/member/login/request/otp`, data, {
        headers: {
          Authorization: `Bearer ${company}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// cek email phone

export const cekEmailPhone = (company, user) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}authentication/member/check-email-phone/${company}/${user}`,
        {
          // headers: {
          //   Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          // },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// new verivication wa

export const requestTokenVerivicationWa = (data) => {
  let url = `${baseUrlDev}authentication/guest/whatsapp-token/request`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkTokenVerification = (data) => {
  let url = `${baseUrlDev}authentication/guest/whatsapp-token/check`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const requestTokenNewDevice = (data) => {
  let url = `${baseUrlDev}authentication/guest/whatsapp-token/request-login`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEmailPhoneNoMasking = (id, username, type) => {
  let url = `${baseUrlDev}authentication/member/get-email-phone/${id}/${username}/${type}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const secureRedirect = (data) => {
  let url = `${baseUrlDev}authentication/secure-redirect`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const gatewayAuthorizationMember = (intial, code) => {
  let url = `${baseUrlDev}gateway/api/authorization/member/token/${intial}?code=${code}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
