import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import success from "../../assets/images/icon_features/success.svg";
import failed from "../../assets/images/icon_features/failed.svg";
import bg from "../../assets/images/icon_features/bg_detail.svg";
import moment from "moment";
import BottomLogo from "../_common/bottomLogo";
import User from "../../core/models/user";
import { useAppSelector } from "../../core/feature/hooks";
import arrow from "../../assets/images/arrow-left-ic.svg";
// import Company from "../../core/models/company";
import { ConfigColor } from "../../config/global";
import { useTranslation } from "react-i18next";
import Company from "../../core/models/company";

const AccessResultWeb = () => {
  const color = ConfigColor();
  const { t } = useTranslation();
  const [result, setResult] = useState<any>({});
  const parse = localStorage.getItem("result");
  const user: User = useAppSelector((state) => state.user.user);
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();

  const backButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    setResult(JSON.parse(parse as any));
  }, [parse]);

  return (
    <div className="min-h-screen w-full bg-mobile">
      <div className="bg-white w-full bg-fixed p-4 flex">
        <div
          onClick={() => backButton()}
          className="flex cursor-pointer bg-white rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>
        <h1 className="font-montserrat font-bold text-base text-black m-auto relative text-center -left-6">
          {result?.data?.type === "ENTRANCE_SUCCESS"
            ? t("accessibility.success")
            : t("accessibility.failed")}
        </h1>
      </div>
      <div className="container">
        <div className="w-1/2 mx-auto mt-10">
          <div className="w-full bg-white p-8 font-montserrat">
            {result?.data?.type === "ENTRANCE_SUCCESS" ? (
              <div>
                <img src={success} alt="" className="flex mx-auto" />
                <div className="text-center mt-4">
                  {t("accessibility.your_success_in")} :
                  <p
                    className="font-semibold text-2xl"
                    style={{ color: color.color1 }}
                  >
                    {moment(result?.data?.data.entranceTime).format(
                      "HH : mm : ss"
                    )}
                  </p>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${bg})`,
                    backgroundColor: color.color1,
                  }}
                  className="bg-cover rounded-xl p-3 flex"
                >
                  <div
                    style={{ backgroundImage: `url(${user?.profileImage})` }}
                    className="rounded-xl bg-white min-w-[100px] min-h-[100px] bg-cover"
                  ></div>
                  <div className="flex flex-col ml-3 text-white">
                    <span className="text-sm">
                      {moment(result?.data?.data.entranceTime).format(
                        "dddd, DD-MM-YYYY"
                      )}
                    </span>
                    <span className="text-[18px] font-semibold mt-2">
                      {result?.data?.data.name}
                    </span>
                    <span className="text-[15px]">{user?.tipeNik}</span>
                    <span className="text-[15px]">{user?.nik}</span>
                  </div>
                </div>
                <h4 className="text-center text-gray-400 mt-4 text-sm">
                  {t("accessibility.message")}
                </h4>
                <button
                  onClick={() => navigate(-1)}
                  className="py-2 px-4 mb-2 mt-20 rounded-lg w-full border-1 text-white border-white"
                  style={{ backgroundColor: color.color1 }}
                >
                  <span>Oke</span>
                </button>
              </div>
            ) : (
              <div className="text-center">
                <img src={failed} alt="" className="flex mx-auto" />
                <div className="text-center mt-4 font-semibold text-xl text-red">
                  {t("accessibility.access_failed")}
                </div>
                <span>{t("accessibility.error_access")}</span>
                <button
                  onClick={() => navigate(-1)}
                  className="py-2 px-4 mb-2 mt-20 rounded-lg w-full border-1 text-white border-white"
                  style={{ backgroundColor: color.color1 }}
                >
                  <span>Oke</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </div>
  );
};

export default AccessResultWeb;
