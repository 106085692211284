import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { map } from "lodash";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { imageOnError } from "../../config/global";
import { useAppSelector } from "../../core/feature/hooks";
// import { setToken } from "../../core/feature/token/tokenSlice";
import Company from "../../core/models/company";
// import Token from "../../core/models/token";
import User from "../../core/models/user";
import UserLogin from "../../core/models/userLogin";
import { useTranslation } from "react-i18next";
// import { cekTokenAccount } from "../../core/service/api";

interface SwitchAccountListProps {
  setSelectedUser: Function;
}

const SwitchAccountList: FC<SwitchAccountListProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  // const token: Token[] = useAppSelector((state) => state.token.token);
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserLogin[]>([]);
  const [maxUser] = useState(5);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  // const dispatch = useAppDispatch();

  useEffect(() => {
    if (userLogin) {
      console.log(user);
      setSelectedUser(_.find(userLogin, (item) => item.id === user.noId));
      return setUsers([...userLogin]);
    }
    return navigate(`/${company?.initial}/login`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full flex flex-col gap-2">
      {map(users, (item) => (
        <div
          className={`flex gap-2 items-center justify-start bg-white hover:bg-blue-gray-50 hover:cursor-pointer py-2 px-4 rounded-lg ${selectedUser?.id === item.id ? "active" : ""
            }`}
          onClick={() => {
            setSelectedUser(item);
            props.setSelectedUser(item);
            // changeAccount(item);
          }}
        >
          <img
            className="rounded-full border-solid border-gray-400 bg-gray-400"
            style={{ width: "70px", maxHeight: "70px" }}
            src={item.photo || ""}
            alt={item.photo}
            onError={imageOnError}
          />
          <span className="min-w-[10rem] flex-grow overflow-hidden whitespace-nowrap text-ellipsis">
            {item.name}
          </span>
          <input
            className="w-4"
            type="radio"
            name={item.id}
            id={item.id}
            checked={selectedUser?.id === item.id}
          ></input>
        </div>
      ))}

      <div
        className="flex gap-2 items-center justify-start  text-[#D9D9D9] border-[#D9D9D9] border-[1px] hover:bg-blue-gray-50 hover:cursor-pointer py-2 rounded-lg px-4"
        onClick={() => {
          if (userLogin && userLogin.length < maxUser)
            return navigate(`/${company?.initial}/login`);
          toast.warn(
            t('switchAccount.maxAccountWarning', { maxUser })
          );
        }}
      >
        <span className="rounded-full border-[#D9D9D9] border-[1px] w-[70px] h-[70px] flex justify-center items-center">
          <FontAwesomeIcon
            style={{ width: "50px", height: "50px" }}
            icon={faPlus}
            color="#D9D9D9"
          ></FontAwesomeIcon>
        </span>
        <span className="w-[10rem] text-ellipsis overflow-hidden whitespace-nowrap">
          {t('switchAccount.addAccount')}
        </span>
      </div>
    </div>
  );
};

export default SwitchAccountList;
