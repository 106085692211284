import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as API_USER from "../../core/service/api_user";
import * as API from "../../core/service/api";
import * as API_GATEWAY from "../../core/service/api_gateway";
import { setCompany } from "../../core/feature/company/companySlice";
import { setToken } from "../../core/feature/token/tokenSlice";
import Token from "../../core/models/token";
import { useAppSelector } from "../../core/feature/hooks";
import { setUser } from "../../core/feature/user/userSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";

const AuthorizeIPB = () => {
  const dispatch = useDispatch();

  let pathName = window?.location?.pathname;
  let query = pathName.split("/");
  let cid = query[2];
  let token = query[3];
  let noId = query[4];

  const gatewayAuth = async () => {
    await API_GATEWAY.gatewayIPB(cid, token, noId)
      .then((res) => {
        let newToken = [
          {
            cid: res.data.data.companyInitial,
            token: res.data.data.authToken,
          },
        ];
        console.log(newToken);
        dispatch(setToken(newToken));
        cekToken(res.data.data);
      })
      .catch((err) => {
        // document.location.href = `/IPB`;
        dispatch(setLoading(false));
      });
  };

  const cekToken = async (item: any) => {
    dispatch(setLoading(true));
    await API.cekTokenIPB(item.authToken)
      .then((res) => {
        dispatch(setUser(res.data.data));
        let userLogin = [
          {
            token: res.data.data.authToken,
            id: res.data.data.noId,
            initial: res.data.data.companyInitial,
          },
        ];
        dispatch(setUserLogin(userLogin));
        redirect(item);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const redirect = (res: any) => {
    document.location.href = `/${res.companyInitial}`;
  };

  useEffect(() => {
    gatewayAuth();
  }, []);

  return <div></div>;
};

export default AuthorizeIPB;
