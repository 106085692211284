import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as API_USER from "../../core/service/api_user";
import * as API_AUTH from "../../core/service/api_auth";
import * as API from "../../core/service/api";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import toast from "react-hot-toast";
import { setCompany } from "../../core/feature/company/companySlice";
import fingerPrint from "@fingerprintjs/fingerprintjs";
import {
  setOtpCompanyId,
  setOtpDeviceId,
  setOtpEmail,
  setOtpPassword,
  setOtpUsername,
} from "../../core/feature/otp/otpSlice";
import { setSkipTo } from "../../core/feature/otp/addDestinationSlice";
import { setUser } from "../../core/feature/user/userSlice";
import { setPin } from "../../core/feature/user/pinSlice";
import { useAppSelector } from "../../core/feature/hooks";
import Token from "../../core/models/token";
import _, { findIndex } from "lodash";
import { setToken } from "../../core/feature/token/tokenSlice";
import UserLogin from "../../core/models/userLogin";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";
import {
  setIsEmailVerifiedDestination,
  setIsPhoneVerifiedDestination,
} from "../../core/feature/otp/destinationSlice";
import DestinationOtpComponent from "../_common/destinationOtpComponent";
import OtpComponent from "../_common/otpComponent";
import Company from "../../core/models/company";

const Autologin = () => {
  const location = useLocation();
  const pathStr = location.pathname;
  const pathName = pathStr.split("/");
  const username = pathName[3];
  const password = pathName[4];
  const companyInitial = pathName[1];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deviceId, setDeviceId] = useState("");
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  let destinationEmailVerified: boolean = useAppSelector(
    (state) => state.destination.isEmailVerified
  );
  let destinationPhoneVerified: boolean = useAppSelector(
    (state) => state.destination.isPhoneVerified
  );
  const [data, setData] = useState<any>({});
  const [destinationOtp, setDestinationOtp] = useState(false);
  const [showOtpEmail, setShowOtpEmail] = useState(false);
  const fpPromise = fingerPrint.load();

  const getCompany = async (deviceId?: any) => {
    dispatch(setLoading(true));
    await API_USER.getCompany(companyInitial)
      .then((res) => {
        login(res.data.data.id, deviceId);
        dispatch(setCompany(res.data.data));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toast.error("Instansi " + pathName + " Tidak Ditemukan");
        navigate("/companies");
      });
  };

  const login = async (companyId: any, deviceId: any) => {
    var query = {
      username: username,
      password: password,
      companyId: companyId,
      deviceId: deviceId,
      // state: stateEnd,
    };
    dispatch(setSkipTo(`/${companyInitial}`));
    await API.login(query, "")
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setUser(res.data.data));
        dispatch(setPin(Number(res.data.data.securityCode)));
        var newToken = token ? [...token] : [];
        let indexToken = findIndex(token, {
          cid: companyInitial,
        });

        if (indexToken < 0) {
          newToken.push({
            cid: companyInitial,
            token: res.data.data.authToken,
          });
        } else {
          newToken[indexToken] = {
            cid: companyInitial,
            token: res.data.data.authToken,
          };
        }
        dispatch(setToken(newToken));

        /** startof user login section */
        var newUserLogin = userLogin?.length > 0 ? [...userLogin] : [];
        let indexUserLogin = findIndex(newUserLogin, {
          id: res.data.data.noId,
        });
        if (indexUserLogin < 0) {
          newUserLogin.push({
            token: res.data.data.authToken,
            id: res.data.data.noId,
            initial: companyInitial,
          });
        } else {
          newUserLogin[indexUserLogin] = {
            token: res.data.data.authToken,
            id: res.data.data.noId,
            initial: companyInitial,
          };
        }
        dispatch(setUserLogin(newUserLogin));
        // navigate(`/${companyInitial}`);
        /** endof user login section */

        if (res.data.data.firstLogin) {
          navigate(`/${companyInitial}/create-password`, {});
        } else {
          navigate(`/${companyInitial}`);
        }
      })
      .catch((err: any) => {
        if (err.response?.status === 403) {
          cekData(err, companyId);
        }
        if (err.response?.status === 401 || err.response?.status === 400) {
          navigate(`/${companyInitial}/login`);
        }
        dispatch(setLoading(false));
      });
  };

  const cekData = async (err: any, companyId: any) => {
    dispatch(setLoading(true));
    await API_AUTH.cekEmailPhone(companyId, username)
      .then((res) => {
        if (err.response?.status === 403) {
          setData(res.data.data);
          dispatch(
            setIsEmailVerifiedDestination(!_.isEmpty(res.data.data.email))
          );
          dispatch(
            setIsPhoneVerifiedDestination(!_.isEmpty(res.data.data.phone))
          );
          dispatch(setOtpEmail(res.data.data.email));
          dispatch(setLoading(false));
          setDestinationOtp(true);
          dispatch(setOtpEmail(err.response.data.detail.message));
          dispatch(setOtpPassword(password));
          dispatch(setOtpUsername(username));
          //   dispatch(setStateEnd(stateEnd));
          dispatch(setOtpCompanyId(companyId));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        const visitorId = result.visitorId;
        getCompany(visitorId);
        setDeviceId(visitorId);
        dispatch(setOtpDeviceId(visitorId));
      });
  }, []);

  return (
    <div className="min-h-screen">
      <DestinationOtpComponent
        key={`${data.toString()}-${destinationEmailVerified ? "email" : ""}-${
          destinationPhoneVerified ? "phone" : ""
        }`}
        showDestinationOtp={destinationOtp}
        showChooseDestination={true}
        showAddDestination={true}
        onHide={() => {
          setDestinationOtp(false);
        }}
        data={data}
        username={username}
        password={password}
        isEmailVerified={destinationEmailVerified}
        isPhoneVerified={destinationPhoneVerified}
        login={login}
        stateEnd=""
      />
    </div>
  );
};

export default Autologin;
