import React, { useState } from "react";
import Header from "../../_common/headerComponent";
// import * as API_USER from "../../../core/service/api_user";
// import user from "../../assets/images/userNew.png";
// import lesson from "../../../assets/images/icon_siakad/lesson.svg";
// import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
// import Company from "../../../core/models/company";
// import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
// import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
const AttendanceDetailMobile = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  // let navigate = useNavigate();
  // let dispatch = useAppDispatch();
  const [value, onChange] = useState<any>(new Date());

  const data = [
    {
      name: "Pertemuan BAB 1",
      status: "ijin",
    },
    {
      name: "Pertemuan BAB 1",
      status: "ijin",
    },
    {
      name: "Pertemuan BAB 1",
      status: "ijin",
    },
    {
      name: "Pertemuan BAB 1",
      status: "ijin",
    },
    {
      name: "Pertemuan BAB 1",
      status: "ijin",
    },
    {
      name: "Pertemuan BAB 1",
      status: "ijin",
    },
  ];
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Absensi"></Header>

      <div className="flex flex-col  py-2 gap-3 pb-4 -mt-8">
        <section className="bg-white w-full px-3 py-4">
          <Calendar
            className={`border-0`}
            locale={"id"}
            selectRange={false}
            onChange={onChange}
            value={value}
          />

          <div className="grid grid-cols-3 mt-4 justify-center items-center">
            <div className="flex flex-col gap-0.5 items-center">
              <span className="font-light text-xs text-[#9A9999]">
                Mata Pelajaran
              </span>
              <span className="font-medium text-xs">Matematika</span>
            </div>
            <div className="flex flex-col gap-0.5 items-center">
              <span className="font-light text-xs text-[#9A9999]">
                Waktu Belajar
              </span>
              <span className="font-medium text-xs">120 Menit</span>
            </div>
            <div className="flex flex-col gap-0.5 items-center">
              <span className="font-light text-xs text-[#9A9999]">
                Guru Pengajar
              </span>
              <span className="font-medium text-xs">Bambang W.</span>
            </div>
          </div>
          <hr className="my-2.5 h-[0.5px] bg-blue-gray-300" />
          <div className="grid grid-cols-3">
            <div className="flex flex-col gap-1 items-center">
              <div className="w-9 h-9 bg-white border border-solid border-[#9A9999]/30 rounded-lg"></div>
              <span className="font-light text-xs text-[#9A9999]">
                Siswa Hadir
              </span>
            </div>
            <div className="flex flex-col gap-1 items-center">
              <div className="w-9 h-9 bg-[#F2F5FD]  rounded-lg"></div>

              <span className="font-light text-xs text-[#9A9999]">
                Siswa ijin
              </span>
            </div>
            <div className="flex flex-col gap-1 items-center">
              <div className="w-9 h-9  bg-[#EC1A1A]/5   rounded-lg"></div>

              <span className="font-light text-xs text-[#9A9999]">
                Tanpa Keterangan
              </span>
            </div>
          </div>

          <div className="w-full mt-4">
            <table className="table-auto w-full">
              <tbody>
                {data.map((item: any, index: number) => (
                  <tr className="font-normal text-sm  text-[#252525] text-center border-b-[0.5px]">
                    <td className="py-2 px-2">{index + 1}</td>
                    <td className="py-2 text-left">{item.name}</td>
                    <td className="py-2 font-medium capitalize">
                      {item.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex flex-row w-full justify-between mt-2.5">
              <button className="text-[#16C894] font-medium text-sm">
                Sebelumnya
              </button>
              <span className="text-[#9A9999] font-medium text-sm">
                2 dari 4
              </span>
              <button className="text-[#16C894] font-medium text-sm">
                Selanjutnya
              </button>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default AttendanceDetailMobile;
