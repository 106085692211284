/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { formatCurrency } from "../../../config/global";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import * as API_BALANCE from "../../../core/service/api_balance";
import DataNotFound from "../../_common/dataNotFound";
import Header from "../../_common/headerComponent";
import MutationIcon from "../../../assets/images/history_vcard.svg";
import Company from "../../../core/models/company";
import { useNavigate } from "react-router-dom";
import BottomLogo from "../../_common/bottomLogo";

const SavingListMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = React.useState<any[]>([]);

  const getSavingList = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.savingListV2()
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    getSavingList();
  }, []);

  return (
    <section className="h-full min-h-screen relative font-poppins bg-mobile">
      <Header navbarText="Daftar Simpanan" />
      <div className="container flex flex-col justify-center items-center gap-4 md:mt-[64px] md:pb-[64px] pb-[1.5rem] mt-[0px] px-4">
        <div className="md:w-3/5 sm:w-full w-full flex flex-col gap-3">
          {data.length > 0 ? (
            data.map((item, index) => (
              <div
                className="flex flex-col bg-white rounded-lg border-[#9A9999] border md:p-[16px] p-[10px]"
                key={index}
              >
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <h6 className="md:text-base text-base text-[#252525] font-medium mb-0 font-montserrat m-0">
                      {item.balanceName}
                    </h6>
                    <h6 className="md:text-base text-sm   font-medium  m-0 font-montserrat bg-green inline-block text-transparent bg-clip-text">
                      {formatCurrency(item.amount)}
                    </h6>
                  </div>
                  <div
                    className="cursor-pointer no-underline"
                    onClick={() =>
                      navigate(`/${company?.initial}/balance/savingMutation`, {
                        state: {
                          balanceCode: {
                            code: item.balanceCode,
                          },
                          name: {
                            name: item.balanceName,
                          },
                        },
                      })
                    }
                  >
                    <img src={MutationIcon} alt="" width={30} height={30} />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <DataNotFound title="Data Tidak Ditemukan" />
          )}
        </div>
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </section>
  );
};

export default SavingListMobile;
