/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import _, { find, findIndex } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import FailedIcon from "../../assets/images/failed-icon.png";
import BankDKI from "../../assets/images/icon_balance/bank_dki.png";
import LinkAja from "../../assets/images/icon_balance/link_aja_whitebg.png";
import noInfo from "../../assets/images/icon_home/berita_kosong.svg";
import balance_ic from "../../assets/images/icon_menu/ic/balance.svg";
import donation from "../../assets/images/icon_menu/ic/donation.svg";
import invoice from "../../assets/images/icon_menu/ic/invoice.svg";
import marketplace from "../../assets/images/icon_menu/ic/marketplace.svg";
import ppob from "../../assets/images/icon_menu/ic/ppob.svg";
import qr from "../../assets/images/icon_menu/ic/qr.svg";
import siakad from "../../assets/images/icon_menu/ic/siakad.svg";
import virtualCard from "../../assets/images/icon_menu/ic/virtual_card.svg";
import mutasi from "../../assets/images/icon_menu/ic/mutation.svg";
import { removeState } from "../../core/feature/browser-storage/browser-storage";
import {
  removeCompany,
  setCompany,
} from "../../core/feature/company/companySlice";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setBalance } from "../../core/feature/user/balanceSlice";
import { setPin } from "../../core/feature/user/pinSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";
import { setUser } from "../../core/feature/user/userSlice";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import User from "../../core/models/user";
// import * as API from "../../core/service/api";
import * as API_BALANCE from "../../core/service/api_balance";
import * as API_INFO from "../../core/service/api_info";
import * as API_USER from "../../core/service/api_user";
import * as API_VIRTUAL_CARD from "../../core/service/api_virtualCard";
import * as API_AUTH from "../../core/service/api_auth";
import { StatsHome } from "../_common/Stats";
import "./home.scss";
import feature from "../../assets/images/icon_home/feature.svg";
import { formatCurrency } from "../../config/global";
import patternSaldo from "../../assets/images/icon_home/pattern-saldo.svg";
import closepayLogo from "../../assets/images/solusinegeri-logo.svg";
import imageHomePage from "../../assets/images/img-homepage2.png";
import UserLogin from "../../core/models/userLogin";
import { Dialog, Transition } from "@headlessui/react";
import defaultIcon from "../../assets/images/icon_menu/customDefault.svg";
import ModalInfo from "../_common/ModallInfo";
import { isIOS, isIOS13 } from "react-device-detect";
import { ConfigColor } from "../../config/global";
import Wisata from "../../assets/images/icon_menu/ic/wisata.svg";
import CustomTooltip from "../_common/tooltip";
import NoImage from "../../assets/images/empty.jpg";
import ProgressBar from "@ramonak/react-progress-bar";
import { getDonationGeneral } from "../../core/service/api_donation";
import VerifiedIcon from "../../assets/images/icon_donation/Verified.svg";
import { Trans, useTranslation } from "react-i18next";

const providers = [
  {
    id: 1,
    code: "BANK_DKI",
    providerName: "Bank DKI",
    img: BankDKI,
  },
  {
    id: 2,
    code: "LINK_AJA",
    providerName: "Link Aja",
    img: LinkAja,
  },
];

const HomeWeb = () => {
  const { t } = useTranslation();
  const color = ConfigColor();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  // const balance = useAppSelector((state) => state.balance.balance);
  // const userChekout: any = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });

  // const pin: number = useAppSelector((state) => state.pin.pin);
  const location = useLocation();
  const { cid } = useParams();

  const [pathName] = useState(
    location.pathname.replaceAll("/", "").toUpperCase()
  );
  const [isLogin, setIsLogin] = useState(user && newToken);
  const [news, setNews] = useState<any[]>([]);
  const [homeMenus, setHomeMenus] = useState<any[]>([]);
  const [comboProviders, setComboProviders] = useState<any[]>([]);
  const [connectedAccounts, setConnectedAccounts] = useState<any[]>([]);
  const [balanceName, setBalanceName] = useState("");
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [configColor, setConfigColor] = useState<any>({});
  const [customMenu, setCustomMenu] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [iframeURL, setIframeUrl] = useState("");
  const [otherMenu, setOtherMenu] = useState(false);
  const [dataDonation, setDataDonation] = useState<any>("");
  const language = localStorage.getItem("i18nextLng") || "id";

  const features = [
    {
      url: `/${company?.initial}/invoices`,
      name: "invoice",
      label: t("home.invoice"),
      icon: invoice,
    },
    {
      url: `/${company?.initial}/siakad`,
      name: "siakad",
      label: t("home.siakad"),
      icon: siakad,
    },
    {
      url: `/${company?.initial}/barcode`,
      name: "barcode_payment",
      label: t("home.barcode_payment"),
      icon: qr,
    },
    {
      url: `/${company?.initial}/donations`,
      name: "donasi",
      label: t("home.donation"),
      icon: donation,
    },
    {
      url: `/${company?.initial}/commerce`,
      name: "marketplace",
      label: t("home.marketplace"),
      icon: marketplace,
    },
    {
      url: `/${company?.initial}/digital-product`,
      name: "ppob",
      label: "PPOB",
      icon: ppob,
    },
    {
      url: `/${company?.initial}/virtual_card`,
      name: "virtual_card",
      label: t("home.virtual_card"),
      icon: virtualCard,
    },
    {
      url: `/${company?.initial}/balance`,
      name: "saldo",
      label: t("home.balance"),
      icon: balance_ic,
    },
    {
      url: `/${company?.initial}/features`,
      name: "accessibility",
      label: t("home.accessibility"),
      icon: feature,
    },
    {
      url: `/${company?.initial}/news-information`,
      name: "info_dan_berita",
      label: t("home.titleCardNews"),
      icon: mutasi,
    },
    {
      url: `/${company?.initial}/trip-tour`,
      name: "trip_tour",
      label: t("home.trip_tour"),
      icon: Wisata,
    },
  ];

  const getConfigColor = async (companyData: any = null) => {
    var color1 =
      companyData?.color?.color1 || company?.color?.color1 !== undefined
        ? company?.color?.color1
        : "#006F54";
    var color2 =
      companyData?.color?.color2 || company?.color?.color2 !== undefined
        ? company?.color?.color2
        : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  let imageNotFound =
    "https://closepay-static.sgp1.digitaloceanspaces.com/image/no-image.png";

  const imageRender = (image: any) => {
    if (image.length !== 0) return image[0];
    if (image === "") return imageNotFound;
    if (image !== "") return image;
  };

  const goToDetail = async (data: any) => {
    navigate(`/${company.initial}/news-information/detail`, {
      state: {
        data: JSON.stringify(data),
      },
    });
  };

  const getConfigPin = async () => {
    await API_USER.getPinConfig()
      .then((res) => {
        dispatch(setPin(Number(res.data.data)));
      })
      .catch((err) => {});
  };

  const getBalance = async () => {
    await API_BALANCE.getBalance("CLOSEPAY")
      .then((res) => {
        dispatch(setBalance(res.data.data.amount));
      })
      .catch((err) => {});
  };

  const getMainBalance = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_BALANCE.getMainBalanceMember()
      .then((res) => {
        dispatch(setLoading(false));
        setBalanceName(res.data.data.balanceName);
        setBalanceAmount(res.data.data.amount);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCompany = async () => {
    if (cid && !company) {
      await API_USER.getCompany(cid)
        .then((res) => {
          dispatch(setCompany(res.data.data));
        })
        .catch((err) => {
          dispatch(removeCompany());
        });
    }
    await getConfigPin();
    if (isLogin) {
      await getBalance();
      await getMainBalance();
    }
  };

  const getConfigCard = async () => {
    if (isLogin) {
      await API_VIRTUAL_CARD.getConfigCard()
        .then(async (res) => {
          if (res.data.data == null) {
            Swal.fire({
              html: '<p class="font-medium">Admin Belum Mengatur Konfigurasi Virtual Card</p>',
              color: "#EC1A1A",
              padding: "1rem",
              imageUrl: FailedIcon,
              imageAlt: "Custom image",
              imageWidth: 150,
              imageHeight: 150,
              confirmButtonColor: "#EC1A1A",
              confirmButtonText: "Kembali",
            });
          } else {
            let query = {
              page: 1,
            };
            await API_VIRTUAL_CARD.getCards(query).then((res2) => {
              if (res2.data.data.length === 1) {
                navigate(`virtual-card/list/${res2.data.data[0]._id}`, {
                  state: {
                    isSingleCard: true,
                  },
                });
              } else {
                navigate(`virtual-card/list`);
              }
            });
          }
        })
        .catch((err) => {});
    } else {
      navigate(`/${company?.initial}/login`);
    }
  };

  const checkBankDKIAccount = async () => {
    await API_BALANCE.checkBankDKIAccount()
      .then((res) => {
        dispatch(setLoading(false));
        if (
          connectedAccounts.length > 0 &&
          connectedAccounts.find(
            (el: any) => el.provider === res.data.provider
          ) !== undefined
        ) {
          return;
        } else {
          setConnectedAccounts(connectedAccounts.concat(res.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const checkLinkAjaAccount = async () => {
    await API_BALANCE.checkLinkAjaAccount()
      .then((res) => {
        dispatch(setLoading(false));
        if (
          connectedAccounts.length > 0 &&
          connectedAccounts.find(
            (el: any) => el.provider === res.data.data.provider
          ) !== undefined
        ) {
          return;
        } else {
          setConnectedAccounts(connectedAccounts.concat(res.data.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProfile = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_USER.getProfile()
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setUser(res.data.data));

        var newUserLogin =
          userLogin?.length > 0
            ? _.filter(userLogin, (el) => el.initial === company.initial)
            : [];
        let indexUserLogin = findIndex(newUserLogin, {
          id: res.data.data.noId,
        });

        if (indexUserLogin < 0) {
          newUserLogin.push({
            ...newUserLogin[indexUserLogin],
            initial: company.initial,
            token: newToken?.token,
            name: res.data.data.name,
            photo: res.data.data.profileImage,
            id: res.data.data.noId,
          });
        } else {
          newUserLogin[indexUserLogin] = {
            ...newUserLogin[indexUserLogin],
            initial: company.initial,
            token: newToken?.token,
            name: res.data.data.name,
            photo: res.data.data.profileImage,
            id: res.data.data.noId,
          };
        }

        dispatch(setUserLogin(newUserLogin));
      })
      .then((res2) => {
        getCompany();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const onClickCustomMenu = async (data: any) => {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let string = "";
    for (let i = 0; i < 25; i++) {
      string += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    if (isLogin) {
      if (data.isAuthRequired === true) {
        dispatch(setLoading(true));
        await API_AUTH.authCustomMenu(string)
          .then((res) => {
            dispatch(setLoading(false));
            console.log(res);
            if (data.redirectType === "internal") {
              setIsOpen(true);
              setOtherMenu(false);
              let url = new URL(data.redirectUrl);
              url.searchParams.set(
                "one_time_access_token",
                `${res.data.data.token}`
              );
              url.searchParams.set("identifier", `${string}`);
              setIframeUrl(`${url.toString()}`);
            } else {
              let url = new URL(data.redirectUrl);
              url.searchParams.set(
                "one_time_access_token",
                `${res.data.data.token}`
              );
              url.searchParams.set("identifier", `${string}`);
              const link = `${url.toString()}`;
              if (isIOS || isIOS13) return window.location.replace(link);
              window.open(link, "_blank");
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      } else {
        let url = new URL(data.redirectUrl);
        if (data.redirectType === "internal") {
          setIsOpen(true);
          setOtherMenu(false);
          setIframeUrl(`${url.toString()}`);
        } else {
          const link = `${url.toString()}`;
          if (isIOS || isIOS13) return window.location.replace(link);
          window.open(link, "_blank");
        }
      }
    } else if (data.name === "Antrian Online" && !isLogin) {
      navigate("online-service");
    } else {
      navigate("login");
    }
  };

  const getDonation = () => {
    setLoading(true);
    getDonationGeneral(company.initial)
      .then((response) => {
        setLoading(false);
        setDataDonation(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const asyncFunct = async (loader = true) => {
      await getProfile(loader);
      // await getConfigPin();
      await removeState("virtual_card");
      await removeState("show_balance_card");
      // await getCompany();
      // if (isLogin) await getBalance();
    };

    if (sessionStorage.getItem("loader")) {
      asyncFunct(false);
    } else {
      asyncFunct();
    }
    getDonation();
  }, [isLogin]);

  useEffect(() => {
    const asyncFunct = async (loader = true) => {
      if (pathName) {
        dispatch(setLoading(loader));
        await API_USER.getCompany(pathName)
          .then((res) => {
            dispatch(setLoading(false));
            dispatch(setCompany(res.data.data));
            dispatch(setLoading(loader));
            API_USER.getHomeConfig(res.data.data.id)
              .then((res) => {
                setHomeMenus(res.data.data);
              })
              .then((res) => {
                API_BALANCE.getComboProviders()
                  .then((res) => {
                    setComboProviders(res.data);
                    let dataOrder = {
                      page: 0,
                      size: 10,
                      sortBy: "createTime",
                      dir: -1,
                      total: 0,
                    };
                    API_BALANCE.getConnectedAccounts2(dataOrder)
                      .then((res) => {
                        let tempConnectedAccounts =
                          res.data.data.linkedAccounts;
                        tempConnectedAccounts.map((el: any) => {
                          switch (el) {
                            case "BANK_DKI":
                              checkBankDKIAccount();
                              break;

                            case "LINK_AJA":
                              checkLinkAjaAccount();
                              break;

                            default:
                              dispatch(setLoading(false));
                              break;
                          }
                        });
                      })
                      .catch((err) => {
                        dispatch(setLoading(false));
                      });
                  })
                  .catch((err) => {
                    dispatch(setLoading(false));
                  });
              })
              .catch((err) => {
                dispatch(setLoading(false));
              });

            dispatch(setLoading(loader));
            let sortBy = "";
            API_USER.getConfigCustomMenu(company.id, sortBy)
              .then((res) => {
                setCustomMenu(res.data.data);
                dispatch(setLoading(false));
              })
              .catch((err) => {
                dispatch(setLoading(false));
              });

            dispatch(setLoading(loader));

            let data = {
              isHeadLine: true,
              page: 1,
              size: 10,
              sortBy: "createdTime",
              dir: -1,
              subCompanyId: user?.subCompanyId,
            };
            API_INFO.getAllNews(res.data.data.id, data)
              .then((res) => {
                dispatch(setLoading(false));
                setNews(res.data.data);
              })
              .catch((err) => {
                dispatch(setLoading(false));
              });
            navigate(`/${res.data.data.initial}`);
          })
          .catch((err) => {});
      }
    };
    if (sessionStorage.getItem("loader")) {
      asyncFunct(false);
    } else {
      asyncFunct();
    }

    setTimeout(() => {
      sessionStorage.removeItem("loader");
    }, 1500);
  }, [pathName]);

  useEffect(() => {
    getConfigColor();
    setIsLogin(user && newToken);
  }, []);

  return (
    <div className="h-full min-h-screen font-montserrat bg-mobile">
      <StatsHome />
      <ModalInfo />
      <div className="container relative -mt-32">
        <div className="mx-auto md:w-3/5 w-full">
          <h1 className="m-0 font-medium text-xl text-white">
            {t("home.greet")},
          </h1>
          <p className="mb-[20px] font-semibold text-2xl text-white">
            {isLogin ? user.name || user.email : "Selamat Datang"}
          </p>
          <div className="relative bg-white1 dark:bg-black rounded-2xl py-4 px-7 shadow min-h-[154px]">
            {isLogin ? (
              <div
                className="flex flex-col justify-center cursor-pointer"
                onClick={() => navigate(`/${company?.initial}/balance`)}
              >
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <h6 className="text-xl text-[#252525] font-montserrat font-medium mb-0">
                      {balanceName}
                    </h6>
                    <h6
                      style={{ color: configColor?.color1 }}
                      className="text-2xl font-montserrat font-semibold mb-0"
                    >
                      {formatCurrency(balanceAmount)}
                    </h6>
                  </div>
                  <img src={patternSaldo} alt="" />
                </div>
                {comboProviders.length > 0 && (
                  <div>
                    {connectedAccounts.length > 0 ? (
                      <div className="grid grid-cols-3 gap-3 justify-center items-center divide-solid divide-x divide-[#eeecec]">
                        {connectedAccounts.map((item) =>
                          providers
                            .filter((el) => el.code === item.provider)
                            .map((i) => (
                              <div
                                className="flex flex-col items-center justify-between gap-2"
                                key={i.id}
                              >
                                <img
                                  src={i.img}
                                  alt=""
                                  className="h-[90px] w-[100px]"
                                />
                                {item?.provider === "LINK_AJA" ? (
                                  <img
                                    src={`data: image/jpeg;base64, ${item?.amount}`}
                                    alt=""
                                  />
                                ) : (
                                  <h6 className="md:text-base text-sm text-[#1AA7EC] font-medium mb-0">
                                    {formatCurrency(item?.amount)}
                                  </h6>
                                )}
                              </div>
                            ))
                        )}
                        <div className="flex w-full justify-center">
                          <button
                            type="button"
                            onClick={() => navigate("balance")}
                            className="rounded-[50%] bg-[#1AA7EC] text-white text-2xl py-2 px-3"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex-flex-row justify-center mt-3">
                        <div className="flex items-center justify-between gap-2">
                          <div
                            style={{ backgroundColor: configColor?.color1 }}
                            className="text-base font-medium mb-0 p-1 px-3 rounded-full text-white"
                          >
                            {t("home.connectEwallet")}
                            <span>
                              <button
                                type="button"
                                onClick={() =>
                                  navigate(`/${company?.initial}/balance`)
                                }
                                style={{ color: configColor?.color1 }}
                                className="rounded-[50%] w-[26px] h-[26px] bg-white py-[1px] px-[6px] ml-3"
                              >
                                +
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col justify-center">
                <div className="w-4/5">
                  {company?.showFtpLogo && (
                    <img src={closepayLogo} alt="" className="md:w-1/5 w-1/3" />
                  )}
                  <p className="md:text-lg text-md text-[#252525] font-semibold">
                    <Trans i18nKey="home.enjoyTransaction">
                      Nikmati kemudahan transaksi
                      <br />
                      dengan Closepay
                    </Trans>
                  </p>
                  {/* <div className="grid grid-cols-2 items-center gap-3 md:w-2/4 w-full">
                    <button
                      type="button"
                      onClick={() => navigate(`login`)}
                      className="w-full p-[5px] rounded-[8px] text-[#FFFFFF] border-[1px] border-[#06A980] hover:text-[#06A980] font-semibold md:text-md text-sm bg-[#06A980] hover:bg-[#FFFFFF]"
                    >
                      Masuk
                    </button>
                    {company?.enableMemberSelfRegister === true && (
                      <button
                        type="button"
                        onClick={() => navigate(`self-register`)}
                        className="w-full p-[5px] rounded-[8px] text-[#06A980] hover:text-[#FFFFFF] border-[1px] border-[#06A980] bg-[#FFFFFF] hover:bg-[#06A980] font-semibold md:text-md text-sm"
                      >
                        Daftar
                      </button>
                    )}
                  </div> */}
                  <div className="flex flex-row gap-2 md:w-2/3 w-full mt-2">
                    <button
                      type="button"
                      style={{ backgroundColor: configColor?.color1 }}
                      onClick={() => navigate(`login`)}
                      className="w-fit px-3 h-fit py-0.5 font-montserrat font-medium text-[10px] rounded-[8px] text-[#FFFFFF] border-[1px] md:text-base text-sm hover:bg-[#FFFFFF]"
                    >
                      {t("home.buttonLogin")}
                    </button>
                    {company?.enableMemberSelfRegister === true && (
                      <button
                        type="button"
                        style={{
                          color: configColor?.color2,
                          borderColor: configColor?.color2,
                        }}
                        onClick={() => navigate(`self-register`)}
                        className="w-fit px-3 h-fit py-0.5 font-montserrat font-medium text-[10px] rounded-[8px] hover:text-[#FFFFFF] border-[1px]  bg-[#FFFFFF] hover:bg-[#06A980] md:text-base text-sm"
                      >
                        {t("home.buttonRegister")}
                      </button>
                    )}
                  </div>
                </div>
                <div className="absolute right-0 top-0 z-10 h-full">
                  <img
                    src={
                      company?.bannerForeground?.web === null ||
                      company?.bannerForeground === null
                        ? imageHomePage
                        : company?.bannerForeground?.web
                    }
                    alt=""
                    className="md:w-full w-[150px] md:h-full"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <section className="container flex flex-col gap-5 justify-center mt-10 md:mb-[0px] mb-[64px] px-4">
        <section className="md:w-4/5 w-full mx-auto gap-5 flex justify-between relative md:mb-[0px] mb-[20px]">
          {dataDonation &&
            moment(dataDonation.endDate).diff(moment(new Date()), "days") >
              0 && (
              <div className="w-3/4 flex flex-col">
                <div className="flex justify-between items-center mb-3 gap-4">
                  <h6 className="md:text-xl text-xl text-[#252525] font-bold">
                    Penggalangan Donasi Pilihan
                  </h6>
                </div>
                <div className="">
                  <div
                    onClick={() => {
                      navigate(`/${company?.initial}/donation-general/detail`);
                    }}
                    className="flex flex-col w-full h-[143px] bg-white md:rounded-md md:shadow-sm shadow-gray-700 gap-3 py-3 md:px-3 cursor-pointer"
                  >
                    <img
                      src={
                        dataDonation?.imageUrl?.length > 0
                          ? dataDonation?.imageUrl[0]
                          : NoImage
                      }
                      className="object-cover bg-cover object-center h-full w-full"
                      alt=""
                    />
                    <div className="flex flex-col flex-grow justify-between">
                      <CustomTooltip
                        // className={"grow max-w-[calc(100%-7rem)] "}
                        content={
                          <div className="max-w-full whitespace-pre-line">
                            {dataDonation.title}
                          </div>
                        }
                        direction="bottom"
                        children={
                          <div className="md:text-base text-xs font-semibold">
                            {dataDonation.title &&
                            dataDonation.title?.length > 30
                              ? dataDonation.title.substr(0, 30) + "..."
                              : dataDonation.title}
                          </div>
                        }
                      ></CustomTooltip>
                      <div className="md:text-sm text-[10px] font-light flex items-center gap-1 mb-2">
                        <span>{dataDonation.foundationName} </span>
                        <img src={VerifiedIcon} alt="Verified.svg" />
                      </div>
                      <ProgressBar
                        isLabelVisible={false}
                        completed={Math.ceil(
                          (dataDonation.total_dana / dataDonation.fundTarget) *
                            100
                        )}
                        height="5px"
                        bgColor={configColor?.color1}
                      />
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                          <span className="md:text-sm text-[10px] font-light">
                            Terkumpul
                          </span>
                          <span className="md:text-base text-xs font-semibold">
                            {formatCurrency(dataDonation.total_dana)}
                          </span>
                        </div>

                        <div className="flex flex-col">
                          <span className="md:text-sm text-[10px] font-light">
                            Sisa Hari
                          </span>
                          <span className="md:text-base text-xs font-semibold text-right">
                            {moment(dataDonation.endDate).diff(
                              moment(new Date()),
                              "days"
                            ) >= 0
                              ? `${moment(dataDonation.endDate).diff(
                                  moment(new Date()),
                                  "days"
                                )}`
                              : "expired"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div id="features" className="w-full">
            <div className="flex flex-row items-center justify-between">
              <h6
                // onClick={() => dispatch(setShowOtp(true))}
                className="md:text-xl text-xl text-[#252525] font-bold mb-2"
              >
                Menu
              </h6>
              {homeMenus.length + customMenu.length > 12 && (
                <button
                  onClick={() => setOtherMenu(true)}
                  style={{ color: configColor?.color1 }}
                  className="text-md font-light"
                >
                  {t("home.seeOther")}
                </button>
              )}
            </div>
            <div className=" dark:bg-black py-2 ">
              <div className="grid lg:grid-cols-6 sm:grid-cols-5 grid-cols-4 gap-3 justify-between">
                {homeMenus.length === 0 &&
                  [1, 2, 3, 4, 5, 6, 7, 8].map((res) => (
                    <div className="flex flex-col items-center">
                      <Link
                        to={""}
                        className="flex flex-col items-center no-underline"
                      >
                        <Skeleton width={64} height={64} />
                        <Skeleton
                          height={10}
                          width={48}
                          className="mt-[14px]"
                        />
                      </Link>
                    </div>
                  ))}
                {homeMenus.length > 0 &&
                  homeMenus.map((item) =>
                    features
                      .filter((el) => el.name === item.name)
                      .map((feature: any, index: number) => (
                        <div
                          className="flex flex-col items-center py-2"
                          key={index}
                        >
                          {feature.name === "virtual_card" ? (
                            <div
                              onClick={getConfigCard}
                              className="flex flex-col gap-2 items-center"
                            >
                              <div className="justify-center rounded-[18px] flex flex-col items-center bg-white no-underline cursor-pointer shadow-xl shadow-[#18181809]">
                                <img
                                  src={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? feature.icon
                                      : item.imageUrl
                                  }
                                  alt=""
                                  className={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? "w-[60px] h-[60px] rounded-[18px]"
                                      : "w-[60px] h-[60px] rounded-[18px] p-2"
                                  }
                                />
                              </div>
                              <h6 className="text-xs text-[#252525] text-center font-medium  mb-0 mt-1">
                                {language === "en"
                                  ? item.displayEn || feature.label
                                  : item.display || feature.label}
                              </h6>
                            </div>
                          ) : feature.name === "siakad" ? (
                            <div
                              onClick={() => {
                                if (isLogin) {
                                  window.location.href = `https://app.dev.solusinegeri.com/siakad/${company.initial}`;
                                  localStorage.removeItem("userType");
                                } else {
                                  navigate("login");
                                }
                              }}
                              className="flex flex-col gap-2 items-center"
                            >
                              <div className="justify-center rounded-[18px] flex flex-col items-center bg-white no-underline cursor-pointer shadow-xl shadow-[#18181809]">
                                <img
                                  src={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? feature.icon
                                      : item.imageUrl
                                  }
                                  alt=""
                                  className={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? "w-[60px] h-[60px] rounded-[18px]"
                                      : "w-[60px] h-[60px] rounded-[18px] p-2"
                                  }
                                />
                              </div>
                              <h6 className="text-xs text-[#252525] text-center font-medium  mb-0 mt-1">
                                {language === "en"
                                  ? item.displayEn || feature.label
                                  : item.display || feature.label}
                              </h6>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                navigate(isLogin ? feature.url : "login", {
                                  state: {
                                    title:
                                      language === "en"
                                        ? item.displayEn || feature.label
                                        : item.display || feature.label,
                                  },
                                });
                              }}
                              className="no-underline flex flex-col gap-2 items-center cursor-pointer"
                            >
                              <div className="flex flex-col justify-center rounded-[18px] items-center bg-white no-underline shadow-xl shadow-[#18181809]">
                                <img
                                  src={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? feature.icon
                                      : item.imageUrl
                                  }
                                  alt=""
                                  className={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? "w-[60px] h-[60px] rounded-[18px]"
                                      : "w-[60px] h-[60px] rounded-[18px] p-2"
                                  }
                                />
                              </div>
                              <h6 className="text-xs text-[#252525] text-center font-medium  mb-0 mt-1">
                                {language === "en"
                                  ? item.displayEn || feature.label
                                  : item.display || feature.label}
                              </h6>
                            </div>
                          )}
                        </div>
                      ))
                  )}
                {customMenu
                  .slice(0, 12 - (homeMenus.length + customMenu.length))
                  .map((res: any) => {
                    return (
                      <div
                        onClick={() => onClickCustomMenu(res)}
                        className={
                          "no-underline flex flex-col items-center gap-2.5 cursor-pointer py-2"
                        }
                      >
                        <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                          <img
                            src={
                              res.imageUrl === null || res.imageUrl === ""
                                ? defaultIcon
                                : res.imageUrl
                            }
                            alt=""
                            className={
                              res.imageUrl === null || res.imageUrl === ""
                                ? "w-[60px] h-[60px] rounded-[18px]"
                                : "w-[60px] h-[60px] rounded-[18px] p-2"
                            }
                          />
                        </div>
                        <h6 className="mt-1 font-montserrat text-black text-center text-[12px] font-medium  mb-0">
                          {res.name === "" ? res.name : res.name}
                        </h6>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
        <div className="md:w-4/5 w-full mx-auto mt-3">
          <div id="news" className="mt-8">
            <div className="flex justify-between items-center mb-2 gap-4">
              <h6 className="md:text-xl text-xl text-[#252525] font-bold mb-0">
                {t("news_information.titleCardNews")}
              </h6>
              <Link
                to="news-information"
                className="underline md:text-base text-sm font-semibold"
                style={{ color: color.color1 }}
              >
                {t("home.seeOther")}
              </Link>
            </div>
            <div className="mb-4">
              {news.length === 0 ? (
                <div className="h-auto w-auto">
                  <img src={noInfo} className="flex mx-auto" alt="" />
                </div>
              ) : (
                ""
              )}
              {news.filter((el) => el.isPublish === true).length > 0 ? (
                <Swiper
                  freeMode={true}
                  grabCursor={true}
                  modules={[FreeMode]}
                  slidesPerView={2}
                  spaceBetween={15}
                  className="mySwiper"
                >
                  {news
                    .filter((el) => el.isHeadLine === true)
                    .map((res, index) => (
                      <SwiperSlide
                        onClick={() => goToDetail(res)}
                        key={index}
                        className="cursor-pointer"
                      >
                        <div draggable={false} className="h-full">
                          <div className="bg-white rounded-[5px] overflow-hidden shadow-sm p-2  min-h-[15rem] mb-3 h-[15rem]">
                            <img
                              loading="lazy"
                              draggable={false}
                              src={imageRender(res.imageUrl)}
                              className="object-cover w-full select-none overflow-hidden rounded-[5px]"
                              alt=""
                              style={{ height: "140px" }}
                            />
                            <div className="flex flex-col">
                              <h1 className="text-base font-montserrat font-semibold text-[#252525] text-left mt-[10px] mb-[8px]">
                                {res.title.length < 60
                                  ? res.title
                                  : res.title.toString().substring(0, 60) +
                                    "..."}
                              </h1>
                              <p className="text-[10px] font-montserrat font-light text-[#9A9999] text-left mb-0">
                                {moment(res?.createdTime).format("LL")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[1000]"
            onClose={() => setIsOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-screen items-center justify-center px-20 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                    <iframe
                      title="menu"
                      src={iframeURL}
                      className="w-full h-full min-h-[80vh]"
                    ></iframe>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <Transition appear show={otherMenu} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[1000]"
            onClose={() => setOtherMenu(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-screen items-center justify-center text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-auto max-w-4xl p-4 rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <div>
                      <h4 className="text-[16px] font-semibold">
                        {t("home.mainMenu")}
                      </h4>
                      <div className="grid lg:grid-cols-6 sm:grid-cols-5 grid-cols-4 gap-2 justify-between">
                        {homeMenus.length === 0 &&
                          [1, 2, 3, 4, 5, 6, 7, 8].map((res) => (
                            <div className="flex flex-col items-center">
                              <Link
                                to={""}
                                className="flex flex-col items-center no-underline"
                              >
                                <Skeleton width={64} height={64} />
                                <Skeleton
                                  height={10}
                                  width={48}
                                  className="mt-[14px]"
                                />
                              </Link>
                            </div>
                          ))}
                        {homeMenus.length > 0 &&
                          homeMenus.map((item) =>
                            features
                              .filter((el) => el.name === item.name)
                              .map((feature: any, index: number) => (
                                <div
                                  className="flex flex-col items-center py-2"
                                  key={index}
                                >
                                  {feature.name === "virtual_card" ? (
                                    <div
                                      onClick={getConfigCard}
                                      className="flex flex-col gap-2 items-center"
                                    >
                                      <div className="justify-center rounded-[18px] flex flex-col items-center bg-white no-underline cursor-pointer shadow-xl shadow-[#18181809]">
                                        <img
                                          src={
                                            item.imageUrl === null ||
                                            item.imageUrl === ""
                                              ? feature.icon
                                              : item.imageUrl
                                          }
                                          alt=""
                                          className={
                                            item.imageUrl === null ||
                                            item.imageUrl === ""
                                              ? "w-[60px] h-[60px] rounded-[18px]"
                                              : "w-[60px] h-[60px] rounded-[18px] p-2"
                                          }
                                        />
                                      </div>
                                      <h6 className="text-xs text-[#252525] text-center font-medium  mb-0 mt-1">
                                        {language === "en"
                                          ? item.displayEn || feature.label
                                          : item.display || feature.label}
                                      </h6>
                                    </div>
                                  ) : feature.name === "siakad" ? (
                                    <div
                                      onClick={() => {
                                        if (isLogin) {
                                          window.location.href = `https://app.dev.solusinegeri.com/siakad/${company.initial}`;
                                          localStorage.removeItem("userType");
                                        } else {
                                          navigate("login");
                                        }
                                      }}
                                      className={
                                        "no-underline flex flex-col items-center gap-2.5"
                                      }
                                    >
                                      <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                                        <img
                                          src={
                                            item.imageUrl === null ||
                                            item.imageUrl === ""
                                              ? feature.icon
                                              : item.imageUrl
                                          }
                                          alt=""
                                          className={
                                            item.imageUrl === null ||
                                            item.imageUrl === ""
                                              ? "w-[54px] h-[54px] rounded-[18px]"
                                              : "w-[54px] h-[54px] rounded-[18px] p-2"
                                          }
                                        />
                                      </div>
                                      <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                                        {language === "en"
                                          ? item.displayEn || feature.label
                                          : item.display || feature.label}
                                      </h6>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        navigate(
                                          isLogin ? feature.url : "login",
                                          {
                                            state: {
                                              title:
                                                language === "en"
                                                  ? item.displayEn ||
                                                    feature.label
                                                  : item.display ||
                                                    feature.label,
                                            },
                                          }
                                        );
                                      }}
                                      className="no-underline flex flex-col gap-2 items-center cursor-pointer"
                                    >
                                      <div className="flex flex-col justify-center rounded-[18px] items-center bg-white no-underline shadow-xl shadow-[#18181809]">
                                        <img
                                          src={
                                            item.imageUrl === null ||
                                            item.imageUrl === ""
                                              ? feature.icon
                                              : item.imageUrl
                                          }
                                          alt=""
                                          className={
                                            item.imageUrl === null ||
                                            item.imageUrl === ""
                                              ? "w-[60px] h-[60px] rounded-[18px]"
                                              : "w-[60px] h-[60px] rounded-[18px] p-2"
                                          }
                                        />
                                      </div>
                                      <h6 className="text-xs text-[#252525] text-center font-medium  mb-0 mt-1">
                                        {language === "en"
                                          ? item.displayEn || feature.label
                                          : item.display || feature.label}
                                      </h6>
                                    </div>
                                  )}
                                </div>
                              ))
                          )}
                      </div>
                      {customMenu.length > 0 && (
                        <h4 className="mt-3 text-[16px] font-semibold">
                          {t("home.menuOther")}
                        </h4>
                      )}
                      <div className="grid lg:grid-cols-6 sm:grid-cols-5 grid-cols-4 gap-2 justify-between">
                        {customMenu.map((res: any) => {
                          return (
                            <div
                              onClick={() => onClickCustomMenu(res)}
                              className={
                                "no-underline flex flex-col items-center gap-2.5 cursor-pointer py-2"
                              }
                            >
                              <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                                <img
                                  src={
                                    res.imageUrl === null || res.imageUrl === ""
                                      ? defaultIcon
                                      : res.imageUrl
                                  }
                                  alt=""
                                  className={
                                    res.imageUrl === null || res.imageUrl === ""
                                      ? "w-[60px] h-[60px] rounded-[18px]"
                                      : "w-[60px] h-[60px] rounded-[18px] p-2"
                                  }
                                />
                              </div>
                              <h6 className="mt-1 font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                                {res.name === "" ? res.name : res.name}
                              </h6>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </section>
    </div>
  );
};

export default HomeWeb;
