import React from 'react';
import Header from '../../_common/headerComponent';
import GreenCard from '../../../assets/images/icon_queue/green-card.png';

const HistoryDetailWeb = () => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />
      <div className="md:w-1/2 w-full mx-auto md:mt-12 mt-[0px] pb-10">
        <div className="relative mb-9 px-4">
          <img
            src={GreenCard}
            alt=""
          />
          <div className="flex flex-col items-center absolute bottom-0 left-1/2 z-10" style={{ transform: "translate(-50%, -50%)" }}>
            <h6 className="md:text-sm text-xs text-white font-normal">Antrian No.</h6>
            <h1 className="md:text-6xl text-5xl text-white font-bold">B-481</h1>
            <h6 className="md:text-sm text-xs text-white font-normal">Klinik Merdeka Batubara</h6>
          </div>
        </div>
        <div className="bg-white p-3">
          <div className="flex items-center justify-between mt-3">
            <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">Waktu Antri</h6>
            <h6 className="md:text-base text-sm text-thirdy font-medium mb-0">31-10-2021, 07 : 53 : 50  WIB</h6>
          </div>
          <div className="flex items-center justify-between mt-3">
            <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">Nama Anggota</h6>
            <h6 className="md:text-base text-sm text-thirdy font-medium mb-0">Santi</h6>
          </div>
          <div className="flex items-center justify-between mt-3">
            <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">Aktivitas</h6>
            <h6 className="md:text-base text-sm text-thirdy font-medium mb-0">Antri Ambil Obat</h6>
          </div>
          <div className="mt-4">
            <h6 className="md:text-base text-sm text-customBlack font-medium">Deskripsi</h6>
            <textarea
              defaultValue="Pelajaran Mewarnai dan Melempar Batu Jumroh"
              readOnly
              disabled
              className="bg-mobile border-[1px] border-[#E5E5E5] text-thirdy md:text-sm text-xs border-solid w-full p-[12px] font-medium rounded-[10px] focus:outline"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HistoryDetailWeb