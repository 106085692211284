import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import MutationWeb from "./mutationWeb";
import MutationMobile from "./mutationMobile";

const Mutation = () => {
  return (
    <>
      <BrowserView>
        <MutationWeb></MutationWeb>
      </BrowserView>
      <MobileView>
        <MutationMobile></MutationMobile>
      </MobileView>
    </>
  );
};

export default Mutation;
