import React, { FC } from 'react';
import TwoPersons from '../../assets/images/icon_queue/two_persons.svg';

interface QueueComponentProps {
  current: string;
  remainder: string;
}

const QueueComponent: FC<QueueComponentProps> = (props): JSX.Element => {
  return (
    <div className="flex items-center justify-center gap-3">
      <div className="bg-[#F5F5F5] rounded-[15px] p-3 flex flex-col items-center">
        <div className="flex items-center gap-2">
          <img src={TwoPersons} alt="" />
          <h3 className="md:text-sm text-xs font-medium text-newColor mb-0">Antrian saat ini</h3>
        </div>
        <h3 className="md:text-[26px] text-2xl font-bold text-newColor mb-0">{props.current}</h3>
      </div>
      <div className="bg-[#F5F5F5] rounded-[15px] p-3 flex flex-col items-center">
        <div className="flex items-center gap-2">
          <img src={TwoPersons} alt="" />
          <h3 className="md:text-sm text-xs font-medium text-newColor mb-0">Sisa antrian</h3>
        </div>
        <h3 className="md:text-[26px] text-2xl font-bold text-newColor mb-0">{props.remainder}</h3>
      </div>
    </div>
  )
}

export default QueueComponent