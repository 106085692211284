import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  showAddDestinationOtp: boolean;
  data: any;
  username: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  destinationType: string;
  skipTo: any;
};

const initialState: InitialState = {
  showAddDestinationOtp: false,
  isEmailVerified: false,
  isPhoneVerified: false,
  data: {},
  username: "",
  destinationType: "",
  skipTo: "",
};

export const addDestinationSlice = createSlice({
  name: "addDestinationOtp",
  initialState: initialState,
  reducers: {
    setShowAddDestinationOtp: (state, action: PayloadAction<any>) => {
      state.showAddDestinationOtp = action.payload;
    },
    setDataDestination: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setUsernameDestination: (state, action: PayloadAction<any>) => {
      state.username = action.payload;
    },
    setIsEmailVerifiedDestination: (state, action: PayloadAction<any>) => {
      state.isEmailVerified = action.payload;
    },
    setIsPhoneVerifiedDestination: (state, action: PayloadAction<any>) => {
      state.isPhoneVerified = action.payload;
    },
    setDestinationType: (state, action: PayloadAction<any>) => {
      state.destinationType = action.payload;
    },
    setSkipTo: (state, action: PayloadAction<any>) => {
      state.skipTo = action.payload;
    },
    resetDestinationOtp: (state) => {
      state.showAddDestinationOtp = false;
      state.data = {};
    },
    removeUsernameDestination: (state) => {
      state.username = "";
      state.isEmailVerified = false;
      state.isPhoneVerified = false;
      state.destinationType = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setShowAddDestinationOtp,
  resetDestinationOtp,
  setDataDestination,
  setUsernameDestination,
  removeUsernameDestination,
  setDestinationType,
  setIsEmailVerifiedDestination,
  setIsPhoneVerifiedDestination,
  setSkipTo,
} = addDestinationSlice.actions;

export default addDestinationSlice.reducer;
