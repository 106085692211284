/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { BrowserView, MobileView } from "react-device-detect";
import QRCode from "react-qr-code";
import { useLocation, useParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import * as API_ABSENSI from "../../core/service/api_absensi.js";
import QrisIcon from "../../assets/images/icon/qris.svg";
import ScanCardIcon from "../../assets/images/icon/scancard.svg";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import ParkingFeaturesDetailMobile from "./parkingFeaturesDetailMobile";
import ParkingFeaturesDetailWeb from "./parkingFeaturesDetailWeb";
import { setLoading } from "../../core/feature/config/configSlice";
import Token from "../../core/models/token.js";
import Company from "../../core/models/company.js";
import _ from "lodash";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ParkingFeaturesDetail = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [modalQRIS, setModalQRIS] = useState(false);
  const [modalQR, setModalQR] = useState(false);
  const [modalCard, setModalCard] = useState(false);
  const [open, setOpen] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [valueQR, setValueQR] = useState("");
  const [timerQR, setTimerQR] = useState(300000);
  const [timerQRIS, setTimerQRIS] = useState(300000);
  const location: any = useLocation();
  const token: Token[] = useAppSelector((state) => state.token.token);
  const company: Company = useAppSelector((state) => state.company.company);
  const [isLogout, setIsLogout] = useState(false);
  const [bayar, setBayar] = useState(false);

  const getParkingDetail = async (parkingId: any) => {
    dispatch(setLoading(true));
    await API_ABSENSI.getParkingDetail(encodeURIComponent(parkingId))
      .then((res) => {
        dispatch(setLoading(false));
        let resData = res.data.data;
        setData(resData);
        setValueQR(resData.id);
        if (resData.payments.length > 0) {
          let findData = resData.payments.find(
            (el: any) => el.status === "PAID"
          );
          if (findData !== undefined) setIsPaid(true);
        }
        if (resData.status === "LOGOUT") {
          setIsLogout(true);
        } else {
          setIsLogout(false);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const showQRIS = (value: any) => {
    setValueQR(value);
    setModalQRIS(true);
  };

  const showQR = (value: any) => {
    setValueQR(value);
    setModalQR(true);
  };

  const renderer = (data: any) => {
    if (data.completed) {
      // setSecurityCode("");
      return (
        <button
          className="text-themePrimary font-medium md:text-base text-sm mt-3"
          type="button"
          onClick={() => {
            regenerateQRIS();
          }}
        >
          {t('accessibility.regenerate')}
        </button>
      );
    } else {
      return (
        <span className="font-medium md:text-base text-sm mt-3 flex flex-col">
          <span>{t('accessibility.timeLimit')}</span>
          <span className="font-bold text-themeDanger">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };

  const rendererQR = (data: any) => {
    if (data.completed) {
      // setSecurityCode("");
      return (
        <button
          className="text-themePrimary font-medium md:text-base text-sm mt-3"
          type="button"
          onClick={() => {
            regenerateQR();
          }}
        >
          {t('accessibility.regenerate')}
        </button>
      );
    } else {
      return (
        <span className="font-medium md:text-base text-sm mt-3 flex flex-col">
          <span>{t('accessibility.timeLimit')}</span>
          <span className="font-bold text-themeDanger">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };

  const navigateToCheckout = async () => {
    dispatch(setLoading(true));
    let newData = {
      parkingId: data?.id,
      // paymentMethod: "CHECKOUT",
    };
    await API_ABSENSI.parkingPaymentCheckout(newData)
      .then((res: any) => {
        dispatch(setLoading(false));
        let dataToken: any = _.find(token, function (o) {
          return o.cid === company.initial;
        });
        // window.location.href = `https://${res.data.data.paymentCheckoutLink}?access_token=${dataToken?.token}`;
      })
      .catch((err: any) => {
        dispatch(setLoading(false));
      });
  };

  const parkingPayment = async () => {
    dispatch(setLoading(true));
    let newData = {
      parkingId: data?.id,
      // paymentMethod: "CHECKOUT",
    };
    await API_ABSENSI.parkingPaymentCheckout(newData)
      .then((res: any) => {
        dispatch(setLoading(false));
        let dataToken: any = _.find(token, function (o) {
          return o.cid === company.initial;
        });
        if (res.data.data.method === "SUBSCRIPTION_PARKING") {
          toast.success("Berhasil terbayar");
          getParkingDetail(location?.state?.data?.id);
        } else {
          // setBayar(true);
          window.location.href = `https://${res.data.data.paymentCheckoutLink}?access_token=${dataToken?.token}`;
        }
      })
      .catch((err: any) => {
        dispatch(setLoading(false));
      });
  };

  const regenerateQRIS = () => {
    setTimerQRIS(Date.now() + 300000);
    showQRIS("adasd");
    console.log("asuhaijbcuqavciy");
  };

  const regenerateQR = () => {
    setTimerQR(Date.now() + 300000);
    showQR("adasd");
  };

  const generateQRCode = () => {
    setTimerQRIS(Date.now() + 300000);
    setValueQR("adasd");

    setOpen(true);
  };

  useEffect(() => {
    getParkingDetail(location?.state?.data?.id);
  }, []);

  useEffect(() => {
    // console.log(valueQR);
    setTimerQRIS(Date.now() + 300000);
  }, [modalQRIS, valueQR]);

  useEffect(() => {
    // console.log(valueQR);
    setTimerQR(Date.now() + 300000);
  }, [modalQR, valueQR]);

  return (
    <>
      {/* Modal QRIS */}
      <div
        className={`${modalQRIS ? "block" : "hidden"} relative z-50`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="text-end md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setModalQRIS(false);
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="text-center mb-2">
                <div className="px-3 py-4 flex flex-col gap-4 items-center">
                  <span>
                    {t('accessibility.scan_qris')}
                  </span>
                  <img src={QrisIcon} alt="" />
                  <QRCode
                    style={{ height: "50%", width: "50%" }}
                    value={valueQR}
                  />
                  <Countdown
                    key={timerQRIS}
                    date={timerQRIS}
                    renderer={renderer}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
      {/* Modal switch */}
      <div
        className={`${modalQR ? "block" : "hidden"} relative z-50`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="text-end md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setModalQR(false);
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="text-center mb-2">
                <div className="px-3 py-4 flex flex-col gap-4 items-center">
                  <span>
                    {t('accessibility.scan_qris_device_parking')}
                  </span>
                  <QRCode
                    style={{ height: "50%", width: "50%" }}
                    value={valueQR}
                  />
                  {/* <Countdown
                    key={timerQR}
                    date={timerQR}
                    renderer={rendererQR}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
      {/* Modal Card */}
      <div
        className={`${modalCard ? "block" : "hidden"} relative z-50`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="text-end md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setModalCard(false);
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="text-center mb-2">
                <div className="px-3 py-4 flex flex-col gap-4 items-center">
                  <img src={ScanCardIcon} alt="" />
                  <span>
                    {t('accessibility.tap_card_member')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
      {/* Modal QRIS New */}
      <BottomSheet open={open} onDismiss={() => setOpen(false)}>
        <div className="mx-4 mt-1 mb-10 font-montserrat">
          <div className="text-center mb-2">
            <div className="px-3 py-4 flex flex-col gap-4 items-center">
              <span>
                {t('accessibility.scan_qris')}
              </span>
              <img src={QrisIcon} alt="" />
              <QRCode style={{ height: "50%", width: "50%" }} value={valueQR} />
              <Countdown key={timerQRIS} date={timerQRIS} renderer={renderer} />
            </div>
          </div>
        </div>
      </BottomSheet>
      {/* --- */}
      <BrowserView>
        <ParkingFeaturesDetailWeb
          data={data}
          valueQR={valueQR}
          isPaid={isPaid}
          checkoutLink={() => navigateToCheckout()}
          regenerateQRIS={() => regenerateQRIS()}
          regenerateQR={() => regenerateQR()}
          setModalCard={(value: boolean) => setModalCard(value)}
          setOpen={(value: boolean) => setOpen(value)}
          generateQRCode={() => generateQRCode()}
          setModalQR={(value: any) => setModalQR(value)}
          isLogout={isLogout}
          bayar={bayar}
          setBayar={setBayar}
          parkingPayment={parkingPayment}
        />
      </BrowserView>
      <MobileView>
        <ParkingFeaturesDetailMobile
          data={data}
          valueQR={valueQR}
          isPaid={isPaid}
          checkoutLink={() => navigateToCheckout()}
          regenerateQRIS={() => regenerateQRIS()}
          regenerateQR={() => regenerateQR()}
          setModalCard={(value: boolean) => setModalCard(value)}
          setOpen={(value: boolean) => setOpen(value)}
          setModalQR={(value: any) => setModalQR(value)}
          generateQRCode={() => generateQRCode()}
          isLogout={isLogout}
          bayar={bayar}
          setBayar={setBayar}
          parkingPayment={parkingPayment}
        />
      </MobileView>
    </>
  );
};

export default ParkingFeaturesDetail;
