import Header from "../_common/headerComponent";
import bg from "../../assets/images/icon_features/bg_QR.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import { scanQrCode } from "../../core/service/api_absensi";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import * as API_ABSENSI from "../../core/service/api_absensi";
// import Company from "../../core/models/company";
// import { useAppSelector } from "../../core/feature/hooks";
import Barcode from "qrcode";
import Countdown from "react-countdown";
import BottomLogo from "../_common/bottomLogo";
import { ConfigColor } from "../../config/global";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";

const ScanQRAccessMobile = () => {
  const navigate = useNavigate();
  const color = ConfigColor();
  const { t } = useTranslation();
  const [counter, setCounter] = useState(60);
  const dispatch = useDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  // const [src, setSrc] = useState("");
  const [barcode, setBarcode] = useState("");

  const getQrCode = async () => {
    let query = {
      companyId: "",
      companyInitial: "",
      email: "",
      userType: "",
      userId: "",
    };
    dispatch(setLoading(true));
    await API_ABSENSI.scanQrCode(query)
      .then((res) => {
        dispatch(setLoading(false));
        // setSrc(res.data.data.qrId);
        Barcode.toDataURL(res.data.data.qrId).then((res) => {
          setBarcode(res);
          setTimeout(() => setCounter(counter - 1), 100);
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const reGenerate = () => {
    getQrCode();
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      setBarcode("");
    } else {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    getQrCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full block min-h-screen bg-mobile">
      <div className="w-full min-h-full relative font-montserrat">
        <Header navbarText="QR Barcode" />
        <div className="p-4">
          <div
            className="bg-cover px-4 py-5 text-white rounded-xl min-h-[50vh] text-center"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundColor: `${color.color1}`,
            }}
          >
            <p className="text-center text-lg mb-4">
              {t("accessibility.message_scan_qr_access")}
            </p>
            {barcode === "" ? (
              <p className="mt-32">{t("accessibility.barcode_exp")}</p>
            ) : (
              <div className="flex flex-col text-lg">
                <img
                  className="flex mx-auto rounded-xl w-[50vw] mb-4"
                  src={barcode}
                  alt=""
                />
                <span>{t("accessibility.timeLimit")}</span>
                <Countdown date={Date.now() + 300000} renderer={renderer} />
              </div>
            )}
          </div>
          {barcode === "" ? (
            <button
              onClick={() => reGenerate()}
              className="bg-gradient-to-b border-2 rounded-xl w-full p-2 text-white mt-8"
              style={{ backgroundColor: `${color.color1}` }}
            >
              Generate Barcode
            </button>
          ) : (
            <button
              onClick={() => navigate(-1)}
              className="border-red border-2 rounded-xl w-full p-2 text-red mt-8"
            >
              {t("accessibility.cancel")}
            </button>
          )}
        </div>
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </div>
  );
};

export default ScanQRAccessMobile;
