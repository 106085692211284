/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TopUpCardBalancePaymentSummaryWeb from "./topUpCardBalancePaymentSummaryWeb";
import TopUpCardBalancePaymentSummaryMobile from "./topUpCardBalancePaymentSummaryMobile";
import { useParams } from "react-router-dom";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { formatCurrency, formatDateTimeUTC } from "../../../config/global";
import { setLoading } from "../../../core/feature/config/configSlice";
import Company from "../../../core/models/company";
import { useTranslation } from "react-i18next";

const TopUpCardBalancePaymentSummary = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [dataPost, setDataPost] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any>({});

  const getDetail = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.getDetailTopUpCardBalance(params.transactionId)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        let tempData = res.data.data;
        let newData = [
          {
            property: t('virtual_card.transaction_id'),
            value: tempData._id,
          },
          {
            property: t('virtual_card.date'),
            value: formatDateTimeUTC(tempData.createdTime),
          },
          {
            property: t('virtual_card.member_name'),
            value: tempData.user.name,
          },
          {
            property: t('virtual_card.payment_type'),
            value: "Top Up Saldo Kartu",
          },
          {
            property: t('virtual_card.card_name'),
            value: tempData.cardName,
          },
          {
            property: t('virtual_card.paymentMethod'),
            value: "Saldo Closeloop",
          },
          {
            property: t('virtual_card.nominal_topup'),
            value: formatCurrency(tempData.amount),
          },
          {
            property: t('withdraw_funds.adminFee'),
            value: formatCurrency(tempData.totalCharges),
          },
        ];
        setDataPost(newData);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <BrowserView>
        <TopUpCardBalancePaymentSummaryWeb
          company={company}
          dataPost={dataPost}
          data={data}
        />
      </BrowserView>
      <MobileView>
        <TopUpCardBalancePaymentSummaryMobile
          company={company}
          dataPost={dataPost}
          data={data}
        />
      </MobileView>
    </>
  );
};

export default TopUpCardBalancePaymentSummary;
