import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationWeb from "./donationWeb";
import DonationMobile from "./donationMobile";

const Donation = () => {
  return (
    <>
      <BrowserView>
        <DonationWeb></DonationWeb>
      </BrowserView>
      <MobileView>
        <DonationMobile></DonationMobile>
      </MobileView>
    </>
  );
};

export default Donation;
