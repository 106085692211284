import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPajakPBBSummaryWeb from "./ppobPajakPBBSummaryWeb";
import PPOBPajakPBBSummaryMobile from "./ppobPajakPBBSummaryMobile";

const PPOBPajakPBBSummary = () => {
  return (
    <>
      <BrowserView>
        <PPOBPajakPBBSummaryWeb></PPOBPajakPBBSummaryWeb>
      </BrowserView>
      <MobileView>
        <PPOBPajakPBBSummaryMobile></PPOBPajakPBBSummaryMobile>
      </MobileView>
    </>
  );
};

export default PPOBPajakPBBSummary;
