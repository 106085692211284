import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "@ramonak/react-progress-bar";
import _ from "lodash";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "react-spring-bottom-sheet/dist/style.css";
import DocumentIcon from "../../assets/images/Iconly/Bold/Document.svg";
import DownloadIcon from "../../assets/images/Iconly/Bold/Download.svg";
import HeartIcon from "../../assets/images/Iconly/Bold/Heart.svg";
import arrowRightIcon from "../../assets/images/Iconly/Light-Outline/ArrowRight.svg";
import VerifiedIcon from "../../assets/images/icon_donation/Verified.svg";
import { imageOnError } from "../../config/global.js";
import Header from "../_common/headerComponent";
import CustomTooltip from "../_common/tooltip";
import { useTranslation } from "react-i18next";

const DonationWeb = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Header></Header>
      <section className="h-full min-h-[calc(100vh-104px)] font-poppins bg-mobile">
        <div className="h-full m-auto w-2/5 lg:w-1/2 min-w-[30rem] font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100 bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                <div className="h-60 flex flex-col gap-1 justify-center items-center w-full">
                  <div
                    key={"detail-image-" + props.data.imageUrl?.length}
                    className="slide-container w-full"
                  >
                    <Slide
                      transitionDuration={500}
                      indicators={props.indicators}
                      prevArrow={<></>}
                      nextArrow={<></>}
                      canSwipe
                    >
                      {props.data.imageUrl?.map(
                        (slideImage: any, index: number) => (
                          <div key={index}>
                            <img
                              className="bg-[#D9D9D9] rounded-md w-fit h-52 mx-auto"
                              onError={imageOnError}
                              src={slideImage}
                              alt=""
                            />
                          </div>
                        )
                      )}
                    </Slide>
                  </div>
                </div>
                <CustomTooltip
                  content={
                    <div className="max-w-full whitespace-pre-line">
                      {props.data.title}
                    </div>
                  }
                  direction="bottom"
                  children={
                    <div className="text-[#252525] text-base font-semibold whitespace-nowrap overflow-hidden truncate ">
                      {props.data?.title}
                    </div>
                  }
                ></CustomTooltip>
                <div>
                  <ProgressBar
                    isLabelVisible={false}
                    completed={Math.ceil(
                      (props.data?.total_dana / props.data?.fundTarget) * 100
                    )}
                    height="5px"
                    bgColor={props.color.color1}
                  />
                </div>
                <div className="text-[#252525] text-sm font-semibold flex justify-between">
                  <div className="border-solid border-[#94959e58] border-r-[1px] w-1/3 flex flex-col justify-between items-center gap-2">
                    <div className="flex gap-2">
                      <img src={HeartIcon} alt="Donation.svg" />
                      <span>{props.data.total_funder}</span>
                    </div>
                    <span className="font-light text-xs">Zakat</span>
                  </div>
                  <div className="border-solid border-[#94959e58] border-r-[1px] w-1/3 flex flex-col justify-between items-center gap-2">
                    <div className="flex gap-2">
                      <img src={DocumentIcon} alt="Document.svg" />
                      <span>{props.data.total_progress}</span>
                    </div>
                    <span className="font-light text-xs">{t('zakat.latestNews')}</span>
                  </div>
                  <div className="w-1/3 flex flex-col justify-between items-center gap-2">
                    <div className="flex gap-2">
                      <img src={DownloadIcon} alt="Download.svg" />
                      <span>{props.data.total_disbursement}</span>
                    </div>
                    <span className="font-light text-xs">{t('zakat.fundWithdrawal')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-2/5 lg:w-1/2  min-w-[30rem]  h-full m-auto font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                <div>{t('zakat.info')}</div>

                <div className="border-solid border-[.3px] border-[#94959e8f] rounded-md px-3 py-4 text-sm font-semibold">
                  <span>{t('zakat.foundation')}</span>
                  <div className="">
                    <span className="flex gap-2">
                      <span>{props.data.foundationName}</span>
                      <img src={VerifiedIcon} alt="Verified.svg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-2/5 lg:w-1/2  min-w-[30rem]  h-full m-auto font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                {/* </div>
              <div className="border-solid border-[.3px] border-[#94959e8f] rounded-md px-3 py-4 text-sm font-semibold"> */}
                <span>{t('zakat.background')}</span>
                <div className="flex gap-2 flex-wrap justify-center">
                  {_.map(
                    _.filter(props.data.imageUrl, (item) => item),
                    (item) => (
                      <div className="w-1/4 min-w-[100px] max-w-[150px]">
                        <img
                          src={item}
                          alt=""
                          onError={imageOnError}
                          className="bg-[#D9D9D9] rounded-md w-full"
                        />
                      </div>
                    )
                  )}
                </div>
                <div className="h-52 relative flex justify-start">
                  <div className="hDescDesktop absolute h-fit" id="hDesc">
                    {props.data.description}
                  </div>
                  <button
                    className="absolute bottom-0 h-10 flex justify-center items-center w-fit  py-1 rounded-md text-"
                    hidden={props.hideButton}
                    onClick={() => props.setShowModal(true)}
                  >
                    {t('zakat.viewMore')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-2/5 lg:w-1/2  min-w-[30rem]  h-full m-auto font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                {/* PROGRESS */}
                <div
                  className="flex justify-between py-4 border-solid border-b-[.3px] border-[aaa]"
                  onClick={() =>
                    props.navigate(
                      `/${props.company?.initial}/donations/zakat/progress/${props.params.id}`,
                      {
                        state: {
                          foundationName: props.data?.foundationName,
                          progress: props.data?.progress,
                        },
                      }
                    )
                  }
                >
                  <div>
                    <div className="flex gap-2 font-bold">
                      <span className="text-base">{t('zakat.latestNews')}</span>
                      <span
                        className="bg-[#E6F5FB] text-themePrimary rounded-xl px-3 flex justify-center items-center text-xs "
                        style={{ color: props.color.color1 }}
                      >
                        {props.data?.progress?.length}
                      </span>
                    </div>
                    <div className="flex gap-2 text-xs font-light">
                      <span className="flex gap-1 items-center">
                        {t('zakat.lastUpdate')}
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </span>
                      <span>{props.getLastDate(props.data?.progress)}</span>
                    </div>
                  </div>
                  <img src={arrowRightIcon} alt="" />
                </div>
                {/* DISBURSMENT */}
                <div
                  className="flex justify-between py-4 border-solid border-b-[.3px] border-[aaa]"
                  onClick={() =>
                    props.navigate(
                      `/${props.company?.initial}/donations/zakat/disbursement/${props.params.id}`,
                      {
                        state: {
                          foundationName: props.data?.foundationName,
                          disbursement: props.data?.disbursement,
                          total_dana: props.data?.total_dana,
                          total_funder: props.data?.total_funder,
                          total_transaction: props.data?.transaction?.length,
                        },
                      }
                    )
                  }
                >
                  <div>
                    <div className="flex gap-2 font-bold">
                      <span className="text-base">{t('zakat.fundWithdrawal')}</span>
                      <span
                        className="bg-[#E6F5FB] text-themePrimary rounded-xl px-3 flex justify-center items-center text-xs "
                        style={{ color: props.color.color1 }}
                      >
                        {props.data?.disbursement?.length}
                      </span>
                    </div>
                    <div className="flex gap-2 text-xs font-light">
                      <span className="flex gap-1 items-center">
                        {t('zakat.lastfundWithdrawal')}
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </span>
                      <span>{props.getLastDate(props.data?.disbursement)}</span>
                    </div>
                  </div>
                  <img src={arrowRightIcon} alt="" />
                </div>
                {/* TRANSACTION */}
                <div
                  className="flex justify-between py-4 border-solid border-b-[.3px] border-[aaa]"
                  onClick={() =>
                    props.navigate(
                      `/${props.company?.initial}/donations/zakat/transaction/${props.params.id}`
                    )
                  }
                >
                  <div>
                    <div className="flex gap-2 font-bold">
                      <span className="text-base">Zakat</span>
                      <span
                        className="bg-[#E6F5FB] text-themePrimary rounded-xl px-3 flex justify-center items-center text-xs "
                        style={{ color: props.color.color1 }}
                      >
                        {props.data?.transaction?.length}
                      </span>
                    </div>
                    <div className="flex gap-2 text-xs font-light">
                      <span className="flex gap-1 items-center">
                        {t('zakat.lastZakat')}
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </span>
                      <span>
                        {props.getLastDate(
                          props.data?.transaction,
                          "createdTime"
                        )}
                      </span>
                    </div>
                  </div>
                  <img src={arrowRightIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-2/5 lg:w-1/2  min-w-[30rem]  bottom-0 sticky m-auto font-poppins">
          <div className="bg-white p-4 mt-4 flex flex-col gap-2">
            <button
              onClick={() => {
                props.navigate(
                  `/${props.company?.initial}/donations/zakat/zakat/${props.params.id}`
                );
              }}
              style={{ backgroundColor: props.color.color1 }}
              type="button"
              className="bg-themePrimary text-white w-full h-10 rounded-md"
            >
              {t('zakat.zakatNow')}
            </button>
          </div>
        </div>

        {/* Modal switch */}
        <div
          className={`${props.showModal ? "block" : "hidden"} relative z-50`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
                <div className="text-end md:px-[0px] px-[0.5rem]">
                  <button
                    type="button"
                    onClick={() => {
                      props.setShowModal(false);
                    }}
                    className="fa-xl text-gray-400"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <div className="text-center mb-2">
                  <div className="px-3 py-4 flex flex-col gap-4 ">
                    <div className="font-semibold ">{t('zakat.background')}</div>
                    <div className="flex flex-row flex-wrap gap-2">
                      {props.data?.description}
                    </div>
                    <div className="flex flex-col gap-2 mt-4">
                      <button
                        onClick={() => {
                          props.setShowModal(false);
                        }}
                        type="button"
                        className="bg-themePrimary text-white w-full h-10 rounded-md"
                      >
                        {t('zakat.close')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
      </section>
    </>
  );
};

export default DonationWeb;
