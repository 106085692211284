import React from "react";
import QRCode from "react-qr-code";
import Header from "../../_common/headerComponent";
import { BottomSheet } from "react-spring-bottom-sheet";
import Html5QrcodePlugin from "../../_common/Html5QRCodePlugin";
import QueueComponent from "../../_common/queueComponent";
import ProgressBar from "../../../assets/images/icon_queue/progress_bar.png";

const QueueNumberWeb = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [openTab, setOpenTab] = React.useState(1);
  // const [code, setCode] = React.useState("");

  const onNewScanResult = (decodedText: any) => {
    // setCode(decodedText);
  };

  const ScanQRCode = () => {
    return (
      <div className="md:w-2/3 w-full mx-auto custom-scan-barcode">
        <Html5QrcodePlugin
          fps={30}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
      </div>
    );
  };

  const CreateQRCode = () => {
    return (
      <React.Fragment>
        <div className="bg-[#FAFBFD] rounded-[15px] max-w-[260px] mx-auto p-4">
          <QRCode style={{ height: "100%", width: "100%" }} value="Zx0988" />
        </div>
        <p className="text-xl font-medium text-customBlack text-center mt-4">
          Arahkan kamera hingga memuat seluruh barcode.
        </p>
      </React.Fragment>
    );
  };

  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <Header />
      <div className="md:w-1/2 w-full mx-auto md:mt-12 mt-[0px] px-4 pb-28">
        <div className="max-w-[150px] mx-auto">
          <QRCode style={{ height: "100%", width: "100%" }} value="Zx0988" />
        </div>
        <h6 className="md:text-lg text-base text-green font-bold text-center my-4">
          Zx0988
        </h6>
        <div className="grid grid-cols-3 items-center border-b-[1px] border-newColor divide-x-[0.5px] divide-newColor pb-2">
          <div className="text-center px-2">
            <h6 className="md:text-sm text-xs text-newColor">Nama</h6>
            <h6 className="md:text-base text-sm text-newColor font-semibold">
              Ulfatun Amanah
            </h6>
          </div>
          <div className="text-center px-2">
            <h6 className="md:text-sm text-xs text-newColor">Hari</h6>
            <h6 className="md:text-base text-sm text-newColor font-semibold">
              Senin, 10-2-2023
            </h6>
          </div>
          <div className="text-center px-2">
            <h6 className="md:text-sm text-xs text-newColor">Layanan</h6>
            <h6 className="md:text-base text-sm text-newColor font-semibold">
              Cek Mata
            </h6>
          </div>
        </div>
        <h6 className="md:text-base text-sm text-customBlack text-center my-3">
          Antrian
        </h6>
        <QueueComponent current="A-10" remainder="13" />
        <div className="text-center mt-4">
          <div className="relative">
            <img src={ProgressBar} alt="" className="mx-auto w-[155px]" />
            <div
              className="absolute left-1/2 top-1/2"
              style={{ transform: "translate(-50%, -50%)" }}
            >
              <h6 className="md:text-sm text-xs text-newColor">No Anda</h6>
              <h2 className="text-green font-bold text-[32px]">A-18</h2>
            </div>
          </div>
          <h6 className="md:text-lg text-base text-customBlack font-light mt-5">
            Estimasi Waktu :
            <span className="text-green font-semibold">10.15 WIB</span>
          </h6>
          <p className="md:text-lg text-base text-newColor">
            Harap Datang 15 menit sebelum waktu estimasi yang ditentukan
          </p>
        </div>
      </div>
      <div
        className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 px-4"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
        >
          Check in
        </button>
      </div>

      <BottomSheet open={isOpen} onDismiss={() => setIsOpen(false)}>
        <div className="mx-4 my-3 font-montserrat">
          <ul className="md:w-1/2 w-2/3 flex gap-3 p-1 mb-4 bg-gradient-to-r from-themePrimary to-themePrimary rounded-lg mx-auto">
            <li className="flex w-full">
              <button
                onClick={() => setOpenTab(1)}
                className={` ${
                  openTab === 1
                    ? "bg-white text-themePrimary"
                    : "bg-transparent text-white"
                } inline-block px-2 py-2 rounded-lg w-full md:text-base text-sm`}
              >
                Scan
              </button>
            </li>
            <li className="flex w-full">
              <button
                onClick={() => setOpenTab(2)}
                className={` ${
                  openTab === 2
                    ? "bg-white text-themePrimary"
                    : "bg-transparent text-white"
                } inline-block px-2 py-2 rounded-lg w-full md:text-base text-sm`}
              >
                QR Code
              </button>
            </li>
          </ul>
          <div className="md:mt-6 mt-[0px] w-full mb-3">
            <div className={openTab === 1 ? "block" : "hidden"}>
              <ScanQRCode />
            </div>

            {openTab === 2 && (
              <div className={openTab === 2 ? "block" : "hidden"}>
                <CreateQRCode />
              </div>
            )}
          </div>
        </div>
      </BottomSheet>
    </section>
  );
};

export default QueueNumberWeb;
