import _ from "lodash";
import React, { Fragment, useState } from "react";
import locationIcon from "../../../assets/images/icon_rent/location.svg";
import { BottomSheet } from "react-spring-bottom-sheet";

export default function DetailProduct(props: any) {
  const [show, setShow] = useState("description");

  const list = [
    { value: "description", label: "Deskripsi" },
    { value: "address", label: "Alamat" },
    { value: "schedule", label: "Jadwal" },
    { value: "quota", label: "Kuota" },
  ];

  return (
    <div>
      <div className="flex justify-between">
        {_.map(list, (el: any, index: any) => (
          <div
            key={index}
            className={`${
              show === el.value
                ? "text-[#3867E0] border-b border-[#3867E0]"
                : "text-[#646464]"
            } font-[500] py-2 px-2 cursor-pointer`}
            onClick={() => setShow(el.value)}
          >
            {el.label}
          </div>
        ))}
      </div>
      <div className="relative overflow-hidden min-h-screen mt-2 text-base">
        <div
          className={`absolute inset-0 transition-opacity duration-500 ease-in-out transform ${
            show === "description"
              ? "opacity-100 translate-x-0"
              : "opacity-0 -translate-x-full"
          }`}
        >
          {show === "description" && <div>{props.data.description}</div>}
        </div>
        <div
          className={`absolute inset-0 transition-opacity duration-500 ease-in-out transform ${
            show === "address"
              ? "opacity-100 translate-x-0"
              : "opacity-0 -translate-x-full"
          }`}
        >
          {show === "address" && (
            <div className="flex gap-2">
              <img src={locationIcon} alt="" />
              {props.data.address}
            </div>
          )}
        </div>
        <div
          className={`absolute inset-0 transition-opacity duration-500 ease-in-out transform ${
            show === "schedule"
              ? "opacity-100 translate-x-0"
              : "opacity-0 -translate-x-full"
          }`}
        >
          {show === "schedule" && <div>{props.data.schedule}</div>}
        </div>
        <div
          className={`absolute inset-0 transition-opacity duration-500 ease-in-out transform ${
            show === "quota"
              ? "opacity-100 translate-x-0"
              : "opacity-0 -translate-x-full"
          }`}
        >
          {show === "quota" && (
            <div>
              <span>Kuota :</span> <span>{props.data.quota}</span>
            </div>
          )}
        </div>
   
      </div>
    
    </div>
  );
}
