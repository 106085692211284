import React from "react";
import Countdown from "react-countdown";
import CopyLogo from "../../../assets/images/copy_icon.svg";
import { formatCurrency, formatDateTimeUTC } from "../../../config/global";
import { BalanceCard } from "../../_common/BalanceCard";
import Header from "../../_common/headerComponent";
import { useTranslation } from "react-i18next";
import Collapse from "../../_common/Collapse";
import Information from "../../_common/information";

const Icon = (data: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        data.id === data.open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

const IconDetail = (data: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        data.open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

const TopUpDetailWeb = (props: any) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>
      <div className="container flex flex-col gap-5 justify-center mt-[0px] px-4">
        <div className="md:w-3/5 w-full mx-auto mb-10">
          <div className="md:mb-6">
            <Information message="Minimal nominal top up saldo adalah Rp 10.000" />
          </div>
          <BalanceCard />
          <div className="w-full">
            <div className="bg-white border-[1px] border-[#E9E9E9] rounded-md">
              <div className="flex items-center gap-3 p-3">
                <img
                  src={props.bankData?.img}
                  alt=""
                  className="max-w-[100px]"
                />
                <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">
                  {props.bankData?.bank?.replaceAll("_TBG", " ")}{" "}
                  <span className="italic text-xs">
                    {props.isProvider ? " (Provider)" : ""}
                  </span>
                </h6>
              </div>
              <hr className="my-0" />
              {props.isCloseVA ? (
                <div className="flex flex-col gap-2 p-3 divide-y">
                  <div>
                    <h6 className="md:text-sm text-xs text-customBlack font-normal mb-0">
                      {t("topup.nominalPayment")}
                    </h6>
                    <p className="md:text-xl text-lg text-green font-medium mb-0">
                      {formatCurrency(props.data.vaAmount)}
                    </p>
                  </div>
                  <div className="flex items-center gap-3 pt-2">
                    <div>
                      <h6 className="md:text-sm text-xs text-customBlack font-normal mb-0">
                        Virtual Account
                      </h6>
                      <p className="md:text-xl text-lg text-customBlack font-medium mb-0">
                        {props.vANumber}
                      </p>
                    </div>
                    <div className="cursor-pointer" onClick={props.copyVa}>
                      <img src={CopyLogo} alt="" />
                    </div>
                  </div>
                  <div className="pt-2">
                    <h6 className="md:text-sm text-xs text-customBlack font-normal mb-0">
                      {t("topup.payBefore")}
                    </h6>
                    <Countdown
                      key={props.timer}
                      date={props.timer}
                      renderer={props.renderer}
                    />
                    <p className="md:text-sm text-xs text-newColor font-normal mb-0">
                      {t("topup.dueDate")}{" "}
                      {formatDateTimeUTC(props.data.expiredDate)}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-3 p-3">
                  <div>
                    <h6 className="md:text-base text-sm text-customBlack font-semibold mb-0">
                      Virtual Account
                    </h6>
                    <p className="md:text-base text-sm text-green font-semibold mb-0">
                      {props.vANumber}
                    </p>
                  </div>
                  <div className="cursor-pointer" onClick={props.copyVa}>
                    <img src={CopyLogo} alt="" />
                  </div>
                </div>
              )}
            </div>
            {props.isCloseVA && (
              <div className="bg-white border-[1px] border-[#E9E9E9] rounded-md mt-3">
                <div className="space-between p-3">
                  <h6 className="md:text-base text-sm text-customBlack font-semibold m-0">
                    {t("topup.detailPayment")}
                  </h6>
                  <button
                    type="button"
                    onClick={() => setShowDetail(!showDetail)}
                  >
                    <IconDetail open={showDetail} />
                  </button>
                </div>
                {showDetail && (
                  <React.Fragment>
                    <hr className="my-0" />
                    <div className="flex flex-col items-center gap-2 p-3">
                      <div className="space-between w-full gap-3">
                        <h6 className="md:text-base text-sm text-customBlack font-normal mb-0">
                          Nominal
                        </h6>
                        <p className="md:text-base text-sm text-customBlack font-medium mb-0">
                          {formatCurrency(props.data.total)}
                        </p>
                      </div>
                      <div className="space-between w-full gap-3">
                        <h6 className="md:text-base text-sm text-customBlack font-normal mb-0">
                          {t("topup.adminFee")}
                        </h6>
                        <p className="md:text-base text-sm text-customBlack font-medium mb-0">
                          {formatCurrency(props.data.totalCharges)}
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
            {!props.isCloseVA && (
              <div className="bg-white shadow-sm rounded-md mb-4 mt-4">
                <div className="space-between p-3">
                  <h6 className="md:text-base text-sm text-customBlack font-semibold m-0">
                    Informasi Top Up
                  </h6>
                  <button
                    type="button"
                    onClick={() => props.setOpenDetail(!props.openDetail)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`${
                        props.openDetail ? "rotate-180" : ""
                      } h-5 w-5 transition-transform`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                </div>
                <Collapse open={props.openDetail}>
                  <div className="p-3 text-xs text-justify space-y-2">
                    <span>
                      Saldo yang masuk ke aplikasi akan dikurangi dengan biaya
                      admin virtual account sebesar{" "}
                      {formatCurrency(props?.charge?.nominal)} dari nominal top
                      up yang dilakukan.
                    </span>
                    <div className="p-2 bg-[#FAE7A3] rounded-[8px]">
                      (Contoh : Nominal top up Rp 10.000, maka saldo yang akan
                      masuk ke aplikasi adalah Rp 10.000-
                      {formatCurrency(props?.charge?.nominal)})
                    </div>
                  </div>
                </Collapse>
              </div>
            )}
            {props.paymentInstructions
              .filter((i: any) => i.type === props.type)
              .map((el: any) => (
                <div className="bg-white shadow-sm rounded-md mb-4 mt-4">
                  <div className="space-between p-3">
                    <h6 className="md:text-base text-sm text-customBlack font-semibold m-0">
                      {el.title}
                    </h6>
                    <button type="button" onClick={() => props.handleOpen(1)}>
                      <Icon id={1} open={props.open} />
                    </button>
                  </div>
                  <Collapse open={props.open === 1}>
                    <div className="p-3 bg-[#F2F2F2]">
                      <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">
                        {t("topup.instructions")}
                      </h6>
                      <hr className="my-2" />
                      {el.child.map((el2: any) => (
                        <React.Fragment key={el2.id}>
                          <div className="flex items-center">
                            <div className="w-[10%]">
                              <div className="bg-green text-sm text-white rounded-full w-7 h-7 flex items-center justify-center">
                                {el2.id}
                              </div>
                            </div>
                            <div className="w-[90%]">
                              <p className="text-customBlack text-sm mb-0">
                                {el2.detail}
                              </p>
                            </div>
                          </div>
                          <hr className="my-2" />
                        </React.Fragment>
                      ))}
                    </div>
                  </Collapse>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopUpDetailWeb;
