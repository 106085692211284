/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { BrowserView, MobileView } from "react-device-detect";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppSelector } from "../../core/feature/hooks";
import User from "../../core/models/user";
import * as API_ABSENSI from "../../core/service/api_absensi.js";
import ParkingFeaturesMobile from "./parkingFeaturesMobile";
import ParkingFeaturesWeb from "./parkingFeaturesWeb";
import Company from "../../core/models/company";
import { useTranslation } from "react-i18next";

const ParkingFeatures = () => {
  const [modalQR, setModalQR] = useState(false);
  const [valueQR, setValueQR] = useState("");
  const [timer, setTimer] = useState(300000);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const [filter, setFilter] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [parkingStatus, setParkingStatus] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);

  const [config, setConfig] = useState<any>("");
  const [active, setActive] = useState<any>("");
  const showQR = (value: any) => {
    setValueQR(value);
    setModalQR(true);
  };

  const renderer = (data: any) => {
    return (
      <div className="font-medium md:text-base text-sm mt-3 flex flex-col w-full">
        <span className="md:text-lg text-base text-newColor">
          {t("accessibility.timeLimit")}
        </span>
        <span
          className={`${
            data.completed ? "text-newColor" : "text-themeDanger"
          } font-semibold md:text-2xl text-xl`}
        >
          {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
        </span>
        <button
          className={`${
            data.completed
              ? "bg-green text-white"
              : "bg-[#CCCCCC] text-newColor"
          } font-medium md:text-base text-sm mt-3 w-full py-2 rounded-[10px]`}
          type="button"
          onClick={() => {
            regenerateQR();
          }}
        >
          {t("accessibility.regenerate")}
        </button>
      </div>
    );
  };

  const regenerateQR = () => {
    setTimer(Date.now() + 300000);
    let newData = {
      companyId: company.id,
      companyInitial: company.initial,
      email: user.email,
      userId: user._id,
      userType: user.userType,
    };
    API_ABSENSI.scanQrCode(newData)
      .then((res) => {
        setValueQR(res.data.data.qrId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createQR = () => {
    let newData = {
      companyId: company.id,
      companyInitial: company.initial,
      email: user.email,
      userId: user._id,
      userType: user.userType,
    };
    API_ABSENSI.scanQrCode(newData)
      .then((res) => {
        showQR(res.data.data.qrId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToDetail = (data: any) => {
    navigate("detail", {
      state: {
        data: data,
      },
    });
  };

  const addMore = async () => {
    setPage(page + 1);
    getData(page + 1, parkingStatus);
  };

  function currentSize() {
    let a = data.length;
    let b = a / page;

    return b;
  }

  const getData = async (
    currentPage: number = page,
    currentParkingStatus: string = parkingStatus
  ) => {
    let query = {
      companyId: "",
      companyInitial: "",
      email: "",
      userType: "",
      userId: "",
      loginTimeStart: "",
      loginTimeEnd: "",
      size: 10,
      page: currentPage,
      sortBy: "-createdDate",
      parkingStatus: currentParkingStatus,
    };
    dispatch(setLoading(true));
    await API_ABSENSI.listParking(query)
      .then((res) => {
        if (currentPage > 1) {
          setData(data.concat(res.data.data));
        } else {
          setData(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
        setSize(res.data.paging.size);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const filterDate = () => {
    getData(1, parkingStatus);
  };

  const resetFilter = async () => {
    setFilter(false);
    setParkingStatus("");
    getData(1, "");
  };

  const closeModal = () => {
    resetFilter();
    setPage(1);
  };

  const getStatusQrCode = async (value: any) => {
    let query = {
      companyId: "",
      companyInitial: "",
      email: "",
      userType: "",
      userId: "",
    };
    // dispatch(setLoading(true));
    await API_ABSENSI.getStatusQrAbsen(value, query)
      .then((res) => {
        if (res.data.data.status === "VERIFIED") {
          // setVisible(true);
          navigate("result");
          localStorage.setItem("resultParking", JSON.stringify(res));
        }
      })
      .catch((err) => {
        localStorage.removeItem("resultParking");
        dispatch(setLoading(false));
      });
  };

  const getConfig = () => {
    dispatch(setLoading(true));
    API_ABSENSI.getSubcriptionConfig()
      .then((res) => {
        dispatch(setLoading(false));
        setConfig(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getActive = () => {
    dispatch(setLoading(true));
    API_ABSENSI.SubcriptionActive()
      .then((res) => {
        dispatch(setLoading(false));
        setActive(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getData();
    getConfig();
    getActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set interval and store the interval ID
    const loop = setInterval(() => {
      if (!valueQR) return;
      getStatusQrCode(valueQR);
    }, 5000);
    setIntervalId(loop);

    // Clean up function to clear interval when component unmounts
    return () => clearInterval(loop);
  }, [valueQR]);

  useEffect(() => {
    setTimer(Date.now() + 300000);
  }, []);

  return (
    <>
      {/* Modal switch */}
      <div
        className={`${modalQR ? "block" : "hidden"} relative z-50`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="text-end md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setModalQR(false);
                    clearInterval(intervalId);
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="text-center mb-2">
                <div className="px-3 py-4 flex flex-col gap-4 items-center">
                  <span>{t("accessibility.message_scan_qr_parking")}</span>
                  <QRCode
                    style={{ height: "50%", width: "50%" }}
                    value={valueQR}
                  />
                  <Countdown key={timer} date={timer} renderer={renderer} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
      <BrowserView>
        <ParkingFeaturesWeb
          data={data}
          resetFilter={resetFilter}
          filterDate={filterDate}
          currentSize={currentSize}
          addMore={addMore}
          closeModal={closeModal}
          filter={filter}
          parkingStatus={parkingStatus}
          totalElements={totalElements}
          size={size}
          page={page}
          showQR={(value: any) => showQR(value)}
          goToDetail={(value: any) => goToDetail(value)}
          setPage={(value: any) => setPage(value)}
          setFilter={(value: any) => setFilter(value)}
          setParkingStatus={(value: any) => setParkingStatus(value)}
          createQR={() => createQR()}
          config={config}
          active={active}
        />
      </BrowserView>
      <MobileView>
        <ParkingFeaturesMobile
          data={data}
          resetFilter={resetFilter}
          filterDate={filterDate}
          currentSize={currentSize}
          addMore={addMore}
          closeModal={closeModal}
          filter={filter}
          parkingStatus={parkingStatus}
          totalElements={totalElements}
          size={size}
          page={page}
          showQR={(value: any) => showQR(value)}
          goToDetail={(value: any) => goToDetail(value)}
          setPage={(value: any) => setPage(value)}
          setFilter={(value: any) => setFilter(value)}
          setParkingStatus={(value: any) => setParkingStatus(value)}
          createQR={() => createQR()}
          config={config}
          active={active}
        />
      </MobileView>
    </>
  );
};

export default ParkingFeatures;
