import { BrowserView, MobileView } from "react-device-detect";
import AbsenResultWeb from "./absenResultWeb";
import AbsenResultMobile from "./absenResultMobile";

const AbsenResult = () => {
  return (
    <div>
      <BrowserView>
        <AbsenResultWeb />
      </BrowserView>
      <MobileView>
        <AbsenResultMobile />
      </MobileView>
    </div>
  );
};

export default AbsenResult;
