import Config from "../../config/Config";
import ErrorHandler from "./errorHandler";
import { bearer } from "../../config/global";

const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getCompany = (initial) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/info/company/get/${initial}`, {
        headers: {},
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        ErrorHandler(err);
        reject(err);
      });
  });
};

export const getHomeConfig = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}user/account/member/menu/member_home?companyId=${companyId}`,
        {
          headers: {},
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPinConfig = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/account/member/has_security_code`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getBalanceConfig = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}user/account/member/menu/member_saldo?companyId=${companyId}`,
        {
          headers: {},
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProfile = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/account/member/profile`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changeSecurityCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}user/account/member/security_code`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateProfile = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}user/account/member/profile`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const selfRegister = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}user/account/member/register`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createSecurityCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}user/account/member/security_code`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgetSecurityCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}user/account/member/security_code/reset`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgetPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}user/account/member/forgot_password`, data, {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgetPasswordWa = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}user/account/member/forgot_password_wa`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changePassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}user/account/member/change_password`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changePasswordFirstLogin = (data) => {
  const token = sessionStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}user/account/member/change_password_first_login`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMetaDataSelfRegister = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}user/info/company/metadata/get?companyId=${companyId}&userType=MEMBER`,
        {}
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const PostMetaDataSelfRegister = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}user/account/member/register`, data, {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadPhotoProfile = (image) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("profilePicture", image);
    axios
      .post(
        `${baseUrlDev}user/account/member/change_profile_picture`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMetaDataTags = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/info/company/tags/register/${companyId}`, {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//

export const profileMenuName = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/account/member/menu/member?companyId=${query}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const balanceMenuName = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}user/account/member/menu/member_saldo?companyId=${query}`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboMerchantByid = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}user/account/member/combo/merchant_noid?noId=${query}`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMerchantByNoId = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/account/member/get_merchant/noid?noId=${query}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const comboMemberByid = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/account/member/combo/member_noid?noId=${query}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMemberByNoId = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/account/member/get_member/noid?noId=${query}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Custom Menu

export const getConfigCustomMenu = (companyId, sortby) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}user/account/member/menu/custom_menu?companyId=${companyId}&sortby=${sortby}`,
        {
          headers: {},
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Veirification

export const verificationEmail = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}user/account/member/change_email`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verificationPhone = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}user/account/member/change_phone_whatsapp`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// add data

export const addPhone = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}user/account/member/change_email`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDeviceFirebase = (data) => {
  let query = {
    registrationToken: data,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}user/account/member/update_firebase_web`, query, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDeviceFirebase = () => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrlDev}user/account/member/logout_firebase_web`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPasswordStrength = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}user/info/company/get_password_strength/${companyId}`,
        {
          headers: {},
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

