import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPLNSuccessWeb from "./ppobPLNSuccessWeb";
import PPOBPLNSuccessMobile from "./ppobPLNSuccessMobile";

const PPOBPLNSuccess = () => {
  return (
    <>
      <BrowserView>
        <PPOBPLNSuccessWeb></PPOBPLNSuccessWeb>
      </BrowserView>
      <MobileView>
        <PPOBPLNSuccessMobile></PPOBPLNSuccessMobile>
      </MobileView>
    </>
  );
};

export default PPOBPLNSuccess;
