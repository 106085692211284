/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Company from "../../core/models/company";
import Header from '../_common/headerComponent';
import { useAppDispatch, useAppSelector } from '../../core/feature/hooks';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { onlyNumber } from '../../config/global';
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { setLoading } from '../../core/feature/config/configSlice';
import { useTranslation } from 'react-i18next';

const PPOBInternetTVWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [internetTVList, setInternetTVList] = useState<any[]>([]);
  const [number, setNumber] = useState("");
  const [code, setCode] = useState("");
  const [service, setService] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [errorService, setErrorService] = useState("");
  const [showModal, setShowModal] = useState(false);

  const getInternetTvList = async () => {
    dispatch(setLoading(true));
    await API_DIGIPROD.internetTvList()
      .then((res) => {
        dispatch(setLoading(false));
        setInternetTVList(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      })
  }

  const handleOnClick = () => {
    if (service === "") {
      setErrorService(t('internet_and_tv.choose_product_type'));
    } else if (number === "") {
      setErrorNumber(t('internet_and_tv.enter_number_id'));
    } else {
      let query = {
        internetTvNumber: number,
        internetTvCode: code,
      }
      dispatch(setLoading(true));
      API_DIGIPROD.trxInternetTvInquiry(query)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(
            `/${company?.initial}/digital-product/internet-tv/summary`, {
            state: {
              data: res.data.data
            }
          }
          )
        })
        .catch((err) => {
          dispatch(setLoading(false));
        })
    }
  }

  useEffect(() => {
    getInternetTvList();
  }, [])

  return (
    <section className="h-full min-h-screen font-poppins bg-mobile">
      <Header></Header>

      {/* MODAL */}
      <div className={`${showModal ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="space-between md:px-[0px] px-[0.5rem]">
                <h4 className="md:text-base text-sm text-customBlack font-medium mb-0">{t('internet_and_tv.choose_service')}</h4>
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setCode("");
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <hr />
              <ul className="px-3 mb-0">
                {internetTVList.map((item, index) => (
                  <li
                    className="list-none cursor-pointer hover:bg-teal-50"
                    key={index}
                    onClick={() => {
                      setCode(item.code);
                      setService(item.name);
                      setErrorService("");
                      setShowModal(false);
                    }}
                  >
                    <div className="flex flex-row justify-between items-center px-2 pt-2">
                      <p className="md:text-base text-sm font-semibold mb-0 text-start">{item.name}</p>
                    </div>
                    <hr className="mt-2" />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* ---- */}

      <div className="w-full md:w-2/3 lg:w-1/2 mx-auto flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 font-montserrat">
        <div className="flex flex-col items-center gap-3  md:mb-10 mb-6">
          <div className="text-start w-full">
            <div className="relative">
              <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                {t('internet_and_tv.choose_service_tv')}
              </label>
              <input
                defaultValue={service}
                readOnly
                onClick={() => setShowModal(true)}
                className="bg-[#fff] border-[0.5px] border-[#9A9999] w-full p-2.5 md:text-base text-sm rounded-xl focus:outline"
              />
              <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                <button type="button" onClick={() => setShowModal(true)}>
                  <FontAwesomeIcon icon={faCaretDown} size="1x" />
                </button>
              </div>
            </div>
            <p className="text-sm text-red pl-1 mb-0 font-montserrat">
              {errorService}
            </p>
          </div>
          <div className="text-start w-full">
            <div className="relative">
              <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                {t('internet_and_tv.number_user')}
              </label>
              <input
                type="number"
                value={number}
                onKeyPress={onlyNumber}
                onChange={(e) => {
                  setNumber(e.target.value);
                  setErrorNumber("");
                }}
                className="bg-[#fff] border-[0.5px] border-[#9A9999] w-full p-2.5 md:text-base text-sm rounded-xl focus:outline"
              />
              <div className="absolute md:top-[47px] top-[40px] right-4">
                <button
                  type="button"
                  className="bg-gray-500 flex justify-center rounded-[50%] px-[4px] py-[2px] text-white"
                  onClick={() => {
                    setNumber("");
                    setErrorNumber("");
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
            <p className="text-sm text-red pl-1 mb-0 font-montserrat">
              {errorNumber}
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 w-full md:w-2/3 lg:w-1/2 left-1/2 px-4" style={{ transform: "translate(-50%, -50%)" }}>
          <button
            type="button"
            style={{ background: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
            className="bg-green text-white font-medium text-base rounded-[10px] w-full py-[10px] font-montserrat"
            onClick={() => handleOnClick()}
          >
            {t('internet_and_tv.next')}
          </button>
        </div>
      </div>
    </section>
  )
}

export default PPOBInternetTVWeb;