/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import RentBookingMobile from "./rentBookingMobile";
import RentBookingWeb from "./rentBookingWeb";

const RentBooking = () => {
  React.useEffect(() => {}, []);



  return (
    <>
      <BrowserView>
        <RentBookingWeb />
      </BrowserView>
      <MobileView>
        <RentBookingMobile/>
      </MobileView>

      {/* Modal */}

      {/* --- */}
    </>
  );
};

export default RentBooking;
