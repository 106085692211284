import React, { FC, useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import Swal from "sweetalert2";
import * as API from "../../core/service/api.js";
import * as API_USER from "../../core/service/api_user.js";
import * as API_AUTH from "../../core/service/api_auth.js";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppSelector, useAppDispatch } from "../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
import icon_failed from "../../assets/images/icon-failed.png";
import Company from "../../core/models/company";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { setToken } from "../../core/feature/token/tokenSlice";
import Token from "../../core/models/token.js";
import { findIndex } from "lodash";
import { setUser } from "../../core/feature/user/userSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";
import UserLogin from "../../core/models/userLogin";
import { removeOtp } from "../../core/feature/otp/otpSlice";
import { BrowserView, MobileView } from "react-device-detect";
import { StatefulPinInput } from "react-input-pin-code";
import { censorEmail } from "../../config/global.js";
import { firebaseSupported } from "../../firebase.js";
import ConfirmationModal from "./confirmationModal";

interface OtpComponentProps {
  showInputOtp: boolean;
  onHide: any;
  transactionType: string;
  width: number;
  height: number;
  expOtp: number;
  email?: string;
  margin: string;
  submit?: (token: any) => any;
  id?: any;
  redirect?: any;
  deviceId?: string;
  message?: string;
  data?: {};
  companyId?: any;
  username?: string;
  password?: string;
  stateEnd?: string;
}

const OtpComponent: FC<OtpComponentProps> = (props): JSX.Element => {
  const company: Company = useAppSelector((state) => state.company.company);
  const otpUrl: string = useAppSelector((state) => state.otp.url);
  const destinationType: string = useAppSelector(
    (state) => state.destination.destinationType
  );
  const otpNavigateTo: string = useAppSelector((state) => state.otp.navigateTo);
  const otpData: any = useAppSelector((state) => state.otp.data);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState(0);
  const [data, setData] = useState<any>({});
  const [NewValues, setNewValues] = React.useState(["", "", "", "", "", ""]);
  const [first, setFirst] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const hideModal = () => setShowModal(false);

  const requestOtp = async () => {
    dispatch(setLoading(true));

    if (props.transactionType === "SELF_REGISTER") {
      let query = {
        destinationOtp: props.email || data.email,
        companyId: company?.id,
        userType: "MEMBER",
        name: "",
        otpType: "SELF_REGISTER",
      };
      await API_AUTH.requestOtp(query)
        .then((res) => {
          dispatch(setLoading(false));
          toast.success("Kode OTP telah dikirim ke email Anda");
          setTimer(Date.now() + res.data.data.expiredMillisecond);
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    } else if (props.transactionType === "NewDevice") {
      let query = {
        username: props.username,
        password: props.password,
        companyId: props.companyId,
        deviceId: props.deviceId,
        state: props.stateEnd,
      };
      await API.login(query, "")
        .then((res) => {
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (err.response?.status === 403) {
            setTimer(Date.now() + 300000);
          }
        });
    } else if (props.transactionType === "ADD_BANK_ACCOUNT") {
      let query = {
        transactionType: "ADD_BANK_ACCOUNT",
        otpDestinationType: destinationType,
      };
      await API_AUTH.requestOtpMember(query)
        .then((res) => {
          dispatch(setLoading(false));
          setTimer(Date.now() + res.data.data.expiredMillisecond);
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <button
          className="text-[#26A69A]"
          type="button"
          onClick={() => requestOtp()}
        >
          Kirim Ulang
        </button>
      );
    } else {
      return (
        <span className="text-[14px] text-center my-2">
          <span>Kirim ulang OTP dalam</span>
          <span className="text-[#FF9723] font-semibold ml-2">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };

  const reauthenticate = async () => {
    dispatch(setLoading(true));
    if (props.transactionType === "NewDevice") {
      let query = {
        username: props.username,
        password: props.password,
        companyId: props.companyId,
        deviceId: props.deviceId,
        state: props.stateEnd,
      };

      API.login(query, NewValues.join(""))
        .then((res) => {
          var newToken = token ? [...token] : [];
          let indexToken = findIndex(token, { cid: company.initial });

          if (indexToken < 0) {
            newToken.push({
              cid: company.initial,
              token: res.data.data.authToken,
            });
          } else {
            newToken[indexToken] = {
              cid: company.initial,
              token: res.data.data.authToken,
            };
          }

          var newUserLogin = userLogin?.length > 0 ? [...userLogin] : [];
          let indexUserLogin = findIndex(newUserLogin, {
            id: res.data.data.noId,
          });

          if (indexUserLogin < 0) {
            newUserLogin.push({
              token: res.data.data.authToken,
              id: res.data.data.noId,
            });
          } else {
            newUserLogin[indexUserLogin] = {
              token: res.data.data.authToken,
              id: res.data.data.noId,
            };
          }

          dispatch(setUserLogin(newUserLogin));
          setNewValues(["", "", "", "", "", ""]);
          if (res.data.data.firstLogin) {
            dispatch(setToken(newToken));
            props.onHide();
            navigate(`/${company?.initial}/create-password`);
          } else {
            dispatch(setUser(res.data.data));
            dispatch(setToken(newToken));
            if (props.redirect) {
              props.onHide();
              console.log("redirect");
              navigate(props.redirect);
            } else {
              console.log("no redirect");
              props.onHide();
              navigate(`/${company?.initial}`);
              firebaseSupported();
            }
          }
          dispatch(removeOtp());
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          setNewValues(["", "", "", "", "", ""]);
          if (err.response.data.detail === "Kode OTP suspended") {
            props.onHide();
            Swal.fire({
              title:
                "Percobaan login melebihi batas, kode OTP telah tersuspend. Silakan login kembali.",
              imageUrl: icon_failed,
              imageAlt: "Custom image",
              imageWidth: 220,
              imageHeight: 220,
              confirmButtonColor: "#00D19F",
              confirmButtonText: "Konfirmasi",
            }).then((result) => {
              if (result.isConfirmed) {
                setNewValues(["", "", "", "", "", ""]);
                props.onHide();
                navigate(`/${company.initial}/login`, {
                  state: {},
                });
                dispatch(removeOtp());
              }
            });
          }
          if (err.response?.data?.detail?.type === "OTP_NOT_FOUND") {
            setNewValues(["", "", "", "", "", ""]);
            props.onHide();
          }
        });
    } else if (props.transactionType === "SELF_REGISTER") {
      console.log(data);
      API_USER.selfRegister(data)
        .then((res) => {
          dispatch(setLoading(false));
          props.onHide();
          if (props.redirect) navigate(props.redirect);
        })
        .catch((err) => {
          dispatch(setLoading(false));
          if (err.response.data.detail.type === "OTP_NOT_FOUND") {
            props.onHide();
            setNewValues(["", "", "", "", "", ""]);
          } else if (err.response.data.detail === "Kode OTP suspended") {
            props.onHide();
            Swal.fire({
              title:
                "Percobaan melebihi batas, kode OTP telah tersuspend. Silakan ulangi kembali.",
              imageUrl: icon_failed,
              imageAlt: "Custom image",
              imageWidth: 220,
              imageHeight: 220,
              confirmButtonColor: "#00D19F",
              confirmButtonText: "Konfirmasi",
            }).then((result) => {
              if (result.isConfirmed) {
                setNewValues(["", "", "", "", "", ""]);
                props.onHide();
                // navigate(`/${company.initial}/login`, {
                //   state: {},
                // });
                dispatch(removeOtp());
              }
            });
          } else {
            props.onHide();
            setNewValues(["", "", "", "", "", ""]);
            dispatch(removeOtp());
          }
        });
    } else if (props.transactionType === "ADD_BANK_ACCOUNT") {
      API_AUTH.sendOtp(otpUrl, otpData, NewValues.join(""))
        .then((res: any) => {
          setTimeout(() => {
            dispatch(setLoading(false));
            props.onHide();
            setNewValues(["", "", "", "", "", ""]);
            setFirst(true);
            navigate(`${otpNavigateTo}`, {
              replace: true,
              state: res.data.data,
            });
            dispatch(removeOtp());
          }, 6000);
        })
        .catch((err: any) => {
          setNewValues(["", "", "", "", "", ""]);
          setFirst(true);
          dispatch(setLoading(false));
          props.onHide();
          if (
            err.response?.data?.detail?.type ===
            "MAX_BANK_ACCOUNT_COUNTS_REACHED"
          ) {
            dispatch(removeOtp());
            navigate(`${company?.initial}/transfer/bank`, {
              replace: true,
            });
          }
        });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    reauthenticate();
  };

  useEffect(() => {
    setNewValues(["", "", "", "", "", ""]);
    if (first) setFirst(false);
    if (props.showInputOtp) {
      setTimer(Date.now() + props.expOtp);
      if (props.transactionType === "SELF_REGISTER") {
        requestOtp();
      }
    }
    if (props.data) setData({ ...props.data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showInputOtp]);

  return (
    <div
      className={`${props.showInputOtp ? "block" : "hidden"} relative z-10`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* <ConfirmationModal
        showModal={showModal}
        hideModal={hideModal}
        confirm={reauthenticate}
      /> */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div
        className={`${
          showModal ? "z-0" : "z-10"
        } fixed inset-0 overflow-y-auto`}
      >
        <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]"
          >
            <div className="flex flex-row justify-end w-100 mb-3 md:px-[0px] px-[0.5rem]">
              <button
                type="button"
                onClick={() => {
                  props.onHide();
                  setNewValues(["", "", "", "", "", ""]);
                  setFirst(true);
                }}
                className="fa-xl text-gray-400"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <p className="md:text-2xl text-sm text-black md:mb-4">
              Masukan kode OTP yang telah dikirimkan ke&nbsp;
              <span className="font-bold">
                {censorEmail(props.email) || censorEmail(data.email)}{" "}
              </span>
              &nbsp;dengan subjek&nbsp;
              <span className="font-bold">{props.message}</span>
            </p>
            <BrowserView>
              <StatefulPinInput
                key={first ? "kosong" : "isi"}
                length={6}
                placeholder=""
                initialValue={NewValues}
                onChange={(value, index, values) => {
                  setNewValues(values);
                  data["otp"] = values.join("");
                }}
                type="number"
                mask={true}
                focusBorderColor="#26A69A"
                validBorderColor="#26A69A"
              />
            </BrowserView>
            <MobileView>
              <StatefulPinInput
                key={first ? "kosong" : "isi"}
                length={6}
                placeholder=""
                initialValue={NewValues}
                onChange={(value, index, values) => {
                  setNewValues(values);
                  data["otp"] = values.join("");
                }}
                type="number"
                mask={true}
                focusBorderColor="#26A69A"
                validBorderColor="#26A69A"
              />
            </MobileView>
            <Countdown key={timer} date={timer} renderer={renderer} />
            <button
              disabled={NewValues.join("").length < 6}
              onClick={handleSubmit}
              className={`${
                NewValues.join("").length < 6 ? "bg-gray-500" : "bg-[#26A69A]"
              } w-full md:py-[16px] p-[10px] rounded-full text-white font-semibold text-base disabled:bg-slate-50`}
            >
              Konfirmasi
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OtpComponent;
