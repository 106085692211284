import { BrowserView, MobileView } from "react-device-detect";
import FeaturesMobile from "./featuresMobile";
import FeaturesWeb from "./featuresWeb";

const Features = () => {
  return (
    <>
      <BrowserView>
        <FeaturesWeb />
      </BrowserView>
      <MobileView>
        <FeaturesMobile />
      </MobileView>
    </>
  );
};

export default Features;
