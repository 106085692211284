/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import Effect from "../../assets/images/icon_balance/Effects.svg";
import Logo from "../../assets/images/icon_balance/solusiNegeriLogo.svg";
import NoProfile from "../../assets/images/no_profile.png";
import { formatCurrency } from "../../config/global";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import User from "../../core/models/user";
import noProfile from "../../assets/images/icon_balance/noProfile.svg";

interface BalanceCardProps {
  name: string;
  code?: string;
  amount?: any;
  backgroundColor?: string;
  backgroundColor2?: string;
  logo?: string;
  noId?: string;
}

export const BalanceCardV2: FC<BalanceCardProps> = (props): JSX.Element => {
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const [data, setData] = useState<any>({});

  const [configColor, setConfigColor] = useState<any>({});
  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined && company?.color?.color1 !== ""
        ? company?.color?.color1
        : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined && company?.color?.color2 !== ""
        ? company?.color?.color2
        : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };
  console.log(company);

  useEffect(() => {
    getConfigColor();
    setData(props);
  }, [props]);

  return (
    <>
      <div
        style={{
          backgroundImage: `${
            data.backgroundColor
              ? `linear-gradient(to right, ${data.backgroundColor}, ${data.backgroundColor2})`
              : `linear-gradient(to right, ${configColor?.color1}, ${configColor?.color2})`
          }`,
        }}
        className="relative w-full items-end rounded-[20px] overflow-hidden p-8 md:w-96 mx-auto h-52 md:h-60 flex flex-col justify-between"
      >
        <img
          src={Effect}
          className="absolute md:top-10 top-14 left-0 right-0 bottom-0"
          alt=""
        />
        <div className="bg-[#181818] bg-opacity-[26%] capitalize z-5 px-3 py-2 text-[10px] font-semibold text-white font-montserrat absolute top-0 right-0 rounded-bl-xl">
          {data.name}
        </div>
        <div className="w-full">
          <div className="rounded-md">
            <img
              src={user.profileImage ? user.profileImage : noProfile}
              alt=""
              className="md:h-[116px] h-[76px] md:w-[116px] w-[76px] rounded-[12px]"
            />
          </div>
        </div>
        <div className="flex justify-between w-full mt-4 ">
          <div className="flex flex-col">
            {data.code === "LINK_AJA" ? (
              <img src={`data: image/jpeg;base64, ${data.amount}`} alt="" />
            ) : (
              <h6 className="md:text-base  text-[#F4F4F4] text-base font-semibold mb-0">
                {formatCurrency(data.amount)}
              </h6>
            )}
            <h6 className="md:text-base  text-[#F4F4F4] text-base font-semibold mb-0">
              {data.noId}
            </h6>
          </div>
          {company?.showFtpLogo && (
            <img src={data.logo || Logo} alt="" className="w-10 h-10" />
          )}
        </div>
      </div>
    </>
  );
};
