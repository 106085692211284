import { BrowserView, MobileView } from "react-device-detect";
import AbsenFeaturesWeb from "./absenFeaturesWeb";
import AbsenFeaturesMobile from "./absenFeturesMobile";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as API_ABSENSI from "../../core/service/api_absensi.js";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import { ConfigColor } from "../../config/global";

const AbsenFeatures = () => {
  // const user: User = useAppSelector((state) => state.user.user);
  const color = ConfigColor();
  const [filter, setFilter] = useState(false);
  const [openMethod, setOpenMethod] = useState(false);
  const navigate = useNavigate();
  // const [methode, setMethode] = useState("login");
  const [selectedMethode, setSelectedMethode] = useState("barcode");
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [startDate2, setStartDate2] = useState<any>(null);
  const [endDate] = useState<any>(null);
  const [endDate2] = useState<any>(null);
  const [newStartDate, setNewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");
  const [act, setAct] = useState(false);
  const [page, setPage] = useState(1);
  const [size] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [activity, setAcitivity] = useState<any[]>([]);
  const [pageA, setPageA] = useState(1);
  const [totalElementsA, setTotalElementsA] = useState(0);
  const [dataAct, setDataAct] = useState<any[]>([]);
  // const [rule, setRule] = useState<any>([]);
  const [selectedAct, setSelectedAct] = useState<any>();
  const [name] = useState(localStorage.getItem("configF")?.toString());
  const [confirm, setConfirm] = useState(false);

  const pilihMethode = async (data: any) => {
    if (selectedMethode === "qr") {
      navigate("scan-qr");
    } else {
      navigate("barcode", {
        state: { data: data },
      });
      localStorage.removeItem("result");
    }
  };

  const resetMethode = () => {
    setSelectedMethode("qr");
    setOpenMethod(false);
    setPageA(1);
    scanBarcodeRadio(1);
  };

  const goToDetail = (data: any) => {
    navigate("detail", {
      state: { data: data },
    });
  };

  const filterDate = () => {
    getListAbsensi(1, newStartDate, newEndDate);
  };

  const resetFilter = async () => {
    setFilter(false);
    setNewStartDate("");
    setNewEndDate("");
    setStartDate(null);
    setStartDate2(null);
    getListAbsensi(1, "", "");
  };

  const getListAbsensi = async (
    currentPage: number = page,
    attendanceTimeStart: any = newStartDate,
    attendanceTimeEnd: any = newEndDate
  ) => {
    let query = {
      companyId: "",
      companyInitial: "",
      email: "",
      userType: "",
      userId: "",
      attendanceTimeStart,
      attendanceTimeEnd,
      size: size,
      page: currentPage,
      sortBy: "-createdDate",
    };
    dispatch(setLoading(true));
    await API_ABSENSI.listAttendance(query)
      .then((res) => {
        if (currentPage > 1) {
          setData(data.concat(res.data.data));
        } else {
          setData(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const scanBarcodeRadio = async (currentPage: number = pageA) => {
    setSelectedMethode("barcode");
    setAct(true);
    setConfirm(true);
    // dispatch(setLoading(true));
    // await API_ABSENSI.listActivity(currentPage)
    //   .then((res) => {
    //     if (currentPage > 1) {
    //       setAcitivity(activity.concat(res.data.data));
    //     } else {
    //       setAcitivity(res.data.data);
    //     }
    //     setTotalElementsA(res.data.paging.totalElements);
    //     dispatch(setLoading(false));
    //   })
    //   .catch((err) => {
    //     dispatch(setLoading(false));
    //   });
  };

  const scanQrRadio = () => {
    setSelectedMethode("qr");
    setAct(false);
    setConfirm(true);
  };

  function currentSize() {
    let a = data.length;
    let b = a / page;

    return b;
  }

  function currentSizeActivity() {
    let a = activity.length;
    let b = a / pageA;

    return b;
  }

  const addMoreAct = async () => {
    setPageA(pageA + 1);
    scanBarcodeRadio(pageA + 1);
  };

  const addMore = async () => {
    setPage(page + 1);
    getListAbsensi(page + 1, newStartDate, newEndDate);
  };

  const closeModal = () => {
    resetFilter();
    setPage(1);
  };

  useEffect(() => {
    getListAbsensi();
    localStorage.removeItem("result");
    localStorage.removeItem("resultEror");
    sessionStorage.removeItem("scanCounter");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BrowserView>
        <AbsenFeaturesWeb
          color={color}
          filter={filter}
          setFilter={(value: any) => setFilter(value)}
          openMethod={openMethod}
          setOpenMethod={(value: any) => setOpenMethod(value)}
          navigate={navigate}
          selectedMethode={selectedMethode}
          setSelectedMethode={(value: any) => setSelectedMethode(value)}
          dispatch={dispatch}
          data={data}
          setData={(value: any) => setData(value)}
          startDate={startDate}
          setStartDate={(value: any) => setStartDate(value)}
          startDate2={startDate2}
          setStartDate2={(value: any) => setStartDate2(value)}
          endDate={endDate}
          endDate2={endDate2}
          newStartDate={newStartDate}
          setNewStartDate={(value: any) => setNewStartDate(value)}
          newEndDate={newEndDate}
          setNewEndDate={(value: any) => setNewEndDate(value)}
          act={act}
          setAct={(value: any) => setAct(value)}
          page={page}
          setPage={(value: any) => setPage(value)}
          size={size}
          totalElements={totalElements}
          setTotalElements={(value: any) => setTotalElements(value)}
          activity={activity}
          setAcitivity={(value: any) => setAcitivity(value)}
          pageA={pageA}
          setPageA={(value: any) => setPageA(value)}
          totalElementsA={totalElementsA}
          setTotalElementsA={(value: any) => setTotalElementsA(value)}
          dataAct={dataAct}
          setDataAct={(value: any) => setDataAct(value)}
          selectedAct={selectedAct}
          setSelectedAct={(value: any) => setSelectedAct(value)}
          confirm={confirm}
          setConfirm={(value: any) => setConfirm(value)}
          pilihMethode={pilihMethode}
          resetMethode={resetMethode}
          goToDetail={goToDetail}
          filterDate={filterDate}
          resetFilter={resetFilter}
          getListAbsensi={getListAbsensi}
          scanBarcodeRadio={scanBarcodeRadio}
          scanQrRadio={scanQrRadio}
          currentSize={currentSize}
          currentSizeActivity={currentSizeActivity}
          addMoreAct={addMoreAct}
          addMore={addMore}
          closeModal={closeModal}
          name={name}
        />
      </BrowserView>
      <MobileView>
        <AbsenFeaturesMobile
          color={color}
          filter={filter}
          setFilter={(value: any) => setFilter(value)}
          openMethod={openMethod}
          setOpenMethod={(value: any) => setOpenMethod(value)}
          navigate={navigate}
          selectedMethode={selectedMethode}
          setSelectedMethode={(value: any) => setSelectedMethode(value)}
          dispatch={dispatch}
          data={data}
          setData={(value: any) => setData(value)}
          startDate={startDate}
          setStartDate={(value: any) => setStartDate(value)}
          startDate2={startDate2}
          setStartDate2={(value: any) => setStartDate2(value)}
          endDate={endDate}
          endDate2={endDate2}
          newStartDate={newStartDate}
          setNewStartDate={(value: any) => setNewStartDate(value)}
          newEndDate={newEndDate}
          setNewEndDate={(value: any) => setNewEndDate(value)}
          act={act}
          setAct={(value: any) => setAct(value)}
          page={page}
          setPage={(value: any) => setPage(value)}
          size={size}
          totalElements={totalElements}
          setTotalElements={(value: any) => setTotalElements(value)}
          activity={activity}
          setAcitivity={(value: any) => setAcitivity(value)}
          pageA={pageA}
          setPageA={(value: any) => setPageA(value)}
          totalElementsA={totalElementsA}
          setTotalElementsA={(value: any) => setTotalElementsA(value)}
          dataAct={dataAct}
          setDataAct={(value: any) => setDataAct(value)}
          selectedAct={selectedAct}
          setSelectedAct={(value: any) => setSelectedAct(value)}
          confirm={confirm}
          setConfirm={(value: any) => setConfirm(value)}
          pilihMethode={pilihMethode}
          resetMethode={resetMethode}
          goToDetail={goToDetail}
          filterDate={filterDate}
          resetFilter={resetFilter}
          getListAbsensi={getListAbsensi}
          scanBarcodeRadio={scanBarcodeRadio}
          scanQrRadio={scanQrRadio}
          currentSize={currentSize}
          currentSizeActivity={currentSizeActivity}
          addMoreAct={addMoreAct}
          addMore={addMore}
          closeModal={closeModal}
          name={name}
        />
      </MobileView>
    </>
  );
};

export default AbsenFeatures;
