/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import MerchantDetailMobile from "./merchantDetailMobile";
import MerchantDetailWeb from "./merchantDetailWeb";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useParams } from "react-router-dom";
import User, { MarketplaceConfig } from "../../../core/models/user";
import Company from "../../../core/models/company";
import Token from "../../../core/models/token";
import { find } from "lodash";
import moment from "moment";
import { formatHour } from "../../../config/global";
import { BottomSheet } from "react-spring-bottom-sheet";
import { TimeZone } from "../../../config/global";
import { useTranslation } from "react-i18next";

const MerchantDetail = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});
  const marketplaceConfig: MarketplaceConfig = useAppSelector(
    (state) => state.marketplaceConfig.marketplaceConfig
  );
  const [products, setProducts] = useState<any[]>([]);
  const [workHour, setWorkHour] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [dir, setDir] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [openHour, setOpenHour] = useState("");
  const [closed, setClosed] = useState("");
  const [mNameNoLogin, setMNameNoLogin] = useState("");
  const [pNameNoLogin, setPNameNoLogin] = useState("");
  const dispatch = useAppDispatch();
  const params = useParams();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [isLogin, setIsLogin] = React.useState(user && newToken);

  const daysOfWeek = [
    t('merchants.monday'),
    t('merchants.tuesday'),
    t('merchants.wednesday'),
    t('merchants.thursday'),
    t('merchants.friday'),
    t('merchants.saturday'),
    t('merchants.sunday'),
  ];
  var today = moment();
  const time = TimeZone();

  const getShopDetail = async () => {
    dispatch(setLoading(true));
    let query = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    await API_COMMERCE.getShopDetail(params.id, query)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        getAllProducts();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getShopDetailNoLogin = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.getShopDetailNoLogin(company.id, params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        getAllProductsNoLogin();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getAllProducts = async (currentPage: number = page) => {
    let query = {
      timezone: time,
      page: currentPage,
      size: 10,
      sortBy: dir === 1 ? "-createdDate" : "createdDate",
      merchantId: params.id,
    };
    dispatch(setLoading(true));
    await API_COMMERCE.getAllProducts(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setProducts(products.concat(res.data.data));
        } else {
          setProducts(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getAllProductsNoLogin = async (currentPage: number = page) => {
    let query = {
      timezone: time,
      page: currentPage,
      size: 10,
      sortBy: dir === 1 ? "-createdDate" : "createdDate",
      merchantId: params.id,
    };
    dispatch(setLoading(true));
    await API_COMMERCE.productNoLogin(company.id, query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setProducts(products.concat(res.data.data));
        } else {
          setProducts(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getMnameConfigNoLogin = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.marketplaceNameNoLogin(company.id)
      .then((res) => {
        if (Object.keys(res.data.data).length === 0) {
          setMNameNoLogin("Toko");
          setPNameNoLogin("Produk");
        } else {
          if (
            res.data.data.merchantName === "" ||
            res.data.data.merchantName === null
          ) {
            setMNameNoLogin("Toko");
          } else {
            setMNameNoLogin(res.data.data.merchantName);
          }

          if (
            res.data.data.productName === "" ||
            res.data.data.merchantName === null
          ) {
            setPNameNoLogin("Produk");
          } else {
            setPNameNoLogin(res.data.data.productName);
          }
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const getMerchantWorkHour = async () => {
    dispatch(setLoading(true));
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await API_COMMERCE.getComboMerchantWorkHour(params.id, timezone)
      .then((res) => {
        dispatch(setLoading(false));
        setWorkHour(res.data.data.dayList);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  function currentSize() {
    let a = products.length;
    let b = a / page;

    return b;
  }

  const addMore = async () => {
    setPage(page + 1);
    getAllProducts(page + 1);
  };

  const getOpenHour = () => {
    let tempOpenDays = [];
    for (let index = new Date().getDay(); index < workHour.length; index++) {
      if (workHour[index]?.isOpen && workHour[index]?.timeSlots.length > 0) {
        tempOpenDays.push(workHour[index]);
      }
    }
    console.log(tempOpenDays);
    if (tempOpenDays.length > 0) {
      setOpenHour(formatHour(tempOpenDays[0]?.timeSlots[0]?.startTime));
      console.log(formatHour(tempOpenDays[0]?.timeSlots[0]?.startTime));
    }
  };

  React.useEffect(() => {
    console.log(data);
    console.log(workHour);
    if (data.isOpen === false) {
      // TOKO TUTUP
      let findDay = workHour.find(
        (el: any) => el.dayOfWeek === new Date().getDay()
      );
      console.log(findDay);
      if (findDay !== undefined) {
        if (findDay?.timeSlots.length > 1) {
          let tempOpenHour = [];
          for (let index = 0; index < findDay?.timeSlots?.length; index++) {
            const isOpen = today.isBefore(
              moment(formatHour(findDay?.timeSlots[index]?.startTime), "hh:mm")
            );
            if (isOpen)
              tempOpenHour.push(
                formatHour(findDay?.timeSlots[index].startTime)
              );
            console.log(isOpen);
          }
          if (tempOpenHour.length > 0) {
            console.log(tempOpenHour);
            setOpenHour(tempOpenHour[0]);
          } else {
            getOpenHour();
          }
        } else {
          getOpenHour();
        }
      }
    } else {
      // TOKO BUKA
      let findDay = workHour.find(
        (el: any) => el.dayOfWeek === new Date().getDay()
      );
      console.log(findDay);
      if (findDay !== undefined) {
        if (findDay?.timeSlots.length > 1) {
          // TIMESLOTS LEBIH DARI SATU
          let tempCloseHour = [];
          for (let index = 0; index < findDay?.timeSlots.length; index++) {
            const isOpen = today.isAfter(
              moment(formatHour(findDay?.timeSlots[index]?.endTime), "hh:mm")
            );
            if (isOpen === false)
              tempCloseHour.push(formatHour(findDay?.timeSlots[index].endTime));
            console.log(isOpen);
          }
          setClosed("Tutup " + tempCloseHour[0]);
          console.log(tempCloseHour);
        } else if(findDay?.timeSlots.length === 1) {
          // TIMESLOTS HANYA SATU
          setClosed("Tutup " + formatHour(findDay?.timeSlots[0]?.endTime));
          console.log(findDay?.timeSlots[0]?.endTime);
        } else {
          // TIMESLOTS KOSONG
          setClosed("Buka 24 Jam");
        }
      } else {
      }
    }
  }, [data, workHour]);

  const getMerchantWorkHourNoLogin = async () => {
    dispatch(setLoading(true));
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await API_COMMERCE.cekOperationalTimeNoLogin(params.id, timezone)
      .then((res) => {
        dispatch(setLoading(false));
        setWorkHour(res.data.data.dayList);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    if (isLogin) {
      getMerchantWorkHour();
      getShopDetail();
    } else {
      getShopDetailNoLogin();
      getMerchantWorkHourNoLogin();
    }
    getMnameConfigNoLogin();
    setIsLogin(user && newToken);
  }, []);
  
  return (
    <>
      <BrowserView>
        <MerchantDetailWeb
          getShopDetail={() => getShopDetail()}
          getShopDetailNoLogin={() => getShopDetailNoLogin()}
          getAllProducts={(currentPage: number) => getAllProducts(currentPage)}
          getAllProductsNoLogin={(currentPage: number) => getAllProductsNoLogin(currentPage)}
          addMore={() => addMore()}
          currentSize={currentSize}
          data={data}
          marketplaceConfig={marketplaceConfig}
          products={products}
          totalElements={totalElements}
          workHour={workHour}
          dir={dir}
          showModal={showModal}
          showModal2={showModal2}
          openHour={openHour}
          closed={closed}
          mNameNoLogin={mNameNoLogin}
          pNameNoLogin={pNameNoLogin}
          daysOfWeek={daysOfWeek}
          isLogin={isLogin}
          setDir={(value: any) => setDir(value)}
          setShowModal={(value: any) => setShowModal(value)}
          setPage={(value: any) => setPage(value)}
          setShowModal2={(value: any) => setShowModal2(value)}
          setShowDescription={(value: any) => setShowDescription(value)}
        />
      </BrowserView>
      <MobileView>
        <MerchantDetailMobile
          getShopDetail={() => getShopDetail()}
          getShopDetailNoLogin={() => getShopDetailNoLogin()}
          getAllProducts={(currentPage: number) => getAllProducts(currentPage)}
          getAllProductsNoLogin={(currentPage: number) => getAllProductsNoLogin(currentPage)}
          addMore={() => addMore()}
          currentSize={currentSize}
          data={data}
          marketplaceConfig={marketplaceConfig}
          products={products}
          totalElements={totalElements}
          workHour={workHour}
          dir={dir}
          showModal={showModal}
          showModal2={showModal2}
          openHour={openHour}
          closed={closed}
          mNameNoLogin={mNameNoLogin}
          pNameNoLogin={pNameNoLogin}
          daysOfWeek={daysOfWeek}
          isLogin={isLogin}
          setDir={(value: any) => setDir(value)}
          setShowModal={(value: any) => setShowModal(value)}
          setPage={(value: any) => setPage(value)}
          setShowModal2={(value: any) => setShowModal2(value)}
          setShowDescription={(value: any) => setShowDescription(value)}
        />
      </MobileView>

      {/* DESCRIPTION MODAL */}
      <BottomSheet
        open={showDescription}
        onDismiss={() => setShowDescription(false)}
        className="relative z-50 font-montserrat"
      >
        <div className="mx-4 mb-4">
          <h6 className="md:text-base text-sm text-customBlack font-medium border-b-[1px] border-b-[#CCCCCC] mb-0 pb-2">
            Deskripsi
          </h6>
          <p className="md:text-sm text-xs text-thirdy font-light mt-2 mb-0">
            {data.merchantDescription !== undefined
              ? data.merchantDescription
              : "-"}
          </p>
        </div>
      </BottomSheet>
    </>
  );
};

export default MerchantDetail;
