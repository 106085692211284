import React from "react";
import Header from "../_common/headerComponent";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PPOBPulsaDataMobile = () => {
  const { t } = useTranslation();

  const data = [
    {
      id: "1",
      name: t('topUp.prepaid'),
      desc: t('topUp.prepaidDesc'),
      url: "prabayar",
    },
    {
      id: "2",
      name: t('topUp.postpaid'),
      desc: t('topUp.postpaidDesc'),
      url: "pascabayar",
    },
  ];

  return (
    <>
      <section className="h-full min-h-screen font-montserrat bg-mobile">
        <Header navbarText={t('ppobHistory.pulse_and_data')}></Header>
        <div className="container flex md:flex-row flex-col-reverse gap-5 justify-center md:mt-[64px] mt-[0px]">
          <div className="w-full md:w-2/3 lg:w-1/2">
            <h3 className="md:text-lg text-base text-customBlack text-start font-medium mb-[1rem]">{t('topUp.title')}</h3>
            {data.map((item) => (
              <Link to={item.url} key={item.id} className="no-underline">
                <div className="flex items-center gap-3 bg-white rounded-xl md:px-[1rem] px-[24px] py-3 md:mb-10 mb-6 shadow-sm">
                  <div className="text-start w-full">
                    <h3 className="md:text-lg text-base font-bold text-customBlack mb-[3px]">{item.name}</h3>
                    <p className="md:text-base text-sm font-normal text-customBlack mb-0">{item.desc}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default PPOBPulsaDataMobile;
