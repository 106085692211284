/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ListVirtualCardWeb from "./listVirtualCardWeb";
import ListVirtualCardMobile from "./listVirtualCardMobile";
import { useNavigate } from "react-router-dom";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import { setLoading } from "../../../core/feature/config/configSlice";
import Company from "../../../core/models/company";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ListVirtualCard = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [cards, setCards] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [customButton, setCustomButton] = useState<any>({});
  const [cardLimit, setCardLimit] = useState(0);
  const [background, setBackground] = useState("");
  const [page, setPage] = useState(1);
  const [isPortrait, setIsPortrait] = useState(false);

  const getCards = async (currentPage: number = page) => {
    dispatch(setLoading(true));
    let query = {
      page: currentPage,
    };
    await API_VIRTUAL_CARD.getCards(query)
      .then(async (res) => {
        dispatch(setLoading(false));
        setCards(cards.concat(res.data.data));
        let tempDatas: any = [];
        if (res.data.data.length > 0) {
          res.data.data.map((el: any) =>
            tempDatas.push({
              template: el.template,
              cardId: el._id,
            })
          );
        }
        setData(data.concat(tempDatas));

        if (res.data.total / res.data.page > 10) {
          setPage(res.data.page + 1);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getConfigCard = async () => {
    await API_VIRTUAL_CARD.getConfigCard()
      .then((res2) => {
        dispatch(setLoading(false));
        setCardLimit(res2.data.data.maxUserCardNumber);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getConfigButtonOrder = async () => {
    await API_VIRTUAL_CARD.getConfigButtonOrder()
      .then((res) => {
        if (res.data.data.isActived) setCustomButton(res.data.data);
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const getTemplate = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.getTemplate()
      .then((res) => {
        setBackground(res.data.background);
        setIsPortrait(res.data.isPotrait);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = () => {
    if (data.length === cardLimit) {
      toast.error(
        `${t('virtual_card.limit_card_member')} ${cardLimit}`
      );
    } else {
      navigate(`/${company.initial}/virtual-card/add`);
    }
  };

  useEffect(() => {
    getCards();
    getConfigCard();
    getTemplate();
    getConfigButtonOrder();
  }, []);

  useEffect(() => {
    getCards(page);
  }, [page]);

  return (
    <>
      <BrowserView>
        <ListVirtualCardWeb
          handleOnClick={() => handleOnClick()}
          data={data}
          cards={cards}
          customButton={customButton}
          cardLimit={cardLimit}
          background={background}
          company={company}
          isPortrait={isPortrait}
        />
      </BrowserView>
      <MobileView>
        <ListVirtualCardMobile
          handleOnClick={() => handleOnClick()}
          data={data}
          cards={cards}
          customButton={customButton}
          cardLimit={cardLimit}
          background={background}
          company={company}
          isPortrait={isPortrait}
        />
      </MobileView>
    </>
  );
};

export default ListVirtualCard;
