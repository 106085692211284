/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PaymentSummaryWeb from "./paymentSummaryWeb";
import PaymentSummaryMobile from "./paymentSummaryMobile";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../core/feature/hooks";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import { setLoading } from "../../../core/feature/config/configSlice";
import { formatDateTimeUTC } from "../../../config/global";
import { useTranslation } from "react-i18next";

const PaymentSummary = () => {
  let dispatch = useAppDispatch();
  let params = useParams();
  const { t } = useTranslation();
  const [dataPost, setDataPost] = useState<any[]>([]);
  const [cardPrice, setCardPrice] = useState("");

  const getDetail = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.getCreateCardTransactionDetail(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        let tempData = res.data.data;
        let detailPrice = 0;
        tempData.detailPrice.map((item: any) => (detailPrice += item.amount));
        setCardPrice(detailPrice.toString());
        let newData = [
          {
            property: t('virtual_card.transaction_id'),
            value: tempData._id,
          },
          {
            property: t('virtual_card.date'),
            value: formatDateTimeUTC(tempData.createdTime),
          },
          {
            property: t('virtual_card.member_name'),
            value: tempData.user.name,
          },
          {
            property: t('virtual_card.payment_type'),
            value: t('virtual_card.virtual_card_creation'),
          },
          {
            property: t('virtual_card.card_name'),
            value: tempData.cardName,
          },
          {
            property: t('virtual_card.type_card'),
            value:
              tempData.detailPrice.length > 1
                ? t('virtual_card.identity_cards_transactions')
                : t('virtual_card.identity_card'),
          },
          {
            property: t('virtual_card.paymentMethod'),
            value: t('virtual_card.balance_closeloop'),
          },
          {
            property: t('virtual_card.price_card'),
            value: tempData.amount,
          },
        ];
        setDataPost(newData);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <BrowserView>
        <PaymentSummaryWeb
          dataPost={dataPost}
          cardPrice={cardPrice}
        />
      </BrowserView>
      <MobileView>
        <PaymentSummaryMobile
          dataPost={dataPost}
          cardPrice={cardPrice}
        />
      </MobileView>
    </>
  );
};

export default PaymentSummary;
