/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, Fragment } from "react";
import dataNotFound from "../../assets/images/data_not_found.svg";
import addressNotFound from "../../assets/images/alamatNotFound.png";
import invoiceNotFound from "../../assets/images/invoices_not_found.svg";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
import { useTranslation } from "react-i18next";

interface DataNotFoundProps {
  title?: string;
  desc?: string;
  type?: string;
  handleOnClick?: any;
}

const DataNotFound: FC<DataNotFoundProps> = (props): JSX.Element => {
  const company: Company = useAppSelector((state) => state.company.company);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center my-3">
      {props.type === "address" && (
        <div className="bg-white text-center p-2">
          <img src={addressNotFound} alt="" width={400} />
          <h6 className="md:text-2xl text-xl text-[#252525] font-semibold mb-0">
            {t('create_order.empty_address')}
          </h6>
          <h6 className="text-base text-thirdy font-semibold mb-0">
            {t('create_order.desc_address')}
          </h6>
          <button
            type="button"
            onClick={props.handleOnClick}
            style={{ backgroundColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
            className="w-full text-white p-[12px] rounded-[10px] mt-[21px]"
          >
            {t('create_order.add')}
          </button>
        </div>
      )}

      {props.type === "invoice" && (
        <Fragment>
          <img src={invoiceNotFound} alt="" width={175} />
          <h6 className="md:text-base text-sm text-[#00D19F] font-semibold mb-0">
            {props.desc || t('create_order.no_data_description_invoice')}
          </h6>
        </Fragment>
      )}

      {props.type === "donation" && (
        <Fragment>
          <img src={invoiceNotFound} alt="" width={175} />
          <h6 className="md:text-base text-sm text-[#00D19F] font-semibold mb-0">
            {props.desc || t('create_order.no_data_description_invoice')}
          </h6>
        </Fragment>
      )}

      {props.type !== "invoice" && props.type !== "address" && (
        <Fragment>
          <img src={dataNotFound} alt="" width={400} />
          <h6 className="md:text-2xl text-xl text-[#252525] font-semibold mb-0">
            {props.title}
          </h6>
          <h6 className="text-base text-thirdy font-semibold mb-0">
            {props.desc}
          </h6>
        </Fragment>
      )}
    </div>
  );
};

export default DataNotFound;
