/* eslint-disable react-hooks/exhaustive-deps */
import { findIndex } from "lodash";
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ConfigColor } from "../../config/global";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import {
  checkoutInvoice,
  getDetailInvoice
} from "../../core/service/api_invoice";
import InvoicePaymentMobile from "./invoicePaymentMobile";
import InvoicePaymentWeb from "./invoicePaymentWeb";
import { useTranslation } from "react-i18next";

const InvoicePayment = () => {
  const color = ConfigColor();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const [isPayAll, setIsPayAll] = React.useState(false);
  const [isPartial, setIsPartial] = React.useState(true);
  const [inquiry, setInquiry] = React.useState(false);
  const [data, setdata] = React.useState<any>();
  const [inquiryData, setInquiryData] = React.useState<any>();
  const [createdBy, setcreatedBy] = React.useState("");
  const [amountPayment, setAmountPayment] = React.useState("");
  const params = useParams();
  const query = useLocation();
  const { t } = useTranslation()
  let redirectCustom = window.location.hostname;

  const getData = () => {
    dispatch(setLoading(true))
    let state: any = query.state;
    setcreatedBy(state.createdBy);
    getDetailInvoice(params.id, state.createdBy.toLowerCase())
      .then((res) => {
        dispatch(setLoading(false))
        setdata({ ...res.data.data });
        if (!res.data.data?.config?.allowPartialPayment) {
          setAmountPayment(res.data.data.final);
          setIsPartial(res.data.data?.config?.allowPartialPayment);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false))
      });
  };

  const handlePayment = () => {
    if (amountPayment === "") return toast.error(t('donation.nominalRequired'));
    let state: any = query.state;
    if (inquiry) {
      let orderData = {
        amount: amountPayment,
        client: "app_member",
      };
      checkoutInvoice(createdBy.toLowerCase(), data._id, orderData)
        .then((res) => {
          let indexToken = findIndex(token, { cid: company.initial });
          if (
            company.initial === "ITSTOKO" ||
            company.initial === "HSI" ||
            company.initial === "QA"
          ) {
            window.location.href = `https://${res.data.data.checkoutLink}?access_token=${token[indexToken].token}&successRedirectUrl=${redirectCustom}/${company.initial}&backRedirectUrl=${redirectCustom}/${company.initial}`;
          } else {
            window.location.href = `https://${res.data.data.checkoutLink}?access_token=${token[indexToken].token}`;
          }
          navigate(`/${company.initial}?redirect=false`);
        })
        .catch((err) => { });
    } else {
      setInquiryData(data);
      setInquiry(true);
    }
  };

  const handlePayAll = () => {
    if (!inquiry) {
      if (!isPayAll) setAmountPayment(data?.final.toString());
      else setAmountPayment("");
      setIsPayAll(!isPayAll);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <BrowserView>
        <InvoicePaymentWeb
          setAmountPayment={(value: any) => setAmountPayment(value)}
          setInquiry={(value: any) => setInquiry(value)}
          handlePayment={() => handlePayment()}
          handlePayAll={() => handlePayAll()}
          color={color}
          isPayAll={isPayAll}
          isPartial={isPartial}
          inquiry={inquiry}
          data={data}
          inquiryData={inquiryData}
          amountPayment={amountPayment}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <InvoicePaymentMobile
          setAmountPayment={(value: any) => setAmountPayment(value)}
          setInquiry={(value: any) => setInquiry(value)}
          handlePayment={() => handlePayment()}
          handlePayAll={() => handlePayAll()}
          color={color}
          isPayAll={isPayAll}
          isPartial={isPartial}
          inquiry={inquiry}
          data={data}
          inquiryData={inquiryData}
          amountPayment={amountPayment}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default InvoicePayment;
