import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SwitchAccountMobile from "./switchAccountMobile";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
import SwitchAccountWeb from "./switchAccountWeb";

const SwitchAccount = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  console.log(company.layout, "profile");

  return (
    <>
      <BrowserView>
        <SwitchAccountWeb></SwitchAccountWeb>
      </BrowserView>
      <MobileView>
        <SwitchAccountMobile></SwitchAccountMobile>
      </MobileView>
    </>
  );
};

export default SwitchAccount;
