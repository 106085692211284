import Config from "../../config/Config";
import _ from "lodash";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";

const axios = require("axios");
const baseUrlDev = Config.BaseUrlDev;
const bearer = () => {
  let company = JSON.parse(localStorage.getItem("company_redux"));
  let newToken = _.find(JSON.parse(localStorage.getItem("token_redux")), {
    cid: company.initial,
  });
  return newToken.token;
};

export const gatewayAuth = (cid, code) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}gateway/api/auth/member/token/${cid}/${code}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const gatewayIPB = (companyId, token, noId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}gtw_webview/gateway/login_webview/${companyId}/${token}/${noId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
