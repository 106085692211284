import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useParams } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import ProfileIcon from "../../assets/images/Iconly/Bold/Profile.svg";
import { donationCategories } from "../../config/Combo";
import {
  camelCase,
  formatCurrency,
  formatDate,
  imageOnError,
} from "../../config/global.js";
import { getZakatProgress } from "../../core/service/api_zakat";
import DataNotFound from "../_common/dataNotFound";
import Header from "../_common/headerComponent";
import DonationTransactionSkeleton from "../_common/skeleton/DonationtransactionSkeleton";
import CustomTooltip from "../_common/tooltip";
import { useTranslation } from "react-i18next";

const ZakatTransactionWeb = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setsize] = useState(10);
  const [page, setpage] = useState(1);
  const [sortBy, setsortBy] = useState("createdTime");
  const [dir, setdir] = useState(-1);
  const [name] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectCategory, setSelectCategory] = useState("");

  const [categories] = useState([...donationCategories]);

  const changeDate = (item: any) => {
    setSelectedDate([item?.selection]);
    setStartDate(moment(item?.selection.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(item?.selection.endDate).format("YYYY-MM-DD"));
  };
  const resetFilter = () => {
    setdir(-1);
    setSelectCategory("");
    setStartDate("");
    setEndDate("");

    setSelectedDate([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const [data, setdata] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [totalElement, setTotalElement] = useState(0);
  const params = useParams();

  const [openTab, setOpenTab] = useState(1);

  const getDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setdata([]);
    setIsLoading(true);
    getZakatProgress(params.id, query)
      .then((res) => {
        setIsLoading(false);
        setdata(res.data.data.data);
        setpage(res.data.paging.page);
        setsize(res.data.paging.size);
        setTotalElement(res.data.paging.total);
        // setTotalPage(res.paging.totalPage);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getMoreDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setIsLoading(true);
    getZakatProgress(params.id, query)
      .then((res) => {
        setIsLoading(false);
        setdata([...data, ...res.data.data.data]);
        setpage(res.data.paging.page);
        setsize(res.data.paging.size);
        setTotalElement(res.data.paging.totalElement);
        // setTotalPage(res.data.paging.totalPage);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, dir]);

  return (
    <>
      <Header></Header>
      <section className="h-full min-h-screen font-poppins bg-mobile">
        <div className="w-2/5 lg:w-1/2  min-w-[30rem] h-full m-auto font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100">
              {" "}
              <ul className="flex gap-3 w-full p-1 bg-gradient-to-r from-[#1aa6ecdd] to-[#1990CADD] rounded-lg">
                <li className="flex w-full">
                  <button
                    onClick={() => {
                      setOpenTab(1);
                      setdir(-1);
                      setsortBy("createdTime");
                    }}
                    className={` ${openTab === 1
                      ? "bg-white text-[#429AC6]"
                      : "bg-transparent text-white"
                      } inline-block px-2 py-2 rounded-lg w-full`}
                  >
                    {t("zakat.recent")}
                  </button>
                </li>
                <li className="flex w-full">
                  <button
                    onClick={() => {
                      setOpenTab(2);
                      setdir(1);
                      setsortBy("createdTime");
                    }}
                    className={` ${openTab === 2
                      ? "bg-white text-[#429AC6]"
                      : "bg-transparent text-white"
                      } inline-block px-2 py-2 rounded-lg w-full`}
                  >
                    {t("zakat.longest")}
                  </button>
                </li>
                {/* <li className="flex w-full">
                  <button
                    onClick={() => {
                      setOpenTab(2);
                      setsortBy("amount");
                    }}
                    className={` ${
                      openTab === 2
                        ? "bg-white text-[#429AC6]"
                        : "bg-transparent text-white"
                    } inline-block px-2 py-2 rounded-lg w-full`}
                  >
                    Terbesar
                  </button>
                </li> */}
              </ul>{" "}
              <div className="flex flex-col gap-2 my-4">
                {data.filter((el: any) => el.status === "PAID").length === 0 &&
                  !isLoading && <DataNotFound title={t("zakat.noData")} />}
                {_.map(data, (item) => (
                  <div
                    key={item._id}
                    // onClick={() => {
                    //   navigate(
                    //     `/${company?.initial}/donations/donation/transaction/detail/${item._id}`
                    //   );
                    // }}
                    className="flex w-full max-h-[100px] bg-white rounded-md shadow-sm shadow-gray-700 gap-1 p-2"
                  >
                    <div className="w-32 flex justify-center items-center">
                      <div className="flex justify-center items-center rounded-full w-12 h-12 bg-[#D9D9D9]">
                        <img
                          className=" bg-contain w-5 h-5"
                          onError={imageOnError}
                          src={ProfileIcon}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex flex-col flex-grow justify-between">
                      {/* <div>{item?.nameOfUser}</div> */}
                      <CustomTooltip
                        // className={"grow max-w-[calc(100%-7rem)] "}
                        content={
                          <div className="max-w-full whitespace-pre-line">
                            {item?.nameOfUser}
                          </div>
                        }
                        direction="bottom"
                        children={
                          <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                            {item?.nameOfUser}
                          </div>
                        }
                      ></CustomTooltip>
                      <div className="flex gap">
                        <span className="text-[10px] font-light">
                          {t("zakat.zakatAmount")}&nbsp;
                        </span>
                        <span className="text-xs font-semibold">
                          {formatCurrency(item?.amount)}
                        </span>
                      </div>
                      <div className="flex">
                        <span className="text-[10px] font-light">
                          {t("zakat.message")}&nbsp;:&nbsp;
                        </span>
                        <span className="text-xs font-semibold">
                          {item?.message || "-"}
                        </span>
                      </div>
                      <div className="flex">
                        <span className="text-[10px] font-light">
                          {formatDate(
                            new Date(item?.createdTime),
                            "DD MMMM YYYY"
                          )}
                          {/* {moment(new Date(item?.createdTime)).fromNow()} */}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
                {isLoading ? (
                  <>
                    {_.map(new Array(size), (el) => (
                      <DonationTransactionSkeleton></DonationTransactionSkeleton>
                    ))}
                  </>
                ) : null}
                {totalElement > data.length ? (
                  <div
                    className={`bg-themePrimary rounded-md py-1 text-center text-sm text-white font-semibold ${isLoading ? "cursor-progress" : "cursor-pointer"
                      }`}
                    onClick={() =>
                      !isLoading && getMoreDatas(false, size, page + 1)
                    }
                  >
                    {isLoading ? t("zakat.waiting") : "Load More...."}
                  </div>
                ) : null}
              </div>
            </div>
            {/* Modal switch */}
            <div
              className={`${showModal ? "block" : "hidden"} relative z-50`}
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
              <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
                    <div className="text-end md:px-[0px] px-[0.5rem]">
                      <button
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                        }}
                        className="fa-xl text-gray-400"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                    <div className="text-center mb-2">
                      <div className="px-3 py-4 flex flex-col gap-4 ">
                        <div className="font-semibold ">Filter</div>
                        <DateRange
                          displayMode="date"
                          editableDateInputs={true}
                          onChange={(item) => changeDate(item)}
                          moveRangeOnFirstSelection={false}
                          ranges={selectedDate}
                          maxDate={new Date()}
                        />
                        <div className="flex flex-row flex-wrap gap-2">
                          {_.map(categories, (category) => (
                            <div className="flex flex-col w-16  text-center items-center">
                              <div
                                onClick={() => {
                                  setSelectCategory(category.value);
                                }}
                                className={`flex w-12 h-12 rounded-md border-[1px] border-solid ${selectCategory === category.value
                                  ? "border-accentGreen bg-accentDarkGreen"
                                  : "border-[#70717d80] bg-white"
                                  }`}
                              >
                                <img
                                  src={category.icon}
                                  alt=""
                                  className="m-auto"
                                />
                              </div>
                              <span className="text-[10px] text-[#252525] font-light">
                                {camelCase(category.value, "_")}
                              </span>
                            </div>
                          ))}
                        </div>{" "}
                        <div className="flex flex-col gap-2 mt-4">
                          <button
                            onClick={() => {
                              setShowModal(false);
                              getDatas(true);
                            }}
                            type="button"
                            className="bg-themePrimary text-white w-full h-10 rounded-md"
                          >
                            {t("zakat.apply")}
                          </button>
                          <button
                            type="button"
                            className="border-1 border-themeMuted bg-white text-themeMuted w-full h-10 rounded-md"
                            onClick={() => resetFilter()}
                          >
                            {t("zakat.reset")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* --- */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ZakatTransactionWeb;
