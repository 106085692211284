/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import ATMLogo from "../../../assets/images/icon_balance/ATM.svg";
import IconBank from "../../../assets/images/icon_balance/bank.svg";

import MBankingLogo from "../../../assets/images/icon_balance/ibanking.svg";
import { iconBank } from "../../../config/iconBank";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import * as API_BALANCE from "../../../core/service/api_balance";
import SelectBankMobile from "./selectBankMobile";
import SelectBankWeb from "./selectBankWeb";
import { useTranslation } from "react-i18next";

const paymentTools = [
  { name: "ATM", img: ATMLogo, id: 1 },
  { name: "M Banking / I Banking", img: MBankingLogo, id: 2 },
  { name: "Bank Lain", img: IconBank, id: 3 },
];

const SelectBank = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const location: any = useLocation();
  let dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(0);
  const [data, setData] = React.useState<any[]>([]);
  const { t } = useTranslation();

  const instructions = [
    {
      id: 1,
      detail: t("topup.instructions_one"),
    },
    {
      id: 2,
      detail: t("topup.instructions_two"),
    },
    {
      id: 3,
      detail: t("topup.instructions_three"),
    },
    {
      id: 4,
      detail:
        t("topup.instructions_four"),
    },
    {
      id: 5,
      detail:
        t("topup.instructions_five"),
    },
  ];

  const getCombo = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getComboAvailableBanks()
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const handleOnClick = async (bankData: any, type: string, provider: any) => {
    dispatch(setLoading(true));
    let query = {
      amount: location.state.amount,
      balanceCode: "CLOSEPAY",
      bank: bankData.bank,
      provider: provider,
    };
    await API_BALANCE.createVAClose(query)
      .then((res) => {
        dispatch(setLoading(false));
        navigate(`/${company?.initial}/topup/detail`, {
          state: {
            data: res.data.data,
            bankData: { ...bankData, img: iconBank(bankData.bank) },
            type,
            provider: provider,
            isCloseVA: true,
          },
        });
      })
      .catch((err) => dispatch(setLoading(false)));
  };

  React.useEffect(() => {
    getCombo();
  }, []);

  return (
    <>
      <BrowserView>
        <SelectBankWeb
          handleOpen={(value: any) => handleOpen(value)}
          handleOnClick={(bankData: any, type: string, provider: any) =>
            handleOnClick(bankData, type, provider)
          }
          open={open}
          data={data}
          paymentTools={paymentTools}
          instructions={instructions}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <SelectBankMobile
          handleOpen={(value: any) => handleOpen(value)}
          handleOnClick={(bankData: any, type: string, provider: any) =>
            handleOnClick(bankData, type, provider)
          }
          open={open}
          data={data}
          paymentTools={paymentTools}
          instructions={instructions}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default SelectBank;
