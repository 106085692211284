import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PaymentMethodWeb from "./paymentMethodWeb";
import PaymentMethodMobile from "./paymentMethodMobile";

const PaymentMethod = () => {
  return (
    <>
      <BrowserView>
        <PaymentMethodWeb></PaymentMethodWeb>
      </BrowserView>
      <MobileView>
        <PaymentMethodMobile></PaymentMethodMobile>
      </MobileView>
    </>
  );
};

export default PaymentMethod;
