import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import BranchWeb from "./branchWeb";
import BranchMobile from "./branchMobile";

const Branch = () => {
  return (
    <>
      <BrowserView>
        <BranchWeb></BranchWeb>
      </BrowserView>
      <MobileView>
        <BranchMobile></BranchMobile>
      </MobileView>
    </>
  );
};

export default Branch;
