import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TipAndTourWeb from "./tripAndTourWeb";
import TipAndTourMobile from "./tripAndTourMobile";
import * as API from "../../core/service/api_commerce";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";

const TripAndTour = () => {
  const [data, setData] = useState([]);
  const [dataMerchant, setDataMerchant] = useState([]);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [pageMerchant, setPageMerchant] = useState(1);
  const [sizeMerchant, setSizeMerchant] = useState(10);
  const [totalElementsMerchant, setTotalElementsMerchant] = useState(0);
  const [filtername, setFiltername] = useState("");

  const getData = (currentPage = page, name = filtername) => {
    dispatch(setLoading(true));
    let query = {
      page: currentPage,
      size: size,
      productName: name,
      sortBy: "-createdDate",
    };
    API.getTourProducts(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setData(data.concat(res.data.data));
        } else {
          setData(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
  const getDataMerchant = (currentPage = pageMerchant, name = filtername) => {
    dispatch(setLoading(true));
    let query = {
      page: currentPage,
      size: size,
      productName: name,
      sortBy: "-createdDate",
    };
    API.getTourMerchant(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setDataMerchant(dataMerchant.concat(res.data.data));
        } else {
          setDataMerchant(res.data.data);
        }
        setTotalElementsMerchant(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
  
  const addMore = async () => {
    setPage(page + 1);
    getData(page + 1, filtername);
  };

  function currentSize() {
    let a = data.length;
    let b = a / page;

    return b;
  }

  const addMoreMerchant = async () => {
    setPageMerchant(pageMerchant + 1);
    getDataMerchant(pageMerchant + 1, filtername);
  };

  function currentSizeMerchant() {
    let a = dataMerchant.length;
    let b = a / pageMerchant;

    return b;
  }

  const filter = () => {
    setPage(1);
    getData(1, filtername);
  };

  const resetFilter = () => {
    setPage(1);
    setFiltername("");
    getData(1, "");
  };

  useEffect(() => {
    getData();
    getDataMerchant()
  }, []);
  return (
    <>
      <BrowserView>
        <TipAndTourWeb
          data={data}
          filtername={filtername}
          setFiltername={setFiltername}
          addMore={addMore}
          currentSize={currentSize}
          totalElements={totalElements}
          filter={filter}
          resetFilter={resetFilter}
          dataMerchant={dataMerchant}
          addMoreMerchant={addMoreMerchant}
          currentSizeMerchant={currentSizeMerchant}
          totalElementsMerchant={totalElementsMerchant}
        ></TipAndTourWeb>
      </BrowserView>
      <MobileView>
        <TipAndTourMobile
          data={data}
          filtername={filtername}
          setFiltername={setFiltername}
          addMore={addMore}
          currentSize={currentSize}
          totalElements={totalElements}
          filter={filter}
          resetFilter={resetFilter}
          dataMerchant={dataMerchant}
          addMoreMerchant={addMoreMerchant}
          currentSizeMerchant={currentSizeMerchant}
          totalElementsMerchant={totalElementsMerchant}
        />
      </MobileView>
    </>
  );
};

export default TripAndTour;
