/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InvoiceDetailWeb from "./invoiceDetailWeb";
import InvoiceDetailMobile from "./invoiceDetailMobile";
import _ from "lodash";
// import { exportComponentAsPNG } from "react-component-export-image";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../core/feature/hooks";
import User from "../../core/models/user";
import * as API_INVOICE from "../../core/service/api_invoice";
import { getDetailInvoice } from "../../core/service/api_invoice";
import Company from "../../core/models/company";
import Barcode from "qrcode";
import { ConfigColor } from "../../config/global";
import toast from "react-hot-toast";
import ToastConfig from "../../config/Toast";

const InvoiceDetail = () => {
  const color = ConfigColor();
  const [isLoading, setIsLoading] = React.useState(false);
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const componentRef = React.useRef(null);
  const navigate = useNavigate();
  const [data, setdata] = React.useState<any>({});
  const params = useParams();
  const query: any = useLocation();
  const [toggleSetting, setToggleSetting] = React.useState(false);
  const [state, setState] = React.useState(query.state);
  const [barcode, setBarcode] = React.useState("");
  const [hidePaymentButton, setHidePaymentButton] = React.useState(true);

  const getData = () => {
    setIsLoading(true);
    getDetailInvoice(params.id, state.createdBy.toLowerCase())
      .then((res) => {
        setIsLoading(false);
        setdata(res.data.data);
        getBarcode(res.data.data.link);
        setHidePaymentButton(user._id !== res.data.data.userId);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getDetailInvoiceSent = async () => {
    await API_INVOICE.getDetailInvoiceSent(params.id)
      .then((res) => {
        setdata(res.data.data);
        getBarcode(res.data.data.link);
        setHidePaymentButton(user._id !== res.data.data.userId);
      })
      .catch((err) => {});
  };

  const deleteInvoiceSent = async () => {
    await API_INVOICE.deleteInvoiceSent(params.id)
      .then((res) => {
        navigate(-1);
      })
      .catch((err) => {});
  };

  const handlePayment = () => {
    navigate(`/${company?.initial}/invoices/payment/${params.id}`, {
      state: {
        createdBy: state.createdBy,
        userType: state.userType,
      },
    });
  };

  const handleDownload = () => {
    // exportComponentAsPNG(componentRef, {
    //   fileName: `Detail Invoice ${data.title}`,
    //   html2CanvasOptions: {
    //     scale: 1.3,
    //   },
    // });
    toast("Coming Soon", ToastConfig.warning);
  };

  const handleShare = () => {
    setToggleSetting(true);
  };

  const getBarcode = (src: any) => {
    Barcode.toDataURL(src).then((res) => {
      setBarcode(res);
    });
  };

  React.useEffect(() => {
    if (state.type === "receive") {
      getData();
    } else {
      getDetailInvoiceSent();
    }
  }, []);

  return (
    <>
      <BrowserView>
        <InvoiceDetailWeb
          setToggleSetting={(value: any) => setToggleSetting(value)}
          deleteInvoiceSent={() => deleteInvoiceSent()}
          handlePayment={() => handlePayment()}
          handleDownload={() => handleDownload()}
          handleShare={() => handleShare()}
          color={color}
          isLoading={isLoading}
          data={data}
          toggleSetting={toggleSetting}
          state={state}
          barcode={barcode}
          hidePaymentButton={hidePaymentButton}
          componentRef={componentRef}
        />
      </BrowserView>
      <MobileView>
        <InvoiceDetailMobile
          setToggleSetting={(value: any) => setToggleSetting(value)}
          deleteInvoiceSent={() => deleteInvoiceSent()}
          handlePayment={() => handlePayment()}
          handleDownload={() => handleDownload()}
          handleShare={() => handleShare()}
          color={color}
          isLoading={isLoading}
          data={data}
          toggleSetting={toggleSetting}
          state={state}
          barcode={barcode}
          hidePaymentButton={hidePaymentButton}
          componentRef={componentRef}
        />
      </MobileView>
    </>
  );
};

export default InvoiceDetail;