import BalitaIcon from "../assets/images/icon/balita.svg";
import AlamIcon from "../assets/images/icon/bencana.svg";
import DifabelIcon from "../assets/images/icon/tabler_disabled.svg";
import HewanIcon from "../assets/images/icon/guidance_service-animal-2.svg";
import IbadahIcon from "../assets/images/icon/worship.svg";
import KemanusiaanIcon from "../assets/images/icon/kemanusiaan.svg";
import LingkunganIcon from "../assets/images/icon/lingkungan.svg";
import MedisIcon from "../assets/images/icon/medic.svg";
import PantiIcon from "../assets/images/icon/panti.svg";
import PendidikanIcon from "../assets/images/icon/pendidikan.svg";
import SeniIcon from "../assets/images/icon/ideas.svg";
import SosialIcon from "../assets/images/icon/social.svg";
import UmumIcon from "../assets/images/icon/infrastruktur.svg";
const Combo: any = {
  orderStatus: [
    {
      value: "WAITING_PAYMENT",
      label: "Menunggu Pembayaran",
      bgcolor: "#FFD400",
      color: "#F9FFAE",
    },
    {
      value: "CREATED",
      label: "Dipesan",
      bgcolor: "#FFD400",
      color: "#F9FFAE",
    },

    {
      value: "CANCELED",
      label: "Dibatalkan",
      bgcolor: "#EFC6B2",
      color: "#D20303",
    },
    {
      value: "PROCESSED",
      label: "Diproses",
      bgcolor: "#F9FFAE",
      color: "#FFD400",
    },
    {
      value: "REJECTED",
      label: "Ditolak",
      bgcolor: "#D20303",
      color: "#EFC6B2",
    },
    {
      value: "SENT",
      label: "Dikirim",
      bgcolor: "#9DF9C0",
      color: "#26BC63",
    },
    {
      value: "CONFIRMED",
      label: "Selesai",
      bgcolor: "#9DF9C0",
      color: "#26BC63",
    },
  ],
  orderDate: [
    { value: "-createdDate", label: "Terbaru" },
    { value: "createdDate", label: "Terlama" },
  ],
  categoryPulse: {
    axis: ["0831", "0832", "0833", "0838"],
    three: ["0895", "0896", "0897", "0898", "0899"],
    xl: ["0817", "0818", "0819", "0859", "0878", "0877"],
    indosat: ["0814", "0815", "0816", "0855", "0856", "0857", "0858"],
    telkomsel: [
      "0812",
      "0813",
      "0852",
      "0853",
      "0821",
      "0823",
      "0822",
      "0851",
      "0811",
    ],
    smartfren: [
      "0881",
      "0882",
      "0883",
      "0884",
      "0885",
      "0886",
      "0887",
      "0888",
      "0889",
    ],
  },
  invoiceType: [
    {
      value: "UMUM",
      label: "UMUM",
    },
  ],
  userType: [
    {
      value: "MEMBER",
      label: "MEMBER",
    },
    {
      value: "MERCHANT",
      label: "MERCHANT",
    },
  ],
  listTransactionType: [
    {
      name: "Pembayaran Kartu",
      code: "CARD_PAYMENT",
    },
    {
      name: "Transfer Bank",
      code: "TRANSFER_BANK",
    },
    {
      name: "Refund Transfer Bank",
      code: "REVERSAL_TRANSFER_BANK",
    },
    {
      name: "Transfer Member",
      code: "TRANSFER_MEMBER",
    },
    {
      name: "Refund Transfer Member",
      code: "REVERSAL_TRANSFER_MEMBER",
    },
    {
      name: "Transfer Merchant",
      code: "TRANSFER_MERCHANT",
    },
    {
      name: "Refund Transfer Merchant",
      code: "REVERSAL_TRANSFER_MERCHANT",
    },
    {
      name: "Marketplace",
      code: "MARKETPLACE",
    },
    {
      name: "Refund Marketplace",
      code: "REVERSAL_MARKETPLACE",
    },
    {
      name: "QR Code",
      code: "QR_CODE",
    },
    {
      name: "Refund QR Code",
      code: "REVERSAL_QR_CODE",
    },
    {
      name: "PPOB",
      code: "DIGITAL_PRODUCT",
    },
    {
      name: "Refund PPOB",
      code: "REVERSAL_DIGITAL_PRODUCT",
    },
    {
      name: "Top Up VA",
      code: "TOP_UP_VA",
    },
    {
      name: "Top Up Via Merchant",
      code: "TOP_UP_MERCHANT",
    },
    {
      name: "Refund Top Up Via Merchant",
      code: "REVERSAL_TOP_UP_MERCHANT",
    },
    {
      name: "Top Up Admin",
      code: "TOP_UP_ADMIN",
    },
    {
      name: "Refund Top Up Admin",
      code: "REVERSAL_TOP_UP_ADMIN",
    },
    {
      name: "Top Up Saldo Kartu",
      code: "TOP_UP_CARD_BALANCE",
    },
    {
      name: "Refund Top Up Saldo Kartu",
      code: "REVERSAL_TOP_UP_CARD_BALANCE",
    },
    {
      name: "Pembayaran",
      code: "CASHOUT_MERCHANT",
    },
    {
      name: "Parkir",
      code: "PARKING",
    },
    {
      name: "Pembuatan Kartu",
      code: "CREATE_CARD",
    },
    {
      name: "Refund Pembuatan Kartu",
      code: "REVERSAL_CREATE_CARD",
    },
    {
      name: "Donasi",
      code: "DONATION",
    },
    {
      name: "Refund Donasi",
      code: "REVERSAL_DONATION",
    },
    {
      name: "Pencairan",
      code: "WITHDRAW_ADMIN",
    },
    {
      name: "Kas Keluar",
      code: "DISBURSEMENT_ADMIN",
    },
    {
      name: "Refund Kas Keluar",
      code: "REVERSAL_DISBURSEMENT_ADMIN",
    },
    {
      name: "Invoice",
      code: "INVOICE",
    },
    {
      name: "Refund Invoice",
      code: "REVERSAL_INVOICE",
    },
    {
      name: "Refund Pembayaran Kartu",
      code: "REVERSAL_CARD_PAYMENT",
    },
    {
      name: "Refund Pembayaran",
      code: "REVERSAL_CASHOUT_MERCHANT",
    },
    {
      name: "Biaya Perusahaan",
      code: "CHARGE_COMPANY",
    },
    {
      name: "Refund Biaya Perusahaan",
      code: "REVERSAL_CHARGE_COMPANY",
    },
    {
      name: "Biaya Master",
      code: "CHARGE_MASTER",
    },
    {
      name: "Refund Biaya Master",
      code: "REVERSAL_CHARGE_MASTER",
    },
    {
      name: "Update Credit",
      code: "UPDATE_CREDIT",
    },
    {
      name: "Pencairan Merchant",
      code: "WITHDRAW_MERCHANT",
    },
    {
      name: "Refund Pencairan Merchant",
      code: "REVERSAL_WITHDRAW_MERCHANT",
    },
    {
      name: "Pencairan Saldo Kartu",
      code: "WITHDRAW_CARD_BALANCE",
    },
    {
      name: "Refund Pencairan Saldo Kartu",
      code: "REVERSAL_WITHDRAW_CARD_BALANCE",
    },
    {
      name: "DISPENSER",
      code: "DISPENSER",
    },
    {
      name: "Top Up Saldo Kartu Merchant",
      code: "TOP_UP_CARD_BALANCE_MERCHANT",
    },
    {
      name: "Refund Top Up Saldo Kartu Merchant",
      code: "REVERSAL_TOP_UP_CARD_BALANCE_MERCHANT",
    },
    {
      name: "Pencairan Saldo Kartu Merchant",
      code: "WITHDRAW_CARD_BALANCE_MERCHANT",
    },
    {
      name: "Refund Pencairan Saldo Kartu Merchant",
      code: "REVERSAL_WITHDRAW_CARD_BALANCE_MERCHANT",
    },
    {
      name: "Invoice Retribution",
      code: "INVOICE_RETRIBUTION",
    },
    {
      name: "Refund Invoice Retribution",
      code: "REVERSAL_INVOICE_RETRIBUTION",
    },
  ],
};

export const donationCategories = [
  {
    value: "BENCANA_ALAM",
    icon: AlamIcon,
  },
  {
    value: "KEGIATAN_SOSIAL",
    icon: SosialIcon,
  },
  {
    value: "INFRASTRUKTUR_UMUM",
    icon: UmumIcon,
  },
  {
    value: "LINGKUNGAN",
    icon: LingkunganIcon,
  },
  {
    value: "BANTUAN_PENDIDIKAN",
    icon: PendidikanIcon,
  },
  {
    value: "BALITA_DAN_ANAK_SAKIT",
    icon: BalitaIcon,
  },
  {
    value: "BANTUAN_MEDIS_DAN_KESEHATAN",
    icon: MedisIcon,
  },
  {
    value: "DIFABEL",
    icon: DifabelIcon,
  },
  {
    value: "MENOLONG_HEWAN",
    icon: HewanIcon,
  },
  {
    value: "KARYA_KREATIF_DAN_MODAL_USAHA",
    icon: SeniIcon,
  },
  {
    value: "RUMAH_IBADAH",
    icon: IbadahIcon,
  },
  {
    value: "KEMANUSIAAN",
    icon: KemanusiaanIcon,
  },
  {
    value: "PANTI_ASUHAN",
    icon: PantiIcon,
  },
];

export default Combo;
