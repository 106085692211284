import React from "react";
import closepayLogo from "../../assets/images/solusinegerinew.svg";
import { removeCompany } from "../../core/feature/company/companySlice";
import {} from "../../core/feature/store";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Countdown from "react-countdown";
import { StatefulPinInput } from "react-input-pin-code";
import { Link } from "react-router-dom";
import desktopBackground from "../../assets/images/mobileBgBlur.svg";
import icon_verify from "../../assets/images/verified.svg";
import { removeUserLogin } from "../../core/feature/userLogin/userLoginSlice";
import DestinationOtpComponent from "../_common/destinationOtpComponent";
import OtpComponent from "../_common/otpComponent";
import ConfirmationModal from "../_common/confirmationModal";
import { useTranslation } from "react-i18next";

const LoginMobile = (props: any) => {
  const { t } = useTranslation();

  return (
    <div
      className="h-full min-h-screen bg-second dark:bg-black font-montserrat flex flex-col justify-center items-center bg-cover"
      style={{ backgroundImage: `url(${desktopBackground})` }}
    >
      <DestinationOtpComponent
        key={`${props.data.toString()}-${
          props.destinationEmailVerified ? "email" : ""
        }-${props.destinationPhoneVerified ? "phone" : ""}`}
        showDestinationOtp={props.destinationOtp}
        showChooseDestination={true}
        showAddDestination={true}
        onHide={() => {
          props.setDestinationOtp(false);
        }}
        data={props.data}
        username={props.username}
        password={props.password}
        isEmailVerified={props.destinationEmailVerified}
        isPhoneVerified={props.destinationPhoneVerified}
        login={props.logiin}
        stateEnd={props.stateEnd}
        getProfil={props.getProfil}
      />

      <OtpComponent
        showInputOtp={props.showOtpEmail}
        transactionType="NoEmail"
        email={
          props.selected === "WA" || props.selected === "wa"
            ? props.phoneFer
            : props.emailFer
        }
        username={props.username}
        password={props.password}
        deviceId={props.deviceId}
        companyId={props.prefixId === "" ? props.company?.id : props.prefixId}
        message={"Notifikasi Keamanan Akun"}
        onHide={() => {
          props.setShowOtpEmail(false);
        }}
        expOtp={300000}
        width={40}
        height={43}
        margin="0 0.4rem"
        stateEnd="coba"
      ></OtpComponent>

      {/* <ConfirmationModal
        showModal={props.showModalPhone}
        hideModal={props.hideModal}
        confirm={props.confirmPhone}
      />

      <ConfirmationModal
        showModal={props.showModalEmail}
        hideModal={props.hideModal}
        confirm={props.confirmEmail}
      /> */}

      <div
        className={`${props.showInputEmail ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-1/2 sm:my-8 md:p-[3rem] p-[1.5rem]">
              <div className="space-between">
                <h4 className="md:text-2xl text-sm text-black font-medium mb-0">
                  {t("login.noEmail")}
                </h4>
                <button type="button" onClick={() => props.addPassword()}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p className="md:text-xl text-sm text-black text-start">
                {t("login.noEmailDesc")}
              </p>
              <form className="w-full" onSubmit={props.submitEmail}>
                <div className="flex items-center justify-center gap-3">
                  <a
                    href="https://dev.closepay.id/termcondition/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-sky-500 underline"
                  >
                    {t("login.termConditions")}
                  </a>
                  <input
                    type="checkbox"
                    id="ckSyaratDanKetentuan"
                    checked={props.isChecked}
                    onChange={() => props.setIsChecked(!props.isChecked)}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    onChange={(e) => props.setEmail(e.target.value.trim())}
                    className="focus:border-1 rounded-full p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 w-full my-3"
                  />
                </div>
                <button
                  type="submit"
                  disabled={props.disabledButton()}
                  className={`${
                    props.disabledButton() === true
                      ? "bg-gray-500"
                      : "bg-btnGreen"
                  } w-full md:py-[16px] p-[10px] rounded-full text-white font-semibold text-base`}
                >
                  {t("login.confirm")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Add Email & Phone*/}
      <div
        className={`${props.addEmailForm ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white w-full shadow-xl transition-all md:w-1/2 sm:my-8 md:p-[3rem] p-[1.5rem]">
              <span className="text-[20px] font-semibold mb-2">
                {t("login.addData")}
              </span>
              <p className="text-[12px]">{t("login.addDataDesc")}</p>
              <div className="w-full text-left">
                {!props.user?.verifiedPhone && (
                  <div className="flex gap-2 my-3">
                    <input
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      type="radio"
                      id="EMAIL"
                      checked={props.selected === "EMAIL"}
                      onChange={() => {
                        props.setSelected("EMAIL");
                      }}
                    />
                    <div className="flex flex-col gap-1 text-start grow relative">
                      <label className="flex justify-start ">E-Mail</label>
                      <span className="text-xs text-thirdy">
                        {t("login.insertEmail")}
                        {/* {item.description} */}
                      </span>
                      <input
                        disabled={props.selected !== "EMAIL"}
                        type="text"
                        value={props.emailFer}
                        onChange={(e) => {
                          props.setEmailFer(e.target.value);
                        }}
                        className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      ></input>
                      {props.user?.verifiedEmail === true && (
                        <img
                          src={icon_verify}
                          alt=""
                          className="w-7 h-7 absolute right-2 top-[54px]"
                        />
                      )}
                    </div>
                  </div>
                )}
                {!props.user?.verifiedEmail && (
                  <div className="flex gap-2">
                    <input
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      type="radio"
                      id="WA"
                      checked={props.selected === "WA"}
                      // onChange={() => {
                      //   props.setSelected("WA");
                      // }}
                    />
                    <div className="flex flex-col gap-1 text-start grow relative">
                      <label className="flex justify-start ">
                        WhatsApp (Whatsapp sedang gangguan)
                      </label>
                      <span className="text-xs text-thirdy">
                        {t("login.formatNumber")}
                        {/* Saat ini WhatsApp tidak tersedia. Silahkan hubungi{" "}
                      <a
                        href="https://wa.me/+6289526643223"
                        target="_blank"
                        rel="noreferrer"
                      >
                        CS Closepay
                      </a>
                      props. */}
                      </span>
                      <input
                        disabled={props.selected !== "WA"}
                        // disabled
                        type="text"
                        value={props.phoneFer}
                        onChange={(e) => {
                          props.setPhoneFer(e.target.value);
                        }}
                        className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      ></input>
                      {props.user?.verifiedPhone === true && (
                        <img
                          src={icon_verify}
                          alt=""
                          className="w-7 h-7 absolute right-2 top-[54px]"
                        />
                      )}
                      {props.selected === "WA" && (
                        <span className="text-center text-[12px] mt-3 text-gray-600 -ml-4">
                          {t("login.messageConfirm")}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <button
                disabled={!props.selected}
                onClick={() => {
                  if (props.selected === "EMAIL") {
                    props.changeEmail();
                  } else {
                    props.changePhone();
                  }
                }}
                className={`w-full ${
                  !props.selected ? "bg-gray-500" : "bg-green"
                } text-white py-2 mt-5 rounded-lg`}
              >
                {t("login.confirm")}
              </button>
              <span
                className="mt-4 text-xs cursor-pointer"
                onClick={() => {
                  props.setShowDataVerification(false);
                  props.navigate(`/${props.company.initial}`);
                }}
              >
                {t("login.skipStep")}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}

      {/* Modal Verivication */}
      <div
        className={`${
          props.showVerificationFrom ? "block" : "hidden"
        } relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white w-full shadow-xl transition-all md:w-1/2 sm:my-8 md:p-[3rem] p-[1.5rem]">
              <span className="text-[20px] font-semibold mb-2">
                {t("login.notificationDestination")}
              </span>
              <p className="text-[12px]">{t("login.selectDestination")}</p>
              <div className="w-full text-left">
                <div className="flex gap-2 my-3">
                  <input
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="radio"
                    name="selected"
                    checked={props.selected === "EMAIL"}
                    onChange={() => {
                      if (props.user.email) {
                        props.setSelected("EMAIL");
                        props.setIsAddData(false);
                      } else {
                        props.setSelected("EMAIL");
                        props.setIsAddData(true);
                      }
                    }}
                  />
                  <div className="flex flex-col gap-1 text-start grow relative">
                    <label className="flex justify-start ">
                      {t("login.emailLabel")}
                    </label>
                    <span className="text-xs text-thirdy">
                      {t("login.insertEmail")}
                      {/* {item.description} */}
                    </span>
                    <input
                      disabled
                      type="text"
                      value={props.emailFer}
                      onChange={(e) => {
                        props.setEmailFer(e.target.value);
                      }}
                      className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    ></input>
                    {props.user?.verifiedEmail === true && (
                      <img
                        src={icon_verify}
                        alt=""
                        className="w-7 h-7 absolute right-2 top-[54px]"
                      />
                    )}
                  </div>
                </div>
                <div className="flex gap-2">
                  <input
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="radio"
                    name="selected2"
                    checked={props.selected === "WA"}
                    onChange={() => {
                      if (props.user?.phone) {
                        props.setSelected("WA");
                      } else {
                        props.setSelected("WA");
                        props.setIsAddData(true);
                      }
                    }}
                  />
                  <div className="flex flex-col gap-1 text-start grow relative">
                    <label className="flex justify-start ">
                      {t("login.whatsappLabel")}
                    </label>
                    <span className="text-xs text-thirdy">
                      {t("login.insertWhastapp")}
                      {/* Saat ini WhatsApp tidak tersedia. Silahkan hubungi{" "}
                      <a
                        href="https://wa.me/+6289526643223"
                        target="_blank"
                        rel="noreferrer"
                      >
                        CS Closepay
                      </a> */}
                    </span>
                    <input
                      disabled={!props.isAddData}
                      // disabled
                      type="text"
                      value={props.phoneFer}
                      onChange={(e) => {
                        props.setPhoneFer(e.target.value);
                      }}
                      className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    ></input>
                    {props.user?.verifiedPhone === true && (
                      <img
                        src={icon_verify}
                        alt=""
                        className="w-7 h-7 absolute right-2 top-[54px]"
                      />
                    )}
                  </div>
                </div>
              </div>
              {props.isAddData ? (
                <button
                  disabled={!props.selected}
                  onClick={() => {
                    props.requestOtp();
                  }}
                  className={`w-full ${
                    !props.selected ? "bg-gray-500" : "bg-green"
                  } text-white py-2 mt-5 rounded-lg`}
                >
                  {t("login.confirm")}
                </button>
              ) : (
                <button
                  disabled={!props.selected}
                  onClick={() => {
                    props.requestOtp();
                  }}
                  className={`w-full ${
                    !props.selected ? "bg-gray-500" : "bg-green"
                  } text-white py-2 mt-5 rounded-lg`}
                >
                  {t("login.verification")}
                </button>
              )}
              <span
                className="mt-4 text-xs cursor-pointer"
                onClick={() => {
                  props.setShowDataVerification(false);
                  props.navigate(`/${props.company.initial}`);
                }}
              >
                {t("login.skipStep")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${
          props.showDataVerification ? "block" : "hidden"
        } relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div
          className={`${
            props.showModalEmail || props.showModalPhone ? "z-0" : "z-10"
          } fixed inset-0 overflow-y-auto`}
        >
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]">
              <div className="flex flex-row justify-end w-100 mb-3 md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    props.setShowDataVerification(false);
                    props.setPhoneFer("");
                    props.setEmailFer("");
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p className="md:text-2xl text-sm text-black md:mb-4">
                {t("login.insertOtp")}
                <span className="font-bold">
                  {" "}
                  {props.selected === "WA"
                    ? props.phoneFer
                    : props.emailFer}{" "}
                </span>
                {/* dengan subjek <span className="font-bold">{props.message}</span> */}
              </p>
              <StatefulPinInput
                length={6}
                placeholder=""
                initialValue={props.NewValues}
                onChange={(value, index, values) => {
                  props.setNewValues(values);
                  props.data["otp"] = values.join("");
                  console.log(values.join(""));
                  console.log(props.NewValues);
                  console.log(typeof props.NewValues);
                  console.log(props.NewValues.length);
                }}
                type="number"
                mask={true}
                focusBorderColor="#26A69A"
                validBorderColor="#26A69A"
              />
              <Countdown
                key={props.timer}
                date={props.timer}
                renderer={props.renderer}
              />
              <button
                disabled={props.NewValues.join("").length < 6}
                onClick={() => {
                  props.setShowDataVerification(false);
                  if (props.selected === "WA") {
                    props.confirmPhone();
                  } else {
                    props.confirmEmail();
                  }
                }}
                className={`${
                  props.NewValues.join("").length < 6
                    ? "bg-gray-500"
                    : "bg-[#26A69A]"
                } w-full md:py-[16px] p-[10px] rounded-full text-white font-semibold text-base disabled:bg-slate-50`}
              >
                {t("login.confirm")}
              </button>
              <span
                className="mt-4 text-xs cursor-pointer"
                onClick={() => {
                  props.setShowDataVerification(false);
                  props.navigate(`/${props.company.initial}`);
                }}
              >
                {t("login.skipStep")}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}

      <div
        className={`${
          props.showCreatePassword ? "block" : "hidden"
        } relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-1/2 sm:my-8 md:p-[3rem] p-[1.5rem]">
              <button
                className="absolute right-4 top-4"
                onClick={() => props.setshowCreatePassword(false)}
              >
                X
              </button>
              <span className="text-[20px] font-semibold mb-2">
                {t("login.registeredPassword")}
              </span>
              <p className="text-[12px]">{t("login.registeredPasswordDesc")}</p>
              <div className="w-full text-left">
                <div className="flex flex-col mb-3 relative">
                  <label className="text-xs text-thirdy font-medium pl-1 mb-0">
                    {t("login.passwordLabel")}
                  </label>
                  <input
                    ref={props.emailRef}
                    type={props.visiblePW1 ? "text" : "password"}
                    onChange={(e) => {
                      props.setNewPassword(e.target.value);
                    }}
                    className="w-full p-[10px] text-xs focus:border-1 rounded-md h-10 border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 shadow-md"
                  />
                  <div
                    className="absolute top-[25px] right-[15px] cursor-pointer"
                    onClick={() => props.setVisiblePW1(!props.visiblePW1)}
                  >
                    {props.visiblePW1 ? (
                      <FontAwesomeIcon icon={faEye} color="#C4C4C4" />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} color="#C4C4C4" />
                    )}
                  </div>
                </div>
                <div className="flex flex-col relative">
                  <label className="text-xs text-thirdy font-medium pl-1 mb-0">
                    {t("login.confirmPassword")}
                  </label>
                  <input
                    ref={props.emailRef}
                    type={props.visiblePW2 ? "text" : "password"}
                    onChange={(e) => {
                      props.setNewPassword2(e.target.value);
                    }}
                    className="w-full p-[10px] text-xs focus:border-1 rounded-md h-10 border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 shadow-md"
                  />
                  <div
                    className="absolute top-[25px] right-[15px] cursor-pointer"
                    onClick={() => props.setVisiblePW2(!props.visiblePW2)}
                  >
                    {props.visiblePW2 ? (
                      <FontAwesomeIcon icon={faEye} color="#C4C4C4" />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} color="#C4C4C4" />
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={() => props.confirmNewPassword()}
                className="w-full bg-green text-white py-2 mt-5 rounded-lg"
              >
                {t("login.confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {!props.showPasswordForm && (
        <div className="lg:w-1/2 sm:w-2/4 md:w-3/4 m-auto w-full px-16">
          <div className="w-100">
            <img
              src={
                props.company?.companyLogo !== null
                  ? props.company?.companyLogo
                  : closepayLogo
              }
              alt="icon"
              className="h-32 mx-auto"
            />
          </div>
          <div className="mt-10 mb-6 w-100">
            <h4 className="text-left text-base font-semibold font-montserrat">
              {t("login.welcome")}
            </h4>
            <p className="text-left text-thirdy font-light text-xs">
              {t("login.welcomeDesc")}
            </p>
          </div>
          <div className="w-100">
            <form
              onSubmit={props.handleUsername}
              className="flex flex-col gap-2 mb-1"
            >
              <label className="text-xs text-thirdy font-medium pl-1 mb-0">
                {t("login.username")}
              </label>
              <input
                ref={props.emailRef}
                type="text"
                onChange={(e) => {
                  let user = e.target.value.trim();
                  let com = e.target.value.toUpperCase();
                  props.setUsername(user.substring(user.indexOf(".") + 1));
                  props.setUsernameEmail(e.target.value);
                  if (com.substring(0, com.indexOf(".")).includes("@")) {
                    props.setUsername(e.target.value);
                    props.setPrefix("");
                  } else {
                    props.setPrefix(com.substring(0, com.indexOf(".")));
                  }
                  props.setErrorUsername("");
                }}
                className="grow p-[10px] text-xs focus:border-1 rounded-full h-10 border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 shadow-md"
              />
              <p className="text-xs text-red pl-1 mb-0">
                {props.errorUsername}
              </p>
            </form>

            <div className="justify-between mt-1 flex text-[12px]">
              <span
                onClick={() => props.navigate(`/${props.company?.initial}`)}
                className="hover:underline text-[#728F9E]"
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" />{" "}
                {t("login.back")}
              </span>
            </div>
            <div className="flex flex-col gap-3">
              <button
                onClick={() => props.cekPassword()}
                className="w-full p-[10px] mt-6 rounded-full text-white font-semibold text-sm bg-[#26A69A]"
              >
                {t("login.next")}
              </button>
              {/* <button
              type="button"
              className="w-full p-[10px] rounded-full text-white font-semibold text-sm bg-btnYellow"
              
            >
              Lupa Password
            </button> */}
            </div>
          </div>
          {props.company?.enableMemberSelfRegister === true && (
            <div className="text-center w-100 mt-3 text-xs">
              <span className="text-[#728F9E]">{t("login.noAccount")}</span>
              <Link
                to={`/${props.company?.initial}/self-register`}
                className="text-[#26A69A] text-xs text-center no-underline"
              >
                <span className="ms-2">{t("login.register")}</span>
              </Link>
            </div>
          )}
          <div className="text-center w-100 mt-2">
            {props.isEverChooseCompany && (
              <Link
                to="/companies"
                onClick={() => {
                  props.dispatch(removeCompany());
                  props.dispatch(removeUserLogin());
                }}
                className="text-[#26A69A] text-xs text-center no-underline"
              >
                <span className="ms-2">{t("login.selectInstitution")}</span>
              </Link>
            )}
          </div>
          {props?.company?.showFtpLogo && (
            <div className="mt-5 bottom-0 flex flex-col gap-1 items-center py-2">
              <p
                className="text-[11px] font-light text-center mb-0"
                style={{ color: "#8F8F8F" }}
              >
                Powered By
              </p>
              <img src={closepayLogo} alt="icon" className="w-16" />
            </div>
          )}
        </div>
      )}

      {props.showPasswordForm && (
        <div className="lg:w-1/2 sm:w-3/4 md:w-3/4 m-auto w-full px-16">
          <div className="w-100">
            <img
              src={
                props.company?.companyLogo !== null
                  ? props.company?.companyLogo
                  : closepayLogo
              }
              alt="icon"
              className="h-32 mx-auto"
            />
          </div>
          <div className="mt-10 mb-6 w-100">
            <h4 className="text-left text-base font-semibold font-montserrat">
              {t("login.insertPassword")}
            </h4>
            <p className="text-left text-thirdy font-light text-xs">
              {t("login.insertMessage")}
            </p>
          </div>
          <div className="w-100">
            <form
              onSubmit={props.handlePassword}
              className="flex flex-col gap-2 relative"
            >
              <label className="text-xs text-thirdy font-medium pl-1 mb-0">
                {t("login.passwordLabel")}
              </label>
              <input
                ref={props.passwordRef}
                onChange={(e) => {
                  props.encriptedvalue(e.target.value.trim());
                  props.setErrorPassword("");
                }}
                type={props.visible ? "text" : "password"}
                className="grow p-[10px] text-xs focus:border-1 rounded-full h-10 border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 shadow-md"
              />
              <div
                className="absolute top-[32px] right-[15px] cursor-pointer"
                onClick={() => props.setVisible(!props.visible)}
              >
                {props.visible ? (
                  <FontAwesomeIcon icon={faEye} color="#C4C4C4" />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash} color="#C4C4C4" />
                )}
              </div>
              <p className="text-xs text-red pl-1 mb-0">
                {props.errorPassword}
              </p>
            </form>
            <div className="justify-between mt-2 flex text-[12px]">
              <span
                onClick={() => props.setshowPasswordForm(false)}
                className="hover:underline text-[#728F9E]"
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" />{" "}
                {t("login.back")}
              </span>
              <span
                onClick={() =>
                  props.navigate(
                    `/${props.company?.initial}/login/forget-password`,
                    {
                      state: {
                        username: props.username,
                        deviceId: props.deviceId,
                      },
                    }
                  )
                }
                className="hover:underline text-[#728F9E]"
              >
                {t("login.forgotPassword")}
              </span>
            </div>
            <div className="flex flex-col gap-3">
              <button
                onClick={() => props.logiin()}
                className="w-full p-[10px] mt-6 rounded-full text-white font-semibold text-sm bg-[#26A69A]"
              >
                {t("login.login")}
              </button>
              {/* <button
              type="button"
              className="w-full p-[10px] rounded-full text-white font-semibold text-sm bg-btnYellow"
              
            >
              Lupa Password
            </button> */}
            </div>
          </div>
          {props?.company?.showFtpLogo && (
            <div className="mt-5 bottom-0 flex flex-col gap-1 items-center py-2">
              <p
                className="text-[11px] font-light text-center mb-0"
                style={{ color: "#8F8F8F" }}
              >
                Powered By
              </p>
              <img src={closepayLogo} alt="icon" className="w-16" />
            </div>
          )}
        </div>
      )}
      <div
        className={`${props.success ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]">
              <svg
                width="118"
                height="118"
                viewBox="0 0 118 118"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="118" height="118" rx="59" fill="#CFEAE8" />
                <path
                  d="M40.2354 66.6789V38.0625C40.2354 37.5102 40.6831 37.0625 41.2354 37.0625H76.7648C77.317 37.0625 77.7648 37.5102 77.7648 38.0625V66.6789C77.7648 70.2214 75.6866 73.4344 72.4556 74.8872L59.4102 80.7531C59.1493 80.8704 58.8508 80.8704 58.59 80.7531L45.5445 74.8872C42.3135 73.4344 40.2354 70.2214 40.2354 66.6789Z"
                  stroke="#408D86"
                  stroke-width="4"
                />
                <path
                  d="M50.4707 56.4688L56.4413 62.375L67.5295 49.7188"
                  stroke="#FF9723"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-semibold text-[14px] mt-4">
                {props.selected === "WA" ? "Nomor" : "Email"}{" "}
                {t("login.terverified")}
              </p>
              <button
                onClick={() => props.login()}
                className="bg-green py-2.5 px-6 rounded-xl text-white font-semibold"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginMobile;
