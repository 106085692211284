/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import * as API_USER from "../../core/service/api_user";
import iconSuccess from "../../assets/images/iconSuccess.png";
import iconFailed from "../../assets/images/icon-failed.png";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";
import closepayLogo from "../../assets/images/solusinegeri-logo.svg";
import Company from "../../core/models/company";
import { useNavigate, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import Header from "../_common/headerComponent";
import Token from "../../core/models/token";
import _, { findIndex } from "lodash";
import User from "../../core/models/user";
import { setUser } from "../../core/feature/user/userSlice";
import { setToken } from "../../core/feature/token/tokenSlice";
import { setCompany } from "../../core/feature/company/companySlice";
import { useTranslation } from "react-i18next";

const CreateSecurityCodeWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const user: User = useAppSelector((state) => state.user.user);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();
  let location: any = useLocation();
  const [securityCode, setSecurityCode] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [createSuccessFully, setCreateSuccessFully] = useState(false);
  const [createFailed, setCreateFailed] = useState(false);
  const [label, setLabel] = useState("");
  const [max, setMax] = React.useState<any>();
  const [NewValues, setNewValues] = React.useState(["", "", "", "", "", ""]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let link = query.get(`link`);
  let companyInitial = query.get("companyinitial");
  let tokenCo = query.get("token");

  const submit = async (e: any) => {
    e.preventDefault();
    if (
      NewValues.join("") === "" ||
      NewValues.join("") ===
      "undefinedundefinedundefinedundefinedundefinedundefined"
    ) {
      setErrorNewPassword(t('card_security_code.required_pin'));
    } else {
      dispatch(setLoading(true));
      let data = {
        securityCode: NewValues.join("").replaceAll("undefined", ""),
      };

      await API_USER.createSecurityCode(data)
        .then((res) => {
          dispatch(setLoading(false));
          setCreateSuccessFully(true);
          if (location.state !== undefined) {
            dispatch(setUser({ ...user, securityCode: true }));
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          setCreateFailed(true);
        });
    }
  };
  const handleNavigateHome = () => {
    if (link) {
      setLabel(t('card_security_code.next_transaction'));
      let dataToken: any = _.find(token, function (o) {
        return o.cid === company.initial;
      });
      window.location.href = `https://${link}?access_token=${dataToken?.token}&message=SUCCESS_LOGIN`;
    } else if (location.state !== undefined) {
      if (location.state.type !== undefined) {
        //check dari transfer member
        if (location.state.type === "transfer") {
          navigate(`/${company?.initial}/${location.state.url}`, {
            replace: true,
            state: {
              data: location.state.data,
            },
          });
        } else if (
          location.state.type === "static" ||
          location.state.type === "dynamic"
        ) {
          navigate(`/${company?.initial}/${location.state.url}`, {
            replace: true,
            state: {
              data: location.state.data,
              type: location.state.type,
            },
          });
        } else if (
          location.state.type === "withdraw-card" ||
          location.state.type === "topup-card"
        ) {
          navigate(`/${company?.initial}/${location.state.url}`, {
            replace: true,
            state: {
              data: location.state.data,
            },
          });
        }
      } else {
        navigate(`/${company?.initial}/virtual-card/add/summary`, {
          replace: true,
          state: {
            data: location.state.data,
            inquiryId: location.state.inquiryId,
          },
        });
      }
    } else {
      navigate(`/${company?.initial}`, { replace: true });
      setLabel(t('card_security_code.pin_transaction'));
    }
  };

  const initialCompany = async () => {
    dispatch(setLoading(true));
    await API_USER.getCompany(companyInitial)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setCompany(res.data.data));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    if (location.state !== undefined) {
      setLabel(
        t('card_security_code.no_have_pin')
      );
    }
    if (link && companyInitial && tokenCo) {
      initialCompany();
      if (company) {
        var newToken = token ? [...token] : [];
        let indexToken = findIndex(token, { cid: companyInitial });

        if (indexToken < 0) {
          newToken.push({
            cid: companyInitial,
            token: tokenCo,
          });
        } else {
          newToken[indexToken] = {
            cid: companyInitial,
            token: tokenCo,
          };
        }
        dispatch(setToken(newToken));
      }
    }
  }, []);

  return (
    <section className="h-full min-h-screen flex flex-col justify-between font-poppins bg-mobile dark:bg-black">
      <Header></Header>
      <div
        className={`${createSuccessFully || createFailed
          ? "hidden"
          : "container font-poppins"
          }`}
      >
        <div className="flex flex-col justify-center items-center">
          <div>
            <div className="mt-10 mb-6 w-100 text-center">
              <h4 className="md:text-2xl text-xl font-semibold md:mt-2 mt-1">
                {t('card_security_code.create_pin')}
              </h4>
              <p className="text-base text-thirdy font-normal">{label}</p>
            </div>
            <form onSubmit={submit} className="w-100">
              <div className="flex flex-col gap-2 mb-4 relative">
                {/* <OtpInput
                  value={securityCode}
                  onChange={(code: React.SetStateAction<string>) => {
                    setSecurityCode(code);
                  }}
                  numInputs={6}
                  separator={<span>&nbsp;</span>}
                  isInputNum
                  isInputSecure
                  inputStyle={{
                    width: 80,
                    height: 83,
                    margin: "0 0.25rem",
                    fontSize: "2rem",
                    borderRadius: 12,
                    border: "0.5px solid #848383",
                  }}
                /> */}
                <div className="mx-auto">
                  <div className="flex flex-row gap-3 relative z-10">
                    {NewValues.map((res, i) => {
                      return (
                        <div
                          className={
                            NewValues[i] === "undefined" || NewValues[i] === ""
                              ? "border outline-none text-center rounded-full w-10 h-10"
                              : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-full w-10 h-10"
                          }
                          key={i}
                        ></div>
                      );
                    })}
                  </div>
                  <div className="w-full -mt-9 relative z-20">
                    <input
                      className="w-full bg-transparent border-none indent-[-999em] caret-transparent text-transparent focus:outline-none py-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      type="number"
                      value={max}
                      onChange={(e) => {
                        if (e.target.value.length > 6) {
                          setMax(max);
                        } else {
                          const value: any = e.target.value.split("");
                          const newValue: any = NewValues;
                          const x: any = [
                            `${(newValue[0] = value[0])}`,
                            `${(newValue[1] = value[1])}`,
                            `${(newValue[2] = value[2])}`,
                            `${(newValue[3] = value[3])}`,
                            `${(newValue[4] = value[4])}`,
                            `${(newValue[5] = value[5])}`,
                          ];
                          setNewValues(x);
                          setMax(e.target.value);
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <p className="text-base text-center text-red pl-1 mb-0">
                  {errorNewPassword}
                </p>
              </div>
              <div className="mt-6">
                <button
                  type="submit"
                  className="w-full md:p-[1rem] p-[12px] rounded-full text-white font-semibold text-base bg-btnGreen"
                >
                  {t('card_security_code.confirm')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`${createSuccessFully ? "container font-poppins" : "hidden"
          }`}
      >
        <div className="lg:w-1/2 sm:w-3/4 md:w-3/4 m-auto">
          <div className="relative items-center mt-4 mb-3">
            <img
              src={iconSuccess}
              alt="user"
              className="mx-auto md:w-max w-[180px]"
            />
          </div>
          <div className="w-100 text-center mb-3">
            <h1 className="font-bold md:text-2xl text-xl">
              {t('card_security_code.pin_successfully_activated')}
            </h1>
          </div>
          <div className="w-full mt-2 text-center">
            <button
              type="button"
              className="w-full md:p-3 p-[12px] mb-3 rounded-full text-white font-semibold text-base bg-btnGreen"
              onClick={handleNavigateHome}
            >
              {t('card_security_code.done')}
            </button>
          </div>
        </div>
      </div>
      <div className={`${createFailed ? "container font-poppins" : "hidden"}`}>
        <div className="lg:w-1/2 sm:w-3/4 md:w-3/4 m-auto">
          <div className="relative items-center mt-4 mb-3">
            <img
              src={iconFailed}
              alt="user"
              className="mx-auto md:w-max w-[180px]"
            />
          </div>
          <div className="w-100 text-center mb-3">
            <h1 className="font-bold md:text-2xl text-xl">{t('card_security_code.pin_failed_activated')}</h1>
          </div>
          <div className="w-full mt-2 text-center">
            <button
              type="button"
              className="w-full md:p-3 p-[12px] mb-3 rounded-full text-white font-semibold text-base bg-btnGreen"
              onClick={() => setCreateFailed(false)}
            >
              {t('accessibility.back')}
            </button>
          </div>
        </div>
      </div>
      <div className="mb-4 w-100">
        {company?.showFtpLogo && (
          <>
            <p
              className="md:text-xl text-base font-medium text-center md:mb-3 mb-2"
              style={{ color: "#8F8F8F" }}
            >
              Powered By
            </p>
            <img
              src={closepayLogo}
              alt="icon"
              className="mx-auto md:w-auto w-[35%]"
            />
          </>
        )}
      </div>
    </section>
  );
};

export default CreateSecurityCodeWeb;
