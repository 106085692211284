import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ConnectAccountMobile from "./connectAccountMobile";
import ConnectAccountWeb from "./connectAccountWeb";

const ConnectAccount = () => {
  const proceduresBankDki = [
    {
      id: 1,
      display: "Masukkan username, password, nomor telepon, email",
      displayEn: "Enter username, password, telephone number, email",
    },
    {
      id: 2,
      display: "Masukkan kode OTP yang telah dikirimkan ke email",
      displayEn: "Enter the OTP code that was sent to the email",
    },
    {
      id: 3,
      display: "Selesai",
      displayEn: "Done",
    },
  ];
  const proceduresLinkAja = [
    {
      id: 1,
      display: "Masukkan nomor telepon yang telah terdaftar di {data.providerName}",
      displayEn: "Enter the telephone number registered in {data.providerName}",
    },
    {
      id: 2,
      display: "Masukkan kode OTP yang telah dikirimkan ke email",
      displayEn: "Enter the OTP code that was sent to the email",
    },
    {
      id: 3,
      display: "Masukkan PIN yang telah anda buat.",
      displayEn: "Enter the PIN you have created.",
    },
  ];
  return (
    <>
      <BrowserView>
        <ConnectAccountWeb proceduresBankDki={proceduresBankDki} proceduresLinkAja={proceduresLinkAja}></ConnectAccountWeb>
      </BrowserView>
      <MobileView>
        <ConnectAccountMobile proceduresBankDki={proceduresBankDki} proceduresLinkAja={proceduresLinkAja}></ConnectAccountMobile>
      </MobileView>
    </>
  );
};

export default ConnectAccount;
