/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import WithdrawCardBalanceWeb from "./withdrawCardBalanceWeb";
import WithdrawCardBalanceMobile from "./withdrawCardBalanceMobile";
import { useNavigate, useParams } from "react-router-dom";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import { useAppDispatch } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useTranslation } from "react-i18next";

const WithdrawCardBalance = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [template, setTemplate] = React.useState("");
  const [background, setBackground] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [errorAmount, setErrorAmount] = React.useState("");
  const [isPortrait, setIsPortrait] = useState(false);

  const getTemplate = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.getTemplate()
      .then((res) => {
        dispatch(setLoading(false));
        setIsPortrait(res.data.isPotrait);
        setBackground(res.data.background);
        API_VIRTUAL_CARD.getCardDetail(params.id)
          .then((res) => {
            setTemplate(res.data.data.template);
          })
          .catch((err) => {
            console.log(err);
           });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = async () => {
    if (amount === "") {
      setErrorAmount(t('withdraw_funds.nominal_withdrawal'));
    } else {
      dispatch(setLoading(true));
      await API_VIRTUAL_CARD.inquiryWithdrawCard(params.id, parseInt(amount))
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`summary`, {
            state: {
              data: res.data.data,
            },
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  React.useEffect(() => {
    getTemplate();
  }, []);

  return (
    <>
      <BrowserView>
        <WithdrawCardBalanceWeb
          handleOnClick={() => handleOnClick()}
          setAmount={(value: any) => setAmount(value)}
          setErrorAmount={(value: any) => setErrorAmount(value)}
          template={template}
          background={background}
          amount={amount}
          errorAmount={errorAmount}
          isPortrait={isPortrait}
        />
      </BrowserView>
      <MobileView>
        <WithdrawCardBalanceMobile
          handleOnClick={() => handleOnClick()}
          setAmount={(value: any) => setAmount(value)}
          setErrorAmount={(value: any) => setErrorAmount(value)}
          template={template}
          background={background}
          amount={amount}
          errorAmount={errorAmount}
          isPortrait={isPortrait}
        />
      </MobileView>
    </>
  );
};

export default WithdrawCardBalance;
