import React, { useEffect, useState } from "react";
import Header from "../_common/headerComponent";
import biaya_pendidikan from "../../assets/images/icon_ppob/biaya_pendidikan.svg";
import bpjs from "../../assets/images/icon_ppob/bpjs.svg";
import e_samsat from "../../assets/images/icon_ppob/e_samsat.svg";
import internet_tv_kabel from "../../assets/images/icon_ppob/internet_tv_kabel.svg";
import listrik from "../../assets/images/icon_ppob/listrik.svg";
import pajak_pbb from "../../assets/images/icon_ppob/pajak_pbb.svg";
import pulsa from "../../assets/images/icon_ppob/pulsa_data.svg";
import air_pdam from "../../assets/images/icon_ppob/air_pdam.svg";
// import PPOBBackground from "../../assets/images/bg_ppob.png";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const PPOBMobile = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const listMenu = [
    {
      name: t("ppob.creditData"),
      img: pulsa,
      url: `/${company?.initial}/digital-product/pulsa-data`,
    },
    {
      name: t("ppob.plnElectricity"),
      img: listrik,
      url: `/${company?.initial}/digital-product/pln`,
    },
    {
      name: t("ppob.waterPdam"),
      img: air_pdam,
      url: `/${company?.initial}/digital-product/pdam`,
    },
    {
      name: t("ppob.bpjs"),
      img: bpjs,
      url: `/${company?.initial}/digital-product/bpjs`,
    },
  ];

  const listMenu2 = [
    {
      name: t("ppob.internetCableTv"),
      img: internet_tv_kabel,
      url: `/${company?.initial}/digital-product/internet-tv`,
    },
    {
      name: t("ppob.pbbTax"),
      img: pajak_pbb,
      url: `/${company?.initial}/digital-product/pbb`,
    },
    {
      name: t("ppob.samsat"),
      img: e_samsat,
      url: `/${company?.initial}/digital-product/e-samsat`,
    },
    {
      name: t("ppob.costEducation"),
      img: biaya_pendidikan,
      url: `/${company?.initial}/digital-product/biaya-pendidikan`,
    },
  ];
  const [configColor, setConfigColor] = useState<any>({});
  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  useEffect(() => {
    getConfigColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header
        navbarText="PPOB"
        historyUrl={`/${company?.initial}/digital-product/history`}
      ></Header>
      <div
        className="container flex flex-col items-center md:gap-[16px] gap-[12px] justify-center bg-no-repeat bg-cover md:py-16 py-12"
        style={{
          backgroundImage: `linear-gradient(to bottom, ${configColor?.color1}, ${configColor?.color2})`,
        }}
      >
        <p className="font-semibold md:text-xl text-lg text-white text-center mb-0">
          {t("ppob.payVarious")}{" "}
          <span className="text-[#F6B557] italic">{t("ppob.bil")}</span>{" "}
          {t("ppob.sub")}
        </p>
        <p className="md:text-sm text-xs font-normal text-white text-center mb-0">
          {t("ppob.description")}
        </p>
      </div>
      <div className="container mt-6 md:mt-[64px]">
        <div className="w-full md:w-2/3 mx-auto">
          <div className="grid grid-cols-4 gap-6">
            {listMenu.map((item, index) => (
              <Link to={item.url} key={index} className="no-underline">
                <div className="flex flex-col items-center mb-10">
                  <div className="bg-white shadow-ppob md:p-[16px] p-[12px] rounded-[10px]">
                    <img src={item.img} alt="" />
                  </div>
                  <h3 className="md:text-base text-sm font-normal text-newColor text-center mt-2 mb-0">
                    {item.name}
                  </h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="w-full md:w-2/3 mx-auto">
          <div className="grid grid-cols-4 gap-6">
            {listMenu2.map((item, index) =>
              item.name === t("ppob.samsat") ||
              item.name === t("ppob.costEducation") ? (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() => toast.warning("Coming Soon")}
                >
                  <div className="flex flex-col items-center mb-10">
                    <div className="bg-white shadow-ppob md:p-[16px] p-[12px] rounded-[10px]">
                      <img src={item.img} alt="" />
                    </div>
                    <h3 className="md:text-base text-sm font-normal text-newColor text-center mt-2 mb-0">
                      {item.name}
                    </h3>
                  </div>
                </div>
              ) : (
                <Link to={item.url} key={index} className="no-underline">
                  <div className="flex flex-col items-center mb-10">
                    <div className="bg-white shadow-ppob md:p-[16px] p-[12px] rounded-[10px]">
                      <img src={item.img} alt="" />
                    </div>
                    <h3 className="md:text-base text-sm font-normal text-newColor text-center mt-2 mb-0">
                      {item.name}
                    </h3>
                  </div>
                </Link>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PPOBMobile;
