import React from "react";
import OtpInput from "react-otp-input";
import Header from "../../../_common/headerComponent";
import iconSuccess from "../../../../assets/images/iconSuccess.png";
import ConfirmationModal from "../../../_common/confirmationModal";

const ChangeCardSecurityCodeWeb = (props: any) => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile dark:bg-black">
      <Header></Header>

      {/* <ConfirmationModal
        showModal={props.showModal}
        hideModal={props.hideModal}
        confirm={props.confirm}
      /> */}

      <div
        className={`${props.createSuccessFully ? "hidden" : "container font-poppins"
          } md:pb-28`}
      >
        <div className="flex flex-col justify-center items-center mt-10 mb-6">
          <div className="md:w-2/3 w-full">
            <div className="flex flex-col gap-2 mb-4 relative items-center">
              <p className="text-base text-customBlack font-semibold text-center mb-0">
                {props.t('card_security_code.old_pin')}
              </p>
              <OtpInput
                value={props.oldSecurityCode}
                onChange={(code: React.SetStateAction<string>) => {
                  props.setOldSecurityCode(code);
                  props.setErrorOldSecurityCode("");
                }}
                numInputs={6}
                separator={<span>&nbsp;</span>}
                isInputNum
                isInputSecure
                inputStyle={{
                  width: 73,
                  height: 73,
                  margin: "0 0.25rem",
                  fontSize: "2rem",
                  fontWeight: 700,
                  borderRadius: 8,
                  border: "1px solid #26A69A",
                  color: "#26A69A",
                }}
              />
              <p className="text-base text-red pl-1 mb-0">
                {props.errorOldSecurityCode}
              </p>
            </div>
            <div className="flex flex-col gap-2 mb-4 relative items-center">
              <p className="text-base text-customBlack font-semibold text-center mb-0">
                {props.t('card_security_code.new_pin')}
              </p>
              <OtpInput
                value={props.newSecurityCode}
                onChange={(code: React.SetStateAction<string>) => {
                  props.setNewSecurityCode(code);
                  props.setErrorNewSecurityCode("");
                }}
                numInputs={6}
                separator={<span>&nbsp;</span>}
                isInputNum
                isInputSecure
                inputStyle={{
                  width: 73,
                  height: 73,
                  margin: "0 0.25rem",
                  fontSize: "2rem",
                  fontWeight: 700,
                  borderRadius: 8,
                  border: "1px solid #26A69A",
                  color: "#26A69A",
                }}
              />
              <p className="text-base text-red pl-1 mb-0">
                {props.errorNewSecurityCode}
              </p>
            </div>
            <div className="flex flex-col gap-2 mb-4 relative items-center">
              <p className="text-base text-customBlack font-semibold text-center mb-0">
                {props.t('card_security_code.confirm_pin_new')}
              </p>
              <OtpInput
                value={props.confirmNewSecurityCode}
                onChange={(code: React.SetStateAction<string>) => {
                  props.setConfirmNewSecurityCode(code);
                  props.setErrorConfirmNewSecurityCode("");
                }}
                numInputs={6}
                separator={<span>&nbsp;</span>}
                isInputNum
                isInputSecure
                inputStyle={{
                  width: 73,
                  height: 73,
                  margin: "0 0.25rem",
                  fontSize: "2rem",
                  fontWeight: 700,
                  borderRadius: 8,
                  border: "1px solid #26A69A",
                  color: "#26A69A",
                }}
              />
              <p className="text-base text-red pl-1 mb-0">
                {props.errorConfirmNewSecurityCode}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${props.createSuccessFully ? "container font-poppins" : "hidden"
          } md:pb-28`}
      >
        <div className="lg:w-1/2 sm:w-3/4 md:w-3/4 m-auto">
          <div className="relative items-center mt-4 mb-3">
            <img
              src={iconSuccess}
              alt="user"
              className="mx-auto md:w-max w-[180px]"
            />
          </div>
          <div className="w-100 text-center mb-3">
            <h1 className="font-bold md:text-2xl text-xl">
              {props.t('card_security_code.pin_successfully_changed')}
            </h1>
          </div>
        </div>
      </div>
      <div
        className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <button
          type="button"
          style={{
            backgroundColor: `${props.company?.color?.color1 !== undefined
              ? props.company?.color?.color1
              : "#26A69A"
              }`,
          }}
          onClick={props.handleOnClick}
          className="w-full md:p-[1rem] p-[12px] rounded-[10px] text-white font-semibold text-base bg-green"
        >
          {props.createSuccessFully ? props.t('card_security_code.done') : props.t('card_security_code.confirm')}
        </button>
      </div>
    </section>
  );
};

export default ChangeCardSecurityCodeWeb;
