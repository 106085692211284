/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ListProductsMobile from "./listProductsMobile";
import ListProductsWeb from "./listProductsWeb";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useLocation, useParams } from "react-router-dom";
import User, { MarketplaceConfig } from "../../../core/models/user";
import Token from "../../../core/models/token";
import { find } from "lodash";
import Company from "../../../core/models/company";
import { TimeZone } from "../../../config/global";

const ListProducts = () => {
  const marketplaceConfig: MarketplaceConfig = useAppSelector(
    (state) => state.marketplaceConfig.marketplaceConfig
  );
  const params= useParams()
  const [products, setProducts] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [level2Categories, setLevel2Categories] = React.useState<any[]>([]);
  const [level3Categories, setLevel3Categories] = React.useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});
  const [categoryNames, setCategoryNames] = React.useState<any>({
    level1: "",
    level2: "",
    level3: "",
  });
  const [isActive, setIsActive] = React.useState("");
  const [isActive2, setIsActive2] = React.useState("");
  const [dir, setDir] = useState(1);
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [categoryId, setCategoryId] = useState<any>("");
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const [mNameNoLogin, setMNameNoLogin] = useState("");
  const [pNameNoLogin, setPNameNoLogin] = useState("");

  const [categoryNoLogin, setCategoryNoLogin] = useState<any[]>([]);
  const [productNoLogin, setProductNoLogin] = useState<any[]>([]);
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [isLogin, setIsLogin] = React.useState(user && newToken);
  const pName: string = marketplaceConfig?.productName || "";
  const [id, setId] = useState("");
  const [categoryIdNoLogin, setcategoryIdNoLogin] = useState(
    location?.state?.category?.id
  );
  const [totalElementsNoLogin, setTotalElementsNoLogin] = useState(0);
  const time = TimeZone();

  const tempCategoryNames = {
    level1: "",
    level2: "",
    level3: "",
  };

  const getAllProducts = async (
    currentCategoryId: string = categoryId,
    currentPage: number = page,
    productName: string = ""
  ) => {
    let query = {
      timezone: time,
      page: currentPage,
      size: 10,
      sortBy: dir === 1 ? "-createdDate" : "createdDate",
      categoryId: currentCategoryId,
      productName,
    };
    dispatch(setLoading(true));
    await API_COMMERCE.getAllProducts(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setProducts(products.concat(res.data.data));
        } else {
          setProducts(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCategories = async (
    categoryName: string = "",
    level: number = 1,
    parentCategoryId: string = ""
  ) => {
    dispatch(setLoading(true));
    let query = {
      categoryName: "",
      level,
      parentCategoryId,
    };
    setCategoryId(parentCategoryId);
    await API_COMMERCE.getComboCategory(query)
      .then((res) => {
        dispatch(setLoading(false));
        switch (level) {
          case 2:
            setLevel2Categories(res.data.data);
            setSelectedCategory({});
            if (isActive !== parentCategoryId) {
              setIsActive(parentCategoryId);
              setCategoryNames({
                level1: categoryName,
                level2: "",
                level3: "",
              });
            } else {
              setIsActive("");
              setIsActive2("");
              setCategoryNames(tempCategoryNames);
            }
            break;

          case 3:
            setLevel3Categories(res.data.data);
            setSelectedCategory({});
            if (isActive2 !== parentCategoryId) {
              setIsActive2(parentCategoryId);
              setCategoryNames({ ...categoryNames, level2: categoryName });
            } else {
              setIsActive2("");
              setCategoryNames({ ...categoryNames, level2: "", level3: "" });
            }
            break;

          default:
            setCategories(res.data.data);
            setIsActive("");
            setIsActive2("");
            setSelectedCategory({});
            break;
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  function currentSize() {
    let a = products?.length;
    let b = a / page;

    return b;
  }

  const addMore = async () => {
    setPage(page + 1);
    getAllProducts(categoryId, page + 1);
  };

  // No Login

  function currentSizeNoLogin() {
    let a = productNoLogin?.length;
    let b = a / page;

    return b;
  }

  const addMoreNoLogin = async () => {
    setPage(page + 1);
    getProductNoLogin(categoryId, page + 1);
  };

  const getCategoryNoLogin = async (
    categoryName: string = "",
    level: number = 1,
    parentCategoryId: string = "",
    index: number = 0,
    index2: number = 0
  ) => {
    dispatch(setLoading(true));
    let query = {
      categoryName,
      level,
      parentCategoryId,
    };
    await API_COMMERCE.categoryNoLogin(company.id, query)
      .then((res) => {
        dispatch(setLoading(false));
        switch (level) {
          case 2:
            setLevel2Categories(res.data.data[index].childCategories);
            setSelectedCategory({});
            if (isActive !== parentCategoryId) {
              setIsActive(parentCategoryId);
              setCategoryNames({
                level1: categoryName,
                level2: "",
                level3: "",
              });
            } else {
              setIsActive("");
              setIsActive2("");
              setCategoryNames(tempCategoryNames);
            }
            break;

          case 3:
            setLevel3Categories(
              res.data.data[index].childCategories[index2].childCategories
            );
            setSelectedCategory({});
            if (isActive2 !== parentCategoryId) {
              setIsActive2(parentCategoryId);
              setCategoryNames({ ...categoryNames, level2: categoryName });
            } else {
              setIsActive2("");
              setCategoryNames({ ...categoryNames, level2: "", level3: "" });
            }
            break;

          default:
            setCategoryNoLogin(res.data.data);
            setIsActive("");
            setIsActive2("");
            setSelectedCategory({});
            break;
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProductNoLogin = async (
    currentCategoryId: string = categoryIdNoLogin,
    currentPage: number = page,
    productName: string = ""
  ) => {
    let query = {
      timezone: time,
      page: currentPage,
      size: 10,
      sortBy: dir === 1 ? "-createdDate" : "createdDate",
      categoryId: currentCategoryId,
      productName,
    };
    dispatch(setLoading(true));
    await API_COMMERCE.productNoLogin(company.id, query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setProductNoLogin(productNoLogin.concat(res.data.data));
        } else {
          setProductNoLogin(res.data.data);
        }
        setTotalElementsNoLogin(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getMnameConfigNoLogin = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.marketplaceNameNoLogin(company.id)
      .then((res) => {
        if (Object.keys(res.data.data).length === 0) {
          setMNameNoLogin("Toko");
          setPNameNoLogin("Produk");
        } else {
          if (
            res.data.data.merchantName === "" ||
            res.data.data.merchantName === null
          ) {
            setMNameNoLogin("Toko");
          } else {
            setMNameNoLogin(res.data.data.merchantName);
          }

          if (
            res.data.data.productName === "" ||
            res.data.data.merchantName === null
          ) {
            setPNameNoLogin("Produk");
          } else {
            setPNameNoLogin(res.data.data.productName);
          }
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  // Batas No Login

  React.useEffect(() => {
    getProductNoLogin();
    if (location.state !== null) {
      setCategoryId(params.id);
      getAllProducts(location.state.category.id);
      setCategoryNames(location.state.category_names);
    } else {
      getAllProducts();
      setCategoryNames(undefined);
    }
    getMnameConfigNoLogin();
    setIsLogin(user && newToken);
  }, []);
  
  return (
    <>
      <BrowserView>
        <ListProductsWeb
          getAllProducts={(currentCategoryId: string, currentPage: number, productName: string) => getAllProducts(currentCategoryId, currentPage, productName)}
          getProductNoLogin={(currentCategoryId: string, currentPage: number, productName: string) => getProductNoLogin(currentCategoryId, currentPage, productName)}
          getCategories={(categoryName: string, level: number, parentCategoryId: string) => getCategories(categoryName, level, parentCategoryId)}
          getCategoryNoLogin={(categoryName: string, level: number, parentCategoryId: string, index: number, index2: number) => getCategoryNoLogin(categoryName, level, parentCategoryId, index, index2)}
          addMore={() => addMore()}
          addMoreNoLogin={() => addMoreNoLogin()}
          currentSize={currentSize}
          currentSizeNoLogin={currentSizeNoLogin}
          marketplaceConfig={marketplaceConfig}
          products={products}
          categories={categories}
          level2Categories={level2Categories}
          level3Categories={level3Categories}
          selectedCategory={selectedCategory}
          categoryNames={categoryNames}
          isActive={isActive}
          isActive2={isActive2}
          dir={dir}
          totalElements={totalElements}
          showModal={showModal}
          keyword={keyword}
          categoryId={categoryId}
          mNameNoLogin={mNameNoLogin}
          pNameNoLogin={pNameNoLogin}
          categoryNoLogin={categoryNoLogin}
          productNoLogin={productNoLogin}
          totalElementsNoLogin={totalElementsNoLogin}
          company={company}
          isLogin={isLogin}
          pName={pName}
          id={id}
          tempCategoryNames={tempCategoryNames}
          setShowModal={(value: any) => setShowModal(value)}
          setDir={(value: any) => setDir(value)}
          setCategoryNames={(value: any) => setCategoryNames(value)}
          setSelectedCategory={(value: any) => setSelectedCategory(value)}
          setId={(value: any) => setId(value)}
          setCategoryId={(value: any) => setCategoryId(value)}
          setPage={(value: any) => setPage(value)}
          setcategoryIdNoLogin={(value: any) => setcategoryIdNoLogin(value)}
          setKeyword={(value: any) => setKeyword(value)}
        />
      </BrowserView>
      <MobileView>
        <ListProductsMobile
          getAllProducts={(currentCategoryId: string, currentPage: number, productName: string) => getAllProducts(currentCategoryId, currentPage, productName)}
          getProductNoLogin={(currentCategoryId: string, currentPage: number, productName: string) => getProductNoLogin(currentCategoryId, currentPage, productName)}
          getCategories={(categoryName: string, level: number, parentCategoryId: string) => getCategories(categoryName, level, parentCategoryId)}
          getCategoryNoLogin={(categoryName: string, level: number, parentCategoryId: string, index: number, index2: number) => getCategoryNoLogin(categoryName, level, parentCategoryId, index, index2)}
          addMore={() => addMore()}
          addMoreNoLogin={() => addMoreNoLogin()}
          currentSize={currentSize}
          currentSizeNoLogin={currentSizeNoLogin}
          marketplaceConfig={marketplaceConfig}
          products={products}
          categories={categories}
          level2Categories={level2Categories}
          level3Categories={level3Categories}
          selectedCategory={selectedCategory}
          categoryNames={categoryNames}
          isActive={isActive}
          isActive2={isActive2}
          dir={dir}
          totalElements={totalElements}
          showModal={showModal}
          keyword={keyword}
          categoryId={categoryId}
          mNameNoLogin={mNameNoLogin}
          pNameNoLogin={pNameNoLogin}
          categoryNoLogin={categoryNoLogin}
          productNoLogin={productNoLogin}
          totalElementsNoLogin={totalElementsNoLogin}
          company={company}
          isLogin={isLogin}
          pName={pName}
          id={id}
          tempCategoryNames={tempCategoryNames}
          setShowModal={(value: any) => setShowModal(value)}
          setDir={(value: any) => setDir(value)}
          setCategoryNames={(value: any) => setCategoryNames(value)}
          setSelectedCategory={(value: any) => setSelectedCategory(value)}
          setId={(value: any) => setId(value)}
          setCategoryId={(value: any) => setCategoryId(value)}
          setPage={(value: any) => setPage(value)}
          setcategoryIdNoLogin={(value: any) => setcategoryIdNoLogin(value)}
          setKeyword={(value: any) => setKeyword(value)}
        />
      </MobileView>
    </>
  );
};

export default ListProducts;
