import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import MultiLanguageWeb from './multiLanguageWeb'
import MultiLanguageMobile from './multiLanguageMobile'

function MultiLanguage() {
    return (
        <>
            <BrowserView>
                <MultiLanguageWeb></MultiLanguageWeb>
            </BrowserView>
            <MobileView>
                <MultiLanguageMobile></MultiLanguageMobile>
            </MobileView>
        </>
    )
}

export default MultiLanguage