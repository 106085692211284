import Lottie from "react-lottie";
import animationData from "./animationData.json";

const Loading = ({ isLoading }: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    isLoading && (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-opacity-60 bg-gray-900 z-[1000]">
      <Lottie
        options={defaultOptions}
        height={150} // Adjust height and width according to your needs
        width={150}
      />
      <div className="text-white text-center text-sm px-6">
        Silahkan tunggu beberapa saat. <br/>Aktivitas/transaksi Anda sedang kami
        proses.
      </div>
    </div>
    )
  );
};

export default Loading;
