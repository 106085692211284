import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SecurityCodeWeb from "./securityCodeWeb";
import SecurityCodeMobile from "./securityCodeMobile";

const SecurityCode = () => {
  return (
    <>
      <BrowserView>
        <SecurityCodeWeb></SecurityCodeWeb>
      </BrowserView>
      <MobileView>
        <SecurityCodeMobile></SecurityCodeMobile>
      </MobileView>
    </>
  );
};

export default SecurityCode;
