import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ZakatAddWeb from "./zakatAddWeb";
import ZakatAddMobile from "./zakatAddMobile";

const ZakatAdd = () => {
  return (
    <>
      <BrowserView>
        <ZakatAddWeb></ZakatAddWeb>
      </BrowserView>
      <MobileView>
        <ZakatAddMobile></ZakatAddMobile>
      </MobileView>
    </>
  );
};

export default ZakatAdd;
