/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import WithdrawCardSummaryWeb from "./withdrawCardSummaryWeb";
import WithdrawCardSummaryMobile from "./withdrawCardSummaryMobile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "../../../config/global";
import Company from "../../../core/models/company";
import { useAppSelector } from "../../../core/feature/hooks";
import moment from "moment";
import { useTranslation } from "react-i18next";

const WithdrawCardSummary = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const pin: number = useAppSelector((state) => state.pin.pin);
  let navigate = useNavigate();
  let location: any = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const [dataPost, setDataPost] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any>({});
  const [inquiryId, setInquiryId] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  const handleOnClick = () => {
    if (pin === 1) {
      setShowModal(true);
    } else {
      navigate(`/${company?.initial}/create-security-code`, {
        replace: true,
        state: {
          data,
          type: "withdraw-card",
          url: `virtual-card/list/${params.id}/withdraw/summary`,
        },
      });
    }
  };

  React.useEffect(() => {
    setData(location.state.data.detail);
    setInquiryId(location.state.data.inquiryId);
    let dataOrder = location.state.data.detail;
    let newData = [
      {
        property: t('virtual_card.date'),
        value: moment(dataOrder.createdTime).format("DD/MM/YYYY HH:mm:ss"),
      },
      {
        property: t('virtual_card.member_name'),
        value: dataOrder.memberName,
      },
      {
        property: t('virtual_card.payment_type'),
        value: t('withdraw_funds.card_balance_withdrawal'),
      },
      {
        property: t('virtual_card.card_name'),
        value: dataOrder.cardName,
      },
      {
        property: t('virtual_card.type_card'),
        value: dataOrder.cardType.map((res: any, i: any) => {
          return <div key={i}>{res}</div>;
        }),
      },
      {
        property: t('withdraw_funds.purpose_disbursement'),
        value: t('virtual_card.balance_closeloop'),
      },
      {
        property: t('withdraw_funds.nominal'),
        value: formatCurrency(dataOrder.amount),
      },
      {
        property: t('withdraw_funds.adminFee'),
        value: formatCurrency(dataOrder.totalCharges),
      },
    ];
    setDataPost(newData);
  }, []);

  return (
    <>
      <BrowserView>
        <WithdrawCardSummaryWeb
          setShowModal={(value: any) => setShowModal(value)}
          handleOnClick={() => handleOnClick()}
          company={company}
          dataPost={dataPost}
          data={data}
          inquiryId={inquiryId}
          showModal={showModal}
        />
      </BrowserView>
      <MobileView>
        <WithdrawCardSummaryMobile
          setShowModal={(value: any) => setShowModal(value)}
          handleOnClick={() => handleOnClick()}
          company={company}
          dataPost={dataPost}
          data={data}
          inquiryId={inquiryId}
          showModal={showModal}
        />
      </MobileView>
    </>
  );
};

export default WithdrawCardSummary;
