import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as API from "../../core/service/api";
import { setUser } from "../../core/feature/user/userSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";

const RedirectToken = () => {
  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let destination: any = query.get("destination");
  let url = decodeURIComponent(destination);
  let token = query.get("token");

  const cekToken = async () => {
    dispatch(setLoading(true));
    await API.cekTokenRedirectToken(token)
      .then((res) => {
        dispatch(setUser(res.data.data));
        let userLogin = [
          {
            token: res.data.data.authToken,
            id: res.data.data.noId,
            initial: res.data.data.companyInitial,
          },
        ];
        dispatch(setUserLogin(userLogin));
        dispatch(setLoading(false));
        redirect(url);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const redirect = (res: any) => {
    document.location.href = `${res}`;
  };

  useEffect(() => {
    cekToken();
  }, []);

  return <div></div>;
};

export default RedirectToken;
