import React, { FC, useEffect, useRef, useState } from "react";
import * as API_AUTH from "../../core/service/api_auth";
import * as API_BALANCE from "../../core/service/api_balance";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppSelector, useAppDispatch } from "../../core/feature/hooks";
import { Link, useNavigate } from "react-router-dom";
import Company from "../../core/models/company";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { removeState } from "../../core/feature/browser-storage/browser-storage";
import { StatefulPinInput } from "react-input-pin-code";
import ConfirmationModal from "./confirmationModal";
import { backTo } from "../../config/global";
import { useTranslation } from "react-i18next";

interface ReauthenticateProps {
  show: boolean;
  onHide: any;
  transactionType?: string;
  width: number;
  height: number;
  margin: string;
  redirect?: any;
  inquiryId?: string;
  transactionId?: string;
  isNewDesign?: boolean;
  fontSize?: string;
  amount?: string;
  isPaymentOpen?: boolean;
  dataOrder?: any;
  balanceCode?: string;
  setShowDetailPayment?: any;
}

const Reauthenticate: FC<ReauthenticateProps> = (props): JSX.Element => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [NewValues, setNewValues] = React.useState(["", "", "", "", "", ""]);
  const [showModal, setShowModal] = React.useState(false);
  const [max, setMax] = React.useState<any>();
  const { t } = useTranslation();

  const hideModal = () => setShowModal(false);

  const reauthenticate = async () => {
    dispatch(setLoading(true));
    let data = {
      securityCode: NewValues.join(""),
      transactionType: props.transactionType,
    };
    let data2 = {
      securityCode: NewValues.join(""),
    };
    if (props.transactionType === "QR_CODE" && props.setShowDetailPayment) {
      props.setShowDetailPayment(false);
    }
    API_AUTH.reauthenticate(props.transactionType ? data : data2)
      .then((res) => {
        // dispatch(setLoading(false));
        setNewValues(["", "", "", "", "", ""]);
        setMax("");
        let securityCodeToken = res.data.data.securityCodeToken;
        if (
          props.transactionType === "QR_CODE" ||
          props.transactionType === "MARKETPLACE" ||
          props.transactionType === "POS" ||
          props.transactionType === "FNB"
        ) {
          let data = {
            balanceCode: props.balanceCode,
          };
          let data2 = {
            balanceCode: props.balanceCode,
            amount: props.amount,
          };
          if (props.isPaymentOpen) {
            API_BALANCE.paymentInquiryClose(
              props.inquiryId,
              data2,
              securityCodeToken
            )
              .then((res) => {
                dispatch(setLoading(false));
                if (props.setShowDetailPayment) {
                  props.setShowDetailPayment(true);
                }
                props.onHide();
                if (props.redirect)
                  navigate(props.redirect, {
                    replace: true,
                    state: {
                      id: res.data.data.transactionId,
                      data: { ...props.dataOrder, ...res.data.data },
                    },
                  });
              })
              .catch((err) => {
                dispatch(setLoading(false));
                if (props.setShowDetailPayment) {
                  props.setShowDetailPayment(true);
                }
                backTo(`/${company?.initial}`);
              });
          } else {
            API_BALANCE.paymentInquiryClose(
              props.inquiryId,
              data,
              securityCodeToken
            )
              .then((res) => {
                dispatch(setLoading(false));
                props.onHide();
                if (props.redirect)
                  navigate(props.redirect, {
                    replace: true,
                    state: {
                      id: res.data.data.transactionId,
                      data: props.dataOrder,
                    },
                  });
              })
              .catch((err) => {
                dispatch(setLoading(false));
                backTo(`/${company?.initial}`);
              });
          }
        } else {
          let data = {
            balanceCode: "Closepay",
          };
          API_BALANCE.paymentInquiryClose(
            props.inquiryId,
            data,
            securityCodeToken
          )
            .then((res) => {
              dispatch(setLoading(false));
              props.onHide();
              if (props.isNewDesign) {
                removeState("virtual_card");
                removeState("show_balance_card");
                if (props.redirect)
                  navigate(props.redirect + `/${res.data.data.transactionId}`, {
                    replace: true,
                    state: {
                      id: res.data.data.transactionId,
                    },
                  });
              } else {
                if (props.redirect)
                  navigate(props.redirect, {
                    replace: true,
                    state: {
                      id: props.transactionId,
                    },
                  });
              }
            })
            .catch((err) => {
              dispatch(setLoading(false));
            });
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setNewValues(["", "", "", "", "", ""]);
        setMax("");
      });
  };

  // batas

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (
    { target }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const { value } = target;
    const newOtp: string[] = [...NewValues];
    newOtp[index] = value.substring(value.length - 1);
    setNewValues(newOtp);
    if (!value) setActiveIndex(index - 1);
    else setActiveIndex(index + 1);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    reauthenticate();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeIndex]);

  return (
    <section className="font-montserrat">
      {/* <ConfirmationModal
        showModal={showModal}
        hideModal={hideModal}
        confirm={reauthenticate}
      /> */}

      {props.isNewDesign ? (
        <div
          className={`${props.show ? "block" : "hidden"} relative z-10`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 sm:items-center sm:p-0">
              <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg p-3 bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:p-[1rem]">
                <div className="flex items-end">
                  <button
                    type="button"
                    onClick={() => {
                      props.onHide();
                      setNewValues(["", "", "", "", "", ""]);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} size="2x" />
                  </button>
                </div>
                <div className="mb-6 p-2">
                  <h3 className="md:text-base text-sm font-medium">
                    {t('securityCodeWeb.insertPinMember')}
                  </h3>
                  <Link
                    to={`/${company?.initial}/profile/security-code/forget`}
                    replace
                    className="text-[#1AA7EC]"
                  >
                    {t('securityCodeWeb.forgetPin')}
                  </Link>
                </div>
                <div className="w-full flex flex-col items-center">
                  {/* <StatefulPinInput
                    length={6}
                    placeholder=""
                    initialValue={NewValues}
                    onChange={(value, index, values) => {
                      setNewValues(values);
                    }}
                    type="number"
                    mask={true}
                    focusBorderColor="#26A69A"
                    validBorderColor="#26A69A"
                  /> */}
                  <form onSubmit={handleSubmit}>
                    <div className="flex flex-row gap-3 relative z-10">
                      {NewValues.map((res, i) => {
                        return (
                          <div
                            className={
                              NewValues[i] === "undefined" ||
                                NewValues[i] === ""
                                ? "border outline-none text-center rounded-full w-8 h-8"
                                : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-full w-8 h-8"
                            }
                            key={i}
                          ></div>
                        );
                      })}
                    </div>
                    <div className="w-full -mt-9 relative z-20">
                      <input
                        className="w-full bg-transparent border-none indent-[-999em] caret-transparent text-transparent focus:outline-none py-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        type="number"
                        value={max}
                        onChange={(e) => {
                          if (e.target.value.length > 6) {
                            setMax(max);
                          } else {
                            const value: any = e.target.value.split("");
                            const newValue: any = NewValues;
                            const x: any = [
                              `${(newValue[0] = value[0])}`,
                              `${(newValue[1] = value[1])}`,
                              `${(newValue[2] = value[2])}`,
                              `${(newValue[3] = value[3])}`,
                              `${(newValue[4] = value[4])}`,
                              `${(newValue[5] = value[5])}`,
                            ];
                            setNewValues(x);
                            setMax(e.target.value);
                          }
                        }}
                      ></input>
                    </div>
                  </form>
                  <div className="mt-10 mb-3 text-left w-full">
                    <button
                      disabled={NewValues.join("").length < 6}
                      onClick={() => {
                        // setShowModal(true);
                        reauthenticate();
                        props.onHide();
                      }}
                      style={{
                        backgroundColor:
                          NewValues.join("").length < 6
                            ? "#9A9999"
                            : company?.color?.color1 !== undefined
                              ? company?.color?.color1
                              : "#26A69A",
                      }}
                      className="w-full md:py-[16px] p-[10px] rounded-lg text-white font-semibold md:text-base text-sm disabled:bg-slate-50 md:mt-6 mt-[1rem]"
                    >
                      {t('securityCodeWeb.confirm')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${props.show ? "block" : "hidden"} relative z-10`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col justify-center items-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]">
                <div className="flex flex-col w-full">
                  <div className="space-between">
                    <h4 className="md:text-2xl text-sm text-black font-semibold mb-0">
                      Security PIN
                    </h4>
                    <button type="button" onClick={props.onHide}>
                      <FontAwesomeIcon icon={faTimes} size="2x" />
                    </button>
                  </div>
                  <p className="md:text-base text-sm text-black mb-1 text-start">
                    {t('securityCodeWeb.insertPin')}
                  </p>
                  <Link
                    to={`/${company?.initial}/profile/security-code/forget`}
                    className="mb-3 no-underline text-start md:text-base text-sm"
                  >
                    {t('securityCodeWeb.forgetPin')}
                  </Link>
                </div>
                {/* <StatefulPinInput
                  length={6}
                  placeholder=""
                  initialValue={NewValues}
                  onChange={(value, index, values) => {
                    setNewValues(values);
                  }}
                  type="number"
                  mask={true}
                  focusBorderColor="#26A69A"
                  validBorderColor="#26A69A"
                /> */}
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-row gap-3 relative z-10">
                    {NewValues.map((res, i) => {
                      return (
                        <div
                          className={
                            NewValues[i] === "undefined" || NewValues[i] === ""
                              ? "border outline-none text-center rounded-full w-8 h-8"
                              : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-full w-8 h-8"
                          }
                          key={i}
                        ></div>
                      );
                    })}
                  </div>
                  <div className="w-full px-4 -mt-9 relative z-20">
                    <input
                      className="w-full bg-transparent border-none indent-[-999em] caret-transparent text-transparent focus:outline-none py-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      type="number"
                      value={max}
                      onChange={(e) => {
                        if (e.target.value.length > 6) {
                          setMax(max);
                        } else {
                          const value: any = e.target.value.split("");
                          const newValue: any = NewValues;
                          const x: any = [
                            `${(newValue[0] = value[0])}`,
                            `${(newValue[1] = value[1])}`,
                            `${(newValue[2] = value[2])}`,
                            `${(newValue[3] = value[3])}`,
                            `${(newValue[4] = value[4])}`,
                            `${(newValue[5] = value[5])}`,
                          ];
                          setNewValues(x);
                          setMax(e.target.value);
                        }
                      }}
                    ></input>
                  </div>
                </form>
                <button
                  disabled={NewValues.join("").length < 6}
                  onClick={() => reauthenticate()}
                  style={{
                    backgroundColor:
                      NewValues.join("").length < 6
                        ? "#9A9999"
                        : company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A",
                  }}
                  className="w-full md:py-[16px] p-[10px] rounded-lg text-white font-semibold md:text-base text-sm disabled:bg-slate-50 md:mt-6 mt-[1rem]"
                >
                  {t('securityCodeWeb.confirm')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Reauthenticate;
