import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import GetAllBankAccountWeb from "./getAllBankAccountWeb";
import GetAllBankAccountMobile from "./getAllBankAccountMobile";

const GetAllBankAccount = () => {
  return (
    <>
      <BrowserView>
        <GetAllBankAccountWeb></GetAllBankAccountWeb>
      </BrowserView>
      <MobileView>
        <GetAllBankAccountMobile></GetAllBankAccountMobile>
      </MobileView>
    </>
  );
};

export default GetAllBankAccount;
