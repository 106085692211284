import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIndex, map } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { imageOnError } from "../../config/global";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setToken } from "../../core/feature/token/tokenSlice";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
// import User from "../../core/models/user";
import UserLogin from "../../core/models/userLogin";
import { cekTokenAccount } from "../../core/service/api";
import ToastConfig from "../../config/Toast";
import { useTranslation } from "react-i18next";

const SwitchAccount = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  // const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserLogin[]>([]);
  const [maxUser] = useState(5);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userLogin) return setUsers([...userLogin]);
    return navigate(`/${company?.initial}/login`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAccount = (item: UserLogin) => {
    cekTokenAccount(item.token)
      .then((res) => {
        var newToken = token ? [...token] : [];
        let indexToken = findIndex(token, { cid: company.initial });

        if (indexToken < 0) {
          newToken.push({
            cid: company.initial,
            token: item.token,
          });
        } else {
          if (newToken[indexToken]?.token === item.token)
            return navigate(`/${company?.initial}`);
          newToken[indexToken] = {
            cid: company.initial,
            token: item.token,
          };
        }
        dispatch(setToken(newToken));
        navigate(`/${company?.initial}`);
      })
      .catch((err) => { });
  };

  return (
    <div className="h-full m-4 flex flex-col gap-2">
      {map(users, (item) => (
        <div
          className="flex gap-2 items-center justify-start hover:bg-blue-gray-50 hover:cursor-pointer py-2 rounded-lg px-8"
          onClick={() => {
            changeAccount(item);
          }}
        >
          <img
            className="rounded-full bg-gray-400"
            style={{ width: "70px", maxHeight: "70px" }}
            src={item.photo || ""}
            alt={item.photo}
            onError={imageOnError}
          />
          <span className="w-[10rem] overflow-hidden whitespace-nowrap text-ellipsis">
            {item.name}
          </span>
        </div>
      ))}

      <div
        className="flex gap-2 items-center justify-start  text-[#D9D9D9] border-[#D9D9D9] border-[1px] hover:bg-blue-gray-50 hover:cursor-pointer py-2 rounded-lg px-8"
        onClick={() => {
          if (userLogin && userLogin.length < maxUser)
            return navigate(`/${company?.initial}/login`);
          toast(
            t('switchAccount.maxAccountWarning', { maxUser }),
            ToastConfig.warning
          );
        }}
      >
        <span className="rounded-full border-[#D9D9D9] border-[1px] w-[70px] h-[70px] flex justify-center items-center">
          <FontAwesomeIcon
            style={{ width: "50px", height: "50px" }}
            icon={faPlus}
            color="#D9D9D9"
          ></FontAwesomeIcon>
        </span>
        <span className="w-[10rem] text-ellipsis overflow-hidden whitespace-nowrap">
          {t('switchAccount.addAccount')}
        </span>
      </div>
    </div>
  );
};

export default SwitchAccount;
