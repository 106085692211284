/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CommerceOrderDetailMobile from "./commerceOrderDetailMobile";
import CommerceOrderDetailWeb from "./commerceOrderDetailWeb";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Token from "../../../core/models/token";
import Company from "../../../core/models/company";
import { findIndex } from "lodash";
import { toast } from "react-hot-toast";
import User, { MarketplaceConfig } from "../../../core/models/user";
import _ from "lodash";
import ToastConfig from "../../../config/Toast";
import { Dialog, Transition } from "@headlessui/react";
import download from "../../../assets/images/download-document.svg";
import {
  saveState,
  removeState,
  loadState,
} from "../../../core/feature/browser-storage/browser-storage";
import { useTranslation } from "react-i18next";

const CommerceOrderDetail = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const marketplaceConfig: MarketplaceConfig = useAppSelector(
    (state) => state.marketplaceConfig.marketplaceConfig
  );
  const [products, setProducts] = useState<any>({});
  const [uniqueProduct, setUniqueProduct] = useState<any>([]);
  const [history, setHistory] = useState<any>({});
  const [emailUser, setEmailUser] = useState("");
  const [currentIndex, setCurrentIndex] = useState<any>({});
  const [showForm, setShowForm] = React.useState("");
  const [selectedProductNotes, setSelectedProductNotes] = React.useState<any>(
    {}
  );
  let redirectCustom = window.location.hostname;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  let redirectCO = loadState("redirectCO");

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const getHistoryDetail = async (trxId: any) => {
    dispatch(setLoading(true));
    await API_COMMERCE.getDetailOrderHistories(trxId)
      .then((res) => {
        dispatch(setLoading(false));
        setHistory(res.data.data);
        const unique = _.uniqBy(res.data.data.products, "productId");
        setUniqueProduct(unique);

        const ld: any = _.groupBy(res.data.data.products, "productId");
        setProducts(ld);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const downloadDoc = async (id: string) => {
    dispatch(setLoading(true));

    if (emailUser === null || emailUser === "") {
      dispatch(setLoading(false));
      openModal();
    } else {
      API_COMMERCE.downloadOrderPDF(id, emailUser).then((res) => {
        dispatch(setLoading(false));
        toast.success(`${t('history.fileEmail')} ${emailUser}`);
      });
    }
  };

  const downloadDocModal = async (id: string) => {
    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (emailUser === "")
      return toast(t('history.insertErrorEmail'), ToastConfig.warning);

    if (emailUser && !emailUser.match(regexEmail))
      return toast(t('history.emailNovalid'), ToastConfig.warning);

    closeModal();
    API_COMMERCE.downloadOrderPDF(id, emailUser).then((res) => {
      toast.success(`${t('history.fileEmail')} ${emailUser}`);
    });
  };

  const getAlamat = (address: any) => {
    let province = address?.location.province?.name;
    let country = address?.location.country?.name;
    let city = address?.location.city?.name;
    let regency = address?.location.suburb?.name;
    let postcode = address?.location?.postcode;
    return (
      regency + ", " + city + ", " + province + ", " + country + " " + postcode
    );
  };

  const getStatus = (stat: any) => {
    let status = {
      textColor: "",
      text: "",
      bgColor: "",
    };
    switch (stat) {
      case "WAITING_PAYMENT":
        status.textColor = "text-[#FFFFFF]";
        status.bgColor = "bg-[#FDBA1D]";
        status.text = t('history.waitingPayment');
        break;
      case "CREATED":
        status.textColor = "text-[#1AA7EC]";
        status.bgColor = "bg-[#1AA7EC52]";
        status.text = t('history.created');
        break;
      case "CANCELED":
        status.textColor = "text-[#9A9999]";
        status.bgColor = "bg-[#9a999952]";
        status.text = t('history.canceled');
        break;
      case "PROCESSED":
        status.textColor = "text-[#FFFFFF]";
        status.bgColor = "bg-[#E7DE1A]";
        status.text = t('history.processed');
        break;
      case "REJECTED":
        status.textColor = "text-[#EC1A1A]";
        status.bgColor = "bg-[#EC1A1A52]";
        status.text = t('history.rejected');
        break;
      case "CONFIRMED":
        status.textColor = "text-[#26BC63]";
        status.bgColor = "bg-[#9DF9C0]";
        status.text = t('history.confirmed');
        break;
      case "ON_REVIEW":
        status.textColor = "text-[#FFFFFF]";
        status.bgColor = "bg-[#E7DE1A]";
        status.text = t('history.onReview');
        break;
      case "SENT":
        status.textColor = "text-[#26BC63]";
        status.bgColor = "bg-[#9DF9C0]";
        status.text = t('history.sent');
        break;
      case "AT_PICKUP_POINT":
        status.textColor = "text-[#FF9723]";
        status.bgColor = "bg-[#FAF5CC]";
        status.text = t('history.atPickupPoint');
        break;
      default:
        status.text = "";
        break;
    }
    return status;
  };

  const checkout = async (link: any, trxId: any) => {
    if (link !== undefined) {
      let indexToken = findIndex(token, { cid: company.initial });
      let tempToken = token[indexToken].token;
      saveState("redirectCO", `/${company.initial}`);
      if (company.initial === "ITSTOKO" || company.initial === "HSI") {
        window.location.href = `https://${link}?access_token=${tempToken}&successRedirectUrl=${redirectCustom}/${company.initial}&backRedirectUrl=${redirectCustom}/${company.initial}`;
      } else {
        window.location.href = `https://${link}?access_token=${tempToken}`;
      }
    } else {
      var query = {
        transactionId: trxId,
        description: "string",
      };
      API_COMMERCE.requestCheckout(query).then((res: any) => {
        var channelLink = res.data.data.checkoutLink;
        let indexToken = findIndex(token, { cid: company.initial });
        let tempToken = token[indexToken].token;
        saveState("redirectCO", `/${company.initial}`);
        if (company.initial === "ITSTOKO" || company.initial === "HSI") {
          window.location.href = `https://${channelLink}?access_token=${tempToken}&successRedirectUrl=${redirectCustom}/${company.initial}&backRedirectUrl=${redirectCustom}/${company.initial}`;
        } else {
          window.location.href = `https://${channelLink}?access_token=${tempToken}`;
        }
      });
    }
  };

  const copyResi = async (resi: string) => {
    await navigator.clipboard.writeText(resi);
    toast.success(t('history.copiedResi'));
  };

  const updateStatus = async (id: string, status: string) => {
    var query = {
      status: status,
    };
    dispatch(setLoading(true));
    API_COMMERCE.updateDetailOrderHistories(id, query)
      .then((res: any) => {
        navigate(`/${company?.initial}/commerce/history`);
        dispatch(setLoading(false));
      })
      .catch((err: any) => {
        dispatch(setLoading(false));
      });
  };

  const collapseProduct = async (productId: string) => {
    setCurrentIndex({ ...currentIndex, [productId]: !currentIndex[productId] });
  };

  const selectForm = (productId: string) => {
    let product = history?.products.find(
      (el: any) => el.productId === productId
    );
    if (product !== undefined) setSelectedProductNotes(product);
    setShowForm("product");
  };

  React.useEffect(() => {
    setEmailUser(user?.email || "");
    getHistoryDetail(location.state.id);
  }, []);

  React.useEffect(() => {
    if (redirectCO) {
      navigate(`${redirectCO}?redirect=false`);
      removeState(`redirectCO`);
    }
  }, [redirectCO]);

  return (
    <>
      <BrowserView>
        <CommerceOrderDetailWeb
          downloadDoc={(id: string) => downloadDoc(id)}
          getAlamat={(address: any) => getAlamat(address)}
          getStatus={(stat: any) => getStatus(stat)}
          checkout={(link: any, trxId: any) => checkout(link, trxId)}
          copyResi={(resi: string) => copyResi(resi)}
          updateStatus={(id: string, status: string) =>
            updateStatus(id, status)
          }
          collapseProduct={(productId: string) => collapseProduct(productId)}
          selectForm={(productId: string) => selectForm(productId)}
          setShowForm={(value: string) => setShowForm(value)}
          products={products}
          uniqueProduct={uniqueProduct}
          showForm={showForm}
          selectedProductNotes={selectedProductNotes}
          marketplaceConfig={marketplaceConfig}
          history={history}
          currentIndex={currentIndex}
          navigate={navigate}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <CommerceOrderDetailMobile
          downloadDoc={(id: string) => downloadDoc(id)}
          getAlamat={(address: any) => getAlamat(address)}
          getStatus={(stat: any) => getStatus(stat)}
          checkout={(link: any, trxId: any) => checkout(link, trxId)}
          copyResi={(resi: string) => copyResi(resi)}
          updateStatus={(id: string, status: string) =>
            updateStatus(id, status)
          }
          collapseProduct={(productId: string) => collapseProduct(productId)}
          selectForm={(productId: string) => selectForm(productId)}
          setShowForm={(value: string) => setShowForm(value)}
          products={products}
          uniqueProduct={uniqueProduct}
          showForm={showForm}
          selectedProductNotes={selectedProductNotes}
          marketplaceConfig={marketplaceConfig}
          history={history}
          currentIndex={currentIndex}
          navigate={navigate}
          t={t}
        />
      </MobileView>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Silahkan Masukkan Email Anda
                  </Dialog.Title>
                  <div className="mt-2">
                    <input
                      type="email"
                      className="w-full px-3 py-2.5 text-base font-normal bg-[#fff] rounded-lg border-[0.5px] border-[#eeeeee]"
                      onChange={(e) => setEmailUser(e.target.value)}
                    />
                  </div>

                  <div className="mt-4">
                    <button
                      onClick={() => downloadDocModal(history.id)}
                      className="my-3 flex flex-row mt-4 gap-1 justify-center bg-transparent border-[0.5px] border-solid border-gray-600 text-[#9A9999] px-3 py-2.5 w-full  text-base font-medium rounded-lg"
                    >
                      <img src={download} alt="" />
                      Unduh
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CommerceOrderDetail;
