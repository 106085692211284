import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoImage from "../../../assets/images/empty.jpg";
import NoImage2 from "../../../assets/images/no_image.png";
import arrow from "../../../assets/images/arrow-left-ic.svg";
import { formatCurrency, onlyNumber } from "../../../config/global";
import { toast } from "react-hot-toast";
import Company from "../../../core/models/company";
import { BottomSheet } from "react-spring-bottom-sheet";
import Warning from "../../../assets/images/warning.svg";
import "react-spring-bottom-sheet/dist/style.css";
import Carousel from "react-multi-carousel";
import { useAppSelector } from "../../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductDetailMobile = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile relative">
      <BottomSheet open={props.open} onDismiss={() => props.setOpen(false)}>
        <div className="mx-4 mt-1 mb-10 font-montserrat">
          {props.isSelectedVariant !== "" ? (
            <div className="space-between gap-3">
              {props.selectedVariant?.imageUrl?.filter((i: any) => i !== "")?.length > 0 ? (
                <img
                  src={props.selectedVariant.imageUrl[0]}
                  alt=""
                  className="w-[120px] h-[120px] rounded-[5px] object-contain"
                />
              ) : (
                <div className="border-[0.5px] border-[#9A9999] rounded-[4px] p-7">
                  <img src={NoImage2} alt="" className="h-full object-cover" />
                </div>
              )}
              <div className="w-full">
                <h6 className="md:text-base text-sm font-medium text-customBlack mb-0 text-start">
                  {props.selectedVariant.variantKey} : {props.selectedVariant.variantValue}
                </h6>
                <h6 className="md:text-base text-sm font-medium text-customBlack mb-0 text-start">
                  {formatCurrency(props.selectedVariant.marketplacePrice)}
                </h6>
                {props.data.type === "BARANG" && (
                  <p className="md:text-base text-sm font-light text-thirdy mb-0">
                    {t('whishlist.stock')} : {props.selectedVariant.stock}
                  </p>
                )}
                <p className="md:text-base text-sm font-light text-thirdy mb-0">
                  {t('whishlist.minOrder')} : {props.selectedVariant.minOrder}
                </p>
              </div>
            </div>
          ) : (
            props.data?.items?.length > 0 && (
              <div className="space-between gap-3">
                {props.data?.items[0]?.imageUrl.length > 0 ? (
                  <img
                    src={props.data?.items[0]?.imageUrl[0]}
                    alt=""
                    className="w-[120px] h-[120px] rounded-[5px] object-contain"
                  />
                ) : (
                  <div className="border-[0.5px] border-[#9A9999] rounded-[4px] p-7">
                    <img
                      src={NoImage2}
                      alt=""
                      className="h-full object-cover"
                    />
                  </div>
                )}
                <div className="w-full">
                  <h6 className="md:text-base text-sm font-medium text-customBlack mb-0 text-start">
                    {props.data?.items?.length > 0 ? props.data?.items[0]?.variant : "-"}
                  </h6>
                  <h6 className="md:text-base text-sm font-medium text-customBlack mb-0 text-start">
                    {props.data?.items?.length > 0
                      ? formatCurrency(props.data?.items[0]?.marketplacePrice)
                      : "-"}
                  </h6>
                  {props.data.type === "BARANG" && (
                    <p className="md:text-base text-sm font-light text-thirdy mb-0">
                      {`${t('whishlist.stock')} : ${props.data?.items?.length > 0 ? props.data?.items[0]?.stock : "-"
                        }`}
                    </p>
                  )}
                  <p className="md:text-base text-sm font-light text-thirdy mb-0">
                    {t('whishlist.minOrder')} :{" "}
                    {props.data?.items?.length > 0 ? props.data?.items[0]?.minOrder : "-"}
                  </p>
                </div>
              </div>
            )
          )}
          <hr />
          <div>
            <h6 className="md:text-base text-sm font-medium mb-1">{t('whishlist.variant')}</h6>
            <div className="grid md:grid-cols-4 grid-cols-3 items-center gap-3">
              {props.data?.items?.length > 0 &&
                props.data?.items?.map((item: any) =>
                  props.data.type === "JASA" ? (
                    <button
                      type="button"
                      onClick={() => {
                        props.setIsSelectedVariant(item?.id);
                        props.setSelectedVariant(item);
                      }}
                      style={{
                        backgroundColor:
                          props.isSelectedVariant !== item?.id
                            ? "#FFFFFF"
                            : `${company?.color?.color1 !== undefined
                              ? company?.color?.color1
                              : "#26A69A"
                            }`,
                        borderColor: `${company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A"
                          }`,
                        color:
                          props.isSelectedVariant === item?.id
                            ? "#FFFFFF"
                            : `${company?.color?.color1 !== undefined
                              ? company?.color?.color1
                              : "#26A69A"
                            }`,
                      }}
                      className={`${props.isSelectedVariant === item?.id
                        ? "bg-green text-white"
                        : "bg-white border-[0.5px] border-green text-green"
                        } text-xs font-normal rounded-md p-[8px] h-full`}
                      key={item.id}
                    >
                      {item.variantKey} : {item.variantValue}
                    </button>
                  ) : item?.stock !== 0 ? (
                    <button
                      type="button"
                      onClick={() => {
                        props.setIsSelectedVariant(item?.id);
                        props.setSelectedVariant(item);
                      }}
                      style={{
                        backgroundColor:
                          props.isSelectedVariant !== item?.id
                            ? "#FFFFFF"
                            : `${company?.color?.color1 !== undefined
                              ? company?.color?.color1
                              : "#26A69A"
                            }`,
                        borderColor: `${company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A"
                          }`,
                        color:
                          props.isSelectedVariant === item?.id
                            ? "#FFFFFF"
                            : `${company?.color?.color1 !== undefined
                              ? company?.color?.color1
                              : "#26A69A"
                            }`,
                      }}
                      className={`${props.isSelectedVariant === item?.id
                        ? "bg-green text-white"
                        : "bg-white border-[0.5px] border-green text-green"
                        } text-xs font-normal rounded-md p-[8px] h-full`}
                      key={item.id}
                    >
                      {item.variantKey} : {item.variantValue}
                    </button>
                  ) : (
                    <button
                      disabled
                      type="button"
                      className="bg-[#9A999980] text-customGrey text-xs font-normal rounded-md p-[8px] h-full"
                      key={item.id}
                    >
                      {item.variantKey} : {item.variantValue}
                    </button>
                  )
                )}
            </div>
          </div>
          <div className="my-3">
            <h6 className="md:text-base text-sm font-medium mb-1">{t('whishlist.quantity')}</h6>
            <div>
              <button
                type="button"
                disabled={props.quantity === 1}
                onClick={() => props.setQuantity(props.quantity - 1)}
                className="border-[0.5px] border-[#C4C4C4] md:px-[1rem] px-[8px] py-2 rounded-l-lg"
              >
                -
              </button>
              <input
                type="text"
                onKeyPress={onlyNumber}
                value={props.quantity}
                onChange={(e) => {
                  if (e.target.value === "") {
                    props.setQuantity(1);
                  } else {
                    props.setQuantity(parseInt(e.target.value));
                  }
                }}
                name="quantity"
                id="quantity"
                className="border-[#C4C4C4] border-t-[0.5px] border-b-[0.5px] text-center px-[1rem] py-2 w-1/4 md:w-[10%] focus:outline outline-[2px] outline-[#C4C4C4]"
              />
              <button
                type="button"
                disabled={props.data.type === "JASA" ? false : props.quantity === props.stock}
                onClick={() => props.setQuantity(props.quantity + 1)}
                className="border-[0.5px] border-[#C4C4C4] md:px-[1rem] px-[8px] py-2 rounded-r-lg"
              >
                +
              </button>
            </div>
          </div>
          {props.data.type === "JASA" ? (
            <button
              type="button"
              onClick={() => {
                if (props.isBuyNow) {
                  if (props.quantity < props.selectedVariant.minOrder) {
                    toast.error(
                      `${t('whishlist.minPurchase')} ${props.selectedVariant.minOrder}`
                    );
                  } else {
                    navigate(`/${company?.initial}/commerce/create-order`, {
                      state: {
                        data: props.data,
                        dataOrder: props.selectedVariant,
                        isCart: false,
                        quantity: props.quantity,
                      },
                    });
                  }
                } else {
                  props.addToCart();
                }
              }}
              style={{
                backgroundColor: `${company?.color?.color1 !== undefined
                  ? company?.color?.color1
                  : "#26A69A"
                  }`,
              }}
              className="bg-green text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
            >
              {props.isBuyNow ? t('whishlist.buyNow') : t('whishlist.addToCart')}
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                if (props.isBuyNow) {
                  if (props.quantity < props.selectedVariant.minOrder) {
                    toast.error(
                      `${t('whishlist.minPurchase')} ${props.selectedVariant.minOrder}`
                    );
                  } else {
                    navigate(`/${company?.initial}/commerce/create-order`, {
                      state: {
                        data: props.data,
                        dataOrder: props.selectedVariant,
                        isCart: false,
                        quantity: props.quantity,
                      },
                    });
                  }
                } else {
                  props.addToCart();
                }
              }}
              disabled={props.selectedVariant.stock === 0}
              style={{
                backgroundColor:
                  props.selectedVariant.stock === 0
                    ? "#9A9999"
                    : `${company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A"
                    }`,
              }}
              className={`${props.selectedVariant.stock === 0 ? "bg-[#9A9999]" : "bg-green"
                } text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full`}
            >
              {props.isBuyNow ? t('whishlist.buyNow') : t('whishlist.addToCart')}
            </button>
          )}
        </div>
      </BottomSheet>

      <BottomSheet
        open={props.showModal2}
        onDismiss={() => {
          props.setShowModal2(false);
        }}
        className="relative z-50 font-montserrat"
      >
        <div className="mx-4 mb-4">
          <h6 className="md:text-lg text-base text-customBlack font-medium mb-3">
            {t('merchants.operasionalTime')}
          </h6>
          {props.workHour.length > 0 &&
            props.workHour.map((item: any, index: number) =>
              item.isOpen ? (
                <div
                  className="flex items-start justify-between gap-2 border-b-[1px] border-b-[#E2E2E2] pb-1 mb-2"
                  key={index}
                >
                  <h6 className="md:text-base text-sm text-customBlack mb-0">
                    {props.daysOfWeek[item.dayOfWeek - 1]}
                  </h6>
                  <div className="flex flex-col gap-2">
                    {item.timeSlots.map((el: any, index2: number) => (
                      <p
                        className="md:text-base text-sm text-customBlack mb-0"
                        key={index2}
                      >
                        {`${el.startTime.slice(0, 2)}.${el.startTime.slice(
                          2
                        )} - ${el.endTime.slice(0, 2)}.${el.endTime.slice(2)}`}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  className="flex items-center justify-between gap-2 border-b-[1px] border-b-[#E2E2E2] pb-1 mb-2"
                  key={index}
                >
                  <h6 className="md:text-base text-sm text-customBlack mb-0">
                    {props.daysOfWeek[item.dayOfWeek - 1]}
                  </h6>
                  <p className="md:text-base text-sm text-customBlack mb-0">
                    {t('merchants.close')}
                  </p>
                </div>
              )
            )}
        </div>
      </BottomSheet>

      <div className="absolute p-4 z-10">
        <div
          onClick={() => navigate(-1)}
          className="flex bg-white rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>
      </div>

      <div className="container gap-5 justify-center px-0">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto">
          <div className="bg-white relative">
            {props.data.type === "BARANG" && props.stock === 0 && (
              <div
                className="bg-[#252525] flex items-center justify-center left-[50%] rounded-full text-white md:text-xl text-lg w-32 h-32 opacity-50 absolute top-[50%] z-20"
                style={{ transform: "translate(-50%, -30%)" }}
              >
                <span>{t('marketplace.sold')}</span>
              </div>
            )}
            {props.data?.imagePath?.filter((i: any) => i !== "")?.length > 0 ? (
              <Carousel
                swipeable
                draggable={false}
                showDots={false}
                responsive={props.responsive}
              >
                {props.data?.imagePath?.filter((i: any) => i !== "")?.map((item: any, index: number) => (
                  <img
                    src={item}
                    alt=""
                    key={index}
                    className="w-full object-cover"
                  />
                ))}
              </Carousel>
            ) : (
              <img src={NoImage} alt="" />
            )}
            {props.data.merchantOpen === false && (
              <div className="flex items-center justify-center gap-3 md:py-3 py-2 bg-[#FFC107] bg-opacity-20 border-y-[1px] border-y-[#FFC107]">
                <img src={Warning} alt="" />
                <div>
                  <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">
                    {t('marketplace.merchantClose')}
                  </h6>
                  <div className="flex items-center gap-1">
                    <p className="md:text-sm text-xs font-light mb-0 text-customBlack">
                      {t('marketplace.merchantText')}
                    </p>
                    <button
                      type="button"
                      onClick={() => props.setShowModal2(true)}
                      className="text-[#FF9723] font-semibold md:text-sm text-xs"
                    >
                      {t('marketplace.checkSchedule')}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="space-between px-6 py-3">
              <div>
                <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">
                  {props.data?.name}
                </h6>
                <h5 className="md:text-2xl text-xl text-customBlack font-bold mb-0">
                  {props.data?.items?.length > 1
                    ? `${formatCurrency(props.minPrice)} - ${formatCurrency(
                      props.maxPrice
                    )}`
                    : formatCurrency(props.minPrice)}
                </h5>
              </div>
              <button type="button" onClick={props.addToWishlist}>
                <FontAwesomeIcon
                  icon={faHeart}
                  size="2x"
                  className={props.isWishlist ? "text-red" : "text-[#D9D9D9]"}
                />
              </button>
            </div>
          </div>
          <div className="bg-white my-3 md:py-6 py-[1rem] px-3">
            <h6 className="md:text-base text-sm font-medium">
              {t('category.variationsAvailable')}
            </h6>
            <div className="grid lg:grid-cols-6 sm:grid-cols-4 grid-cols-3 items-center gap-3">
              {props.data?.items?.length > 0 &&
                props.data?.items?.map((item: any) =>
                  item?.imageUrl?.filter((i: any) => i !== "")?.length > 0 ? (
                    <div
                      key={item?.id}
                      className="flex flex-col items-center"
                      onClick={() => {
                        props.setIsSelectedVariant(item?.id);
                        props.setSelectedVariant(item);
                        props.setOpen(true);
                      }}
                    >
                      <h6 className="md:text-sm text-xs w-full text-center text-newColor mb-1">
                        {item.variantValue}
                      </h6>
                      <img
                        src={item?.imageUrl}
                        alt=""
                        className="object-contain w-20 h-20 rounded-[4px]"
                      />
                    </div>
                  ) : (
                    <div key={item?.id} className="flex flex-col items-center">
                      <h6 className="md:text-sm text-xs font-light text-newColor mb-1">
                        {item.variantValue}
                      </h6>
                      <div className="border-[0.5px] border-[#9A9999] rounded-[4px] p-7">
                        <img
                          src={NoImage2}
                          alt=""
                          className="h-full object-cover w-5"
                        />
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
          <div className="bg-white my-3 md:py-6 py-[1rem] px-3">
            <h6 className="md:text-base text-sm text-customBlack font-medium mb-3">
              {t('category.info')} {props.marketplaceConfig?.merchantName}
            </h6>
            <div className="flex items-center gap-3">
              <img
                src={
                  props.data?.merchant?.profileImage
                    ? props.data?.merchant?.profileImage
                    : NoImage
                }
                alt=""
                width={60}
              />
              <div className="flex flex-col justify-between gap-1">
                <h6 className="md:text-sm text-xs font-medium mb-0">
                  {props.data?.merchant?.name}
                </h6>
                <p className="md:text-sm text-xs text-thirdy font-light mb-0">
                  {props.data?.merchant?.location?.city?.name}
                </p>
                {props.data?.merchant?.phone && (
                  <p className="md:text-sm text-xs text-thirdy font-light mb-0">
                    {t('category.number')} : {props.data?.merchant?.phone}
                  </p>
                )}
              </div>
            </div>
            {props.data?.merchant?.phone && (
              <div className="w-full">
                <button
                  type="button"
                  onClick={() =>
                  (window.location.href = `https://wa.me/${props.data?.merchant?.phone.replace(
                    /^0/,
                    "+62"
                  )}`)
                  }
                  style={{
                    color: `${company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A"
                      }`,
                    borderColor: `${company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A"
                      }`,
                  }}
                  className="w-full no-underline bg-white text-green md:text-base text-sm border-green border-[0.5px] rounded-md px-3 py-2 mt-3"
                >
                  {t('category.chatSeller')}
                </button>
              </div>
            )}
          </div>
          <div className="bg-white my-3 md:py-6 py-[1rem] px-3">
            <h6 className="md:text-base text-sm font-medium mb-0">
              {t('category.info')} {props.marketplaceConfig?.productName}
            </h6>
            {props.data.type === "BARANG" && (
              <div className="flex justify-between items-center gap-3">
                <h6 className="md:text-sm text-xs text-thirdy text-start font-light mb-0">
                  {t('whishlist.stock')} :
                </h6>
                <p className="md:text-sm text-xs text-thirdy text-end font-light mb-0">
                  {props.stock}
                </p>
              </div>
            )}
            <div className="flex justify-between items-center gap-3">
              <h6 className="md:text-sm text-xs text-thirdy text-start font-light mb-0">
                {t('whishlist.sold')} :
              </h6>
              <p className="md:text-sm text-xs text-thirdy text-end font-light mb-0">
                {props.sold}
              </p>
            </div>
            <div className="flex justify-between items-center gap-3">
              <h6 className="md:text-sm text-xs text-thirdy text-start font-light mb-0">
                {t('category.category')} :
              </h6>
              <p className="md:text-sm text-xs text-thirdy text-end font-light mb-0">
                {props.data?.level3CategoryName}
              </p>
            </div>
            <div className="flex justify-between items-center gap-3">
              <h6 className="md:text-sm text-xs text-thirdy text-start font-light mb-0">
                {t('category.type')} {props.marketplaceConfig?.productName} :
              </h6>
              <p className="md:text-sm text-xs text-thirdy text-end font-light mb-0">
                {props.data?.type === "JASA" ? "Jasa" : "Barang"}
              </p>
            </div>
          </div>
          <div className="bg-white my-3 md:py-6 py-[1rem] px-3">
            <h6 className="md:text-base text-sm font-medium mb-0">
              {t('category.description')} {props.marketplaceConfig?.productName}
            </h6>
            <p className="md:text-sm text-xs text-thirdy font-light mb-0">
              {props.data?.description === "" ? "-" : props.data?.description}
            </p>
          </div>
          <div className="bg-white space-between gap-3 mt-3 md:py-6 py-[1rem] px-3">
            {props.data?.type === "JASA" ? (
              <div className="flex justify-center items-center gap-3 w-full">
                <button
                  type="button"
                  onClick={() => {
                    if (props.isLogin) {
                      props.setOpen(true);
                      props.setIsBuyNow(false);
                    } else {
                      navigate(`/${company.initial}/login`);
                    }
                  }}
                  disabled={props.data.merchantOpen === false}
                  className="border-newColor text-newColor border-[1px] md:text-base text-sm rounded-[10px] h-full px-3 py-2"
                >
                  {t('category.cart')}
                </button>
                {props.data.merchantOpen ? (
                  <button
                    type="button"
                    onClick={() => {
                      if (props.isLogin) {
                        props.setOpen(true);
                        props.setIsBuyNow(true);
                      } else {
                        navigate(`/${company.initial}/login`);
                      }
                    }}
                    style={{
                      backgroundColor: `${company?.color?.color1 !== undefined
                        ? company?.color?.color1
                        : "#26A69A"
                        }`,
                    }}
                    className="bg-green text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                  >
                    {t('category.buy')}
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled
                    className="bg-newColor text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                  >
                    {t('category.buy')}
                  </button>
                )}
              </div>
            ) : (
              <div className="flex justify-center items-center gap-3 w-full">
                <button
                  type="button"
                  onClick={() => {
                    if (props.isLogin) {
                      props.setOpen(true);
                      props.setIsBuyNow(false);
                    } else {
                      navigate(`/${company.initial}/login`);
                    }
                  }}
                  disabled={props.stock === 0 || props.data.merchantOpen === false}
                  className={`${props.stock === 0
                    ? "border-[#9A9999] text-[#9A9999]"
                    : "border-newColor text-newColor"
                    } border-[1px] md:text-base text-sm rounded-[10px] h-full px-3 py-2`}
                >
                  {t('category.cart')}
                </button>
                {props.data.merchantOpen || props.stock === 0 ? (
                  <button
                    type="button"
                    onClick={() => {
                      if (props.isLogin) {
                        props.setOpen(true);
                        props.setIsBuyNow(true);
                      } else {
                        navigate(`/${company.initial}/login`);
                      }
                    }}
                    style={{
                      backgroundColor:
                        props.stock === 0
                          ? "#9A9999"
                          : `${company?.color?.color1 !== undefined
                            ? company?.color?.color1
                            : "#26A69A"
                          }`,
                    }}
                    className={`text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full`}
                  >
                    {t('category.buy')}
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled
                    className="bg-newColor text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                  >
                    {t('category.buy')}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetailMobile;