import { BrowserView, MobileView } from "react-device-detect";
import LoginWeb from "./loginWeb";
import LoginMobile from "./loginMobile";
import React, { useEffect, useRef, useState } from "react";
import { setCompany } from "../../core/feature/company/companySlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { } from "../../core/feature/store";
import * as API from "../../core/service/api.js";
import * as API_USER from "../../core/service/api_user.js";
import fingerPrint from "@fingerprintjs/fingerprintjs";
import _, { findIndex } from "lodash";
import Countdown, { zeroPad } from "react-countdown";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import { setSkipTo } from "../../core/feature/otp/addDestinationSlice";
import {
  setIsEmailVerifiedDestination,
  setIsPhoneVerifiedDestination,
} from "../../core/feature/otp/destinationSlice";
import {
  setOtpCompanyId,
  setOtpDeviceId,
  setOtpEmail,
  setOtpPassword,
  setOtpRedirect,
  setOtpTransactonType,
  setOtpUsername,
  setStateEnd,
} from "../../core/feature/otp/otpSlice";
import { setToken } from "../../core/feature/token/tokenSlice";
import { setPin } from "../../core/feature/user/pinSlice";
import { setUser } from "../../core/feature/user/userSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";
import Company from "../../core/models/company";
import Token from "../../core/models/token.js";
import User from "../../core/models/user.js";
import UserLogin from "../../core/models/userLogin.js";
import * as API_AUTH from "../../core/service/api_auth";
import { requestForToken } from "../../firebase";
import icon_verify from "../../assets/images/verified.svg";
import CryptoJS from "crypto-js";
import { AlertPasswordStrength, checkRegex } from "../../config/global";
import { useTranslation } from "react-i18next";

const Login = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const isEverChooseCompany: boolean = useAppSelector(
    (state) => state.config.isEverChooseCompany
  );
  let destinationEmailVerified: boolean = useAppSelector(
    (state) => state.destination.isEmailVerified
  );
  let destinationPhoneVerified: boolean = useAppSelector(
    (state) => state.destination.isPhoneVerified
  );
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  const { t } = useTranslation();
  const params = useParams();
  const [deviceId, setDeviceId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorUsername, setErrorUsername] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [showOtpEmail, setShowOtpEmail] = useState(false);
  const [showInputEmail, setShowInputEmail] = useState(false);
  const [redirect, setRedirect] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [prefix, setPrefix] = useState("");
  const [prefixId, setPrefixId] = useState("");
  const [usernameEmail, setUsernameEmail] = useState("");
  const [showPasswordForm, setshowPasswordForm] = useState(false);
  const [showCreatePassword, setshowCreatePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [addEmailForm, setAddEmailForm] = useState(false);
  const [destinationOtp, setDestinationOtp] = useState(false);
  const [showDataVerification, setShowDataVerification] = useState(false);
  const [NewValues, setNewValues] = React.useState(["", "", ""]);
  const [timer, setTimer] = useState(0);
  const [showVerificationFrom, setShowVerificationForm] = useState(false);
  const [isAddData, setIsAddData] = useState(false);
  const [visiblePW1, setVisiblePW1] = useState(false);
  const [visiblePW2, setVisiblePW2] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [showModalPhone, setShowModalPhone] = useState(false);
  const [selected, setSelected] = useState("");
  const [emailFer, setEmailFer] = useState<any>(
    user?.email === "" ? "" : user?.email
  );
  const [phoneFer, setPhoneFer] = useState<any>(
    user?.phone === "" ? "" : user?.phone
  );
  const [modalVerificationWa, setModalVerificationWa] = useState(false);
  const [state, setState] = useState("");
  const [tokenWa, setTokenWa] = useState("");
  const [data, setData] = useState<any>({});
  // encrypted text
  const [cyperText, setCyperText] = useState("");
  const decodedData = CryptoJS.enc.Base64.parse(cyperText);
  const ivArray = CryptoJS.lib.WordArray.create(decodedData.words.slice(0, 4));
  const keyArray = CryptoJS.lib.WordArray.create(
    decodedData.words.slice(4, 12)
  );
  const stateArray = CryptoJS.lib.WordArray.create(decodedData.words.slice(12));
  const key = CryptoJS.enc.Base64.stringify(keyArray);
  const iv = CryptoJS.enc.Base64.stringify(ivArray);
  const stateEnd = CryptoJS.enc.Base64.stringify(stateArray);
  const [strength, setStrength] = useState("");
  const [profile, setProfil] = useState(true);

  const encryptedValue = (value: any) => {
    try {
      const encrypted = CryptoJS.AES.encrypt(
        value,
        CryptoJS.enc.Base64.parse(key),
        {
          mode: CryptoJS.mode.CBC,
          iv: CryptoJS.enc.Base64.parse(iv),
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      setPassword(encrypted.toString());
    } catch (error) { 
      console.log(error);
    }
  };

  const getPasswordStrength = async () => {
    dispatch(setLoading(true));
    await API_USER.getPasswordStrength(company.id)
      .then((res) => {
        dispatch(setLoading(false));
        setStrength(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const decryptedValue = () => {
    try {
      const decrypted = CryptoJS.AES.decrypt(
        password,
        CryptoJS.enc.Base64.parse(key),
        {
          mode: CryptoJS.mode.CBC,
          keySize: 256 / 8,
          iv: CryptoJS.enc.Base64.parse(iv),
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      console.log(decrypted.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error("Decryption error:", error);
    }
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let getCheckout = query.get("checkoutlogin");
  let getInitialCompany = query.get("initialcompany");
  let link = query.get(`link`);

  const cekData = async (err: any) => {
    dispatch(setLoading(true));
    await API_AUTH.cekEmailPhone(company.id, username)
      .then((res) => {
        if (err.response?.status === 403) {
          setData(res.data.data);
          dispatch(
            setIsEmailVerifiedDestination(!_.isEmpty(res.data.data.email))
          );
          dispatch(
            setIsPhoneVerifiedDestination(!_.isEmpty(res.data.data.phone))
          );
          dispatch(setOtpEmail(res.data.data.email));
          dispatch(setLoading(false));
          setDestinationOtp(true);
          dispatch(setOtpEmail(err.response.data.detail.message));
          dispatch(setOtpPassword(password));
          dispatch(setOtpUsername(username));
          dispatch(setStateEnd(stateEnd));
          dispatch(setOtpCompanyId(prefixId === "" ? company?.id : prefixId));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const changeEmail = async () => {
    if (emailFer === "") {
      toast.error(t('login.toastEmailError'));
    } else {
      if (user?.verifiedEmail === true) {
        login();
        setAddEmailForm(false);
      } else {
        let newData = {
          destinationOtp: emailFer,
          destinationType: "EMAIL",
          otpType: "EMAIL_VERIFICATION",
        };
        dispatch(setLoading(true));
        await API_AUTH.requestDestinationOtp(newData)
          .then((res) => {
            setShowDataVerification(true);
            setTimer(Date.now() + res.data.data.expiredMillisecond);
            setAddEmailForm(false);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            toast.error(t('login.toastEmailError'));
            dispatch(setLoading(false));
          });
      }
    }
  };

  const changePhone = async () => {
    if (phoneFer === "") {
      toast.error(t('login.toastWaError'));
    } else {
      if (user?.verifiedPhone === true) {
        login();
        setAddEmailForm(false);
      } else {
        let newData = {
          phoneNumber: phoneFer,
          companyId: company?.id,
          userId: `${user?.userId}`,
          userType: "MEMBER",
          transactionType: "WA_VERIFICATION",
          deviceId: deviceId,
        };
        dispatch(setLoading(true));
        await API_AUTH.requestTokenVerivicationWa(newData)
          .then((res) => {
            setModalVerificationWa(true);
            window.open(
              `https://wa.me/${res.data.data.destinationPhoneNumber
              }?text=${encodeURI(
                `Halo ini adalah kode verifikasi saya "${res.data.data.token}" tolong verifikasi ya.`
              )}`,
              `_blank`
            );
            setTokenWa(res.data.data.token);
            setState(res.data.data.state);
            setTimer(Date.now() + res.data.data.expiredMillisecond);
            setAddEmailForm(false);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    }
  };

  // cek token wa

  const checkTokenWa = async () => {
    dispatch(setLoading(true));
    let newData = {
      state: state,
      phoneNumber: phoneFer,
      companyId: company?.id,
      transactionType: "WA_VERIFICATION",
      deviceId: deviceId,
      token: tokenWa,
    };
    dispatch(setLoading(true));
    await API_AUTH.checkTokenVerification(newData)
      .then((res) => {
        dispatch(setLoading(false));
        console.log(res.data.data);
        let newData = {
          phone: phoneFer,
          otpPrevPhone: res.data.data.otp,
          otpNewPhone: res.data.data.otp,
        };
        dispatch(setLoading(true));
        API_USER.verificationPhone(newData)
          .then((res) => {
            setModalVerificationWa(false);
            navigate(`/${company.initial}`);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
            setShowDataVerification(false);
          });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const logiin = async () => {
    // decryptedValue();
    if (prefix) {
      let userlogins = userLogin ? [...userLogin] : [];
      if (prefix !== company.initial) userlogins = [];
      await API_USER.getCompany(prefix)
        .then((res) => {
          dispatch(setCompany(res.data.data));
          // setCompanyData(res.data.data);
          dispatch(setLoading(true));
          dispatch(setUserLogin(userlogins));
          setEmailFer(res.data.data.email);
          setPrefixId(res.data.data.id);
          if (username === "") {
            setErrorUsername(t('login.toastUsernameError'));
          } else if (password === "") {
            setErrorPassword(t('login.toastPasswordError'));
          } else {
            dispatch(setLoading(true));
            var query = {
              username,
              password,
              companyId: res.data.data.id,
              deviceId: deviceId,
              state: stateEnd,
            };
            dispatch(setSkipTo(`/${company.initial}`));
            API.login(query, "")
              .then((res) => {
                dispatch(setLoading(false));
                var newToken = token ? [...token] : [];
                let indexToken = findIndex(token, {
                  cid: prefix === "" ? company.initial : prefix,
                });

                if (indexToken < 0) {
                  newToken.push({
                    cid: prefix === "" ? company.initial : prefix,
                    token: res.data.data.authToken,
                  });
                } else {
                  newToken[indexToken] = {
                    cid: prefix === "" ? company.initial : prefix,
                    token: res.data.data.authToken,
                  };
                }
                // setTokenToken(newToken);
                var newUserLogin = [...userlogins];
                let indexUserLogin = findIndex(newUserLogin, {
                  id: res.data.data.noId,
                });

                if (indexUserLogin < 0) {
                  newUserLogin.push({
                    token: res.data.data.authToken,
                    id: res.data.data.noId,
                    initial: prefix === "" ? company.initial : prefix,
                  });
                } else {
                  newUserLogin[indexUserLogin] = {
                    token: res.data.data.authToken,
                    id: res.data.data.noId,
                    initial: prefix === "" ? company.initial : prefix,
                  };
                }
                //set data if firstlogin === false
                if (res.data.data.firstLogin) {
                  sessionStorage.setItem("token", res.data.data.authToken);
                } else {
                  dispatch(setToken(newToken));
                  dispatch(setUser(res.data.data));
                  dispatch(setPin(Number(res.data.data.securityCode)));
                  dispatch(setUserLogin(newUserLogin));
                  requestForToken()
                  .then((payload) => {
                    addDeviceFcm(payload);
                  })
                  .catch((err) => console.log("failed: ", err));
                }
                setFirstLogin(res.data.data.firstLogin);
                if (res.data.data.firstLogin) {
                  if (res.data.email === "") {
                    setShowInputEmail(true);
                  } else {
                    if (link || getInitialCompany || getCheckout) {
                      navigate(
                        `/${getInitialCompany}/create-password?link=${link}&initialcompany=${getInitialCompany}&checkoutlogin=${getCheckout}`,
                        {}
                      );
                    } else {
                      navigate(`/${company?.initial}/create-password`, {});
                    }
                  }
                } else {
                  if (
                    res.data.data.verifiedPhone === false &&
                    res.data.data.verifiedEmail === false
                  ) {
                    setAddEmailForm(true);
                  } else {
                    dispatch(setLoading(true));
                    API_AUTH.cekEmailPhone(company.id, username)
                      .then((res) => {
                        if (
                          res.data.data.email === null &&
                          res.data.data.phone === null
                        ) {
                          setAddEmailForm(true);
                        } else {
                          login();
                          setData(res.data.data);
                          dispatch(setOtpEmail(res.data.data.email));
                        }
                        setEmailFer(res.data.data.email);
                        setPhoneFer(res.data.data.phone);
                        dispatch(setLoading(false));
                      })
                      .catch((err) => {
                        dispatch(setLoading(false));
                      });
                  }
                }
              })
              .catch((err) => {
                if (link || getCheckout || getInitialCompany) {
                  window.location.href = `https://${link}`;
                  toast.error("Login Gagal");
                }

                if (err.response?.status === 403) {
                  cekData(err);
                }
                dispatch(setLoading(false));
              });
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          // setCheckCompany({});
        });
    } else {
      if (username === "") {
        setErrorUsername(t('login.toastUsernameError'));
      } else if (password === "") {
        setErrorPassword(t('login.toastPasswordError'));
      } else {
        dispatch(setLoading(true));
        var query = {
          username,
          password,
          companyId: company.id,
          deviceId: deviceId,
          state: stateEnd,
        };
        dispatch(setSkipTo(`/${company.initial}`));
        await API.login(query, "")
          .then((res) => {
            dispatch(setLoading(false));
            var newToken = token ? [...token] : [];
            let indexToken = findIndex(token, {
              cid: company.initial,
            });

            if (indexToken < 0) {
              newToken.push({
                cid: company.initial,
                token: res.data.data.authToken,
              });
            } else {
              newToken[indexToken] = {
                cid: company.initial,
                token: res.data.data.authToken,
              };
            }

            /** startof user login section */
            var newUserLogin = userLogin?.length > 0 ? [...userLogin] : [];
            let indexUserLogin = findIndex(newUserLogin, {
              id: res.data.data.noId,
            });
            if (indexUserLogin < 0) {
              newUserLogin.push({
                token: res.data.data.authToken,
                id: res.data.data.noId,
                initial: company.initial,
              });
            } else {
              newUserLogin[indexUserLogin] = {
                token: res.data.data.authToken,
                id: res.data.data.noId,
                initial: company.initial,
              };
            }
           //set data if firstlogin === false
            if (res.data.data.firstLogin) {
              sessionStorage.setItem("token", res.data.data.authToken);
            } else {
              dispatch(setToken(newToken));
              dispatch(setUser(res.data.data));
              setPhoneFer(res.data.data.phone);
              setEmailFer(res.data.data.email);
              dispatch(setPin(Number(res.data.data.securityCode)));
              dispatch(setUserLogin(newUserLogin));
              requestForToken()
              .then((payload) => {
                addDeviceFcm(payload);
              })
              .catch((err) => console.log("failed: ", err));
              
            }
            /** endof user login section */

            setFirstLogin(res.data.data.firstLogin);
            if (res.data.data.firstLogin) {
              if (res.data.email === "") {
                setShowInputEmail(true);
              } else {
                if (link || getInitialCompany || getCheckout) {
                  navigate(
                    `/${getInitialCompany}/create-password?link=${link}&initialcompany=${getInitialCompany}&checkoutlogin=${getCheckout}`,
                    {}
                  );
                } else {
                  navigate(
                    `/${prefix === "" ? company.initial : prefix
                    }/create-password`,
                    {}
                  );
                }
              }
            } else {
              setEmail(res.data.email);
              if (link || getInitialCompany || getCheckout) {
                navigate(redirect, { replace: true });
              } else {
                if (
                  res.data.data.verifiedEmail === false &&
                  res.data.data.verifiedPhone === false
                ) {
                  setAddEmailForm(true);
                } else {
                  dispatch(setLoading(true));
                  API_AUTH.cekEmailPhone(company.id, username)
                    .then((res) => {
                      if (
                        res.data.data.email === null &&
                        res.data.data.phone === null
                      ) {
                        setAddEmailForm(true);
                      } else {
                        login();
                        setData(res.data.data);
                        dispatch(setOtpEmail(res.data.data.email));
                      }
                      setEmailFer(res.data.data.email);
                      setPhoneFer(res.data.data.phone);
                      dispatch(setLoading(false));
                    })
                    .catch((err) => {
                      dispatch(setLoading(false));
                    });
                }
              }
            }
          })
          .catch((err) => {
            if (err.response?.status === 403) {
              cekData(err);
            }
            setProfil(false);
            dispatch(setLoading(false));
          });
      }
    }
  };

  const login = async () => {
    if (prefix) {
      let userlogins = userLogin ? [...userLogin] : [];
      if (prefix !== company.initial) userlogins = [];
      await API_USER.getCompany(prefix)
        .then((res) => {
          dispatch(setCompany(res.data.data));
          // setCompanyData(res.data.data);
          dispatch(setLoading(true));
          dispatch(setUserLogin(userlogins));
          setPrefixId(res.data.data.id);
          if (username === "") {
            setErrorUsername(t('login.toastUsernameError'));
          } else if (password === "") {
            setErrorPassword(t('login.toastPasswordError'));
          } else {
            dispatch(setLoading(true));
            var query = {
              username,
              password,
              companyId: res.data.data.id,
              deviceId: deviceId,
              state: stateEnd,
            };
            dispatch(setSkipTo(`/${company.initial}`));
            API.login(query, "")
              .then((res) => {
                dispatch(setLoading(false));
                dispatch(setUser(res.data.data));
                dispatch(setPin(Number(res.data.data.securityCode)));
                var newToken = token ? [...token] : [];
                let indexToken = findIndex(token, {
                  cid: prefix === "" ? company.initial : prefix,
                });

                if (indexToken < 0) {
                  newToken.push({
                    cid: prefix === "" ? company.initial : prefix,
                    token: res.data.data.authToken,
                  });
                } else {
                  newToken[indexToken] = {
                    cid: prefix === "" ? company.initial : prefix,
                    token: res.data.data.authToken,
                  };
                }
                // setTokenToken(newToken);
                if (res.data.data.firstLogin) {
                  sessionStorage.setItem("token", res.data.data.authToken);
                } else {
                  dispatch(setToken(newToken));
                }
                var newUserLogin = [...userlogins];
                let indexUserLogin = findIndex(newUserLogin, {
                  id: res.data.data.noId,
                });

                if (indexUserLogin < 0) {
                  newUserLogin.push({
                    token: res.data.data.authToken,
                    id: res.data.data.noId,
                    initial: prefix === "" ? company.initial : prefix,
                  });
                } else {
                  newUserLogin[indexUserLogin] = {
                    token: res.data.data.authToken,
                    id: res.data.data.noId,
                    initial: prefix === "" ? company.initial : prefix,
                  };
                }

                dispatch(setUserLogin(newUserLogin));
                setFirstLogin(res.data.data.firstLogin);
                if (res.data.data.firstLogin) {
                  if (res.data.email === "") {
                    setShowInputEmail(true);
                  } else {
                    if (link || getInitialCompany || getCheckout) {
                      navigate(
                        `/${getInitialCompany}/create-password?link=${link}&initialcompany=${getInitialCompany}&checkoutlogin=${getCheckout}`,
                        {}
                      );
                    } else {
                      navigate(`/${company?.initial}/create-password`, {});
                    }
                  }
                } else {
                  setEmail(res.data.email);
                  navigate(`/${prefix === "" ? company.initial : prefix}`, {
                    replace: true,
                  });
                  requestForToken()
                    .then((payload) => {
                      addDeviceFcm(payload);
                    })
                    .catch((err) => console.log("failed: ", err));
                }
              })
              .catch((err) => {
                if (link || getCheckout || getInitialCompany) {
                  window.location.href = `https://${link}`;
                  toast.error("Login Gagal");
                }

                if (err.response?.status === 403) {
                  cekData(err);
                }
                dispatch(setLoading(false));
              });
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          // setCheckCompany({});
        });
    } else {
      if (username === "") {
        setErrorUsername(t('login.toastUsernameError'));
      } else if (password === "") {
        setErrorPassword(t('login.toastPasswordError'));
      } else {
        dispatch(setLoading(true));
        var query = {
          username,
          password,
          companyId: company.id,
          deviceId: deviceId,
          state: stateEnd,
        };
        dispatch(setSkipTo(`/${company.initial}`));
        await API.login(query, "")
          .then((res) => {
            dispatch(setLoading(false));
            dispatch(setUser(res.data.data));
            setEmailFer(res.data.data.email);
            setPhoneFer(res.data.data.phone);
            dispatch(setPin(Number(res.data.data.securityCode)));
            var newToken = token ? [...token] : [];
            let indexToken = findIndex(token, {
              cid: company.initial,
            });

            if (indexToken < 0) {
              newToken.push({
                cid: company.initial,
                token: res.data.data.authToken,
              });
            } else {
              newToken[indexToken] = {
                cid: company.initial,
                token: res.data.data.authToken,
              };
            }
            if (res.data.data.firstLogin) {
              sessionStorage.setItem("token", res.data.data.authToken);
            } else {
              dispatch(setToken(newToken));
            }

            /** startof user login section */
            var newUserLogin = userLogin?.length > 0 ? [...userLogin] : [];
            let indexUserLogin = findIndex(newUserLogin, {
              id: res.data.data.noId,
            });
            if (indexUserLogin < 0) {
              newUserLogin.push({
                token: res.data.data.authToken,
                id: res.data.data.noId,
                initial: company.initial,
              });
            } else {
              newUserLogin[indexUserLogin] = {
                token: res.data.data.authToken,
                id: res.data.data.noId,
                initial: company.initial,
              };
            }
            dispatch(setUserLogin(newUserLogin));
            /** endof user login section */

            setFirstLogin(res.data.data.firstLogin);
            if (res.data.data.firstLogin) {
              if (res.data.email === "") {
                setShowInputEmail(true);
              } else {
                if (link || getInitialCompany || getCheckout) {
                  navigate(
                    `/${getInitialCompany}/create-password?link=${link}&initialcompany=${getInitialCompany}&checkoutlogin=${getCheckout}`,
                    {}
                  );
                } else {
                  navigate(
                    `/${prefix === "" ? company.initial : prefix
                    }/create-password`,
                    {}
                  );
                }
              }
            } else {
              setEmail(res.data.email);
              if (link || getInitialCompany || getCheckout) {
                navigate(redirect, { replace: true });
              } else {
                navigate(`/${company?.initial}`);
                requestForToken()
                  .then((payload) => {
                    addDeviceFcm(payload);
                  })
                  .catch((err) => console.log("failed: ", err));
              }
            }
          })
          .catch((err) => {
            if (err.response?.status === 403) {
              cekData(err);
            }
            dispatch(setLoading(false));
          });
      }
    }
  };

  const addDeviceFcm = async (payload?: any) => {
    // dispatch(setLoading(true));
    if (payload) {
      await API_USER.addDeviceFirebase(payload)
        .then((res) => {
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const initialCompany = async () => {
    dispatch(setLoading(true));
    await API_USER.getCompany(getInitialCompany)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setCompany(res.data.data));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const disabledButton = () => {
    if (isChecked && email !== "") {
      return false;
    } else {
      return true;
    }
  };

  const submitEmail = async (evt: any) => {
    evt.preventDefault();
  };

  const addPassword = () => {
    setShowInputEmail(false);
    if (firstLogin === true) {
      navigate(
        `/${prefix === "" ? company.initial : prefix}/login/create-password`,
        {}
      );
    } else {
      navigate(`/${prefix === "" ? company.initial : prefix}`, {});
    }
  };

  const cekPassword = async (prefixText = prefix, usernameText = username) => {
    if (username === "") {
      toast.error(t('login.toastUsernameEmpty'));
    } else {
      if (prefixText) {
        dispatch(setLoading(true));
        await API_USER.getCompany(prefix)
          .then((res) => {
            dispatch(setCompany(res.data.data));
            // setCompanyData(res.data.data);
            setPrefixId(res.data.data.id);
            API.cekPasswordUser(res.data.data.id, username)
              .then((res) => {
                if (res.data.data.passwordExist === true) {
                  setshowPasswordForm(true);
                } else {
                  setshowCreatePassword(true);
                }
                setCyperText(res.data.data.session);
                dispatch(setLoading(false));
              })
              .catch((err) => {
                dispatch(setLoading(false));
              });
          })
          .catch((err) => {
            setPrefix("");
            setUsername(usernameEmail);
            cekPassword("", usernameEmail);
            dispatch(setLoading(false));
          });
      } else {
        dispatch(setLoading(true));
        await API.cekPasswordUser(company.id, usernameText)
          .then((res) => {
            if (res.data.data.passwordExist === true) {
              setshowPasswordForm(true);
            } else {
              setshowCreatePassword(true);
            }
            setCyperText(res.data.data.session);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    }
  };

  const confirmNewPassword = async () => {
    if (!checkRegex("password", newPassword, strength)) {
      toast.error(`${AlertPasswordStrength(strength)}`);
    } else if (newPassword2 === "") {
      toast.error("Konfirmasi password belum di isi");
    } else if (!checkRegex("password", newPassword2, strength)) {
      toast.error(`${AlertPasswordStrength(strength)}`);
    } else {
      dispatch(setLoading(true));
      let data = {
        username: username,
        password: newPassword,
        confirmPassword: newPassword2,
        companyId: company.id,
      };
      await API.createPasswordUser(company.id, data)
        .then((res) => {
          setshowCreatePassword(false);
          setshowPasswordForm(true);
          toast.success(t('login.toastSuccessCreatePassword'));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const confirmEmail = () => {
    let data = {
      email: emailFer,
      otpPrevEmail: NewValues.join(""),
      otpNewEmail: NewValues.join(""),
    };
    dispatch(setLoading(true));
    API_USER.verificationEmail(data)
      .then((res) => {
        setShowDataVerification(false);
        dispatch(setLoading(true));
        var query = {
          username,
          password,
          companyId: company.id,
          deviceId: deviceId,
        };
        dispatch(setSkipTo(`/${company.initial}`));
        // API.login(query, "").then((res) => {});
        setSuccess(true);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setShowDataVerification(false);
      });
  };

  const confirmPhone = () => {
    let newData = {
      phone: phoneFer,
      otpPrevPhone: NewValues.join(""),
      otpNewPhone: NewValues.join(""),
    };
    dispatch(setLoading(true));
    API_USER.verificationPhone(newData)
      .then((res) => {
        setShowDataVerification(false);
        setSuccess(true);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setShowDataVerification(false);
      });
  };

  const hideModal = () => {
    setShowModalEmail(false);
    setShowModalPhone(false);
    setEmailFer(user?.email === "" ? "" : user?.email);
    setPhoneFer(user?.phone === "" ? "" : user?.phone);
  };

  const requestOtp = async () => {
    if (selected === "EMAIL") {
      if (user?.verifiedEmail === true) {
        toast.success(t('login.toastEmailVerified'));
        login();
        setShowVerificationForm(false);
      } else {
      }
    } else {
      if (user?.verifiedPhone === true) {
        toast.success(t('login.toastTelpVerified'));
      } else if (phoneFer === "") {
        toast.error(t('login.toastTelpError'));
      } else {
      }
    }
  };

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <button
          className="text-[#26A69A] my-3"
          type="button"
          onClick={() => (selected === "WA" ? changePhone() : changeEmail())}
        >
          {t('login.resending')}
        </button>
      );
    } else {
      return (
        <span className="text-[14px] text-center my-4">
          <span>{t('login.resendOtp')}</span>
          <span className="text-[#FF9723] font-semibold ml-2">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };

  const handleUsername = (e: any) => {
    e.preventDefault();
    cekPassword();
  };

  const handlePassword = (e: any) => {
    e.preventDefault();
    logiin();
  };

  useEffect(() => {
    setTimer(Date.now() + 300000);
    if (link || getInitialCompany || getCheckout) {
      initialCompany();
      dispatch(
        setOtpRedirect(
          `/${getInitialCompany}?link=${link}&initialcompany=${getInitialCompany}`
        )
      );
      setRedirect(
        `/${getInitialCompany}?link=${link}&initialcompany=${getInitialCompany}`
      );
      localStorage.setItem("co_link", link as any);
    } else {
      localStorage.removeItem("co_link");
    }
    emailRef.current?.focus();
    const fpPromise = fingerPrint.load();

    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        const visitorId = result.visitorId;
        setDeviceId(visitorId);
        dispatch(setOtpDeviceId(visitorId));
      });
    dispatch(setOtpTransactonType("NewDevice"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!company) {
      dispatch(setLoading(true));
      API_USER.getCompany(params.cid)
        .then((res) => {
          dispatch(setLoading(false));
          dispatch(setCompany(res.data.data));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  }, []);

  useEffect(() => {
    if (showCreatePassword) {
      getPasswordStrength();
    }
  }, [showCreatePassword]);

  return (
    <>
      <BrowserView>
        {/* Modal Add Email & Phone*/}
        <div
          className={`${modalVerificationWa ? "block" : "hidden"
            } relative z-10`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl px-4 py-5">
                <button
                  onClick={() => {
                    setModalVerificationWa(false);
                    window.location.reload();
                  }}
                  className="absolute right-4 top-4"
                >
                  X
                </button>
                <span className="text-[20px] font-semibold mb-2">
                  {t('login.activityVerification')}
                </span>
                <div className="text-left mt-4">
                  <span>{t('login.verificationViaTelp')}</span>
                  <input
                    disabled
                    type="number"
                    value={phoneFer}
                    className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                  ></input>
                  <p className="mt-5 text-center">
                    {t('login.verificationDesc')}
                  </p>
                </div>
                <Countdown key={timer} date={timer} renderer={renderer} />
                <button
                  onClick={() => {
                    checkTokenWa();
                  }}
                  className="bg-green text-white py-2 mt-5 rounded-lg w-full"
                >
                  {t('login.alreadyVerified')}
                </button>
                {/* <span
                  className="mt-4 text-xs cursor-pointer"
                  onClick={() => {
                    setShowDataVerification(false);
                    navigate(`/${company.initial}`);
                  }}
                >
                  Lewati langkah ini
                </span> */}
              </div>
            </div>
          </div>
        </div>
        {/* End Modal */}
        <LoginWeb
          company={company}
          user={user}
          token={token}
          isEverChooseCompany={isEverChooseCompany}
          destinationEmailVerified={destinationEmailVerified}
          destinationPhoneVerified={destinationPhoneVerified}
          userLogin={userLogin}
          deviceId={deviceId}
          setDeviceId={(value: any) => setDeviceId(value)}
          username={username}
          setUsername={(value: any) => setUsername(value)}
          password={password}
          setPassword={(value: any) => setPassword(value)}
          email={email}
          setEmail={(value: any) => setEmail(value)}
          errorUsername={errorUsername}
          setErrorUsername={(value: any) => setErrorUsername(value)}
          errorPassword={errorPassword}
          setErrorPassword={(value: any) => setErrorPassword(value)}
          showOtpEmail={showOtpEmail}
          setShowOtpEmail={(value: any) => setShowOtpEmail(value)}
          showInputEmail={showInputEmail}
          setShowInputEmail={(value: any) => setShowInputEmail(value)}
          redirect={redirect}
          setRedirect={(value: any) => setRedirect(value)}
          isChecked={isChecked}
          setIsChecked={(value: any) => setIsChecked(value)}
          visible={visible}
          setVisible={(value: any) => setVisible(value)}
          firstLogin={firstLogin}
          setFirstLogin={(value: any) => setFirstLogin(value)}
          dispatch={dispatch}
          navigate={navigate}
          emailRef={emailRef}
          passwordRef={passwordRef}
          prefix={prefix}
          setPrefix={(value: any) => setPrefix(value)}
          prefixId={prefixId}
          setPrefixId={(value: any) => setPrefixId(value)}
          usernameEmail={usernameEmail}
          setUsernameEmail={(value: any) => setUsernameEmail(value)}
          showPasswordForm={showPasswordForm}
          setshowPasswordForm={(value: any) => setshowPasswordForm(value)}
          showCreatePassword={showCreatePassword}
          setshowCreatePassword={(value: any) => setshowCreatePassword(value)}
          newPassword={newPassword}
          setNewPassword={(value: any) => setNewPassword(value)}
          newPassword2={newPassword2}
          setNewPassword2={(value: any) => setNewPassword2(value)}
          addEmailForm={addEmailForm}
          setAddEmailForm={(value: any) => setAddEmailForm(value)}
          destinationOtp={destinationOtp}
          setDestinationOtp={(value: any) => setDestinationOtp(value)}
          showDataVerification={showDataVerification}
          setShowDataVerification={(value: any) =>
            setShowDataVerification(value)
          }
          NewValues={NewValues}
          setNewValues={(value: any) => setNewValues(value)}
          timer={timer}
          setTimer={(value: any) => setTimer(value)}
          showVerificationFrom={showVerificationFrom}
          setShowVerificationForm={(value: any) =>
            setShowVerificationForm(value)
          }
          isAddData={isAddData}
          setIsAddData={(value: any) => setIsAddData(value)}
          visiblePW1={visiblePW1}
          setVisiblePW1={(value: any) => setVisiblePW1(value)}
          visiblePW2={visiblePW2}
          setVisiblePW2={(value: any) => setVisiblePW2(value)}
          success={success}
          setSuccess={(value: any) => setSuccess(value)}
          showModalEmail={showModalEmail}
          setShowModalEmail={(value: any) => setShowModalEmail(value)}
          showModalPhone={showModalPhone}
          setShowModalPhone={(value: any) => setShowModalPhone(value)}
          selected={selected}
          setSelected={(value: any) => setSelected(value)}
          emailFer={emailFer}
          setEmailFer={(value: any) => setEmailFer(value)}
          phoneFer={phoneFer}
          setPhoneFer={(value: any) => setPhoneFer(value)}
          data={data}
          setData={(value: any) => setData(value)}
          cekData={cekData}
          changeEmail={changeEmail}
          changePhone={changePhone}
          logiin={logiin}
          login={login}
          addDeviceFcm={addDeviceFcm}
          initialCompany={initialCompany}
          disabledButton={disabledButton}
          submitEmail={submitEmail}
          addPassword={addPassword}
          cekPassword={cekPassword}
          confirmNewPassword={confirmNewPassword}
          confirmEmail={confirmEmail}
          confirmPhone={confirmPhone}
          hideModal={hideModal}
          requestOtp={requestOtp}
          renderer={renderer}
          encriptedvalue={encryptedValue}
          stateEnd={stateEnd}
          handleUsername={handleUsername}
          handlePassword={handlePassword}
          getProfil={profile}
        ></LoginWeb>
      </BrowserView>
      <MobileView>
        {/* Modal Add Email & Phone*/}
        <div
          className={`${modalVerificationWa ? "block" : "hidden"
            } relative z-10`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl px-4 py-5">
                <button
                  onClick={() => {
                    setModalVerificationWa(false);
                    window.location.reload();
                  }}
                  className="absolute right-4 top-4"
                >
                  X
                </button>
                <span className="text-[20px] font-semibold mb-2">
                  {t('login.activityVerification')}
                </span>
                <div className="text-left mt-4">
                  <span>{t('login.verificationViaTelp')}</span>
                  <input
                    disabled
                    type="number"
                    value={phoneFer}
                    className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                  ></input>
                  <p className="mt-5 text-center">
                    {t('login.verificationDesc')}
                  </p>
                </div>
                <Countdown key={timer} date={timer} renderer={renderer} />
                <button
                  onClick={() => {
                    checkTokenWa();
                  }}
                  className="bg-green text-white py-2 mt-5 rounded-lg w-full"
                >
                  {t('login.alreadyVerified')}
                </button>
                {/* <span
                  className="mt-4 text-xs cursor-pointer"
                  onClick={() => {
                    setShowDataVerification(false);
                    navigate(`/${company.initial}`);
                  }}
                >
                  Lewati langkah ini
                </span> */}
              </div>
            </div>
          </div>
        </div>
        {/* End Modal */}
        <LoginMobile
          company={company}
          user={user}
          token={token}
          isEverChooseCompany={isEverChooseCompany}
          destinationEmailVerified={destinationEmailVerified}
          destinationPhoneVerified={destinationPhoneVerified}
          userLogin={userLogin}
          deviceId={deviceId}
          setDeviceId={(value: any) => setDeviceId(value)}
          username={username}
          setUsername={(value: any) => setUsername(value)}
          password={password}
          setPassword={(value: any) => setPassword(value)}
          email={email}
          setEmail={(value: any) => setEmail(value)}
          errorUsername={errorUsername}
          setErrorUsername={(value: any) => setErrorUsername(value)}
          errorPassword={errorPassword}
          setErrorPassword={(value: any) => setErrorPassword(value)}
          showOtpEmail={showOtpEmail}
          setShowOtpEmail={(value: any) => setShowOtpEmail(value)}
          showInputEmail={showInputEmail}
          setShowInputEmail={(value: any) => setShowInputEmail(value)}
          redirect={redirect}
          setRedirect={(value: any) => setRedirect(value)}
          isChecked={isChecked}
          setIsChecked={(value: any) => setIsChecked(value)}
          visible={visible}
          setVisible={(value: any) => setVisible(value)}
          firstLogin={firstLogin}
          setFirstLogin={(value: any) => setFirstLogin(value)}
          dispatch={dispatch}
          navigate={navigate}
          emailRef={emailRef}
          passwordRef={passwordRef}
          prefix={prefix}
          setPrefix={(value: any) => setPrefix(value)}
          prefixId={prefixId}
          setPrefixId={(value: any) => setPrefixId(value)}
          usernameEmail={usernameEmail}
          setUsernameEmail={(value: any) => setUsernameEmail(value)}
          showPasswordForm={showPasswordForm}
          setshowPasswordForm={(value: any) => setshowPasswordForm(value)}
          showCreatePassword={showCreatePassword}
          setshowCreatePassword={(value: any) => setshowCreatePassword(value)}
          newPassword={newPassword}
          setNewPassword={(value: any) => setNewPassword(value)}
          newPassword2={newPassword2}
          setNewPassword2={(value: any) => setNewPassword2(value)}
          addEmailForm={addEmailForm}
          setAddEmailForm={(value: any) => setAddEmailForm(value)}
          destinationOtp={destinationOtp}
          setDestinationOtp={(value: any) => setDestinationOtp(value)}
          showDataVerification={showDataVerification}
          setShowDataVerification={(value: any) =>
            setShowDataVerification(value)
          }
          NewValues={NewValues}
          setNewValues={(value: any) => setNewValues(value)}
          timer={timer}
          setTimer={(value: any) => setTimer(value)}
          showVerificationFrom={showVerificationFrom}
          setShowVerificationForm={(value: any) =>
            setShowVerificationForm(value)
          }
          isAddData={isAddData}
          setIsAddData={(value: any) => setIsAddData(value)}
          visiblePW1={visiblePW1}
          setVisiblePW1={(value: any) => setVisiblePW1(value)}
          visiblePW2={visiblePW2}
          setVisiblePW2={(value: any) => setVisiblePW2(value)}
          success={success}
          setSuccess={(value: any) => setSuccess(value)}
          showModalEmail={showModalEmail}
          setShowModalEmail={(value: any) => setShowModalEmail(value)}
          showModalPhone={showModalPhone}
          setShowModalPhone={(value: any) => setShowModalPhone(value)}
          selected={selected}
          setSelected={(value: any) => setSelected(value)}
          emailFer={emailFer}
          setEmailFer={(value: any) => setEmailFer(value)}
          phoneFer={phoneFer}
          setPhoneFer={(value: any) => setPhoneFer(value)}
          data={data}
          setData={(value: any) => setData(value)}
          cekData={cekData}
          changeEmail={changeEmail}
          changePhone={changePhone}
          logiin={logiin}
          login={login}
          addDeviceFcm={addDeviceFcm}
          initialCompany={initialCompany}
          disabledButton={disabledButton}
          submitEmail={submitEmail}
          addPassword={addPassword}
          cekPassword={cekPassword}
          confirmNewPassword={confirmNewPassword}
          confirmEmail={confirmEmail}
          confirmPhone={confirmPhone}
          hideModal={hideModal}
          requestOtp={requestOtp}
          renderer={renderer}
          encriptedvalue={encryptedValue}
          stateEnd={stateEnd}
          handleUsername={handleUsername}
          handlePassword={handlePassword}
          getProfil={profile}
        ></LoginMobile>
      </MobileView>
    </>
  );
};

export default Login;
