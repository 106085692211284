import { useEffect, useState } from "react";
import Header from "../_common/headerComponent";
import QrScanner from "qr-scanner";
import { useLocation, useNavigate } from "react-router-dom";
import BottomLogo from "../_common/bottomLogo";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import * as API_ABSENSI from "../../core/service/api_absensi";
import { ConfigColor } from "../../config/global";
import arrow from "../../assets/images/arrow-left-ic.svg";
import toast from "react-hot-toast";
import failed from "../../assets/images/icon_features/failed.svg";
import { useTranslation } from "react-i18next";

const FeatureScanBarcodeMobile = () => {
  const [btnScan, setBtnScan] = useState(true);
  const color = ConfigColor();
  // const [hasilScan, setHasilScan] = useState("");
  // const navigate = useNavigate();
  // const [id, setId] = useState<any>("");
  // const [activity, setActivity] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});
  const [visible, setVisible] = useState(false);
  let location: any = useLocation();
  let stopScan = false;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [erorMessage, setErorMessage] = useState("");

  const scanNow = async (isScan: any) => {
    setBtnScan(isScan);
    if (isScan === false) stopScan = true;
    await new Promise((r) => setTimeout(r, 100));
    const videoElement: any = document.getElementById("scanView");
    const scanner = new QrScanner(
      videoElement,
      (result: any) => {
        getData(result);
        scanner.stop();
        scanner.destroy();
      },
      {
        onDecodeError: (error) => { },
        maxScansPerSecond: 1,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        returnDetailedScanResult: true,
      }
    );
    await scanner.start();
    while (stopScan === false) await new Promise((r) => setTimeout(r, 100));
    scanner.stop();
    scanner.destroy();
  };

  const getData = (result: any) => {
    let query = {
      qrId: result.data,
    };
    console.log(result.data, result.data.length);
    if (result?.data?.length === 24) {
      dispatch(setLoading(true));
      API_ABSENSI.attendancePresent(query)
        .then((res) => {
          localStorage.setItem("result", JSON.stringify(res));
          navigate("result", { replace: true });
          dispatch(setLoading(false));
        })
        .catch((err) => {
          let counter: any = sessionStorage.getItem("scanCounter");
          let counterTotal: any = JSON.parse(counter);
          if (counter === null) {
            sessionStorage.setItem("scanCounter", JSON.stringify(1));
            setErorMessage(t('accessibility.toast_scan_qr_error'));
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            if (counterTotal >= 5) {
              navigate("result", { replace: true });
            } else {
              sessionStorage.setItem(
                "scanCounter",
                JSON.stringify(counterTotal + 1)
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              setErorMessage(t('accessibility.toast_scan_qr_error'));
            }
          }
          dispatch(setLoading(false));
        });
    } else {
      dispatch(setLoading(true));
      API_ABSENSI.attendancePresentOfflineMode(query)
        .then((res) => {
          localStorage.setItem("result", JSON.stringify(res));
          navigate("result", { replace: true });
          dispatch(setLoading(false));
        })
        .catch((err) => {
          let counter: any = sessionStorage.getItem("scanCounter");
          let counterTotal: any = JSON.parse(counter);
          if (counter === null) {
            sessionStorage.setItem("scanCounter", JSON.stringify(1));
            setErorMessage(t('accessibility.toast_scan_qr_error'));
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            if (counterTotal >= 5) {
              navigate("result", { replace: true });
            } else {
              sessionStorage.setItem(
                "scanCounter",
                JSON.stringify(counterTotal + 1)
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              setErorMessage(t('accessibility.toast_scan_qr_error'));
            }
          }
          dispatch(setLoading(false));
        });
    }
  };

  useEffect(() => {
    scanNow(true);
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col justify-between min-h-screen bg-mobile">
        <div>
          <section className="flex flex-tow items-center z-50 px-4 py-3 mb-7">
            <div
              onClick={async () => {
                await scanNow(false);
                navigate(-1);
              }}
              className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
            >
              <img
                src={arrow}
                style={{ width: 6, height: 12 }}
                className="m-auto"
                alt=""
              />
            </div>

            <h1
              className={`font-montserrat font-bold text-base m-auto relative`}
            >
              {t('accessibility.scan_barcode')}
            </h1>
          </section>
          <div className="w-full font-montserrat absolute min-h-screen -mt-6">
            {/* {btnScan === false && ( */}
            <video
              id="scanView"
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "100%",
                minHeight: "100%",
                borderStyle: "dotted",
              }}
            ></video>
            <h3 className="text-center text-white text-sm px-4 mb-4 -mt-14 absolute z-50">
              {t('accessibility.message_barcode')}
            </h3>
            {erorMessage !== "" && (
              <img src={failed} alt="" className="flex mx-auto" />
            )}
            <h3 className="text-center text-red text-sm px-4 mt-5 mb-4 z-50">
              {erorMessage}
            </h3>
          </div>
        </div>
        <div className="w-full p-4">
          <button
            onClick={async () => {
              console.log("klik");
              await scanNow(false);
              navigate(-1);
              localStorage.removeItem("result");
            }}
            className={
              btnScan
                ? "py-2 px-4 rounded-lg w-full bg-gradient-to-b border-1 relative z-10 text-white border-white"
                : ""
            }
            style={{ backgroundColor: `${color.color1}` }}
          >
            {t('accessibility.cancel')}
          </button>
        </div>
      </div>
    </>
  );
};

export default FeatureScanBarcodeMobile;
