import moment from "moment";
import { useState } from "react";
import QRCode from "react-qr-code";
import { formatCurrency } from "../../config/global";
import Header from "../_common/headerComponent";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const ParkingFeaturesDetailMobile = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [bayar, setBayar] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="w-full font-montserrat bg-mobile min-h-screen h-fit flex flex-col">
      <Header
        navbarText={t("accessibility.parking")}
        backgroundStyle="bg-green"
      />
      <div className="bg-green grow w-full h-full mx-auto md:pt-10">
        <div className="px-4 pt-4">
          <div className="bg-white md:w-3/4 w-full mx-auto rounded-[14px] px-8 py-[12px] mb-4">
            <div className="flex justify-between items-center border-b-[1px] border-b-[#E5E5E5] border-dotted mb-2 pb-2">
              <div className="text-start">
                <h6 className="font-semibold text-customBlack md:text-lg text-base m-0">
                  {t("accessibility.Info")}
                </h6>
                <span className="md:text-sm text-xs text-newColor">
                  {moment(props.data?.loginTime).format("DD MMMM YYYY")}
                </span>
              </div>
              <div className="text-end">
                <h6 className="md:text-sm text-xs text-newColor m-0">Status</h6>
                <span
                  className={`${
                    props.data?.status === "LOGIN"
                      ? "text-green"
                      : "text-[#FF9723]"
                  } font-semibold md:text-lg text-base`}
                >
                  {props.data?.status === "LOGIN" ? "Checkin" : "Checkout"}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 border-b-[1px] border-b-[#E5E5E5] border-dotted">
              <div className="text-start">
                <h6 className="md:text-sm text-xs text-newColor m-0">
                  {t("accessibility.in_date")}
                </h6>
                <span className="text-customBlack font-semibold">
                  {moment
                    .utc(props.data?.loginTime)
                    .local()
                    .format("DD-MM-YYYY, HH:mm:ss")}
                </span>
              </div>
              <div className="text-start">
                <h6 className="md:text-sm text-xs text-newColor m-0">
                  {t("accessibility.out_date")}
                </h6>
                <span className="text-customBlack font-semibold">
                  {props?.data?.logout
                    ? moment
                        .utc(props.data?.logout?.logoutTime)
                        .local()
                        .format("DD-MM-YYYY, HH:mm:ss")
                    : "-"}
                </span>
              </div>
              <div className="text-start">
                <h6 className="md:text-sm text-xs text-newColor m-0">
                  {t("accessibility.activity")}
                </h6>
                <span className="text-customBlack font-semibold">
                  {props.data?.parkingActivity?.name}
                </span>
              </div>
              <div className="text-start">
                <h6 className="md:text-sm text-xs text-newColor m-0">
                  {t("accessibility.method")}
                </h6>
                <span className="text-customBlack font-semibold">
                  {props.data?.method === "CARD"
                    ? t("accessibility.card")
                    : props.data?.method === "QR_LISTEN"
                    ? t("accessibility.create_code_qr")
                    : t("accessibility.scan_qr")}
                </span>
              </div>
              <div className="text-start">
                <h6 className="md:text-sm text-xs text-newColor m-0">
                  {t("accessibility.vehicle_type")}
                </h6>
                <span className="text-customBlack font-semibold">
                  {props.data?.vehicleType === "MOTORCYCLE"
                    ? t("accessibility.motorcycle")
                    : t("accessibility.car")}
                </span>
              </div>
              <div className="text-start">
                <h6 className="md:text-sm text-xs text-newColor m-0">
                  {t("accessibility.number_plate")}
                </h6>
                <span className="text-customBlack font-semibold">
                  {!props.data?.licensePlate ||
                  props.data?.licensePlate === "string" ||
                  props.data?.licensePlate === ""
                    ? "-"
                    : props.data?.licensePlate}
                </span>
              </div>
              <div className="text-start">
                <h6 className="md:text-sm text-xs text-newColor m-0">
                  {t("accessibility.device")}
                </h6>
                <span className="text-customBlack font-semibold">
                  {props.data?.device?.name}
                </span>
              </div>
              <div className="text-start">
                <h6 className="md:text-sm text-xs text-newColor m-0">
                  {t("accessibility.category_user")}
                </h6>
                <span className="text-customBlack font-semibold">
                  {props.data?.memberTags?.join(",")}
                </span>
              </div>
            </div>
            <div className="mt-2">
              <span className="text-newColor">
                {t("accessibility.parking_price")}
              </span>
              <div className="flex justify-between mt-2">
                <div className="flex flex-col">
                  <span className="md:text-sm text-xs text-newColor m-0">
                    {t("accessibility.first_1_hour")}
                  </span>
                  <span className="text-xs">
                    {formatCurrency(props?.data?.detailPrice?.priceFirstHour)}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="md:text-sm text-xs text-newColor m-0">
                    {t("accessibility.price_hour")}
                  </span>
                  <span className="text-xs">
                    {formatCurrency(props?.data?.detailPrice?.pricePerHour)}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="md:text-sm text-xs text-newColor m-0">
                    {t("accessibility.price_day")}
                  </span>
                  <span className="text-xs">
                    {formatCurrency(props?.data?.detailPrice?.pricePerDay)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4">
          <div className="bg-white md:w-3/4 w-full mx-auto rounded-[14px] px-8 py-[12px] md:mb-72">
            <div className="flex justify-between items-center gap-2 border-b-[1px] border-b-[#E5E5E5] border-dotted mb-2 pb-2">
              <h6 className="md:text-lg text-base font-semibold m-0">
                {t("accessibility.payment_detail")}
              </h6>
              <div
                className={`${
                  props.isPaid ? "bg-green" : "bg-[#DC3545]"
                } rounded-[4px] py-1 px-2 md:text-sm text-xs text-white font-medium`}
              >
                {props.isPaid > 0 ? (
                  <span>{t("accessibility.paid_off")}</span>
                ) : (
                  <span>{t("accessibility.not_yet_paid")}</span>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center gap-2">
              <div className="text-start">
                <h6 className="font-light text-newColor md:text-sm text-xs m-0">
                  {t("accessibility.parking_fee")}
                </h6>
                <span className="md:text-sm text-xs text-customBlack font-medium">
                  {formatCurrency(props.data?.detailPayment?.amountPrice)}
                </span>
              </div>
              <div className="text-start">
                <h6 className="font-light text-newColor md:text-sm text-xs m-0">
                  {t("accessibility.serviveFee")}
                </h6>
                <span className="md:text-sm text-xs text-customBlack font-medium">
                  {formatCurrency(
                    props.data?.detailPayment?.amountChargeMaster
                  )}
                </span>
              </div>
              <div className="text-start">
                <h6 className="font-light text-newColor md:text-sm text-xs m-0">
                  {t("accessibility.total_payment")}
                </h6>
                <span className="md:text-sm text-xs text-customBlack font-medium">
                  {formatCurrency(props.data?.detailPayment?.amountPayment)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {props.data?.payments?.length > 0 && (
          <div className="px-4 mt-4">
            <div className="bg-white md:w-3/4 w-full mx-auto rounded-[14px] px-8 py-[12px] md:mb-72">
              <div className="flex justify-between items-center gap-2 border-b-[1px] border-b-[#E5E5E5] border-dotted mb-2 pb-2">
                <h6 className="md:text-lg text-base font-semibold m-0">
                  {t("accessibility.payment_history")}
                </h6>
              </div>
              <div className="items-center max-h-[300px] overflow-y-auto gap-2">
                {props.data?.payments?.length > 0 ? (
                  <>
                    {props.data?.payments?.map((res: any, i: any) => {
                      let myArray = res.charges?.map((item: any, i: any) => {
                        return item.value;
                      });
                      const total = myArray.reduce(
                        (accumulator: any, currentValue: any) =>
                          accumulator + currentValue,
                        0
                      );
                      console.log(total);
                      return (
                        <Disclosure key={i}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 py-2.5 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                <span className="text-[13px]">
                                  {moment(res.lastModifiedDate).format(
                                    "DD MMMM YYYY, HH:mm:ss"
                                  )}
                                </span>
                                <span className="font-semibold">
                                  {formatCurrency(res.amountPayment)}{" "}
                                  <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-4 w-4 text-purple-500`}
                                  />
                                </span>
                              </Disclosure.Button>
                              <Disclosure.Panel>
                                <div className="gap-2 flex flex-col">
                                  <div className="text-start flex justify-between">
                                    <h6 className="font-light text-newColor md:text-sm text-xs m-0">
                                      {t("accessibility.method")}
                                    </h6>
                                    <span className="md:text-sm text-xs text-customBlack font-medium">
                                      {res.method === "BALANCE"
                                        ? t("accessibility.balance")
                                        : res.method === "MERCHANT"
                                        ? t("accessibility.helper")
                                        : res.method === "SUBSCRIPTION_PARKING"
                                        ? t("accessibility.subscriber")
                                        : "Checkout"}
                                    </span>
                                  </div>
                                  <div className="text-start flex justify-between">
                                    <h6 className="font-light text-newColor md:text-sm text-xs m-0">
                                      {t("accessibility.parking_fee")}
                                    </h6>
                                    <span className="md:text-sm text-xs text-customBlack font-medium">
                                      {formatCurrency(res?.amountPrice)}
                                    </span>
                                  </div>
                                  <div className="text-start flex justify-between">
                                    <h6 className="font-light text-newColor md:text-sm text-xs m-0">
                                      {t("accessibility.serviveFee")}
                                    </h6>
                                    <div className="md:text-sm text-xs text-customBlack font-medium flex flex-col">
                                      {formatCurrency(total)}
                                    </div>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      );
                    })}
                  </>
                ) : (
                  <div>{t("accessibility.no_history_payment")}</div>
                )}
              </div>
            </div>
          </div>
        )}

        <div>
          <div className="bg-white flex flex-col items-center gap-3 w-full relative mt-10  bottom-0 rounded-t-[20px] px-6 pt-9">
            <h6 className="font-light md:text-base text-sm text-center w-1/2 m-0">
              {t("accessibility.message_ispaid")}
            </h6>
            <QRCode className="max-w-[180px]" value={props.valueQR} />

            {/* <button
            className="text-white w-full py-[12px] rounded-[10px] font-medium md:text-base text-sm"
            type="button"
            style={{
              backgroundColor:
                company?.color?.color1 !== undefined
                  ? company?.color?.color1
                  : "#26A69A",
            }}
            onClick={() => {
              props.generateQRCode();
            }}
          >
            Show QR
          </button> */}
          </div>

          <div
            className="bg-white w-full px-6 py-9"
            // style={{ transform: "translate(-50%, 0%)" }}
          >
            {props.isLogout ? (
              <button
                className="text-white w-full py-[12px] rounded-[10px] font-medium md:text-base text-sm"
                type="button"
                style={{
                  backgroundColor:
                    company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A",
                }}
                onClick={() => {
                  props.setModalQR(true);
                  // setBayar(true);
                }}
              >
                {t("accessibility.show_qr")}
              </button>
            ) : (
              <button
                className="text-white w-full py-[12px] rounded-[10px] font-medium md:text-base text-sm"
                type="button"
                style={{
                  backgroundColor:
                    company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A",
                }}
                onClick={() => {
                  // props.generateQRCode();
                  // setBayar(true);

                  props.parkingPayment();
                }}
              >
                {t("accessibility.pay")}
              </button>
            )}
          </div>
        </div>
        <BottomSheet open={bayar} onDismiss={() => setBayar(false)}>
          <div className="w-full px-4 py-2">
            <p className="font-semibold mt-4">
              {t("accessibility.paymentMethod")}
            </p>
            <div
              className="border-[#E2E2E2] border-[1px] rounded-[6px] flex items-center gap-3 p-3 mb-3"
              onClick={() => {
                props.regenerateQRIS();
                setSelectedPaymentMethod("1");
              }}
            >
              <p className="md:text-base text-sm text-[#252525] font-normal w-full mb-0">
                QRIS
              </p>
              <input
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                type="radio"
                checked={"1" === selectedPaymentMethod}
                onChange={() => {
                  props.regenerateQRIS();
                  setBayar(false);
                  setSelectedPaymentMethod("1");
                }}
                value=""
              />
            </div>
            {/* <div
          className="border-[#E2E2E2] border-[1px] rounded-[6px] flex items-center gap-3 p-3 mb-3"
          onClick={() => {
            props.setModalCard(true);
            setSelectedPaymentMethod("2");
          }}
        >
          <p className="md:text-base text-sm text-[#252525] font-normal w-full mb-0">
            Kartu Member
          </p>
          <input
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
            type="radio"
            checked={"2" === selectedPaymentMethod}
            onChange={() => {
              props.setModalCard(true);
              setSelectedPaymentMethod("2");
            }}
            value=""
          />
        </div> */}
            <div
              className="border-[#E2E2E2] border-[1px] rounded-[6px] flex items-center gap-3 p-3 mb-3"
              onClick={() => {
                props.checkoutLink();
                setSelectedPaymentMethod("3");
              }}
            >
              <p className="md:text-base text-sm text-[#252525] font-normal w-full mb-0">
                {t("accessibility.link_checkout")}
              </p>
              <input
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                type="radio"
                checked={"3" === selectedPaymentMethod}
                onChange={() => {
                  props.checkoutLink();
                  setSelectedPaymentMethod("3");
                }}
                value=""
              />
            </div>
          </div>
        </BottomSheet>
        {/* {props.isPaid ? (
          
        ) : (
          
        )} */}
      </div>
    </div>
  );
};

export default ParkingFeaturesDetailMobile;
