import { BrowserView, MobileView } from "react-device-detect";
import ScanQRAccessMobile from "./featureScanQRAccessMobile";
import ScanQRAccessWeb from "./featureScanQRAccessWeb";

const ScanQRAccess = () => {
  return (
    <>
      <BrowserView>
        <ScanQRAccessWeb />
      </BrowserView>
      <MobileView>
        <ScanQRAccessMobile />
      </MobileView>
    </>
  );
};

export default ScanQRAccess;
