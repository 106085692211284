/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
// import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import arrowRightIcon from "../../assets/images/Iconly/Light-Outline/ArrowRight.svg";
import LogoIcon from "../../assets/images/icon_closepay/LOGOCP.svg";
import {
  blurAccountNumber,
  clientPaging,
  formatCurrency,
  formatDate,
  imageOnError,
} from "../../config/global.js";
import Header from "../_common/headerComponent";
import DonationTransactionSkeleton from "../_common/skeleton/DonationtransactionSkeleton";
import DataNotFound from "../_common/dataNotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const DonationDisbursmentWeb = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setsize] = useState(10);
  const [page, setpage] = useState(1);
  const [sortBy] = useState("createTime");
  const [dir] = useState(-1);
  const [toggle, setToggle] = useState(false);

  const [data, setdata] = useState<any>([]);
  const [dataPaging, setdataPaging] = useState<any>([]);
  const [totalElement, setTotalElement] = useState(0);
  // const [totalPage, setTotalPage] = useState(0);

  const query: any = useLocation();

  const getDatas = () => {
    setdata([]);
    setIsLoading(true);
    let dataT = //query.state?.disbursement;
      _.orderBy(query.state?.disbursement, "date", "desc");
    setdata(dataT);
    clientPaging(dataT, page, size)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setdataPaging(res.data);
        setpage(res.paging.page);
        setsize(res.paging.size);
        setTotalElement(res.paging.totalElement);
        // setTotalPage(res.paging.totalPage);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getMoreDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    setIsLoading(true);
    clientPaging(data, varPage, varSize)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setdataPaging([...dataPaging, ...res.data]);
        setpage(res.paging.page);
        setsize(res.paging.size);
        setTotalElement(res.paging.totalElement);
        // setTotalPage(res.paging.totalPage);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const countAmount = () => {
    return _.sumBy(data, "amount");
  };
  const countTotal = () => {
    return data.length;
  };

  useEffect(() => {
    getDatas();
  }, []);

  return (
    <>
      <Header></Header>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <div className="w-2/5 lg:w-1/2  min-w-[30rem] h-full px-12 py-4 m-auto font-Montserrat">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full">
              <div className="flex flex-col w-full bg-white rounded-md shadow-sm shadow-gray-700 gap-1 p-2">
                <div className="flex border-b-[.3px] border-solid mx-3 py-3 gap-4">
                  <div className="w-1/2 flex flex-col border-r-[.3px] border-solid">
                    <span className="font-semibold text-sm">
                      {formatCurrency(countAmount())}
                    </span>
                    <span className="font-normal text-xs">
                      {t("donation.fundsAlready")}
                    </span>
                  </div>
                  <div className="w-1/2 flex flex-col">
                    <span className="font-semibold text-sm">
                      {countTotal()}&nbsp;{t("donation.times")}
                    </span>
                    <span className="font-normal text-xs">{t('donation.fundWithdrawal')}</span>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="button"
                    onClick={() => setToggle(true)}
                    className="flex justify-center gap-4 p-2 text-sm font-light text-themePrimary"
                  >
                    <span>{t("donation.informationFundUsage")}</span>
                    <img src={arrowRightIcon} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/5 lg:w-1/2  min-w-[30rem] h-full m-auto px-12 py-4 font-Montserrat">
          <div className="flex flex-col items-center justify-center">
            <div className="w-full">
              <div className="flex flex-col gap-2">
                {dataPaging.length === 0 && !isLoading && (
                  <DataNotFound title={t("donation.noDataDana")}></DataNotFound>
                )}
                {_.map(dataPaging, (item) => (
                  <div
                    key={item._id}
                    className="flex flex-col w-full bg-white rounded-md shadow-sm shadow-gray-700 gap-2 p-4"
                  >
                    <div className="flex">
                      <span className="text-[10px] font-light">
                        {formatDate(
                          new Date(item?.createdTime),
                          "DD MMMM YYYY"
                        )}
                        {/* {moment(new Date(item?.date)).fromNow()} */}
                        {/* {moment(new Date(item?.date)).diff(moment(new Date())) <
                        0
                          ? moment(new Date(item?.date)).fromNow()
                          : moment(new Date(item?.date)).format("DD MMMM YYYY")} */}
                      </span>
                    </div>

                    <div className="w-full flex gap-2">
                      {/* <div className="flex justify-center items-center rounded-full w-12 h-12 bg-[#D9D9D9]"> */}
                      <img
                        className=" bg-contain w-9 h-9"
                        onError={imageOnError}
                        src={LogoIcon}
                        alt=""
                      />
                      {/* </div> */}
                      <div className="flex flex-col">
                        <span>{query?.state?.foundationName}</span>
                      </div>
                    </div>
                    <div className="flex">
                      <span className="text-base font-semibold">
                        {t("donation.fundWithdrawal")}&nbsp;{formatCurrency(item?.amount)}
                      </span>
                    </div>
                    <div className="flex">
                      <span className="text-sm text-[#6A6A6A] font-normal">
                        {`${t('donation.toAccount')} ${item.bankName} ${blurAccountNumber(
                          item.bankAccountNo
                        )} a/n ${item.bankAccountName}`}
                      </span>
                    </div>
                    <div className="text-sm text-[#3A3A3A] font-normal">
                      {item.planUseFunds}
                    </div>
                  </div>
                ))}
                {isLoading ? (
                  <>
                    {_.map(new Array(size), (el) => (
                      <DonationTransactionSkeleton></DonationTransactionSkeleton>
                    ))}
                  </>
                ) : null}
                {totalElement > dataPaging.length ? (
                  <div
                    className={`bg-themePrimary rounded-md py-1 text-center text-sm text-white font-semibold ${isLoading ? "cursor-progress" : "cursor-pointer"
                      }`}
                    onClick={() =>
                      !isLoading && getMoreDatas(false, size, page + 1)
                    }
                  >
                    {isLoading ? t('donation.waiting') : "Load More...."}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* Modal switch */}
        <div
          className={`${toggle ? "block" : "hidden"} relative z-50`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[50%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
                <div className="text-end md:px-[0px] px-[0.5rem]">
                  <button
                    type="button"
                    onClick={() => {
                      setToggle(false);
                    }}
                    className="fa-xl text-gray-400"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>

                <div className="px-3 py-4 flex flex-col gap-4 ">
                  <h6 className="font-semibold md:text-lg text-base text-[#263339]">
                    {t("donation.informationUsage")}
                  </h6>
                  <div className="flex flex-row justify-center gap-2">
                    <div className=" w-full flex border-[#26A69A] border-[1px] border-solid rounded-[10px] mx-3 p-[35px] px-[23px] gap-4">
                      <div className="w-full flex flex-col border-r-[.3px] border-solid">
                        <span className="font-semibold md:text-base text-sm text-[#263339]">
                          {formatCurrency(countAmount())}
                        </span>
                        <span className="font-normal md:text-sm text-xs text-[#263339]">
                          {t("donation.fundsAlready")}
                        </span>
                      </div>
                      <div className="w-full flex flex-col">
                        <span className="font-semibold md:text-base text-sm text-[#263339]">
                          {countTotal()}&nbsp;{t("donation.times")}
                        </span>
                        <span className="font-normal md:text-sm text-xs text-[#263339]">
                          {t("donation.fundWithdrawal")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6 className="font-semibold md:text-lg text-base text-[#263339] mb-[10px]">
                      {t("donation.totalTransaction")}
                    </h6>
                    <div className="space-between">
                      <h6 className="font-normal md:text-base text-sm text-[#263339]">
                        {t("donation.donationAmount")}
                      </h6>
                      <h6 className="font-semibold md:text-base text-sm text-[#263339]">
                        {query.state.total_transaction} {t("donation.transaction")}
                      </h6>
                    </div>
                    <div className="space-between">
                      <h6 className="font-normal md:text-base text-sm text-[#263339]">
                        {t("donation.numberDonors")}
                      </h6>
                      <h6 className="font-semibold md:text-base text-sm text-[#263339]">
                        {query.state.total_funder} {t("donation.donors")}
                      </h6>
                    </div>
                    <div className="space-between">
                      <h6 className="font-normal md:text-base text-sm text-[#263339]">
                        {t("donation.fundsCollected")}
                      </h6>
                      <h6 className="font-semibold md:text-base text-sm text-[#263339]">
                        {formatCurrency(query.state.total_dana)}
                      </h6>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 mt-4">
                    <button
                      onClick={() => setToggle(false)}
                      type="button"
                      className="bg-themePrimary text-white w-full h-10 rounded-md"
                    >
                      {t("donation.close")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
      </section>
    </>
  );
};

export default DonationDisbursmentWeb;
