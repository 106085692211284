import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SavingListMobile from "./savingListMobile";
import SavingListWeb from "./savingListWeb";

const SavingList = () => {
  return (
    <>
      <BrowserView>
        <SavingListWeb></SavingListWeb>
      </BrowserView>
      <MobileView>
        <SavingListMobile></SavingListMobile>
      </MobileView>
    </>
  );
};

export default SavingList;
