import { Link } from 'react-router-dom';
import Header from '../../_common/headerComponent';

const VirtualCardHistoryMobile = (props: any) => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Riwayat"></Header>
      <div className="container flex md:flex-row flex-col-reverse gap-4 md:mt-[64px] mt-[0px] px-4">
        <div className="md:w-1/2 w-full">
          {props.data.map((item: any) => (
            <Link to={item.url} key={item.id} className="no-underline">
              <div className="flex flex-row justify-between items-center gap-3 bg-white rounded-xl mb-4 shadow-sm md:p-[16px] p-[14px]">
                <img src={item.img} alt="" width={36} />
                <div className="text-start w-full">
                  <h3 className="md:text-xl text-base font-normal text-customBlack mb-0">{item.title}</h3>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

export default VirtualCardHistoryMobile;