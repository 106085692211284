/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { zeroPad } from "react-countdown";
import Swal from "sweetalert2";
import Header from "../_common/headerComponent";
import * as API_BALANCE from "../../core/service/api_balance";
import * as API_AUTH from "../../core/service/api_auth";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";
import QRIcon from "../../assets/images/icon_qr.svg";
import CurrencyInput from "react-currency-input-field";
import { formatCurrency, onlyNumber } from "../../config/global";
import SuccessIcon from "../../assets/images/icon-success-vcard.png";
import FailedIcon from "../../assets/images/failed-icon.png";
import Barcode from "react-barcode";
import { useNavigate } from "react-router-dom";
import Company from "../../core/models/company";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import OtpInput from "react-otp-input";
import Select from "react-select";

const CreateBarcodeWeb = () => {
  let dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const pin: number = useAppSelector((state) => state.pin.pin);
  const [nominal, setNominal] = useState("");
  const [balanceList, setBalanceList] = useState<any[]>([]);
  const [selectedBalance, setSelectedBalance] = useState<any>({});
  const [NewValues, setNewValues] = React.useState("");
  const [qrString, setQrString] = useState("");
  const [errorNominal, setErrorNominal] = useState("");
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [isCountdown, setIsCountdown] = useState(false);
  const [timer, setTimer] = useState(0);
  let navigate = useNavigate();

  const createCpmQrCode = async (token: string) => {
    dispatch(setLoading(true));
    let data = {
      amount: nominal,
      balanceCode: selectedBalance.value
    };
    await API_BALANCE.createCpmQrCode(data, token)
      .then((res) => {
        dispatch(setLoading(false));
        setQrString(res.data.data.code);
        setTimer(Date.now() + 300000);
        setShowQR(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getBalanceList = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getComboAllowedBalanceCodes()
      .then((res) => {
        dispatch(setLoading(false));
        let tempBalanceList: any = []
        if(res.data.length > 0){
          res.data.map((el: any) => tempBalanceList.push({
            value: el.balanceCode,
            label: el.balanceName
          }))
          setBalanceList(tempBalanceList);
          console.log(tempBalanceList);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = async () => {
    setNewValues("");
    if (nominal === "") {
      toast.error("Nominal Harus Di isi");
    } else if (selectedBalance.value === undefined) {
      toast.error("Pilih saldo yang akan digunakan");
    } else {
      if (showSecurityCode) {
        dispatch(setLoading(true));
        let data = {
          securityCode: NewValues,
          transactionType: "QR_CODE",
        };
        await API_AUTH.reauthenticate(data)
          .then((res) => {
            dispatch(setLoading(false));
            let securityCodeToken = res.data.data.securityCodeToken;
            createCpmQrCode(securityCodeToken);
            setIsCountdown(true);
            setShowSecurityCode(false);
            setNewValues("");
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      } else {
        if (pin === 1) {
          setShowSecurityCode(true);
        } else {
          navigate(`/${company?.initial}/create-security-code`, {
            state: {
              url: "payment-method/create-barcode",
              type: "transfer",
            },
          });
        }
      }
    }
  };

  const renderer = (data: any) => {
    if (data.completed) {
      setIsCountdown(false);
      setNominal("");
      setQrString("");
      setShowSecurityCode(false);
      setNewValues("");
      setTimer(0);
      Swal.fire({
        title: "Barcode Expired",
        imageUrl: FailedIcon,
        imageAlt: "Custom image",
        imageWidth: 220,
        imageHeight: 220,
        confirmButtonColor: "#EC1A1A",
        confirmButtonText: "Kembali",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setShowQR(false);
        }
      });
      return (
        <span className="text-red font-semibold md:text-xl text-lg text-center">
          Waktu Habis
        </span>
      );
    } else {
      return (
        <span className="text-[#00D19F] font-semibold md:text-xl text-lg text-center">
          {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
        </span>
      );
    }
  };

  const checkStatusCpmQrCode = async (code: string) => {
    await API_BALANCE.checkStatusCpmQrCode(code)
      .then((res) => {
        if (res.data.data.status === "PAID") {
          setIsCountdown(false);
          setQrString("");
          setNominal("");
          Swal.fire({
            title: "Transaksi Berhasil",
            imageUrl: SuccessIcon,
            imageAlt: "Custom image",
            imageWidth: 220,
            imageHeight: 220,
            confirmButtonColor: "#00D19F",
            confirmButtonText: "Kembali",
          }).then((result) => {
            if (result.isConfirmed) {
              setShowQR(false);
              setNewValues("");
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (NewValues: any) => setNewValues(NewValues);

  useEffect(() => {
    if (qrString) {
      const loop = setInterval(() => {
        checkStatusCpmQrCode(qrString);
      }, 3000);
      return () => clearInterval(loop);
    }
  }, [qrString]);

  useEffect(() => {
    getBalanceList();
  }, [])

  return (
    <section className="h-full min-h-screen font-poppins bg-mobile pb-20">
      <div
        className={`${showSecurityCode ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]">
              <div className="flex flex-row justify-end w-100 mb-3 md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setShowSecurityCode(false);
                    setNewValues("");
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p className="md:text-2xl text-sm text-black md:mb-4">
                Masukkan Pin Anda untuk melanjutkan transaksi
              </p>
              {/* <StatefulPinInput
                length={6}
                placeholder=""
                initialValue={NewValues}
                onChange={(value, index, values) => {
                  setNewValues(values);
                }}
                type="number"
                mask={true}
                focusBorderColor="#26A69A"
                validBorderColor="#26A69A"
              /> */}
              <OtpInput
                value={NewValues}
                onChange={handleChange}
                numInputs={6}
                separator={<span style={{ width: "15px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border:
                    NewValues.length < 6
                      ? "1px solid #9A9999"
                      : "1px solid #26A69A",
                  borderRadius: "8px",
                  width: "54px",
                  height: "54px",
                  fontSize: "30px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                isInputSecure={true}
              />
              <button
                disabled={NewValues.length < 6}
                onClick={() => handleOnClick()}
                style={{
                  backgroundColor:
                    NewValues.length < 6
                      ? "#9A9999"
                      : company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A",
                }}
                className="w-full md:py-[16px] p-[10px] rounded-full text-white font-semibold text-base disabled:bg-slate-50 mt-4"
              >
                Konfirmasi
              </button>
            </div>
          </div>
        </div>
      </div>
      <Header></Header>
      <div className="container md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div
          className={`${
            showSecurityCode || showQR
              ? "hidden"
              : "lg:w-1/2 md:w-2/3 w-full mx-auto bg-white py-10 px-4"
          }`}
        >
          <img
            src={QRIcon}
            alt=""
            width={307}
            height={307}
            className="mx-auto"
          />
          <p className="md:w-3/5 w-full mx-auto text-center md:text-base text-sm text-black font-medium mt-[20px] mb-6">
            Masukan nominal sesuai dengan jumlah transaksi pembayaran anda
          </p>
          <div className="md:w-3/4 w-full mx-auto mb-4 select-balance-cpm">
            <label className="md:text-base text-sm text-black font-normal mb-2">
              Bayar Dengan
            </label>
            <Select
              options={balanceList}
              value={selectedBalance}
              onChange={(e) => {
                console.log(e)
                if(e !== null) setSelectedBalance(e);
              }}
              className="z-10"
            />
          </div>
          <div className="md:w-3/4 w-full mx-auto">
            <label className="md:text-base text-sm text-black font-normal mb-2">
              Nominal
            </label>
            <CurrencyInput
              className="w-full bg-white border-[#9A9999] rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-[#9A9999]"
              defaultValue=""
              value={nominal}
              prefix={"Rp"}
              onValueChange={(value, name) => {
                setNominal(value || "");
                setErrorNominal("");
              }}
              placeholder="Masukkan nominal pembayaran"
              onKeyPress={onlyNumber}
            />
            <p className="md:text-base text-sm text-red pl-1 mb-0">
              {errorNominal}
            </p>
          </div>
        </div>
        <div
          className={`${
            showQR
              ? "lg:w-1/2 md:w-3/5 w-full mx-auto bg-white shadow py-10 px-4 mb-6"
              : "hidden"
          }`}
        >
          <h1 className="text-center text-[20px] font-semibold">
            Transaksi QR
          </h1>
          <div className="mx-auto w-fit">
            <Barcode value={qrString} displayValue={false} />
          </div>
          <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center">
            <h6 className="md:text-base text-sm font-medium mt-[20px]">
              Nominal Pembayaran
            </h6>
            <p className="md:text-base text-sm font-semibold">
              {formatCurrency(nominal)}
            </p>
            <h6 className="md:text-base text-sm font-medium mt-8">
              Batas Waktu
            </h6>
            {isCountdown && (
              <Countdown key={timer} date={timer} renderer={renderer} />
            )}
          </div>
        </div>
        <div
          className={`${
            showQR ? "hidden" : "lg:w-1/2 md:w-2/3 w-full mx-auto mt-5 mb-6"
          }`}
        >
          <button
            type="button"
            onClick={() => handleOnClick()}
            style={{
              backgroundColor:
                company?.color?.color1 !== undefined
                  ? company?.color?.color1
                  : "#26A69A",
            }}
            className="bg-[#26A69A] text-white font-bold rounded-xl w-full md:p-[1rem] p-[12px]"
          >
            Selanjutnya
          </button>
        </div>
      </div>
    </section>
  );
};

export default CreateBarcodeWeb;
