import React from "react";
import Header from "../../_common/headerComponent";
import DummyImage from "../../../assets/images/icon_queue/dummy_image.png";
import QueueComponent from "../../_common/queueComponent";

const ViewQueueMobile = () => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <Header navbarText="Lihat Antrian" />
      <div className="md:w-1/2 w-full mx-auto md:mt-12 mt-[0px] pb-10">
        <img src={DummyImage} alt="" />
        <div className="flex items-center gap-3 mt-5 justify-center">
          <div className="bg-customBlack rounded-[50%] flex w-[36px] h-[36px]">
            {/* <img src={BaberkingLogo} alt="" /> */}
          </div>
          <h6 className="md:text-lg text-base font-semibold text-customBlack mb-0">
            Barberking Kartasura
          </h6>
        </div>
        <div className="text-center mt-10">
          <h6 className="md:text-base text-sm text-newColor mb-0">Antrian</h6>
          <h5 className="md:text-2xl text-xl font-semibold text-customBlack mb-10">
            Layanan 1
          </h5>
          <QueueComponent current="A-10" remainder="13" />
          <p className="mt-10 md:text-lg text-base text-newColor w-1/2 mx-auto">
            Nomor Antrian dapat berubah sewaktu waktu
          </p>
        </div>
      </div>
    </section>
  );
};

export default ViewQueueMobile;
