import React from "react";
import Header from "../../_common/headerComponent";
import ArrowRight from "../../../assets/images/icon_queue/arrow_right.svg";
import Calendar from "../../../assets/images/icon_queue/calendar.svg";
import { useNavigate } from "react-router-dom";

const SelectQueueWeb = () => {
  const navigate = useNavigate();
  // const [keyword, setKeyword] = React.useState("");

  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <Header />
      <div className="md:w-1/2 w-full mx-auto md:mt-12 mt-[0px] px-4 pb-28">
        <h5 className="md:text-xl text-lg text-customBlack font-medium">
          Pilih Antrian
        </h5>
        <div>
          <h6 className="md:text-base text-sm text-customBlack font-normal">
            Pilih Anggota
          </h6>
          <button
            type="button"
            // onClick={() => {
            //   setShowDate(true);
            //   setStartDate(convertToUTC(new Date().setHours(0o0, 0o0, 0o0)));
            //   setEndDate(convertToUTC(new Date().setHours(23, 59, 59)));
            // }}
            className="w-full flex items-center justify-between gap-3 border-[1px] border-[#CCCCCC] bg-white text-customBlack rounded-md py-[10px] px-3"
          >
            <img src={Calendar} alt="" />
            <span className="md:text-base text-sm text-customBlack font-normal mb-0 w-full text-start">
              Anggota
            </span>
            <img src={ArrowRight} alt="" />
          </button>
        </div>
        <div className="mt-3">
          <h6 className="md:text-base text-sm text-customBlack font-normal">
            Pilih Tanggal
          </h6>
          <button
            type="button"
            // onClick={() => {
            //   setShowDate(true);
            //   setStartDate(convertToUTC(new Date().setHours(0o0, 0o0, 0o0)));
            //   setEndDate(convertToUTC(new Date().setHours(23, 59, 59)));
            // }}
            className="w-full flex items-center justify-between gap-3 border-[1px] border-[#CCCCCC] bg-white text-customBlack rounded-md py-[10px] px-3"
          >
            <img src={Calendar} alt="" />
            <span className="md:text-base text-sm text-customBlack font-normal mb-0 w-full text-start">
              Tanggal
            </span>
            <img src={ArrowRight} alt="" />
          </button>
        </div>
        <div className="mt-3">
          <h6 className="md:text-base text-sm text-customBlack font-normal">
            Pilih Jam
          </h6>
          <button
            type="button"
            // onClick={() => {
            //   setShowDate(true);
            //   setStartDate(convertToUTC(new Date().setHours(0o0, 0o0, 0o0)));
            //   setEndDate(convertToUTC(new Date().setHours(23, 59, 59)));
            // }}
            className="w-full flex items-center justify-between gap-3 border-[1px] border-[#CCCCCC] bg-white text-customBlack rounded-md py-[10px] px-3"
          >
            <img src={Calendar} alt="" />
            <div className="w-full flex flex-col items-center justify-start">
              <p className="md:text-base text-sm text-customBlack font-normal mb-0">
                08.00-10.00
              </p>
              <div className="flex items-center">
                <p className="md:text-base text-sm text-newColor font-normal mb-0">
                  Kuota : 80
                </p>
                <p className="md:text-base text-sm text-newColor font-normal mb-0">
                  Sisa Kuota : 10
                </p>
              </div>
            </div>
            <img src={ArrowRight} alt="" />
          </button>
        </div>
        <div className="mt-3">
          <h3 className="md:text-xl text-lg font-medium text-customBlack">
            Formulir
          </h3>
          <div>
            <h6 className="md:text-base text-sm text-customBlack font-normal">
              Layanan
            </h6>
            <input
              type="text"
              readOnly
              defaultValue="Cabut Gigi"
              className="bg-[#F5F5F5] rounded-md px-3 py-2 text-newColor md:text-base text-sm w-full"
            />
          </div>
          <div className="mt-3">
            <h6 className="md:text-base text-sm text-customBlack font-normal">
              Nama
            </h6>
            <input
              type="text"
              // onChange={}
              value="Nama Kamu"
              className="bg-white border-[1px] border-[#CCCCCC] rounded-md px-3 py-2 text-newColor md:text-base text-sm w-full"
            />
          </div>
        </div>
      </div>
      <div
        className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 px-4"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <button
          type="button"
          onClick={() => navigate("queue-number")}
          className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
        >
          Ambil Antrian
        </button>
      </div>
    </section>
  );
};

export default SelectQueueWeb;
