import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPrabayarWeb from "./ppobPrabayarWeb";
import PPOBPrabayarMobile from "./ppobPrabayarMobile";

const PPOBPulsaDataPrabayar = () => {
  return (
    <>
      <BrowserView>
        <PPOBPrabayarWeb></PPOBPrabayarWeb>
      </BrowserView>
      <MobileView>
        <PPOBPrabayarMobile></PPOBPrabayarMobile>
      </MobileView>
    </>
  );
};

export default PPOBPulsaDataPrabayar;
