import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  showDestinationOtp: boolean;
  addDestination: boolean;
  chooseDestination: boolean;
  data: any;
  username: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  destinationType: string;
};

const initialState: InitialState = {
  showDestinationOtp: false,
  addDestination: false,
  chooseDestination: true,
  isEmailVerified: false,
  isPhoneVerified: false,
  data: {},
  username: "",
  destinationType: "",
};

export const destinationSlice = createSlice({
  name: "destinationOtp",
  initialState: initialState,
  reducers: {
    setAddDestination: (state, action: PayloadAction<any>) => {
      state.addDestination = action.payload;
    },
    setShowDestinationOtp: (state, action: PayloadAction<any>) => {
      state.showDestinationOtp = action.payload;
    },
    setChooseDestination: (state, action: PayloadAction<any>) => {
      state.chooseDestination = action.payload;
    },
    setDataDestination: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setUsernameDestination: (state, action: PayloadAction<any>) => {
      state.username = action.payload;
    },
    setIsEmailVerifiedDestination: (state, action: PayloadAction<any>) => {
      state.isEmailVerified = action.payload;
    },
    setIsPhoneVerifiedDestination: (state, action: PayloadAction<any>) => {
      state.isPhoneVerified = action.payload;
    },
    setDestinationType: (state, action: PayloadAction<any>) => {
      state.destinationType = action.payload;
    },
    resetDestinationOtp: (state) => {
      state.showDestinationOtp = false;
      state.addDestination = false;
      state.chooseDestination = true;
      state.data = {};
    },
    removeUsernameDestination: (state) => {
      state.username = "";
      state.isEmailVerified = false;
      state.isPhoneVerified = false;
      state.destinationType = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAddDestination,
  setChooseDestination,
  setShowDestinationOtp,
  resetDestinationOtp,
  setDataDestination,
  setUsernameDestination,
  removeUsernameDestination,
  setDestinationType,
  setIsEmailVerifiedDestination,
  setIsPhoneVerifiedDestination,
} = destinationSlice.actions;

export default destinationSlice.reducer;
