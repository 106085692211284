/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Header from '../../_common/headerComponent';
import DummyImage from '../../../assets/images/icon_queue/dummy_image.png';
import { useLocation, useNavigate } from 'react-router-dom';

const BranchMobile = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const [isSetLocation, setIsSetLocation] = React.useState(false);

  const data = [
    {
      img: DummyImage,
      title: "Rs Mutiara Salju Cabang 1",
      time: "08.00 - 12.00 WIB",
      location: "Flores , Nusa Tenggara"
    },
    {
      img: DummyImage,
      title: "Rs Mutiara Salju Cabang 2",
      time: "08.00 - 12.00 WIB",
      location: "Flores , Nusa Tenggara"
    },
    {
      img: DummyImage,
      title: "Rs Mutiara Salju Cabang 3",
      time: "08.00 - 12.00 WIB",
      location: "Flores , Nusa Tenggara"
    },
    {
      img: DummyImage,
      title: "Rs Mutiara Salju Cabang 4",
      time: "08.00 - 12.00 WIB",
      location: "Flores , Nusa Tenggara"
    },
  ]

  React.useEffect(() => {
    if(location.state !== null && location.state === "set-location"){
      setIsSetLocation(true);
    }
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <Header navbarText="Lokasi" />
      <div className="md:w-1/2 w-full mx-auto md:mt-12 mt-[0px] px-4 pb-28">
        {data.map((item, index) => (
          <div className="rounded-[10px] shadow-sm mb-7 overflow-hidden" key={index}>
            <img src={item.img} alt="" />
            <div className="p-3">
              <h6 className="md:text-lg text-base font-medium text-customBlack mb-1">{item.title}</h6>
              <h6 className="md:text-xs text-[10px] text-newColor mb-1">{item.location}</h6>
              <div className="flex items-end justify-between">
                <h6 className="md:text-sm text-xs mb-0 text-customBlack">Jam Operasional : {item.time}</h6>
                <button
                  type="button"
                  onClick={() => navigate("location", {state: isSetLocation})}
                  className="bg-green rounded-md md:text-xs text-[10px] font-medium text-white py-2 px-3"
                >
                  Lihat Cabang
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default BranchMobile