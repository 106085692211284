import React from 'react';
import { useNavigate } from 'react-router-dom';
import Failed from "../../../assets/images/security-fail.png";
import { useAppSelector } from '../../../core/feature/hooks';
import Company from '../../../core/models/company';
import Header from '../../_common/headerComponent';

const FailedConnectAccountWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />
      <div className="container md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto mb-10">
          <div className="mb-7 py-2">
            <img src={Failed} alt="" height={148} width={153} className="mx-auto" />
          </div>
          <p className="md:text-lg text-base text-customBlack font-medium text-center mb-0">
            Akun Gagal Dihubungkan
          </p>
          <div className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]" style={{transform: "translate(-50%, -50%)"}}>
            <button
              type="button"
              onClick={() => navigate(`/${company?.initial}/balance`, {replace: true})}
              style={{ backgroundColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              Kembali
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FailedConnectAccountWeb