import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import ProfileIcon from "../../assets/images/Iconly/Bold/Profile.svg";
import { donationCategories } from "../../config/Combo";
import {
  camelCase,
  formatCurrency,
  formatDate,
  imageOnError,
} from "../../config/global.js";
import { getZakatProgress } from "../../core/service/api_zakat";
import Header from "../_common/headerComponent";
import DonationTransactionSkeleton from "../_common/skeleton/DonationtransactionSkeleton";
import DataNotFound from "../_common/dataNotFound";
import CustomTooltip from "../_common/tooltip";
import { useTranslation } from "react-i18next";

const ZakatTransactionMobile = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setsize] = useState(10);
  const [page, setpage] = useState(1);
  const [sortBy, setsortBy] = useState("createdTime");
  const [dir, setdir] = useState(-1);
  const [name] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectCategory, setSelectCategory] = useState("");
  var categories = [...donationCategories];

  const changeDate = (item: any) => {
    setSelectedDate([item?.selection]);
    setStartDate(moment(item?.selection.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(item?.selection.endDate).format("YYYY-MM-DD"));
  };
  const resetFilter = () => {
    setdir(-1);
    setSelectCategory("");
    setStartDate("");
    setEndDate("");

    setSelectedDate([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const [data, setdata] = useState<any>([]);
  const [toggleSetting, setToggleSetting] = useState(false);
  const [totalElement, setTotalElement] = useState(0);
  const params = useParams();

  const [openTab, setOpenTab] = useState(1);

  const getDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setdata([]);
    setIsLoading(true);
    getZakatProgress(params.id, query)
      .then((res) => {
        setIsLoading(false);
        setdata(res.data.data.data);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.total);
        // setTotalPage(res.data.data.totalPage);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getMoreDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setIsLoading(true);
    getZakatProgress(params.id, query)
      .then((res) => {
        setIsLoading(false);
        setdata([...data, ...res.data.data.data]);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.totalElement);
        // setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, dir]);
  return (
    <>
      <section className="h-full min-h-screen font-poppins bg-mobile">
        <Header navbarText={t('zakat.donors')}></Header>
        <div className="container h-full m-auto px-4 font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100">
              <ul className="flex gap-3 w-full p-1 bg-gradient-to-r from-[#1aa6ecdd] to-[#1990CADD] rounded-lg">
                <li className="flex w-full">
                  <button
                    onClick={() => {
                      setOpenTab(1);
                      setpage(1);
                      setdir(-1);
                      setsortBy("createdTime");
                    }}
                    className={` ${openTab === 1
                      ? "bg-white text-[#429AC6]"
                      : "bg-transparent text-white"
                      } inline-block px-2 py-2 rounded-lg w-full`}
                  >
                    {t("zakat.recent")}
                  </button>
                </li>

                <li className="flex w-full">
                  <button
                    onClick={() => {
                      setOpenTab(2);
                      setpage(1);
                      setdir(1);
                      setsortBy("createdTime");
                    }}
                    className={` ${openTab === 2
                      ? "bg-white text-[#429AC6]"
                      : "bg-transparent text-white"
                      } inline-block px-2 py-2 rounded-lg w-full`}
                  >
                    {t("zakat.longest")}
                  </button>
                </li>
                {/* <li className="flex w-full">
                  <button
                    onClick={() => {
                      setOpenTab(2);
                      setsortBy("amount");
                    }}
                    className={` ${
                      openTab === 2
                        ? "bg-white text-[#429AC6]"
                        : "bg-transparent text-white"
                    } inline-block px-2 py-2 rounded-lg w-full`}
                  >
                    Terbesar
                  </button>
                </li> */}
              </ul>
              <div className="flex flex-col gap-2 my-4">
                {data.filter((el: any) => el.status === "PAID").length === 0 &&
                  !isLoading && <DataNotFound title={t("zakat.noData")} />}
                {_.map(data, (item) => (
                  <div
                    key={item._id}
                    // onClick={() => {
                    //   navigate(
                    //     `/${company?.initial}/donations/donation/transaction/detail/${item._id}`
                    //   );
                    // }}
                    className="flex w-full max-h-[100px] bg-white rounded-md shadow-sm shadow-gray-700 gap-1 p-2"
                  >
                    <div className="w-32 flex justify-center items-center">
                      <div className="flex justify-center items-center rounded-full w-12 h-12 bg-[#D9D9D9]">
                        <img
                          className=" bg-contain w-5 h-5"
                          onError={imageOnError}
                          src={ProfileIcon}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="w-[calc(100%-8rem)] flex flex-col flex-grow  justify-between">
                      <CustomTooltip
                        // className={"grow max-w-[calc(100%-7rem)] "}
                        content={
                          <div className="max-w-full whitespace-pre-line">
                            {item?.nameOfUser}
                          </div>
                        }
                        direction="bottom"
                        children={
                          <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                            {item?.nameOfUser}
                          </div>
                        }
                      ></CustomTooltip>
                      {/* <div>{item?.nameOfUser}</div> */}
                      <div className="flex gap">
                        <span className="text-[10px] font-light">
                          {t("zakat.zakatAmount")}&nbsp;
                        </span>
                        <span className="text-xs font-semibold">
                          {formatCurrency(item?.amount)}
                        </span>
                      </div>
                      <div className="w-full flex">
                        <span className="text-[10px] font-light">
                          {t("zakat.message")}&nbsp;:&nbsp;
                        </span>
                        <span className="w-full text-xs font-semibold">
                          <CustomTooltip
                            className={"grow max-w-[calc(100%-3rem)] "}
                            content={
                              <div className="max-w-full whitespace-pre-line">
                                {item?.message || "-"}
                              </div>
                            }
                            direction="bottom"
                            children={
                              <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                                {item?.message || "-"}
                              </div>
                            }
                          ></CustomTooltip>
                          {/* {item?.message || "-"} */}
                        </span>
                      </div>
                      <div className="flex">
                        <span className="text-[10px] font-light">
                          {formatDate(
                            new Date(item?.createdTime),
                            "DD MMMM YYYY"
                          )}
                          {/* {moment(new Date(item?.createdTime)).fromNow()} */}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
                {isLoading ? (
                  <>
                    {_.map(new Array(size), (el) => (
                      <DonationTransactionSkeleton></DonationTransactionSkeleton>
                    ))}
                  </>
                ) : null}
                {totalElement > data.length ? (
                  <div
                    className={`bg-themePrimary rounded-md py-1 text-center text-sm text-white font-semibold ${isLoading ? "cursor-progress" : "cursor-pointer"
                      }`}
                    onClick={() =>
                      !isLoading && getMoreDatas(false, size, page + 1)
                    }
                  >
                    {isLoading ? t("zakat.waiting") : "Load More...."}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <BottomSheet
            onDismiss={() => setToggleSetting(false)}
            open={toggleSetting}
            className="h-full"
          >
            <div className="px-3 py-4 flex flex-col gap-4 ">
              <div className="font-semibold ">Filter</div>
              <DateRange
                displayMode="date"
                editableDateInputs={true}
                onChange={(item) => changeDate(item)}
                moveRangeOnFirstSelection={false}
                ranges={selectedDate}
                maxDate={new Date()}
              />
              <div className="flex flex-row flex-wrap gap-2">
                {_.map(categories, (category) => (
                  <div className="flex flex-col w-16  text-center items-center">
                    <div
                      onClick={() => {
                        setSelectCategory(category.value);
                      }}
                      className={`flex w-12 h-12 rounded-md border-[1px] border-solid ${selectCategory === category.value
                        ? "border-accentGreen bg-accentDarkGreen"
                        : "border-[#70717d80] bg-white"
                        }`}
                    >
                      <img src={category.icon} alt="" className="m-auto" />
                    </div>
                    <span className="text-[10px] text-[#252525] font-light">
                      {camelCase(category.value, "_")}
                    </span>
                  </div>
                ))}
              </div>{" "}
              <div className="flex flex-col gap-2 mt-4">
                <button
                  onClick={() => {
                    setToggleSetting(false);
                    getDatas(true);
                  }}
                  type="button"
                  className="bg-themePrimary text-white w-full h-10 rounded-md"
                >
                  {t("zakat.apply")}
                </button>
                <button
                  type="button"
                  className="border-1 border-themeMuted bg-white text-themeMuted w-full h-10 rounded-md"
                  onClick={() => resetFilter()}
                >
                  {t("zakat.reset")}
                </button>
              </div>
            </div>
          </BottomSheet>
        </div>
      </section>
    </>
  );
};

export default ZakatTransactionMobile;
