import React from "react";
import Header from "../_common/headerComponent";
import kirim_ke_bank from "../../assets/images/icon_transfer/kirim_ke_bank_icon.svg";
import kirim_ke_member from "../../assets/images/icon_transfer/kirim_ke_member_icon.svg";
// import arrow_left from "../../assets/images/icon_transfer/arrow_left_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import BottomLogo from "../_common/bottomLogo";
import { BalanceCard } from "../_common/BalanceCard";
// import pattern from "../../assets/images/icon_balance/pattern.svg";
import arrow from "../../assets/images/arrow-left-ic.svg";
import { camelCase, formatCurrency } from "../../config/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import Location from "../../assets/images/icon_tripAndTour/location.svg";
import Calendar from "../../assets/images/icon_tripAndTour/Calendar.svg";
import moment from "moment";
import JsonData from "../../config/JsonData";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import NoImage from "../../assets/images/empty.jpg";

const Icon = (data: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={`${
        data.id === data.open ? "rotate-90" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        d="M5.93994 13.28L10.2866 8.9333C10.7999 8.41997 10.7999 7.57997 10.2866 7.06664L5.93994 2.71997"
        stroke="#646464"
        stroke-opacity="0.87"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const TripAndTourDetailMobile = (props: any) => {
  const navigate = useNavigate();
  const imageOnError = (event: any) => {
    event.currentTarget.src = NoImage;
    event.currentTarget.className = `${event.currentTarget.className} error`;
  };

  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <section
        id="header-app"
        className="flex flex-row items-center z-50 px-4 py-3 mb-4"
      >
        <div
          onClick={async () => {
            // await scanNow(false);
            navigate(-1);
          }}
          className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>

        <h1 className={`font-montserrat font-bold text-base m-auto relative`}>
          Wisata
        </h1>
      </section>
      <div className="w-full mt-4">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {props?.data?.imageUrl?.map((el: any) => {
            return (
              <SwiperSlide>
                <img
                  src={el}
                  onError={imageOnError}
                  alt=""
                  className="w-full bg-cover"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[20px] px-4 pb-[10rem]">
        <div className="md:w-3/5 w-full mx-auto">
          <div className="flex justify-between items-start">
            <span className="text-xl font-[600] block">
              {props?.data?.productName}
            </span>
            <span className="font-[700] text-xl text-[#408D86]">
              {formatCurrency(props?.data?.price)}
            </span>
          </div>
          <div className="mt-2">
            <div className="flex items-start gap-2">
              <img src={Location} alt="" />
              <span className="text-sm text-[#728F9E]">
                {props?.data?.locations?.join(", ")}
              </span>
            </div>
            <div className="mt-2">
              <span className="text-sm text-[#728F9E] mt-2">
                Sisa Kuota : {props?.data?.stock}
              </span>
            </div>
          </div>
          <Swiper
            grabCursor={true}
            slidesPerView={3}
            spaceBetween={8}
            className="border-b-[1px] border-[#728F9E] flex justify-between mt-4"
          >
            {props?.listCategory?.map((item: any, index: any) => {
              return (
                <SwiperSlide className="" key={index}>
                  <div
                    className={`${
                      props?.category === item
                        ? "border-b-[1px] border-[#408D86] font-[500] text-black"
                        : "text-[#728F9E] "
                    }" cursor-pointer text-sm p-2 "`}
                    onClick={() => props.setCategory(item)}
                  >
                    {item}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="mt-4">
            {props?.category === "Itinerari" && (
              <div>
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <span className="font-[500] text-[#263339]">
                      Tanggal Mulai
                    </span>
                    <div className="bg-[#1A9E94] bg-opacity-5 flex items-center justify-center mt-2">
                      <img src={Calendar} alt="" />
                      <span className="text-[#728F9E] p-2 text-sm">
                        {moment
                          .utc(props?.data?.startDate)
                          .local()
                          .format("DD MMMM YYYY")}
                      </span>
                    </div>
                    <div></div>
                  </div>
                  <div>
                    <span className="font-[500] text-[#263339]">
                      Tanggal Selesai
                    </span>
                    <div className="bg-[#1A9E94] bg-opacity-5 flex items-center justify-center mt-2">
                      <img src={Calendar} alt="" />
                      <span className="text-[#728F9E] p-2 text-sm">
                        {moment
                          .utc(props?.data?.endDate)
                          .local()
                          .format("DD MMMM YYYY")}
                      </span>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className="mt-4">
                  {props?.data?.itineraries?.map((el: any, index: any) => {
                    return (
                      <div key={index}>
                        <div
                          className="bg-[#1A9E94] bg-opacity-5 p-2 text-[#728F9E] flex justify-between mb-2 cursor-pointer"
                          onClick={() => props.handleOpen(el.day)}
                        >
                          <span>
                            Hari{" "}
                            {
                              JsonData?.dayConvert?.find(
                                (item: any) => item.value === el.day
                              )?.label
                            }
                          </span>
                          <Icon id={el.day} open={props.showDay} />
                        </div>
                        {props.showDay === el.day && (
                          <div>
                            {el?.activities?.map((item: any, index: any) => {
                              return (
                                <div className="p-2 flex justify-between border-b-[1px] border-[#728F9E]">
                                  <div className="flex items-center gap-2">
                                    <div className="bg-[#1A9E94] w-6 h-6 rounded-lg flex justify-center items-center">
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        className="text-white"
                                      />
                                    </div>
                                    <span className="text-[#263339]">
                                      {item?.startTime}
                                    </span>
                                  </div>
                                  <span className="text-gray-600">
                                    {item?.name}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div></div>
                </div>
              </div>
            )}
            {props?.category === "Fasilitas" && (
              <div className="mt-4">
                <span className="font-[600] text-[#263339] text-base">
                  Fasilitas
                </span>
                {props?.data?.facilities?.map((el: any, index: any) => {
                  return (
                    <div
                      className="p-2 flex gap-2 border-b-[1px] border-[#728F9E]"
                      key={index}
                    >
                      <div className="bg-[#1A9E94] w-6 h-6 rounded-lg flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-white"
                        />
                      </div>
                      <span className="text-gray-600">{el}</span>
                    </div>
                  );
                })}
              </div>
            )}
            {props?.category === "Deskripsi" && (
              <div className="mt-4">
                <span className="font-[600] text-[#263339] text-base">
                  Deskripsi
                </span>
                <p className="text-base text-justify text-[#263339]">
                  {props.data.desc}
                </p>
              </div>
            )}
          </div>
          <div className="px-4 pt-2 pb-4 shadow fixed-bottom bg-white">
            <span className="block font-[500]">Harga</span>
            <div className="flex justify-between items-end">
              <span className="text-lg text-[#26A69A] font-[600]">
                {formatCurrency(props.data.price * props.jml)}
              </span>
              <div className="flex items-center gap-2 mb-1">
                <FontAwesomeIcon
                  icon={faMinus}
                  onClick={props.min}
                  className="border-solid border-2 rounded-lg border-[#26A69A] w-[20px] h-[20px] text-[5px] text-[#26A69A]"
                />
                <span className="font-[500]">{props.jml}</span>
                <FontAwesomeIcon
                  icon={faPlus}
                  onClick={props.add}
                  className="border-solid border-2 rounded-lg border-[#26A69A] w-[20px] h-[20px] text-[5px] text-[#26A69A]"
                />
              </div>
            </div>
            <div>
              <button
                className="p-2 bg-[#26A69A] text-center rounded-lg w-full text-white font-[500] mt-2"
                type="button"
                onClick={props.order}
              >
                Selanjutnya
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TripAndTourDetailMobile;
