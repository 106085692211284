import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ForgetCardSecurityCodeWeb from "./forgetCardSecurityCodeWeb";
import ForgetCardSecurityCodeMobile from "./forgetCardSecurityCodeMobile";
import { useNavigate, useParams } from "react-router-dom";
import { zeroPad } from "react-countdown";
import { setLoading } from "../../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
import * as API_VIRTUAL_CARD from "../../../../core/service/api_virtualCard";
import Company from "../../../../core/models/company";
import { useTranslation } from "react-i18next";

const ForgetCardSecurityCode = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [otp, setOtp] = React.useState("");
  const [newSecurityCode, setNewSecurityCode] = React.useState("");
  const [confirmNewSecurityCode, setConfirmNewSecurityCode] = React.useState("");
  const [errorOtp, seterrorOtp] = React.useState("");
  const [errorNewSecurityCode, setErrorNewSecurityCode] = React.useState("");
  const [errorConfirmNewSecurityCode, setErrorConfirmNewSecurityCode] =
    React.useState("");
  const [createSuccessFully, setCreateSuccessFully] = React.useState(false);
  const [timer, setTimer] = React.useState(0);

  const requestOtp = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.requestOtp(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setTimer(Date.now() + 300000);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <div className="w-full mx-auto text-center">
          <button
            className="text-red text-base font-medium text-center md:mb-8 mb-6  font-montserrat"
            type="button"
            onClick={() => requestOtp()}
          >
            {t('card_security_code.send_again')}
          </button>
        </div>
      );
    } else {
      return (
        <p className="text-base text-customBlack font-medium text-center md:mb-8 mb-6 font-montserrat">
          {t('card_security_code.otp_code_burnt')}{" "}
          <span className="text-red">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </p>
      );
    }
  };

  const handleOnClick = async () => {
    if (createSuccessFully) {
      navigate(`/${company?.initial}/virtual-card/list`, { replace: true })
    } else {
      if (otp === "" || otp.length < 6) {
        seterrorOtp(t('card_security_code.required_otp'));
      } else if (newSecurityCode === "" || newSecurityCode.length < 6) {
        setErrorNewSecurityCode(t('card_security_code.insert_pin_new'));
      } else if (
        confirmNewSecurityCode === "" ||
        confirmNewSecurityCode.length < 6
      ) {
        setErrorConfirmNewSecurityCode(t('card_security_code.insert_pin_confirm'));
      } else if (newSecurityCode !== confirmNewSecurityCode) {
        setErrorConfirmNewSecurityCode(t('card_security_code.new_pin_same'));
      } else {
        dispatch(setLoading(true));
        let data = {
          newSecurityCode,
          confirmSecurityCode: confirmNewSecurityCode,
          otp,
        };
        API_VIRTUAL_CARD.resetCardSecurityCode(params.id, data)
          .then((res) => {
            dispatch(setLoading(false));
            setCreateSuccessFully(true);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    }
  };

  React.useEffect(() => {
    setTimer(Date.now() + 300000);
  }, []);

  return (
    <>
      <BrowserView>
        <ForgetCardSecurityCodeWeb
          renderer={(data: any) => renderer(data)}
          handleOnClick={() => handleOnClick()}
          setOtp={(value: any) => setOtp(value)}
          seterrorOtp={(value: any) => seterrorOtp(value)}
          setNewSecurityCode={(value: any) => setNewSecurityCode(value)}
          setErrorNewSecurityCode={(value: any) => setErrorNewSecurityCode(value)}
          setConfirmNewSecurityCode={(value: any) => setConfirmNewSecurityCode(value)}
          setErrorConfirmNewSecurityCode={(value: any) => setErrorConfirmNewSecurityCode(value)}
          company={company}
          otp={otp}
          newSecurityCode={newSecurityCode}
          confirmNewSecurityCode={confirmNewSecurityCode}
          errorOtp={errorOtp}
          errorNewSecurityCode={errorNewSecurityCode}
          errorConfirmNewSecurityCode={errorConfirmNewSecurityCode}
          createSuccessFully={createSuccessFully}
          timer={timer}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <ForgetCardSecurityCodeMobile
          renderer={(data: any) => renderer(data)}
          handleOnClick={() => handleOnClick()}
          setOtp={(value: any) => setOtp(value)}
          seterrorOtp={(value: any) => seterrorOtp(value)}
          setNewSecurityCode={(value: any) => setNewSecurityCode(value)}
          setErrorNewSecurityCode={(value: any) => setErrorNewSecurityCode(value)}
          setConfirmNewSecurityCode={(value: any) => setConfirmNewSecurityCode(value)}
          setErrorConfirmNewSecurityCode={(value: any) => setErrorConfirmNewSecurityCode(value)}
          company={company}
          otp={otp}
          newSecurityCode={newSecurityCode}
          confirmNewSecurityCode={confirmNewSecurityCode}
          errorOtp={errorOtp}
          errorNewSecurityCode={errorNewSecurityCode}
          errorConfirmNewSecurityCode={errorConfirmNewSecurityCode}
          createSuccessFully={createSuccessFully}
          timer={timer}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default ForgetCardSecurityCode;
