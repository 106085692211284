/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SuccessIcon from "../../assets/images/security_success.png";
import { backTo, formatCurrency } from "../../config/global";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { setLoading } from "../../core/feature/config/configSlice";
import { useTranslation } from "react-i18next";

const PPOBInternetTVSuccessMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let location: any = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});

  const getTransactionDetail = async (id: any) => {
    dispatch(setLoading(true));
    API_DIGIPROD.getTransactionById(id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getTransactionDetail(location.state.id);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Internet TV"></Header>
      <div className="flex flex-col justify-center gap-6 lg:w-1/2 md:w-2/3 w-full md:mt-[64px] mt-[0px] mx-auto">
        <div className="bg-white w-full p-4 mb-40">
          <div className="flex flex-col items-center">
            <img
              src={SuccessIcon}
              alt=""
              className="md:h-[220px] h-[120px] md:w-[220px] w-[120px]"
            />
            <h1 className="md:text-2xl text-green font-semibold text-xl mt-3">
              {t('ppobHistory.success')}
            </h1>
          </div>
          <div className="space-between gap-3 mt-4">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.transaction_date')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {moment().format("ll")}
            </p>
          </div>
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.transaction_number')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {data?.transactionId}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.name')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {data?.customerName}
            </p>
          </div>
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.customer_id')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {data?.customerNumber}
            </p>
          </div>
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.service_type')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {data?.productName}
            </p>
          </div>
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.category')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {t('ppobHistory.internet_and_tv')}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem] pb-3">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.total')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {formatCurrency(data?.totalPrice)}
            </p>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 md:w-2/3 w-full absolute bottom-0 right-0 left-1/2 md:px-[0px] px-[1.5rem]" style={{ transform: "translate(-50%, -50%)" }}>
        <button
          type="button"
          style={{ background: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
          className="bg-green text-white font-medium text-base rounded-[10px] w-full md:py-[13px] py-[10px] mt-3"
          onClick={() => {
            backTo(`/${company?.initial}`);
          }}
        >
          {t('ppobHistory.backToHome')}
        </button>
      </div>
    </section>
  );
};

export default PPOBInternetTVSuccessMobile;
