/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import MarketplaceMobile from "./marketplaceMobile";
import MarketplaceWeb from "./marketplaceWeb";
import { removeState } from "../../core/feature/browser-storage/browser-storage";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setMarketplaceConfig } from "../../core/feature/marketplace/marketplaceNameSlice";
import Company from "../../core/models/company";
import { MarketplaceConfig } from "../../core/models/user";
import * as API_COMMERCE from "../../core/service/api_commerce";
import { TimeZone } from "../../config/global";

const Marketplace = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const marketplaceConfig: MarketplaceConfig = useAppSelector(
    (state) => state.marketplaceConfig.marketplaceConfig
  );
  const [categories, setCategories] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [stores, setStores] = useState<any[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [configColor, setConfigColor] = useState<any>({});
  const [isSearch, setIsSearch] = React.useState(false);
  const [dataIcon, setDataIcon] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const mName: string = marketplaceConfig?.merchantName || "";
  const time = TimeZone();

  const getComboCategory = async (
    categoryName: string = "",
    level: number = 1,
    parentCategoryId: string = ""
  ) => {
    dispatch(setLoading(true));
    let query = {
      categoryName,
      level,
      parentCategoryId,
    };
    await API_COMMERCE.getComboCategory(query)
      .then((res) => {
        dispatch(setLoading(false));
        setCategories(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getMarketplaceName = async () => {
    dispatch(setLoading(true));
    API_COMMERCE.getConfigMarketplaceName().then((res: any) => {
      var conf = {
        merchantName: res.data.data.merchantName,
        productName: res.data.data.productName,
      };
      dispatch(setLoading(false));
      dispatch(setMarketplaceConfig(conf));
    });
  };

  const getAllProducts = async () => {
    let query = {
      timezone: time,
      page: 1,
      size: 10,
      sortBy: "-createdDate",
    };
    dispatch(setLoading(true));
    await API_COMMERCE.getAllProducts(query)
      .then((res) => {
        dispatch(setLoading(false));
        setProducts(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProductByName = async (productName: string = "") => {
    let query = {
      timezone: time,
      page: 1,
      size: 10,
      sortBy: "-createdDate",
      productName,
    };
    dispatch(setLoading(true));
    await API_COMMERCE.getAllProducts(query)
      .then((res) => {
        dispatch(setLoading(false));
        setFilteredProducts(res.data.data);
        setIsSearch(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getAllShop = async () => {
    dispatch(setLoading(true));
    let query = {
      timezone: time,
      page: 1,
      size: 10,
      sortBy: "-createdDate",
    };
    await API_COMMERCE.getAllShop(query)
      .then((res) => {
        dispatch(setLoading(false));
        setStores(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#015f48";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const getDataIcon = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.getComboIconCommerce()
      .then((res) => {
        dispatch(setLoading(false));
        setDataIcon(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getComboCategory();
    getAllProducts();
    getAllShop();
    getMarketplaceName();
    removeState("category");
    removeState("category_names");
    getConfigColor();
    getDataIcon();
  }, []);
  
  return (
    <>
      <BrowserView>
        <MarketplaceWeb
          getProductByName={(productName: string) => getProductByName(productName)}
          categories={categories}
          products={products}
          stores={stores}
          filteredProducts={filteredProducts}
          configColor={configColor}
          isSearch={isSearch}
          dataIcon={dataIcon}
          company={company}
          mName={mName}
          setFilteredProducts={(value: any) => setFilteredProducts(value)}
          setIsSearch={(value: any) => setIsSearch(value)}
        />
      </BrowserView>
      <MobileView>
        <MarketplaceMobile
          getProductByName={(productName: string) => getProductByName(productName)}
          categories={categories}
          products={products}
          stores={stores}
          filteredProducts={filteredProducts}
          configColor={configColor}
          isSearch={isSearch}
          dataIcon={dataIcon}
          company={company}
          mName={mName}
          setFilteredProducts={(value: any) => setFilteredProducts(value)}
          setIsSearch={(value: any) => setIsSearch(value)}
        />
      </MobileView>
    </>
  );
};

export default Marketplace;
