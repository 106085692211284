/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import * as API_BALANCE from "../../../core/service/api_balance";
import ReactDatePicker from "react-datepicker";
import Header from "../../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { onlyNumber } from "../../../config/global";
import { useLocation, useNavigate } from "react-router-dom";
import Company from "../../../core/models/company";
import { toast } from "react-hot-toast";

const BankDKIRegistrationWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let location: any = useLocation();
  const [data, setData] = useState<any>({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleOnClick = async () => {
    if (phoneNumber === "") {
      setErrorText("phoneNumber");
    } else if (firstName === "") {
      setErrorText("firstName");
    } else if (lastName === "") {
      setErrorText("lastName");
    } else if (placeOfBirth === "") {
      setErrorText("placeOfBirth");
    } else if (dateOfBirth === "") {
      setErrorText("dateOfBirth");
    } else if (password === "") {
      setErrorText("password");
    } else if (password.length < 6) {
      toast.error("Password/ PIN Harus 6 Digit");
    } else if (email === "") {
      setErrorText("email");
    } else {
      dispatch(setLoading(true));
      let dataOrder = {
        phoneNumber,
        firstName,
        lastName,
        placeOfBirth,
        dateOfBirth,
        password,
        email,
      };
      await API_BALANCE.registrationBankDKI(dataOrder)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`/${company?.initial}/balance/connect-account/input-otp`, {
            state: {
              data,
              dataOrder,
              isOtpForRegistration: true,
            },
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const changeDate = (date: Date) => {
    setStartDate(date);
    setDateOfBirth(date.toISOString().split("T")[0]);
    setErrorText("");
  };

  React.useEffect(() => {
    setData(location.state);
  }, []);

  return (
    <section className="h-full min-h-screen relative font-montserrat bg-mobile">
      <Header />
      <div className="flex flex-col justify-center gap-4 md:mt-[64px] mt-[0px] md:pb-[64px] pb-[1.5rem] px-4">
        <div className="lg:w-1/2 md:w-3/4 w-full mx-auto mb-10">
          <form className="w-full bg-white shadow-sm p-7 mb-5">
            <div className="mb-3">
              <label className="md:text-base text-sm text-customBlack font-normal mb-[8px]">
                Nomor Telepon
              </label>
              <input
                type="number"
                className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-md focus:outline outline-[#9A9999]"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setErrorText("");
                }}
                onKeyPress={onlyNumber}
              />
              {errorText === "phoneNumber" && (
                <p className="text-base text-red pl-1 mb-0">
                  Masukkan nomor telepon
                </p>
              )}
            </div>
            <div className="mb-3">
              <label className="md:text-base text-sm text-customBlack font-normal mb-[8px]">
                Nama Depan
              </label>
              <input
                type="text"
                className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-md focus:outline outline-[#9A9999]"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setErrorText("");
                }}
              />
              {errorText === "firstName" && (
                <p className="text-base text-red pl-1 mb-0">
                  Masukkan nama depan
                </p>
              )}
            </div>

            <div className="mb-3">
              <label className="md:text-base text-sm text-customBlack font-normal mb-[8px]">
                Nama Belakang
              </label>
              <input
                type="text"
                className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-md focus:outline outline-[#9A9999]"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setErrorText("");
                }}
              />
              {errorText === "lastName" && (
                <p className="text-base text-red pl-1 mb-0">
                  Masukkan nama belakang
                </p>
              )}
            </div>

            <div className="mb-3">
              <label className="md:text-base text-sm text-customBlack font-normal mb-[8px]">
                Tempat Lahir
              </label>
              <input
                type="text"
                className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-md focus:outline outline-[#9A9999]"
                value={placeOfBirth}
                onChange={(e) => {
                  setPlaceOfBirth(e.target.value);
                  setErrorText("");
                }}
              />
              {errorText === "placeOfBirth" && (
                <p className="text-base text-red pl-1 mb-0">
                  Masukkan tempat lahir
                </p>
              )}
            </div>

            <div className="mb-3 custom-date-picker-new">
              <label className="md:text-base text-sm text-customBlack font-normal mb-[8px]">
                Tanggal Lahir
              </label>
              <ReactDatePicker
                selected={startDate}
                dateFormat="dd MMMM yyyy"
                onChange={changeDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                maxDate={new Date()}
                dropdownMode="select"
              />
              {errorText === "dateOfBirth" && (
                <p className="text-base text-red pl-1 mb-0">
                  Masukkan tanggal lahir
                </p>
              )}
            </div>

            <div className="relative mb-3">
              <label className="md:text-base text-sm text-customBlack font-normal mb-[8px]">
                Password/PIN
              </label>
              <input
                type={visiblePassword ? "number" : "password"}
                className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-md focus:outline outline-[#9A9999]"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorText("");
                }}
                maxLength={6}
                onKeyPress={onlyNumber}
              />
              <div
                className="absolute md:top-[46px] top-[42px] right-[12px] cursor-pointer"
                onClick={() => setVisiblePassword(!visiblePassword)}
              >
                {visiblePassword ? (
                  <FontAwesomeIcon icon={faEye} size="lg" color="#C4C4C4" />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    size="lg"
                    color="#C4C4C4"
                  />
                )}
              </div>
              {errorText === "password" && (
                <p className="text-base text-red pl-1 mb-0">
                  Masukkan password/PIN
                </p>
              )}
            </div>

            <div className="mb-3">
              <label className="md:text-base text-sm text-customBlack font-normal mb-[8px]">
                Email
              </label>
              <input
                type="text"
                className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-md focus:outline outline-[#9A9999]"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrorText("");
                }}
              />
              {errorText === "email" && (
                <p className="text-base text-red pl-1 mb-0">Masukkan email</p>
              )}
            </div>
          </form>
          <div className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]" style={{transform: "translate(-50%, -50%)"}}>
            <button
              type="button"
              onClick={handleOnClick}
              style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}`}}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              Selanjutnya
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BankDKIRegistrationWeb;
