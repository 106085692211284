/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import _ from "lodash";
import ProfileWeb from "./profileWeb";
import ProfileMobile from "./profileMobile";
import userDefault from "../../assets/images/userDefault.png";
import { removeState } from "../../core/feature/browser-storage/browser-storage";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { removeToken, setToken } from "../../core/feature/token/tokenSlice";
import { removeBalance } from "../../core/feature/user/balanceSlice";
import { removePin } from "../../core/feature/user/pinSlice";
import { removeUser, setUser } from "../../core/feature/user/userSlice";
import { removeFireBaseToken } from "../../core/feature/firebaseToken/firebaseTokenSlice";
import {
  removeUserLoginByUser,
  setUserLogin,
} from "../../core/feature/userLogin/userLoginSlice";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import User from "../../core/models/user";
import UserLogin from "../../core/models/userLogin";
import * as API_AUTH from "../../core/service/api_auth";
import * as API_USER from "../../core/service/api_user";
import { useNavigate } from "react-router-dom";
import { findIndex } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const fireBaseToken: string = useAppSelector(
    (state) => state.fireBaseToken.fireBaseToken
  );
  const user: User = useAppSelector((state) => state.user.user);
  const userLogin: UserLogin[] = useAppSelector((state) => state.userLogin.userLogin);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [profileData, setProfileData] = React.useState<any>({});
  const [toggleChangeAccount, setToggleChangeAccount] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [nameMenu, setNameMenu] = React.useState<any>([]);

  const getProfile = async () => {
    dispatch(setLoading(true));
    await API_USER.getProfile()
      .then((res) => {
        var newUserLogin = userLogin?.length > 0 ? [...userLogin] : [];
        let indexUserLogin = findIndex(newUserLogin, {
          id: res.data.data.noId,
        });

        if (indexUserLogin < 0) {
          // newUserLogin.push({
          //   token: res.data.data.authToken,
          // });
        } else {
          newUserLogin[indexUserLogin] = {
            ...newUserLogin[indexUserLogin],
            name: res.data.data.name,
            photo: res.data.data.profileImage,
            id: res.data.data.noId,
          };
        }

        dispatch(setUserLogin(newUserLogin));

        dispatch(setLoading(false));
        setProfileData(res.data.data);
        dispatch(setUser(res.data.data));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProfileImage = useMemo(() => {
    if (user?.profileImage === "") {
      return userDefault;
    }
    return user?.profileImage;
  }, [user]);

  const handleLogout = async () => {
    dispatch(setLoading(true));
    deleteDeviceFcm();
    var newUserLogin = _.reject(userLogin, { id: user.noId });
    var newToken = token?.length > 0 ? [...token] : [];
    let updatedNewToken: any = [];
    if (newUserLogin.length > 0) {
      updatedNewToken = _.map(newToken, (item) => {
        let itemTemp = { ...item };
        if (item.cid === newUserLogin[0].initial) {
          itemTemp.token = newUserLogin[0]?.token;
        }
        return itemTemp;
      });
      dispatch(removeUserLoginByUser(user));
      dispatch(setToken(updatedNewToken));
      setTimeout(() => {
        navigate(`/${company?.initial}`, { replace: true });
      }, 500);
      addDeviceFcm();
    } else {
      await API_AUTH.logout()
        .then((res) => {
          dispatch(removeUserLoginByUser(user));
          dispatch(setLoading(false));
          dispatch(removeToken());
          dispatch(removeFireBaseToken());
          dispatch(removeUser());
          dispatch(removeBalance());
          dispatch(removePin());
          removeState("notif_statistic");
          navigate(`/${company?.initial}`, { replace: true });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const deleteDeviceFcm = async () => {
    dispatch(setLoading(true));
    await API_USER.deleteDeviceFirebase()
      .then((res) => {
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const addDeviceFcm = async () => {
    // dispatch(setLoading(true));
    if (fireBaseToken) {
      await API_USER.addDeviceFirebase(fireBaseToken)
        .then((res) => {
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const getMenuName = () => {
    dispatch(setLoading(true));
    API_USER.profileMenuName(company?.id)
      .then((res) => {
        setNameMenu(res.data.data);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    getMenuName();
    getProfile();
    removeState("virtual_card");
    removeState("show_balance_card");
  }, []);

  return (
    <>
      <BrowserView>
        <ProfileWeb
          setShowModal={(value: any) => setShowModal(value)}
          setToggleChangeAccount={(value: any) => setToggleChangeAccount(value)}
          getProfileImage={getProfileImage}
          profileData={profileData}
          toggleChangeAccount={toggleChangeAccount}
          showModal={showModal}
          nameMenu={nameMenu}
        />
      </BrowserView>
      <MobileView>
        <ProfileMobile
          setShowModal={(value: any) => setShowModal(value)}
          setToggleChangeAccount={(value: any) => setToggleChangeAccount(value)}
          getProfileImage={getProfileImage}
          profileData={profileData}
          toggleChangeAccount={toggleChangeAccount}
          showModal={showModal}
          nameMenu={nameMenu}
        />
      </MobileView>

      {/* Modal */}
      <div
        className={`${showModal ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="text-end md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="text-center mb-3 mt-2">
                <h4 className="md:text-2xl text-base text-customBlack font-medium mb-0">
                  {t("profile.title_logout")}
                </h4>
                <p className="md:text-base text-sm text-customBlack mb-0">
                  {t("profile.decs_logout")}
                </p>
                <div className="flex items-center gap-3 mx-5 mt-4 mb-3">
                  <button
                    type="button"
                    className="w-full p-[10px] rounded-[8px] text-newColor font-medium text-base bg-white border-[1px] border-newColor"
                    onClick={() => setShowModal(false)}
                  >
                    {t("profile.cancel")}
                  </button>
                  <button
                    type="submit"
                    style={{
                      backgroundColor:
                        company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A",
                    }}
                    className="w-full p-[10px] rounded-[8px] text-white font-medium text-base"
                    onClick={() => handleLogout()}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
    </>
  );
};

export default Profile;
