import React, { FC } from "react";
import { formatCurrency } from "../../config/global";
import iconSuccess from "../../assets/images/security_success.png";
import downloadIcon from "../../assets/images/icon_transaksi/download.svg";
import toast from "react-hot-toast";
import ToastConfig from "../../config/Toast";
import { useTranslation } from "react-i18next";

interface SummaryCardProps {
  data: any;
  headerProperty: string;
  headerValue: any;
  isSuccess: boolean;
  downloadable?: boolean;
}

export const SummaryCard: FC<SummaryCardProps> = (props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="bg-white p-4">
      {!props.isSuccess && (
        <span className="text-customBlack font-semibold text-xl">{t('virtual_card.summary')}</span>
      )}
      {props.isSuccess && (
        <div className="flex flex-col items-center justify-center mb-4">
          <img src={iconSuccess} alt="" width={120} height={120} />
          <h5 className="text-green md:text-2xl text-xl font-medium mb-0 mt-2">
            {t('virtual_card.success')}
          </h5>
        </div>
      )}
      {props.downloadable && (
        <div className="flex flex-row items-center justify-end mb-3" onClick={() => toast("Coming Soon", ToastConfig.warning)}>
          <img src={downloadIcon} alt="" width={50} height={50} />
        </div>
      )}
      {props.data.map((item: any, index: number) => (
        <div className="space-between gap-3 mt-3 overflow-hidden" key={index}>
          <h6 className="md:text-base text-sm text-start text-customBlack rounded-[20px] font-semibold mb-0">
            {item.property}
          </h6>
          <h6 className="md:text-base text-sm text-end text-newColor rounded-[20px] font-normal capitalize mb-0">
            {!isNaN(+item.value) === true
              ? formatCurrency(item.value)
              : item.value}
          </h6>
        </div>
      ))}
      <div className="space-between gap-3 bg-[#087B5E] bg-opacity-10 text-[#087B5E] py-[14px] px-[8px] rounded-md mt-3">
        <h6 className="md:text-base text-sm text-start rounded-[20px] font-bold mb-0">
          {props.headerProperty}
        </h6>
        <h6 className="md:text-base text-sm text-end rounded-[20px] font-bold mb-0">
          {formatCurrency(props.headerValue)}
        </h6>
      </div>
    </div>
  );
};
