/* eslint-disable array-callback-return */
import React from "react";
import { toast } from "react-hot-toast";
import { BottomSheet } from "react-spring-bottom-sheet";
import arrow from "../../assets/images/arrow_right.svg";
import Password from "../../assets/images/icon/auto login.svg";
import Unlock from "../../assets/images/icon/change pass.svg";
import Users from "../../assets/images/icon/changeakun.svg";
import ShieldDone from "../../assets/images/icon/pin.svg";
import UserSupport from "../../assets/images/icon/support.svg";
import Work from "../../assets/images/icon/termofservice.svg";
import Voice from "../../assets/images/icon/ubah bahasa.svg";
import ToastConfig from "../../config/Toast";
import { ConfigColor, goToUrl } from "../../config/global";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import SwitchAccount from "./switchAccount";
import { useNavigate } from "react-router-dom";
import { StatsProfile } from "../_common/Stats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import edit_icon from "../../assets/images/Edit_Icon.svg";
import bgClosepay from "../../assets/images/bgClosepay.png";
import userDefault from "../../assets/images/userDefault.png";
import { useTranslation } from "react-i18next";

const ProfileWeb = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  const [showModalSwitch, setShowModalSwitch] = React.useState(false);
  const color = ConfigColor();
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng') || 'id';

  const getDisplayText = (res: any) => {
    if (language === 'id') {
      return res.display || t(`profile.${res.name}`);
    } else if (language === 'en') {
      return res.displayEn || t(`profile.${res.name}`);
    } else {
      return t(`profile.${res.name}`);
    }
  };

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <StatsProfile />

      {/* Modal switch */}
      <div
        className={`${showModalSwitch ? "block" : "hidden"} relative z-50`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="text-end md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setShowModalSwitch(false);
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="text-center mb-2">
                <SwitchAccount></SwitchAccount>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}

      <div className="container flex lg:flex-row flex-col gap-3 justify-center relative z-0 -mt-20 pb-5">
        <div
          className="rounded-xl lg:w-1/2 w-full md:mt-[1.5rem] mt-0 shadow"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div className="flex flex-col gap-3">
            <div className="bg-white px-4 py-3 rounded-xl items-center gap-1">
              <div>
                <h5 className="grow font-bold md:text-lg text-base mt-2 mb-3 m-0">
                  {t('profile.account_settings')}
                </h5>
              </div>
              <div
                className="flex flex-row items-center cursor-pointer gap-4 h-14"
                onClick={() =>
                  navigate(`/${company?.initial}/profile/switch-account`)
                }
              >
                <img className="grow-0 w-12 h-12" src={Users} alt="" />
                <h1 className="grow font-normal md:text-base text-sm m-0">
                  {t('profile.manage_account')}
                </h1>
                <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
              </div>
              <hr className="m-2" />
              <div
                className="flex flex-row items-center cursor-pointer gap-4 h-14"
                onClick={() => toast(t('profile.coming_soon'), ToastConfig.warning)}
              >
                <img className="grow-0 w-12 h-12" src={Password} alt="" />
                <h1 className="grow font-normal md:text-base text-sm m-0">
                  {t('profile.automatic_login')}
                </h1>
                <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
              </div>
              <hr className="m-2" />
              {props.nameMenu.map((res: any) => {
                if (res.name === "change_password") {
                  return (
                    <div>
                      <div
                        className="flex flex-row items-center cursor-pointer gap-4 h-14"
                        onClick={() =>
                          navigate(
                            `/${company?.initial}/profile/change-password`
                          )
                        }
                      >
                        <img className="grow-0 w-12 h-12" src={Unlock} alt="" />
                        <h1 className="grow font-normal md:text-base text-sm m-0">
                          {getDisplayText(res)}
                        </h1>
                        <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
                      </div>
                      <hr className="m-2" />
                    </div>
                  );
                }
                return <></>;
              })}
              {props.nameMenu.map((res: any) => {
                if (res.name === "change_security_code") {
                  return (
                    <div>
                      <div
                        className="flex flex-row items-center cursor-pointer gap-4 h-14"
                        onClick={() =>
                          navigate(`/${company?.initial}/profile/security-code`)
                        }
                      >
                        <img
                          className="grow-0 w-12 h-12"
                          src={ShieldDone}
                          alt=""
                        />
                        <h1 className="grow font-normal md:text-base text-sm m-0">
                          {getDisplayText(res)}
                        </h1>
                        <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
                      </div>
                      <hr className="m-2" />
                    </div>
                  );
                }
                return <></>;
              })}
              <div
                className="flex flex-row items-center cursor-pointer gap-4 h-14"
                onClick={() => navigate(`/${company?.initial}/profile/language`)}
              >
                <img className="grow-0 w-12 h-12" src={Voice} alt="" />
                <h1 className="grow font-normal md:text-base text-sm m-0">
                  {t('profile.change_language')}
                </h1>
                <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
              </div>
              <hr className="m-2" />
              <div
                className="flex flex-row items-center cursor-pointer gap-4 h-14"
                onClick={() => {
                  goToUrl(
                    "https://dev.solusinegeri.com/term-condition",
                    true,
                    true,
                    true
                  );
                }}
              >
                <img className="grow-0 w-12 h-12" src={Work} alt="" />
                <h1 className="grow font-normal md:text-base text-sm m-0">
                  {t('profile.terms_of_service')}
                </h1>
                <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
              </div>
              <hr className="m-2" />
              {props.nameMenu.map((res: any) => {
                if (res.name === "support") {
                  return (
                    <div>
                      <a
                        className="flex flex-row items-center cursor-pointer gap-4 h-14 no-underline"
                        href="https://wa.me/+6289526643223"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="grow-0 w-12 h-12"
                          src={UserSupport}
                          alt=""
                        />
                        <h1 className="grow font-normal md:text-base text-sm m-0 text-[#252525] ">
                          {getDisplayText(res)}
                        </h1>
                        <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
                      </a>
                      <hr className="m-2" />
                    </div>
                  );
                }
                return <></>;
              })}
              {props.nameMenu.map((res: any) => {
                if (res.name === "logout") {
                  return (
                    <div
                      className="flex flex-row items-center cursor-pointer gap-4 h-14"
                      onClick={() => props.setShowModal(true)}
                    >
                      {/* <img className="grow-0 w-12 h-12" src={log} alt="" /> */}
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                          stroke="#728F9E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16 17L21 12L16 7"
                          stroke="#728F9E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 12H9"
                          stroke="#728F9E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <h1 className="grow font-normal md:text-base text-sm m-0">
                        {getDisplayText(res)}
                      </h1>
                      <img className="grow-0 w-6 mr-2" src={arrow} alt="" />
                    </div>
                  );
                }
                return <></>;
              })}
            </div>
          </div>
        </div>
        <div
          className="relative bg-white h-100 shadow rounded-xl flex flex-col lg:w-1/2 w-full"
          style={{ backgroundColor: `${color?.color1}` }}
        >
          <div
            className="space-between rounded-lg md:p-8 p-[18px] bg-no-repeat"
            style={{
              backgroundImage: `url(${bgClosepay})`,
              backgroundColor: `${color?.color1}`,
            }}
          >
            <div className="items-center h-fit flex flex-row col-span-2 gap-1">
              <div className="items-center">
                <img
                  src={
                    props.profileData?.profileImage
                      ? props.profileData?.profileImage
                      : userDefault
                  }
                  alt=""
                  className="rounded-lg max-w-[7rem] max-h-[7rem] object-cover bg-gray-50"
                />
              </div>
              <div className="p-1 flex flex-col col-span-2">
                <p className="text-white md:text-2xl text-lg font-bold m-0 p-0 mb-1">
                  {props.profileData?.name}
                </p>
                <p className="text-white md:text-lg text-sm m-0 p-0">
                  {props.profileData?.noId}
                </p>
              </div>
            </div>
            {props.nameMenu.map((res: any) => {
              if (res.name === "update_profile") {
                return (
                  <div
                    className="items-center cursor-pointer h-fit flex flex-col gap-1 p-2"
                    onClick={() => {
                      navigate(`/${company.initial}/profile/edit`);
                    }}
                  >
                    <img src={edit_icon} className="w-12" alt="" />
                    <p className="text-white md:text-lg text-sm font-normal m-0">
                      {getDisplayText(res)}
                    </p>
                  </div>
                );
              }
              return <></>;
            })}
          </div>
          <div className="py-1 px-3">
            <p className="md:text-lg text-sm font-poppins font-bold p-2 mt-2 m-0">
              {t('profile.profile_information')}
            </p>
            <hr className="m-2" />
            <div className="flex flex-col">
              <div className="space-between gap-3">
                <p className="md:text-lg text-sm font-poppins p-2 m-0">{t('profile.email')}</p>
                <p className="md:text-lg text-sm font-poppins font-bold p-2 m-0">
                  {props.profileData?.email}
                </p>
              </div>
              <div className="space-between gap-3">
                <p className="md:text-lg text-sm font-poppins p-2 m-0">{t('profile.phone')}</p>
                <p className="md:text-lg text-sm font-poppins font-bold p-2 m-0">
                  {props.profileData?.phone}
                </p>
              </div>
              <div className="space-between gap-3">
                <p className="md:text-lg text-sm font-poppins p-2 m-0">
                  {t('profile.address')}
                </p>
                <p className="md:text-lg text-sm font-poppins font-bold p-2 m-0">
                  {props.profileData?.address
                    ? props.profileData?.address.district == null
                      ? "-"
                      : props.profileData?.address.district +
                        ", " +
                        props.profileData?.address?.city ==
                        null
                        ? "-"
                        : props.profileData?.address.city +
                          ", " +
                          props.profileData?.address?.province ==
                          null
                          ? "-"
                          : props.profileData?.address.province
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomSheet
        onDismiss={() => {
          props.setToggleChangeAccount(false);
        }}
        open={props.toggleChangeAccount}
        className="h-full"
      >
        <SwitchAccount></SwitchAccount>
      </BottomSheet>
    </section>
  );
};

export default ProfileWeb;
