import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { toast } from "react-hot-toast";
import { StatefulPinInput } from "react-input-pin-code";
import { useNavigate } from "react-router-dom";
import icon_verify from "../../assets/images/verified.svg";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import {
  setIsEmailVerifiedDestination,
  setIsPhoneVerifiedDestination,
} from "../../core/feature/otp/destinationSlice";
import User from "../../core/models/user";
import * as API_AUTH from "../../core/service/api_auth";
import * as API_USER from "../../core/service/api_user.js";
import { setUser } from "../../core/feature/user/userSlice";
// import { setShowAddDestinationOtp } from "../../core/feature/otp/addDestinationSlice";
import {
  setShowAddDestinationOtp,
  setSkipTo,
} from "../../core/feature/otp/addDestinationSlice";
import ConfirmationModal from "./confirmationModal";
import Company from "../../core/models/company";
import fingerPrint from "@fingerprintjs/fingerprintjs";

interface AddDestinationOtpComponentProps {
  showAddDestination: boolean;
  onHide: any;
  data: any;
  username: string;
  skipTo: any;
}
const AddDestinationOtpComponent: FC<AddDestinationOtpComponentProps> = (
  props
) => {
  const user: User = useAppSelector((state) => state.user.user);
  //   const [showModal,setShowModal] =useState(false)
  const [emailFer, setEmailFer] = useState<any>(
    user?.email === "" ? "" : user?.email
  );
  const [phoneFer, setPhoneFer] = useState<any>(
    user?.phone === "" ? "" : user?.phone
  );
  const [selected, setSelected] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const [addEmailForm, setAddEmailForm] = useState(false);
  const [timer, setTimer] = useState(0);
  const [showDataVerification, setShowDataVerification] = useState(false);
  // const [showVerificationFrom, setShowVerificationForm] = useState(false);
  const [isAddData, setIsAddData] = useState(false);
  const [NewValues, setNewValues] = useState(["", "", ""]);
  const [data] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [showModalPhone, setShowModalPhone] = useState(false);
  const [modalVerificationWa, setModalVerificationWa] = useState(false);
  const [state, setState] = useState("");
  const [tokenWa, setTokenWa] = useState("");
  const company: Company = useAppSelector((state) => state.company.company);
  const [deviceId, setDeviceId] = useState("");
  const [show, setShow] = useState("block");

  const getDataProfile = async () => {
    await API_USER.getProfile()
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((err) => {});
  };

  const confirmEmail = async () => {
    let data = {
      email: emailFer,
      otpPrevEmail: NewValues.join(""),
      otpNewEmail: NewValues.join(""),
    };
    dispatch(setLoading(true));
    API_USER.verificationEmail(data)
      .then((res) => {
        setShowDataVerification(false);
        dispatch(setLoading(true));
        dispatch(setIsEmailVerifiedDestination(true));
        setSuccess(true);
        dispatch(setLoading(false));
        setShowModalEmail(false);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setShowModalEmail(false);
      });
  };

  const confirmPhone = async () => {
    let newData = {
      phone: phoneFer,
      otpPrevPhone: NewValues.join(""),
      otpNewPhone: NewValues.join(""),
    };
    dispatch(setLoading(true));
    API_USER.verificationPhone(newData)
      .then((res) => {
        setShowDataVerification(false);
        dispatch(setIsPhoneVerifiedDestination(true));
        setSuccess(true);
        dispatch(setLoading(false));
        setShowModalEmail(false);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setShowModalEmail(false);
      });
  };

  const hideModalEmail = () => setShowModalEmail(false);
  const hideModalPhone = () => setShowModalPhone(false);

  const requestOtp = async () => {
    if (selected === "EMAIL") {
      if (user?.verifiedEmail === true) {
        toast.success("Email Sudah Terverifikasi");
        //   login();
        // setShowVerificationForm(false);
      } else {
        changeEmail();
      }
    } else {
      if (user?.verifiedPhone === true) {
        toast.success("Nomer Telepon Sudah Terverifikasi");
      } else if (phoneFer === "" || phoneFer === null) {
        toast.error("Mohon Isi Nomor Telepon Terlebih Dahulu");
      } else {
        changePhone();
      }
    }
  };

  const callbackFunction = () => {};

  const changeEmail = async () => {
    if (emailFer === "" || emailFer === null) {
      toast.error("Email belum di isi");
    } else {
      if (user?.verifiedEmail === true) {
        //   callbackFunction();
        // setAddEmailForm(false);
      } else {
        let newData = {
          destinationOtp: emailFer,
          destinationType: "EMAIL",
          otpType: "EMAIL_VERIFICATION",
        };
        dispatch(setLoading(true));
        await API_AUTH.requestDestinationOtp(newData)
          .then((res) => {
            setShowDataVerification(true);
            setTimer(Date.now() + res.data.data.expiredMillisecond);
            // setAddEmailForm(false);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            toast.error("Email belum di isi");
            dispatch(setLoading(false));
          });
      }
    }
  };

  const changePhone = async () => {
    if (phoneFer === "") {
      toast.error("Nomor WA belum di isi");
    } else {
      let newData = {
        phoneNumber: phoneFer,
        companyId: company?.id,
        userId: `${user?._id}`,
        userType: "MEMBER",
        transactionType: "WA_VERIFICATION",
        deviceId: deviceId,
      };
      dispatch(setLoading(true));
      await API_AUTH.requestTokenVerivicationWa(newData)
        .then((res) => {
          setModalVerificationWa(true);
          window.open(
            `https://wa.me/${
              res.data.data.destinationPhoneNumber
            }?text=${encodeURI(
              `Halo ini adalah kode verifikasi saya "${res.data.data.token}" tolong verifikasi ya.`
            )}`,
            `_blank`
          );
          setTokenWa(res.data.data.token);
          setState(res.data.data.state);
          setTimer(Date.now() + res.data.data.expiredMillisecond);
          setShow("hidden");
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  // cek token wa

  const checkTokenWa = async () => {
    dispatch(setLoading(true));
    let newData = {
      state: state,
      phoneNumber: phoneFer,
      companyId: company?.id,
      transactionType: "WA_VERIFICATION",
      deviceId: deviceId,
      token: tokenWa,
    };
    dispatch(setLoading(true));
    await API_AUTH.checkTokenVerification(newData)
      .then((res) => {
        dispatch(setLoading(false));
        console.log(res.data.data);
        let newData = {
          phone: phoneFer,
          otpPrevPhone: res.data.data.otp,
          otpNewPhone: res.data.data.otp,
        };
        dispatch(setLoading(true));
        API_USER.verificationPhone(newData)
          .then((res) => {
            setModalVerificationWa(false);
            dispatch(setLoading(false));
            setSuccess(true);
          })
          .catch((err) => {
            dispatch(setLoading(false));
            setShowDataVerification(false);
          });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <button
          className="text-[#26A69A] my-3"
          type="button"
          onClick={() => (selected === "WA" ? changePhone() : changeEmail())}
        >
          Kirim Ulang
        </button>
      );
    } else {
      return (
        <span className="text-[14px] text-center my-4">
          <span>Kirim ulang OTP dalam</span>
          <span className="text-[#FF9723] font-semibold ml-2">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };
  const isShowVerified = () => {
    return (
      user?.emailVerified &&
      (user?.verifiedPhone === true ||
        (typeof user?.verifiedPhone === "string" &&
          user?.verifiedPhone === "verified_whatsapp"))
    );
  };

  useEffect(() => {
    const fpPromise = fingerPrint.load();

    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        const visitorId = result.visitorId;
        setDeviceId(visitorId);
      });
  }, []);

  return (
    <>
      <div
        className={`${modalVerificationWa ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl px-4 py-5">
              <button
                onClick={() => {
                  setModalVerificationWa(false);
                  window.location.reload();
                }}
                className="absolute right-4 top-4"
              >
                X
              </button>
              <span className="text-[20px] font-semibold mb-2">
                Verifikasi Aktivitas
              </span>
              <div className="text-left mt-4">
                <span>Verifikasi Melalui Nomor</span>
                <input
                  disabled
                  type="number"
                  value={phoneFer}
                  className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                ></input>
                <p className="mt-5 text-center">
                  Sudah Verifikasi ke WhatsApp? Klik "Saya <br /> Sudah
                  Verifikasi" untuk melanjutkan login
                </p>
              </div>
              <Countdown key={timer} date={timer} renderer={renderer} />
              <button
                onClick={() => {
                  checkTokenWa();
                }}
                className="bg-green text-white py-2 mt-5 rounded-lg w-full"
              >
                Saya Sudah Verifikasi
              </button>
              {/* <span
                  className="mt-4 text-xs cursor-pointer"
                  onClick={() => {
                    setShowDataVerification(false);
                    navigate(`/${company.initial}`);
                  }}
                >
                  Lewati langkah ini
                </span> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          props.showAddDestination && isShowVerified() ? "block" : "hidden"
        } relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div
          className={`${
            showModalEmail || showModalPhone ? "z-0" : "z-10"
          } fixed inset-0 overflow-y-auto`}
        >
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-1/2 sm:my-8 md:p-[3rem] p-[1.5rem]">
              <span className="text-[20px] font-semibold mb-2">
                Penambahan data
              </span>
              <p className="text-[12px]">
                Tambahkan data ke akun anda untuk pilihan <br /> pengiriman
                notifikasi anda
              </p>
              <div className="w-full text-left">
                <div className="flex gap-2 my-3">
                  <input
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="radio"
                    id="EMAIL"
                    checked={selected === "EMAIL"}
                    onChange={() => {
                      setSelected("EMAIL");
                    }}
                  />
                  <div className="flex flex-col gap-1 text-start grow relative">
                    <label
                      className="flex justify-start "
                      // htmlFor={item.value}
                    >
                      E-Mail
                    </label>
                    <span className="text-xs text-thirdy">
                      Masukkan E-Mail
                      {/* {item.description} */}
                    </span>
                    <input
                      disabled={selected !== "EMAIL"}
                      type="text"
                      value={emailFer}
                      onChange={(e) => {
                        setEmailFer(e.target.value);
                      }}
                      className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    ></input>
                    {user?.verifiedEmail === true && (
                      <img
                        src={icon_verify}
                        alt=""
                        className="w-7 h-7 absolute right-2 top-[54px]"
                      />
                    )}
                  </div>
                </div>
                <div className="flex gap-2">
                  <input
                    disabled
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="radio"
                    id="WA"
                    checked={selected === "WA"}
                    onChange={() => {
                      setSelected("WA");
                    }}
                  />
                  <div className="flex flex-col gap-1 text-start grow relative">
                    <label className="flex justify-start ">WhatsApp</label>
                    <span className="text-xs text-thirdy">
                      Masukkan No Telepon (format harus +62)
                      {/* Saat ini WhatsApp tidak tersedia. Silahkan hubungi{" "}
                      <a
                        href="https://wa.me/+6289526643223"
                        target="_blank"
                        rel="noreferrer"
                      >
                        CS Closepay
                      </a> */}
                    </span>
                    <input
                      disabled={selected !== "WA"}
                      // disabled
                      type="text"
                      value={phoneFer}
                      onChange={(e) => {
                        setPhoneFer(e.target.value);
                      }}
                      className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    ></input>
                    {user?.verifiedPhone === true && (
                      <img
                        src={icon_verify}
                        alt=""
                        className="w-7 h-7 absolute right-2 top-[54px]"
                      />
                    )}
                  </div>
                </div>
              </div>
              <button
                disabled={!selected}
                onClick={() => {
                  if (selected === "EMAIL") {
                    changeEmail();
                  } else {
                    changePhone();
                  }
                }}
                className={`w-full ${
                  !selected ? "bg-gray-500" : "bg-green"
                } text-white py-2 mt-5 rounded-lg`}
              >
                Konfirmasi
              </button>{" "}
              <span
                className="mt-4 text-xs cursor-pointer"
                onClick={() => {
                  props.onHide();
                  if (props.skipTo) navigate(props.skipTo);
                  dispatch(setSkipTo(``));
                }}
              >
                Lewati langkah ini
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <ConfirmationModal
        showModal={showModalPhone}
        hideModal={hideModalPhone}
        confirm={confirmPhone}
      />

      <ConfirmationModal
        showModal={showModalEmail}
        hideModal={hideModalEmail}
        confirm={confirmEmail}
      /> */}

      {/* Modal Verivication */}
      <div
        className={`${
          props.showAddDestination && !isShowVerified() ? show : "hidden"
        } relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div
          className={`${
            showModalEmail || showModalPhone ? "z-0" : "z-10"
          } fixed inset-0 overflow-y-auto`}
        >
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white w-full shadow-xl transition-all md:w-1/2 sm:my-8 md:p-[3rem] p-[1.5rem]">
              <span className="text-[20px] font-semibold mb-2">
                Tujuan Notifikasi
              </span>
              <p className="text-[12px]">
                Pilih Tujuan Pengiriman Notifikasi Anda
              </p>
              <div className="w-full text-left">
                <div className="flex gap-2 my-3">
                  <input
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="radio"
                    name="selected"
                    // id={item.value}
                    checked={selected === "EMAIL"}
                    onChange={() => {
                      if (user?.email) {
                        setSelected("EMAIL");
                        // setIsAddData(false);
                      } else {
                        setSelected("EMAIL");
                        setIsAddData(true);
                      }
                      if (
                        (typeof user?.phoneVerified === "string" &&
                          user?.phoneVerified === "unverified") ||
                        !user?.phoneVerified
                      )
                        setPhoneFer(user?.phone || "");
                    }}
                  />
                  <div className="flex flex-col gap-1 text-start grow relative">
                    <label
                      className="flex justify-start "
                      // htmlFor={item.value}
                    >
                      E-Mail
                    </label>
                    <span className="text-xs text-thirdy">
                      Masukkan E-Mail
                      {/* {item.description} */}
                    </span>
                    <input
                      disabled={selected !== "EMAIL" && !isAddData}
                      type="text"
                      value={emailFer}
                      onChange={(e) => {
                        setEmailFer(e.target.value);
                      }}
                      className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    ></input>
                    {user?.verifiedEmail === true && (
                      <img
                        src={icon_verify}
                        alt=""
                        className="w-7 h-7 absolute right-2 top-[54px]"
                      />
                    )}
                  </div>
                </div>
                <div className="flex gap-2">
                  <input
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="radio"
                    name="selected2"
                    // id={item.value}
                    checked={selected === "WA"}
                    disabled
                    onChange={() => {
                      if (user?.phone) {
                        setSelected("WA");
                      } else {
                        setSelected("WA");
                        setIsAddData(true);
                      }
                      if (!user?.emailVerified) setEmailFer(user?.email || "");
                    }}
                  />
                  <div className="flex flex-col gap-1 text-start grow relative">
                    <label className="flex justify-start ">WhatsApp (Whatsapp sedang gangguan)</label>
                    <span className="text-xs text-thirdy">
                      Masukkan No Telepon (wajib gunakan +62)
                      {/* Saat ini WhatsApp tidak tersedia. Silahkan hubungi{" "}
                      <a
                        href="https://wa.me/+6289526643223"
                        target="_blank"
                        rel="noreferrer"
                      >
                        CS Closepay
                      </a> */}
                    </span>
                    <input
                      disabled
                      // disabled={selected !== "WA" && !isAddData}
                      // disabled={!isAddData}
                      type="text"
                      value={phoneFer}
                      onChange={(e) => {
                        setPhoneFer(e.target.value);
                      }}
                      className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    ></input>
                    {user?.verifiedPhone === true && (
                      <img
                        src={icon_verify}
                        alt=""
                        className="w-7 h-7 absolute right-2 top-[54px]"
                      />
                    )}
                    {user?.verifiedPhone === true && (
                      <p className="text-center text-[12px] mt-4">
                        Saat ini Whatsapp tidak tersedia. Silahkan hubungi ,
                        <br />
                        <a
                          className="underline text-blue-400"
                          href="https://wa.me/+6289526643223"
                          target="_blank"
                        >
                          CS Closepay
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {isAddData ? (
                <button
                  disabled={!selected}
                  onClick={() => {
                    requestOtp();
                  }}
                  className={`w-full ${
                    !selected ? "bg-gray-500" : "bg-green"
                  } text-white py-2 mt-5 rounded-lg`}
                >
                  Konfirmasi
                </button>
              ) : (
                <button
                  disabled={!selected}
                  onClick={() => {
                    requestOtp();
                  }}
                  className={`w-full ${
                    !selected ? "bg-gray-500" : "bg-green"
                  } text-white py-2 mt-5 rounded-lg`}
                >
                  Verifikasi
                </button>
              )}

              <span
                className="mt-4 text-xs cursor-pointer"
                onClick={() => {
                  props.onHide();
                  if (props.skipTo) navigate(props.skipTo);
                  dispatch(setSkipTo(``));
                }}
              >
                Lewati langkah ini
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${showDataVerification ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div
          className={`${
            showModalEmail || showModalPhone ? "z-0" : "z-10"
          } fixed inset-0 overflow-y-auto`}
        >
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]">
              <div className="flex flex-row justify-end w-100 mb-3 md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setShowDataVerification(false);
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p className="md:text-2xl text-sm text-black md:mb-4">
                Masukan kode OTP yang telah dikirimkan ke
                <span className="font-bold">
                  {" "}
                  {selected === "WA" ? phoneFer : emailFer}{" "}
                </span>
                {/* dengan subjek <span className="font-bold">{props.message}</span> */}
              </p>
              <StatefulPinInput
                length={6}
                placeholder=""
                initialValue={NewValues}
                onChange={(value, index, values) => {
                  setNewValues(values);
                  data["otp"] = values.join("");
                }}
                type="number"
                mask={true}
                focusBorderColor="#26A69A"
                validBorderColor="#26A69A"
              />
              <Countdown key={timer} date={timer} renderer={renderer} />
              <button
                disabled={NewValues.join("").length < 6}
                onClick={() => {
                  if (selected === "WA") {
                    confirmPhone();
                  } else {
                    confirmEmail();
                  }
                }}
                className={`${
                  NewValues.join("").length < 6 ? "bg-gray-500" : "bg-[#26A69A]"
                } w-full md:py-[16px] p-[10px] rounded-full text-white font-semibold text-base disabled:bg-slate-50`}
              >
                Konfirmasi
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
      <div
        className={`${success ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div
          className={`${
            showModalEmail || showModalPhone ? "z-0" : "z-10"
          } fixed inset-0 overflow-y-auto`}
        >
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]">
              <svg
                width="118"
                height="118"
                viewBox="0 0 118 118"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="118" height="118" rx="59" fill="#CFEAE8" />
                <path
                  d="M40.2354 66.6789V38.0625C40.2354 37.5102 40.6831 37.0625 41.2354 37.0625H76.7648C77.317 37.0625 77.7648 37.5102 77.7648 38.0625V66.6789C77.7648 70.2214 75.6866 73.4344 72.4556 74.8872L59.4102 80.7531C59.1493 80.8704 58.8508 80.8704 58.59 80.7531L45.5445 74.8872C42.3135 73.4344 40.2354 70.2214 40.2354 66.6789Z"
                  stroke="#408D86"
                  stroke-width="4"
                />
                <path
                  d="M50.4707 56.4688L56.4413 62.375L67.5295 49.7188"
                  stroke="#FF9723"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="font-semibold text-[14px] mt-4">
                {selected === "WA" ? "Nomor" : "Email"} anda telah terverifikasi
              </p>
              <button
                onClick={() => {
                  getDataProfile();
                  props.onHide();
                }}
                className="bg-green py-2.5 px-6 rounded-xl text-white font-semibold"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddDestinationOtpComponent;
