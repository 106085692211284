import { BrowserView, MobileView } from "react-device-detect";
import ParkingResultWeb from "./parkingResultWeb";
import ParkingResultMobile from "./parkingResultMobile";

const ParkingResult = () => {
  return (
    <div>
      <BrowserView>
        <ParkingResultWeb />
      </BrowserView>
      <MobileView>
        <ParkingResultMobile />
      </MobileView>
    </div>
  );
};

export default ParkingResult;
