/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import WithdrawCardPaymentSummaryWeb from "./withdrawCardPaymentSummaryWeb";
import WithdrawCardPaymentSummaryMobile from "./withdrawCardPaymentSummaryMobile";
import { useParams } from "react-router-dom";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { formatCurrency, formatDateTimeUTC } from "../../../config/global";
import { setLoading } from "../../../core/feature/config/configSlice";
import Company from "../../../core/models/company";
import { useTranslation } from "react-i18next";

const WithdrawCardPaymentSummary = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [dataPost, setDataPost] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any>({});

  const getDetail = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.getDetailWithdrawBalance(params.transactionId)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        let tempData = res.data.data;
        let newData = [
          {
            property: t('virtual_card.transaction_id'),
            value: tempData._id,
          },
          {
            property: t('virtual_card.date'),
            value: formatDateTimeUTC(tempData.createdTime),
          },
          {
            property: t('virtual_card.member_name'),
            value: tempData.user.name,
          },
          {
            property: t('virtual_card.payment_type'),
            value: t('withdraw_funds.card_balance_withdrawal'),
          },
          {
            property: t('virtual_card.card_name'),
            value: tempData.cardName,
          },
          {
            property: t('withdraw_funds.purpose_disbursement'),
            value: t('virtual_card.balance_closeloop'),
          },
          {
            property: t('withdraw_funds.nominal'),
            value: formatCurrency(tempData.amount),
          },
          {
            property: t('withdraw_funds.adminFee'),
            value: formatCurrency(tempData.totalCharges),
          },
        ];
        setDataPost(newData);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <BrowserView>
        <WithdrawCardPaymentSummaryWeb
          company={company}
          dataPost={dataPost}
          data={data}
        />
      </BrowserView>
      <MobileView>
        <WithdrawCardPaymentSummaryMobile
          company={company}
          dataPost={dataPost}
          data={data}
        />
      </MobileView>
    </>
  );
};

export default WithdrawCardPaymentSummary;
