import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import iconFailed from "../../assets/images/icon-failed.png";
import Success from "../../assets/images/security_success.png";
import {
  AlertPasswordStrength,
  ConfigColor,
  checkRegex,
} from "../../config/global";
import { setLoading } from "../../core/feature/config/configSlice";
import { removeFireBaseToken } from "../../core/feature/firebaseToken/firebaseTokenSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { removeToken, setToken } from "../../core/feature/token/tokenSlice";
import { removeBalance } from "../../core/feature/user/balanceSlice";
import { removePin } from "../../core/feature/user/pinSlice";
import { removeUser } from "../../core/feature/user/userSlice";
import { removeUserLoginByUser } from "../../core/feature/userLogin/userLoginSlice";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import User from "../../core/models/user";
import UserLogin from "../../core/models/userLogin";
import * as API_USER from "../../core/service/api_user";
import Header from "../_common/headerComponent";

const ChangePasswordMobile = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const user: User = useAppSelector((state) => state.user.user);
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState("");
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");
  const [updateSuccessFully, setUpdateSuccessFully] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const color = ConfigColor();
  const [strength, setStrength] = useState("");

  const handlePasswordType = (type: string) => {
    switch (type) {
      case "old":
        switch (oldPasswordType) {
          case "password":
            setOldPasswordType("text");
            break;
          default:
            setOldPasswordType("password");
            break;
        }
        break;

      case "new":
        switch (newPasswordType) {
          case "password":
            setNewPasswordType("text");
            break;
          default:
            setNewPasswordType("password");
            break;
        }
        break;

      case "confirmNew":
        switch (confirmNewPasswordType) {
          case "password":
            setConfirmNewPasswordType("text");
            break;
          default:
            setConfirmNewPasswordType("password");
            break;
        }
        break;

      default:
        setOldPasswordType("password");
        setNewPasswordType("password");
        setConfirmNewPasswordType("password");
    }
  };

  const getPasswordStrength = async () => {
    dispatch(setLoading(true));
    await API_USER.getPasswordStrength(company.id)
      .then((res) => {
        dispatch(setLoading(false));
        setStrength(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = async () => {
    const regexPass =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*~()-_+\-=[\]{};':"|,.<>/?]).{6,}$/;
    if (oldPassword === "") {
      setErrorOldPassword(t("changePasswordWeb.requiredOldPassword"));
    } else if (newPassword === "") {
      setErrorNewPassword(t("changePasswordWeb.requiredNewPassword"));
    } else if (!checkRegex("password", newPassword, strength)) {
      setErrorNewPassword(AlertPasswordStrength(strength));
    } else if (confirmNewPassword === "") {
      setErrorConfirmNewPassword(t("changePasswordWeb.requiredConfirmNewPassword"));
    } else if (newPassword !== confirmNewPassword) {
      setErrorConfirmNewPassword(t("changePasswordWeb.passwordsDoNotMatch"));
    } else {
      dispatch(setLoading(true));
      let data = {
        oldPassword,
        password: newPassword,
        confirmPassword: confirmNewPassword,
      };

      await API_USER.changePassword(data)
        .then((res) => {
          dispatch(setLoading(false));
          setUpdateSuccessFully(true);
          var newUserLogin = _.reject(userLogin, { id: user.noId });
          var newToken = token?.length > 0 ? [...token] : [];
          let updatedNewToken: any = [];
          if (newUserLogin.length > 0) {
            updatedNewToken = _.map(newToken, (item) => {
              let itemTemp = { ...item };
              if (item.cid === newUserLogin[0].initial) {
                itemTemp.token = newUserLogin[0]?.token;
              }
              return itemTemp;
            });
            dispatch(removeUserLoginByUser(user));
            dispatch(setToken(updatedNewToken));
          } else {
            dispatch(removeUserLoginByUser(user));
            dispatch(setLoading(false));
            dispatch(removeToken());
            dispatch(removeFireBaseToken());
            dispatch(removeUser());
            dispatch(removePin());
            dispatch(removeBalance());
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          setUpdateFailed(true);
        });
    }
  };

  useEffect(() => {
    getPasswordStrength();
  }, []);

  return (
    <section className="h-full min-h-screen font-poppins bg-mobile">
      <Header navbarText={t('changePasswordWeb.title')}></Header>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] pb-4 px-4">
        <div
          className={`${updateSuccessFully || updateFailed
            ? "hidden"
            : "flex flex-col md:w-1/2 w-full mx-auto h-[85vh]"
            }`}
        >
          <div>
            <h1 className="font-bold md:text-2xl text-xl text-[#252525] font-montserrat">
              {t('changePasswordWeb.title')}
            </h1>
            <h5 className="font-normal text-sm text-[#252525] font-montserrat">
              {t('changePasswordWeb.description')}
            </h5>
          </div>
          <div className="w-full text-left my-9 flex-grow">
            <div className="w-full flex flex-col gap-1 my-3 relative">
              <h1 className="text-sm text-[#252525] leading-5 font-medium mb-1 font-montserrat">
                {t('changePasswordWeb.oldPassword')}
              </h1>
              <input
                type={oldPasswordType}
                className="focus:border-1 rounded-lg p-2.5 text-base border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  setErrorOldPassword("");
                }}
              />
              <p className="text-sm text-red pl-1 mb-0">{errorOldPassword}</p>
              <div
                className="absolute md:top-[46px] top-[42px] right-[20px] cursor-pointer"
                onClick={() => handlePasswordType("old")}
              >
                {oldPasswordType === "text" ? (
                  <FontAwesomeIcon icon={faEye} size="lg" color="#C4C4C4" />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    size="lg"
                    color="#C4C4C4"
                  />
                )}
              </div>
            </div>
            <div className="w-full flex flex-col gap-1 my-3 relative">
              <h1 className="text-sm text-[#252525] leading-5 font-medium mb-1 font-montserrat">
                {t('changePasswordWeb.newPassword')}
              </h1>
              <input
                type={newPasswordType}
                className="focus:border-1 rounded-lg md:p-[1rem] p-2.5 text-base border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setErrorNewPassword("");
                }}
              />
              <div
                className="absolute md:top-[46px] top-[42px] right-[20px] cursor-pointer "
                onClick={() => handlePasswordType("new")}
              >
                {newPasswordType === "text" ? (
                  <FontAwesomeIcon icon={faEye} size="lg" color="#C4C4C4" />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    size="lg"
                    color="#C4C4C4"
                  />
                )}
              </div>
              <p className="text-sm text-red pl-1 mb-0">{errorNewPassword}</p>
            </div>
            <div className="w-full flex flex-col gap-1 my-3 relative">
              <h1 className="text-sm text-[#252525] leading-5 font-medium mb-1 font-montserrat">
                {t('changePasswordWeb.confirmNewPassword')}
              </h1>
              <input
                type={confirmNewPasswordType}
                className="focus:border-1 rounded-lg md:p-[1rem] p-[12px] text-base border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25"
                value={confirmNewPassword}
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value);
                  setErrorConfirmNewPassword("");
                }}
                onKeyDown={(e) => e.key === "Enter" && handleOnClick()}
              />
              <div
                className="absolute md:top-[46px] top-[42px] right-[20px] cursor-pointer"
                onClick={() => handlePasswordType("confirmNew")}
              >
                {confirmNewPasswordType === "text" ? (
                  <FontAwesomeIcon icon={faEye} size="lg" color="#C4C4C4" />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    size="lg"
                    color="#C4C4C4"
                  />
                )}
              </div>
              <p className="text-sm text-red pl-1 mb-0">
                {errorConfirmNewPassword}
              </p>
            </div>
          </div>
          <div className="w-full mt-4">
            <button
              type="button"
              onClick={() => handleOnClick()}
              style={{
                backgroundColor: `${color?.color1}`,
              }}
              className="w-full md:p-[1rem] p-[12px] rounded-lg text-white text-base font-semibold font-montserrat"
            >
              {t('changePasswordWeb.save')}
            </button>
          </div>
        </div>
        <Transition appear show={updateSuccessFully} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setUpdateSuccessFully(true)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[20rem] md:w-[25rem] max-w-2xl rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium text-center leading-6 text-gray-900"
                    >
                      <img
                        src={Success}
                        alt=""
                        className="mx-auto md:w-[7rem]"
                      />
                    </Dialog.Title>
                    <div className="bg-white rounded-xl mt-3">
                      <div className="flex flex-col items-center w-full gap-4">
                        <span className="text-xl font-[500] md:text-[22px] text-[#263339]">
                          Password Berhasil Diubah
                        </span>
                        <span className="text-sm text-center md:text-base [#263339]">
                          Silakan login ulang menggunakan
                          <br /> password yang baru saja dibuat
                        </span>
                      </div>
                      <div className="bg-white rounded-xl">
                        <div className="flex w-full gap-4">
                          <button
                            onClick={() =>
                              navigate(`/${company?.initial}/login`, {
                                replace: true,
                              })
                            }
                            className="rounded-[8px] text-white font-semibold text-base bg-[#26A69A] px-4 py-2 text-sm font-[500] mx-auto mt-12 focus:outline-none md:text-base"
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <div
          className={`${updateFailed
            ? "flex flex-col md:w-1/2 w-full mx-auto h-[85vh]"
            : "hidden"
            }`}
        >
          <div className="relative items-center mt-4 mb-3">
            <img src={iconFailed} alt="user" className="mx-auto" />
          </div>
          <div className="w-100 text-center mb-3 flex-grow">
            <h1 className="font-bold md:text-2xl text-xl">
              {t('changePasswordWeb.passwordChangeFailed')}
            </h1>
          </div>
          <div className=" w-full mt-2 text-center ">
            <button
              type="button"
              style={{
                backgroundColor: `${color?.color1}`,
              }}
              className="w-full md:p-[1rem] p-[12px] font-semibold rounded-lg text-white text-base "
              onClick={() => setUpdateFailed(false)}
            >
              {t('changePasswordWeb.back')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePasswordMobile;
