/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatCurrency } from "../../config/global";
import Header from "../_common/headerComponent";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";
import Token from "../../core/models/token";
import Reauthenticate from "../_common/reauthenticate";
import Company from "../../core/models/company";
import ConfirmationModal from "../_common/confirmationModal";
import { useTranslation } from "react-i18next";

const PPOBPascabayarSummaryWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  let dispatch = useAppDispatch();
  let location: any = useLocation();
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [inquiryId, setInquiryId] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const trxPostpaid = async () => {
    setShowConfirmationModal(false);
    dispatch(setLoading(true));
    let dataOrder = {
      serviceCode: data.serviceCode,
      customerNumber: data.phoneNumber,
    };
    await API_DIGIPROD.trxPostpaid(dataOrder, token)
      .then((res) => {
        dispatch(setLoading(false));
        setInquiryId(res.data.data.inquiryId);
        setTransactionId(res.data.data.transactionId);
        console.log(res.data.data);
        setShowModal(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    setData(location.state.data);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>

      <Reauthenticate
        show={showModal}
        onHide={() => setShowModal(false)}
        transactionType="DIGITAL_PRODUCT"
        inquiryId={inquiryId}
        transactionId={transactionId}
        redirect={`/${company?.initial}/digital-product/pulsa-data/pascabayar/success`}
        width={60}
        height={63}
        margin="0 0.5rem"
        fontSize="2rem"
      ></Reauthenticate>

      {/* <ConfirmationModal
        showModal={showConfirmationModal}
        hideModal={() => setShowConfirmationModal(false)}
        confirm={trxPostpaid}
      /> */}

      <div className="flex md:flex-row flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0">
        <div className="bg-white w-full md:w-2/3 lg:w-1/2 p-4 md:mb-[100px] mb-[0px]">
          <h6 className="text-base md:text-xl font-medium text-green">
            {t('ppobHistory.summary')}
          </h6>
          <hr />
          <div className="space-between gap-3">
            <p className="text-sm md:text-base text-customBlack font-semibold mb-0">
              {t('ppobHistory.transaction_date')}
            </p>
            <p className="text-sm md:text-base text-newColor font-semibold mb-0">
              {moment().format("ll")}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack font-semibold mb-0">
              {t('ppobHistory.phone_number')}
            </p>
            <p className="text-sm md:text-base text-newColor font-semibold mb-0">
              {data?.phoneNumber}
            </p>
          </div>
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack font-semibold mb-0">
              {t('ppobHistory.service_type')}
            </p>
            <p className="text-sm md:text-base text-newColor font-semibold mb-0 text-end">
              {data?.serviceName} - {t('topUp.postpaid')}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack font-semibold mb-0">
              {t('ppobHistory.total')}
            </p>
            <p className="text-sm md:text-base text-newColor font-semibold mb-0">
              {formatCurrency(data?.totalPrice)}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack font-semibold mb-0">
              {t('ppobHistory.category')}
            </p>
            <p className="text-sm md:text-base text-customBlack font-semibold mb-0">
              {t('ppobHistory.pulse_and_data')}
            </p>
          </div>
        </div>
        <div
          className="absolute bottom-0 left-1/2 w-full md:w-2/3 lg:w-1/2"
          style={{ transform: "translate(-50%, 0)" }}
        >
          <div className="bg-white">
            <div className="px-6 py-7">
              <div className="flex justify-between items-center">
                <h5 className="md:text-xl text-lg text-customBlack font-medium mb-0">
                  {t('ppobHistory.totalPrice')}
                </h5>
                <h5 className="md:text-xl text-lg font-semibold text-green mb-0">
                  {formatCurrency(data.totalPrice)}
                </h5>
              </div>
              <button
                type="button"
                style={{
                  background: `${company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A"
                    }`,
                }}
                className="bg-green text-white font-medium rounded-[10px] w-full py-[10px] mt-3"
                onClick={trxPostpaid}
              >
                {t('ppobHistory.confirmPayment')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PPOBPascabayarSummaryWeb;
