import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useParams, useNavigate } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import { toast } from "react-hot-toast";
import { bearer } from "../../config/global";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company.js";
import { calculate_zakat } from "../../core/service/api_donation";
import { addZakat, getZakatById } from "../../core/service/api_zakat";
import { ConfigColor } from "../../config/global";
import Header from "../_common/headerComponent";
import { useTranslation } from "react-i18next";

const ZakatAddWeb = () => {
  const color = ConfigColor();
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [message, setMessage] = useState("");
  const [amountPayment, setAmountPayment] = useState(0);
  const [mainIncome, setMainIncome] = useState(0);
  const [sideIncome, setSideIncome] = useState(0);
  const [installmentPerMonth, setInstallmentPerMonth] = useState(0);
  const [data, setData] = useState<any>(null);

  const handlePayment = () => {
    if (amountPayment < 1) return toast.error(t('zakat.nominalRequired'));
    let newData = {
      amount: amountPayment,
      zakatId: params.id,
      isAnonymous,
      message,
    };
    // return console.log(newData);
    addZakat(newData)
      .then((response) => {
        // toast.success("Zakat Berhasil");
        window.open(
          `https://${response.data.data.link
          }?access_token=${bearer().replaceAll('"', "")}`,
          "_self"
        );
        navigate(`/${company.initial}?redirect=false`);
        // navigate(`/${company?.initial}/donations/donation/detail/${params.id}`);
      })
      .catch((err) => {
        // toast.error("Zakat Gagal");
        console.log(err);
      });
  };

  const params = useParams();

  const getDonation = () => {
    getZakatById(params.id)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const countPayment = () => {
    let data = {
      mainIncome,
      sideIncome,
      installmentPerMonth,
    };
    calculate_zakat(data)
      .then((res) => {
        console.log(res.data.data);
        setAmountPayment(res.data.data.resultZakat);
      })
      .catch((err) => {
        console.log(err);
       });
  };

  useEffect(() => {
    getDonation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header></Header>
      <section className="min-h-[100vh] font-poppins bg-mobile">
        <div className="w-2/5 lg:w-1/2  min-w-[30rem]  m-auto font-poppins">
          <div className="flex h-full flex-col items-center justify-center">
            <div className="mt-6 w-full h-full">
              <div className="flex flex-col h-full w-full bg-white rounded-md shadow-sm shadow-gray-700 gap-1 p-2">
                <div className="flex flex-col mx-3 py-3 gap-4">
                  {data?.category === "ZAKAT_PENGHASILAN" && (
                    <div className={`flex flex-col gap-1`}>
                      <label className="text-base text-thirdy font-medium pl-1 mb-0">
                        {t('zakat.mainIncome')}
                      </label>
                      <CurrencyInput
                        // className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                        className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border-[#1AA7EC] border-[#7775]"
                        defaultValue={1000}
                        decimalsLimit={2}
                        value={mainIncome}
                        prefix={"Rp "}
                        onValueChange={(value: any, name) => {
                          let amount = !value ? 0 : parseInt(value);
                          setMainIncome(amount);
                        }}
                        allowNegativeValue={false}
                      />
                    </div>
                  )}
                  {data?.category === "ZAKAT_PENGHASILAN" && (
                    <div className={`flex flex-col gap-1`}>
                      <label className="text-base text-thirdy font-medium pl-1 mb-0">
                        {t('zakat.sideIncome')}
                      </label>
                      <CurrencyInput
                        // className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                        className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border-[#1AA7EC] border-[#7775]"
                        defaultValue={1000}
                        decimalsLimit={2}
                        value={sideIncome}
                        prefix={"Rp "}
                        onValueChange={(value: any, name) => {
                          let amount = !value ? 0 : parseInt(value);
                          setSideIncome(amount);
                        }}
                        allowNegativeValue={false}
                      />
                    </div>
                  )}
                  {data?.category === "ZAKAT_PENGHASILAN" && (
                    <div className={`flex flex-col gap-1`}>
                      <label className="text-base text-thirdy font-medium pl-1 mb-0">
                        {t('zakat.monthlyInstallment')}
                      </label>
                      <CurrencyInput
                        // className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                        className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border-[#1AA7EC] border-[#7775]"
                        defaultValue={1000}
                        decimalsLimit={2}
                        value={installmentPerMonth}
                        prefix={"Rp "}
                        onValueChange={(value: any, name) => {
                          let amount = !value ? 0 : parseInt(value);
                          setInstallmentPerMonth(amount);
                        }}
                        allowNegativeValue={false}
                      />
                    </div>
                  )}
                  {data?.category === "ZAKAT_PENGHASILAN" && (
                    <div className="bg-white bottom-0 flex justify-end gap-2">
                      <button
                        disabled={mainIncome <= 0}
                        onClick={() => countPayment()}
                        type="button"
                        className=" border-solid border-[1px] border-[#728F9E] text-[#728F9E] w-fit px-4 h-10 rounded-md"
                      >
                        {t('zakat.count')}
                      </button>
                    </div>
                  )}
                  <div className={`flex flex-col gap-1 mt-3`}>
                    <label className="text-base text-thirdy font-medium pl-1 mb-0">
                      {t('zakat.insertNominal')}
                    </label>
                    <CurrencyInput
                      // className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                      className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border- border-[#7775]"
                      defaultValue={1000}
                      decimalsLimit={2}
                      value={amountPayment}
                      prefix={"Rp "}
                      onValueChange={(value: any, name) => {
                        let amount = !value ? 0 : parseInt(value);
                        setAmountPayment(amount);
                      }}
                      allowNegativeValue={false}
                    />
                  </div>
                  <div className={`flex flex-col gap-1 mt-3`}>
                    <label className="text-base text-thirdy font-medium pl-1 mb-0">
                      {t('zakat.message')}
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      cols={30}
                      rows={5}
                      className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border- border-[#7775]"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className={`flex gap-1 mt-3`}>
                    <label
                      htmlFor="isAnonymous"
                      className="text-base text-thirdy font-medium pl-1 mb-0"
                    >
                      {t('zakat.invisibleName')}
                    </label>
                    <input
                      type="checkbox"
                      id="isAnonymous"
                      checked={isAnonymous}
                      onChange={() => setIsAnonymous(!isAnonymous)}
                    />
                  </div>
                </div>
                <div className="sticky bg-white bottom-0 w-full p-4 mt-4 flex flex-col gap-2">
                  <button
                    disabled={amountPayment <= 0}
                    onClick={() => handlePayment()}
                    type="button"
                    className="bg-themePrimary text-white w-full h-10 rounded-md"
                    style={{ backgroundColor: color.color1 }}
                  >
                    {t('zakat.cashItOut')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZakatAddWeb;
