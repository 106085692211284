import React, { useState } from "react";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency, onlyNumber } from "../../config/global";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { setLoading } from "../../core/feature/config/configSlice";
import logoPLN from "../../assets/images/logo-pln.svg";
import buttonSelected from "../../assets/images/button-selected.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useTranslation } from "react-i18next";

const PPOBPLNMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [priceList, setPriceList] = useState<any[]>([]);
  const [number, setNumber] = useState("");
  const [productType, setProductType] = useState("");
  const [nominal, setNominal] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [errorProductType, setErrorProductType] = useState("");
  const [errorNominal, setErrorNominal] = useState("");
  const [productCode, setProductCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [active, setActive] = useState<any>({});

  const productList = [
    {
      id: "1",
      name: t('pln.token_electricity'),
    },
    {
      id: "2",
      name: t('pln.electricity_bill'),
    },
  ];

  const getPriceList = async () => {
    dispatch(setLoading(true));
    await API_DIGIPROD.tokenPriceList()
      .then((res) => {
        dispatch(setLoading(false));
        setPriceList(res.data.data);
        setShowModal2(true);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = () => {
    if (productType === "") {
      setErrorProductType(t('pln.choose_product_type'));
    } else if (number === "") {
      setErrorNumber(t('pln.enter_meter_number'));
    } else if (productType === t('pln.token_electricity') && nominal === "") {
      setErrorNominal(t('pln.token_electricity'));
    } else {
      let query = {
        productCode,
        customerNumber: number,
      };
      dispatch(setLoading(true));
      if (productType === t('pln.token_electricity')) {
        API_DIGIPROD.trxTokenSummary(query)
          .then((res) => {
            dispatch(setLoading(false));
            navigate(`/${company?.initial}/digital-product/pln/summary`, {
              state: {
                data: res.data.data,
              },
            });
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      } else if (productType === t('pln.electricity_bill')) {
        API_DIGIPROD.trxPlnPostpaidInquiry(query)
          .then((res) => {
            dispatch(setLoading(false));
            navigate(`/${company?.initial}/digital-product/pln/summary`, {
              state: {
                data: res.data.data,
              },
            });
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    }
  };

  // const [configColor, setConfigColor] = useState<any>({});
  // const getConfigColor = async () => {
  //   var color1 =
  //     company?.color?.color1 != undefined ? company?.color?.color1 : "#006F54";
  //   var color2 =
  //     company?.color?.color2 != undefined ? company?.color?.color2 : "#087B5E";

  //   setConfigColor({ color1: color1, color2: color2 });
  // };

  // useEffect(() => {
  //   getConfigColor();
  // }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={t('pln.plnElectricity')}></Header>

      {/* MODAL */}
      <BottomSheet
        open={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
      >
        <div className="mx-4 my-3 font-montserrat">
          <ul className="px-0 mb-0">
            {productList.map((item) => (
              <li
                className="list-none cursor-pointer"
                key={item.id}
                onClick={() => {
                  setActive(item);
                  setProductType(item.name);
                  setErrorProductType("");
                  setShowModal(false);
                }}
              >
                <div className="flex flex-row justify-between items-center">
                  <div className="centered-items gap-2">
                    <img src={logoPLN} alt="" />
                    <p className="md:text-base text-sm font-semibold mb-0 font-montserrat">
                      {item.name}
                    </p>
                  </div>
                  <div
                    className={`${active?.id === item.id ? "inline-block" : "hidden"
                      }`}
                  >
                    <img src={buttonSelected} alt="" />
                  </div>
                </div>
                <hr />
              </li>
            ))}
          </ul>
        </div>
      </BottomSheet>

      <BottomSheet
        open={showModal2}
        onDismiss={() => {
          setShowModal2(false);
        }}
      >
        <div className="mx-4 my-3 font-montserrat">
          <ul className="px-0 mb-0">
            {priceList.map((item, index) => (
              <li
                className="list-none cursor-pointer hover:bg-teal-50"
                key={index}
                onClick={() => {
                  setNominal(item.totalPrice);
                  setProductCode(item.buyer_sku_code);
                  setErrorNominal("");
                  setShowModal2(false);
                }}
              >
                <div className="flex flex-row justify-between items-center p-2">
                  <div>
                    <p className="md:text-base text-sm text-start text-green mb-0">
                      {item?.product_name}
                    </p>
                    <p className="md:text-sm text-xs text-start mb-0">
                      {formatCurrency(item?.totalPrice)}
                    </p>
                  </div>
                </div>
                <hr className="mt-0" />
              </li>
            ))}
          </ul>
        </div>
      </BottomSheet>
      {/* ---- */}

      <div className="w-full md:w-2/3 lg:w-1/2 mx-auto flex flex-col-reverse gap-5 justify-center md:mt-[64px] mt-[0px] px-4">
        <div className="w-full">
          <div className="flex flex-col items-center gap-3 rounded-[10px] md:mb-10 mb-6 ">
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('pln.electricity_product')}
                </label>
                <input
                  defaultValue={productType}
                  readOnly
                  onClick={() => setShowModal(true)}
                  className="bg-[#fff] border-[0.5px] border-[#9A9999] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                />
                <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                  <button type="button" onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0 font-montserrat">
                {errorProductType}
              </p>
            </div>
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('pln.meter_number')}
                </label>
                <input
                  type="number"
                  value={number}
                  onKeyPress={onlyNumber}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setErrorNumber("");
                  }}
                  className="bg-[#fff] border-[0.5px] border-[#9A9999] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                />
                <div className="absolute md:top-[47px] top-[40px] right-4 font-montserrat">
                  <button
                    type="button"
                    className="bg-gray-500 rounded-full px-[5px] py-[2px] flex justify-center text-white"
                    onClick={() => {
                      setNumber("");
                      setErrorNumber("");
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0 font-montserrat">
                {errorNumber}
              </p>
            </div>
            {active?.name === t('pln.token_electricity') && (
              <div className="text-start w-full">
                <div className="relative">
                  <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                    {t('pln.nominal')}
                  </label>
                  <input
                    readOnly
                    defaultValue={nominal}
                    onClick={getPriceList}
                    className="bg-[#fff] border-[0.5px] border-[#9A9999] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                  />
                  <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                    <button type="button" onClick={() => setShowModal2(true)}>
                      <FontAwesomeIcon icon={faCaretDown} size="2x" />
                    </button>
                  </div>
                </div>
                <p className="text-sm text-red pl-1 mb-0">{errorNominal}</p>
              </div>
            )}
          </div>
        </div>
        <div
          className="absolute bottom-0 right-0 w-full md:w-2/3 lg:w-1/2 left-1/2 px-4"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <button
            type="button"
            style={{
              background: `${company?.color?.color1 !== undefined
                ? company?.color?.color1
                : "#26A69A"
                }`,
            }}
            className="bg-green text-white font-medium text-base rounded-[10px] w-full py-[10px] font-montserrat"
            onClick={() => handleOnClick()}
          >
            {t('pln.next')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default PPOBPLNMobile;
