import { BrowserView, MobileView } from "react-device-detect";
import FeatureScanBarcodeMobile from "./featureScanBarcodeMobile";
import FeatureScanBarcodeWeb from "./featureScanBarcodeWeb";

const FeatureScanBarcode = () => {
  return (
    <div>
      <BrowserView>
        <FeatureScanBarcodeWeb />
      </BrowserView>
      <MobileView>
        <FeatureScanBarcodeMobile />
      </MobileView>
    </div>
  );
};

export default FeatureScanBarcode;
