import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ChangeCompanyMobile from "./changeCompanyMobile";
import ChangeCompanyWeb from "./changeCompanyWeb";

const ChangeCompany = () => {
  return (
    <>
      <BrowserView>
        <ChangeCompanyWeb></ChangeCompanyWeb>
      </BrowserView>
      <MobileView>
        <ChangeCompanyMobile></ChangeCompanyMobile>
      </MobileView>
    </>
  );
};

export default ChangeCompany;
