import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPrabayarSuccessWeb from "./ppobPrabayarSuccessWeb";
import PPOBPrabayarSuccessMobile from "./ppobPrabayarSuccessMobile";

const PPOBPrabayarSuccess = () => {
  return (
    <>
      <BrowserView>
        <PPOBPrabayarSuccessWeb></PPOBPrabayarSuccessWeb>
      </BrowserView>
      <MobileView>
        <PPOBPrabayarSuccessMobile></PPOBPrabayarSuccessMobile>
      </MobileView>
    </>
  );
};

export default PPOBPrabayarSuccess;
