import Header from "../../_common/headerComponent";
import card from "../../../assets/images/fnb/cardAntrian.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { formatCurrency } from "../../../config/global";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../core/feature/config/configSlice";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import Company from "../../../core/models/company";
import { useAppSelector } from "../../../core/feature/hooks";
import { removeState } from "../../../core/feature/browser-storage/browser-storage";
import { Dialog, Transition } from "@headlessui/react";
import { loadState } from "../../../core/feature/browser-storage/browser-storage";
import { saveState } from "../../../core/feature/browser-storage/browser-storage";

const OrderSummaryMobile = () => {
  const [data, setData] = useState<any>({});
  const company: Company = useAppSelector((state) => state.company.company);
  const dispath = useDispatch();
  const navigate = useNavigate();
  // let location: any = useLocation();
  const param = useParams();
  const [chosePayment, setChosePayment] = useState(false);
  const [paymentMethode, setPaymentMethode] = useState("cash");
  let redirectCO = loadState("redirectCO");

  const handleCheckout = async () => {
    if (paymentMethode === "online") {
      if (localStorage.getItem("linkCo") === null) {
        await API_COMMERCE.configPayment(param.merchantId)
          .then((res) => {
            if (res.data.data.paymentConfig === "PAY_BEFORE") {
              dispath(setLoading(true));
              API_COMMERCE.checkoutFnb(company.id, data.transactionId)
                .then((res) => {
                  console.log(res.data.data.checkoutId);
                  localStorage.setItem("coId", res.data.data.checkoutId);
                  localStorage.setItem(
                    "linkCo",
                    `https://${res.data.data.checkoutLink}`
                  );
                  saveState("redirectCO", `/${company.initial}`);
                  window.location.href = `https://${res.data.data.checkoutLink}`;
                  removeState("cart_data");
                  dispath(setLoading(false));
                })
                .catch((err) => {
                  dispath(setLoading(false));
                });
            } else {
              navigate("order");
              removeState("cart_data");
            }
          })
          .catch((err) => {});
      } else {
        saveState("redirectCO", `/${company.initial}`);
        window.location.href = `${localStorage.getItem("linkCo")}`;
      }
    } else {
      navigate("cash");
      removeState("cart_data");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("coId") !== null) {
      API_COMMERCE.checkPaymentResult(localStorage.getItem("coId"))
        .then((res) => {
          console.log(res);
          if (res.data.data.status === "CLOSE") {
            navigate("order");
            localStorage.removeItem("linkCo");
          }
        })
        .catch((err) => {});
    }
    var data: any = localStorage?.getItem("summaryData");
    setData(JSON.parse(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (redirectCO) {
      navigate(`${redirectCO}?redirect=false`);
      removeState(`redirectCO`);
    }
  }, [redirectCO]);

  return (
    <div className="min-h-screen bg-white font-montserrat pb-20">
      <div className="w-full bg-white">
        <Header navbarText="Konfirmasi Pesanan" />
        {data?.customer?.tableNumber !== "" && (
          <div className="w-full text-center mb-20">
            <img src={card} className="flex mx-auto" alt="" />
            <div className="-mt-24 text-white">
              <span>No Meja</span>
              <p className="font-semibold text-3xl mt-2">
                {data?.customer?.tableNumber}
              </p>
            </div>
          </div>
        )}
        <div className="w-full p-4">
          <p className="font-semibold text-lg">Ringkasan</p>
          <div className="flex justify-between border-b py-2 px-2">
            <span className="text-secondary font-semibold">Nama</span>
            <span>{data?.customer?.name}</span>
          </div>
          <div className="flex justify-between border-b py-2 px-2">
            <span className="text-secondary font-semibold">Nomor Wa</span>
            <span>
              {data?.customer?.phoneNumber === ""
                ? "-"
                : data?.customer?.phoneNumber}
            </span>
          </div>
          <div className="flex justify-between border-b py-2 px-2">
            <span className="text-secondary font-semibold">Email</span>
            <span>
              {data?.customer?.email === "" ? "-" : data?.customer?.email}
            </span>
          </div>
          <div className="flex justify-between border-b py-2 px-2">
            <span className="text-secondary font-semibold">Metode</span>
            <span>
              {data?.customer?.fnbType === "DINE_IN" ? "Dine In" : "Take Away"}
            </span>
          </div>
          {data?.productList?.map((res: any, i: any) => {
            return (
              <div key={i} className="flex justify-between border-b py-2 px-2">
                <div className="flex flex-col">
                  <span className="text-secondary font-semibold">
                    {res.name}
                  </span>
                  <span className="text-secondary">
                    {" "}
                    {res.variantKey} : {res.itemName}
                  </span>
                  <span className="text-secondary">
                    {res?.quantity} x {res?.finalPrice}
                  </span>
                </div>
                <div className="flex flex-col mt-4">
                  {formatCurrency(res?.totalFinalPrice)}
                </div>
              </div>
            );
          })}
          {/* <div className="flex justify-between border-b py-2 px-2">
            <span className="text-secondary font-semibold">Biaya Admin</span>
            <span>Rp 4.000</span>
          </div> */}
          <div className="flex justify-between bg-gray-50 py-2 px-2 text-[#26A69A] font-semibold">
            <span>Total</span>
            <span>{formatCurrency(data?.totalProductPrice)}</span>
          </div>
        </div>
        <div className="w-full p-4">
          <p className="font-semibold text-lg">Catatan</p>
          <textarea
            className="w-full border rounded-xl p-3 outline-none"
            placeholder="Tidak Ada Catatan"
            value={data?.customer?.note}
            disabled
          ></textarea>
        </div>
        <div className="w-full p-4 absolute bottom-0">
          <button
            onClick={async () => {
              dispath(setLoading(true));
              await API_COMMERCE.configPayment(param.merchantId)
                .then((res) => {
                  if (res.data.data.paymentConfig === "PAY_BEFORE") {
                    dispath(setLoading(false));
                    setChosePayment(true);
                  } else {
                    navigate("order");
                    removeState("cart_data");
                  }
                })
                .catch((err) => {
                  dispath(setLoading(false));
                });
            }}
            className="w-full rounded-xl py-2.5 text-white font-semibold bg-[#26A69A]"
          >
            Selanjutnya
          </button>
        </div>
      </div>
      <Transition appear show={chosePayment} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-montserrat"
          onClose={() => setChosePayment(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-medium leading-6 text-gray-900"
                  >
                    Silahkan Pilih Metode Pembayaran
                  </Dialog.Title>
                  <div className="mt-2">
                    <input
                      className="relative float-left mr-1 mt-0.5 h-5 w-5 rounded-full border-2 border-solid border-neutral-300"
                      type="radio"
                      name="pembayaran"
                      onChange={() => setPaymentMethode("cash")}
                    />
                    <label
                      className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer mb-2"
                      htmlFor="radioDefault01"
                    >
                      Bayar Ditempat
                    </label>
                    <br />
                    <input
                      className="relative float-left mr-1 mt-0.5 h-5 w-5 rounded-full border-2 border-solid border-neutral-300"
                      type="radio"
                      name="pembayaran"
                      onChange={() => setPaymentMethode("online")}
                    />
                    <label
                      className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer mb-4"
                      htmlFor="radioDefault01"
                    >
                      Bayar Online
                    </label>
                  </div>
                  <div className="mt-4 flex gap-3 w-full">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-gray-400"
                      onClick={() => setChosePayment(false)}
                    >
                      Batal
                    </button>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent bg-[#26A69A] px-4 py-2 text-sm font-medium text-white"
                      onClick={() => {
                        handleCheckout();
                      }}
                    >
                      Konfirmasi
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default OrderSummaryMobile;
