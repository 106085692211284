import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InactivateCardSecurityCodeWeb from "./inactivateCardSecurityCodeWeb";
import InactivateCardSecurityCodeMobile from "./inactivateCardSecurityCodeMobile";
import { useNavigate, useParams } from "react-router-dom";
import { setLoading } from "../../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
import * as API_VIRTUAL_CARD from "../../../../core/service/api_virtualCard";
import * as API_AUTH from "../../../../core/service/api_auth";
import Company from "../../../../core/models/company";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const InactivateCardSecurityCode = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [securityCode, setSecurityCode] = React.useState("");
  const [createSuccessFully, setCreateSuccessFully] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const hideModal = () => setShowModal(false);

  const confirm = () => {
    dispatch(setLoading(true));
    let data = {
      cardId: params.id,
      securityCode,
    };

    API_AUTH.reauthenticateCardSecurityCode(data)
      .then((res) => {
        dispatch(setLoading(false));
        API_VIRTUAL_CARD.inactiveCardSecurityCode(
          params.id,
          res.data.data.securityCodeToken
        )
          .then((res2) => {
            setCreateSuccessFully(true);
            setShowModal(false);
          })
          .catch((err2) => {
            setShowModal(false);
          });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setShowModal(false);
      });
  };

  const handleOnClick = async () => {
    if (createSuccessFully) {
      navigate(`/${company?.initial}/virtual-card/list`, { replace: true });
    } else {
      if (securityCode === "") {
        toast.error(t('card_security_code.required_pin'));
      } else {
        confirm();
      }
    }
  };

  return (
    <>
      <BrowserView>
        <InactivateCardSecurityCodeWeb
          confirm={() => confirm()}
          handleOnClick={() => handleOnClick()}
          hideModal={() => hideModal()}
          setSecurityCode={(value: any) => setSecurityCode(value)}
          company={company}
          securityCode={securityCode}
          createSuccessFully={createSuccessFully}
          showModal={showModal}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <InactivateCardSecurityCodeMobile
          confirm={() => confirm()}
          handleOnClick={() => handleOnClick()}
          hideModal={() => hideModal()}
          setSecurityCode={(value: any) => setSecurityCode(value)}
          company={company}
          securityCode={securityCode}
          createSuccessFully={createSuccessFully}
          showModal={showModal}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default InactivateCardSecurityCode;
