import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InputNumberMobile from "./inputNumberMobile";
import InputNumberWeb from "./inputNumberWeb";

const InputNumber = () => {
  return (
    <>
      <BrowserView>
        <InputNumberWeb></InputNumberWeb>
      </BrowserView>
      <MobileView>
        <InputNumberMobile></InputNumberMobile>
      </MobileView>
    </>
  );
};

export default InputNumber;
