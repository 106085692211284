import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import success from "../../assets/images/icon_features/success.svg";
import failed from "../../assets/images/icon_features/failed.svg";
// import bg from "../../assets/images/icon_features/bg_detail.svg";
import moment from "moment";
import BottomLogo from "../_common/bottomLogo";
import User from "../../core/models/user";
import { useAppSelector } from "../../core/feature/hooks";
import arrow from "../../assets/images/arrow-left-ic.svg";
// import Company from "../../core/models/company";
import { ConfigColor } from "../../config/global";
import "moment/locale/id";
import Success from "../../assets/images/iconSuccess.svg";
// import Failed from "../../assets/images/security-fail.png";
import { formatCurrency } from "../../config/global";
import { useTranslation } from "react-i18next";
import Company from "../../core/models/company";

const PayGateResultWeb = () => {
  const [result, setResult] = useState<any>({});
  const [resultEror, setResultEror] = useState<any>({});
  const color = ConfigColor();
  const parse = localStorage.getItem("result");
  const parseEror = localStorage.getItem("resultEror");
  const user: User = useAppSelector((state) => state.user.user);
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backButton = () => {
    navigate(-1);
    localStorage.removeItem("result");
  };
  useEffect(() => {
    setResult(JSON?.parse(parse as any));
    setResultEror(JSON?.parse(parseEror as any));
  }, [parse, parseEror]);

  return (
    <div
      className="w-full h-full block min-h-screen bg-mobile"
      style={{
        backgroundColor: `${color.color1}`,
      }}
    >
      <div
        className=" w-full bg-fixed p-4 flex"
        style={{
          backgroundColor: `${color.color1}`,
        }}
      >
        <div
          onClick={() => backButton()}
          className="flex bg-white rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>
        <h1 className="font-montserrat font-bold text-base m-auto relative text-center -left-6 text-white">
          {result?.type === "PAYGATE_PAYMENT_SUCCESS" ||
          result?.data?.data?.status === "VERIFIED"
            ? t("accessibility.success")
            : t("accessibility.failed")}
        </h1>
      </div>
      <div className="w-full font-montserrat">
        {result?.type === "PAYGATE_PAYMENT_SUCCESS" ||
        result?.data?.data?.status === "VERIFIED" ? (
          <div
            className="grow w-full h-full mx-auto px-4 pb-4 md:pt-10"
            style={{
              backgroundColor: `${color.color1}`,
            }}
          >
            <div className="bg-white md:w-[50%] w-full mx-auto rounded-[14px] p-8 mb-4 mt-4 relative">
              <div className="flex flex-col justify-center items-center">
                <img src={Success} alt="" className="w-[4.5rem] h-[4.5rem]" />
                <span className=" md:text-lg text-base font-semibold text-[#26A69A]">
                  {t("accessibility.access_succes")}
                </span>
              </div>

              <div className=" items-center border-b-[1px] border-b-[#E5E5E5] border-dotted mb-2 pb-2 relative mt-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-start">
                    <h6 className="md:text-sm text-xs text-newColor m-0">
                      {t("accessibility.in_date")}
                    </h6>
                    <span className="text-customBlack font-semibold">
                      {moment
                        .utc(result?.data?.loginTime)
                        .local()
                        .format("DD-MM-YYYY, HH:mm:ss")}
                    </span>
                  </div>
                  <div className="text-start">
                    <h6 className="md:text-sm text-xs text-newColor m-0">
                      {t("accessibility.in_time")}
                    </h6>
                    <span className="text-customBlack font-semibold">
                      {moment
                        .utc(result?.data?.loginTime)
                        .local()
                        .format("HH:mm:ss")}
                    </span>
                  </div>
                  <div className="text-start">
                    <h6 className="md:text-sm text-xs text-newColor m-0">
                      {t("accessibility.paygate_vehicle")}
                    </h6>
                    <span className="text-customBlack font-semibold">
                      {result?.data?.activityName}
                    </span>
                  </div>
                </div>

                <div className="absolute flex justify-between w-full">
                  <div
                    className="w-7 h-7 rounded-full -ml-12"
                    style={{
                      backgroundColor: `${color.color1}`,
                    }}
                  ></div>
                  <div
                    className="w-7 h-7  rounded-full -mr-12"
                    style={{
                      backgroundColor: `${color.color1}`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="">
                <h6 className="md:text-lg text-base font-semibold m-0 mb-2">
                  {t("accessibility.payment_detail")}
                </h6>

                <div className="flex justify-between items-center gap-2">
                  <div className="text-start">
                    <h6 className="text-newColor md:text-sm text-xs m-0">
                      {t("accessibility.nominal")}
                    </h6>
                    <span className="md:text-sm text-xs text-customBlack font-semibold">
                      {formatCurrency(result?.data?.priceGate)}
                    </span>
                  </div>
                  <div
                    className={`${
                      result?.status_code === "200"
                        ? "bg-green"
                        : "bg-[#DC3545]"
                    } rounded-[4px] py-1 px-2 md:text-sm text-xs text-white font-medium`}
                  >
                    {result?.status_code === "200" ? (
                      <span>{t("accessibility.paid_off")}</span>
                    ) : (
                      <span>{t("accessibility.not_yet_paid")}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center p-4">
            <img src={failed} alt="" className="flex mx-auto" />
            <div className="text-center mt-4 font-semibold text-xl text-red">
              {t("accessibility.access_failed")}
            </div>
            <span>
              {resultEror?.type === "QR_NOT_FOUND"
                ? t("accessibility.qr_not_found")
                : resultEror?.type === "ATTENDANCE_RULE_NOT_PASSED"
                ? t("accessibility.required_absence")
                : resultEror?.type === "ACTIVITY_NOT_FOUND"
                ? t("accessibility.activity_absence_not_found")
                : resultEror?.type === "QR_ALREADY_USED"
                ? t("accessibility.qr_used")
                : resultEror?.type === "BALANCE_NOT_ENOUGH"
                ? resultEror.message
                : t("accessibility.error")}
              , {t("accessibility.access_again")}
            </span>
          </div>
        )}
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </div>
  );
};

export default PayGateResultWeb;
