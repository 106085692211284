import { toast } from "react-hot-toast";
import { BottomSheet } from "react-spring-bottom-sheet";
import arrow from "../../assets/images/arrow_right.svg";
import Logout from "../../assets/images/cta/log-out.svg";
import Password from "../../assets/images/icon/auto login.svg";
import Unlock from "../../assets/images/icon/change pass.svg";
import Users from "../../assets/images/icon/changeakun.svg";
import userIcon from "../../assets/images/icon/editprofil.svg";
import ShieldDone from "../../assets/images/icon/pin.svg";
import UserSupport from "../../assets/images/icon/support.svg";
import Work from "../../assets/images/icon/termofservice.svg";
import Voice from "../../assets/images/icon/ubah bahasa.svg";
import ToastConfig from "../../config/Toast";
import { formatCurrency, goToUrl } from "../../config/global";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import CustomTooltip from "../_common/tooltip";
import BottomNavigator from "../_common/bottomNavigator";
import { useNavigate } from "react-router-dom";
import iconSearch from "../../assets/images/icon_rent/search-normal.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Dummy from "./dummy";
import _ from "lodash";
import location from "../../assets/images/icon_rent/location.svg";
import Round from "../../assets/images/icon_rent/round.svg";
import { useState } from "react";

const RentWeb = (props: any) => {
  const [enabled, setEnabled] = useState(false);
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();

  const filteredDummy = props?.Dummy?.filter(
    (el: any) =>
      el.name.toLowerCase().includes(filter?.toLowerCase()) ||
      el.address.toLowerCase().includes(filter?.toLowerCase())
  );
  return (
    <>
      <section className="h-full min-h-screen font-montserrat bg-mobile pb-12">
        <Header navbarText="Menu Sewa"></Header>
        <div className="px-6 lg:w-[70%] md:w-[80%] mx-auto">
          <span className="font-[700] text-2xl block">Let’s find your</span>
          <span className="font-[700] text-2xl text-[#3867E0] block">
            Sport Center.
          </span>
          {/* <div
            className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-300 ease-in-out ${
              enabled ? "bg-[#3867E0] bg-opacity-60" : "bg-gray-300"
            }`}
            onClick={() => setEnabled(!enabled)}
          >
            <span
              className={`inline-block w-4 h-4 transform rounded-full transition-transform duration-300 ease-in-out ${
                enabled ? "translate-x-6 bg-[#3867E0]" : "translate-x-1 bg-white "
              }`}
            />
          </div> */}
          <div className="relative">
            <img src={iconSearch} alt="" className="absolute top-10 left-4" />
            <input
              type="text"
              className="bg-[#F5F5F5] focus:outline-none focus:outline-[#3867E0] rounded-[4px] w-full py-3 mt-4 pl-12 text-base pr-2"
              placeholder="Search any city, area, landmark"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <span className="text-lg font-[700] ">Our Recommendations</span>
            <div className="mt-2">
              {_.map(filteredDummy, (el: any, index: any) => (
                <div
                  className="space-y-2"
                  onClick={() => navigate(`${el.id}`, { state: { data: el } })}
                >
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={false}
                    modules={[Pagination, Navigation]}
                    className="mySwiper rounded-[10px] bg-white"
                  >
                    {el.image?.map((item: any, index: any) => (
                      <SwiperSlide key={index}>
                        <SwiperSlide key={index}>
                          <div
                            className=" h-[290px] bg-center bg-no-repeat bg-cover w-full"
                            style={{ backgroundImage: `url(${item})` }}
                          />
                        </SwiperSlide>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="flex justify-between">
                    <span className="font-[500] ">{el.name}</span>
                    <div className="text-[#3867E0] bg-[#3867E0] bg-opacity-10 px-2 rounded-[4px]">
                      venue
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <img src={location} alt="" />
                    <span className="text-[#A8A8A8]">{el.address}</span>
                  </div>
                  <div className="flex mt-2 gap-2">
                    {el.tags.map((x: any, index: any) => {
                      return (
                        <div className="flex items-center gap-2">
                          <span className="text-[#A8A8A8]">{x}</span>
                          {index + 1 != el.tags.length && (
                            <div className="h-[4px] bg-[#A8A8A8] w-[4px] rounded-full"></div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="text-base font-[600] text-[#3867E0]">
                    {formatCurrency(el.price)}/jam
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RentWeb;
