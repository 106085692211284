import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
  getEntityPy,
} from "../../config/global";
const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getAllNews = (companyId, data) => {
  console.log("news data", data);
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}info/member/news/get_news/${companyId}?${createQueryUrl(
          getEntityPy(createArrayEntityFromObject(data), data),
          "&"
        )}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllInfo = (companyId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}info/member/popup/get_popup/${companyId}?${createQueryUrl(
          getEntity(createArrayEntityFromObject(data), data),
          "&"
        )}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
