import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPulsaDataWeb from "./ppobPulsaDataWeb";
import PPOBPulsaDataMobile from "./ppobPulsaDataMobile";

const PPOBPulsaData = () => {
  return (
    <>
      <BrowserView>
        <PPOBPulsaDataWeb></PPOBPulsaDataWeb>
      </BrowserView>
      <MobileView>
        <PPOBPulsaDataMobile></PPOBPulsaDataMobile>
      </MobileView>
    </>
  );
};

export default PPOBPulsaData;
