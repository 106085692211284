import Header from "../../_common/headerComponent";
import { SummaryCard } from "../../_common/summaryCard";
import Reauthenticate from "../../_common/reauthenticate";
import ConfirmationModal from "../../_common/confirmationModal";

const VirtualCardSummaryWeb = (props: any) => {
  return (
    <section className="h-full min-h-screen flex flex-col justify-between font-montserrat bg-mobile">
      <Header></Header>
      <Reauthenticate
        isNewDesign={true}
        show={props.showModal}
        onHide={() => props.setShowModal(false)}
        inquiryId={props.inquiryId}
        redirect="payment-summary"
        width={60}
        height={63}
        margin="0 0.25rem"
        fontSize="2rem"
      ></Reauthenticate>
      {/* 
      <ConfirmationModal
        showModal={props.showConfirmationModal}
        hideModal={() => props.setShowConfirmationModal(false)}
        confirm={props.handleOnClick}
      /> */}

      <>
        <div className="container flex md:flex-row flex-col-reverse justify-center gap-4 md:mt-[64px] mt-[0px] md:px-4 px-0 pb-5">
          <div className="md:w-1/2 w-full">
            <h3 className="md:text-base text-sm font-medium mb-4 md:px-0 px-4">
              {props.t('virtual_card.description_summary')}
            </h3>
            <SummaryCard
              data={props.dataPost}
              headerProperty={props.t('virtual_card.total_payment')}
              headerValue={props.cardPrice}
              isSuccess={false}
            />
            <div className="flex gap-3 flex-row-reverse mt-8 md:mx-0 mx-4">
              <button
                type="button"
                style={{
                  backgroundColor: `${props.company?.color?.color1 !== undefined
                    ? props.company?.color?.color1
                    : "#26A69A"
                    }`,
                }}
                onClick={() => props.handleOnClick()}
                className="bg-green text-white font-medium rounded-lg w-100 md:p-3 p-[12px]"
              >
                {props.t('virtual_card.next')}
              </button>
            </div>
          </div>
        </div>
      </>
    </section>
  );
};

export default VirtualCardSummaryWeb;
