import Header from "../../_common/headerComponent";
import CurrencyInput from "react-currency-input-field";
import Company from "../../../core/models/company";
import userDefault from "../../../assets/images/card_avatar.png";
import { useAppSelector } from "../../../core/feature/hooks";
import { useTranslation } from "react-i18next";

const WithdrawCardBalanceWeb = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>
      <div className="md:w-1/2 w-full mx-auto mb-20">
        <div className="mx-auto flex flex-col items-center md:mt-[64px] mt-[0px] gap-2 md:px-[0px] px-8">
          <div className="md:w-1/2 w-full">
            <div className="flex flex-col items-center mx-auto">
              {/* <div className="relative mx-auto rounded-xl shadow-xl mb-3">
                <img src={props.background} alt="" className="rounded-xl" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.template.replace("src=-", `src=${userDefault}`),
                  }}
                ></div>
              </div> */}
              <div className="w-full h-full ">
                <iframe
                  className={
                    props.isPortrait
                      ? `w-60 mx-auto overflow-hidden rounded-2xl h-[400px] bg-cover shadow-xl`
                      : `w-full overflow-hidden rounded-2xl h-56 bg-cover shadow-xl`
                  }
                  style={{ backgroundImage: `url(${props.background})` }}
                  scrolling="no"
                  srcDoc={props.template.replace("src=-", `src=${userDefault}`)}
                ></iframe>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-sm p-10 mt-8">
            <div className="w-full px-4 md:pb-28">
              <p className="md:text-base text-sm text-customBlack font-normal mb-3">
                {t('withdraw_funds.subtitle')}
              </p>
              <div className="mt-12">
                <label className="md:text-xl text-lg text-center font-normal text-newColor w-full mb-6">
                  {t('withdraw_funds.amount_disbursement')}
                </label>
                <CurrencyInput
                  className="border-b-[1px] border-b-[#E5E5E5] text-[#408D86] border-b-solid w-full text-center font-bold md:text-3xl text-xl focus-visible:outline-none"
                  placeholder={t('withdraw_funds.insertNominal')}
                  defaultValue={1000}
                  decimalsLimit={2}
                  value={props.amount}
                  prefix={"Rp"}
                  onValueChange={(value, name) => {
                    props.setAmount(value || "");
                    props.setErrorAmount("");
                  }}
                  allowDecimals={false}
                  allowNegativeValue={false}
                />
                <p className="text-start md:text-base text-sm text-red pl-1 mb-0">
                  {props.errorAmount}
                </p>
              </div>
            </div>
            <div className="mt-10">
              <p className="text-lg font-normal text-newColor mb-[8px] text-center">
                {t('withdraw_funds.purpose_balance_withdrawal')}
              </p>
              <input
                disabled
                defaultValue={t('withdraw_funds.main_balance')}
                readOnly
                className="bg-[#E5E5E5] text-center w-full p-[12px] text-newColor font-bold text-2xl rounded-xl"
              />
            </div>
            <div
              className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full md:px-0 px-[1.5rem]"
              style={{ transform: "translate(-50%, -50%)" }}
            >
              <button
                type="button"
                style={{
                  backgroundColor: `${company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A"
                    }`,
                }}
                className="bg-green text-white text-base font-medium rounded-[10px] w-full py-[10px] mt-3"
                onClick={() => props.handleOnClick()}
              >
                {t('withdraw_funds.next')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WithdrawCardBalanceWeb;
