import { BrowserView, MobileView } from "react-device-detect";
import AccessResultWeb from "./acceessResultWeb";
import AccessResultMobile from "./acceessResultMobile";

const AccessResult = () => {
  return (
    <div>
      <BrowserView>
        <AccessResultWeb/>
      </BrowserView>
      <MobileView>
        <AccessResultMobile/>
      </MobileView>
    </div>
  );
};

export default AccessResult;
