import Header from "../../_common/headerComponent";
import Select from "react-select";
import { loadState } from "../../../core/feature/browser-storage/browser-storage";
import { formatCurrency } from "../../../config/global";
import DataNotFound from "../../_common/dataNotFound";
import NoImage from "../../../assets/images/empty.jpg";

const CartFnbWeb = (props: any) => {
  return (
    <div className="min-h-screen bg-white font-montserrat relative pb-32">
      <div className="bg-mobile">
        <Header navbarText="Keranjang" />
      </div>
      <div className="bg-white w-full p-4 flex flex-col gap-2 container px-5">
        {loadState("cart_data") === undefined ? (
          <div>
            <DataNotFound />
            <p className="text-xl text-center">Keranjang anda masih kosong</p>
          </div>
        ) : (
          <div className="bg-white w-full flex flex-col gap-2 ">
            {props.data?.map((res: any, index: any) => {
              return (
                <div
                  key={index}
                  className="w-full flex border-b border-gray-400 pb-2"
                >
                  <img
                    src={
                      res?.item?.imageUrl[0] === "" ||
                      res?.item?.imageUrl.length === 0
                        ? NoImage
                        : res?.item?.imageUrl[0]
                    }
                    alt=""
                    className="max-w-[88px] max-h-[88px] min-w-[88px] min-h-[88px] rounded-xl mr-3"
                  />
                  <div className="flex flex-col w-full">
                    <span className="font-semibold text-[16px]">
                      {res.item.productName}
                    </span>
                    <span className="text-gray-600">
                      {res.item.variantKey} : {res.item.variantValue}
                    </span>
                    <div className="flex justify-between mt-3">
                      <span className="text-green font-semibold text-[20px]">
                        {formatCurrency(res.item.finalPrice * res.quantity)}
                      </span>
                      <div className="flex gap-1">
                        <button
                          onClick={() => props.editQuantity("min", index)}
                          className="w-7 h-7 bg-[#F5F5F5] rounded-lg font-semibold"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="w-7 h-7 text-center font-semibold"
                          value={`${res.quantity}`}
                        />
                        <button
                          onClick={() => props.editQuantity("add", index)}
                          className="w-7 h-7 bg-[#F5F5F5] rounded-lg font-semibold"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="flex flex-col gap-3">
          <div>
            <span className="font-semibold text-[16px]">Pilih</span>
            <Select
              options={props.option}
              defaultValue={{ value: "DINE_IN", label: "Dine In" }}
              value={props.selected}
              onChange={(e) => {
                props.setSelected(e);
                props.setFnbType(e.value);
              }}
              className="mt-2 rounded-lg"
            />
          </div>
          <div className="flex flex-col">
            <span className="font-semibold text-[16px]">
              Informasi Data Diri <span className="text-red">*</span>
            </span>
            <span className="text-[12px] text-red mb-3">
              <span className="text-red font-semibold">*</span> Required
            </span>
            <span>
              Nama <span className="text-red font-semibold">*</span>
            </span>
            <input
              type="text"
              className="w-full py-2 px-2 border rounded-lg outline-none mb-3"
              onChange={(e) => {
                props.setNama(e.target.value);
              }}
            />
            {props.fnbType === "DINE_IN" && (
              <div>
                <span>
                  Nomer Meja <span className="text-red font-semibold">*</span>
                </span>
                <input
                  type="text"
                  className="w-full py-2 px-2 border rounded-lg outline-none mb-3"
                  onChange={(e) => {
                    props.setTableNumber(e.target.value);
                  }}
                />
              </div>
            )}
            <span>Catatan</span>
            <textarea
              style={{ resize: "vertical" }}
              className="w-full py-2 px-2 border rounded-lg outline-none mb-3"
              onChange={(e) => {
                props.setNote(e.target.value);
              }}
            />
            <span>
              No Whatsapp{" "}
              <span className="text-[12px] italic text-gray-500">
                (Opsional)
              </span>
            </span>
            <input
              type="number"
              className="w-full py-2 px-2 border rounded-lg outline-none mb-3"
              onChange={(e) => {
                props.setNoWa(e.target.value);
              }}
            />
            <span>
              Email{" "}
              <span className="text-[12px] italic text-gray-500">
                (Opsional)
              </span>
            </span>
            <input
              type="text"
              className="w-full py-2 px-2 border rounded-lg outline-none mb-3"
              onChange={(e) => {
                props.setEmail(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-white shadow-lg p-4 bg-fixed absolute bottom-0">
        <div className="flex justify-between font-semibold mb-2">
          <span>Jumlah</span>
          <span>{formatCurrency(props.totalValue)}</span>
        </div>
        <button
          onClick={() => props.createOrder()}
          className="py-2 text-white font-semibold rounded-lg bg-green w-full"
        >
          Pesan Sekarang
        </button>
      </div>
    </div>
  );
};

export default CartFnbWeb;
