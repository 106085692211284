import { BrowserView, MobileView } from "react-device-detect";
import FeatureScanBarcodeAccessMobile from "./featureScanBarcodeAccessMobile";
import FeatureScanBarcodeAccessWeb from "./featureScanBarcodeAccessWeb";

const FeatureScanBarcodeAccess = () => {
  return (
    <div>
      <BrowserView>
        <FeatureScanBarcodeAccessWeb />
      </BrowserView>
      <MobileView>
        <FeatureScanBarcodeAccessMobile />
      </MobileView>
    </div>
  );
};

export default FeatureScanBarcodeAccess;
