import ImageIcon from "../assets/images/Iconly/Light-Outline/Image.svg";
import ProfileIcon from "../assets/images/Iconly/Bold/Profile.svg";
import moment from "moment";
import _ from "lodash";
import { useAppSelector } from "../core/feature/hooks";
import { decryptedValue } from "./Encription";

export const createArrayEntityFromObject = (obj) => {
  return Object.keys(obj);
};

export const formatDateTimeUTC = (date) => {
  return moment.utc(date).local().format("DD MMM YYYY, HH:mm:ss");
};

export const convertToUTC = (date) => {
  return moment.utc(date).format();
};

export const getEntity = (keys, obj) => {
  return _.map(keys, (k) => {
    if (typeof obj[k] == "boolean") {
      // console.log("obj[k]", obj[k], "[k]", [k]);
      return obj[k];
      // return { key: [k], value: obj[k] };
    } else {
      if (obj[k]) {
        return { key: [k], value: obj[k] };
      }
    }
  }).filter((el) => el !== undefined);
};

export const getEntityPy = (keys, obj) => {
  return _.map(keys, (k) => {
    if (typeof obj[k] === "boolean" || typeof obj[k] === "number") {
      // console.log("obj[k]", obj[k], "[k]", [k]);
      // return obj[k];
      return { key: [k], value: obj[k] };
    } else {
      if (obj[k]) return { key: [k], value: obj[k] };
    }
  }).filter((el) => el !== undefined);
};

export const createQueryUrl = (arr, separator) => {
  let query = "";
  _.map(arr, (el, n) => {
    if (arr.length - 1 > n)
      return (query = `${query}${el.key}=${el.value}${separator}`);
    if (typeof el.value === "boolean" || typeof el.value === "number") {
      // console.log("el", el);
      query = `${query}${el.key}=${el.value}`;
    } else if (el.value) {
      query = `${query}${el.key}=${el.value}`;
    }
  });
  // console.log(query);
  return query;
};

export const findArray = (arr, fromKey, value, returnKey) => {
  if (arr.find((el) => el[fromKey].includes(value))) {
    return arr.find((el) => el[fromKey].includes(value))[returnKey];
  } else {
    return null;
  }
};

export const formatCurrency = (value) => {
  if (value === "" || value === null || value === undefined) return "Rp 0";
  return new Intl.NumberFormat("in-IN", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }).format(value);
};

export const formatHour = (value) => {
  return moment(value, "hh:mm").format("HH:mm");
};

export const onlyNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return evt.preventDefault();
  } else {
    return true;
  }
};

export const onlyAlphaNum = (evt, type) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;

  if (
    charCode > 31 &&
    !(
      (
        (charCode >= 65 && charCode <= 90) || // A-Z
        (charCode >= 97 && charCode <= 122) || // a-z
        (charCode >= 48 && charCode <= 57) ||
        charCode === 45 ||
        charCode === 46 ||
        charCode === 95
      ) // 0-9
    )
  ) {
    return evt.preventDefault();
  } else {
    return true;
  }
};

export const replaceEmailWithStar = (s) => {
  var i = s.indexOf("@");
  var startIndex = (i * 0.2) | 0;
  var endIndex = (i * 0.9) | 0;
  return (
    s.slice(0, startIndex) +
    s.slice(startIndex, endIndex).replace(/./g, "*") +
    s.slice(endIndex)
  );
};

export const isNumeric = (number) => {
  if (+number === +number) {
    // if is a number
    return true;
  }

  return false;
};

export const backTo = (url, query = {}) => {
  window.location.replace(url);
};

export const checkType = (data) => {
  if (data) {
    if (parseInt(data) !== isNaN) {
      return parseInt(data);
    }
    return data;
  }
};

export const createNewArray = (array, keys, nested = {}) => {
  let returnArr = [];
  _.map(array, (arr) => {
    let obj = {};
    _.map(keys, (key) => {
      obj[key.to] = arr[key.from];
    });
    if (nested && arr[nested.from]) {
      obj[nested.to] = createNewArray(arr[nested.from], keys, nested);
    }
    returnArr.push(obj);
  });
  return returnArr;
};

// export const downloadPdf = (transaction_type, id, query = {}) => {
//   console.log(transaction_type);
//   if (transaction_type === "marketplace_transactions") {
//     API_COMMERCE.downloadPDFMarketplace(id)
//       .then((response) => {
//         let name = "MARKETPLACE.pdf"; //response.data.data.url.split();
//         let url = response.data.url;
//         var link = document.createElement("a");
//         console.log(response);
//         link.href = url;
//         link.target = "_blank";
//         link.download = name;
//         link.setAttribute("download", `${name}`); //or any other extension
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch((err) => {});
//   } else if (transaction_type === "digital_product_transactions") {
//     API_DIGIPROD.downloadPDF(id)
//       .then((response) => {
//         console.log(response);
//         let name = response.data.name;
//         let url = response.data.url;
//         var link = document.createElement("a");
//         link.href = url;
//         link.target = "_blank";
//         link.download = name;
//         console.log(name);
//         link.setAttribute("download", `${name}`); //or any other extension
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch((err) => {});
//   } else if (transaction_type === "pencairan_dana_admin") {
//     console.log(id);
//     API_TRANSACTION.downloadPDFPencairanDana(id)
//       .then((response) => {
//         console.log(response);
//         let name = "PencairanDanaAdmin.pdf"; //response.data.data.url.split();
//         let url = response.data.data.url;
//         var link = document.createElement("a");
//         console.log(response);
//         link.href = url;
//         link.target = "_blank";
//         link.download = name;
//         link.setAttribute("download", `${name}`); //or any other extension
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch((err) => {});
//   } else {
//     API_TRANSACTION.downloadPDF(transaction_type, id, query)
//       .then((response) => {
//         console.log(response);
//         let name = response.data.data.url.split();
//         var link = document.createElement("a");
//         link.download = name[name.length - 1];
//         link.href = response.data.data.url;
//         link.click();
//       })
//       .catch((err) => {});
//   }
// };
export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return moment(date).format("MMMM");
};

export const subStringText = (message, start, end, a) => {
  if (message?.length >= a) {
    return message.substring(start, end) + "...";
  } else {
    return message;
  }
};

export const camelCase = (text, separator = " ") => {
  return text
    .toLowerCase()
    .split(separator)
    .map((el, ind) => {
      if (el === "dan") el = "&";
      if (el) {
        return el[0].toUpperCase() + el.substring(1, el.length);
      }
      return "";
    })
    .join(" ");
};

export const replaceText = (text, from, to) => {
  return text.replaceAll(from, to);
};

export const humanizedText = (text) => {
  return text.replaceAll("-", " ").replaceAll("_", " ");
};

export const formatDate = (text, format) => {
  return moment(new Date(text)).format(format);
};

export const imageOnError = (event, type = "") => {
  event.currentTarget.src = ImageIcon;
  if (type === "user") event.currentTarget.src = ProfileIcon;
  event.currentTarget.className = `${event.currentTarget.className} error`;
};

export const blurAccountNumber = (accountNumber, blurCount = 4) => {
  return accountNumber
    ? accountNumber
        .slice(accountNumber.length - blurCount)
        .padStart(accountNumber.length, "*")
    : "-";
};

export const clientPaging = (data, page, size) => {
  return new Promise(function (resolve, reject) {
    console.log(data, page, size);
    let totalElement = data.length;
    let totalPage = Math.ceil(data.length / size);
    try {
      resolve({
        data: data.slice(page * size - size, page * size),
        paging: {
          page,
          size,
          totalElement,
          totalPage,
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const bearer = () => {
  let ciperTextCompany = decryptedValue(JSON.parse(localStorage.getItem("company_redux")))
  let company = ciperTextCompany;
  let ciperTextToken =decryptedValue(JSON.parse(localStorage.getItem("token_redux")))
  let newToken = _.find(ciperTextToken, {
    cid: company.initial,
  });
  return newToken.token;
};

export const goToUrl = (
  url,
  isProfile = false,
  isExternal = true,
  isNewTab = false
) => {
  let newUrl = url;
  if (isProfile)
    if (!window.location.host.includes("dev"))
      newUrl = url.replaceAll("dev.", "");
  console.log("newUrl", newUrl);
  if (isExternal) {
    if (isNewTab) return window.open(newUrl, "_blank");
    window.location.href = newUrl;
  }
};

export const getDonationTransaction = (data, sortBy, dir, showNumber) => {
  console.log(data, sortBy, dir, showNumber);
  let returnArray = [];
  // returnArray = _.orderBy(data, sortBy, dir);

  returnArray = _.slice(_.orderBy(data, sortBy, dir), 0, showNumber);
  console.log(returnArray);
  return returnArray;
};

export const checkRegex = (type, value, strength) => {
  var strongRegex = "";
  switch (type) {
    case "email":
      strongRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      break;
    case "password":
      if (strength === "strong") {
        strongRegex =
          /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,30}$/;
      } else if (strength === "medium") {
        strongRegex = /^(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{4,30}$/
      }else{
        strongRegex = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?/~`\-\\]{1,30}$/;
      }
      break;
    case "noId":
      strongRegex = /^[a-zA-Z0-9\-_]+$/;
      break;
    case "alphanumeric":
      strongRegex = /^[a-zA-Z0-9\-_]+$/;
      break;
    case "decimal":
      strongRegex = /^\d*\.?\d*$/;
      break;
    case "URL":
      strongRegex =
        /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
      break;
    default:
      break;
  }
  if (strongRegex.test(value)) {
    return true;
  }
  return false;
};

export const AlertPasswordStrength = (strength) => {
  let alert = "";
  switch (strength) {
    case "strong":
      alert =
        "Password harus terdiri dari 6-30 karakter, harus mengandung minimal 1 huruf besar, 1 huruf kecil, 1 angka, dan 1 karakter spesial";
      break;
    case "medium":
      alert = "Password harus terdiri dari 4-30 karakter, harus mengandung minimal 1 huruf dan 1 angka";
      break;
    case "low":
      alert = "Password harus terdiri dari 1-30 karakter harus mengandung 1 angka atau 1 huruf";
      break;

    default:
      break;
  }

  return alert;
};

export const checkErrorResponse = (data) => {
  let msg = "";
  if (typeof data === "object") {
    if (data.detail)
      msg = data.detail.message
        ? checkErrorResponse(data.detail.message)
        : data.detail.type;
  } else if (typeof data === "string") {
    msg = data;
  }

  return msg;
};

export const checkLoginStatus = (data) => {
  console.log(data.headers?.Authorization ? true : false);
  return data.headers?.Authorization ? true : false;
  // let company = JSON.parse(localStorage.getItem("company_redux"));
  // let newToken = _.find(JSON.parse(localStorage.getItem("token_redux")), {
  //   cid: company.initial,
  // });
  // return newToken.token;
};

export const formatDatePickerUTC = (date, isEnd = false) => {
  let newDate = new Date(date);
  if (isEnd) {
    newDate = new Date(date).setTime(
      new Date(date).getTime() + (23 * 60 * 60 + 59 * 60 + 59) * 1000
    );
  }
  return moment(newDate).utc().format("YYYY-MM-DDTHH:mm:ss");
};

export const censorWord = (str, end = 1) => {
  if (!str) return "";
  if (str.length > end + 1)
    return str[0] + "*".repeat(str.length - (1 + end)) + str.slice(-end);
  return str;
};

export const censorEmail = (email) => {
  if (!email) return "";
  var arr = email.split("@");
  if (arr.length === 2) return censorWord(arr[0], 3) + "@" + censorWord(arr[1]);
  return censorWord(arr[0], 3);
};

export const ConfigColor = () => {
  const company = useAppSelector((state) => state.company.company);

  var color1 =
    company?.color?.color1 !== undefined &&
    company?.color?.color1 !== "" &&
    company?.color?.color1
      ? company?.color?.color1
      : "#006F54";
  var color2 =
    company?.color?.color2 !== undefined && company?.color?.color2 !== ""
      ? company?.color?.color2 && company?.color?.color2
      : "#015f48";

  const Color = {
    color1: color1,
    color2: color2,
  };
  return Color;
};

export const hexToRgba = (hexColor, alpha) => {
  const hex = hexColor.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const TimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone.replace("/", "%2F");
};

export const FormatIndonesianTime = (time, isUtc = false) => {
  if (!time) return "-";
  let getZone = moment(time).format("ZZ");
  let zone = "";
  if (getZone === "+0700") zone = "WIB";
  if (getZone === "+0800") zone = "WITA";
  if (getZone === "+0900") zone = "WIT";
  return moment(time).format("HH:mm:ss") + " " + zone;
};

export const CheckRedirect = () => {
  let redirect = "companies";
  let ciperTextCompany = decryptedValue(
    JSON.parse(localStorage.getItem("company_redux"))
  );
  let company = ciperTextCompany;
  let ciperTextToken = decryptedValue(
    JSON.parse(localStorage.getItem("token_redux"))
  );
  let newToken = _.find(ciperTextToken, {
    cid: company?.initial,
  });

  return newToken ? company.initial : redirect;
};
