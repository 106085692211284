/* eslint-disable react-hooks/exhaustive-deps */
import _, { find, findIndex } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Swiper, SwiperSlide } from "swiper/react";
// import circle from "../../assets/images/icon_cardmember/circle.svg";
import {
  removeCompany,
  setCompany,
} from "../../core/feature/company/companySlice";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setUser } from "../../core/feature/user/userSlice";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import User from "../../core/models/user";
import * as API_BALANCE from "../../core/service/api_balance";
import * as API_INFO from "../../core/service/api_info";
import * as API_USER from "../../core/service/api_user";
import * as API_VIRTUAL_CARD from "../../core/service/api_virtualCard";
import * as API_AUTH from "../../core/service/api_auth";
import "./home.scss";

// Import Swiper styles
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import FailedIcon from "../../assets/images/failed-icon.png";
import BankDKI from "../../assets/images/icon_balance/bank_dki.png";
import LinkAja from "../../assets/images/icon_balance/link_aja_whitebg.png";
// import defaultPhoto from "../../assets/images/icon_cardmember/user.jpg";
import noInfo from "../../assets/images/icon_home/berita_kosong.svg";
import feature from "../../assets/images/icon_home/feature.svg";
import balance_ic from "../../assets/images/icon_menu/ic/balance.svg";
import donation from "../../assets/images/icon_menu/ic/donation.svg";
import invoice from "../../assets/images/icon_menu/ic/invoice.svg";
import marketplace from "../../assets/images/icon_menu/ic/marketplace.svg";
import ppob from "../../assets/images/icon_menu/ic/ppob.svg";
import qr from "../../assets/images/icon_menu/ic/qr.svg";
import siakad from "../../assets/images/icon_menu/ic/siakad.svg";
import virtualCard from "../../assets/images/icon_menu/ic/virtual_card.svg";
import mutasi from "../../assets/images/icon_menu/ic/mutation.svg";
import imageHomePage from "../../assets/images/img-homepage2.png";
import closepayLogo from "../../assets/images/solusinegeri-logo.svg";
import { formatCurrency } from "../../config/global";
import {
  // loadState,
  removeState,
} from "../../core/feature/browser-storage/browser-storage";
import { setBalance } from "../../core/feature/user/balanceSlice";
import { setPin } from "../../core/feature/user/pinSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";
import UserLogin from "../../core/models/userLogin";
import { StatsMobile } from "../_common/Stats";
import BottomNavigator from "../_common/bottomNavigator";
import patternSaldo from "../../assets/images/icon_home/pattern-saldo.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Dialog, Transition } from "@headlessui/react";
import defaultIcon from "../../assets/images/icon_menu/customDefault.svg";
import ModalInfo from "../_common/ModallInfo";
import { VirtualCardQRCode } from "../_common/virtualCardQRCodeComponent";
import { isIOS, isIOS13 } from "react-device-detect";
import { scanQrCode } from "../../core/service/api_absensi";
import icon from "../../assets/images/Administrator-icon.png";
import Wisata from "../../assets/images/icon_menu/ic/wisata.svg";
import { Trans, useTranslation } from "react-i18next";

const providers = [
  {
    id: 1,
    code: "BANK_DKI",
    providerName: "Bank DKI",
    img: BankDKI,
  },
  {
    id: 2,
    code: "LINK_AJA",
    providerName: "Link Aja",
    img: LinkAja,
  },
];

const HomeMobileCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  // const balance = useAppSelector((state) => state.balance.balance);
  // const userChekout: any = useAppSelector((state) => state.user.user);
  // const pin: number = useAppSelector((state) => state.pin.pin);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [configColor, setConfigColor] = useState<any>({});
  const { cid } = useParams();
  // const location = useLocation();
  // const [pathName, setPathName] = useState(
  //   location.pathname.replaceAll("/", "").toUpperCase()
  // );
  const [isLogin, setIsLogin] = useState(user && newToken);
  const [news, setNews] = useState<any[]>([]);
  const [homeMenus, setHomeMenus] = useState<any[]>([]);
  const [comboProviders, setComboProviders] = useState<any[]>([]);
  const [connectedAccounts, setConnectedAccounts] = useState<any[]>([]);
  const [balanceName, setBalanceName] = useState("");
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [bgCard, setBgCard] = useState<any>("");
  const [cardData, setCardData] = useState<any>("");
  // const vcard_data = loadState("virtual_card");
  const [otherMenu, setOtherMenu] = useState(false);
  const [customMenu, setCustomMenu] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [showQRAccess, setShowQRAccess] = useState(false);
  const [qrAccess, setqrAccess] = useState("");
  const [iframeURL, setIframeUrl] = useState("");
  const [cardId, setCardId] = useState("");
  const [isPotrait, setIsPotrait] = useState(false);
  const language = localStorage.getItem("i18nextLng") || "id";

  const features = [
    {
      url: `/${company?.initial}/invoices`,
      name: "invoice",
      label: t("home.invoice"),
      icon: invoice,
    },
    {
      url: `/${company?.initial}/siakad`,
      name: "siakad",
      label: t("home.siakad"),
      icon: siakad,
    },
    {
      url: `/${company?.initial}/barcode`,
      name: "barcode_payment",
      label: t("home.barcode_payment"),
      icon: qr,
    },
    {
      url: `/${company?.initial}/donations`,
      name: "donasi",
      label: t("home.donation"),
      icon: donation,
    },
    {
      url: `/${company?.initial}/commerce`,
      name: "marketplace",
      label: t("home.marketplace"),
      icon: marketplace,
    },
    {
      url: `/${company?.initial}/digital-product`,
      name: "ppob",
      label: "PPOB",
      icon: ppob,
    },
    {
      url: `/${company?.initial}/virtual_card`,
      name: "virtual_card",
      label: t("home.virtual_card"),
      icon: virtualCard,
    },
    {
      url: `/${company?.initial}/balance`,
      name: "saldo",
      label: t("home.balance"),
      icon: balance_ic,
    },
    {
      url: `/${company?.initial}/features`,
      name: "accessibility",
      label: t("home.accessibility"),
      icon: feature,
    },
    {
      url: `/${company?.initial}/news-information`,
      name: "info_dan_berita",
      label: t("home.titleCardNews"),
      icon: mutasi,
    },
    {
      url: `/${company?.initial}/trip-tour`,
      name: "trip_tour",
      label: "Trip & Tour",
      icon: Wisata,
    },
  ];

  let imageNotFound =
    "https://closepay-static.sgp1.digitaloceanspaces.com/image/no-image.png";

  const imageRender = (image: any) => {
    if (image.length !== 0) return image[0];
    if (image === "") return imageNotFound;
    if (image !== "") return image;
  };

  const goToDetail = async (data: any) => {
    navigate(`/${company.initial}/news-information/detail`, {
      state: {
        data: JSON.stringify(data),
      },
    });
  };

  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#015f48";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const getBalance = async () => {
    await API_BALANCE.getBalance("CLOSEPAY")
      .then((res) => {
        dispatch(setBalance(res.data.data.amount));
      })
      .catch((err) => {});
  };

  const getCompany = async () => {
    if (cid && !company) {
      await API_USER.getCompany(cid)
        .then((res) => {
          dispatch(setCompany(res.data.data));
        })
        .catch((err) => {
          dispatch(removeCompany());
        });
    }
  };

  const getConfigPin = async () => {
    await API_USER.getPinConfig()
      .then((res) => {
        dispatch(setPin(Number(res.data.data)));
      })
      .catch((err) => {});
  };

  const getAllNews = async (loader = true) => {
    dispatch(setLoading(loader));
    let data = {
      isHeadLine: true,
      page: 1,
      size: 10,
      sortBy: "createdTime",
      dir: -1,
      subCompanyId: user?.subCompanyId,
    };
    await API_INFO.getAllNews(company?.id, data)
      .then((res) => {
        dispatch(setLoading(false));
        setNews(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getConfigCard = async () => {
    if (isLogin) {
      await API_VIRTUAL_CARD.getConfigCard()
        .then(async (res) => {
          if (res.data.data == null) {
            Swal.fire({
              html: `<p class="font-medium">${t("marketplace.text_popup")}</p>`,
              color: "#EC1A1A",
              padding: "1rem",
              imageUrl: FailedIcon,
              imageAlt: "Custom image",
              imageWidth: 150,
              imageHeight: 150,
              confirmButtonColor: "#EC1A1A",
              confirmButtonText: "Kembali",
            });
          } else {
            let query = {
              page: 1,
            };
            await API_VIRTUAL_CARD.getCards(query).then((res2) => {
              if (res2.data.data.length === 1) {
                navigate(`virtual-card/list/${res2.data.data[0]._id}`, {
                  state: {
                    isSingleCard: true,
                  },
                });
              } else {
                navigate(`virtual-card/list`);
                console.log("navigate");
              }
            });
          }
        })
        .catch((err) => {});
    } else {
      navigate(`/${company?.initial}/login`);
    }
  };

  const checkBankDKIAccount = async () => {
    await API_BALANCE.checkBankDKIAccount()
      .then((res) => {
        dispatch(setLoading(false));
        if (
          connectedAccounts.length > 0 &&
          connectedAccounts.find(
            (el: any) => el.provider === res.data.provider
          ) !== undefined
        ) {
          return;
        } else {
          setConnectedAccounts(connectedAccounts.concat(res.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const checkLinkAjaAccount = async () => {
    await API_BALANCE.checkLinkAjaAccount()
      .then((res) => {
        dispatch(setLoading(false));
        if (
          connectedAccounts.length > 0 &&
          connectedAccounts.find(
            (el: any) => el.provider === res.data.data.provider
          ) !== undefined
        ) {
          return;
        } else {
          setConnectedAccounts(connectedAccounts.concat(res.data.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getHomeMenu = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_USER.getHomeConfig(company?.id)
      .then((res) => {
        setHomeMenus(res.data.data);
        API_BALANCE.getComboProviders()
          .then((res) => {
            setComboProviders(res.data);
            let dataOrder = {
              page: 0,
              size: 10,
              sortBy: "createTime",
              dir: -1,
              total: 0,
            };
            API_BALANCE.getConnectedAccounts2(dataOrder)
              .then((res) => {
                let tempConnectedAccounts = res.data.data.linkedAccounts;
                _.map(tempConnectedAccounts, (el: any) => {
                  switch (el) {
                    case "BANK_DKI":
                      checkBankDKIAccount();
                      break;

                    case "LINK_AJA":
                      checkLinkAjaAccount();
                      break;

                    default:
                      dispatch(setLoading(false));
                      break;
                  }
                });
              })
              .catch((err) => {
                dispatch(setLoading(false));
              });
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
        dispatch(setLoading(loader));
        let sortBy = "";
        API_USER.getConfigCustomMenu(company.id, sortBy)
          .then((res) => {
            setCustomMenu(res.data.data);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProfile = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_USER.getProfile()
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setUser(res.data.data));

        var newUserLogin =
          userLogin?.length > 0
            ? _.filter(userLogin, (el) => el.initial === company.initial)
            : [];
        let indexUserLogin = findIndex(newUserLogin, {
          id: res.data.data.noId,
        });
        // console.log("newUserLogin", newUserLogin);
        // console.log("indexUserLogin", indexUserLogin);

        if (indexUserLogin < 0) {
          newUserLogin.push({
            ...newUserLogin[indexUserLogin],
            initial: company.initial,
            token: newToken?.token,
            name: res.data.data.name,
            photo: res.data.data.profileImage,
            id: res.data.data.noId,
          });
        } else {
          newUserLogin[indexUserLogin] = {
            ...newUserLogin[indexUserLogin],
            initial: company.initial,
            token: newToken?.token,
            name: res.data.data.name,
            photo: res.data.data.profileImage,
            id: res.data.data.noId,
          };
        }

        dispatch(setUserLogin(newUserLogin));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getTem = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_VIRTUAL_CARD.getCardTemplate()
      .then((res) => {
        setCardData(res.data.data);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCardTemplate = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_VIRTUAL_CARD.getTemplate()
      .then((res) => {
        dispatch(setLoading(false));
        setBgCard(res.data.background);
        setCardId(res.data._id);
        setIsPotrait(res.data.isPotrait);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const onClickCustomMenu = async (data: any) => {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let string = "";
    for (let i = 0; i < 25; i++) {
      string += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    if (isLogin) {
      if (data.isAuthRequired === true) {
        dispatch(setLoading(true));
        await API_AUTH.authCustomMenu(string)
          .then((res) => {
            dispatch(setLoading(false));
            console.log(res);
            if (data.redirectType === "internal") {
              setIsOpen(true);
              setOtherMenu(false);
              let url = new URL(data.redirectUrl);
              url.searchParams.set(
                "one_time_access_token",
                `${res.data.data.token}`
              );
              url.searchParams.set("identifier", `${string}`);
              setIframeUrl(`${url.toString()}`);
            } else {
              let url = new URL(data.redirectUrl);
              url.searchParams.set(
                "one_time_access_token",
                `${res.data.data.token}`
              );
              url.searchParams.set("identifier", `${string}`);
              const link = `${url.toString()}`;
              if (isIOS || isIOS13) return window.location.replace(link);
              window.open(link, "_blank");
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      } else {
        let url = new URL(data.redirectUrl);
        if (data.redirectType === "internal") {
          setIsOpen(true);
          setOtherMenu(false);
          setIframeUrl(`${url.toString()}`);
        } else {
          const link = `${url.toString()}`;
          if (isIOS || isIOS13) return window.location.replace(link);
          window.open(link, "_blank");
        }
      }
    } else if (data.name === "Antrian Online" && !isLogin) {
      navigate("online-service");
    } else {
      navigate("login");
    }
  };

  const createQRAccess = () => {
    let newData = {
      companyId: company.id,
      companyInitial: company.initial,
      email: user.email,
      userId: user._id,
      userType: user.userType,
    };
    scanQrCode(newData)
      .then((res) => {
        setqrAccess(res.data.data.qrId);
        setShowQRAccess(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMainBalance = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_BALANCE.getMainBalanceMember()
      .then((res) => {
        dispatch(setLoading(false));
        setBalanceName(res.data.data.balanceName);
        setBalanceAmount(res.data.data.amount);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    const asyncFunct = async (loader = true) => {
      await getCardTemplate(loader);
      await getHomeMenu(loader);
      await getConfigPin();
      await removeState("virtual_card");
      await removeState("show_balance_card");
      await getCompany();
      await getAllNews(loader);
      if (isLogin) {
        await getBalance();
        await getMainBalance(loader);
      }
      await getProfile(loader);
      await getTem(loader);
      setTimeout(() => {
        sessionStorage.removeItem("loader");
      }, 1500);
    };

    if (sessionStorage.getItem("loader")) {
      asyncFunct(false);
    } else {
      asyncFunct();
    }
  }, [isLogin]);

  useEffect(() => {
    getConfigColor();
    setIsLogin(user && newToken);
    localStorage.removeItem("configF");
    localStorage.removeItem("configName");
  }, []);

  return (
    <div className="h-full min-h-screen font-montserrat bg-mobile pb-1">
      <StatsMobile />
      <ModalInfo />
      {showQR && (
        <VirtualCardQRCode
          onHide={() => setShowQR(false)}
          showQRCode={showQR}
          cardId={cardId}
          memberId={user.noId === undefined ? "" : user.noId}
          name={user.name === undefined ? "" : user.name}
          isHomePage={true}
        />
      )}
      {showQRAccess && (
        <VirtualCardQRCode
          key={qrAccess}
          onHide={() => setShowQRAccess(false)}
          showQRCode={showQRAccess}
          cardId={cardId}
          memberId={user.noId === undefined ? "" : user.noId}
          name={user.name === undefined ? "" : user.name}
          isHomePage={true}
          isModal={true}
          qrAccess={qrAccess}
        />
      )}
      <section className="container flex flex-col justify-center relative -mt-16 md:mb-[0px] mb-[20px] px-4">
        {isLogin &&
          (isPotrait ? (
            <div className="flex flex-col items-end">
              <div className="shadow h-[25rem] relative rounded-xl w-[15rem] flex mx-auto">
                <iframe
                  className={`w-full h-full overflow-hidden rounded-xl image-cover-style shadow-xl pl-2`}
                  style={{ backgroundImage: `url(${bgCard})` }}
                  scrolling="no"
                  srcDoc={cardData
                    .replace("src=-", `src=${icon}`)
                    .replace('src="-"', `src="${icon}"`)}
                ></iframe>
              </div>
              <div className="w-full flex justify-between">
                {company?.initial === "CFEST" && (
                  <button
                    type="button"
                    className="relative border-[1px] bg-white rounded-[8px] border-secondary text-secondary md:text-sm text-xs p-2 mt-3"
                    onClick={() => {
                      createQRAccess();
                      // setShowQRAccess(true);
                    }}
                  >
                    {t("home.qrAccess")}
                  </button>
                )}
                <button
                  type="button"
                  className="relative border-[1px] bg-white rounded-[8px] border-secondary text-secondary md:text-sm text-xs p-2 mt-3"
                  onClick={() => setShowQR(true)}
                >
                  {t("home.showQr")}
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-end">
              <div className="w-full h-full md:w-[30rem] mx-auto">
                <iframe
                  className={`w-full overflow-hidden rounded-2xl h-64 image-cover-style shadow-xl pl-2`}
                  style={{
                    backgroundImage: `url(${bgCard})`,
                    overflowX: "auto", // Tambahkan properti overflow-x atau overflow-y sesuai kebutuhan
                    overflowY: "auto",
                  }}
                  scrolling="no"
                  srcDoc={cardData
                    .replace("src=-", `src=${icon}`)
                    .replace('src="-"', `src="${icon}"`)}
                ></iframe>
              </div>
              <div className="w-full flex justify-between">
                {company?.initial === "CFEST" && (
                  <button
                    type="button"
                    className="relative border-[1px] bg-white rounded-[8px] border-secondary text-secondary md:text-sm text-xs p-2 mt-3"
                    onClick={() => {
                      createQRAccess();
                      // setShowQRAccess(true);
                    }}
                  >
                    {t("home.qrAccess")}
                  </button>
                )}
                <button
                  type="button"
                  className="relative border-[1px] bg-white rounded-[8px] border-secondary text-secondary md:text-sm text-xs p-2 mt-3"
                  onClick={() => setShowQR(true)}
                >
                  {t("home.showQr")}
                </button>
              </div>
            </div>
          ))}
      </section>

      <section className="container relative flex flex-col justify-center mt-6 md:mb-[0px] mb-[20px] px-4">
        {isLogin ? (
          <h6 className="md:text-xl text-base text-[#252525] font-medium font-montserrat">
            {t("home.balance")}
          </h6>
        ) : null}
        <div className="relative bg-white1 dark:bg-black rounded-xl shadow min-h-[122px]">
          {isLogin ? (
            <div
              className="flex flex-col justify-center cursor-pointer py-3 px-3"
              onClick={() => navigate(`/${company.initial}/balance`)}
            >
              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <h6 className="text-md text-[#252525] font-montserrat font-medium mb-0">
                    {balanceName}
                  </h6>
                  <h6
                    style={{ color: configColor?.color1 }}
                    className="text-xl font-montserrat font-semibold mb-0"
                  >
                    {formatCurrency(balanceAmount)}
                  </h6>
                </div>
                <img src={patternSaldo} alt="" className="w-28" />
              </div>
              {comboProviders.length > 0 && (
                <div>
                  {connectedAccounts.length > 0 ? (
                    <div className="grid grid-cols-3 gap-3 justify-center items-center divide-solid divide-x divide-[#eeecec]">
                      {connectedAccounts.map((item) =>
                        providers
                          .filter((el) => el.code === item.provider)
                          .map((i) => (
                            <div
                              className="flex flex-col items-center justify-between gap-2"
                              key={i.id}
                            >
                              <img
                                src={i.img}
                                alt=""
                                className="h-[80px] w-[90px]"
                              />
                              {item?.provider === "LINK_AJA" ? (
                                <img
                                  src={`data: image/jpeg;base64, ${item?.amount}`}
                                  alt=""
                                />
                              ) : (
                                <h6 className="md:text-base text-sm text-[#1AA7EC] font-medium mb-0">
                                  {formatCurrency(item?.amount)}
                                </h6>
                              )}
                            </div>
                          ))
                      )}
                      <div className="flex w-full justify-center">
                        <button
                          type="button"
                          onClick={() => navigate("balance")}
                          className="rounded-full bg-[#1AA7EC] text-white h-[24px] w-[24px] ml-3"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-3 gap-3 justify-between w-full mt-2">
                      <div className="col-span-3 flex items-center justify-between">
                        <div
                          style={{ backgroundColor: configColor?.color1 }}
                          className="text-[11px] text-white px-2 rounded-full font-medium mb-0 font-montserrat flex py-1"
                        >
                          {t("home.connectEwallet")}
                          <button
                            type="button"
                            onClick={() =>
                              navigate(`/${company.initial}/balance`)
                            }
                            style={{ color: configColor?.color1 }}
                            className="rounded-full bg-white h-[16px] w-[16px] ml-2 text-[9px]"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="relative flex flex-col justify-center px-4 h-fit overflow-hidden">
              <div className="w-[80%] z-30  py-2.5">
                {company?.showFtpLogo && (
                  <img src={closepayLogo} alt="" className="h-[18px]" />
                )}
                <p className="md:text-lg text-sm font-montserrat text-[#252525] font-semibold mt-3">
                  <Trans i18nKey="home.enjoyTransaction">
                    Nikmati kemudahan transaksi dalam satu genggaman!
                  </Trans>
                </p>
                <div className="flex flex-row gap-2 md:w-2/3 w-full mt-2">
                  <button
                    type="button"
                    style={{ backgroundColor: configColor?.color1 }}
                    onClick={() => navigate(`login`)}
                    className="w-fit px-3 h-fit py-0.5 font-montserrat font-medium text-[10px] rounded-[8px] text-[#FFFFFF] border-[1px] md:text-base text-sm hover:bg-[#FFFFFF]"
                  >
                    {t("home.buttonLogin")}
                  </button>
                  {company?.enableMemberSelfRegister === true && (
                    <button
                      type="button"
                      style={{
                        color: configColor?.color2,
                        borderColor: configColor?.color2,
                      }}
                      onClick={() => navigate(`self-register`)}
                      className="w-fit px-3 h-fit py-0.5 font-montserrat font-medium text-[10px] rounded-[8px] hover:text-[#FFFFFF] border-[1px]  bg-[#FFFFFF] hover:bg-[#06A980] md:text-base text-sm"
                    >
                      {t("home.buttonRegister")}
                    </button>
                  )}
                </div>
              </div>
              <img
                src={
                  company?.bannerForeground?.mobile === null ||
                  company?.bannerForeground === null
                    ? imageHomePage
                    : company?.bannerForeground?.mobile
                }
                alt=""
                className="md:w-[230px] w-[130px] md:h-full bottom-0 right-1 absolute overflow-hidden"
              />
            </div>
          )}
        </div>
      </section>

      <section className="container flex flex-col gap-5 justify-center md:mt-[64px] md:mb-[0px] mb-[64px] px-4 mt-3">
        <div className="w-full mx-auto">
          <div id="features">
            <div className="flex flex-row items-center justify-between">
              <h6 className="md:text-xl text-base text-[#252525] font-medium mb-2 font-montserrat">
                Menu
              </h6>
              {homeMenus.length + customMenu.length > 8 && (
                <button
                  onClick={() => setOtherMenu(true)}
                  style={{ color: configColor?.color1 }}
                  className="text-xs font-light"
                >
                  {t("home.seeOther")}
                </button>
              )}
            </div>
            <div className="">
              <div className="grid lg:grid-cols-5 sm:grid-cols-4 md:grid-cols-5 grid-cols-4 gap-4 justify-between">
                {homeMenus.length === 0 &&
                  [1, 2, 3, 4, 5, 6, 7, 8].map((res) => (
                    <div className="flex flex-col items-center">
                      <Link
                        to={""}
                        className="flex flex-col items-center no-underline"
                      >
                        <Skeleton width={48} height={48} />
                        <Skeleton
                          height={10}
                          width={48}
                          className="mt-[14px]"
                        />
                      </Link>
                    </div>
                  ))}
                {homeMenus.length > 0 &&
                  homeMenus.slice(0, 8).map((item) =>
                    features
                      .filter((el) => el.name === item.name)
                      .map((feature: any, index: number) => (
                        <div
                          className=" flex flex-col items-center justify-start z-30"
                          key={index}
                        >
                          {feature.name === "virtual_card" ? (
                            <div
                              onClick={getConfigCard}
                              className={
                                "no-underline flex flex-col items-center gap-2.5"
                              }
                            >
                              <div className="flex flex-col items-center justify-center bg-white no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                                <img
                                  src={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? feature.icon
                                      : item.imageUrl
                                  }
                                  alt=""
                                  className={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? "w-[54px] h-[54px] rounded-[18px]"
                                      : "w-[54px] h-[54px] rounded-[18px] p-2"
                                  }
                                />
                              </div>
                              <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                                {language === "en"
                                  ? item.displayEn || feature.label
                                  : item.display || feature.label}
                              </h6>
                            </div>
                          ) : feature.name === "siakad" ? (
                            <div
                              onClick={() => {
                                if (isLogin) {
                                  window.location.href = `https://app.dev.solusinegeri.com/siakad/${company.initial}`;
                                  localStorage.removeItem("userType");
                                } else {
                                  navigate("login");
                                }
                              }}
                              className={
                                "no-underline flex flex-col items-center gap-2.5"
                              }
                            >
                              <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                                <img
                                  src={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? feature.icon
                                      : item.imageUrl
                                  }
                                  alt=""
                                  className={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? "w-[54px] h-[54px] rounded-[18px]"
                                      : "w-[54px] h-[54px] rounded-[18px] p-2"
                                  }
                                />
                              </div>
                              <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                                {language === "en"
                                  ? item.displayEn || feature.label
                                  : item.display || feature.label}
                              </h6>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                navigate(isLogin ? feature.url : "login", {
                                  state: {
                                    title:
                                      language === "en"
                                        ? item.displayEn || feature.label
                                        : item.display || feature.label,
                                  },
                                });
                              }}
                              className={
                                "no-underline flex flex-col items-center gap-2.5"
                              }
                            >
                              <div className="flex flex-col items-center justify-center bg-white no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                                <img
                                  src={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? feature.icon
                                      : item.imageUrl
                                  }
                                  alt=""
                                  className={
                                    item.imageUrl === null ||
                                    item.imageUrl === ""
                                      ? "w-[54px] h-[54px] rounded-[18px]"
                                      : "w-[54px] h-[54px] rounded-[18px] p-2"
                                  }
                                />
                              </div>
                              <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                                {language === "en"
                                  ? item.displayEn || feature.label
                                  : item.display || feature.label}
                              </h6>
                            </div>
                          )}
                        </div>
                      ))
                  )}
                {company?.initial === "KADIN" ? (
                  <div className="flex flex-col justify-center rounded-lg items-center no-underline">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://investree.id/v3/auth/registration/borrower"
                      className="no-underline mt-1 text-gray-600"
                    >
                      <img
                        src="https://www.compasslist.com/image/f08b86b27d2aba9557e4c9b51d6b5923bc3cf8bc.jpeg?width=192&height=192"
                        alt=""
                        className="rounded-xl w-[50px] h-[50px]"
                      />
                      <p className="mt-2 text-[12px]">Investree</p>
                    </a>
                  </div>
                ) : null}
                {homeMenus.length < 8 && (
                  <>
                    {customMenu
                      .slice(0, 8 - homeMenus.length)
                      .map((res: any) => {
                        return (
                          <div
                            onClick={() => onClickCustomMenu(res)}
                            className={
                              "no-underline flex flex-col items-center gap-2.5"
                            }
                          >
                            <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                              <img
                                src={
                                  res.imageUrl === null || res.imageUrl === ""
                                    ? defaultIcon
                                    : res.imageUrl
                                }
                                alt=""
                                className={
                                  res.imageUrl === null || res.imageUrl === ""
                                    ? "w-[54px] h-[54px] rounded-[18px]"
                                    : "w-[54px] h-[54px] rounded-[18px] p-2"
                                }
                              />
                            </div>
                            <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                              {res.name === "" ? res.name : res.name}
                            </h6>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
          <div id="news" className="mt-3">
            <div className="flex justify-between items-center mb-2 gap-4">
              <h6 className="md:text-xl text-base text-[#252525] font-medium  font-montserrat m-0">
                {t("home.titleCardNews")}
              </h6>
              <Link
                to={`/${company.initial}/news-information`}
                style={{ color: configColor?.color1 }}
                className="no-underline text-[#1AA7EC] text-xs font-medium font-montserrat"
              >
                {t("home.seeOther")}
              </Link>
            </div>
            <div className="mb-4">
              {news.length === 0 ? (
                <div className="h-auto w-auto">
                  <img src={noInfo} className="flex mx-auto" alt="" />
                </div>
              ) : (
                ""
              )}
              {news.filter((el) => el.isPublish === true).length > 0 ? (
                <Swiper
                  freeMode={true}
                  grabCursor={true}
                  modules={[FreeMode]}
                  slidesPerView={"auto"}
                  spaceBetween={15}
                  className="mySwiper"
                >
                  {news
                    .filter((el) => el.isHeadLine === true)
                    .map((res, index) => (
                      <SwiperSlide
                        onClick={() => goToDetail(res)}
                        key={index}
                        className="cursor-pointer w-auto"
                      >
                        <div draggable={false} className="h-full">
                          <div className="bg-white relative rounded-[5px] overflow-hidden shadow-sm p-2 min-h-[11.5rem] mb-3 w-[13rem] h-[11.5rem]">
                            <img
                              loading="lazy"
                              draggable={false}
                              src={imageRender(res.imageUrl)}
                              className="object-cover w-full min-w-[184px] min-h-[104px] select-none overflow-hidden rounded-[5px]"
                              alt=""
                              style={{ height: "81px" }}
                            />
                            <div className="">
                              <h1 className="text-xs font-montserrat font-semibold text-[#252525] text-left mt-[10px]">
                                {res.title.length < 30
                                  ? res.title
                                  : res.title.toString().substring(0, 30) +
                                    "..."}
                              </h1>
                            </div>
                            <p className="text-[8px] font-montserrat font-light text-[#9A9999] text-left mb-0 fixed bottom-6">
                              {moment(res?.createdTime).format("LL")}
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <BottomSheet
          onDismiss={() => setOtherMenu(false)}
          open={otherMenu}
          className="z-50 relative"
        >
          <div className="p-3">
            <h4 className="text-[16px] font-semibold">{t("home.mainMenu")}</h4>
            <div className="grid lg:grid-cols-5 sm:grid-cols-4 grid-cols-4 gap-4 justify-between p-2">
              {homeMenus.map((item: any) =>
                features
                  .filter((el) => el.name === item.name)
                  .map((feature: any, index: number) => (
                    <div
                      className=" flex flex-col items-center justify-start "
                      key={index}
                    >
                      {feature.name === "virtual_card" ? (
                        <div
                          onClick={getConfigCard}
                          className={
                            "no-underline flex flex-col items-center gap-2.5"
                          }
                        >
                          <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                            <img
                              src={
                                item.imageUrl === null || item.imageUrl === ""
                                  ? feature.icon
                                  : item.imageUrl
                              }
                              alt=""
                              className={
                                item.imageUrl === null || item.imageUrl === ""
                                  ? "w-[54px] h-[54px] rounded-[18px]"
                                  : "w-[54px] h-[54px] rounded-[18px] p-2"
                              }
                            />
                          </div>
                          <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                            {language === "en"
                              ? item.displayEn || feature.label
                              : item.display || feature.label}
                          </h6>
                        </div>
                      ) : feature.name === "siakad" ? (
                        <div
                          onClick={() => {
                            if (isLogin) {
                              window.location.href = `https://app.dev.solusinegeri.com/siakad/${company.initial}`;
                              localStorage.removeItem("userType");
                            } else {
                              navigate("login");
                            }
                          }}
                          className={
                            "no-underline flex flex-col items-center gap-2.5"
                          }
                        >
                          <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                            <img
                              src={
                                item.imageUrl === null || item.imageUrl === ""
                                  ? feature.icon
                                  : item.imageUrl
                              }
                              alt=""
                              className={
                                item.imageUrl === null || item.imageUrl === ""
                                  ? "w-[54px] h-[54px] rounded-[18px]"
                                  : "w-[54px] h-[54px] rounded-[18px] p-2"
                              }
                            />
                          </div>
                          <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                            {language === "en"
                              ? item.displayEn || feature.label
                              : item.display || feature.label}
                          </h6>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            navigate(isLogin ? feature.url : "login", {
                              state: {
                                title:
                                  item.display === ""
                                    ? feature.label
                                    : item.display,
                              },
                            });
                          }}
                          className={
                            "no-underline flex flex-col items-center gap-2.5"
                          }
                        >
                          <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                            <img
                              src={
                                item.imageUrl === null || item.imageUrl === ""
                                  ? feature.icon
                                  : item.imageUrl
                              }
                              alt=""
                              className={
                                item.imageUrl === null || item.imageUrl === ""
                                  ? "w-[54px] h-[54px] rounded-[18px]"
                                  : "w-[54px] h-[54px] rounded-[18px] p-2"
                              }
                            />
                          </div>
                          <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                            {language === "en"
                              ? item.displayEn || feature.label
                              : item.display || feature.label}
                          </h6>
                        </div>
                      )}
                    </div>
                  ))
              )}
            </div>
            {customMenu.length > 0 && (
              <h4 className="mt-3 text-[16px] font-semibold">
                {t("home.menuOther")}
              </h4>
            )}
            <div className="grid lg:grid-cols-5 sm:grid-cols-4 grid-cols-4 gap-4 justify-between p-2">
              {customMenu.map((res: any) => {
                return (
                  <div
                    onClick={() => onClickCustomMenu(res)}
                    className={
                      "no-underline flex flex-col items-center gap-2.5"
                    }
                  >
                    <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                      <img
                        src={
                          res.imageUrl === null || res.imageUrl === ""
                            ? defaultIcon
                            : res.imageUrl
                        }
                        alt=""
                        className={
                          res.imageUrl === null || res.imageUrl === ""
                            ? "w-[54px] h-[54px] rounded-[18px]"
                            : "w-[54px] h-[54px] rounded-[18px] p-2"
                        }
                      />
                    </div>
                    <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                      {res.name === "" ? res.name : res.name}
                    </h6>
                  </div>
                );
              })}
            </div>
          </div>
        </BottomSheet>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-[1000]"
            onClose={() => setIsOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto z-[2000]">
              <div className="flex min-h-screen items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                    <iframe
                      title="menu"
                      src={iframeURL}
                      className="w-full h-full min-h-[70vh]"
                    ></iframe>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </section>
      <BottomNavigator title="home" />
    </div>
  );
};

export default HomeMobileCard;
