import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import absen from "../../assets/images/icon_features/absen.svg";
import akses from "../../assets/images/icon_features/akses.svg";
import machine from "../../assets/images/icon_features/machine.svg";
import parkir from "../../assets/images/icon_features/parkir.svg";
import wisata from "../../assets/images/icon_features/wisata.svg";
import ToastConfig from "../../config/Toast";
import { ConfigColor } from "../../config/global";
import { setLoading } from "../../core/feature/config/configSlice";
import * as API_ABSENSI from "../../core/service/api_absensi";
import BottomLogo from "../_common/bottomLogo";
import DataNotFound from "../_common/dataNotFound";
import Header from "../_common/headerComponent";
import { useTranslation } from "react-i18next";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";

const comingSoon = () => {
  toast("Coming Soon", ToastConfig.info);
};

const FeaturesWeb = () => {
  const color = ConfigColor();
  const dispatch = useDispatch();
  const [dataMenu, setDataMenu] = useState<any[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng") || "id";
  const company: Company = useAppSelector((state) => state.company.company);

  const getListMenu = async () => {
    dispatch(setLoading(true));
    await API_ABSENSI.listMenu()
      .then((res) => {
        const menu = [
          {
            id: 0,
            name: t("accessibility.absence"),
            title:
              language === "id"
                ? res?.data?.data?.attendanceMenu?.customName ||
                  t("accessibility.absence")
                : res?.data?.data?.attendanceMenu?.customNameEn ||
                  t("accessibility.absence"),
            icon:
              res?.data?.data?.attendanceMenu?.customLogoUrl === null
                ? absen
                : res?.data?.data?.attendanceMenu?.customLogoUrl,
            routes: "absen",
            isActive: res?.data?.data?.attendanceMenu?.isActive,
            isShow: res?.data?.data?.attendanceMenu?.isShow,
          },
          {
            id: 1,
            name: t("accessibility.access"),
            title:
              language === "id"
                ? res?.data?.data?.entranceMenu?.customName ||
                  t("accessibility.access")
                : res?.data?.data?.entranceMenu?.customNameEn ||
                  t("accessibility.access"),
            icon:
              res?.data?.data?.entranceMenu?.customLogoUrl === null
                ? akses
                : res?.data?.data?.entranceMenu?.customLogoUrl,
            routes: "access",
            isActive: res?.data?.data?.entranceMenu?.isActive,
            isShow: res?.data?.data?.entranceMenu?.isShow,
          },
          {
            id: 2,
            name: t("accessibility.parking"),
            title:
              language === "id"
                ? res?.data?.data?.parkingMenu?.customName ||
                  t("accessibility.parking")
                : res?.data?.data?.parkingMenu?.customNameEn ||
                  t("accessibility.parking"),
            icon:
              res?.data?.data?.parkingMenu?.customLogoUrl === null
                ? parkir
                : res?.data?.data?.parkingMenu?.customLogoUrl,
            routes: "parkir",
            isActive: res?.data?.data?.parkingMenu?.isActive,
            isShow: res?.data?.data?.parkingMenu?.isShow,
          },
          {
            id: 3,
            name: t("accessibility.machine"),
            title:
              language === "id"
                ? res?.data?.data?.dispenserMenu?.customName ||
                  t("accessibility.machine")
                : res?.data?.data?.dispenserMenu?.customNameEn ||
                  t("accessibility.machine"),
            icon:
              res?.data?.data?.dispenserMenu?.customLogoUrl === null
                ? machine
                : res?.data?.data?.dispenserMenu?.customLogoUrl,
            routes: "machine",
            isActive: res?.data?.data?.dispenserMenu?.isActive,
            isShow: res?.data?.data?.dispenserMenu?.isShow,
          },
          {
            id: 4,
            name: t("accessibility.tour"),
            title:
              language === "id"
                ? res?.data?.data?.paygateMenu?.customName ||
                  t("accessibility.tour")
                : res?.data?.data?.paygateMenu?.customNameEn ||
                  t("accessibility.tour"),
            icon:
              res?.data?.data?.paygateMenu?.customLogoUrl === null
                ? wisata
                : res?.data?.data?.paygateMenu?.customLogoUrl,
            routes: "pay-gate",
            isActive: res?.data?.data?.paygateMenu?.isActive,
            isShow: res?.data?.data?.paygateMenu?.isShow,
          },
        ];
        setDataMenu(menu);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getListMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-mobile min-h-screen">
      <div className="w-full font-montserrat">
        <Header />
        <div className="container">
          <div className="w-1/2 mx-auto pt-10">
            <span className="font-semibold text-2xl">
              {t("accessibility.activity")}
            </span>
            {dataMenu.length > 0 ? (
              <div className="grid grid-cols-4 justify-between mt-4 cursor-pointer">
                {dataMenu.map((item: any) => {
                  if (item.isShow === false) {
                    return null;
                  } else {
                    if (item.isActive === true) {
                      if (item.name === t("accessibility.machine")) {
                        return (
                          <div
                            onClick={() => comingSoon()}
                            key={item.id}
                            className="no-underline cursor-pointer flex flex-col text-center mx-auto w-32 relative"
                          >
                            <span
                              className="absolute mt-3 text-[9px] text-white px-0.5 rounded-md right-3 top-3"
                              style={{ backgroundColor: `${color.color1}` }}
                            >
                              {t("accessibility.soon")}
                            </span>
                            <img src={item.icon} alt="" />
                            <p className="no-underline text-sm text-thirdy -mt-4">
                              {item.title}
                            </p>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            onClick={() => {
                              navigate(item.routes, {
                                state: {
                                  title: item.title,
                                },
                              });
                            }}
                            key={item.id}
                            className="no-underline flex flex-col text-center mx-auto w-32"
                          >
                            <img src={item.icon} alt="" />
                            <p className="no-underline text-sm text-thirdy -mt-4">
                              {item.title}
                            </p>
                          </div>
                        );
                      }
                    } else if (item.isActive === false) {
                      return (
                        <div
                          onClick={() =>
                            toast(
                              t("accessibility.menu_cannot_accessed"),
                              ToastConfig.warning
                            )
                          }
                          key={item.id}
                          className="no-underline cursor-pointer flex flex-col text-center mx-auto w-32"
                        >
                          <img src={item.icon} alt="" />
                          <p className="no-underline text-sm text-thirdy -mt-4">
                            {item.title}
                          </p>
                        </div>
                      );
                    }
                  }
                  return "";
                })}
              </div>
            ) : (
              <div className="container">
                <DataNotFound title={t("accessibility.noData")} />
              </div>
            )}
          </div>
        </div>
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </div>
  );
};

export default FeaturesWeb;
