/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import arrow from '../../../assets/images/arrow-left-ic.svg';
import DummyImage2 from '../../../assets/images/icon_queue/dummy_image_2.png';
import LoocationIcon from '../../../assets/images/icon_queue/location.svg';
import NoImage from "../../../assets/images/no_image.png";
import { useAppSelector } from '../../../core/feature/hooks';
import Company from '../../../core/models/company';

const LocationMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const location: any = useLocation();
  const [isSetLocation, setIsSetLocation] = React.useState(false);

  const openingHours = [
    {
      day: "Senin",
      hours: "08.00 - 20.00 WIB"
    },
    {
      day: "Selasa",
      hours: "08.00 - 20.00 WIB"
    },
    {
      day: "Rabu",
      hours: "08.00 - 20.00 WIB"
    },
    {
      day: "Kamis",
      hours: "08.00 - 20.00 WIB"
    },
    {
      day: "Jumat",
      hours: "08.00 - 20.00 WIB"
    },
    {
      day: "Sabtu",
      hours: "08.00 - 20.00 WIB"
    },
    {
      day: "Minggu",
      hours: "08.00 - 20.00 WIB"
    },
  ];

  React.useEffect(() => {
    if(location.state !== null && location.state === true){
      setIsSetLocation(true);
    }
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <div className="absolute p-4 z-10 flex items-center justify-between w-full">
        <div
          onClick={() => navigate(-1)}
          className="flex bg-white rounded-2xl border-1 border-solid border-gray-400 w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>
        <div
          // onClick={() => navigate(-1)}
          className="flex bg-white rounded-2xl border-1 border-solid border-gray-400 w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img
            src={LoocationIcon}
            style={{ width: 15, height: 18 }}
            className="m-auto"
            alt=""
          />
        </div>
      </div>

      <div className="h-[380px] sm:w-full relative z-0">
        <div className="absolute w-full">
          <img src={DummyImage2} className="object-cover h-[380px] w-full" alt="" />
        </div>
      </div>

      <div className="w-full relative">
        <div className="container md:-mt-[80px] -mt-[50px] pb-[44px] px-0">
          <div className="w-full mx-auto bg-white rounded-t-[30px] px-4 pb-3 pt-9 mb-4">
            <div className="flex items-start justify-between">
              <h5 className="md:text-[22px] text-xl font-medium text-customBlack">Barberking Salatiga - BK13</h5>
              <button
                type="button"
                // onClick={}
                className="bg-green rounded-[8px] px-3 py-2 md:text-sm text-xs text-white"
              >
                Buka
              </button>
            </div>
            <p className="md:text-xs text-[10px] font-light text-[#9A9999] mb-3">Jl. Diponegoro, Salatiga, Kec. Sidorejo,Kota Salatiga</p>
            <div className="mb-3">
              <h6 className="md:text-lg text-base font-semibold mb-1">Jam Buka</h6>
              {openingHours.map((item, index) => (
                <h6 className="md:text-sm text-xs font-[#9A9999]">{item.day} : {item.hours}</h6>
              ))}
            </div>
            <div className="mb-3">
              <h6 className="md:text-lg text-base font-semibold">Barberman</h6>
              <div className="grid md:grid-cols-7 grid-cols-4 items-center justify-between gap-3">
                <div className="border-[0.5px] border-[#9A9999] rounded-[4px] p-7">
                  <img src={NoImage} alt="" className="h-full object-cover mx-auto" />
                </div>
                <div className="border-[0.5px] border-[#9A9999] rounded-[4px] p-7">
                  <img src={NoImage} alt="" className="h-full object-cover mx-auto" />
                </div>
                <div className="border-[0.5px] border-[#9A9999] rounded-[4px] p-7">
                  <img src={NoImage} alt="" className="h-full object-cover mx-auto" />
                </div>
                <div className="border-[0.5px] border-[#9A9999] rounded-[4px] p-7">
                  <img src={NoImage} alt="" className="h-full object-cover mx-auto" />
                </div>
                <div className="border-[0.5px] border-[#9A9999] rounded-[4px] p-7">
                  <img src={NoImage} alt="" className="h-full object-cover mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isSetLocation && (
        <div className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 px-4" style={{transform: "translate(-50%, -50%)"}}>
          <button
            type="button"
            onClick={() => navigate(`/${company.name}/online-service`)}
            className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
          >
            Pilih Lokasi Ini
          </button>
        </div>
      )}
    </section>
  )
}

export default LocationMobile