import React, { Fragment, useEffect, useState } from "react";
import Header from "../_common/headerComponent";
import { useNavigate } from "react-router-dom";
import fill from "../../assets/images/icon_features/filter.svg";
import BottomLogo from "../_common/bottomLogo";
import moment from "moment";
import * as API_ABSENSI from "../../core/service/api_absensi.js";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import DataNotFound from "../_common/dataNotFound";
import ReactDatePicker from "react-datepicker";
import calendar from "../../assets/images/icon_features/calendarlogo.svg";
import LoadMoreButton from "../_common/loadMoreButton";
// import User from "../../core/models/user";
// import { useAppSelector } from "../../core/feature/hooks";
import { Dialog, Transition } from "@headlessui/react";
import { absenPlus } from "../../config/configIcon";
import { hexToRgba, ConfigColor } from "../../config/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";

const AccessFeaturesWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const color = ConfigColor();
  const [filter, setFilter] = useState(false);
  const [openMethod, setOpenMethod] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [methode, setMethode] = useState("login");
  const [selectedMethode, setSelectedMethode] = useState("barcode");
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [act, setAct] = useState(false);
  const [activity, setAcitivity] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [startDate2, setStartDate2] = useState<any>(null);
  const [endDate] = useState<any>(null);
  const [endDate2] = useState<any>(null);
  const [newStartDate, setNewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [size] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [pageA, setPageA] = useState(1);
  const [totalElementsA, setTotalElementsA] = useState(0);
  const [dataAct, setDataAct] = useState<any[]>([]);
  // const [rule, setRule] = useState<any>([]);
  const [selectedAct, setSelectedAct] = useState<any>();
  // const [name, setName] = useState(localStorage.getItem("configF")?.toString());
  const [confirm, setConfirm] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [detail, setDetail] = useState<any>({});
  const [newEntranceState, setNewEntranceState] = useState("ENTRANCE_START");

  const pilihMethode = async (data: any) => {
    if (selectedMethode === "qr") {
      navigate("scan-qr");
    } else {
      // if (rule?.userIds?.length === 0) {
      //   navigate("barcode", {
      //     state: { data: data },
      //   });
      // } else if (rule?.userTags?.length === 0) {
      //   navigate("barcode", {
      //     state: { data: data },
      //   });
      // } else if (rule?.userIds?.includes(user?.id)) {
      //   navigate("barcode", {
      //     state: { data: data },
      //   });
      // } else if (rule?.userTags?.includes(user?.tags[0])) {
      //   navigate("barcode", {
      //     state: { data: data },
      //   });
      // } else {
      //   toast.error("Anda tidak terdaftar di aktivitas ini");
      // }
      navigate("barcode", {
        state: { data: data },
      });
      localStorage.removeItem("result");
    }
  };

  const scanQrRadio = () => {
    setSelectedMethode("qr");
    setAct(false);
    setConfirm(true);
  };

  const scanBarcodeRadio = async (currentPage: number = pageA) => {
    setSelectedMethode("barcode");
    setAct(true);
    setConfirm(true);
    // dispatch(setLoading(true));
    // await API_ABSENSI.listEntranceActivity(currentPage)
    //   .then((res) => {
    //     if (currentPage > 1) {
    //       setAcitivity(activity.concat(res.data.data));
    //     } else {
    //       setAcitivity(res.data.data);
    //     }
    //     setTotalElementsA(res.data.paging.totalElements);
    //     dispatch(setLoading(false));
    //   })
    //   .catch((err) => {
    //     dispatch(setLoading(false));
    //   });
  };

  const resetMethode = () => {
    setSelectedMethode("qr");
    setOpenMethod(false);
    setPageA(1);
    scanBarcodeRadio(1);
  };

  const goToDetail = async (data: any) => {
    dispatch(setLoading(true));
    await API_ABSENSI.entranceDetailById(data)
      .then((res) => {
        setDetail(res.data.data);
        setShowDetail(true);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getListEntrance = async (
    currentPage: number = page,
    entranceTimeStart: any = newStartDate,
    entranceTimeEnd: any = newEndDate,
    entranceState: any = newEntranceState
  ) => {
    let query = {
      companyId: "",
      companyInitial: "",
      email: "",
      userType: "",
      userId: "",
      entranceTimeStart,
      entranceTimeEnd,
      entranceState,
      size: size,
      page: currentPage,
      sortBy: "-createdDate",
    };
    dispatch(setLoading(true));
    await API_ABSENSI.listEntrance(query)
      .then((res) => {
        if (currentPage > 1) {
          setData(data.concat(res.data.data));
        } else {
          setData(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const filterDate = () => {
    getListEntrance(1, newStartDate, newEndDate, newEntranceState);
  };

  const resetFilter = async () => {
    setPage(1);
    setFilter(false);
    setNewStartDate("");
    setNewEndDate("");
    setStartDate(null);
    setStartDate2(null);
    setNewEntranceState("");
    getListEntrance(1, "", "", "");
  };

  function currentSize() {
    let a = data.length;
    let b = a / page;

    return b;
  }

  function currentSizeActivity() {
    let a = activity.length;
    let b = a / pageA;

    return b;
  }

  const addMoreAct = async () => {
    setPageA(pageA + 1);
    scanBarcodeRadio(pageA + 1);
  };

  // const backAct = async () => {
  //   setPageA(pageA - 1);
  //   scanBarcodeRadio(pageA - 1);
  // };

  const addMore = async () => {
    setPage(page + 1);
    getListEntrance(page + 1, newStartDate, newEndDate);
  };

  const closeModal = () => {
    resetFilter();
    setPage(1);
  };

  const convertTime = (time: any) => {
    var days = Math.floor(time / 1440); // 1 hari memiliki 1440 menit
    var hours = Math.floor((time % 1440) / 60);
    var remainingMinutes = time % 60;
    const timeString = `${days} Hari ${hours} jam ${remainingMinutes} Menit`;

    return timeString;
  };

  useEffect(() => {
    getListEntrance(1, "", "", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-mobile min-h-screen pb-36">
      <Header />
      <div className="w-full bg-mobile h-full relative font-montserrat container">
        <div className="w-1/2 mx-auto">
          <div className="w-full px-4 flex gap-3 bg-white py-4 mt-4">
            <button
              onClick={() => {
                setOpenMethod(true);
                setConfirm(true);
              }}
              className="flex bg-opacity-10 font-semibold text-[15px] p-2.5 rounded-lg w-5/6"
              style={{
                color: `${color.color1}`,
                backgroundColor: hexToRgba(color.color1, 0.1),
              }}
            >
              {absenPlus(color)}{" "}
              <span className="ml-2">{t("accessibility.access_with_qr")}</span>
            </button>
            <button
              onClick={() => {
                setNewEntranceState("ENTRANCE_START");
                setFilter(true);
              }}
              className="border-1 border-gray-300 flex bg-opacity-10 p-2.5 rounded-lg w-1/6"
            >
              <img src={fill} alt="" className="mr-2 mx-auto" />
            </button>
          </div>
          <div className="w-full p-4 mt-4 bg-white">
            <span>{t("accessibility.history")}</span>
            {data.length > 0 ? (
              <div className="">
                {data.map((res: any) => {
                  return (
                    <div
                      key={res.id}
                      onClick={() => goToDetail(res.id)}
                      className="p-3 text-black border-b-2 border-gray-400 grid grid-cols-3 relative gap-0.5 my-3 hover:cursor-pointer"
                    >
                      <div className="flex flex-col justify-center">
                        <span>{res?.entranceActivity?.name || "-"}</span>
                        <span>{res?.entranceActivity?.description || "-"}</span>
                      </div>
                      <div className="flex flex-col text-center items-center justify-center relative">
                        <span
                          className={
                            res.entranceStateDuration === "OUT_DURATION"
                              ? "text-red font-semibold"
                              : "text-black font-semibold"
                          }
                        >
                          {res?.entranceStart?.entranceTime === null ||
                          res?.entranceStart?.entranceTime === undefined ||
                          res?.entranceStart?.entranceTime === ""
                            ? "-"
                            : moment
                                .utc(res?.entranceStart?.entranceTime)
                                .local()
                                .format("HH : mm : ss")}
                        </span>
                        <span className="text-gray-600">
                          {" "}
                          {res?.entranceStart?.entranceTime === null ||
                          res?.entranceStart?.entranceTime === undefined ||
                          res?.entranceStart?.entranceTime === ""
                            ? "-"
                            : moment
                                .utc(res?.entranceStart?.entranceTime)
                                .local()
                                .format("DD MMM YYYY")}
                        </span>
                      </div>
                      <div className="flex text-center items-center justify-center relative">
                        <div className="flex flex-col">
                          <span
                            className={
                              res.entranceStateDuration === "OUT_DURATION"
                                ? "text-red font-semibold"
                                : "text-black font-semibold"
                            }
                          >
                            {res?.entranceFinish?.entranceTime === null ||
                            res?.entranceFinish?.entranceTime === undefined ||
                            res?.entranceFinish?.entranceTime === ""
                              ? "-"
                              : moment
                                  .utc(res?.entranceFinish?.entranceTime)
                                  .local()
                                  .format("HH : mm : ss")}
                          </span>
                          <span className="text-gray-600">
                            {res?.entranceFinish?.entranceTime === null ||
                            res?.entranceFinish?.entranceTime === undefined ||
                            res?.entranceFinish?.entranceTime === ""
                              ? "-"
                              : moment
                                  .utc(res?.entranceFinish?.entranceTime)
                                  .local()
                                  .format("DD MMM YYYY")}
                          </span>
                        </div>
                        <div className="absolute right-3">
                          <FontAwesomeIcon icon={faAngleRight} size="1x" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <DataNotFound title={t("accessibility.noData")} />
            )}
            {currentSize() >= 10 && totalElements >= 10 ? (
              <LoadMoreButton addMore={addMore} />
            ) : (
              ""
            )}
          </div>

          <Transition appear show={filter} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-2xl rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium text-center leading-6 text-gray-900"
                      >
                        Filter
                      </Dialog.Title>
                      <div className="p-4 bg-white rounded-xl">
                        <div className="pb-4">
                          <label>{t("accessibility.category")}</label>
                          <select
                            onChange={(e) =>
                              setNewEntranceState(e.target.value)
                            }
                            className="w-full rounded-lg bg-white text-black py-2 px-3 border"
                          >
                            <option value={"ENTRANCE_START"}>
                              {t("accessibility.start_access")}
                            </option>
                            <option value={"ENTRANCE_FINISH"}>
                              {t("accessibility.end_access")}
                            </option>
                          </select>
                        </div>
                        <div className="flex flex-row mb-4 gap-4">
                          <div className="flex w-1/2 flex-col relative">
                            <span>{t("accessibility.startDate")}</span>
                            <ReactDatePicker
                              selected={startDate}
                              onChange={(date: Date) => {
                                setStartDate(date);
                                setNewStartDate(
                                  moment(date)
                                    .format()
                                    .toString()
                                    .replace(":", "%3A")
                                    .replace(":", "%3A")
                                    .replace(":", "%3A")
                                    .replace("+", "%2B")
                                );
                              }}
                              selectsStart
                              startDate={startDate}
                              endDate={endDate}
                              dateFormat="dd-MM-yyyy"
                              maxDate={new Date()}
                              placeholderText={t("accessibility.selectDate")}
                              className="border-[1px] border-[#C4C4C4] text-left p-[13px] rounded-lg w-[100%] md:text-sm text-sm"
                            />
                            <img
                              src={calendar}
                              className="absolute right-3 mt-8"
                              alt=""
                            />
                          </div>
                          <div className="flex w-1/2 flex-col relative">
                            <span>{t("accessibility.endDate")}</span>
                            <ReactDatePicker
                              selected={startDate2}
                              onChange={(date: Date) => {
                                setStartDate2(date);
                                setNewEndDate(
                                  moment(date).format("YYYY-MM-DD") +
                                    "T23%3A59%3A59%2B07%3A00"
                                );
                              }}
                              selectsStart
                              startDate={startDate2}
                              endDate={endDate2}
                              dateFormat="dd-MM-yyyy"
                              maxDate={new Date()}
                              placeholderText={t("accessibility.selectDate")}
                              className="border-[1px] border-[#C4C4C4] text-left p-[13px] rounded-lg w-[100%] md:text-sm text-sm"
                            />
                            <img
                              src={calendar}
                              className="absolute right-3 mt-8"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="flex w-full gap-4">
                          <button
                            onClick={() => {
                              resetFilter();
                              setPage(1);
                              setNewEntranceState("");
                            }}
                            className="text-gray-500 border-1 border-gray-500 w-1/2 p-2 rounded-lg"
                          >
                            {t("accessibility.reset")}
                          </button>
                          <button
                            onClick={() => {
                              filterDate();
                              setPage(1);
                              setFilter(false);
                            }}
                            className="w-1/2 p-2 rounded-lg text-white"
                            style={{ backgroundColor: `${color.color1}` }}
                          >
                            {t("accessibility.apply")}
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          <Transition appear show={openMethod} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={resetMethode}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-2xl rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium text-center leading-6 text-gray-900"
                      >
                        {t("accessibility.select_method_access")}
                      </Dialog.Title>
                      <div className="p-4 bg-white min-w-[500px] rounded-xl">
                        <div className="flex flex-col my-4 text-left">
                          <div className="flex items-center pl-4 rounded dark:border-gray-700">
                            <input
                              checked={selectedMethode === "barcode"}
                              onChange={() => scanBarcodeRadio()}
                              id="bordered-radio-2"
                              type="radio"
                              value="barcode"
                              name="scan-barcode"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            ></input>
                            <label className="w-full my-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              {t("accessibility.scan_barcode")}
                            </label>
                          </div>
                          <div className="flex items-center pl-4 rounded dark:border-gray-700">
                            <input
                              checked={selectedMethode === "qr"}
                              onChange={() => scanQrRadio()}
                              id="bordered-radio-1"
                              type="radio"
                              value="qr"
                              name="create-qr"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            ></input>
                            <label className="w-full my-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              {t("accessibility.create_qr")}
                            </label>
                          </div>
                          {/* {act && (
                            <div className="p-2">
                              <span className="pl-4 font-bold">
                                Pilih Aktivitas
                              </span>
                              {activity.map((res) => {
                                return (
                                  <div
                                    key={res.id}
                                    className="flex items-center pl-4 rounded dark:border-gray-700"
                                  >
                                    <input
                                      checked={selectedAct === res.id}
                                      onChange={() => {
                                        setSelectedAct(res.id);
                                        // setRule(res.rule);
                                        setDataAct(res);
                                        setConfirm(true);
                                        console.log(res);
                                      }}
                                      id={res.id}
                                      type="radio"
                                      value={res.id}
                                      name="activity"
                                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    ></input>
                                    <label className="w-full my-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                      {res.name}
                                    </label>
                                  </div>
                                );
                              })}
                              {currentSizeActivity() >= 10 &&
                              totalElementsA >= 10 ? (
                                <div
                                  onClick={() => addMoreAct()}
                                  className="text-center cursor-pointer"
                                >
                                  <span>Selanjutnya {">>"}</span>
                                </div>
                              ) : null}
                            </div>
                          )} */}
                        </div>
                        <div className="flex w-full gap-4">
                          <button
                            onClick={() => resetMethode()}
                            className="text-gray-500 border-1 border-gray-500 w-1/2 p-2 rounded-lg"
                          >
                            {t("accessibility.reset")}
                          </button>
                          <button
                            disabled={confirm === false}
                            onClick={() => pilihMethode(dataAct)}
                            className="w-1/2 p-2 rounded-lg text-white disabled:bg-gray-500"
                            style={{ backgroundColor: `${color.color1}` }}
                          >
                            {t("accessibility.apply")}
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          <Transition appear show={showDetail} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>
              <div className="fixed inset-0 overflow-y-auto font-montserrat">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-2xl rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                      <div className="p-4 bg-white rounded-xl font-montserrat">
                        <div>
                          {detail?.entranceStateDuration ? (
                            detail?.entranceStateDuration !== "ON_DURATION" ? (
                              <div className="w-full rounded-xl bg-[#FF9723] bg-opacity-20 p-2 mb-4">
                                {t("accessibility.access")} {detail?.note}
                              </div>
                            ) : (
                              <div className="w-full rounded-xl bg-green bg-opacity-20 p-2 mb-4">
                                {t("accessibility.access_time_limit")}
                              </div>
                            )
                          ) : null}
                          <div className="w-full gap-2">
                            <div className="flex justify-between gap-5">
                              <span>{t("accessibility.activity_name")}</span>
                              <span className="text-right">
                                {detail?.entranceActivity?.name || "-"}
                              </span>
                            </div>
                            <div className="flex justify-between gap-5">
                              <span>{t("accessibility.position")}</span>
                              <span
                                className={
                                  detail?.lastPosition === "OUT"
                                    ? "text-orange-500"
                                    : "text-green"
                                }
                              >
                                {detail?.lastPosition === "OUT"
                                  ? t("accessibility.outside")
                                  : t("accessibility.inside") || "-"}
                              </span>
                            </div>
                            <div className="flex justify-between gap-5">
                              <span>{t("accessibility.duration_status")}</span>
                              <span
                                className={
                                  detail?.entranceStateDuration
                                    ? detail?.entranceStateDuration ===
                                      "ON_DURATION"
                                      ? "text-green"
                                      : "text-orange-500"
                                    : "text-black"
                                }
                              >
                                {detail?.entranceStateDuration
                                  ? detail?.entranceStateDuration ===
                                    "ON_DURATION"
                                    ? t("accessibility.within_limits")
                                    : t("accessibility.out_of_limit") || "-"
                                  : "-"}
                              </span>
                            </div>
                            <div className="flex justify-between gap-5 mb-3">
                              <span>{t("accessibility.access_limit")}</span>
                              <span>
                                {detail?.duration === 0 ||
                                detail?.duration === null ||
                                detail?.duration === undefined
                                  ? "-"
                                  : convertTime(detail?.duration)}
                              </span>
                            </div>
                            <span className="font-bold text-lg">
                              {t("accessibility.access_access")}
                            </span>
                            <div className="flex justify-between gap-5">
                              <span>{t("accessibility.method")}</span>
                              <span className="text-gray-600">
                                {detail?.entranceStart?.accessMethod ===
                                "QR_PRESENT"
                                  ? t("accessibility.scan_qr")
                                  : detail?.entranceStart?.accessMethod ===
                                    "QR_LISTEN"
                                  ? t("accessibility.create_code_qr")
                                  : t("accessibility.card") || "-"}
                              </span>
                            </div>
                            <div className="flex justify-between gap-5">
                              <span>{t("accessibility.device_name")}</span>
                              <span className="text-gray-600">
                                {detail?.entranceStart?.deviceVO?.name || "-"}
                              </span>
                            </div>
                            <div className="flex justify-between gap-5 mb-3">
                              <span>{t("accessibility.time")}</span>
                              <span className="text-gray-600">
                                {detail?.entranceStart?.entranceTime === "" ||
                                detail?.entranceStart?.entranceTime === null ||
                                detail?.entranceStart?.entranceTime ===
                                  undefined
                                  ? "-"
                                  : moment
                                      .utc(detail?.entranceStart?.entranceTime)
                                      .local()
                                      .format("HH : mm : ss")}
                              </span>
                            </div>
                            <span className="font-bold text-lg">
                              {t("accessibility.access_done_access")}
                            </span>
                            <div className="flex justify-between gap-5">
                              <span>{t("accessibility.method")}</span>
                              <span className="text-gray-600">
                                {detail?.entranceFinish?.accessMethod
                                  ? detail?.entranceFinish?.accessMethod ===
                                    "QR_PRESENT"
                                    ? t("accessibility.scan_qr")
                                    : detail?.entranceFinish?.accessMethod ===
                                      "QR_LISTEN"
                                    ? t("accessibility.create_code_qr")
                                    : t("accessibility.card") || "-"
                                  : "-"}
                              </span>
                            </div>
                            <div className="flex justify-between gap-5">
                              <span>{t("accessibility.device_name")}</span>
                              <span className="text-gray-600">
                                {detail?.entranceFinish?.deviceVO?.name || "-"}
                              </span>
                            </div>
                            <div className="flex justify-between gap-5">
                              <span>{t("accessibility.time")}</span>
                              <span className="text-gray-600">
                                {detail?.entranceFinish?.entranceTime === "" ||
                                detail?.entranceFinish?.entranceTime === null ||
                                detail?.entranceFinish?.entranceTime ===
                                  undefined
                                  ? "-"
                                  : moment
                                      .utc(detail?.entranceFinish?.entranceTime)
                                      .local()
                                      .format("HH : mm : ss")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            setShowDetail(false);
                          }}
                          className="w-full p-2 rounded-lg border-2 bg-transparent font-semibold mt-20"
                          style={{
                            borderColor: `${color.color1}`,
                            color: `${color.color1}`,
                          }}
                        >
                          {t("accessibility.back")}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </div>
  );
};

export default AccessFeaturesWeb;
