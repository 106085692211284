/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../../config/global";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
import Company from "../../../../core/models/company";
import Header from "../../../_common/headerComponent";
import * as API_BALANCE from "../../../../core/service/api_balance";
import { setLoading } from "../../../../core/feature/config/configSlice";
import iconSuccess from "../../../../assets/images/security_success.png";
import { useTranslation } from "react-i18next";

const PaymentSummaryMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  let location: any = useLocation();
  const [data, setData] = useState<any>({});
  const { t } = useTranslation();

  const getDetail = async () => {
    dispatch(setLoading(true));
    let transactionId = location.state.id;
    await API_BALANCE.getTransactionTransferMemberById(transactionId)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <section className="h-full min-h-screen flex flex-col font-montserrat bg-mobile">
      <Header navbarText="Transfer Member"></Header>
      <div className="container flex md:flex-row flex-col-reverse justify-center gap-4 md:mt-[64px] mt-[0px] px-0">
        <div className="md:w-3/5 w-full mx-auto mb-28">
          <div className="bg-white px-6 py-11 md:mb-10 mb-8 shadow-sm">
            <div className="flex flex-col items-center justify-center mb-4">
              <img src={iconSuccess} alt="" width={120} height={120} />
              <h5 className="text-green md:text-xl text-base font-medium mb-0 mt-2">
                {t('ppob.success')}
              </h5>
            </div>
            <div className="space-between gap-3">
              <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                {t('ppobHistory.transaction_date')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {moment(data?.createdTime).format("ll")}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                {t('ppobHistory.transaction_number')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {data?.id}
              </p>
            </div>
            <hr />
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                {t('transfer_bank.delivery')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {data?.userSourceName}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                {t('transfer_bank.to')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {data?.userDestName}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                {t('home.category')}
              </p>
              <div className="flex flex-row items-center gap-2">
                <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                  Transfer Member
                </p>
              </div>
            </div>
            <hr />
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                {t('whishlist.quantity')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {formatCurrency(data?.amount)}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                {t('withdraw_funds.adminFee')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {formatCurrency(data?.totalCharges)}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                Total
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {formatCurrency(data?.total)}
              </p>
            </div>
          </div>
          <div
            className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <button
              type="button"
              onClick={() => navigate(`/${company.initial}`, { replace: true })}
              style={{
                backgroundColor:
                  company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
              }}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              {t('card_security_code.done')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSummaryMobile;
