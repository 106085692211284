/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faTimes,
  faTimesRectangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import { formatCurrency, formatDate, hexToRgba } from "../../config/global.js";
import {
  getAllInvoice,
  getAllInvoiceSent
} from "../../core/service/api_invoice.js";
import DataNotFound from "../_common/dataNotFound";
import Header from "../_common/headerComponent";
import LoadMoreButton from "../_common/loadMoreButton";
import ReceivedInvoiceSkeleton from "../_common/skeleton/ReceivedInvoiceSkeleton";
import SentInvoiceSkeleton from "../_common/skeleton/SentInvoiceSkeleton";
import CustomTooltip from "../_common/tooltip";
import { useTranslation } from "react-i18next";

const InvoiceWeb = (props: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();

  const Riwayat = (prop: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState("createTime");
    const [dir, setdir] = useState(-1);
    const [status, setstatus] = useState<string[]>([
      "PARTIAL",
      "UNPAID",
      "PAID",
    ]);
    const [type, settype] = useState("");
    const [keyword, setKeyword] = useState("");
    const [totalElements, setTotalElements] = useState(0);
    const resetFilter = () => {
      settype("");
      setstatus([]);
      setSortBy("createTime");
      setPage(1);
      setdir(-1);
      setKeyword("");
    };
    const [dataInvoice, setdataInvoice] = useState<any[]>([]);
    const [modal, setModal] = useState(false);
    const typeList = ["MEMBER", "MERCHANT"];
    const sortList = [
      { value: 1, label: t('invoice.oldestToNewest') },
      { value: -1, label: t('invoice.newestToOldest') },
    ];
    const sortByList = [
      { value: "invoiceDate", label: t('invoice.dateInvoice') },
      { value: "expiredDate", label: t('invoice.dueDate') },
      { value: "createTime", label: t('invoice.createDate') },
    ];
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [isFirst, setIsFirst] = useState(true);

    const changeStatus = (value: string) => {
      let data: any = [];
      let isInclude = status.includes(value);
      if (isInclude) data = _.reject(status, (item) => item === value);
      else data = _.concat(status, value);

      setstatus(data);
    };

    useEffect(() => {
      if (!isFirst) {
        const timeOutId = setTimeout(() => {
          setPage(1);
          getInvoices(status, type, size, 1);
        }, 1000);
        return () => clearTimeout(timeOutId);
      }
    }, [keyword]);

    useEffect(() => {
      setPage(1);
      getInvoices(status, type, size, 1);
    }, [status, type, startDate, endDate]);

    const getInvoices = (
      varStatus: any = status,
      varType: any = type,
      varSize: any = size,
      varPage: any = page,
      varSort: any = sortBy,
      varDir: any = dir,
      varTitle: any = keyword,
      varStartDate: any = startDate,
      varEndDate: any = endDate
    ) => {
      if (prop && prop.openTab !== 2) return;
      setIsLoading(true);

      getAllInvoiceSent(
        varSize,
        varPage,
        varSort,
        varDir,
        varStatus,
        varType,
        varTitle,
        (varStartDate = varStartDate
          ? formatDate(varStartDate, "YYYY-MM-DD")
          : varStartDate),
        (varEndDate = varEndDate
          ? formatDate(varEndDate, "YYYY-MM-DD")
          : varEndDate)
      )
        .then((res) => {
          setIsLoading(false);
          if (varPage > 1) {
            let tempDataInvoice = [...dataInvoice];
            res.data.data.map((el: any) => {
              let findInvoice = tempDataInvoice.find(
                (i: any) => i._id === el._id
              );
              if (findInvoice === undefined) tempDataInvoice.push(el);
            });
            setdataInvoice(tempDataInvoice);
          } else {
            setdataInvoice(res.data.data);
          }
          setTotalElements(res.data.total);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };

    const addMore = async () => {
      setPage(page + 1);
      getInvoices(status, type, size, page + 1);
    };

    const changeDate = (date: any, isStart: boolean = false) => {
      if (isStart) return setStartDate(date);
      return setEndDate(date);
    };

    useEffect(() => {
      if (isFirst) setIsFirst(false);
    }, []);

    return (
      <>
        <div className="flex flex-col gap-3">
          <div className="bg-white flex flex-col gap-4">
            <div className="h-full w-[600px] m-auto px-12 py-4 gap-2 flex flex-col">
              <div className="flex items-center gap-2">
                <input
                  type="search"
                  className="px-2 rounded-md w-full p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 "
                  placeholder={t('invoice.placeholderInvoice')}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <button
                  className="bg-[#FFFFFF] text-[#D9D9D9] border-1 border-solid border-neutral-200 rounded-md flex justify-center items-center h-10 w-28"
                  onClick={() => {
                    if (!modal) setModal(!modal);
                  }}
                >
                  {/* <img src={SettingsIcon} alt="" />&nbsp; */}
                  {t('invoice.sort')}&nbsp;
                  <FontAwesomeIcon
                    icon={!isOpen ? faChevronDown : faChevronUp}
                  ></FontAwesomeIcon>
                </button>
              </div>
              <div className="p-1 flex flex-col gap-2">
                <div
                  className="text-themeMuted text-xs font-medium cursor-pointer select-none w-fit"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Filter&nbsp;
                  <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                </div>
                <div className="flex flex-col gap-2" hidden={!isOpen}>
                  <div className="flex gap-2 flex-wrap">
                    <button
                      onClick={() => changeStatus("PAID")}
                      type="button"
                      style={{ backgroundColor: props.configColor?.color1 }}
                      className={`${status.includes("PAID")
                        ? "bg-themePrimary text-white border-1 border-themePrimary"
                        : "text-[#94959E] bg-white border-1 border-[#94959E]"
                        }  w-fit px-4 min-h-8 rounded-md`}
                    >
                      {t('invoice.paidOff')}&nbsp;
                      {status.includes("PAID") ? (
                        <FontAwesomeIcon
                          icon={faTimesRectangle}
                        ></FontAwesomeIcon>
                      ) : null}
                    </button>
                    <button
                      onClick={() => changeStatus("UNPAID")}
                      type="button"
                      style={{ backgroundColor: props.configColor?.color1 }}
                      className={`${status.includes("UNPAID")
                        ? "bg-themePrimary text-white border-1 border-themePrimary"
                        : "text-[#94959E] bg-white border-1 border-[#94959E]"
                        }  w-fit px-4 min-h-8 rounded-md`}
                    >
                      {t('invoice.unpaid')}&nbsp;
                      {status.includes("UNPAID") ? (
                        <FontAwesomeIcon
                          icon={faTimesRectangle}
                        ></FontAwesomeIcon>
                      ) : null}
                    </button>
                    <button
                      onClick={() => changeStatus("PARTIAL")}
                      type="button"
                      style={{ backgroundColor: props.configColor?.color1 }}
                      className={`${status.includes("PARTIAL")
                        ? "bg-themePrimary text-white border-1 border-themePrimary"
                        : "text-[#94959E] bg-white border-1 border-[#94959E]"
                        }  w-fit px-4 min-h-8 rounded-md`}
                    >
                      {t('invoice.Installment')}&nbsp;
                      {status.includes("PARTIAL") ? (
                        <FontAwesomeIcon
                          icon={faTimesRectangle}
                        ></FontAwesomeIcon>
                      ) : null}
                    </button>
                  </div>
                  <div className="flex gap-2 flex-wrap">
                    <ReactDatePicker
                      wrapperClassName="max-w-[225px]"
                      className="rounded-[6px] md:px-[1rem] px-[12px] min-h-8 border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                      placeholderText={t('invoice.startDate')}
                      selected={startDate}
                      dateFormat="dd MMMM yyyy"
                      onChange={(evt: any) => changeDate(evt, true)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      maxDate={endDate || null}
                      isClearable
                      dropdownMode="select"
                    />
                    <ReactDatePicker
                      wrapperClassName="max-w-[225px]"
                      className="rounded-[6px] md:px-[1rem] px-[12px] min-h-8 border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                      placeholderText={t('invoice.endDate')}
                      selected={endDate}
                      dateFormat="dd MMMM yyyy"
                      onChange={(evt: any) => changeDate(evt)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      isClearable
                      minDate={startDate || null}
                      dropdownMode="select"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="text-themeMuted text-xs font-medium cursor-pointer select-none w-fit">
                      {t('invoice.SentTo')}
                    </div>
                    <div className="flex gap-3 flex-wrap">
                      {_.map(typeList, (item) => (
                        <button
                          onClick={() => settype(item)}
                          type="button"
                          style={{ backgroundColor: props.configColor?.color1 }}
                          className={`${type === item
                            ? "bg-themePrimary text-white"
                            : "text-[#94959E] bg-white border-1 border-[#94959E]"
                            } w-fit px-4 min-h-8 rounded-md`}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white flex flex-col gap-4">
            <div className="h-full w-[600px] m-auto px-12 py-4 gap-2 flex flex-col">
              {dataInvoice.length > 0 &&
                _.map(dataInvoice, (item: any) => (
                  <div
                    key={item._id}
                    className="flex flex-col gap-1 bg-white rounded-xl px-3 py-[20px] border-[1px] border-[#CCCCCC] cursor-pointer min-w-[300px]"
                    onClick={() =>
                      navigate(`/${props.company?.initial}/invoices/${item._id}`, {
                        state: {
                          item: item,
                          type: "sent",
                          createdBy: "MEMBER",
                          userType: item.userType,
                        },
                      })
                    }
                  >
                    <div className="flex flex-col xs:flex-row xs:items-center items-start justify-between">
                      <div className="flex gap-1 items-center justify-between xs:justify-start flex-grow w-fit xs:max-w-[calc(100%-9.5rem)] max-w-[calc(100%-12.5rem)] ">
                        <CustomTooltip
                          className="min-w-[80px] max-w-[calc(100%-1rem)] "
                          // className=" max-w-[calc(100%-7rem)] "
                          content={
                            <div className="w-96 whitespace-pre-line">
                              {item.title}
                            </div>
                          }
                          direction="bottom"
                          children={
                            <div
                              className="text-sm font-semibold  whitespace-nowrap overflow-hidden truncate "
                              style={{ color: props.color.color1 }}
                            >
                              {item.title}
                            </div>
                          }
                        ></CustomTooltip>
                        {item.config?.isAutoPayment && (
                          <span className="text-[10px] text-themeYellow font-semibold bg-themeYellow bg-opacity-20 px-2 rounded-md min-w-[4rem]">
                            {t('invoice.autoPay')}
                          </span>
                        )}
                      </div>
                      <div className="-order-1 xs:order-1 text-center text-xs text-white font-semibold">
                        {item.paymentStatus === "PARTIAL" && (
                          <div className="px-4 py-2 rounded-lg bg-themeWarning">
                            {t('invoice.Installment')}
                          </div>
                        )}
                        {item.paymentStatus === "PAID" && (
                          <div className="px-4 py-2 rounded-lg bg-themePrimary">
                            {t('invoice.paidOff')}
                          </div>
                        )}
                        {item.paymentStatus === "UNPAID" && (
                          <div className="px-4 py-2 rounded-lg bg-themeDanger">
                            {t('invoice.unpaid')}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-themePrimary md:text-sm text-xs font-light">
                      {t('invoice.billFrom')} : {"MEMBER"}
                    </div>
                    <div className="text-thirdy font-light md:text-sm text-xs">
                      {moment(new Date(item.invoiceDate)).format("DD MMM YYYY")}
                    </div>
                    <div
                      className="space-between md:text-sm text-xs  font-semibold p-3 rounded-md mt-2"
                      style={{
                        color: props.color.color1,
                        backgroundColor: hexToRgba(props.color.color1, 0.1),
                      }}
                    >
                      <span> {t('invoice.totalPayment')}</span>
                      <span>{formatCurrency(item.amount)}</span>
                    </div>
                  </div>
                ))}

              {!isLoading && dataInvoice.length === 0 && (
                <DataNotFound
                  type="invoice"
                  desc={t('invoice.noData')}
                />
              )}
              {isLoading && (
                <>
                  {_.map(new Array(size), (el) => (
                    <SentInvoiceSkeleton></SentInvoiceSkeleton>
                  ))}
                </>
              )}

              {totalElements > size * page && (
                <LoadMoreButton addMore={addMore} />
              )}
            </div>
          </div>
          <button
            type="button"
            style={{ backgroundColor: props.configColor?.color1 }}
            onClick={() => navigate("send")}
            className="fixed bottom-0 z-[2] right-[3rem] rounded-[50%] bg-themePrimary text-white text-[50px] font-light flex justify-center items-center w-[72px] h-[72px] my-4"
          >
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </button>

          {/* Modal switch */}
          <div
            className={`${modal ? "block" : "hidden"} relative z-50`}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[50%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
                  <div className="text-end md:px-[0px] px-[0.5rem]">
                    <button
                      type="button"
                      onClick={() => {
                        setModal(false);
                      }}
                      className="fa-xl text-gray-400"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                  <div className="text-left mb-2">
                    <div className="px-3 py-4 flex flex-col gap-4 ">
                      <div className="font-semibold ">{t('invoice.sort')}</div>

                      <div className="flex flex-col gap-2">
                        <div className="text-[#70717D] text-xs font-medium">
                          {t('invoice.selectType')}
                        </div>
                        <div className="flex flex-col" key={sortBy}>
                          {_.map(sortByList, (item) => (
                            <div
                              className="form-check form-check-inline"
                              key={`sorting-sort1-${item.value}`}
                            >
                              <input
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                                type="radio"
                                name="sortingSort1"
                                id={`sorting-sort1-${item.value}`}
                                value={item.value}
                                checked={sortBy === item.value}
                                onChange={() => setSortBy(item.value)}
                              />
                              <label
                                className="form-check-label inline-block text-gray-800"
                                htmlFor={`sorting-sort1-${item.value}`}
                              // onClick={() => setSortBy(item.value)}
                              >
                                {item.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="text-[#70717D] text-xs font-medium">
                          {t('invoice.selectSort')}
                        </div>
                        <div className="flex flex-col" key={dir}>
                          {_.map(sortList, (item) => (
                            <div
                              className="form-check form-check-inline "
                              key={`sorting-dir1-${item.value}`}
                            >
                              <input
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                                type="radio"
                                name="sortingDir1"
                                id={`sorting-dir1-${item.value}`}
                                value={item.value}
                                checked={dir === item.value}
                                onChange={() => setdir(item.value)}
                              />
                              <label
                                className="form-check-label inline-block text-gray-800"
                                htmlFor={`sorting-dir1-${item.value}`}
                              // onClick={() => setdir(item.value)}
                              >
                                {item.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 mt-4">
                        <button
                          onClick={() => {
                            setModal(false);
                            setPage(1);
                            getInvoices(status, type, size, 1);
                          }}
                          type="button"
                          style={{ backgroundColor: props.configColor?.color1 }}
                          className=" text-white w-full h-10 rounded-md"
                        >
                          {t('invoice.apply')}
                        </button>
                        <button
                          type="button"
                          className="border-1 border-themeMuted bg-white text-themeMuted w-full h-10 rounded-md"
                          onClick={() => resetFilter()}
                        >
                          {t('invoice.reset')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* --- */}
        </div>
      </>
    );
  };

  const Invoice = (prop: any) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState("expiredDate");
    const [dir, setdir] = useState(1);
    const [status, setstatus] = useState<string[]>(["PARTIAL", "UNPAID"]);
    const [type, settype] = useState("");
    const [keyword, setKeyword] = useState("");
    const [totalElements, setTotalElements] = useState(0);
    const resetFilter = () => {
      settype("");
      setstatus([]);
      setSortBy("expiredDate");
      setdir(1);
      setPage(1);
      setKeyword("");
    };
    const [dataInvoice, setdataInvoice] = useState<any[]>([]);
    const [modal, setModal] = useState(false);
    const typeList = ["ADMIN", "MEMBER", "MERCHANT"];
    const sortList = [
      { value: 1, label: t('invoice.oldestToNewest') },
      { value: -1, label: t('invoice.newestToOldest') },
    ];
    const sortByList = [
      { value: "invoiceDate", label: t('invoice.dateInvoice') },
      { value: "expiredDate", label: t('invoice.dueDate') },
      { value: "createTime", label: t('invoice.createDate') },
    ];
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [isFirst, setIsFirst] = useState(true);

    const changeStatus = (value: string) => {
      let data: any = [];
      let isInclude = status.includes(value);
      if (isInclude) data = _.reject(status, (item) => item === value);
      else data = _.concat(status, value);

      setstatus(data);
    };

    useEffect(() => {
      if (!isFirst) {
        const timeOutId = setTimeout(() => {
          setPage(1);
          getInvoices(status, type, size, 1);
        }, 1000);
        return () => clearTimeout(timeOutId);
      }
    }, [keyword]);

    useEffect(() => {
      setPage(1);
      getInvoices(status, type, size, 1);
    }, [status, type, startDate, endDate]);

    const getInvoices = (
      varStatus: any = status,
      varType: any = type,
      varSize: any = size,
      varPage: any = page,
      varSort: any = sortBy,
      varDir: any = dir,
      varTitle: any = keyword,
      varStartDate: any = startDate,
      varEndDate: any = endDate
    ) => {
      if (prop && prop.openTab !== 1) return;
      setIsLoading(true);

      getAllInvoice(
        varSize,
        varPage,
        varSort,
        varDir,
        varStatus,
        varType,
        varTitle,
        (varStartDate = varStartDate
          ? formatDate(varStartDate, "YYYY-MM-DD")
          : varStartDate),
        (varEndDate = varEndDate
          ? formatDate(varEndDate, "YYYY-MM-DD")
          : varEndDate)
      )
        .then((res) => {
          setIsLoading(false);
          if (varPage > 1) {
            let tempDataInvoice = [...dataInvoice];
            res.data.data.map((el: any) => {
              let findInvoice = tempDataInvoice.find(
                (i: any) => i.idInvoice === el.idInvoice
              );
              if (findInvoice === undefined) tempDataInvoice.push(el);
            });
            setdataInvoice(tempDataInvoice);
          } else {
            setdataInvoice(res.data.data);
          }

          setTotalElements(res.data.total);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };

    const addMore = async () => {
      setPage(page + 1);
      getInvoices(status, type, size, page + 1);
    };

    const changeDate = (date: any, isStart: boolean = false) => {
      if (isStart) return setStartDate(date);
      return setEndDate(date);
    };

    useEffect(() => {
      if (isFirst) setIsFirst(false);
    }, []);

    return (
      <div className="flex flex-col gap-3">
        <div className="bg-white flex flex-col gap-4">
          <div className="h-full w-[600px] m-auto px-12 py-4 gap-2 flex flex-col">
            <div className="flex items-center gap-2">
              <input
                type="search"
                className="px-2 rounded-md w-full p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 "
                placeholder={t('invoice.placeholderInvoice')}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <button
                className="bg-[#FFFFFF] text-[#D9D9D9] border-1 border-solid border-neutral-200 rounded-md flex justify-center items-center h-10 w-28"
                onClick={() => {
                  if (!modal) setModal(!modal);
                }}
              >
                {/* <img src={SettingsIcon} alt="" />&nbsp; */}
                {t('invoice.sort')}&nbsp;
                <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
              </button>
            </div>
            <div className="p-1 flex flex-col gap-2">
              <div
                className="text-themeMuted text-xs font-medium cursor-pointer select-none w-fit"
                onClick={() => setIsOpen(!isOpen)}
              >
                Filter&nbsp;
                <FontAwesomeIcon
                  icon={!isOpen ? faChevronDown : faChevronUp}
                ></FontAwesomeIcon>
              </div>
              <div className="flex flex-col gap-2" hidden={!isOpen}>
                <div className="flex gap-2 flex-wrap">
                  <button
                    onClick={() => changeStatus("PAID")}
                    type="button"
                    style={{ backgroundColor: props.configColor?.color1 }}
                    className={`${status.includes("PAID")
                      ? "bg-themePrimary text-white border-1 border-themePrimary"
                      : "text-[#94959E] bg-white border-1 border-[#94959E]"
                      }  w-fit px-4 min-h-8 rounded-md`}
                  >
                    {t('invoice.paidOff')}&nbsp;
                    {status.includes("PAID") ? (
                      <FontAwesomeIcon
                        icon={faTimesRectangle}
                      ></FontAwesomeIcon>
                    ) : null}
                  </button>
                  <button
                    onClick={() => changeStatus("UNPAID")}
                    type="button"
                    style={{ backgroundColor: props.configColor?.color1 }}
                    className={`${status.includes("UNPAID")
                      ? "bg-themePrimary text-white border-1 border-themePrimary"
                      : "text-[#94959E] bg-white border-1 border-[#94959E]"
                      }  w-fit px-4 min-h-8 rounded-md`}
                  >
                    {t('invoice.unpaid')}&nbsp;
                    {status.includes("UNPAID") ? (
                      <FontAwesomeIcon
                        icon={faTimesRectangle}
                      ></FontAwesomeIcon>
                    ) : null}
                  </button>
                  <button
                    onClick={() => changeStatus("PARTIAL")}
                    type="button"
                    style={{ backgroundColor: props.configColor?.color1 }}
                    className={`${status.includes("PARTIAL")
                      ? "bg-themePrimary text-white border-1 border-themePrimary"
                      : "text-[#94959E] bg-white border-1 border-[#94959E]"
                      }  w-fit px-4 min-h-8 rounded-md`}
                  >
                    {t('invoice.Installment')}&nbsp;
                    {status.includes("PARTIAL") ? (
                      <FontAwesomeIcon
                        icon={faTimesRectangle}
                      ></FontAwesomeIcon>
                    ) : null}
                  </button>
                </div>
                <div className="flex gap-2 flex-wrap">
                  <ReactDatePicker
                    wrapperClassName="max-w-[225px]"
                    className="rounded-[6px] md:px-[1rem] px-[12px] min-h-8 border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                    placeholderText={t('invoice.startDate')}
                    selected={startDate}
                    dateFormat="dd MMMM yyyy"
                    onChange={(evt: any) => changeDate(evt, true)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    maxDate={endDate || null}
                    isClearable
                    dropdownMode="select"
                  />
                  <ReactDatePicker
                    wrapperClassName="max-w-[225px]"
                    className="rounded-[6px] md:px-[1rem] px-[12px] min-h-8 border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                    placeholderText={t('invoice.endDate')}
                    selected={endDate}
                    dateFormat="dd MMMM yyyy"
                    onChange={(evt: any) => changeDate(evt)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    isClearable
                    minDate={startDate || null}
                    dropdownMode="select"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-themeMuted text-xs font-medium cursor-pointer select-none w-fit">
                    {t('invoice.SentTo')}
                  </div>
                  <div className="flex gap-3 flex-wrap">
                    {_.map(typeList, (item) => (
                      <button
                        onClick={() => settype(item)}
                        type="button"
                        style={{ backgroundColor: props.configColor?.color1 }}
                        className={`${type === item
                          ? "bg-themePrimary text-white"
                          : "text-[#94959E] bg-white border-1 border-[#94959E]"
                          } w-fit px-4 min-h-8 rounded-md`}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white flex flex-col gap-4">
          <div className="h-full w-[600px] m-auto px-12 py-4 gap-2 flex flex-col">
            {dataInvoice.length > 0 &&
              _.map(dataInvoice, (item: any, index: any) => (
                <div
                  key={`invoice-received-${index}`}
                  className="flex flex-col gap-1 bg-white rounded-xl p-3 border-[1px] border-[#CCCCCC] cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/${props.company?.initial}/invoices/${item.idInvoice}`,
                      {
                        state: {
                          type: "receive",
                          createdBy: item.createdBy,
                          userType: item.userType,
                        },
                      }
                    )
                  }
                >
                  <div className="flex flex-col xs:flex-row xs:items-center items-start justify-between">
                    <div className="flex gap-1 items-center justify-between xs:justify-start flex-grow w-fit xs:max-w-[calc(100%-9.5rem)] max-w-[calc(100%-12.5rem)] ">
                      <CustomTooltip
                        className="min-w-[80px] max-w-[calc(100%-1rem)] "
                        // className=" max-w-[calc(100%-7rem)] "
                        content={
                          <div className="w-96 whitespace-pre-line">
                            {item.title}
                          </div>
                        }
                        direction="bottom"
                        children={
                          <div
                            className="text-sm font-semibold  whitespace-nowrap overflow-hidden truncate "
                            style={{ color: props.color.color1 }}
                          >
                            {item.title}
                          </div>
                        }
                      ></CustomTooltip>
                      {item.config?.isAutoPayment && (
                        <span className="text-[10px] text-themeYellow font-semibold bg-themeYellow bg-opacity-20 px-2 rounded-md min-w-[4rem]">
                          {t('invoice.autoPay')}
                        </span>
                      )}
                    </div>
                    <div className="-order-1 xs:order-1 text-center text-xs text-white font-semibold">
                      {item.paymentStatus === "PARTIAL" && (
                        <div className="px-4 py-2 rounded-lg bg-themeWarning">
                          {t('invoice.Installment')}
                        </div>
                      )}
                      {item.paymentStatus === "PAID" && (
                        <div className="px-4 py-2 rounded-lg bg-themePrimary">
                          {t('invoice.paidOff')}
                        </div>
                      )}
                      {item.paymentStatus === "UNPAID" && (
                        <div className="px-4 py-2 rounded-lg bg-themeDanger">
                          {t('invoice.unpaid')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-themePrimary text-sm font-semibold  grow  ">
                    {t('invoice.billFrom')} : {item.createdBy}
                  </div>
                  <div className="text-gray-500 font-light text-xs">
                    {moment(new Date(item.invoiceDate)).format("DD MM YYYY")}
                  </div>
                  {/* <hr /> */}
                  <div
                    className="flex justify-between text-xs  font-semibold  p-3 rounded-lg"
                    style={{
                      color: props.color.color1,
                      backgroundColor: hexToRgba(props.color.color1, 0.1),
                    }}
                  >
                    <span>{t('invoice.totalPayment')}</span>
                    <span>{formatCurrency(item.amount)}</span>
                  </div>
                </div>
              ))}
            {!isLoading && dataInvoice.length === 0 && (
              <DataNotFound type="invoice" desc={t('invoice.noData')} />
            )}
            {isLoading && (
              <>
                {_.map(new Array(size), (el) => (
                  <ReceivedInvoiceSkeleton></ReceivedInvoiceSkeleton>
                ))}
              </>
            )}
            {totalElements > size * page && (
              <LoadMoreButton addMore={addMore} />
            )}
          </div>
        </div>

        {/* Modal switch */}
        <div
          className={`${modal ? "block" : "hidden"} relative z-50`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[50%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
                <div className="text-end md:px-[0px] px-[0.5rem]">
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false);
                    }}
                    className="fa-xl text-gray-400"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <div className="text-left mb-2">
                  <div className="px-3 py-4 flex flex-col gap-4 ">
                    <div className="font-semibold ">{t('invoice.sort')}</div>
                    <div className="flex flex-col gap-2">
                      <div className="text-[#70717D] text-xs font-medium">
                        {t('invoice.selectType')}
                      </div>
                      <div className="flex flex-col" key={sortBy}>
                        {_.map(sortByList, (item) => (
                          <div
                            className="form-check form-check-inline"
                            key={`sorting-sort2-${item.value}`}
                          >
                            <input
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                              type="radio"
                              name="sortingSort2"
                              id={`sorting-sort2-${item.value}`}
                              value={item.value}
                              checked={sortBy === item.value}
                              onChange={(e) => setSortBy(item.value)}
                            />
                            <label
                              className="form-check-label inline-block text-gray-800"
                              htmlFor={`sorting-sort2-${item.value}`}
                            >
                              {item.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="text-[#70717D] text-xs font-medium">
                        {t('invoice.selectSort')}
                      </div>
                      <div className="flex flex-col" key={dir}>
                        {_.map(sortList, (item) => (
                          <div
                            className="form-check form-check-inline"
                            key={`sorting-dir2-${item.value}`}
                          >
                            <input
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                              type="radio"
                              name="sortingDir2"
                              id={`sorting-dir2-${item.value}`}
                              value={item.value}
                              checked={dir === item.value}
                              onChange={() => setdir(item.value)}
                            />
                            <label
                              className="form-check-label inline-block text-gray-800"
                              htmlFor={`sorting-dir2-${item.value}`}
                            >
                              {item.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-4">
                      <button
                        onClick={() => {
                          setModal(false);
                          setPage(1);
                          getInvoices(status, type, size, 1);
                        }}
                        type="button"
                        style={{ backgroundColor: props.configColor?.color1 }}
                        className="bg-themePrimary text-white w-full h-10 rounded-md"
                      >
                        {t('invoice.apply')}
                      </button>
                      <button
                        type="button"
                        className="border-1 border-themeMuted bg-white text-themeMuted w-full h-10 rounded-md"
                        onClick={() => resetFilter()}
                      >
                        {t('invoice.reset')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
      </div>
    );
  };

  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <Header backToUrl={`/${props.company?.initial}`}></Header>
        <div className="flex flex-col items-center justify-center">
          <div className="w-[600px] m-auto px-12 py-4">
            <ul
              style={{ backgroundColor: props.configColor?.color1 }}
              className="flex gap-3 w-full p-1 rounded-lg"
            >
              <li className="flex w-full">
                <button
                  onClick={() => props.setOpenTab(1)}
                  className={` ${props.openTab === 1 ? "bg-white " : "bg-transparent"
                    } inline-block px-2 py-2 rounded-lg w-full md:text-base text-sm`}
                  style={{ color: props.openTab === 1 ? props.color.color1 : "#FFFFFF" }}
                >
                  {t('invoice.billsForYou')}
                </button>
              </li>
              {props.showSentInvoice && (
                <li className="flex w-full">
                  <button
                    onClick={() => props.setOpenTab(2)}
                    className={` ${props.openTab === 2 ? "bg-white " : "bg-transparent"
                      } inline-block px-2 py-2 rounded-lg w-full md:text-base text-sm`}
                    style={{ color: props.openTab === 2 ? props.color.color1 : "#FFFFFF" }}
                  >
                    {t('invoice.sendInvoice')}
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className="w-100">
            <div className={props.openTab === 1 ? "block" : "hidden"}>
              <Invoice openTab={props.openTab} />
            </div>
            {props.showSentInvoice && (
              <div className={props.openTab === 2 ? "block" : "hidden"}>
                <Riwayat openTab={props.openTab} />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default InvoiceWeb;