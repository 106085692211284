/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import VirtualCardMutationWeb from "./virtualCardMutationWeb";
import VirtualCardMutationMobile from "./virtualCardMutationMobile";
import { useParams } from "react-router-dom";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import TopUp from "../../../assets/images/vcard_topup.svg";
import TopUpViaMerchant from "../../../assets/images/topup_admin.svg";
import Pencairan from "../../../assets/images/vcard_withdraw.svg";
import Pembayaran from "../../../assets/images/card_transaction.svg";
import { convertToUTC } from "../../../config/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DateRange } from "react-date-range";
import Company from "../../../core/models/company";
import Card from "../../../assets/images/icon_transaksi/cards.svg"
import { useTranslation } from "react-i18next";

const VirtualCardMutation = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [transactionType, setTransactionType] = React.useState("");
  const [data, setData] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(1);
  const [totalElements, setTotalElements] = React.useState(0);
  const [showDate, setShowDate] = React.useState(false);
  const [currentTransactionType, setCurrentTransactionType] = React.useState(t('mutation_virtual_card.selectTransaction'));
  const [showTransactionType, setShowTransactionType] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const listTransactionType = [
    {
      id: 1,
      name: t('mutation_virtual_card.top_up_merchant'),
      value: "TOP_UP_CARD_BALANCE_MERCHANT",
      image: TopUpViaMerchant,
      type: "DEBT",
    },
    {
      id: 2,
      name: "Top Up",
      value: "TOP_UP_CARD_BALANCE",
      image: TopUp,
      type: "DEBT",
    },
    {
      id: 3,
      name: t('mutation_virtual_card.disbursement'),
      value: "WITHDRAW_CARD_BALANCE",
      image: Pencairan,
      type: "CREDIT",
    },
    {
      id: 4,
      name: t('mutation_virtual_card.card_balance_withdrawal_merchant'),
      value: "WITHDRAW_CARD_BALANCE_MERCHANT",
      image: Pencairan,
      type: "CREDIT",
    },
    {
      id: 5,
      name: t('mutation_virtual_card.payment'),
      value: "CARD_PAYMENT_MERCHANT",
      image: Pembayaran,
      type: "CREDIT",
    },
    {
      id: 6,
      name: t('mutation_virtual_card.fnb_card_transactions'),
      value: "COMMERCE_FNB_PAYMENT_CARD",
      image: Pembayaran,
      type: "DEBT",
    },
    {
      id: 7,
      name: t('mutation_virtual_card.gateway_card_transactions'),
      value: "CARD_PAYMENT_GATEWAY",
      image: Card,
      type: "DEBT",
    },
  ];

  const getMutations = async (
    startDate: any = "",
    endDate: any = "",
    currentPage: any = page,
    currentTransactionType: string = transactionType,
  ) => {
    dispatch(setLoading(true));
    let query = {
      startDate,
      endDate,
      page: currentPage,
      size: 10,
      sortBy: "createdTime",
      dir: -1,
      transactionType: currentTransactionType
    };
    await API_VIRTUAL_CARD.getMutations(params.id, query)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        setTotalElements(res.data.total);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const changeDate = (item: any) => {
    setSelectedDate([item.selection]);
    setStartDate(
      convertToUTC(item.selection.startDate.setHours(0o0, 0o0, 0o0))
    );
    setEndDate(convertToUTC(item.selection.endDate.setHours(23, 59, 59)));
  };

  const resetDate = () => {
    setShowDate(false);
    setSelectedDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStartDate("");
    setEndDate("");
    setPage(1);
    getMutations("", "", 1);
  };

  const confirmDate = (type: string = transactionType) => {
    setShowDate(false);
    setPage(1);
    getMutations(startDate, endDate, 1, type);
  }

  function currentSize() {
    let a = data.length;
    let b = a / page;

    return b;
  }

  const addMore = async () => {
    setPage(page + 1);
    dispatch(setLoading(true));
    let query = {
      startDate,
      endDate,
      page: page + 1,
      size: 10,
      sortBy: "createdTime",
      dir: -1,
      transactionType
    };
    await API_VIRTUAL_CARD.getMutations(params.id, query)
      .then((res) => {
        dispatch(setLoading(false));
        setData(data.concat(res.data.data));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    getMutations();
  }, []);

  return (
    <>
      <BrowserView>
        <VirtualCardMutationWeb
          changeDate={(item: any) => changeDate(item)}
          confirmDate={(type: string) => confirmDate(type)}
          resetDate={() => resetDate()}
          addMore={() => addMore()}
          setTransactionType={(value: any) => setTransactionType(value)}
          setShowDate={(value: any) => setShowDate(value)}
          setStartDate={(value: any) => setStartDate(value)}
          setEndDate={(value: any) => setEndDate(value)}
          setShowTransactionType={(value: any) => setShowTransactionType(value)}
          currentSize={currentSize}
          listTransactionType={listTransactionType}
          data={data}
          totalElements={totalElements}
          showDate={showDate}
          selectedDate={selectedDate}
          currentTransactionType={currentTransactionType}
        />
      </BrowserView>
      <MobileView>
        <VirtualCardMutationMobile
          changeDate={(item: any) => changeDate(item)}
          confirmDate={(type: string) => confirmDate(type)}
          resetDate={() => resetDate()}
          addMore={() => addMore()}
          setTransactionType={(value: any) => setTransactionType(value)}
          setShowDate={(value: any) => setShowDate(value)}
          setStartDate={(value: any) => setStartDate(value)}
          setEndDate={(value: any) => setEndDate(value)}
          setShowTransactionType={(value: any) => setShowTransactionType(value)}
          currentSize={currentSize}
          listTransactionType={listTransactionType}
          data={data}
          totalElements={totalElements}
          showDate={showDate}
          selectedDate={selectedDate}
          currentTransactionType={currentTransactionType}
        />
      </MobileView>

      {/* MODAL */}

      <div className={`${showDate ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-auto sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="space-between md:px-[0px] px-[0.5rem]">
                <h4 className="md:text-xl text-lg text-black font-medium mb-0">{t('mutation_virtual_card.dateFilter')}</h4>
                <button
                  type="button"
                  onClick={() => resetDate()}
                >
                  <FontAwesomeIcon icon={faTimes} className="fa-xl" />
                </button>
              </div>
              <hr />
              <div className="flex flex-col justify-center">
                <DateRange
                  editableDateInputs={true}
                  onChange={item => changeDate(item)}
                  moveRangeOnFirstSelection={false}
                  ranges={selectedDate}
                  maxDate={new Date()}
                />
                <div className="flex items-center justify-end gap-3">
                  <button
                    type="button"
                    className="bg-[#EC1A1A] text-white rounded-[8px] p-[10px] w-full"
                    onClick={() => resetDate()}
                  >
                    {t('mutation_virtual_card.reset')}
                  </button>
                  <button
                    type="button"
                    style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                    className="bg-green text-white rounded-[8px] p-[10px] w-full"
                    onClick={() => confirmDate()}
                  >
                    {t('mutation_virtual_card.confirm')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${showTransactionType ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-sm transition-all md:w-[40%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="space-between md:px-[0px] px-[0.5rem]">
                <h4 className="md:md:text-xl text-lg text-customBlack font-medium mb-0">
                  {t('mutation_virtual_card.selectTransaction')}
                </h4>
                <button
                  type="button"
                  onClick={() => setShowTransactionType(false)}
                >
                  <FontAwesomeIcon icon={faTimes} className="fa-xl" />
                </button>
              </div>
              <hr />
              <div className="flex flex-col justify-center">
                <ul className="px-3 mb-0">
                  {listTransactionType.map((item) => (
                    <li
                      className="list-none cursor-pointer hover:bg-teal-50"
                      key={item.id}
                      onClick={() => {
                        setTransactionType(item.value);
                        setCurrentTransactionType(item.name);
                        confirmDate(item.value);
                        setShowTransactionType(false);
                      }}
                    >
                      <div className="flex flex-row justify-between items-center px-2 pt-2">
                        <p className="md:text-base text-sm font-semibold mb-0 text-start">
                          {item.name}
                        </p>
                      </div>
                      <hr className="mt-2" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------ */}
    </>
  );
};

export default VirtualCardMutation;
