import Header from "../../_common/headerComponent";
import { SummaryCard } from "../../_common/summaryCard";
import Reauthenticate from "../../_common/reauthenticate";
import { useTranslation } from "react-i18next";

const WithdrawCardSummaryMobile = (props: any) => {
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen relative font-poppins bg-mobile">
      <Reauthenticate
        isNewDesign={true}
        show={props.showModal}
        onHide={() => props.setShowModal(false)}
        transactionType="WITHDRAW_CARD_BALANCE"
        inquiryId={props.inquiryId}
        redirect="detail"
        width={40}
        height={43}
        margin="0 0.2rem"
        fontSize="1rem"
      ></Reauthenticate>
      <Header navbarText={t("withdraw_funds.disbursement_summary")}></Header>
      <div className="flex md:flex-row flex-col-reverse justify-center gap-4 md:mt-[64px] mt-[0px] md:px-4 px-0 md:pb-28">
        <div className="lg:w-1/2 md:w-2/3 w-full">
          <SummaryCard
            data={props.dataPost}
            headerProperty={t("withdraw_funds.disbursement")}
            headerValue={props.data.total}
            isSuccess={false}
          />
        </div>
      </div>
      <div
        className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <button
          type="button"
          onClick={props.handleOnClick}
          style={{ backgroundColor: `${props.company?.color?.color1 !== undefined ? props.company?.color?.color1 : "#26A69A"}` }}
          className=" text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
        >
          {t("withdraw_funds.next")}
        </button>
      </div>
    </section>
  );
};

export default WithdrawCardSummaryMobile;