import { faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "../../assets/images/invoice/Iconly/Light-Outline/Danger Circle.svg";
import { camelCase, formatCurrency } from "../../config/global";
import Header from "../_common/headerComponent";
import sendIcon from "../../assets/images/Send.svg";
import downloadIcon from "../../assets/images/download_invoice.svg";
import printIcon from "../../assets/images/printer.svg";
import DetailInvoiceSkeleton from "../_common/skeleton/DetailInvoiceSkeleton";
import moment from "moment";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { BottomSheet } from "react-spring-bottom-sheet";
import { toast } from "react-hot-toast";
import CustomTooltip from "../../components/_common/tooltip";
import BottomLogo from "../_common/bottomLogo";
import ToastConfig from "../../config/Toast";
import { hexToRgba } from "../../config/global.js";
import { useTranslation } from "react-i18next";

const InvoiceDetailMobile = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile pb-10">
        <Header navbarText="Detail Invoice" />

        <BottomSheet
          onDismiss={() => props.setToggleSetting(false)}
          open={props.toggleSetting}
          className="h-full"
        >
          <div className="px-3 flex gap-4 justify-center font-bold text-base">
            <img
              className="flex mx-auto rounded-xl xl:w-[15vw] lg:w-[15vw] md:w-[20vw] w-[45vw]"
              src={props.barcode}
              alt=""
            />
          </div>
          <div className="px-3 py-4 flex gap-4 justify-center font-bold text-base">
            {t("invoice.share_via")}
          </div>
          <div className="px-3 pb-10 flex gap-4 justify-center items-start">
            <FacebookShareButton
              title="Berikut link tagihan Anda:"
              url={props.data?.link}
              children={
                <FacebookIcon
                  href={props.data?.link}
                  size={45}
                  round
                ></FacebookIcon>
              }
            ></FacebookShareButton>
            <WhatsappShareButton
              title="Berikut link tagihan Anda:"
              url={props.data?.link}
              children={<WhatsappIcon size={45} round></WhatsappIcon>}
            ></WhatsappShareButton>
          </div>
        </BottomSheet>

        <div className="container justify-center gap-5 md:mt-[64px] mt-[0px] pb-28">
          {props.isLoading ? (
            <DetailInvoiceSkeleton></DetailInvoiceSkeleton>
          ) : (
            <div className="overflow-hidden md:w-2/3 w-full mx-auto min-h-[500px] ">
              <div className="bg-white shadow-sm shadow-gray-600">
                <div className="flex justify-end px-4 pt-4">
                  <CustomTooltip
                    content={<>{t("invoice.share")}</>}
                    direction="bottom"
                  >
                    <button
                      type="button"
                      onClick={props.handleShare}
                      className="ml-[12px]"
                    >
                      <img src={sendIcon} alt="" />
                    </button>
                  </CustomTooltip>
                  <CustomTooltip
                    content={<>{t("invoice.download")}</>}
                    direction="bottom"
                  >
                    <button
                      type="button"
                      onClick={props.handleDownload}
                      className="ml-[12px]"
                    >
                      <img src={downloadIcon} alt="" />
                    </button>
                  </CustomTooltip>
                  <CustomTooltip
                    content={<>{t("invoice.print")}</>}
                    direction="bottom"
                  >
                    <button
                      type="button"
                      onClick={() => toast("Coming Soon", ToastConfig.warning)}
                      className="ml-[12px]"
                    >
                      <img src={printIcon} alt="" />
                    </button>
                  </CustomTooltip>
                </div>
                <div ref={props.componentRef} className="p-4">
                  <CustomTooltip
                    className={"w-fit max-w-[calc(100%-7rem)] "}
                    content={
                      <div
                        className="w-96 whitespace-pre-line "
                        key={props.data?.title}
                      >
                        {props.data?.title}
                      </div>
                    }
                    direction="right"
                    children={
                      <h3 className="text-themeDark xs:text-xl text-md font-bold mt-2">
                        {props.data?.title ? camelCase(props.data?.title) : ""}
                      </h3>
                    }
                  ></CustomTooltip>
                  <div className="flex flex-col p-3 border-[0.5px] border-[#C4C4C4] mb-6">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        <h6 className="md:text-sm xs:text-xs text-[11px] text-themeDark mb-0">
                          {t("invoice.for_invoice")} : {props?.state?.createdBy}
                        </h6>
                        {props.data?.config?.isAutoPayment && (
                          <span className="xs:text-[11px] text-[8px] text-themeYellow font-semibold bg-themeYellow bg-opacity-20 px-1 xs:px-2 rounded-md xs:min-w-[4rem]">
                            Auto-Pay
                          </span>
                        )}
                      </div>
                      <div className=" text-center md:text-sm xs:text-xs text-[11px] text-white font-semibold">
                        {/* <div className="-order-1 xs:order-1 text-center  md:text-sm xs:text-xs text-[11px] text-white font-semibold"> */}
                        {props.data?.paymentStatus === "PARTIAL" && (
                          <div className="xs:px-4 xs:py-2 px-2 py-1 rounded-lg bg-themeWarning">
                            {t("invoice.Installment")}
                          </div>
                        )}
                        {props.data?.paymentStatus === "PAID" && (
                          <div className="xs:px-4 xs:py-2 px-2 py-1 rounded-lg bg-themePrimary">
                            {t("invoice.paidOff")}
                          </div>
                        )}
                        {props.data?.paymentStatus === "UNPAID" && (
                          <div className="xs:px-4 xs:py-2 px-2 py-1 rounded-lg bg-themeDanger">
                            {t("invoice.unpaid")}
                          </div>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 gap-3">
                      <div>
                        <h6 className=" md:text-sm xs:text-xs text-[11px] text-themeMuted mb-0">
                          {t("invoice.date_issue")}
                        </h6>
                        <p className=" md:text-sm xs:text-xs text-[11px] text-themePrimary   font-medium mb-0">
                          {moment(props.data?.invoiceDate).format(
                            "DD MMM YYYY"
                          )}
                        </p>
                      </div>
                      <div>
                        <h6 className=" md:text-sm xs:text-xs text-[11px] text-themeMuted mb-0">
                          {t("invoice.due_date")}
                        </h6>
                        <p className=" md:text-sm xs:text-xs text-[11px] text-themePrimary   font-medium mb-0">
                          {moment(props.data?.expiredDate).format(
                            "DD MMM YYYY"
                          )}
                        </p>
                      </div>
                      <div>
                        <h6 className=" md:text-sm xs:text-xs text-[11px] text-themeMuted mb-0">
                          {t("invoice.bill_for")}
                        </h6>
                        <p className=" md:text-sm xs:text-xs text-[11px] text-themePrimary   font-medium mb-0">
                          {props.data?.receiverDetail?.name ||
                            props.data?.accountReceiverName}
                        </p>
                      </div>
                    </div>
                  </div>

                  <h6 className="md:text-base text-sm text-themeDark font-semibold mb-[8px]">
                    {t("invoice.bill_details")}
                  </h6>
                  <div className="flex flex-col pt-3 bg-themeGray bg-opacity-50 rounded-[3px] mb-6">
                    {props.data?.item_details?.length > 0 &&
                      props.data?.item_details.map((el: any, index: number) => (
                        <div key={index} className="px-3">
                          <h6 className=" md:text-sm xs:text-xs text-[11px] text-themeDark font-semibold">
                            {el.name}
                          </h6>
                          <h6 className=" md:text-sm xs:text-xs text-[11px] text-themeDark">
                            {formatCurrency(el.amount)}
                          </h6>
                          <div className="flex items-center justify-between">
                            <p className="md:text-sm text-xs text-themeDark mb-2">
                              {t("invoice.bill_code")}
                            </p>
                            <p className="md:text-sm text-xs text-themeDark font-semibold mb-2">
                              {el.invoiceCode || "-"}
                            </p>
                          </div>
                          <div className="flex items-center justify-between">
                            <p className=" md:text-sm xs:text-xs text-[11px] text-themeDark mb-0">
                              {t("invoice.discount")}{" "}
                              {formatCurrency(el.discount)}
                            </p>
                            <p className=" md:text-sm xs:text-xs text-[11px] text-themeDark font-semibold mb-0">
                              {formatCurrency(el.total)}
                            </p>
                          </div>
                          <hr />
                        </div>
                      ))}

                    <div className="px-3 mb-3">
                      <div className="flex items-center justify-between  md:text-sm xs:text-xs text-[11px] text-themeDark font-semibold mb-[8px]">
                        <p className="mb-0">{t("invoice.total_bill")}</p>
                        <p className="mb-0">
                          {formatCurrency(props.data?.amount)}
                        </p>
                      </div>
                      <div className="flex items-center justify-between  md:text-sm xs:text-xs text-[11px] text-themeDark">
                        <p className="mb-0">{t("invoice.bill_paid")}</p>
                        <p className="mb-0">
                          {formatCurrency(props.data?.paid)}
                        </p>
                      </div>
                    </div>

                    <div
                      className="flex items-center justify-between rounded-b-[3px] md:text-base text-sm font-semibold px-3 py-[12px]"
                      style={{
                        color: props.color.color1,
                        backgroundColor: hexToRgba(props.color.color1, 0.1),
                      }}
                    >
                      <p className="mb-0">{t("invoice.bill_unpaid")}</p>
                      <p className="mb-0">
                        {formatCurrency(props.data?.final)}
                      </p>
                    </div>
                  </div>

                  <h6 className="md:text-base text-sm text-themeDark font-semibold mb-[8px]">
                    {t("invoice.note_description")}
                  </h6>
                  <div className="bg-[#F9FBFB] rounded-[3px] p-3">
                    <p className=" md:text-sm xs:text-xs text-[11px] text-themeDark mb-0">
                      {props.data?.description ? props.data?.description : "-"}
                    </p>
                  </div>
                  {props.data?.config?.isAutoPayment && (
                    <>
                      <h6 className="flex gap-1 md:text-base text-sm text-themeDark font-medium mb-[8px]">
                        Auto-Pay <img src={InfoIcon} alt="auto-pay" />
                      </h6>
                      <div className="bg-themeYellow bg-opacity-10 rounded-[3px] p-3 mt-4">
                        <p className="md:text-sm text-xs font-light text-themeDark mb-0 text-justify">
                          {t("invoice.desc_autopay")}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {props?.query?.state !== null &&
                (props?.state?.type === "receive" &&
                props.data?.paymentStatus !== "PAID" &&
                !props.hidePaymentButton ? (
                  <div className="flex flex-col gap-2 mb-4 mt-9">
                    <button
                      onClick={() => props.handlePayment()}
                      type="button"
                      className=" text-white w-full py-[8px] rounded-lg"
                      style={{ backgroundColor: props.color.color1 }}
                    >
                      <FontAwesomeIcon icon={faMoneyBill1Wave} size="lg" />
                      &nbsp; {t("invoice.payNow")}
                    </button>
                  </div>
                ) : null)}

              {(props?.query?.state === null ||
                props?.state?.type === "sent") &&
                props.data?.paymentStatus === "UNPAID" && (
                  <div className="flex flex-col gap-2 mb-4 mt-9">
                    <button
                      onClick={() => props.deleteInvoiceSent()}
                      type="button"
                      className="bg-white border-[#EC1A1A] border-[1px] rounded-[10px] text-[#EC1A1A] w-full py-[8px]"
                    >
                      {t("invoice.delete")}
                    </button>
                  </div>
                )}
            </div>
          )}
        </div>
        {props?.company?.showFtpLogo && <BottomLogo />}
      </section>
    </>
  );
};

export default InvoiceDetailMobile;
