import React from "react";
import { faMagnifyingGlass, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import { toast } from "react-hot-toast";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import NoImage from "../../assets/images/empty.jpg";
import ProductNotFound from "../../assets/images/icon_search_notFound.png";
import {
  loadState,
  removeState,
} from "../../core/feature/browser-storage/browser-storage";
import { StatsMobile } from "../_common/Stats";
import CategoryList from "../_common/categoryList";
import DataNotFound from "../_common/dataNotFound";
import ProductComponent from "../_common/productComponent";
import arrow from "../../assets/images/arrow-left-ic.svg";
import merchantDefault from "../../assets/images/new_merchant_logo.png";
import CartIcon from "../../assets/images/icon_landingpageMarketplace/icon_keranjang.svg";
import StoreIcon from "../../assets/images/icon_landingpageMarketplace/icon_merchant.svg";
import HistoryIcon from "../../assets/images/icon_landingpageMarketplace/icon_riwayat.svg";
import WishlistIcon from "../../assets/images/icon_landingpageMarketplace/icon_whistlist.svg";
import { useTranslation } from "react-i18next";

const MarketplaceMobile = (props: any) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [keyword, setKeyword] = React.useState("");
  const navigate = useNavigate();

  const MarketplaceIcons = [
    {
      id: "1",
      name: t("marketplace.wishlist"),
      icon: WishlistIcon,
      url: "wishlist",
    },
    {
      id: "2",
      name: t("marketplace.history"),
      icon: HistoryIcon,
      url: "history",
    },
    {
      id: "3",
      name: t("marketplace.cart"),
      icon: CartIcon,
      url: "cart",
    },
    {
      id: "4",
      name: props.mName,
      icon: StoreIcon,
      url: "merchants",
    },
  ];

  return (
    <div className="h-full min-h-screen font-montserrat bg-white">
      <BottomSheet open={open}>
        <div className="pb-20 z-50">
          <div className="flex flex-col relative transform overflow-hidden rounded-lg bg-white transition-all md:w-1/2  p-[1.5rem]">
            <div className="flex flex-col min-h-full p-2 text-center sm:items-center sm:p-0">
              <div className="mb-3 flex justify-between">
                <h4 className="md:text-xl text-left text-lg text-customBlack font-medium mb-0">
                  {t("marketplace.category")}
                </h4>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(false);
                    removeState("category");
                    removeState("category_names");
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="mt-1 mb-3 w-full">
                {props.categories.map((item: any, index: number) => (
                  <CategoryList data={item} title={item.title} key={index} />
                ))}
              </div>
              <div className="flex items-center gap-3 w-full">
                <button
                  type="button"
                  onClick={() => {
                    setOpen(false);
                    removeState("category");
                    removeState("category_names");
                  }}
                  className="bg-white border-[0.5px] border-[#70717D] text-[#70717D] md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                >
                  {t("marketplace.cancel")}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(false);
                    if (loadState("category") !== undefined) {
                      navigate(
                        `/${props.company?.initial}/commerce/products/${
                          loadState("category").id
                        }`,
                        {
                          state: {
                            category: loadState("category"),
                            category_names: loadState("category_names"),
                          },
                        }
                      );
                    } else {
                      toast.error(t("marketplace.toastErrorCategory"));
                    }
                  }}
                  style={{
                    backgroundColor: `${
                      props.company?.color?.color1 !== undefined
                        ? props.company?.color?.color1
                        : "#26A69A"
                    }`,
                  }}
                  className="bg-green text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                >
                  {t("marketplace.apply")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </BottomSheet>

      <div className="absolute p-3 py-4 z-10">
        <div
          onClick={() => navigate(-1)}
          className="flex bg-white rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300 mr-[-2rem]"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>
      </div>

      <StatsMobile isLandingPage={false} layout={"mobile"} />

      <div className="w-full relative">
        <div className="container md:-mt-[80px] -mt-[50px] pb-[44px] px-0">
          <div className="w-full mx-auto bg-white rounded-t-[30px] md:shadow-sm px-4 pb-3 pt-9">
            <div className="w-full relative">
              <div className="w-full mb-4">
                <div className="relative flex items-center text-[#CCCCCC]">
                  <button
                    type="button"
                    onClick={() => props.getProductByName(keyword)}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      color="#728F9E"
                      className="absolute ml-3 top-[11px]"
                    />
                  </button>
                  <input
                    type="text"
                    value={keyword}
                    placeholder={t("marketplace.searchProduct")}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                      if (e.target.value === "") {
                        props.setFilteredProducts([]);
                        props.setIsSearch(false);
                      }
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter" && props.getProductByName(keyword)
                    }
                    className="w-full bg-[#FAFAFA] text-newColor font-medium placeholder-newColor rounded-[18px] border-none focus:outline-2 focus:outline-newColor text-sm py-2 pr-3 pl-10"
                  />
                </div>
                {props.isSearch &&
                  keyword !== "" &&
                  (props.filteredProducts.length > 0 ? (
                    <div className="absolute bg-white p-3 mt-1 w-full z-20">
                      <ul>
                        {props.filteredProducts.map((el: any) => (
                          <li key={el.id} className="mb-3">
                            <Link
                              to={`products/${el.id}`}
                              className="no-underline flex items-center gap-3"
                            >
                              <img
                                src={
                                  el?.imagePath.length > 0 &&
                                  el?.imagePath[0] !== ""
                                    ? el?.imagePath[0]
                                    : NoImage
                                }
                                alt=""
                                className="w-[50px] h-[50px] object-contain"
                              />
                              <p className="text-thirdy md:text-base text-sm font-medium mb-0">
                                {el?.name}
                              </p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className="absolute bg-white p-3 flex flex-col justify-center items-center w-full mt-1 z-20">
                      <img src={ProductNotFound} alt="" />
                      <h6 className="text-thirdy md:text-sm text-xs font-medium mb-0 p-3">
                        {t("marketplace.productNotFound")}
                      </h6>
                    </div>
                  ))}
              </div>
              <div className="grid grid-cols-4 items-start justify-start gap-3">
                {MarketplaceIcons.map((item: any) => (
                  <Link
                    to={item.url}
                    className="no-underline flex flex-col align-items-center justify-start gap-2"
                    key={item.id}
                  >
                    <div>
                      <img
                        src={
                          props.dataIcon.find(
                            (el: any) => el.menu.toLowerCase() === item.url
                          )?.url
                            ? props.dataIcon.find(
                                (el: any) => el.menu.toLowerCase() === item.url
                              )?.url
                            : item.icon
                        }
                        alt=""
                        className="h-[70px]"
                      />
                    </div>
                    <h6 className="text-thirdy md:text-sm text-xs font-medium text-center mb-0">
                      {item.name}
                    </h6>
                  </Link>
                ))}
              </div>
            </div>

            <section id="categories" className="w-full mt-4">
              <div className="space-between mb-3">
                <h6 className="md:text-2xl text-xl text-customBlack text-semibold mb-0">
                  {t("marketplace.category")}
                </h6>
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  style={{ color: props.configColor?.color1 }}
                  className="md:text-base text-sm text-green font-medium no-underline"
                >
                  {t("marketplace.viewAll")}
                </button>
              </div>
              {props.categories.length > 0 ? (
                <Swiper
                  freeMode={true}
                  grabCursor={true}
                  modules={[FreeMode]}
                  spaceBetween={16}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 2.75,
                      spaceBetween: 16,
                    },
                    "@0.75": {
                      slidesPerView: 3,
                      spaceBetween: 16,
                    },
                    "@1.00": {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    "@1.50": {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                  }}
                  className="w-full relative justify-center mySwiper"
                >
                  {props.categories.map((item: any) => (
                    <SwiperSlide
                      onClick={() =>
                        navigate(`products/${item.id}`, {
                          state: {
                            category: {
                              id: item.id,
                            },
                            category_names: {
                              level1: item.name,
                              level2: "",
                              level3: "",
                            },
                          },
                        })
                      }
                      key={item.id}
                      className="swiper-slide-auto"
                    >
                      <div className="cursor-pointer rounded-full border-[#E4E4E4] border-1 flex flex-row py-2 pl-2 pr-3">
                        <div className="w-6 h-6">
                          <img
                            loading="lazy"
                            draggable={false}
                            className="object-contain w-6 h-6 select-none overflow-hidden rounded-full"
                            src={
                              item.imagePath === "" ? NoImage : item.imagePath
                            }
                            alt=""
                          />
                        </div>
                        <span className="text-thirdy md:text-base text-sm font-medium text-center ml-2 mb-0">
                          {item.name}
                        </span>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <DataNotFound title={t("marketplace.dataNotFound")} />
              )}
            </section>

            <section id="products" className="w-full mt-4">
              <div className="space-between mb-3">
                <h6 className="md:text-2xl text-xl text-customBlack text-semibold mb-0">
                  {t("marketplace.mostPopular")}
                </h6>
                <Link
                  to="products"
                  style={{ color: props.configColor?.color1 }}
                  className="md:text-base text-sm text-green font-medium no-underline"
                >
                  {t("marketplace.viewAll")}
                </Link>
              </div>
              {props.products.length > 0 ? (
                <Swiper
                  freeMode={true}
                  grabCursor={true}
                  modules={[FreeMode]}
                  slidesPerView={"auto"}
                  spaceBetween={24}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 2,
                      spaceBetween: 24,
                    },
                    "@0.75": {
                      slidesPerView: 2,
                      spaceBetween: 24,
                    },
                    "@1.00": {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    "@1.50": {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    },
                  }}
                  className="w-full relative justify-center swiper-slide-100"
                >
                  {props.products.map((item: any) => (
                    <SwiperSlide key={item.id} className="cursor-pointer">
                      <ProductComponent item={item} type="P" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <DataNotFound title={t("marketplace.dataNotFound")} />
              )}
            </section>

            <section id="stores" className="w-full mt-4">
              <div className="space-between mb-3">
                <h6 className="md:text-2xl text-xl text-customBlack text-semibold mb-0">
                  {t("marketplace.storeList")} {props.mName}
                </h6>
                <Link
                  to="merchants"
                  style={{ color: props.configColor?.color1 }}
                  className="md:text-base text-sm text-green font-medium no-underline"
                >
                  {t("marketplace.viewAll")}
                </Link>
              </div>
              {props.stores.length > 0 ? (
                <Swiper
                  freeMode={true}
                  grabCursor={true}
                  modules={[FreeMode]}
                  slidesPerView={"auto"}
                  spaceBetween={24}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 2,
                      spaceBetween: 24,
                    },
                    "@0.75": {
                      slidesPerView: 2,
                      spaceBetween: 24,
                    },
                    "@1.00": {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    "@1.50": {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    },
                  }}
                  className="flex gap-6 w-full relative justify-center swiper-slide-100"
                >
                  {props.stores.map((item: any) => (
                    <SwiperSlide key={item.id} className="cursor-pointer">
                      <div
                        onClick={() => navigate(`merchants/${item.id}`)}
                        className="cursor-pointer bg-white overflow-hidden"
                        key={item.id}
                      >
                        <img
                          src={
                            item?.profileImage
                              ? item?.profileImage
                              : merchantDefault
                          }
                          alt=""
                          className="object-cover w-full select-none overflow-hidden rounded-lg"
                          style={{ height: 150 }}
                        />
                        <div className="flex flex-col items-start pt-3">
                          <div className="bg-[#F5F5F5] rounded-md flex items-center justify-center py-1 px-2">
                            <span className="md:text-xs text-[10px] font-medium text-customBlack">
                              {t("marketplace.merchant")}
                            </span>
                          </div>
                          <h6 className="text-customBlack md:text-lg text-base font-medium mb-0 pt-2">
                            {item.name}
                          </h6>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <DataNotFound title={t("marketplace.dataNotFound")} />
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceMobile;
