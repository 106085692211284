import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntityPy,
} from "../../config/global";
import { bearer } from "../../config/global";

const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getZakats = (query) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/zakat/get_zakat?${createQueryUrl(
    getEntityPy(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getZakatById = (id) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/zakat/detail/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getZakatHistory = (query) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/transaction_zakat/get_transaction_member?${createQueryUrl(
    getEntityPy(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getZakatProgress = (id, query) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/transaction_zakat/get_transaction_zakatId/${id}?${createQueryUrl(
    getEntityPy(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getZakatProgressById = (id) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/zakat/donation_progress/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addZakat = (data) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/transaction_zakat/add_transaction`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
