import React, { FC } from "react";
import ClosepayLogo from "../../assets/images/solusinegeri-logo.svg";
import SwapIcon from "../../assets/images/icon_balance/swap_icon.png";

interface LinkedAccountCardProps {
  data: any;
}

const LinkedAccountCard: FC<LinkedAccountCardProps> = (props): JSX.Element => {
  return (
    <div className="bg-[#2196f319] rounded-[20px] md:py-[20px] py-[14px] centered-items gap-5">
      <img src={ClosepayLogo} alt="" className="h-[28px]" />
      <img src={SwapIcon} alt="" className="w-[25px] h-[25px]" />
      <img src={props.data?.img} alt="" className="w-[56px] h-[56px]" />
    </div>
  );
};

export default LinkedAccountCard;
