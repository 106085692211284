/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { BrowserView, MobileView } from "react-device-detect";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppSelector } from "../../core/feature/hooks";
import User from "../../core/models/user";
import * as API_ABSENSI from "../../core/service/api_absensi.js";
import PayGateFeaturesMobile from "./payGateFeaturesMobile";
import PayGateFeaturesWeb from "./payGateFeaturesWeb";
import Company from "../../core/models/company";
import { ConfigColor } from "../../config/global";
import { useTranslation } from "react-i18next";

const PayGateFeatures = () => {
  const color = ConfigColor();
  const [modalQR, setModalQR] = useState(false);
  const [valueQR, setValueQR] = useState("");
  const [timer, setTimer] = useState(300000);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const [filter, setFilter] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [parkingStatus, setParkingStatus] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [openMethod, setOpenMethod] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const showQR = (value: any) => {
    setValueQR(value);
    setModalQR(true);
  };
  const [selectedMethode, setSelectedMethode] = useState("barcode");
  const [newStartDate, setNewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");

  const pilihMethode = async (data: any) => {
    if (selectedMethode === "qr") {
      navigate("scan-qr");
    } else {
      navigate("barcode", {
        state: { data: data },
      });
      localStorage.removeItem("result");
    }
  };
  const renderer = (data: any) => {
    return (
      <div className="font-medium md:text-base text-sm mt-3 flex flex-col w-full">
        <span className="md:text-lg text-base text-newColor">{t('accessibility.timeLimit')}</span>
        <span
          className={`${data.completed ? "text-newColor" : "text-themeDanger"
            } font-semibold md:text-2xl text-xl`}
        >
          {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
        </span>
        <button
          className={`${data.completed
            ? "bg-green text-white"
            : "bg-[#CCCCCC] text-newColor"
            } font-medium md:text-base text-sm mt-3 w-full py-2 rounded-[10px]`}
          type="button"
          onClick={() => {
            regenerateQR();
          }}
        >
          {t('accessibility.regenerate')}
        </button>
      </div>
    );
  };

  const regenerateQR = () => {
    setTimer(Date.now() + 300000);
  };

  const createQR = () => {
    let newData = {
      companyId: company.id,
      companyInitial: company.initial,
      email: user.email,
      userId: user._id,
      userType: user.userType,
    };
    API_ABSENSI.scanQrCode(newData)
      .then((res) => {
        showQR(res.data.data.qrId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToDetail = (data: any) => {
    navigate("detail", {
      state: {
        data: data,
      },
    });
  };

  const addMore = async () => {
    setPage(page + 1);
    getData(page + 1, parkingStatus);
  };

  function currentSize() {
    let a = data.length;
    let b = a / page;

    return b;
  }

  const getData = async (
    currentPage: number = page,
    startDate: any = newStartDate,
    endDate: any = newEndDate
  ) => {
    let query = {
      companyId: "",
      companyInitial: "",
      email: "",
      userType: "",
      userId: "",
      loginTimeStart: "",
      loginTimeEnd: "",
      size: 10,
      page: currentPage,
      sortBy: "-createdDate",
      startDate,
      endDate,
    };
    dispatch(setLoading(true));
    await API_ABSENSI.listPayGate(query)
      .then((res) => {
        if (currentPage > 1) {
          setData(data.concat(res.data.data));
        } else {
          setData(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
        setSize(res.data.paging.size);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const filterDate = () => {
    getData(1, newStartDate, newEndDate);
  };

  const resetFilter = async () => {
    setFilter(false);
    setNewStartDate("");
    setNewEndDate("");
    getData(1, "", "");
  };

  const closeModal = () => {
    resetFilter();
    setPage(1);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const resetMethode = () => {
    // setSelectedMethode("qr");
    setOpenMethod(false);
    // setPageA(1);
    // scanBarcodeRadio(1);
  };

  const scanBarcodeRadio = async () => {
    setSelectedMethode("barcode");

    // dispatch(setLoading(true));
    // await API_ABSENSI.listActivity(currentPage)
    //   .then((res) => {
    //     if (currentPage > 1) {
    //       setAcitivity(activity.concat(res.data.data));
    //     } else {
    //       setAcitivity(res.data.data);
    //     }
    //     setTotalElementsA(res.data.paging.totalElements);
    //     dispatch(setLoading(false));
    //   })
    //   .catch((err) => {
    //     dispatch(setLoading(false));
    //   });
  };

  const scanQrRadio = () => {
    setSelectedMethode("qr");
  };

  useEffect(() => {
    setTimer(Date.now() + 300000);
  }, [modalQR, valueQR]);

  return (
    <>
      {/* Modal switch */}
      <div
        className={`${modalQR ? "block" : "hidden"} relative z-50`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="text-end md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setModalQR(false);
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="text-center mb-2">
                <div className="px-3 py-4 flex flex-col gap-4 items-center">
                  <span>
                    {t('accessibility.message_scan_qr_parking')}
                  </span>
                  <QRCode
                    style={{ height: "50%", width: "50%" }}
                    value={valueQR}
                  />
                  <Countdown key={timer} date={timer} renderer={renderer} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- */}
      <BrowserView>
        <PayGateFeaturesWeb
          data={data}
          resetFilter={resetFilter}
          filterDate={filterDate}
          currentSize={currentSize}
          addMore={addMore}
          closeModal={closeModal}
          filter={filter}
          parkingStatus={parkingStatus}
          totalElements={totalElements}
          size={size}
          page={page}
          showQR={(value: any) => showQR(value)}
          goToDetail={(value: any) => goToDetail(value)}
          setPage={(value: any) => setPage(value)}
          setFilter={(value: any) => setFilter(value)}
          setParkingStatus={(value: any) => setParkingStatus(value)}
          createQR={() => createQR()}
          openMethod={openMethod}
          setOpenMethod={(value: any) => setOpenMethod(value)}
          resetMethode={resetMethode}
          color={color}
          scanBarcodeRadio={scanBarcodeRadio}
          scanQrRadio={scanQrRadio}
          pilihMethode={pilihMethode}
          selectedMethode={selectedMethode}
          newStartDate={newStartDate}
          setNewStartDate={(value: any) => setNewStartDate(value)}
          newEndDate={newEndDate}
          setNewEndDate={(value: any) => setNewEndDate(value)}
        />
      </BrowserView>
      <MobileView>
        <PayGateFeaturesMobile
          data={data}
          resetFilter={resetFilter}
          filterDate={filterDate}
          currentSize={currentSize}
          addMore={addMore}
          closeModal={closeModal}
          filter={filter}
          parkingStatus={parkingStatus}
          totalElements={totalElements}
          size={size}
          page={page}
          showQR={(value: any) => showQR(value)}
          goToDetail={(value: any) => goToDetail(value)}
          setPage={(value: any) => setPage(value)}
          setFilter={(value: any) => setFilter(value)}
          setParkingStatus={(value: any) => setParkingStatus(value)}
          createQR={() => createQR()}
          openMethod={openMethod}
          setOpenMethod={(value: any) => setOpenMethod(value)}
          resetMethode={resetMethode}
          color={color}
          scanBarcodeRadio={scanBarcodeRadio}
          scanQrRadio={scanQrRadio}
          pilihMethode={pilihMethode}
          selectedMethode={selectedMethode}
          newStartDate={newStartDate}
          setNewStartDate={(value: any) => setNewStartDate(value)}
          newEndDate={newEndDate}
          setNewEndDate={(value: any) => setNewEndDate(value)}
        />
      </MobileView>
    </>
  );
};

export default PayGateFeatures;
