import { Fragment, useEffect, useState } from "react";
import * as API from "../../core/service/api_notification";
import { useAppDispatch } from "../../core/feature/hooks";

// import { useNavigate } from "react-router-dom";
// import Company from "../../core/models/company.js";
import LoadMoreButton from "../_common/loadMoreButton";
import moment from "moment";

import Wa from "../../assets/images/WhatsApp.svg";
import Google from "../../assets/images/google.svg";
import Sms from "../../assets/images/sms.svg";

import ReactDatePicker from "react-datepicker";
import "react-spring-bottom-sheet/dist/style.css";
import { setLoading } from "../../core/feature/config/configSlice";
import noInfo2 from "../../assets/images/notif_kosong.svg";
import { StatsNotification } from "../_common/Stats";
import { Dialog, Transition } from "@headlessui/react";
import calendar from "../../assets/images/icon_features/calendarlogo.svg";
import { formatDatePickerUTC } from "../../config/global";
import fill from "../../assets/images/icon_features/filter.svg";
import DetailNotification from "./detailNotification";
import { configMessage } from "../../config/configIcon";
import { ConfigColor } from "../../config/global";
import { saveState } from "../../core/feature/browser-storage/browser-storage";
import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const NotificationWeb = () => {
  const { t } = useTranslation();
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const startMonth = moment().startOf("month").format("YYYY-MM");

  let color = ConfigColor();
  const dispatch = useAppDispatch();
  // const company: Company = useAppSelector((state) => state.company.company);
  const [data, setData] = useState<any>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [size] = useState(10);
  const [page, setPage] = useState(1);
  const [dir] = useState(-1);
  const [sortBy] = useState("createdTime");
  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState<any>(
    formatDatePickerUTC(startOfMonth)
  );
  const [endDate, setEndDate] = useState<any>(
    formatDatePickerUTC(moment().format("YYYY-MM-DD"), true)
  );
  const [startDate2, setStartDate2] = useState<any>(null);
  const [endDate2, setEndDate2] = useState<any>(null);
  const [filter, setFilter] = useState(false);
  const [visible, setVisible] = useState(false);
  const [detailData, setDetailData] = useState<any>("");
  const [month, setMonth] = useState(startMonth);

  const getNotification = async (
    currentPage: number = page,
    title: string = keyword,
    createdTime: any = startDate,
    createdTimeFinish: any = endDate
  ) => {
    dispatch(setLoading(true));
    let query: any = {
      page: currentPage,
      size: size,
      sortby: sortBy,
      dir: dir,
      order: "dsc",
      title,
      createdTime,
      createdTimeFinish,
    };

    await API.getNotificationMember(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setData(data.concat(res.data.data.items));
        } else {
          setData(res.data.data.items);
        }
        setTotalElements(res.data.data.total);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setStartDate(formatDatePickerUTC(startOfMonth));
        setEndDate(formatDatePickerUTC(moment().format("YYYY-MM-DD"), true));
        setStartDate2(null);
        setEndDate2(null);
      });
  };

  const filterDate = () => {
    setPage(1);
    setFilter(false);
    setMonth("");
    getNotification(1, keyword, startDate, endDate);
  };

  const resetFilter = () => {
    setPage(1);
    setMonth(startMonth);
    setStartDate(formatDatePickerUTC(startOfMonth));
    setEndDate(formatDatePickerUTC(moment().format("YYYY-MM-DD"), true));
    setStartDate2(null);
    setEndDate2(null);
    setFilter(!filter);
    getNotification(
      1,
      keyword,
      formatDatePickerUTC(startOfMonth),
      formatDatePickerUTC(moment().format("YYYY-MM-DD"), true)
    );
  };

  const readable = (res?: any) => {
    setData((prevData: any) => {
      return prevData.map((obj: any) => {
        if (obj._id === res?._id) {
          return { ...obj, isUnread: false };
        }
        return obj;
      });
    });
    updateReadNotification(res._id);
  };

  const updateReadNotification = async (id: any) => {
    await API.updateRead(id)
      .then((res) => {
        dispatch(setLoading(false));
        saveState("notif_statistic", res.data.data.stat);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  function currentSize() {
    let a = data.length;
    let b = a / page;

    return b;
  }

  const addMore = async () => {
    setPage(page + 1);
    getNotification(page + 1, keyword, startDate, endDate);
  };

  const actionMonth = () => {
    if (!month) return toast.error("Bulan belum dimasukkan");
    let firstDay = moment(`${month}-15 00:00`, "YYYY-MM-DD h:m")
      .startOf("month")
      .format("YYYY-MM-DD");
    let endDay = moment(`${month}-15 00:00`, "YYYY-MM-DD h:m")
      .endOf("month")
      .format("YYYY-MM-DD");

    setStartDate(formatDatePickerUTC(firstDay));
    setEndDate(formatDatePickerUTC(endDay, true));
    setStartDate2(null);
    setEndDate2(null);
    getNotification(
      1,
      keyword,
      formatDatePickerUTC(firstDay),
      formatDatePickerUTC(endDay, true)
    );
  };

  const disabled = () => {
    if (startDate2 && endDate2) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return (
    <>
      <DetailNotification
        visible={visible}
        setVisible={setVisible}
        data={detailData}
      />

      <Transition appear show={filter} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setFilter(!filter)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-center leading-6 text-gray-900"
                  >
                    Filter
                  </Dialog.Title>
                  <div className="p-4 bg-white rounded-xl">
                    <div className="flex flex-row mb-4 gap-4">
                      <div className="flex w-1/2 flex-col relative">
                        <span>{t('notification.startDate')}</span>
                        <ReactDatePicker
                          selected={startDate2}
                          onChange={(date: Date) => {
                            setStartDate2(date);
                            setStartDate(formatDatePickerUTC(date));
                          }}
                          selectsStart
                          dateFormat="dd-MM-yyyy"
                          maxDate={endDate2 ? endDate2 : new Date()}
                          placeholderText={t('notification.placeholderDate')}
                          className="border-[1px] border-[#C4C4C4] text-left p-[13px] rounded-lg w-[100%] md:text-sm text-sm"
                        />
                        <img
                          src={calendar}
                          className="absolute right-3 mt-8"
                          alt=""
                        />
                      </div>
                      <div className="flex w-1/2 flex-col relative">
                        <span>{t('notification.endDate')}</span>
                        <ReactDatePicker
                          selected={endDate2}
                          onChange={(date: Date) => {
                            setEndDate2(date);
                            setEndDate(formatDatePickerUTC(date, true));
                          }}
                          selectsStart
                          dateFormat="dd-MM-yyyy"
                          minDate={startDate2}
                          maxDate={new Date()}
                          placeholderText={t('notification.placeholderDate')}
                          className="border-[1px] border-[#C4C4C4] text-left p-[13px] rounded-lg w-[100%] md:text-sm text-sm"
                        />
                        <img
                          src={calendar}
                          className="absolute right-3 mt-8"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex w-full gap-4">
                      <button
                        onClick={() => {
                          resetFilter();
                        }}
                        className="text-gray-500 border-1 border-gray-500 w-1/2 p-2 rounded-lg"
                      >
                        Reset
                      </button>
                      <button
                        onClick={() => {
                          filterDate();
                        }}
                        disabled={disabled()}
                        className=" w-1/2 p-2 rounded-lg text-white"
                        style={{
                          backgroundColor:
                            startDate2 && endDate2
                              ? `${color.color1}`
                              : "#a0a0a0",
                        }}
                      >
                        {t('notification.buttonApply')}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <section className="h-full min-h-screen font-poppins bg-white">
        <StatsNotification />

        <div className="container flex flex-col gap-3 justify-center -mt-20 z-10 relative pb-[64px]">
          <div className="w-4/5 mx-auto bg-white p-5 rounded-2xl shadow mb-20">
            <div className="flex items-center gap-2 mb-4">
              <input
                type="search"
                className="px-2 rounded-md w-full p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 "
                placeholder={t('notification.placeholderSearch')}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                  setPage(1);
                }}
              />
              <button
                onClick={() => setFilter(!filter)}
                className="border-1 border-gray-300 flex bg-opacity-10 p-2.5 rounded-lg w-1/6"
              >
                <img src={fill} alt="" className="mr-2 mx-auto" />
              </button>
            </div>
            <div className="mb-4">
              <input
                type="month"
                className="rounded-lg p-2 border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 "
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              />
              <button
                style={{ backgroundColor: color.color1 }}
                className="p-2 ml-2 text-white rounded-lg"
                onClick={() => actionMonth()}
              >
                {t('notification.buttonSearch')}
              </button>
            </div>
            {data?.length > 0 ? (
              <div className=" w-full mx-auto mb-4">
                {data.map((res: any, index: any) => (
                  <div
                    key={index}
                    onClick={() => {
                      setVisible(!visible);
                      setDetailData(res);
                      readable(res);
                    }}
                    className={`${res.isUnread ? "bg-[#F4F6F8]" : "bg-white"
                      } relative flex items-center border-b-[0.5px] border-[#E2E2E2] md:flex-row hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 w-full p-2 space-x-2 cursor-pointer px-3`}
                  >
                    <div className="absolute object-cover rounded-full border-1 border-slate-100 top-0 mt-1 p-2">
                      {configMessage(res.isUnread ? "notifikasi" : "", color)}
                    </div>
                    <div className="flex flex-col justify-between leading-normal pl-16">
                      <h5 className="mb-2 md:text-lg text-xs text-[#252525] tracking-tight news-desc">
                        {res?.title?.length < 90
                          ? res?.title
                          : res?.title?.toString().substring(0, 90) + "..."}
                      </h5>
                      <p className="mb-3 font-normal md:text-xs text-xs text-gray-700 news-desc">
                        {res.description !== undefined
                          ? res?.description?.length < 200
                            ? res?.description
                            : res?.description?.toString().substring(0, 200) +
                            "..."
                          : ""}
                      </p>
                      <div className="flex flex-wrap md:text-sm text-[10px] text-[#9A9999] space-x-1">
                        <div className="md:text-sm text-[10px] text-[#9A9999]">
                          {moment
                            .utc(res.createdTime)
                            .local()
                            .format("DD MMMM YYYY | HH : mm : ss")}{" "}
                        </div>
                        <div>{"|"}</div>
                        <div className="mr-1">{res.status}</div>
                        <div>{"|"}</div>
                        <div>
                          {res.templateType === "Whatsapp" && (
                            <span className="flex space-x-2 ">
                              <img src={Wa} alt="" className="w-[20px] mr-1" />{" "}
                              {res.templateType}
                            </span>
                          )}
                          {res.templateType === "Email" && (
                            <span className="flex space-x-2">
                              <img src={Sms} alt="" className="w-[15px] mr-1" />{" "}
                              {res.templateType}
                            </span>
                          )}
                          {res.templateType === "SMS" && (
                            <span className="flex space-x-2">
                              <img src={Sms} alt="" className="w-[10px] mr-1" />{" "}
                              {res.templateType}
                            </span>
                          )}
                          {res.templateType === "FirebaseWeb" && (
                            <span className="flex space-x-2">
                              <img
                                src={Google}
                                alt=""
                                className="w-[10px] mr-1"
                              />{" "}
                              {"Web"}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {currentSize() >= 10 && totalElements > 10 ? (
                  <LoadMoreButton addMore={addMore} />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="h-auto w-auto">
                <img src={noInfo2} className="flex mx-auto" alt="" />
                <div className="text-base mt-4 text-center font-medium">
                  {t('notification.notFound')}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default NotificationWeb;
