import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  saveState,
  loadState,
  removeState,
} from "../browser-storage/browser-storage";

type InitialState = {
  loading: boolean;
  showOtp: boolean;
  isEverChooseCompany: boolean;
};
const type = "chooseCompany";

const initialState: InitialState = {
  loading: false,
  showOtp: false,
  isEverChooseCompany: loadState(type),
};

export const configSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    setShowOtp: (state, action: PayloadAction<any>) => {
      state.showOtp = action.payload;
    },
    setIsEverChooseCompany: (state, action: PayloadAction<any>) => {
      state.isEverChooseCompany = action.payload;
      saveState(type, action.payload);
    },
    removeIsEverChooseCompany: (state) => {
      state.isEverChooseCompany = false;
      removeState(type);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setIsEverChooseCompany, setShowOtp } =
  configSlice.actions;

export default configSlice.reducer;
