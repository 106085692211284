import React from "react";
import { useNavigate } from "react-router-dom";
import arrow_right from "../../assets/images/arrow_right.svg";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import { useTranslation } from "react-i18next";

const SecurityCodeWeb = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();

  return (
    <>
      <section className="h-full min-h-screen font-poppins bg-mobile">
        <Header></Header>
        <div className="h-full flex flex-col w-2/5 lg:w-1/2 min-w-[30rem] px-4 md:mt-[64px] mt-[0px] mx-auto">
          <div className="w-full text-left mb-3">
            <h1 className="font-poppins font-bold md:text-3xl text-xl">
              {t('securityCodeWeb.title')}
            </h1>
            <h5 className="font-poppins font-normal md:text-lg text-base">
              {t('securityCodeWeb.description')}
            </h5>
          </div>

          <div className="w-full">
            <div
              className="flex flex-row justify-between items-center my-4 md:p-[1.5rem] p-[1rem] cursor-pointer bg-white rounded-lg shadow-sm"
              onClick={() =>
                navigate(`/${company?.initial}/profile/security-code/change`)
              }
            >
              <div>
                <h5 className="grow md:text-lg text-base text-gray-800 mb-0">
                  {t('securityCodeWeb.changePin')}
                </h5>
              </div>
              <div>
                <img className="grow-0 w-6 h-6 m-1" src={arrow_right} alt="" />
              </div>
            </div>
            <div
              className="flex flex-row justify-between items-center my-4 md:p-[1.5rem] p-[1rem] cursor-pointer bg-white rounded-lg shadow-sm"
              onClick={() =>
                navigate(`/${company?.initial}/profile/security-code/forget`)
              }
            >
              <div>
                <h5 className="grow md:text-lg text-base text-gray-800 mb-0">
                  {t('securityCodeWeb.forgetPin')}
                </h5>
              </div>
              <div>
                <img className="grow-0 w-6 h-6 m-1" src={arrow_right} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecurityCodeWeb;
