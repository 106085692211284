import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SiakadWeb from "./siakadWeb";
import SiakadMobile from "./siakadMobile";

const Siakad = () => {
  return (
    <>
      <BrowserView>
        <SiakadWeb></SiakadWeb>
      </BrowserView>
      <MobileView>
        <SiakadMobile></SiakadMobile>
      </MobileView>
    </>
  );
};

export default Siakad;
