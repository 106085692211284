import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import { ConfigColor, checkRegex } from "../../../config/global";
import { useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import {
  addDonationGeneral
} from "../../../core/service/api_donation";
import Header from "../../_common/headerComponent";

const DonationGeneralAddMobile = () => {
  const color = ConfigColor();
  const company: Company = useAppSelector((state) => state.company.company);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [message, setMessage] = useState("");
  const [amountPayment, setAmountPayment] = useState("");
  const [nameOfUser, setNameOfUser] = useState("");
  const [emailOfUser, setEmailOfUser] = useState("");
  const navigate = useNavigate();

  const handlePayment = () => {
    if (!isAnonymous && !nameOfUser) return toast.error("Nama belum diisi");
    if (!isAnonymous && !emailOfUser) return toast.error("Email belum diisi");
    if (!isAnonymous && !checkRegex("email", emailOfUser))
      return toast.error("Format email belum sesuai");
    if (parseInt(amountPayment) < 1 || amountPayment === "")
      return toast.error("Nominal belum diisi");
    let newData = {
      amount: parseInt(amountPayment),
      isAnonymous,
      nameOfUser,
      message,
    };
    addDonationGeneral(company.initial, newData)
      .then((response) => {
        window.open(`https://${response.data.data.link}`, "_self");
        navigate(`/${company.initial}?redirect=false`);
        // else toast.error("Mohon coba lagi, link donasi belum tersedia");
        // navigate(`/${company?.initial}/donations/donation/detail/${params.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const params = useParams();

  return (
    <>
      <section className="h-[calc(100vh-110px)] min-h-screen font-Montserrat bg-mobile">
        <Header navbarText="Donasi"></Header>
        <div className="h-[calc(100vh-80px)] m-auto font-Montserrat">
          <div className="flex flex-col justify-between h-full w-full bg-white shadow-sm shadow-gray-700 gap-1 p-2">
            <div className="overflow-auto">
              <div className="flex flex-col mx-3 py-3 gap-4">
                {!isAnonymous && (
                  <>
                    <div className={`flex flex-col gap-1 mt-2`}>
                      <label className="text-base text-thirdy font-medium pl-1 mb-0">
                        Nama
                      </label>
                      <input
                        className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                        value={nameOfUser}
                        onChange={(e) => {
                          setNameOfUser(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className={`flex flex-col gap-1 mt-2`}>
                      <label className="text-base text-thirdy font-medium pl-1 mb-0">
                        Email
                      </label>
                      <input
                        className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                        value={emailOfUser}
                        onChange={(e) => {
                          setEmailOfUser(e.target.value);
                        }}
                      ></input>
                    </div>
                  </>
                )}
                <div className={`flex flex-col gap-1 mt-2`}>
                  <label className="text-base text-thirdy font-medium pl-1 mb-0">
                    Masukkan Nominal Donasi
                  </label>
                  <CurrencyInput
                    className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                    defaultValue={1000}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    value={amountPayment}
                    prefix={"Rp "}
                    onValueChange={(value: any, name) => {
                      setAmountPayment(value || "");
                    }}
                  />
                </div>
                <div className={`flex flex-col gap-1 mt-2`}>
                  <label className="text-base text-thirdy font-medium pl-1 mb-0">
                    Pesan
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    cols={30}
                    rows={5}
                    className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className={`flex gap-1 mt-2`}>
                  <label
                    htmlFor="isAnonymous"
                    className="text-base text-thirdy font-medium pl-1 mb-0"
                  >
                    Sembunyikan Nama
                  </label>
                  <input
                    type="checkbox"
                    id="isAnonymous"
                    checked={isAnonymous}
                    onChange={() => setIsAnonymous(!isAnonymous)}
                  />
                </div>
              </div>
            </div>
            <div className="sticky bg-white bottom-0 w-full p-4 mt-4 flex flex-col gap-2">
              <button
                onClick={() => handlePayment()}
                type="button"
                className="bg-themePrimary text-white w-full h-10 rounded-md"
                style={{ backgroundColor: color.color1 }}
              >
                Donasi Sekarang
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DonationGeneralAddMobile;
