import Header from "../../_common/headerComponent";
import { useAppSelector } from "../../../core/feature/hooks";
import DataNotFound from "../../_common/dataNotFound";
import ProductComponent from "../../_common/productComponent";
import LoadMoreButton from "../../_common/loadMoreButton";
import Company from "../../../core/models/company";
import Ellipse from "../../../assets/images/ellipse.svg";
import Warning from "../../../assets/images/warning.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import merchantDefault from "../../../assets/images/new_merchant_logo.png";
import ArrowLeft from "../../../assets/images/arrow_right_2.svg";
import { useTranslation } from "react-i18next";

const MerchantDetailMobile = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header
        navbarText={
          "Detail " +
          (props.isLogin
            ? props.marketplaceConfig?.merchantName
            : props.mNameNoLogin)
        }
      />

      <BottomSheet
        open={props.showModal}
        onDismiss={() => {
          props.setDir(1);
          props.setShowModal(false);
        }}
        className="relative z-50 font-montserrat"
      >
        <div className="mx-4 mb-4">
          <div className="mb-4">
            <h4 className="md:text-xl text-lg text-black font-medium mb-0">
              {t('merchants.sort')}
            </h4>
            <div className="flex items-center text-start w-full">
              <input
                type="radio"
                value="Terbaru"
                checked={props.dir === 1}
                onChange={() => props.setDir(1)}
                id="Terbaru"
              />
              <label
                htmlFor="Terbaru"
                className="form-check-label inline-block mx-3"
              >
                {t('merchants.newProduct')}
              </label>
            </div>
            <div className="flex items-center text-start w-full">
              <input
                type="radio"
                value="Terlama"
                checked={props.dir === -1}
                onChange={() => props.setDir(-1)}
                id="Terlama"
              />
              <label
                htmlFor="Terlama"
                className="form-check-label inline-block mx-3"
              >
                {t('merchants.oldProduct')}
              </label>
            </div>
          </div>
          <button
            type="button"
            style={{
              backgroundColor: `${company?.color?.color1 !== undefined
                ? company?.color?.color1
                : "#26A69A"
                }`,
            }}
            className="bg-green w-full text-white py-2 rounded-[8px]"
            onClick={() => {
              if (props.isLogin) {
                props.setPage(1);
                props.getAllProducts(1);
                props.setShowModal(false);
              } else {
                props.setPage(1);
                props.getAllProductsNoLogin(1);
                props.setShowModal(false);
              }
            }}
          >
            {t('merchants.confirm')}
          </button>
        </div>
      </BottomSheet>

      <BottomSheet
        open={props.showModal2}
        onDismiss={() => {
          props.setShowModal2(false);
        }}
        className="relative z-50 font-montserrat"
      >
        <div className="mx-4 mb-4">
          <h6 className="md:text-lg text-base text-customBlack font-medium mb-3">
            {t('merchants.operasionalTime')}
          </h6>
          {props.workHour.length > 0 &&
            props.workHour.map((item: any, index: number) =>
              item.isOpen ? (
                <div
                  className="flex items-start justify-between gap-2 border-b-[1px] border-b-[#E2E2E2] pb-1 mb-2"
                  key={index}
                >
                  <h6 className="md:text-base text-sm text-customBlack mb-0">
                    {props.daysOfWeek[item.dayOfWeek - 1]}
                  </h6>
                  <div className="flex flex-col gap-2">
                    {item.timeSlots.length === 0 ? (
                      <div> {t('merchants.open24Hours')}</div>
                    ) : (
                      <div>
                        {item.timeSlots.map((el: any, index2: number) => (
                          <p
                            className="md:text-base text-sm text-customBlack mb-0"
                            key={index2}
                          >
                            {`${el.startTime.slice(0, 2)}.${el.startTime.slice(
                              2
                            )} - ${el.endTime.slice(0, 2)}.${el.endTime.slice(
                              2
                            )}`}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="flex items-center justify-between gap-2 border-b-[1px] border-b-[#E2E2E2] pb-1 mb-2"
                  key={index}
                >
                  <h6 className="md:text-base text-sm text-customBlack mb-0">
                    {props.daysOfWeek[item.dayOfWeek - 1]}
                  </h6>
                  <p className="md:text-base text-sm text-customBlack mb-0">
                    {t('merchants.close')}
                  </p>
                </div>
              )
            )}
        </div>
      </BottomSheet>

      <div className="container flex flex-col datas-center md:mt-[64px] mt-[0px] px-0">
        <div className="w-full mx-auto">
          <div className="mb-5">
            {props.data.isOpen === false && (
              <div className="flex items-center justify-center gap-3 md:py-3 py-2 bg-[#FFC107] bg-opacity-20 border-y-[1px] border-y-[#FFC107]">
                <img src={Warning} alt="" />
                <div>
                  <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">
                    {t('merchants.merchantClose')}
                  </h6>
                  <div className="flex items-center gap-1">
                    <p className="md:text-sm text-xs font-light mb-0 text-customBlack">
                      {t('merchants.merchantText')}
                    </p>
                    <button
                      type="button"
                      onClick={() => props.setShowModal2(true)}
                      className="text-[#FF9723] font-semibold md:text-sm text-xs"
                    >
                      {t('merchants.checkSchedule')}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white shadow-sm px-3 pt-4 mb-3">
              <div className="flex items-center gap-3">
                <img
                  src={
                    props.data.profileImage
                      ? props.data.profileImage
                      : merchantDefault
                  }
                  alt=""
                  className="object-cover h-[75px] w-[75px] select-none rounded-lg"
                />
                <div className="flex flex-col justify-between items-start gap-4 w-full">
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => props.setShowDescription(true)}
                  >
                    <h6 className="md:text-lg text-base font-semibold mb-0">
                      {props.data?.name}
                    </h6>
                    <img src={ArrowLeft} alt="" />
                  </div>
                  <p className="md:text-sm text-xs text-customBlack text-center font-light mb-0">
                    {props.data?.location
                      ? props.data?.location.city.name
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex justify-center mt-3 pb-3 divide-x-[1px] divide-[#CCCCCC]">
                {props.data?.phone && (
                  <div className="flex flex-col px-3">
                    <h6 className="md:text-base text-sm text-customBlack font-medium mb-2 text-center">
                      {t('merchants.noWhastapp')}
                    </h6>
                    <div className="flex items-center justify-between gap-2">
                      <span className="md:text-base text-sm text-green mb-0">
                        Admin
                      </span>
                      <span>
                        <img src={Ellipse} alt="" />
                      </span>
                      <span className="md:text-sm text-xs text-thirdy font-light mb-0">
                        {props.data?.phone}
                      </span>
                    </div>
                  </div>
                )}
                <div
                  className="px-3 cursor-pointer"
                  onClick={() => props.setShowModal2(true)}
                >
                  <h6 className="md:text-base text-sm text-customBlack font-medium mb-2 text-center">
                    {t('merchants.operasionalTime')}
                  </h6>
                  <div className="flex items-center justify-between gap-3">
                    <div>
                      {props.data.isOpen
                        ? props.workHour.length > 0 && (
                          <div className="flex items-center justify-between gap-2">
                            <span className="md:text-base text-sm text-green mb-0">
                              {t('merchants.open')}
                            </span>
                            <span>
                              <img src={Ellipse} alt="" />
                            </span>
                            <span className="md:text-base text-sm text-customBlack">
                              {props.closed}
                            </span>
                          </div>
                        )
                        : props.workHour.length > 0 && (
                          <span className="md:text-base text-sm text-[#DC3545] mb-0">
                            {t('merchants.close')}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-[12px] lg:w-1/4 w-full pb-[20px]">
                {props.data?.phone && (
                  <button
                    type="button"
                    onClick={() =>
                    (window.location.href = `https://wa.me/${props.data?.phone.replace(
                      /^0/,
                      "+62"
                    )}`)
                    }
                    style={{
                      backgroundColor: `${company?.color?.color1 !== undefined
                        ? company?.color?.color1
                        : "#26A69A"
                        }`,
                    }}
                    className="bg-green text-white md:text-base text-sm font-semibold rounded-[10px] w-full p-2"
                  >
                    {t('merchants.chatSeller')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={() =>
                    (window.location.href = props.data?.catalogueLink)
                  }
                  style={{
                    borderColor: `${company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A"
                      }`,
                    color: `${company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A"
                      }`,
                  }}
                  className="bg-white border-[1px] md:text-base text-sm font-semibold rounded-[10px] w-full p-2"
                >
                  {t('merchants.buyOnSpot')}
                </button>
              </div>
            </div>
            <div className="bg-white shadow-sm px-3 py-[12px] mb-3">
              <h6 className="md:text-xl text-lg text-customBlack font-semibold mb-0">
                {props.marketplaceConfig?.productName}  {t('merchants.forSale')}
              </h6>
              <h6 className="md:text-lg text-base text-customBlack font-medium mb-0">
                {t('merchants.recommended')}
              </h6>
              {props.products.length > 0 ? (
                <div className="grid lg:grid-cols-4 grid-cols-2 gap-3 mt-2">
                  {props.products.map((el: any) => (
                    <div key={el.id}>
                      <ProductComponent item={el} type="P" />
                    </div>
                  ))}
                </div>
              ) : (
                <DataNotFound
                  title={
                    props.marketplaceConfig?.merchantName +
                    ` ${t('merchants.noDataProduct')}`
                  }
                />
              )}
            </div>

            {props.currentSize() >= 10 && props.totalElements > 10 ? (
              <LoadMoreButton addMore={props.addMore} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MerchantDetailMobile;
