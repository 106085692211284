/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatCurrency } from "../../config/global";
import Header from "../_common/headerComponent";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";
import Token from "../../core/models/token";
import Reauthenticate from "../_common/reauthenticate";
import Company from "../../core/models/company";
import ConfirmationModal from "../_common/confirmationModal";
import { useTranslation } from "react-i18next";

const PPOBPrabayarSummaryWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  let dispatch = useAppDispatch();
  let location: any = useLocation();
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});
  const [dataOrder, setDataOrder] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [inquiryId, setInquiryId] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const trxPrepaid = async () => {
    setShowConfirmationModal(false);
    dispatch(setLoading(true));
    await API_DIGIPROD.trxPrepaid(dataOrder, token)
      .then((res) => {
        dispatch(setLoading(false));
        setInquiryId(res.data.data.inquiryId);
        setTransactionId(res.data.data.transactionId);
        setShowModal(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    setData(location.state.data);
    setDataOrder(location.state.dataOrder);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>

      <Reauthenticate
        show={showModal}
        onHide={() => setShowModal(false)}
        transactionType="DIGITAL_PRODUCT"
        inquiryId={inquiryId}
        transactionId={transactionId}
        redirect={`/${company?.initial}/digital-product/pulsa-data/prabayar/success`}
        width={40}
        height={43}
        margin="0 0.4rem"
      ></Reauthenticate>

      {/* <ConfirmationModal
        showModal={showConfirmationModal}
        hideModal={() => setShowConfirmationModal(false)}
        confirm={trxPrepaid}
      /> */}

      <div className="flex md:flex-row flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0 font-montserrat">
        <div className="bg-white md:w-2/3 w-full mx-auto p-4 md:rounded-xl rounded-none mb-40">
          <h6 className="text-base md:text-lg font-semibold text-green">
            {t("ppobHistory.summary")}
          </h6>
          <hr />
          <div className="space-between gap-3">
            <p className="text-sm md:text-base text-customBlack font-medium mb-0">
              {t("ppobHistory.transaction_date")}
            </p>
            <p className="text-sm md:text-base text-newColor font-light mb-0">
              {moment().format("ll")}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack font-medium mb-0">
              {t("ppobHistory.phone_number")}
            </p>
            <p className="text-sm md:text-base text-newColor font-light mb-0">
              {dataOrder?.customerNumber}
            </p>
          </div>
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack font-medium mb-0">
              {t("ppobHistory.service_type")}
            </p>
            {data.category === "Pulsa" && (
              <p className="text-sm md:text-base text-newColor font-light mb-0 text-end">
                {data?.brand} - {data?.price} {t("topUp.prepaid")}
              </p>
            )}
            {data.category === "Paket Data" && (
              <p className="text-sm md:text-base text-newColor font-light mb-0 text-end">
                {data?.product_name}
              </p>
            )}
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack font-medium mb-0">
              {t("ppobHistory.total")}
            </p>
            <p className="text-sm md:text-base text-newColor font-light mb-0">
              {formatCurrency(data?.totalPrice)}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack font-medium mb-0">
              {t("ppobHistory.category")}
            </p>
            <p className="text-sm md:text-base text-customBlack font-light mb-0">
              {t("ppobHistory.pulse_and_data")}
            </p>
          </div>
        </div>
        <div
          className="md:w-2/3 w-full absolute bottom-0 right-0 left-1/2 px-4"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <div className="flex justify-between items-center">
            <h5 className="text-lg md:text-xl font-medium mb-0">{t("ppobHistory.totalPrice")}</h5>
            <h5 className="text-lg md:text-xl font-semibold text-green mb-0">
              {formatCurrency(data.totalPrice)}
            </h5>
          </div>
          <button
            type="button"
            style={{
              background: `${company?.color?.color1 !== undefined
                ? company?.color?.color1
                : "#26A69A"
                }`,
            }}
            className="bg-green text-white font-medium text-base rounded-lg w-full py-[10px] mt-3"
            onClick={trxPrepaid}
          >
            {t("ppob.confirm")}
          </button>
        </div>
      </div>
    </section>
  );
};

export default PPOBPrabayarSummaryWeb;
