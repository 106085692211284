import React, { useEffect, useState } from "react";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faMagnifyingGlass,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { setLoading } from "../../core/feature/config/configSlice";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useTranslation } from "react-i18next";

const PPOBAirPDAMMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [clusterList, setClusterList] = useState<any[]>([]);
  const [pdamList, setPdamList] = useState<any[]>([]);
  const [number, setNumber] = useState("");
  const [cluster, setCluster] = useState("");
  const [region, setRegion] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [errorCluster, setErrorCluster] = useState("");
  const [errorRegion, setErrorRegion] = useState("");
  const [pdamCode, setPdamCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [searchCluster, setSearchCluster] = useState("");
  const [searchRegion, setSearchRegion] = useState("");

  const getPdamClusterList = async (clusterName: string) => {
    dispatch(setLoading(true));
    let query = {
      clusterName,
    };
    await API_DIGIPROD.pdamClusterList(query)
      .then((res) => {
        dispatch(setLoading(false));
        setClusterList(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getPdamList = async (cluster: string, pdamName: string = "") => {
    dispatch(setLoading(true));
    let query = {
      pdamName,
      cluster,
    };
    await API_DIGIPROD.pdamList(query)
      .then((res) => {
        dispatch(setLoading(false));
        setPdamList(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = () => {
    if (cluster === "") {
      setErrorCluster(t("pdam.choose_product_type"));
    } else if (region === "") {
      setErrorRegion(t("pdam.toast_choose_region"));
    } else if (number === "") {
      setErrorNumber(t("pdam.enter_number_id"));
    } else {
      let query = {
        pdamNumber: number,
        pdamCode,
      };
      dispatch(setLoading(true));
      API_DIGIPROD.trxPDAMInquiry(query)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`/${company?.initial}/digital-product/pdam/summary`, {
            state: {
              data: res.data.data,
            },
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  // const [configColor, setConfigColor] = useState<any>({});
  // const getConfigColor = async () => {
  //   var color1 =
  //     company?.color?.color1 != undefined ? company?.color?.color1 : "#006F54";
  //   var color2 =
  //     company?.color?.color2 != undefined ? company?.color?.color2 : "#087B5E";

  //   setConfigColor({ color1: color1, color2: color2 });
  // };

  // useEffect(() => {
  //   getConfigColor();
  // }, []);

  useEffect(() => {
    getPdamClusterList("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={t('pdam.pdam')}></Header>

      {/* MODAL */}
      <BottomSheet
        open={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
      >
        <div className="mx-4 my-3">
          <div className="relative flex items-center text-gray-400 mb-3">
            <button>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color="#848383"
                className="absolute top-[11px] ml-3"
              />
            </button>
            <input
              type="text"
              value={searchCluster}
              onChange={(e) => setSearchCluster(e.target.value)}
              name="search"
              className="w-full text-gray-400 font-medium placeholder-gray-400 rounded-[4px] border-none ring-2 ring-[#D9D9D9] focus:outline-2 focus:outline-gray-300 text-sm py-2 pr-3 pl-10"
            />
          </div>
          <ul className="px-0 mb-0">
            {clusterList
              .filter((el) =>
                el.name.toLowerCase().includes(searchCluster.toLowerCase())
              )
              .map((item, index) => (
                <li
                  className="list-none cursor-pointer hover:bg-teal-50"
                  key={index}
                  onClick={() => {
                    setCluster(item.name);
                    setErrorCluster("");
                    getPdamList(item.name);
                    setShowModal(false);
                  }}
                >
                  <div className="flex flex-row justify-between items-center px-2 pt-2">
                    <p className="text-customBlack md:text-base text-sm font-medium mb-0 text-start font-montserrat">
                      {item.name}
                    </p>
                  </div>
                  <hr className="mt-2" />
                </li>
              ))}
          </ul>
        </div>
      </BottomSheet>

      <BottomSheet
        open={showModal2}
        onDismiss={() => {
          setShowModal2(false);
        }}
      >
        <div className="mx-4 my-3">
          <div className="relative flex items-center text-gray-400 mb-3">
            <button>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color="#848383"
                className="absolute top-[11px] ml-3"
              />
            </button>
            <input
              type="text"
              value={searchRegion}
              onChange={(e) => setSearchRegion(e.target.value)}
              name="search"
              className="w-full text-gray-400 font-medium placeholder-gray-400 rounded-[4px] border-none ring-2 ring-[#D9D9D9] focus:outline-2 focus:outline-gray-300 text-sm py-2 pr-3 pl-10"
            />
          </div>
          <ul className="px-0 mb-0">
            {pdamList
              .filter((el) =>
                el.name.toLowerCase().includes(searchRegion.toLowerCase())
              )
              .map((item, index) => (
                <li
                  className="list-none cursor-pointer hover:bg-teal-50"
                  key={index}
                  onClick={() => {
                    setRegion(item.name);
                    setErrorRegion("");
                    setPdamCode(item.code);
                    setShowModal2(false);
                  }}
                >
                  <div className="flex flex-row justify-between items-center px-2 pt-2">
                    <p className="text-customBlack md:text-base text-sm font-medium mb-0 text-start font-montserrat">
                      {item.name}
                    </p>
                  </div>
                  <hr className="mt-2" />
                </li>
              ))}
          </ul>
        </div>
      </BottomSheet>

      <div className="w-full md:w-2/3 lg:w-1/2 mx-auto flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4">
        <div className="w-full mb-20">
          <div className="flex flex-col items-center gap-3 rounded-[10px]  md:mb-10 mb-6">
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('pdam.choose_cluster')}
                </label>
                <input
                  defaultValue={cluster}
                  readOnly
                  onClick={() => setShowModal(true)}
                  className="bg-white border-[0.5px] border-newColor w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                />
                <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                  <button type="button" onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0">{errorCluster}</p>
            </div>
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('pdam.choose_region')}
                </label>
                <input
                  defaultValue={region}
                  readOnly
                  disabled={cluster === ""}
                  onClick={() => setShowModal2(true)}
                  className="bg-white border-[0.5px] border-newColor w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                />
                <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                  <button type="button" onClick={() => setShowModal2(true)}>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0">{errorRegion}</p>
            </div>
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('pdam.number_user')}
                </label>
                <input
                  type="text"
                  value={number}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setErrorNumber("");
                  }}
                  className="bg-white border-[0.5px] border-newColor w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                />
                <div className="absolute md:top-[47px] top-[42px] right-4">
                  <button
                    type="button"
                    className="bg-thirdy border-[0.5px] border-newColor text-white rounded-[50%]  w-[17px] h-[17px] flex justify-center font-montserrat"
                    onClick={() => {
                      setNumber("");
                      setErrorNumber("");
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0">{errorNumber}</p>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 right-0 w-full md:w-2/3 lg:w-1/2 left-1/2 px-4"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <button
            type="button"
            style={{
              background: `${company?.color?.color1 !== undefined
                ? company?.color?.color1
                : "#26A69A"
                }`,
            }}
            className="bg-green text-white font-medium text-base rounded-[10px] w-full py-[10px] font-montserrat"
            onClick={() => handleOnClick()}
          >
            {t('pdam.next')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default PPOBAirPDAMMobile;