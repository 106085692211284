import { BrowserView, MobileView } from "react-device-detect";
import PesananWeb from "../pesananComponent/pesananWeb";
import PesananMobile from "../pesananComponent/pesananMobile";
import { Fragment, useEffect, useState } from "react";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import Company from "../../../core/models/company";
import { useAppSelector } from "../../../core/feature/hooks";
import checklist from "../../../assets/images/fnb/checklist.svg";
import cancel from "../../../assets/images/fnb/cancel.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../core/feature/config/configSlice";
import Swal from "sweetalert2";
import { Dialog, Transition } from "@headlessui/react";
import { removeState } from "../../../core/feature/browser-storage/browser-storage";
import { loadState } from "../../../core/feature/browser-storage/browser-storage";
import { saveState } from "../../../core/feature/browser-storage/browser-storage";

const Pesanan = () => {
  const [data, setData] = useState<any>();
  const [dataMerchant, setDataMerchant] = useState<any>();
  const id = localStorage.getItem("orderId");
  const company: Company = useAppSelector((state) => state.company.company);
  const param = useParams();
  const [bayar, setBayar] = useState(false);
  const dispath = useDispatch();
  const navigate = useNavigate();
  // const [detail, setDetail] = useState(false);
  // const [dataSum, setDataSum] = useState<any>({});
  const [indexSlider, setIndexSlider] = useState<any>(
    localStorage.getItem("index")
  );
  const [status, setStatus] = useState<any[]>([]);
  const [chosePayment, setChosePayment] = useState(false);
  const [paymentMethode, setPaymentMethode] = useState("cash");
  let redirectCO = loadState("redirectCO");

  const statusProses = [
    {
      icon: checklist,
      name: "Pesanan Terbuat",
      key: "CREATED",
    },
    {
      icon: checklist,
      name: "Pesanan Diproses",
      key: "PROCESSED",
    },
    {
      icon: checklist,
      name: "Pesanan Siap",
      key: "DONE",
    },
    {
      icon: checklist,
      name: "Pesanan Selesai",
      key: "CONFIRMED",
    },
  ];

  const statusCancel = [
    {
      icon: checklist,
      name: "Pesanan Terbuat",
      key: "CREATED",
    },
    {
      icon: cancel,
      name: "Pesanan Dibatalkan",
      key: "CANCELED",
    },
  ];

  const statusReject = [
    {
      icon: checklist,
      name: "Pesanan Terbuat",
      key: "CREATED",
    },
    {
      icon: cancel,
      name: "Pesanan Ditolak",
      key: "REJECTED",
    },
  ];

  const getStatus = async () => {
    await API_COMMERCE.cekStatus(id)
      .then((res) => {
        setData(res.data.data);
        var x = JSON.stringify(res.data.data);
        localStorage.setItem("orderData", x);
        if (res.data.data?.status === "CREATED") {
          setStatus(statusProses);
          setIndexSlider(0);
        } else if (res.data.data?.status === "PROCESSED") {
          setStatus(statusProses);
          setIndexSlider(1);
        } else if (res.data.data?.status === "DONE") {
          setStatus(statusProses);
          setIndexSlider(2);
        } else if (res.data.data?.status === "CONFIRMED") {
          setStatus(statusProses);
          setIndexSlider(3);
        } else if (res.data.data?.status === "REJECTED") {
          setIndexSlider(1);
          setStatus(statusReject);
        } else if (res.data.data?.status === "CANCELED") {
          setIndexSlider(1);
          setStatus(statusCancel);
        }
      })
      .catch((err) => {});
  };

  const getMerchant = async () => {
    await API_COMMERCE.getShopDetailNoLogin(company.id, param.merchantId)
      .then((res) => {
        // console.log(res);
        setDataMerchant(res.data.data);
      })
      .catch((err) => {});
  };

  const cekConfig = async () => {
    var x: any = localStorage.getItem("orderData");
    var y: any = JSON.parse(x);
    if (y?.paymentStatus === "PAID") {
      return setBayar(false);
    } else {
      await API_COMMERCE.configPayment(param.merchantId)
        .then((res) => {
          if (res.data.data.paymentConfig === "PAY_AFTER") {
            setBayar(true);
          } else {
            setBayar(false);
          }
        })
        .catch((err) => {});
    }
  };

  const handleCheckout = async () => {
    if (paymentMethode === "online") {
      if (localStorage.getItem("linkCo") === null) {
        dispath(setLoading(true));
        await API_COMMERCE.checkoutFnb(company.id, data.transactionId)
          .then((res) => {
            localStorage.setItem("coId", res.data.data.checkoutId);
            localStorage.setItem(
              "linkCo",
              `https://${res.data.data.checkoutLink}`
            );
            saveState("redirectCO", `/${company.initial}`);
            window.location.href = `https://${res.data.data.checkoutLink}`;
            dispath(setLoading(false));
          })
          .catch((err) => {
            dispath(setLoading(false));
          });
      } else {
        saveState("redirectCO", `/${company.initial}`);
        window.location.href = `${localStorage.getItem("linkCo")}`;
      }
    } else {
      var x: any = localStorage.getItem("orderData");
      var y: any = JSON.parse(x);
      navigate(
        `/${company.initial}/merchant_catalog_product/${param.merchantId}/cart/${y?.transactionId}/cash`
      );
    }
  };

  const batalPesanan = () => {
    Swal.fire({
      title: "Apakah anda yakin untuk membatalkan pesanan?",
      text: "Lanjutkan proses",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
    }).then((res) => {
      if (res.isConfirmed) {
        dispath(setLoading(true));
        API_COMMERCE.confirmOrder(data.transactionId, "CANCELED")
          .then((res) => {
            dispath(setLoading(false));
          })
          .catch((err) => {
            dispath(setLoading(false));
          });
        // navigate(
        //   `/${company.initial}/merchant_catalog_product/${param.merchantId}`
        // );
      }
    });
  };

  const confirmOrder = async () => {
    Swal.fire({
      title: "Apakah anda yakin telah menerima pesanan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        dispath(setLoading(true));
        API_COMMERCE.confirmOrder(data.transactionId, "CONFIRMED")
          .then((res) => {
            dispath(setLoading(false));
            localStorage.removeItem("orderData");
          })
          .catch((err) => {
            dispath(setLoading(false));
          });
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem("coId") !== null) {
      API_COMMERCE.checkPaymentResult(localStorage.getItem("coId"))
        .then((res) => {
          console.log(res);
          if (res.data.data.status === "CLOSE") {
            setBayar(false);
          }
        })
        .catch((err) => {});
    }
    // var data: any = localStorage?.getItem("summaryData");
    // setDataSum(JSON.parse(data));
    cekConfig();
    getMerchant();
    getStatus();
    const loop = setInterval(() => {
      getStatus();
    }, 5000);
    return () => clearInterval(loop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (redirectCO) {
      navigate(`${redirectCO}?redirect=false`);
      removeState(`redirectCO`);
    }
  }, [redirectCO]);

  return (
    <>
      <BrowserView>
        <Transition appear show={chosePayment} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 font-montserrat"
            onClose={() => setChosePayment(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-medium leading-6 text-gray-900"
                    >
                      Silahkan Pilih Metode Pembayaran
                    </Dialog.Title>
                    <div className="mt-2">
                      <input
                        className="relative float-left mr-1 mt-0.5 h-5 w-5 rounded-full border-2 border-solid border-neutral-300"
                        type="radio"
                        name="pembayaran"
                        onChange={() => setPaymentMethode("cash")}
                      />
                      <label
                        className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer mb-2"
                        htmlFor="radioDefault01"
                      >
                        Bayar Ditempat
                      </label>
                      <br />
                      <input
                        className="relative float-left mr-1 mt-0.5 h-5 w-5 rounded-full border-2 border-solid border-neutral-300"
                        type="radio"
                        name="pembayaran"
                        onChange={() => setPaymentMethode("online")}
                      />
                      <label
                        className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer mb-4"
                        htmlFor="radioDefault01"
                      >
                        Bayar Online
                      </label>
                    </div>
                    <div className="mt-4 flex gap-3 w-full">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-gray-400"
                        onClick={() => setChosePayment(false)}
                      >
                        Batal
                      </button>
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent bg-[#26A69A] px-4 py-2 text-sm font-medium text-white"
                        onClick={() => handleCheckout()}
                      >
                        Konfirmasi
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <PesananWeb
          data={data}
          dataMerchant={dataMerchant}
          id={id}
          company={company}
          param={param}
          bayar={bayar}
          navigate={navigate}
          indexSlider={indexSlider}
          statusProses={statusProses}
          statusCancel={statusCancel}
          handleCheckout={handleCheckout}
          batalPesanan={batalPesanan}
          confirmOrder={confirmOrder}
          status={status}
          setChosePayment={(value: any) => setChosePayment(value)}
        ></PesananWeb>
      </BrowserView>
      <MobileView>
        <Transition appear show={chosePayment} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 font-montserrat"
            onClose={() => setChosePayment(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-medium leading-6 text-gray-900"
                    >
                      Silahkan Pilih Metode Pembayaran
                    </Dialog.Title>
                    <div className="mt-2">
                      <input
                        className="relative float-left mr-1 mt-0.5 h-5 w-5 rounded-full border-2 border-solid border-neutral-300"
                        type="radio"
                        name="pembayaran"
                        onChange={() => setPaymentMethode("cash")}
                      />
                      <label
                        className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer mb-2"
                        htmlFor="radioDefault01"
                      >
                        Bayar Ditempat
                      </label>
                      <br />
                      <input
                        className="relative float-left mr-1 mt-0.5 h-5 w-5 rounded-full border-2 border-solid border-neutral-300"
                        type="radio"
                        name="pembayaran"
                        onChange={() => setPaymentMethode("online")}
                      />
                      <label
                        className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer mb-4"
                        htmlFor="radioDefault01"
                      >
                        Bayar Online
                      </label>
                    </div>
                    <div className="mt-4 flex gap-3 w-full">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-gray-400"
                        onClick={() => setChosePayment(false)}
                      >
                        Batal
                      </button>
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent bg-[#26A69A] px-4 py-2 text-sm font-medium text-white"
                        onClick={() => {
                          handleCheckout();
                        }}
                      >
                        Konfirmasi
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <PesananMobile
          data={data}
          dataMerchant={dataMerchant}
          id={id}
          company={company}
          param={param}
          bayar={bayar}
          navigate={navigate}
          indexSlider={indexSlider}
          statusProses={statusProses}
          statusCancel={statusCancel}
          handleCheckout={handleCheckout}
          batalPesanan={batalPesanan}
          confirmOrder={confirmOrder}
          status={status}
          setChosePayment={(value: any) => setChosePayment(value)}
        ></PesananMobile>
      </MobileView>
    </>
  );
};

export default Pesanan;
