import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import OnlineQueueWeb from "./onlineQueueWeb";
import OnlineQueueMobile from "./onlineQueueMobile";

const OnlineQueue = () => {
  return (
    <>
      <BrowserView>
        <OnlineQueueWeb></OnlineQueueWeb>
      </BrowserView>
      <MobileView>
        <OnlineQueueMobile></OnlineQueueMobile>
      </MobileView>
    </>
  );
};

export default OnlineQueue;
