import { toast } from "react-hot-toast";
import { BottomSheet } from "react-spring-bottom-sheet";
import arrowRight from "../../../assets/images/arrow-left-ic.svg";

import Logout from "../../assets/images/cta/log-out.svg";
import Password from "../../assets/images/icon/auto login.svg";
import Unlock from "../../assets/images/icon/change pass.svg";
import Users from "../../assets/images/icon/changeakun.svg";
import userIcon from "../../assets/images/icon/editprofil.svg";
import ShieldDone from "../../assets/images/icon/pin.svg";
import UserSupport from "../../assets/images/icon/support.svg";
import Work from "../../assets/images/icon/termofservice.svg";
import Voice from "../../assets/images/icon/ubah bahasa.svg";

import { formatCurrency, goToUrl } from "../../../config/global";

import Header from "../../_common/headerComponent";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import iconSearch from "../../../assets/images/icon_rent/search-normal.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Dummy from "../dummy";
import _ from "lodash";
import locationIcon from "../../../assets/images/icon_rent/location.svg";
import Active from "../../../assets/images/icon_rent/active.svg";
import dropdownUp from "../../../assets/images/icon_rent/arrow-circle-down-up.png";
import dropdown from "../../../assets/images/icon_rent/arrow-circle-down.png";
import ArrowRight from "../../../assets/images/icon_rent/arrow-right.png";

import NoActive from "../../../assets/images/icon_rent/noActive.svg";

import Round from "../../assets/images/icon_rent/round.svg";
import { Fragment, useEffect, useState } from "react";
import DetailProduct from "./DetailProduct";
import moment from "moment";
import checkActive from "../../../assets/images/icon_rent/tick-circle-active.png";
import check from "../../../assets/images/icon_rent/tick-circle.png";
import Collapse from "../../_common/Collapse";
import Company from "../../../core/models/company";
import { useAppSelector } from "../../../core/feature/hooks";

const RentBookingWeb = (props: any) => {
  const [enabled, setEnabled] = useState(false);
  const company: Company = useAppSelector((state) => state.company.company);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const params = useParams();
  const location = useLocation();
  const [venue, setVenue] = useState(location.state.venue);
  const [data, setData] = useState<any>("");
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);
  const [payment, setPayment] = useState<any>([]);

  const startOfMonth = moment().startOf("month");
  const endOfMonth = moment().endOf("month");
  const [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));
  const [dates, setDates] = useState<any>([]);
  const [activeData, setActiveData] = useState(0);

  const DaysOfMonth = () => {
    const daysInMonth = [];

    for (let day = startOfMonth; day.isBefore(endOfMonth); day.add(1, "days")) {
      daysInMonth.push(day.format("YYYY-MM-DD"));
    }

    setDates(daysInMonth);
  };

  const handleDateClick = (data: any) => {
    setActiveDate(data);
  };

  const changeFilter = () => {
    setData(
      location.state.data.listOf.filter(
        (el: any) => el.date === activeDate && el.tags === venue
      )
    );
    setShow(false);
  };

  useEffect(() => {
    DaysOfMonth();
  }, [activeDate]);

  const handleClickDate = (item: any, index: any) => {
    if (payment.find((el: any) => el.index === index + 1)) {
      setPayment(payment.filter((el: any) => el.index !== index + 1));
    } else {
      setPayment([...payment, { ...item, index: index + 1 }]);
    }
  };

  useEffect(()=>{
    setData(
      location.state.data.listOf.filter(
        (el: any) => el.date === activeDate && el.tags === location.state.venue
      )
    );
  },[activeDate])

  const tags = ["Sepakbola", "Tennis", "Gym", "Badminton"];
  const handleNavigate = () => {
    if (payment.length === 0) return toast.error("Lapangan belum pilih");
    navigate(`/${company.initial}/rent/payment`, {
      state: { data: location.state.data, payment: payment },
    });
  };
  return (
    <>
      <section className="h-full min-h-screen font-montserrat bg-white pb-24">
        <section className="flex flex-tow items-center z-50 px-4 py-3">
          <div
            onClick={() => navigate(-1)}
            className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
          >
            <img
              src={arrowRight}
              style={{ width: 6, height: 12 }}
              className="m-auto"
              alt=""
            />
          </div>

          <div
            className={`${
              props.backgroundStyle ? "text-white" : "text-customBlack"
            } font-montserrat text-base m-auto relative border px-2 rounded-[12px] flex items-center py-2 gap-2`}
            onClick={() => setShow(!show)}
          >
            <span>{venue}</span>
            <img src={dropdown} alt="" />
          </div>
        </section>
        <div className="px-6 lg:w-[70%] md:w-[80%] mx-auto">
          {/* <div
            className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-300 ease-in-out ${
              enabled ? "bg-[#3867E0] bg-opacity-60" : "bg-gray-300"
            }`}
            onClick={() => setEnabled(!enabled)}
          >
            <span
              className={`inline-block w-4 h-4 transform rounded-full transition-transform duration-300 ease-in-out ${
                enabled ? "translate-x-6 bg-[#3867E0]" : "translate-x-1 bg-white "
              }`}
            />
          </div> */}
          <div className="mt-4">
            <div className="mt-2">
              <div className="space-y-2">
                <Swiper
                  grabCursor={true}
                  slidesPerView={6}
                  spaceBetween={2}
                  className="text-[0.75rem] mt-4"
                  initialSlide={parseInt(moment(activeDate).format("DD")) - 1}
                >
                  {dates.map((day: any, index: any) => (
                    <SwiperSlide
                      key={index}
                      className="bg-white mt-2 pr-2 rounded-lg text-xs text-[#728F9E] cursor-pointer"
                    >
                      <div
                        className={`flex flex-col gap-1 text-center rounded-lg py-2 px-6 ${
                          activeDate && day === activeDate
                            ? "bg-[#3867E0] bg-opacity-20 border-[1px] border-[#3867E0] text-[#3867E0]"
                            : "bg-white text-[#728F9E] border-[1px]"
                        }`}
                        onClick={() => handleDateClick(day)}
                      >
                        <span className="font-normal text-sm">
                          {moment(day).format("dddd").substring(0, 3)}
                        </span>
                        <span
                          className={`${
                            day === activeDate
                              ? "text-[#3867E0]"
                              : "text-[#263339]"
                          } font-semibold text-base `}
                        >
                          {moment(day).format("DD")}
                        </span>
                        <span className="font-normal text-sm">
                          {moment(day).format("YYYY")}
                        </span>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="mt-2 flex-col gap-2">
                  {_.map(data, (el: any, index: any) => (
                    <div key={index}>
                      <div className="flex items-center gap-2 py-2 border-b">
                        <img
                          src={el.img}
                          className="min-w-20 min-h-20 max-w-20 max-h-20 rounded-[10px]"
                          alt=""
                        />
                        <div className="flex flex-col justify-between w-full min-h-20">
                          <div className="flex-grow">
                            <div className="flex items-center justify-between">
                              <span className="text-base font-[500]">
                                {el.name}
                              </span>
                              <img
                                src={
                                  activeData === index + 1
                                    ? dropdownUp
                                    : dropdown
                                }
                                alt=""
                                onClick={() => {
                                  setActiveData(
                                    activeData === index + 1 ? 0 : index + 1
                                  );
                                  setData({...data,lapangan:el.name,img:el.img})
                                  setPayment([]);
                                }}
                              />
                            </div>
                            <div className="text-sm text-[#A8A8A8]">
                              {el.countUse} terjadwal
                            </div>
                          </div>
                          <div className="text-sm text-[#3867E0] font-[500]">
                            {el.count - el.countUse} lapangan tersedia
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        {_.map(el.lisClock, (item: any, index1: any) => (
                          <Collapse open={activeData === index + 1} key={index}>
                            <button
                              onClick={() => handleClickDate(item, index1)}
                              className={`${
                                payment.find((x: any) => x.index === index1 + 1)
                                  ? "border-[1px] mb-2 border-[#3867E0] text-[#3867E0] bg-[#3867E0] bg-opacity-30 "
                                  : "border-[1px] mb-2 border-[#E5E5E5] text-[#E5E5E5] "
                              }" rounded-[12px]  flex justify-between items-center w-full p-2 rounded-[12px]"`}
                            >
                              <div className="flex items-center gap-2">
                                <img
                                  src={
                                    payment.find(
                                      (x: any) => x.index === index1 + 1
                                    )
                                      ? checkActive
                                      : check
                                  }
                                  alt=""
                                />
                                <div className="flex flex-col">
                                  <span className="block font-[600] text-left">
                                    {item.duration} menit
                                  </span>
                                  <span className="font-[500]">
                                    {item.start} - {item.end}
                                  </span>
                                </div>
                              </div>
                              <span
                                className={`${
                                  payment.find(
                                    (x: any) => x.index === index1 + 1
                                  )
                                    ? "text-[#3867E0]"
                                    : "text-black"
                                } font-[500] `}
                              >
                                {formatCurrency(item.price)}
                              </span>
                            </button>
                          </Collapse>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="fixed bottom-0 left-0 right-0 shadow p-4 z-5 bg-white grid grid-cols-2 gap-2 items-center">
              <div>
                <div className="flex items-center">
                  <span className="text-[#A8A8A8] block">Total Biaya</span>
                  <img src={ArrowRight} alt="" />
                </div>
                <span className="font-[500] text-xl break-words">
                  {formatCurrency(_.sumBy(payment, "price"))}
                </span>
              </div>
              <button
                className="bg-[#3867E0] font-[500] text-white w-full p-2 rounded-[8px] h-12"
                onClick={handleNavigate}
              >
                Pesan Sekarang
              </button>
            </div>

            <BottomSheet
              onDismiss={() => setShow(!show)}
              open={show}
              className="z-50 fixed inset-0 font-montserrat"
            >
              <div className="flex flex-col m-3">
                <Fragment>
                  <span className="font-[500] text-[20px] ">
                    Pilih Venue Lapangan{" "}
                  </span>
                  <div className="my-4">
                    {_.map(tags, (el: any, index: any) => (
                      <div
                        key={index}
                        className="flex items-center gap-4 mb-4 cursor-pointer"
                        onClick={() => setVenue(el)}
                      >
                        <img src={venue === el ? Active : NoActive} alt="" />
                        <span className="text-base">{el}</span>
                      </div>
                    ))}
                  </div>
                  <div className="">
                    <button
                      className="bg-[#3867E0] font-[500] text-white w-full p-2 rounded-[8px]"
                      onClick={changeFilter}
                    >
                      Simpan Perubahan
                    </button>
                  </div>
                </Fragment>
              </div>
            </BottomSheet>
          </div>
        </div>
      </section>
    </>
  );
};

export default RentBookingWeb;
