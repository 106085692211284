/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import VirtualCardDetailWeb from "./virtualCardDetailWeb";
import VirtualCardDetailMobile from "./virtualCardDetailMobile";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useAppDispatch } from "../../../core/feature/hooks";
import TopUpIcon from "../../../assets/images/vcard_topup.svg";
import WithdrawIcon from "../../../assets/images/vcard_withdraw.svg";
import HistoryIcon from "../../../assets/images/vcard_history.svg";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import scanQRCode from "../../../assets/images/scan-barcode.svg";
import { useTranslation } from "react-i18next";

const VirtualCardDetail = () => {
  let dispatch = useAppDispatch();
  let location: any = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const [detailData, setDetailData] = React.useState<any>({});
  const [configData, setConfigData] = React.useState<any>({});
  const [background, setBackground] = React.useState("");
  const [template, setTemplate] = React.useState("");
  const [isOpen, setIsOpen] = React.useState("");
  const [editable, setEditable] = React.useState("");
  const [oneTimeTxLimit, setOneTimeTxLimit] = React.useState(0);
  const [dailyTimeTxLimit, setDailyTimeTxLimit] = React.useState(0);
  const [monthTimeTxLimit, setMonthTimeTxLimit] = React.useState(0);
  const [singleCard, setSingleCard] = React.useState(false);
  const [customButton, setCustomButton] = React.useState<any>({});
  const [isPortrait, setIsPortrait] = useState(false);

  const features = [
    {
      title: t('virtual_card.withdraw_funds'),
      img: WithdrawIcon,
      url: "withdraw",
    },
    {
      title: t('virtual_card.top_up_card'),
      img: TopUpIcon,
      url: "topup",
    },
    {
      title: t('virtual_card.transaction_history'),
      img: HistoryIcon,
      url: "mutation",
    },
    {
      title: t('virtual_card.card_qr_code'),
      img: scanQRCode,
      url: "mutation",
    },
  ];

  const getTemplate = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.getTemplate()
      .then((res) => {
        dispatch(setLoading(false));
        setBackground(res.data.background);
        API_VIRTUAL_CARD.getCardDetail(params.id)
          .then((res) => {
            setDetailData(res.data.data);
            setTemplate(res.data.data.template);
          })
          .catch((err) => {
            console.log(err);
           });
        setIsPortrait(res.data.isPotrait);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getConfigCard = async () => {
    await API_VIRTUAL_CARD.getConfigCard()
      .then((res) => {
        let resData = res.data.data;
        setConfigData(resData);
        setOneTimeTxLimit(
          resData.masterOneTimeTxLimit === null
            ? oneTimeTxLimit
            : resData.masterOneTimeTxLimit
        );
        setDailyTimeTxLimit(
          resData.masterDailyTxLimit === null
            ? dailyTimeTxLimit
            : resData.masterDailyTxLimit
        );
        setMonthTimeTxLimit(
          resData.masterMonthlyTxLimit === null
            ? monthTimeTxLimit
            : resData.masterMonthlyTxLimit
        );
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const getConfigButtonOrder = async () => {
    await API_VIRTUAL_CARD.getConfigButtonOrder()
      .then((res) => {
        if (res.data.data.isActived) setCustomButton(res.data.data);
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const changeModalView = (value: string) => {
    setIsOpen(value);
    setEditable(value);
  };

  const changeInput = async (value: any, item: any) => {
    let tempCardIdentifier = detailData.cardIdentifier;
    const index = detailData.cardIdentifier.findIndex(
      (d: any) => d.key === item.key
    );
    tempCardIdentifier[index].value = value;
    setDetailData({ ...detailData, cardIdentifier: tempCardIdentifier });
  };

  const inputHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    if (event.currentTarget.files) {
      const enteredName = event?.currentTarget?.files[0];
      uploadImage(enteredName);
    }
  };

  const changeData = async (
    value: any,
    key: any,
    prop: any = "",
    subprop: any = ""
  ) => {
    let tempData = detailData;
    if (prop === "") {
      setDetailData({ ...tempData, [key]: value });
    } else if (subprop !== "") {
      return setDetailData({
        ...tempData,
        [prop]: {
          ...tempData[prop],
          [key]: { ...tempData[prop][key], [subprop]: value },
        },
      });
    } else {
      if (typeof value === "string") {
        setDetailData({
          ...tempData,
          [prop]: { ...tempData[prop], [key]: parseInt(value) },
        });
      } else {
        setDetailData({
          ...tempData,
          [prop]: { ...tempData[prop], [key]: value },
        });
      }
    }
  };

  const uploadImage = async (imageFile: any) => {
    await API_VIRTUAL_CARD.uploadImage(imageFile)
      .then((res) => {
        changeInput(res.data, { key: "profileImage" });
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const handleOnClick = async () => {
    let filteredData = detailData.cardIdentifier.filter(
      (item: any) => item.value === ""
    );

    if (editable === "cardIdentifier") {
      if (detailData.name === "") {
        toast.error(t('virtual_card.card_name_is_required'));
      } else if (detailData.name.length > 50) {
        toast.error(t('virtual_card.card_name_length_cannot_exceed_50_characters'));
      } else if (filteredData.length > 0) {
        filteredData.map((item: any) => {
          toast.error(item.key_readable + t('virtual_card.required'));
        });
      } else {
        dispatch(setLoading(true));
        let dataOrder: any = {};
        if (detailData.cardBalance === null) {
          dataOrder = {
            name: detailData.name,
            cardIdentifier: detailData.cardIdentifier,
            description: detailData.description,
          };
        } else {
          dataOrder = {
            name: detailData.name,
            cardIdentifier: detailData.cardIdentifier,
            cardBalance: detailData.cardBalance,
            description: detailData.description,
          };
        }

        await API_VIRTUAL_CARD.updateCard(params.id, dataOrder)
          .then((res) => {
            dispatch(setLoading(false));
            toast.success(t('virtual_card.virtual_card_data_successfully_updated'));
            changeModalView("");
          })
          .catch((err) => {
            dispatch(setLoading(false));
            toast.error(t('virtual_card.failed_to_update_virtual_card_data'));
          });
      }
    } else {
      if (detailData.name === "") {
        toast.error(t('virtual_card.card_name_is_required'));
      } else if (detailData.name.length > 50) {
        toast.error(t('virtual_card.card_name_length_cannot_exceed_50_characters'));
      } else if (filteredData.length > 0) {
        filteredData.map((item: any) => {
          toast.error(item.key_readable + t('virtual_card.required'));
        });
      } else if (
        configData?.masterOneTimeTxLimit === null ||
        configData?.masterDailyTxLimit === null ||
        configData?.masterMonthlyTxLimit === null
      ) {
        if (
          detailData.cardBalance.oneTimeTxLimit >
          detailData.cardBalance.dailyTxLimit ||
          detailData.cardBalance.oneTimeTxLimit >
          detailData.cardBalance.monthTxLimit
        ) {
          toast.error(
            t('virtual_card.one_time_transaction_limit_cannot_be_greater_than_daily_or_monthly_limit')
          );
        } else if (
          detailData.cardBalance.dailyTxLimit >
          detailData.cardBalance.monthTxLimit
        ) {
          toast.error(
            t('virtual_card.daily_limit_cannot_be_greater_than_monthly_limit')
          );
        } else {
          let tempData = detailData;
          if (detailData.cardBalance.oneTimeTxLimit === undefined)
            tempData.cardBalance.oneTimeTxLimit =
              configData?.masterOneTimeTxLimit;
          if (detailData.cardBalance.dailyTxLimit === undefined)
            tempData.cardBalance.dailyTxLimit = configData?.masterDailyTxLimit;
          if (detailData.cardBalance.monthTxLimit === undefined)
            tempData.cardBalance.monthTxLimit =
              configData?.masterMonthlyTxLimit;
          dispatch(setLoading(true));
          let dataOrder = {
            name: detailData.name,
            cardIdentifier: detailData.cardIdentifier,
            cardBalance: detailData.cardBalance,
            description: detailData.description,
          };
          await API_VIRTUAL_CARD.updateCard(params.id, dataOrder)
            .then((res) => {
              dispatch(setLoading(false));
              toast.success(t('virtual_card.virtual_card_data_successfully_updated'));
              changeModalView("");
            })
            .catch((err) => {
              dispatch(setLoading(false));
              toast.error(t('virtual_card.failed_to_update_virtual_card_data'));
            });
        }
      } else if (detailData.cardBalance.oneTimeTxLimit > oneTimeTxLimit) {
        toast.error(
          t('virtual_card.your_card_transaction_limit_settings_exceed_the_default_limit')
        );
      } else if (
        detailData.cardBalance.oneTimeTxLimit >
        detailData.cardBalance.dailyTxLimit ||
        detailData.cardBalance.oneTimeTxLimit >
        detailData.cardBalance.monthTxLimit
      ) {
        toast.error(
          t('virtual_card.one_time_transaction_limit_cannot_be_greater_than_daily_or_monthly_limit')
        );
      } else if (detailData.cardBalance.dailyTxLimit > dailyTimeTxLimit) {
        toast.error(t('virtual_card.your_daily_limit_settings_exceed_the_default_limit'));
      } else if (
        detailData.cardBalance.dailyTxLimit >
        detailData.cardBalance.monthTxLimit
      ) {
        toast.error(t('virtual_card.daily_limit_cannot_be_greater_than_monthly_limit'));
      } else if (detailData.cardBalance.monthTxLimit > monthTimeTxLimit) {
        toast.error(t('virtual_card.your_monthly_limit_settings_exceed_the_default_limit'));
      } else {
        let tempCardBalance = detailData.cardBalance;
        if (tempCardBalance.oneTimeTxLimit === undefined)
          tempCardBalance.oneTimeTxLimit = configData?.masterOneTimeTxLimit;
        if (tempCardBalance.dailyTxLimit === undefined)
          tempCardBalance.dailyTxLimit = configData?.masterDailyTxLimit;
        if (tempCardBalance.monthTxLimit === undefined)
          tempCardBalance.monthTxLimit = configData?.masterMonthlyTxLimit;
        dispatch(setLoading(true));
        let dataOrder = {
          name: detailData.name,
          cardIdentifier: detailData.cardIdentifier,
          cardBalance: detailData.cardBalance,
          description: detailData.description,
        };
        await API_VIRTUAL_CARD.updateCard(params.id, dataOrder)
          .then((res) => {
            dispatch(setLoading(false));
            toast.success(t('virtual_card.virtual_card_data_successfully_updated'));
            changeModalView("");
          })
          .catch((err) => {
            dispatch(setLoading(false));
            toast.error(t('virtual_card.failed_to_update_virtual_card_data'));
          });
      }
    }
  };

  const downloadVirtualCard = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.downloadVirtualCardPDF(params?.id)
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `Virtual Card - ${params?.id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  React.useEffect(() => {
    getTemplate();
    getConfigCard();
    getConfigButtonOrder();
    if (location.state !== null) {
      setSingleCard(location.state.isSingleCard);
    }
  }, []);

  return (
    <>
      <BrowserView>
        <VirtualCardDetailWeb
          inputHandler={(event: any, key: string) => inputHandler(event, key)}
          changeData={(value: any, key: string, prop: any, subprop: any) =>
            changeData(value, key, prop, subprop)
          }
          changeModalView={(value: string) => changeModalView(value)}
          changeInput={(value: any, item: AnyAsyncThunk) =>
            changeInput(value, item)
          }
          getTemplate={() => getTemplate()}
          handleOnClick={() => handleOnClick()}
          setIsOpen={(value: any) => setIsOpen(value)}
          setEditable={(value: any) => setEditable(value)}
          setDetailData={(value: any) => setDetailData(value)}
          detailData={detailData}
          configData={configData}
          background={background}
          template={template}
          isOpen={isOpen}
          editable={editable}
          oneTimeTxLimit={oneTimeTxLimit}
          dailyTimeTxLimit={dailyTimeTxLimit}
          monthTimeTxLimit={monthTimeTxLimit}
          singleCard={singleCard}
          customButton={customButton}
          features={features}
          downloadVirtualCard={downloadVirtualCard}
          isPortrait={isPortrait}
        />
      </BrowserView>
      <MobileView>
        <VirtualCardDetailMobile
          inputHandler={(event: any, key: string) => inputHandler(event, key)}
          changeData={(value: any, key: string, prop: any, subprop: any) =>
            changeData(value, key, prop, subprop)
          }
          changeModalView={(value: string) => changeModalView(value)}
          changeInput={(value: any, item: AnyAsyncThunk) =>
            changeInput(value, item)
          }
          getTemplate={() => getTemplate()}
          handleOnClick={() => handleOnClick()}
          setIsOpen={(value: any) => setIsOpen(value)}
          setEditable={(value: any) => setEditable(value)}
          setDetailData={(value: any) => setDetailData(value)}
          detailData={detailData}
          configData={configData}
          background={background}
          template={template}
          isOpen={isOpen}
          editable={editable}
          oneTimeTxLimit={oneTimeTxLimit}
          dailyTimeTxLimit={dailyTimeTxLimit}
          monthTimeTxLimit={monthTimeTxLimit}
          singleCard={singleCard}
          customButton={customButton}
          features={features}
          downloadVirtualCard={downloadVirtualCard}
          isPortrait={isPortrait}
        />
      </MobileView>
    </>
  );
};

export default VirtualCardDetail;
