import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TransferMemberSuccessWeb from "./paymentSummaryWeb";
import TransferMemberSuccessMobile from "./paymentSummaryMobile";

const TransferMemberSuccess = () => {
  return (
    <>
      <BrowserView>
        <TransferMemberSuccessWeb></TransferMemberSuccessWeb>
      </BrowserView>
      <MobileView>
        <TransferMemberSuccessMobile></TransferMemberSuccessMobile>
      </MobileView>
    </>
  );
};

export default TransferMemberSuccess;
