import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ZakatDetailWeb from "./zakatDetailWeb";
import ZakatDetailMobile from "./zakatDetailMobile";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useNavigate, useParams } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import "react-spring-bottom-sheet/dist/style.css";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company.js";
import { getZakatById } from "../../core/service/api_zakat";
import { ConfigColor } from "../../config/global.js";

const ZakatDetail = () => {
  const color = ConfigColor();
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<any>({});
  const [hideButton, setHideButton] = useState(true);

  const getData = () => {
    setLoading(true);
    getZakatById(params.id)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getLastDate = (arr: any[] = [], key = "date") => {
    let firstArray = _.first(arr);
    if (firstArray)
      return moment(new Date(firstArray[key])).format("LL") || "-";
  };

  // const handleRemainingDays2 = (date: any) => {
  //   let dateA = moment(new Date(date));
  //   let dateB = moment(new Date());
  //   let dateDiff = dateB.diff(dateA, "days");
  //   return dateDiff > -1 ? `${dateDiff} Hari Lagi` : "Expired";
  // };

  const indicators = (index: any) => (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="indicator"
    ></div>
  );

  useEffect(() => {
    if (params.id) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let hDesc: any = document.getElementById("hDesc");
    setHideButton(true);
    if ((hDesc?.offsetHeight as number) > 150) setHideButton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <BrowserView>
        <ZakatDetailWeb
          color={color}
          company={company}
          navigate={navigate}
          params={params}
          showModal={showModal}
          setShowModal={(value: any) => setShowModal(value)}
          data={data}
          setData={(value: any) => setData(value)}
          hideButton={hideButton}
          setHideButton={(value: any) => setHideButton(value)}
          getData={getData}
          getLastDate={getLastDate}
          indicator={indicators}
        ></ZakatDetailWeb>
      </BrowserView>
      <MobileView>
        <ZakatDetailMobile></ZakatDetailMobile>
      </MobileView>
    </>
  );
};

export default ZakatDetail;
