import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ZakatWeb from "./zakatWeb";
import ZakatMobile from "./zakatMobile";

const Zakat = () => {
  return (
    <>
      <BrowserView>
        <ZakatWeb></ZakatWeb>
      </BrowserView>
      <MobileView>
        <ZakatMobile></ZakatMobile>
      </MobileView>
    </>
  );
};

export default Zakat;
