import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InvoiceWeb from "./invoiceCreateWeb";
import InvoiceMobile from "./invoiceCreateMobile";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Combo from "../../config/Combo";
import { checkRegex } from "../../config/global.js";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company.js";
import { createInvoice } from "../../core/service/api_invoice.js";
import {
  comboMemberByid,
  comboMerchantByid,
  getMemberByNoId,
  getMerchantByNoId,
} from "../../core/service/api_user";
import { ConfigColor } from "../../config/global.js";

const Invoice = () => {
  const color = ConfigColor();
  const company: Company = useAppSelector((state) => state.company.company);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [targetMember, setTargetMember] = React.useState<any>({});
  const [dataPost, setDataPost] = React.useState({
    title: "",
    description: "",
    invoiceDate: "",
    expiredDate: "",
    item_details: [],
    tags: [] as any[],
    userId: "",
    userType: "",
    invoiceType: "UMUM",
    config: { allowPartialPayment: true },
  });
  const [noId, setNoId] = React.useState("");
  const [valid, setValid] = React.useState({
    title: true,
    description: true,
    invoiceDate: true,
    expiredDate: true,
    item_details: true,
    tags: true,
    userId: true,
    userType: true,
    invoiceType: true,
  });
  const [toggleSetting, setToggleSetting] = React.useState(false);
  const [allowPartialPayment, setAllowPartialPayment] = React.useState(true);
  const [dataDetails, setDataDetails] = React.useState<any[]>(() => []);
  const [detailIndex, setDetailIndex] = React.useState(-1);
  const [tags, setTags] = React.useState<any[]>(() => []);

  const [dataDetail, setDataDetail] = React.useState({
    name: "",
    amount: "0",
    discount: "0",
  });
  const [validDetail, setValidDetail] = React.useState({
    name: true,
    amount: true,
    discount: true,
  });

  const [listUserType, setListUserType] = React.useState([...Combo.userType]);
  const [selectedUserType, setSelectedUserType] = React.useState<any>(null);

  const [listInvoiceType, setListInvoiceType] = React.useState([
    ...Combo.invoiceType,
  ]);
  const [selectedInvoiceType, setSelectedInvoiceType] = React.useState<any>({
    value: "UMUM",
    label: "UMUM",
  });

  const handleTags = (tags: any) => {
    setTags(tags);
  };

  const changeCombo = (e: any, key: any) => {
    changeData(e.value, key);
  };

  const changeData = async (value: any, key: any, prop: any = "") => {
    let tempData: any = { ...dataPost };

    if (prop !== "") {
      return setDataPost({
        ...dataPost,
        [prop]: { ...tempData[prop], [key]: value },
      });
    } else {
      setDataPost({ ...dataPost, [key]: value });
    }
  };

  const changeDataDetail = async (value: any, key: any, prop: any = "") => {
    let tempData: any = { ...dataDetail };

    if (prop !== "") {
      return setDataDetail({
        ...dataDetail,
        [prop]: { ...tempData[prop], [key]: value },
      });
    } else {
      setDataDetail({ ...dataDetail, [key]: value });
    }
  };

  const changeValid = (key: any) => {
    setValid({ ...valid, [key]: true });
  };
  const changeValidDetail = (key: any) => {
    setValidDetail({ ...validDetail, [key]: true });
  };

  const handleDetail = () => {
    let valid = { name: true, amount: true, discount: true };
    if (!dataDetail.name) {
      valid.name = false;
    }
    if (!parseInt(dataDetail.amount)) {
      valid.amount = false;
    }
    if (parseInt(dataDetail.discount) > parseInt(dataDetail.amount)) {
      valid.discount = false;
    }
    setValidDetail(valid);
    if (!(valid.name && valid.amount && valid.discount)) return false;
    let details = [...dataDetails];
    if (detailIndex < 0) details.push(dataDetail);
    else details[detailIndex] = dataDetail;
    setDataDetails(details);
    setToggleSetting(false);
  };
  const handleDelete = () => {
    let details = [...dataDetails];
    details.splice(detailIndex, 1);
    setDataDetails(details);
    setToggleSetting(false);
    setDetailIndex(-1);
  };

  const postData = () => {
    let valid = {
      title: true,
      description: true,
      invoiceDate: true,
      expiredDate: true,
      item_details: true,
      tags: true,
      userId: true,
      userType: true,
      invoiceType: true,
    };
    if (!dataPost.title) {
      valid.title = false;
    }
    if (!dataPost.invoiceDate) {
      valid.invoiceDate = false;
    }
    if (!dataPost.expiredDate) {
      valid.expiredDate = false;
    }
    if (dataDetails.length === 0) {
      valid.item_details = false;
    }
    if (!targetMember._id) {
      valid.userId = false;
    }
    if (!selectedUserType) {
      valid.userType = false;
    }
    if (!selectedInvoiceType) {
      valid.invoiceType = false;
    }
    setValid(valid);
    if (
      !(
        valid.title &&
        valid.description &&
        valid.invoiceDate &&
        valid.expiredDate &&
        valid.item_details &&
        valid.tags &&
        valid.userId &&
        valid.userType &&
        valid.invoiceType
      )
    )
      return false;

    let newData = { ...dataPost, item_details: dataDetails };
    newData["userId"] = targetMember._id;
    newData["tags"] = tags;
    newData["userType"] = selectedUserType.value;
    newData["invoiceType"] = selectedInvoiceType.value;
    newData["config"] = {
      allowPartialPayment: allowPartialPayment,
    };
    createInvoice(newData)
      .then((response) => {
        navigate(`/${company.initial}/invoices/`, {
          state: {
            openTab: 2,
          },
        });
      })
      .catch((err) => {});
  };

  const checkMember = async (isMember = true) => {
    if (!checkRegex("alphanumeric", noId)) {
      return;
    }
    dispatch(setLoading(true));
    setTargetMember({});
    if (isMember)
      return await getMemberByNoId(noId)
        .then((res) => {
          dispatch(setLoading(false));
          setTargetMember(res.data.data);
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    await getMerchantByNoId(noId)
      .then((res) => {
        dispatch(setLoading(false));
        setTargetMember(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const countTotal = (item: any) => {
    return item.amount - item.discount;
  };

  const sumTotal = () => {
    return _.sumBy(dataDetails, (el) => el.amount - el.discount);
  };

  return (
    <>
      <BrowserView>
        <InvoiceWeb
          handleTags={(tags: any) => handleTags(tags)}
          changeCombo={(e: any, key: any) => changeCombo(e, key)}
          changeData={(value: any, key: any, prop: any) => changeData(value, key, prop)}
          changeDataDetail={(value: any, key: any, prop: any) => changeDataDetail(value, key, prop)}
          changeValid={(key: any) => changeValid(key)}
          changeValidDetail={(key: any) => changeValidDetail(key)}
          checkMember={(isMember: any) => checkMember(isMember)}
          countTotal={(item: any) => countTotal(item)}
          setSelectedInvoiceType={(value: any) => setSelectedInvoiceType(value)}
          setSelectedUserType={(value: any) => setSelectedUserType(value)}
          setNoId={(value: any) => setNoId(value)}
          setDetailIndex={(value: any) => setDetailIndex(value)}
          setToggleSetting={(value: any) => setToggleSetting(value)}
          setDataDetail={(value: any) => setDataDetail(value)}
          setValidDetail={(value: any) => setValidDetail(value)}
          setAllowPartialPayment={(value: any) => setAllowPartialPayment(value)}
          handleDetail={() => handleDetail()}
          handleDelete={() => handleDelete()}
          postData={() => postData()}
          sumTotal={() => sumTotal()}
          color={color}
          targetMember={targetMember}
          dataPost={dataPost}
          noId={noId}
          valid={valid}
          toggleSetting={toggleSetting}
          allowPartialPayment={allowPartialPayment}
          dataDetails={dataDetails}
          detailIndex={detailIndex}
          tags={tags}
          dataDetail={dataDetail}
          validDetail={validDetail}
          listUserType={listUserType}
          selectedUserType={selectedUserType}
          listInvoiceType={listInvoiceType}
          selectedInvoiceType={selectedInvoiceType}
        />
      </BrowserView>
      <MobileView>
        <InvoiceMobile
          handleTags={(tags: any) => handleTags(tags)}
          changeCombo={(e: any, key: any) => changeCombo(e, key)}
          changeData={(value: any, key: any, prop: any) => changeData(value, key, prop)}
          changeDataDetail={(value: any, key: any, prop: any) => changeDataDetail(value, key, prop)}
          changeValid={(key: any) => changeValid(key)}
          changeValidDetail={(key: any) => changeValidDetail(key)}
          checkMember={(isMember: any) => checkMember(isMember)}
          countTotal={(item: any) => countTotal(item)}
          setSelectedInvoiceType={(value: any) => setSelectedInvoiceType(value)}
          setSelectedUserType={(value: any) => setSelectedUserType(value)}
          setNoId={(value: any) => setNoId(value)}
          setDetailIndex={(value: any) => setDetailIndex(value)}
          setToggleSetting={(value: any) => setToggleSetting(value)}
          setDataDetail={(value: any) => setDataDetail(value)}
          setValidDetail={(value: any) => setValidDetail(value)}
          setAllowPartialPayment={(value: any) => setAllowPartialPayment(value)}
          handleDetail={() => handleDetail()}
          handleDelete={() => handleDelete()}
          postData={() => postData()}
          sumTotal={() => sumTotal()}
          color={color}
          targetMember={targetMember}
          dataPost={dataPost}
          noId={noId}
          valid={valid}
          toggleSetting={toggleSetting}
          allowPartialPayment={allowPartialPayment}
          dataDetails={dataDetails}
          detailIndex={detailIndex}
          tags={tags}
          dataDetail={dataDetail}
          validDetail={validDetail}
          listUserType={listUserType}
          selectedUserType={selectedUserType}
          listInvoiceType={listInvoiceType}
          selectedInvoiceType={selectedInvoiceType}
        />
      </MobileView>
    </>
  );
};

export default Invoice;
