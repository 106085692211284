/* eslint-disable react-hooks/exhaustive-deps */
import { findIndex } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import userDefault from "../../assets/images/userDefault.png";
import { removeState } from "../../core/feature/browser-storage/browser-storage";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setToken } from "../../core/feature/token/tokenSlice";
import { setUser } from "../../core/feature/user/userSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
// import User from "../../core/models/user";
import UserLogin from "../../core/models/userLogin";
import { cekTokenAccount } from "../../core/service/api";
import * as API_USER from "../../core/service/api_user";
import Header from "../_common/headerComponent";
import SwitchAccount from "./switchAccountList";
import { ConfigColor } from "../../config/global";
import { useTranslation } from "react-i18next";

const SwitchAccountWeb = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  // const [profileData, setProfileData] = useState<any>({});
  const token: Token[] = useAppSelector((state) => state.token.token);
  const fireBaseToken: string = useAppSelector(
    (state) => state.fireBaseToken.fireBaseToken
  );
  // const user: User = useAppSelector((state) => state.user.user);
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const color = ConfigColor();

  const getProfile = async () => {
    dispatch(setLoading(true));
    await API_USER.getProfile()
      .then((res) => {
        console.log("haii", res.data.data.profileImage);
        var newUserLogin = userLogin?.length > 0 ? [...userLogin] : [];
        let indexUserLogin = findIndex(newUserLogin, {
          id: res.data.data.noId,
        });
        // console.log(indexUserLogin);

        if (indexUserLogin < 0) {
          // newUserLogin.push({
          //   token: res.data.data.authToken,
          // });
        } else {
          newUserLogin[indexUserLogin] = {
            ...newUserLogin[indexUserLogin],
            name: res.data.data.name,
            photo: res.data.data.profileImage,
            id: res.data.data.noId,
          };
        }

        dispatch(setUserLogin(newUserLogin));

        dispatch(setLoading(false));
        // setProfileData(res.data.data);
        dispatch(setUser(res.data.data));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const changeAccount = (item: UserLogin) => {
    deleteDeviceFcm();
    cekTokenAccount(item.token)
      .then((res) => {
        var newToken = token ? [...token] : [];
        let indexToken = findIndex(token, { cid: company.initial });

        if (indexToken < 0) {
          newToken.push({
            cid: company.initial,
            token: item.token,
          });
        } else {
          if (newToken[indexToken]?.token === item.token)
            return navigate(`/${company?.initial}`);
          newToken[indexToken] = {
            cid: company.initial,
            token: item.token,
          };
        }
        dispatch(setToken(newToken));
        addDeviceFcm();
        navigate(`/${company?.initial}`);
      })
      .catch((err) => { });
  };

  const deleteDeviceFcm = async () => {
    dispatch(setLoading(true));
    await API_USER.deleteDeviceFirebase()
      .then((res) => {
        dispatch(setLoading(false));
        console.log("success delete");
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const addDeviceFcm = async () => {
    // dispatch(setLoading(true));
    if (fireBaseToken) {
      await API_USER.addDeviceFirebase(fireBaseToken)
        .then((res) => {
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  // const getProfileImage = useMemo(() => {
  //   console.log("in", user?.profileImage);

  //   if (user?.profileImage == "") {
  //     return userDefault;
  //   }
  //   return user?.profileImage;
  // }, [user]);

  useEffect(() => {
    // getMenuName();
    getProfile();
    removeState("virtual_card");
    removeState("show_balance_card");
    console.log(window.location);
  }, []);

  return (
    <>
      <Header />
      <section className="h-full  min-h-screen font-poppins bg-mobile">
        <div className="h-[87vh] m-auto w-2/5 lg:w-1/2 min-w-[30rem] flex flex-col gap-3 justify-center relative z-0 -mt-20 pb-5">
          <div className="flex-grow mt-6">
            <p className="text-thirdy">{t('switchAccount.chooseAccount')}</p>
            <SwitchAccount
              setSelectedUser={(item: any) => setSelectedUser(item)}
            ></SwitchAccount>
          </div>

          <div className="w-full ">
            <button
              style={{
                backgroundColor: `${color?.color1}`,
              }}
              className="flex flex-row items-center justify-center cursor-pointer gap-2 h-[48px] w-full text-xl p-0.5 rounded-lg"
              onClick={() => changeAccount(selectedUser)}
            >
              <div className="w-full bg-mobile h-full rounded-lg justify-center flex">
                <div className="flex flex-row items-center w-fit gap-2 text-white">
                  {/* <img className="grow-0 w-6 h-6 " src={Logout} alt="" /> */}
                  <h1
                    style={{
                      backgroundColor: `${color?.color1}`,
                    }}
                    className="grow font-semibold text-base inline-block text-transparent bg-clip-text md:text-base m-0  font-poppins"
                  >
                    {t('switchAccount.confirm')}
                  </h1>
                </div>
              </div>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default SwitchAccountWeb;
