import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBHistoryDetailWeb from "./ppobHistoryDetailWeb";
import PPOBHistoryDetailMobile from "./ppobHistoryDetailMobile";

const PPOBHistoryDetail = () => {
  return (
    <>
      <BrowserView>
        <PPOBHistoryDetailWeb></PPOBHistoryDetailWeb>
      </BrowserView>
      <MobileView>
        <PPOBHistoryDetailMobile></PPOBHistoryDetailMobile>
      </MobileView>
    </>
  );
};

export default PPOBHistoryDetail;
