import { BrowserView, MobileView } from "react-device-detect";
import CashWeb from "./cashWeb";
import CashMobile from "./cashMobile";
import { useNavigate, useParams } from "react-router-dom";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useEffect } from "react";
import Company from "../../../core/models/company";
import { useAppSelector } from "../../../core/feature/hooks";

const Cash = () => {
  const tutor = [
    { no: 1, text: "Silahkan datang ke kasir" },
    { no: 2, text: "Siapkan kartu atau uang untuk melanjutkan transaksi" },
    { no: 3, text: "Sebutkan Nama dan No Meja untuk membayar" },
    {
      no: 4,
      text: "Jika pembayaran menggunakan kartu, Kartu akan di tap pada mesin tap kartu restaurant",
    },
    {
      no: 5,
      text: "Jika pembayaran menggunakan uang, bayar sesuai jumlah yang tertera",
    },
  ];

  const navigate = useNavigate();
  const id = localStorage.getItem("orderId");
  const param = useParams();
  const company: Company = useAppSelector((state) => state.company.company);

  const getStatus = async () => {
    await API_COMMERCE.cekStatus(id)
      .then((res) => {
        var x: any = localStorage.getItem("summaryData");
        var y: any = JSON.parse(x);
        if (res.data.data.paymentStatus === "PAID") {
          return navigate(
            `/${company.initial}/merchant_catalog_product/${param.merchantId}/cart/${y?.transactionId}/order`
          );
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const loop = setInterval(() => {
      getStatus();
    }, 5000);
    return () => clearInterval(loop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BrowserView>
        <CashWeb
          company={company}
          param={param}
          navigate={navigate}
          tutor={tutor}
        />
      </BrowserView>
      <MobileView>
        <CashMobile
          company={company}
          param={param}
          navigate={navigate}
          tutor={tutor}
        />
      </MobileView>
    </>
  );
};

export default Cash;
