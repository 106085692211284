/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ListMerchantsMobile from "./listMerchantsMobile";
import ListMerchantsWeb from "./listMerchantsWeb";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import User, { MarketplaceConfig } from "../../../core/models/user";
import Company from "../../../core/models/company";
import Token from "../../../core/models/token";
import { find } from "lodash";
import { TimeZone } from "../../../config/global";

const ListMerchants = () => {
  const marketplaceConfig: MarketplaceConfig = useAppSelector(
    (state) => state.marketplaceConfig.marketplaceConfig
  );
  const [merchants, setMerchants] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [isLogin, setIsLogin] = React.useState(user && newToken);
  const [pNameNoLogin, setPNameNoLogin] = useState("");
  const [mNameNoLogin, setMNameNoLogin] = useState("");
  const mName: string = marketplaceConfig?.merchantName || "";
  const time = TimeZone();

  const getAllShop = async (currentPage: number = page) => {
    let query = {
      timezone: time,
      page: currentPage,
      size: 10,
      sortBy: "-createdDate",
    };
    dispatch(setLoading(true));
    await API_COMMERCE.getAllShop(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setMerchants(merchants.concat(res.data.data));
        } else {
          setMerchants(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getAllShopNoLoginn = async (currentPage: number = page) => {
    let query = {
      timezone: time,
      page: currentPage,
      size: 10,
      sortBy: "-createdDate",
    };
    dispatch(setLoading(true));
    await API_COMMERCE.getAllShopNoLogin(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setMerchants(merchants.concat(res.data.data));
        } else {
          setMerchants(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  function currentSize() {
    let a = merchants?.length;
    let b = a / page;

    return b;
  }

  const addMore = async () => {
    setPage(page + 1);
    if (isLogin) {
      getAllShop(page + 1);
    } else {
      getAllShopNoLoginn(page + 1);
    }
  };

  const getMnameConfigNoLogin = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.marketplaceNameNoLogin(company.id)
      .then((res) => {
        if (Object.keys(res.data.data).length === 0) {
          setMNameNoLogin("Toko");
          setPNameNoLogin("Produk");
        } else {
          if (
            res.data.data.merchantName === "" ||
            res.data.data.merchantName === null
          ) {
            setMNameNoLogin("Toko");
          } else {
            setMNameNoLogin(res.data.data.merchantName);
          }

          if (
            res.data.data.productName === "" ||
            res.data.data.merchantName === null
          ) {
            setPNameNoLogin("Produk");
          } else {
            setPNameNoLogin(res.data.data.productName);
          }
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    setIsLogin(user && newToken);
    if (isLogin) {
      getAllShop();
    } else {
      getAllShopNoLoginn();
    }
    getMnameConfigNoLogin();
  }, []);

  return (
    <>
      <BrowserView>
        <ListMerchantsWeb
          addMore={() => addMore()}
          currentSize={currentSize}
          totalElements={totalElements}
          pNameNoLogin={pNameNoLogin}
          mNameNoLogin={mNameNoLogin}
          mName={mName}
          merchants={merchants}
          marketplaceConfig={marketplaceConfig}
        />
      </BrowserView>
      <MobileView>
        <ListMerchantsMobile
          addMore={() => addMore()}
          currentSize={currentSize}
          totalElements={totalElements}
          pNameNoLogin={pNameNoLogin}
          mNameNoLogin={mNameNoLogin}
          mName={mName}
          merchants={merchants}
          marketplaceConfig={marketplaceConfig}
        />
      </MobileView>
    </>
  );
};

export default ListMerchants;
