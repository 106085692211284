/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from "react";
import * as API_USER from "../../core/service/api_user.js";
import "react-multi-carousel/lib/styles.css";
import userDefault from "../../assets/images/userDefault.png";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import User from "../../core/models/user";
import closepayLogo from "../../assets/images/solusinegeri-logo.svg";

import info from "../../assets/images/info.svg";
import notification from "../../assets/images/notification.svg";
import arrow from "../../assets/images/arrow-left-ic.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Token from "../../core/models/token";
import * as API from "../../core/service/api";
import { find } from "lodash";
import DocumentIcon from "../../assets/images/Iconly/Light-Outline/Document.svg";
import scanQRCode from "../../assets/images/scan-barcode.svg";
import { setLoading } from "../../core/feature/config/configSlice";
import { setPin } from "../../core/feature/user/pinSlice";
import download_icon from "../../assets/images/download-document.svg";

import CustomTooltip from "../_common/tooltip";
import { useTranslation } from "react-i18next";
interface HeaderProps {
  navbarBrand?: any;
  navbarText?: string;
  backgroundStyle?: string;
  navigateTo?: any;
  historyUrl?: any;
  backToUrl?: string;
  type?: string;
  onClick?: any;
  isHideBackButton?: boolean;
}

const Header: FC<HeaderProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const sessionUrl = sessionStorage.getItem("urlWebview");
  const url = sessionUrl
    ?.replace("https://app.dev.solusinegeri.com", "")
    .replace("https://app.solusinegeri.com", "");

  const getPinConfig = async () => {
    await API_USER.getPinConfig()
      .then((res) => {
        dispatch(setPin(Number(res.data.data)));
      })
      .catch((err) => {
        // dispatch(removeCompany());
      });
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let header: any = query.get("header");
  console.log(header, "halllo");

  React.useEffect(() => {
    dispatch(setLoading(true));
    getPinConfig();
    API.cekToken()
      .then((res) => {
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <>
      <section
        id="header-app"
        className={
          props.navbarText
            ? props.backgroundStyle
              ? props.backgroundStyle
              : "bg-transparent mb-3"
            : "bg-second"
        }
      >
        {props.navbarText ? (
          <section className="flex flex-tow items-center z-50 px-4 py-3">
            {props.navigateTo ? (
              <div
                onClick={() =>
                  navigate(props.navigateTo, {
                    state: { navigateTo: `/${company?.initial}` },
                  })
                }
                className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
              >
                <img
                  src={arrow}
                  style={{ width: 6, height: 12 }}
                  className="m-auto"
                  alt=""
                />
              </div>
            ) : props.isHideBackButton === true || header === "false" ? (
              ""
            ) : (
              <div
                onClick={() => {
                  if (sessionUrl !== null) {
                    if (pathname === url) {
                      window.close();
                    } else {
                      navigate(-1);
                    }
                  } else {
                    props.backToUrl
                      ? navigate(`${props.backToUrl}`)
                      : navigate(-1);
                  }
                }}
                className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
              >
                <img
                  src={arrow}
                  style={{ width: 6, height: 12 }}
                  className="m-auto"
                  alt=""
                />
              </div>
            )}

            <h1
              className={`${
                props.backgroundStyle ? "text-white" : "text-customBlack"
              } font-montserrat font-bold text-base m-auto relative`}
            >
              {props.navbarText}
            </h1>
            {props.historyUrl ? (
              <div
                onClick={() => navigate(props.historyUrl)}
                className="cursor-pointer"
              >
                <img src={DocumentIcon} alt="Document.svg" />
              </div>
            ) : null}
            {props.type === "virtual-card" && (
              <div
                onClick={() => props.onClick()}
                className="bg-white border-[1px] border-[#D9D9D9] rounded-[18px] p-2 cursor-pointer"
              >
                <img src={download_icon} alt="" />
              </div>
            )}
          </section>
        ) : user && newToken ? (
          <div className="container flex py-4 items-center">
            {header === "false" ? (
              ""
            ) : (
              <div
                onClick={() =>
                  props.backToUrl
                    ? navigate(`${props.backToUrl}`)
                    : navigate(-1)
                }
                className="hover:cursor-pointer flex absolute left-5 bg-white rounded-2xl border-1 border-solid border-gray-400  w-10 h-10 justify-center items-center p-1.5 active:bg-gray-300"
              >
                <img
                  src={arrow}
                  style={{ width: 6, height: 12 }}
                  className="m-auto"
                  alt=""
                />
              </div>
            )}
            <div className="grow">
              <img
                src={
                  company?.companyLogo !== null
                    ? company?.companyLogo
                    : closepayLogo
                }
                className="h-12 w-12 object-cover cursor-pointer rounded-full"
                alt="closepayLogo"
                onClick={() => navigate(`/${company?.initial}`)}
              />
            </div>
            <div className="flex grow-0 gap-6 items-center">
              <div
                className="flex flex-col gap-2 items-center cursor-pointer"
                onClick={() => navigate(`/${company?.initial}/notification`)}
              >
                <img src={notification} alt="" className="w-8 h-8" />
                <p className="text-pHeder font-normal text-xs mb-0">
                  {t("navbar.notification")}
                </p>
              </div>
              <div
                className="flex flex-col gap-2 items-center cursor-pointer"
                onClick={() =>
                  navigate(`/${company?.initial}/news-information`)
                }
              >
                <img src={info} alt="" className="w-8 h-8" />
                <p className="text-pHeder font-normal text-xs mb-0">
                  {t("navbar.news")}
                </p>
              </div>

              <div
                className="flex gap-3 items-center cursor-pointer ml-12"
                onClick={() => navigate(`/${company?.initial}/profile`)}
              >
                <CustomTooltip
                  content={
                    <div className="max-w-full whitespace-pre-line">
                      {user.name}
                    </div>
                  }
                  direction="bottom"
                  children={
                    <p className="text-pHeder font-light text-lg m-0">
                      {/* {user.name} */}
                      {(user?.name as string)?.length > 10
                        ? user?.name?.toString().substring(0, 10) + "..."
                        : user?.name}
                    </p>
                    //     <div className="md:text-base text-xs font-semibold">
                    // </div>
                  }
                ></CustomTooltip>
                <img
                  src={user.profileImage ? `${user.profileImage}` : userDefault}
                  alt=""
                  className="w-12 h-12 rounded-full object-cover"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="container flex py-4 items-center">
            <div className="grow">
              <img
                src={
                  company?.companyLogo !== null
                    ? company?.companyLogo
                    : closepayLogo
                }
                className="h-12 cursor-pointer"
                alt="closepayLogo"
                onClick={() => navigate(`/${company?.initial}`)}
              />
            </div>
            <div className="flex grow-0 gap-6 items-center">
              <div
                className="flex flex-col gap-2 items-center cursor-pointer"
                onClick={() => navigate(`/${company?.initial}/login`)}
              >
                <img src={notification} alt="" className="w-8 h-8" />
                <p className="text-pHeder font-normal text-xs mb-0">
                  {t("navbar.notification")}
                </p>
              </div>
              <div
                className="flex flex-col gap-2 items-center cursor-pointer"
                onClick={() => navigate(`/${company?.initial}/news`)}
              >
                <img src={info} alt="" className="w-8 h-8" />
                <p className="text-pHeder font-normal text-xs mb-0">
                  {t("navbar.news")}
                </p>
              </div>

              <div
                className="flex gap-3 items-center cursor-pointer ml-12"
                onClick={() => navigate(`/${company?.initial}/login`)}
              >
                <p className="text-pHeder font-light text-lg m-0">
                  Selamat Datang
                </p>
                <img src={userDefault} alt="" className="w-12 h-12" />
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Header;
