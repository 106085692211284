import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBBPJSWeb from "./ppobBPJSWeb";
import PPOBBPJSMobile from "./ppobBPJSMobile";

const PPOBBPJS = () => {
  return (
    <>
      <BrowserView>
        <PPOBBPJSWeb></PPOBBPJSWeb>
      </BrowserView>
      <MobileView>
        <PPOBBPJSMobile></PPOBBPJSMobile>
      </MobileView>
    </>
  );
};

export default PPOBBPJS;
