import React from 'react';
import Header from '../../_common/headerComponent';
import GreenCard from '../../../assets/images/icon_queue/green-card.png';
import QueueComponent from '../../_common/queueComponent';
import ProgressBar from '../../../assets/images/icon_queue/progress_bar.png';

const SuccessCheckinWeb = () => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <Header />
      <div className="md:w-1/2 w-full mx-auto md:mt-12 mt-[0px] px-4 pb-10">
        <div className="relative mb-9">
          <img
            src={GreenCard}
            alt=""
          />
          <div className="flex flex-col items-center absolute bottom-0 left-1/2 z-10" style={{ transform: "translate(-50%, -50%)" }}>
            <h6 className="md:text-sm text-xs text-white font-normal">Antrian No.</h6>
            <h1 className="md:text-6xl text-5xl text-white font-bold">B-481</h1>
            <h6 className="md:text-sm text-xs text-white font-normal">Klinik Merdeka Batubara</h6>
          </div>
        </div>
        <h6 className="md:text-lg text-base text-green font-bold text-center my-4">Zx0988</h6>
        <div className="grid grid-cols-3 items-center border-b-[1px] border-newColor divide-x-[0.5px] divide-newColor pb-2">
          <div className="text-center px-2">
            <h6 className="md:text-sm text-xs text-newColor">Nama</h6>
            <h6 className="md:text-base text-sm text-newColor font-semibold">Ulfatun Amanah</h6>
          </div>
          <div className="text-center px-2">
            <h6 className="md:text-sm text-xs text-newColor">Hari</h6>
            <h6 className="md:text-base text-sm text-newColor font-semibold">Senin, 10-2-2023</h6>
          </div>
          <div className="text-center px-2">
            <h6 className="md:text-sm text-xs text-newColor">Layanan</h6>
            <h6 className="md:text-base text-sm text-newColor font-semibold">Cek Mata</h6>
          </div>
        </div>
        <h6 className="md:text-base text-sm text-customBlack text-center my-3">Antrian</h6>
        <QueueComponent current="A-10" remainder="13" />
        <div className="text-center mt-4">
          <div className="relative">
            <img src={ProgressBar} alt="" className="mx-auto w-[155px]" />
            <div className="absolute left-1/2 top-1/2" style={{transform: "translate(-50%, -50%)"}}>
              <h6 className="md:text-sm text-xs text-newColor">No Anda</h6>
              <h2 className="text-green font-bold text-[32px]">A-18</h2>
            </div>
          </div>
          <h6 className="md:text-lg text-base text-customBlack font-light mt-5">Estimasi Waktu : 
            <span className="text-green font-semibold">10.15 WIB</span>
          </h6>
          <p className="md:text-lg text-base text-newColor">Harap Datang 15 menit sebelum waktu estimasi yang ditentukan</p>
        </div>
      </div>
    </section>
  )
}

export default SuccessCheckinWeb