import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  url: string;
  data: any;
  transactionType: string;
  navigateTo: string;
  navigateState: any;
  redirect: string;
  email: string;
  username: string;
  password: string;
  deviceId: string;
  companyId: string;
  message: string;
  requested: boolean;
  stateEnd: string
};

const initialState: InitialState = {
  url: "",
  data: {},
  transactionType: "",
  navigateTo: "",
  navigateState: {},
  redirect: "",
  email: "",
  username: "",
  password: "",
  deviceId: "",
  companyId: "",
  message: "",
  requested: false,
  stateEnd: ""
};

export const configSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    setOtpUrl: (state, action: PayloadAction<any>) => {
      state.url = action.payload;
    },
    setOtpData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setOtpTransactonType: (state, action: PayloadAction<any>) => {
      state.transactionType = action.payload;
    },
    setOtpNavigateTo: (state, action: PayloadAction<any>) => {
      state.navigateTo = action.payload;
    },
    setOtpNavigateState: (state, action: PayloadAction<any>) => {
      state.navigateState = action.payload;
    },
    setOtpRedirect: (state, action: PayloadAction<any>) => {
      state.redirect = action.payload;
    },
    setOtpDeviceId: (state, action: PayloadAction<any>) => {
      state.deviceId = action.payload;
    },
    setOtpEmail: (state, action: PayloadAction<any>) => {
      state.email = action.payload;
    },
    setOtpPassword: (state, action: PayloadAction<any>) => {
      state.password = action.payload;
    },
    setOtpUsername: (state, action: PayloadAction<any>) => {
      state.username = action.payload;
    },
    setOtpCompanyId: (state, action: PayloadAction<any>) => {
      state.companyId = action.payload;
    },
    setOtpMessage: (state, action: PayloadAction<any>) => {
      state.message = action.payload;
    },
    setOtpRequested: (state, action: PayloadAction<any>) => {
      state.requested = action.payload;
    },
    setStateEnd: (state, action: PayloadAction<any>) => {
      state.stateEnd = action.payload;
    },
    removeOtp: (state) => {
      state.url = "";
      state.transactionType = "";
      state.navigateTo = "";
      state.redirect = "";
      state.username = "";
      state.email = "";
      state.password = "";
      state.deviceId = "";
      state.companyId = "";
      state.message = "";
      state.navigateState = {};
      state.data = {};
      state.requested = false;
      state.stateEnd = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOtpUrl,
  setOtpData,
  setOtpTransactonType,
  setOtpNavigateState,
  setOtpNavigateTo,
  removeOtp,
  setOtpRedirect,
  setOtpDeviceId,
  setOtpEmail,
  setOtpPassword,
  setOtpUsername,
  setOtpCompanyId,
  setOtpMessage,
  setOtpRequested,
  setStateEnd,
} = configSlice.actions;

export default configSlice.reducer;
