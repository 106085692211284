import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ZakatTransactionWeb from "./zakatTransactionWeb";
import ZakatTransactionMobile from "./zakatTransactionMobile";

const ZakatTransaction = () => {
  return (
    <>
      <BrowserView>
        <ZakatTransactionWeb></ZakatTransactionWeb>
      </BrowserView>
      <MobileView>
        <ZakatTransactionMobile></ZakatTransactionMobile>
      </MobileView>
    </>
  );
};

export default ZakatTransaction;
