import { BrowserView, MobileView } from "react-device-detect";
import AbsenFeaturesWeb from "./absenFeaturesDetailWeb";
import AbsenFeaturesMobile from "./absenFeturesDetailMobile";

const AbsenFeaturesDetail = () => {
  return (
    <>
      <BrowserView>
        <AbsenFeaturesWeb />
      </BrowserView>
      <MobileView>
        <AbsenFeaturesMobile />
      </MobileView>
    </>
  );
};

export default AbsenFeaturesDetail;
