import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import GuideWeb from "./guideWeb";
import GuideMobile from "./guideMobile";

const GuideComponent = () => {
  return (
    <>
      <BrowserView>
        <GuideWeb></GuideWeb>
      </BrowserView>
      <MobileView>
        <GuideMobile></GuideMobile>
      </MobileView>
    </>
  );
};

export default GuideComponent;
