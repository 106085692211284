/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatCurrency } from "../../config/global";
import Header from "../_common/headerComponent";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";
import Token from "../../core/models/token";
import Reauthenticate from "../_common/reauthenticate";
import Company from "../../core/models/company";
import ConfirmationModal from "../_common/confirmationModal";
import { useTranslation } from "react-i18next";

const PPOBBPJSSummaryMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  let dispatch = useAppDispatch();
  let location: any = useLocation();
  const { t } = useTranslation();
  const [dataOrder, setDataOrder] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [inquiryId, setInquiryId] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const trxToken = async () => {
    setShowConfirmationModal(false);
    dispatch(setLoading(true));
    let data = {
      customerNumber: dataOrder.bpjsNumber,
    };
    await API_DIGIPROD.trxBPSJ(data, token)
      .then((res) => {
        dispatch(setLoading(false));
        setInquiryId(res.data.data.inquiryId);
        setTransactionId(res.data.data.transactionId);
        setShowModal(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    setDataOrder(location.state.data);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="BPJS"></Header>

      <Reauthenticate
        show={showModal}
        onHide={() => setShowModal(false)}
        transactionType="DIGITAL_PRODUCT"
        inquiryId={inquiryId}
        transactionId={transactionId}
        redirect={`/${company?.initial}/digital-product/bpjs/success`}
        width={40}
        height={43}
        margin="0 0.25rem"
      ></Reauthenticate>

      {/* <ConfirmationModal
        showModal={showConfirmationModal}
        hideModal={() => setShowConfirmationModal(false)}
        confirm={trxToken}
      /> */}

      <div className="flex lg:flex-row flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0">
        <div className="bg-white lg:w-1/2 md:w-2/3 w-full mx-auto p-4 mb-28">
          <h6 className="text-base md:text-lg font-medium text-green">
            {t('ppobHistory.summary')}
          </h6>
          <hr />
          <div className="space-between gap-3">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.transaction_date')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {moment().format("ll")}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.name')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {dataOrder?.customerName}
            </p>
          </div>
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.number_card_bpjs')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {dataOrder?.bpjsNumber}
            </p>
          </div>
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.service_type')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {dataOrder?.serviceName}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.total')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {formatCurrency(dataOrder?.totalPrice)}
            </p>
          </div>
          <hr />
          <div className="space-between gap-3 mt-[1rem]">
            <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
              {t('ppobHistory.category')}
            </p>
            <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
              {t('ppobHistory.bpjs')}
            </p>
          </div>
        </div>
        <div
          className="absolute bottom-0 right-0 w-full md:w-2/3 lg:w-1/2 left-1/2"
          style={{ transform: "translate(-50%, 0px)" }}
        >
          <div className="bg-white">
            <div className="px-6 py-7">
              <div className="flex justify-between items-center">
                <h5 className="md:text-xl text-lg font-medium mb-0">
                  {t('ppobHistory.totalPrice')}
                </h5>
                <h5 className="md:text-xl text-lg font-semibold text-green mb-0">
                  {formatCurrency(dataOrder.totalPrice)}
                </h5>
              </div>
              <button
                type="button"
                style={{
                  background: `${company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A"
                    }`,
                }}
                className="bg-green text-white font-medium rounded-[10px] w-full py-[10px] mt-3"
                onClick={trxToken}
              >
                {t('ppobHistory.confirmPayment')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PPOBBPJSSummaryMobile;
