/* eslint-disable react-hooks/exhaustive-deps */
import { find } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import User from "../../core/models/user";
import userDefault from "../../assets/images/userDefault.png";
import Navbar from "../_common/Navbar";
import bgHome from "../../assets/images/icon_home/texture-bg.svg";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import * as API_USER from "../../core/service/api_user";
import BgMobile from "../../assets/images/bgMobileMarketplace.png";
import { useTranslation } from "react-i18next";

interface StatsComponentProps {
  isLandingPage?: boolean;
  layout?: String;
  home?: String;
}

export const StatsHome = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [configColor, setConfigColor] = useState<any>({});
  const [isLogin] = useState(user && newToken);

  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const dispatch = useDispatch();
  const [bannerWeb, setBannerWeb] = useState<any>("");
  // const [bannerMobile, setBannerMobile] = useState<any>("");

  const getCompany = async () => {
    // dispatch(setLoading(true));
    await API_USER.getCompany(company.initial)
      .then((res) => {
        dispatch(setLoading(false));
        // setBannerMobile(res.data.data.bannerImage.mobile);
        setBannerWeb(res.data.data.bannerImage.web);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getConfigColor();
    getCompany();
  }, []);

  return (
    <section
      id="header"
      className={
        props.layout === "lms"
          ? `sm:w-full bg-repeat-x h-[300px] relative z-0`
          : `sm:w-full bg-repeat-x bg-[#039F79] h-[300px] relative z-0`
      }
      // style={{ backgroundImage: `url(${headerBackground})` }}
      style={
        bannerWeb === "" || bannerWeb === null
          ? {
            backgroundImage:
              props.layout === "lms"
                ? ``
                : `linear-gradient(to bottom, ${configColor?.color1}, ${configColor?.color2})`,
          }
          : {
            backgroundImage:
              props.layout === "lms" ? `` : `url(${bannerWeb})`,
          }
      }
    >
      <div className="container px-4">
        <div className="mx-auto w-4/5 pt-4">
          <div className="flex flex-row items-center justify-between mb-20">
            <div className="h-11 rounded-full">
              <Link to={`/${company?.initial}`}>
                <img
                  src={company?.companyLogo}
                  alt=""
                  className="h-11 rounded-full object-cover"
                />
              </Link>
            </div>
            <div className="flex mx-auto gap-4">
              <Navbar item="Home" />
            </div>
            {isLogin && (
              <Link to={isLogin ? `/${company?.initial}/profile` : "login"}>
                <img
                  src={
                    user?.profileImage ? `${user?.profileImage}` : userDefault
                  }
                  alt=""
                  className="w-11 h-11 rounded-full object-cover"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const StatsInformation = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [configColor, setConfigColor] = useState<any>({});
  const [isLogin] = useState(user && newToken);
  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const dispatch = useDispatch();
  const [bannerWeb, setBannerWeb] = useState<any>("");
  // const [bannerMobile, setBannerMobile] = useState<any>("");

  const getCompany = async () => {
    // dispatch(setLoading(true));
    await API_USER.getCompany(company.initial)
      .then((res) => {
        dispatch(setLoading(false));
        // setBannerMobile(res.data.data.bannerImage.mobile);
        setBannerWeb(res.data.data.bannerImage.web);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  // let imageNotFound =
  //   "https://closepay-static.sgp1.digitaloceanspaces.com/image/no-image.png";

  useEffect(() => {
    getConfigColor();
    getCompany();
  }, []);

  return (
    <section
      id="header"
      className={
        props.layout === "lms"
          ? `sm:w-full bg-repeat-x h-[300px] relative z-0`
          : `sm:w-full bg-repeat-x bg-[#039F79] h-[300px] relative z-0`
      }
      // style={{ backgroundImage: `url(${headerBackground})` }}
      style={
        bannerWeb === "" || bannerWeb === null
          ? {
            backgroundImage:
              props.layout === "lms"
                ? ``
                : `linear-gradient(to bottom, ${configColor?.color1}, ${configColor?.color2})`,
          }
          : {
            backgroundImage:
              props.layout === "lms" ? `` : `url(${bannerWeb})`,
          }
      }
    >
      <div className="container px-4">
        <div className="mx-auto w-4/5 pt-4">
          <div className="flex flex-row items-center justify-between mb-20">
            <div className="bg-white h-11 rounded-full">
              <Link to={`/${company?.initial}`}>
                <img
                  src={company?.companyLogo}
                  alt=""
                  className="h-11 rounded-full object-cover"
                />
              </Link>
            </div>
            <div className="flex mx-auto gap-4">
              <Navbar item="Informasi" />
            </div>
            {isLogin && (
              <Link to={`/${company?.initial}/profile`}>
                <img
                  src={
                    user?.profileImage ? `${user?.profileImage}` : userDefault
                  }
                  alt=""
                  className="w-11 h-11 rounded-full object-cover"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const StatsNotification = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [configColor, setConfigColor] = useState<any>({});
  const [isLogin] = useState(user && newToken);
  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const dispatch = useDispatch();
  const [bannerWeb, setBannerWeb] = useState<any>("");
  // const [bannerMobile, setBannerMobile] = useState<any>("");

  const getCompany = async () => {
    // dispatch(setLoading(true));
    await API_USER.getCompany(company.initial)
      .then((res) => {
        dispatch(setLoading(false));
        // setBannerMobile(res.data.data.bannerImage.mobile);
        setBannerWeb(res.data.data.bannerImage.web);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  // let imageNotFound =
  //   "https://closepay-static.sgp1.digitaloceanspaces.com/image/no-image.png";

  useEffect(() => {
    getConfigColor();
    getCompany();
  }, []);

  return (
    <section
      id="header"
      className={
        props.layout === "lms"
          ? `sm:w-full bg-repeat-x h-[300px] relative z-0`
          : `sm:w-full bg-repeat-x bg-[#039F79] h-[300px] relative z-0`
      }
      // style={{ backgroundImage: `url(${headerBackground})` }}
      style={
        bannerWeb === "" || bannerWeb === null
          ? {
            backgroundImage:
              props.layout === "lms"
                ? ``
                : `linear-gradient(to bottom, ${configColor?.color1}, ${configColor?.color2})`,
          }
          : {
            backgroundImage:
              props.layout === "lms" ? `` : `url(${bannerWeb})`,
          }
      }
    >
      <div className="container px-4">
        <div className="mx-auto w-4/5 pt-4">
          <div className="flex flex-row items-center justify-between mb-20">
            <div className="bg-white h-11 rounded-full">
              <Link to={`/${company?.initial}`}>
                <img
                  src={company?.companyLogo}
                  alt=""
                  className="h-11 rounded-full object-cover"
                />
              </Link>
            </div>
            <div className="flex mx-auto gap-4">
              <Navbar item="Pesan" />
            </div>
            {isLogin && (
              <Link to={`/${company?.initial}/profile`}>
                <img
                  src={
                    user?.profileImage ? `${user?.profileImage}` : userDefault
                  }
                  alt=""
                  className="w-11 h-11 rounded-full object-cover"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const StatsProfile = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [configColor, setConfigColor] = useState<any>({});
  const [isLogin] = useState(user && newToken);
  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const dispatch = useDispatch();
  const [bannerWeb, setBannerWeb] = useState<any>("");
  // const [bannerMobile, setBannerMobile] = useState<any>("");

  const getCompany = async () => {
    // dispatch(setLoading(true));
    await API_USER.getCompany(company.initial)
      .then((res) => {
        dispatch(setLoading(false));
        // setBannerMobile(res.data.data.bannerImage.mobile);
        setBannerWeb(res.data.data.bannerImage.web);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  // let imageNotFound =
  //   "https://closepay-static.sgp1.digitaloceanspaces.com/image/no-image.png";

  useEffect(() => {
    getConfigColor();
    getCompany();
  }, []);

  return (
    <section
      id="header"
      className={
        props.layout === "lms"
          ? `sm:w-full bg-repeat-x h-[300px] relative z-0`
          : `sm:w-full bg-repeat-x bg-[#039F79] h-[300px] relative z-0`
      }
      // style={{ backgroundImage: `url(${headerBackground})` }}
      style={
        bannerWeb === "" || bannerWeb === null
          ? {
            backgroundImage:
              props.layout === "lms"
                ? ``
                : `linear-gradient(to bottom, ${configColor?.color1}, ${configColor?.color2})`,
          }
          : {
            backgroundImage:
              props.layout === "lms" ? `` : `url(${bannerWeb})`,
          }
      }
    >
      <div className="container px-4">
        <div className="mx-auto w-4/5 pt-4">
          <div className="flex flex-row items-center justify-between mb-20">
            <div className="bg-white h-11 rounded-full">
              <Link to={`/${company?.initial}`}>
                <img
                  src={company?.companyLogo}
                  alt=""
                  className="h-11 rounded-full object-cover"
                />
              </Link>
            </div>
            <div className="flex mx-auto gap-4">
              <Navbar />
            </div>
            {isLogin && (
              <Link to={`/${company?.initial}/profile`}>
                <img
                  src={
                    user?.profileImage ? `${user?.profileImage}` : userDefault
                  }
                  alt=""
                  className="w-11 h-11 rounded-full object-cover"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const StatsMobile: FC<StatsComponentProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [configColor, setConfigColor] = useState<any>({});
  const [isLogin] = useState(user && newToken);
  const [isLandingPage, setIsLandingPage] = useState(true);
  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const dispatch = useDispatch();
  // const [bannerWeb, setBannerWeb] = useState<any>("");
  const [bannerMobile, setBannerMobile] = useState<any>("");

  const getCompany = async () => {
    // dispatch(setLoading(true));
    await API_USER.getCompany(company.initial)
      .then((res) => {
        dispatch(setLoading(false));
        setBannerMobile(res.data.data.bannerImage.mobile);
        // setBannerWeb(res.data.data.bannerImage.web);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getConfigColor();
    getCompany();
    if (props.isLandingPage === false) {
      setIsLandingPage(false);
    }
  }, []);

  return (
    <section
      id="header"
      style={
        bannerMobile === "" || bannerMobile === null
          ? {
            backgroundImage:
              props.home === "lms"
                ? ``
                : `linear-gradient(to bottom, ${configColor?.color1}, ${configColor?.color2})`,
          }
          : {
            backgroundImage:
              props.home === "lms" ? `` : `url(${bannerMobile})`,
          }
      }
      className={`${isLandingPage ? "h-full" : "h-[280px]"
        } sm:w-full bg-cover relative pb-14 z-0`}
    >
      <div className="absolute w-full">
        <img
          src={props.layout === "mobile" ? BgMobile : bgHome}
          className="object-contain w-full bg-cover"
          alt=""
        />
      </div>
      {isLandingPage && (
        <div className="container px-4 relative z-10">
          <div className="flex flex-row items-center justify-between pt-[20px]">
            <Link to={`/${company?.initial}`}>
              <img
                src={company?.companyLogo}
                alt=""
                className=" h-10 rounded-full"
              />
            </Link>
            {isLogin && (
              <Link to="profile">
                <img
                  src={user.profileImage ? `${user.profileImage}` : userDefault}
                  alt=""
                  className="w-10 h-10 rounded-full "
                />
              </Link>
            )}
          </div>
          <div className="mx-auto w-full mt-3">
            <h1
              className={
                props.home === "lms"
                  ? `m-0 font-medium text-xl text-black font-montserrat`
                  : `m-0 font-medium text-xl text-white font-montserrat`
              }
            >
              {t('home.greet')},
            </h1>
            <p
              className={
                props.home === "lms"
                  ? `mb-[20px] font-semibold text-2xl capitalize text-black font-montserrat`
                  : `mb-[20px] font-semibold text-2xl capitalize text-white font-montserrat`
              }
            >
              {isLogin ? user.name || user.email : "Selamat Datang"}
            </p>
          </div>
        </div>
      )}
    </section>
  );
};
