import Header from "../../_common/headerComponent";
import card from "../../../assets/images/fnb/anntrianCard.svg";
import card2 from "../../../assets/images/fnb/cardAntrian.svg";
import waiting from "../../../assets/images/fnb/waiting.svg";
import done from "../../../assets/images/fnb/done.svg";
import reject from "../../../assets/images/fnb/rejected.svg";
import { formatCurrency } from "../../../config/global";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper";

const PesananMobile = (props: any) => {
  return (
    <div className="min-h-screen w-full bg-mobile">
      <Header navbarText="Pesanan" />
      {props.data?.status === "CREATED" && (
        <>
          <div className="w-full bg-[#43A49B] py-3 -mt-4 text-xl text-center text-white font-semibold">
            <span>Sisa Antrian : {props.data?.queueLeft}</span>
          </div>
          <div
            style={{ backgroundImage: `url(${card})` }}
            className="my-16 bg-cover mx-auto w-[80%] relative"
          >
            {/* <img src={card} className="rounded-t-3xl flex mx-auto" alt="" /> */}
            <div className="flex flex-col py-10 text-center text-white">
              <span className="text-[12px] absolute right-6 top-4 font-semibold">
                {props.data?.customer.fnbType === "DINE_IN"
                  ? "Dine In"
                  : "Take Away"}
              </span>
              <span className="text-[16px]">Nomor Antrian</span>
              {/* <span>{data.}</span> */}
              <span className="font-bold text-2xl mb-3">
                {props.data?.queueNumber}
              </span>
              <span className="">{props.dataMerchant?.name}</span>
            </div>
          </div>
        </>
      )}
      {props.data?.status === "PROCESSED" && (
        <div className="w-full">
          <div className="w-full bg-[#43A49B] py-3 -mt-4 text-xl text-center text-white font-semibold">
            <span>Sisa Antrian : {props.data?.queueLeft}</span>
          </div>
          <div className="w-full text-center mt-20">
            <img src={waiting} alt="" className="flex mx-auto" />
            <div className="mt-4">
              <span className="text-secondary">
                Mohon Ditunggu <br /> Pesananmu Sedang Diproses
              </span>
            </div>
          </div>
        </div>
      )}
      {props.data?.status === "DONE" && (
        <div className="w-full">
          <div className="w-full text-center mt-20">
            <img src={done} alt="" className="flex mx-auto" />
            <div className="mt-4">
              <span className="text-secondary">
                Pesanan Sudah Siap, <br />{" "}
                {props.bayar === true
                  ? "Silahkan Lakukan Pembayaran"
                  : "Selamat Makan"}
              </span>
            </div>
          </div>
        </div>
      )}
      {props.data?.status === "CONFIRMED" && (
        <div className="w-full">
          <div className="w-full text-center mt-20">
            <img src={done} alt="" className="flex mx-auto" />
            <div className="mt-4">
              <span className="text-secondary">
                Pesanan Selesai <br /> Terima Kasih
              </span>
            </div>
          </div>
        </div>
      )}
      {props.data?.status === "REJECTED" && (
        <div className="w-full">
          <div className="w-full text-center mt-20">
            <img src={reject} alt="" className="flex mx-auto" />
            <div className="mt-4">
              <span className="text-secondary">
                Chefnya baru pergi, <br /> pesananmu ditolak
              </span>
            </div>
          </div>
        </div>
      )}
      {props.data?.status === "CANCELED" && (
        <div className="w-full">
          <div className="w-full text-center mt-20">
            <img src={reject} alt="" className="flex mx-auto" />
            <div className="mt-4">
              <span className="text-secondary">Pesanan Dibatalkan</span>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white w-full rounded-t-3xl mt-24 p-4">
        <div className="border-b pb-1 justify-between flex">
          <span className="tracking-widest mt-4 text-secondary text-[16px]">
            STATUS PESANAN
          </span>
          {props.data?.customer?.fnbType === "DINE_IN" && (
            <div className="text-white">
              <img src={card2} className="h-12 w-12 flex" alt="" />
              <div className=" text-center flex flex-col -mt-[45px]">
                <span className="text-[8px]">No Meja</span>
                <span className="font-semibold text-[16px]">
                  {props.data?.customer?.tableNumber}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="border-b text-center w-full py-5">
          <Swiper
            key={`swiper-${props.indexSlider}`}
            effect={"coverflow"}
            grabCursor={false}
            centeredSlides={true}
            slidesPerView={3}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              slideShadows: false,
            }}
            spaceBetween={110}
            modules={[EffectCoverflow]}
            className="w-full"
            initialSlide={props.indexSlider}
            allowSlideNext={false}
            allowSlidePrev={false}
            allowTouchMove={false}
          >
            {props.status?.map((res: any, i: any) => {
              return (
                <SwiperSlide
                  key={i}
                  className={
                    i === parseInt(props.indexSlider)
                      ? `opacity-100`
                      : `opacity-40`
                  }
                >
                  <img src={res.icon} alt="" className="w-10 h-10" />
                  <p
                    className={
                      res.key === "REJECTED"
                        ? "text-red text-[12px] text-center"
                        : res.key === "CANCELED"
                        ? "text-red text-[12px] text-center"
                        : "text-green text-[12px] text-center"
                    }
                  >
                    {res.name}
                  </p>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="w-full py-3">
          <div>
            <p className="text-black font-semibold">
              {props.dataMerchant?.name}
            </p>
            <div className="flex gap-4 text-secondary">
              <span>{formatCurrency(props.data?.totalPayment)}</span>
              <div className="flex gap-4">
                <li>{props.data?.productList.length} Menu</li>
                <li>
                  {props.data?.customer.fnbType === "DINE_IN"
                    ? "Dine In"
                    : "Take Away"}
                </li>
              </div>
            </div>
          </div>
          <button
            onClick={() => props.navigate("detail")}
            className="text-green font-semibold mx-auto flex mt-5"
          >
            Lihat detail
          </button>
          {props.data?.status === "CONFIRMED" && (
            <button
              onClick={() => {
                localStorage.removeItem("coId");
                localStorage.removeItem("orderId");
                localStorage.removeItem("summaryData");
                localStorage.removeItem("orderData");
                props.navigate(
                  `/${props.company.initial}/merchant_catalog_product/${props.param.merchantId}`
                );
              }}
              className="w-full py-2.5 text-white rounded-lg bg-green mt-3"
            >
              Pesan Lagi
            </button>
          )}
          {props.data?.status === "CANCELED" && (
            <button
              onClick={() => {
                localStorage.removeItem("coId");
                localStorage.removeItem("orderId");
                localStorage.removeItem("summaryData");
                localStorage.removeItem("orderData");
                props.navigate(
                  `/${props.company.initial}/merchant_catalog_product/${props.param.merchantId}`
                );
              }}
              className="w-full py-2.5 text-white rounded-lg bg-green mt-3"
            >
              Pesan Lagi
            </button>
          )}
          {props.data?.status === "REJECTED" && (
            <button
              onClick={() => {
                localStorage.removeItem("coId");
                localStorage.removeItem("orderId");
                localStorage.removeItem("summaryData");
                localStorage.removeItem("orderData");
                props.navigate(
                  `/${props.company.initial}/merchant_catalog_product/${props.param.merchantId}`
                );
              }}
              className="w-full py-2.5 text-white rounded-lg bg-green mt-3"
            >
              Pesan Lagi
            </button>
          )}
          {props.data?.status === "DONE" && props.bayar === true && (
            <button
              onClick={() => props.setChosePayment(true)}
              className="w-full py-2.5 text-white rounded-lg bg-green mt-3"
            >
              Bayar Sekarang
            </button>
          )}
          {props.bayar === false && props.data?.status === "DONE" && (
            <button
              onClick={() => props.confirmOrder()}
              className="w-full py-2.5 text-white rounded-lg bg-green mt-3"
            >
              Terima Pesanan
            </button>
          )}
          {props.data?.status === "CREATED" && (
            <button
              onClick={() => props.batalPesanan()}
              className="w-full py-2.5 text-white rounded-lg bg-green mt-3"
            >
              Batalkan Pesanan
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PesananMobile;
