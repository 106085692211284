/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import RentResultMobile from "./rentResultMobile";
import RentResultWeb from "./rentResultWeb";

const RentResult = () => {
  React.useEffect(() => {}, []);

  return (
    <>
      <BrowserView>
        <RentResultWeb />
      </BrowserView>
      <MobileView>
        <RentResultMobile/>
      </MobileView>

      {/* Modal */}

      {/* --- */}
    </>
  );
};

export default RentResult;
