import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SelectQueueWeb from "./selectQueueWeb";
import SelectQueueMobile from "./selectQueueMobile";

const SelectQueue = () => {
  return (
    <>
      <BrowserView>
        <SelectQueueWeb></SelectQueueWeb>
      </BrowserView>
      <MobileView>
        <SelectQueueMobile></SelectQueueMobile>
      </MobileView>
    </>
  );
};

export default SelectQueue;
