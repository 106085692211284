import React, { useState } from "react";
import iconSuccess from "../../assets/images/iconSuccess.png";
import Header from "../_common/headerComponent";
import OtpInput from "react-otp-input";
import Company from "../../core/models/company";
import * as API_USER from "../../core/service/api_user";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import { ConfigColor } from "../../config/global";
import { useTranslation } from "react-i18next";

const ChangePasswordMobile = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [oldSecurityCode, setOldSecurityCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [newSecurityCode, setNewSecurityCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [confirmNewSecurityCode, setConfirmNewSecurityCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [errorOldSecurityCode, setErrorOldSecurityCode] = useState("");
  const [errorNewSecurityCode, setErrorNewSecurityCode] = useState("");
  const [errorConfirmNewSecurityCode, setErrorConfirmNewSecurityCode] =
    useState("");
  const [updateSuccessFully, setUpdateSuccessFully] = useState(false);
  const color = ConfigColor();
  const [max, setMax] = React.useState<any>();
  const [max2, setMax2] = React.useState<any>();
  const [max3, setMax3] = React.useState<any>();

  const handleOnClick = async () => {
    if (oldSecurityCode.join("") === "" || oldSecurityCode.join("").length !== 6) {
      setErrorOldSecurityCode(t("changePin.oldPinRequired"));
    } else if (newSecurityCode.join("") === "" || newSecurityCode.join("").length !== 6) {
      setErrorNewSecurityCode(t("changePin.newPinRequired"));
    } else if (confirmNewSecurityCode.join("") === "" || confirmNewSecurityCode.join("").length !== 6) {
      setErrorConfirmNewSecurityCode(t("changePin.confirmNewPinRequired"));
    } else if (newSecurityCode.join("") !== confirmNewSecurityCode.join("")) {
      setErrorConfirmNewSecurityCode(t("changePin.confirmPinMismatch"));
    } else {
      dispatch(setLoading(true));
      let data = {
        oldSecurityCode: oldSecurityCode.join(""),
        newSecurityCode: newSecurityCode.join(""),
      };

      await API_USER.changeSecurityCode(data)
        .then((res) => {
          dispatch(setLoading(false));
          setUpdateSuccessFully(true);
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleOnClick();
  };

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Ganti Pin"></Header>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] pb-4 px-4">
        <div
          className={`${updateSuccessFully ? "hidden" : "flex flex-col h-[85vh] items-start"
            }`}
        >
          <div className="w-100 md:text-center text-left mb-3">
            <h1 className="font-bold md:text-3xl text-base font-montserrat">
              {t("changePin.title")}
            </h1>
            <h5 className=" font-montserrat font-normal md:text-lg text-sm">
              {t("changePin.description")}
            </h5>
          </div>
          <div className="flex-grow flex flex-col w-full text-left mb-4">
            <form onSubmit={handleSubmit} className="flex flex-col gap-1 my-3">
              <h1 className="md:text-lg text-base leading-5 font-normal mb-1">
                {t("changePin.oldPin")}
              </h1>
              <div className="flex flex-row gap-3 relative z-10 mx-auto">
                {oldSecurityCode.map((res, i) => {
                  return (
                    <div
                      className={
                        oldSecurityCode[i] === "undefined" ||
                          oldSecurityCode[i] === ""
                          ? "border outline-none text-center rounded-xl w-[40px] h-[40px]"
                          : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-xl w-[40px] h-[40px]"
                      }
                      key={i}
                    ></div>
                  );
                })}
              </div>
              <div className="w-full -mt-12 relative z-20">
                <input
                  style={{
                    letterSpacing: "49px",
                    caretColor: max?.length === 6 ? "transparent" : "#000",
                  }}
                  className="w-full pl-9 bg-transparent border-none text-transparent focus:outline-none py-2 mt-1 text-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  value={max}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      setMax(max);
                    } else {
                      const value: any = e.target.value.split("");
                      const newValue: any = oldSecurityCode;
                      const x: any = [
                        `${(newValue[0] = value[0])}`,
                        `${(newValue[1] = value[1])}`,
                        `${(newValue[2] = value[2])}`,
                        `${(newValue[3] = value[3])}`,
                        `${(newValue[4] = value[4])}`,
                        `${(newValue[5] = value[5])}`,
                      ];
                      setOldSecurityCode(x);
                      setMax(e.target.value);
                    }
                  }}
                ></input>
              </div>
              {/* <OtpInput
                value={oldSecurityCode}
                onChange={(code: React.SetStateAction<string>) => {
                  setOldSecurityCode(code);
                  setErrorOldSecurityCode("");
                }}
                numInputs={6}
                separator={<span>&nbsp;</span>}
                isInputNum
                isInputSecure
                inputStyle={{
                  width: 80,
                  height: 83,
                  margin: "0 0.25rem",
                  fontSize: "2rem",
                  borderRadius: 12,
                  border: "1px solid #70717d4d",
                }}
              /> */}
              <p className="text-base text-red pl-1 mb-0">
                {errorOldSecurityCode}
              </p>
            </form>
            <form onSubmit={handleSubmit} className="flex flex-col gap-1 my-3">
              <h1 className="md:text-lg text-base leading-5 font-normal mb-1">
                {t("changePin.newPin")}
              </h1>
              <div className="flex flex-row gap-3 relative z-10 mx-auto">
                {newSecurityCode.map((res, i) => {
                  return (
                    <div
                      className={
                        newSecurityCode[i] === "undefined" ||
                          newSecurityCode[i] === ""
                          ? "border outline-none text-center rounded-xl w-[40px] h-[40px]"
                          : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-xl w-[40px] h-[40px]"
                      }
                      key={i}
                    ></div>
                  );
                })}
              </div>
              <div className="w-full -mt-12 relative z-20">
                <input
                  style={{
                    letterSpacing: "49px",
                    caretColor: max2?.length === 6 ? "transparent" : "#000",
                  }}
                  className="w-full pl-9 bg-transparent border-none text-transparent focus:outline-none py-2 mt-1 text-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  value={max2}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      setMax2(max2);
                    } else {
                      const value: any = e.target.value.split("");
                      const newValue: any = newSecurityCode;
                      const x: any = [
                        `${(newValue[0] = value[0])}`,
                        `${(newValue[1] = value[1])}`,
                        `${(newValue[2] = value[2])}`,
                        `${(newValue[3] = value[3])}`,
                        `${(newValue[4] = value[4])}`,
                        `${(newValue[5] = value[5])}`,
                      ];
                      setNewSecurityCode(x);
                      setMax2(e.target.value);
                    }
                  }}
                ></input>
              </div>
              {/* <OtpInput
                value={newSecurityCode}
                onChange={(code: React.SetStateAction<string>) => {
                  setNewSecurityCode(code);
                  setErrorNewSecurityCode("");
                }}
                numInputs={6}
                separator={<span>&nbsp;</span>}
                isInputNum
                isInputSecure
                inputStyle={{
                  width: 80,
                  height: 83,
                  margin: "0 0.25rem",
                  fontSize: "2rem",
                  borderRadius: 12,
                  border: "1px solid #70717d4d",
                }}
              /> */}
              <p className="text-base text-red pl-1 mb-0">
                {errorNewSecurityCode}
              </p>
            </form>
            <form onSubmit={handleSubmit} className="flex flex-col gap-1 my-3">
              <h1 className="md:text-lg text-base leading-5 font-normal mb-1">
                {t("changePin.confirmNewPin")}
              </h1>
              <div className="flex flex-row gap-3 relative z-10 mx-auto">
                {confirmNewSecurityCode.map((res, i) => {
                  return (
                    <div
                      className={
                        confirmNewSecurityCode[i] === "undefined" ||
                          confirmNewSecurityCode[i] === ""
                          ? "border outline-none text-center rounded-xl w-[40px] h-[40px]"
                          : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-xl w-[40px] h-[40px]"
                      }
                      key={i}
                    ></div>
                  );
                })}
              </div>
              <div className="w-full -mt-12 relative z-20">
                <input
                  style={{
                    letterSpacing: "49px",
                    caretColor: max3?.length === 6 ? "transparent" : "#000",
                  }}
                  className="w-full pl-9 bg-transparent border-none text-transparent focus:outline-none py-2 mt-1 text-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  value={max3}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      setMax3(max3);
                    } else {
                      const value: any = e.target.value.split("");
                      const newValue: any = confirmNewSecurityCode;
                      const x: any = [
                        `${(newValue[0] = value[0])}`,
                        `${(newValue[1] = value[1])}`,
                        `${(newValue[2] = value[2])}`,
                        `${(newValue[3] = value[3])}`,
                        `${(newValue[4] = value[4])}`,
                        `${(newValue[5] = value[5])}`,
                      ];
                      setConfirmNewSecurityCode(x);
                      setMax3(e.target.value);
                    }
                  }}
                ></input>
              </div>
              {/* <OtpInput
                value={confirmNewSecurityCode}
                onChange={(code: React.SetStateAction<string>) => {
                  setConfirmNewSecurityCode(code);
                  setErrorConfirmNewSecurityCode("");
                }}
                numInputs={6}
                separator={<span>&nbsp;</span>}
                isInputNum
                isInputSecure
                inputStyle={{
                  width: 80,
                  height: 83,
                  margin: "0 0.25rem",
                  fontSize: "2rem",
                  borderRadius: 12,
                  border: "1px solid #70717d4d",
                }}
              /> */}
              <p className="text-base text-red pl-1 mb-0">
                {errorConfirmNewSecurityCode}
              </p>
            </form>
          </div>
          <div className="md:w-3/4 lg:w-1/2 w-full mx-auto">
            <button
              type="button"
              onClick={() => handleOnClick()}
              style={{
                backgroundColor: `${color?.color1}`,
              }}
              className="w-full md:p-[1rem] p-[12px] rounded-lg text-white font-semibold md:text-lg text-base "
            >
              {t("changePin.confirm")}
            </button>
          </div>
        </div>
        <div
          className={`${updateSuccessFully
            ? "flex flex-col h-[85vh] items-start justify-center"
            : "hidden"
            }`}
        >
          <div className="w-full relative items-center mt-4 mb-3">
            <img src={iconSuccess} alt="user" className="mx-auto" />
          </div>
          <div className="flex-grow w-100 text-center mb-3">
            <h1 className="font-bold md:text-2xl text-base font-montserrat">
              {t("changePin.pinChangedSuccess")}
            </h1>
          </div>
          <div className="w-full mt-2 text-center">
            <button
              type="button"
              style={{
                backgroundColor: `${color?.color1}`,
              }}
              className="w-full md:p-[1rem] p-[12px] font-semibold rounded-lg text-white text-base "
              onClick={() =>
                navigate(`/${company?.initial}/profile`, { replace: true })
              }
            >
              {t("changePin.done")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePasswordMobile;
