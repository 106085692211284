import { useNavigate } from "react-router-dom";
import ArrowLeft from "../../assets/images/arrow_right_2.svg";
import NoImage from "../../assets/images/empty.jpg";
import merchantDefault from "../../assets/images/new_merchant_logo.png";
import { formatCurrency } from "../../config/global";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import DataNotFound from "../_common/dataNotFound";
import Header from "../_common/headerComponent";
import LoadMoreButton from "../_common/loadMoreButton";

const MerchantDetailMobile = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const imageOnError = (event: any) => {
    event.currentTarget.src = NoImage;
    event.currentTarget.className = `${event.currentTarget.className} error`;
  };

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={"Detail Merchant"} />

      <div className="container flex flex-col datas-center md:mt-[64px] mt-[0px] px-0">
        <div className="w-full mx-auto">
          <div className="mb-5">
            <div className="bg-white shadow-sm px-3 pt-4 mb-3 pb-4">
              <div className="flex items-center gap-3">
                <img
                  src={
                    props.data.profileImage
                      ? props.data.profileImage
                      : merchantDefault
                  }
                  alt=""
                  className="object-cover h-[75px] w-[75px] select-none rounded-lg"
                />
                <div className="flex flex-col justify-between items-start gap-4 w-full">
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => props.setShowDescription(true)}
                  >
                    <h6 className="md:text-lg text-base font-semibold mb-0">
                      {props.data?.name}
                    </h6>
                    <img src={ArrowLeft} alt="" />
                  </div>
                  <p className="md:text-sm text-xs text-customBlack text-center font-light mb-0">
                    {props.data?.location
                      ? props.data?.location.city.name
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm px-3 py-[12px] mb-3">
              <h6 className="md:text-lg text-base text-customBlack font-medium mb-0">
                Rekomendasi
              </h6>
              {props.products.length > 0 ? (
                <div className="grid lg:grid-cols-4 grid-cols-2 gap-3 mt-2">
                  {props.products.map((el: any) => (
                    <div key={el.id}>
                      <div
                        className="flex flex-col h-full cursor-pointer"
                        onClick={() =>
                          navigate(`/${company.initial}/trip-tour/${el.id}`)
                        }
                      >
                        <img
                          src={
                            el?.imageUrl[0] === "" || el?.imageUrl === null
                              ? NoImage
                              : el?.imageUrl[0]
                          }
                          alt=""
                          onError={imageOnError}
                          className="object-center select-none overflow-hidden rounded-t-lg"
                          style={{ width: "100%", height: 140 }}
                        />
                        <div className="mt-2 flex flex-col justify-between flex-grow">
                          <span className="text-[#263339] font-[500]">
                            {el.productName}
                          </span>
                          <div className="flex flex-wrap gap-2  mt-2 mb-0">
                            <div className="text-[#408D86] font-[700]">
                              {formatCurrency(el.price)}
                            </div>
                            {/* <div className="text-[#728F9E] text-xs line-through bottom-[1rem]">
                            {formatCurrency("15000")}
                          </div> */}
                          </div>
                          {/* <div className="rounded-full py-1 px-2 bg-white absolute right-2 top-2 text-xs text-[#FF9723] flex items-center gap-1 font-[500]">
                          <FontAwesomeIcon
                            icon={faStar}
                            className="text-[#FF9723] text-xs"
                          />
                          <span>4.8</span>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <DataNotFound
                  title={
                    props.data?.name +
                    " ini belum memiliki produk"
                  }
                />
              )}
            </div>

            {props.currentSize() >= 10 && props.totalElements > 10 ? (
              <LoadMoreButton addMore={props.addMore} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MerchantDetailMobile;
