// import React, { Component, useEffect, useState } from "react";
import FooterProfileMobile from "../footerProfileMobile/footerComponent";

const ProfileMobile = () => {
  // const dataPassword = {
  //   passwordLama:"12345678",
  //   passwordBaru:"12345678",
  //   konfirmasiPasswordBaru:"12345678",
  // }
  return (
    <>
      <div className="relative h-auto">
        {/* <section>
          <div className="flex flex-row bg-white p-3 items-center">
            <img src={back} className="w-6 mr-3" />
            <h1 className="p-0 m-0 font-bold text-sm text-black">Profile</h1>
          </div>
        </section> */}
        <section className="px-6 pt-6 pb-2 h-screen">
          <div className="w-5/6 text-left mb-3">
            <h1 className=" font-poppins font-bold text-xl">Automatic Login</h1>
          </div>

          <div>
            <div className="form-check my-3  pl-3 py-2  h-10 bg-white rounded-lg">
              <input
                className="w-3 h-3 mr-2 rounded-full border border-gray-300 mt-2 align-top bg-no-repeat bg-center"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked
              />
              <label className="form-check-label inline-block text-base text-gray-800 mt-0">
                Aktif
              </label>
            </div>
            <div className="form-check my-3 pl-3 py-2 h-10 bg-white rounded-lg">
              <input
                className="w-3 h-3 mr-2 rounded-full border border-gray-300 mt-2 align-top bg-no-repeat bg-center"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
              />
              <label className="form-check-label inline-block  text-base text-gray-800 mt-0">
                Tidak Aktif
              </label>
            </div>
          </div>

          <div className="w-5/6 text-left mt-4 mb-3">
            <h1 className=" font-poppins font-bold text-xl">Ganti Bahasa</h1>
          </div>

          <div>
            <div className="form-check my-3  pl-3 py-2  h-10 bg-white rounded-lg">
              <input
                className="w-3 h-3 mr-2 rounded-full border border-gray-300 mt-2 align-top bg-no-repeat bg-center"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked
              />
              <label className="form-check-label inline-block text-base text-gray-800 mt-0">
                Inggris
              </label>
            </div>
            <div className="form-check my-3 pl-3 py-2 h-10 bg-white rounded-lg">
              <input
                className="w-3 h-3 mr-2 rounded-full border border-gray-300 mt-2 align-top bg-no-repeat bg-center"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
              />
              <label className="form-check-label inline-block  text-base text-gray-800 mt-0">
                Indonesia
              </label>
            </div>
          </div>
        </section>

        <FooterProfileMobile></FooterProfileMobile>
      </div>
    </>
  );
};

export default ProfileMobile;
