import React, { useState } from "react";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import { useAppSelector } from "../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency, onlyNumber } from "../../config/global";
import Combo from "../../config/Combo";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import {
  AxisProvider,
  IndoProvider,
  SmartProvider,
  TelkomselProvider,
  ThreeProvider,
  XlProvider,
} from "../../assets/images/svg/icon";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useTranslation } from "react-i18next";

const PPOBPrabayarWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [pulsaList, setPulsaList] = useState<any[]>([]);
  const [dataList, setDataList] = useState<any[]>([]);
  const [selectedPulsa, setSelectedPulsa] = useState<any>({});
  const [selectedData, setSelectedData] = useState<any>({});
  const [phone, setPhone] = useState("");
  const [brand, setBrand] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [category, setCategory] = useState("Pulsa");
  const [isSelected, setIsSelected] = useState(false);
  const [open, setOpen] = useState(false);

  const listIcon = {
    XlProvider: <XlProvider />,
    SmartProvider: <SmartProvider />,
    AxisProvider: <AxisProvider />,
    IndoProvider: <IndoProvider />,
    ThreeProvider: <ThreeProvider />,
    TelkomselProvider: <TelkomselProvider />,
  };

  const getListPrepaid = (brand: any, category: any) => {
    API_DIGIPROD.listPrepaid({ brand, category }).then((res) => {
      if (category === "Pulsa") {
        setPulsaList(res.data.data);
      }
      if (category === "Data") {
        setDataList(res.data.data);
      }
    });
  };

  const deteksiOperatorSeluler = (evt: any) => {
    setErrorPhone("");
    let data = evt.target.value;
    setPhone(data);
    setIsSelected(false);
    setSelectedPulsa({});
    setSelectedData({});
    setErrorPhone("");
    setBrand("");
    setPulsaList([]);
    setDataList([]);
    let code = "";
    let prefix = data.slice(0, 4);
    if (prefix.length >= 4) {
      if (Combo.categoryPulse.axis.includes(prefix)) {
        code = "XL";
        getListPrepaid(code, category);
        setBrand(code);
      }
      if (Combo.categoryPulse.three.includes(prefix)) {
        code = "TRI";
        getListPrepaid(code, category);
        setBrand(code);
      }
      if (Combo.categoryPulse.xl.includes(prefix)) {
        code = "XL";
        getListPrepaid(code, category);
        setBrand(code);
      }
      if (Combo.categoryPulse.indosat.includes(prefix)) {
        code = "INDOSAT";
        getListPrepaid(code, category);
        setBrand(code);
      }
      if (Combo.categoryPulse.telkomsel.includes(prefix)) {
        code = "TELKOMSEL";
        getListPrepaid(code, category);
        setBrand(code);
      }
      if (Combo.categoryPulse.smartfren.includes(prefix)) {
        code = "SMART";
        getListPrepaid(code, category);
        setBrand(code);
      }
    }
  };

  const changeCategory = (key: string) => {
    if (key === "Pulsa") setSelectedPulsa({});
    if (key === "Data") setSelectedData({});
    setCategory(key);
    setIsSelected(false);
    setErrorPhone("");
    if (phone.length >= 4) getListPrepaid(brand, key);
  };

  const clearData = () => {
    setPhone("");
    setIsSelected(false);
    setSelectedPulsa({});
    setSelectedData({});
    setErrorPhone("");
    setBrand("");
    setPulsaList([]);
    setDataList([]);
  };

  const handleOnClick = () => {
    let dataOrder: any = {
      serviceCode: "",
      customerNumber: phone,
    };
    let data: any = {};
    if (phone === "") {
      setErrorPhone(t('topUp.insertNumber'));
    } else if (phone.length < 10) {
      setErrorPhone(t('topUp.insertShortNumber'));
    } else if (pulsaList.length === 0 && dataList.length === 0) {
      setErrorPhone(t('topUp.insertNumberValid'));
    } else if (category === "Pulsa" && !isSelected) {
      setErrorPhone(t('topUp.selectNominal'));
    } else if (category === "Data" && !isSelected) {
      setErrorPhone(t('topUp.selectData'));
    } else {
      if (category === "Pulsa") {
        dataOrder["serviceCode"] = selectedPulsa.serviceCode;
        data = selectedPulsa;
      }
      if (category === "Data") {
        dataOrder["serviceCode"] = selectedData.serviceCode;
        data = selectedData;
      }
      navigate(
        `/${company?.initial}/digital-product/pulsa-data/prabayar/summary`,
        {
          state: {
            data,
            dataOrder,
          },
        }
      );
    }
  };

  return (
    <>
      <section className="h-full min-h-screen font-montserrat bg-mobile">
        <Header></Header>
        <div className="flex md:flex-row flex-col-reverse gap-5 justify-center md:mt-[64px] mt-[0px]">
          <div className="lg:w-1/2 md:w-3/4 w-full px-4 mb-36">
            <div className="flex items-center gap-3 rounded-xl md:mb-10 mb-3">
              <div className="text-start w-full">
                <h3 className="md:text-lg text-base font-medium text-[#252525] font-montserrat mb-[8px]">
                  {t('topUp.number')}
                </h3>
                <div className="relative">
                  <input
                    type="number"
                    placeholder={t('topUp.insertNumber')}
                    onKeyPress={onlyNumber}
                    onChange={deteksiOperatorSeluler}
                    value={phone}
                    className="bg-white w-full p-2.5 md:text-base text-sm rounded-xl focus:outline border border-[#848383]"
                  />
                  <div className="absolute top-[10px] right-12 h-3">
                    <span className="h-3">
                      {brand === "SMART" ? (
                        listIcon[`SmartProvider`]
                      ) : brand === "XL" ? (
                        listIcon[`XlProvider`]
                      ) : brand === "TELKOMSEL" ? (
                        listIcon[`TelkomselProvider`]
                      ) : brand === "TRI" ? (
                        listIcon[`ThreeProvider`]
                      ) : brand === "INDOSAT" ? (
                        listIcon[`IndoProvider`]
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                  <div className="absolute top-[14px] right-4">
                    <button
                      type="button"
                      className="bg-gray-500 flex items-center justify-center rounded-[50%] px-[5px] w-[17px] h-[17px] text-white"
                      onClick={() => clearData()}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                </div>
                <p className="text-sm text-red pl-1 mb-0">{errorPhone}</p>
                {category === "Pulsa" ? (
                  pulsaList.length === 0 && (
                    <p className="text-sm text-newColor pl-1 mb-0"><span className="text-red">*</span>{t('topUp.requiredNumber')}</p>
                  )
                ) : (
                  dataList.length === 0 && (
                    <p className="text-sm text-newColor pl-1 mb-0"><span className="text-red">*</span>{t('topUp.requiredNumber')}</p>
                  )
                )}
              </div>
            </div>
            <div
              style={{ background: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
              className="bg-green rounded-xl p-2 md:mb-10 mb-7"
            >
              <div className="flex flex-col items-center justify-center">
                <ul className="flex w-full p-0 h-fit m-0">
                  <li className="w-full">
                    <button
                      onClick={() => changeCategory("Pulsa")}
                      style={{ color: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                      className={` ${category === "Pulsa"
                        ? "bg-white shadow-inner shadow-[#e2e2e2] text-green font-semibold"
                        : " tranparent text-white font-medium"
                        } md:text-lg text-sm inline-block px-4 py-2 rounded-lg w-full font-montserrat`}
                    >
                      {t('topUp.pulse')}
                    </button>
                  </li>
                  <li className="w-full">
                    <button
                      onClick={() => changeCategory("Data")}
                      style={{ color: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                      className={` ${category === "Data"
                        ? "bg-white  shadow-inner shadow-[#e2e2e2] text-green font-semibold"
                        : " tranparent text-white font-medium"
                        } md:text-lg text-sm inline-block px-4 py-2 rounded-lg w-full font-montserrat`}
                    >
                      {t('topUp.dataPackage')}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full mt-3">
              <div
                className={
                  category === "Pulsa" ? "grid md:grid-cols-3 grid-cols-2 gap-3" : "hidden"
                }
              >
                {pulsaList.map((item, index) => (
                  <div
                    className={`${selectedPulsa.serviceCode === item.serviceCode
                      ? "bg-[#26a69a19] border-[0.5px] border-green"
                      : "bg-[#fff] border-[#9A9999]"
                      } flex flex-col justify-center border-[0.5px] cursor-pointer rounded-xl p-[14px]`}
                    key={index}
                    onClick={() => {
                      setSelectedPulsa(item);
                      setErrorPhone("");
                      setIsSelected(true);
                    }}
                  >
                    <h5 className="md:text-base text-sm font-semibold font-montserrat m-0 mb-0 capitalize">
                      {item.product_name}
                    </h5>
                    <span className="md:text-sm text-xs font-montserrat text-[#9A9999]">
                      {item.desc}
                    </span>

                    <h5
                      style={{ color: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
                      className="text-green md:text-base text-sm font-medium mb-0 font-montserrat"
                    >
                      {formatCurrency(item.totalPrice)}
                    </h5>
                  </div>
                ))}
              </div>
              <div
                className={
                  category === "Data" ? "grid grid-cols-2 gap-3" : "hidden"
                }
              >
                {dataList.map((item, index) => (
                  <div
                    className={`${selectedData.serviceCode === item.serviceCode
                      ? "bg-[#26a69a19] border-[0.5px] border-green"
                      : "bg-[#fff] border-[#9A9999]"
                      } flex flex-col justify-center border-[0.5px] cursor-pointer rounded-xl p-[14px]`}
                    key={index}
                    onClick={() => {
                      setSelectedData(item);
                      setErrorPhone("");
                      setIsSelected(true);
                      setOpen(true);
                    }}
                  >
                    <h5 className="md:text-base text-sm font-semibold font-montserrat m-0 mb-0 capitalize">
                      {item.product_name}
                    </h5>
                    <span className="md:text-sm text-xs font-montserrat text-[#9A9999]">
                      {item.desc}
                    </span>

                    <h5
                      style={{ color: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
                      className="text-green md:text-base text-sm font-medium mb-0 font-montserrat"
                    >
                      {formatCurrency(item.totalPrice)}
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {category === "Pulsa" && (
            <div className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-3/4 w-full px-4" style={{ transform: "translate(-50%, -50%)" }}>
              <button
                type="button"
                style={{ background: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                className="bg-green text-white text-base font-medium rounded-[10px] w-full py-[10px] mt-3"
                onClick={() => handleOnClick()}
              >
               {t('topUp.next')} 
              </button>
            </div>
          )}
        </div>

        {/* MODAL */}
        <BottomSheet open={open} onDismiss={() => setOpen(false)}>
          <div className="mx-4 mt-1 mb-4 font-montserrat">
            <h6 className="md:text-lg text-base text-customBlack font-bold mb-0">{t('topUp.packageDetails')}</h6>
            <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">{selectedData?.product_name}</h6>
            <p className="md:text-sm text-xs text-newColor font-light mb-0">{selectedData?.desc}</p>
            <p className="md:text-sm text-xs text-customBlack font-medium mb-0">{t('topUp.amount')}</p>
            <p
              style={{ color: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
              className="md:text-lg text-base text-green font-semibold mb-0"
            >
              {formatCurrency(selectedData?.totalPrice)}
            </p>
            <button
              type="button"
              style={{ background: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
              className="bg-green text-white text-base font-medium rounded-[10px] w-full py-[10px] mt-3"
              onClick={() => handleOnClick()}
            >
              {t('topUp.pay')}
            </button>
          </div>
        </BottomSheet>
      </section>
    </>
  );
};

export default PPOBPrabayarWeb;
