import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InputDataWeb from "./inputDataWeb";
import InputDataMobile from "./inputDataMobile";

const InputData = () => {
  return (
    <>
      <BrowserView>
        <InputDataWeb></InputDataWeb>
      </BrowserView>
      <MobileView>
        <InputDataMobile></InputDataMobile>
      </MobileView>
    </>
  );
};

export default InputData;
