/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SuccessIcon from "../../assets/images/security_success.png";
import { backTo, formatCurrency } from "../../config/global";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { setLoading } from "../../core/feature/config/configSlice";
import { useTranslation } from "react-i18next";

const PPOBPrabayarSuccessMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let location: any = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});

  const getTransactionDetail = async (id: any) => {
    dispatch(setLoading(true));
    API_DIGIPROD.getTransactionById(id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    getTransactionDetail(location.state.id);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={t('topUp.prepaid')}></Header>
      <div className="flex flex-col justify-center gap-6 md:w-2/3 w-full md:mt-[64px] mt-[0px] md:px-4 mx-auto">
        <div className="w-full p-4 mb-40 h-full md:rounded-xl bg-white">
          <div className="pb-4">
            <div className="flex flex-col items-center mt-2">
              <img
                src={SuccessIcon}
                alt=""
                className="md:h-[220px] h-[120px] md:w-[220px] w-[120px]"
              />
              <h1 className="md:text-3xl text-xl text-green font-bold mt-3">
                {t('ppobHistory.success')}
              </h1>
            </div>
            <div className="space-between gap-3 mt-4">
              <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                {t('ppobHistory.transaction_date')}
              </p>
              <p className="text-sm md:text-base text-newColor font-light mb-0">
                {moment().format("ll")}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                {t('ppobHistory.transaction_number')}
              </p>
              <p className="text-sm md:text-base text-newColor font-light mb-0">
                {data?.transactionId}
              </p>
            </div>
            <hr className="border-dotted  border-[#9A9999] my-4 border" />
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                {t('ppobHistory.phone_number')}
              </p>
              <p className="text-sm md:text-base text-newColor font-light mb-0">
                {data?.customerNumber}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                {t('ppobHistory.service_type')}
              </p>
              <p className="text-sm md:text-base text-newColor font-light mb-0 text-end">
                {data?.productName}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                {t('ppobHistory.category')}
              </p>
              <p className="text-sm md:text-base text-customBlack font-light mb-0">
                {t('ppobHistory.pulse_and_data')}
              </p>
            </div>
            <hr className="border-dotted  border-[#9A9999] my-3 border" />
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                {t('ppobHistory.total')}
              </p>
              <p className="text-sm md:text-base text-newColor font-light mb-0">
                {formatCurrency(data?.totalPrice)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-2/3 w-full absolute bottom-0 right-0 left-1/2 px-4" style={{ transform: "translate(-50%, -50%)" }}>
        <button
          type="button"
          style={{
            background: `${company?.color?.color1 !== undefined
              ? company?.color?.color1
              : "#26A69A"
              }`,
          }}
          className="bg-green text-white font-medium text-base rounded-lg w-full md:py-[1rem] py-[10px] mt-3"
          onClick={() => {
            backTo(`/${company?.initial}`);
          }}
        >
          {t('ppobHistory.backToHome')}
        </button>
      </div>
    </section>
  );
};

export default PPOBPrabayarSuccessMobile;
