import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import HistoryWeb from "./historyWeb";
import HistoryMobile from "./historyMobile";

const History = () => {
  return (
    <>
      <BrowserView>
        <HistoryWeb></HistoryWeb>
      </BrowserView>
      <MobileView>
        <HistoryMobile></HistoryMobile>
      </MobileView>
    </>
  );
};

export default History;
