import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { isSupported } from "firebase/messaging";
import toast from "react-hot-toast";
import { encryptedValue } from "./config/Encription";
// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
var firebaseConfig = {
  apiKey: "AIzaSyDVZyJAE3jLfH5bVSKsIxgHoXJwkU2PiyQ",
  authDomain: "solusinegeri-merchant.firebaseapp.com",
  projectId: "solusinegeri-merchant",
  storageBucket: "solusinegeri-merchant.appspot.com",
  messagingSenderId: "9296043542",
  appId: "1:9296043542:web:add970585f9fccbe479f37",
  measurementId: "G-VQY2Z62978",
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BPoPfPt9H3ZWN5PQC-bVwEn2N3baYiy4FM2jQCRV8Pbzj1WnmvKUEz4Lb2ACTXh5wZJtNbVVq_vnlFNtdphtmt8",
  })
    .then((currentToken) => {
      if (currentToken) {
        let ciperText = encryptedValue(currentToken)
        localStorage.setItem("fcm_token", ciperText);
        return currentToken;
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const firebaseSupported = async () => {
  const hasFirebaseMessagingSupport = await isSupported();
  if (hasFirebaseMessagingSupport) {
    localStorage.setItem("fcm", true);
  } else {
    localStorage.setItem("fcm", false);
    toast.error("Browser tidak mendukung notifikasi");
  }
};
