import { BrowserView, MobileView } from "react-device-detect";
import PayGateResultWeb from "./payGateResultWeb";
import PayGateResultMobile from "./payGateResultMobile";

const PayGateResult = () => {
  return (
    <div>
      <BrowserView>
        <PayGateResultWeb />
      </BrowserView>
      <MobileView>
        <PayGateResultMobile />
      </MobileView>
    </div>
  );
};

export default PayGateResult;
