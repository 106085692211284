/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../../config/global";
import Header from "../../../_common/headerComponent";
import { BalanceCard } from "../../../_common/BalanceCard";
import Reauthenticate from "../../../_common/reauthenticate";
import Company from "../../../../core/models/company";
import { useAppSelector } from "../../../../core/feature/hooks";
import { getPinConfig } from "../../../../core/service/api_user";
import { useDispatch } from "react-redux";
import { setPin } from "../../../../core/feature/user/pinSlice";
import ConfirmationModal from "../../../_common/confirmationModal";
import { useTranslation } from "react-i18next";

const SummaryMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const pin: number = useAppSelector((state) => state.pin.pin);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location: any = useLocation();
  const [dataOrder, setDataOrder] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [inquiryId, setInquiryId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const { t } = useTranslation();

  const getConfigPin = async () => {
    await getPinConfig()
      .then((res) => {
        dispatch(setPin(Number(res.data.data)));
      })
      .catch((err) => {
        // dispatch(removeCompany());
      });
  };

  const order = async () => {
    setShowConfirmationModal(false);
    if (pin === 1) {
      setShowModal(true);
    } else {
      navigate(`/${company?.initial}/create-security-code`, {
        state: {
          data: location.state.data,
          url: "transfer/bank/input-data/summary",
          type: "transfer",
        },
      });
    }
  };

  const [configColor, setConfigColor] = useState<any>({});
  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  useEffect(() => {
    getConfigColor();
  }, []);

  useEffect(() => {
    getConfigPin();
    setDataOrder(location.state.data.detailTransaction);
    setInquiryId(location.state.data.inquiryId);
    setTransactionId(location.state.data.detailTransaction._id);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Transfer Bank"></Header>

      <Reauthenticate
        show={showModal}
        onHide={() => setShowModal(false)}
        transactionType="TRANSFER_BANK"
        inquiryId={inquiryId}
        transactionId={transactionId}
        redirect="success"
        width={40}
        height={43}
        fontSize="1rem"
        margin="0 0.25rem"
      ></Reauthenticate>

      {/* <ConfirmationModal
        showModal={showConfirmationModal}
        hideModal={() => setShowConfirmationModal(false)}
        confirm={order}
      /> */}

      <div className="flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto mb-20">
          <div className="px-4">
            <BalanceCard />
          </div>
          <div className="bg-white w-full p-4 md:mb-10 mb-8 shadow-sm">
            <h6
              style={{
                color: `${configColor?.color1}`,
              }}
              className="text-lg md:text-xl font-medium text-green"
            >
              {t('barcodeWeb.summary')}
            </h6>
            <hr />
            <div className="space-between gap-3">
              <p className="text-sm md:text-base text-customBlack text-start font-semibold mb-0">
                {t('ppobHistory.transaction_date')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {moment().format("ll")}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-semibold mb-0">
                {t('ppobHistory.transaction_number')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {dataOrder?._id}
              </p>
            </div>
            <hr />
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-semibold mb-0">
                {t('transfer_bank.delivery')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {dataOrder?.name}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-semibold mb-0">
                {t('transfer_bank.to')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {dataOrder?.destAccountName}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-semibold mb-0">
                {t('home.category')}
              </p>
              <div className="flex flex-row items-center gap-2">
                <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                  {t('getAllBankAccount.title')}
                </p>
              </div>
            </div>
            <hr />
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-semibold mb-0">
                {t('whishlist.quantity')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {formatCurrency(dataOrder?.amount)}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-semibold mb-0">
                {t('withdraw_funds.adminFee')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {formatCurrency(dataOrder?.totalCharges)}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack text-start font-semibold mb-0">
                {t('barcodeWeb.total')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                {formatCurrency(dataOrder?.total)}
              </p>
            </div>
          </div>
          <div
            className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <button
              type="button"
              style={{
                backgroundColor:
                  company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
              }}
              onClick={order}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              Transfer
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SummaryMobile;
