/* eslint-disable react-hooks/exhaustive-deps */
import { faMagnifyingGlass, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { find, findIndex } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import { toast } from "react-hot-toast";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import NoImage from "../../assets/images/empty.jpg";
import BankDKI from "../../assets/images/icon_balance/bank_dki.png";
import LinkAja from "../../assets/images/icon_balance/link_aja_whitebg.png";
import CartIcon from "../../assets/images/icon_landingpageMarketplace/icon_keranjang.svg";
import StoreIcon from "../../assets/images/icon_landingpageMarketplace/icon_merchant.svg";
import HistoryIcon from "../../assets/images/icon_landingpageMarketplace/icon_riwayat.svg";
import WishlistIcon from "../../assets/images/icon_landingpageMarketplace/icon_whistlist.svg";
import ProductNotFound from "../../assets/images/icon_search_notFound.png";
import imageHomePage from "../../assets/images/img-homepage2.png";
import { TimeZone, formatCurrency } from "../../config/global";
import {
  loadState,
  removeState,
} from "../../core/feature/browser-storage/browser-storage";
import {
  // removeCompany,
  setCompany,
} from "../../core/feature/company/companySlice";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setMarketplaceConfig } from "../../core/feature/marketplace/marketplaceNameSlice";
import { setBalance } from "../../core/feature/user/balanceSlice";
import { setUser } from "../../core/feature/user/userSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import User, { MarketplaceConfig } from "../../core/models/user";
import UserLogin from "../../core/models/userLogin";
import * as API_BALANCE from "../../core/service/api_balance";
import * as API_COMMERCE from "../../core/service/api_commerce";
import * as API_USER from "../../core/service/api_user";
import { StatsMobile } from "../_common/Stats";
import BottomNavigator from "../_common/bottomNavigator";
import CategoryListNoLogin from "../_common/categorListNoLogin";
import CategoryList from "../_common/categoryList";
import DataNotFound from "../_common/dataNotFound";
import ProductComponent from "../_common/productComponent";
import patternSaldo from "../../assets/images/icon_home/pattern-saldo.svg";
import Swal from "sweetalert2";
import * as API_VIRTUAL_CARD from "../../core/service/api_virtualCard";
import FailedIcon from "../../assets/images/failed-icon.png";
import feature from "../../assets/images/icon_home/feature.svg";
import balance_ic from "../../assets/images/icon_menu/ic/balance.svg";
import donation from "../../assets/images/icon_menu/ic/donation.svg";
import invoice from "../../assets/images/icon_menu/ic/invoice.svg";
import marketplace from "../../assets/images/icon_menu/ic/marketplace.svg";
import ppob from "../../assets/images/icon_menu/ic/ppob.svg";
import qr from "../../assets/images/icon_menu/ic/qr.svg";
import siakad from "../../assets/images/icon_menu/ic/siakad.svg";
import virtualCard from "../../assets/images/icon_menu/ic/virtual_card.svg";
import mutasi from "../../assets/images/icon_menu/ic/mutation.svg";
import defaultIcon from "../../assets/images/icon_menu/customDefault.svg";
import * as API_AUTH from "../../core/service/api_auth";
import { Dialog, Transition } from "@headlessui/react";
import ModalInfo from "../_common/ModallInfo";
import merchantDefault from "../../assets/images/new_merchant_logo.png";
import Wisata from "../../assets/images/icon_menu/ic/wisata.svg";
import { useTranslation } from "react-i18next";
import userDefault from "../../assets/images/userDefault.png";
import bgdefault from "./icon/bgdefault.svg";
import { isIOS, isIOS13 } from "react-device-detect";

const LandingpageMarketplaceMobile = () => {
  const marketplaceConfig: MarketplaceConfig = useAppSelector(
    (state) => state?.marketplaceConfig?.marketplaceConfig
  );
  const [categories, setCategories] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [stores, setStores] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const userLogin: UserLogin[] = useAppSelector(
    (state) => state.userLogin.userLogin
  );
  // const balance = useAppSelector((state) => state.balance.balance);
  // const userChekout: any = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [isLogin] = useState((user && newToken) || false);
  const [balanceName, setBalanceName] = useState("");
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [comboProviders, setComboProviders] = useState<any[]>([]);
  const [connectedAccounts, setConnectedAccounts] = useState<any[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  // const [open, setOpen] = React.useState(false);
  const [keyword, setKeyword] = React.useState("");
  const [isSearch, setIsSearch] = React.useState(false);
  const mName: string = marketplaceConfig?.merchantName || "";
  const pName: string = marketplaceConfig?.productName || "";
  const location = useLocation();
  const [pathName] = useState(
    location.pathname.replaceAll("/", "").toUpperCase()
  );
  // const { cid } = useParams();
  const [homeMenus, setHomeMenus] = useState<any[]>([]);
  const [customMenu, setCustomMenu] = useState<any>([]);
  const [otherMenu, setOtherMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [iframeURL, setIframeUrl] = useState("");
  const [dataIcon, setDataIcon] = useState<any[]>([]);

  // No Login
  const [storesNologin, setStoresNologin] = useState<any[]>([]);
  const [categoryNoLogin, setCategoryNoLogin] = useState<any[]>([]);
  const [productNoLogin, setProductNoLogin] = useState<any[]>([]);
  const [pNameNoLogin, setPNameNoLogin] = useState("");
  const [mNameNoLogin, setMNameNoLogin] = useState("");
  const [dir] = useState(1);
  // const [page, setPage] = useState(1);
  // const [categoryId, setCategoryId] = useState("");
  const [configColor, setConfigColor] = useState<any>({});
  const time = TimeZone();
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng") || "id";

  const features = [
    {
      url: `/${company?.initial}/invoices`,
      name: "invoice",
      label: t("home.invoice"),
      icon: invoice,
    },
    {
      url: `/${company?.initial}/siakad`,
      name: "siakad",
      label: t("home.siakad"),
      icon: siakad,
    },
    {
      url: `/${company?.initial}/barcode`,
      name: "barcode_payment",
      label: t("home.barcode_payment"),
      icon: qr,
    },
    {
      url: `/${company?.initial}/donations`,
      name: "donasi",
      label: t("home.donation"),
      icon: donation,
    },
    {
      url: `/${company?.initial}/commerce`,
      name: "marketplace",
      label: t("home.marketplace"),
      icon: marketplace,
    },
    {
      url: `/${company?.initial}/digital-product`,
      name: "ppob",
      label: "PPOB",
      icon: ppob,
    },
    {
      url: `/${company?.initial}/virtual_card`,
      name: "virtual_card",
      label: t("home.virtual_card"),
      icon: virtualCard,
    },
    {
      url: `/${company?.initial}/balance`,
      name: "saldo",
      label: t("home.balance"),
      icon: balance_ic,
    },
    {
      url: `/${company?.initial}/features`,
      name: "accessibility",
      label: t("home.accessibility"),
      icon: feature,
    },
    {
      url: `/${company?.initial}/news-information`,
      name: "info_dan_berita",
      label: t("home.titleCardNews"),
      icon: mutasi,
    },
    {
      url: `/${company?.initial}/trip-tour`,
      name: "trip_tour",
      label: t("home.trip_tour"),
      icon: Wisata,
    },
  ];

  const providers = [
    {
      id: 1,
      code: "BANK_DKI",
      providerName: "Bank DKI",
      img: BankDKI,
    },
    {
      id: 2,
      code: "LINK_AJA",
      providerName: "Link Aja",
      img: LinkAja,
    },
  ];

  const MarketplaceIcons = [
    {
      id: "1",
      name: t("marketplace.wishlist"),
      icon: WishlistIcon,
      url: isLogin ? "commerce/wishlist" : "login",
      initial: "wishlist",
    },
    {
      id: "2",
      name: t("marketplace.history"),
      icon: HistoryIcon,
      url: isLogin ? "commerce/history" : "login",
      initial: "history",
    },
    {
      id: "3",
      name: t("marketplace.cart"),
      icon: CartIcon,
      url: isLogin ? "commerce/cart" : "login",
      initial: "cart",
    },
    {
      id: "4",
      name: isLogin ? mName : mNameNoLogin,
      icon: StoreIcon,
      url: isLogin ? "commerce/merchants" : "login",
      initial: "merchants",
    },
  ];

  const getMenu = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_USER.getCompany(pathName)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setCompany(res.data.data));
        dispatch(setLoading(loader));
        API_USER.getHomeConfig(res.data.data.id)
          .then((res) => {
            setHomeMenus(res.data.data);
          })
          .then((res) => {
            API_BALANCE.getComboProviders()
              .then((res) => {
                setComboProviders(res.data);
                let dataOrder = {
                  page: 0,
                  size: 10,
                  sortBy: "createTime",
                  dir: -1,
                  total: 0,
                };
                API_BALANCE.getConnectedAccounts2(dataOrder)
                  .then((res) => {
                    let tempConnectedAccounts = res.data.data.linkedAccounts;
                    _.map(tempConnectedAccounts, (el: any) => {
                      switch (el) {
                        case "BANK_DKI":
                          checkBankDKIAccount();
                          break;

                        case "LINK_AJA":
                          checkLinkAjaAccount();
                          break;

                        default:
                          dispatch(setLoading(false));
                          break;
                      }
                    });
                  })
                  .catch((err) => {
                    dispatch(setLoading(false));
                  });
              })
              .catch((err) => {
                dispatch(setLoading(false));
              });
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
        dispatch(setLoading(loader));
        let sortBy = "";
        API_USER.getConfigCustomMenu(company.id, sortBy)
          .then((res) => {
            console.log(res, "haloo");
            setCustomMenu(res.data.data);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
        dispatch(setLoading(loader));
        // let data = {
        //   title: "",
        //   isHeadline: true,
        //   page: 1,
        //   size: 10,
        //   sortBy: "createdTime",
        //   dir: -1,
        // };
        navigate(`/${res.data.data.initial}`);
      })
      .catch((err) => {});
  };

  const getProductByName = async (productName: string = "") => {
    let query = {
      timezone: time,
      page: 1,
      size: 10,
      sortBy: "-createdDate",
      productName,
    };
    dispatch(setLoading(true));
    await API_COMMERCE.getAllProducts(query)
      .then((res) => {
        dispatch(setLoading(false));
        setFilteredProducts(res.data.data);
        setIsSearch(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getBalance = async () => {
    await API_BALANCE.getBalance("CLOSEPAY")
      .then((res) => {
        dispatch(setBalance(res.data.data.amount));
      })
      .catch((err) => {});
  };

  const checkBankDKIAccount = async () => {
    await API_BALANCE.checkBankDKIAccount()
      .then((res) => {
        dispatch(setLoading(false));
        if (
          connectedAccounts.length > 0 &&
          connectedAccounts.find(
            (el: any) => el.provider === res.data.provider
          ) !== undefined
        ) {
          return;
        } else {
          setConnectedAccounts(connectedAccounts.concat(res.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const checkLinkAjaAccount = async () => {
    await API_BALANCE.checkLinkAjaAccount()
      .then((res) => {
        dispatch(setLoading(false));
        if (
          connectedAccounts.length > 0 &&
          connectedAccounts.find(
            (el: any) => el.provider === res.data.data.provider
          ) !== undefined
        ) {
          return;
        } else {
          setConnectedAccounts(connectedAccounts.concat(res.data.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getComboCategory = async (
    categoryName: string = "",
    level: number = 1,
    parentCategoryId: string = "",
    loader = true
  ) => {
    dispatch(setLoading(loader));
    let query = {
      categoryName,
      level,
      parentCategoryId,
    };
    await API_COMMERCE.getComboCategory(query)
      .then((res) => {
        dispatch(setLoading(false));
        setCategories(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getAllProducts = async (loader = true) => {
    let query = {
      timezone: time,
      page: 1,
      size: 10,
      sortBy: "-createdDate",
    };
    dispatch(setLoading(loader));
    await API_COMMERCE.getAllProducts(query)
      .then((res) => {
        dispatch(setLoading(false));
        setProducts(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getAllShop = async (loader = true) => {
    dispatch(setLoading(loader));
    let query = {
      timezone: time,
      page: 1,
      size: 10,
      sortBy: "-createdDate",
    };
    await API_COMMERCE.getAllShop(query)
      .then((res) => {
        dispatch(setLoading(false));
        setStores(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getMarketplaceName = async (loader = true) => {
    dispatch(setLoading(loader));
    API_COMMERCE.getConfigMarketplaceName().then((res: any) => {
      var conf = {
        merchantName: res.data.data.merchantName,
        productName: res.data.data.productName,
      };
      dispatch(setLoading(false));
      dispatch(setMarketplaceConfig(conf));
    });
  };

  // No Login

  const getCategoryNoLogin = async (
    categoryName: string = "",
    level: number = 1,
    parentCategoryId: string = "",
    loader = true
  ) => {
    dispatch(setLoading(loader));
    let query = {
      categoryName,
      level,
      parentCategoryId,
    };
    await API_COMMERCE.categoryNoLogin(company?.id, query)
      .then((res) => {
        dispatch(setLoading(false));
        setCategoryNoLogin(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProductNoLoginByName = async (productName: string = "") => {
    let query = {
      timezone: time,
      productName,
      page: 1,
      size: 10,
      sortBy: "-createdDate",
    };
    dispatch(setLoading(true));
    await API_COMMERCE.productNoLogin(company?.id, query)
      .then((res) => {
        dispatch(setLoading(false));
        setFilteredProducts(res.data.data);
        setIsSearch(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProductNoLogin = async (loader = true) => {
    let query = {
      categoryId: "",
      merchantId: "",
      productName: "",
      timezone: time,
      page: 1,
      size: 10,
      sortBy: dir === 1 ? "-createdDate" : "createdDate",
    };
    dispatch(setLoading(loader));
    await API_COMMERCE.productNoLogin(company?.id, query)
      .then((res) => {
        dispatch(setLoading(false));
        setProductNoLogin(products.concat(res.data.data));
        setProductNoLogin(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getMnameConfigNoLogin = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_COMMERCE.marketplaceNameNoLogin(company?.id)
      .then((res) => {
        if (Object.keys(res.data.data).length === 0) {
          setMNameNoLogin("Toko");
          setPNameNoLogin("Produk");
        } else {
          if (
            res.data.data.merchantName === "" ||
            res.data.data.merchantName === null
          ) {
            setMNameNoLogin("Toko");
          } else {
            setMNameNoLogin(res.data.data.merchantName);
          }

          if (
            res.data.data.productName === "" ||
            res.data.data.merchantName === null
          ) {
            setPNameNoLogin("Produk");
          } else {
            setPNameNoLogin(res.data.data.productName);
          }
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getProfile = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_USER.getProfile()
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setUser(res.data.data));
        var newUserLogin =
          userLogin?.length > 0
            ? _.filter(userLogin, (el) => el.initial === company.initial)
            : [];
        let indexUserLogin = findIndex(newUserLogin, {
          id: res.data.data.noId,
        });

        if (indexUserLogin < 0) {
          newUserLogin.push({
            ...newUserLogin[indexUserLogin],
            initial: company.initial,
            token: newToken?.token,
            name: res.data.data.name,
            photo: res.data.data.profileImage,
            id: res.data.data.noId,
          });
        } else {
          newUserLogin[indexUserLogin] = {
            ...newUserLogin[indexUserLogin],
            initial: company.initial,
            token: newToken?.token,
            name: res.data.data.name,
            photo: res.data.data.profileImage,
            id: res.data.data.noId,
          };
        }

        dispatch(setUserLogin(newUserLogin));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getMerchantNoLogin = async (loader = true) => {
    dispatch(setLoading(loader));
    let query = {
      timezone: time,
      page: 1,
      size: 10,
      sortBy: "-createdDate",
    };
    await API_COMMERCE.getAllShopNoLogin(query)
      .then((res) => {
        dispatch(setLoading(false));
        setStoresNologin(res.data.data);
        console.log(res, "kon");
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  // Batas No Login

  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#015f48";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const getConfigCard = async () => {
    if (isLogin) {
      await API_VIRTUAL_CARD.getConfigCard()
        .then(async (res) => {
          if (res.data.data == null) {
            Swal.fire({
              html: `<p class="font-medium">${t("marketplace.text_popup")}</p>`,
              color: "#EC1A1A",
              padding: "1rem",
              imageUrl: FailedIcon,
              imageAlt: "Custom image",
              imageWidth: 150,
              imageHeight: 150,
              confirmButtonColor: "#EC1A1A",
              confirmButtonText: "Kembali",
            });
          } else {
            let query = {
              page: 1,
            };
            await API_VIRTUAL_CARD.getCards(query).then((res2) => {
              if (res2.data.data.length === 1) {
                navigate(`virtual-card/list/${res2.data.data[0]._id}`, {
                  state: {
                    isSingleCard: true,
                  },
                });
              } else {
                navigate(`virtual-card/list`);
              }
            });
          }
        })
        .catch((err) => {});
    } else {
      navigate(`/${company?.initial}/login`);
    }
  };

  const onClickCustomMenu = async (data: any) => {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let string = "";
    for (let i = 0; i < 25; i++) {
      string += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    if (isLogin) {
      if (data.isAuthRequired === true) {
        dispatch(setLoading(true));
        await API_AUTH.authCustomMenu(string)
          .then((res) => {
            dispatch(setLoading(false));
            console.log(res);
            if (data.redirectType === "internal") {
              setIsOpen(true);
              setOtherMenu(false);
              let url = new URL(data.redirectUrl);
              url.searchParams.set(
                "one_time_access_token",
                `${res.data.data.token}`
              );
              url.searchParams.set("identifier", `${string}`);
              setIframeUrl(`${url.toString()}`);
            } else {
              let url = new URL(data.redirectUrl);
              url.searchParams.set(
                "one_time_access_token",
                `${res.data.data.token}`
              );
              url.searchParams.set("identifier", `${string}`);
              const link = `${url.toString()}`;
              if (isIOS || isIOS13) return window.location.replace(link);
              window.open(link, "_blank");
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      } else {
        let url = new URL(data.redirectUrl);
        if (data.redirectType === "internal") {
          setIsOpen(true);
          setOtherMenu(false);
          setIframeUrl(`${url.toString()}`);
        } else {
          const link = `${url.toString()}`;
          if (isIOS || isIOS13) return window.location.replace(link);
          window.open(link, "_blank");
        }
      }
    } else if (data.name === "Antrian Online" && !isLogin) {
      navigate("online-service");
    } else {
      navigate("login");
    }
  };

  const getDataIcon = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_COMMERCE.getComboIconCommerce()
      .then((res) => {
        console.log(res.data.data, "cek");
        dispatch(setLoading(false));
        setDataIcon(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getMainBalance = async (loader = true) => {
    dispatch(setLoading(loader));
    await API_BALANCE.getMainBalanceMember()
      .then((res) => {
        dispatch(setLoading(false));
        setBalanceName(res.data.data.balanceName);
        setBalanceAmount(res.data.data.amount);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    const asyncFunct = async (loader = true) => {
      await getDataIcon(loader);
      await getConfigColor();
      await getCategoryNoLogin("", null as any, null as any, loader);
      await getMarketplaceName(loader);
      await getProductNoLogin(loader);
      await getMerchantNoLogin(loader);
      await getMnameConfigNoLogin(loader);
      await getComboCategory("" as any, "" as any, "" as any, loader);
      await getAllProducts(loader);
      await getAllShop(loader);
      await getBalance();
      await getMenu(loader);
      await getProfile(loader);
      await removeState("category");
      await getMainBalance(loader);
      setTimeout(() => {
        sessionStorage.removeItem("loader");
      }, 1500);
    };

    if (sessionStorage.getItem("loader")) {
      asyncFunct(false);
    } else {
      asyncFunct();
    }
  }, []);

  return (
    <section>
      <div className="h-full min-h-screen font-montserrat bg-gray-100">
        <BottomSheet open={showModal}>
          <ModalInfo />
          <div className="pb-20 z-50">
            <div className="flex flex-col relative transform overflow-hidden rounded-lg bg-white transition-all md:w-1/2  p-[1.5rem]">
              {!isLogin && (
                <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white transition-all md:w-1/2">
                  <div className="space-between mb-3 px-3">
                    <h4 className="md:text-xl text-lg text-black font-medium mb-0">
                      {t("home.category")}
                    </h4>
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                        removeState("category");
                        removeState("category_names");
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                  <div className="mt-1 mb-3 w-full">
                    {categoryNoLogin.map((el, i) => (
                      <CategoryListNoLogin data={el} title={el.title} id={i} />
                    ))}
                  </div>
                  <div className="flex items-center gap-3 w-full">
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                        removeState("category");
                        removeState("category_names");
                      }}
                      className="bg-white border-[0.5px] border-[#70717D] text-[#70717D] md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                    >
                      {t("home.buttonCancel")}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        // setOpen(false);
                        if (loadState("category") !== undefined) {
                          navigate(
                            `/${company?.initial}/commerce/products/${
                              loadState("category").id
                            }`,
                            {
                              state: {
                                category: loadState("category"),
                                category_names: loadState("category_names"),
                              },
                            }
                          );
                        } else {
                          toast.error("Pilih kategori terlebih dahulu");
                        }
                      }}
                      style={{ backgroundColor: configColor?.color1 }}
                      className=" text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                    >
                      {t("home.buttonApply")}
                    </button>
                  </div>
                </div>
              )}

              {isLogin && (
                <div className="flex flex-col min-h-full p-2 text-center sm:items-center sm:p-0">
                  <div className="mb-3 flex justify-between">
                    <h4 className="md:text-xl text-left text-lg text-black font-medium mb-0">
                      {t("home.category")}
                    </h4>
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                        removeState("category");
                        removeState("category_names");
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                  <div className="mt-1 mb-3 w-full">
                    {categories.map((item) => (
                      <CategoryList data={item} title={item.title} />
                    ))}
                  </div>
                  <div className="flex items-center gap-3 w-full">
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                        removeState("category");
                        removeState("category_names");
                      }}
                      className="bg-white border-[0.5px] border-[#70717D] text-[#70717D] md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                    >
                      {t("home.buttonCancel")}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        // setOpen(false);
                        if (loadState("category") !== undefined) {
                          navigate(
                            `/${company?.initial}/commerce/products/${
                              loadState("category").id
                            }`,
                            {
                              state: {
                                category: loadState("category"),
                                category_names: loadState("category_names"),
                              },
                            }
                          );
                        } else {
                          toast.error(t("marketplace.toastErrorCategory"));
                        }
                      }}
                      style={{ backgroundColor: configColor?.color1 }}
                      className=" text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                    >
                      {t("marketplace.apply")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </BottomSheet>

        {/* <StatsMobile /> */}

        <div className="w-full relative shadow">
          <div className="container justify-center pb-[44px]">
            <section className="relative z-0 flex flex-col justify-center pt-6 md:mb-[0px] mb-[20px] px-3">
              <div className="relative z-10 mb-4">
                <div className="flex flex-row items-center justify-between pt-[20px]">
                  <Link to={`/${company?.initial}`}>
                    <img
                      src={company?.companyLogo}
                      alt=""
                      className=" h-10 rounded-full"
                    />
                  </Link>
                  {isLogin && (
                    <Link to="profile">
                      <img
                        src={
                          user.profileImage
                            ? `${user.profileImage}`
                            : userDefault
                        }
                        alt=""
                        className="h-10 rounded-full "
                      />
                    </Link>
                  )}
                </div>
              </div>
              {isLogin && (
                <div
                  onClick={() => navigate("balance")}
                  className="flex flex-col"
                >
                  <span className="text-[#728F9E] text-[16px] flex items-center">
                    {t("accessibility.balance")}{" "}
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.65967 2.51225L7.91967 5.77225C8.30467 6.15725 8.30467 6.78725 7.91967 7.17225L4.65967 10.4323"
                        stroke="#728F9E"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    style={{ color: configColor?.color1 }}
                    className="text-xl font-montserrat font-semibold mb-0"
                  >
                    {formatCurrency(balanceAmount)}
                  </span>
                </div>
              )}
            </section>

            <div className="w-full relative px-3">
              <div className="w-full mb-4 relative">
                <div className="relative flex items-center text-gray-400">
                  <button
                    type="button"
                    onClick={() =>
                      isLogin
                        ? getProductByName(keyword)
                        : getProductNoLoginByName(keyword)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      color="#728F9E"
                      size="lg"
                      className="absolute top-[16px] ml-3"
                    />
                  </button>
                  <input
                    type="text"
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                      setIsSearch(false);
                    }}
                    placeholder={t("marketplace.searchProduct")}
                    onKeyDown={(e) => {
                      isLogin
                        ? e.key === "Enter" && getProductByName(keyword)
                        : e.key === "Enter" && getProductNoLoginByName(keyword);
                    }}
                    name="search"
                    className="w-full bg-[#FAFAFA] text-newColor font-medium placeholder-newColor rounded-[12px] focus:outline-2 focus:outline-newColor text-sm py-3 pr-3 pl-10"
                  />
                </div>
                {isSearch &&
                  keyword !== "" &&
                  (filteredProducts.length > 0 ? (
                    <div className="absolute bg-white p-3 mt-1 w-full z-20">
                      <ul>
                        {filteredProducts.map((el: any) => (
                          <li key={el.id}>
                            <Link
                              to={`commerce/products/detail/${el.id}`}
                              className="no-underline flex items-center gap-3"
                            >
                              <img
                                src={
                                  el?.imagePath.length > 0 &&
                                  el?.imagePath[0] !== ""
                                    ? el?.imagePath[0]
                                    : NoImage
                                }
                                alt=""
                                className="w-[50px] h-[50px] object-contain"
                              />
                              <p className="text-thirdy md:text-base text-sm font-medium mb-0">
                                {el?.name}
                              </p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className="absolute bg-white p-3 flex flex-col justify-center items-center w-full mt-1 z-20">
                      <img src={ProductNotFound} alt="" />
                      <h6 className="text-thirdy md:text-sm text-xs font-medium mb-0 p-3">
                        {t("marketplace.productNotFound")}
                      </h6>
                    </div>
                  ))}
              </div>

              <div className="w-full mb-4">
                <img
                  src={company?.bannerImage?.mobile || bgdefault}
                  alt=""
                  className="rounded-xl w-full"
                ></img>
              </div>

              {!isLogin && (
                <div
                  style={{ backgroundColor: configColor?.color1 }}
                  className="relative dark:bg-black rounded-xl shadow py-2 mb-4"
                >
                  <div className="flex flex-row justify-center px-4 h-fit overflow-hidden items-center">
                    <p className="text-sm font-montserrat text-white mt-3">
                      {t("home.titleCardDescriptionNoLogin")}
                    </p>
                    <div className="flex flex-col gap-2">
                      <button
                        type="button"
                        style={{
                          color: configColor?.color1,
                          borderColor: configColor?.color1,
                        }}
                        onClick={() => navigate(`login`)}
                        className="w-fit px-3 h-fit py-0.5 font-montserrat bg-white font-medium text-[10px] rounded-[8px] text-[#FFFFFF] border-[1px] md:text-base text-sm hover:bg-[#FFFFFF]"
                      >
                        {t("home.buttonLogin")}
                      </button>
                      {company?.enableMemberSelfRegister === true && (
                        <button
                          type="button"
                          style={{
                            color: configColor?.color1,
                            borderColor: configColor?.color1,
                          }}
                          onClick={() => navigate(`self-register`)}
                          className="w-fit px-3 h-fit py-0.5 font-montserrat bg-white font-medium text-[10px] rounded-[8px] text-[#FFFFFF] border-[1px] md:text-base text-sm hover:bg-[#FFFFFF]"
                        >
                          {t("home.buttonRegister")}
                        </button>
                      )}
                    </div>
                    {/* <img
                      src={
                        company?.bannerForeground?.mobile === null ||
                        company?.bannerForeground === null
                          ? imageHomePage
                          : company?.bannerForeground?.mobile
                      }
                      alt=""
                      className=" w-[130px] bottom-0 right-0 absolute"
                    /> */}
                  </div>
                </div>
              )}

              {isLogin && (
                <div className="space-between mb-3">
                  <button className="text-lg text-black text-semibold mb-0">
                    {t("merchants.recommended")}
                  </button>
                  <button
                    type="button"
                    onClick={() => setOtherMenu(true)}
                    style={{ color: configColor?.color1 }}
                    className="md:text-sm text-xs no-underline "
                  >
                    {t("merchants.all_menu")}
                  </button>
                </div>
              )}
              {isLogin && (
                <div className="grid grid-cols-4 items-start justify-start gap-3">
                  {MarketplaceIcons.map((item) => (
                    <Link
                      to={item.url}
                      className="no-underline flex flex-col align-items-center justify-start gap-2"
                      key={item.id}
                    >
                      <div>
                        <img
                          src={
                            dataIcon.find(
                              (el) => el.menu.toLowerCase() === item.initial
                            )?.url
                              ? dataIcon.find(
                                  (el) => el.menu.toLowerCase() === item.initial
                                )?.url
                              : item.icon
                          }
                          alt=""
                          className="h-[70px]"
                        />
                      </div>
                      <h6 className="text-thirdy md:text-sm text-xs font-medium text-center mb-0">
                        {item.name}
                      </h6>
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {!isLogin && (
              <section id="categories" className="w-full mt-4">
                <div className="space-between mb-3 px-3">
                  <h6 className="md:text-xl text-lg text-black text-semibold mb-0">
                    {t("merchants.recommended")}
                  </h6>
                  <button
                    type="button"
                    onClick={() => setShowModal(true)}
                    style={{ color: configColor?.color1 }}
                    className="md:text-sm text-xs no-underline"
                  >
                    {t("merchants.all_menu")}
                  </button>
                </div>
                {categoryNoLogin.length > 0 ? (
                  <Swiper
                    freeMode={true}
                    grabCursor={true}
                    modules={[FreeMode]}
                    spaceBetween={16}
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 3,
                        spaceBetween: 16,
                      },
                      "@0.75": {
                        slidesPerView: 3,
                        spaceBetween: 16,
                      },
                      "@1.00": {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                      "@1.50": {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                    }}
                    className="w-full relative justify-center mySwiper"
                  >
                    {categoryNoLogin.map((item: any) => (
                      <SwiperSlide
                        onClick={() => {
                          navigate(`commerce/products/${item.id}`, {
                            state: {
                              category: {
                                id: item.id,
                              },
                              category_names: {
                                level1: item.name,
                                level2: "",
                                level3: "",
                              },
                            },
                          });
                        }}
                        key={item.id}
                        className="swiper-slide-auto"
                      >
                        <div className="cursor-pointer rounded-full border-[#E4E4E4] border-1 flex flex-row py-2 pl-2 pr-3">
                          <div className="w-6 h-6">
                            <img
                              loading="lazy"
                              draggable={false}
                              className="object-contain w-6 h-6 select-none overflow-hidden rounded-full"
                              src={
                                item.imagePath === "" ? NoImage : item.imagePath
                              }
                              alt=""
                            />
                          </div>
                          <span className="text-thirdy md:text-base text-sm font-medium text-center ml-2 mb-0">
                            {item.name}
                          </span>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  <DataNotFound title={t("marketplace.dataNotFound")} />
                )}
              </section>
            )}

            {isLogin && (
              <section id="categories" className="w-full mt-4">
                <div className="space-between mb-3 px-3">
                  <h6 className="md:text-xl text-lg text-black text-semibold mb-0">
                    {t("marketplace.buy_now")}
                  </h6>
                  <button
                    type="button"
                    onClick={() => setShowModal(true)}
                    style={{ color: configColor?.color1 }}
                    className="md:text-sm text-xs no-underline"
                  >
                    {t("marketplace.viewAll")}
                  </button>
                </div>
                {categories.length > 0 ? (
                  <Swiper
                    freeMode={true}
                    grabCursor={true}
                    modules={[FreeMode]}
                    spaceBetween={16}
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 3,
                        spaceBetween: 16,
                      },
                      "@0.75": {
                        slidesPerView: 3,
                        spaceBetween: 16,
                      },
                      "@1.00": {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                      "@1.50": {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                    }}
                    className="w-full relative justify-center mySwiper"
                  >
                    {categories.map((item) => (
                      <SwiperSlide
                        onClick={() =>
                          navigate(`commerce/products/${item.id}`, {
                            state: {
                              category: {
                                id: item.id,
                              },
                              category_names: {
                                level1: item.name,
                                level2: "",
                                level3: "",
                              },
                            },
                          })
                        }
                        key={item.id}
                        className="swiper-slide-auto"
                      >
                        <div className="cursor-pointer rounded-full border-[#E4E4E4] border-1 flex flex-row py-2 pl-2 pr-3">
                          <div className="w-6 h-6">
                            <img
                              loading="lazy"
                              draggable={false}
                              className="object-contain w-6 h-6 select-none overflow-hidden rounded-full"
                              src={
                                item.imagePath === "" ? NoImage : item.imagePath
                              }
                              alt=""
                            />
                          </div>
                          <span className="text-thirdy md:text-base text-sm font-medium text-center ml-2 mb-0">
                            {item.name}
                          </span>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  <DataNotFound title={t("marketplace.dataNotFound")} />
                )}
              </section>
            )}

            {!isLogin && (
              <section id="products" className="w-full mb-14 mt-3">
                {productNoLogin.length > 0 ? (
                  <div className="grid grid-cols-2 gap-4 px-2">
                    {productNoLogin.slice(0, 4).map((item) => (
                      <ProductComponent item={item} type="P" />
                    ))}
                  </div>
                ) : (
                  <DataNotFound title={t("marketplace.dataNotFound")} />
                )}
              </section>
            )}

            {isLogin && (
              <section id="products" className="w-full mb-4 mt-3">
                {products.length > 0 ? (
                  <div className="grid grid-cols-2 gap-4 px-2">
                    {products.slice(0, 4).map((item) => (
                      <ProductComponent item={item} type="P" />
                    ))}
                  </div>
                ) : (
                  <DataNotFound title={t("marketplace.dataNotFound")} />
                )}
              </section>
            )}
          </div>
        </div>
        <BottomNavigator title="home" />
      </div>
      <BottomSheet
        onDismiss={() => setOtherMenu(false)}
        open={otherMenu}
        className="z-50 relative"
      >
        <div className="p-3">
          <h4 className="text-[16px] font-semibold">{t("home.mainMenu")}</h4>
          <div className="grid lg:grid-cols-5 sm:grid-cols-4 grid-cols-4 gap-4 justify-between p-2">
            {homeMenus.map((item: any) =>
              features
                .filter((el) => el.name === item.name)
                .map((feature: any, index: number) => (
                  <div
                    className=" flex flex-col items-center justify-start "
                    key={index}
                  >
                    {feature.name === "virtual_card" ? (
                      <div
                        onClick={getConfigCard}
                        className={
                          "no-underline flex flex-col items-center gap-2.5"
                        }
                      >
                        <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                          <img
                            src={
                              item.imageUrl === null || item.imageUrl === ""
                                ? feature.icon
                                : item.imageUrl
                            }
                            alt=""
                            className={
                              item.imageUrl === null || item.imageUrl === ""
                                ? "w-[54px] h-[54px] rounded-[18px]"
                                : "w-[54px] h-[54px] rounded-[18px] p-2"
                            }
                          />
                        </div>
                        <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                          {language === "en"
                            ? item.displayEn || feature.label
                            : item.display || feature.label}
                        </h6>
                      </div>
                    ) : feature.name === "siakad" ? (
                      <div
                        onClick={() => {
                          if (isLogin) {
                            window.location.href = `https://app.dev.solusinegeri.com/siakad/${company.initial}`;
                            localStorage.removeItem("userType");
                          } else {
                            navigate("login");
                          }
                        }}
                        className={
                          "no-underline flex flex-col items-center gap-2.5"
                        }
                      >
                        <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                          <img
                            src={
                              item.imageUrl === null || item.imageUrl === ""
                                ? feature.icon
                                : item.imageUrl
                            }
                            alt=""
                            className={
                              item.imageUrl === null || item.imageUrl === ""
                                ? "w-[54px] h-[54px] rounded-[18px]"
                                : "w-[54px] h-[54px] rounded-[18px] p-2"
                            }
                          />
                        </div>
                        <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                          {language === "en"
                            ? item.displayEn || feature.label
                            : item.display || feature.label}
                        </h6>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          navigate(isLogin ? feature.url : "login", {
                            state: {
                              title:
                                language === "en"
                                  ? item.displayEn || feature.label
                                  : item.display || feature.label,
                            },
                          });
                        }}
                        className={
                          "no-underline flex flex-col items-center gap-2.5"
                        }
                      >
                        <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                          <img
                            src={
                              item.imageUrl === null || item.imageUrl === ""
                                ? feature.icon
                                : item.imageUrl
                            }
                            alt=""
                            className={
                              item.imageUrl === null || item.imageUrl === ""
                                ? "w-[54px] h-[54px] rounded-[18px]"
                                : "w-[54px] h-[54px] rounded-[18px] p-2"
                            }
                          />
                        </div>
                        <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                          {language === "en"
                            ? item.displayEn || feature.label
                            : item.display || feature.label}
                        </h6>
                      </div>
                    )}
                  </div>
                ))
            )}
          </div>
          {customMenu.length > 0 && (
            <h4 className="mt-3 text-[16px] font-semibold">
              {t("home.menuOther")}
            </h4>
          )}
          <div className="grid lg:grid-cols-5 sm:grid-cols-4 grid-cols-4 gap-4 justify-between p-2">
            {customMenu.map((res: any) => {
              return (
                <div
                  onClick={() => onClickCustomMenu(res)}
                  className={"no-underline flex flex-col items-center gap-2.5"}
                >
                  <div className="flex flex-col items-center bg-white justify-center no-underline shadow-xl shadow-[#18181809] rounded-[18px]">
                    <img
                      src={
                        res.imageUrl === null || res.imageUrl === ""
                          ? defaultIcon
                          : res.imageUrl
                      }
                      alt=""
                      className={
                        res.imageUrl === null || res.imageUrl === ""
                          ? "w-[54px] h-[54px] rounded-[18px]"
                          : "w-[54px] h-[54px] rounded-[18px] p-2"
                      }
                    />
                  </div>
                  <h6 className="font-montserrat text-[#777879] text-center text-[12px] font-medium  mb-0">
                    {res.name === "" ? res.name : res.name}
                  </h6>
                </div>
              );
            })}
          </div>
        </div>
      </BottomSheet>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1000]"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-screen items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                  <iframe
                    title="menu"
                    src={iframeURL}
                    className="w-full h-full min-h-[70vh]"
                  ></iframe>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </section>
  );
};

export default LandingpageMarketplaceMobile;
