import { BrowserView, MobileView } from "react-device-detect";
import CartWeb from "../cartComponent/cartWeb";
import CartMobile from "../cartComponent/cartMobile";
import { useEffect, useState } from "react";
import {
  loadState,
  removeState,
  saveState,
} from "../../../core/feature/browser-storage/browser-storage";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../core/feature/config/configSlice";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import Company from "../../../core/models/company";
import { useAppSelector } from "../../../core/feature/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";

const CartFnb = () => {
  const dispatch = useDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const [fnbType, setFnbType] = useState("DINE_IN");
  const [nama, setNama] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [noWa, setNoWa] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [selected, setSelected] = useState<any>({
    value: "DINE_IN",
    label: "Dine In",
  });
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);

  let params = useParams();

  const product = data?.map((res: any) => {
    let total = res.item.price * res.quantity;
    let totalFinal = res.item.finalPrice * res.quantity;
    return {
      productId: res.item.productId,
      itemId: res.item.id,
      name: res.item.productName,
      variantKey: res.item.variantKey,
      itemName: res.item.productName,
      sku: "",
      imageList: res.item.imageUrl,
      price: res.item.price,
      finalPrice: res.item.finalPrice,
      quantity: res.quantity,
      totalPrice: total,
      totalFinalPrice: totalFinal,
    };
  });

  var totalValue = product?.reduce(
    (a: any, v: any) => (a = a + v.totalFinalPrice),
    0
  );

  const createOrder = async () => {
    if (nama === "") {
      toast.error("Nama harus di isi");
    } else if (fnbType === "DINE_IN") {
      if (tableNumber === "") {
        toast.error("Nomor meja harus di isi");
      } else {
        dispatch(setLoading(true));
        let query = {
          customer: {
            name: nama,
            tableNumber: tableNumber,
            phoneNumber: noWa,
            email: email,
            fnbType: selected.value,
            note: note,
          },
          productList: product,
        };
        await API_COMMERCE.createOrderFnb(company.id, params.merchantId, query)
          .then((res) => {
            console.log(res);
            localStorage.setItem("orderId", res.data.data.id);
            var data = res.data.data;
            localStorage.setItem("summaryData", JSON.stringify(data));
            navigate(res.data.data.transactionId);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    } else {
      dispatch(setLoading(true));
      let query = {
        customer: {
          name: nama,
          tableNumber: tableNumber,
          phoneNumber: noWa,
          email: email,
          fnbType: selected.value,
          note: note,
        },
        productList: product,
      };
      await API_COMMERCE.createOrderFnb(company.id, params.merchantId, query)
        .then((res) => {
          console.log(res);
          localStorage.setItem("orderId", res.data.data.id);
          var data = res.data.data;
          localStorage.setItem("summaryData", JSON.stringify(data));
          navigate(res.data.data.transactionId);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const editQuantity = (con: any, i: any) => {
    if (con === "add") {
      let newQuantity = [];
      newQuantity = data;
      let quantity = newQuantity[i].quantity + 1;
      setData(
        data.map((item: any) =>
          item.item.id === data[i].item.id
            ? { ...item, quantity: quantity }
            : item
        )
      );
      saveState(
        "cart_data",
        data.map((item: any) =>
          item.item.id === data[i].item.id
            ? { ...item, quantity: quantity }
            : item
        )
      );
    } else {
      let newQuantity = [];
      newQuantity = data;
      let quantity = newQuantity[i].quantity - 1;
      if (newQuantity[i].quantity - 1 === 0) {
        Swal.fire({
          title: "Apakah anda yakin untuk menghapus?",
          text: "Lanjutkan proses",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Submit",
        }).then((res) => {
          if (res.isConfirmed) {
            if (data.length > 1) {
              data.splice(i, 1);
              setData(data);
              saveState("cart_data", data);
              window.location.reload();
            } else {
              setData([]);
              removeState("cart_data");
              navigate(
                `/${company.initial}/merchant_catalog_product/${params.merchantId}`
              );
            }
          }
        });
      } else {
        setData(
          data.map((item: any) =>
            item.item.id === data[i].item.id
              ? { ...item, quantity: quantity }
              : item
          )
        );
        saveState(
          "cart_data",
          data.map((item: any) =>
            item.item.id === data[i].item.id
              ? { ...item, quantity: quantity }
              : item
          )
        );
      }
    }
  };

  const option = [
    { value: "DINE_IN", label: "Dine In" },
    { value: "TAKE_AWAY", label: "Take Away" },
  ];

  const getData = () => {
    setData(loadState("cart_data"));
  };

  useEffect(() => {
    getData();
    console.log(loadState("cart_data"), "halo");
  }, []);

  useEffect(() => {
    if (loadState("cart_data") === undefined) {
      navigate(
        `/${company.initial}/merchant_catalog_product/${params.merchantId}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BrowserView>
        <CartWeb
          editQuantity={editQuantity}
          createOrder={createOrder}
          data={data}
          totalValue={totalValue}
          option={option}
          selected={selected}
          fnbType={fnbType}
          setFnbType={(value: any) => setFnbType(value)}
          setNama={(value: any) => setNama(value)}
          setTableNumber={(value: any) => setTableNumber(value)}
          setNote={(value: any) => setNote(value)}
          setSelected={(value: any) => setSelected(value)}
          setNoWa={(value: any) => setNoWa(value)}
          setEmail={(value: any) => setEmail(value)}
        />
      </BrowserView>
      <MobileView>
        <CartMobile
          editQuantity={editQuantity}
          createOrder={createOrder}
          data={data}
          totalValue={totalValue}
          option={option}
          selected={selected}
          fnbType={fnbType}
          setFnbType={(value: any) => setFnbType(value)}
          setNama={(value: any) => setNama(value)}
          setTableNumber={(value: any) => setTableNumber(value)}
          setNote={(value: any) => setNote(value)}
          setSelected={(value: any) => setSelected(value)}
          setNoWa={(value: any) => setNoWa(value)}
          setEmail={(value: any) => setEmail(value)}
        />
      </MobileView>
    </>
  );
};

export default CartFnb;
