import Balance from "./components/balanceComponent";
import ChangeCompany from "./components/changeCompanyComponent";
import CreatePassword from "./components/createPasswordComponent";
import Home from "./components/homeComponent";
import Invoice from "./components/invoiceComponent";
import InvoiceDetail from "./components/invoiceDetailComponent";
import Login from "./components/loginComponent";
import PPOB from "./components/ppobComponent";
import PPOBPrabayarSuccess from "./components/ppobPrabayarSuccessComponent";
import PPOBPrabayarSummary from "./components/ppobPrabayarSummaryComponent";
import PPOBPulsaData from "./components/ppobPulsaDataComponent";
import PPOBPascabayar from "./components/ppobPascabayarComponent";
import PPOBPrabayar from "./components/ppobPrabayarComponent";
import PPOBPascabayarSummary from "./components/ppobPascabayarSummaryComponent";
import Profile from "./components/profileComponent";
import Topup from "./components/topupComponent";
import Transfer from "./components/transferComponent";
import PPOBPascabayarSuccess from "./components/ppobPascabayarSuccessComponent";
import PPOBPLN from "./components/ppobPLNComponent";
import PPOBPLNSummary from "./components/ppobPLNSummaryComponent";
import PPOBPLNSuccess from "./components/ppobPLNSuccess";
import PPOBAirPDAM from "./components/ppobAirPDAMComponent";
import PPOBAirPDAMSummary from "./components/ppobAirPDAMSummaryComponent";
import PPOBAirPDAMSuccess from "./components/ppobAirPDAMSuccessComponent";
import PPOBBPJS from "./components/ppobBPJSComponent";
import PPOBBPJSSummary from "./components/ppobBPJSSummaryComponent";
import PPOBBPJSSuccess from "./components/ppobBPJSSuccessComponent";
import PPOBInternetTV from "./components/ppobInternetTVComponent";
import EditProfile from "./components/editProfileComponent";
import ChangePassword from "./components/changePasswordComponent";
import TermOfService from "./components/TermOfService";
import SettingProfile from "./components/settingProfile";
import SecurityCode from "./components/securityCodeComponent";
import ChangeSecurityCode from "./components/changeSecurityCodeComponent";
import ForgetSecurityCode from "./components/forgetSecurityCodeComponent";
import VerifikasiEmailOTP from "./components/verifikasiEmailOTP";
import PPOBInternetTVSummary from "./components/ppobInternetTVSummaryComponent";
import TransferMemberData from "./components/transferComponent/transferMemberComponent/inputDataComponent";
import TransferMemberSummary from "./components/transferComponent/transferMemberComponent/summaryComponent";
import TransferMemberPaymentSummary from "./components/transferComponent/transferMemberComponent/paymentSummaryComponent";
import PPOBInternetTVSuccess from "./components/ppobInternetTVSuccessComponent";
import PPOBPajakPBB from "./components/ppobPajakPBBComponent";
import PPOBPajakPBBSummary from "./components/ppobPajakPBBSummaryComponent";
import PPOBPajakPBBSuccess from "./components/ppobPajakPBBSuccessComponent";
import ForgetPassword from "./components/forgetPasswordComponent";
import InputEmail from "./components/InputEmailComponent";
import SelfRegister from "./components/selfRegisterComponent";
import ListVirtualCard from "./components/virtualCard/listVirtualCardComponent";
import AddVirtualCard from "./components/virtualCard/addVirtualCardComponent";
import VirtualCardSummary from "./components/virtualCard/virtualCardSummaryComponent";
import PaymentSummary from "./components/virtualCard/paymentSummaryComponent";
import CreateSecurityCode from "./components/createSecurityCodeComponent";
import VirtualCardDetail from "./components/virtualCard/virtualCardDetailComponent";
import CardSecurityCode from "./components/virtualCard/securityCodeComponent/cardSecurityCodeComponent";
import ActivateCardSecurityCode from "./components/virtualCard/securityCodeComponent/activateCardSecurityCodeComponent";
import ChangeCardSecurityCode from "./components/virtualCard/securityCodeComponent/changeCardSecurityCodeComponent";
import InactivateCardSecurityCode from "./components/virtualCard/securityCodeComponent/inactivateCardSecurityCodeComponent";
import EmailVerification from "./components/virtualCard/securityCodeComponent/emailVerificationComponent";
import ForgetCardSecurityCode from "./components/virtualCard/securityCodeComponent/forgetCardSecurityCodeComponent";
import VirtualCardHistory from "./components/virtualCard/virtualCardHistoryComponent";
import VirtualCardMutation from "./components/virtualCard/virtualCardMutationComponent";
import TransactionDetail from "./components/virtualCard/transactionDetailComponent";
import WithdrawCardBalance from "./components/virtualCard/withdrawCardBalance";
import WithdrawCardSummary from "./components/virtualCard/withdrawCardSummaryComponent";
import WithdrawCardPaymentSummary from "./components/virtualCard/withdrawCardPaymentSummaryComponent";
import TopUpCardBalance from "./components/virtualCard/topUpCardBalanceComponent";
import TopUpCardBalanceSummary from "./components/virtualCard/topUpCardBalanceSummaryComponent";
import TopUpCardBalancePaymentSummary from "./components/virtualCard/topUpCardBalancePaymentSummaryComponent";
import InvoicePayment from "./components/invoicePaymentComponent";
import InvoiceSuccessPayment from "./components/invoiceSuccessPaymentComponent";
import GetAllBankAccount from "./components/transferComponent/transferBankComponent/getAllBankAccountComponent";
import AddBankAccount from "./components/transferComponent/transferBankComponent/addBankAccountComponent";
import TransferBankData from "./components/transferComponent/transferBankComponent/inputDataComponent";
import TransferBankSummary from "./components/transferComponent/transferBankComponent/summaryComponent";
import TransferBankSuccess from "./components/transferComponent/transferBankComponent/paymentSummaryComponent";
import TopUpDetail from "./components/topupComponent/topUpDetailComponent";
import InvoiceCreate from "./components/invoiceCreateComponent";
import ConnectAccount from "./components/balanceComponent/connectAccountComponent";
import InputNumber from "./components/balanceComponent/inputNumberComponent";
import SuccessConnectAccount from "./components/balanceComponent/successConnectAccountComponent";
import CreateBarcode from "./components/createBarcodeComponent";
import DonationHome from "./components/donationHomeComponent";
import Donation from "./components/donationComponent";
import Zakat from "./components/zakatComponent";
import DonationDetail from "./components/donationDetailComponent";
import ZakatDetail from "./components/zakatDetailComponent";
import DonationTransaction from "./components/donationTransactionComponent";
import ZakatTransaction from "./components/zakatTransactionComponent";
import DonationProgress from "./components/donationProgressComponent";
import ZakatProgress from "./components/zakatProgressComponent";
import DonationDisbursement from "./components/donationDisbursementComponent";
import ZakatDisbursement from "./components/zakatDisbursementComponent";
import DonationAdd from "./components/donationAddComponent";
import ZakatAdd from "./components/zakatAddComponent";
import DonationHistory from "./components/donationHistoryComponent";
import DonationHistoryDetail from "./components/donationHistoryDetailComponent";
import ZakatHistory from "./components/zakatHistoryComponent";
import ZakatHistoryDetail from "./components/zakatHistoryDetailComponent";
import BankDKIRegistration from "./components/balanceComponent/bankDKIRegistrationComponent";
import ConfirmOtp from "./components/balanceComponent/confirmOtpComponent";
import InputPin from "./components/balanceComponent/inputPinComponent";
import PaymentMethod from "./components/paymentMethodComponent";
import ScanBarcode from "./components/paymentMethodComponent/ScanBarcodeComponent";
import InputNominal from "./components/paymentMethodComponent/inputNominalComponent";
import ScanBarcodePaymentSummary from "./components/paymentMethodComponent/paymentSummaryComponent";
import ScanBarcodeSummary from "./components/paymentMethodComponent/summaryComponent";
import RedirectPage from "./components/balanceComponent/redirectPage";
import Mutation from "./components/mutationComponent";
import FailedConnectAccount from "./components/balanceComponent/failedConnectAccountComponent";
import SavingList from "./components/balanceComponent/savingListComponent";
import SavingMutation from "./components/balanceComponent/savingMutation";
import NewsInformation from "./components/NewsInformationComponent/NewsInformation";
import NewsInformationDetail from "./components/NewsInformationDetailComponent/NewsInformationDetail";
import Marketplace from "./components/marketplaceComponents";
import ListProducts from "./components/marketplaceComponents/listProductsComponent";
import ProductDetail from "./components/marketplaceComponents/productDetailComponent";
import ListMerchants from "./components/marketplaceComponents/listMerchantsComponent";
import MerchantDetail from "./components/marketplaceComponents/merchantDetailComponent";
import Wishlist from "./components/marketplaceComponents/wishlistComponent";
import Cart from "./components/marketplaceComponents/cartComponent";
import CreateOrder from "./components/marketplaceComponents/createOrderComponent";
import MarketplaceSummary from "./components/marketplaceComponents/summaryComponent";
import CommerceOrderHistories from "./components/marketplaceComponents/commerceOrderHistoriesComponent";
import CommerceOrderDetail from "./components/marketplaceComponents/commerceOrderDetailComponent";
import Siakad from "./components/siakadComponent";
import LessonTimetable from "./components/siakadComponent/lessonTimetableComponent";
import LessonTimetableDetail from "./components/siakadComponent/lessonTimetableDetailComponent";
import Features from "./components/featuresComponent";
import FeaturesAbsen from "./components/absenFeatureComponent";
import FeaturesAbsenDetail from "./components/absenFeatureDetailComponent";
import Grade from "./components/siakadComponent/gradeComponent";
import Attendance from "./components/siakadComponent/attendanceComponent";
import ERaport from "./components/siakadComponent/eRaportComponent";
import ERaportDetail from "./components/siakadComponent/eRaportDetailComponent";
import AttendanceDetail from "./components/siakadComponent/attendanceDetailComponent";
import ScanQRComponent from "./components/featureScanQRComponent";
import ScanBarcodeComponent from "./components/featureScanBarcodeComponent";
import Access from "./components/accessFeatureComponent";
import AccessDetail from "./components/accessFeatureDetailComponent";
import ScanQRAccess from "./components/featureScanQRAccessComponent";
import ScanBarcodeAccess from "./components/featureScanBarcodeAccessComponent";
import PPOBHistory from "./components/ppobHistoryComponent";
import ProfileRoutes from "./routes/ProfileRoutes";
import PPOBHistoryDetail from "./components/ppobHistoryDetailComponent";
import Notification from "./components/notificationComponent";
import OnlineQueue from "./components/onlineQueueComponent";
import SelectQueue from "./components/onlineQueueComponent/selectQueueComponent";
import QueueNumber from "./components/onlineQueueComponent/queueNumberComponent";
import Branch from "./components/onlineQueueComponent/branchComponent";
import Location from "./components/onlineQueueComponent/locationComponent";
import GuideComponent from "./components/onlineQueueComponent/guideComponent";
import HistoryComponent from "./components/onlineQueueComponent/historyComponent";
import HistoryDetail from "./components/onlineQueueComponent/historyDetailComponent";
import Member from "./components/onlineQueueComponent/memberComponent";
import ViewQueue from "./components/onlineQueueComponent/viewQueueComponent";
import SuccessCheckin from "./components/onlineQueueComponent/successCheckinComponent";
import ProductList from "./components/fnbComponent/productListComponent";
import ParkingRoutes from "./routes/ParkingRoutes";
import CartFnb from "./components/fnbComponent/cartComponent";
import SummaryFnb from "./components/fnbComponent/orderSummaryComponent";
import OrderFnb from "./components/fnbComponent/pesananComponent";
import OrderDetailFnb from "./components/fnbComponent/pesananDetailComponent";
import ViewBarcode from "./components/paymentMethodComponent/viewBarcodeComponent";
import Cash from "./components/fnbComponent/cashComponent";
import CashDetail from "./components/fnbComponent/cashDetailComponent";
import SelectBank from "./components/topupComponent/selectBankComponent";
import Authorize from "./components/authorize";
import AbsenResult from "./components/absenResultComponent";
import PayGateFeatures from "./components/payGateFeatureComponent";
import PayGateFeaturesDetail from "./components/payGateDetailComponent";
import FeatureScanBarcodePayGate from "./components/featureScanBarcodePayGateComponent";
import PayGateResult from "./components/payGateResultComponent";
import TripAndTour from "./components/tripAndTourComponent";
import TripAndTourDetail from "./components/tripAndTourDetailComponent";
import TripAndTourPayment from "./components/tripAndTourPaymentComponent";
import TripAndTourHistoryDetail from "./components/tripAndTourHistoryDetailComponent";
import TripAndTourHistory from "./components/tripAndTourHistoryComponent/index";
import TripAndTourBarcode from "./components/tripAndTourBarcodeComponent";
import Autologin from "./components/autologin";
import TripAndTourMerchantDetail from "./components/tripAndTourMerchantDetailComponent";
import AuthorizeIPB from "./components/authorize_ipb";
import DonationDetailGeneral from "./components/donationDetailGeneralComponent";
import DonationGeneralAdd from "./components/donationDetailGeneralComponent/donationGeneralAddComponent";
import DonationGeneralTransaction from "./components/donationDetailGeneralComponent/donationGeneralTransactionComponent";
import Checkpath from "./components/_common/checkpath";
import MutationDetail from "./components/mutationDetailComponent";
import MultiLanguage from "./components/multiLanguage";
import RedirectToken from "./components/redirect_token";
import Provider from "./components/balanceComponent/providerComponent";
import SecureRedirect from "./components/secure_redirect";
import GatewayAuthorizationMember from "./components/gateway_authorization/member";
import Rent from "./components/rentComponent";
import RentDetail from "./components/rentComponent/rentDetailComponent";
import RentBooking from "./components/rentComponent/rentBookingComponent";
import RentPayment from "./components/rentComponent/rentPaymentComponent";
import RentResult from "./components/rentComponent/rentResultComponent";

const Routes: any = [
  {
    path: "companies",
    component: ChangeCompany,
  },
  {
    path: "authorize",
    component: Authorize,
  },
  {
    path: "redirect_token",
    component: RedirectToken,
  },
  {
    path: "authorize_ipb/:companyId/:token/:nomorId",
    component: AuthorizeIPB,
  },
  {
    path: "secure_redirect",
    component: SecureRedirect,
  },
  {
    path: "gateway_authorization_member/:cid",
    component: GatewayAuthorizationMember,
  },
  {
    path: "/",
    component: Checkpath,
  },
  {
    path: ":cid/",
    component: Home,
  },
  {
    path: ":cid/login",
    component: Login,
  },
  {
    path: ":cid/login/forget-password",
    component: InputEmail,
  },
  {
    path: ":cid/login/forget-password/change",
    component: ForgetPassword,
  },
  {
    path: ":cid/self-register",
    component: SelfRegister,
  },
  {
    path: ":cid/create-password",
    component: CreatePassword,
  },
  {
    path: ":cid/create-security-code",
    component: CreateSecurityCode,
  },
  {
    path: ":cid/topup",
    component: Topup,
  },
  {
    path: ":cid/topup/select-bank",
    component: SelectBank,
  },
  {
    path: ":cid/topup/detail",
    component: TopUpDetail,
  },
  {
    path: ":cid/invoices",
    component: Invoice,
  },
  {
    path: ":cid/invoices/send",
    component: InvoiceCreate,
  },
  {
    path: ":cid/invoices/:id",
    component: InvoiceDetail,
  },
  {
    path: ":cid/invoices/payment/:id",
    component: InvoicePayment,
  },
  {
    path: ":cid/invoices/payment/success/:id",
    component: InvoiceSuccessPayment,
  },
  {
    path: ":cid/profile",
    component: Profile,
  },
  {
    path: ":cid/balance",
    component: Balance,
  },
  {
    path: ":cid/balance/connect-account",
    component: ConnectAccount,
  },
  {
    path: ":cid/balance/connect-account/input-number",
    component: InputNumber,
  },
  {
    path: ":cid/balance/connect-account/input-number/redirect",
    component: RedirectPage,
  },
  {
    path: ":cid/balance/connect-account/input-otp",
    component: ConfirmOtp,
  },
  {
    path: ":cid/balance/connect-account/input-pin",
    component: InputPin,
  },
  {
    path: ":cid/balance/connect-account/success",
    component: SuccessConnectAccount,
  },
  {
    path: ":cid/balance/connect-account/failed",
    component: FailedConnectAccount,
  },
  {
    path: ":cid/balance/connect-account/bank-dki-registration",
    component: BankDKIRegistration,
  },
  {
    path: ":cid/balance/mutation",
    component: Mutation,
  },
  {
    path: ":cid/balance/mutation/detail",
    component: MutationDetail,
  },
  {
    path: ":cid/balance/savings",
    component: SavingList,
  },
  {
    path: ":cid/balance/savingMutation",
    component: SavingMutation,
  },
  {
    path: ":cid/transfer",
    component: Transfer,
  },
  {
    path: ":cid/transfer/member",
    component: TransferMemberData,
  },
  {
    path: ":cid/transfer/member/summary",
    component: TransferMemberSummary,
  },
  {
    path: ":cid/transfer/member/summary/success",
    component: TransferMemberPaymentSummary,
  },
  {
    path: ":cid/transfer/bank",
    component: GetAllBankAccount,
  },
  {
    path: ":cid/transfer/bank/add-bank-account",
    component: AddBankAccount,
  },
  {
    path: ":cid/transfer/bank/input-data",
    component: TransferBankData,
  },
  {
    path: ":cid/transfer/bank/input-data/summary",
    component: TransferBankSummary,
  },
  {
    path: ":cid/transfer/bank/input-data/summary/success",
    component: TransferBankSuccess,
  },
  {
    path: ":cid/barcode",
    component: PaymentMethod,
  },
  {
    path: ":cid/barcode/create",
    component: ViewBarcode,
  },
  {
    path: ":cid/barcode/create-barcode",
    component: CreateBarcode,
  },
  {
    path: ":cid/barcode/scan-barcode",
    component: ScanBarcode,
  },
  {
    path: ":cid/barcode/input-nominal",
    component: InputNominal,
  },
  {
    path: ":cid/barcode/payment-summary",
    component: ScanBarcodePaymentSummary,
  },
  {
    path: ":cid/barcode/summary",
    component: ScanBarcodeSummary,
  },
  {
    path: ":cid/digital-product",
    component: PPOB,
  },
  {
    path: ":cid/digital-product/history",
    component: PPOBHistory,
  },
  {
    path: ":cid/digital-product/history/:id",
    component: PPOBHistoryDetail,
  },
  {
    path: ":cid/digital-product/pulsa-data",
    component: PPOBPulsaData,
  },
  {
    path: ":cid/digital-product/pulsa-data/prabayar",
    component: PPOBPrabayar,
  },
  {
    path: ":cid/digital-product/pulsa-data/prabayar/summary",
    component: PPOBPrabayarSummary,
  },
  {
    path: ":cid/digital-product/pulsa-data/prabayar/success",
    component: PPOBPrabayarSuccess,
  },
  {
    path: ":cid/digital-product/pulsa-data/pascabayar",
    component: PPOBPascabayar,
  },
  {
    path: ":cid/digital-product/pulsa-data/pascabayar/summary",
    component: PPOBPascabayarSummary,
  },
  {
    path: ":cid/digital-product/pulsa-data/pascabayar/success",
    component: PPOBPascabayarSuccess,
  },
  {
    path: ":cid/digital-product/pln",
    component: PPOBPLN,
  },
  {
    path: ":cid/digital-product/pln/summary",
    component: PPOBPLNSummary,
  },
  {
    path: ":cid/digital-product/pln/success",
    component: PPOBPLNSuccess,
  },
  {
    path: ":cid/digital-product/pdam",
    component: PPOBAirPDAM,
  },
  {
    path: ":cid/digital-product/pdam/summary",
    component: PPOBAirPDAMSummary,
  },
  {
    path: ":cid/digital-product/pdam/success",
    component: PPOBAirPDAMSuccess,
  },
  {
    path: ":cid/digital-product/bpjs",
    component: PPOBBPJS,
  },
  {
    path: ":cid/digital-product/bpjs/summary",
    component: PPOBBPJSSummary,
  },
  {
    path: ":cid/digital-product/bpjs/success",
    component: PPOBBPJSSuccess,
  },
  {
    path: ":cid/digital-product/internet-tv",
    component: PPOBInternetTV,
  },
  {
    path: ":cid/digital-product/internet-tv/summary",
    component: PPOBInternetTVSummary,
  },
  {
    path: ":cid/digital-product/internet-tv/success",
    component: PPOBInternetTVSuccess,
  },
  {
    path: ":cid/digital-product/pbb",
    component: PPOBPajakPBB,
  },
  {
    path: ":cid/digital-product/pbb/summary",
    component: PPOBPajakPBBSummary,
  },
  {
    path: ":cid/digital-product/pbb/success",
    component: PPOBPajakPBBSuccess,
  },
  {
    path: ":cid/profile/edit",
    component: EditProfile,
  },
  {
    path: ":cid/profile/change-password",
    component: ChangePassword,
  },
  {
    path: ":cid/termofservice",
    component: TermOfService,
  },
  {
    path: ":cid/settingprofile",
    component: SettingProfile,
  },
  {
    path: ":cid/profile/security-code",
    component: SecurityCode,
  },
  {
    path: ":cid/profile/security-code/change",
    component: ChangeSecurityCode,
  },
  {
    path: ":cid/profile/security-code/forget",
    component: VerifikasiEmailOTP,
  },
  {
    path: ":cid/profile/security-code/forget/change",
    component: ForgetSecurityCode,
  },
  {
    path: ":cid/profile/language",
    component: MultiLanguage,
  },
  {
    path: ":cid/veririkasiemailotp",
    component: VerifikasiEmailOTP,
  },
  {
    path: ":cid/virtual-card/list",
    component: ListVirtualCard,
  },
  {
    path: ":cid/virtual-card/list/:id",
    component: VirtualCardDetail,
  },
  {
    path: ":cid/virtual-card/list/:id/topup",
    component: TopUpCardBalance,
  },
  {
    path: ":cid/virtual-card/list/:id/topup/summary",
    component: TopUpCardBalanceSummary,
  },
  {
    path: ":cid/virtual-card/list/:id/topup/summary/detail/:transactionId",
    component: TopUpCardBalancePaymentSummary,
  },
  {
    path: ":cid/virtual-card/list/:id/withdraw",
    component: WithdrawCardBalance,
  },
  {
    path: ":cid/virtual-card/list/:id/withdraw/summary",
    component: WithdrawCardSummary,
  },
  {
    path: ":cid/virtual-card/list/:id/withdraw/summary/detail/:transactionId",
    component: WithdrawCardPaymentSummary,
  },
  {
    path: ":cid/virtual-card/list/:id/history",
    component: VirtualCardHistory,
  },
  {
    path: ":cid/virtual-card/list/:id/mutation",
    component: VirtualCardMutation,
  },
  {
    path: ":cid/virtual-card/list/:id/mutation/:transactionType/:transactionId",
    component: TransactionDetail,
  },
  {
    path: ":cid/virtual-card/list/:id/card-security-code",
    component: CardSecurityCode,
  },
  {
    path: ":cid/virtual-card/list/:id/card-security-code/activate",
    component: ActivateCardSecurityCode,
  },
  {
    path: ":cid/virtual-card/list/:id/card-security-code/change",
    component: ChangeCardSecurityCode,
  },
  {
    path: ":cid/virtual-card/list/:id/card-security-code/inactivate",
    component: InactivateCardSecurityCode,
  },
  {
    path: ":cid/virtual-card/list/:id/card-security-code/verification",
    component: EmailVerification,
  },
  {
    path: ":cid/virtual-card/list/:id/card-security-code/verification/reset-pin",
    component: ForgetCardSecurityCode,
  },
  {
    path: ":cid/virtual-card/add",
    component: AddVirtualCard,
  },
  {
    path: ":cid/virtual-card/add/summary",
    component: VirtualCardSummary,
  },
  {
    path: ":cid/virtual-card/add/summary/payment-summary/:id",
    component: PaymentSummary,
  },
  {
    path: ":cid/donations",
    component: DonationHome,
  },
  {
    path: ":cid/donations/donation",
    component: Donation,
  },
  {
    path: ":cid/donations/donation/detail/:id",
    component: DonationDetail,
  },
  {
    path: ":cid/donations/donation/transaction/:id",
    component: DonationTransaction,
  },
  {
    path: ":cid/donations/donation/progress/:id",
    component: DonationProgress,
  },
  {
    path: ":cid/donations/donation/disbursement/:id",
    component: DonationDisbursement,
  },
  {
    path: ":cid/donations/donation/donation/:id",
    component: DonationAdd,
  },
  {
    path: ":cid/donations/donation/history/:id",
    component: DonationHistoryDetail,
  },
  {
    path: ":cid/donations/donation/history",
    component: DonationHistory,
  },
  {
    path: ":cid/donations/zakat",
    component: Zakat,
  },
  {
    path: ":cid/donations/zakat/detail/:id",
    component: ZakatDetail,
  },
  {
    path: ":cid/donations/zakat/progress/:id",
    component: ZakatProgress,
  },
  {
    path: ":cid/donations/zakat/transaction/:id",
    component: ZakatTransaction,
  },
  {
    path: ":cid/donations/zakat/disbursement/:id",
    component: ZakatDisbursement,
  },
  {
    path: ":cid/donations/zakat/zakat/:id",
    component: ZakatAdd,
  },
  {
    path: ":cid/donations/zakat/history",
    component: ZakatHistory,
  },
  {
    path: ":cid/donations/zakat/history/:id",
    component: ZakatHistoryDetail,
  },
  {
    path: ":cid/news-information",
    component: NewsInformation,
  },
  {
    path: ":cid/news-information/detail",
    component: NewsInformationDetail,
  },
  {
    path: ":cid/commerce",
    component: Marketplace,
  },
  {
    path: ":cid/commerce/products",
    component: ListProducts,
  },
  {
    path: ":cid/commerce/products/:id",
    component: ListProducts,
  },
  {
    path: ":cid/commerce/history",
    component: CommerceOrderHistories,
  },
  {
    path: ":cid/commerce/history/detail/:id",
    component: CommerceOrderDetail,
  },
  {
    path: ":cid/commerce/products/detail/:id",
    component: ProductDetail,
  },
  {
    path: ":cid/commerce/merchants",
    component: ListMerchants,
  },
  {
    path: ":cid/commerce/merchants/:id",
    component: MerchantDetail,
  },
  {
    path: ":cid/commerce/wishlist",
    component: Wishlist,
  },
  {
    path: ":cid/commerce/cart",
    component: Cart,
  },
  {
    path: ":cid/commerce/create-order",
    component: CreateOrder,
  },
  {
    path: ":cid/commerce/create-order/summary",
    component: MarketplaceSummary,
  },
  // SIakad
  {
    path: ":cid/siakad",
    component: Siakad,
  },
  {
    path: ":cid/siakad/lesson-timetable",
    component: LessonTimetable,
  },
  {
    path: ":cid/siakad/lesson-timetable/detail",
    component: LessonTimetableDetail,
  },
  {
    path: ":cid/siakad/grade",
    component: Grade,
  },
  {
    path: ":cid/siakad/attendance",
    component: Attendance,
  },
  {
    path: ":cid/siakad/attendance/detail",
    component: AttendanceDetail,
  },
  {
    path: ":cid/siakad/eraport",
    component: ERaport,
  },
  {
    path: ":cid/siakad/eraport/detail",
    component: ERaportDetail,
  },
  {
    path: ":cid/siakad/grade",
    component: Grade,
  },
  {
    path: ":cid/siakad/attendance",
    component: Attendance,
  },
  {
    path: ":cid/siakad/attendance/detail",
    component: AttendanceDetail,
  },
  {
    path: ":cid/siakad/eraport",
    component: ERaport,
  },
  {
    path: ":cid/siakad/eraport/detail",
    component: ERaportDetail,
  },
  //Features
  {
    path: ":cid/features",
    component: Features,
  },
  {
    path: ":cid/features/absen",
    component: FeaturesAbsen,
  },
  {
    path: ":cid/features/absen/detail",
    component: FeaturesAbsenDetail,
  },
  {
    path: ":cid/features/absen/scan-qr",
    component: ScanQRComponent,
  },
  {
    path: ":cid/features/absen/barcode",
    component: ScanBarcodeComponent,
  },
  {
    path: ":cid/features/absen/barcode/result",
    component: AbsenResult,
  },
  {
    path: ":cid/features/access",
    component: Access,
  },
  {
    path: ":cid/features/access/detail",
    component: AccessDetail,
  },
  {
    path: ":cid/features/access/scan-qr",
    component: ScanQRAccess,
  },
  {
    path: ":cid/features/access/barcode",
    component: ScanBarcodeAccess,
  },
  ...ProfileRoutes,
  ...ParkingRoutes,
  {
    path: ":cid/notification",
    component: Notification,
  },
  {
    path: ":cid/online-service",
    component: OnlineQueue,
  },
  {
    path: ":cid/online-service/select",
    component: SelectQueue,
  },
  {
    path: ":cid/online-service/select/queue-number",
    component: QueueNumber,
  },
  {
    path: ":cid/online-service/select/queue-number/success-checkin",
    component: SuccessCheckin,
  },
  {
    path: ":cid/online-service/branch",
    component: Branch,
  },
  {
    path: ":cid/online-service/branch/location",
    component: Location,
  },
  {
    path: ":cid/online-service/guide",
    component: GuideComponent,
  },
  {
    path: ":cid/online-service/history",
    component: HistoryComponent,
  },
  {
    path: ":cid/online-service/history/detail",
    component: HistoryDetail,
  },
  {
    path: ":cid/online-service/members",
    component: Member,
  },
  {
    path: ":cid/online-service/view",
    component: ViewQueue,
  },
  // FNB
  {
    path: ":cid/merchant_catalog_product/:merchantId",
    component: ProductList,
  },
  {
    path: ":cid/merchant_catalog_product/:merchantId/cart",
    component: CartFnb,
  },
  {
    path: ":cid/merchant_catalog_product/:merchantId/cart/:transactionId",
    component: SummaryFnb,
  },
  {
    path: ":cid/merchant_catalog_product/:merchantId/cart/:transactionId/order",
    component: OrderFnb,
  },
  {
    path: ":cid/merchant_catalog_product/:merchantId/cart/:transactionId/order/detail",
    component: OrderDetailFnb,
  },
  {
    path: ":cid/merchant_catalog_product/:merchantId/cart/:transactionId/cash",
    component: Cash,
  },
  {
    path: ":cid/merchant_catalog_product/:merchantId/cart/:transactionId/cash/detail",
    component: CashDetail,
  },
  {
    path: ":cid/features/pay-gate",
    component: PayGateFeatures,
  },
  {
    path: ":cid/features/pay-gate/detail",
    component: PayGateFeaturesDetail,
  },
  {
    path: ":cid/features/pay-gate/barcode",
    component: FeatureScanBarcodePayGate,
  },
  {
    path: ":cid/features/pay-gate/barcode/result",
    component: PayGateResult,
  },
  {
    path: ":cid/trip-tour",
    component: TripAndTour,
  },
  {
    path: ":cid/trip-tour/:id",
    component: TripAndTourDetail,
  },
  {
    path: ":cid/trip-tour/payment",
    component: TripAndTourPayment,
  },
  {
    path: ":cid/trip-tour/history",
    component: TripAndTourHistory,
  },
  {
    path: ":cid/trip-tour/history/:id",
    component: TripAndTourHistoryDetail,
  },
  {
    path: ":cid/trip-tour/history/barcode",
    component: TripAndTourBarcode,
  },
  {
    path: ":cid/trip-tour/merchant/:id",
    component: TripAndTourMerchantDetail,
  },
  {
    path: ":cid/autologin/:username/:password",
    component: Autologin,
  },
  {
    path: ":cid/donation-general/detail",
    component: DonationDetailGeneral,
  },
  {
    path: ":cid/donation-general/detail/progress",
    component: DonationProgress,
  },
  {
    path: ":cid/donation-general/detail/disbursement",
    component: DonationDisbursement,
  },
  {
    path: ":cid/donation-general/detail/add",
    component: DonationGeneralAdd,
  },
  {
    path: ":cid/donation-general/detail/transaction",
    component: DonationGeneralTransaction,
  },
  {
    path: ":cid/balance/providers",
    component: Provider,
  },
  {
    path: ":cid/rent",
    component: Rent,
  },
  {
    path: ":cid/rent/:id",
    component: RentDetail,
  },
  {
    path: ":cid/rent/booking",
    component: RentBooking,
  },
  {
    path: ":cid/rent/payment",
    component: RentPayment,
  },
  {
    path: ":cid/rent/result",
    component: RentResult,
  },
];
export default Routes;
