import React from "react";
import desktopBackground from "../../assets/images/mobileBgBlur.svg";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-phone-number-input/style.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import { onlyNumber } from "../../config/global";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useTranslation } from "react-i18next";

const SelfRegisterWeb = (props: any) => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(false);

  return (
    <section
      className="h-full min-h-screen flex flex-col items-center justify-center bg-second dark:bg-black bg-cover bg-no-repeat font-montserrat"
      style={{ backgroundImage: `url(${desktopBackground})` }}
    >

      <div className="container p-5">
        <div className="lg:w-1/2 sm:w-3/4 md:w-3/4 m-auto">
          <h1 className="font-semibold md:text-lg text-base font-montserrat">
            {t('register.title')}
          </h1>
          <h1 className="md:text-sm text-xs text-thirdy font-montserrat">
            {t('register.description')}
          </h1>
          <div className="flex flex-col gap-5 justify-center items-center">
            <div className="col-span-2 relative h-100 rounded-xl flex flex-col w-full">
              <div>
                {props.metadata.map((res: any) => {
                  if (res.data.typeData === undefined) {
                    if (res.data.key === "phone") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          {/* <PhoneInput
                            placeholder="Masukan nomor telepon"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="ID"
                            className="bg-white text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                            value={phone}
                            onChange={(e: any) => {
                              changeValue(e && formatPhoneNumber(e), res.data.key, res.data.tag);
                            }}
                          /> */}
                          <input
                            type="tel"
                            onChange={(e) =>
                              props.changeValue(
                                e.target.value,
                                res.data.key,
                                res.data.tag
                              )
                            }
                            onKeyPress={onlyNumber}
                            value={
                              res.data.tag === "BasicData"
                                ? props.dataOrder[res.data.key]
                                : props.dataOrder.additionalData[res.data.key]
                            }
                            className="text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    } else if (res.data.key === "dateOfBirth") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <DatePicker
                            selected={props.customDate[res.data.key]}
                            dateFormat="dd MMMM yyyy"
                            onChange={(e: any) => {
                              props.changeValue(
                                moment(e).format("YYYY-MM-DD"),
                                res.data.key,
                                res.data.tag
                              );
                              props.setCustomDate({
                                ...props.customDate,
                                [res.data.key]: e,
                              });
                              console.log(e);
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            className="text-sm w-full font-normal rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                            maxDate={new Date()}
                            dropdownMode="select"
                          />
                        </div>
                      );
                    } else if (res.data.key === "gender") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3 select-self-register"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <Select
                            options={props.genderList}
                            value={props.selectedData[res.data.key]}
                            onChange={(e) => {
                              props.changeValue(e.value, res.data.key, res.data.tag);
                              props.changeSelectedData(e, res.data.key);
                            }}
                            className="rounded-[30px]"
                          />
                        </div>
                      );
                    } else if (res.data.key === "tipeNik") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3 select-self-register"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <Select
                            options={props.identityType}
                            value={props.selectedData[res.data.key]}
                            onChange={(e) => {
                              props.changeValue(e.value, res.data.key, res.data.tag);
                              props.changeSelectedData(e, res.data.key);
                            }}
                            className="rounded-[30px]"
                          />
                        </div>
                      );
                    } else if (res.data.key === "lang") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3 select-self-register"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <Select
                            options={props.languageList}
                            value={props.selectedData[res.data.key]}
                            onChange={(e) => {
                              props.changeValue(e.value, res.data.key, res.data.tag);
                              props.changeSelectedData(e, res.data.key);
                            }}
                            className="rounded-[30px]"
                          />
                        </div>
                      );
                    } else if (res.data.key === "password") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3 relative"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <input
                            type={visible ? "text" : "password"}
                            onChange={(e) =>
                              props.changeValue(
                                e.target.value,
                                res.data.key,
                                res.data.tag
                              )
                            }
                            className="text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4] pr-[43px]"
                          />
                          <div
                            className="absolute top-[35px] right-[17px] cursor-pointer"
                            onClick={() => setVisible(!visible)}
                          >
                            {visible ? (
                              <FontAwesomeIcon
                                icon={faEye}
                                size="lg"
                                color="#C4C4C4"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                size="lg"
                                color="#C4C4C4"
                              />
                            )}
                          </div>
                        </div>
                      );
                    } else if (res.data.key === "username") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3 cek"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <input
                            type={"text"}
                            value={
                              res.data.tag === "BasicData"
                                ? props.dataOrder[res.data.key]
                                : props.dataOrder.additionalData[res.data.key]
                            }
                            onChange={(e) =>
                              props.changeValue(
                                e.target.value,
                                res.data.key,
                                res.data.tag
                              )
                            }
                            className="text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    } else if (res.data.key === "tags") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3 cek"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            name="colors"
                            options={props.tagList}
                            onChange={(e) => {
                              let tempTags: any = [];
                              e.map((el: any) => tempTags.push(el.value));
                              props.changeValue(tempTags, res.data.key, res.data.tag);
                            }}
                            className="basic-multi-select tags-select"
                            classNamePrefix="select"
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3 cek"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <input
                            type={"text"}
                            value={
                              res.data.tag === "BasicData"
                                ? props.dataOrder[res.data.key]
                                : props.dataOrder.additionalData[res.data.key]
                            }
                            onChange={(e) =>
                              props.changeValue(
                                e.target.value,
                                res.data.key,
                                res.data.tag
                              )
                            }
                            className="text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    }
                  } else {
                    if (res.data.typeData === "Enum") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3 select-self-register"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <Select
                            options={props.extraDatas[res.data.key]}
                            value={props.selectedData[res.data.key]}
                            onChange={(e) => {
                              props.changeValue(
                                e.value,
                                res.data.key,
                                res.data.typeData
                              );
                              props.changeSelectedData(e, res.data.key);
                            }}
                            className="rounded-[30px]"
                          />
                        </div>
                      );
                    } else if (res.data.typeData === "Flags") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}{" "}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <div className="grid grid-cols-2 gap-2">
                            {res.data.extraData.map(
                              (flag: any, index: string) => (
                                <div className="flex flex-row gap-2">
                                  <input
                                    type="checkbox"
                                    id={res.data.key + index}
                                    name={res.data.key}
                                    value={res.data.key}
                                    onChange={() =>
                                      props.changeValue(
                                        flag,
                                        res.data.key,
                                        res.data.tag,
                                        res.data.typeData
                                      )
                                    }
                                    className="rounded-[30px]"
                                  />
                                  <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                                    {flag}
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      );
                    } else if (res.data.typeData === "Date") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <DatePicker
                            selected={props.customDate[res.data.key]}
                            dateFormat="dd MMMM yyyy"
                            onChange={(e: any) => {
                              props.changeValue(
                                moment(e).format("YYYY-MM-DD"),
                                res.data.key,
                                res.data.tag,
                                res.data.typeData
                              );
                              props.setCustomDate({
                                ...props.customDate,
                                [res.data.key]: e,
                              });
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            className="text-sm w-full font-normal rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                            maxDate={new Date()}
                            dropdownMode="select"
                          />
                        </div>
                      );
                    } else if (res.data.typeData === "Datetime") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <DatePicker
                            selected={props.customDate[res.data.key]}
                            onChange={(e: any) => {
                              props.changeValue(
                                moment(e).format("YYYY-MM-DD hh:mm:ss"),
                                res.data.key,
                                res.data.tag,
                                res.data.typeData
                              );
                              props.setCustomDate({
                                ...props.customDate,
                                [res.data.key]: e,
                              });
                            }}
                            showTimeSelect
                            filterTime={props.filterPassedTime}
                            dateFormat="dd MMMM yyyy, hh:mm:ss"
                            className="text-sm w-full font-normal rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    } else if (res.data.typeData === "Time") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="time"
                            step="2"
                            value={
                              res.data.tag === "BasicData"
                                ? props.dataOrder[res.data.key]
                                : props.dataOrder.additionalData[res.data.key]
                            }
                            onChange={(e) => {
                              props.changeValue(
                                e.target.value,
                                res.data.key,
                                res.data.tag
                              );
                            }}
                            className="text-sm w-full font-normal rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    } else if (res.data.typeData === "Email") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="email"
                            onChange={(e) =>
                              props.changeValue(
                                e.target.value,
                                res.data.key,
                                res.data.tag
                              )
                            }
                            className="text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    } else if (res.data.typeData === "Integer") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="number"
                            onKeyPress={onlyNumber}
                            onChange={(e) => {
                              if (
                                e.target.value.length > 1 &&
                                e.target.value[0] === "0"
                              ) {
                                e.target.value = e.target.value.slice(1);
                                props.changeValue(
                                  e.target.value,
                                  res.data.key,
                                  res.data.tag
                                );
                                console.log(e.target.value);
                              } else {
                                props.changeValue(
                                  e.target.value,
                                  res.data.key,
                                  res.data.tag
                                );
                              }
                            }}
                            className="text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    } else if (res.data.typeData === "Numeric") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="number"
                            onChange={(e) =>
                              props.changeValue(
                                e.target.value,
                                res.data.key,
                                res.data.tag
                              )
                            }
                            onKeyPress={onlyNumber}
                            className="text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    } else if (res.data.typeData === "Phone") {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>

                          <input
                            type="tel"
                            onChange={(e) =>
                              props.changeValue(
                                e.target.value,
                                res.data.key,
                                res.data.tag
                              )
                            }
                            onKeyPress={onlyNumber}
                            value={
                              res.data.tag === "BasicData"
                                ? props.dataOrder[res.data.key]
                                : props.dataOrder.additionalData[res.data.key]
                            }
                            className="text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="flex flex-col gap-1 mt-3"
                          hidden={!res.data.isVisible}
                        >
                          <label className="md:text-sm text-xs text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {res.data.display}
                            <span
                              hidden={!res.data.isRequired}
                              className="text-red text-sm ml-0.5"
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            onChange={(e) =>
                              props.changeValue(
                                e.target.value,
                                res.data.key,
                                res.data.tag
                              )
                            }
                            value={
                              res.data.tag === "BasicData"
                                ? props.dataOrder[res.data.key]
                                : props.dataOrder.additionalData[res.data.key]
                            }
                            className="text-sm font-normal focus:border-1 rounded-[30px] p-[12px] focus:outline outline-[1px] outline-[#80CBC4]"
                          />
                        </div>
                      );
                    }
                  }
                })}
                <button
                  onClick={() => props.confirm()}
                  className="bg-green mt-4 text-white font-semibold text-base rounded-[30px] w-full p-[12px] font-montserrat"
                >
                  {t('home.buttonRegister')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SelfRegisterWeb;
