/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CartLogo from "../../../assets/images/white_cart.svg";
import NoImage from "../../../assets/images/empty.jpg";
import { BottomSheet } from "react-spring-bottom-sheet";
import toast from "react-hot-toast";
import ToastConfig from "../../../config/Toast";
import { formatCurrency, onlyNumber } from "../../../config/global";
import Carousel from "react-multi-carousel";
import Plus from "../../../assets/images/plus.svg";
import Minus from "../../../assets/images/minus.svg";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const ProductListMobile = (props: any) => {
  return (
    <section className="realtive h-full min-h-screen font-montserrat bg-mobile flex flex-col">
      <div
        className={`${
          props.open || props.showDetail ? "" : "z-20"
        } fixed w-full flex-none`}
      >
        <div className="bg-white p-3 flex flex-col items-center justify-center mb-3">
          <div className="flex items-center gap-2 mb-2">
            <img src={props.company?.companyLogo} alt="" className="w-6" />
            <h6 className="text-green mb-0">{props.company?.name}</h6>
          </div>
          <h5 className="text-2xl font-semibold text-customBlack mb-0">
            {props.merchantName}
          </h5>
          <div className="relative flex items-center text-[#D9D9D9] md:w-4/5 w-full mt-2 px-4 mx-auto">
            <button
              type="button"
              onClick={() => {
                props.setSelectedCategory({});
                props.getCatalog(props.company?.id, "", props.keyword);
              }}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color="#728F9E"
                className="absolute ml-3 top-[11px]"
              />
            </button>
            <input
              type="text"
              value={props.keyword}
              onChange={(e) => props.setKeyword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.setSelectedCategory({});
                  props.getCatalog(props.company?.id, "", props.keyword);
                  let element = document.getElementById("search");
                  if (element !== null) element.blur();
                }
              }}
              placeholder="Cari makanan, minuman..."
              name="search"
              id="search"
              className="w-full text-[#CCCCCC] font-medium placeholder-[#CCCCCC] border-[#CCCCCC] border-1 rounded-[10px] focus:outline-2 focus:outline-gray-300 text-sm py-2 pr-3 pl-10"
            />
          </div>
        </div>
      </div>

      <div className="flex grow px-0 pt-[150px]">
        <div className="flex flex-col md:w-4/5 w-full mx-auto mb-5 px-4">
          <section id="categories" className="flex-none w-full mt-4">
            <div className="space-between mb-3">
              <h6 className="md:text-lg text-base text-customBlack text-medium mb-0">
                Kategori
              </h6>
              <button
                type="button"
                onClick={() => props.setOpen(true)}
                className="md:text-sm text-xs text-green font-medium no-underline"
              >
                Lihat Semua
              </button>
            </div>
            {props.categories.length > 0 ? (
              <Swiper
                freeMode={true}
                grabCursor={true}
                modules={[FreeMode]}
                slidesPerView={"auto"}
                spaceBetween={20}
                className="w-full relative justify-center mySwiper"
              >
                <SwiperSlide
                  onClick={() => {
                    props.setSelectedCategory({});
                    props.getCatalog(props.company?.id, "", props.keyword);
                  }}
                  className="swiper-slide-auto"
                >
                  <div
                    className={`${
                      props.selectedCategory?.id === undefined
                        ? "bg-green text-white"
                        : "border-[#E4E4E4] border-1 bg-white text-newColor"
                    } flex flex-row p-2 cursor-pointer rounded-full`}
                  >
                    <h6 className="md:text-base text-sm font-medium text-center m-1">
                      Semua
                    </h6>
                  </div>
                </SwiperSlide>
                {props.categories.map((item: any) => (
                  <SwiperSlide
                    onClick={() => {
                      props.setSelectedCategory(item);
                      props.getCatalog(
                        props.company?.id,
                        item.id,
                        props.keyword
                      );
                    }}
                    key={item.id}
                    className="swiper-slide-auto"
                  >
                    <div
                      className={`${
                        props.selectedCategory?.id === item.id
                          ? "bg-green text-white"
                          : "border-[#E4E4E4] border-1 bg-white text-newColor"
                      } flex flex-row p-2 cursor-pointer rounded-full`}
                    >
                      <h6 className="md:text-base text-sm font-medium text-center m-1">
                        {item.categoryName}
                      </h6>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <h6 className="md:text-lg text-base text-center text-[#CCCCCC] font-semibold mb-0">
                Data tidak ditemukan
              </h6>
            )}
          </section>
          <section id="products" className="grow relative w-full mt-2">
            <h6 className="md:text-lg text-base text-customBlack text-medium mb-3">
              Menu
            </h6>
            {props.data.length > 0 ? (
              <div className="grid md:grid-cols-3 grid-cols-2 gap-4">
                {props.data.map((item: any, index: number) => (
                  <div
                    className="flex flex-col align-items-center bg-white shadow-sm rounded-[8px] h-full overflow-hidden relative"
                    onClick={() => {
                      let readyStock = item.productItems.find(
                        (el: any) => el.stock > 0
                      );
                      if (
                        item.productItems.length !== 0 &&
                        readyStock !== undefined
                      ) {
                        props.getCatalogDetail(item.id);
                        props.setShowDetail(true);
                      }
                    }}
                    key={index}
                  >
                    <div className="relative w-full">
                      {item?.imageUrl?.length > 0 ? (
                        <img
                          src={
                            item?.imageUrl[0] === ""
                              ? NoImage
                              : item?.imageUrl[0]
                          }
                          alt=""
                          className="object-cover w-full select-none overflow-hidden"
                          style={{ height: 175 }}
                        />
                      ) : (
                        <img
                          src={NoImage}
                          alt=""
                          className="object-cover w-full select-none overflow-hidden"
                          style={{ height: 175 }}
                        />
                      )}

                      {item.productItems.length === 0 ||
                      item.productItems.find((el: any) => el.stock > 0) ===
                        undefined ? (
                        <div
                          className="bg-customBlack flex items-center justify-center left-[50%] rounded-full w-[70px] h-[70px] bg-opacity-60 absolute top-[50%] z-10"
                          style={{ transform: "translate(-50%, -30%)" }}
                        >
                          <span className="text-white md:text-sm text-xs font-medium text-center">
                            {item.productItems.length === 0
                              ? "Tidak Tersedia"
                              : "Habis"}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <div className="grid gap-1 grow w-full px-[10px] py-3">
                      <div>
                        <p className="text-customBlack md:text-sm text-xs font-medium mb-0">
                          {item?.productName}
                        </p>
                        <p className="text-newColor md:text-xs text-[10px] font-light mb-0">
                          {item?.categoryName}
                        </p>
                      </div>
                      {item?.productItems?.length > 0 &&
                        (item?.productItems?.length > 1 ? (
                          <h6 className="md:text-lg text-base font-semibold text-green mb-0">
                            {props.priceList[item?.id]?.minPrice ===
                            props.priceList[item?.id]?.maxPrice
                              ? formatCurrency(
                                  props.priceList[item?.id]?.minPrice
                                )
                              : `${formatCurrency(
                                  props.priceList[item?.id]?.minPrice
                                )} - ${formatCurrency(
                                  props.priceList[item?.id]?.maxPrice
                                )}`}
                          </h6>
                        ) : (
                          <h6 className="md:text-lg text-base font-semibold text-green mb-0 mt-2">
                            {formatCurrency(item?.productItems[0]?.finalPrice)}
                          </h6>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <h6
                className="absolute left-1/2 top-1/2 md:text-lg text-base text-center text-[#CCCCCC] font-semibold w-full mb-0"
                style={{ transform: "translate(-50%, -30%)" }}
              >
                Belum ada produk di sini
              </h6>
            )}
          </section>
        </div>
      </div>

      <div className="fixed bottom-[2%] right-[1.5rem] z-10">
        <button
          onClick={() => {
            if (props.quantity === 0) {
              toast.error(
                "Keranjang masih kosong, pilih barang anda terlebih dahulu!"
              );
            } else {
              props.navigate("cart");
            }
          }}
          className="bg-[#43A49B] rounded-[20px] p-2 gap-2 flex items-center justify-between"
        >
          <img src={CartLogo} alt="" />
          <span className="md:text-base text-sm font-semibold text-white">
            Keranjang
          </span>
          <div className="h-[17px] w-[17px] bg-white flex items-center rounded-full justify-center text-green md:text-sm text-xs font-semibold">
            {props.cartQuantity}
          </div>
        </button>
      </div>

      <BottomSheet
        open={props.open}
        onDismiss={() => {
          props.setOpen(false);
          props.setSelectedCategory({});
          props.getCatalog(props.company?.id);
        }}
      >
        <div className="z-50 font-montserrat">
          <div className="flex flex-col relative transform overflow-hidden rounded-lg bg-white transition-all p-[1.5rem]">
            <div className="flex flex-col min-h-full p-2 text-center sm:items-center sm:p-0">
              <h4 className="md:text-2xl text-left text-xl text-customBlack font-medium w-full">
                Kategori
              </h4>
              <div className="mt-1 mb-3 w-full">
                {props.categories.map((item: any, index: any) => (
                  <div className="flex items-center gap-3 mb-2" key={index}>
                    <h6 className="text-start md:text-lg text-base font-medium text-customBlack mb-0 w-full">
                      {item?.categoryName}
                    </h6>
                    <input
                      type="radio"
                      name="category"
                      id="category"
                      value=""
                      onChange={() => {
                        props.setSelectedCategory(item);
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="flex items-center gap-3 w-full">
                <button
                  type="button"
                  onClick={() => {
                    props.setOpen(false);
                    props.setSelectedCategory({});
                    props.getCatalog(props.company?.id);
                  }}
                  className="bg-white border-[0.5px] border-[#70717D] text-[#70717D] md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                >
                  Batal
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (props.selectedCategory?.id !== undefined) {
                      let tempCategories = props.categories;
                      let category = tempCategories.findIndex(
                        (el: any) => el.id === props.selectedCategory.id
                      );
                      let element = tempCategories[category];
                      tempCategories.splice(category, 1);
                      tempCategories.splice(0, 0, element);
                      props.getCatalog(
                        props.company?.id,
                        props.selectedCategory.id,
                        props.keyword
                      );
                      props.setOpen(false);
                    } else {
                      toast("Silakan pilih kategori", ToastConfig.warning);
                    }
                  }}
                  className="bg-green text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                >
                  Terapkan
                </button>
              </div>
            </div>
          </div>
        </div>
      </BottomSheet>

      {/* PRODUCT DETAIL */}
      <BottomSheet
        open={props.showDetail}
        onDismiss={() => {
          props.setShowDetail(false);
          props.setSelectedCategory({});
          props.getCatalog(props.company?.id);
          props.setQuantity(1);
        }}
        snapPoints={({ maxHeight }) => [
          maxHeight - maxHeight / 10,
          maxHeight / 0.75,
          maxHeight * 0.75,
        ]}
      >
        <div className="container flex flex-col items-center px-0 font-montserrat">
          <div className="md:w-4/5 w-full mx-auto mb-32 bg-white px-7 py-[12px]">
            {props.detailData?.imageUrl?.filter((i: any) => i !== "")?.length >
            0 ? (
              <Carousel
                swipeable
                draggable={false}
                showDots={false}
                responsive={responsive}
              >
                {props.detailData?.imageUrl
                  ?.filter((i: any) => i !== "")
                  ?.map((item: any, index: number) => (
                    <img
                      src={item}
                      alt=""
                      key={index}
                      className="mx-auto object-cover rounded-[10px]"
                    />
                  ))}
              </Carousel>
            ) : (
              <img src={NoImage} alt="" className="mx-auto" />
            )}
            <div className="flex items-end justify-between gap-3 mt-[22px] mb-2">
              <h6 className="md:text-xl text-lg font-medium text-customBlack mb-0">
                {props.detailData?.productName}
              </h6>
            </div>
            <h6 className="md:text-base text-sm font-light text-newColor mb-0">
              {props.detailData?.categoryName}
            </h6>
            <div
              className={`${
                props.hiddenButton ? "" : "h-28"
              } min-h-[40px] relative flex justify-start mt-2`}
            >
              <div
                className={`${
                  props.hiddenButton ? "" : "hDescList absolute max-h-[80px]"
                } h-fit text-sm text-[#3A3A3A] font-normal`}
                id="hDesc"
              >
                {props.detailData?.desc}
              </div>
              <button
                className="absolute bottom-[-10px] flex justify-center items-center w-fit rounded-md text-green"
                hidden={props.hiddenButton}
                onClick={() => {
                  props.setHiddenButton(true);
                }}
              >
                Selengkapnya...
              </button>
              {props.detailData?.desc?.length > 446 && (
                <button
                  className="absolute bottom-[-1rem] right-0 flex justify-center items-center w-fit rounded-md text-green"
                  hidden={!props.hiddenButton}
                  onClick={() => {
                    props.setHiddenButton(false);
                  }}
                >
                  Sembunyikan
                </button>
              )}
            </div>
            <div className="bg-white my-6">
              <h6 className="md:text-xl text-lg font-medium text-customBlack">
                Variasi
              </h6>
              {props.detailData?.productItems?.length > 0 &&
                props.detailData?.productItems?.map(
                  (item: any, index: number) => (
                    <div
                      className={`${
                        item.stock === 0
                          ? "bg-[#CCCCCC]"
                          : props.selectedItem.id === item.id
                          ? "border-b-[#CCCCCC] border-b-[1px] cursor-pointer"
                          : "border-b-[#CCCCCC] border-b-[1px] bg-transparent cursor-pointer"
                      } p-[10px] flex items-center justify-between h-full gap-2`}
                      key={index}
                      onClick={() => {
                        if (item.stock !== 0) {
                          props.setSelectedItem(item);
                          props.setQuantity(1);
                        }
                      }}
                    >
                      <div>
                        <input
                          className="w-4 h-4 rounded-[3px] cursor-pointer"
                          type="radio"
                          checked={props.selectedItem.id === item.id}
                          onChange={() => {
                            if (item.stock !== 0) {
                              props.setSelectedItem(item);
                              props.setQuantity(1);
                            }
                          }}
                          value=""
                        />
                      </div>
                      {item?.imageUrl?.length > 0 ? (
                        <img
                          src={
                            item?.imageUrl[0] === ""
                              ? NoImage
                              : item?.imageUrl[0]
                          }
                          alt=""
                          className="object-cover md:w-[100px] w-[60px] md:h-[80px] h-[40px] mx-auto rounded-[4px]"
                        />
                      ) : (
                        <img
                          src={NoImage}
                          alt=""
                          className="object-cover md:w-[100px] w-[60px] md:h-[80px] h-[40px] mx-auto rounded-[4px]"
                        />
                      )}
                      <h6 className="w-full text-customBlack md:text-sm text-xs font-light text-start mb-0">
                        {item?.variantKey}: {item?.variantValue}
                      </h6>
                      <p className="w-1/4 text-end text-base md:text-sm text-customBlack font-semibold mb-0">
                        {item.stock === 0
                          ? "Habis"
                          : formatCurrency(item?.finalPrice)}
                      </p>
                      <div className="relative w-fit mx-auto">
                        {item.stock === 0 && (
                          <div className="md:w-[100px] w-[60px] md:h-[100px] h-[60px] bg-[#CCCCCC] opacity-25 absolute top-0 left-0"></div>
                        )}
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </div>

        <div className="flex justify-center font-montserrat">
          <div className="fixed bottom-0 w-full md:w-2/3 z-10">
            <div className="bg-white mt-4 p-4">
              <div className="flex items-center justify-between mb-3">
                <div>
                  <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                    Harga
                  </p>
                  <p className="text-lg md:text-xl text-green font-medium mb-0">
                    {formatCurrency(props.selectedItem?.finalPrice)}
                  </p>
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    disabled={props.quantity === 1}
                    onClick={() => props.setQuantity(props.quantity - 1)}
                  >
                    <img src={Minus} alt="" />
                  </button>
                  <input
                    type="text"
                    onKeyPress={onlyNumber}
                    value={props.quantity}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        props.setQuantity(1);
                      } else {
                        props.setQuantity(parseInt(e.target.value));
                      }
                    }}
                    name="quantity"
                    id="quantity"
                    className="text-center px-[1rem] w-1/4"
                  />
                  <button
                    type="button"
                    disabled={props.quantity === props.selectedItem?.stock}
                    onClick={() => props.setQuantity(props.quantity + 1)}
                  >
                    <img src={Plus} alt="" />
                  </button>
                </div>
              </div>
              <button
                type="button"
                onClick={props.AddToCart}
                className="w-full md:py-[12px] p-[8px] text-white font-semibold text-base disabled:bg-slate-50 rounded-[10px] bg-green"
              >
                Tambah Pesanan
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>
    </section>
  );
};

export default ProductListMobile;
