/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../assets/images/icon_balance/Arrow - Right.png";
import menuMutation from "../../assets/images/icon_balance/menuMutation.svg";
import {
  ConfigColor,
  formatCurrency,
  formatDateTimeUTC,
} from "../../config/global";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import { useTranslation } from "react-i18next";
import { BalanceCardV2 } from "../_common/BalanceCardV2";
import BalanceSelect from "../_common/BalanceSelect";
import Header from "../_common/headerComponent";
import DataNotFound from "../_common/dataNotFound";

const BalanceWeb = (props: any) => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const configColor = ConfigColor();

  return (
    <section className="h-full w-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>
      <div className="container justify-center md:mt-[64px] mt-[0px] px-4 pb-8">
        <div className="lg:w-1/2 md:w-3/5 w-full mx-auto">
          <div className="w-full">
            <BalanceSelect
              listSaldo={props.listSaldo}
              detail={props.detail}
              changeSaldo={(value: any) => props.changeSaldo(value)}
            />
          </div>
          <div className="w-full mt-4">
            <BalanceCardV2
              name={props.detail.balanceName}
              amount={props.detail.amount}
              noId={props.detail.noId}
            />
          </div>
          {props.detail.type && (
            <div className="grid grid-cols-4 gap-2 mt-2">
              {props.listMenu.map((el: any, index: any) => {
                return (
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/${company.initial}/${props.menu.find((item: any) => item.name === el.name)
                          ?.url
                        }`
                      )
                    }
                  >
                    <img
                      src={
                        el.imageUrl
                          ? el.imageUrl
                          : props.menu.find(
                            (item: any) => item.name === el.name
                          )?.imageUrl
                      }
                      alt=""
                      className="mx-auto"
                    />
                    <div className="text-center mt-[-15px] text-xs md:text-sn">
                      <span className="text-center">
                        {el.display
                          ? el.display
                          : props.menu.find(
                            (item: any) => item.name === el.name
                          )?.display}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div
                className="cursor-pointer"
                onClick={() => navigate(`/${company.initial}/balance/mutation`)}
              >
                <img src={menuMutation} alt="" className="mx-auto" />
                <div className="text-center mt-[-15px] text-xs md:text-sn">
                  <span className="">{t('mutation.titleHeader')}</span>
                </div>
              </div>
            </div>
          )}
          {props.connectted.length > 0 ? (
            <div className="w-full mb-6 mt-4">
              <div
                className="bg-cover bg-center rounded-[10px] px-4 py-3 flex justify-between items-center text-white gap-2 mb-3"
                style={{
                  backgroundImage: `linear-gradient(to right, ${configColor?.color1}, ${configColor?.color2})`,
                }}
                onClick={() => {
                  navigate("providers");
                }}
              >
                <span className="text-sm md:text-base font-[700]">
                  {t('balance.balance_ewallet')}{" "}
                </span>

                <button className="">
                  <img src={ArrowRight} alt="" />
                </button>
              </div>
            </div>
          ) : (
            <div className="w-full mb-6 mt-4">
              <div
                className="bg-cover bg-center rounded-[10px] px-4 py-3 flex justify-between text-white gap-2 mb-3"
                style={{
                  backgroundImage: `linear-gradient(to right, ${configColor?.color1}, ${configColor?.color2})`,
                }}
                onClick={() => {
                  navigate("providers");
                }}
              >
                <div className="flex flex-col">
                  <span className="text-sm md:text-base font-[700]">
                    {t('balance.connectAccount')}
                  </span>
                  <span className="text-xs md:text-sm">
                    {t('balance.description')}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <button className="bg-[#FF9723] px-4 py-2 w-[] text-[10px] font-[600] rounded-[10px]">
                    Detail
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <span className="text-base font-[600]">{t('virtual_card.transaction_history')}</span>
              <span
                className="text-xs md:text-sm text-[#728F9E] cursor-pointer"
                onClick={() =>
                  navigate(
                    `/${company.initial}/balance/${props.detail.type ? "mutation" : "savingMutation"
                    }`,
                    {
                      state: {
                        balanceCode: {
                          code: props.detail.balanceCode,
                        },
                        name: {
                          name: props.detail.balanceName,
                        },
                      },
                    }
                  )
                }
              >
                {t('home.seeOther')}
              </span>
            </div>
            <div className="mt-3">
              {props.mutation.length > 0 ? (
                <>
                  {props.mutation.map((item: any, index: any) => {
                    return (
                      <div key={index} className="flex flex-col">
                        <div className="flex flex-row justify-between items-center gap-3">
                          <div className="w-full">
                            <h3 className="md:text-xl text-sm font-medium text-black mb-0 w-full">
                              {item.transactionType}
                            </h3>
                            <h6 className="md:text-sm text-xs text-[#6B6B6B] font-light text-left mb-1">
                              {formatDateTimeUTC(item.createTime)}
                            </h6>
                          </div>
                          <div className="w-full">
                            {item.mutationType === "DEBT" ? (
                              <h6 className="md:text-xl text-sm text-end text-red font-medium mb-0 w-full">
                                -{formatCurrency(item?.amount)}
                              </h6>
                            ) : (
                              <h6 className="md:text-xl text-sm text-end text-green font-medium mb-0 w-full">
                                +{formatCurrency(item?.amount)}
                              </h6>
                            )}
                            <h6 className="md:text-sm text-xs font-light mb-1 text-end break-words text-[#263339]">
                              {t('mutation.theRemainingBalance')} :{" "}
                              {formatCurrency(item?.currentBalance)}
                            </h6>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </>
              ) : (
                <DataNotFound title={t('balance.notFound')} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BalanceWeb;
