import React, { FC } from "react";
import { saveState } from "../../core/feature/browser-storage/browser-storage";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch } from "../../core/feature/hooks";
import * as API_COMMERCE from "../../core/service/api_commerce";
import { ConfigColor } from "../../config/global";

interface CategoryListProps {
  data: any;
  title: string;
}

const CategoryList: FC<CategoryListProps> = (props): JSX.Element => {
  const [level2Categories, setLevel2Categories] = React.useState<any[]>([]);
  const [level3Categories, setLevel3Categories] = React.useState<any[]>([]);
  const [categoryNames, setCategoryNames] = React.useState<any>({
    level1: "",
    level2: "",
    level3: "",
  });
  const [isActive, setIsActive] = React.useState(false);
  const [isActive2, setIsActive2] = React.useState(false);
  const [id, setId] = React.useState("");
  const dispatch = useAppDispatch();
  const config = ConfigColor();

  const getCategories = async (
    categoryName: string = "",
    level: number = 1,
    parentCategoryId: string = ""
  ) => {
    dispatch(setLoading(true));
    let query = {
      categoryName: "",
      level,
      parentCategoryId,
    };
    await API_COMMERCE.getComboCategory(query)
      .then((res) => {
        dispatch(setLoading(false));
        switch (level) {
          case 2:
            setLevel2Categories(res.data.data);
            setCategoryNames({ ...categoryNames, level1: categoryName });
            setIsActive(!isActive);
            break;

          case 3:
            setLevel3Categories(res.data.data);
            setIsActive2(!isActive2);
            setCategoryNames({ ...categoryNames, level2: categoryName });
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  return (
    <div className="mb-3">
      <div className="flex items-center gap-3 mb-2">
        <div
          onClick={() => getCategories(props.data.name, 2, props.data?.id)}
          className="cursor-pointer"
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33366 16.6641L8.10052 16.6609C3.60818 16.5373 0.00032649 12.8528 0.000326885 8.33073C0.000327287 3.73906 3.73366 -0.00260466 8.33366 -0.00260426C12.9253 -0.00260386 16.667 3.73906 16.667 8.33073C16.667 12.9307 12.9253 16.6641 8.33366 16.6641ZM6.68366 11.6641C6.93366 11.9141 7.32533 11.9141 7.56699 11.6641L10.4753 8.7724C10.592 8.65573 10.6587 8.4974 10.6587 8.33073C10.6587 8.16406 10.592 8.00573 10.4753 7.88906L7.56699 4.9974C7.45033 4.8724 7.29199 4.81406 7.13366 4.81406C6.96699 4.81406 6.80866 4.8724 6.68366 4.9974C6.44199 5.2474 6.44199 5.63906 6.69199 5.88073L9.15033 8.33073L6.69199 10.7807C6.44199 11.0224 6.44199 11.4224 6.68366 11.6641Z"
              fill={`${config?.color1}`}
            />
          </svg>
        </div>
        <h6 className="text-start md:text-base text-sm font-medium text-customBlack mb-0 w-full">
          {props.data?.name}
        </h6>
      </div>
      {isActive && (
        <div className="accordion-content">
          {level2Categories.length > 0 &&
            level2Categories.map((item: any) => (
              <div key={item?.id}>
                <div className="flex items-center gap-3 mb-2 ml-8">
                  <div
                    onClick={() => {
                      getCategories(item.name, 3, item?.id);
                      setId(item?.id);
                    }}
                    className="cursor-pointer"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.33366 16.6641L8.10052 16.6609C3.60818 16.5373 0.00032649 12.8528 0.000326885 8.33073C0.000327287 3.73906 3.73366 -0.00260466 8.33366 -0.00260426C12.9253 -0.00260386 16.667 3.73906 16.667 8.33073C16.667 12.9307 12.9253 16.6641 8.33366 16.6641ZM6.68366 11.6641C6.93366 11.9141 7.32533 11.9141 7.56699 11.6641L10.4753 8.7724C10.592 8.65573 10.6587 8.4974 10.6587 8.33073C10.6587 8.16406 10.592 8.00573 10.4753 7.88906L7.56699 4.9974C7.45033 4.8724 7.29199 4.81406 7.13366 4.81406C6.96699 4.81406 6.80866 4.8724 6.68366 4.9974C6.44199 5.2474 6.44199 5.63906 6.69199 5.88073L9.15033 8.33073L6.69199 10.7807C6.44199 11.0224 6.44199 11.4224 6.68366 11.6641Z"
                        fill={`${config?.color1}`}
                      />
                    </svg>
                  </div>
                  <h6 className="text-start md:text-base text-sm font-medium text-customBlack mb-0 w-full">
                    {item?.name}
                  </h6>
                </div>
                {isActive2 && id === item?.id
                  ? level3Categories.map((el: any) => (
                      <div
                        className="flex items-center gap-3 ml-11 mb-2"
                        key={el?.id}
                      >
                        <h6 className="text-start md:text-base text-sm font-medium text-customBlack mb-0 w-full">
                          {el?.name}
                        </h6>
                        <input
                          type="radio"
                          name="category"
                          id="category"
                          value=""
                          onChange={() => {
                            saveState("category", el);
                            saveState("category_names", {
                              ...categoryNames,
                              level3: el?.name,
                            });
                          }}
                        />
                      </div>
                    ))
                  : null}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CategoryList;
