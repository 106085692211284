/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CreateOrderMobile from "./createOrderMobile";
import CreateOrderWeb from "./createOrderWeb";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  loadState,
  removeState,
  saveState,
} from "../../../core/feature/browser-storage/browser-storage";
import { toast } from "react-hot-toast";
import "react-spring-bottom-sheet/dist/style.css";
import Swal from "sweetalert2";
import { BottomSheet } from "react-spring-bottom-sheet";
import { formatCurrency } from "../../../config/global";
import Company from "../../../core/models/company";
import PaymentMethodNotFound from "../../../assets/images/not-found-payment-method.png";
import { Trans, useTranslation } from "react-i18next";

const CreateOrder = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let address_data = loadState("address_data");
  const { t } = useTranslation();
  const [data, setData] = React.useState<any>({
    addressId: "",
    order: [
      {
        note: "",
        merchantId: "",
        shipId: "",
        cod: false,
        useInsurance: false,
        productList: [
          {
            price: 0,
            itemId: "",
            productId: "",
            quantity: 0,
            amountTotal: 0,
            productName: "",
            productDescription: "",
            imageList: [""],
            productNoteVOS: [
              {
                name: "",
                note: "",
              },
            ],
          },
        ],
        voucherId: [],
        orderReceiverId: "",
      },
    ],
    universalNote: [
      {
        key: "",
        value: "",
      },
    ],
  });
  const [products, setProducts] = React.useState<any[]>([]);
  const [ship, setShip] = React.useState<any[]>([]);
  const [shipId, setShipId] = React.useState<any>([]);
  const [selectedShip, setSelectedShip] = React.useState<any[]>([]);
  const [selectedShipId, setSelectedShipId] = React.useState("");
  const [productNotes, setProductNotes] = React.useState<any>({});
  const [selectedProductNotes, setSelectedProductNotes] = React.useState<any>(
    {}
  );
  const [universalNotes, setUniversalNotes] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [expand, setExpand] = React.useState<any>({});
  const [expandAll, setExpandAll] = React.useState<any>({});
  const [merchantName, setMerchantName] = React.useState("");
  const [totalPrice, setTotalPrice] = React.useState<any>({});
  const [amountTotal, setAmountTotal] = React.useState<any[]>([]);
  const [merchantId, setMerchantId] = React.useState<string[]>([]);
  const [addressData, setAddressData] = React.useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = React.useState<any>({});
  const [merchantCouriers, setMerchantCouriers] = React.useState<any[]>([]);
  const [selectedOrderReceiverId, setSelectedOrderReceiverId] = React.useState<any>({});
  const [openMerchantCouriers, setOpenMerchantCouriers] = React.useState<any>({});
  const [addressModal, setAddressModal] = React.useState("");
  const [quantity, setQuantity] = React.useState(1);
  const [orderTotal, setOrderTotal] = React.useState(0);
  const [shipTotal, setShipTotal] = React.useState(0);
  const [showUniversalNotes, setShowUniversalNotes] = React.useState(false);
  const [isCart, setIsCart] = React.useState(false);
  const [configOrderReceiver, setConfigOrderReceiver] = React.useState(false);
  const [provinces, setProvinces] = React.useState<any[]>([]);
  const [cities, setCities] = React.useState<any[]>([]);
  const [suburbs, setSuburbs] = React.useState<any[]>([]);
  const [areas, setAreas] = React.useState<any[]>([]);
  const [errorName, setErrorName] = React.useState("");
  const [selectedMerchantId, setSelectedMerchantId] = React.useState("");
  const [formData, setFormData] = React.useState<any>({
    mainAddress: false,
    address: "",
    name: "",
    phone: "",
    province: "",
    city: "",
    suburbId: "",
    areaId: "",
    note: "",
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location: any = useLocation();

  var secondaryNote = [
    {
      key: "",
      value: "",
    },
  ];

  var thirdyNote = [
    {
      name: "",
      note: "",
    },
  ];

  let query = {
    addressId: "",
    order: [
      {
        note: "",
        merchantId: "",
        shipId: "",
        cod: false,
        useInsurance: false,
        productList: [
          {
            price: 0,
            itemId: "",
            productId: "",
            quantity: 0,
            amountTotal: 0,
            productName: "",
            productDescription: "",
            imageList: [""],
            productNoteVOS: [
              {
                name: "",
                note: "",
              },
            ],
          },
        ],
        voucherId: [],
        orderReceiverId: "",
      },
    ],
    universalNote: [
      {
        key: "",
        value: "",
      },
    ],
  };

  let defaultFormData = {
    mainAddress: false,
    address: "",
    name: "",
    phone: "",
    province: "",
    city: "",
    suburbId: "",
    areaId: "",
    note: "",
  };

  const getAddress = async () => {
    dispatch(setLoading(true));
    let query = {
      page: 1,
      size: 10,
      sortBy: "-createdDate",
    };
    await API_COMMERCE.getAddress(query)
      .then((res) => {
        dispatch(setLoading(false));
        setAddressData(res.data.data);
        if (selectedAddress.addressId === undefined) {
          let mainAddress = res.data.data.find((el: any) => el.mainAddress);
          if (mainAddress !== undefined) setSelectedAddress(mainAddress);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const deleteAddress = async (item: any) => {
    Swal.fire({
      title: t('create_order.delete_address'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t('create_order.yes'),
      cancelButtonText: t('create_order.no'),
    }).then((res) => {
      if (res.isConfirmed) {
        dispatch(setLoading(true));
        let data = {
          mainAddress: item.mainAddress,
          address: item.address,
          suburbId: item.location.suburb.id,
          areaId: item.location.id,
          name: item.name,
          deleted: true,
          phone: item.phone,
          note: "",
        };
        API_COMMERCE.updateAddress(item.addressId, data)
          .then((res) => {
            dispatch(setLoading(false));
            getAddress();
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  const selectAddress = () => {
    if (selectedAddress.addressId !== undefined) {
      setAddressModal("");
    } else {
      toast.error(t('create_order.required_address'));
    }
  };

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prev: any) => {
      return { ...prev, [name]: value };
    });
    setErrorName("");
  };

  const getProvinces = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.getProvinces()
      .then((res) => {
        dispatch(setLoading(false));
        setProvinces(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCities = async (provinceId: string) => {
    dispatch(setLoading(true));
    await API_COMMERCE.getCities(provinceId)
      .then((res) => {
        dispatch(setLoading(false));
        setCities(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getSuburbs = async (cityId: string) => {
    dispatch(setLoading(true));
    await API_COMMERCE.getSuburbs(cityId)
      .then((res) => {
        dispatch(setLoading(false));
        setSuburbs(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getAreas = async (suburbId: string) => {
    dispatch(setLoading(true));
    await API_COMMERCE.getAreas(suburbId)
      .then((res) => {
        dispatch(setLoading(false));
        setAreas(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const editAddress = (item: any) => {
    setAddressModal("edit");
    saveState("address_data", item);
    let { name, phone, mainAddress, address } = item;
    let { city, province, suburb } = item.location;
    setFormData({
      mainAddress,
      address,
      name,
      phone,
      province: province.id,
      city: city.id,
      suburbId: suburb.id,
      areaId: item.location.id,
      note: "",
    });
    getProvinces();
    getCities(province.id);
    getSuburbs(city.id);
    getAreas(suburb.id);
  };

  const handleSubmit = async () => {
    for (let k in formData) {
      if (formData[k] === "" && k !== "note") {
        return setErrorName(k);
      }
    }
    if (address_data !== undefined) {
      if (errorName === "") {
        dispatch(setLoading(true));
        let { mainAddress, address, name, phone, suburbId, areaId, note } =
          formData;
        let newData = {
          mainAddress,
          address,
          suburbId,
          areaId,
          name,
          deleted: false,
          phone,
          note,
        };
        await API_COMMERCE.updateAddress(address_data.addressId, newData)
          .then((res) => {
            dispatch(setLoading(false));
            getAddress();
            setAddressModal("list");
            removeState("address_data");
            setFormData(defaultFormData);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    } else {
      if (errorName === "") {
        dispatch(setLoading(true));
        let { mainAddress, address, name, phone, suburbId, areaId, note } =
          formData;
        let newData = {
          mainAddress,
          address,
          name,
          phone,
          suburbId,
          areaId,
          note,
        };
        await API_COMMERCE.addAddress(newData)
          .then((res) => {
            dispatch(setLoading(false));
            getAddress();
            setAddressModal("list");
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    }
  };

  const getComboNote = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.getComboNote()
      .then((res) => {
        dispatch(setLoading(false));
        let tempNotes: any = [];
        res.data.data.universalNotes.map((item: any) => {
          tempNotes.push({
            name: item.name,
            required: item.required,
            type: item.type,
            note: "",
            message: "",
          });
          secondaryNote.push({
            key: item.name,
            value: "",
          });
        });
        secondaryNote.splice(0, 1);
        query.universalNote = secondaryNote;
        setData(query);
        setUniversalNotes(tempNotes);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getShip = async (merchantId: string, productList: any) => {
    dispatch(setLoading(true));
    let newData = {
      productList: productList,
      memberAddressId: selectedAddress.addressId,
    };
    await API_COMMERCE.getShip(merchantId, newData)
      .then(async (res) => {
        setShip(res.data.data);
        let merchantCouriers = openMerchantCouriers;
        if (Object.keys(merchantCouriers).length === 0) {
          res.data.data.filter((i: any) => !i.isShipper).map((el: any) => {
            merchantCouriers = { ...merchantCouriers, [el.merchantShipId]: false }
          })
        }
        setOpenMerchantCouriers(merchantCouriers);
        setOpen(true);
        dispatch(setLoading(false));
        API_COMMERCE.getConfigOrderReceiver()
          .then((res) => setConfigOrderReceiver(res.data.data.isActive))
          .catch((err) => console.log(err))
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getOrderReceiverList = async (merchantShipId: string) => {
    dispatch(setLoading(true));
    await API_COMMERCE.getOrderReceiverList()
      .then((res) => {
        dispatch(setLoading(false));
        if (res.data.data.length > 0) setMerchantCouriers(res.data.data.filter((el: any) => el.active));
        console.log(openMerchantCouriers)
        console.log({ ...openMerchantCouriers, [merchantShipId]: true });
        Object.keys(openMerchantCouriers).forEach((item) => {
          if (item !== merchantShipId) {
            openMerchantCouriers[item] = false
          }
        })
        setOpenMerchantCouriers({ ...openMerchantCouriers, [merchantShipId]: true });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const selectShip = (merchantId: string) => {
    setSelectedMerchantId(merchantId);
    if (selectedAddress.address === undefined) {
      toast.error(t('create_order.required_address'));
    } else {
      let tempProductList: any = [];
      let findMerchant = products.filter(
        (el: any) => el.merchantId === merchantId
      );
      findMerchant.map((el: any) => {
        if (isCart) {
          el.product.map((el2: any) =>
            el2.item.map((i: any) =>
              tempProductList.push({
                price: i.marketplacePrice,
                itemId: i.id,
                productId: i.productId,
                quantity: i.quantity,
                amountTotal: i.marketplacePrice * i.quantity,
              })
            )
          );
        } else {
          tempProductList.push({
            price: el.marketplacePrice,
            itemId: el.id,
            productId: el.productId,
            quantity,
            amountTotal: el.marketplacePrice * quantity,
          });
        }
      });
      getShip(merchantId, tempProductList);
    }
  };

  const onSelectShip = (el: any) => {
    if (el.isShipper) {
      let tempOpenMerchantCouriers = openMerchantCouriers;
      Object.keys(tempOpenMerchantCouriers).forEach((item) => tempOpenMerchantCouriers[item] = false)
      setOpenMerchantCouriers(tempOpenMerchantCouriers);
      setSelectedShipId(el.merchantShipId);
      let tempShipId = shipId;
      let findShip = tempShipId.findIndex(
        (i: any) => i.merchantId === el.merchantId
      );
      if (findShip >= 0) {
        tempShipId.splice(findShip, 1);
        tempShipId.push(el);
      } else {
        tempShipId.push(el);
      }
      console.log(shipId)
      setShipId(tempShipId);
    } else {
      if (configOrderReceiver) getOrderReceiverList(el.merchantShipId);
      setSelectedShipId(el.merchantShipId);
      let tempShipId = shipId;
      let findShip = tempShipId.findIndex(
        (i: any) => i.merchantId === el.merchantId
      );
      if (findShip >= 0) {
        tempShipId.splice(findShip, 1);
        tempShipId.push(el);
      } else {
        tempShipId.push(el);
      }
      console.log(shipId)
      setShipId(tempShipId);
    }
  };

  const onSelectOrderReceiver = (el: any) => {
    setSelectedOrderReceiverId({ ...selectedOrderReceiverId, [selectedMerchantId]: el.id });
    console.log({ ...selectedOrderReceiverId, [selectedMerchantId]: el.id });
  };

  const onConfirmShip = (merchantShip: any, merchantId: any) => {
    setOpen(false);
    let tempSelectedShip = [...selectedShip];
    let findShip = tempSelectedShip.findIndex(
      (i: any) => i.merchantId === merchantShip.merchantId
    );
    if (findShip >= 0) {
      tempSelectedShip.splice(findShip, 1);
      tempSelectedShip.push(merchantShip);
    } else {
      tempSelectedShip.push(merchantShip);
    }
    setSelectedShip(tempSelectedShip);
    console.log(tempSelectedShip);
    let tempShipTotal = 0;
    tempSelectedShip.map((el: any) => (tempShipTotal += el.price));
    setShipTotal(tempShipTotal);
    let tempAmountTotal = [...amountTotal];
    let findPrice = tempAmountTotal.find(
      (j: any) => j.merchantId === merchantShip.merchantId
    );
    if (findPrice === undefined) {
      tempAmountTotal.push({
        merchantId: merchantShip.merchantId,
        price: totalPrice[merchantShip.merchantId] + merchantShip.price,
      });
      setAmountTotal(tempAmountTotal);
    }

    let tempData: any = { ...data };
    query = tempData;
    let findMerchant = query.order.find(
      (j: any) => j.merchantId === merchantShip.merchantId
    );
    if (findMerchant !== undefined) {
      findMerchant.shipId = merchantShip.merchantShipId;
      if (selectedOrderReceiverId[merchantId] !== "") findMerchant.orderReceiverId = selectedOrderReceiverId[merchantId];
    }
    setSelectedMerchantId("");
  }

  const confirmShip = (merchantId: string) => {
    console.log(shipId)
    let merchantShip: any = shipId.find((i: any) => i.merchantId === merchantId);
    console.log(merchantShip)
    console.log(selectedOrderReceiverId)
    console.log(selectedOrderReceiverId[merchantId])
    if (merchantShip !== undefined) {
      if (!configOrderReceiver) {
        onConfirmShip(merchantShip, merchantId);
      } else {
        if (merchantShip.isShipper) {
          onConfirmShip(merchantShip, merchantId);
        } else {
          if (selectedOrderReceiverId[merchantId] !== "") {
            onConfirmShip(merchantShip, merchantId);
          } else {
            toast.error(t('create_order.required_shipment'));
          }
        }
      }
    } else {
      toast.error(t('create_order.required_shipment'));
    }
  };

  const selectForm = (merchantId: string, productId: string) => {
    if (productNotes[merchantId] !== undefined) {
      let findProduct = productNotes[merchantId].find(
        (el: any) => el.productId === productId
      );
      let newData = { ...findProduct, merchantId };
      setSelectedProductNotes(newData);
    }
  };

  const handleClose = () => {
    let tempProductNotes: any = productNotes;
    if (tempProductNotes[selectedProductNotes.merchantId] !== undefined) {
      let product = tempProductNotes[selectedProductNotes.merchantId].find(
        (i: any) => i.productId === selectedProductNotes.productId
      );
      product.notes.map((el: any) => (el.note = ""));
    }

    let tempData = { ...data };
    let findProductId = tempData.order
      .find((j: any) => j.merchantId === selectedProductNotes.merchantId)
      .productList.find(
        (k: any) => k.productId === selectedProductNotes.productId
      );
    findProductId.productNoteVOS.map((el: any) => (el.note = ""));
    setData(tempData);
    setProductNotes(tempProductNotes);
    setSelectedProductNotes({});
  };

  const handleClose2 = () => {
    let tempUniversalNotes: any = universalNotes;
    tempUniversalNotes.map((el: any) => (el.note = ""));

    let tempData = data;
    tempData.universalNote.map((el: any) => (el.value = ""));
    setUniversalNotes(tempUniversalNotes);
    setData(tempData);
    setShowUniversalNotes(false);
  };

  const addProductNotes = (
    merchantId: any,
    productId: any,
    name: any,
    note: any
  ) => {
    let tempProductNotes: any = productNotes;
    if (tempProductNotes[merchantId] !== undefined) {
      let product = tempProductNotes[merchantId].find(
        (i: any) => i.productId === productId
      );
      product.notes.find((i: any) => i.name === name).note = note;
    }

    let tempData = { ...data };
    let findProductId = tempData.order
      .find((j: any) => j.merchantId === merchantId)
      .productList.find((k: any) => k.productId === productId);
    let findName = findProductId.productNoteVOS.find(
      (j: any) => j.name === name
    );
    if (findName !== undefined) {
      findName.note = note;
    }
    setData(tempData);
    setProductNotes(tempProductNotes);
  };

  const addNote = (merchantId: any, note: any) => {
    let tempData: any = { ...data };
    let findNote = tempData.order.find((j: any) => j.merchantId === merchantId);
    if (findNote !== undefined) {
      findNote.note = note;
    }
    setData(tempData);
  };

  const addUniversalNotes = (name: any, note: any) => {
    let tempUniversalNotes: any = [...universalNotes];
    let findNote = tempUniversalNotes.find((k: any) => k.name === name);
    if (findNote !== undefined) {
      findNote.note = note;
    }

    let tempData = { ...data };
    let findKey = tempData.universalNote.find((j: any) => j.key === name);
    if (findKey !== undefined) {
      findKey.value = note;
    }
    setData(tempData);
  };

  const handleOpen = (itemId: string) => {
    if (expand[itemId] !== undefined) {
      setExpand({ ...expand, [itemId]: !expand[itemId] });
    }
  };

  const handleSave = () => {
    let findNote = selectedProductNotes.notes.find(
      (el: any) => el.required && el.note === ""
    );
    if (findNote !== undefined) {
      toast.error(`${findNote.name} ${t('create_order.required')}`);
    } else {
      setSelectedProductNotes({});
    }
  };

  const handleOnClick = async () => {
    let filteredUniversalNote = universalNotes.find(
      (i: any) => i.note === "" && i.required === true
    );
    let filteredProductNote: any = [];
    let filteredShip: any = [];
    merchantId.map((el: any) => {
      if (productNotes[el] !== undefined) {
        productNotes[el].map((i: any) => {
          let findNote = i.notes.find(
            (j: any) => j.note === "" && j.required === true
          );
          if (findNote !== undefined) filteredProductNote.push(findNote);
        });
      }
      console.log(selectedShip)
      if (selectedShip.find((i: any) => i.merchantId === el) === undefined) {
        if (isCart) {
          let findMerchant = products.find((j: any) => j.merchantId === el);
          let findProduct = findMerchant.product.find(
            (k: any) => k.productType === "BARANG"
          );
          if (findProduct !== undefined) filteredShip.push(el);
        } else {
          if (location.state.data.type === "BARANG") filteredShip.push(el);
        }
      }
    });
    if (selectedAddress.address === undefined) {
      toast.error(t('create_order.insert_address'));
    } else if (filteredShip.length > 0) {
      toast.error(t('create_order.required_shipment'));
    } else if (filteredProductNote.length > 0) {
      toast.error(t('create_order.form_product'));
    } else if (filteredUniversalNote !== undefined) {
      toast.error(t('create_order.form_general'));
    } else {
      let tempData: any = { ...data };
      query = tempData;
      query.addressId = selectedAddress.addressId;
      let cartIds: any = [];
      if (isCart) {
        products.map((item: any) =>
          item.product.map((el: any) =>
            el.item.map((el2: any) => cartIds.push(el2.cartId))
          )
        );
      }

      console.log(query)
      dispatch(setLoading(true));
      await API_COMMERCE.getSummary(query)
        .then((res) => {
          dispatch(setLoading(false));
          if (isCart) {
            navigate("summary", {
              state: {
                data: res.data.data,
                dataOrder: query,
                cartIds,
              },
            });
          } else {
            navigate("summary", {
              state: {
                data: res.data.data,
                dataOrder: query,
              },
            });
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  React.useEffect(() => {
    if (location.state !== undefined) {
      setIsCart(location.state.isCart);
      if (location.state.isCart) {
        setProducts(location.state.data);
        let orderData: any = [];
        let tempProductNotes = { ...productNotes };
        let tempTotalPrice = totalPrice;
        let tempMerchantId = merchantId;
        let tempExpandData = expand;
        let tempExpandAll = expandAll;
        location.state.data.map((item: any) => {
          tempMerchantId.push(item.merchantId);
          tempExpandAll = { ...tempExpandAll, [item.merchantId]: false };
          let productData: any = [];
          item.product.map((el: any) => {
            let priceItem = 0;
            el.item.map((i: any) => {
              let total = i.marketplacePrice * i.quantity;
              return (priceItem += total);
            });
            if (tempTotalPrice[el.merchantId] === undefined) {
              tempTotalPrice = {
                ...tempTotalPrice,
                [el.merchantId]: priceItem,
              };
            } else {
              tempTotalPrice[el.merchantId] =
                tempTotalPrice[el.merchantId] + priceItem;
            }
            let productNoteVOS: any = [];
            if (el.noteVOS !== undefined && el.noteVOS.length > 0) {
              let tempNoteVOS: any = [];
              el.noteVOS.map((i: any) => {
                if (i.name !== undefined) {
                  productNoteVOS.push({
                    name: i.name,
                    note: "",
                  });
                  tempNoteVOS.push({
                    name: i.name,
                    required: i.required,
                    type: i.type,
                    note: "",
                    message: "",
                  });
                }
              });
              let newNotes = {
                productId: el.productId,
                productName: el.name,
                notes: tempNoteVOS,
              };
              if (tempProductNotes[el.merchantId] === undefined) {
                tempProductNotes = {
                  ...tempProductNotes,
                  [el.merchantId]: [newNotes],
                };
              } else {
                tempProductNotes[el.merchantId].push(newNotes);
              }
            }
            el.item.map((el2: any) => {
              productData.push({
                price: el2.marketplacePrice,
                itemId: el2.id,
                productId: el2.productId,
                quantity: el2.quantity,
                amountTotal: el2.marketplacePrice * el2.quantity,
                productName: el2.productName,
                productDescription: el.description,
                imageList: el2.imageUrl,
                productNoteVOS: productNoteVOS,
              });
            });

            tempExpandData = { ...tempExpandData, [el.productId]: false };
          });
          orderData.push({
            note: "",
            merchantId: item.merchantId,
            shipId: "",
            cod: false,
            useInsurance: false,
            productList: productData,
            voucherId: [],
          });
        });
        query.order = orderData;
        let tempOrderTotal = orderTotal;
        tempMerchantId.map((i: any) => {
          return (tempOrderTotal += tempTotalPrice[i]);
        });
        let tempSelectedOrderReceiverId = selectedOrderReceiverId;
        tempMerchantId.map((i: any) => {
          return tempSelectedOrderReceiverId = { ...tempSelectedOrderReceiverId, [i]: "" };
        })
        console.log("tempSelectedOrderReceiverId:", tempSelectedOrderReceiverId);
        setOrderTotal(tempOrderTotal);
        setTotalPrice(tempTotalPrice);
        setExpand(tempExpandData);
        setExpandAll(tempExpandAll);
        setProductNotes(tempProductNotes);
        setMerchantId(tempMerchantId);
        setSelectedOrderReceiverId(tempSelectedOrderReceiverId);
        getComboNote();
      } else {
        let stateData = location.state.data;
        let stateDataOrder = location.state.dataOrder;
        let tempProducts: any = [];
        tempProducts.push(stateDataOrder);
        setProducts(tempProducts);
        setMerchantName(location.state.data.merchant.name);
        setQuantity(location.state.quantity);
        setMerchantId([...merchantId, stateData.merchantId]);
        setExpand({ ...expand, [stateDataOrder.id]: false });
        setSelectedOrderReceiverId({ ...selectedOrderReceiverId, [stateData.merchantId]: "" })
        if (stateData.notes.length > 0) {
          let tempNoteVOS: any = [];
          let productNoteVOS: any = [];
          let tempProductNotes = { ...productNotes };
          stateData.notes.map((i: any) => {
            if (i.name !== undefined) {
              productNoteVOS.push({
                name: i.name,
                note: "",
              });
              tempNoteVOS.push({
                name: i.name,
                required: i.required,
                type: i.type,
                note: "",
                message: "",
              });
            }
          });
          let newNotes = {
            productId: stateDataOrder.productId,
            productName: stateDataOrder.productName,
            notes: tempNoteVOS,
          };
          if (tempProductNotes[stateData.merchantId] === undefined) {
            tempProductNotes = {
              ...tempProductNotes,
              [stateData.merchantId]: [newNotes],
            };
          } else {
            tempProductNotes[stateData.merchantId].push(newNotes);
          }
          setProductNotes(tempProductNotes);
        }
        if (stateData.notes.length > 0) {
          location.state.data.notes.map(
            (el: any) =>
              el.name !== undefined &&
              thirdyNote.push({
                name: el.name,
                note: "",
              })
          );
          thirdyNote.splice(0, 1);
        }
        let productData = [
          {
            price: stateDataOrder.marketplacePrice,
            itemId: stateDataOrder.id,
            productId: stateDataOrder.productId,
            quantity: location.state.quantity,
            amountTotal:
              stateDataOrder.marketplacePrice * location.state.quantity,
            productName: stateDataOrder.productName,
            productDescription: stateData.description,
            imageList: stateDataOrder.imageUrl,
            productNoteVOS: thirdyNote,
          },
        ];
        let orderData = [
          {
            note: "",
            merchantId: stateData.merchantId,
            shipId: "",
            cod: false,
            useInsurance: false,
            productList: productData,
            voucherId: [],
            orderReceiverId: "",
          },
        ];
        query.addressId = "";
        query.order = orderData;

        orderData.map((j: any) =>
          j.productList.map((k: any) => {
            setTotalPrice({
              ...totalPrice,
              [j.merchantId]: k.price * k.quantity,
            });
            return setOrderTotal(k.price * k.quantity);
          })
        );
        getComboNote();
      }
      getAddress();
    }
  }, []);

  return (
    <>
      <BrowserView>
        <CreateOrderWeb
          deleteAddress={(item: any) => deleteAddress(item)}
          selectAddress={() => selectAddress()}
          handleChange={(e: any) => handleChange(e)}
          getProvinces={() => getProvinces()}
          getCities={(provinceId: string) => getCities(provinceId)}
          getSuburbs={(cityId: string) => getSuburbs(cityId)}
          getAreas={(suburbId: string) => getAreas(suburbId)}
          editAddress={(item: any) => editAddress(item)}
          handleSubmit={() => handleSubmit()}
          getComboNote={() => getComboNote()}
          getShip={(merchantId: string, productList: any) => getShip(merchantId, productList)}
          selectShip={(merchantId: string) => selectShip(merchantId)}
          selectForm={(merchantId: string, productId: string) => selectForm(merchantId, productId)}
          handleClose={() => handleClose()}
          handleClose2={() => handleClose2()}
          addProductNotes={(merchantId: any, productId: any, name: any, note: any) => addProductNotes(merchantId, productId, name, note)}
          addNote={(merchantId: string, note: any) => addNote(merchantId, note)}
          addUniversalNotes={(merchantId: string, note: any) => addUniversalNotes(merchantId, note)}
          handleOpen={(itemId: string) => handleOpen(itemId)}
          handleSave={() => handleSave()}
          handleOnClick={() => handleOnClick()}
          setAddressModal={(value: any) => setAddressModal(value)}
          setSelectedAddress={(value: any) => setSelectedAddress(value)}
          setFormData={(value: any) => setFormData(value)}
          setErrorName={(value: any) => setErrorName(value)}
          setShowUniversalNotes={(value: any) => setShowUniversalNotes(value)}
          data={data}
          products={products}
          ship={ship}
          shipId={shipId}
          selectedShip={selectedShip}
          productNotes={productNotes}
          selectedProductNotes={selectedProductNotes}
          universalNotes={universalNotes}
          expand={expand}
          expandAll={expandAll}
          merchantName={merchantName}
          totalPrice={totalPrice}
          amountTotal={amountTotal}
          merchantId={merchantId}
          addressData={addressData}
          selectedAddress={selectedAddress}
          addressModal={addressModal}
          orderTotal={orderTotal}
          shipTotal={shipTotal}
          showUniversalNotes={showUniversalNotes}
          isCart={isCart}
          provinces={provinces}
          cities={cities}
          suburbs={suburbs}
          areas={areas}
          errorName={errorName}
          formData={formData}
          defaultFormData={defaultFormData}
        />
      </BrowserView>
      <MobileView>
        <CreateOrderMobile
          deleteAddress={(item: any) => deleteAddress(item)}
          selectAddress={() => selectAddress()}
          handleChange={(e: any) => handleChange(e)}
          getProvinces={() => getProvinces()}
          getCities={(provinceId: string) => getCities(provinceId)}
          getSuburbs={(cityId: string) => getSuburbs(cityId)}
          getAreas={(suburbId: string) => getAreas(suburbId)}
          editAddress={(item: any) => editAddress(item)}
          handleSubmit={() => handleSubmit()}
          getComboNote={() => getComboNote()}
          getShip={(merchantId: string, productList: any) => getShip(merchantId, productList)}
          selectShip={(merchantId: string) => selectShip(merchantId)}
          selectForm={(merchantId: string, productId: string) => selectForm(merchantId, productId)}
          handleClose={() => handleClose()}
          handleClose2={() => handleClose2()}
          addProductNotes={(merchantId: any, productId: any, name: any, note: any) => addProductNotes(merchantId, productId, name, note)}
          addNote={(merchantId: string, note: any) => addNote(merchantId, note)}
          addUniversalNotes={(merchantId: string, note: any) => addUniversalNotes(merchantId, note)}
          handleOpen={(itemId: string) => handleOpen(itemId)}
          handleSave={() => handleSave()}
          handleOnClick={() => handleOnClick()}
          setAddressModal={(value: any) => setAddressModal(value)}
          setSelectedAddress={(value: any) => setSelectedAddress(value)}
          setFormData={(value: any) => setFormData(value)}
          setErrorName={(value: any) => setErrorName(value)}
          setShowUniversalNotes={(value: any) => setShowUniversalNotes(value)}
          data={data}
          products={products}
          ship={ship}
          shipId={shipId}
          selectedShip={selectedShip}
          productNotes={productNotes}
          selectedProductNotes={selectedProductNotes}
          universalNotes={universalNotes}
          expand={expand}
          expandAll={expandAll}
          merchantName={merchantName}
          totalPrice={totalPrice}
          amountTotal={amountTotal}
          merchantId={merchantId}
          addressData={addressData}
          selectedAddress={selectedAddress}
          addressModal={addressModal}
          orderTotal={orderTotal}
          shipTotal={shipTotal}
          showUniversalNotes={showUniversalNotes}
          isCart={isCart}
          provinces={provinces}
          cities={cities}
          suburbs={suburbs}
          areas={areas}
          errorName={errorName}
          formData={formData}
          defaultFormData={defaultFormData}
        />
      </MobileView>

      <BottomSheet
        open={open}
        onDismiss={() => {
          setOpen(false);
          setShipId([]);
          setSelectedShipId("");
        }}
      >
        <div className="mx-6 my-3 font-montserrat">
          {ship.length > 0 || merchantCouriers?.length > 0 ? (
            <div>
              <h6 className="md:text-lg text-base font-bold mb-2">
                {t('create_order.shipping_options')}
              </h6>
              {ship.map((el) =>
                !configOrderReceiver ? (
                  <div
                    className="border-[#E2E2E2] border-[1px] rounded-[5px] flex items-center gap-3 py-3 px-2 mb-6 cursor-pointer"
                    key={el.merchantShipId}
                    onClick={() => onSelectShip(el)}
                  >
                    <p className="md:text-base text-md text-[#252525] font-normal w-full mb-0">
                      {el.name} - {formatCurrency(el.price)}
                    </p>
                    <input
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                      type="radio"
                      checked={
                        selectedShipId
                          ? selectedShipId === el.merchantShipId
                          : selectedShip.find(
                            (i: any) => i.merchantShipId === el.merchantShipId
                          ) !== undefined
                      }
                      onChange={() => onSelectShip(el)}
                      value=""
                    />
                  </div>
                ) : el?.isShipper ? (
                  <div
                    className="border-[#E2E2E2] border-[1px] rounded-[5px] flex items-center gap-3 py-3 px-2 mb-6 cursor-pointer"
                    key={el.merchantShipId}
                    onClick={() => onSelectShip(el)}
                  >
                    <p className="md:text-base text-md text-[#252525] font-normal w-full mb-0">
                      {el.name} - {formatCurrency(el.price)}
                    </p>
                    <input
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                      type="radio"
                      checked={
                        selectedShipId
                          ? selectedShipId === el.merchantShipId
                          : selectedShip.find(
                            (i: any) => i.merchantShipId === el.merchantShipId
                          ) !== undefined
                      }
                      onChange={() => onSelectShip(el)}
                      value=""
                    />
                  </div>
                ) : (
                  <div
                    className={`${openMerchantCouriers[el.merchantShipId] ? "pb-3" : ""
                      } border-[#E2E2E2] border-[1px] rounded-[5px] mb-6 cursor-pointer`}
                  >
                    <div
                      className={`${openMerchantCouriers[el.merchantShipId]
                        ? "border-b-[1px] border-b-[#E2E2E2]"
                        : ""
                        } flex justify-between gap-3 px-2 py-3`}
                    >
                      <label className="md:text-base text-md text-[#252525] font-normal w-full mb-0">
                        {el.name} - {formatCurrency(el.price)}
                      </label>
                      <input
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                        type="radio"
                        checked={openMerchantCouriers[el.merchantShipId]}
                        onChange={() => onSelectShip(el)}
                        value=""
                      />
                    </div>
                    {openMerchantCouriers[el.merchantShipId] &&
                      merchantCouriers.map((el) => (
                        <div
                          className="flex items-center gap-3 mt-3 px-2 cursor-pointer"
                          key={el.id}
                          onClick={() => onSelectOrderReceiver(el)}
                        >
                          <input
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                            type="checkbox"
                            checked={
                              selectedOrderReceiverId[selectedMerchantId] ===
                              el.id
                            }
                            onChange={() => onSelectOrderReceiver(el)}
                            value=""
                          />
                          <p className="md:text-base text-md text-[#252525] font-normal w-full mb-0">
                            {el.name} - ({el.address})
                          </p>
                        </div>
                      ))}
                  </div>
                )
              )}
              <button
                type="button"
                onClick={() => confirmShip(ship[0].merchantId)}
                style={{
                  backgroundColor: `${company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A"
                    }`,
                }}
                className="bg-green w-full p-[12px] text-white rounded-[10px]"
              >
                {t('create_order.confirm')}
              </button>
            </div>
          ) : (
            <div>
              <h6 className="md:text-lg text-base font-bold mb-2 text-center">
                {t('create_order.shipping_options')}
              </h6>
              <img src={PaymentMethodNotFound} alt="" className="mx-auto" />
              <p className="md:text-lg text-base font-bold text-center">
                {t('create_order.no_shipping')}
              </p>
            </div>
          )}
        </div>
      </BottomSheet>
    </>
  );
};

export default CreateOrder;
