import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import QueueNumberWeb from "./queueNumberWeb";
import QueueNumberMobile from "./queueNumberMobile";

const QueueNumber = () => {
  return (
    <>
      <BrowserView>
        <QueueNumberWeb></QueueNumberWeb>
      </BrowserView>
      <MobileView>
        <QueueNumberMobile></QueueNumberMobile>
      </MobileView>
    </>
  );
};

export default QueueNumber;
