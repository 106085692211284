import Config from "../../config/Config";
import { bearer } from "../../config/global";

const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getAllInvoiceSent = (
  size,
  page,
  sort,
  dir,
  status,
  type,
  title,
  startDate,
  endDate
) => {
  let params = `size=${size}&page=${page}&sortBy=${sort}&dir=${dir}&title=${title}`;
  params = `${params}&isPaid=${status.includes("PAID")}`;
  params = `${params}&isUnpaid=${status.includes("UNPAID")}`;
  params = `${params}&isPartial=${status.includes("PARTIAL")}`;
  if (type) params = `${params}&userType=${type}`;
  if (startDate) params = `${params}&startDate=${startDate}`;
  if (endDate) params = `${params}&endDate=${endDate}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}invoice/member/invoice/sent?${params}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDetailInvoiceSent = (idInvoice) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}invoice/member/invoice/sent/${idInvoice}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteInvoiceSent = (idInvoice) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrlDev}invoice/member/invoice/sent/${idInvoice}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllInvoice = (
  size,
  page,
  sort,
  dir,
  status,
  type,
  title,
  startDate,
  endDate
) => {
  let params = `size=${size}&page=${page}&sortBy=${sort}&dir=${dir}&title=${title}`;
  params = `${params}&isPaid=${status.includes("PAID")}`;
  params = `${params}&isUnpaid=${status.includes("UNPAID")}`;
  params = `${params}&isPartial=${status.includes("PARTIAL")}`;
  if (type) params = `${params}&createdBy=${type}`;
  if (startDate) params = `${params}&startDate=${startDate}`;
  if (endDate) params = `${params}&endDate=${endDate}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}invoice/member/invoice/received?${params}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllInvoiceByType = (size, page, sort, dir, status, type) => {
  console.log(bearer());
  let params = `size=${size}&page=${page}&sort=${sort}&dir=${dir}`;
  if (status) params = `${params}&paymentStatus=${status}`;
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}invoice/member/invoice/created_by_${type.toLowerCase()}?${params}`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDetailInvoice = (id, type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}invoice/member/invoice/created_by_${type}/${id}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inquiryPayment = (type, id, body, token = "") => {
  console.log(type, id, body);
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrlDev}invoice/member/invoice/created_by_${type}/${id}/inquiry_payment`,
        body,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
            reauthentication: token,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const confirmPayment = (type, id, body, token = "") => {
  console.log(type, id, body);
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrlDev}invoice/member/invoice/created_by_${type}/${id}/inquiry_payment`,
        body,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
            reauthentication: token,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createInvoice = (body = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}invoice/member/invoice`, body, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkoutInvoice = (type, idInvoice, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrlDev}invoice/member/invoice/created_by_${type}/${idInvoice}/create_checkout`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigInvoice = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}invoice/member/config/member`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
