import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToastConfig: any = {
  warning: {
    // Custom Icon
    icon: (
      <FontAwesomeIcon
        style={{ color: "F1C40F" }}
        icon={faWarning}
      ></FontAwesomeIcon>
    ),
    id: 'warning',
  },

  info: {
    // Custom Icon
    icon: (
      <FontAwesomeIcon
        style={{ color: "3498DB" }}
        icon={faInfoCircle}
      ></FontAwesomeIcon>
    ),
    id: 'info',
  },

  success: {
    // Custom Icon
    icon: (
      <FontAwesomeIcon
        style={{ color: "#FFFFFF" }}
        icon={faCircleCheck}
      ></FontAwesomeIcon>
    ),
    id: 'success',
    style: {
      background: "#26A69A",
      color: "#FFFFFF",
    },
  },
};

export default ToastConfig;
