import React from "react";
import MerchantLogo from "../../../assets/images/icon_bank/merchant.png";
import { iconBank } from "../../../config/iconBank";
import { BalanceCard } from "../../_common/BalanceCard";
import Header from "../../_common/headerComponent";
import Collapse from "../../_common/Collapse";

const Icon = (data: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        data.id === data.open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

const selectBankMobile = (props: any) => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Top Up"></Header>
      <div className="flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4">
        <div className="md:w-3/5 w-full mx-auto mb-10">
          <BalanceCard />
          <div className="w-full">
            <h1 className="md:text-lg text-base font-medium text-customBlack py-3 mb-0">
              {props.t("topup.selectMethodTitle")}
            </h1>
            <React.Fragment>
              {props.data.map((item: any, index: any) => {
                return (
                  <div
                    className="bg-white p-3 mb-6 rounded-[5px] shadow-sm cursor-pointer"
                    key={index}
                    onClick={() => props.handleOpen(index + 1)}
                  >
                    <div className="space-between">
                      <div className="flex flex-row items-center gap-3">
                        <img
                          src={iconBank(item.bank)}
                          alt=""
                          width={40}
                          height={40}
                        />
                        <h6 className="md:text-base text-sm text-customBlack font-medium font-poppins mb-0">
                          {item.bank === "BSI_TBG"
                            ? item.bank.replaceAll("_TBG", " ")
                            : item.bank}{" "}
                          <span className="italic text-xs">
                            {item.provider === "XENDIT" ||
                            item.provider === "DURIAN" ||
                            item.provider === "FAZZ" ||
                            item.provider === "LINKQU"
                              ? " (Provider)"
                              : ""}
                          </span>
                        </h6>
                      </div>
                      <button
                        type="button"
                        onClick={() => props.handleOpen(index + 1)}
                      >
                        <Icon id={index + 1} open={props.open} />
                      </button>
                    </div>
                    <Collapse open={props.open === index + 1}>
                      <hr className="my-2" />
                      {props.paymentTools.map((el: any) => (
                        <div key={el.id}>
                          <div
                            className="cursor-pointer flex flex-row items-center gap-3"
                            onClick={() =>
                              props.handleOnClick(item, el.name, item.provider)
                            }
                          >
                            <img src={el.img} alt="" width={24} height={24} />
                            <h6 className="md:text-base text-sm text-customBlack font-medium font-poppins mb-0">
                              {el.name}
                            </h6>
                          </div>
                          <hr className="my-2" />
                        </div>
                      ))}
                    </Collapse>
                  </div>
                );
              })}
              <div
                className="bg-white p-3 mb-6 rounded-[5px] shadow-sm cursor-pointer"
                onClick={() => props.handleOpen(props.data.length + 1)}
              >
                <div className="space-between">
                  <div className="flex flex-row items-center gap-3">
                    <img src={MerchantLogo} alt="" width={30} height={30} />
                    <h6 className="md:text-base text-sm text-customBlack font-medium font-poppins mb-0">
                      {props.t("topup.merchant")}
                    </h6>
                  </div>
                  <button
                    type="button"
                    onClick={() => props.handleOpen(props.data.length + 1)}
                  >
                    <Icon id={props.data.length + 1} open={props.open} />
                  </button>
                </div>
                <Collapse open={props.open === props.data.length + 1}>
                  <hr className="my-2" />
                  <h6 className="md:text-base text-sm text-customBlack font-medium font-poppins mb-0">
                    {props.t("topup.instructions")}
                  </h6>
                  <hr className="my-2" />
                  {props.instructions.map((el: any) => (
                    <div key={el.id}>
                      <div className="flex items-center gap-3">
                        <div className="bg-[#2BC569] text-sm text-white rounded-[50%] px-2 py-1 text-center">
                          {el.id}
                        </div>
                        <p className="text-customBlack text-sm font-poppins mb-0">
                          {el.detail}
                        </p>
                      </div>
                      <hr className="my-2" />
                    </div>
                  ))}
                </Collapse>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
    </section>
  );
};

export default selectBankMobile;
