/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Header from '../_common/headerComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from "swiper/react";
import GreenCard from '../../assets/images/icon_queue/green-card.png';
import GreyCard from '../../assets/images/icon_queue/grey-card.png';
import ArrowBottom from '../../assets/images/icon_queue/arrow_bottom.svg';
import TimeIcon from '../../assets/images/icon_queue/time_icon.svg';
import BranchIcon from '../../assets/images/icon_queue/branch.svg';
import GuideIcon from '../../assets/images/icon_queue/guide.svg';
import HistoryIcon from '../../assets/images/icon_queue/history.svg';
import MemberIcon from '../../assets/images/icon_queue/member.svg';
import Model1 from '../../assets/images/icon_queue/model_1.png';
import Model2 from '../../assets/images/icon_queue/model_2.png';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../config/global';
import { Pagination } from 'swiper';
import { find } from 'lodash';
import User from '../../core/models/user';
import { useAppSelector } from '../../core/feature/hooks';
import Company from '../../core/models/company';
import Token from '../../core/models/token';

const menus = [
  {
    image: BranchIcon,
    name: "Cabang",
    url: "branch"
  },
  {
    image: GuideIcon,
    name: "Panduan",
    url: "guide"
  },
  {
    image: HistoryIcon,
    name: "Riwayat",
    url: "history"
  },
  {
    image: MemberIcon,
    name: "Anggota",
    url: "members"
  },
]

const services = [
  {
    image: Model1,
    name1: "Comma Hair",
    name2: "Haircut",
    price: 100000,
  },
  {
    image: Model2,
    name1: "Shaving",
    name2: "Perawatan",
    price: 200000,
  },
]

const queues = [
  {
    number: "A-481",
    status: "onProgress",
    location: "Klinik Merdeka Batubara"
  },
  {
    number: "A-480",
    status: "onCheck",
    location: "Klinik Merdeka Batubara"
  },
]

const OnlineQueueWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const user: User = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [keyword, setKeyword] = React.useState("");
  const [isLogin, setIsLogin] = React.useState(user && newToken);

  React.useEffect(() => {
    setIsLogin(user && newToken);
  }, [])

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />
      <div className="md:w-1/2 w-full mx-auto md:mt-12 mt-[0px] px-4 pb-8">
        <Swiper
          slidesPerView={'auto'}
          dir="rtl"
          centeredSlides={true}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="w-full relative justify-center swiper-slide-100"
        >
          {queues.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="relative mb-9">
                {item.status === "onCheck" && (
                  <img
                    src={GreyCard}
                    alt=""
                  />
                )}
                {item.status === "onProgress" && (
                  <img
                    src={GreenCard}
                    alt=""
                  />
                )}
                <div className="flex flex-col items-center absolute bottom-0 left-1/2 z-10" style={{ transform: "translate(-50%, -50%)" }}>
                  <h6 className="md:text-sm text-xs text-white font-normal">Antrian No.</h6>
                  <h1 className="md:text-6xl text-5xl text-white font-bold">{item.number}</h1>
                  <h6 className="md:text-sm text-xs text-white font-normal">{item.location}</h6>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="mt-4">
          <h6 className="text-customBlack md:text-lg text-base font-medium">Aktivitas</h6>
          <div className="grid md:grid-cols-7 grid-cols-4 gap-3 items-center">
            {menus.map((menu, index) => (
              <div key={index} className="cursor-pointer flex flex-col items-center" onClick={() => navigate(menu.url)}>
                <div className="bg-white rounded-[18px] shadow-ppob p-2 mb-2">
                  <img src={menu.image} alt="" className="mx-auto" />
                </div>
                <h6 className="md:text-sm text-xs text-customBlack font-medium mb-0 text-center">{menu.name}</h6>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <h6 className="text-customBlack md:text-lg text-base font-medium">Lokasi</h6>
          <div className="border-[1px] border-[#E2E2E2] bg-white rounded-lg px-3 py-2">
            <h6 className="text-newColor md:text-xs text-[10px] font-medium mb-0">Lokasi</h6>
            <div className="flex items-center justify-between border-b-[0.3px] border-b-[#DBDBDB] mb-2 py-2">
              <div className="border-l-[4px] border-l-green">
                <h6 className="text-customBlack md:text-sm text-xs font-semibold mb-1 ml-2">Rumah Sakit A Pusat</h6>
                <h6 className="text-newColor md:text-xs text-[10px] font-light mb-0 ml-2">Jl. Diponegoro, Salatiga, Kec. Sidorejo, Kota Salatiga</h6>
              </div>
              <button
                onClick={() => navigate("branch", {state: "set-location"})}
              >
                <img src={ArrowBottom} alt="" />
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <img src={TimeIcon} alt="" />
                <h6 className="md:text-xs text-[10px] text-newColor font-light mb-0">08.00 - 10.00</h6>
              </div>
              <button
                type="button"
                onClick={() => navigate("branch/location")}
                className="rounded-md bg-green px-3 py-1 text-white md:text-xs text-[10px] font-medium"
              >
                Detail
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <h6 className="text-customBlack md:text-lg text-base font-medium">Layanan</h6>
          <div className="relative flex items-center text-[#CCCCCC]">
            <button
              type="button"
              // onClick={() => getProductByName(keyword)}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color="#CCCCCC"
                className="absolute ml-3 top-[11px]"
              />
            </button>
            <input
              type="text"
              value={keyword}
              placeholder="Cari Layanan"
              onChange={(e) => {
                setKeyword(e.target.value);
                // if(e.target.value === ""){
                //   setFilteredProducts([]);
                //   setIsSearch(false);
                // }
              }}
              // onKeyDown={(e) => e.key === "Enter" && getProductByName(keyword)}
              className="w-full text-[#CCCCCC] font-medium placeholder-[#CCCCCC] rounded-[18px] border-none ring-1 ring-[#CCCCCC] focus:outline-2 focus:outline-gray-300 text-sm py-2 pr-3 pl-10"
            />
          </div>
          <div className="flex flex-col">
            {services.map((service, index) => (
              <div className="border-[1px] border-[#E2E2E2] bg-white rounded-lg flex overflow-hidden gap-3 mt-3" key={index}>
                <img src={service.image} alt="" className="w-[120px] h-[120px] object-cover" />
                <div className="flex flex-col justify-between gap-2 py-2 mr-3 w-full">
                  <div>
                    <h6 className="md:text-sm text-xs font-semibold text-customBlack mb-1">{service.name1}</h6>
                    <h6 className="md:text-sm text-xs font-normal text-customBlack mb-0">{service.name2}</h6>
                  </div>
                  <div className="flex items-end justify-between gap-2">
                    <div>
                      <h6 className="md:text-xs text-[10px] text-newColor font-medium line-through mb-1">{formatCurrency(service.price)}</h6>
                      <h6 className="md:text-sm text-xs text-customBlack font-medium mb-0">{formatCurrency(service.price)}</h6>
                    </div>
                    <div className="flex flex-col gap-2">
                      <button
                        type="button"
                        onClick={() => navigate("view")}
                        className="rounded-md border-[1px] border-newColor px-2 py-1 text-newColor md:text-xs text-[10px] font-medium"
                      >
                        Lihat Antrian
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          if(isLogin){
                            navigate("select");
                          } else {
                            navigate(`/${company?.initial}/login`);
                          }
                        }}
                        className="rounded-md bg-green px-3 py-1 text-white md:text-xs text-[10px] font-medium"
                      >
                        Ambil Antrian
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default OnlineQueueWeb