import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntityPy,
} from "../../config/global";
import { bearer } from "../../config/global";

const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getDonations = (query) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/donation/get_donation?${createQueryUrl(
    getEntityPy(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDonationById = (id) => {
  let url = `${baseUrlDev}donation/member/donation/detail/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDonationHistory = (query) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/transaction_donation/get_transaction_member?${createQueryUrl(
    getEntityPy(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDonationProgress = (id, query) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/transaction_donation/get_transaction_donationId/${id}?${createQueryUrl(
    getEntityPy(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDonationProgressById = (id) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/donation/donation_progress/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDonation = (data) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/transaction_donation/add_transaction`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const calculate_zakat = (data) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/calculate/zakat_income`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMenuDonation = () => {
  // console.log(query);
  let url = `${baseUrlDev}donation/member/config-menu`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDonationGeneral = (initialCompany) => {
  let url = `${baseUrlDev}donation/public/donation/donation-general/get_donation_general/${initialCompany}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDonationGeneral = (initialCompany,data) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/public/donation/donation-general/add_transaction/${initialCompany}`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDonationGeneralProgress = (initialCompany, query) => {
  // console.log(query);
  let url = `${baseUrlDev}donation/public/donation/donation-general/get_transaction_donation/${initialCompany}?${createQueryUrl(
    getEntityPy(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        // headers: {
        //   Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        // },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
