/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import biaya_pendidikan from "../../assets/images/icon_ppob/biaya_pendidikan.svg";
import bpjs from "../../assets/images/icon_ppob/bpjs.svg";
import e_samsat from "../../assets/images/icon_ppob/e_samsat.svg";
import internet_tv_kabel from "../../assets/images/icon_ppob/internet_tv_kabel.svg";
import listrik from "../../assets/images/icon_ppob/listrik.svg";
import pajak_pbb from "../../assets/images/icon_ppob/pajak_pbb.svg";
import pulsa from "../../assets/images/icon_ppob/pulsa_data.svg";
import air_pdam from "../../assets/images/icon_ppob/air_pdam.svg";
import ArrowDown from "../../assets/images/arrow_down.svg";
import { useAppDispatch, useAppSelector } from '../../core/feature/hooks';
import { setLoading } from '../../core/feature/config/configSlice';
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import Company from '../../core/models/company';
import { formatCurrency, formatDateTimeUTC } from '../../config/global';
import Header from '../_common/headerComponent';
import LoadMoreButton from '../_common/loadMoreButton';
import ReactDatePicker from 'react-datepicker';
import { BottomSheet } from 'react-spring-bottom-sheet';
import DataNotFound from '../_common/dataNotFound';
import { useTranslation } from 'react-i18next';

const TransactionDataMobile = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [transactionType, setTransactionType] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [showTransactionType, setShowTransactionType] = useState(false);

  const listMenu = [
    {
      name: t('ppob.creditData'),
      img: pulsa,
      url: `/${company?.initial}/digital-product/pulsa-data`,
      transactionType: "PULSE_AND_DATA"
    },
    {
      name: t('ppob.plnElectricity'),
      img: listrik,
      url: `/${company?.initial}/digital-product/pln`,
      transactionType: "PLN"
    },
    {
      name: t('ppob.waterPdam'),
      img: air_pdam,
      url: `/${company?.initial}/digital-product/pdam`,
      transactionType: "PDAM"
    },
    {
      name: t('ppob.bpjs'),
      img: bpjs,
      url: `/${company?.initial}/digital-product/bpjs`,
      transactionType: "BPJS"
    },
    {
      name: t('ppob.internetCableTv'),
      img: internet_tv_kabel,
      url: `/${company?.initial}/digital-product/internet-tv`,
      transactionType: "INTERNET_AND_TV"
    },
    {
      name: t('ppob.pbbTax'),
      img: pajak_pbb,
      url: `/${company?.initial}/digital-product/pbb`,
      transactionType: "PBB"
    },
    {
      name: t('ppob.samsat'),
      img: e_samsat,
      url: `/${company?.initial}/digital-product/e-samsat`,
      transactionType: "ESAMSAT"
    },
    {
      name: t('ppob.costEducation'),
      img: biaya_pendidikan,
      url: `/${company?.initial}/digital-product/biaya-pendidikan`,
      transactionType: "BIAYA"
    },
  ];

  const getAllTransactions = async (
    currentYear: any = year,
    currentMonth: any = month,
    currentPage: number = page,
    currentTransactionType: string = transactionType,
  ) => {
    dispatch(setLoading(true));
    let query = {
      year: currentYear,
      month: currentMonth,
      page: currentPage,
      size: 10,
      sortBy: "-createdDate",
      transactionType: currentTransactionType
    }
    await API_DIGIPROD.listTransactions(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage === 1) {
          setData(res.data.data);
        } else {
          setData(data.concat(res.data.data));
        }
        setTotalElements(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      })
  }

  const changeDate = (date: any) => {
    setMonth(date.getMonth() + 1)
    setYear(date.getFullYear())
    setStartDate(date);
  }

  const resetDate = () => {
    setShowDate(false);
    setYear("");
    setMonth("");
    getAllTransactions("", "");
  }

  const confirmDate = () => {
    setShowDate(false);
    getAllTransactions(year, month, page, transactionType);
  }

  function currentSize() {
    let a = data.length;
    let b = a / page;

    return b;
  }

  const addMore = async () => {
    setPage(page + 1);
    getAllTransactions(year, month, page + 1);
  }

  useEffect(() => {
    getAllTransactions();
  }, [])

  return (
    <section className="h-full min-h-screen relative font-montserrat bg-mobile">
      {/* MODAL */}
      <BottomSheet
        open={showDate}
        onDismiss={() => {
          resetDate();
        }}
      >
        <div className="mx-4 my-3 font-montserrat">
          <div className="flex flex-col justify-center">
            <h4 className="md:md:text-xl text-lg text-customBlack font-medium mb-0">{t('ppob.dateFilter')}</h4>
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => {
                changeDate(date);
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              maxDate={new Date()}
              className="border-[1px] border-[#C4C4C4] focus:outline-2 outline-newColor p-[13px] rounded-lg w-full mb-5"
            />
            <div className="flex items-center justify-end gap-3">
              <button
                type="button"
                className="bg-white border-[1px] border-newColor text-newColor rounded-[8px] p-[10px] w-full"
                onClick={() => resetDate()}
              >
                {t('ppob.reset')}
              </button>
              <button
                type="button"
                style={{ backgroundColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
                className="bg-green text-white rounded-[8px] p-[10px] w-full"
                onClick={() => confirmDate()}
              >
                {t('ppob.confirm')}
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>

      <BottomSheet
        open={showTransactionType}
        onDismiss={() => {
          setShowTransactionType(false);
        }}
      >
        <div className="mx-4 my-3 font-montserrat">
          <h4 className="md:md:text-xl text-lg text-customBlack font-medium mb-0">{t('ppob.selectTransaction')}</h4>
          <ul className="px-0">
            {listMenu.map((item, index) => (
              <li
                className="list-none cursor-pointer hover:bg-teal-50"
                key={index}
                onClick={() => {
                  setTransactionType(item.transactionType);
                  getAllTransactions(year, month, page, item.transactionType);
                  setShowTransactionType(false);
                }}
              >
                <div className="flex flex-row justify-between items-center px-2 pt-2">
                  <p className="md:text-base text-sm font-semibold mb-0 text-start">{item.name}</p>
                </div>
                <hr className="mt-2" />
              </li>
            ))}
          </ul>
        </div>
      </BottomSheet>
      {/* ------ */}

      <Header navbarText="Data Transaksi" />

      <div className="container flex flex-col justify-center items-center md:mt-[64px] pb-[1.5rem] mt-[0px] px-4">
        <div className="md:w-3/5 sm:w-full w-full">
          <div className="grid grid-cols-6 items-center gap-3">
            <div className="lg:col-span-2 col-span-3">
              <button
                type="button"
                onClick={() => {
                  setShowDate(true);
                  let currentYear = new Date().getFullYear();
                  let currentMonth = new Date().getMonth() + 1;
                  setYear(currentYear.toString());
                  setMonth(currentMonth.toString());
                }}
                className="w-full flex items-center justify-between border-[1px] border-[#E5E5E5] bg-[#F4F4F4] text-customBlack rounded-md py-[10px] px-3"
              >
                <span>{t('ppob.dateFilter')}</span>
                <img src={ArrowDown} alt="" />
              </button>
            </div>
            <div className="lg:col-span-4 col-span-3">
              <button
                type="button"
                onClick={() => setShowTransactionType(true)}
                className="w-full flex items-center justify-between border-[1px] border-[#E5E5E5] bg-[#F4F4F4] text-customBlack rounded-md py-[10px] px-3"
              >
                <span>{t('ppob.selectTransaction')}</span>
                <img src={ArrowDown} alt="" />
              </button>
            </div>
          </div>
          {data.length > 0 ? (
            data.map((item: any, index: number) => (
              <Link
                to={`${item?.transactionId}`}
                state={item?.transactionId}
                key={index}
                className="no-underline"
              >
                <div className="flex flex-row justify-between items-center gap-4 bg-white rounded-xl mt-4 shadow-sm md:p-[16px] p-[14px]">
                  <img src={listMenu.find((el) => el.transactionType === item?.transactionType)?.img} alt="" width={45} height={45} />
                  <div className="flex flex-col w-full">
                    <h6 className="md:text-sm text-xs text-newColor font-light text-left mb-0">
                      {formatDateTimeUTC(item.transactionDate)}
                    </h6>
                    <h6 className="md:text-lg text-base text-customBlack font-medium text-left mb-0">{listMenu.find((el) => el.transactionType === item?.transactionType)?.name || "Pembayaran"}</h6>
                    <h6 className="md:text-base text-sm text-newColor font-medium text-left mb-0">{formatCurrency(item?.totalPrice)}</h6>
                  </div>
                  {item?.status === "SUKSES" && (
                    <h6 className="md:text-lg text-base text-green font-semibold text-left mb-0">
                      {t('ppob.success')}
                    </h6>
                  )}
                  {item?.status === "PENDING" && (
                    <h6 className="md:text-lg text-base text-[#FFC107] font-semibold text-left mb-0">
                      {t('ppob.pending')}
                    </h6>
                  )}
                  {item?.status === "GAGAL" && (
                    <h6 className="md:text-lg text-base text-[#DC3545] font-semibold text-left mb-0">
                      {t('ppob.failed')}
                    </h6>
                  )}
                </div>
              </Link>
            )
            )) : (
            <DataNotFound title={t('ppob.noData')} />
          )}
        </div>
        {currentSize() >= 10 && totalElements > 10 ? (
          <LoadMoreButton addMore={addMore} />
        ) : ("")}
      </div>
    </section>
  )
}

export default TransactionDataMobile;