import fingerPrint from "@fingerprintjs/fingerprintjs";
import CryptoJS from "crypto-js";

const uint8 = new Uint8Array(4);
const decodedData = CryptoJS.enc.Base64.parse(`${uint8}`);

const keyArray = CryptoJS.lib.WordArray.create(decodedData.words.slice(4, 12));
const staticKey = CryptoJS.enc.Base64.stringify(keyArray);

// Calculate IV using MD5

export const getDeviceId = () => {
  return fingerPrint
    .load()
    .then((fp) => fp.get())
    .then((result) => {
      const visitorId = result.visitorId;
      return visitorId;
    })
    .catch((error) => {
      console.error("Error fetching device ID:", error);
      return null; // or handle the error as needed
    });
};

function calculateHmacSha256(key, data) {
  const hmac = CryptoJS.HmacSHA256(data, key);
  return hmac.toString(CryptoJS.enc.Base64);
}

// Function to perform MD5 hashing
function md5Hash(data) {
  return CryptoJS.MD5(data).toString(CryptoJS.enc.Hex);
}

export const encryptedValue = (value) => {
  const iv = md5Hash(getDeviceId()).slice(0, 16);
  // const deviceId = getDeviceId();
  const aesKey = calculateHmacSha256(staticKey, getDeviceId());
  // Calculate AES key using HMAC-SHA256
  try {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      CryptoJS.enc.Base64.parse(aesKey),
      {
        mode: CryptoJS.mode.CBC,
        iv: CryptoJS.enc.Base64.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const ciphertext = encrypted.toString();
    return ciphertext;
  } catch (error) {
    return null; // or handle the error as needed
  }
};

export const decryptedValue = (value) => {
  const iv = md5Hash(getDeviceId()).slice(0, 16);
  // const deviceId = getDeviceId();
  const aesKey = calculateHmacSha256(staticKey, getDeviceId());
  // Calculate AES key using HMAC-SHA256
  try {
    const decrypted = CryptoJS.AES.decrypt(
      value,
      CryptoJS.enc.Base64.parse(aesKey),
      {
        mode: CryptoJS.mode.CBC,
        iv: CryptoJS.enc.Base64.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const decryptedValue = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedValue)
  } catch (error) {
    return null; // or handle the error as needed
  }
};
