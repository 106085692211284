/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { BrowserView, MobileView } from "react-device-detect";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppSelector } from "../../core/feature/hooks";
import User from "../../core/models/user";
import * as API_ABSENSI from "../../core/service/api_absensi.js";
import ParkingFeaturesSubcriptionMobile from "./parkingFeaturesSubcriptionMobile";
import ParkingFeaturesSubcriptionWeb from "./parkingFeaturesSubcriptionWeb";
import Token from "../../core/models/token";
import Company from "../../core/models/company";
import { findIndex } from "lodash";
import toast from "react-hot-toast";

const ParkingFeaturesSubcription = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const [barActive, setBarActive] = useState("profit");
  const [visible, setVisible] = useState(true);
  const [config, setConfig] = useState<any>("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [active, setActive] = useState({});

  const getData = async (currentPage: number = page) => {
    let query = {
      companyId: "",
      companyInitial: "",
      email: "",
      userType: "",
      userId: "",
      loginTimeStart: "",
      loginTimeEnd: "",
      size: 10,
      page: currentPage,
      sortBy: "-createdDate",
    };
    dispatch(setLoading(true));
    await API_ABSENSI.getSubcriptions(query)
      .then((res) => {
        if (currentPage > 1) {
          setData(data.concat(res.data.data));
        } else {
          setData(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
        setSize(res.data.paging.size);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
  const addMore = async () => {
    setPage(page + 1);
    getData(page + 1);
  };

  const checkout = () => {
    dispatch(setLoading(true));
    API_ABSENSI.SubcriptionPayment()
      .then((res) => {
        dispatch(setLoading(false));
        let indexToken = findIndex(token, { cid: company.initial });
        window.location.href = `https://${res.data.data.checkoutLink}?access_token=${token[indexToken].token}`;
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getConfig = () => {
    dispatch(setLoading(true));
    API_ABSENSI.getSubcriptionConfig()
      .then((res) => {
        dispatch(setLoading(false));
        setConfig(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getActive = () => {
    dispatch(setLoading(true));
    API_ABSENSI.SubcriptionActive()
      .then((res) => {
        dispatch(setLoading(false));
        setActive(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getConfig();
    getData();
    getActive();
  }, []);

  return (
    <>
      {/* Modal switch */}

      {/* --- */}
      <BrowserView>
        <ParkingFeaturesSubcriptionWeb
          barActive={barActive}
          setBarActive={setBarActive}
          visible={visible}
          setVisible={setVisible}
          config={config}
          data={data}
          checkout={checkout}
          addMore={addMore}
          totalElements={totalElements}
          size={size}
          page={page}
          active={active}
        />
      </BrowserView>
      <MobileView>
        <ParkingFeaturesSubcriptionMobile
          barActive={barActive}
          setBarActive={setBarActive}
          visible={visible}
          setVisible={setVisible}
          config={config}
          data={data}
          checkout={checkout}
          addMore={addMore}
          totalElements={totalElements}
          size={size}
          page={page}
          active={active}
        />
      </MobileView>
    </>
  );
};

export default ParkingFeaturesSubcription;
