import { BrowserView, MobileView } from "react-device-detect";
import OrderSummaryWeb from "../orderSummaryComponent/orderSummaryWeb";
import OrderSummaryMobile from "../orderSummaryComponent/orderSummaryMobile";

const OrderSummary = () => {
  return (
    <>
      <BrowserView>
        <OrderSummaryWeb></OrderSummaryWeb>
      </BrowserView>
      <MobileView>
        <OrderSummaryMobile></OrderSummaryMobile>
      </MobileView>
    </>
  );
};

export default OrderSummary;
