import React, { useEffect, useState } from "react";
import Header from "../_common/headerComponent";
import { useLocation } from "react-router-dom";
import bg from "../../assets/images/icon_features/bg_detail.svg";
import late from "../../assets/images/icon_features/bg_failed.svg";
import BottomLogo from "../_common/bottomLogo";
import moment from "moment";
import { ConfigColor } from "../../config/global";

const AbsenFeaturesDetailWeb = () => {
  // const navigate = useNavigate();
  const color = ConfigColor();
  const [data, setData] = useState<any>([]);
  let location: any = useLocation();

  const getData = () => {
    setData(location.state?.data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <div className="bg-mobile min-h-screen">
      <Header />
      <div className="w-full font-montserrat container">
        <div className="w-1/2 mx-auto bg-mobile px-4">
          <div className="mb-5">
            <div
              className="rounded-xl p-2 text-white flex flex-col relative gap-0.5 mt-10 bg-cover mx-5 shadow"
              style={{
                backgroundImage:
                  data?.status === "LATE" ? `url(${late})` : `url(${bg})`,
                backgroundColor:
                  data?.status === "LATE" ? `#DC3545` : color.color1,
              }}
            >
              <div className="flex flex-col text-center">
                <p className="font-bold text-xl mt-3">{data?.member?.name}</p>
                <div className="flex flex-row mt-4 mb-3">
                  <div className="w-1/2 flex flex-col">
                    <span className="font-bold">ID</span>
                    <span className="text-center">{data?.member?.noId}</span>
                  </div>
                  <div className="w-1/2 flex flex-col">
                    <span className="font-bold">Device</span>
                    <span className="text-center">{data?.device?.name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-4">
            <div className="flex justify-between mb-2">
              <span>Waktu absen</span>
              <span className="text-gray-700">
                {moment(data?.attendanceTime).format("DD-MM-YYYY")},{" "}
                {moment(data?.attendanceTime).format("hh:mm:ss")} WIB
              </span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Metode Akses</span>
              <span className="text-gray-700">
                {data?.accessMethod === "QR_PRESENT"
                  ? "Pindai Kode QR"
                  : data?.accessMethod === "CARD"
                  ? "Kartu"
                  : "Buat Kode QR"}
              </span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Aktivitas</span>
              <span className="text-gray-700">
                {data?.attendanceActivity?.name}
              </span>
            </div>
            <div className="flex justify-between mb-2 text-sm">
              <span>Status</span>
              <span className="text-gray-700">
                {data?.category === "UNDEFINED_CATEGORY"
                  ? "Tidak Ditemukan"
                  : data?.category === "IN"
                  ? "Masuk"
                  : "Keluar"}
              </span>
            </div>
            <div className="mt-4">
              <span>Deskripsi</span>
              <div className="border rounded-xl p-2 text-xs text-gray-600 mt-2">
                {data?.attendanceActivity?.description}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {company?.showFtpLogo && <BottomLogo />} */}
    </div>
  );
};

export default AbsenFeaturesDetailWeb;
