import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBAirPDAMSummaryWeb from "./ppobAirPDAMSummaryWeb";
import PPOBAirPDAMSummaryMobile from "./ppobAirPDAMSummaryMobile";

const PPOBAirPDAMSummary = () => {
  return (
    <>
      <BrowserView>
        <PPOBAirPDAMSummaryWeb></PPOBAirPDAMSummaryWeb>
      </BrowserView>
      <MobileView>
        <PPOBAirPDAMSummaryMobile></PPOBAirPDAMSummaryMobile>
      </MobileView>
    </>
  );
};

export default PPOBAirPDAMSummary;
