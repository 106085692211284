import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
import { bearer } from "../../config/global";

const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getBalance = (balanceCode) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/balance/${balanceCode}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const paymentInquiryClose = (idInquiry, data, securityCodeToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrlDev}balance/transaction/member/payment/inquiry_close/${idInquiry}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
            ReAuthenticated: securityCodeToken,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const paymentInquiryOpen = (idInquiry, data, securityCodeToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrlDev}balance/transaction/member/payment/inquiry_open/${idInquiry}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
            ReAuthenticated: securityCodeToken,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMemberByNoId = (noId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/transfer/member_data/${noId}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inquiryPaymentTransferMember = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}balance/member/transfer/inquiry_payment`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inquiryPaymentTransferMemberV2 = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}balance/member/transfer/inquiry_payment/v2`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTransactionTransferMemberById = (idTransaction) => {
  let url = `${baseUrlDev}balance/member/transfer/transaction/${idTransaction}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllBankAccounts = (data) => {
  let url = `${baseUrlDev}balance/member/bank_account?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboMasterBank = () => {
  let url = `${baseUrlDev}balance/member/bank_account/combo_master_bank`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const AddBankAccount = (data) => {
  let url = `${baseUrlDev}balance/member/bank_account/v2`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const AddBankAccountV2 = (data, otp = null) => {
  let url = `${baseUrlDev}balance/member/bank_account/v3`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          "otp-security-code": otp,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inquiryPaymentTransferBankV2 = (data, otp = "000000") => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}balance/member/transfer_bank/inquiry_payment/v2`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
            "otp-security-code": otp,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inquiryPaymentTransferBank = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}balance/member/transfer_bank/inquiry_payment`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTransactionTransferBankById = (idTransaction) => {
  let url = `${baseUrlDev}balance/member/transfer_bank/transaction/${idTransaction}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// TOP UP
export const getComboAvailableBanks = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/va/combo_available_banks`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createVABank = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}balance/member/va/top_up/create_va`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getChargeVABank = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}balance/member/va/top_up/total_charge`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// LINKED ACCOUNT
export const getComboProviders = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/linked_account/combo_providers`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConnectedAccounts2 = (data) => {
  let url = `${baseUrlDev}balance/member/linked_account/connected_accounts_v2?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const connectLinkAjaAccount = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}balance/member/linked_account/link_aja/enable`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifySuccessEnableLinkAja = (data) => {
  let url = `${baseUrlDev}balance/member/linked_account/link_aja/verify_success_enable?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const registrationBankDKI = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}balance/member/linked_account/bank_dki/registration`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const confirmRegistrationBankDKI = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}balance/member/linked_account/bank_dki/confirm_registration`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createOtpActivationBankDKI = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}balance/member/linked_account/bank_dki/create_otp_activation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const validateOtpActivationBankDKI = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}balance/member/linked_account/bank_dki/validate_otp_activation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const paymentBankDKI = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}balance/member/linked_account/bank_dki/payment`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkBankDKIAccount = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}balance/member/linked_account/bank_dki/check_account`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkLinkAjaAccount = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}balance/member/linked_account/link_aja/check_account`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// WITHDRAW
export const createCpmQrCode = (data, token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}balance/member/qr_code/cpm/create`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          ReAuthenticated: token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkStatusCpmQrCode = (code) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}balance/member/qr_code/cpm/check_status?code=${code}`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// MPM
export const inquiryStaticBarcode = (code, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}balance/member/qr_code/mpm/v2/inquiry_qr_code_static?code=${code}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inquiryDynamicBarcode = (code) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}balance/member/qr_code/mpm/v2/inquiry_qr_code_dynamic?code=${code}`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMerchantProfileAndBarcodeType = (code) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}balance/member/qr_code/mpm/v2/merchant_profile_and_qr_code_type?code=${code}`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// MUTATIONS
export const getAllMemberMutations = (balanceCode, data) => {
  let url = `${baseUrlDev}balance/member/mutation/${balanceCode}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getMutationById = (data) => {
  let url = `${baseUrlDev}balance/member/mutation/transaction/detail?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboTransactionType = (balanceCode) => {
  let url = `${baseUrlDev}balance/member/mutation/${balanceCode}/v2/combo_transaction_type`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllMemberSavingMutations = (balanceCode, data) => {
  let url = `${baseUrlDev}balance/member/mutation/${balanceCode}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// SAVING

export const savingListV2 = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/balance/balance_saving_v2`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//delete

export const deleteAccountBank = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrlDev}balance/member/bank_account/${query}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboAllowedBalanceCodes = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/qr_code/combo/allowed_balance_codes`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMainBalanceMember = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/balance/main_balance`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigTopUpVA = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/top_up_va/config`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createVAClose = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}balance/member/va_close/top_up/create_va`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};