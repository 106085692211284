import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
import { bearer } from "../../config/global";

const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getCards = (data) => {
  let url = `${baseUrlDev}card/member/virtual-card/get_cards?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTemplate = () => {
  let url = `${baseUrlDev}card/member/config/get_template`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigCard = () => {
  let url = `${baseUrlDev}card/member/config/get_config_card`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigButtonOrder = () => {
  let url = `${baseUrlDev}card/member/config/config_button_order`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createCard = (data) => {
  let url = `${baseUrlDev}card/member/virtual-card/create_card`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCardDetail = (cardId) => {
  let url = `${baseUrlDev}card/member/virtual-card/get_detail_card/${cardId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCard = (cardId, data) => {
  let url = `${baseUrlDev}card/member/virtual-card/update_card/${cardId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cardSecurityCode = (cardId, data, token) => {
  let url = `${baseUrlDev}card/member/virtual-card/update_card/securityCode/${cardId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          ReAuthenticated: token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inactiveCardSecurityCode = (cardId, token) => {
  let url = `${baseUrlDev}card/member/virtual-card/update_card/securityCode/${cardId}/disable`;
  return new Promise((resolve, reject) => {
    axios
      .put(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
            ReAuthenticated: token,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const requestOtp = (cardId) => {
  let url = `${baseUrlDev}card/member/virtual-card/update_card/securityCode/otp/forgot_pin/${cardId}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetCardSecurityCode = (cardId, data) => {
  let url = `${baseUrlDev}card/member/virtual-card/update_card/securityCode/reset_pin/${cardId}`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMutations = (cardId, data) => {
  let url = `${baseUrlDev}card/member/mutation/get_mutation/${cardId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCreateCardTransactions = (data) => {
  let url = `${baseUrlDev}card/member/transaction/create_card_transaction?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCreateCardTransactionDetail = (transactionId) => {
  let url = `${baseUrlDev}card/member/transaction/create_card_transaction/detail/${transactionId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inquiryWithdrawCard = (cardId, amount) => {
  let url = `${baseUrlDev}card/member/transaction/withdraw_balance/inquiry_withdraw_card/${cardId}?amount=${amount}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDetailWithdrawBalance = (transactionId) => {
  let url = `${baseUrlDev}card/member/transaction/withdraw_balance/detail/${transactionId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDetailWithdrawMerchant = (transactionId) => {
  let url = `${baseUrlDev}card/member/transaction/withdraw_balance_merchant/detail/${transactionId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllWithdrawTransactions = (cardId, data) => {
  let url = `${baseUrlDev}card/member/transaction/withdraw_balance/${cardId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllWithdrawMerchantTransactions = (cardId, data) => {
  let url = `${baseUrlDev}card/member/transaction/withdraw_balance_merchant/${cardId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inquiryTopUpCardBalance = (cardId, amount) => {
  let url = `${baseUrlDev}card/member/transaction/top_up_balance/inquiry_top_up_card/${cardId}?amount=${amount}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllTopUpTransactions = (cardId, data) => {
  let url = `${baseUrlDev}card/member/transaction/top_up_balance/${cardId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllTopUpBalanceMerchant = (cardId, data) => {
  let url = `${baseUrlDev}card/member/transaction/top_up_balance_merchant/${cardId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDetailTopUpCardBalance = (transactionId) => {
  let url = `${baseUrlDev}card/member/transaction/top_up_balance/detail/${transactionId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTopUpBalanceMerchantDetail = (transactionId) => {
  let url = `${baseUrlDev}card/member/transaction/top_up_balance_merchant/detail/${transactionId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllTransactions = (cardId, data) => {
  let url = `${baseUrlDev}card/member/summary_transaction/${cardId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllCardTransactions = (cardId, data) => {
  let url = `${baseUrlDev}card/member/transaction/transaction_card/${cardId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(data), data),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCardTransactionDetail = (transactionId, data) => {
  let url = `${baseUrlDev}card/member/transaction/transaction_card/detail/${transactionId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadImage = (image) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("image", image);
    console.log(formData);
    axios
      .post(
        `${baseUrlDev}card/member/virtual-card/upload/profile_image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCardTemplate = () => {
  let url = `${baseUrlDev}card/member/virtual-card/get_card_byProfile`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadVirtualCardPDF = (id) => {
  let url = `${baseUrlDev}card/member/virtual-card/download_card/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPaygateRecord = (transactionId) => {
  let url = `${baseUrlDev}access/member/paygate-record/${transactionId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
