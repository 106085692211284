import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Info from "../../models/info";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";

type InitialState = {
  info: Info
}
const type = 'info'

const initialState: InitialState = {
  info: loadState(type)
}

export const infoSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setInfo: (state, action: PayloadAction<Info>) => {
      state.info = action.payload;
      saveState(type, action.payload)
    },
    removeInfo: (state) => {
      state.info = {};
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInfo, removeInfo } = infoSlice.actions;

export default infoSlice.reducer;