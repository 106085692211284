import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LoadMoreButtonProps {
  addMore: any;
}

const LoadMoreButton: FC<LoadMoreButtonProps> = (props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <button
        type="button"
        onClick={props.addMore}
        className="text-[#1AA7EC] font-semibold md:text-base text-sm hover:underline mt-5"
      >
        {t('news_information.readMore')}
      </button>
    </div>
  )
}

export default LoadMoreButton;
