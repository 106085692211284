/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPajakPBBWeb from "./ppobPajakPBBWeb";
import PPOBPajakPBBMobile from "./ppobPajakPBBMobile";
import React, { useEffect, useState } from "react";
import Company from "../../core/models/company";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { setLoading } from "../../core/feature/config/configSlice";
import { useTranslation } from "react-i18next";

const PPOBPajakPBB = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [clusterList, setClusterList] = useState<any[]>([]);
  const [pbbList, setPbbList] = useState<any[]>([]);
  const [timeList, setTimeList] = useState<any[]>([]);
  const [number, setNumber] = useState("");
  const [cluster, setCluster] = useState("");
  const [region, setRegion] = useState("");
  const [time, setTime] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [errorCluster, setErrorCluster] = useState("");
  const [errorRegion, setErrorRegion] = useState("");
  const [errorTime, setErrorTime] = useState("");
  const [pbbCode, setPbbCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [searchCluster, setSearchCluster] = useState("");
  const [searchRegion, setSearchRegion] = useState("");

  const getPbbClusterList = async (clusterName: string) => {
    dispatch(setLoading(true));
    let query = {
      clusterName,
    };
    await API_DIGIPROD.pbbClusterList(query)
      .then((res) => {
        dispatch(setLoading(false));
        setClusterList(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getPBBList = async (cluster: string, pbbName: string = "") => {
    dispatch(setLoading(true));
    let query = {
      pbbName,
      cluster,
    };
    await API_DIGIPROD.pbbList(query)
      .then((res) => {
        dispatch(setLoading(false));
        setPbbList(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getTime = () => {
    let now = new Date();
    let time = [];
    for (let i = 0; i <= 12; i++) {
      let future = new Date(now.getFullYear() - i, 1);
      let year = future.getFullYear();
      time.push(year);
    }
    setTimeList(time);
  };

  const handleOnClick = () => {
    if (cluster === "") {
      setErrorCluster(t('property_tax.choose_product_type'));
    } else if (region === "") {
      setErrorRegion(t('property_tax.choose_region'));
    } else if (time === "") {
      setErrorTime(t('property_tax.choose_payment_pbb'));
    } else if (number === "") {
      setErrorNumber(t('property_tax.enter_number_id'));
    } else {
      let query = {
        pbbNumber: number,
        pbbCode,
        year: time,
      };
      dispatch(setLoading(true));
      API_DIGIPROD.trxPBBInquiry(query)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`/${company?.initial}/digital-product/pbb/summary`, {
            state: {
              data: res.data.data,
              year: time,
            },
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  useEffect(() => {
    getPbbClusterList("");
    getTime();
  }, []);
  return (
    <>
      <BrowserView>
        <PPOBPajakPBBWeb
          company={company}
          navigate={navigate}
          dispatch={dispatch}
          clusterList={clusterList}
          setClusterList={(value: any) => setClusterList(value)}
          pbbList={pbbList}
          setPbbList={(value: any) => setPbbList(value)}
          timeList={timeList}
          setTimeList={(value: any) => setTimeList(value)}
          number={number}
          setNumber={(value: any) => setNumber(value)}
          cluster={cluster}
          setCluster={(value: any) => setCluster(value)}
          region={region}
          setRegion={(value: any) => setRegion(value)}
          time={time}
          setTime={(value: any) => setTime(value)}
          errorNumber={errorNumber}
          setErrorNumber={(value: any) => setErrorNumber(value)}
          errorCluster={errorCluster}
          setErrorCluster={(value: any) => setErrorCluster(value)}
          errorRegion={errorRegion}
          setErrorRegion={(value: any) => setErrorRegion(value)}
          errorTime={errorTime}
          setErrorTime={(value: any) => setErrorTime(value)}
          pbbCode={pbbCode}
          setPbbCode={(value: any) => setPbbCode(value)}
          showModal={showModal}
          setShowModal={(value: any) => setShowModal(value)}
          showModal2={showModal2}
          setShowModal2={(value: any) => setShowModal2(value)}
          showModal3={showModal3}
          setShowModal3={(value: any) => setShowModal3(value)}
          searchCluster={searchCluster}
          setSearchCluster={(value: any) => setSearchCluster(value)}
          searchRegion={searchRegion}
          setSearchRegion={(value: any) => setSearchRegion(value)}
          getPbbClusterList={getPbbClusterList}
          getPBBList={getPBBList}
          getTime={getTime}
          handleOnClick={handleOnClick}
        ></PPOBPajakPBBWeb>
      </BrowserView>
      <MobileView>
        <PPOBPajakPBBMobile
          company={company}
          navigate={navigate}
          dispatch={dispatch}
          clusterList={clusterList}
          setClusterList={(value: any) => setClusterList(value)}
          pbbList={pbbList}
          setPbbList={(value: any) => setPbbList(value)}
          timeList={timeList}
          setTimeList={(value: any) => setTimeList(value)}
          number={number}
          setNumber={(value: any) => setNumber(value)}
          cluster={cluster}
          setCluster={(value: any) => setCluster(value)}
          region={region}
          setRegion={(value: any) => setRegion(value)}
          time={time}
          setTime={(value: any) => setTime(value)}
          errorNumber={errorNumber}
          setErrorNumber={(value: any) => setErrorNumber(value)}
          errorCluster={errorCluster}
          setErrorCluster={(value: any) => setErrorCluster(value)}
          errorRegion={errorRegion}
          setErrorRegion={(value: any) => setErrorRegion(value)}
          errorTime={errorTime}
          setErrorTime={(value: any) => setErrorTime(value)}
          pbbCode={pbbCode}
          setPbbCode={(value: any) => setPbbCode(value)}
          showModal={showModal}
          setShowModal={(value: any) => setShowModal(value)}
          showModal2={showModal2}
          setShowModal2={(value: any) => setShowModal2(value)}
          showModal3={showModal3}
          setShowModal3={(value: any) => setShowModal3(value)}
          searchCluster={searchCluster}
          setSearchCluster={(value: any) => setSearchCluster(value)}
          searchRegion={searchRegion}
          setSearchRegion={(value: any) => setSearchRegion(value)}
          getPbbClusterList={getPbbClusterList}
          getPBBList={getPBBList}
          getTime={getTime}
          handleOnClick={handleOnClick}
        ></PPOBPajakPBBMobile>
      </MobileView>
    </>
  );
};

export default PPOBPajakPBB;
