import React, { useState } from "react";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { onlyNumber } from "../../config/global";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import Combo from "../../config/Combo";
import { setLoading } from "../../core/feature/config/configSlice";
import {
  AxisProvider,
  IndoProvider,
  SmartProvider,
  TelkomselProvider,
  ThreeProvider,
  XlProvider,
} from "../../assets/images/svg/icon";
import { useTranslation } from "react-i18next";

const listIcon = {
  XlProvider: <XlProvider />,
  SmartProvider: <SmartProvider />,
  AxisProvider: <AxisProvider />,
  IndoProvider: <IndoProvider />,
  ThreeProvider: <ThreeProvider />,
  TelkomselProvider: <TelkomselProvider />,
};

const PPOBPascabayarMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [code, setCode] = useState("");

  const deteksiOperatorSeluler = (evt: any) => {
    setErrorPhone("");
    setErrorPhone("");
    setCode("");
    let data = evt.target.value;
    let code = "";
    let prefix = data.slice(0, 4);
    if (prefix.length >= 4) {
      if (Combo.categoryPulse.axis.includes(prefix)) {
        code = "POSTPAID_XL";
        setCode(code);
      }
      if (Combo.categoryPulse.three.includes(prefix)) {
        code = "POSTPAID_TRI";
        setCode(code);
      }
      if (Combo.categoryPulse.xl.includes(prefix)) {
        code = "POSTPAID_XL";
        setCode(code);
      }
      if (Combo.categoryPulse.indosat.includes(prefix)) {
        code = "POSTPAID_MATRIX";
        setCode(code);
      }
      if (Combo.categoryPulse.telkomsel.includes(prefix)) {
        code = "POSTPAID_HALO";
        setCode(code);
      }
      if (Combo.categoryPulse.smartfren.includes(prefix)) {
        code = "POSTPAID_SMART";
        setCode(code);
      }
      setPhone(data);
    }
  };

  const clearData = () => {
    setPhone("");
    setErrorPhone("");
  };

  const handleOnClick = () => {
    if (phone === "") {
      setErrorPhone(t('topUp.insertNumber'));
    } else if (code === "") {
      setErrorPhone(t('topUp.insertNumberValid'));
    } else {
      let query = {
        code,
        phoneNumber: phone,
      };
      dispatch(setLoading(true));
      API_DIGIPROD.inquiryPostpaid(query)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(
            `/${company?.initial}/digital-product/pulsa-data/pascabayar/summary`,
            {
              state: {
                data: res.data.data,
              },
            }
          );
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  // const [configColor, setConfigColor] = useState<any>({});
  // const getConfigColor = async () => {
  //   var color1 =
  //     company?.color?.color1 != undefined ? company?.color?.color1 : "#006F54";
  //   var color2 =
  //     company?.color?.color2 != undefined ? company?.color?.color2 : "#087B5E";

  //   setConfigColor({ color1: color1, color2: color2 });
  // };

  // useEffect(() => {
  //   getConfigColor();
  // }, []);

  return (
    <>
      <section className="h-full min-h-screen font-montserrat bg-mobile">
        <Header navbarText={t('topUp.postpaid')}></Header>
        <div className="flex md:flex-row flex-col-reverse gap-5 justify-center md:mt-[64px] mt-[0px] md:px-0 px-4">
          <div className="w-full md:w-2/3 lg:w-1/2">
            <div className="flex items-center gap-3 md:mb-10">
              <div className="text-start w-full">
                <h3 className="md:text-base text-sm font-medium text-customBlack mb-[11px]">
                  {t('topUp.number')}
                </h3>
                <div className="relative">
                  <input
                    type="number"
                    placeholder={t('topUp.insertNumber')}
                    onKeyPress={onlyNumber}
                    onChange={deteksiOperatorSeluler}
                    className="bg-[#fff] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline border-[1px] border-newColor text-newColor"
                  />
                  <div className="absolute top-[10px] right-12">
                    <span>
                      {code === "POSTPAID_SMART" ? (
                        listIcon[`SmartProvider`]
                      ) : code === "POSTPAID_XL" ? (
                        listIcon[`XlProvider`]
                      ) : code === "POSTPAID_HALO" ? (
                        listIcon[`TelkomselProvider`]
                      ) : code === "POSTPAID_TRI" ? (
                        listIcon[`ThreeProvider`]
                      ) : code === "POSTPAID_MATRIX" ? (
                        listIcon[`IndoProvider`]
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                  <div className="absolute top-[13px] right-4">
                    <button
                      type="button"
                      onClick={() => clearData()}
                      className="bg-gray-500 rounded-[50%] py-0.5 w-[17px] h-[17px] text-white flex justify-center"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                </div>
                <p className="text-sm text-red pl-1 mb-0">{errorPhone}</p>
              </div>
            </div>
          </div>
          <div
            className="absolute bottom-0 left-1/2 w-full md:w-2/3 lg:w-1/2"
            style={{ transform: "translate(-50%, 0)" }}
          >
            <div className="bg-white">
              <div className="px-6 py-7">
                <button
                  type="button"
                  style={{
                    background: `${company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A"
                      }`,
                  }}
                  className="bg-green text-white font-medium text-base rounded-[10px] w-full py-[10px]"
                  onClick={() => handleOnClick()}
                >
                  {t('topUp.next')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PPOBPascabayarMobile;
