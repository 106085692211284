import React, { useEffect, useState } from "react";
import * as API_INFO from "../../core/service/api_info";
import {} from "../../core/feature/store";
import {} from "../../core/feature/store";
import { useAppSelector, useAppDispatch } from "../../core/feature/hooks";
import useDebounce from "./UseDebounce";
import Header from "../_common/headerComponent";
import { useNavigate } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import noInfo2 from "../../assets/images/icon_home/berita_kosong2.svg";
import { ConfigColor } from "../../config/global.js";
import { setLoading } from "../../core/feature/config/configSlice";
import {} from "../../core/feature/store";
import Company from "../../core/models/company.js";
import User from "../../core/models/user";
import { StatsInformation } from "../_common/Stats";
import BottomLogo from "../_common/bottomLogo";
import BottomNavigator from "../_common/bottomNavigator";
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from "react-device-detect";
import moment from "moment";
import LoadMoreButton from "../_common/loadMoreButton";
import NoImage from "../../assets/images/empty.jpg";

const NewsInformation = () => {
  const { t } = useTranslation();
  const color = ConfigColor();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const [news, setNews] = useState<any>([]);
  const [newsFilter, setNewsFilter] = useState<any>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [size] = useState(10);
  const [page, setPage] = useState(1);
  const [dir] = useState(-1);
  const [sortBy] = useState("createdTime");
  const [title, setTitle] = useState("");
  let imageNotFound =
    "https://closepay-static.sgp1.digitaloceanspaces.com/image/no-image.png";

  const goToDetail = async (data: any) => {
    navigate(`/${company.initial}/news-information/detail`, {
      state: {
        data: JSON.stringify(data),
      },
    });
  };

  const imageOnError = (event: any) => {
    event.currentTarget.src = NoImage;
    event.currentTarget.className = `${event.currentTarget.className} error`;
  };

  const getAllNews = async (currentPage: number = page) => {
    dispatch(setLoading(true));
    let data = {
      page: currentPage,
      size: size,
      sortBy: sortBy,
      dir: dir,
      subCompanyId: user?.subCompanyId,
    };

    await API_INFO.getAllNews(company?.id, data)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setNews(news.concat(res.data.data));
        } else {
          setNews(res.data.data);
        }
        setTotalElements(res.data.total);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useDebounce(
    () => {
      setNewsFilter(
        news.filter((d: any) =>
          d.title.toUpperCase().includes(title.toUpperCase())
        )
      );
    },
    [news, title],
    800
  );

  const imageRender = (image: any) => {
    if (image.length !== 0) return image[0];
    if (image === "") return imageNotFound;
    if (image !== "") return image;
  };
  function currentSize() {
    let a = news.length;
    let b = a / page;

    return b;
  }
  const addMore = async () => {
    setPage(page + 1);
    getAllNews(page + 1);
  };
  useEffect(() => {
    getAllNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <BrowserView>
        <section className="h-full min-h-screen  bg-mobile">
          <StatsInformation />
          <div className="container flex flex-col gap-3 justify-center -mt-20 z-50 relative pb-20">
            <div className="w-4/5 mx-auto bg-white p-5 rounded-2xl shadow mb-20">
              <h3>{t("news_information.titleCardNews")}</h3>
              <div className="flex justify-center">
                <input
                  type="search"
                  className="px-2 my-2 rounded-md w-full p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25"
                  placeholder={t("news_information.placeholderSearch")}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              {news.filter((el: any) => el.isPublish === true).length > 0 ? (
                <div className=" w-full mx-auto mb-4">
                  {!title ? (
                    <div className="mb-4">
                      <div className="space-y-0 mb-2">
                        <h6 className="md:text-xl text-xl text-[#252525] font-semibold m-0 font-montserrat">
                          {t("news_information.breakingNews")}
                        </h6>
                        <span className="text-xs font-light text-[#9A9999] font-montserrat">
                          {moment(Date.now()).format("LL")}
                        </span>
                      </div>
                      <div
                        onClick={() => goToDetail(news[0])}
                        className="bg-white shadow-xl shadow-[#f1f1f1] rounded p-2  hover:bg-gray-200 cursor-pointer hover:scale-105 duration-200 relative"
                      >
                        <div
                          className="absolute rounded-xl bg-[#26A69A] text-sm md:text-base text-white font-semibold p-2 text-center top-0 left-0 m-4"
                          style={{ backgroundColor: color.color1 }}
                        >
                          {t("news_information.hotNews")}
                        </div>
                        <img
                          src={imageRender(news[0]?.imageUrl)}
                          alt=""
                          className="w-full border-1 border-slate-100 mb-2 object-cover rounded md:h-96 h-72"
                          onError={imageOnError}
                        ></img>
                        <h6 className="md:text-xl text-xs text-[#252525] font-semibold mb-2 news-desc font-montserrat">
                          {news[0]?.title.length < 60
                            ? news[0]?.title
                            : news[0]?.title.toString().substring(0, 60) +
                              "..."}
                        </h6>
                        <p className="mb-3 font-normal md:text-sm text-xs text-gray-700 dark:text-gray-400 news-desc font-montserrat">
                          {news[0]?.description.length < 60
                            ? news[0]?.description
                            : news[0]?.description.toString().substring(0, 60) +
                              "..."}
                        </p>
                        <span className="md:text-sm text-[10px] text-[#9A9999] font-montserrat">
                          {moment(news[0]?.createdTime).format("LL")}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    {!title ? (
                      <div className="mb-2">
                        <h6 className="md:text-xl text-lg text-[#252525] font-semibold mb-2">
                          {t("news_information.other")}
                        </h6>
                      </div>
                    ) : (
                      ""
                    )}
                    {!title ? (
                      <div>
                        {news
                          .filter((el: any) => el.isPublish === true)
                          .map((res: any, index: any) => (
                            <div
                              key={index}
                              onClick={() => goToDetail(res)}
                              className="bg-white flex items-center border-gray-200 rounded-lg shadow-xl shadow-[#f1f1f1] md:flex-row hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 w-full p-2 space-x-2 cursor-pointer mb-2"
                            >
                              <img
                                className="object-cover min-w-36 md:w-36 md:h-36 h-24 w-24 md:rounded-none rounded border-1 border-slate-100"
                                src={imageRender(res.imageUrl)}
                                alt=""
                                onError={imageOnError}
                              />
                              <div className="flex flex-col justify-between leading-normal pl-3">
                                <h5 className="mb-2 md:text-xl text-xs text-[#252525] font-semibold tracking-tight dark:text-white news-desc">
                                  {res.title.length < 60
                                    ? res.title
                                    : res.title.toString().substring(0, 60) +
                                      "..."}
                                </h5>
                                <p className="mb-3 font-normal md:text-xs text-xs text-gray-700 dark:text-gray-400 news-desc">
                                  {res?.description.length < 60
                                    ? res?.description
                                    : res?.description
                                        .toString()
                                        .substring(0, 60) + "..."}
                                </p>
                                <span className="md:text-sm text-[10px] text-[#9A9999]">
                                  {moment(res?.createdTime).format("LL")}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div>
                        {newsFilter
                          .filter((el: any) => el.isPublish === true)
                          .map((res: any, index: any) => (
                            <div
                              key={index}
                              onClick={() => goToDetail(res)}
                              className="flex flex-row items-center border-gray-200 rounded-lg shadow-xl shadow-[#f1f1f1] md:flex-row hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 w-full p-2 space-x-2 cursor-pointer mb-2"
                            >
                              <img
                                className="flex-0 object-cover  min-w-[94px] md:w-36 md:h-36 h-24 w-24 md:rounded-none rounded border-1 border-slate-100"
                                src={imageRender(res.imageUrl)}
                                alt=""
                                onError={imageOnError}
                              />
                              <div className="grow flex flex-col justify-between leading-normal pl-3">
                                <h5 className="mb-2 md:text-xl text-sm text-[#252525] font-semibold tracking-tight dark:text-white news-desc">
                                  {res.title.length < 35
                                    ? res.title
                                    : res.title.toString().substring(0, 35) +
                                      "..."}
                                </h5>
                                <p className="mb-3 font-normal md:text-sm text-xs text-gray-700 dark:text-gray-400 news-desc">
                                  {res?.description.length < 100
                                    ? res?.description
                                    : res?.description
                                        .toString()
                                        .substring(0, 100) + "..."}
                                </p>
                                <span className="md:text-sm text-xs text-[#9A9999]">
                                  {moment(res?.createdTime).format("LL")}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                // <DataNotFound title="Data Tidak Ditemukan" />
                <div className="h-auto w-auto">
                  <img
                    src={noInfo2}
                    className="min-w-[500px] flex mx-auto"
                    alt=""
                  />
                </div>
              )}
              <div hidden={title !== ""}>
                {currentSize() >= 10 && totalElements > 10 ? (
                  <LoadMoreButton addMore={addMore} />
                ) : (
                  ""
                )}
              </div>
            </div>
            {company?.showFtpLogo && <BottomLogo />}
          </div>
        </section>
      </BrowserView>

      <MobileView>
        <section className="h-full min-h-screen pb-12">
          <div className="md:hidden">
            <Header navbarText={t("news_information.header")}></Header>
          </div>
          <div className="container flex flex-col gap-3 justify-center md:mt-[64px] mt-[0px] px-4 pb-[64px]">
            <div className="flex justify-center">
              <input
                type="search"
                className="px-2 rounded-md md:w-[60%] w-full p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25"
                placeholder={t("news_information.placeholderSearch")}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {news.filter((el: any) => el.isPublish === true).length > 0 ? (
              <div className="md:w-[60%] w-full mx-auto">
                {!title ? (
                  <div className="mb-4">
                    <div className="space-y-0 mb-2">
                      <h6 className="md:text-xl text-xl text-[#252525] font-semibold m-0 font-montserrat">
                        {t("news_information.breakingNews")}
                      </h6>
                      <span className="text-xs font-light text-[#9A9999] font-montserrat">
                        {moment(Date.now()).format("LL")}
                      </span>
                    </div>
                    <div
                      onClick={() => goToDetail(news[0])}
                      className="bg-white shadow-xl shadow-[#f1f1f1] rounded p-2  hover:bg-gray-200 cursor-pointer hover:scale-105 duration-200 relative"
                    >
                      <div
                        className="absolute rounded-xl bg-[#26A69A] text-sm md:text-base text-white font-semibold p-2 text-center top-0 left-0 m-4"
                        style={{ backgroundColor: color.color1 }}
                      >
                        {t("news_information.hotNews")}
                      </div>
                      <img
                        src={imageRender(news[0]?.imageUrl)}
                        alt=""
                        className="w-full border-1 border-slate-100 mb-2 object-cover rounded md:h-96 h-72"
                        onError={imageOnError}
                      />
                      <h6 className="md:text-xl text-xs text-[#252525] font-semibold mb-2 news-desc font-montserrat">
                        {news[0]?.title.length < 60
                          ? news[0]?.title
                          : news[0]?.title.toString().substring(0, 60) + "..."}
                      </h6>
                      <p className="mb-3 font-normal md:text-sm text-xs text-gray-700 dark:text-gray-400 news-desc font-montserrat">
                        {news[0]?.description.length < 60
                          ? news[0]?.description
                          : news[0]?.description.toString().substring(0, 60) +
                            "..."}
                      </p>
                      <span className="md:text-sm text-[10px] text-[#9A9999] font-montserrat">
                        {moment(news[0]?.createdTime).format("LL")}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div>
                  {!title ? (
                    <div className="mb-2">
                      <h6 className="md:text-xl text-lg text-[#252525] font-semibold mb-2">
                        {t("news_information.other")}
                      </h6>
                    </div>
                  ) : (
                    ""
                  )}
                  {!title ? (
                    <div>
                      {news
                        .filter((el: any) => el.isPublish === true)
                        .map((res: any, index: any) => (
                          <div
                            key={index}
                            onClick={() => goToDetail(res)}
                            className="bg-white flex items-center border-gray-200 rounded-lg shadow-xl shadow-[#f1f1f1] md:flex-row hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 w-full p-2 space-x-2 cursor-pointer mb-2"
                          >
                            <img
                              className="object-cover min-w-[94px] md:w-36 md:h-36 h-24 w-24 md:rounded-none rounded border-1 border-slate-100"
                              src={imageRender(res.imageUrl)}
                              alt=""
                              onError={imageOnError}
                            />
                            <div className="flex flex-col justify-between leading-normal pl-3">
                              <h5 className="mb-2 md:text-xl text-xs text-[#252525] font-semibold tracking-tight dark:text-white news-desc">
                                {res.title.length < 60
                                  ? res.title
                                  : res.title.toString().substring(0, 60) +
                                    "..."}
                              </h5>
                              <p className="mb-3 font-normal md:text-xs text-xs text-gray-700 dark:text-gray-400 news-desc">
                                {res?.description.length < 60
                                  ? res?.description
                                  : res?.description
                                      .toString()
                                      .substring(0, 60) + "..."}
                              </p>
                              <span className="md:text-sm text-[10px] text-[#9A9999]">
                                {moment(res?.createdTime).format("LL")}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div>
                      {newsFilter
                        .filter((el: any) => el.isPublish === true)
                        .map((res: any, index: any) => (
                          <div
                            key={index}
                            onClick={() => goToDetail(res)}
                            className="flex flex-row bg-white items-center border-gray-200 rounded-lg shadow-xl shadow-[#f1f1f1] md:flex-row hover:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 w-full p-2 space-x-2 cursor-pointer mb-2"
                          >
                            <img
                              className="flex-0 object-cover  min-w-[94px] md:w-36 md:h-36 h-24 w-24 md:rounded-none rounded border-1 border-slate-100"
                              src={imageRender(res.imageUrl)}
                              alt=""
                              onError={imageOnError}
                            />
                            <div className="grow flex flex-col justify-between leading-normal pl-3">
                              <h5 className="mb-2 md:text-xl text-sm text-[#252525] font-semibold tracking-tight dark:text-white news-desc">
                                {res.title.length < 35
                                  ? res.title
                                  : res.title.toString().substring(0, 35) +
                                    "..."}
                              </h5>
                              <p className="mb-3 font-normal md:text-sm text-xs text-gray-700 dark:text-gray-400 news-desc">
                                {res?.description.length < 100
                                  ? res?.description
                                  : res?.description
                                      .toString()
                                      .substring(0, 100) + "..."}
                              </p>
                              <span className="md:text-sm text-xs text-[#9A9999]">
                                {moment(res?.createdTime).format("LL")}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              // <DataNotFound title="Data Tidak Ditemukan" />
              <div className="h-auto w-auto flex flex-col items-center justify-center gap-2">
                <img src={noInfo2} className="flex mx-auto" alt="" />
                <p>{t("news_information.noData")}</p>
              </div>
            )}
            <div hidden={title !== ""}>
              {currentSize() >= 10 && totalElements > 10 ? (
                <LoadMoreButton addMore={addMore} />
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
        <BottomNavigator title="news" />
      </MobileView>
    </>
  );
};

export default NewsInformation;
