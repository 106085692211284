import React, { useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBWeb from "./ppobWeb";
import PPOBMobile from "./ppobMobile";

const PPOB = () => {
  useEffect(() => {
    sessionStorage.setItem("erorMessage", "PPOB");
  }, []);
  return (
    <>
      <BrowserView>
        <PPOBWeb></PPOBWeb>
      </BrowserView>
      <MobileView>
        <PPOBMobile></PPOBMobile>
      </MobileView>
    </>
  );
};

export default PPOB;
