import moment from "moment";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import closeIcon from "../../assets/images/Iconly/CloseSquare.svg";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setInfo } from "../../core/feature/info/infoSlice";
import Company from "../../core/models/company";
import * as API_INFO from "../../core/service/api_info";
import User from "../../core/models/user";

const ModalInfo = () => {
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  let dateInfo = JSON.parse(localStorage.getItem("dateInfo") as any);
  const info: any = useAppSelector((state) => state.info.info);
  const [currentIndex, setCurrentIndex] = useState(0);
  let dateNow = new Date();
  let timeNow = moment().format("HH:mm");

  const getAllInfo = () => {
    let tempInfo: any;
    let data = {
      page: 1,
      size: 10,
      sortBy: "createdTime",
      subCompanyId:user?.subCompanyId,
      dir: 1,
    };

    API_INFO.getAllInfo(company?.id, data)
      .then((res) => {
        dispatch(setLoading(false));
        if (info?.length > 0) {
          compareAndReplace(res.data.data);
        } else {
          tempInfo = res.data.data
            .filter(
              (el: any) =>
                convertToMilies(el.activeStartDate) <=
                  convertToMilies(dateNow) &&
                convertToMilies(el.activeEndDate) >= convertToMilies(dateNow) &&
                toSecond(el.showStartTime) <= toSecond(timeNow) &&
                toSecond(el.showEndTime) >= toSecond(timeNow)
            )
            .map((item: any) => {
              return {
                ...item,
                status: true,
              };
            });
          dispatch(setInfo(tempInfo));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const navigateTo = (item: any, index: any) => {
    window.open(
      `${item.redirectUrl}`,
      "_blank" // <- This is what makes it open in a new window.
    );
    let infoClose: any = [...info];
    infoClose[index] = { ...item, status: !item.status };
    dispatch(setInfo(infoClose));
    setCurrentIndex(currentIndex + 1);
  };

  const handleClose = (item: any, index: any) => {
    let infoClose: any = [...info];
    infoClose[index] = { ...item, status: !item.status };
    dispatch(setInfo(infoClose));
    setCurrentIndex(currentIndex + 1);
  };

  const toSecond = (time: any) => {
    var duration = moment.duration(time, "minutes");
    var hour = duration.hours();
    var minute = duration.minutes();
    var hourTominute = hour * 60;
    var hourTosecond = hourTominute * 60;
    var minuteTosecond = minute * 60;
    var totalSecond = minuteTosecond + hourTosecond;

    return totalSecond;
  };

  const compareAndReplace = async (data: any) => {
    const updatedArray: any = [];
    info
      .filter(
        (el: any) =>
          convertToMilies(el.activeStartDate) <= convertToMilies(dateNow) &&
          convertToMilies(el.activeEndDate) >= convertToMilies(dateNow) &&
          toSecond(el.showStartTime) <= toSecond(timeNow) &&
          toSecond(el.showEndTime) >= toSecond(timeNow)
      )
      .forEach((oldItem: any) => {
        const newItem = data.find(
          (newItem: any) => newItem._id === oldItem._id
        );
        if (newItem) {
          // Jika id sama, perbarui item lama dengan item baru
          updatedArray.push({ ...oldItem, ...newItem, status: oldItem.status });
        } else {
          // Jika id tidak ada di array baru, tandai sebagai dihapus
          updatedArray.push({ ...oldItem, status: "deleted" });
        }
      });

    // Tambahkan item baru dari array baru yang tidak ada di array lama
    data
      .filter(
        (el: any) =>
          convertToMilies(el.activeStartDate) <= convertToMilies(dateNow) &&
          convertToMilies(el.activeEndDate) >= convertToMilies(dateNow) &&
          toSecond(el.showStartTime) <= toSecond(timeNow) &&
          toSecond(el.showEndTime) >= toSecond(timeNow)
      )
      .forEach((newItem: any) => {
        if (!info.find((oldItem: any) => oldItem._id === newItem._id)) {
          updatedArray.push({ ...newItem, status: true });
        }
      });

    dispatch(
      setInfo(updatedArray.filter((item: any) => item.status != "deleted"))
    );
    dailyClick(updatedArray.filter((item: any) => item.status != "deleted"));
  };

  const convertToMilies = (date: any) => {
    var dateUtc = moment.utc(date).format("YYYY-MM-DD");

    var stillUtc = moment.utc(dateUtc).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD");
    var timeArr = moment(local).format("x");
    let int = parseInt(timeArr);

    return int;
  };

  const dailyClick = async (data: any) => {
    if (dateInfo) {
      if (dateInfo < moment.utc(dateNow).local().format("YYYY-MM-DD")) {
        const updatedArray = data.map((item: any) => {
          // Update the value of each object in the array
          if (item.recommendationDailyClick === true) {
            return { ...item, status: true };
          }
          return item;
        });

        dispatch(setInfo(updatedArray));
      }
      localStorage.setItem(
        "dateInfo",
        JSON.stringify(moment.utc(dateNow).local().format("YYYY-MM-DD")) as any
      );
    } else {
      localStorage.setItem(
        "dateInfo",
        JSON.stringify(moment.utc(dateNow).local().format("YYYY-MM-DD")) as any
      );
    }
  };

  useEffect(() => {
    getAllInfo();
  }, []);

  return (
    <div className={`relative z-50`}>
      {info?.filter((el: any) => el.status === true).length > 0 ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity">
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <BrowserView>
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Pagination, Navigation]}
                  className="mySwiper swiper-slide-info-web"
                >
                  {info?.map((item: any, index: any) =>
                    item.status === true ? (
                      <SwiperSlide key={index}>
                        <div
                          className="flex flex-col justify-center relative transform overflow-hidden w-full h-full rounded-3xl bg-white shadow-xl transition-all"
                          onClick={() => navigateTo(item, index)}
                        >
                          <img src={item.imageUrl} alt="" />
                          <div onClick={(e) => e.stopPropagation()}>
                            <button
                              type="button"
                              hidden={item.closeable === false}
                              onClick={() => {
                                handleClose(item, index);
                              }}
                              className="absolute text-gray-400 right-0 top-0 m-2"
                            >
                              <img
                                src={closeIcon}
                                alt=""
                                className="md:w-[5rem] w-[4rem] bg-black rounded"
                              />
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ) : (
                      ""
                    )
                  )}
                </Swiper>
              </BrowserView>
              <MobileView>
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Pagination, Navigation]}
                  className="mySwiper swiper-slide-info-mobile"
                >
                  {info?.map((item: any, index: any) =>
                    item.status === true ? (
                      <SwiperSlide key={index}>
                        <div
                          className="flex flex-col justify-center relative transform overflow-hidden w-full h-full rounded-3xl bg-white shadow-xl transition-all"
                          onClick={() => navigateTo(item, index)}
                          // style={{ backgroundImage: `url(${item.imageUrl})` }}
                        >
                          <img src={item.imageUrl} alt="" />
                          <div onClick={(e) => e.stopPropagation()}>
                            <button
                              type="button"
                              hidden={item.closeable === false}
                              onClick={() => {
                                handleClose(item, index);
                              }}
                              className="absolute text-gray-400 right-0 top-0 m-2"
                            >
                              <img
                                src={closeIcon}
                                alt=""
                                className="md:w-[5rem] w-[4rem]  bg-black rounded"
                              />
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ) : (
                      ""
                    )
                  )}
                </Swiper>
              </MobileView>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ModalInfo;
