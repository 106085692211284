import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPLNWeb from "./ppobPLNWeb";
import PPOBPLNMobile from "./ppobPLNMobile";

const PPOBPLN = () => {
  return (
    <>
      <BrowserView>
        <PPOBPLNWeb></PPOBPLNWeb>
      </BrowserView>
      <MobileView>
        <PPOBPLNMobile></PPOBPLNMobile>
      </MobileView>
    </>
  );
};

export default PPOBPLN;
