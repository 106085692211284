import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CreateSecurityCodeWeb from "./createSecurityCodeWeb";
import CreateSecurityCodeMobile from "./createSecurityCodeMobile";

const CreateSecurityCode = () => {
  return (
    <>
      <BrowserView>
        <CreateSecurityCodeWeb></CreateSecurityCodeWeb>
      </BrowserView>
      <MobileView>
        <CreateSecurityCodeMobile></CreateSecurityCodeMobile>
      </MobileView>
    </>
  );
};

export default CreateSecurityCode;
