import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TransferBankSuccessWeb from "./paymentSummaryWeb";
import TransferBankSuccessMobile from "./paymentSummaryMobile";

const TransferBankSuccess = () => {
  return (
    <>
      <BrowserView>
        <TransferBankSuccessWeb></TransferBankSuccessWeb>
      </BrowserView>
      <MobileView>
        <TransferBankSuccessMobile></TransferBankSuccessMobile>
      </MobileView>
    </>
  );
};

export default TransferBankSuccess;
