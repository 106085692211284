import { BrowserView, MobileView } from "react-device-detect";
import AccessFeatureDetailWeb from "./accessFeatureDetailWeb";
import AccessFeatureDetailMobile from "./accessFeatureDetailMobile";

const AccessFeatureDetail = () => {
  return (
    <>
      <BrowserView>
        <AccessFeatureDetailWeb />
      </BrowserView>
      <MobileView>
        <AccessFeatureDetailMobile />
      </MobileView>
    </>
  );
};

export default AccessFeatureDetail;
