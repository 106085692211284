import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CreatePasswordWeb from "./createPasswordWeb";
import CreatePasswordMobile from "./createPasswordMobile";

const CreatePassword = () => {
  return (
    <>
      <BrowserView>
        <CreatePasswordWeb></CreatePasswordWeb>
      </BrowserView>
      <MobileView>
        <CreatePasswordMobile></CreatePasswordMobile>
      </MobileView>
    </>
  );
};

export default CreatePassword;
