/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as API_BALANCE from "../../../core/service/api_balance";
import Header from '../../_common/headerComponent';
import Company from '../../../core/models/company';
import { useAppDispatch, useAppSelector } from '../../../core/feature/hooks';
import SuccessLogo from "../../../assets/images/security_success.png";
import { setLoading } from '../../../core/feature/config/configSlice';

const SuccessConnectAccountMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let location: any = useLocation();
  const [data, setData] = useState<any>({});
  const [successRegistration, setSuccessRegistration] = useState(false);
  const [successConnectAccount, setSuccessConnectAccount] = useState(false);

  function useQuery(){
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const verifySuccessEnableLinkAja = async() => {
    dispatch(setLoading(true));
    let data = {
      src: query.get("src"),
      trxId: query.get("trxId"),
      mandateID: query.get("mandateID"),
      refNum: query.get("refNum"),
    }
    await API_BALANCE.verifySuccessEnableLinkAja(data)
      .then((res) => {
        dispatch(setLoading(false));
        navigate(`/${company?.initial}/balance`);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      })
  }

  useEffect(() => {
    if(location.state !== null){
      if(location.state.successRegistration !== undefined){
        setSuccessRegistration(location.state.successRegistration);
      } else {
        setSuccessConnectAccount(true);
      }
      setData(location.state.data);
    } else {
      verifySuccessEnableLinkAja();
    }
  }, [])

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Sukses" />
      <div className="container md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        {successRegistration && (
          <div className="lg:w-1/2 md:w-2/3 w-full mx-auto mb-10">
            <div className="mb-7 py-2">
              <img src={SuccessLogo} alt="" height={148} width={153} className="mx-auto" />
            </div>
            <p className="md:text-lg text-base text-customBlack font-medium text-center mb-0">
              Selamat !!! Pendaftaran Anda Berhasil
            </p>
            <div className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]" style={{transform: "translate(-50%, -50%)"}}>
              <button
                type="button"
                onClick={() => navigate(`/${company?.initial}/balance`, {state: data, replace: true})}
                style={{ backgroundColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
                className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
              >
                Selesai
              </button>
            </div>
          </div>
        )}
        {successConnectAccount && (
          <div className="lg:w-1/2 md:w-2/3 w-full mx-auto mb-10">
            <div className="mb-7 py-2">
              <img src={SuccessLogo} alt="" height={148} width={153} className="mx-auto" />
            </div>
            <p className="md:text-lg text-base text-customBlack font-medium text-center mb-0">
              Selamat !!! Akun Closepay dan {data?.providerName} sudah tersambung
            </p>
            <div className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]" style={{transform: "translate(-50%, -50%)"}}>
              <button
                type="button"
                onClick={() => navigate(`/${company?.initial}/balance`, {replace: true})}
                style={{ backgroundColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
                className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
              >
                Selesai
              </button>
            </div>
          </div>
        )}
        {!successRegistration && !successConnectAccount && (
          <div className="lg:w-1/2 md:w-2/3 w-full mx-auto mb-10">
            <div className="mb-7 py-2">
              <img src={SuccessLogo} alt="" height={148} width={153} className="mx-auto" />
            </div>
            <p className="md:text-lg text-base text-customBlack font-medium text-center mb-0">
              Selamat !!! Akun Closepay dan Link Aja sudah tersambung
            </p>
            <div className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]" style={{transform: "translate(-50%, -50%)"}}>
              <button
                type="button"
                onClick={() => navigate(`/${company?.initial}/balance`, {replace: true})}
                style={{ backgroundColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
                className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
              >
                Selesai
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default SuccessConnectAccountMobile;