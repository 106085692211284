import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Company from "../../models/company";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";
import { decryptedValue, encryptedValue } from "../../../config/Encription";

type InitialState = {
  company: Company
}
const type = 'company'

function loadCompany (){
  let ciperText= loadState(type)
  let decrypted= decryptedValue(ciperText)  
  return decrypted
 }

const initialState: InitialState = {
  company: loadCompany()
}

export const companySlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
      let ciperText = encryptedValue(action.payload)
      saveState(type, ciperText)
    },
    removeCompany: (state) => {
      state.company = {};
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCompany, removeCompany } = companySlice.actions;

export default companySlice.reducer;
