import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationGeneralAddWeb from "./donationGeneralAddWeb";
import DonationGeneralAddMobile from "./donationGeneralAddMobile";

const DonationGeneralAdd = () => {
  return (
    <>
      <BrowserView>
        <DonationGeneralAddWeb></DonationGeneralAddWeb>
      </BrowserView>
      <MobileView>
        <DonationGeneralAddMobile></DonationGeneralAddMobile>
      </MobileView>
    </>
  );
};

export default DonationGeneralAdd;
