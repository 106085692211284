/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import RentDetailMobile from "./rentDetailMobile";
import RentDetailWeb from "./rentDetailWeb";

const RentDetail = () => {
  React.useEffect(() => {}, []);

  return (
    <>
      <BrowserView>
        <RentDetailWeb />
      </BrowserView>
      <MobileView>
        <RentDetailMobile/>
      </MobileView>

      {/* Modal */}

      {/* --- */}
    </>
  );
};

export default RentDetail;
