import { BrowserView, MobileView } from "react-device-detect";
import FeatureScanBarcodePayGateMobile from "./featureScanBarcodePayGateMobile";
import FeatureScanBarcodePayGateWeb from "./featureScanBarcodePayGateWeb";

const FeatureScanBarcodePayGate = () => {
  return (
    <div>
      <BrowserView>
        <FeatureScanBarcodePayGateWeb />
      </BrowserView>
      <MobileView>
        <FeatureScanBarcodePayGateMobile />
      </MobileView>
    </div>
  );
};

export default FeatureScanBarcodePayGate;
