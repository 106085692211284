import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import User from "../../models/user";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";
import { decryptedValue, encryptedValue } from "../../../config/Encription";

type InitialState = {
  user: User
}
const type = 'user'

function loadUser (){
 let ciperText= loadState(type)
 let decrypted= decryptedValue(ciperText)
 return decrypted
}

const initialState: InitialState = {
  user: loadUser()
}

export const userSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      let ciperText = encryptedValue(action.payload)
      saveState(type, ciperText)
    },
    removeUser: (state:any) => {
      state.user = {};
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
