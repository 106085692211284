/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import Check from "../../../assets/images/icon_balance/check.png";
import folder from "../../../assets/images/icon_balance/folder-open.png";
import UnCheck from "../../../assets/images/icon_balance/unCheck.png";
import { useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import { BalanceCardV2 } from "../../_common/BalanceCardV2";
import Header from "../../_common/headerComponent";
import _ from "lodash";
import toast from "react-hot-toast";
import User from "../../../core/models/user";
import { useTranslation } from "react-i18next";

const ProviderWeb = (props: any) => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <section className="h-full w-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>
      <div className="container justify-center md:mt-[64px] mt-[0px] px-4 pb-8">
        <div className="lg:w-1/2 md:w-3/5 w-full mx-auto">
          {props.connectedAccounts.map((item: any, index: any) => {
            return (
              <div key={index} className="mb-4">
                <BalanceCardV2
                  name={
                    props.providers.find((el: any) => el.code === item.provider)
                      .providerName
                      ? props.providers.find(
                          (el: any) => el.code === item.provider
                        ).providerName
                      : item.provider
                  }
                  amount={item.amount}
                  code={item.provider}
                  logo={
                    props.providers.find((el: any) => el.code === item.provider)
                      .img
                  }
                  backgroundColor={
                    props.providers.find((el: any) => el.code === item.provider)
                      .background
                  }
                  backgroundColor2={
                    props.providers.find((el: any) => el.code === item.provider)
                      .background2
                  }
                  noId={user.noId}
                />
              </div>
            );
          })}
          {props.listProvider.length != props.listConnected.length && (
            <div
              className="h-52 border-dashed border-2 border-[#728F9E] rounded-[20px] flex flex-col justify-center items-center md:w-96 md:h-60 mx-auto"
              onClick={() => setOpen(!open)}
            >
              <img src={folder} alt="" className="w-20 h-20" />
              <span className="text-[#728F9E] text-center">
                {t("balance.providers.addConnect")}
                <br /> {t("balance.providers.withEwallet")}
              </span>
            </div>
          )}
        </div>
      </div>
      <BottomSheet
        onDismiss={() => setOpen(false)}
        open={open}
        className="z-50 relative"
      >
        <div className="p-3">
          <h4 className="text-[24px] font-semibold text-center">
            {t("balance.providers.addConnect")}{" "}
            {t("balance.providers.withEwallet")}
          </h4>
          <p className="text-[14px] text-[#263339] text-center font-[300]">
            {t("balance.providers.description")}
          </p>
          <div className="mt-20">
            <span className="text-[16px] mb-2">
              {t("balance.connectAccount")}
            </span>
            {props?.listProvider?.map((item: any, index: any) => {
              return (
                <button
                  className="flex justify-between mb-2 w-full items-center cursor-pointer focus:outline-none"
                  key={index}
                  hidden={props.listConnected.includes(item.provider)}
                  onClick={() => props.selected(item)}
                >
                  <div className="flex gap-2 items-center">
                    <img
                      src={
                        props.providers.find(
                          (el: any) => el.code === item.provider
                        ).img
                      }
                      alt=""
                      className="w-10"
                    />
                    <span className="">
                      {props.providers.find(
                        (el: any) => el.code === item.provider
                      ).providerName
                        ? props.providers.find(
                            (el: any) => el.code === item.provider
                          ).providerName
                        : item.provider}
                    </span>
                  </div>
                  <div>
                    <img
                      src={
                        item.provider === props.select.code ? Check : UnCheck
                      }
                      alt=""
                    />
                  </div>
                </button>
              );
            })}
          </div>
          <button
            type="button"
            onClick={() => {
              if (!props.select) return toast.error("E Wallet belum dipilih");
              navigate(`/${company.initial}/balance/connect-account`, {
                state: props.select,
              });
            }}
            style={{
              backgroundColor: `${
                company?.color?.color1 !== undefined
                  ? company?.color?.color1
                  : "#26A69A"
              }`,
            }}
            className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px] mt-10"
          >
            {t("balance.providers.next")}
          </button>
        </div>
      </BottomSheet>
    </section>
  );
};

export default ProviderWeb;
