import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationHomeWeb from "./donationHomeWeb";
import DonationHomeMobile from "./donationHomeMobile";

const DonationHome = () => {
  return (
    <>
      <BrowserView>
        <DonationHomeWeb></DonationHomeWeb>
      </BrowserView>
      <MobileView>
        <DonationHomeMobile></DonationHomeMobile>
      </MobileView>
    </>
  );
};

export default DonationHome;
