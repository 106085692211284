import React, { useEffect, useState } from "react";
import * as API_USER from "../../core/service/api_user";
import iconSuccess from "../../assets/images/iconSuccess.png";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import closepayLogo from "../../assets/images/solusinegeri-logo.svg";
import Company from "../../core/models/company";
import desktopBackground from "../../assets/images/mobileBgBlur.svg";
import { useNavigate } from "react-router-dom";
import BottomLogo from "../_common/bottomLogo";
import { AlertPasswordStrength, checkRegex } from "../../config/global";

const CreatePasswordMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState("");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");
  const [createSuccessFully, setCreateSuccessFully] = useState(false);
  const [strength, setStrength] = useState("");

  const handlePasswordType = (type: string) => {
    switch (type) {
      case "new":
        switch (newPasswordType) {
          case "password":
            setNewPasswordType("text");
            break;
          default:
            setNewPasswordType("password");
            break;
        }
        break;

      case "confirmNew":
        switch (confirmNewPasswordType) {
          case "password":
            setConfirmNewPasswordType("text");
            break;
          default:
            setConfirmNewPasswordType("password");
            break;
        }
        break;

      default:
        setNewPasswordType("password");
        setConfirmNewPasswordType("password");
    }
  };

  const getPasswordStrength = async () => {
    dispatch(setLoading(true));
    await API_USER.getPasswordStrength(company.id)
      .then((res) => {
        dispatch(setLoading(false));
        setStrength(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const submit = async () => {
    console.log("test");

    const regexPass =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*~()-_+\-=[\]{};':"|,.<>/?]).{6,}$/;
    if (newPassword === "") {
      setErrorNewPassword("Password baru harap diisi");
    } else if (!checkRegex("password", newPassword, strength)) {
      setErrorNewPassword(AlertPasswordStrength(strength));
    } else if (confirmNewPassword === "") {
      setErrorConfirmNewPassword("Konfirmasi password baru harap diisi");
    } else if (newPassword !== confirmNewPassword) {
      setErrorConfirmNewPassword("Konfirmasi password baru tidak sesuai");
    } else {
      dispatch(setLoading(true));
      let data = {
        password: newPassword,
      };
      console.log(data);

      API_USER.changePasswordFirstLogin(data)
        .then((res) => {
          sessionStorage.removeItem("token");
          dispatch(setLoading(false));
          setCreateSuccessFully(true);
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  useEffect(() => {
    getPasswordStrength();
  }, []);

  return (
    <>
      <section
        className="h-full min-h-screen flex items-center bg-second dark:bg-black bg-cover p-4 font-montserrat"
        style={{ backgroundImage: `url(${desktopBackground})` }}
      >
        <div
          className={`${
            createSuccessFully ? "hidden" : "container font-poppins"
          }`}
        >
          <div className="flex flex-col justify-center items-center">
            <div className="lg:w-1/2 md:w-3/4 sm:w-3/4 px-5">
              <div className="w-100">
                <img
                  src={
                    company?.companyLogo !== null
                      ? company?.companyLogo
                      : closepayLogo
                  }
                  alt="icon"
                  className="md:w-72 w-32 mx-auto"
                />
              </div>
              <div className="mt-9 mb-4 w-100">
                <h4 className="text-left md:text-2xl text-xl font-semibold md:mt-2 mt-1">
                  Password
                </h4>
                <p className="text-left text-base text-thirdy font-normal">
                  Silakan masukan password baru untuk akun Anda
                </p>
              </div>
              <div className="flex flex-col gap-2 mb-3 relative">
                <label className="text-base text-thirdy font-medium pl-1 mb-0">
                  Password
                </label>
                <input
                  type={newPasswordType}
                  className="focus:border-1 rounded-full md:p-[1rem] p-[12px] text-base border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25 shadow shadow-black"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setErrorNewPassword("");
                  }}
                />
                <p className="text-base text-red pl-1 mb-0">
                  {errorNewPassword}
                </p>
                <div
                  className="absolute md:top-[50px] top-[45px] right-[20px] cursor-pointer"
                  onClick={() => handlePasswordType("new")}
                >
                  {newPasswordType === "text" ? (
                    <FontAwesomeIcon icon={faEye} size="lg" color="#C4C4C4" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      size="lg"
                      color="#C4C4C4"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 relative">
                <label className="text-base text-thirdy font-medium pl-1 mb-0">
                  Konfirmasi Password
                </label>
                <input
                  type={confirmNewPasswordType}
                  className="focus:border-1 rounded-full md:p-[1rem] p-[12px] text-base border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25 shadow shadow-black"
                  value={confirmNewPassword}
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                    setErrorConfirmNewPassword("");
                  }}
                />
                <div
                  className="absolute md:top-[50px] top-[45px] right-[20px] cursor-pointer"
                  onClick={() => handlePasswordType("confirmNew")}
                >
                  {confirmNewPasswordType === "text" ? (
                    <FontAwesomeIcon icon={faEye} size="lg" color="#C4C4C4" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      size="lg"
                      color="#C4C4C4"
                    />
                  )}
                </div>
                <p className="text-base text-red pl-1 mb-0">
                  {errorConfirmNewPassword}
                </p>
              </div>
              <div className="">
                <button
                  onClick={() => submit()}
                  className="w-full md:p-[1rem] p-[12px] mt-7 rounded-full text-white font-semibold text-base bg-[#26A69A]"
                >
                  Konfirmasi
                </button>
              </div>
              {/* <div className="mt-6 w-100">
                <p
                  className="md:text-xl text-xs font-medium text-center md:mb-3 mb-0"
                  style={{ color: "#8F8F8F" }}
                >
                  Powered By
                </p>
                <img src={closepayLogo} alt="icon" className="w-32 mx-auto" />
              </div> */}
            </div>
          </div>
        </div>
        <div
          className={`${
            createSuccessFully ? "container font-poppins" : "hidden"
          }`}
        >
          <div className="lg:w-1/2 sm:w-3/4 md:w-3/4 m-auto">
            <div className="relative items-center mt-4 mb-3">
              <img
                src={iconSuccess}
                alt="user"
                className="mx-auto md:w-max w-[180px]"
              />
            </div>
            <div className="w-100 text-center mb-3">
              <h1 className="font-bold md:text-2xl text-xl">
                Password Berhasil Dibuat
              </h1>
              <h5 className="font-normal md:text-lg text-base text-thirdy">
                {" "}
                Silakan login kembali menggunakan password baru
              </h5>
            </div>
            <div className="w-full mt-2 text-center">
              <button
                type="button"
                className="w-full md:p-3 p-[12px] mb-3 rounded-full text-white font-semibold text-base bg-[#26A69A]"
                onClick={() =>
                  navigate(`/${company?.initial}/login`, { replace: true })
                }
              >
                Login
              </button>
            </div>
          </div>
        </div>
        {company?.showFtpLogo && <BottomLogo />}
      </section>
    </>
  );
};

export default CreatePasswordMobile;
