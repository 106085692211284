// import React, { useState } from "react";
import Header from "../../_common/headerComponent";
// import * as API_USER from "../../../core/service/api_user";
// import user from "../../assets/images/userNew.png";
// import lesson from "../../../assets/images/icon_siakad/lesson.svg";
// import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
// import Company from "../../../core/models/company";
import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
// import moment from "moment";

const AttendanceMobile = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  // let dispatch = useAppDispatch();
  let navigate = useNavigate();

  // const user = [];
  const data = [
    {
      name: "umaya",
      class: "XI IPA 2",
      mapel: "Matematika",
      attendance: {
        totalPertemuan: 50,
        pertemuan: 30,
        hadir: 14,
        tanpa: 4,
        ijin: 1,
      },
    },
    {
      name: "umaya",
      class: "XI IPA 2",
      mapel: "Matematika",
      attendance: {
        totalPertemuan: 50,
        pertemuan: 10,
        hadir: 14,
        tanpa: 4,
        ijin: 1,
      },
    },
    {
      name: "umaya",
      class: "XI IPA 2",
      mapel: "Matematika",
      attendance: {
        totalPertemuan: 50,
        pertemuan: 50,
        hadir: 14,
        tanpa: 4,
        ijin: 1,
      },
    },
  ];
  // const countPertemuan = async (pertemuan: number, total: number) => {
  //   var result = (pertemuan / total) * 100;
  //   console.log(`width-[${result}%]`);
  //   var a = `width-[${result}%]`;
  //   return a;
  // };
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Absensi"></Header>

      <div className="flex flex-col px-6 py-2 gap-3 pb-4 ">
        {data.map((item: any) => (
          <section
            onClick={() => navigate("detail")}
            className="bg-white shadow-md shadow-[#00000002] rounded-lg overflow-hidden"
          >
            <div className="flex flex-col px-3 py-3 text-white bg-gradient-to-br from-[#007e5e] via-[#087B5E] to-[#0b9b77]">
              <h1 className="font-medium text-[9px]">{item.name}</h1>
              <span className="font-light text-[9px]">{item.class}</span>
              <span className="font-semibold text-xs">{item.mapel}</span>
            </div>
            <div className="flex flex-col mt-2 p-3 gap-2">
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className={`transition-transform ease-in-out bg-gradient-to-l from-[#0b9b77] via-[#006F54] to-[#087B5E] h-2.5 rounded-full`}
                  style={{
                    width:
                      (item.attendance.pertemuan /
                        item.attendance.totalPertemuan) *
                        100 +
                      "%",
                  }}
                ></div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <h1 className="font-medium text-sm m-0">Pertemuan</h1>
                <span className="text-[#9A9999] font-light text-base">
                  30 / 50
                </span>
              </div>
              <div className="flex flex-row items-center gap-2">
                <div className="flex-0 w-3 h-3 bg-white border border-solid border-[#9A9999] rounded-full"></div>
                <h1 className="grow font-medium text-sm m-0">Hadir</h1>
                <span className="flex-0 text-[#9A9999] font-light text-base">
                  14x
                </span>
              </div>

              <div className="flex flex-row items-center gap-2">
                <div className="flex-0 w-3 h-3 bg-[#EC1A1A]/5  rounded-full"></div>
                <h1 className="grow font-medium text-sm m-0">
                  Tanpa Keterangan
                </h1>
                <span className="flex-0 text-[#9A9999] font-light text-base">
                  14x
                </span>
              </div>
              <div className="flex flex-row items-center gap-2">
                <div className="flex-0 w-3 h-3 bg-[#F2F5FD]  rounded-full"></div>
                <h1 className="grow font-medium text-sm m-0">Hadir</h1>
                <span className="flex-0 text-[#9A9999] font-light text-base">
                  14x
                </span>
              </div>
            </div>
          </section>
        ))}
      </div>
    </section>
  );
};

export default AttendanceMobile;
