// import React, { useState } from "react";
import Header from "../../_common/headerComponent";
// import * as API_USER from "../../../core/service/api_user";
// import user from "../../assets/images/userNew.png";
import map from "../../../assets/images/icon_siakad/map.svg";
// import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
// import Company from "../../../core/models/company";
// import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
// import moment from "moment";

const ERaportDetailMobile = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  // let navigate = useNavigate();
  // let dispatch = useAppDispatch();

  const nilai = [
    { mapel: "Matematika", nilai: 80, kkm: 80 },
    { mapel: "Seni Budaya", nilai: 80, kkm: 80 },
    { mapel: "IPA", nilai: 40, kkm: 80 },
    { mapel: "IPS", nilai: 80, kkm: 80 },
    { mapel: "Geografi", nilai: 80, kkm: 80 },
    { mapel: "Olahraga", nilai: 80, kkm: 80 },
    { mapel: "Kimia", nilai: 70, kkm: 80 },
  ];
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="E Rapor"></Header>

      <div className="flex flex-col px-6 py-2 gap-3 pb-4">
        <div className="relative flex flex-col justify-between p-3 min-h-[185px] bg-gradient-to-br from-[#13b886] via-[#069470] to-[#13b886] text-white rounded-lg">
          <div className="absolute top-10 z-0">
            <img src={map} alt="" className="object-cover" />
          </div>
          <h1 className="font-semibold text-base mt-2 relative z-10">
            Umay Umaya
          </h1>
          <div className="grid grid-cols-3">
            <div className="flex flex-col items-start justify-center gap-1  relative z-10">
              <h1 className="ont-semibold text-base m-0">XI IPA 3</h1>
              <span className="font-light text-sm">Kelas</span>
            </div>
            <div className="flex flex-col items-center justify-center gap-1">
              <h1 className="ont-semibold text-base  m-0">1000981265</h1>
              <span className="font-light text-sm">NIS</span>
            </div>
            <div className="flex flex-col items-end justify-center gap-1">
              <h1 className="ont-semibold text-base m-0">Genap</h1>
              <span className="font-light text-sm">Semester</span>
            </div>
          </div>
        </div>
        <div className="w-full px-3 grid grid-cols-2 divide-x py-1 bg-transparent rounded-lg text-[#9A9999] border border-[#9A9999] font-semibold text-base">
          <div className="flex flex-col items-center">
            <h1 className="m-0 font-light text-base">Kehadiran</h1>
            <h1 className="m-0 font-medium text-base text-black">100</h1>
          </div>
          <div className="flex flex-col items-center">
            <h1 className="m-0 font-light text-base">Tidak Hadir</h1>
            <h1 className="m-0 font-medium text-base  text-black">2</h1>
          </div>
        </div>

        <table className="table-auto">
          <thead>
            <tr className="font-medium text-xs text-[#9A9999] text-center">
              <th>No</th>
              <th className="text-left">Nama Pelajaran</th>
              <th>KKM</th>
              <th>Nilai</th>
            </tr>
          </thead>
          <tbody>
            {nilai.map((item: any, index: number) => (
              <tr className="font-normal text-sm even:bg-[#1aa6ec09] text-[#252525] text-center">
                <td className="py-2">{index + 1}</td>
                <td className="py-2 text-left">{item.mapel}</td>
                <td className="py-2 font-semibold text-[#9A9999]">
                  {item.nilai}
                </td>
                <td className={` py-2 font-semibold`}>{item.kkm}</td>
              </tr>
            ))}
            <tr className="font-normal text-sm  text-[#00D19F]">
              <td className="text-center">Rata Rata</td>
              <td></td>
              <td></td>
              <td className="text-center">82</td>
            </tr>
            <tr className="font-normal text-sm  text-[#9A9999]">
              <td className="text-center">Rangking</td>
              <td></td>
              <td></td>
              <td className="text-center">2</td>
            </tr>
          </tbody>
        </table>
        <hr className="h-[0.5px] bg-blue-gray-400 m-px" />
        <div className="flex flex-col text-xs font-normal gap-1">
          <span className="italic underline text-[#9A9999]">Catatan</span>
          <p className="m-0">
            Ketekunan adalah kunci keberhasilan, rajin adalah kunci kesuksesan,
            perbaiki diri dengan rajin belajar agar meraih segala impian.
          </p>
          <span className="text-[#9A9999]">Bambang W, S.Pd / Wali Kelas</span>
        </div>
      </div>
    </section>
  );
};

export default ERaportDetailMobile;
