import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TransferMemberSummaryWeb from "./summaryWeb";
import TransferMemberSummaryMobile from "./summaryMobile";

const TransferMemberSummary = () => {
  return (
    <>
      <BrowserView>
        <TransferMemberSummaryWeb></TransferMemberSummaryWeb>
      </BrowserView>
      <MobileView>
        <TransferMemberSummaryMobile></TransferMemberSummaryMobile>
      </MobileView>
    </>
  );
};

export default TransferMemberSummary;
