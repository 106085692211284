/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import VirtualCardSummaryWeb from "./virtualCardSummaryWeb";
import VirtualCardSummaryMobile from "./virtualCardSummaryMobile";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import { useTranslation } from "react-i18next";

const VirtualCardSummary = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const pin: Number = useAppSelector((state) => state.pin.pin);
  let navigate = useNavigate();
  let location: any = useLocation();
  const { t } = useTranslation();
  const [dataPost, setDataPost] = useState<any[]>([]);
  const [data, setData] = useState<any>({});
  const [inquiryId, setInquiryId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [cardPrice, setCardPrice] = useState("");

  const handleOnClick = async () => {
    setShowConfirmationModal(false);
    if (pin === 1) {
      setShowModal(true);
    } else {
      navigate(`/${company?.initial}/create-security-code`, {
        replace: true,
        state: {
          data,
          inquiryId,
        },
      });
    }
  };

  useEffect(() => {
    setData(location.state.data);
    setInquiryId(location.state.inquiryId);
    let data = location.state.data;
    let detailPrice = 0;
    data.detailPrice.map((item: any) => (detailPrice += item.amount));
    setCardPrice(detailPrice.toString());
    let newData = [
      {
        property: t('virtual_card.member_name'),
        value: data.user.name,
      },
      {
        property: t('virtual_card.payment_type'),
        value: t('virtual_card.virtual_card_creation'),
      },
      {
        property: t('virtual_card.card_name'),
        value: data.cardName,
      },
      {
        property: t('virtual_card.type_card'),
        value:
          data.cardType.length > 1
            ? t('virtual_card.identity_cards_transactions')
            : t('virtual_card.identity_card'),
      },
      {
        property: t('virtual_card.paymentMethod'),
        value: t('virtual_card.balance_closeloop'),
      },
      {
        property: t('virtual_card.price_card'),
        value: data.amount,
      },
    ];
    setDataPost(newData);
  }, []);

  return (
    <>
      <BrowserView>
        <VirtualCardSummaryWeb
          handleOnClick={() => handleOnClick()}
          setShowModal={(value: any) => setShowModal(value)}
          setShowConfirmationModal={(value: any) => setShowConfirmationModal(value)}
          company={company}
          dataPost={dataPost}
          inquiryId={inquiryId}
          showModal={showModal}
          showConfirmationModal={showConfirmationModal}
          cardPrice={cardPrice}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <VirtualCardSummaryMobile
          handleOnClick={() => handleOnClick()}
          setShowModal={(value: any) => setShowModal(value)}
          setShowConfirmationModal={(value: any) => setShowConfirmationModal(value)}
          company={company}
          dataPost={dataPost}
          inquiryId={inquiryId}
          showModal={showModal}
          showConfirmationModal={showConfirmationModal}
          cardPrice={cardPrice}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default VirtualCardSummary;
