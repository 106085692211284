import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
import { bearer } from "../../config/global";

const axios = require("axios");
const baseUrlDev = Config.BaseUrlDev;

export const getNotification = (data) => {
  return new Promise((resolve, reject) => {
    let url = `${baseUrlDev}mob_user/get_notifs?${createQueryUrl(
      getEntity(createArrayEntityFromObject(data), data),
      "&"
    )}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getNotificationMember = (data) => {
  return new Promise((resolve, reject) => {
    let url = `${baseUrlDev}notification/member/search?${createQueryUrl(
      getEntity(createArrayEntityFromObject(data), data),
      "&"
    )}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getNotificationDetailMember = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}notification/member/view/${id}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateRead = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrlDev}notification/member/mark_readed/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getNotificationStatistic = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}notification/member/statistic`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
