import React from "react";
import Header from "../_common/headerComponent";
import kirim_ke_bank from "../../assets/images/icon_transfer/kirim_ke_bank_icon.svg";
import kirim_ke_member from "../../assets/images/icon_transfer/kirim_ke_member_icon.svg";
// import arrow_left from "../../assets/images/icon_transfer/arrow_left_icon.svg";
import { Link } from "react-router-dom";
import BottomLogo from "../_common/bottomLogo";
import { BalanceCard } from "../_common/BalanceCard";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
// import pattern from "../../assets/images/icon_balance/pattern.svg";

const data = [
  {
    id: "1",
    title: "Kirim Ke Member",
    img: kirim_ke_member,
    url: "member",
  },
  {
    id: "2",
    title: "Kirim ke Rekening Bank ",
    img: kirim_ke_bank,
    url: "bank",
  },
];

const TransferMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Transfer"></Header>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div className="md:w-3/5 w-full mx-auto">
          <BalanceCard />
          <div className="w-full">
            <h3 className="md:text-lg text-base text-start font-medium mb-[1rem]">
              Virtual Acount Payment
            </h3>

            {data.map((item) => (
              <Link to={item.url} key={item.id} className="no-underline">
                <div className="flex flex-row justify-between items-center gap-2.5 bg-white rounded-xl py-3 px-7 md:mb-10 mb-4 shadow-xl shadow-[#00000005]">
                  <img src={item.img} alt="" className="w-8 h-8" />
                  <div className="text-start w-full">
                    <h3 className="md:text-lg text-sm font-medium text-black mb-0">
                      {item.title}
                    </h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </section>
  );
};

export default TransferMobile;
