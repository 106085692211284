import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useLocation } from "react-router-dom";
import * as API from "../../../core/service/api_auth";
import * as API_USER from "../../../core/service/api_user";
import { setUser } from "../../../core/feature/user/userSlice";
import { setUserLogin } from "../../../core/feature/userLogin/userLoginSlice";
import { useAppSelector } from "../../../core/feature/hooks";
import { findIndex } from "lodash";
import Token from "../../../core/models/token";
import { setToken } from "../../../core/feature/token/tokenSlice";
import { setCompany } from "../../../core/feature/company/companySlice";

const GatewayAuthorizationMember = () => {
  const dispatch = useDispatch();
  const token: Token[] = useAppSelector((state) => state.token.token);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let cid = window.location.pathname.split("/")[2];
  let query = useQuery();
  let code = query.get("code");

  const cekToken = async () => {
    await API.gatewayAuthorizationMember(cid, code)
      .then((res) => {
        const data: any = res.data.data;
        getCompanyInitial(data.companyInitial);
        let userLogin = [
          {
            token: data.authToken,
            id: data.noId,
            initial: data.companyInitial,
          },
        ];
        var newToken = token ? [...token] : [];
        let indexToken = findIndex(token, {
          cid: res.data.initial,
        });

        if (indexToken < 0) {
          newToken.push({
            cid: res.data.initial,
            token: data.authToken,
          });
        } else {
          newToken[indexToken] = {
            cid: res.data.initial,
            token: data.authToken,
          };
        }
        dispatch(setToken(newToken));
        dispatch(setUser(data));
        dispatch(setUserLogin(userLogin));
        redirect(res.data.initial, res.data.path);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCompanyInitial = async (init: any) => {
    await API_USER.getCompany(init)
      .then((res) => {
        dispatch(setCompany(res.data.data));
      })
      .catch((err) => {});
  };

  const redirect = (init: any, res: any) => {
    document.location.href = `/${init}${res}`;
  };

  useEffect(() => {
    cekToken();
  }, []);

  return <div></div>;
};

export default GatewayAuthorizationMember;
