import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationAddWeb from "./donationAddWeb";
import DonationAddMobile from "./donationAddMobile";

const DonationAdd = () => {
  return (
    <>
      <BrowserView>
        <DonationAddWeb></DonationAddWeb>
      </BrowserView>
      <MobileView>
        <DonationAddMobile></DonationAddMobile>
      </MobileView>
    </>
  );
};

export default DonationAdd;
