import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ProductListWeb from "./productListWeb";
import ProductListMobile from "./productListMobile";
import Company from "../../../core/models/company";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import * as API_USER from "../../../core/service/api_user";
import { useNavigate, useParams } from "react-router-dom";
import { setCompany } from "../../../core/feature/company/companySlice";
import { setLoading } from "../../../core/feature/config/configSlice";
import toast from "react-hot-toast";
import ToastConfig from "../../../config/Toast";
import {
  loadState,
  removeState,
  saveState,
} from "../../../core/feature/browser-storage/browser-storage";

const ProductList = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let params = useParams();
  const [data, setData] = React.useState<any[]>([]);
  const [categories, setCategories] = React.useState<any[]>([]);
  const [cartData, setCartData] = React.useState<any[]>([]);
  const [detailData, setDetailData] = React.useState<any>({});
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});
  const [priceList, setPriceList] = React.useState<any>({});
  const [selectedItem, setSelectedItem] = React.useState<any>({});
  const [open, setOpen] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState(false);
  const [hiddenButton, setHiddenButton] = React.useState(true);
  const [keyword, setKeyword] = React.useState("");
  const [merchantName, setMerchantName] = React.useState("");
  const [quantity, setQuantity] = React.useState(1);
  const [cartQuantity, setCartQuantity] = React.useState(0);

  let cart_data = loadState("cart_data");

  const getCatalog = async (
    companyId: any,
    categoryId: string = "",
    productName: string = ""
  ) => {
    let query = {
      categoryId,
      productName,
    };
    dispatch(setLoading(true));
    await API_COMMERCE.getCatalog(companyId, params.merchantId, query)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        let tempPriceList: any = {};
        res.data.data.map((item: any) => {
          if (item.productItems.length > 1) {
            let tempMinMax: any = [];
            item.productItems.map((el: any) => {
              return tempMinMax.push(el.finalPrice);
            });
            let price = {
              maxPrice: Math.max(...tempMinMax),
              minPrice: Math.min(...tempMinMax),
            };
            tempPriceList = { ...tempPriceList, [item.id]: price };
          }
          return "";
        });
        setPriceList(tempPriceList);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getShopDetail = async (companyId: string) => {
    dispatch(setLoading(true));
    await API_COMMERCE.getShopDetailNoLogin(companyId, params.merchantId)
      .then((res) => {
        dispatch(setLoading(false));
        setMerchantName(res.data.data.name);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getFnbCategory = async (companyId: string) => {
    let query = {};
    dispatch(setLoading(true));
    await API_COMMERCE.getFnbCategory(companyId, params.merchantId, query)
      .then((res) => {
        dispatch(setLoading(false));
        setCategories(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCompany = async (companyId: string) => {
    dispatch(setLoading(true));
    await API_USER.getCompany(companyId.toUpperCase())
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setCompany(res.data.data));
        getCatalog(res.data.data.id);
        getFnbCategory(res.data.data.id);
        getShopDetail(res.data.data.id);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCatalogDetail = async (id: string) => {
    dispatch(setLoading(true));
    await API_COMMERCE.getCatalogDetail(company?.id, params?.merchantId, id)
      .then((res) => {
        dispatch(setLoading(false));
        setDetailData(res.data.data);
        if (res.data.data.desc.length > 446) setHiddenButton(false);
        setSelectedItem(res.data.data.productItems[0]);
      })
      .catch((err) => dispatch(setLoading(false)));
  };

  const AddToCart = () => {
    let tempCartData: any = cartData;
    let newCartData = {
      item: selectedItem,
      quantity,
    };
    if (cart_data === undefined) {
      tempCartData.push(newCartData);
      setCartData(tempCartData);
      saveState("cart_data", tempCartData);
    } else {
      console.log(tempCartData);
      let findData = tempCartData.find(
        (el: any) => el.item.id === selectedItem.id
      );
      if (findData === undefined) {
        tempCartData.push(newCartData);
        setCartData(tempCartData);
        saveState("cart_data", tempCartData);
      } else {
        findData.quantity += quantity;
        setCartData(tempCartData);
        saveState("cart_data", tempCartData);
      }
    }
    toast("Ditambahkan ke keranjang", ToastConfig.success);
  };

  React.useEffect(() => {
    if (cart_data !== undefined) {
      if (cart_data[0].item.companyId !== company?.id) {
        removeState("cart_data");
        setCartQuantity(0);
      } else {
        let tempQuantity = 0;
        cart_data.map((el: any) => (tempQuantity += el.quantity));
        setCartQuantity(tempQuantity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart_data]);

  React.useEffect(() => {
    if (params.cid !== undefined) getCompany(params.cid);
    if (cart_data !== undefined) {
      setCartData(cart_data);
    }
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      setShowDetail(false);
      window.history.pushState(null, document.title, window.location.href);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    var x: any = localStorage.getItem("orderData");
    var y: any = JSON.parse(x);
    if (localStorage.getItem("orderData") !== null) {
      return navigate(`cart/${y?.transactionId}/order`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <BrowserView>
        <ProductListWeb
          company={company}
          navigate={navigate}
          dispatch={dispatch}
          params={params}
          data={data}
          categories={categories}
          detailData={detailData}
          selectedCategory={selectedCategory}
          priceList={priceList}
          selectedItem={selectedItem}
          open={open}
          showDetail={showDetail}
          hiddenButton={hiddenButton}
          keyword={keyword}
          merchantName={merchantName}
          cartQuantity={cartQuantity}
          getCatalogDetail={getCatalogDetail}
          AddToCart={AddToCart}
          setData={(value: any) => setData(value)}
          setCategories={(value: any) => setCategories(value)}
          setCartData={(value: any) => setCartData(value)}
          setDetailData={(value: any) => setDetailData(value)}
          setSelectedCategory={(value: any) => setSelectedCategory(value)}
          setPriceList={(value: any) => setPriceList(value)}
          setSelectedItem={(value: any) => setSelectedItem(value)}
          setOpen={(value: any) => setOpen(value)}
          setShowDetail={(value: any) => setShowDetail(value)}
          setHiddenButton={(value: any) => setHiddenButton(value)}
          setKeyword={(value: any) => setKeyword(value)}
          setMerchantName={(value: any) => setMerchantName(value)}
          setQuantity={(value: any) => setQuantity(value)}
          setCartQuantity={(value: any) => setCartQuantity(value)}
          cart_data={cart_data}
          getCatalog={getCatalog}
          quantity={quantity}
        ></ProductListWeb>
      </BrowserView>
      <MobileView>
        <ProductListMobile
          company={company}
          navigate={navigate}
          dispatch={dispatch}
          params={params}
          data={data}
          categories={categories}
          detailData={detailData}
          selectedCategory={selectedCategory}
          priceList={priceList}
          selectedItem={selectedItem}
          open={open}
          showDetail={showDetail}
          hiddenButton={hiddenButton}
          keyword={keyword}
          merchantName={merchantName}
          cartQuantity={cartQuantity}
          getCatalogDetail={getCatalogDetail}
          AddToCart={AddToCart}
          setData={(value: any) => setData(value)}
          setCategories={(value: any) => setCategories(value)}
          setCartData={(value: any) => setCartData(value)}
          setDetailData={(value: any) => setDetailData(value)}
          setSelectedCategory={(value: any) => setSelectedCategory(value)}
          setPriceList={(value: any) => setPriceList(value)}
          setSelectedItem={(value: any) => setSelectedItem(value)}
          setOpen={(value: any) => setOpen(value)}
          setShowDetail={(value: any) => setShowDetail(value)}
          setHiddenButton={(value: any) => setHiddenButton(value)}
          setKeyword={(value: any) => setKeyword(value)}
          setMerchantName={(value: any) => setMerchantName(value)}
          setQuantity={(value: any) => setQuantity(value)}
          setCartQuantity={(value: any) => setCartQuantity(value)}
          cart_data={cart_data}
          getCatalog={getCatalog}
          quantity={quantity}
        ></ProductListMobile>
      </MobileView>
    </>
  );
};

export default ProductList;
