/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import Header from "../../_common/headerComponent";
import LinkedAccountCard from "../../_common/linkedAccountCard";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import User from "../../../core/models/user";

const ConnectAccountMobile = (props: any) => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  let navigate = useNavigate();
  let location: any = useLocation();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    setData(location.state);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={t("balance.connectAccount")} />
      <div className="md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div className="lg:w-1/2 md:w-3/5 w-full mx-auto mb-10">
          <LinkedAccountCard data={data} />
          <div className="mt-12 md:w-4/5 w-full mx-auto">
            <div className="text-center mb-3">
              <h6 className="md:text-lg text-base text-customBlack font-semibold mb-0">
                {" "}
                {t("balance.providers.easyPay")}
              </h6>
              <p className="md:text-base text-sm text-customBlack font-light mb-0">
                {t("balance.providers.description")}
              </p>
            </div>
            <div className="text-start">
              <h6 className="md:text-lg text-base text-customBlack font-semibold">
                Tiga langkah mudah cara menghubungkan akun
              </h6>
              {data.code === "BANK_DKI" ? (
                <ol className="list-decimal px-3">
                  {_.map(props.proceduresBankDki, (el: any, index: any) => (
                    <li
                      className="md:text-base text-sm text-start px-2"
                      key={index}
                    >
                      {user.lang == "en" ? el.displayEn : el.display}
                    </li>
                  ))}
                </ol>
              ) : (
                <ol className="list-decimal px-3">
                  {_.map(props.proceduresLinkAja, (el: any, index: any) => (
                    <li
                      className="md:text-base text-sm text-start px-2"
                      key={index}
                    >
                      {user.lang == "en"
                        ? el.displayEn.replaceAll(
                            "{data.providerName}",
                            `${data.providerName}`
                          )
                        : el.display.replaceAll(
                            "{data.providerName}",
                            `${data.providerName}`
                          )}
                    </li>
                  ))}
                </ol>
              )}
            </div>
          </div>
          {data.code === "BANK_DKI" && (
            <p className="md:text-sm text-xs text-customBlack text-center font-light mt-5 mb-0">
              {" "}
              {t("balance.providers.notYetAccount")}{" "}
              <Link
                to={`/${company?.initial}/balance/connect-account/bank-dki-registration`}
                state={data}
                className="text-[#1AA7EC] font-medium no-underline"
              >
                {" "}
                {t("balance.providers.register")}
              </Link>
            </p>
          )}
          {/* <div className="w-full mb-6 mt-5">
            <button 
              type="button"
              onClick={() => navigate("input-number", {state: data})}
              className="bg-green text-white font-bold rounded-xl w-full md:p-[1rem] p-[12px]"
            >
              Selanjutnya
            </button>
          </div> */}
          <div
            className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <button
              type="button"
              onClick={() => navigate("input-number", { state: data })}
              style={{
                backgroundColor:
                  company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
              }}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              {t("balance.providers.next")}{" "}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectAccountMobile;
