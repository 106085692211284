import moment from "moment";
import { formatCurrency, camelCase } from "../../config/global";
import { ConfigColor, hexToRgba } from "../../config/global";
import { useNavigate } from "react-router-dom";
import arrow from "../../assets/images/arrow-left-ic.svg";
import Success from "../../assets/images/iconSuccess.svg";
import Failed from "../../assets/images/security-fail.png";
import { useTranslation } from "react-i18next";

const PayGateFeaturesDetailMobile = (props: any) => {
  const color = ConfigColor();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="w-full font-montserrat bg-mobile min-h-screen h-fit flex flex-col">
      <section
        className="flex flex-tow items-center z-50 px-4 py-3"
        style={{
          backgroundColor: `${color.color1}`,
        }}
      >
        <div
          onClick={() =>
            props.backToUrl ? navigate(`${props.backToUrl}`) : navigate(-1)
          }
          className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300 bg-white"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>

        <h1
          className={`font-montserrat font-bold text-base m-auto relative text-white`}
        >
          {t('accessibility.paygate')}
        </h1>
      </section>

      <div
        className="grow w-full h-full mx-auto px-4 pb-4 md:pt-10"
        style={{
          backgroundColor: `${color.color1}`,
        }}
      >
        <div className="bg-white md:w-3/4 w-full mx-auto rounded-[14px] p-8 mb-4 mt-10 ">
          {props.data.status === "PAID" ? (
            <div className="flex flex-col justify-center items-center">
              <img src={Success} alt="" className="w-[4.5rem] h-[4.5rem]" />
              <span className=" md:text-lg text-base font-semibold text-[#26A69A]">
                {t('accessibility.access_succes')}
              </span>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <img src={Failed} alt="" className="w-[4.5rem] h-[4.5rem]" />
              <span className=" md:text-lg text-base font-semibold text-[#DC3545]">
                {t('accessibility.access_failed')}
              </span>
            </div>
          )}
          <div className="flex justify-between items-center border-b-[1px] border-b-[#E5E5E5] border-dotted mb-2 pb-2 relative mt-4">
            <div className="text-start">
              <h6 className="font-semibold text-customBlack md:text-lg text-base m-0">
                {t('accessibility.Info')}
              </h6>
              <span className="md:text-sm text-xs text-newColor">
                {moment(props.data?.loginTime).format("dddd, DD MMMM YYYY")}
              </span>
            </div>
            {/* <div className="text-end">
              <h6 className="md:text-sm text-xs text-newColor m-0">Status</h6>
              <span
                className={`${
                  props.data?.status === "LOGIN"
                    ? "text-green"
                    : "text-[#FF9723]"
                } font-semibold md:text-lg text-base`}
              >
                {props.data?.status === "LOGIN" ? "Checkin" : "Checkout"}
              </span>
            </div> */}
            <div className="absolute flex justify-between mt-5 w-full">
              <div
                className="w-7 h-7 rounded-full -ml-12"
                style={{
                  backgroundColor: `${color.color1}`,
                }}
              ></div>
              <div
                className="w-7 h-7  rounded-full -mr-12"
                style={{
                  backgroundColor: `${color.color1}`,
                }}
              ></div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="text-start">
              <h6 className="md:text-sm text-xs text-newColor m-0">
                {t('accessibility.in_date')}
              </h6>
              <span className="text-customBlack font-semibold">
                {moment
                  .utc(props.data?.loginTime)
                  .local()
                  .format("DD-MM-YYYY, HH:mm:ss")}
              </span>
            </div>
            <div className="text-start">
              <h6 className="md:text-sm text-xs text-newColor m-0">{t('accessibility.device')}</h6>
              <span className="text-customBlack font-semibold">
                {props.data?.deviceName}
              </span>
            </div>
            <div className="text-start">
              <h6 className="md:text-sm text-xs text-newColor m-0">{t('accessibility.paygate_vehicle')}</h6>
              <span className="text-customBlack font-semibold">
                {props.data?.paygateActivity?.name}
              </span>
            </div>
            <div className="text-start">
              <h6 className="md:text-sm text-xs text-newColor m-0">{t('accessibility.method')}</h6>
              <span className="text-customBlack font-semibold">
                {props.data.accessMethod === "CARD"
                  ? t('accessibility.card')
                  : props.data.accessMethod === "QR_LISTEN"
                    ? t('accessibility.create_code_qr')
                    : t('accessibility.scan_qr')}
              </span>
            </div>
            <div className="text-start">
              <h6 className="md:text-sm text-xs text-newColor m-0">
                {t('accessibility.category_user')}
              </h6>
              <span className="text-customBlack font-semibold">
                {props.data?.memberTags}
              </span>
            </div>
          </div>
        </div>

        <div className="bg-white md:w-3/4 w-full mx-auto rounded-[14px] px-8 py-[12px] md:mb-72">
          <div className="flex justify-between items-center gap-2 border-b-[1px] border-b-[#E5E5E5] border-dotted mb-2 pb-2">
            <h6 className="md:text-lg text-base font-semibold m-0">
              {t('accessibility.payment_detail')}
            </h6>
            <div
              className={`${props.data.status === "PAID" ? "bg-green" : "bg-[#DC3545]"
                } rounded-[4px] py-1 px-2 md:text-sm text-xs text-white font-medium`}
            >
              {props.data.status === "PAID" ? (
                <span>{t('accessibility.paid_off')}</span>
              ) : (
                <span>{t('accessibility.not_yet_paid')}</span>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center gap-2">
            <div className="text-start">
              <h6 className=" text-newColor md:text-sm text-xs m-0"> {t('accessibility.nominal')}</h6>
            </div>
            <div className="text-start">
              <span className="md:text-sm text-xs text-customBlack font-semibold">
                {formatCurrency(props.data?.price)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayGateFeaturesDetailMobile;
