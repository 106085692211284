import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import menuTopup from "../../assets/images/icon_balance/menuTopup.svg";
import menuTransferBank from "../../assets/images/icon_balance/menuTranferBank.svg";
import menuTransferMember from "../../assets/images/icon_balance/menuTransferMember.svg";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import * as API_BALANCE from "../../core/service/api_balance";
import * as API_USER from "../../core/service/api_user";
import BalanceMobile from "./balanceMobile";
import BalanceWeb from "./balanceWeb";
import { setBalance } from "../../core/feature/user/balanceSlice";

const menu = [
  {
    name: "topup",
    display: "Top up",
    displayEn: null,
    imageUrl: menuTopup,
    url: "topup",
  },
  {
    name: "transfer_bank",
    display: "Transfer Bank",
    displayEn: null,
    imageUrl: menuTransferBank,
    url: "transfer/bank",
  },
  {
    name: "transfer_member",
    display: "Transfer Member",
    displayEn: null,
    imageUrl: menuTransferMember,
    url: "transfer/member",
  },
];

const Balance = () => {
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const [listMenu, setListMenu] = useState<any>([]);
  const [mutation, setMutation] = useState<any>([]);
  const [listSaldo, setListSaldo] = useState<any>([]);
  const [detail, setDetail] = useState("");
  const [connectted, setConnected] = useState([]);

  const getAllTransactions = async (balanCode = "CLOSEPAY") => {
    dispatch(setLoading(true));
    let query = {
      page: 0,
      size: 5,
      sortBy: "createTime",
      dir: -1,
    };
    await API_BALANCE.getAllMemberSavingMutations(balanCode, query)
      .then((res) => {
        dispatch(setLoading(false));
        setMutation(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getBalance = async () => {
    await API_BALANCE.getBalance("CLOSEPAY")
      .then((res) => {
        dispatch(setBalance(res.data.data.amount));
        setDetail({ ...res.data.data, type: "main_balance" });
        getSavingList(res.data.data);
      })
      .catch((err) => { });
  };

  const getSavingList = async (data: any) => {
    dispatch(setLoading(true));
    await API_BALANCE.savingListV2()
      .then((res) => {
        dispatch(setLoading(false));
        setListSaldo([{ ...data, type: "main_balance" }, ...res.data.data]);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getMenuList = () => {
    dispatch(setLoading(true));
    API_USER.balanceMenuName(company?.id)
      .then((res) => {
        setListMenu(res.data.data);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const changeSaldo = (data: any) => {
    setDetail(data);
    getAllTransactions(data.balanceCode);
  };

  const getConnected = async () => {
    dispatch(setLoading(true));

    API_BALANCE.getConnectedAccounts2("")
      .then((res) => {
        dispatch(setLoading(false));
        setConnected(res.data.data.linkedAccounts);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getAllTransactions();
    getMenuList();
    getBalance();
    getConnected();
  }, []);

  return (
    <>
      <BrowserView>
        <BalanceWeb
          mutation={mutation}
          listMenu={listMenu}
          menu={menu}
          listSaldo={listSaldo}
          detail={detail}
          changeSaldo={(value: any) => changeSaldo(value)}
          connectted={connectted}
        />
      </BrowserView>
      <MobileView>
        <BalanceMobile
          mutation={mutation}
          listMenu={listMenu}
          menu={menu}
          listSaldo={listSaldo}
          detail={detail}
          changeSaldo={(value: any) => changeSaldo(value)}
          connectted={connectted}
        ></BalanceMobile>
      </MobileView>
    </>
  );
};

export default Balance;
