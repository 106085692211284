import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ERaportDetailMobile from "./eRaportDetailMobile";
import ERaportDetailWeb from "./eRaportDetailWeb";

const ERaportDetail = () => {
  return (
    <>
      <BrowserView>
        <ERaportDetailWeb></ERaportDetailWeb>
      </BrowserView>
      <MobileView>
        <ERaportDetailMobile></ERaportDetailMobile>
      </MobileView>
    </>
  );
};

export default ERaportDetail;
