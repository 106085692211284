import React, { useEffect, useState } from "react";
import Header from "../_common/headerComponent";
import fill from "../../assets/images/icon_features/filter.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import BottomLogo from "../_common/bottomLogo";
import moment from "moment";
import DataNotFound from "../_common/dataNotFound";
import ReactDatePicker from "react-datepicker";
import calendar from "../../assets/images/icon_features/calendarlogo.svg";
import LoadMoreButton from "../_common/loadMoreButton";
import { absenPlus } from "../../config/configIcon";
import { hexToRgba } from "../../config/global";
import icon_in from "../../assets/parking/icon_in.svg";
import icon_out from "../../assets/parking/icon_out.svg";
import icon_close from "../../assets/parking/icon_close.svg";
import bg from "../../assets/images/icon_features/bg_detail.svg";
import late from "../../assets/images/icon_features/bg_failed.svg";
import { useTranslation } from "react-i18next";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";

const AbsenFeaturesMobile = (props: any) => {
  const [openDetail, setOpenDetail] = useState(false);
  const [data, setData] = useState<any>();
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);

  return (
    <div className="w-full h-full block min-h-screen bg-white">
      <div className="w-full bg-white h-full relative font-montserrat pb-36">
        <Header navbarText={props.name} />
        <div className="w-full px-4 flex gap-3 b-w">
          <button
            onClick={() => {
              props.setConfirm(true);
              props.setOpenMethod(true);
            }}
            className=" text-[16px] font-semibold flex bg-opacity-10 py-2.5 px-3 rounded-lg w-full"
            style={{
              color: `${props.color.color1}`,
              backgroundColor: hexToRgba(props.color.color1, 0.1),
            }}
          >
            {absenPlus(props.color)}{" "}
            <span className="ml-2">
              {t("accessibility.attendance_with_qr")}
            </span>
          </button>
        </div>
        <div className="w-full p-4 mt-2 bg-white">
          <div className="flex justify-between">
            <span className="text-lg">{t("accessibility.history")}</span>
            <button
              onClick={() => props.setFilter(true)}
              className=" text-gray-600 -mt-1 gap-3 flex py-1.5 px-3 rounded-lg bg-[#F5F5F5]"
            >
              {/* <img src={fill} alt="" className="mr-2 mx-auto" /> */}
              Filter
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.72 5.94L7.06667 10.2867C7.58 10.8 8.42 10.8 8.93333 10.2867L13.28 5.94"
                  stroke="#728F9E"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          {props.data.length > 0 ? (
            <div className="">
              {props.data.map((res: any) => {
                return (
                  <div
                    key={res.id}
                    onClick={() => {
                      setOpenDetail(true);
                      setData(res);
                    }}
                    className="text-white bg-white flex justify-between gap-2 relative my-4"
                  >
                    <div className="flex gap-3 w-4/6">
                      <div className="rounded-full flex max-w-[48px] min-w-[48px] max-h-[48px] min-h-[48px] bg-gray-200">
                        <img
                          src={
                            res?.category === "UNDEFINED_CATEGORY"
                              ? icon_in
                              : res?.category === "IN"
                              ? icon_in
                              : icon_out
                          }
                          alt=""
                          className="flex w-7 h-7 mx-auto mt-2.5"
                        />
                      </div>
                      <div className="flex flex-col mt-1">
                        <span className="text-primer font-bold text-[16px]">
                          {moment(res.attendanceTime).format("HH : mm : ss")}{" "}
                          WIB
                        </span>
                        <span className="text-[#728F9E] text-[12px]">
                          {res?.attendanceActivity?.name},{" "}
                          {moment(res.attendanceTime).format("DD MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        color:
                          res.status === "COME_LATE"
                            ? "#DC3545"
                            : res.status === "OUT_EARLY"
                            ? "#DC3545"
                            : res.status === "COME_EARLY"
                            ? "#FF9723"
                            : res.status === "OUT_OVERTIME"
                            ? "#FF9723"
                            : "#28A745",
                      }}
                      className="font-bold w-2/6 uppercase mt-2 text-right"
                    >
                      <span className="text-[14px] text-right">
                        {res.status === "COME_LATE"
                          ? t("accessibility.come_late")
                          : res.status === "OUT_EARLY"
                          ? t("accessibility.out_early")
                          : res.status === "COME_EARLY"
                          ? t("accessibility.come_early")
                          : res.status === "OUT_OVERTIME"
                          ? t("accessibility.out_overtime")
                          : t("accessibility.on_time")}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <DataNotFound title={t("accessibility.noData")} />
          )}
          {props.currentSize() >= 10 && props.totalElements >= 10 ? (
            <LoadMoreButton addMore={props.addMore} />
          ) : (
            ""
          )}
        </div>

        {/* Bottom Sheet Component */}
        <BottomSheet
          open={props.filter}
          onDismiss={() => props.setFilter(false)}
        >
          <div className="p-4">
            <p className="text-xl">Filter</p>
            <div className="flex flex-row mb-4 gap-4">
              <div className="flex w-1/2 flex-col">
                <span>{t("accessibility.startDate")}</span>
                <ReactDatePicker
                  selected={props.startDate}
                  onChange={(date: Date) => {
                    props.setStartDate(date);
                    props.setNewStartDate(
                      moment(date)
                        .format()
                        .toString()
                        .replace(":", "%3A")
                        .replace(":", "%3A")
                        .replace(":", "%3A")
                        .replace("+", "%2B")
                    );
                  }}
                  selectsStart
                  startDate={props.startDate}
                  endDate={props.endDate}
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                  placeholderText={t("accessibility.selectDate")}
                  className="border-[1px] border-[#C4C4C4] text-left p-[13px] rounded-lg w-[100%] md:text-sm text-sm"
                />
                <img
                  src={calendar}
                  className="absolute left-[153px] mt-8"
                  alt=""
                />
              </div>
              <div className="flex w-1/2 flex-col">
                <span>{t("accessibility.endDate")}</span>
                <ReactDatePicker
                  selected={props.startDate2}
                  onChange={(date: Date) => {
                    props.setStartDate2(date);
                    props.setNewEndDate(
                      moment(date).format("YYYY-MM-DD") +
                        "T23%3A59%3A59%2B07%3A00"
                    );
                  }}
                  selectsStart
                  startDate={props.startDate2}
                  endDate={props.endDate2}
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                  placeholderText={t("accessibility.selectDate")}
                  className="border-[1px] border-[#C4C4C4] text-left p-[13px] rounded-lg w-[100%] md:text-sm text-sm"
                />
                <img src={calendar} className="absolute right-8 mt-8" alt="" />
              </div>
            </div>
            <div className="flex w-full gap-4 mb-4">
              <button
                onClick={() => {
                  props.resetFilter();
                  props.setPage(1);
                }}
                className="text-gray-500 border-1 border-gray-500 w-1/2 p-2 rounded-lg"
              >
                {t("accessibility.reset")}
              </button>
              <button
                onClick={() => {
                  props.setPage(1);
                  props.filterDate();
                  props.setFilter(false);
                }}
                className="w-1/2 p-2 rounded-lg text-white"
                style={{ backgroundColor: `${props.color.color1}` }}
              >
                {t("accessibility.apply")}
              </button>
            </div>
          </div>
        </BottomSheet>

        <BottomSheet
          open={props.openMethod}
          onDismiss={() => props.resetMethode()}
        >
          <div className="p-4">
            <span className="text-xl">{t("accessibility.method_absence")}</span>
            <div className="flex flex-col my-4">
              <div className="flex items-center pl-4 rounded dark:border-gray-700">
                <input
                  checked={props.selectedMethode === "barcode"}
                  onChange={() => props.scanBarcodeRadio()}
                  id="bordered-radio-2"
                  type="radio"
                  value="barcode"
                  name="scan-barcode"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                ></input>
                <label className="w-full my-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {t("accessibility.qr_scan")}
                </label>
              </div>
              <div className="flex items-center pl-4 rounded dark:border-gray-700">
                <input
                  checked={props.selectedMethode === "qr"}
                  onChange={() => props.scanQrRadio()}
                  id="bordered-radio-1"
                  type="radio"
                  value="qr"
                  name="create-qr"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                ></input>
                <label className="w-full my-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {t("accessibility.create_qr")}
                </label>
              </div>

              {/* {props.act && (
                <div className="p-2">
                  <span className="pl-4 font-bold">Pilih Aktivitas</span>
                  {props.activity.map((res: any) => {
                    return (
                      <div
                        key={res.id}
                        className="flex items-center pl-4 rounded dark:border-gray-700"
                      >
                        <input
                          checked={props.selectedAct === res.id}
                          onChange={() => {
                            props.setSelectedAct(res.id);
                            // setRule(res.rule);
                            props.setDataAct(res);
                            props.setConfirm(true);
                            console.log(res);
                          }}
                          id={res.id}
                          type="radio"
                          value={res.id}
                          name="activity"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        ></input>
                        <label className="w-full my-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                          {res.name}
                        </label>
                      </div>
                    );
                  })}
                  {props.currentSizeActivity() >= 10 &&
                  props.totalElementsA >= 10 ? (
                    <div
                      onClick={() => props.addMoreAct()}
                      className="text-center"
                    >
                      <span>Selanjutnya {">>"}</span>
                    </div>
                  ) : null}
                </div>
              )} */}
            </div>
            <div className="flex w-full gap-4">
              <button
                onClick={() => props.resetMethode()}
                className="text-gray-500 border-1 border-gray-500 w-1/2 p-2 rounded-lg"
              >
                {t("accessibility.reset")}
              </button>
              <button
                disabled={props.confirm === false}
                onClick={() => props.pilihMethode(props.dataAct)}
                className=" w-1/2 p-2 rounded-lg text-white disabled:bg-gray-500"
                style={{ backgroundColor: `${props.color.color1}` }}
              >
                {t("accessibility.apply")}
              </button>
            </div>
          </div>
        </BottomSheet>

        <BottomSheet open={openDetail} onDismiss={() => setOpenDetail(false)}>
          <div className="flex justify-between px-4">
            <span className="text-lg font-semibold">
              {t("accessibility.history_absence")}
            </span>
            <img src={icon_close} alt="" onClick={() => setOpenDetail(false)} />
          </div>
          <div className="px-4 pb-5">
            <div className="w-full font-montserrat">
              <div className="mx-auto">
                <div className="mb-4">
                  <div
                    className="rounded-xl p-2 text-white flex flex-col relative gap-0.5 mt-4 bg-cover"
                    style={{
                      backgroundImage:
                        data?.status === "COME_LATE"
                          ? `url(${late})`
                          : `url(${bg})`,
                      backgroundColor:
                        data?.status === "COME_LATE"
                          ? `#DC3545`
                          : props.color.color1,
                    }}
                  >
                    <div className="flex flex-col text-center">
                      <p className="font-bold text-xl mt-3">
                        {data?.member?.name}
                      </p>
                      <div className="flex flex-row mt-4 mb-3">
                        <div className="w-1/2 flex flex-col">
                          <span className="font-bold">ID</span>
                          <span className="text-center">
                            {data?.member?.noId}
                          </span>
                        </div>
                        <div className="w-1/2 flex flex-col">
                          <span className="font-bold">
                            {t("accessibility.device")}
                          </span>
                          <span className="text-center">
                            {data?.device?.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white">
                  <div className="flex justify-between mb-2 text-sm">
                    <span>{t("accessibility.category")}</span>
                    <div className="text-gray-700 flex gap-1.5">
                      {data?.category === "UNDEFINED_CATEGORY"
                        ? t("accessibility.not_found")
                        : data?.category === "IN"
                        ? t("accessibility.in")
                        : t("accessibility.out")}
                      <img
                        src={
                          data?.category === "UNDEFINED_CATEGORY"
                            ? icon_in
                            : data?.category === "IN"
                            ? icon_in
                            : icon_out
                        }
                        alt=""
                        className=" w-5 h-5"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between mb-2 text-sm">
                    <span>Status</span>
                    <div className="text-gray-700 flex gap-1.5">
                      <div
                        style={{
                          color:
                            data?.status === "COME_LATE"
                              ? "#DC3545"
                              : data?.status === "OUT_EARLY"
                              ? "#DC3545"
                              : data?.status === "COME_EARLY"
                              ? "#FF9723"
                              : data?.status === "OUT_OVERTIME"
                              ? "#FF9723"
                              : "#28A745",
                        }}
                        className="font-bold   uppercase text-right"
                      >
                        <span className="text-[14px] text-right">
                          {data?.status === "COME_LATE"
                            ? t("accessibility.come_late")
                            : data?.status === "OUT_EARLY"
                            ? t("accessibility.out_early")
                            : data?.status === "COME_EARLY"
                            ? t("accessibility.come_early")
                            : data?.status === "OUT_OVERTIME"
                            ? t("accessibility.out_overtime")
                            : t("accessibility.on_time")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span>{t("accessibility.time")}</span>
                    <span className="text-gray-700">
                      {moment(data?.attendanceTime).format("DD-MM-YYYY")},{" "}
                      {moment(data?.attendanceTime).format("HH:mm:ss")} WIB
                    </span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span>{t("accessibility.method_access")}</span>
                    <span className="text-gray-700">
                      {data?.accessMethod === "QR_PRESENT"
                        ? t("accessibility.scan_qr")
                        : data?.accessMethod === "CARD"
                        ? t("accessibility.card")
                        : t("accessibility.create_code_qr")}
                    </span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span>{t("accessibility.activity")}</span>
                    <span className="text-gray-700">
                      {data?.attendanceActivity?.name}
                    </span>
                  </div>

                  <div className="mt-4">
                    <span>{t("accessibility.description")}</span>
                    <div className="border rounded-xl p-2 text-xs text-gray-600 mt-2">
                      {data?.attendanceActivity?.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BottomSheet>
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </div>
  );
};

export default AbsenFeaturesMobile;
