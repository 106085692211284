/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import AddVirtualCardWeb from "./addVirtualCardWeb";
import AddVirtualCardMobile from "./addVirtualCardMobile";
import { useNavigate } from "react-router-dom";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import * as API_USER from "../../../core/service/api_user";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import {
  loadState,
  removeState,
  saveState,
} from "../../../core/feature/browser-storage/browser-storage";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const AddVirtualCard = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const vcard_data = loadState("virtual_card");
  const show_balance_card = loadState("show_balance_card");
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [configData, setConfigData] = useState<any>({});
  const [data, setData] = useState({
    name: "",
    cardBalance: {
      oneTimeTxLimit: 0,
      dailyTxLimit: 0,
      monthTxLimit: 0,
      securityCode: "",
      balanceSync: {
        isActive: false,
        balanceCode: "CLOSEPAY",
      },
    },
    cardIdentifier: [
      {
        key: "",
        key_readable: "",
        type: "",
        value: "",
        message: "",
      },
    ],
    description: "",
  });
  const [background, setBackground] = useState("");
  const [template, setTemplate] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [oneTimeTxLimit, setOneTimeTxLimit] = useState(0);
  const [dailyTimeTxLimit, setDailyTimeTxLimit] = useState(0);
  const [monthTimeTxLimit, setMonthTimeTxLimit] = useState(0);
  const [errorCardName, setErrorCardName] = useState("");
  const [showBalanceCard, setShowBalanceCard] = useState(false);
  const [isOpen, setIsOpen] = useState("");
  const [isVirtualCardTransaction, setIsVirtualCardTransaction] =
    useState(false);
  const [isPortrait, setIsPortrait] = useState(false);

  let secondary = [
    {
      key: "",
      key_readable: "",
      type: "",
      value: "",
      message: "",
    },
  ];

  let query = {
    name: "",
    cardBalance: {
      oneTimeTxLimit: 0,
      dailyTxLimit: 0,
      monthTxLimit: 0,
      securityCode: "",
      balanceSync: {
        isActive: false,
        balanceCode: "CLOSEPAY",
      },
    },
    cardIdentifier: [
      {
        key: "",
        key_readable: "",
        type: "",
        value: "",
        message: "",
      },
    ],
    description: "",
  };

  const getTem = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.getCardTemplate()
      .then((res) => {
        setTemplate(res.data.data);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getTemplate = async () => {
    dispatch(setLoading(true));
    await API_USER.getProfile()
      .then((res) => {
        let profile = res.data.data;
        API_VIRTUAL_CARD.getTemplate()
          .then((res) => {
            setIsPortrait(true);
            dispatch(setLoading(false));
            setBackground(res.data.background);
            res.data.key_data.map((item: any) => {
              if (item.type === "IMAGE") {
                return secondary.push({
                  key: item.key,
                  key_readable: item.key_readable,
                  type: item.type,
                  value: "",
                  message: "",
                });
              } else {
                return secondary.push({
                  key: item.key,
                  key_readable: item.key_readable,
                  type: item.type,
                  value: profile[item.key] ? profile[item.key] : "",
                  message: "",
                });
              }
            });
            secondary.splice(0, 1);
            API_VIRTUAL_CARD.getConfigCard()
              .then((res) => {
                let resData = res.data.data;
                if (resData.isVirtualCardTransactionEnabled) {
                  setIsActive(resData.isVirtualCardTransactionEnabled);
                  setIsVirtualCardTransaction(
                    resData.isVirtualCardTransactionEnabled
                  );
                } else if (resData.securityCodeEnable) {
                  setIsActive(resData.securityCodeEnable);
                } else {
                  setIsActive(resData.securityCodeEnable);
                }
                setConfigData(resData);
                if (vcard_data === undefined) {
                  query.cardBalance.oneTimeTxLimit =
                    resData.masterOneTimeTxLimit;
                  query.cardBalance.dailyTxLimit = resData.masterDailyTxLimit;
                  query.cardBalance.monthTxLimit = resData.masterMonthlyTxLimit;
                } else {
                  if (vcard_data.cardBalance === undefined) {
                    query.cardBalance.oneTimeTxLimit =
                      resData.masterOneTimeTxLimit;
                    query.cardBalance.dailyTxLimit = resData.masterDailyTxLimit;
                    query.cardBalance.monthTxLimit =
                      resData.masterMonthlyTxLimit;
                  } else {
                    query.cardBalance = vcard_data.cardBalance;
                  }
                }
                query.name =
                  vcard_data === undefined ? query.name : vcard_data.name;
                query.cardIdentifier =
                  vcard_data === undefined
                    ? secondary
                    : vcard_data.cardIdentifier;
                query.description =
                  vcard_data === undefined
                    ? query.description
                    : vcard_data.description;
                setData(query);
                setOneTimeTxLimit(resData.masterOneTimeTxLimit);
                setDailyTimeTxLimit(resData.masterDailyTxLimit);
                setMonthTimeTxLimit(resData.masterMonthlyTxLimit);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const changeInput = async (value: any, item: any) => {
    query.cardIdentifier = data.cardIdentifier;
    const index = data.cardIdentifier.findIndex((d: any) => d.key === item.key);

    query.cardIdentifier[index].value = value;
    query.cardIdentifier[index].message = "";
    setData({ ...data, cardIdentifier: query.cardIdentifier });
  };

  const changeData = async (
    value: any,
    key: any,
    prop: any = "",
    subprop: any = ""
  ) => {
    let tempData: any = { ...data };
    if (prop === "") {
      return setData({ ...tempData, [key]: value });
    } else if (subprop !== "") {
      return setData({
        ...tempData,
        [prop]: {
          ...tempData[prop],
          [key]: { ...tempData[prop][key], [subprop]: value },
        },
      });
    } else {
      if (key === "securityCode") {
        return setData({
          ...tempData,
          [prop]: { ...tempData[prop], [key]: value },
        });
      } else if (typeof value === "string") {
        return setData({
          ...tempData,
          [prop]: { ...tempData[prop], [key]: parseInt(value) },
        });
      } else {
        return setData({
          ...tempData,
          [prop]: { ...tempData[prop], [key]: value },
        });
      }
    }
  };

  const changeModalView = (value: string) => {
    setIsOpen(value);
    getTemplate();
  };

  const handleClick = async () => {
    let filteredData = data.cardIdentifier.filter((item) => item.value === "");
    if (showBalanceCard === false) {
      if (data.name === "") {
        setErrorCardName(t('virtual_card.required_name_card'));
      } else if (data.name.length > 50) {
        setErrorCardName(t('virtual_card.required_long_name'));
      } else if (filteredData.length > 0) {
        filteredData.map((item) =>
          toast.error(item.key_readable + t('virtual_card.required'))
        );
      } else {
        dispatch(setLoading(true));
        let newData = {
          name: data.name,
          cardIdentifier: data.cardIdentifier,
          description: data.description,
        };
        await API_VIRTUAL_CARD.createCard(newData)
          .then((res) => {
            dispatch(setLoading(false));
            saveState("virtual_card", newData);
            saveState("show_balance_card", showBalanceCard);
            navigate(`/${company.initial}/virtual-card/add/summary`, {
              state: {
                data: res.data.data.detail,
                inquiryId: res.data.data.inquiryId,
              },
            });
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    } else {
      if (data.name === "") {
        setErrorCardName(t('virtual_card.required_name_card'));
      } else if (data.name.length > 50) {
        setErrorCardName(t('virtual_card.required_long_name'));
      } else if (filteredData.length > 0) {
        filteredData.map((item) =>
          toast.error(item.key_readable + t('virtual_card.required'))
        );
      } else if (
        data.cardBalance.oneTimeTxLimit > configData?.masterOneTimeTxLimit
      ) {
        toast.error(
          t('virtual_card.limit_setting')
        );
      } else if (
        data.cardBalance.oneTimeTxLimit > data.cardBalance.dailyTxLimit ||
        data.cardBalance.oneTimeTxLimit > data.cardBalance.monthTxLimit
      ) {
        toast.error(
          t('virtual_card.limit_transaction')
        );
      } else if (
        data.cardBalance.dailyTxLimit > configData?.masterDailyTxLimit
      ) {
        toast.error(t('virtual_card.limit_daily'));
      } else if (
        data.cardBalance.dailyTxLimit > data.cardBalance.monthTxLimit
      ) {
        toast.error(t('virtual_card.required_limit_daily'));
      } else if (
        data.cardBalance.monthTxLimit > configData?.masterMonthlyTxLimit
      ) {
        toast.error(t('virtual_card.limit_monthly'));
      } else if (isActive && data.cardBalance.securityCode === "") {
        toast.error(t('virtual_card.required_pin'));
      } else {
        let tempData: any = { ...data };
        query = tempData;
        if (data.cardBalance.oneTimeTxLimit === undefined)
          query.cardBalance.oneTimeTxLimit = oneTimeTxLimit;
        if (data.cardBalance.dailyTxLimit === undefined)
          query.cardBalance.dailyTxLimit = dailyTimeTxLimit;
        if (data.cardBalance.monthTxLimit === undefined)
          query.cardBalance.monthTxLimit = monthTimeTxLimit;
        dispatch(setLoading(true));
        API_VIRTUAL_CARD.createCard(data)
          .then((res) => {
            dispatch(setLoading(false));
            saveState("virtual_card", data);
            saveState("show_balance_card", showBalanceCard);
            navigate(`/${company.initial}/virtual-card/add/summary`, {
              state: {
                data: res.data.data.detail,
                inquiryId: res.data.data.inquiryId,
              },
            });
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    }
  };

  const inputHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    if (event.currentTarget.files) {
      const enteredName = event?.currentTarget?.files[0];
      uploadImage(enteredName);
    }
  };

  const uploadImage = async (imageFile: any) => {
    await API_VIRTUAL_CARD.uploadImage(imageFile)
      .then((res) => {
        changeInput(res.data, { key: "profileImage" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTemplate();
    if (show_balance_card !== undefined) setShowBalanceCard(show_balance_card);
    window.onpopstate = (e) => {
      removeState("virtual_card");
      removeState("show_balance_card");
    };
  }, []);

  const [configColor, setConfigColor] = useState<any>({});
  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  useEffect(() => {
    getConfigColor();
    getTem();
  }, []);

  return (
    <>
      <BrowserView>
        <AddVirtualCardWeb
          changeData={(value: any, key: any, prop: any, subprop: any) =>
            changeData(value, key, prop, subprop)
          }
          changeModalView={(value: string) => changeModalView(value)}
          handleClick={() => handleClick()}
          inputHandler={(event: any, key: string) => inputHandler(event, key)}
          changeInput={(value: any, item: any) => changeInput(value, item)}
          setErrorCardName={(value: any) => setErrorCardName(value)}
          setIsOpen={(value: any) => setIsOpen(value)}
          setIsActive={(value: any) => setIsActive(value)}
          setShowBalanceCard={(value: any) => setShowBalanceCard(value)}
          data={data}
          background={background}
          template={template}
          isActive={isActive}
          errorCardName={errorCardName}
          isOpen={isOpen}
          showBalanceCard={showBalanceCard}
          configColor={configColor}
          isVirtualCardTransaction={isVirtualCardTransaction}
          isPortrait={isPortrait}
        />
      </BrowserView>
      <MobileView>
        <AddVirtualCardMobile
          changeData={(value: any, key: any, prop: any, subprop: any) =>
            changeData(value, key, prop, subprop)
          }
          changeModalView={(value: string) => changeModalView(value)}
          handleClick={() => handleClick()}
          inputHandler={(event: any, key: string) => inputHandler(event, key)}
          changeInput={(value: any, item: any) => changeInput(value, item)}
          setErrorCardName={(value: any) => setErrorCardName(value)}
          setIsOpen={(value: any) => setIsOpen(value)}
          setIsActive={(value: any) => setIsActive(value)}
          setShowBalanceCard={(value: any) => setShowBalanceCard(value)}
          data={data}
          background={background}
          template={template}
          isActive={isActive}
          errorCardName={errorCardName}
          isOpen={isOpen}
          showBalanceCard={showBalanceCard}
          configColor={configColor}
          isVirtualCardTransaction={isVirtualCardTransaction}
          isPortrait={isPortrait}
        />
      </MobileView>
    </>
  );
};

export default AddVirtualCard;
