// import React, { useState } from "react";
import Header from "../../_common/headerComponent";
// import * as API_USER from "../../../core/service/api_user";
// import user from "../../assets/images/userNew.png";
// import lesson from "../../../assets/images/icon_siakad/lesson.svg";
import eraport from "../../../assets/images/icon_siakad/Document.svg";
// import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
// import Company from "../../../core/models/company";
import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
// import moment from "moment";

const ERaportMobile = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  // let dispatch = useAppDispatch();

  const data = [
    "Rapor Kelas 10 Semester 1",
    "Rapor Kelas 10 Semester 2",
    "Rapor Kelas 10 Semester 3",
    "Rapor Kelas 10 Semester 4",
    "Rapor Kelas 10 Semester 5",
    "Rapor Kelas 10 Semester 6",
  ];
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="E Rapor"></Header>

      <div className="flex flex-col px-6 py-2 gap-3">
        {data.map((item: any) => (
          <div
            onClick={() => navigate("detail")}
            className="flex flex-row items-center gap-3 px-3 py-2.5 shadow-md shadow-[#f4f8f8] bg-white rounded-md"
          >
            <img src={eraport} alt="" className="w-[36px] h-[36px]" />
            <h1 className="m-0 font-medium text-sm">{item}</h1>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ERaportMobile;
