// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React, { FC, useState, useEffect } from "react";

// import "react-multi-carousel/lib/styles.css";
// import {
//   faSearch,
//   faHome,
//   faUserCircle,
// } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";

// import qrcode_icon from "../../assets/images/qrcode_icon.svg";
import home from "../../assets/images/icon_nav/home.svg";
import notification from "../../assets/images/icon_nav/notification.svg";
import info from "../../assets/images/icon_nav/info.svg";
import profile_active from "../../assets/images/icon_nav/profile-active.svg";

const FooterProfileMobile = () => {
  return (
    <>
      <section>
        <div className="flex gap-0 fixed bottom-0 bg-white px-2 py-2.5 w-full">
          <div className="grow flex flex-col items-center">
            <img src={home} alt="" className="w-5" />
            <h1 className="m-0 text-xs font-normal text-gray-300">Home</h1>
          </div>
          <div className="grow flex flex-col items-center">
            <img src={notification} alt="" className="w-5" />
            <h1 className="m-0 text-xs font-normal text-gray-300">
              {" "}
              Notifikasi
            </h1>
          </div>
          <div className="grow flex flex-col items-center">
            <img src={info} alt="" className="w-5" />
            <h1 className="m-0 text-xs font-normal text-gray-300">Info</h1>
          </div>
          <div className="grow flex flex-col items-center">
            <img src={profile_active} alt="" className="w-5" />
            <h1 className="m-0 text-xs font-normal text-green-500">Profile</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterProfileMobile;
