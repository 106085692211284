// import React, { useState } from "react";
import Header from "../../_common/headerComponent";
// import * as API_USER from "../../../core/service/api_user";
// import user from "../../assets/images/userNew.png";
import map from "../../../assets/images/icon_siakad/map.svg";
// import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
// import Company from "../../../core/models/company";
// import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
// import moment from "moment";

const GradeMobile = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  // let navigate = useNavigate();
  // let dispatch = useAppDispatch();

  const nilai = [
    { mapel: "BAB I", nilai: 80, index: "A" },
    { mapel: "BAB II", nilai: 80, index: "A" },
    { mapel: "BAB III", nilai: 40, index: "C" },
    { mapel: "BAB IV", nilai: 80, index: "A" },
    { mapel: "Ulangan Harian", nilai: 80, index: "A" },
    { mapel: "BAB V", nilai: 80, index: "A" },
    { mapel: "BAB VI", nilai: 70, index: "B" },
    { mapel: "BAB VII", nilai: 80, index: "A" },
  ];
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Nilai"></Header>

      <div className="flex flex-col px-6 py-2 gap-3">
        <div className="relative flex flex-col justify-between p-3 min-h-[185px] bg-gradient-to-br from-[#13b886] via-[#069470] to-[#13b886] text-white rounded-lg">
          <div className="absolute top-10 z-0">
            <img src={map} alt="" className="object-cover" />
          </div>
          <h1 className="font-semibold text-base mt-2 relative z-10">
            Umay Umaya
          </h1>
          <div className="grid grid-cols-3 relative z-10">
            <div className="flex flex-col items-start justify-centergap-1">
              <h1 className="ont-semibold text-base m-0">XI IPA 3</h1>
              <span className="font-light text-sm">Kelas</span>
            </div>
            <div className="flex flex-col items-center justify-center gap-1">
              <h1 className="ont-semibold text-base  m-0">1000981265</h1>
              <span className="font-light text-sm">NIS</span>
            </div>
            <div className="flex flex-col items-end justify-center gap-1">
              <h1 className="ont-semibold text-base m-0">Genap</h1>
              <span className="font-light text-sm">Semester</span>
            </div>
          </div>
        </div>
        <button className="w-full px-3 py-2.5 bg-transparent rounded-lg text-[#9A9999] border border-[#9A9999] font-semibold text-base">
          Pilih Mata Pelajaran
        </button>

        <table className="table-auto">
          <thead>
            <tr className="font-medium text-xs text-[#9A9999] text-center">
              <th>Tugas</th>
              <th className="text-left">Nama Tugas</th>
              <th>Nilai</th>
              <th>Indexs</th>
            </tr>
          </thead>
          <tbody>
            {nilai.map((item: any, index: number) => (
              <tr className="font-normal text-sm even:bg-[#1aa6ec09] text-[#252525] text-center">
                <td className="py-2">{index + 1}</td>
                <td className="py-2 text-left">{item.mapel}</td>
                <td className="py-2 font-semibold">{item.nilai}</td>
                <td
                  className={`${
                    item.index === "A"
                      ? "text-[#00D19F]"
                      : item.index === "B"
                      ? "text-[#1AA7EC]"
                      : item.index === "C"
                      ? "text-[#EC1A1A]"
                      : "text-black"
                  } py-2 font-semibold`}
                >
                  {item.index}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default GradeMobile;
