/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ScanQRMobile from "./featureScanQRMobile";
import ScanQRWeb from "./featureScanQRWeb";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import * as API_ABSENSI from "../../core/service/api_absensi";
import { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";

const ScanQR = () => {
  const [timer, setTimer] = React.useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [valueQR, setValueQR] = React.useState<any>("");
  const [visible, setVisible] = React.useState(false);
  let qr: any = "";

  const getQrCode = () => {
    let query = {
      companyId: "",
      companyInitial: "",
      email: "",
      userType: "",
      userId: "",
    };
    dispatch(setLoading(true));
    API_ABSENSI.scanQrCode(query)
      .then((res) => {
        dispatch(setLoading(false));
        setValueQR(res.data.data.qrId);
        qr = res.data.data.qrId;
        setTimer(Date.now() + 300000);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getStatusQrCode = async () => {
    let query = {
      companyId: "",
      companyInitial: "",
      email: "",
      userType: "",
      userId: "",
    };
    // dispatch(setLoading(true));
    await API_ABSENSI.getStatusQrAbsen(qr, query)
      .then((res) => {
        if (res.data.data.status === "VERIFIED") {
          setVisible(true);
          localStorage.setItem("result", JSON.stringify(res));
        }
      })
      .catch((err) => {
        localStorage.removeItem("result");
        dispatch(setLoading(false));
      });
  };

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <button
          className="text-white text-base bg-green rounded-[10px] mt-5 w-full p-2"
          type="button"
          onClick={getQrCode}
        >
          {t('accessibility.regenerate')}
        </button>
      );
    } else {
      return (
        <div className="flex flex-col gap-2 mt-4">
          <span className="font-light md:text-lg text-base">{t('accessibility.timeLimit')}</span>
          <span className="font-semibold md:text-[26px] text-2xl">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </div>
      );
    }
  };

  React.useEffect(() => {
    getQrCode();
    const loop = setInterval(() => {
      getStatusQrCode();
    }, 5000);
    return () => clearInterval(loop);
  }, []);

  return (
    <>
      <BrowserView>
        <ScanQRWeb
          renderer={renderer}
          timer={timer}
          valueQR={valueQR}
          visible={visible}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <ScanQRMobile
          renderer={renderer}
          timer={timer}
          valueQR={valueQR}
          visible={visible}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default ScanQR;
