import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import LocationWeb from "./locationWeb";
import LocationMobile from "./locationMobile";

const Location = () => {
  return (
    <>
      <BrowserView>
        <LocationWeb></LocationWeb>
      </BrowserView>
      <MobileView>
        <LocationMobile></LocationMobile>
      </MobileView>
    </>
  );
};

export default Location;
