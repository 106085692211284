import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TransferWeb from "./transferWeb";
import TransferMobile from "./transferMobile";

const Transfer = () => {
  return (
    <>
      <BrowserView>
        <TransferWeb></TransferWeb>
      </BrowserView>
      <MobileView>
        <TransferMobile></TransferMobile>
      </MobileView>
    </>
  );
};

export default Transfer;
