/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import NoImage from "../../assets/images/empty.jpg";
import SoldOutIcon from "../../assets/images/soldoutIcon.png";
import { formatCurrency } from "../../config/global";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
// import User from "../../core/models/user";
// import { find } from "lodash";
// import Token from "../../core/models/token";
import AddToCartLogo from "../../assets/images/add_to_cart.svg";
import { useTranslation } from "react-i18next";

interface ProductComponentProps {
  item: any;
  type: string;
  merchantName?: string;
}

const ProductComponent: FC<ProductComponentProps> = (props): JSX.Element => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = React.useState<any>({});
  const [productList, setProductList] = React.useState<any[]>([]);
  const [maxPrice, setMaxPrice] = React.useState(0);
  const [minPrice, setMinPrice] = React.useState(0);
  const [soldOut, setSoldOut] = React.useState(true);
  // const user: User = useAppSelector((state) => state.user.user);
  // const token: Token[] = useAppSelector((state) => state.token.token);
  // const newToken = find(token, { cid: company?.initial });
  // const [isLogin, setIsLogin] = React.useState(user && newToken);

  React.useEffect(() => {
    setData(props.item);
    if (props.type === "P") {
      setProductList(props.item.items);
      if (props.item.type === "JASA") setSoldOut(false);
      if (props.item.items.length > 0 && props.item.type === "BARANG") {
        let productStock = props.item.items.find((el: any) => el.stock !== 0);
        if (productStock !== undefined) setSoldOut(false);
      }
      if (props.item.items.length > 1) {
        let priceList: any = [];
        props.item.items.map((el: any) => priceList.push(el.marketplacePrice));
        setMaxPrice(Math.max(...priceList));
        setMinPrice(Math.min(...priceList));
      }
    } else if (props.type === "M") {
      setProductList(props.item.itemList);
      if (props.item.itemList.length > 1) {
        let priceList: any = [];
        props.item.itemList.map((el: any) =>
          priceList.push(el.marketplacePrice)
        );
        setMaxPrice(Math.max(...priceList));
        setMinPrice(Math.min(...priceList));
      }
    } else if (props.type === "FNB") {
      console.log(props.item.productName);
      setProductList(props.item.productItems);
      if (props.item.productItems.length > 1) {
        let priceList: any = [];
        props.item.productItems.map((el: any) => priceList.push(el.finalPrice));
        setMaxPrice(Math.max(...priceList));
        setMinPrice(Math.min(...priceList));
      }
      if (props.item.productItems.length === 0) {
        setSoldOut(false);
      } else {
        let readyStock = props.item.productItems.find(
          (el: any) => el.stock > 0
        );
        if (readyStock === undefined) setSoldOut(false);
      }
    }
  }, []);

  return props.type === "P" ? (
    <div
      className="cursor-pointer flex flex-col align-items-center h-[250px] overflow-hidden"
      key={data.id}
      onClick={
        () =>
          navigate(`/${company?.initial}/commerce/products/detail/${data.id}`)
        // isLogin
        //   ?
        //   : navigate(`/${company?.initial}/login`)
      }
    >
      {data?.imagePath?.length > 0 ? (
        soldOut ? (
          // <div className="bg-white relative w-full">
          //   {/* <div
          //     className="bg-[#252525] flex items-center justify-center left-[50%] rounded-full text-white h-[60px] w-[60px] opacity-50 absolute top-[50%] z-20"
          //     style={{ transform: "translate(-50%, -50%)" }}
          //   >
          //     <span> {t("marketplace.sold")}</span>
          //   </div> */}
          // </div>
          <img
            src={data?.imagePath[0] === "" ? NoImage : data?.imagePath[0]}
            alt=""
            className="object-center w-full select-none overflow-hidden rounded-xl"
            style={{ height: 190 }}
          />
        ) : (
          <img
            src={data?.imagePath[0] === "" ? NoImage : data?.imagePath[0]}
            alt=""
            className="object-center w-full select-none overflow-hidden rounded-xl"
            style={{ height: 140 }}
          />
        )
      ) : (
        <img
          src={NoImage}
          alt=""
          className="object-center w-full select-none overflow-hidden rounded-xl"
          style={{ height: 190 }}
        />
      )}
      <div className="w-full flex flex-col justify-between gap-2 px-[10px] py-3">
        <p className="text-customBlack text-start md:text-base text-sm mb-0">
          {data.name}
        </p>
        <div className="flex justify-between items-center gap-2">
          <div className="flex flex-col items-start">
            {productList?.length > 0 && (
              <h6 className="md:text-base text-sm text-start font-bold text-green mb-0">
                {productList?.length > 1
                  ? `${formatCurrency(minPrice)} - ${formatCurrency(maxPrice)}`
                  : formatCurrency(productList[0]?.marketplacePrice)}
              </h6>
            )}
            <h6 className="text-[#C4C4C4] md:text-sm text-xs text-start font-medium mb-0">
              {data?.merchant?.name}
            </h6>
          </div>
          {soldOut && (
            <img src={SoldOutIcon} alt="" style={{ width: 50, height: 50 }} />
          )}
        </div>
      </div>
    </div>
  ) : props.type === "FNB" ? (
    <div
      className="flex flex-col align-items-center bg-white shadow-sm rounded-[8px] h-full overflow-hidden relative"
      onClick={() => {
        console.log(soldOut);
        if (soldOut) {
          navigate(`${data?.id}`);
        }
      }}
    >
      {productList?.length > 0 ? (
        data?.imageUrl?.length > 0 ? (
          <img
            src={data?.imageUrl[0] === "" ? NoImage : data?.imageUrl[0]}
            alt=""
            className="object-cover w-full select-none overflow-hidden"
            style={{ height: 175 }}
          />
        ) : (
          <img
            src={NoImage}
            alt=""
            className="object-cover w-full select-none overflow-hidden"
            style={{ height: 175 }}
          />
        )
      ) : (
        <img
          src={NoImage}
          alt=""
          className="object-cover w-full select-none overflow-hidden"
          style={{ height: 175 }}
        />
      )}
      <div className="w-full flex flex-col justify-between gap-2 px-[10px] py-3">
        <div>
          <p className="text-customBlack md:text-sm text-xs font-medium mb-0">
            {data?.productName}
          </p>
          <p className="text-newColor md:text-xs text-[10px] font-light mb-0">
            {data?.categoryName}
          </p>
          {productList.length > 0 && (
            <div className="flex items-center justify-between">
              <h6 className="md:text-base text-sm font-semibold text-green mb-0 mt-2">
                {productList?.length > 1
                  ? `${formatCurrency(minPrice)} - ${formatCurrency(maxPrice)}`
                  : formatCurrency(productList[0].finalPrice)}
              </h6>
              <button
                type="button"
                disabled={!soldOut}
                // onClick={() => }
              >
                <img src={AddToCartLogo} alt="" />
              </button>
            </div>
          )}
        </div>
      </div>
      {!soldOut && (
        <div className="absolute top-0 right-0 md:p-3 p-2">
          <div className="bg-white bg-opacity-60 rounded-[10px] py-[4px] px-[8px]">
            <p className="text-customBlack md:text-xs text-[10px] mb-0">
              {t("marketplace.sold")}
            </p>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div
      className="cursor-pointer flex flex-col align-items-center h-full overflow-hidden"
      onClick={
        () =>
          navigate(`/${company?.initial}/commerce/products/detail/${data.id}`)
        // isLogin
        //   ? navigate(`/${company?.initial}/commerce/products/${data.id}`)
        //   : navigate(`/${company?.initial}/login`)
      }
    >
      <div className="border-[1px] border-[#E5E5E5] rounded-[4px] overflow-hidden">
        {productList?.length > 0 ? (
          data?.imagePaths.length > 0 ? (
            <img
              src={data?.imagePaths[0] === "" ? NoImage : data?.imagePaths[0]}
              alt=""
              className="object-center select-none overflow-hidden"
              style={{ width: 68, height: 71 }}
            />
          ) : (
            <img
              src={NoImage}
              alt=""
              className="object-center select-none overflow-hidden"
              style={{ width: 68, height: 71 }}
            />
          )
        ) : (
          <img
            src={NoImage}
            alt=""
            className="object-center select-none overflow-hidden"
            style={{ width: 68, height: 71 }}
          />
        )}
      </div>
      {productList?.length > 0 && (
        <h6 className="md:text-xs text-[0.6rem] text-green font-medium mb-0 mt-1">
          {productList?.length > 1
            ? `${formatCurrency(200000)} - ${formatCurrency(4000000)}`
            : formatCurrency(productList[0].marketplacePrice)}
        </h6>
      )}
    </div>
  );
};

export default ProductComponent;
