import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import * as API_BALANCE from "../../../core/service/api_balance";
import * as API_USER from "../../../core/service/api_user";
import { setBalance } from "../../../core/feature/user/balanceSlice";
import ProviderMobile from "./providerMobile";
import ProviderWeb from "./providerWeb";
import BankDKI from "../../../assets/images/icon_balance/bank_dki.png";
import LinkAja from "../../../assets/images/icon_balance/link_aja.png";

const providers = [
  {
    id: 1,
    code: "BANK_DKI",
    providerName: "Bank DKI",
    img: BankDKI,
    background: "#FFFFFF",
    background2: "#ff3232",
    border: "#9A9999",
    color: "#252525",
  },
  {
    id: 2,
    code: "LINK_AJA",
    providerName: "Link Aja",
    img: LinkAja,
    background: "#E82529",
    background2: "#E82529",
    border: "#E82529",
    color: "#FFFFFF",
  },
];

const Provider = () => {
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const [listProvider, setListProvider] = useState([]);
  const [connectedAccounts, setConnectedAccounts] = useState<any[]>([]);
  const [listConnected, setListConnect] = useState<any>([]);
  const [select, setSelect] = useState<any>("");

  const getComboProviders = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getComboProviders()
      .then((res) => {
        dispatch(setLoading(false));
        setListProvider(res.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const checkBankDKIAccount = async () => {
    await API_BALANCE.checkBankDKIAccount()
      .then((res) => {
        dispatch(setLoading(false));
        if (
          connectedAccounts.length > 0 &&
          connectedAccounts.find(
            (el: any) => el.provider === res.data.provider
          ) !== undefined
        ) {
          return;
        } else {
          setConnectedAccounts(connectedAccounts.concat(res.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const checkLinkAjaAccount = async () => {
    await API_BALANCE.checkLinkAjaAccount()
      .then((res) => {
        dispatch(setLoading(false));
        if (
          connectedAccounts.length > 0 &&
          connectedAccounts.find(
            (el: any) => el.provider === res.data.data.provider
          ) !== undefined
        ) {
          return;
        } else {
          setConnectedAccounts(connectedAccounts.concat(res.data.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getConnectedProvider = () => {
    let data = {
      page: 0,
      size: 10,
      sortBy: "createTime",
      dir: -1,
      total: 0,
    };
    API_BALANCE.getConnectedAccounts2(data)
      .then((res) => {
        let tempConnectedAccounts = res.data.data.linkedAccounts;
        setListConnect(res.data.data.linkedAccounts);
        tempConnectedAccounts.map((el: any) => {
          switch (el) {
            case "BANK_DKI":
              checkBankDKIAccount();
              break;

            case "LINK_AJA":
              checkLinkAjaAccount();
              break;

            default:
              dispatch(setLoading(false));
              break;
          }
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const selected = (item: any) => {
    if (item.provider === select.code) {
      setSelect("");
    } else {
      setSelect(providers.find((el: any) => el.code === item.provider));
    }
  };

  useEffect(() => {
    getComboProviders();
    getConnectedProvider();
  }, []);

  return (
    <>
      <BrowserView>
        <ProviderWeb
          listProvider={listProvider}
          connectedAccounts={connectedAccounts}
          providers={providers}
          listConnected={listConnected}
          select={select}
          selected={(value: any) => selected(value)}
        />
      </BrowserView>
      <MobileView>
        <ProviderMobile
          listProvider={listProvider}
          connectedAccounts={connectedAccounts}
          providers={providers}
          listConnected={listConnected}
          select={select}
          selected={(value: any) => selected(value)}
        />
      </MobileView>
    </>
  );
};

export default Provider;
