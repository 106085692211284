import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DetailMobile from "./detailMobile";
import DetailWeb from "./detailWeb";

const DetailSubcription = () => {
  return (
    <>
      <BrowserView>
        <DetailWeb></DetailWeb>
      </BrowserView>
      <MobileView>
        <DetailMobile></DetailMobile>
      </MobileView>
    </>
  );
};

export default DetailSubcription;
