import Header from "../../_common/headerComponent";
import NoImage from "../../../assets/images/empty.jpg";
import Delete from "../../../assets/images/DeleteIcon.svg";
import { formatCurrency, onlyNumber } from "../../../config/global";
import DataNotFound from "../../_common/dataNotFound";
import LoadMoreButton from "../../_common/loadMoreButton";

const CartWeb = (props: any) => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />
      <div className="container flex flex-col items-center md:mt-[64px] mt-[0px] px-0 pb-[110px] md:pb-[150px]">
        <div className="md:w-4/5 w-full mx-auto mb-5">
          {props.productList.length > 0 ? (
            <div>
              <div className="w-full">
                <div className="bg-white space-between gap-3 p-3">
                  <h6 className="md:text-base text-sm font-semibold mb-0">
                    {props.t('cart.selectAll')}
                  </h6>
                  <div className="flex items-center">
                    <input
                      id="defauSelectedProductslt-checkbox"
                      type="checkbox"
                      value=""
                      checked={props.isSelectedAll}
                      onChange={props.selectAll}
                      className="w-[18px] h-[18px] text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                </div>
                {props.productList.map((item: any) => (
                  <div className="w-full bg-white mt-4" key={item.merchantId}>
                    <div className="space-between gap-3 pt-3 px-3">
                      <h6 className="md:text-base text-sm font-semibold mb-0">
                        {item?.merchant?.name}
                      </h6>
                      <div className="flex items-center">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value={item.merchantId}
                          checked={props.selectedMerchantId[item.merchantId]}
                          onChange={(e) => {
                            props.selectMerchant(item);
                          }}
                          className="w-[18px] h-[18px] text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="divide-y-2 px-3">
                      {item?.product?.map((el: any) =>
                        el.item.map((el2: any, index: number) => (
                          <div
                            key={index}
                            className="grid grid-cols-5 gap-3 py-3"
                          >
                            {el2?.imageUrl?.length > 0 ? (
                              <img
                                src={
                                  el2?.imageUrl[0] !== ""
                                    ? el2?.imageUrl[0]
                                    : NoImage
                                }
                                alt=""
                                width={110}
                                height={120}
                              />
                            ) : (
                              <img
                                src={NoImage}
                                alt=""
                                width={110}
                                height={120}
                              />
                            )}

                            <div className="col-span-3 flex flex-col justify-between">
                              <h6 className="text-customBlack">
                                {el2?.productName}
                              </h6>
                              <h5>{formatCurrency(el2?.marketplacePrice)}</h5>
                              <div className="bg-[#F5F5F5] rounded-[4px] w-fit p-2 text-newColor text-xs">
                                <span>
                                  {el2?.variantKey} : {el2?.variantValue}
                                </span>
                              </div>
                              <div className="bg-white mt-3">
                                <button
                                  type="button"
                                  disabled={el2.quantity === 1}
                                  onClick={() =>
                                    props.changeQuantity(item, el2, "substraction")
                                  }
                                  className="border-[0.5px] border-[#C4C4C4] px-[8px] rounded-l-lg"
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  onKeyPress={onlyNumber}
                                  value={el2?.quantity}
                                  onChange={() =>
                                    props.changeQuantity(item, el2, "custom")
                                  }
                                  name="quantity"
                                  id="quantity"
                                  className="border-[#C4C4C4] border-t-[0.5px] border-b-[0.5px] text-center px-[8px] w-[15%] focus:outline outline-[2px] outline-[#C4C4C4]"
                                />
                                <button
                                  type="button"
                                  disabled={el2.quantity === el2?.item?.stock}
                                  onClick={() =>
                                    props.changeQuantity(item, el2, "addition")
                                  }
                                  className="border-[0.5px] border-[#C4C4C4] px-[8px] rounded-r-lg"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div className="flex flex-col justify-between items-end">
                              <div className="flex items-center">
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  value={item.merchantId + "-" + el2.id}
                                  checked={props.selectedItemId[el2.cartId]}
                                  onChange={(e) => (
                                    props.selectProduct(item, el2)
                                  )}
                                  className="w-[18px] h-[18px] text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                              <button
                                type="button"
                                onClick={() => props.deleteItem(el2.cartId)}
                              >
                                <img
                                  src={Delete}
                                  alt=""
                                  width={22}
                                  height={22}
                                />
                              </button>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                ))}
                {props.currentSize() >= 10 && (
                  <LoadMoreButton addMore={props.addMore} />
                )}
                <div className="flex justify-center">
                  <div className="fixed bottom-0 w-full md:w-2/3">
                    <div className="bg-white mt-4 p-3 md:mx-3">
                      <div className="space-between">
                        <p className="text-base text-customBlack font-medium">
                          {props.t('cart.totalAmount')}
                        </p>
                        <p className="text-green md:text-lg text-base font-semibold">
                          {formatCurrency(props.totalAmount)}
                        </p>
                      </div>
                      <button
                        type="button"
                        onClick={props.handleOnClick}
                        disabled={props.selectedProducts.length === 0}
                        style={{
                          background: `${props.selectedProducts.length === 0 ? "#E5E5E5" : props.company?.color?.color1 !== undefined ? props.company?.color?.color1 : "#26A69A"}`
                        }}
                        className={`w-full md:py-[16px] p-[10px] text-white font-semibold text-base disabled:bg-slate-50 rounded-[10px]`}
                      >
                        {props.t('cart.createPesanan')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <DataNotFound title={props.t('cart.noData')} />
          )}
        </div>
      </div>
    </section>
  );
};

export default CartWeb;