import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import arrowRightIcon from "../../assets/images/Iconly/Light-Outline/ArrowRight.svg";
import LogoIcon from "../../assets/images/icon_closepay/LOGOCP.svg";
import {
  blurAccountNumber,
  clientPaging,
  formatCurrency,
  formatDate,
  imageOnError,
} from "../../config/global.js";
import Header from "../_common/headerComponent";
import DonationTransactionSkeleton from "../_common/skeleton/DonationtransactionSkeleton";
import DataNotFound from "../_common/dataNotFound";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useTranslation } from "react-i18next";

const ZakatDisbursmentMobile = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setsize] = useState(10);
  const [page, setpage] = useState(1);
  const [sortBy] = useState("createTime");
  const [dir] = useState(-1);
  const [toggle, setToggle] = useState(false);

  const [data, setdata] = useState<any>([]);
  const [dataPaging, setdataPaging] = useState<any>([]);
  const [totalElement, setTotalElement] = useState(0);
  // const [totalPage, setTotalPage] = useState(0);

  const query: any = useLocation();

  const getDatas = () => {
    setdata([]);
    setIsLoading(true);
    let dataT = query.state?.disbursement;
    setdata(dataT);
    clientPaging(dataT, page, size)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setdataPaging(res.data.data);
        setpage(res.data.paging.page);
        setsize(res.data.paging.size);
        setTotalElement(res.data.paging.totalElement);
        // setTotalPage(res.data.paging.totalPage);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getMoreDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    setIsLoading(true);
    clientPaging(data, varPage, varSize)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setdataPaging([...dataPaging, ...res.data.data]);
        setpage(res.data.paging.page);
        setsize(res.data.paging.size);
        setTotalElement(res.data.paging.totalElement);
        // setTotalPage(res.data.paging.totalPage);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const countAmount = () => {
    return _.sumBy(data, "amount");
  };
  const countTotal = () => {
    return data.length;
  };

  useEffect(() => {
    getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="h-full min-h-screen font-poppins bg-mobile">
        <Header navbarText={t('zakat.fundWithdrawal')}></Header>
        <div className="container h-full m-auto px-4 font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className=" w-100">
              <div className="flex flex-col w-full  bg-white rounded-md shadow-sm shadow-gray-700 gap-1 p-2">
                <div className="flex border-b-[.3px] border-solid mx-3 py-3 gap-4">
                  <div className="w-1/2 flex flex-col border-r-[.3px] border-solid">
                    <span className="font-semibold text-sm">
                      {formatCurrency(countAmount())}
                    </span>
                    <span className="font-normal text-xs">
                      {t('zakat.fundsAlready')}
                    </span>
                  </div>
                  <div className="w-1/2 flex flex-col">
                    <span className="font-semibold text-sm">
                      {countTotal()}&nbsp;{t('zakat.times')}
                    </span>
                    <span className="font-normal text-xs">{t('zakat.fundWithdrawal')}</span>
                  </div>
                </div>
                <div className="flex justify-center gap-4 p-2 text-sm font-light text-">
                  <button
                    type="button"
                    onClick={() => setToggle(true)}
                    className="flex justify-center gap-4 p-2 text-sm font-light text-themePrimary"
                  >
                    <span>{t('zakat.informationFundUsage')}</span>
                    <img src={arrowRightIcon} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container h-full m-auto px-4 font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100">
              <div className="flex flex-col gap-2 my-4">
                {data.length === 0 && !isLoading && (
                  <DataNotFound title={t('zakat.noData')} />
                )}
                {_.map(data, (item) => (
                  <div
                    key={item._id}
                    className="flex flex-col w-full bg-white rounded-md shadow-sm shadow-gray-700 gap-2 p-4"
                  >
                    <div className="flex">
                      <span className="text-[10px] font-light">
                        {formatDate(new Date(item?.date), "DD MMMM YYYY")}
                        {/* {moment(new Date(item?.createdTime)).fromNow()} */}
                        {/* {moment(new Date(item?.date)).fromNow()} */}
                      </span>
                    </div>

                    <div className="w-full flex gap-2">
                      {/* <div className="flex justify-center items-center rounded-full w-12 h-12 bg-[#D9D9D9]"> */}
                      <img
                        className=" bg-contain w-9 h-9"
                        onError={imageOnError}
                        src={LogoIcon}
                        alt=""
                      />
                      {/* </div> */}
                      <div className="flex flex-col">
                        <span>{query?.state?.foundationName}</span>
                      </div>
                    </div>
                    <div className="flex">
                      <span className="text-base font-semibold">
                        {t('zakat.fundWithdrawal')}&nbsp;{formatCurrency(item?.amount)}
                      </span>
                    </div>
                    <div className="flex">
                      <span className="text-sm text-[#6A6A6A] font-normal">
                        {`${t('zakat.toAccount')} ${item.bankName} ${blurAccountNumber(
                          item.bankAccountNo
                        )} a/n ${item.bankAccountName}`}
                      </span>
                    </div>
                    <div className="text-sm text-[#3A3A3A] font-normal">
                      {item.planUseFunds}
                    </div>
                  </div>
                ))}
                {isLoading ? (
                  <>
                    {_.map(new Array(size), (el) => (
                      <DonationTransactionSkeleton></DonationTransactionSkeleton>
                    ))}
                  </>
                ) : null}
                {totalElement > data.length ? (
                  <div
                    className={`bg-themePrimary rounded-md py-1 text-center text-sm text-white font-semibold ${isLoading ? "cursor-progress" : "cursor-pointer"
                      }`}
                    onClick={() =>
                      !isLoading && getMoreDatas(false, size, page + 1)
                    }
                  >
                    {isLoading ? t('zakat.waiting') : "Load More...."}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <BottomSheet
          onDismiss={() => setToggle(false)}
          open={toggle}
          className="h-full font-Montserrat"
        >
          <div className="px-3 py-4 flex flex-col gap-4 ">
            <h6 className="font-semibold md:text-lg text-base text-[#263339]">
              {t('zakat.informationUsage')}
            </h6>
            <div className="flex flex-row justify-center gap-2">
              <div className="lg:w-1/2 md:w-2/3 w-full flex border-[#26A69A] border-[1px] border-solid rounded-[10px] mx-3 p-[35px] px-[23px] gap-4">
                <div className="w-full flex flex-col border-r-[.3px] border-solid">
                  <span className="font-semibold md:text-base text-sm text-[#263339]">
                    {formatCurrency(countAmount())}
                  </span>
                  <span className="font-normal md:text-sm text-xs text-[#263339]">
                    {t('zakat.fundsAlready')}
                  </span>
                </div>
                <div className="w-full flex flex-col">
                  <span className="font-semibold md:text-base text-sm text-[#263339]">
                    {countTotal()}&nbsp;{t('zakat.times')}
                  </span>
                  <span className="font-normal md:text-sm text-xs text-[#263339]">
                    {t('zakat.fundWithdrawal')}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <h6 className="font-semibold md:text-lg text-base text-[#263339] mb-[10px]">
                {t('zakat.totalTransaction')}
              </h6>
              <div className="space-between">
                <h6 className="font-normal md:text-base text-sm text-[#263339]">
                  {t('zakat.donationAmount')}
                </h6>
                <h6 className="font-semibold md:text-base text-sm text-[#263339]">
                  {query.state.total_transaction} {t('zakat.transaction')}
                </h6>
              </div>
              <div className="space-between">
                <h6 className="font-normal md:text-base text-sm text-[#263339]">
                  {t('zakat.numberDonors')}
                </h6>
                <h6 className="font-semibold md:text-base text-sm text-[#263339]">
                  {query.state.total_funder} {t('zakat.donors')}
                </h6>
              </div>
              <div className="space-between">
                <h6 className="font-normal md:text-base text-sm text-[#263339]">
                  {t('zakat.fundsCollected')}
                </h6>
                <h6 className="font-semibold md:text-base text-sm text-[#263339]">
                  {formatCurrency(query.state.total_dana)}
                </h6>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-4">
              <button
                onClick={() => setToggle(false)}
                type="button"
                className="bg-themePrimary text-white w-full h-10 rounded-md"
              >
                {t('zakat.close')}
              </button>
            </div>
          </div>
        </BottomSheet>
      </section>
    </>
  );
};

export default ZakatDisbursmentMobile;
