import { configureStore } from "@reduxjs/toolkit";
import companyReducer from "./company/companySlice";
import userReducer from "./user/userSlice";
import userLoginReducer from "./userLogin/userLoginSlice";
import balanceReducer from "./user/balanceSlice";
import configReducer from "./config/configSlice";
import tokenReducer from "./token/tokenSlice";
import pinReducer from "./user/pinSlice";
import otpReducer from "./otp/otpSlice";
import destinationReducer from "./otp/destinationSlice";
import addDestinationReducer from "./otp/addDestinationSlice";
import marketplaceConfigReducer from "./marketplace/marketplaceNameSlice";
import infoReducer from "./info/infoSlice";
import fireBaseTokenReducer from "./firebaseToken/firebaseTokenSlice"

// export default configureStore({
//   reducer: {
//     company: companyReducer,
//   },
// });

export const store = configureStore({
  reducer: {
    company: companyReducer,
    user: userReducer,
    userLogin: userLoginReducer,
    balance: balanceReducer,
    config: configReducer,
    token: tokenReducer,
    pin: pinReducer,
    otp: otpReducer,
    destination: destinationReducer,
    marketplaceConfig: marketplaceConfigReducer,
    info: infoReducer,
    addDestination:addDestinationReducer,
    fireBaseToken:fireBaseTokenReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;