/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Header from "../_common/headerComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faMagnifyingGlass,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { onlyNumber } from "../../config/global";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useTranslation } from "react-i18next";

const PPOBPajakPBBMobile = (props: any) => {
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={t('ppobHistory.property_tax')}></Header>

      {/* MODAL */}
      <BottomSheet
        open={props.showModal}
        onDismiss={() => {
          props.setShowModal(false);
        }}
      >
        <div className="mx-4 my-3 font-montserrat">
          <div className="relative flex items-center text-gray-400 mb-3">
            <button>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color="#848383"
                className="absolute top-[11px] ml-3"
              />
            </button>
            <input
              type="text"
              value={props.searchCluster}
              onChange={(e) => props.setSearchCluster(e.target.value)}
              className="w-full text-gray-400 font-medium placeholder-gray-400 rounded-[4px] border-none ring-2 ring-[#D9D9D9] focus:outline-2 focus:outline-gray-300 text-sm py-2 pr-3 pl-10"
            />
          </div>
          <ul className="px-0 mb-0">
            {props.clusterList
              .filter((el: any) =>
                el.name
                  .toLowerCase()
                  .includes(props.searchCluster.toLowerCase())
              )
              .map((item: any, index: any) => (
                <li
                  className="list-none cursor-pointer hover:bg-teal-50"
                  key={index}
                  onClick={() => {
                    props.setCluster(item.name);
                    props.setErrorCluster("");
                    props.getPBBList(item.name);
                    props.setShowModal(false);
                  }}
                >
                  <div className="flex flex-row justify-between items-center px-2 pt-2">
                    <p className="md:text-base text-sm font-semibold mb-0 text-start font-montserrat">
                      {item.name}
                    </p>
                  </div>
                  <hr className="mt-2" />
                </li>
              ))}
          </ul>
        </div>
      </BottomSheet>

      <BottomSheet
        open={props.showModal2}
        onDismiss={() => {
          props.setShowModal2(false);
        }}
      >
        <div className="mx-4 my-3 font-montserrat">
          <div className="relative flex items-center text-gray-400 mb-3">
            <button>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color="#848383"
                className="absolute top-[11px] ml-3"
              />
            </button>
            <input
              type="text"
              value={props.searchRegion}
              onChange={(e) => props.setSearchRegion(e.target.value)}
              className="w-full text-gray-400 font-medium placeholder-gray-400 rounded-[4px] border-none ring-2 ring-[#D9D9D9] focus:outline-2 focus:outline-gray-300 text-sm py-2 pr-3 pl-10"
            />
          </div>
          <ul className="px-0 mb-0">
            {props.pbbList
              .filter((el: any) =>
                el.name.toLowerCase().includes(props.searchRegion.toLowerCase())
              )
              .map((item: any, index: any) => (
                <li
                  className="list-none cursor-pointer hover:bg-teal-50"
                  key={index}
                  onClick={() => {
                    props.setRegion(item.name);
                    props.setErrorRegion("");
                    props.setPbbCode(item.code);
                    props.setShowModal2(false);
                  }}
                >
                  <div className="flex flex-row justify-between items-center px-2 pt-2">
                    <p className="md:text-base text-sm font-semibold mb-0 text-start font-montserrat">
                      {item.name}
                    </p>
                  </div>
                  <hr className="mt-2" />
                </li>
              ))}
          </ul>
        </div>
      </BottomSheet>

      <BottomSheet
        open={props.showModal3}
        onDismiss={() => {
          props.setShowModal3(false);
        }}
      >
        <div className="mx-4 my-3 font-montserrat">
          <ul className="px-0 mb-0">
            {props.timeList.map((item: any, index: any) => (
              <li
                className="list-none cursor-pointer hover:bg-teal-50"
                key={index}
                onClick={() => {
                  props.setTime(item);
                  props.setErrorTime("");
                  props.setShowModal3(false);
                }}
              >
                <div className="flex flex-row justify-between items-center px-2 pt-2">
                  <p className="md:text-base text-sm font-semibold mb-0 text-start font-montserrat">
                    {item}
                  </p>
                </div>
                <hr className="mt-2" />
              </li>
            ))}
          </ul>
        </div>
      </BottomSheet>
      {/* ---- */}

      <div className="w-full md:w-2/3 lg:w-1/2 mx-auto flex lg:flex-row flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 font-montserrat">
        <div className="w-full mb-20">
          <div className="flex flex-col items-center gap-3 md:mb-10 mb-6">
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('property_tax.choose_cluster')}
                </label>
                <input
                  defaultValue={props.cluster}
                  readOnly
                  onClick={() => props.setShowModal(true)}
                  className="bg-[#fff] border-[0.5px] border-[#9A9999] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                />
                <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                  <button
                    type="button"
                    onClick={() => props.setShowModal(true)}
                  >
                    <FontAwesomeIcon icon={faCaretDown} size="1x" />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0">{props.errorCluster}</p>
            </div>
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('property_tax.choose_city')}
                </label>
                <input
                  defaultValue={props.region}
                  readOnly
                  disabled={props.cluster === ""}
                  onClick={() => props.setShowModal2(true)}
                  className="bg-[#fff] border-[0.5px] border-[#9A9999] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                />
                <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                  <button
                    type="button"
                    onClick={() => props.setShowModal2(true)}
                  >
                    <FontAwesomeIcon icon={faCaretDown} size="1x" />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0 font-montserrat">
                {props.errorRegion}
              </p>
            </div>
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('property_tax.payment_pbb')}
                </label>
                <input
                  defaultValue={props.time}
                  readOnly
                  onClick={() => props.setShowModal3(true)}
                  className="bg-[#fff] border-[0.5px] border-[#9A9999] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                />
                <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                  <button
                    type="button"
                    onClick={() => props.setShowModal3(true)}
                  >
                    <FontAwesomeIcon icon={faCaretDown} size="1x" />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0">{props.errorTime}</p>
            </div>
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('property_tax.number_tax')}
                </label>
                <input
                  type="number"
                  value={props.number}
                  onKeyPress={onlyNumber}
                  onChange={(e) => {
                    props.setNumber(e.target.value);
                    props.setErrorNumber("");
                  }}
                  className="bg-[#fff] border-[0.5px] border-[#9A9999] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline"
                />
                <div className="absolute md:top-[47px] top-[40px] right-4">
                  <button
                    type="button"
                    className="bg-gray-500 flex justify-center rounded-[50%] px-[4px] py-[2px] text-white"
                    onClick={() => {
                      props.setNumber("");
                      props.setErrorNumber("");
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0">{props.errorNumber}</p>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 right-0 w-full md:w-2/3 lg:w-1/2 left-1/2 px-4"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <button
            type="button"
            style={{
              background: `${props.company?.color?.color1 !== undefined
                ? props.company?.color?.color1
                : "#26A69A"
                }`,
            }}
            className="bg-green text-white font-medium text-base rounded-[10px] w-full py-[10px] font-montserrat"
            onClick={() => props.handleOnClick()}
          >
            {t('property_tax.next')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default PPOBPajakPBBMobile;
