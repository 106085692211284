import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SettingProfileWeb from "./settingProfileWeb";
import SettingProfileMobile from "./settingProfileMobile";

const SettingProfile = () => {
  return (
    <>
      <BrowserView>
        <SettingProfileWeb></SettingProfileWeb>
      </BrowserView>
      <MobileView>
        <SettingProfileMobile></SettingProfileMobile>
      </MobileView>
    </>
  );
};

export default SettingProfile;
