/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Header from "../../_common/headerComponent";
import empty from "../../../assets/images/empty-img.svg";
import data_not_found from "../../../assets/images/icon_marketplace/data_not_found.svg";
import moment from "moment";
import { formatCurrency } from "../../../config/global";
import { BottomSheet } from "react-spring-bottom-sheet";
import Company from "../../../core/models/company";
import LoadMoreButton from "../../_common/loadMoreButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { useAppSelector } from "../../../core/feature/hooks";
import { useTranslation } from "react-i18next";

const CommerceOrderHistoriesMobile = (props: any) => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const [showBottomSheetSort, setShowBottomSheetSort] = React.useState(false);
  const [showBottomSheetFilter, setShowBottomSheetFilter] = React.useState(false);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile pb-4">
      {props.navigateTo === "" ? (
        <Header
          navbarText={t("history.sectionHeader")}
          backToUrl={`/${company?.initial}`}
        />
      ) : (
        <Header navbarText={t('history.sectionHeader')} navigateTo={props.navigateTo} />
      )}
      <div className="container flex flex-col items-center md:mt-[64px] mt-[0px] px-0">
        <div className="md:w-4/5 w-full mx-auto">
          <Swiper
            freeMode={true}
            grabCursor={true}
            modules={[FreeMode]}
            slidesPerView={"auto"}
            spaceBetween={20}
            className="w-full relative justify-center mySwiper"
          >
            {props.allStatus.map((item: any, index: number) => (
              <SwiperSlide
                key={index}
                className="swiper-slide-auto"
              >
                <button
                  onClick={() => {
                    props.setStatusTrx(item.value);
                    props.setOpenTab(item.id);
                    props.setPage(1);
                    props.getHistory(1, item.value);
                  }}
                  style={{
                    color:
                      props.openTab !== item.id
                        ? "#263339"
                        : company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A",
                    borderColor:
                      props.openTab !== item.id
                        ? "#FFFFFF"
                        : company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A",

                  }}
                  className={`${props.openTab === item.id ? "border-b-[2px]" : ""} inline-block px-2 py-2 w-full md:text-base text-sm font-medium`}
                >
                  {item.title}
                </button>
              </SwiperSlide>
            ))}
          </Swiper>

          {props.history.length < 1 ? (
            <DataIsEmpty />
          ) : (
            <section className="flex flex-col gap-6 w-full px-7">
              {props.history.map((res: any) => (
                <div className="flex flex-col gap-2.5">
                  {res.orderList?.map((order: any) => (
                    <div
                      className="bg-white flex flex-col w-full px-4 py-3 rounded-lg"
                      onClick={() =>
                        props.navigate(
                          `/${company?.initial}/commerce/history/detail/${order.id}`,
                          { state: { id: order.id } }
                        )
                      }
                    >
                      <div className="flex flex-row justify-between items-center border-b-[0.5px] border-b-[#E5E5E5] pb-2">
                        <div className="flex flex-col">
                          <h1 className="m-0 font-medium md:text-sm text-xs text-customBlack">
                            {t('history.dateLabel')}
                          </h1>
                          <span className="m-0 md:text-sm text-xs text-customBlack">
                            {moment(res.orderDate).format("DD MMM YYYY")}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <h6 className="text-customBlack md:text-sm text-xs mb-0">{t('history.transactionLabel')}</h6>
                          <p className="text-newColor md:text-sm text-xs mb-0">
                            {res.transactionId > 15 ? res.transactionId.substring(0, 15) + "..." : res.transactionId}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col gap-3 pt-2">
                        <div className="flex flex-col">
                          <div className="flex flex-row justify-between items-center">
                            <h1 className="m-0 font-medium text-xs capitalize">
                              {order.merchant.name}
                            </h1>
                            <span
                              className={`font-medium md:text-xs text-[10px] ${props.getStatus(order.status).bgColor
                                } py-1.5 px-2 rounded-md ${props.getStatus(order.status).textColor
                                }`}
                            >
                              {props.getStatus(order.status).text}
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 border-b-[0.5px] border-[#E5E5E5] pb-2">
                            {order?.products?.map((product: any) => (
                              <div className="p-1 flex flex-row gap-2 mt-2">
                                <img
                                  src={
                                    product.imageList[0] !== ""
                                      ? product.imageList[0]
                                      : empty
                                  }
                                  alt=""
                                  className="flex-0 w-[96px] h-[96px] rounded-lg object-cover"
                                />
                                <div className="grow flex flex-col justify-between gap-1">
                                  <h1 className="m-0 font-medium text-sm capitalize">
                                    {product.productName}
                                  </h1>
                                  <div className="flex items-start justify-between">
                                    <p className="text-xs md:text-sm font-light text-newColor m-0 w-fit rounded-md inline-block">
                                      <span>
                                        {product.variantKey}
                                      </span>
                                      &nbsp; : {product.itemName}
                                    </p>
                                    <div className="flex flex-col items-end">
                                      <span className="md:text-sm text-xs text-newColor text-end font-semibold">
                                        X{product.quantity}
                                      </span>
                                      <span className="md:text-sm text-xs text-newColor text-end">
                                        {formatCurrency(
                                          product.price * product.quantity
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="flex-0 flex flex-col items-end gap-2 text-white">
                            <div className="flex flex-row w-full items-center justify-between border-b-[0.5px] border-[#E5E5E5] py-2">
                              <h1 className="m-0 font-light text-newColor md:text-sm text-xs">
                                {props.quantity[res.transactionId]} {t('history.totalItems')}
                              </h1>
                              <div>
                                <span className="text-newColor md:text-sm text-xs">{t('history.totalAmount')}: </span>
                                <span className="text-green md:text-sm text-xs font-bold">{formatCurrency(order.amountTotal)}</span>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between gap-2">
                              <button className="font-normal md:text-sm text-xs text-green">
                                {t('history.viewTransactionDetail')}
                              </button>
                              <button
                                style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                                hidden={
                                  order?.status === "CONFIRMED" ? false : true
                                }
                                className="font-medium md:text-sm text-xs bg-green text-white px-3 py-1 rounded-lg"
                              >
                                {t('history.buyAgain')}
                              </button>
                              <button
                                hidden={order?.status === "CREATED" ? false : true}
                                className="font-medium md:text-sm text-xs bg-white text-newColor border-newColor border-[1px] px-2 py-1 rounded-lg"
                              >
                                {t('history.cancelOrder')}
                              </button>
                              <button
                                style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                                hidden={
                                  order?.status === "WAITING_PAYMENT"
                                    ? false
                                    : true
                                }
                                className="font-medium md:text-sm text-xs bg-[#12C28F] text-white px-2 py-1 rounded-lg"
                              >
                                {t('history.payNow')}
                              </button>
                            </div>
                          </div>
                          {/* <hr className="border-[0.5px] my-2" /> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </section>
          )}
        </div>
      </div>
      {props.currentSize < 10 ? "" : <LoadMoreButton addMore={props.addMore} />}

      {/* Bottom sheet Sort  */}
      <BottomSheet
        onDismiss={() => setShowBottomSheetSort(false)}
        open={showBottomSheetSort}
        className="h-full"
      >
        <div className="px-3 py-4 pt-0 flex flex-col gap-1 font-montserrat mb-2">
          <div className="font-semibold ">
            <h1 className="font-semibold text-sm m-0">{t('history.sortHeader')}</h1>
            <p className="m-0 mb-1 font-light text-xs text-[#9A9999]">
              {t('history.sortByOrderStatus')}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="sort"
                id="sort1"
                value="-createdDate"
                onChange={props.onSortChange}
                checked={props.sort === "-createdDate"}
              />
              <span className="font-medium text-sm">{t('history.newestOrders')}</span>
            </label>
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="sort"
                id="sort2"
                value="createdDate"
                onChange={props.onSortChange}
                checked={props.sort === "createdDate"}
              />
              {t('history.oldestOrders')}
            </label>
          </div>
          <button
            style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
            onClick={() => {
              props.getHistory(1);
              setShowBottomSheetSort(false);
              props.setPage(1);
            }}
            className="w-full px-4 py-2.5 text-white rounded-lg text-base font-medium mt-3 bg-green"
          >
            {t('history.confirmSort')}
          </button>
        </div>
      </BottomSheet>

      {/* Bottom sheet Filter  */}
      <BottomSheet
        onDismiss={() => setShowBottomSheetFilter(false)}
        open={showBottomSheetFilter}
        className="h-full"
      >
        <div className="px-3 py-4 pt-0 flex flex-col gap-1 font-montserrat mb-2">
          <div className="font-semibold ">
            <h1 className="font-semibold text-sm m-0">{t('history.filterHeader')}</h1>
            <p className="m-0 mb-1 font-light text-xs text-[#9A9999]">
              {t('history.filterByItemStatus')}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="filter"
                id="filter1"
                value=""
                onChange={props.onFilterChange}
                checked={props.statusTrx === ""}
              />
              {t('history.all')}
            </label>
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="filter"
                id="filter1"
                value="WAITING_PAYMENT"
                onChange={props.onFilterChange}
                checked={props.statusTrx === "WAITING_PAYMENT"}
              />
              {t('history.waitingPayment')}
            </label>
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="filter"
                id="filter2"
                onChange={props.onFilterChange}
                value="CREATED"
                checked={props.statusTrx === "CREATED"}
              />
              {t('history.created')}
            </label>
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="filter"
                id="filter1"
                value="ON_REVIEW"
                onChange={props.onFilterChange}
                checked={props.statusTrx === "ON_REVIEW"}
              />
              {t('history.onReview')}
            </label>
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="filter"
                id="filter3"
                onChange={props.onFilterChange}
                value="CANCELED"
                checked={props.statusTrx === "CANCELED"}
              />
              {t('history.canceled')}
            </label>
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="filter"
                id="filter4"
                onChange={props.onFilterChange}
                value="REJECTED"
                checked={props.statusTrx === "REJECTED"}
              />
              {t('history.rejected')}
            </label>
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="filter"
                id="filter4"
                onChange={props.onFilterChange}
                value="PROCESSED"
                checked={props.statusTrx === "PROCESSED"}
              />
              {t('history.processed')}
            </label>
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="filter"
                id="filter6"
                onChange={props.onFilterChange}
                value="SENT"
                checked={props.statusTrx === "SENT"}
              />
              {t('history.sent')}
            </label>
            <label className="flex flex-row items-center gap-2.5 text-sm font-medium ">
              <input
                className="w-fit m-0 bg-gray-100 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                type="radio"
                name="filter"
                id="filter5"
                onChange={props.onFilterChange}
                value="CONFIRMED"
                checked={props.statusTrx === "CONFIRMED"}
              />
              {t('history.confirmed')}
            </label>
          </div>
          <button
            style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
            onClick={() => {
              props.getHistory(1);
              setShowBottomSheetFilter(false);
              props.setPage(1);
            }}
            className="w-full px-4 py-2.5 text-white rounded-lg text-base font-medium mt-3 bg-green"
          >
            {t('history.confirmSort')}
          </button>
        </div>
      </BottomSheet>
    </section>
  );
};

const DataIsEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className="px-3 flex flex-col gap-4 md:w-[400px] w-[300px] mx-auto">
      <img src={data_not_found} alt="" />
      <div className="flex flex-col gap-2.5 items-center">
        <h1 className="m-0 font-bold text-xl text-center">
          {t('history.noRiwayat')}
        </h1>
        <p className="m-0 font-normal text-xs text-[#848383] text-center">
          {t('history.noRiwayatDesc')}
        </p>
      </div>
    </div>
  );
};

export default CommerceOrderHistoriesMobile;