import { toast } from "react-hot-toast";
import { BottomSheet } from "react-spring-bottom-sheet";
import arrow from "../../assets/images/arrow_right.svg";
import Logout from "../../assets/images/cta/log-out.svg";
import Password from "../../assets/images/icon/auto login.svg";
import Unlock from "../../assets/images/icon/change pass.svg";
import Users from "../../assets/images/icon/changeakun.svg";
import userIcon from "../../assets/images/icon/editprofil.svg";
import ShieldDone from "../../assets/images/icon/pin.svg";
import UserSupport from "../../assets/images/icon/support.svg";
import Work from "../../assets/images/icon/termofservice.svg";
import Voice from "../../assets/images/icon/ubah bahasa.svg";
import "./styles.css";

import { formatCurrency, goToUrl } from "../../../config/global";

import Header from "../../_common/headerComponent";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import iconSearch from "../../../assets/images/icon_rent/search-normal.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Dummy from "../dummy";
import _ from "lodash";
import locationIcon from "../../../assets/images/icon_rent/location.svg";
import Active from "../../../assets/images/icon_rent/active.svg";
import NoActive from "../../../assets/images/icon_rent/noActive.svg";

import Round from "../../assets/images/icon_rent/round.svg";
import { Fragment, useEffect, useState } from "react";
import DetailProduct from "./DetailProduct";
import moment from "moment";
import Company from "../../../core/models/company";
import { useAppSelector } from "../../../core/feature/hooks";

const RentPaymentMobile = (props: any) => {
  const [enabled, setEnabled] = useState(false);
  const company: Company = useAppSelector((state) => state.company.company);

  const [show, setShow] = useState(false);
  const [venue, setVenue] = useState("");
  const params = useParams();
  const navigate = useNavigate()
  const location = useLocation();
  const [data, setData] = useState<any>("");
console.log(data);

  useEffect(() => {
    setData(location.state);
  }, []);
  return (
    <>
      <section className="h-full min-h-screen font-montserrat pb-24 bg-[#f5f5f5]">
        <Header navbarText="Menu Sewa"></Header>
        <div className="px-6 lg:w-[50%] md:w-[70%] mx-auto">
          <div className="bg-white rounded-[14px]">
            <div className="relative p-3">
              <img
                src="https://lapanganfutsal.id/wp-content/uploads/2023/01/flamboyan-futsal-lapangan-futsal-pekanbaru-1.jpg"
                alt=""
                className="rounded-[5px] h-52 w-full object-center object-cover"
              />
              {/* <div className="absolute bottom-0 border-[1px] border-[#f5f5f5]"/> */}
            </div>
            <div className="relative">
              <div className="absolute top-0 left-0 bg-[#f5f5f5] w-6 h-6 rounded-r-full ml-[-10px]" />
              <div className="top-3 relative border-dotted border-b border-[#CCCCCC] w-[90%] mx-auto"></div>
              <div className="absolute right-0 top-0 bg-[#f5f5f5] w-6 h-6 rounded-l-full mr-[-10px]" />
              <div className="p-4 space-y-4 mt-4">
                <span className="block text-base font-[500]">Ringkasan</span>
                <div className="flex justify-between text-base">
                  <span>Nama</span>
                  <span>Tegar</span>
                </div>
                <div className="flex justify-between text-base">
                  <span>Gedung</span>
                  <span>{data?.data?.lapangan}</span>
                </div>
                <div className="flex justify-between text-base">
                  <span>Jam</span>
                  <div className="flex flex-col text-right">
                    {data?.payment?.map((el: any, index: any) => {
                      return (
                        <div>
                          <span>{el.start}</span>-<span>{el.end}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex justify-between text-base">
                  <span>Pembayaran</span>
                  <span>
                    {" "}
                    {formatCurrency(_.sumBy(data?.payment, "price"))}
                  </span>
                </div>
                <div className="grid grid-cols-2 text-base">
                  <span>Catatan</span>
                  <span className="text-right break-words">-</span>
                </div>
              </div>
            </div>
          </div>

          <div className="relative">
            <div className="fixed bottom-0 left-0 right-0 p-4 z-5 bg-[#f5f5f5]">
              <button
                className="bg-[#3867E0] font-[500] text-white w-full p-2 rounded-[8px]"
                onClick={() => navigate(`/${company.initial}/rent/result`,{state:data})}
              >
                Pesan Sekarang
              </button>
            </div>

    
          </div>
        </div>
      </section>
    </>
  );
};

export default RentPaymentMobile;
