import { BrowserView, MobileView } from "react-device-detect";
import PesananDetailWeb from "../pesananDetailComponent/pesananDetailWeb";
import PesananDetailMobile from "../pesananDetailComponent/pesananDetailMobile";

const PesananDetail = () => {
  return (
    <>
      <BrowserView>
        <PesananDetailWeb></PesananDetailWeb>
      </BrowserView>
      <MobileView>
        <PesananDetailMobile></PesananDetailMobile>
      </MobileView>
    </>
  );
};

export default PesananDetail;
