import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import Camera from "../../assets/images/Camera.png";
import EditFoto from "../../assets/images/gallery-edit.svg";
import { onlyNumber } from "../../config/global";
import Header from "../_common/headerComponent";
import "./index.scss";

const EditProfileWeb = (props: any) => {
  
  return (
    <section className=" h-full min-h-screen font-poppins bg-mobile">
      <Header />

      <div className="md:w-1/2 w-full mx-auto md:mt-[64px] mt-[0px] pb-4 px-4">
        <div className="flex w-full h-auto justify-center min-w-[58px] mb-4 relative">
          <div
            className="rounded-full h-[221px] w-[221px]"
            onClick={() => props.handleClick()}
          >
            <div className="bg-[#00000027] md:bg-transparent md:hover:bg-[#00000027] absolute h-[221px] w-[221px] flex flex-row rounded-full items-center justify-center">
              <img src={Camera} alt="" className="h-14" />
            </div>
            <img
              src={props.previewImage || props.getProfileImage}
              alt=""
              className="rounded-full h-[221px] w-[221px] object-cover"
            />
            <input
              accept="image/*"
              style={{ display: "none" }}
              ref={props.inputRef}
              type="file"
              className="bg-red opacity-0 w-36 h-36 rounded-full cursor-pointer absolute"
              onChange={props.inputHandler}
            />
          </div>
        </div>

        <div className="flex gap-2 justify-center">
          <button className="flex gap-2" onClick={() => props.handleClick()}>
            <img src={EditFoto} alt="" />
            <span className="text-[#728F9E] text-base">
              {props.t("editPhoto.title")}
            </span>
          </button>
        </div>

        <div className="px-3 flex flex-col gap-2">
          {props.metadata.map((res: any) => {
            if (res.data.typeData === undefined) {
              if (res.data.key === "phone") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="tel"
                      onChange={(e) =>
                        props.changeValue(
                          e.target.value,
                          res.data.key,
                          res.data.tag
                        )
                      }
                      onKeyPress={onlyNumber}
                      value={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      readOnly
                      disabled
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    />
                  </div>
                );
              } else if (res.data.key === "dateOfBirth") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <DatePicker
                      selected={props.customDate[res.data.key]}
                      dateFormat="dd MMMM yyyy"
                      onChange={(e: any) => {
                        props.changeValue(
                          moment(e).format("YYYY-MM-DD"),
                          res.data.key,
                          res.data.tag
                        );
                        props.setCustomDate({
                          ...props.customDate,
                          [res.data.key]: e,
                        });
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      maxDate={new Date()}
                      dropdownMode="select"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.key === "gender") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3 select-edit-profile"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <Select
                      options={props.genderList}
                      value={props.selectedData[res.data.key]}
                      onChange={(e) => {
                        props.changeValue(e.value, res.data.key, res.data.tag);
                        props.changeSelectedData(e, res.data.key);
                      }}
                      isDisabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.key === "tipeNik") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3 select-edit-profile"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <Select
                      options={props.identityType}
                      defaultValue={props.selectedData[res.data.key]}
                      isDisabled
                      // onChange={(e) => {
                      //   props.changeValue(e.value, res.data.key, res.data.tag);
                      //   changeSelectedData(e, res.data.key);
                      // }}
                    />
                  </div>
                );
              } else if (res.data.key === "lang") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3 select-edit-profile"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <Select
                      options={props.languageList}
                      value={props.languageList.find(
                        (option: any) => option.value === props.selectedLanguage
                      )}
                      onChange={(e) =>
                        props.handleLanguageChange(
                          e,
                          res.data.key,
                          res.data.tag
                        )
                      }
                      isDisabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.key === "username") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3 cek"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type={"text"}
                      defaultValue={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      readOnly
                      disabled
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    />
                  </div>
                );
              } else if (res.data.key === "tags") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3 cek"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <TagsInput
                      focusedClassName="tag-input-focused"
                      value={props.tags}
                      onChange={props.handleTags}
                      disabled
                      tagProps={{
                        className:
                          "react-tagsinput-tag bg-info text-white rounded h-11",
                      }}
                    />
                  </div>
                );
              } else if (res.data.key === "noId") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3 cek"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type={"text"}
                      defaultValue={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      readOnly
                      disabled
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    />
                  </div>
                );
              } else if (res.data.key === "email") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3 cek"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type={"text"}
                      defaultValue={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      readOnly
                      disabled
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3 cek"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type={"text"}
                      value={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      onChange={(e) =>
                        props.changeValue(
                          e.target.value,
                          res.data.key,
                          res.data.tag
                        )
                      }
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              }
            } else {
              if (res.data.typeData === "Enum") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3 select-edit-profile"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <Select
                      options={props.extraDatas[res.data.key]}
                      value={props.selectedData[res.data.key]}
                      onChange={(e) => {
                        props.changeValue(
                          e.value,
                          res.data.key,
                          res.data.typeData
                        );
                        props.changeSelectedData(e, res.data.key);
                      }}
                      isDisabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.typeData === "Flags") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}{" "}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                      {res.data.extraData.map((flag: any, index: string) => (
                        <div className="flex flex-row gap-2">
                          <input
                            type="checkbox"
                            id={res.data.key + index}
                            name={res.data.key}
                            value={res.data.key}
                            checked={
                              props.flagDatas[res.data.key] !== undefined
                                ? props.flagDatas[res.data.key][flag]
                                : false
                            }
                            onChange={() => {
                              props.changeValue(
                                flag,
                                res.data.key,
                                res.data.tag,
                                res.data.typeData
                              );
                              props.setFlagDatas({
                                ...props.flagDatas,
                                [res.data.key]: {
                                  ...props.flagDatas[res.data.key],
                                  [flag]: !props.flagDatas[res.data.key][flag],
                                },
                              });
                            }}
                            disabled={res.data.isReadOnly}
                          />
                          <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                            {flag}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              } else if (res.data.typeData === "Date") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <DatePicker
                      selected={props.customDate[res.data.key]}
                      dateFormat="dd MMMM yyyy"
                      onChange={(e: any) => {
                        props.changeValue(
                          moment(e).format("YYYY-MM-DD"),
                          res.data.key,
                          res.data.tag,
                          res.data.typeData
                        );
                        props.setCustomDate({
                          ...props.customDate,
                          [res.data.key]: e,
                        });
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      maxDate={new Date()}
                      dropdownMode="select"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.typeData === "Datetime") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <DatePicker
                      selected={props.customDate[res.data.key]}
                      onChange={(e: any) => {
                        props.changeValue(
                          moment(e).format("YYYY-MM-DD hh:mm:ss"),
                          res.data.key,
                          res.data.tag,
                          res.data.typeData
                        );
                        props.setCustomDate({
                          ...props.customDate,
                          [res.data.key]: e,
                        });
                      }}
                      showTimeSelect
                      filterTime={props.filterPassedTime}
                      dateFormat="dd MMMM yyyy, hh:mm:ss"
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.typeData === "Time") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="time"
                      step="2"
                      value={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      onChange={(e) => {
                        props.changeValue(
                          e.target.value,
                          res.data.key,
                          res.data.tag
                        );
                      }}
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.typeData === "Email") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="email"
                      value={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      onChange={(e) =>
                        props.changeValue(
                          e.target.value,
                          res.data.key,
                          res.data.tag
                        )
                      }
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.typeData === "Integer") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="number"
                      onKeyPress={onlyNumber}
                      value={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      onChange={(e) => {
                        if (
                          e.target.value.length > 1 &&
                          e.target.value[0] === "0"
                        ) {
                          e.target.value = e.target.value.slice(1);
                          props.changeValue(
                            e.target.value,
                            res.data.key,
                            res.data.tag
                          );
                          // console.log(e.target.value);
                        } else {
                          props.changeValue(
                            e.target.value,
                            res.data.key,
                            res.data.tag
                          );
                        }
                      }}
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.typeData === "Numeric") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="number"
                      value={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      onChange={(e) =>
                        props.changeValue(
                          e.target.value,
                          res.data.key,
                          res.data.tag
                        )
                      }
                      onKeyPress={onlyNumber}
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else if (res.data.typeData === "Phone") {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>

                    <input
                      type="tel"
                      onChange={(e) =>
                        props.changeValue(
                          e.target.value,
                          res.data.key,
                          res.data.tag
                        )
                      }
                      onKeyPress={onlyNumber}
                      value={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    className="flex flex-col gap-1 mt-3"
                    hidden={!res.data.isVisible}
                  >
                    <label className="text-md text-[#C4C4C4] leading-5 font-medium font-montserrat">
                      {res.data.display}
                      <span
                        hidden={!res.data.isRequired}
                        className="text-red text-sm ml-0.5"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        props.changeValue(
                          e.target.value,
                          res.data.key,
                          res.data.tag
                        )
                      }
                      value={
                        res.data.tag === "BasicData"
                          ? props.dataOrder[res.data.key] === null
                            ? ""
                            : props.dataOrder[res.data.key]
                          : props.dataOrder.additionalData[res.data.key] ===
                            null
                          ? ""
                          : props.dataOrder.additionalData[res.data.key]
                      }
                      className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                      disabled={res.data.isReadOnly}
                    />
                  </div>
                );
              }
            }
          })}
          <button
            onClick={() => {
              props.confirm();
            }}
            style={{
              backgroundColor: `${props.color?.color1}`,
            }}
            className="p-2.5 text-white rounded-lg mt-4 font-medium text-base"
          >
            Simpan
          </button>
        </div>
      </div>
    </section>
  );
};

export default EditProfileWeb;
