/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import * as API_BALANCE from "../../../core/service/api_balance";
// import BottomLogo from "../../_common/bottomLogo";
import { formatCurrency } from "../../../config/global";
import { setLoading } from "../../../core/feature/config/configSlice";
import Company from "../../../core/models/company";
import Header from "../../_common/headerComponent";
import Reauthenticate from "../../_common/reauthenticate";
// import User from "../../../core/models/user";
import { setPin } from "../../../core/feature/user/pinSlice";
import { getPinConfig } from "../../../core/service/api_user";
import { useTranslation } from "react-i18next";

const SummaryMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const { t } = useTranslation();
  // const user: User = useAppSelector((state) => state.user.user);
  const pin: number = useAppSelector((state) => state.pin.pin);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [data, setData] = React.useState<any>({});
  const [showDynamicModal, setShowDynamicModal] = React.useState(false);
  const [showStaticModal, setShowStaticModal] = React.useState(false);
  const [inquiryId, setInquiryId] = React.useState("");
  const [transactionType, setTransactionType] = React.useState("");
  const [balanceCode, setBalanceCode] = React.useState("");
  const [showDetailPayment, setShowDetailPayment] = React.useState(false);

  const inquiryDynamicBarcode = async () => {
    dispatch(setLoading(true));
    let barcode = location.state.barcode;
    await API_BALANCE.inquiryDynamicBarcode(barcode)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data.detailTransaction);
        setInquiryId(res.data.data.inquiryId);
        setTransactionType(res.data.data.detailTransaction.transactionType);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getConfigPin = async () => {
    await getPinConfig()
      .then((res) => {
        console.log(res);

        dispatch(setPin(Number(res.data.data)));
      })
      .catch((err) => {
        // dispatch(removeCompany());
      });
  };
  const handleOnClick = () => {
    if (pin === 1) {
      switch (location.state.type) {
        case "static":
          setShowStaticModal(true);
          break;

        case "dynamic":
          setShowDynamicModal(true);
          break;

        default:
          break;
      }
    } else {
      navigate(`/${company?.initial}/create-security-code`, {
        state: {
          data: location.state.data,
          url: "barcode/summary",
          type: location.state.type,
        },
      });
    }
  };

  React.useEffect(() => {
    getConfigPin();
    setBalanceCode(location.state.balanceCode);
    switch (location.state.type) {
      case "static":
        setData(location.state.data);
        setInquiryId(location.state.inquiryId);
        break;

      case "dynamic":
        inquiryDynamicBarcode();
        break;

      default:
        break;
    }
    setShowDetailPayment(true);
  }, []);

  return (
    <>
      <section className="h-full min-h-screen font-montserrat bg-white">
        <Reauthenticate
          isNewDesign={true}
          show={showDynamicModal}
          onHide={() => setShowDynamicModal(false)}
          transactionType={transactionType}
          inquiryId={inquiryId}
          amount={data.amount}
          balanceCode={balanceCode}
          redirect={`/${company?.initial}/barcode/payment-summary`}
          dataOrder={data}
          width={40}
          height={43}
          margin="0 0.2rem"
          fontSize="1rem"
        />
        <Reauthenticate
          isNewDesign={true}
          show={showStaticModal}
          onHide={() => setShowStaticModal(false)}
          transactionType="QR_CODE"
          isPaymentOpen={true}
          inquiryId={inquiryId}
          amount={data.amount}
          balanceCode={balanceCode}
          redirect={`/${company?.initial}/barcode/payment-summary`}
          dataOrder={data}
          width={40}
          height={43}
          margin="0 0.2rem"
          fontSize="1rem"
          setShowDetailPayment={setShowDetailPayment}
        />

        <Header navbarText={t('barcodeWeb.payBardcode')} />
        {showDetailPayment && (
          <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 pb-[64px]">
            <div className="md:w-3/5 w-full mx-auto">
              <div className="w-full bg-white px-3 py-10 rounded-md">
                <h6 className="text-lg md:text-xl font-semibold text-green">
                  {t('barcodeWeb.summary')}
                </h6>
                <hr />
                <div className="space-between gap-3 mt-3 overflow-hidden">
                  <h6 className="summary-page-text text-start">
                    {t('barcodeWeb.merchantName')}
                  </h6>
                  <h6 className="summary-page-text text-end text-[#728F9E]">
                    {data.merchantName}
                  </h6>
                </div>
                <div className="space-between gap-3 mt-3 overflow-hidden">
                  <h6 className="summary-page-text text-start">
                    {t('barcodeWeb.paymentMethod')}
                  </h6>
                  <h6 className="summary-page-text text-end text-[#728F9E]">
                    {location.state.type === "static"
                      ? "Barcode Statis"
                      : "Barcode Dinamis"}
                  </h6>
                </div>
                <div className="space-between gap-3 mt-3 overflow-hidden">
                  <h6 className="summary-page-text text-start">Kategori</h6>
                  <h6 className="summary-page-text text-end text-[#728F9E]">
                    {t('barcodeWeb.paymentBarcode')}
                  </h6>
                </div>
                <div className="space-between gap-3 mt-3 overflow-hidden">
                  <h6 className="summary-page-text text-start">{t('barcodeWeb.nominal')}</h6>
                  <h6 className="summary-page-text text-end text-[#728F9E]">
                    {formatCurrency(data.amount)}
                  </h6>
                </div>
                <div className="space-between gap-3 mt-3 overflow-hidden">
                  <h6 className="summary-page-text text-start">{t('topup.adminFee')}</h6>
                  <h6 className="summary-page-text text-end text-[#728F9E]">
                    {formatCurrency(data.totalCharges)}
                  </h6>
                </div>
                <hr />
                <div className="space-between gap-3 mt-3 overflow-hidden">
                  <h6 className="summary-page-text text-start">{t('barcodeWeb.total')}</h6>
                  <h6 className="summary-page-text text-end text-[#728F9E]">
                    {formatCurrency(data.total)}
                  </h6>
                </div>
              </div>
              <div className="w-full mb-6 mt-5">
                <button
                  type="button"
                  onClick={handleOnClick}
                  style={{
                    backgroundColor:
                      company?.color?.color1 !== undefined
                        ? company?.color?.color1
                        : "#26A69A",
                  }}
                  className="bg-[#26A69A] w-full py-2.5 text-[16px] text-white rounded-xl"
                >
                  {t('barcodeWeb.continue')}
                </button>
              </div>
            </div>
          </div>
        )}
        {/* <BottomLogo /> */}
      </section>
    </>
  );
};

export default SummaryMobile;
