import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useNavigate, useParams } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import { toast } from "react-hot-toast";
import { bearer } from "../../config/global";
import { addDonation } from "../../core/service/api_donation";
import Header from "../_common/headerComponent";
import { ConfigColor } from "../../config/global";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
import { useTranslation } from "react-i18next";

const DonationAddMobile = () => {
  const color = ConfigColor();
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [message, setMessage] = useState("");
  const [amountPayment, setAmountPayment] = useState("");
  const navigate = useNavigate()

  const handlePayment = () => {
    if (parseInt(amountPayment) < 1 || amountPayment === "")
      return toast.error(t("donation.nominalRequired"));
    let newData = {
      amount: parseInt(amountPayment),
      donationId: params.id,
      isAnonymous,
      message,
    };
    addDonation(newData)
      .then((response) => {
        window.open(
          `https://${response.data.data.link
          }?access_token=${bearer().replaceAll('"', "")}`,
          "_self"
        );
        navigate(`/${company.initial}?redirect=false`);
        // else toast.error("Mohon coba lagi, link donasi belum tersedia");
        // navigate(`/${company?.initial}/donations/donation/detail/${params.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const params = useParams()

  return (
    <>
      <section className="h-[calc(100vh-110px)] min-h-screen font-Montserrat bg-mobile">
        <Header navbarText={t('donation.donation')}></Header>
        <div className="h-[calc(100vh-80px)] m-auto font-Montserrat">
          <div className="flex flex-col justify-between h-full w-full bg-white shadow-sm shadow-gray-700 gap-1 p-2">
            <div className="flex flex-col mx-3 py-3 gap-4">
              <div className={`flex flex-col gap-1 mt-3`}>
                <label className="text-base text-thirdy font-medium pl-1 mb-0">
                  {t("donation.insertNominal")}
                </label>
                <CurrencyInput
                  className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                  defaultValue={1000}
                  allowDecimals={false}
                  allowNegativeValue={false}
                  value={amountPayment}
                  prefix={"Rp "}
                  onValueChange={(value: any, name) => {
                    setAmountPayment(value || "");
                  }}
                />
              </div>
              <div className={`flex flex-col gap-1 mt-3`}>
                <label className="text-base text-thirdy font-medium pl-1 mb-0">
                  {t("donation.message")}
                </label>
                <textarea
                  name="message"
                  id="message"
                  cols={30}
                  rows={5}
                  className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className={`flex gap-1 mt-3`}>
                <label
                  htmlFor="isAnonymous"
                  className="text-base text-thirdy font-medium pl-1 mb-0"
                >
                  {t("donation.invisibleName")}
                </label>
                <input
                  type="checkbox"
                  id="isAnonymous"
                  checked={isAnonymous}
                  onChange={() => setIsAnonymous(!isAnonymous)}
                />
              </div>
            </div>
            <div className="sticky bg-white bottom-0 w-full p-4 mt-4 flex flex-col gap-2">
              <button
                onClick={() => handlePayment()}
                type="button"
                className="bg-themePrimary text-white w-full h-10 rounded-md"
                style={{ backgroundColor: color.color1 }}
              >
                {t("donation.donateNow")}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DonationAddMobile;
