/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";

import Pending from "../../../assets/images/Pending.png";
import Failed from "../../../assets/images/security-fail.png";
import Success from "../../../assets/images/security_success.png";
import { formatCurrency } from "../../../config/global";
import { useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import Header from "../../_common/headerComponent";

const DetailMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);

  const navigate = useNavigate();
  const location: any = useLocation();
  const [data, setData] = useState<any>(location.state || "");
console.log(data);

  return (
    <section className="h-full min-h-screen relative font-montserrat bg-mobile">
      {/* MODAL */}

      <Header navbarText="Detail Transaksi" />

      <div className="container flex flex-col justify-center items-center gap-4 md:mt-[64px] md:pb-[64px] pb-[1.5rem] mt-[0px] px-4">
        <div className="md:w-3/5 sm:w-full w-full mb-20 flex flex-col justify-between ">
          <div className="flex-grow">
            {data.status === "PAID" ? (
              <div className="flex flex-col gap-2">
                <img src={Success} alt="" className="mx-auto" />
                <span className="text-xl text-[#26A69A] font-[600] text-center">
                  Berhasil
                </span>
              </div>
            ) : data.status === "GAGAL" || data.status === "UNPAID" ? (
              <div className="flex flex-col gap-2">
                <img src={Failed} alt="" className="mx-auto w-32" />
                <span className="text-xl font-[600] text-center text-red">
                  {data.status === "GAGAL" ? "Gagal" : "Belum Dibayar"}
                </span>
              </div>
            ) : data.status === "PROCESSING" ? (
              <div className="flex flex-col gap-2">
                <img src={Pending} alt="" className="mx-auto w-32" />
                <span className="text-xl text-amber-400 font-[600] text-center">
                  Sedang Diproses
                </span>
              </div>
            ) : (
              ""
            )}

            <div>
              <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                <div className="grid grid-cols-2">
                  <span className="text-sm font-[500] break-words">
                    Tanggal Transaksi
                  </span>
                  <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                    {moment
                      .utc(data.createdDate)
                      .local()
                      .format("DD MMMM YYYY")}
                  </span>
                </div>
                <div className="grid grid-cols-2">
                  <span className="text-sm font-[500] break-words">
                    Tanggal Kadaluarsa
                  </span>
                  <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                    {moment
                      .utc(data.expiredDate)
                      .local()
                      .format("DD MMMM YYYY")}
                  </span>
                </div>
                <div className="grid grid-cols-2">
                  <span className="text-sm font-[500] break-words">
                    Jenis Transaksi
                  </span>
                  <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                    {/* {location.state.transactionType || "-"} */}
                    Langganan
                  </span>
                </div>
              </div>
              <div className="py-3 border-b border-[#728F9E] flex flex-col gap-3">
                <div className="grid grid-cols-2">
                  <span className="text-sm font-[500] break-words">
                    Nama Pelanggan
                  </span>
                  <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                    {data.member.name}
                  </span>
                </div>
                <div className="grid grid-cols-2">
                  <span className="text-sm font-[500] break-words">
                    Id Pelanggan
                  </span>
                  <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                    {data.member.noId}
                  </span>
                </div>
              </div>
              <div className="py-3 flex flex-col gap-3">
                <div className="grid grid-cols-2">
                  <span className="text-sm font-[500] break-words">
                    Nominal
                  </span>
                  <span className="text-[#728F9E] text-sm font-[500] text-right break-words">
                    {formatCurrency(data.price)}
                  </span>
                </div>
              </div>
              <div className="mt-2 text-[#FF9723] bg-[#FF9723] text-sm bg-opacity-10 p-2">
                *Saldo Anda berkurang sebesar {formatCurrency(data.price)}
              </div>
            </div>
          </div>
          <div className="p-4 fixed-bottom bg-white">
            <button
              className="font-[500] text-white rounded-[10px] bg-[#26A69A] w-full p-2"
              onClick={() => navigate(`/${company.initial}`)}
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailMobile;
