import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationHistoryWeb from "./donationHistoryWeb";
import DonationHistoryMobile from "./donationHistoryMobile";

const DonationHistory = () => {
  return (
    <>
      <BrowserView>
        <DonationHistoryWeb></DonationHistoryWeb>
      </BrowserView>
      <MobileView>
        <DonationHistoryMobile></DonationHistoryMobile>
      </MobileView>
    </>
  );
};

export default DonationHistory;
