import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationDisbursementWeb from "./donationDisbursementWeb";
import DonationDisbursementMobile from "./donationDisbursementMobile";

const DonationDisbursement = () => {
  return (
    <>
      <BrowserView>
        <DonationDisbursementWeb></DonationDisbursementWeb>
      </BrowserView>
      <MobileView>
        <DonationDisbursementMobile></DonationDisbursementMobile>
      </MobileView>
    </>
  );
};

export default DonationDisbursement;
