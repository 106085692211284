// import arrow_left from "../../assets/images/icon_transfer/arrow_left_icon.svg";
// import pattern from "../../assets/images/icon_balance/pattern.svg";
import arrow from "../../assets/images/arrow-left-ic.svg";
import { formatCurrency } from "../../config/global";
import { Swiper, SwiperSlide } from "swiper/react";
import LoadMoreButton from "../_common/loadMoreButton";
import { useNavigate } from "react-router-dom";
import NoImage from "../../assets/images/empty.jpg";
import moment from "moment";
import Header from "../_common/headerComponent";

const TripAndTourHistoryWeb = (props: any) => {
  const navigate = useNavigate();
  const imageOnError = (event: any) => {
    event.currentTarget.src = NoImage;
    event.currentTarget.className = `${event.currentTarget.className} error`;
  };

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile ">
      <Header />
      <div className="lg:w-[40%] md:w-[60%] mx-auto">
        <Swiper
          grabCursor={true}
          slidesPerView={3}
          spaceBetween={8}
          className="flex items-center bg-white pt-4 px-4"
        >
          {props?.statusMethod?.map((el: any, index: any) => {
            return (
              <SwiperSlide
                className={`${
                  props.status.value === el.value
                    ? " border-[#408D86] text-[#408D86] font-[600] "
                    : "text-[#263339]"
                }" text-sm border-b-[1px] pb-2 text-[#263339] cursor-pointer"`}
                onClick={() => props.filter(el)}
                key={el.value}
              >
                <div className="text-sm cursor-pointer">{el.label}</div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="md:mt-[64px] mt-[20px] pb-[10rem] ">
          <div className=" w-full">
            {props.data.map((item: any, index: any) => {
              return (
                <div
                  className="p-4 bg-white w-full mb-4 cursor-pointer"
                  key={index}
                >
                  <div className="flex justify-between text-xs pb-2 border-b-[1px] border-[#E5E5E5]">
                    <div>
                      <span className="block font-[500]">
                        Tanggal Pemesanan
                      </span>
                      <span className="block text-[#728F9E]">
                        {moment
                          .utc(item.createdDate)
                          .local()
                          .format("DD MMMM YYYY")}
                      </span>
                    </div>
                    <div>
                      <span className="block font-[500]">No Transaksi</span>
                      <span className="block text-[#728F9E]">
                        {item.transactionId}
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="text-xs flex justify-between items-center">
                      <span className="font-[500]">{item.merchant.name}</span>
                      {item.status === "WAITING_PAYMENT" && (
                        <div className="py-1 px-2 text-[#FF9723] rounded-lg bg-[#FF9723] bg-opacity-10 font-[500]">
                          Menunggu Pembayaran
                        </div>
                      )}
                      {item.status === "PAID" && (
                        <div className="py-1 px-2 text-[#26A69A] rounded-lg bg-[#26A69A] bg-opacity-10 font-[500]">
                          Selesai
                        </div>
                      )}
                      {item.status === "CANCELED" && (
                        <div className="py-1 px-2 text-[#DC3545] rounded-lg bg-[#DC3545] bg-opacity-10 font-[500]">
                          Dibatalkan
                        </div>
                      )}
                    </div>
                    <div className="mt-2 flex gap-2">
                      <img
                        src={
                          item.imageUrl[0] === "" || item.imageUrl === null
                            ? NoImage
                            : item.imageUrl[0]
                        }
                        alt=""
                        onError={imageOnError}
                        className="rounded-lg object-center  w-20 h-20"
                      />
                      <div className="text-sm w-full flex flex-col justify-between">
                        <span className="font-[500] flex-grow">
                          {item.tourProductName}
                        </span>
                        <div className="flex flex-col items-end">
                          <span className="text-[#728F9E] block font-[500]">
                            X{item.quantity}
                          </span>
                          <span className="text-[#728F9E]">
                            {formatCurrency(item.price)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="text-[#728F9E] text-xs py-2 mt-2 border-y-[1px] border-[#E5E5E5] flex justify-between">
                      <span
                        style={{ minWidth: "25%", display: "inline-block" }}
                      >
                        {item.quantity} Item/Tiket 
                      </span>
                      <div className="text-right">
                        Jumlah yang harus dibayar :{" "}
                        <span className="font-[700] text-[#26A69A]">
                          {formatCurrency(item.amountTotal)}
                        </span>
                      </div>
                    </div>
                    <div className="text-xs mt-2 flex justify-end items-center gap-2">
                      <button
                        className="text-white px-2 py-1 rounded-[6px]"
                        type="button"
                        onClick={() => navigate(`${item.id}`)}
                        style={{ backgroundColor: `${props?.color?.color1}` }}
                      >
                        Detail
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            {props.currentSize() >= 10 && props.totalElements > 10 ? (
              <LoadMoreButton addMore={props.addMore} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TripAndTourHistoryWeb;
