import moment from "moment";
import { useState, Fragment } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import fill from "../../assets/images/icon_features/filter.svg";
import { absenPlus } from "../../config/configIcon";
import { ConfigColor, hexToRgba } from "../../config/global";
import DataNotFound from "../_common/dataNotFound";
import Header from "../_common/headerComponent";
import LoadMoreButton from "../_common/loadMoreButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import ReactDatePicker from "react-datepicker";
import calendar from "../../assets/images/icon_features/calendarlogo.svg";
import { useTranslation } from "react-i18next";

const PayGateFeaturesWeb = (props: any) => {
  const color = ConfigColor();
  const [filter, setFilter] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>
      <div className="container h-full m-auto px-4 pb-4 md:pt-10">
        <div className="md:w-3/4 w-full mx-auto flex gap-3">
          <button
            onClick={() => {
              navigate("barcode");
              localStorage.removeItem("result")
            }}
            className=" text-[16px] font-semibold flex bg-opacity-10  p-2.5 rounded-lg w-5/6"
            style={{
              color: `${color.color1}`,
              backgroundColor: hexToRgba(color.color1, 0.1),
            }}
          >
            {absenPlus(color)}{" "}
            <span className="ml-2">{t('accessibility.access_paygate')}</span>
          </button>
          <button
            onClick={() => props.setFilter(true)}
            className="border-1 border-gray-300 flex bg-opacity-10 p-2.5 rounded-lg w-1/6"
          >
            <img src={fill} alt="" className="mr-2 mx-auto" />
          </button>
        </div>
        <div className="md:w-3/4 w-full mx-auto mt-4 bg-mobile">
          <h6 className="md:text-lg text-base text-newColor font-semibold mb-3">
            {t('accessibility.history')}
          </h6>
          {props.data?.length > 0 ? (
            props.data?.map((res: any) => {
              return (
                <div
                  key={res.id}
                  onClick={() => props.goToDetail(res)}
                  className="rounded-[10px] shadow-sm text-white bg-white flex flex-col relative gap-0.5 mb-4 cursor-pointer"
                >
                  <div className="absolute flex justify-between mt-5 w-full">
                    <div className="w-7 h-7 bg-mobile rounded-full -ml-4"></div>
                    <div className="w-7 h-7 bg-mobile rounded-full -mr-4"></div>
                  </div>
                  <div className="flex justify-between items-center px-3 pt-3 border-b-[1px] border-b-[#E5E5E5] border-dotted mb-2 pb-2">
                    <div>
                      <h6 className="md:text-sm text-xs m-0 text-[#728F9E]">
                        {t('accessibility.paygate_vehicle')}
                      </h6>
                      <span className="md:text-base text-base font-semibold text-[#26A69A]">
                        {res?.paygateActivity?.name}
                      </span>
                    </div>
                    <div className="text-start">
                      <h6 className="md:text-sm text-xs font-light text-newColor m-0">
                        Status
                      </h6>
                      <div className="flex flex-col">
                        {res.status === "PAID" ? (
                          <h6 className="md:text-lg text-base font-semibold m-0 text-[#26A69A]">
                            {t('accessibility.paid_off')}
                          </h6>
                        ) : (
                          <h6 className="md:text-lg text-base font-semibold m-0 text-[#DC3545]">
                            {t('accessibility.not_yet_paid')}
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-3 px-3 pb-3">
                    <div className="text-start">
                      <h6 className="md:text-sm text-xs font-light text-newColor m-0">
                        {t('accessibility.parking_device')}
                      </h6>
                      <span className="md:text-sm text-xs font-medium text-customBlack">
                        {res.deviceName.substring(0, 10) + "..."}
                      </span>
                    </div>
                    <div className="flex flex-col items-center">
                      <div>
                        <h6 className="md:text-sm text-xs font-light text-newColor m-0">
                          {t('accessibility.date')}
                        </h6>
                        <span className="md:text-sm text-xs font-medium text-customBlack">
                          {moment
                            .utc(res.loginTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col items-end">
                      <div>
                        <h6 className="md:text-sm text-xs font-light text-newColor m-0">
                          {t('accessibility.time')}
                        </h6>
                        <span
                          className="md:text-sm text-xs font-semibold text-customBlack"
                        // onClick={(e) => {
                        //   e.stopPropagation();
                        //   props.createQR();
                        // }}
                        >
                          {moment.utc(res.loginTime).local().format("HH:mm:ss")}
                          {/* {res.accessMethod === "CARD"
                        ? "Kartu"
                        : res.accessMethod === "QR_LISTEN"
                        ? "Buat Kode QR"
                        : "Pindai Kode QR"} */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <DataNotFound title={t('accessibility.noData')} />
          )}
          {props.totalElements > props.size * props.page && (
            <LoadMoreButton addMore={props.addMore} />
          )}
        </div>

        <Transition appear show={props.filter} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={props.closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-2xl rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium text-center leading-6 text-gray-900"
                    >
                      Filter
                    </Dialog.Title>
                    <div className="p-4 bg-white rounded-xl">
                      <div className="flex flex-row mb-4 gap-4">
                        <div className="flex w-1/2 flex-col relative">
                          <span>{t('accessibility.startDate')}</span>
                          <ReactDatePicker
                            selected={
                              props.newStartDate
                                ? new Date(props.newStartDate)
                                : null
                            }
                            onChange={(date: Date) => {
                              props.setNewStartDate(
                                moment(date).format("YYYY-MM-DD")
                              );
                            }}
                            selectsStart
                            startDate={
                              props.newStartDate
                                ? new Date(props.newStartDate)
                                : null
                            }
                            endDate={
                              props.newEndDate
                                ? new Date(props.newEndDate)
                                : null
                            }
                            dateFormat="dd-MM-yyyy"
                            maxDate={new Date()}
                            placeholderText={t('accessibility.selectDate')}
                            className="border-[1px] border-[#C4C4C4] text-left p-[13px] rounded-lg w-[100%] md:text-sm text-sm"
                          />
                          <img
                            src={calendar}
                            className="absolute right-3 mt-8"
                            alt=""
                          />
                        </div>
                        <div className="flex w-1/2 flex-col relative">
                          <span>{t('accessibility.endDate')}</span>
                          <ReactDatePicker
                            selected={
                              props.newEndDate
                                ? new Date(props.newEndDate)
                                : null
                            }
                            onChange={(date: Date) => {
                              props.setNewEndDate(
                                moment(date).format("YYYY-MM-DD")
                              );
                            }}
                            selectsStart
                            startDate={
                              props.newStartDate
                                ? new Date(props.newStartDate)
                                : null
                            }
                            endDate={
                              props.newEndDate
                                ? new Date(props.newEndDate)
                                : null
                            }
                            dateFormat="dd-MM-yyyy"
                            minDate={
                              props.newStartDate
                                ? new Date(props.newStartDate)
                                : new Date()
                            }
                            maxDate={new Date()}
                            placeholderText={t('accessibility.selectDate')}
                            className="border-[1px] border-[#C4C4C4] text-left p-[13px] rounded-lg w-[100%] md:text-sm text-sm"
                          />
                          <img
                            src={calendar}
                            className="absolute right-3 mt-8"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="flex w-full gap-4">
                        <button
                          onClick={() => {
                            props.resetFilter();
                            props.setPage(1);
                          }}
                          className="text-gray-500 border-1 border-gray-500 w-1/2 p-2 rounded-lg"
                        >
                          {t('accessibility.reset')}
                        </button>
                        <button
                          onClick={() => {
                            props.filterDate();
                            props.setPage(1);
                            props.setFilter(false);
                          }}
                          className="w-1/2 p-2 rounded-lg text-white"
                          style={{ backgroundColor: props.color.color1 }}
                        >
                          {t('accessibility.apply')}
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </section>
  );
};

export default PayGateFeaturesWeb;
