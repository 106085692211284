import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ERaportMobile from "./eRaportMobile";
import ERaportWeb from "./eRaportWeb";

const ERaport = () => {
  return (
    <>
      <BrowserView>
        <ERaportWeb></ERaportWeb>
      </BrowserView>
      <MobileView>
        <ERaportMobile></ERaportMobile>
      </MobileView>
    </>
  );
};

export default ERaport;
