import { Fragment, useEffect } from "react";
// import * as API from "../../core/service/api_notification";
// import { useAppDispatch } from "../../core/feature/hooks";
// import { useNavigate } from "react-router-dom";
import Wa from "../../assets/images/WhatsApp.svg";
import Google from "../../assets/images/google.svg";
import Sms from "../../assets/images/sms.svg";

import moment from "moment";
// import _, { get } from "lodash";
import "react-spring-bottom-sheet/dist/style.css";
// import { setLoading } from "../../core/feature/config/configSlice";
import { Dialog, Transition } from "@headlessui/react";
import { ConfigColor } from "../../config/global";

const DetailNotification = (props?: any) => {
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  let color = ConfigColor();
  // const [filter, setFilter] = useState(false);
  // const updateReadNotification = async (id: any) => {
  //   dispatch(setLoading(true));

  //   await API.updateRead(id)
  //     .then((res) => {
  //       dispatch(setLoading(false));
  //     })
  //     .catch((err) => {
  //       dispatch(setLoading(false));
  //     });
  // };

  useEffect(() => {}, []);
  return (
    <>
      <Transition appear show={props.visible} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => props.setVisible(!props.visible)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto text-xs">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full md:w-[30%] max-w-2xl rounded-2xl bg-white px-4 py-8 text-left align-middle shadow-xl transition-all ">
                  <Dialog.Title
                    as="h3"
                    className="text-base md:text-lg font-medium leading-6 text-gray-900"
                  >
                    <span>{props.data.title} </span>
                    <div className="flex justify-between items-center text-[0.625rem] md:text-xs text-[#728F9E]">
                      <div className="flex flex-wrap items-center mt-[-0.3rem] md:mt-0">
                        <span className="mr-1">
                          {moment
                            .utc(props.data.createdTime)
                            .local()
                            .format("DD MMMM YYYY ")}{" "}
                        </span>{" "}
                        <span className="mr-1 rounded-full w-1 h-1 bg-[#D9D9D9]" />
                        <span className="mr-1">
                          {moment
                            .utc(props.data.createdTime)
                            .local()
                            .format(" HH : mm : ss")}{" "}
                        </span>{" "}
                        <span className="mr-1 rounded-full w-1 h-1 bg-[#D9D9D9]" />
                        {props.data?.templateType === "Whatsapp" && (
                          <span className="flex space-x-2 mr-1">
                            <img
                              src={Wa}
                              alt=""
                              className="w-[10px] mr-1 mt-[-2px]"
                            />{" "}
                            {props.data?.templateType}
                          </span>
                        )}
                        {props.data?.templateType === "Email" && (
                          <span className="flex space-x-2 items-center mr-1">
                            <img
                              src={Sms}
                              alt=""
                              className="w-[10px] mr-1 mt-[-2px]"
                            />{" "}
                            {props.data?.templateType}
                          </span>
                        )}
                        {props.data?.templateType === "SMS" && (
                          <span className="flex space-x-2 items-center mr-1">
                            <img src={Sms} alt="" className="w-[10px] mr-1 " />{" "}
                            {props.data?.templateType}
                          </span>
                        )}
                        {props.data?.templateType === "FirebaseWeb" && (
                          <span className="flex space-x-2 mr-1">
                            <img
                              src={Google}
                              alt=""
                              className="w-[10px] mr-1 mt-[-2px]"
                            />{" "}
                            {"Web"}
                          </span>
                        )}
                        <span className="mr-1 rounded-full w-1 h-1 bg-[#D9D9D9]" />
                        <span>{props.data?.status}</span>{" "}
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className="text-[#263339] mb-8 text-justify text-sm">
                    {props.data?.description}
                  </div>
                  <div className="bg-white rounded-xl">
                    <div className="flex w-full gap-4">
                      <button
                        onClick={() => {
                          props.setVisible(!props.visible);
                        }}
                        className="w-full text-sm md:text-lg p-2 rounded-[8px] text-white mx-auto focus:outline-none"
                        style={{ backgroundColor: `${color.color1}` }}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DetailNotification;
