// import arrow_left from "../../assets/images/icon_transfer/arrow_left_icon.svg";
import { useNavigate } from "react-router-dom";
// import pattern from "../../assets/images/icon_balance/pattern.svg";
import arrow from "../../assets/images/arrow-left-ic.svg";
import Shop from "../../assets/images/icon_tripAndTour/shop.svg";
import { formatCurrency } from "../../config/global";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import NoImage from "../../assets/images/empty.jpg";
import { useEffect, useState } from "react";
import moment from "moment";

const TripAndTourHistoryDetailMobile = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const [image, setImage] = useState("");

  const imageOnError = (event: any) => {
    event.currentTarget.src = NoImage;
    event.currentTarget.className = `${event.currentTarget.className} error`;
  };
  useEffect(() => {
    setTimeout(() => {
      setImage(props?.data?.imageUrl[0]);
    }, 100);
  }, [props]);
  return (
    <section className="h-full min-h-screen font-montserrat bg-white flex flex-col justify-between">
      <div className="flex-grow">
        <section
          id="header-app"
          className="flex flex-row items-center z-50 px-4 py-3"
        >
          <div
            onClick={async () => {
              navigate(-1);
            }}
            className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
          >
            <img
              src={arrow}
              style={{ width: 6, height: 12 }}
              className="m-auto"
              alt=""
            />
          </div>

          <h1 className={`font-montserrat font-bold text-base m-auto relative`}>
            Detail Riwayat
          </h1>
        </section>
        <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[20px] px-4">
          <div className="md:w-3/5 w-full mx-auto">
            <div className="mt-4 ">
              <div className="flex justify-between items-center">
                <span className="font-[600] text-[#263339] text-base">
                  Informasi
                </span>
                {props.data.status === "PAID" && (
                  <div className="bg-[#26A69A] rounded-xl py-1 px-2 font-[600] text-white">
                    Berhasil
                  </div>
                )}
                {props.data.status === "WAITING_PAYMENT" && (
                  <div className="py-1 px-2 text-[#FF9723] rounded-lg bg-[#FF9723] bg-opacity-10 font-[500]">
                    Menunggu Pembayaran
                  </div>
                )}
                {props.data.status === "CANCELED" && (
                  <div className="py-1 px-2 text-[#DC3545] rounded-lg bg-[#DC3545] bg-opacity-10 font-[500]">
                    Dibatalkan
                  </div>
                )}
              </div>
              <div className="mt-2 bg-[#F5F5F5] p-3 space-y-4">
                <div className="flex justify-between text-base ">
                  <span className="text-[#263339] font-[400]">
                    Tgl. Pemesanan
                  </span>
                  <span className="text-[#263339] font-[500]">
                    {moment
                      .utc(props.data.createdDate)
                      .local()
                      .format("DD MMMM YYYY")}
                  </span>
                </div>
                <div className="grid grid-cols-2 text-base ">
                  <span className="text-[#263339] font-[400]">
                    No.Transaksi
                  </span>
                  <span className="text-[#263339] font-[500] break-words text-right">
                    {props.data.transactionId}
                  </span>
                </div>
                <div className="grid grid-cols-2 text-base ">
                  <span className="text-[#263339] font-[400]">Nama</span>
                  <span className="text-[#263339] font-[500] text-right break-words">
                    {props.data.tourProductName}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex gap-2 mt-4">
              <img src={Shop} alt="" />
              <span className="font-[600] text-[#263339] text-base">
                {props?.data?.merchant?.name}
              </span>
            </div>
            <div className="mt-2 bg-[#F5F5F5] p-3 flex gap-2">
              <img
                src={image === "" || image === null ? NoImage : image}
                onError={imageOnError}
                alt=""
                className="bg-cover h-16 w-16 rounded-lg"
              />
              <div className="flex flex-col justify-between w-full">
                <span className="text-xs text-[#263339] font-[400] flex-grow">
                  {props.data.tourProductName}
                </span>
                <div className="flex justify-between">
                  <span className="text-xs text-[#728F9E] font-[500]">
                    {props.data.quantity} x {formatCurrency(props.data.price)}
                  </span>
                  <span className="text-xs font-[600]">
                    {formatCurrency(props.data.amountTotal)}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <span className="font-[600] text-[#263339] text-base">
                Detail Pembayaran
              </span>
              <div className="mt-2 bg-[#F5F5F5] p-3 space-y-4">
                <div className="flex justify-between text-base ">
                  <span className="text-[#263339] font-[400]">
                    Subtotal Pesanan
                  </span>
                  <span className="text-[#263339] font-[500]">
                    {formatCurrency(props.data.amountTotal)}
                  </span>
                </div>
                {/* <div className="flex justify-between text-base ">
                <span className="text-[#263339] font-[400]">Biaya Layanan</span>
                <span className="text-[#263339] font-[500]">100000</span>
              </div> */}
                <div className="flex justify-between text-base ">
                  <span className="text-[#263339] font-[700]">
                    Total Pesanan ( {props.data.quantity} Pesanan ){" "}
                  </span>
                  <span className="text-[#263339] font-[700]">
                    {formatCurrency(props.data.price)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4">
        {props.data.status === "PAID" ? (
          <div className="mt-4">
            <div className="text-center">
              <img src={props.barcode} alt="" className="mx-auto w-36" />
              <span className="text-sm text-[#263339]">
                Tunjukkan barcode ke petugas <br />
                untuk menjadi bukti transaksi dan <br /> berfungsi sebagai akses
                wisata
              </span>
            </div>
            <button
              className="text-base text-white text-center bg-[#26A69A] w-full p-2 font-[700] rounded-xl mt-4"
              type="button"
              onClick={() =>
                navigate(`/${company.initial}/trip-tour/history/barcode`, {
                  state: props,
                })
              }
            >
              Show Barcode
            </button>
          </div>
        ) : props.data.status === "WAITING_PAYMENT" ? (
          <div className="mt-4">
            <button
              className="text-base text-white text-center bg-[#26A69A] w-full p-2 font-[700] rounded-xl mt-4"
              type="button"
              onClick={props.checkouth}
            >
              Bayar Sekarang
            </button>
            <button
              className="text-base text-gray-500 text-center border border-slate-500 bg-white w-full p-2 font-[700] rounded-xl mt-4"
              type="button"
              onClick={props.cancelOrder}
            >
              Batalkan Pesanan
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default TripAndTourHistoryDetailMobile;
