import React from "react";
import Icon from "../../assets/images/info-circle.png";

export default function Information(props: any) {
  return (
    <div className="p-2 flex gap-2 bg-[#FAE7A3] right-0 left-0">
      <img src={Icon} alt="" className="w-6 h-6" />
      <span>{props.message}</span>
    </div>
  );
}
