import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ForgetSecurityCodeWeb from "./forgetSecurityCodeWeb";
import ForgetSecurityCodeMobile from "./forgetSecurityCodeMobile";

const ForgetSecurityCode = () => {
  return (
    <>
      <BrowserView>
        <ForgetSecurityCodeWeb></ForgetSecurityCodeWeb>
      </BrowserView>
      <MobileView>
        <ForgetSecurityCodeMobile></ForgetSecurityCodeMobile>
      </MobileView>
    </>
  );
};

export default ForgetSecurityCode;
