/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { onlyNumber } from '../../../config/global';
import { setLoading } from '../../../core/feature/config/configSlice';
import { useAppDispatch, useAppSelector } from '../../../core/feature/hooks';
import Company from '../../../core/models/company';
import * as API_BALANCE from "../../../core/service/api_balance";
import Header from '../../_common/headerComponent';
import LinkedAccountCard from '../../_common/linkedAccountCard';

const InputPinMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [data, setData] = useState<any>({});
  const [pin, setPin] = useState("");
  const [errorPin, setErrorPin] = useState("");

  const handleOnClick = async() => {
    if(pin === ""){
      setErrorPin("Masukkan PIN");
    } else {
      dispatch(setLoading(true));
      let data = {
        pin
      }
      await API_BALANCE.connectLinkAjaAccount(data)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`/${company?.initial}/balance/connect-account/success`, {
            state: {
              data
            },
            replace: true
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        })
    }
  }

  useEffect(() => {
    setData(location.state);
  }, [])

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Hubungkan Akun" />
      <div className="md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto mb-10">
          <LinkedAccountCard data={data} />
          <div className="mt-12 w-full mx-auto">
            <div className="text-center mb-3">
              <h6 className="md:text-lg text-base text-customBlack font-semibold mb-0">
                Bayar Makin Gampang
              </h6>
              <p className="md:text-base text-sm text-customBlack font-light mb-0">
                Nikmati kemudahan bertransaksi melaui Closepay.
              </p>
            </div>
            <div className="text-center">
              <label className="md:text-lg text-base text-customBlack font-medium mb-2">
                Masukkan PIN
              </label>
              <input
                value={pin}
                type="password"
                onChange={(e) => {
                  setPin(e.target.value);
                  setErrorPin("");
                }}
                onKeyPress={onlyNumber}
                className="w-full bg-white border-[#D9D9D9] rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-[#D9D9D9] shadow-sm"
                maxLength={6}
              />
              <p className="md:text-base text-sm text-red pl-1 mb-0">
                {errorPin}
              </p>
            </div>
          </div>
          <div className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]" style={{transform: "translate(-50%, -50%)"}}>
            <button
              type="button"
              onClick={() => handleOnClick()}
              style={{ backgroundColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              Selanjutnya
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InputPinMobile;