import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import User from "../../core/models/user";
import Header from "../_common/headerComponent";
import * as API_AUTH from "../../core/service/api_auth";
import { setLoading } from "../../core/feature/config/configSlice";
import * as API_USER from "../../core/service/api_user";
import * as API from "../../core/service/api";
import { setUser } from "../../core/feature/user/userSlice";
import { setToken } from "../../core/feature/token/tokenSlice";
import { setCompany } from "../../core/feature/company/companySlice";
import Token from "../../core/models/token";
import { findIndex } from "lodash";
import { ConfigColor } from "../../config/global";
import fingerPrint from "@fingerprintjs/fingerprintjs";
import Countdown, { zeroPad } from "react-countdown";
import { setShowDestinationOtp } from "../../core/feature/otp/destinationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const ChangePasswordMobile = () => {
  const { t } = useTranslation();
  const token: Token[] = useAppSelector((state) => state.token.token);
  const user: User = useAppSelector((state) => state.user.user);
  const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const color = ConfigColor();
  const [deviceId, setDeviceId] = useState("");
  const [modalVerificationWa, setModalVerificationWa] = useState(false);
  const [state, setState] = useState("");
  const [tokenWa, setTokenWa] = useState("");
  const [timer, setTimer] = useState(0);
  const [alert, setAlert] = useState(false);

  const handleOnClick = async () => {
    dispatch(setLoading(true));

    let data = {
      destinationOtp: user?.email,
      companyId: company?.id,
      userType: "MEMBER",
      name: user?.name,
      otpType: "FORGOT_SECURITY_CODE",
    };

    await API_AUTH.requestOtp(data)
      .then((res) => {
        dispatch(setLoading(false));
        if (link && companyInitial && tokenCo) {
          navigate(
            `/${company?.initial}/profile/security-code/forget/change?link=${link}`,
            {
              replace: true,
              state: {
                time: res.data.data.expiredMillisecond,
              },
            }
          );
        } else {
          navigate(`/${company?.initial}/profile/security-code/forget/change`, {
            replace: true,
            state: {
              time: res.data.data.expiredMillisecond,
            },
          });
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let link = query.get(`link`);
  let companyInitial = query.get("companyinitial");
  let tokenCo = query.get("token");

  const initialCompany = async () => {
    dispatch(setLoading(true));
    await API_USER.getCompany(companyInitial)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setCompany(res.data.data));
        cekToken();
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const requestOtp = async () => {
    setAlert(true);
    // dispatch(setLoading(true));
    // let data = {
    //   phoneNumber: user?.phone,
    //   companyId: company?.id,
    //   userId: user?.id,
    //   userType: "MEMBER",
    //   transactionType: "FORGOT_SECURITY_CODE",
    //   deviceId: deviceId,
    // };
    // await API_AUTH.requestTokenVerivicationWa(data)
    //   .then((res) => {
    //     window.open(
    //       `https://wa.me/${
    //         res.data.data.destinationPhoneNumber
    //       }?text=${encodeURI(
    //         `Halo ini adalah kode verifikasi saya "${res.data.data.token}" tolong verifikasi ya.`
    //       )}`,
    //       `_blank`
    //     );
    //     setTokenWa(res.data.data.token);
    //     setState(res.data.data.state);
    //     setTimer(Date.now() + res.data.data.expiredMillisecond);
    //     setModalVerificationWa(true);
    //     dispatch(setLoading(false));
    //   })
    //   .catch((err) => {
    //     dispatch(setLoading(false));
    //   });
  };

  const checkTokenWa = async () => {
    dispatch(setLoading(true));
    let newData = {
      state: state,
      phoneNumber: user?.phone,
      companyId: company?.id,
      transactionType: "FORGOT_SECURITY_CODE",
      deviceId: deviceId,
      token: tokenWa,
    };
    dispatch(setLoading(true));
    await API_AUTH.checkTokenVerification(newData)
      .then((res) => {
        dispatch(setLoading(false));
        console.log(res.data.data);
        if (link && companyInitial && tokenCo) {
          navigate(
            `/${company?.initial}/profile/security-code/forget/change?link=${link}`,
            {
              replace: true,
              state: {
                time: res.data.data.expiredMillisecond,
              },
            }
          );
        } else {
          navigate(`/${company?.initial}/profile/security-code/forget/change`, {
            replace: true,
            state: {
              time: res.data.data.expiredMillisecond,
              otp: res.data.data.otp,
            },
          });
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const cekToken = async () => {
    dispatch(setLoading(true));
    await API.cekToken()
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setUser(res.data.data));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <button
          className="text-[#26A69A] my-3"
          type="button"
          onClick={() => requestOtp()}
        >
          {t('verification.resend_otp')}
        </button>
      );
    } else {
      return (
        <span className="text-[14px] text-center my-4">
          <span>{t('verification.resend_otp_in')}</span>
          <span className="text-[#FF9723] font-semibold ml-2">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };

  React.useEffect(() => {
    if (link && companyInitial && tokenCo) {
      initialCompany();
      var newToken = token ? [...token] : [];
      let indexToken = findIndex(token, { cid: companyInitial });
      if (indexToken < 0) {
        newToken.push({
          cid: companyInitial,
          token: tokenCo,
        });
      } else {
        newToken[indexToken] = {
          cid: companyInitial,
          token: tokenCo,
        };
      }
      dispatch(setToken(newToken));
    }
    const fpPromise = fingerPrint.load();

    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        const visitorId = result.visitorId;
        setDeviceId(visitorId);
        // dispatch(setOtpDeviceId(visitorId));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="h-full flex flex-col justify-between min-h-screen font-montserrat bg-mobile">
      <div
        className={`${modalVerificationWa ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl px-4 py-5">
              <span className="text-[20px] font-semibold mb-2">
                {t('verification.verify_activity')}
              </span>
              <button
                type="button"
                onClick={() => {
                  setModalVerificationWa(false);
                  window.location.reload();
                }}
                className="fa-xl text-gray-400 absolute right-10 top-5"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <div className="text-left mt-4">
                <span>{t('verification.verify_via_number')}</span>
                <input
                  disabled
                  type="number"
                  value={user?.phone}
                  className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                ></input>
                <p className="mt-5 text-center">
                  {t('verification.verified_message')}
                </p>
              </div>
              <Countdown key={timer} date={timer} renderer={renderer} />
              <button
                onClick={() => {
                  checkTokenWa();
                }}
                className="bg-green text-white py-2 mt-5 rounded-lg w-full"
              >
                {t('verification.i_have_verified')}
              </button>
              {/* <span
                  className="mt-4 text-xs cursor-pointer"
                  onClick={() => {
                    setShowDataVerification(false);
                    navigate(`/${company.initial}`);
                  }}
                >
                  {t('verification.skip_step')}
                </span> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Header navbarText="Lupa Kode Keamanan"></Header>
        <div className="container md:mt-[64px] mt-[0px] mb-4 px-4">
          {/* <div className="md:w-1/2 w-full"> */}
          <div className="w-100 text-center mb-3">
            <h1 className="font-bold md:text-3xl text-xl">{t('verification.title')}</h1>
            <h5 className="font-normal md:text-base text-sm text-black">
              {t('verification.subtitle')}
            </h5>
          </div>
          {user?.emailVerified !== false && (
            <div className="w-full flex-grow text-left my-4">
              <h1 className="md:text-base text-sm text-black leading-5 font-medium">
                {t('verification.email_label')}
              </h1>
              <input
                defaultValue={user?.email}
                readOnly
                disabled
                className="bg-[#fff] text-thirdy text-center w-full p-2.5 md:text-base text-sm rounded-lg  border-1 border-solid border-neutral-200"
              />
            </div>
          )}
          {user?.phoneVerified !== "unverified" && (
            <div className="w-full text-left my-4">
              <h1 className="md:text-base text-sm text-black leading-5 font-medium">
                {t('verification.phone_label')}
              </h1>
              <input
                defaultValue={user?.phone}
                readOnly
                disabled
                className="bg-[#fff] text-thirdy text-center w-full p-2.5 md:text-base text-sm rounded-lg  border-1 border-solid border-neutral-200"
              />
            </div>
          )}
          {user?.phoneVerified === "unverified" &&
            user?.emailVerified === false && (
              <div className="text-center mt-10">
                <p>
                  {t('verification.unverified_message')}
                </p>
                <button
                  style={{
                    backgroundColor: `${color?.color1}`,
                  }}
                  onClick={() => dispatch(setShowDestinationOtp(true))}
                  className="w-full md:p-[1rem] p-[12px] rounded-lg text-white font-semibold md:text-base text-sm "
                >
                  {t('verification.verify_now')}
                </button>
              </div>
            )}
          {alert && (
            <div>
              <p className="text-center text-[12px] mt-4">
                {t('verification.whatsapp_alert')} <br />
                <a
                  className="underline text-blue-400"
                  href="https://wa.me/+6289526643223"
                  target="_blank"
                >
                  {t('verification.cs_link_text')}
                </a>
              </p>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
      <div className="w-full p-4">
        <button
          type="button"
          style={{
            backgroundColor: `${color?.color1}`,
            display:
              user?.phoneVerified === "unverified" &&
                user?.emailVerified === false
                ? "none"
                : "block",
          }}
          onClick={() => {
            if (user?.phoneVerified !== "unverified") {
              requestOtp();
            } else {
              handleOnClick();
            }
          }}
          className="w-full md:p-[1rem] p-[12px] rounded-lg text-white font-semibold md:text-base text-sm "
        >
          {t('verification.confirm')}
        </button>
      </div>
    </section>
  );
};

export default ChangePasswordMobile;
