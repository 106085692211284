import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBInternetTVSummaryWeb from "./ppobInternetTVSummaryWeb";
import PPOBInternetTVSummaryMobile from "./ppobInternetTVSummaryMobile";

const PPOBInternetTVSummary = () => {
  return (
    <>
      <BrowserView>
        <PPOBInternetTVSummaryWeb></PPOBInternetTVSummaryWeb>
      </BrowserView>
      <MobileView>
        <PPOBInternetTVSummaryMobile></PPOBInternetTVSummaryMobile>
      </MobileView>
    </>
  );
};

export default PPOBInternetTVSummary;
