/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import qrcode_icon from "../../assets/images/barcode-icon.svg";
import * as API from "../../core/service/api_notification";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
import { find } from "lodash";
import User from "../../core/models/user";
import Token from "../../core/models/token";
import {
  configHome,
  configMessage,
  configNews,
  configProfile,
} from "../../config/configIcon";
import { isIOS, isIOS13 } from "react-device-detect";
import {
  loadState,
  saveState,
} from "../../core/feature/browser-storage/browser-storage";
import { useTranslation } from "react-i18next";

const BottomNavigator = (props?: any) => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const [active] = useState(props?.title);
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const [isLogin, setIsLogin] = useState(false);
  // const navigate = useNavigate();
  const [configColor, setConfigColor] = useState<any>({});
  const notif = loadState("notif_statistic");

  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#015f48";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const getNotifStatistic = () => {
    API.getNotificationStatistic()
      .then((res) => {
        saveState("notif_statistic", res.data.data);
      })
      .catch((er) => { });
  };

  let textColor = `text-[${configColor.color1}]`;

  React.useEffect(() => {
    setIsLogin(user && (newToken as any));
    if (isLogin) getNotifStatistic();
    getConfigColor();
  }, [isLogin]);

  return (
    <div
      className={`flex gap-0 fixed bottom-0 bg-white px-2 ${isIOS || isIOS13 ? "py-4" : "py-2.5"
        } w-full z-30`}
    >
      {/* {isIOS || isIOS13 ? "ios" : "android"} */}
      <Link
        to={`/${company.initial}`}
        className="grow flex flex-col items-center no-underline"
      >
        {configHome(active, configColor)}
        <h1
          className="m-0 text-[11px] font-normal"
          style={{
            textDecoration: "none",
            color: active === "home" ? `${configColor.color1}` : "#CCCCCC",
          }}
        >
          {t('navbar.home')}
        </h1>
      </Link>
      <Link
        to={`/${company.initial}/news-information`}
        className="grow flex flex-col items-center no-underline"
      >
        {configNews(active, configColor)}
        <h1
          className={`${active === "news" ? `${textColor} block` : "text-gray-300 block"
            } m-0 text-[11px] font-normal"`}
          style={{
            textDecoration: "none",
            color: active === "news" ? `${configColor.color1}` : "#CCCCCC",
          }}
        >
          {t('navbar.news')}
        </h1>
      </Link>
      <Link
        to={
          isLogin ? `/${company.initial}/barcode` : `/${company.initial}/login`
        }
        className="grow w-16 relative"
      >
        <div
          style={{ backgroundColor: configColor?.color1 }}
          className="absolute  flex p-2 m-auto left-0 right-0 -top-10 w-16 rounded-full h-16 shadow-lg items-center justify-center"
        >
          <img src={qrcode_icon} alt="" />
        </div>
      </Link>
      <Link
        to={
          isLogin
            ? `/${company.initial}/notification`
            : `/${company.initial}/login`
        }
        className="relative grow flex flex-col items-center no-underline"
      >
        {isLogin && notif?.unreadMessage > 0 && (
          <span className="absolute ml-5 mt-[-0.4rem] text-[0.375rem] w-[1rem] h-[1rem] font-normal p-1 flex items-center justify-center rounded-xl bg-red text-white">
            {notif?.unreadMessage > 99 ? "99+" : notif?.unreadMessage}
          </span>
        )}

        {configMessage(active, configColor)}
        <h1
          className={`${active === "notifikasi"
            ? `${textColor} block`
            : "text-gray-300 block"
            } m-0 text-[11px] font-normal`}
          style={{
            textDecoration: "none",
            color:
              active === "notifikasi" ? `${configColor.color1}` : "#CCCCCC",
          }}
        >
          {t('navbar.notification')}
        </h1>
      </Link>
      <Link
        to={
          isLogin ? `/${company.initial}/profile` : `/${company.initial}/login`
        }
        className="grow flex flex-col items-center no-underline"
      >
        {configProfile(active, configColor)}
        <h1
          className={`${active === "profile" ? `${textColor} block` : "text-gray-300 block"
            } m-0 text-[11px] font-normal`}
          style={{
            textDecoration: "none",
            color: active === "profile" ? `${configColor.color1}` : "#CCCCCC",
          }}
        >
          {t('navbar.profile')}
        </h1>
      </Link>
    </div>
  );
};

export default BottomNavigator;
