// import React, { Component, useEffect, useState } from "react";
import Header from "../_common/headerComponent";

const ProfileWeb = () => {
  // const dataPassword = {
  //   passwordLama:"12345678",
  //   passwordBaru:"12345678",
  //   konfirmasiPasswordBaru:"12345678",
  // }
  return (
    <>
      <Header></Header>
      <section className="w-full h-auto">
        <div className="m-auto w-10/12 pt-8 pb-3 text-center">
          <div className="m-auto w-7/12 text-left">
            <div className="w-5/6 text-left mb-3">
              <h1 className=" font-poppins font-bold text-3xl">
                Automatic Login
              </h1>
              <h5 className=" font-poppins font-normal text-sm">
                Pilihlah keadaan login yang anda inginkan ketika menggunakan
                aplikasi
              </h5>
            </div>

            <div>
              <div className="form-check my-3  pl-3 py-2  h-12 bg-white rounded-lg">
                <input
                  className="w-6 h-6 mr-6 rounded-full border border-gray-300 mt-1 align-top bg-no-repeat bg-center"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked
                />
                <label className="form-check-label inline-block text-base text-gray-800 mt-1">
                  Aktif
                </label>
              </div>
              <div className="form-check my-3 pl-3 py-2 h-12 bg-white rounded-lg">
                <input
                  className="w-6 h-6 mr-6 rounded-full border border-gray-300 mt-1 align-top bg-no-repeat bg-center"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label className="form-check-label inline-block  text-base text-gray-800 mt-1">
                  Tidak Aktif
                </label>
              </div>
            </div>

            <div className="w-5/6 text-left mt-5 mb-3">
              <h1 className=" font-poppins font-bold text-3xl">Ganti Bahasa</h1>
              <h5 className=" font-poppins font-normal text-sm">
                Pilihlah keadaan login yang anda inginkan ketika menggunakan
                aplikasi
              </h5>
            </div>

            <div>
              <div className="form-check my-3  pl-3 py-2  h-12 bg-white rounded-lg">
                <input
                  className="w-6 h-6 mr-6 rounded-full border border-gray-300 mt-1 align-top bg-no-repeat bg-center"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked
                />
                <label className="form-check-label inline-block text-base text-gray-800 mt-1">
                  Inggris
                </label>
              </div>
              <div className="form-check my-3 pl-3 py-2 h-12 bg-white rounded-lg">
                <input
                  className="w-6 h-6 mr-6 rounded-full border border-gray-300 mt-1 align-top bg-no-repeat bg-center"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label className="form-check-label inline-block  text-base text-gray-800 mt-1">
                  Indonesia
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileWeb;
