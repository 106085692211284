import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import BankDKIRegistrationWeb from "./bankDKIRegistrationWeb";
import BankDKIRegistrationMobile from "./bankDKIRegistrationMobile";

const BankDKIRegistration = () => {
  return (
    <>
      <BrowserView>
        <BankDKIRegistrationWeb></BankDKIRegistrationWeb>
      </BrowserView>
      <MobileView>
        <BankDKIRegistrationMobile></BankDKIRegistrationMobile>
      </MobileView>
    </>
  );
};

export default BankDKIRegistration;
