import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ActivateCardSecurityCodeWeb from "./activateCardSecurityCodeWeb";
import ActivateCardSecurityCodeMobile from "./activateCardSecurityCodeMobile";
import { useNavigate, useParams } from "react-router-dom";
import { setLoading } from "../../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
import * as API_VIRTUAL_CARD from "../../../../core/service/api_virtualCard";
import Company from "../../../../core/models/company";
import { useTranslation } from "react-i18next";

const ActivateCardSecurityCode = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [securityCode, setSecurityCode] = React.useState("");
  const [confirmSecurityCode, setConfirmSecurityCode] = React.useState("");
  const [errorSecurityCode, setErrorSecurityCode] = React.useState("");
  const [errorConfirmSecurityCode, setErrorConfirmSecurityCode] = React.useState("");
  const [createSuccessFully, setCreateSuccessFully] = React.useState(false);

  const handleOnClick = async () => {
    if (createSuccessFully) {
      navigate(`/${company?.initial}/virtual-card/list`, { replace: true })
    } else {
      if (securityCode === "") {
        setErrorSecurityCode(t('card_security_code.required_pin'));
      } else if (confirmSecurityCode === "") {
        setErrorConfirmSecurityCode(t('card_security_code.confirm_pin'));
      } else if (confirmSecurityCode !== securityCode) {
        setErrorConfirmSecurityCode(t('card_security_code.pin_not_same'));
      } else {
        dispatch(setLoading(true));
        let data = {
          newSecurityCode: securityCode,
          confirmSecurityCode,
        };
        API_VIRTUAL_CARD.cardSecurityCode(params.id, data, "")
          .then((res) => {
            dispatch(setLoading(false));
            setCreateSuccessFully(true);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    }
  };

  return (
    <>
      <BrowserView>
        <ActivateCardSecurityCodeWeb
          handleOnClick={() => handleOnClick()}
          setSecurityCode={(value: any) => setSecurityCode(value)}
          setConfirmSecurityCode={(value: any) => setConfirmSecurityCode(value)}
          setErrorSecurityCode={(value: any) => setErrorSecurityCode(value)}
          setErrorConfirmSecurityCode={(value: any) => setErrorConfirmSecurityCode(value)}
          company={company}
          securityCode={securityCode}
          confirmSecurityCode={confirmSecurityCode}
          errorSecurityCode={errorSecurityCode}
          errorConfirmSecurityCode={errorConfirmSecurityCode}
          createSuccessFully={createSuccessFully}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <ActivateCardSecurityCodeMobile
          handleOnClick={() => handleOnClick()}
          setSecurityCode={(value: any) => setSecurityCode(value)}
          setConfirmSecurityCode={(value: any) => setConfirmSecurityCode(value)}
          setErrorSecurityCode={(value: any) => setErrorSecurityCode(value)}
          setErrorConfirmSecurityCode={(value: any) => setErrorConfirmSecurityCode(value)}
          company={company}
          securityCode={securityCode}
          confirmSecurityCode={confirmSecurityCode}
          errorSecurityCode={errorSecurityCode}
          errorConfirmSecurityCode={errorConfirmSecurityCode}
          createSuccessFully={createSuccessFully}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default ActivateCardSecurityCode;
