import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CreateBarcodeWeb from "./createBarcodeWeb";
import CreateBarcodeMobile from "./createBarcodeMobile";

const CreateBarcode = () => {
  return (
    <>
      <BrowserView>
        <CreateBarcodeWeb></CreateBarcodeWeb>
      </BrowserView>
      <MobileView>
        <CreateBarcodeMobile></CreateBarcodeMobile>
      </MobileView>
    </>
  );
};

export default CreateBarcode;
