import { BrowserView, MobileView } from "react-device-detect";
import CardSecurityCodeWeb from "./cardSecurityCodeWeb";
import CardSecurityCodeMobile from "./cardSecurityCodeMobile";
import unlock from "../../../../assets/images/unlockv2.svg"
import lock from "../../../../assets/images/lock.svg"
import forget from "../../../../assets/images/forget_pin_icon.svg"
import { useTranslation } from "react-i18next";


const CardSecurityCode = () => {
  const { t } = useTranslation();
  const data = [
    {
      id: "1",
      title: t('card_security_code.nonaktif_pin'),
      icon: lock,
      url: "inactivate",
    },
    {
      id: "2",
      title: t('card_security_code.change_pin'),
      icon: unlock,
      url: "change",
    },
    {
      id: "3",
      title: t('card_security_code.forgot_pin'),
      icon: forget,
      url: "verification",
    },
  ];

  return (
    <>
      <BrowserView>
        <CardSecurityCodeWeb
          data={data}
        />
      </BrowserView>
      <MobileView>
        <CardSecurityCodeMobile
          data={data}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default CardSecurityCode;
