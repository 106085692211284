import { checkErrorResponse } from "../../config/global";

const erormessage = sessionStorage.getItem("erorMessage");

export const ValidateSuccess = (respon) => {
  if (respon.type === "SUCCESS_LOGOUT") {
    return "Logout Berhasil";
  } else if (respon.type === "SUCCESS_LOGIN") {
    return "Login Berhasil";
  } else if (respon.type === "SUCCESS_REQUEST_OTP") {
    return "Permintaan OTP Berhasil";
  } else if (respon.type === "SUCCESS_VALIDATE_OTP") {
    return "Validasi OTP Berhasil";
  } else if (respon.type === "SUCCESS_VALIDATE_SECURITY_CODE") {
    return "Validasi Kode Keamanan Berhasil";
  } else if (respon.type === "SUCCESS_VALIDATE_SECURITY_CODE_TOKEN") {
    return "Validasi Kode Keamanan Token Berhasil";
  } else if (respon.type === "SUCCESS_GET_OTP_CONFIG") {
    return "Konfigurasi OTP Berhasil Didapatkan";
  } else if (respon.type === "SUCCESS_UPDATE_OTP_CONFIG") {
    return "Konfigurasi OTP Berhasil Dirubah";
  } else if (respon.type === "SUCCESS_CREATED") {
    return "Berhasil Dibuat";
  } else if (respon.type === "SUCCESS_UPDATED") {
    return "Berhasil Dirubah";
  } else if (respon.type === "SUCCESS_DELETED") {
    return "Berhasil Dihapus";
  } else if (respon.type === "OK") {
    return "Berhasil";
  } else if (respon.type === "") {
    return "Berhasil";
  } else {
    return respon.type;
  }
};

export const ValidateError = (respon, config) => {
  if (respon?.detail?.status_code) {
    switch (parseInt(respon.detail.status_code)) {
      case 400:
        if (respon.detail.type === "INVALID_COMPANY") {
          return "Company Tidak Sah";
        } else if (respon.detail.type === "USER_NOT_FOUND") {
          return "Username/Email Tidak Ditemukan";
        } else if (respon.detail.type === "USER_INACTIVE") {
          return "Akun Terblokir, Silahkan Hubungi Admin";
        } else if (respon.detail.type === "WRONG_PASSWORD_COUNT_EXCEEDED") {
          return "Percobaan Melebihi Batas, Silahkan Reset Password Anda";
        } else if (respon.detail.type === "AUTHENTICATION_FAILED") {
          return "Otentikasi Gagal";
        } else if (respon.detail.type === "OTP_NOT_FOUND") {
          return "OTP Tidak Ditemukan";
        } else if (respon.detail.type === "SECURITY_CODE_NOT_MATCH") {
          return "PIN Salah";
        } else if (
          respon.detail.type === "WRONG_SECURITY_CODE_COUNT_EXCEEDED"
        ) {
          return "Pin Terblokir . Silakan Klik Lupa Pin";
        } else if (respon.detail.type === "USER_DO_NOT_HAVE_SECURITY_CODE") {
          return "Pengguna Tidak Memiliki Kode Keamanan";
        } else if (respon.detail.type === "SECURITY_CODE_TOKEN_NOT_FOUND") {
          return "Token Kode Keamanan Tidak Ditemukan";
        } else if (respon.detail.type === "INVALID_DATE_FORMAT") {
          return "Format Tanggal Tidak Valid";
        } else if (respon.detail.type === "INVALID_PHONE_NUMBER") {
          return "Nomor Telepon Tidak Valid";
        } else if (respon.detail.type === "INVALID_EMAIL") {
          return "Email Tidak Valid";
        } else if (respon.detail.type === "INVALID_OBJECT_ID") {
          return "ID Obyek Tidak Valid";
        } else if (respon.detail.type === "INVALID_PASSWORD_LENGTH") {
          return "Panjang Password Tidak Valid";
        } else if (respon.detail.type === "FAILED_TO_REQUEST") {
          return "Gagal Meminta";
        } else if (respon.detail.type === "COMPANY_INITIAL_OR_NAME_EXIST") {
          return "Inisial atau Nama Perusahaan Ada";
        } else if (respon.detail.type === "TOKEN_EXPIRED") {
          return "Token Kadaluwarsa";
        } else if (respon.detail.type === "TOKEN_ALREADY_USED") {
          return "Token Sudah Digunakan";
        } else if (respon.detail.type === "WRONG_CONFIRMATION_PASSWORD") {
          return "Password Konfirmasi Salah";
        } else if (respon.detail.type === "WRONG_PASSWORD") {
          return "Username atau Password Salah";
        } else if (respon.detail.type === "OLD_PASSWORD_IS_WRONG") {
          return "Gagal mengganti password. Password lama tidak sesuai";
        } else if (respon.detail.type === "INVALID_PASSWORD_CRITERIA") {
          return "Password harus mengandung huruf (kapital dan kecil), angka, karakter spesial dan harus 6-30 karakter ";
        } else if (
          respon.detail.type ===
          "MEMBER_RECEIVER_AND_MEMBER_SENDER_MUST_BELONG_TO_THE_SAME_COMPANY"
        ) {
          return "Member tidak ditemukan";
        } else if (
          respon.detail.type ===
            "AMOUNT_MINUS_TOTAL_CHARGES_MUST_BE_BIGGER_THAN_ZERO" &&
          respon.detail.path === "/balance/member/transfer/inquiry_payment"
        ) {
          return `Total potongan adalah Rp${respon.detail.message.totalCharges}. Nominal harus lebih dari total potongan`;
        } else if (respon.detail.type === "DISCOUNT_CANNOT_BE_LESS_THAN_ZERO") {
          return "Diskon Harus lebih besar Dari Nol";
        } else if (
          respon.detail.type === "TOTAL_TRANSFER_LESS_THAN_MIN_AMOUNT"
        ) {
          return `Total transfer Harus lebih besar Dari Rp${respon.detail.message.minAmount}`;
        } else if (
          respon.detail.type ===
          "AMOUNT_IN_DETAIL_ITEM_INVOICE_MUST_BE_BIGGER_THAN_ZERO"
        ) {
          return "Nominal di Detail Invoice Harus lebih besar Dari Nol";
        } else if (
          respon.detail.type ===
          "EXPIRED_DATE_MUST_BE_LATER_OR_EQUAL_TO_INVOICE_DATE"
        ) {
          return "Tanggal Jatuh Tempo Harus lebih dari atau sama dengan Tanggal Invoice";
        } else if (
          respon.detail.type ===
          "EXPIRED_DATE_MUST_BE_LATER_THAN_OR_EQUAL_TO_THE_CURRENT_DATE"
        ) {
          return "Tanggal Jatuh Tempo Harus lebih dari atau sama dengan Tanggal Saat ini";
        } else if (
          respon.detail.type ===
          "INVOICE_DATE_MUST_BE_LATER_OR_THE_SAME_AS_THE_CURRENT_DATE"
        ) {
          return "Tanggal Invoice Harus lebih dari atau sama dengan Tanggal Saat ini";
        } else if (
          respon.detail.type ===
          "AMOUNT_MINUS_DISCOUNT_CANNOT_BE_LESS_THAN_ZERO"
        ) {
          return "Nominal Dikurangi Diskon Harus lebih besar Dari Nol";
        } else if (
          respon.detail.type ===
          "TOTAL_AMOUNT_INVOICE_CANNOT_BE_ZERO_OR_LESS_THAN_ZERO"
        ) {
          return "Total Invoice harus lebih besar Dari Nol";
        } else if (
          respon.detail.type ===
          "INVOICE_DATE_CANNOT_BE_NULL_IF_IS_REPEATING_IS_FALSE"
        ) {
          return "Tanggal Invoice Harus Diisi Apabila Fitur Berulang Tidak Aktif";
        } else if (
          respon.detail.type ===
          "EXPIRED_DATE_CANNOT_BE_NULL_IF_IS_REPEATING_IS_FALSE"
        ) {
          return "Tanggal Jatuh Tempo Harus Diisi Apabila Fitur Berulang Tidak Aktif";
        } else if (
          respon.detail.type ===
          "REPEATING_UNLIMITED_CANNOT_BE_TRUE_AND_REPEATING_METHOD_MAKE_ALL_AT_THE_SAME_TIME"
        ) {
          return "Fitur Berulang Tak Terbatas dan Metode Kemunculan Invoice Tidak Bisa Diaktifkan Bersamaan";
        } else if (
          respon.detail.type === "REPEATING_RANGE_MUST_BE_BIGGER_THAN_ZERO"
        ) {
          return "Durasi Perulangan Harus lebih Dari Nol";
        } else if (
          respon.detail.type ===
          "RANGE_BETWEEN_INVOICE_DATE_AND_EXPIRED_DATE_MUST_BE_BIGGER_OR_EQUAL_TO_ZERO"
        ) {
          return "Rentang Tanggal Invoice dan Tanggal Jatuh Tempo harus lebih besar Dari Nol";
        } else if (
          respon.detail.type === "REPEATING_START_DATE_CANNOT_BE_NULL"
        ) {
          return "Tanggal Mulai Perulangan Tidak Boleh Kosong";
        } else if (respon.detail.type === "REPEATING_END_DATE_CANNOT_BE_NULL") {
          return "Tanggal Selesai Perulangan Tidak Boleh Kosong";
        } else if (
          respon.detail.type ===
          "REPEATING_START_DATE_MUST_BE_LATER_THAN_CURRENT_DATE"
        ) {
          return "Tanggal Mulai Perulangan Harus lebih Dari Tanggal Saat ini";
        } else if (
          respon.detail.type ===
          "REPEATING_END_DATE_MUST_BE_LATER_OR_EQUAL_TO_REPEATING_START_DATE"
        ) {
          return "Tanggal Selesai Perulangan Harus lebih Dari Tanggal Saat ini";
        } else if (
          respon.detail.type ===
          "REPEATING_RANGE_MUST_BE_BIGGER_THAN_RANGE_BETWEEN_INVOICE_DATE_AND_EXPIRED_DATE"
        ) {
          return "Durasi Perulangan Harus lebih besar Dari Rentang Tanggal Invoice dan Tanggal Jatuh Tempo";
        } else if (
          respon.detail.type === "REPEATING_START_MONTH_CANNOT_BE_NULL"
        ) {
          return "Bulan Mulai Perulangan Tidak Boleh Kosong";
        } else if (
          respon.detail.type === "REPEATING_END_MONTH_CANNOT_BE_NULL"
        ) {
          return "Bulan Selesai Perulangan Tidak Boleh Kosong";
        } else if (
          respon.detail.type === "REPEATING_START_YEAR_CANNOT_BE_NULL"
        ) {
          return "Tahun Mulai Perulangan Tidak Boleh Kosong";
        } else if (respon.detail.type === "REPEATING_END_YEAR_CANNOT_BE_NULL") {
          return "Tahun Selesai Perulangan Tidak Boleh Kosong";
        } else if (
          respon.detail.type === "REPEATING_DATE_OF_MONTH_CANNOT_BE_NULL"
        ) {
          return "Tanggal Perulangan Tidak Boleh Kosong";
        } else if (
          respon.detail.type ===
          "REPEATING_START_MONTH_YEAR_MUST_BE_LESS_OR_EQUAL_TO_REPEATING_END_MONTH_YEAR"
        ) {
          return "Bulan Tahun Mulai Perulangan Harus kurang dari atau sama dengan Bulan Tahun Selesai Perulangan";
        } else if (
          respon.detail.type ===
          "REPEATING_START_MONTH_YEAR_MUST_BE_LATER_THAN_CURRENT_MONTH_YEAR"
        ) {
          return "Bulan Tahun Mulai Perulangan Harus lebih dari Bulan Tahun Saat ini";
        } else if (
          respon.detail.type ===
          "REPEATING_DATE_OF_MONTH_MUST_BE_BETWEEN_1_AND_28"
        ) {
          return "Tanggal Perulangan Harus Diantara 1 dan 28";
        } else if (
          respon.detail.type === "INVOICE_ALREADY_CREATED_FROM_MASTER"
        ) {
          return "Invoice Sudah Terbuat dari Master";
        } else if (respon.detail.type === "INVOICE_MUST_BE_UNPAID") {
          return "Invoice Harus Belum Terbayar";
        } else if (
          respon.detail.type === "USER_CANNOT_CREATE_INVOICE_FOR_SELF"
        ) {
          return "User Tidak Bisa Membuat Invoice Untuk Dirinya Sendiri";
        } else if (
          respon.detail.type === "INVOICE_CUSTOMER_IS_NOT_CURRENT_USER"
        ) {
          return "User Tertagih Bukan User Saat Ini";
        } else if (
          respon.detail.type === "INVOICE_CREATOR_IS_NOT_CURRENT_USER"
        ) {
          return "User Pembuat Invoice Bukan User Saat Ini";
        } else if (
          respon.detail.type ===
          "INVOICE_COMPANY_IS_NOT_THE_SAME_AS_CURRENT_USER_COMPANY"
        ) {
          return "Invoice Perusahaan Tidak Sama Dengan Perusahaan User Saat ini";
        } else if (respon.detail.type === "INVOICE_MUST_BE_PAID") {
          return "Invoice Harus Terbayar";
        } else if (
          respon.detail.type ===
          "INPUT_AMOUNT_MUST_BE_SMALLER_OR_EQUAL_TO_INVOICE_FINAL"
        ) {
          return "Nominal Harus lebih kecil atau sama dengan Total Invoice";
        } else if (respon.detail.type === "AMOUNT_MUST_BE_BIGGER_THAN_ZERO") {
          return "Nominal Harus lebih besar dari Nol";
        } else if (respon.detail.type === "INVOICE_ALREADY_PAID") {
          return "Invoice Telah Terbayar";
        } else if (respon.detail.type === "INVOICE_IS_UNPAID") {
          return "Invoice Belum Terbayar";
        } else if (respon.detail.type === "MASTER_INVOICE_MUST_BE_ACTIVE") {
          return "Invoice Master Harus Aktif";
        } else if (
          respon.detail.type ===
          "INVOICE_LINK_USER_ID_MUST_BE_THE_SAME_AS_INVOICE_USER_ID"
        ) {
          return "Link Id Harus sama dengan User Id Tertagih";
        } else if (respon.detail.type === "FIELD_NOT_EXIST") {
          return "Field Tidak Tersedia";
        } else if (
          respon.detail.type ===
          "START_DATE_END_DATE_DIFFERENCE_MUST_BE_LESS_OR_EQUAL_TO_THIRTY_DAYS"
        ) {
          return "Perbedaan Rentang Tanggal Mulai Dan Tanggal Selesai Harus lebih sedikit atau sama dengan 30 hari";
        } else if (respon.detail.type === "INVALID_COLUMN") {
          return "Kolom Tidak Valid";
        } else if (
          respon.detail.type === "UPLOADED_EXCEL_HAVE_INVALID_COLUMNS"
        ) {
          return "Berkas Excel yang Diunggah memiliki Kolom Tidak Valid";
        } else if (
          respon.detail.type === "TOPIC_MUST_CONFORM_TO_AGREED_FORMAT"
        ) {
          return "Topik Harus Menyesuaikan Format Kesepakatan";
        } else if (respon.detail.type === "DESTINATION_BALANCE_NOT_ALLOWED") {
          return "Saldo Tujuan Tidak Diperbolehkan";
        } else if (respon.detail.type === "INVOICE_IS_NOT_CREATED_BY_GATEWAY") {
          return "Invoice Tidak Terbuat oleh Gateway";
        } else if (respon.detail.type === "PHONE_NUMBER_FORMAT_MUST_08") {
          return "Nomor Telepon Tidak Valid";
        } else if (respon.detail.type === "PHONE_NUMBER_MIN_8") {
          return "Nomor Telepon Minimal 8 Angka";
        } else if (respon.detail.type === "FAILED_SEND_OTP_ACTIVATION") {
          return "Data akun Bank DKI Anda tidak sesuai";
        } else if (respon.detail.type === "FAILED_CONFIRM_REGISTRATION") {
          return "Konfirmasi pendaftaran gagal";
        } else if (respon.detail.type === "PHONE_NUMBER_ALREADY_REGISTERED") {
          return "Nomor Telepon Telah Terdaftar";
        } else if (respon.detail.type === "FAILED_CHECK_ACCOUNT") {
          return "Data Akun Bank DKI Anda Tidak Sesuai";
        } else if (respon.detail.type === "EMAIL_ALREADY_REGISTERED") {
          return "Email Telah Terdaftar";
        } else if (respon.detail.type === "USERNAME_ALREADY_REGISTERED") {
          return "Username Telah Terdaftar";
        } else if (respon.detail.type === "ACTIVATION_FAILED") {
          return "Aktivasi Gagal";
        } else if (respon.detail.type === "DUPLICATE_EMAIL") {
          return "Email Sudah Terdaftar";
        } else if (respon.detail.type === "DUPLICATE_PHONE_NUMBER") {
          return "Nomor Telepon Sudah Terdaftar";
        } else if (respon.detail.type === "USERNAME_ALREADY_EXIST") {
          return "Username Sudah Terdaftar";
        } else if (respon.detail.type === "NOID_ALREADY_EXISTS") {
          return "No ID Sudah Terdaftar";
        } else if (respon.detail.type === "CONFIG_CHECKOUT_NOT_FOUND") {
          return "Config pembayaran checkout belum diatur, silahkan hubungi admin";
        } else if (respon.detail.type === "MERCHANT_NOT_ENABLED") {
          return "Merchant belum melakukan aktivasi, silahkan hubungi merchant";
        } else if (respon.detail.type === "FAILED_CREATE_CHECKOUT") {
          return "Metode pembayaran instansi belum diatur, silahkan hubungi admin";
        } else if (respon.detail.type === "MERCHANT_CHARGE_NOT_FULFILLED") {
          return `Ada masalah pada harga produk ini, silahkan hubungi ${respon.detail.message}`;
        } else if (
          respon.detail.type ===
          "AMOUNT_MINUS_TOTAL_CHARGES_MUST_BE_BIGGER_THAN_ZERO"
        ) {
          return `Minimal nominal transaksi di atas ${respon.detail.message.totalCharges}`;
        } else if (respon.detail.type === "BALANCE_NOT_FOUND") {
          return `Saldo PPOB Company tidak tersedia`;
        } else if (respon.detail.type === "BALANCE_NOT_ENOUGH") {
          return `Saldo Instansi Anda tidak cukup`;
        } else if (respon.detail.type === "MAX_BANK_ACCOUNT_COUNTS_REACHED") {
          return `Anda sudah memiliki 10 akun bank`;
        } else if (respon.detail.type === "FAILED_INQUIRY") {
          return `Permintaan gagal, silahkan coba kembali.`;
        } else if (respon.detail.type === "PROVIDER_TROUBLE") {
          return `Provider sedang dalam gangguan`;
        } else if (respon.detail.type === "FAILED_PAYMENT") {
          return `Akun Rekening tidak ditemukan`;
        } else if (
          respon.detail.type === "TOTAL_TRANSFER_LESS_THAN_MIN_AMOUNT"
        ) {
          return `Total transfer kurang dari minimal transfer bank`;
        } else if (
          respon.detail.type === "TOTAL_TRANSFER_BIGGER_THAN_MAX_AMOUNT"
        ) {
          return `Total transfer melebihi dari maksimal transfer bank`;
        } else if (
          respon.detail.type ===
          "MEMBER_SENDER_AND_MEMBER_RECEIVER_MUST_NOT_BE_THE_SAME"
        ) {
          return `User penerima tidak boleh sama dengan user pengirim`;
        } else if (respon.detail.type === "FAILED_CREATE_VA") {
          return `Gagal Membuat VA`;
        } else if (respon.detail.type === "FAILED_TO_CREATE_VA") {
          return `Gagal Membuat VA`;
        } else if (respon.detail.type === "PROVIDER_TROUBLE") {
          return "Terjadi masalah penyedia layanan, silahkan coba beberapa saat lagi";
        } else if (respon.detail.type === "BANK_NOT_FOUND") {
          return "Bank tidak ditemukan";
        } else if (respon.detail.type === "PASSWORD_MUST_NUMERIC") {
          return "Password harus berupa angka";
        } else if (respon.detail.type === "PASSWORD_MUST_6_DIGIT") {
          return "Password harus 6 digit";
        } else if (respon.detail.type === "MERCHANT_NOT_EXIST") {
          return "Merchant Tidak Aktif";
        } else if (respon.detail.type === "FAILED_GET_TOKEN") {
          return "Mohon periksa nomor handphone";
        } else if (respon.detail.type === "TRANSFER_BALANCE_NOT_ENOUGH") {
          return "Saldo transfer tidak mencukupi, silahkan hubungi admin";
        } else if (
          respon.detail.type ===
          "MEMBER_SENDER_AND_MEMBER_RECEIVER_MUST_NOT_BE_THE_SAME"
        ) {
          return `User Penerima tidak boleh sama dengan User Pengirim`;
        } else if (respon.detail.type === "INVALID_DATE_RANGE") {
          return `Rentang tanggal maksimal 31 hari`;
        } else if (respon.detail.type === "USER_BALANCE_NOT_ENOUGH") {
          return `Saldo Tidak Cukup`;
        } else if (
          respon.detail.type ===
          "AMOUNT_MUST_BE_SMALLER_OR_THE_SAME_AS_MAX_AMOUNT"
        ) {
          return `Maksimal nominal top up adalah ${respon.detail.message.maxAmount}`;
        } else if (
          respon.detail.type ===
          "AMOUNT_MUST_BE_BIGGER_OR_THE_SAME_AS_MIN_AMOUNT"
        ) {
          return `Minimal nominal top up adalah ${respon.detail.message.minAmount}`;
        } else if (respon.detail.type === "CONFIG_DISBURSEMENT_NOT_SET") {
          return `Konfig disbursement belum diatur, silahkan hubungi admin`;
        } else if (respon.detail.type === "PRODUCT_NOT_EXIST") {
          return `Produk tidak tersedia`;
        } else if (respon.detail.type === "MAIN_BALANCE_MUST_BE_CLOSEPAY") {
          return `Transaksi tidak diizinkan untuk saldo selain Closepay, silahkan hubungi admin instansi Anda`;
        } else if (respon.detail.type === "STATUS_NOT_VALID") {
          return `Order tidak dapat di tolak karena sudah di bayar`;
        } else if (respon.detail.type === "FAILED_CREATE_CHECKOUT") {
          return `Checkout gagal`;
        } else if (respon.detail.type === "STOCK_PRODUCT_NOT_EXIST") {
          return `Produk tidak tersedia`;
        } else if (respon.detail.type === "INVALID_PRODUCT_NOTE_NAME") {
          return `Produk sedang bermasalah`;
        } else if (respon.detail.type === "TRANSACTION_TYPE_NOT_SUPPORTED") {
          return `Jenis transaksi belum didukung`;
        } else {
          return (
            checkErrorResponse(respon.detail.message) || respon.detail.type
          );
        }
      // BAD_REQUEST = "BAD_REQUEST"
      // INVALID_PASSWORD_LENGTH = "INVALID_PASSWORD_LENGHT"

      case 401:
        if (respon.detail.type === "WRONG_PASSWORD") {
          return "Username atau Password Salah";
        } else if (respon.detail.type === "AUTHENTICATION_FAILED") {
          return "Otentikasi Gagal";
        } else if (
          ["UNAUTHORIZED", "UNATHORIZED"].includes(respon.detail.type)
        ) {
          return "Tidak Diizinkan";
        } else {
          return (
            checkErrorResponse(respon.detail.message) || respon.detail.type
          );
        }
      case 403:
        if (respon.detail.type === "UNAUTHORIZED_ROLE") {
          return "Akses Tidak Diizinkan";
        } else if (respon.detail.type === "ACCESS_FORBIDDEN") {
          return "Sesi login telah berakhir, silakan melakukan login";
        } else if (respon.detail.type === "OTP_REQUIRED") {
          return "";
        } else if (respon.detail.type === "OTP_NOT_FOUND") {
          return "masukkan OTP untuk melanjutkan transaksi";
        } else if (respon.detail.type === "SUPERADMIN_MUST_CHANGE_COMPANY") {
          return;
        } else {
          return (
            checkErrorResponse(respon.detail.message) || respon.detail.type
          );
        }
      case 404:
        if (respon.detail.type === "COMPANY_NOT_FOUND") {
          return "Instansi/Perusahaan tidak ditemukan, huruf besar/kecil berpengaruh";
        } else if (respon.detail.type === "QR_CODE_NOT_FOUND") {
          return "Barcode tidak ditemukan";
        } else if (respon.detail.type === "NOT_FOUND") {
          return "Tidak Ditemukan";
        } else if (respon.detail.type === "ADMIN_NOT_FOUND") {
          return "Admin Tidak Ditemukan";
        } else if (respon.detail.type === "MEMBER_NOT_FOUND") {
          return "Member Tidak Ditemukan";
        } else if (respon.detail.type === "MERCHANT_NOT_FOUND") {
          return "Merchant Tidak Ditemukan";
        } else if (respon.detail.type === "USER_NOT_FOUND") {
          return "User Tidak Ditemukan";
        } else if (respon.detail.type === "INVOICE_TRANSACTION_NOT_FOUND") {
          return "Transksi Invoice Tidak Ditemukan";
        } else if (respon.detail.type === "INVOICE_LINK_NOT_FOUND") {
          return "Link Invoice Tidak Ditemukan";
        } else if (respon.detail.type === "MASTER_INVOICE_NOT_FOUND") {
          return "Invoice Master Tidak Ditemukan";
        } else if (respon.detail.type === "INVOICE_NOT_FOUND") {
          return "Invoice Tidak Ditemukan";
        } else if (respon.detail.type === "DONATION_NOT_FOUND") {
          return "";
        } else {
          return respon.detail.message || respon.detail.type;
        }
      case 500:
        if (respon.detail.type === "INTERNAL_SERVER_ERROR") {
          return "Terjadi Kesalahan Sistem";
        } else {
          return (
            checkErrorResponse(respon.detail.message) || respon.detail.type
          );
        }
      default:
        return checkErrorResponse(respon.detail.message) || respon.detail.type;
    }
  } else if (respon.status_code) {
    switch (parseInt(respon.status_code)) {
      case 400:
        if (respon.type === "INVALID_COMPANY") {
          return "Company Tidak Sah";
        } else if (respon.type === "USER_NOT_FOUND") {
          return "Username/Email Tidak Ditemukan";
        } else if (respon.type === "USER_INACTIVE") {
          return "Akun Terblokir";
        }
      default:
        return checkErrorResponse(respon.message) || respon.type;
    }
  } else if (respon?.errorCodes[0]) {
    let errData = respon.errorCodes[0];
    if (errData === "FAILED_INQUIRY") {
      if (respon?.errors?.message) {
        console.log(respon);
        return respon?.errors?.message[0];
      } else {
        return `Produk sedang bermasalah, silakan coba beberapa saat lagi`;
      }
    } else if (errData === "PRODUCT_NOT_EXIST") {
      return `Produk tidak tersedia`;
    } else {
      return errData;
    }
  }
};

// if (respon === "SUCCESS_LOGOUT") {
//   return "Berhasil Logout";
// } else if (respon === "SUCCESS_REQUEST_OTP") {
//   return "Permintaan OTP Berhasil";
// } else if (respon === "SUCCESS_VALIDATE_OTP") {
//   return "Validasi OTP Berhasil";
// } else if (respon === "SUCCESS_VALIDATE_SECURITY_CODE") {
//   return "Validasi Kode Keamanan Berhasil";
// } else if (respon === "SUCCESS_VALIDATE_SECURITY_CODE_TOKEN") {
//   return "Validasi Kode Keamanan Token Berhasil";
// } else if (respon === "SUCCESS_GET_OTP_CONFIG") {
//   return "Konfigurasi OTP Berhasil";
// } else if (respon === "SUCCESS_UPDATE_OTP_CONFIG") {
//   return "Token Diperlukan";
// } else if (respon === "INVALID_TOKEN") {
//   return "Token Salah";
// } else if (respon === "EXPIRED_TOKEN") {
//   return "Token Expired";
// } else if (respon === "WRONG_TRANSACTION") {
//   return "Transaksi Salah";
// } else if (respon === "WRONG_USER_ID") {
//   return "User Id Salah";
// } else if (respon === "User tidak ditemukan") {
//   return "User tidak ditemukan";
// } else if (respon === "Company tidak ditemukan") {
//   return "Company tidak ditemukan";
// } else if (respon === "User tidak memiliki email") {
//   return "User tidak memiliki email";
// } else if (respon === "Device baru, mohon masukkan kode otp") {
//   return "Device baru, mohon masukkan kode otp";
// } else if (respon === "Kode OTP tidak ditemukan") {
//   return "Kode OTP tidak ditemukan";
// } else if (respon === "Kode OTP suspended") {
//   return "Kode OTP suspended";
// } else if (respon === "Kode OTP expired") {
//   return "Kode OTP expired";
// } else if (respon === "OTP salah") {
//   return "OTP salah";
// } else if (respon === "Your Account is Disabled") {
//   return "Akun Anda di Nonaktifkan";
// } else if (respon === "Incorrect old password") {
//   return "Password lama tidak sesuai";
// } else if (respon === "User not found") {
//   return "User tidak di temukan";
// } else if (respon === "Email tidak valid") {
//   return "Email tidak valid";
// } else if (respon === "Email sudah terdaftar") {
//   return "Email sudah terdaftar";
// } else if (respon === "Gagal update data") {
//   return "Gagal update data";
// } else if (
//   respon === "Belum bisa membuat virtual account dengan bank terkait"
// ) {
//   return "Belum bisa membuat virtual account dengan bank terkait";
// } else if (respon === "Gagal mengirim request create va ke gateway") {
//   return "Gagal mengirim request create va ke gateway";
// } else if (respon === "COMPANY_ACCOUNT_NOT_FOUND") {
//   return "Company Akun Tidak ditemukan";
// } else if (respon === "WRONG_ACCOUNT_NAME") {
//   return "Nama Akun Salah";
// } else if (respon === "BANK_ACCOUNT_NOT_FOUND") {
//   return "Akun Bank Tidak Di temukan";
// } else if (respon === "DONATION_HAS_ENDED") {
//   return "Donasi telah Berakhir";
// } else if (respon === "MIN_DONATION_10000") {
//   return "Minimal Donasi Rp 10.000";
// } else if (respon === "DONATION_PROGRESS_NOT_FOUND") {
//   return "Donasi tidak di temukan";
// } else if (respon === "QR_CODE_NOT_FOUND") {
//   return "Qr Code Tidak di temukan";
// } else if (respon === "QR_CODE_EXPIRED") {
//   return "QR Code Expired";
// } else if (respon === "User bukan member") {
//   return "User bukan member";
// } else if (respon === "USER_ALREADY_HAVE_SECURITY_CODE") {
//   return "User Sudah memiliki kode Keamanan";
// } else if (respon === "SECURITY_CODE_INVALID") {
//   return "Kode Keamanan Invalid";
// } else if (respon === "WRONG_SECURITY_CODE") {
//   return "Kode Keamanan Salah";
// } else if (respon === "SUSPENDED_SECURITY_CODE") {
//   return "Kode Keamanan di Suspend";
// } else if (respon === "USER_HAVE_NO_SECURITY_CODE") {
//   return "User Tidak Memiliki Kode Keamanan";
// } else if (respon === "REQUEST_FOR_RESET_OTP_TOO_QUICK") {
//   return "Permintaan request OTP untuk reset kode keamanan terlalu cepat. Mohon tunggu beberapa saat";
// } else if (respon === "OTP_REQUEST_EXCEED_MAXIMUM_NUMBER_IN_ONE_DAY") {
//   return "PERMINTAAN OTP MELEBIHI JUMLAH MAKSIMUM DALAM SATU HARI";
// } else if (respon === "OTP_CODE_EXPIRED") {
//   return "Kode OTP Expired";
// } else if (respon === "WRONG_OTP_CODE") {
//   return "Kode OTP Tidak Sesuai";
// } else if (respon === "EXTENSION_NOT_ALLOWED") {
//   return "Extensi Salah";
// } else if (respon === "FAILED_UPLOADING_FILE") {
//   return "Gagal Upload file";
// } else if (respon === "MERCHANT_NOT_EXIST") {
//   return "Merchant tidak di temukan";
// } else if (respon === "MEMBER_NOT_EXIST") {
//   return "Member tidak di temukan";
// } else if (respon === "MERCHANT_SHIP_NOT_EXIST") {
//   return "Kurir Merchant tidak di temukan";
// } else if (respon === "AUTHENTICATION_FAILED") {
//   return "Authentikasi gagal";
// } else if (respon === "BALANCE_NOT_ENOUGH") {
//   return "Saldo saat create order tidak cukup";
// } else if (respon === "FAILED_CREATING_ORDER") {
//   return "Order gagal di buat";
// } else if (respon === "STATUS_NOT_VALID") {
//   return "Status order tidak valid";
// } else if (respon === "CATEGORY_NOT_EXIST") {
//   return "Kategori Tidak di temukan";
// } else if (respon === "PRODUCT_NOT_EXIST") {
//   return "Product Tidak di temukan";
// } else if (respon === "MEMBER_ADDRESS_NOT_EXIST") {
//   return "Alamat Member tidak di temukan";
// } else if (respon === "CART_NOT_EXIST") {
//   return "Keranjang Tidak di temukan";
// } else if (respon === "ORDER_NOT_EXIST") {
//   return "Order Tidak di temukan";
// } else if (respon === "INVALID_PRICE") {
//   return "Harga Salah";
// } else if (respon === "INVALID_ORDER_QUANTITY") {
//   return "Harga Salah";
// } else if (respon === "Harga Order salah") {
//   return "Harga Salah";
// } else if (respon === "FAILED_REVERSE_BALANCE") {
//   return "Gagal Mengembalikan Saldo";
// } else if (respon === "PRODUCT_QUANTITY_NOT_EXIST") {
//   return "Jumlah Product Tidak Tersedia";
// } else if (respon === "FAILED_SEND_NOTIFICATION") {
//   return "Gagal Mengirim Notifikasi";
// } else if (respon === "UNAUTHORIZED_ROLE") {
//   return "Role tidak bisa di akses";
// } else if (respon === "CHARGE_NOT_EXIST") {
//   return "Data charge tidak di temukan";
// } else if (respon === "FAILED_UPDATE_CHARGE_STATUS") {
//   return "Gagal mengubah mutasi charge";
// } else if (respon === "PULSE_PRODUCT_NOT_EXIST") {
//   return "Pulsa tidak tersedia";
// } else if (respon === "FAILED_PULSE_INQUIRY") {
//   return "Gagal Mengambil data Pulsa";
// } else if (respon === "PLN_CUSTOMER_NUMBER_NOT_FOUND") {
//   return "PLN Customer Number tidak di temukan";
// } else if (respon === "PLN_PRODUCT_NOT_EXIST") {
//   return "PLN Tidak tersedia";
// } else if (respon === "TRANSACTION_NOT_EXIST") {
//   return "Tidak ada Teransaksi";
// } else if (respon === "FAILED_PLN_INQUIRY") {
//   return "PLN Inquiry gagal";
// } else if (respon === "FAILED_BPJS_INQUIRY") {
//   return "BPJS Inquiry gagal";
// } else if (respon === "FAILED_PBB_INQUIRY") {
//   return "PBB Inquiry gagal";
// } else if (respon === "PBB_CODE_NOT_EXIST") {
//   return "Kode PBB Tidak di temukan";
// } else if (respon === "FAILED_PDAM_INQUIRY") {
//   return "PDAM Inquiry gagal";
// } else if (respon === "PDAM_CODE_NOT_EXIST") {
//   return "Kode PDAM Tidak di Temukan";
// } else if (respon === "FAILED_INTERNET_TV_INQUIRY") {
//   return "Internet TV Inquiry gagal";
// } else if (respon === "INTERNET_TV_PRODUCT_NOT_EXIST") {
//   return "Product Internet Tv Tidak di temukan";
// } else if (respon === "ACCOUNT_NOT_FOUND") {
//   return "Akun Tidak Di Temukan";
// } else if (respon === "STOCK_PRODUCT_NOT_EXIST") {
//   return "Stok Produk Tidak Mencukupi";
// }
// else {
//   return respon;
// };
