import { useEffect, useState } from "react";
import Header from "../_common/headerComponent";
import QrScanner from "qr-scanner";
import { useLocation, useNavigate } from "react-router-dom";
import BottomLogo from "../_common/bottomLogo";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import * as API_ABSENSI from "../../core/service/api_absensi";
import AccessResult from "../accessResultComponent";
import { ConfigColor } from "../../config/global";
import arrow from "../../assets/images/arrow-left-ic.svg";
import { useTranslation } from "react-i18next";

const FeatureScanBarcodeAccessMobile = () => {
  const color = ConfigColor();
  const { t } = useTranslation();
  const [btnScan, setBtnScan] = useState(true);
  // const [hasilScan, setHasilScan] = useState("");
  // const navigate = useNavigate();
  // const [id, setId] = useState<any>("");
  // const [activity, setActivity] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});
  const [visible, setVisible] = useState(false);
  let location: any = useLocation();
  let stopScan = false;
  const navigate = useNavigate();

  const scanNow = async (isScan?: any) => {
    setBtnScan(isScan);
    if (isScan === false) stopScan = true;
    if (btnScan === false) return;
    // stopScan = false;
    await new Promise((r) => setTimeout(r, 100));
    const videoElement: any = document.getElementById("scanView");
    const scanner = new QrScanner(
      videoElement,
      (result: any) => {
        // setHasilScan(JSON.stringify(result.data));
        setBtnScan(true);
        stopScan = true;
        // setId(result.data);
        let query = {
          // activityId: data?.id,
          qrId: result.data,
        };
        dispatch(setLoading(true));
        API_ABSENSI.entrancePresent(query)
          .then((res) => {
            dispatch(setLoading(false));
            localStorage.setItem("result", JSON.stringify(res));
            setVisible(true);
          })
          .catch((err) => {
            dispatch(setLoading(false));
            setVisible(true);
          });
      },
      {
        onDecodeError: (error) => {
          console.error(error);
        },
        maxScansPerSecond: 1,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        returnDetailedScanResult: true,
      }
    );
    await scanner.start();
    while (stopScan === false) await new Promise((r) => setTimeout(r, 100));
    scanner.stop();
    scanner.destroy();
  };

  useEffect(() => {
    // setData(location.state?.data);
    scanNow(true);
  }, []);

  return (
    <>
      <div hidden={!visible}>
        <AccessResult />
      </div>
      <div className="w-full h-full flex flex-col justify-between min-h-screen bg-mobile">
        <div hidden={visible}>
          <section className="flex flex-tow items-center z-50 px-4 py-3 mb-7">
            <div
              onClick={async () => {
                await scanNow(false);
                navigate(-1);
              }}
              className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
            >
              <img
                src={arrow}
                style={{ width: 6, height: 12 }}
                className="m-auto"
                alt=""
              />
            </div>

            <h1
              className={`font-montserrat font-bold text-base m-auto relative`}
            >
              {t('accessibility.scan_barcode')}
            </h1>
          </section>
          <div className="w-full font-montserrat absolute min-h-screen -mt-6">
            {/* {btnScan === false && ( */}
            <video
              id="scanView"
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "100%",
                minHeight: "100%",
                borderStyle: "dotted",
              }}
            ></video>
            {/* )} */}
            {/* {btnScan == false && ( */}
            <h3 className="text-center text-white text-sm px-4 mb-4 -mt-14 absolute z-50">
              {t('accessibility.message_barcode')}
            </h3>
          </div>
        </div>

        <div className="w-full p-4">
          <button
            onClick={async () => {
              console.log("klik");
              await scanNow(false);
              navigate(-1);
              localStorage.removeItem("result");
            }}
            className={
              btnScan
                ? "py-2 px-4 rounded-lg w-full bg-gradient-to-b border-1 relative z-10 text-white border-white"
                : ""
            }
            style={{ backgroundColor: `${color.color1}` }}
          >
            {t('accessibility.cancel')}
          </button>
          {/* <BottomLogo /> */}
        </div>
      </div>
    </>
  );
};

export default FeatureScanBarcodeAccessMobile;
