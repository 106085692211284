import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationGeneralTransactionWeb from "./donationGeneralTransactionWeb";
import DonationGeneralTransactionMobile from "./donationGeneralTransactionMobile";

const DonationGeneralTransaction = () => {
  return (
    <>
      <BrowserView>
        <DonationGeneralTransactionWeb></DonationGeneralTransactionWeb>
      </BrowserView>
      <MobileView>
        <DonationGeneralTransactionMobile></DonationGeneralTransactionMobile>
      </MobileView>
    </>
  );
};

export default DonationGeneralTransaction;
