import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBBPJSSummaryWeb from "./ppobBPJSSummaryWeb";
import PPOBBPJSSummaryMobile from "./ppobBPJSSummaryMobile";

const PPOBBPJSSummary = () => {
  return (
    <>
      <BrowserView>
        <PPOBBPJSSummaryWeb></PPOBBPJSSummaryWeb>
      </BrowserView>
      <MobileView>
        <PPOBBPJSSummaryMobile></PPOBBPJSSummaryMobile>
      </MobileView>
    </>
  );
};

export default PPOBBPJSSummary;
