/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TransactionDetailWeb from "./transactionDetailWeb";
import TransactionDetailMobile from "./transactionDetailMobile";
import { useParams } from "react-router-dom";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { formatCurrency, formatDateTimeUTC } from "../../../config/global";
import { setLoading } from "../../../core/feature/config/configSlice";
import Company from "../../../core/models/company";
import { useTranslation } from "react-i18next";

const TransactionDetail = () => {
  let dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [dataPost, setDataPost] = React.useState<any[]>([]);
  const [headerProperty, setHeaderProperty] = React.useState("");
  const [headerValue, setHeaderValue] = React.useState(0);
  const company: Company = useAppSelector((state) => state.company.company);

  const getTransactionDetail = async () => {
    dispatch(setLoading(true));
    let transactionType = params.transactionType as string;
    let transactionId = params.transactionId;
    switch (transactionType) {
      case "TOP_UP_CARD_BALANCE":
        return await API_VIRTUAL_CARD.getDetailTopUpCardBalance(transactionId)
          .then((res) => {
            dispatch(setLoading(false));
            setHeaderProperty("Top Up");
            setHeaderValue(res.data.data.total);
            let tempData = res.data.data;
            let newData = [
              {
                property: t('virtual_card.transaction_id'),
                value: tempData._id,
              },
              {
                property: t('virtual_card.date'),
                value: formatDateTimeUTC(tempData.createdTime),
              },
              {
                property: t('virtual_card.member_name'),
                value: tempData.user.name,
              },
              {
                property: t('virtual_card.payment_type'),
                value: t('virtual_card.top_up_card_balance'),
              },
              {
                property: t('virtual_card.card_name'),
                value: tempData.cardName,
              },
              {
                property: t('virtual_card.type_card'),
                value: t('mutation_virtual_card.identity_transaction'),
              },
              {
                property: t('mutation_virtual_card.topup_purpose'),
                value: t('virtual_card.balance_closeloop'),
              },
              {
                property: t('virtual_card.nominal_topup'),
                value: formatCurrency(tempData.amount),
              },
              {
                property: t('withdraw_funds.adminFee'),
                value: formatCurrency(tempData.totalCharges),
              },
            ];
            setDataPost(newData);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });

      case "TOP_UP_CARD_BALANCE_MERCHANT":
        return await API_VIRTUAL_CARD.getTopUpBalanceMerchantDetail(
          transactionId
        )
          .then((res) => {
            dispatch(setLoading(false));
            setHeaderProperty(t('mutation_virtual_card.top_up_merchant'));
            setHeaderValue(res.data.data.totalMember);
            let tempData = res.data.data;
            let newData = [
              {
                property: t('virtual_card.transaction_id'),
                value: tempData._id,
              },
              {
                property: t('virtual_card.date'),
                value: formatDateTimeUTC(tempData.createdTime),
              },
              {
                property: t('virtual_card.member_name'),
                value: tempData.member.name,
              },
              {
                property: t('virtual_card.payment_type'),
                value: t('mutation_virtual_card.top_up_card_balance_via_merchant'),
              },
              {
                property: t('virtual_card.card_name'),
                value: tempData.cardName,
              },
              {
                property: t('virtual_card.type_card'),
                value: t('mutation_virtual_card.identity_transaction'),
              },
              {
                property: t('mutation_virtual_card.topup_purpose'),
                value: t('virtual_card.balance_closeloop'),
              },
              {
                property: t('virtual_card.nominal_topup'),
                value: formatCurrency(tempData.amount),
              },
              {
                property: t('withdraw_funds.adminFee'),
                value: formatCurrency(tempData.totalChargesMember),
              },
            ];
            setDataPost(newData);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });

      case "WITHDRAW_CARD_BALANCE":
        return await API_VIRTUAL_CARD.getDetailWithdrawBalance(transactionId)
          .then((res) => {
            dispatch(setLoading(false));
            setHeaderProperty(t('mutation_virtual_card.disbursement'));
            setHeaderValue(res.data.data.total);
            let tempData = res.data.data;
            let newData = [
              {
                property: t('virtual_card.transaction_id'),
                value: tempData._id,
              },
              {
                property: t('virtual_card.date'),
                value: formatDateTimeUTC(tempData.createdTime),
              },
              {
                property: t('virtual_card.member_name'),
                value: tempData.user.name,
              },
              {
                property: t('virtual_card.payment_type'),
                value: t('mutation_virtual_card.card_balance_withdrawal'),
              },
              {
                property: t('virtual_card.card_name'),
                value: tempData.cardName,
              },
              {
                property: t('virtual_card.type_card'),
                value: t('mutation_virtual_card.identity_transaction'),
              },
              {
                property: t('withdraw_funds.purpose_disbursement'),
                value: t('virtual_card.balance_closeloop'),
              },
              {
                property: t('withdraw_funds.nominal'),
                value: formatCurrency(tempData.amount),
              },
              {
                property: t('withdraw_funds.adminFee'),
                value: formatCurrency(tempData.totalCharges),
              },
            ];
            setDataPost(newData);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });

      case "WITHDRAW_CARD_BALANCE_MERCHANT":
        return await API_VIRTUAL_CARD.getDetailWithdrawMerchant(transactionId)
          .then((res) => {
            dispatch(setLoading(false));
            setHeaderProperty(t('mutation_virtual_card.card_balance_withdrawal_via_merchant'));
            setHeaderValue(res.data.data.totalMember);
            let tempData = res.data.data;
            let newData = [
              {
                property: t('virtual_card.transaction_id'),
                value: tempData._id,
              },
              {
                property: t('virtual_card.date'),
                value: formatDateTimeUTC(tempData.createdTime),
              },
              {
                property: t('virtual_card.member_name'),
                value: tempData.member.name,
              },
              {
                property: t('virtual_card.payment_type'),
                value: t('mutation_virtual_card.card_balance_withdrawal_via_merchant'),
              },
              {
                property: t('virtual_card.card_name'),
                value: tempData.cardName,
              },
              {
                property: t('virtual_card.type_card'),
                value: t('mutation_virtual_card.identity_transaction'),
              },
              {
                property: t('withdraw_funds.purpose_disbursement'),
                value: t('virtual_card.balance_closeloop'),
              },
              {
                property: t('withdraw_funds.nominal'),
                value: formatCurrency(tempData.amount),
              },
              {
                property: t('withdraw_funds.adminFee'),
                value: formatCurrency(tempData.totalChargesMember),
              },
            ];
            setDataPost(newData);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });

      case "CARD_PAYMENT_MERCHANT":
        return await API_VIRTUAL_CARD.getCardTransactionDetail(transactionId)
          .then((res) => {
            dispatch(setLoading(false));
            setHeaderProperty(t('mutation_virtual_card.payment'));
            setHeaderValue(res.data.data.refOfObject.totalMember);
            let tempData = res.data.data;
            let newData = [
              {
                property: t('virtual_card.transaction_id'),
                value: tempData._id,
              },
              {
                property: t('virtual_card.date'),
                value: formatDateTimeUTC(tempData.createdTime),
              },
              {
                property: t('virtual_card.member_name'),
                value: tempData.member.name,
              },
              {
                property: t('virtual_card.payment_type'),
                value: t('mutation_virtual_card.card_transaction'),
              },
              {
                property: t('virtual_card.card_name'),
                value: tempData.cardName,
              },
              {
                property: t('virtual_card.type_card'),
                value: t('mutation_virtual_card.identity_transaction'),
              },
              {
                property: t('virtual_card.paymentMethod'),
                value: t('virtual_card.balance_closeloop'),
              },
              {
                property: t('virtual_card.total_payment'),
                value: formatCurrency(tempData.amount),
              },
              {
                property: t('withdraw_funds.adminFee'),
                value: formatCurrency(tempData.refOfObject.totalChargesMember),
              },
            ];
            setDataPost(newData);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });

      case "COMMERCE_FNB_PAYMENT_CARD":
        return await API_VIRTUAL_CARD.getCardTransactionDetail(transactionId)
          .then((res) => {
            dispatch(setLoading(false));
            setHeaderProperty(t('mutation_virtual_card.payment'));
            setHeaderValue(res.data.data.refOfObject.totalMember);
            let tempData = res.data.data;
            let newData = [
              {
                property: t('virtual_card.transaction_id'),
                value: tempData._id,
              },
              {
                property: t('virtual_card.date'),
                value: formatDateTimeUTC(tempData.createdTime),
              },
              {
                property: t('virtual_card.member_name'),
                value: tempData.member.name,
              },
              {
                property: t('virtual_card.payment_type'),
                value: t('mutation_virtual_card.fnb_card_transactions'),
              },
              {
                property: t('virtual_card.card_name'),
                value: tempData.cardName,
              },
              {
                property: t('virtual_card.type_card'),
                value: t('mutation_virtual_card.identity_transaction'),
              },
              {
                property: t('virtual_card.paymentMethod'),
                value: t('virtual_card.balance_closeloop'),
              },
              {
                property: t('virtual_card.total_payment'),
                value: formatCurrency(tempData.amount),
              },
              {
                property: t('withdraw_funds.adminFee'),
                value: formatCurrency(tempData.refOfObject.totalChargesMember),
              },
            ];
            setDataPost(newData);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });

      case "PAYGATE_PAYMENT_TRANSACTION":
        return await API_VIRTUAL_CARD.getPaygateRecord(transactionId)
          .then((res) => {
            dispatch(setLoading(false));
            setHeaderProperty(t('mutation_virtual_card.payment'));
            setHeaderValue(res.data.data.price);
            let tempData = res.data.data;
            console.log(tempData);
            let newData = [
              {
                property: t('virtual_card.transaction_id'),
                value: tempData.transactionId,
              },
              {
                property: t('virtual_card.date'),
                value: formatDateTimeUTC(tempData.loginTime),
              },
              {
                property: t('virtual_card.member_name'),
                value: tempData.userName,
              },
              {
                property: t('virtual_card.payment_type'),
                value: t('mutation_virtual_card.card_transaction_paygate'),
              },
              {
                property: t('virtual_card.type_card'),
                value: t('mutation_virtual_card.identity_transaction'),
              },
              {
                property: t('virtual_card.paymentMethod'),
                value: t('virtual_card.balance_closeloop'),
              },
              {
                property: t('virtual_card.total_payment'),
                value: formatCurrency(tempData.price),
              },
            ];
            setDataPost(newData);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      case "CARD_PAYMENT_GATEWAY":
        return await API_VIRTUAL_CARD.getCardTransactionDetail(transactionId)
          .then((res) => {
            dispatch(setLoading(false));
            setHeaderProperty(t('mutation_virtual_card.payment'));
            setHeaderValue(res.data.data.refOfObject.totalMember);
            let tempData = res.data.data;
            let newData = [
              {
                property: t('virtual_card.transaction_id'),
                value: tempData._id,
              },
              {
                property: t('virtual_card.date'),
                value: formatDateTimeUTC(tempData.createdTime),
              },
              {
                property: t('virtual_card.member_name'),
                value: tempData.member.name,
              },
              {
                property: t('virtual_card.payment_type'),
                value: t('mutation_virtual_card.gateway_card_transactions'),
              },
              {
                property: t('virtual_card.card_name'),
                value: tempData.cardName,
              },
              {
                property: t('virtual_card.type_card'),
                value: t('mutation_virtual_card.identity_transaction'),
              },
              {
                property: t('virtual_card.paymentMethod'),
                value: t('virtual_card.balance_closeloop'),
              },
              {
                property: t('virtual_card.total_payment'),
                value: formatCurrency(tempData.amount),
              },
              {
                property: t('withdraw_funds.adminFee'),
                value: formatCurrency(tempData.refOfObject.totalChargesMember),
              },
            ];
            setDataPost(newData);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });

      default:
        dispatch(setLoading(false));
        break;
    }
  };

  React.useEffect(() => {
    getTransactionDetail();
  }, []);

  return (
    <>
      <BrowserView>
        <TransactionDetailWeb
          dataPost={dataPost}
          headerProperty={headerProperty}
          headerValue={headerValue}
          company={company}
        />
      </BrowserView>
      <MobileView>
        <TransactionDetailMobile
          dataPost={dataPost}
          headerProperty={headerProperty}
          headerValue={headerValue}
          company={company}
        />
      </MobileView>
    </>
  );
};

export default TransactionDetail;
