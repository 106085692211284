// import React, { Component, useEffect, useState } from "react";
import Header from "../_common/headerComponent";

const ProfileWeb = () => {
  // const dataPassword = {
  //   passwordLama:"12345678",
  //   passwordBaru:"12345678",
  //   konfirmasiPasswordBaru:"12345678",
  // }
  return (
    <>
      <Header></Header>
      <section className="w-full h-auto">
        <div className="m-auto w-10/12 pt-8 pb-3 text-center">
          <div className="m-auto w-5/12 text-left">
            <div className="w-5/6 text-left mb-3">
              <h1 className=" font-poppins font-bold text-3xl">
                Term of Service
              </h1>
            </div>
            <div className="bg-white rounded-2xl p-3  flex flex-col ">
              <div className="bg-gray-200 rounded-2xl  flex flex-col ">
                <div className="py-1 px-3">
                  <p className="text-sm font-poppins font-normal p-2 m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Porttitor lectus ac ornare auctor quis facilisis tortor orci
                    vel. A nunc cursus phasellus ultrices quam purus. Quis ac,
                    risus dapibus sed amet. Nisi, nibh eu dapibus mauris nec,
                    magna. Ullamcorper nunc lectus venenatis blandit. Posuere
                    est id id mattis vivamus id non accumsan cursus. Facilisis
                    consectetur fames volutpat egestas sagittis. Quam iaculis
                    elementum faucibus arcu cursus arcu amet, pellentesque.
                    Risus a facilisis nunc enim vitae nibh. Nunc dui habitant
                    amet et. Ut dolor morbi auctor tellus varius quam. Massa
                    lectus cras sed egestas ac tempus. Faucibus morbi
                    suspendisse a libero. Elit dictum id vitae cursus. Aliquet
                    tortor, enim egestas risus elementum. Nisl libero, in est,
                    pellentesque. Enim, sit nisl dignissim malesuada aliquet et,
                    integer. Nec, varius aliquam turpis eu in cursus integer
                    nibh. Dignissim viverra elit facilisi et sagittis. Nec,
                    mattis nunc laoreet donec aliquam, id aliquam habitasse.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex mt-3 mb-1 p-2">
              <input className="mr-2 w-6 h-6" type="checkbox" />
              <div className="flex mt-1">
                <label className="inline-block text-sm text-gray-800">
                  I Agree to the term of the service
                </label>
                <label className="ml-1 text-sm text-red-600">*</label>
              </div>
            </div>
            <div className="w-full text-left mt-2">
              <button
                className="w-full h-10 p-1 mt-1 mb-2 rounded-2xl text-white text-base"
                style={{ backgroundColor: "#00D19F" }}
              >
                Agree
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileWeb;
