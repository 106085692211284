import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../_common/headerComponent";
import DataNotFound from "../../_common/dataNotFound";
import {
  convertToUTC,
  formatCurrency,
  formatDateTimeUTC,
} from "../../../config/global";
import LoadMoreButton from "../../_common/loadMoreButton";
import ArrowDown from "../../../assets/images/arrow_down.svg";
import TopUpIcon from "../../../assets/images/vcard_topup.svg";
import WithdrawIcon from "../../../assets/images/vcard_withdraw.svg";
import CardTransactionIcon from "../../../assets/images/card_transaction.svg";
import Card from "../../../assets/images/icon_transaksi/cards.svg";
import { useTranslation } from "react-i18next";

const VirtualCardMutationWeb = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-poppins bg-mobile">
      <Header />

      <div className="container flex justify-center gap-4 md:mt-[64px] md:pb-[64px] pb-[1.5rem] mt-[0px] px-4">
        <div className="lg:w-1/2 md:w-2/3 w-full">
          <div className="grid grid-cols-6 items-center gap-3">
            <div className="lg:col-span-2 col-span-3">
              <button
                type="button"
                onClick={() => {
                  props.setShowDate(true);
                  props.setStartDate(
                    convertToUTC(new Date().setHours(0o0, 0o0, 0o0))
                  );
                  props.setEndDate(
                    convertToUTC(new Date().setHours(23, 59, 59))
                  );
                }}
                className="w-full flex items-center justify-between border-[1px] border-[#E5E5E5] bg-[#F4F4F4] text-customBlack rounded-md py-[10px] px-3"
              >
                <span>{t('mutation_virtual_card.dateFilter')}</span>
                <img src={ArrowDown} alt="" />
              </button>
            </div>
            <div className="lg:col-span-4 col-span-3">
              <button
                type="button"
                onClick={() => props.setShowTransactionType(true)}
                className="w-full flex items-center justify-between border-[1px] border-[#E5E5E5] bg-[#F4F4F4] text-customBlack rounded-md py-[10px] px-3 overflow-hidden"
              >
                <span>{props.currentTransactionType}</span>
                <img src={ArrowDown} alt="" />
              </button>
            </div>
          </div>
          {props.data.length > 0 ? (
            props.data.map((item: any, index: number) => (
              <div
                className="flex items-center justify-between gap-3 bg-white rounded-xl mt-4 shadow-sm md:p-[16px] p-[10px] cursor-pointer"
                key={index}
                onClick={() =>
                  navigate(`${item.transactionType}/${item.transactionId}`)
                }
              >
                {item.transactionType === "TOP_UP_CARD_BALANCE" ||
                  item.transactionType === "TOP_UP_CARD_BALANCE_MERCHANT" ? (
                  <img src={TopUpIcon} alt="" />
                ) : null}
                {item.transactionType === "WITHDRAW_CARD_BALANCE" ||
                  item.transactionType === "WITHDRAW_CARD_BALANCE_MERCHANT" ? (
                  <img src={WithdrawIcon} alt="" />
                ) : null}
                {item.transactionType === "CARD_PAYMENT_MERCHANT" ||
                  item.transactionType === "COMMERCE_FNB_PAYMENT_CARD" ||
                  item.transactionType === "PAYGATE_PAYMENT_TRANSACTION" ? (
                  <img src={CardTransactionIcon} alt="" />
                ) : null}
                {item.transactionType === "CARD_PAYMENT_GATEWAY" ? (
                  <img src={Card} alt="" />
                ) : null}
                <div className="flex flex-col w-full">
                  <h6 className="text-xs text-[#6B6B6B] font-light text-left mb-0">
                    {formatDateTimeUTC(item.createdTime)}
                  </h6>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <h6 className="md:text-base text-sm text-customBlack font-medium text-left mb-0">
                      {item.transactionType === "TOP_UP_CARD_BALANCE"
                        ? t('mutation_virtual_card.top_up_card_balance')
                        : item.transactionType ===
                          "TOP_UP_CARD_BALANCE_MERCHANT"
                          ? t('mutation_virtual_card.top_up_card_balance_via_merchant')
                          : ""}
                      {item.transactionType === "WITHDRAW_CARD_BALANCE" &&
                        t('mutation_virtual_card.card_balance_withdrawal')}
                      {item.transactionType ===
                        "WITHDRAW_CARD_BALANCE_MERCHANT" &&
                        t('mutation_virtual_card.card_balance_withdrawal_via_merchant')}
                      {item.transactionType === "CARD_PAYMENT_MERCHANT" &&
                        t('mutation_virtual_card.card_transaction')}
                      {item.transactionType === "COMMERCE_FNB_PAYMENT_CARD" &&
                        t('mutation_virtual_card.fnb_card_transactions')}
                      {item.transactionType === "PAYGATE_PAYMENT_TRANSACTION" &&
                        t('mutation_virtual_card.card_transaction_paygate')}
                      {item.transactionType === "CARD_PAYMENT_GATEWAY" &&
                        t('mutation_virtual_card.gateway_card_transactions')}
                    </h6>
                    {item.mutationType === "CREDIT" ? (
                      <h6 className="md:text-lg text-base text-green font-semibold text-left mb-0">
                        +{formatCurrency(item.amount)}
                      </h6>
                    ) : (
                      <h6 className="md:text-lg text-base text-[#DC3545] font-semibold text-left mb-0">
                        -{formatCurrency(item.amount)}
                      </h6>
                    )}
                  </div>
                  <h6 className="text-xs text-[#6B6B6B] font-light text-left mb-0">
                    {item.cardName}
                  </h6>
                </div>
              </div>
            ))
          ) : (
            <DataNotFound title={t('mutation_virtual_card.noData')} />
          )}

          {props.currentSize() >= 10 && props.totalElements > 10 ? (
            <LoadMoreButton addMore={props.addMore} />
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};

export default VirtualCardMutationWeb;
