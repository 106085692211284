import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ZakatDisbursementWeb from "./zakatDisbursementWeb";
import ZakatDisbursementMobile from "./zakatDisbursementMobile";

const ZakatDisbursement = () => {
  return (
    <>
      <BrowserView>
        <ZakatDisbursementWeb></ZakatDisbursementWeb>
      </BrowserView>
      <MobileView>
        <ZakatDisbursementMobile></ZakatDisbursementMobile>
      </MobileView>
    </>
  );
};

export default ZakatDisbursement;
