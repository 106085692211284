import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ChangeSecurityCodeWeb from "./changeSecurityCodeWeb";
import ChangeSecurityCodeMobile from "./changeSecurityCodeMobile";

const ChangeSecurityCode = () => {
  return (
    <>
      <BrowserView>
        <ChangeSecurityCodeWeb></ChangeSecurityCodeWeb>
      </BrowserView>
      <MobileView>
        <ChangeSecurityCodeMobile></ChangeSecurityCodeMobile>
      </MobileView>
    </>
  );
};

export default ChangeSecurityCode;
