import moment from "moment";
import { useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import fill from "../../assets/images/icon_features/filter.svg";
import { absenPlus } from "../../config/configIcon";
import { ConfigColor, hexToRgba } from "../../config/global";
import DataNotFound from "../_common/dataNotFound";
import Header from "../_common/headerComponent";
import LoadMoreButton from "../_common/loadMoreButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Bg from "../../assets/images/icon_features/subcriptionBg.svg";

const ParkingFeaturesWeb = (props: any) => {
  const color = ConfigColor();
  const [filter, setFilter] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <Header></Header>
      <div className="h-full m-auto pb-4 md:pt-10">
        <div className="md:w-3/5 w-full mx-auto flex gap-3 px-4">
          <button
            onClick={() => {
              // navigate("scan-qr");
              props.createQR();
            }}
            className=" text-[16px] font-semibold flex bg-opacity-10  p-2.5 rounded-lg w-5/6"
            style={{
              color: `${color.color1}`,
              backgroundColor: hexToRgba(color.color1, 0.1),
            }}
          >
            {absenPlus(color)}{" "}
            <span className="ml-2">{t("accessibility.scan_parkir")}</span>
          </button>
          <button
            onClick={() => setFilter(true)}
            className="border-1 border-gray-300 flex bg-opacity-10 p-2.5 rounded-lg w-1/6"
          >
            <img src={fill} alt="" className="mr-2 mx-auto" />
          </button>
        </div>
        <div className="md:w-3/5 w-full mx-auto mt-4 px-4 pt-4 relative bg-mobile">
          {props.config.isActive && (
            <div
              className="bg-cover bg-center rounded-[10px] p-4 flex justify-between text-white gap-2 mb-3"
              style={{
                backgroundImage: `url(${Bg})`,
              }}
              onClick={() => {
                navigate("subcription");
              }}
            >
              <div className="flex flex-col">
                <span className="text-lg font-[700]">Langganan</span>
                <span className="text-sm">
                  {props.active.status ? (
                    <div>
                      Langganan anda berlaku dari <br /> tanggal{" "}
                      {moment
                        .utc(props.active.createdDate)
                        .local()
                        .format("DD MMMM YYYY")}
                    </div>
                  ) : (
                    "Langganan lebih hemat dan lebih nyaman"
                  )}
                </span>
              </div>
              <div className="flex justify-center items-center">
                <button className="bg-[#FF9723] px-4 py-2 text-sm font-[600] rounded-[10px]">
                  Detail
                </button>
              </div>
            </div>
          )}
          <h6 className="md:text-lg text-base text-newColor font-semibold mb-3">
            {t("accessibility.history")}
          </h6>
          {props.data?.length > 0 ? (
            props.data?.map((res: any) => {
              return (
                <div
                  key={res.id}
                  onClick={() => props.goToDetail(res)}
                  className="rounded-[10px] shadow-sm text-white bg-white flex flex-col relative gap-0.5 mb-4 cursor-pointer"
                >
                  <div className="absolute flex justify-between mt-14 w-full">
                    <div className="w-7 h-7 bg-mobile rounded-full -ml-4"></div>
                    <div className="w-7 h-7 bg-mobile rounded-full -mr-4"></div>
                  </div>
                  <div className="flex justify-between items-center px-3 pt-3 border-b-[1px] border-b-[#E5E5E5] border-dotted mb-2 pb-2">
                    <div>
                      <h6 className="md:text-sm text-xs m-0 text-customBlack">
                        {res.parkingActivity?.name}
                      </h6>
                      <span className="md:text-base text-sm font-semibold text-customBlack">
                        {moment.utc(res.loginTime).local().format("HH:mm:ss")}
                      </span>
                    </div>
                    <div className="text-start">
                      <h6 className="md:text-sm text-xs font-light text-newColor m-0">
                        Status
                      </h6>
                      <div className="flex flex-col">
                        {res.status === "LOGIN" && (
                          <h6 className="md:text-lg text-base font-semibold m-0 text-[#26A69A]">
                            Checkin
                          </h6>
                        )}
                        {res.status === "LOGOUT" && (
                          <h6 className="md:text-lg text-base font-semibold m-0 text-[#FF9723]">
                            Checkout
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-3 px-3 pb-3">
                    <div className="text-start">
                      <h6 className="md:text-sm text-xs font-light text-newColor m-0">
                        {t("accessibility.parking_device")}
                      </h6>
                      <span className="md:text-sm text-xs font-medium text-customBlack">
                        {res.device?.name.substring(0, 10) + "..."}
                      </span>
                    </div>
                    <div className="flex flex-col items-center">
                      <div>
                        <h6 className="md:text-sm text-xs font-light text-newColor m-0">
                          {t("accessibility.date")}
                        </h6>
                        <span className="md:text-sm text-xs font-medium text-customBlack">
                          {moment
                            .utc(res.loginTime)
                            .local()
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="text-start">
                      <h6 className="md:text-sm text-xs font-light text-newColor m-0">
                        {t("accessibility.method")}
                      </h6>
                      <span
                        className="md:text-sm text-xs font-semibold text-customBlack"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.createQR();
                        }}
                      >
                        {res?.method === "CARD"
                          ? t("accessibility.card")
                          : res?.method === "QR_LISTEN"
                          ? t("accessibility.create_code_qr")
                          : t("accessibility.scan_qr")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <DataNotFound title={t("accessibility.noData")} />
          )}
          {props.totalElements > props.size * props.page && (
            <LoadMoreButton addMore={props.addMore} />
          )}
        </div>

        {/* Bottom Sheet Component */}
        <BottomSheet
          open={filter}
          onDismiss={() => setFilter(false)}
          className="font-montserrat"
        >
          <div className="px-4 pb-4">
            <p className="text-xl">Filter</p>
            <div className="flex flex-col mb-4 gap-2">
              <div className="flex items-center gap-3">
                <input
                  className="cursor-pointer"
                  type="radio"
                  checked={"LOGIN" === props.parkingStatus}
                  onChange={() => {
                    props.setParkingStatus("LOGIN");
                  }}
                  value=""
                />
                <p className="md:text-lg text-base text-[#252525] font-medium w-full mb-0">
                  Checkin
                </p>
              </div>
              <div className="flex items-center gap-3">
                <input
                  className="cursor-pointer"
                  type="radio"
                  checked={"LOGOUT" === props.parkingStatus}
                  onChange={() => {
                    props.setParkingStatus("LOGOUT");
                  }}
                  value=""
                />
                <p className="md:text-lg text-base text-[#252525] font-medium w-full mb-0">
                  Checkout
                </p>
              </div>
            </div>
            <div className="flex w-full gap-4">
              <button
                onClick={() => {
                  props.resetFilter();
                  props.setPage(1);
                  setFilter(false);
                }}
                className="text-gray-500 border-1 border-gray-500 w-1/2 p-2 rounded-lg"
              >
                {t("accessibility.cancel")}
              </button>
              <button
                onClick={() => {
                  props.setPage(1);
                  props.filterDate();
                  setFilter(false);
                }}
                className="w-1/2 p-2 rounded-lg text-white"
                style={{ backgroundColor: `${color.color1}` }}
              >
                {t("accessibility.save")}
              </button>
            </div>
          </div>
        </BottomSheet>
      </div>
    </section>
  );
};

export default ParkingFeaturesWeb;
