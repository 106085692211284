// import React, { useState } from "react";
import Header from "../../_common/headerComponent";
// import * as API_USER from "../../../core/service/api_user";
// // import iconSuccess from "../../assets/images/iconSuccess.png";
// import iconFailed from "../../assets/images/icon-failed.png";
// import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
// // import { setLoading } from "../../../core/feature/config/configSlice";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";
// import Company from "../../../core/models/company";

const LessonTimetableDetailWeb = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  // let navigate = useNavigate();
  // let dispatch = useAppDispatch();

  return (
    <section className="h-full min-h-screen font-poppins bg-mobile">
      <Header></Header>
    </section>
  );
};

export default LessonTimetableDetailWeb;
