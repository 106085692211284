import Header from '../_common/headerComponent';
import { ConfigColor } from '../../config/global';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as API_USER from "../../core/service/api_user";
import { setLoading } from '../../core/feature/config/configSlice';
import { useAppDispatch, useAppSelector } from '../../core/feature/hooks';
import User from '../../core/models/user';
import { setUser } from '../../core/feature/user/userSlice';
import toast from 'react-hot-toast';

function MultiLanguageMobile() {
  const user: User = useAppSelector((state) => state.user.user);
  const { t, i18n } = useTranslation();
  const color = ConfigColor();
  const [selectedLanguage, setSelectedLanguage] = React.useState(user.lang || "id");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  console.log("user", user);

  const editLanguage = () => {
    let dataOrder = {
      additionalData: user.additionalData,
      companyId: user.companyId,
      name: user.name,
      lang: selectedLanguage,
      phone: user.phone,
      dateOfBirth: user.dateOfBirth,
      username: user.username,
      noId: user.noId,
      email: user.email,
      tags: user.tags,
    }

    API_USER.updateProfile(dataOrder)
      .then((res) => {
        dispatch(setLoading(false));
        setUser({ ...user, lang: selectedLanguage });
        toast.success(t('profile.changeLanguage'));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  }

  const handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLanguage(e.target.value);
  };

  const confirmChanges = () => {
    editLanguage();
    i18n.changeLanguage(selectedLanguage);
    navigate(-1);
  };

  const languageList = [
    {
      value: "id",
      label: t('changeLanguage.indonesian'),
    },
    {
      value: "en",
      label: t('changeLanguage.english'),
    },
  ];

  return (
    <section className="h-full min-h-screen font-poppins bg-mobile flex flex-col overflow-x-hidden">
      <Header navbarText={t('changeLanguage.title')} />
      <div className="flex flex-col w-full gap-5 justify-start flex-1 mt-[0px] mx-auto pb-4 px-4">
        <div className="flex flex-col items-start">
          <h1 className="text-xl font-bold">{t('changeLanguage.title')}</h1>
          <p className="text-sm">{t('changeLanguage.description')}</p>
        </div>
        <div className="flex flex-col gap-4 flex-1">
          {languageList.map((item, index) => (
            <div key={index} className="flex items-center justify-start bg-white py-3 px-8 rounded-xl gap-2">
              <input
                type="radio"
                name="language"
                id={`language-${item.value}`}
                value={item.value}
                checked={selectedLanguage === item.value}
                className="h-4 w-4"
                onChange={handleLanguageChange}
              />
              <label htmlFor={`language-${item.value}`} className="text-primer text-sm w-full h-full">
                {item.label}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full px-4 pb-4">
        <button
          style={{
            backgroundColor: `${color?.color1}`,
          }}
          className="flex flex-row items-center justify-center cursor-pointer gap-2 h-[48px] w-full text-xl p-0.5 rounded-lg"
          onClick={confirmChanges}
        >
          <div className="w-full bg-mobile h-full rounded-lg justify-center flex">
            <div className="flex flex-row items-center w-fit gap-2 text-white">
              <h1
                style={{
                  backgroundColor: `${color?.color1}`,
                }}
                className="grow font-semibold text-base inline-block text-transparent bg-clip-text md:text-base m-0 font-poppins"
              >
                {t('changeLanguage.confirm')}
              </h1>
            </div>
          </div>
        </button>
      </div>
    </section>
  );
}

export default MultiLanguageMobile;
