import Header from "../../_common/headerComponent";
import arrow_down from "../../../assets/images/DropDown.svg";
import moment from "moment";
import { formatCurrency } from "../../../config/global";
import download from "../../../assets/images/download-document.svg";

const CommerceOrderDetailWeb = (props: any) => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile pb-4">
      <Header />

      <div className="container flex flex-col items-center md:mt-[64px] mt-[0px] px-0">
        <div className="md:w-4/5 w-full mx-auto">
          <div className="flex flex-col justify-between gap-1 px-4 py-2 mb-2 bg-white">
            <h1 className="md:text-lg text-base text-green font-medium m-0">{props.t('history.shippingAddress')}</h1>
            <span className="md:text-sm text-xs font-medium text-[#848383] capitalize">
              {props.history?.address?.name}
            </span>
            <span className="md:text-sm text-xs font-light text-[#848383]">
              {props.getAlamat(props.history?.address)}
            </span>
          </div>
          <div className="flex flex-col justify-between px-4 py-2 mb-2 bg-white">
            <div className="flex flex-row justify-between w-full">
              <h1 className="m-0 font-medium md:text-lg text-base text-green">
                {props.t('history.delivery')}
              </h1>
              <h1
                onClick={() =>
                  props.copyResi(
                    props.history?.shipperOrderId !== undefined ||
                      props.history.shipperOrderId !== ""
                      ? props.history.shipperOrderId
                      : ""
                  )
                }
                className="m-0 font-light text-base text-newColor"
              >
                {props.t('history.copyReceipt')}
              </h1>
            </div>
            <div className="flex flex-row justify-between w-full">
              <span className="font-normal md:text-base text-sm text-customBlack">
                {props.t('history.receiptNumber')} :
              </span>
              <span className="font-normal md:text-base text-sm text-customBlack">
                {props.history?.shipperOrderId !== undefined
                  ? props.history.shipperOrderId
                  : "-"}
              </span>
            </div>
          </div>
          <div className="bg-white">
            <div className="flex flex-row bg-white items-center justify-between px-4 py-2">
              <h1 className="m-0 font-medium md:text-lg text-base text-green">{props.t('history.status')}</h1>
              <span
                className={`m-0 font-medium md:text-sm text-xs rounded-lg px-2 uppercase ${props.getStatus(props.history.status).textColor
                  } py-1.5 ${props.getStatus(props.history.status).bgColor} ]`}
              >
                {props.getStatus(props.history.status).text}
              </span>
            </div>
            <hr className="m-1 mx-4 border-[0.5px] border-[#8d8d8d]" />
            <div className="px-4 py-2 flex flex-col gap-3">
              <div className="flex flex-row justify-between items-center">
                <h1 className="md:text-base text-sm font-normal m-0">{props.t('history.orderDate')}</h1>
                <span className="md:text-sm text-xs font-medium text-[#848383]">
                  {moment(props.history?.orderCreatedDate).format("LL")}
                </span>
              </div>
              <div className="flex flex-row justify-between  items-center">
                <h1 className="md:text-base text-sm font-normal m-0">{props.t('history.transactionId')}</h1>
                <span className="md:text-sm text-xs font-medium text-[#848383]">
                  {props.history?.transactionId}
                </span>
              </div>
              <div className="flex flex-row justify-between items-center">
                <h1 className="md:text-base text-sm font-normal m-0">{props.t('history.optionDelivery')}</h1>
                <span className="md:text-sm text-xs font-medium text-[#848383]">
                  {props.history?.ship?.name}
                </span>
              </div>
              {props.history?.orderReceiver !== undefined && (
                <div className="flex flex-col gap-3">
                  <h1 className="m-0 font-medium md:text-lg text-base text-green">{props.t('history.orderDataReceiver')}</h1>
                  <div className="flex flex-row justify-between items-center">
                    <h1 className="md:text-base text-sm font-normal m-0">{props.t('history.name')}</h1>
                    <span className="md:text-sm text-xs font-medium text-[#848383]">
                      {props.history?.orderReceiver?.name}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <h1 className="md:text-base text-sm font-normal m-0">{props.t('history.address')}</h1>
                    <span className="md:text-sm text-xs font-medium text-[#848383]">
                      {props.history?.orderReceiver?.address}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <h1 className="md:text-base text-sm font-normal m-0">{props.t('history.phone')}</h1>
                    <span className="md:text-sm text-xs font-medium text-[#848383]">
                      {props.history?.orderReceiver?.phone}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex flex-row justify-between items-center">
                <h1 className="md:text-base text-sm font-normal m-0">
                  {props.marketplaceConfig.merchantName}
                </h1>
                <span className="md:text-sm text-xs font-medium text-[#848383] capitalize">
                  {props.history?.merchant?.name}
                </span>
              </div>
              <div className="flex flex-col justify-between">
                <h1 className="md:text-lg text-base text-green font-medium m-0">
                  {props.marketplaceConfig.productName}
                </h1>
                {props.uniqueProduct?.map((product: any, i: number) => (
                  <div>
                    {props.products[product.productId].map(
                      (item: any, index: string) => (
                        <div key={index}>
                          <div className="flex flex-row justify-between items-center">
                            <div>
                              <h1 className="mt-2 font-semibold md:text-sm text-xs text-[#9A9999]">
                                {item.productName}
                              </h1>
                            </div>
                            <div
                              className="flex flex-row gap-1 items-center"
                              onClick={() => props.collapseProduct(item.itemId)}
                            >
                              <span className="font-light text-[10px] text-[#9A9999]">
                                {props.t('history.more')}{" "}
                                {!props.currentIndex[item.itemId]
                                  ? props.t('history.complete')
                                  : props.t('history.little')}
                              </span>
                              <img
                                src={arrow_down}
                                alt=""
                                className={
                                  !props.currentIndex[item.itemId]
                                    ? "w-2 h-2"
                                    : "transform rotate-180 w-2 h-2"
                                }
                              />
                            </div>
                          </div>
                          <ul
                            className="list-disc"
                            hidden={!props.currentIndex[item.itemId]}
                          >
                            <li>
                              <div className="flex flex-row justify-between items-end md:text-sm text-xs font-light text-[#9A9999]">
                                <div className="flex flex-col">
                                  <span>
                                    {item.itemName} {item.quantity}X
                                  </span>
                                  <span>
                                    {item.quantity}X {formatCurrency(item.price)}
                                  </span>
                                </div>
                                <span>
                                  {" "}
                                  {formatCurrency(item.price * item.quantity)}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )
                    )}
                    {product?.productNoteVOS?.length > 0 && (
                      <div className="flex flex-col gap-2 items-center">
                        <h1 className="m-0 font-semibold md:text-base text-sm">
                          {props.t('history.form')} {product.productName}
                        </h1>
                        <button
                          onClick={() => props.selectForm(product?.productId)}
                          className="font-light md:text-base text-sm text-green w-fit"
                        >
                          {props.t('history.viewForm')}
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <hr className="my-2 border-[0.1px] border-[#8d8d8d]" />

              <div className="flex flex-col gap-1">
                <span className="md:text-base text-sm font-semibold">{props.t('history.note')}</span>
                <input
                  type="text"
                  value={props.history.note}
                  disabled={true}
                  className="disabled:bg-[#fff] px-2 py-2.5 capitalize md:text-base text-sm border-[0.5px] border-solid bg-[#fff] rounded-lg border-[#C4C4C4]"
                />
              </div>

              <div
                hidden={
                  props.history?.status === "CONFIRMED"
                    ? false
                    : props.history?.status === "SENT"
                      ? false
                      : true
                }
                className="flex flex-col gap-1"
              >
                <span className="md:text-base text-sm font-normal">{props.t('history.merchantNote')}</span>
                <input
                  type="text"
                  value={props.history.merchantNote}
                  disabled={true}
                  className="disabled:bg-[#fff] px-2 py-2.5 md:text-base text-sm border-[0.5px] border-solid bg-[#fff] rounded-lg border-[#C4C4C4]"
                />
              </div>

              <hr className="my-3 border-[0.1px] border-[#8d8d8d]" />

              {props.history?.universalNotes?.length > 0 && (
                <div className="flex flex-col gap-2 mt-1 pb-3 ">
                  <h1 className="m-0 font-semibold md:text-base text-sm">
                    {props.t('history.generalOrderForm')}
                  </h1>
                  <button
                    onClick={() => props.setShowForm("universalNotes")}
                    className="font-light md:text-base text-sm text-green"
                  >
                    {props.t('history.viewForm')}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col bg-white px-4 py-3 mt-2 gap-2">
            <div className="flex flex-row justify-between items-center">
              <h1 className="md:text-base text-sm font-semibold m-0">{props.t('history.orderSubtotal')}</h1>
              <span className="md:text-sm text-xs font-medium text-[#848383]">
                {formatCurrency(props.history.amountProduct)}
              </span>
            </div>
            <div className="flex flex-row justify-between items-center">
              <h1 className="md:text-base text-sm font-semibold m-0">{props.t('history.shippingCost')}</h1>
              <span className="md:text-sm text-xs font-medium text-[#848383]">
                {formatCurrency(props.history.amountDelivery)}
              </span>
            </div>
            <div className="flex flex-row justify-between items-center">
              <h1 className="md:text-base text-sm font-semibold m-0">{props.t('history.serviceFee')}</h1>
              <span className="md:text-sm text-xs font-medium text-[#848383]">
                {formatCurrency(props.history.amountCharge)}
              </span>
            </div>
            <div className="flex flex-row justify-between px-2 py-2 rounded-md bg-[#CFEAE8] text-white">
              <h1 className="md:text-sm text-xs font-semibold text-green m-0">{props.t('history.totalOrder')}</h1>
              <span className="md:text-sm text-xs font-semibold text-green">
                {formatCurrency(props.history.amountTotal)}
              </span>
            </div>
          </div>
          <div className="flex flex-col md:px-[0px] px-[1.5rem] gap-2 pb-4">
            <button
              onClick={() => props.downloadDoc(props.history.id)}
              className="my-3 flex flex-row mt-4 gap-1 justify-center border-[1px] border-newColor text-newColor px-3 py-2.5 w-full  text-base font-medium rounded-lg"
            >
              <img src={download} alt="" />
              {props.t('history.download')}
            </button>
            <button
              hidden={props.history?.status === "CONFIRMED" ? false : true}
              onClick={() =>
                props.navigate(
                  `/${props.company?.initial}/commerce/products/detail/${props.history?.products[0]?.productId}`
                )
              }
              style={{ backgroundColor: `${props.company?.color?.color1 !== undefined ? props.company?.color?.color1 : "#26A69A"}` }}
              className="bg-green px-3 py-2.5 w-full text-white text-base font-medium rounded-lg"
            >
              {props.t('history.buyAgain')}
            </button>
            <button
              hidden={props.history?.status === "CREATED" ? false : true}
              onClick={() => props.updateStatus(props.history.id, "CANCELED")}
              className="border-[1px] border-newColor text-newColor px-3 py-2.5 w-full text-base font-medium rounded-lg"
            >
              {props.t('history.cancelOrder')}
            </button>
            <button
              hidden={props.history?.status === "SENT" || props.history?.status === "AT_PICKUP_POINT" ? false : true}
              onClick={() => props.updateStatus(props.history.id, "CONFIRMED")}
              className="border-[1px] border-newColor text-newColor px-3 py-2.5 w-full text-base font-medium rounded-lg"
            >
              {props.t('history.confirmOrder')}
            </button>
            <button
              onClick={() =>
                props.checkout(props.history?.checkoutLink, props.history.transactionId)
              }
              hidden={props.history?.status === "WAITING_PAYMENT" ? false : true}
              style={{ backgroundColor: `${props.company?.color?.color1 !== undefined ? props.company?.color?.color1 : "#26A69A"}` }}
              className="bg-green px-3 py-2.5 w-full text-white text-base font-medium rounded-lg"
            >
              {props.t('history.payNow')}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${props.showForm === "" ? "hidden" : "block"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 p-3">
              <button
                type="button"
                onClick={() => props.setShowForm("")}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="p-3">
                <h5 className="md:text-base text-sm font-bold mb-4">
                  {props.showForm === "universalNotes" && "Formulir Umum Pemesanan"}
                  {props.showForm === "product" &&
                    `Formulir ${props.selectedProductNotes?.productName}`}
                </h5>
                <form className="space-y-6" action="#">
                  {props.showForm === "universalNotes" &&
                    props.history?.universalNotes?.map((el: any, index: number) => (
                      <div className="px-2 mb-2 text-start" key={index}>
                        <label htmlFor="">{el.key}</label>
                        <input
                          className="w-full border-[1px] border-solid border-[#C4C4C4] bg-[#D9D9D9] rounded-md p-2"
                          value={el.value}
                          readOnly
                          disabled
                        />
                      </div>
                    ))}
                  {props.showForm === "product" &&
                    props.selectedProductNotes?.productNoteVOS.map(
                      (el: any, index: number) => (
                        <div className="px-2 mb-2 text-start" key={index}>
                          <label htmlFor="">{el.name}</label>
                          <input
                            className="w-full border-[1px] border-solid border-[#C4C4C4] bg-[#D9D9D9] rounded-md p-2"
                            value={el.note}
                            readOnly
                            disabled
                          />
                        </div>
                      )
                    )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommerceOrderDetailWeb;