import React, { useState } from "react";
import Header from "../../_common/headerComponent";
// import * as API_USER from "../../../core/service/api_user";
// import user from "../../assets/images/userNew.png";
// import lesson from "../../../assets/images/icon_siakad/lesson.svg";
// import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
// import Company from "../../../core/models/company";
// import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
// import moment from "moment";
import { Tab } from "@headlessui/react";

import time from "../../../assets/images/icon_siakad/time.svg";
import profile from "../../../assets/images/icon_siakad/profile.svg";
import book from "../../../assets/images/icon_siakad/book.svg";
import pen from "../../../assets/images/icon_siakad/pen.svg";
const LessonTimetableDetailMobile = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  // let navigate = useNavigate();
  // let dispatch = useAppDispatch();
  let [categories] = useState({
    Senin: [
      {
        classRoom: "XI IPA 2",
        mapel: "Matematika",
        time: "08.00-10.00",
        teacher: "Bu Umay, S.Pd",
      },
      {
        classRoom: "XI IPA 2",
        mapel: "Bahasa Indonesia",
        time: "10.00-12.00",
        teacher: "Bu Umay, S.Pd",
      },
    ],
    Selasa: [
      {
        classRoom: "XI IPA 2",
        mapel: "Matematika",
        time: "08.00-10.00",
        teacher: "Bu Umay, S.Pd",
      },
      {
        classRoom: "XI IPA 2",
        mapel: "Bahasa Indonesia",
        time: "10.00-12.00",
        teacher: "Bu Umay, S.Pd",
      },
    ],
    Rabu: [
      {
        classRoom: "XI IPA 2",
        mapel: "Matematika",
        time: "08.00-10.00",
        teacher: "Bu Umay, S.Pd",
      },
      {
        classRoom: "XI IPA 2",
        mapel: "Bahasa Indonesia",
        time: "10.00-12.00",
        teacher: "Bu Umay, S.Pd",
      },
    ],
    Kamis: [
      {
        classRoom: "XI IPA 2",
        mapel: "Matematika",
        time: "08.00-10.00",
        teacher: "Bu Umay, S.Pd",
      },
      {
        classRoom: "XI IPA 2",
        mapel: "Bahasa Indonesia",
        time: "10.00-12.00",
        teacher: "Bu Umay, S.Pd",
      },
    ],
    Jumat: [
      {
        classRoom: "XI IPA 2",
        mapel: "Matematika",
        time: "08.00-10.00",
        teacher: "Bu Umay, S.Pd",
      },
      {
        classRoom: "XI IPA 2",
        mapel: "Bahasa Indonesia",
        time: "10.00-12.00",
        teacher: "Bu Umay, S.Pd",
      },
    ],
    Sabtu: [
      {
        classRoom: "XI IPA 2",
        mapel: "Matematika",
        time: "08.00-10.00",
        teacher: "Bu Umay, S.Pd",
      },
      {
        classRoom: "XI IPA 2",
        mapel: "Bahasa Indonesia",
        time: "10.00-12.00",
        teacher: "Bu Umay, S.Pd",
      },
    ],
  });
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Jadwal Pelajaran"></Header>

      <div className="flex flex-col px-6 py-2 gap-2">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-lg bg-[#26A69A] p-1">
            {Object.keys(categories).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-xs font-normal leading-5",
                    " focus:outline-none ",
                    selected
                      ? "bg-white font-bold text-[#26A69A]"
                      : "hover:bg-white/[0.12] hover:text-white text-white"
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {Object.values(categories).map((posts, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  "rounded-xl  ",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                )}
              >
                <div className="flex flex-col gap-3">
                  {posts.map((item) => (
                    <div className=" relative items-start shadow-xl  shadow-[#000]/5 flex flex-col bg-white  mt-2 p-3 rounded-lg">
                      <h1 className="font-light text-[10px] text-left text-[#9A9999]">
                        {item.classRoom}
                      </h1>
                      <hr className="my-1 h-[0.5px]  bg-[#929292]" />
                      <div>
                        <h1 className="font-semibold text-xs  text-left">
                          {item.mapel}
                        </h1>
                        <div className="flex flex-row  gap-2 mt-1">
                          <img src={time} alt="" className="h-3 w-3" />
                          <span className="font-light text-[10px] text-[#9A9999]">
                            {item.time}
                          </span>
                        </div>
                        <div className="flex flex-row gap-2 mt-1">
                          <img src={profile} alt="" className="h-3 w-3" />
                          <span className="font-light text-[10px] text-[#9A9999]">
                            {item.teacher}
                          </span>
                        </div>
                      </div>

                      <img
                        src={book}
                        alt=""
                        className="absolute bottom-0 right-8"
                      />
                      <img
                        src={pen}
                        alt=""
                        className="absolute bottom-0 right-28"
                      />
                    </div>
                  ))}
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </section>
  );
};

export default LessonTimetableDetailMobile;
