/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { BrowserView, MobileView } from "react-device-detect";
import QRCode from "react-qr-code";
import { useLocation, useParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import * as API_ABSENSI from "../../core/service/api_absensi.js";
import QrisIcon from "../../assets/images/icon/qris.svg";
import ScanCardIcon from "../../assets/images/icon/scancard.svg";
import { ConfigColor } from "../../config/global.js";
import { useAppDispatch } from "../../core/feature/hooks";
import PayGateFeaturesDetailMobile from "./payGateFeaturesDetailMobile";
import PayGateFeaturesDetailWeb from "./payGateFeaturesDetailWeb";
import { setLoading } from "../../core/feature/config/configSlice";
import toast from "react-hot-toast";

const PayGateFeaturesDetail = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [data, setData] = useState<any>([]);
  let location: any = useLocation();

  return (
    <>
      <BrowserView>
        <PayGateFeaturesDetailWeb data={location.state.data} />
      </BrowserView>
      <MobileView>
        <PayGateFeaturesDetailMobile data={location.state.data} />
      </MobileView>
    </>
  );
};

export default PayGateFeaturesDetail;
