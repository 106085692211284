import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import AttendanceDetailMobile from "./attendanceDetailMobile";
import AttendanceDetailWeb from "./attendanceDetailWeb";

const AttendanceDetail = () => {
  return (
    <>
      <BrowserView>
        <AttendanceDetailWeb></AttendanceDetailWeb>
      </BrowserView>
      <MobileView>
        <AttendanceDetailMobile></AttendanceDetailMobile>
      </MobileView>
    </>
  );
};

export default AttendanceDetail;
