import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ZakatProgressWeb from "./zakatProgressWeb";
import ZakatProgressMobile from "./zakatProgressMobile";

const ZakatProgress = () => {
  return (
    <>
      <BrowserView>
        <ZakatProgressWeb></ZakatProgressWeb>
      </BrowserView>
      <MobileView>
        <ZakatProgressMobile></ZakatProgressMobile>
      </MobileView>
    </>
  );
};

export default ZakatProgress;
