import React, { useEffect, useState } from "react";
import * as API_USER from "../../core/service/api_user";
import {} from "../../core/feature/store";
import { useAppDispatch } from "../../core/feature/hooks";
import {
  // removeCompany,
  setCompany,
} from "../../core/feature/company/companySlice";
import closepayLogo from "../../assets/images/solusinegeri-logo.svg";
import emptyImg from "../../assets/images/empty-img.svg";

// import Company from "../../core/models/company";
import {
  setIsEverChooseCompany,
  setLoading,
} from "../../core/feature/config/configSlice";
import { useNavigate } from "react-router-dom";
import desktopBackground from "../../assets/images/mobileBgBlur.svg";
import BottomLogo from "../_common/bottomLogo";

const ChangeCompanyMobile = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  const [companyInput, setCompanyInput] = useState("");
  const [companyData, setCompanyData] = useState<any>({});
  const [showCompany, setShowCompany] = useState(false);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const checkCompany = async () => {
    dispatch(setLoading(true));
    await API_USER.getCompany(companyInput)
      .then((res) => {
        dispatch(setLoading(false));
        setCompanyData(res.data.data);
        setShowCompany(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setCompanyData({});
        setShowCompany(false);
      });
  };

  const confirmCompany = async () => {
    dispatch(setCompany(companyData));
    console.log(companyData);
    navigate(`/${companyData?.initial}`, { replace: true });
  };
  // let fcmtoken = localStorage.getItem("fcm_token");
  useEffect(() => {
    dispatch(setIsEverChooseCompany(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="h-full min-h-screen bg-second dark:bg-black font-poppins flex flex-col justify-center items-center bg-cover"
        style={{ backgroundImage: `url(${desktopBackground})` }}
      >
        <div className="p-6">
          <div>
            <img src={closepayLogo} alt="icon" className="w-52 mx-auto" />
          </div>
          <div className="mt-10 mb-6">
            <h4 className="text-left text-base font-bold">Pilih Instansi</h4>
            <p className="text-left text-[#848383] text-sm">
              Silahkan masukkan kode instansi anda
            </p>
          </div>
          <div className="flex flex-row w-full gap-2">
            <input
              type="text"
              onChange={(e) => setCompanyInput(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" && checkCompany();
              }}
              className="px-2 rounded-full w-3/4 p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25"
            />
            <button
              onClick={() => checkCompany()}
              className="w-1/4 p-[10px] bg-emerald-400 dark:bg-slate-900 bg-[#26A69A] rounded-full text-center text-white"
            >
              Cari
            </button>
          </div>
          <div hidden={!showCompany} className="mt-6">
            <h1 className="font-medium text-base text-thirdy">
              Instansi yang sesuai
            </h1>
            <div className="flex flex-row gap-4 items-center mt-3">
              {companyData.companyLogo !== null ? (
                <img src={companyData.companyLogo} alt="" className="h-16" />
              ) : (
                <img src={emptyImg} alt="" className="h-16" />
              )}
              <h1 className="m-0 font-normal text-sm italic text-thirdy">
                {companyData.name}
              </h1>
            </div>
            <button
              onClick={() => confirmCompany()}
              className="mt-3 w-full bg-[#26A69A] hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:bg-emerald-500 focus:ring-opacity-75 rounded-full py-[10px] text-white"
            >
              Konfirmasi
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeCompanyMobile;
