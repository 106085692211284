/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import RentDetailMobile from "./rentMobile";
import RentDetailWeb from "./rentWeb";

const Rent = () => {
  React.useEffect(() => {}, []);

  const tagsOptions = ["Sepakbola", "Tennis", "Gym", "Badminton"];

// Fungsi untuk mendapatkan URL gambar berdasarkan tags
const getImageUrlByTags = (tags:any) => {
  switch (tags) {
    case "Sepakbola":
      return "https://lapanganfutsal.id/wp-content/uploads/2023/01/flamboyan-futsal-lapangan-futsal-pekanbaru-1.jpg";
    case "Tennis":
      return "https://asset.kompas.com/crops/pPWVvdkuG9NYna7kCDWOuyiozsc=/0x0:1000x667/750x500/data/photo/2018/02/03/1668836006.jpg"; // Ganti dengan URL gambar tennis yang sesuai
    case "Gym":
      return "https://asset.kompas.com/crops/MUiHPEJKwJknhjbHQTINeA3BkTI=/0x0:0x0/1200x800/data/photo/2021/05/08/60961de48b31a.jpg"; // Ganti dengan URL gambar gym yang sesuai
    case "Badminton":
      return "https://student-activity.binus.ac.id/badminton/wp-content/uploads/sites/65/2024/01/S__13672454.jpg"; // Ganti dengan URL gambar badminton yang sesuai
    default:
      return "https://lapanganfutsal.id/wp-content/uploads/2023/01/flamboyan-futsal-lapangan-futsal-pekanbaru-1.jpg"; // URL gambar default jika tidak ada tags yang cocok
  }
};
let list =[]
// Array baru dengan panjang 20


// Mendapatkan tanggal awal dan akhir bulan ini
const now = new Date();
const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

// Fungsi untuk mendapatkan tanggal acak antara dua tanggal
const getRandomDate = (start:any, end:any) => {
  const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  return date.toISOString().split('T')[0];
};

// Membuat data
const dataPerDate = 5;
const totalDays = (endDate.getDate() - startDate.getDate() + 1);

for (let i = 0; i < totalDays; i++) {
  for (let j = 0; j < dataPerDate; j++) {
    const randomTags = tagsOptions[j % tagsOptions.length];
    const date = getRandomDate(startDate, endDate);
    const imgUrl = getImageUrlByTags(randomTags);

    list.push({
      name: `Lapangan ${String.fromCharCode(65 + j)}`, // Nama lapangan dari A sampai E setiap tanggal
      count: 8, // Contoh nilai count
      tags: randomTags,
      date: date,
      countUse: 5, // Contoh nilai countUse
      img: imgUrl,
      lisClock: [ // Contoh lisClock
        { start: "07:00", end: "09:00", price: 10000, duration: 60 },
        { start: "09:00", end: "10:00", price: 10000, duration: 60 },
        { start: "11:00", end: "12:00", price: 10000, duration: 60 },
        { start: "20:00", end: "21:00", price: 10000, duration: 60 },
      ],
    });
  }
}



  const Dummy: any = [
    {
      id: "1",
      name: "Closepay Sport Center",
      address: "Salatiga, Jawa Tengah",
      quota: 500,
      tags: ["Sepakbola", "Tennis", "Gym", "Badminton"],
      price: "16000",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      image: [
        "https://cdn.idntimes.com/content-images/community/2020/09/lapangan-futsal-94db8dd85c516f7bffe3836900ae1e6e_600x400.jpg",
        "https://storage.googleapis.com/narasi-production.appspot.com/production/medium/1690969302471/ukuran-lapangan-futsal-panduan-lengkap-memahami-pentingnya-standar-dimensi-arena-medium.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaoIccHsK66ybmwTzSKb8itHVh1WDJcUP3jQ&s",
        "https://lapanganfutsal.id/wp-content/uploads/2023/01/flamboyan-futsal-lapangan-futsal-pekanbaru-1.jpg",
      ],
      listOf:list,
    },
    {
      id: "2",
      name: "TKI Sport Center",
      address: "Semarang, Jawa Tengah",
      quota: 500,
      tags: ["Sepakbola", "Tennis", "Gym", "Badminton"],
      price: "16000",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      image: [
        "https://asset.kompas.com/crops/pPWVvdkuG9NYna7kCDWOuyiozsc=/0x0:1000x667/750x500/data/photo/2018/02/03/1668836006.jpg",
      ],
      listOf:list,
    },
  ];

  

  return (
    <>
      <BrowserView>
        <RentDetailWeb  Dummy={Dummy}/>
      </BrowserView>
      <MobileView>
        <RentDetailMobile Dummy={Dummy}/>
      </MobileView>

      {/* Modal */}

      {/* --- */}
    </>
  );
};

export default Rent;
