import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationHistoryDetailWeb from "./donationHistoryDetailWeb";
import DonationHistoryDetailMobile from "./donationHistoryDetailMobile";

const DonationHistory = () => {
  return (
    <>
      <BrowserView>
        <DonationHistoryDetailWeb></DonationHistoryDetailWeb>
      </BrowserView>
      <MobileView>
        <DonationHistoryDetailMobile></DonationHistoryDetailMobile>
      </MobileView>
    </>
  );
};

export default DonationHistory;
