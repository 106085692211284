import { useNavigate } from "react-router-dom";
import Header from "../../_common/headerComponent";
import { SummaryCard } from "../../_common/summaryCard";
import { useTranslation } from "react-i18next";

const WithdrawCardPaymentSummaryMobile = (props: any) => {
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat md:bg-mobile bg-[#FFFFFF]">
      <Header navbarText={t("withdraw_funds.payment_success")} />
      <div className="flex md:flex-row flex-col-reverse justify-center gap-4 md:mt-[64px] mt-[0px] md:px-4 px-0 md:pb-28">
        <div className="lg:w-1/2 md:w-2/3 w-full">
          <SummaryCard
            data={props.dataPost}
            headerProperty={t("withdraw_funds.disbursement")}
            headerValue={props.data.total}
            isSuccess={true}
          />
        </div>
      </div>
      <div
        className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <button
          type="button"
          onClick={() => navigate(-1)}
          style={{ backgroundColor: `${props.company?.color?.color1 !== undefined ? props.company?.color?.color1 : "#26A69A"}` }}
          className=" text-white font-medium rounded-[10px] w-100 md:p-3 p-[12px]"
        >
          {t("virtual_card.done")}
        </button>
      </div>
    </section>
  );
};

export default WithdrawCardPaymentSummaryMobile;