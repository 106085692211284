import { BrowserView, MobileView } from "react-device-detect";
import TripAndTourBarcodeWeb from "./tripAndTourBarcodeWeb";
import TripAndTourBarcodeMobile from "./tripAndTourBarcodeMobile";
import { useLocation } from "react-router-dom";

const TripAndTourBarcode = () => {
  const location:any = useLocation()
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = location.state.data.barcode;
    link.download = "barcode-tour" || 'image'; // You can customize the default file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <BrowserView>
        <TripAndTourBarcodeWeb data={location.state} donwload={handleDownload}/>
      </BrowserView>
      <MobileView>
        <TripAndTourBarcodeMobile data={location.state} donwload={handleDownload}/>
      </MobileView>
    </div>
  );
};

export default TripAndTourBarcode;
