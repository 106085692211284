// import arrow_left from "../../assets/images/icon_transfer/arrow_left_icon.svg";
import { useNavigate } from "react-router-dom";
// import pattern from "../../assets/images/icon_balance/pattern.svg";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrow from "../../assets/images/arrow-left-ic.svg";
import NoImage from "../../assets/images/empty.jpg";
import Search from "../../assets/images/icon_tripAndTour/Search.svg";
import History from "../../assets/images/icon_tripAndTour/hitory.svg";
import { formatCurrency } from "../../config/global";
import LoadMoreButton from "../_common/loadMoreButton";
import Location from "../../assets/images/icon_tripAndTour/location.svg";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
import DataNotFound from "../_common/dataNotFound";

const TripAndTourMobile = (props: any) => {
  const navigate = useNavigate();
  const company: Company = useAppSelector((state) => state.company.company);
  const imageOnError = (event: any) => {
    event.currentTarget.src = NoImage;
    event.currentTarget.className = `${event.currentTarget.className} error`;
  };

  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <section
        id="header-app"
        className="flex flex-tow items-center z-50 px-4 py-3 mb-7"
      >
        <div
          onClick={async () => {
            navigate(-1);
          }}
          className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>

        <h1 className={`font-montserrat font-bold text-base m-auto relative`}>
          Wisata
        </h1>
        <div
          onClick={async () => {
            navigate("history");
          }}
          className="flex rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img src={History} className="m-auto" alt="" />
        </div>
      </section>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div className="md:w-3/5 w-full mx-auto">
          <div className="relative">
            <input
              type="text"
              className="bg-[#FAFAFA] w-full rounded-[18px] py-2.5 pl-12 pr-4 focus:outline-none"
              value={props.filtername}
              placeholder="Cari Produk..."
              onChange={(e) => props.setFiltername(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" && props.filter();
                console.log(e);
              }}
            />
            <img src={Search} alt="" className="absolute top-0 mt-2.5 left-4" />
            {props.filtername && (
              <FontAwesomeIcon
                icon={faX}
                className="absolute right-4 top-3 cursor-pointer"
                onClick={props.resetFilter}
              />
            )}
          </div>
          <div className="w-full mt-4">
            <div>
              <h6 className="font-[600] text-[1.25rem]">Rekomendasi</h6>
              {props?.data?.length > 0 ? (
                <div className="grid grid-cols-2 gap-2">
                  {props.data.map((el: any, index: any) => {
                    return (
                      <div
                        className="flex flex-col h-full cursor-pointer"
                        onClick={() => navigate(`${el.id}`)}
                      >
                        <img
                          src={
                            el?.imageUrl[0] === "" || el?.imageUrl === null
                              ? NoImage
                              : el?.imageUrl[0]
                          }
                          alt=""
                          onError={imageOnError}
                          className="object-center select-none overflow-hidden rounded-t-lg"
                          style={{ width: "100%", height: 140 }}
                        />
                        <div className="mt-2 flex flex-col justify-between flex-grow">
                          <span className="text-[#263339] font-[500]">
                            {el.productName}
                          </span>
                          <div className="flex flex-wrap gap-2  mt-2 mb-0">
                            <div className="text-[#408D86] font-[700]">
                              {formatCurrency(el.price)}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <DataNotFound title={"Data Tidak Ditemukan"} />
              )}
              {props.currentSize() >= 10 && props.totalElements > 10 ? (
                <LoadMoreButton addMore={props.addMore} />
              ) : (
                ""
              )}
            </div>
            <div className="mt-4">
              <h6 className="font-[600] text-[1.25rem]">Merchant</h6>
              {props?.dataMerchant?.length > 0 ? (
                <div>
                  {props?.dataMerchant?.map((el: any, index: any) => {
                    return (
                      <div
                        className="mt-2 flex gap-2"
                        key={index}
                        onClick={() =>
                          navigate(
                            `/${company.initial}/trip-tour/merchant/${el.id}`
                          )
                        }
                      >
                        <img
                          src={el.profileImage ? el.profileImage : NoImage}
                          alt=""
                          className="rounded-lg object-cover md:w-36 md:h-36 h-36 w-36"
                          onError={imageOnError}
                        />
                        <div className="flex flex-col">
                          <div className="">
                            <span className="text-[#263339] font-[500]">
                              {el.name}
                            </span>
                            <div className="flex items-start gap-2">
                              <img src={Location} alt="" className="" />
                              <div className="text-[#728F9E] text-xs">
                                {el?.location ? el?.location.city.name : "-"}
                              </div>
                            </div>
                          </div>
                          <div className="text-[#728F9E] text-xs mt-2">
                            {el.merchantDescription
                              ? el?.merchantDescription?.length < 80
                                ? el?.merchantDescription
                                : el?.merchantDescription
                                    ?.toString()
                                    .substring(0, 80) + "..."
                              : ""}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <DataNotFound title={"Data Tidak Ditemukan"} />
              )}
              {props.currentSizeMerchant() >= 10 &&
              props.totalElementsMerchant > 10 ? (
                <LoadMoreButton addMore={props.addMoreMerchant} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <BottomLogo /> */}
    </section>
  );
};

export default TripAndTourMobile;
