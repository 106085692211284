import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ScanBarcodeMobile from "./scanBarcodeMobile";
import ScanBarcodeWeb from "./scanBarcodeWeb";

const ScanBarcode = () => {
  return (
    <>
      <BrowserView>
        <ScanBarcodeWeb></ScanBarcodeWeb>
      </BrowserView>
      <MobileView>
        <ScanBarcodeMobile></ScanBarcodeMobile>
      </MobileView>
    </>
  );
};

export default ScanBarcode;
