import React, { FC } from "react";
import closepayLogo from "../../assets/images/solusinegerinew.svg";

interface BottomLogoProps {
  width?: string;
}

const BottomLogo: FC<BottomLogoProps> = (props): JSX.Element => {
  return (
    <div
      className="absolute left-[50%] bottom-0 flex flex-col gap-1 items-center py-2"
      style={{ transform: "translate(-50%, -30%)" }}
    >
      <p
        className="text-[11px] font-light text-center mb-0"
        style={{ color: "#8F8F8F" }}
      >
        Powered By
      </p>
      <img
        src={closepayLogo}
        alt="icon"
        className={`${props.width ? `w-[${props.width}]` : "w-16"} mx-auto`}
      />
    </div>
  );
};

export default BottomLogo;
