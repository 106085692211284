/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import Header from "../../_common/headerComponent";
import { useAppSelector } from "../../../core/feature/hooks";
import { formatCurrency } from "../../../config/global";
import ArrowLeft from "../../../assets/images/arrow_right_2.svg";
import { toast } from "react-hot-toast";
import Delete from "../../../assets/images/Delete(gray).svg";
import Edit from "../../../assets/images/Edit(gray).svg";
import "react-spring-bottom-sheet/dist/style.css";
import { MarketplaceConfig } from "../../../core/models/user";
import DataNotFound from "../../_common/dataNotFound";
import Company from "../../../core/models/company";
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

const Icon2 = (data: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${data.open[data.id] ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

const CreateOrderWeb = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const marketplaceConfig: MarketplaceConfig = useAppSelector(
    (state) => state.marketplaceConfig.marketplaceConfig
  );
  const location: any = useLocation();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />

      {/* LIST ADDRESS MODAL */}
      <Transition appear show={props.addressModal === "list"} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-montserrat"
          onClose={() => {
            props.setAddressModal("");
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="w-full mx-auto mt-3">
                    {props.addressData?.length > 0 ? (
                      <Fragment>
                        <div className="flex flex-col items-start">
                          <h5 className="md:text-xl text-lg font-bold">
                            {t('create_order.select_address')}
                          </h5>
                          <button
                            type="button"
                            onClick={() => {
                              props.setAddressModal("edit");
                              props.getProvinces();
                            }}
                            style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                            className="bg-green p-[10px] text-white rounded-[10px] w-auto mb-4"
                          >
                            {t('create_order.add_new_address')}
                          </button>
                        </div>
                        {props.addressData?.map((item: any) => (
                          <div
                            className="no-underline bg-white w-full flex gap-3 mb-3 md:py-[1rem] py-3 md:px-7 px-6 shadow-sm relative border"
                            key={item?.addressId}
                            onClick={() => props.setSelectedAddress(item)}
                          >
                            {props.selectedAddress.addressId !== undefined ? (
                              <input
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                                type="radio"
                                name={item.addressId}
                                value={item.name}
                                checked={
                                  props.selectedAddress.addressId === item.addressId
                                }
                                onChange={(e) => props.setSelectedAddress(item)}
                              />
                            ) : (
                              <input
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input"
                                type="radio"
                                name={item.addressId}
                                value={item.name}
                                checked={item.mainAddress}
                                onChange={(e) => props.setSelectedAddress(item)}
                              />
                            )}
                            <div className="w-full">
                              <div className="space-between gap-3">
                                <h6 className="md:text-base text-sm text-customBlack font-semibold mb-0">
                                  {item?.name}
                                </h6>
                                <div className="flex items-center gap-3">
                                  <button
                                    type="button"
                                    onClick={() => props.deleteAddress(item)}
                                    className="md:text-base text-sm text-[#16C894] font-light underline"
                                  >
                                    <img src={Delete} alt="delete" />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => props.editAddress(item)}
                                    className="md:text-base text-sm text-[#16C894] font-light underline relative z-50"
                                  >
                                    <img src={Edit} alt="edit" />
                                  </button>
                                </div>
                              </div>
                              <div className="space-between gap-3 my-3">
                                <h6 className="md:text-base text-sm text-customBlack font-medium mb-0">
                                  {item?.name}
                                </h6>
                                <h6 className="md:text-base text-sm text-customBlack font-light mb-0">
                                  {item?.phone}
                                </h6>
                              </div>
                              <p className="md:text-base text-sm text-start text-customBlack font-light mb-0">
                                {item?.address}
                              </p>
                              {item?.mainAddress && (
                                <div className="bg-[#D9D9D9] lg:w-1/4 md:w-1/3 w-1/2 mt-2 text-center py-1">
                                  <h6 className="md:text-base text-sm text-customBlack font-semibold mb-0">
                                    {t('create_order.main_address')}
                                  </h6>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={props.selectAddress}
                          style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                          className="bg-green p-[10px] text-white rounded-[10px] w-full"
                        >
                          {t('create_order.select')}
                        </button>
                      </Fragment>
                    ) : (
                      <DataNotFound
                        type="address"
                        handleOnClick={() => {
                          props.setAddressModal("edit");
                          props.getProvinces();
                        }}
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* ADD ADDRESS MODAL */}
      <Transition appear show={props.addressModal === "edit"} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-montserrat"
          onClose={() => {
            props.setAddressModal("");
            props.setFormData(props.formDataDefault);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mx-4 mb-4">
                    <h5 className="md:text-xl text-lg font-bold">{t('create_order.address')}</h5>
                    <p className="md:text-base text-sm font-normal">
                      {t('create_order.desc_add_address')}
                    </p>
                    <form>
                      <div className="mb-[22px]">
                        <label
                          htmlFor="address"
                          className="md:text-base text-sm text-customBlack font-medium"
                        >
                          {t('create_order.address')}
                        </label>
                        <textarea
                          name="address"
                          id="address"
                          value={props.formData?.address}
                          onChange={props.handleChange}
                          className="focus:border-1 border-[#C4C4C4] p-[12px] border-1 border-solid focus:outline outline-[3px] outline-teal-300/25 w-full rounded-md md:text-base text-sm text-thirdy"
                        />
                        {props.errorName === "address" && (
                          <p className="text-base text-red pl-1 mb-0">
                            {t('create_order.insert_address')}
                          </p>
                        )}
                      </div>
                      <div className="mb-[22px]">
                        <label
                          htmlFor="name"
                          className="md:text-base text-sm text-customBlack font-medium"
                        >
                          {t('create_order.name')}
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={props.formData?.name}
                          onChange={props.handleChange}
                          className="focus:border-1 border-[#C4C4C4] p-[12px] border-1 border-solid focus:outline outline-[3px] outline-teal-300/25 w-full rounded-md md:text-base text-sm text-thirdy"
                        />
                        {props.errorName === "name" && (
                          <p className="text-base text-red pl-1 mb-0">
                            {t('create_order.required_name')}
                          </p>
                        )}
                      </div>
                      <div className="mb-[22px]">
                        <label
                          htmlFor="phone"
                          className="md:text-base text-sm text-customBlack font-medium"
                        >
                          {t('create_order.phone')}
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          value={props.formData?.phone}
                          onChange={props.handleChange}
                          className="focus:border-1 border-[#C4C4C4] p-[12px] border-1 border-solid focus:outline outline-[3px] outline-teal-300/25 w-full rounded-md md:text-base text-sm text-thirdy"
                        />
                        {props.errorName === "phone" && (
                          <p className="text-base text-red pl-1 mb-0">
                            {t('create_order.required_phone')}
                          </p>
                        )}
                      </div>
                      <div className="mb-[22px]">
                        <label
                          htmlFor="provinces"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {t('create_order.province')}
                        </label>
                        <select
                          onChange={(e) => {
                            props.getCities(e.target.value);
                            props.handleChange(e);
                          }}
                          id="provinces"
                          name="province"
                          value={props.formData?.province}
                          className="focus:border-1 border-[#C4C4C4] p-[12px] border-1 border-solid focus:outline outline-[3px] outline-teal-300/25 w-full rounded-md md:text-base text-sm text-thirdy"
                        >
                          <option value="">{t('create_order.select_province')}</option>
                          {props.provinces.map((item: any) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {props.errorName === "province" && (
                          <p className="text-base text-red pl-1 mb-0">
                            {t('create_order.required_province')}
                          </p>
                        )}
                      </div>
                      <div className="mb-[22px]">
                        <label
                          htmlFor="cities"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {t('create_order.city_regency')}
                        </label>
                        <select
                          value={props.formData?.city}
                          onChange={(e) => {
                            props.getSuburbs(e.target.value);
                            props.handleChange(e);
                          }}
                          id="cities"
                          name="city"
                          className="focus:border-1 border-[#C4C4C4] p-[12px] border-1 border-solid focus:outline outline-[3px] outline-teal-300/25 w-full rounded-md md:text-base text-sm text-thirdy"
                        >
                          <option value="">{t('create_order.select_city_regency')}</option>
                          {props.cities.map((item: any) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {props.errorName === "city" && (
                          <p className="text-base text-red pl-1 mb-0">
                            {t('create_order.required_city_regency')}
                          </p>
                        )}
                      </div>
                      <div className="mb-[22px]">
                        <label
                          htmlFor="suburbs"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {t('create_order.subdistrict')}
                        </label>
                        <select
                          value={props.formData?.suburbId}
                          onChange={(e) => {
                            props.getAreas(e.target.value);
                            props.handleChange(e);
                          }}
                          id="suburbs"
                          name="suburbId"
                          className="focus:border-1 border-[#C4C4C4] p-[12px] border-1 border-solid focus:outline outline-[3px] outline-teal-300/25 w-full rounded-md md:text-base text-sm text-thirdy"
                        >
                          <option value="">{t('create_order.select_subdistrict')}</option>
                          {props.suburbs.map((item: any) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {props.errorName === "suburbId" && (
                          <p className="text-base text-red pl-1 mb-0">
                            {t('create_order.required_subdistrict')}
                          </p>
                        )}
                      </div>
                      <div className="mb-[22px]">
                        <label
                          htmlFor="areas"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {t('create_order.village')}
                        </label>
                        <select
                          value={props.formData?.areaId}
                          onChange={props.handleChange}
                          id="areas"
                          name="areaId"
                          className="focus:border-1 border-[#C4C4C4] p-[12px] border-1 border-solid focus:outline outline-[3px] outline-teal-300/25 w-full rounded-md md:text-base text-sm text-thirdy"
                        >
                          <option value="">{t('create_order.select_village')}</option>
                          {props.areas.map((item: any) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {props.errorName === "areaId" && (
                          <p className="text-base text-red pl-1 mb-0">
                            {t('create_order.required_village')}
                          </p>
                        )}
                      </div>
                      <div className="mb-[22px]">
                        <label
                          htmlFor="name"
                          className="md:text-base text-sm text-customBlack font-medium"
                        >
                          {t('create_order.note')}
                        </label>
                        <input
                          type="text"
                          name="note"
                          id="note"
                          value={props.formData?.note}
                          onChange={props.handleChange}
                          className="focus:border-1 border-[#C4C4C4] p-[12px] border-1 border-solid focus:outline outline-[3px] outline-teal-300/25 w-full rounded-md md:text-base text-sm text-thirdy"
                        />
                      </div>
                      <div className="space-between first-letter:mb-[22px]">
                        <p className="md:text-base text-sm text-customBlack font-medium mb-0">
                          {t('create_order.set_primary_address')}
                        </p>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            name="mainAddress"
                            checked={props.formData?.mainAddress}
                            onChange={(e) => {
                              props.setFormData((prev: any) => {
                                return {
                                  ...prev,
                                  [e.target.name]: !props.formData?.mainAddress,
                                };
                              });
                            }}
                            value=""
                            className="sr-only peer"
                          />
                          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                      </div>
                      <button
                        type="button"
                        onClick={props.handleSubmit}
                        style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                        className="bg-green text-white rounded-[10px] p-[12px] w-full md:mt-9 mt-6"
                      >
                        {t('create_order.confirm')}
                      </button>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* PRODUCT NOTES MODAL */}
      <div
        className={`${props.selectedProductNotes.notes !== undefined ? "block" : "hidden"
          } relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 p-3">
              <button
                type="button"
                onClick={props.handleClose}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="p-3">
                <h5 className="md:text-base text-sm font-bold mb-4">
                  {t('create_order.form')} {props.selectedProductNotes.productName}
                </h5>
                <form className="space-y-6" action="#">
                  {props.selectedProductNotes?.notes?.length > 0 &&
                    props.selectedProductNotes?.notes?.map(
                      (item: any, index: number) => (
                        <div className="mb-2 text-start" key={index}>
                          <label htmlFor="">{item.name}</label>
                          {item.required && <span className="text-red">*</span>}
                          <input
                            type={item?.type}
                            className="w-full border-[1px] border-solid border-[#C4C4C4] rounded-md p-2"
                            value={item?.note}
                            onChange={(e) =>
                              props.addProductNotes(
                                props.selectedProductNotes.merchantId,
                                props.selectedProductNotes.productId,
                                item.name,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      )
                    )}
                  <button
                    type="button"
                    onClick={props.handleSave}
                    style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                    className="bg-green w-full p-[12px] text-white rounded-[10px]"
                  >
                    {t('create_order.save')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* UNIVERSAL NOTES MODAL */}
      <div
        className={`${props.showUniversalNotes ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 p-3">
              <button
                type="button"
                onClick={props.handleClose2}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="p-3">
                <h5 className="md:text-base text-sm font-bold mb-4">
                  {t('create_order.general_form')}
                </h5>
                <form className="space-y-6" action="#">
                  {props.universalNotes.map((el: any, index: number) => (
                    <div className="mb-2 text-start" key={index}>
                      <label htmlFor="">{el.name}</label>
                      {el.required && <span className="text-red">*</span>}
                      <input
                        type={el?.type}
                        className="w-full border-[1px] border-solid border-[#C4C4C4] rounded-md p-2"
                        value={el.note}
                        onChange={(e) =>
                          props.addUniversalNotes(el.name, e.target.value)
                        }
                      />
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => {
                      let findNote = props.universalNotes.find(
                        (i: any) => i.required && i.note === ""
                      );
                      if (findNote !== undefined) {
                        toast.error(`${findNote.name} ${t('create_order.required')}`);
                      } else {
                        props.setShowUniversalNotes(false);
                      }
                    }}
                    style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
                    className="bg-green w-full p-[12px] text-white rounded-[10px]"
                  >
                    {t('create_order.save')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container flex flex-col items-center md:mt-[64px] mt-[0px] px-0">
        <div className="md:w-4/5 w-full mx-auto mb-5">
          <div
            onClick={() => props.setAddressModal("list")}
            className="bg-white w-full cursor-pointer space-between gap-3 mb-3 px-6 py-2 shadow-sm"
          >
            <div>
              <h6 className="text-customBlack md:text-base text-sm font-medium mb-0">
                {t('create_order.address')}
              </h6>
              <p className="text-thirdy md:text-base text-sm font-medium mb-0">
                {props.selectedAddress.address !== undefined
                  ? props.selectedAddress.address
                  : t('create_order.select_address')}
              </p>
            </div>
            <img src={ArrowLeft} alt="" />
          </div>
          {props.products.map((item: any) => (
            <div className="bg-white w-full mb-3 py-3 shadow-sm" key={item.id}>
              <div className="px-3">
                <div className="space-between mb-3 px-2">
                  <div>
                    <h6 className="text-customBlack md:text-base text-sm font-semibold mb-0">
                      {t('create_order.name')} {marketplaceConfig.merchantName}
                    </h6>
                    <p className="text-customBlack md:text-base text-sm font-light mb-0">
                      {props.merchantName === "" ? item.merchant.name : props.merchantName}
                    </p>
                  </div>
                </div>
                <hr />
                <h6 className="text-customBlack md:text-base text-sm font-semibold mb-0 px-2">
                  {marketplaceConfig.productName}
                </h6>
                {props.isCart ? (
                  item?.product.map((el: any, index: number) => (
                    <div key={index} className="px-2 mt-[7px] mb-2">
                      <div className="space-between gap-3">
                        <h6 className="text-xs md:text-sm text-newColor font-semibold mb-0">
                          {el?.name}
                        </h6>
                        <div
                          className="space-between gap-2 cursor-pointer"
                          onClick={() => props.handleOpen(el?.productId)}
                        >
                          <h6 className="text-[#C4C4C4] font-montserrat text-xs font-semibold mb-0">
                            {t('create_order.more_complete')}
                          </h6>
                          <Icon2 open={props.expand} id={el?.productId} />
                        </div>
                      </div>
                      {props.expand[el?.productId] && (
                        <Fragment>
                          <ul className="list-disc">
                            {el.item.map((i: any, index: number) => (
                              <li key={index}>
                                <div className="flex justify-between items-end gap-3 text-[#9A9999] text-xs font-light mb-0 mt-1">
                                  <div>
                                    <p className="mb-1">
                                      {i.variantKey} : {i.variantValue}
                                    </p>
                                    <p className="mb-0">
                                      {i.quantity} x{" "}
                                      {formatCurrency(i.marketplacePrice)}
                                    </p>
                                  </div>
                                  <span>
                                    {formatCurrency(
                                      i.quantity * i.marketplacePrice
                                    )}
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {props.productNotes[item.merchantId] !== undefined &&
                            props.productNotes[item.merchantId]
                              .filter((i: any) => i.productId === el.productId)
                              .map((productNote: any) => (
                                <div
                                  key={productNote.productId}
                                  className="flex flex-col items-center mb-2 px-2"
                                >
                                  <h6 className="text-customBlack font-montserrat text-sm font-semibold w-full text-start mb-2">
                                    {t('create_order.form')} {productNote.productName}
                                  </h6>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      props.selectForm(
                                        item.merchantId,
                                        productNote.productId
                                      )
                                    }
                                    className="font-montserrat text-sm text-green font-normal"
                                  >
                                    {t('create_order.fill_form')}
                                  </button>
                                </div>
                              ))}
                        </Fragment>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="px-2 mt-[7px] mb-2">
                    <div className="space-between gap-3">
                      <h6 className="text-xs md:text-sm text-newColor font-semibold mb-0">
                        {item?.productName}
                      </h6>
                      <div
                        className="space-between gap-2 cursor-pointer"
                        onClick={() => props.handleOpen(item?.id)}
                      >
                        <h6 className="text-[#C4C4C4] font-montserrat text-xs font-semibold mb-0">
                          {t('create_order.more_complete')}
                        </h6>
                        <Icon2 open={props.expand} id={item?.id} />
                      </div>
                    </div>
                    {props.expand[item.id] && (
                      <Fragment>
                        <ul className="list-disc">
                          <li>
                            <div className="flex justify-between items-end gap-3 font-montserrat text-[#9A9999] text-xs font-light mb-0 mt-1">
                              <div>
                                <p className="mb-1">
                                  {item.variantKey} : {item.variantValue}
                                </p>
                                <p className="mb-0">
                                  {location.state.quantity} x{" "}
                                  {formatCurrency(item.marketplacePrice)}
                                </p>
                              </div>
                              <span>
                                {formatCurrency(
                                  location.state.quantity *
                                  item.marketplacePrice
                                )}
                              </span>
                            </div>
                          </li>
                        </ul>
                        {props.productNotes[item.merchantId] !== undefined &&
                          props.productNotes[item.merchantId].filter(
                            (i: any) =>
                              i.productId === item.productId &&
                              i.notes.length > 0
                          ).length > 0 &&
                          props.productNotes[item.merchantId]
                            .filter(
                              (i: any) =>
                                i.productId === item.productId &&
                                i.notes.length > 0
                            )
                            .map((productNote: any) => (
                              <div
                                key={productNote.productId}
                                className="flex flex-col items-center mb-2 px-2"
                              >
                                <h6 className="text-customBlack font-montserrat text-sm font-semibold w-full text-start mb-2">
                                  {t("create_order.form")} {productNote.productName}
                                </h6>
                                <button
                                  type="button"
                                  onClick={() =>
                                    props.selectForm(
                                      item.merchantId,
                                      productNote.productId
                                    )
                                  }
                                  className="font-montserrat text-sm text-green font-normal"
                                >
                                  {t("create_order.fill_form")}
                                </button>
                              </div>
                            ))}
                      </Fragment>
                    )}
                  </div>
                )}

                <hr />
                {props.isCart
                  ? item.product.find(
                    (i: any) => i.productType === "BARANG"
                  ) !== undefined && (
                    <Fragment>
                      <div
                        onClick={() => props.selectShip(item.merchantId)}
                        className="space-between gap-3 px-2 cursor-pointer"
                      >
                        <div>
                          <h6 className="text-customBlack md:text-base text-sm font-medium mb-0">
                            {t("create_order.shipping_options")}
                          </h6>
                          <p className="text-customBlack md:text-base text-sm font-light mb-0">
                            {props.selectedShip.find(
                              (i: any) => i.merchantId === item.merchantId
                            ) === undefined
                              ? t("create_order.select_option_shipping")
                              : `${props.selectedShip.find(
                                (i: any) => i.merchantId === item.merchantId
                              ).name
                              } - ${formatCurrency(
                                props.selectedShip.find(
                                  (i: any) => i.merchantId === item.merchantId
                                ).price
                              )}`}
                          </p>
                        </div>
                        <img src={ArrowLeft} alt="" />
                      </div>
                      <hr />
                    </Fragment>
                  )
                  : location.state.data.type === "BARANG" && (
                    <Fragment>
                      <div
                        onClick={() => props.selectShip(item.merchantId)}
                        className="space-between gap-3 px-2 cursor-pointer"
                      >
                        <div>
                          <h6 className="text-customBlack md:text-base text-sm font-medium mb-0">
                            {t("create_order.shipping_options")}
                          </h6>
                          <p className="text-customBlack md:text-base text-sm font-light mb-0">
                            {props.selectedShip.find(
                              (i: any) => i.merchantId === item.merchantId
                            ) === undefined
                              ? t("create_order.select_option_shipping")
                              : `${props.selectedShip.find(
                                (i: any) => i.merchantId === item.merchantId
                              ).name
                              } - ${formatCurrency(
                                props.selectedShip.find(
                                  (i: any) => i.merchantId === item.merchantId
                                ).price
                              )}`}
                          </p>
                        </div>
                        <img src={ArrowLeft} alt="" />
                      </div>
                      <hr />
                    </Fragment>
                  )}

                <div className="px-2">
                  <h6 className="text-customBlack md:text-base text-sm font-semibold mb-2">
                    {t("create_order.note")}
                  </h6>
                  <input
                    type="text"
                    className="w-full border-[1px] border-solid border-[#C4C4C4] focus:outline-2 focus:outline-[#C4C4C4] rounded-md p-2"
                    value={
                      props.data.order.find(
                        (j: any) => j.merchantId === item.merchantId
                      ) === undefined
                        ? ""
                        : props.data.order.find(
                          (j: any) => j.merchantId === item.merchantId
                        ).note
                    }
                    onChange={(e) => props.addNote(item.merchantId, e.target.value)}
                  />
                </div>
                {props.expandAll[item.merchantId] ? (
                  <div className="space-between gap-3 mt-3 px-2">
                    <h6 className="text-customBlack md:text-base text-sm font-semibold mb-0">
                      {t("create_order.total_order")}
                    </h6>
                    {props.isCart ? (
                      item.product.find(
                        (j: any) => j.productType === "BARANG"
                      ) === undefined ? (
                        <p className="text-thirdy md:text-base text-sm font-medium mb-0">
                          {formatCurrency(props.totalPrice[item.merchantId])}
                        </p>
                      ) : (
                        <p className="text-thirdy md:text-base text-sm font-medium mb-0">
                          {props.selectedShip.find(
                            (i: any) => i.merchantId === item.merchantId
                          ) === undefined
                            ? formatCurrency(props.totalPrice[item.merchantId])
                            : formatCurrency(
                              props.selectedShip.find(
                                (i: any) => i.merchantId === item.merchantId
                              ).price + props.totalPrice[item.merchantId]
                            )}
                        </p>
                      )
                    ) : location.state.data.type === "BARANG" ? (
                      <p className="text-thirdy md:text-base text-sm font-medium mb-0">
                        {props.selectedShip.find(
                          (i: any) => i.merchantId === item.merchantId
                        ) === undefined
                          ? formatCurrency(props.totalPrice[item.merchantId])
                          : formatCurrency(
                            props.selectedShip.find(
                              (i: any) => i.merchantId === item.merchantId
                            ).price + props.totalPrice[item.merchantId]
                          )}
                      </p>
                    ) : (
                      <p className="text-thirdy md:text-base text-sm font-medium mb-0">
                        {formatCurrency(props.totalPrice[item.merchantId])}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="space-between gap-3 bg-[#CFEAE8] rounded-md px-2 py-[10px] mt-3 mx-2">
                    <h6 className="text-green md:text-base text-sm font-semibold mb-0">
                      {t('create_order.total_order')}
                    </h6>
                    {props.isCart ? (
                      item.product.find(
                        (j: any) => j.productType === "BARANG"
                      ) === undefined ? (
                        <p className="text-green md:text-base text-sm font-medium mb-0">
                          {formatCurrency(props.totalPrice[item.merchantId])}
                        </p>
                      ) : (
                        <p className="text-green md:text-base text-sm font-medium mb-0">
                          {props.selectedShip.find(
                            (i: any) => i.merchantId === item.merchantId
                          ) === undefined
                            ? formatCurrency(props.totalPrice[item.merchantId])
                            : formatCurrency(
                              props.selectedShip.find(
                                (i: any) => i.merchantId === item.merchantId
                              ).price + props.totalPrice[item.merchantId]
                            )}
                        </p>
                      )
                    ) : location.state.data.type === "BARANG" ? (
                      <p className="text-green md:text-base text-sm font-medium mb-0">
                        {props.selectedShip.find(
                          (i: any) => i.merchantId === item.merchantId
                        ) === undefined
                          ? formatCurrency(props.totalPrice[item.merchantId])
                          : formatCurrency(
                            props.selectedShip.find(
                              (i: any) => i.merchantId === item.merchantId
                            ).price + props.totalPrice[item.merchantId]
                          )}
                      </p>
                    ) : (
                      <p className="text-white md:text-base text-sm font-medium mb-0">
                        {formatCurrency(props.totalPrice[item.merchantId])}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
          {props.universalNotes.length > 0 && (
            <div className="bg-white shadow-sm p-3 flex flex-col items-center mb-3">
              <h6 className="text-customBlack w-full text-start text-sm font-semibold mb-2">
                {t('create_order.general_form')}
              </h6>
              <button
                type="button"
                onClick={() => props.setShowUniversalNotes(true)}
                className="font-montserrat text-sm text-green font-normal"
              >
                {t('create_order.fill_form')}
              </button>
            </div>
          )}
          <div className="bg-white shadow-sm p-3">
            <div className="space-between gap-3 px-2 mb-2">
              <h6 className="text-customBlack text-sm font-semibold mb-0">
                {t('create_order.order_subtotal')}
              </h6>
              <p className="text-thirdy text-sm font-light mb-0">
                {formatCurrency(props.orderTotal)}
              </p>
            </div>
            <div className="space-between gap-3 px-2 mb-2">
              <h6 className="text-customBlack text-sm font-semibold mb-0">
                {t('create_order.shippingCost')}
              </h6>
              <p className="text-thirdy text-xs font-light mb-0">
                {formatCurrency(props.shipTotal)}
              </p>
            </div>
            <div className="space-between gap-3 px-2 mb-2">
              <h6 className="text-customBlack text-sm font-semibold mb-0">
                {t('create_order.payment_total')}
              </h6>
              <p className="text-thirdy text-sm font-medium mb-0">
                {formatCurrency(props.orderTotal + props.shipTotal)}
              </p>
            </div>
          </div>
          <div className="bg-white mt-4 p-4">
            <div className="space-between gap-3 mb-2">
              <p className="md:text-base text-sm text-customBlack font-medium mb-0">
                {t('create_order.payment_total')}
              </p>
              <p className="text-green md:text-lg text-base font-semibold mb-0">
                {formatCurrency(props.orderTotal + props.shipTotal)}
              </p>
            </div>
            <button
              type="button"
              onClick={props.handleOnClick}
              style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
              className="bg-green text-white md:text-base text-sm font-semibold w-full p-[12px] rounded-[10px]"
            >
              {t('create_order.create_order')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateOrderWeb;