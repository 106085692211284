import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as API from "../../core/service/api_auth";
import * as API_USER from "../../core/service/api_user";
import { setUser } from "../../core/feature/user/userSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";
import { useAppSelector } from "../../core/feature/hooks";
import Token from "../../core/models/token";
import { findIndex } from "lodash";
import { setToken } from "../../core/feature/token/tokenSlice";
import { setCompany } from "../../core/feature/company/companySlice";

const SecureRedirect = () => {
  const dispatch = useDispatch();
  const token: Token[] = useAppSelector((state) => state.token.token);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let userType = query.get("userType");
  let state = query.get("state");
  let tokenurl = query.get("token");
  let destination: any = query.get("url");
  let url = decodeURIComponent(destination);

  const cekToken = async () => {
    dispatch(setLoading(true));
    const datapost = {
      state: state,
      token: tokenurl,
      userType: userType,
    };
    await API.secureRedirect(datapost)
      .then((res) => {
        const data: any = res.data.data;
        getCompanyInitial(data.companyInitial);
        let userLogin = [
          {
            token: data.authToken,
            id: data.noId,
            initial: data.companyInitial,
          },
        ];
        var newToken = token ? [...token] : [];
        let indexToken = findIndex(token, {
          cid: data.companyInitial,
        });
        if (indexToken < 0) {
          newToken.push({
            cid: data.companyInitial,
            token: data.authToken,
          });
        } else {
          newToken[indexToken] = {
            cid: data.companyInitial,
            token: data.authToken,
          };
        }
        dispatch(setToken(newToken));
        dispatch(setUser(data));
        dispatch(setUserLogin(userLogin));
        dispatch(setLoading(false));
        redirect(url);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCompanyInitial = async (init: any) => {
    await API_USER.getCompany(init)
      .then((res) => {
        dispatch(setCompany(res.data.data));
      })
      .catch((err) => {});
  };

  const redirect = (res: any) => {
    document.location.href = `${res}`;
    sessionStorage.setItem("urlWebview", res);
    sessionStorage.setItem("isWebview", "true");
  };

  useEffect(() => {
    cekToken();
  }, []);

  return <div></div>;
};

export default SecureRedirect;
