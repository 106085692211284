import _ from "lodash";
import Barcode from "qrcode";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import * as API from "../../core/service/api_commerce";
import TipAndTourHistoryDetailMobile from "./tripAndTourHistoryDetailMobile";
import TipAndTourHistoryDetailWeb from "./tripAndTourHistoryDetailWeb";
import toast from "react-hot-toast";

const TripAndTourHistoryDetail = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const [data, setData] = useState<any>([]);
  const dispatch = useAppDispatch();
  const params = useParams();
  const [barcode, setBarcode] = useState("");

  const getData = () => {
    dispatch(setLoading(true));
    API.getTourOrderById(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        Barcode.toDataURL(res.data.data.inquiryId).then((res) => {
          setBarcode(res);
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const checkouth = () => {
    let dataToken: any = _.find(token, function (o) {
      return o.cid === company.initial;
    });
    window.location.href = `https://${data.checkoutLink}?access_token=${dataToken?.token}&message=SUCCESS_LOGIN`;
  };

  const cancelOrder = () => {
    dispatch(setLoading(true));
    API.cancelTourOrder(data.transactionId)
      .then((res) => {
        dispatch(setLoading(false));
        toast.success("Pesanan berhasil dibatalkan")
        getData()
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <BrowserView>
        <TipAndTourHistoryDetailWeb
          data={data}
          barcode={barcode}
          checkouth={checkouth}
          cancelOrder={cancelOrder}
        ></TipAndTourHistoryDetailWeb>
      </BrowserView>
      <MobileView>
        <TipAndTourHistoryDetailMobile
          data={data}
          barcode={barcode}
          checkouth={checkouth}
          cancelOrder={cancelOrder}
        ></TipAndTourHistoryDetailMobile>
      </MobileView>
    </>
  );
};

export default TripAndTourHistoryDetail;
