const Collapse = ({ children, open}: any) => {

    return (
      <div
        className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
          open ? "max-h-screen" : "max-h-0"
        }`}
      >
        {children}
      </div>
    );
  };
  
  export default Collapse;