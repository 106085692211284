import React, { FC, Fragment } from "react";
import QRCode from "react-qr-code";
import { BottomSheet } from "react-spring-bottom-sheet";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface VirtualCardQRCodeProps {
  cardId: string;
  memberId: string;
  name: string;
  showQRCode: boolean;
  onHide: any;
  isHomePage: boolean;
  isModal?: boolean;
  qrAccess?: string;
}

export const VirtualCardQRCode: FC<VirtualCardQRCodeProps> = (
  props
): JSX.Element => {
  const [openTab, setOpenTab] = React.useState(1);
  const company: Company = useAppSelector((state) => state.company.company);

  const MemberId = () => {
    return (
      <div className="bg-[#FAFBFD] rounded-[15px] max-w-[260px] mx-auto p-4">
        <QRCode
          style={{ height: "100%", width: "100%" }}
          value={props.memberId}
        />
      </div>
    );
  };

  const MemberAccess = () => {
    return (
      <div className="bg-[#FAFBFD] rounded-[15px] max-w-[260px] mx-auto p-4">
        <QRCode
          style={{ height: "100%", width: "100%" }}
          value={props.qrAccess || ""}
        />
      </div>
    );
  };

  const CardId = () => {
    return (
      <div className="bg-[#FAFBFD] rounded-[15px] max-w-[260px] mx-auto p-4">
        <QRCode
          style={{ height: "100%", width: "100%" }}
          value={props.cardId}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      {props.isModal && (
        <div
          className={`${props.showQRCode ? "block" : "hidden"} relative z-50`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
                <div className="text-end md:px-[0px] px-[0.5rem]">
                  <button
                    type="button"
                    onClick={() => {
                      props.onHide();
                      // setShowModal(false);
                    }}
                    className="fa-xl text-gray-400"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <div className="text-center mb-2">
                  <div className="flex flex-col items-center justify-center m-3">
                    <MemberAccess />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!props.isModal && (
        <BottomSheet
          onDismiss={props.onHide}
          open={props.showQRCode}
          className="z-50 relative font-montserrat"
        >
          <div className="flex flex-col items-center justify-center m-3">
            {props.isHomePage ? (
              <MemberId />
            ) : (
              <Fragment>
                <ul
                  style={{
                    backgroundColor:
                      company?.color?.color1 !== undefined
                        ? company?.color?.color1
                        : "#26A69A",
                  }}
                  className="md:w-1/2 w-2/3 flex gap-3 p-1 mb-4 rounded-lg"
                >
                  <li className="flex w-full">
                    <button
                      onClick={() => setOpenTab(1)}
                      style={{
                        color:
                          openTab !== 1
                            ? "#FFFFFF"
                            : company?.color?.color1 !== undefined
                            ? company?.color?.color1
                            : "#26A69A",
                        backgroundColor:
                          openTab !== 1 ? "transparent" : "#FFFFFF",
                      }}
                      className="inline-block px-2 py-2 rounded-lg w-full md:text-base text-sm"
                    >
                      Id Member
                    </button>
                  </li>
                  <li className="flex w-full">
                    <button
                      onClick={() => setOpenTab(2)}
                      style={{
                        color:
                          openTab !== 2
                            ? "#FFFFFF"
                            : company?.color?.color1 !== undefined
                            ? company?.color?.color1
                            : "#26A69A",
                        backgroundColor:
                          openTab !== 2 ? "transparent" : "#FFFFFF",
                      }}
                      className="inline-block px-2 py-2 rounded-lg w-full md:text-base text-sm"
                    >
                      Id Card
                    </button>
                  </li>
                </ul>
                <div className="md:mt-6 mt-[0px] w-full mb-3">
                  <div className={openTab === 1 ? "block" : "hidden"}>
                    <MemberId />
                  </div>

                  {openTab === 2 && (
                    <div className={openTab === 2 ? "block" : "hidden"}>
                      <CardId />
                    </div>
                  )}
                </div>
              </Fragment>
            )}
            <h6 className="font-medium text-xl text-customBlack mb-0">
              {props.name}
            </h6>
            <h6 className="font-medium text-xl text-secondary">
              {openTab === 1 ? props.memberId : props.cardId}
            </h6>
          </div>
        </BottomSheet>
      )}
    </React.Fragment>
  );
};
