import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ChangePasswordWeb from "./changePasswordWeb";
import ChangePasswordMobile from "./changePasswordMobile";

const ChangePassword = () => {
  return (
    <>
      <BrowserView>
        <ChangePasswordWeb></ChangePasswordWeb>
      </BrowserView>
      <MobileView>
        <ChangePasswordMobile></ChangePasswordMobile>
      </MobileView>
    </>
  );
};

export default ChangePassword;
