import React from "react";
import CurrencyInput from "react-currency-input-field";
import Header from "../../_common/headerComponent";
import CustomTooltip from "../../../components/_common/tooltip";
import { Dialog, Transition } from "@headlessui/react";
import Check2 from "../../../assets/images/vcard_check2.svg";
import { toast } from "react-hot-toast";
import userDefault from "../../../assets/images/card_avatar.png";
import { useTranslation } from "react-i18next";

const AddVirtualCardWeb = (props: any) => {
  const { t } = useTranslation();
  const [errorOneTimeTxLimit, setErrorOneTimeTxLimit] = React.useState("");
  const [errorDailyTimeTxLimit, setErrorDailyTimeTxLimit] = React.useState("");
  const [errorMonthTimeTxLimit, setErrorMonthTimeTxLimit] = React.useState("");
  const [errorSecurityCode, setErrorSecurityCode] = React.useState("");
  const [isCompleted, setIsCompleted] = React.useState<any>({
    cardIdentifier: false,
    cardBalance: false,
  });

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] pb-5 md:px-4 px-[0px]">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto">
          <div className="md:w-[100%] w-full mx-auto">
            <div className="flex flex-col w-3/5 items-center mx-auto">
              <div className="w-full h-full ">
                <iframe
                  className={
                    props.isPortrait
                      ? `w-60 mx-auto overflow-hidden rounded-2xl h-[400px] image-cover-style shadow-xl`
                      : `w-full overflow-hidden rounded-2xl h-64 image-cover-style shadow-xl pl-3`
                  }
                  style={{ backgroundImage: `url(${props.background})` }}
                  scrolling="no"
                  srcDoc={props.template.replace("src=-", `src=${userDefault}`)}
                ></iframe>
              </div>
            </div>

            <div className="bg-white p-7 mt-8 mb-6">
              <p className="text-base font-medium text-customBlack md:text-center text-start mb-6">
                {t('virtual_card.complete_form')}
              </p>
              <div className="w-full mb-3">
                <label className="text-base font-semibold text-customBlack mb-[8px]">
                  {t('virtual_card.card_name')}
                </label>
                <input
                  type="text"
                  placeholder={t('virtual_card.placeholder_card_name')}
                  value={props.data.name}
                  onChange={(e) => {
                    props.changeData(e.target.value, "name");
                    props.setErrorCardName("");
                  }}
                  className="bg-white border-[0.5px] border-[#E5E5E5] text-newColor border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline"
                />
                <p className="text-start md:text-base text-sm text-red pl-1 mb-0">
                  {props.errorCardName}
                </p>
              </div>
              {!props.showBalanceCard && (
                <div>
                  <label className="text-base font-semibold text-customBlack mb-[8px]">
                    {t('virtual_card.description_card')}
                  </label>
                  <textarea
                    placeholder={t('virtual_card.placeholder_description_card')}
                    value={props.data.description}
                    onChange={(e) =>
                      props.changeData(e.target.value, "description")
                    }
                    className="bg-white border-[0.5px] border-[#E5E5E5] text-newColor border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline"
                  />
                </div>
              )}
            </div>

            <div className="bg-white p-7">
              <h6 className="text-base font-semibold text-customBlack mb-0">
                {t('virtual_card.type_card')}
              </h6>
              <div className="space-between gap-3 border-b-[1px] border-[#E5E5E5] pb-2 mt-[14px]">
                <div className="flex items-center gap-2">
                  <h6 className="text-base font-normal text-customBlack mb-0">
                    {t('virtual_card.identity_card')}
                  </h6>
                  <CustomTooltip
                    content={
                      <>
                        {t('virtual_card.type_card_functioning')}
                        <br />
                        {t('virtual_card.as_identity_card')}
                        <br />
                        {t('virtual_card.for_user')}
                        <br />
                        {t('virtual_card.user_can_use')}
                        <br />
                        {t('virtual_card.this_card')}
                        <br />
                        {t('virtual_card.attendance_card')}
                        <br />
                        {t('virtual_card.attendance_event')}
                      </>
                    }
                    direction="bottom"
                  >
                    <button
                      type="button"
                      className="rounded-[50%] text-customBlack border-[1px] border-solid border-[#252525] text-sm px-2"
                    >
                      !
                    </button>
                  </CustomTooltip>
                </div>
                {isCompleted["cardIdentifier"] ? (
                  <div
                    className={`text-[#80b7aa] font-normal italic flex items-center gap-1 text-sm cursor-pointer`}
                    onClick={() => props.setIsOpen("cardIdentifier")}
                  >
                    <span>{t('virtual_card.already_filled')}</span>
                    <img src={Check2} alt="" />
                  </div>
                ) : (
                  <div
                    className={`text-[#728F9E] font-normal text-sm cursor-pointer`}
                    onClick={() => props.setIsOpen("cardIdentifier")}
                  >
                    {t('virtual_card.complete_data')} {">"}
                  </div>
                )}
              </div>
              <div className="space-between gap-3 border-b-[1px] border-[#E5E5E5] mt-[14px]">
                <div className="flex items-center gap-2 pb-2">
                  <h6 className="text-base font-normal text-customBlack mb-0">
                    {isCompleted["cardBalance"]
                      ? t('virtual_card.card_transaction')
                      : t('virtual_card.no_card_transaction')}
                  </h6>
                  <CustomTooltip
                    content={
                      <>
                        {t('virtual_card.type_card_functioning')}
                        <br />
                        {t('virtual_card.as_identity_card')}
                        <br />
                        {t('virtual_card.for_user')}
                        <br />
                        {t('virtual_card.user_can_use')}
                        <br />
                        {t('virtual_card.this_card')}
                        <br />
                        {t('virtual_card.attendance_card')}
                        <br />
                        {t('virtual_card.attendance_event')}
                      </>
                    }
                    direction="bottom"
                  >
                    <button
                      type="button"
                      className="rounded-[50%] text-customBlack border-[1px] border-solid border-[#252525] text-sm px-2"
                    >
                      !
                    </button>
                  </CustomTooltip>
                </div>
                {isCompleted["cardBalance"] ? (
                  <div
                    className={`${isCompleted["cardBalance"]
                      ? "text-[#D9D9D9]"
                      : "text-[#016F54]"
                      } flex items-center font-normal text-sm italic cursor-pointer`}
                    onClick={() => props.setIsOpen("cardBalance")}
                  >
                    <span>{t('virtual_card.already_filled')}</span>
                    <img src={Check2} alt="" />
                  </div>
                ) : (
                  <div
                    className="text-[#26A69A] underline font-medium text-sm cursor-pointer"
                    onClick={() => props.setIsOpen("cardBalance")}
                  >
                    {t('virtual_card.create_now')}
                  </div>
                )}
              </div>
            </div>

            {props.showBalanceCard && (
              <div className="bg-white p-7 mb-0 mt-4 px-4">
                <label className="text-base font-semibold text-customBlack mb-[8px]">
                  {t('virtual_card.description_card')}
                </label>
                <textarea
                  placeholder={t('virtual_card.placeholder_description_card')}
                  value={props.data.description}
                  onChange={(e) =>
                    props.changeData(e.target.value, "description")
                  }
                  className="bg-white border-[0.5px] border-[#E5E5E5] text-newColor border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline"
                />
              </div>
            )}
          </div>

          {/* Modal Kartu Identitas */}
          <Transition
            appear
            show={props.isOpen === "cardIdentifier"}
            as={React.Fragment}
          >
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => props.setIsOpen("cardIdentifier")}
            >
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all font-montserrat">
                      <div className="mx-auto bg-white rounded-md px-3 py-3">
                        <div className="flex items-center gap-2 mb-[12px]">
                          <h6 className="text-base font-semibold text-customBlack mb-0">
                            {t('virtual_card.identity_card')}
                          </h6>
                          <CustomTooltip
                            content={
                              <>
                                {t('virtual_card.type_card_functioning')}
                                <br />
                                {t('virtual_card.as_identity_card')}
                                <br />
                                {t('virtual_card.for_user')}
                                <br />
                                {t('virtual_card.user_can_use')}
                                <br />
                                {t('virtual_card.this_card')}
                                <br />
                                {t('virtual_card.attendance_card')}
                                <br />
                                {t('virtual_card.attendance_event')}
                              </>
                            }
                            direction="bottom"
                          >
                            <button
                              type="button"
                              className="rounded-[50%] text-customBlack border-[1px] border-solid border-[#252525] text-sm px-2"
                            >
                              !
                            </button>
                          </CustomTooltip>
                        </div>
                        {props.data.cardIdentifier.map(
                          (item: any, index: number) =>
                            item.type === "IMAGE" ? (
                              <div className="mb-3" key={index}>
                                <label className="text-base font-medium text-customBlack mb-[8px]">
                                  {item.key_readable}
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  // value={item.value}
                                  onChange={(e) =>
                                    props.inputHandler(e, item.key)
                                  }
                                  placeholder="Pilih Foto"
                                  className="bg-white border-[0.5px] border-[#728F9E] text-newColor border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline"
                                />
                                <p className="text-start text-base text-red pl-1 mb-0">
                                  {item.message}
                                </p>
                              </div>
                            ) : (
                              <div className="mb-3" key={index}>
                                <label className="text-base font-medium text-customBlack mb-[8px]">
                                  {item.key_readable}
                                </label>
                                <input
                                  type={item.type.toLowerCase()}
                                  value={item.value}
                                  onChange={(e) => {
                                    props.changeInput(e.target.value, item);
                                  }}
                                  placeholder={`${t("virtual_card.insert")} ${item.key_readable}`}
                                  className="bg-white border-[0.5px] border-[#728F9E] text-newColor border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline"
                                />
                                <p className="text-start text-base text-red pl-1 mb-0">
                                  {item.message}
                                </p>
                              </div>
                            )
                        )}
                        <div className="flex flex-row gap-3 mt-4">
                          <button
                            type="button"
                            onClick={() => props.changeModalView("")}
                            className="bg-white text-newColor border-[0.5px] border-secondary border-solid font-medium text-sm rounded-[10px] w-100 md:p-3 px-3 py-2"
                          >
                            {t("virtual_card.cancel")}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              props.setIsOpen("");
                              setIsCompleted({
                                ...isCompleted,
                                cardIdentifier: true,
                              });
                            }}
                            style={{
                              backgroundColor: `${props.configColor?.color1}`,
                            }}
                            className=" text-white font-medium text-sm rounded-[10px] w-100 md:p-3 px-3 py-2"
                          >
                            {t("virtual_card.save")}
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          {/* Modal Kartu Transaksi */}
          <Transition
            appear
            show={props.isOpen === "cardBalance"}
            as={React.Fragment}
          >
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => props.setIsOpen("cardBalance")}
            >
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all font-montserrat">
                      <div className="mx-auto bg-white rounded-md px-3 py-3">
                        <div className="flex items-center gap-2 mb-[12px]">
                          <h6 className="md:text-xl text-lg font-semibold text-customBlack mb-0">
                            {t('virtual_card.identity_card')}
                          </h6>
                          <CustomTooltip
                            content={
                              <>
                                {t('virtual_card.type_of_card')}
                                <br />
                                {t('virtual_card.used')}
                                <br />
                                {t('virtual_card.payment')}
                              </>
                            }
                            direction="bottom"
                          >
                            <button
                              type="button"
                              className="rounded-[50%] text-[#252525] border-[1px] border-solid border-[#252525] text-sm px-2"
                            >
                              !
                            </button>
                          </CustomTooltip>
                        </div>
                        <div className="mb-3">
                          <label className="text-base font-medium text-customBlack mb-[8px]">
                            {t('virtual_card.one_time_limit')}
                          </label>
                          <span className="mx-1">
                            <CustomTooltip
                              content={
                                <>
                                  {t('virtual_card.limit_set')}
                                  <br />
                                  {t('virtual_card.max_transaction')}
                                  <br />
                                  {t('virtual_card.can_be')}
                                  <br />
                                  {t('virtual_card.card_one')}
                                  <br />
                                  {t('virtual_card.user_transaction')}
                                  <br />
                                  {t('virtual_card.reduce_limit')}
                                  <br />
                                  {t('virtual_card.admin_set')}
                                  <br />
                                  {t('virtual_card.but_cant')}
                                </>
                              }
                              direction="bottom"
                            >
                              <button
                                type="button"
                                className="rounded-[50%] text-customBlack border-[1px] border-solid border-[#252525] text-sm px-2"
                              >
                                !
                              </button>
                            </CustomTooltip>
                          </span>
                          <CurrencyInput
                            className="bg-white border-[0.5px] border-[#728F9E] text-newColor border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline"
                            defaultValue=""
                            decimalsLimit={2}
                            value={props.data.cardBalance.oneTimeTxLimit}
                            prefix={"Rp"}
                            placeholder={t('virtual_card.placeholder_one_limit')}
                            onValueChange={(value, name) => {
                              props.changeData(
                                value,
                                "oneTimeTxLimit",
                                "cardBalance"
                              );
                              setErrorOneTimeTxLimit("");
                            }}
                          />
                          <p className="text-start md:text-base text-sm text-red pl-1 mb-0">
                            {errorOneTimeTxLimit}
                          </p>
                        </div>
                        <div className="mb-3">
                          <label className="text-base font-medium text-customBlack mb-[8px]">
                            {t('virtual_card.limit_transaction_daily')}
                          </label>
                          <span className="mx-1">
                            <CustomTooltip
                              content={
                                <>
                                  {t('virtual_card.limit_set')}
                                  <br />
                                  {t('virtual_card.max_transaction')}
                                  <br />
                                  {t('virtual_card.can_be')}
                                  <br />
                                  {t('virtual_card.card_one')}
                                  <br />
                                  {t('virtual_card.user_transaction')}
                                  <br />
                                  {t('virtual_card.reduce_limit')}
                                  <br />
                                  {t('virtual_card.admin_set')}
                                  <br />
                                  {t('virtual_card.but_cant')}
                                </>
                              }
                              direction="bottom"
                            >
                              <button
                                type="button"
                                className="rounded-[50%] text-customBlack border-[1px] border-solid border-[#252525] text-sm px-2"
                              >
                                !
                              </button>
                            </CustomTooltip>
                          </span>
                          <CurrencyInput
                            className="bg-white border-[0.5px] border-[#728F9E] text-newColor border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline"
                            defaultValue=""
                            decimalsLimit={2}
                            value={props.data.cardBalance.dailyTxLimit}
                            prefix={"Rp"}
                            placeholder={t('virtual_card.placeholder_one_limit')}
                            onValueChange={(value, name) => {
                              props.changeData(
                                value,
                                "dailyTxLimit",
                                "cardBalance"
                              );
                              setErrorDailyTimeTxLimit("");
                            }}
                          />
                          <p className="text-start md:text-base text-sm text-red pl-1 mb-0">
                            {errorDailyTimeTxLimit}
                          </p>
                        </div>
                        <div className="mb-3">
                          <label className="text-base font-medium text-customBlack mb-[8px]">
                            {t('virtual_card.limit_transaction_monthly')}
                          </label>
                          <span className="mx-1">
                            <CustomTooltip
                              content={
                                <>
                                  {t('virtual_card.limit_set')}
                                  <br />
                                  {t('virtual_card.max_transaction')}
                                  <br />
                                  {t('virtual_card.can_be')}
                                  <br />
                                  {t('virtual_card.card_month')}
                                  <br />
                                  {t('virtual_card.card_month_user_transaction')}
                                  <br />
                                  {t('virtual_card.card_month_reduce_limit')}
                                  <br />
                                  {t('virtual_card.card_month_admin_set')}
                                  <br />
                                  {t('virtual_card.card_month_but_cant')}
                                </>
                              }
                              direction="bottom"
                            >
                              <button
                                type="button"
                                className="rounded-[50%] text-customBlack border-[1px] border-solid border-[#252525] text-sm px-2"
                              >
                                !
                              </button>
                            </CustomTooltip>
                          </span>
                          <CurrencyInput
                            className="bg-white border-[0.5px] border-[#728F9E] text-newColor border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline"
                            defaultValue=""
                            decimalsLimit={2}
                            value={props.data.cardBalance.monthTxLimit}
                            prefix={"Rp"}
                            placeholder={t('virtual_card.placeholder_monthly')}
                            onValueChange={(value, name) => {
                              props.changeData(
                                value,
                                "monthTxLimit",
                                "cardBalance"
                              );
                              setErrorMonthTimeTxLimit("");
                            }}
                          />
                          <p className="text-start md:text-base text-sm text-red pl-1 mb-0">
                            {errorMonthTimeTxLimit}
                          </p>
                        </div>
                        <div className="mb-3">
                          <label className="text-base font-medium text-customBlack mb-[8px]">
                            {t('virtual_card.kode_security')}
                          </label>
                          <span className="mx-1">
                            <CustomTooltip
                              content={
                                <>
                                  {t('virtual_card.user_set')}
                                  <br />
                                  {t('virtual_card.user_pin_transaction')}
                                  <br />
                                  {t('virtual_card.or_kode_security')}
                                  <br />
                                  {t('virtual_card.will_be_used')}
                                  <br />
                                  {t('virtual_card.payment_transaction')}
                                  <br />
                                  {t('virtual_card.used_card')}
                                </>
                              }
                              direction="bottom"
                            >
                              <button
                                type="button"
                                className="rounded-[50%] text-customBlack border-[1px] border-solid border-[#252525] text-sm px-2"
                              >
                                !
                              </button>
                            </CustomTooltip>
                          </span>
                          <div className="flex my-1">
                            <div className="form-check form-check-inline">
                              <input
                                checked={props.isActive}
                                onChange={() => props.setIsActive(true)}
                                className="w-4 h-4 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input text-newColor"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="Aktif"
                              />
                              <label
                                className="form-check-label inline-block text-newColor"
                                htmlFor="inlineRadio10"
                              >
                                {t('virtual_card.active')}
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                checked={!props.isActive}
                                disabled={props.isVirtualCardTransaction}
                                onChange={() => props.setIsActive(false)}
                                className="w-4 h-4 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input text-newColor"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="Tidak Aktif"
                              />
                              <label
                                className="form-check-label inline-block text-newColor"
                                htmlFor="inlineRadio20"
                              >
                                {t('virtual_card.not_active')}
                              </label>
                            </div>
                          </div>
                          {props.isActive && (
                            <>
                              <input
                                type="number"
                                placeholder={t('virtual_card.placeholder_register_pin')}
                                value={
                                  props.data.cardBalance.securityCode === null
                                    ? ""
                                    : props.data.cardBalance.securityCode
                                }
                                onChange={(e) => {
                                  const limit = 6;
                                  props.changeData(
                                    e.target.value.slice(0, limit),
                                    "securityCode",
                                    "cardBalance"
                                  );
                                  setErrorSecurityCode("");
                                }}
                                className="bg-white border-[0.5px] border-[#728F9E] text-newColor border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline"
                              />
                              <p className="text-start md:text-base text-sm text-red pl-1 mb-0">
                                {errorSecurityCode}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="mb-3">
                          <div className="flex items-center gap-[14px]">
                            <input
                              id="defauSelectedProductslt-checkbox"
                              type="checkbox"
                              value=""
                              checked={
                                props.data?.cardBalance?.balanceSync.isActive
                              }
                              onChange={() =>
                                props.changeData(
                                  !props.data?.cardBalance?.balanceSync
                                    .isActive,
                                  "balanceSync",
                                  "cardBalance",
                                  "isActive"
                                )
                              }
                              className="w-[18px] h-[18px] text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <h6 className="text-sm md:text-base text-newColor mb-0">
                              {t('virtual_card.sync_balance')}
                            </h6>
                          </div>
                          {props.data?.cardBalance?.balanceSync.isActive && (
                            <div className="flex flex-col ml-8 my-1">
                              <div className="form-check form-check-inline">
                                <input
                                  checked={
                                    props.data?.cardBalance?.balanceSync
                                      .balanceCode === "CREDIT"
                                  }
                                  onChange={() =>
                                    props.changeData(
                                      "CREDIT",
                                      "balanceSync",
                                      "cardBalance",
                                      "balanceCode"
                                    )
                                  }
                                  className="w-4 h-4 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input text-newColor"
                                  type="radio"
                                  value="Saldo Plafon"
                                />
                                <label className="form-check-label inline-block text-newColor">
                                  {t('virtual_card.balance_plafon')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  checked={
                                    props.data?.cardBalance?.balanceSync
                                      .balanceCode === "CLOSEPAY"
                                  }
                                  onChange={() =>
                                    props.changeData(
                                      "CLOSEPAY",
                                      "balanceSync",
                                      "cardBalance",
                                      "balanceCode"
                                    )
                                  }
                                  className="w-4 h-4 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input text-newColor"
                                  type="radio"
                                  value="Saldo Utama"
                                />
                                <label
                                  className="form-check-label inline-block text-newColor"
                                  htmlFor="inlineRadio20"
                                >
                                  {t('virtual_card.main_balance')}
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row gap-3 mt-4">
                          <button
                            type="button"
                            onClick={() => props.changeModalView("")}
                            className="bg-white text-newColor border-[0.5px] border-secondary border-solid font-medium  text-sm rounded-[10px] w-100 md:p-3 px-3 py-2"
                          >
                            {t('virtual_card.cancel')}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              props.setIsOpen("");
                              setIsCompleted({
                                ...isCompleted,
                                cardBalance: true,
                              });
                              props.setShowBalanceCard(true);
                            }}
                            style={{
                              backgroundColor: `${props.configColor?.color1}`,
                            }}
                            className=" text-white font-medium text-sm rounded-[10px] w-100 md:p-3 px-3 py-2 "
                          >
                            {t('virtual_card.save')}
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          <div className="w-full mx-auto pt-4 md:px-0 px-4">
            <button
              type="button"
              onClick={() => {
                if (props.showBalanceCard) {
                  if (
                    isCompleted["cardIdentifier"] &&
                    isCompleted["cardBalance"]
                  ) {
                    props.handleClick();
                  } else {
                    toast.error(t('virtual_card.required_complete_data'));
                  }
                } else {
                  if (isCompleted["cardIdentifier"]) {
                    props.handleClick();
                  } else {
                    toast.error(t('virtual_card.required_complete_data'));
                  }
                }
              }}
              style={{
                backgroundColor: `${props.company?.color?.color1 !== undefined
                  ? props.company?.color?.color1
                  : "#26A69A"
                  }`,
              }}
              className="text-white font-medium rounded-[10px] w-100 md:p-3 p-[12px]"
            >
              {t('virtual_card.next')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddVirtualCardWeb;
