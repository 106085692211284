/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TopUpCardBalanceWeb from "./topUpCardBalanceWeb";
import TopUpCardBalanceMobile from "./topUpCardBalanceMobile";
import { useNavigate, useParams } from "react-router-dom";
import * as API_VIRTUAL_CARD from "../../../core/service/api_virtualCard";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { toast } from "react-hot-toast";
import Company from "../../../core/models/company";
import { useTranslation } from "react-i18next";

const TopUpCardBalance = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const balance = useAppSelector((state) => state.balance.balance);
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [template, setTemplate] = React.useState("");
  const [background, setBackground] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [errorAmount, setErrorAmount] = React.useState("");
  const [isPortrait, setIsPortrait] = useState(false);

  const getTemplate = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.getTemplate()
      .then((res) => {
        dispatch(setLoading(false));
        setIsPortrait(res.data.isPotrait);
        setBackground(res.data.background);
        API_VIRTUAL_CARD.getCardDetail(params.id)
          .then((res) => {
            setTemplate(res.data.data.template);
          })
          .catch((err) => {
            console.log(err);
           });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = async () => {
    if (amount === "") {
      setErrorAmount(t('topup_card.nominal_topup'));
    } else if (parseInt(amount) > balance) {
      toast.error(t('topup_card.insufficient_balance'));
    } else {
      dispatch(setLoading(true));
      await API_VIRTUAL_CARD.inquiryTopUpCardBalance(
        params.id,
        parseInt(amount)
      )
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`summary`, {
            state: {
              data: res.data.data,
            },
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  React.useEffect(() => {
    getTemplate();
  }, []);

  return (
    <>
      <BrowserView>
        <TopUpCardBalanceWeb
          handleOnClick={() => handleOnClick()}
          setAmount={(value: any) => setAmount(value)}
          setErrorAmount={(value: any) => setErrorAmount(value)}
          company={company}
          template={template}
          background={background}
          amount={amount}
          errorAmount={errorAmount}
          isPortrait={isPortrait}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <TopUpCardBalanceMobile
          handleOnClick={() => handleOnClick()}
          setAmount={(value: any) => setAmount(value)}
          setErrorAmount={(value: any) => setErrorAmount(value)}
          company={company}
          template={template}
          background={background}
          amount={amount}
          errorAmount={errorAmount}
          isPortrait={isPortrait}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default TopUpCardBalance;
