import Header from "../_common/headerComponent";
import Countdown from "react-countdown";
import QRCode from "react-qr-code";
import { ConfigColor } from "../../config/global";
import bg from "../../assets/images/icon_features/bg_QR.svg";
import BottomLogo from "../_common/bottomLogo";
import AbsenResult from "../absenResultComponent";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";

const ScanQRMobile = (props: any) => {
  const color = ConfigColor();
  const company: Company = useAppSelector((state) => state.company.company);
  return (
    <>
      <div hidden={!props.visible}>
        <AbsenResult />
      </div>
      <div className="w-full min-h-screen relative font-montserrat bg-mobile">
        <Header navbarText="QR Barcode" />
        <div className="md:w-1/2 w-full mx-auto p-4">
          <div className="text-center mb-2">
            <div
              style={{
                backgroundImage: `url(${bg})`,
                backgroundColor: `${color.color1}`,
              }}
              className="bg-cover px-4 py-5 text-white rounded-xl min-h-[50vh] text-center"
            >
              <span className="md:text-lg text-base font-medium w-4/5 text-center mb-4">
                {props.t("accessibility.message_scan_qr")}
              </span>
              <div className="mx-auto flex w-52 h-52 my-10 bg-white p-4 rounded-lg">
                <QRCode
                  style={{ height: "100%", width: "100%" }}
                  value={props.valueQR}
                />
              </div>
              <Countdown
                key={props.timer}
                date={props.timer}
                renderer={props.renderer}
              />
            </div>
          </div>
        </div>
        {company?.showFtpLogo && <BottomLogo />}
      </div>
    </>
  );
};

export default ScanQRMobile;
