// import React, { Component, useEffect, useState } from "react";

import FooterProfileMobile from "../footerProfileMobile/footerComponent";

const ProfileMobile = () => {
  // const dataPassword = {
  //   passwordLama:"12345678",
  //   passwordBaru:"12345678",
  //   konfirmasiPasswordBaru:"12345678",
  // }
  return (
    <>
      <div className="relative h-auto">
        {/* <section>
          <div className="flex flex-row bg-white p-3 items-center">
            <img src={back} className="w-6 mr-3" />
            <h1 className="p-0 m-0 font-bold text-sm text-black">Profile</h1>
          </div>
        </section> */}
        <section className="px-6 pt-6 pb-2 h-screen">
          <div className="w-5/6 text-left mb-3">
            <h1 className=" font-poppins font-bold text-xl">Term of Service</h1>
          </div>
          <div className="bg-white rounded-2xl  flex flex-col ">
            <div className="py-1 px-3">
              <p className="text-xs font-poppins font-normal p-2 m-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Porttitor lectus ac ornare auctor quis facilisis tortor orci
                vel. A nunc cursus phasellus ultrices quam purus. Quis ac, risus
                dapibus sed amet. Nisi, nibh eu dapibus mauris nec, magna.
                Ullamcorper nunc lectus venenatis blandit. Posuere est id id
                mattis vivamus id non accumsan cursus. Facilisis consectetur
                fames volutpat egestas sagittis. Quam iaculis elementum faucibus
                arcu cursus arcu amet, pellentesque. Risus a facilisis nunc enim
                vitae nibh. Nunc dui habitant amet et. Ut dolor morbi auctor
                tellus varius quam. Massa lectus cras sed egestas ac tempus.
                Faucibus morbi suspendisse a libero. Elit dictum id vitae
                cursus. Aliquet tortor, enim egestas risus elementum. Nisl
                libero, in est, pellentesque. Enim, sit nisl dignissim malesuada
                aliquet et, integer. Nec, varius aliquam turpis eu in cursus
                integer nibh. Dignissim viverra elit facilisi et sagittis. Nec,
                mattis nunc laoreet donec aliquam, id aliquam habitasse.
              </p>
            </div>
          </div>
          <div className="flex mt-3 mb-1 p-2">
            <input className="mt-1 mr-2 w-4 h-4" type="checkbox" />
            <div className="flex">
              <label className="inline-block text-gray-800">
                I Agree to the term of the service
              </label>
              <label className="ml-1 text-red-600">*</label>
            </div>
          </div>
          <div className="w-full text-left mt-1">
            <button
              className="w-3/6 h-8 p-1 mt-2 mb-2 rounded-xl text-white text-base"
              style={{ backgroundColor: "#FFD400" }}
            >
              Agree
            </button>
          </div>
        </section>

        <FooterProfileMobile></FooterProfileMobile>
      </div>
    </>
  );
};

export default ProfileMobile;
