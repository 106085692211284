import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InputPinMobile from "./inputPinMobile";
import InputPinWeb from "./inputPinWeb";

const InputPin = () => {
  return (
    <>
      <BrowserView>
        <InputPinWeb></InputPinWeb>
      </BrowserView>
      <MobileView>
        <InputPinMobile></InputPinMobile>
      </MobileView>
    </>
  );
};

export default InputPin;
