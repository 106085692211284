import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
import { bearer } from "../../config/global";

const axios = require("axios");
const baseUrlDev = Config.BaseUrlDev;

export const scanQrCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}access/qr/listen`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getStatusQrAbsen = (id, query) => {
  let url = `${baseUrlDev}access/qr/listen/${id}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const scanBarcode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}access/attendance/qr-present/_validate?companyId=string&companyInitial=string&email=string&userType=SUPERADMIN&userId=string`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const listAttendance = (query) => {
  let url = `${baseUrlDev}access/member/attendance-record?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const listMenu = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}access/member/config-menu?companyId=string&companyInitial=string&email=string&userType=SUPERADMIN&userId=string`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const attendancePresent = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}access/attendance/qr-present/_validate?companyId=string&companyInitial=string&email=string&userType=SUPERADMIN&userId=string`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const attendancePresentOfflineMode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}access/attendance/qr-present/_validate/offline-mode?companyId=string&companyInitial=string&email=string&userType=SUPERADMIN&userId=string`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const listActivity = (page) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}access/member/attendance-activity?companyId=string&companyInitial=string&email=string&userType=SUPERADMIN&userId=string&size=10&page=${page}&sortBy=-createdDate`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const entrancePresent = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}access/entrance/qr-present/_validate?companyId=string&companyInitial=string&email=string&userType=SUPERADMIN&userId=string`,
        data,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const listEntranceActivity = (page) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}access/member/entrance-activity?companyId=string&companyInitial=string&email=string&userType=SUPERADMIN&userId=string&size=10&page=${page}&sortBy=-createdDate`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const listEntrance = (query) => {
  let url = `${baseUrlDev}access/member/entrance-record?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const listParking = (query) => {
  let url = `${baseUrlDev}access/member/parking-record?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getParkingDetail = (parkingId) => {
  let url = `${baseUrlDev}access/member/parking-record/${parkingId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const parkingPaymentCheckout = (data) => {
  let url = `${baseUrlDev}access/member/parking-controller/payment`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const listPayGate = (query) => {
  let url = `${baseUrlDev}access/member/paygate-record?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const payGateScan = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}access/paygate/balance/summary`, "", {
        headers: {
          qrId: data.qrId,
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const payGatePayment = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}access/paygate/balance/payment`, data, {
        headers: {
          ...data,
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const entranceDetailById = (id) => {
  let url = `${baseUrlDev}access/member/entrance-record/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSubcriptions = (query) => {
  let url = `${baseUrlDev}access/member/subscription-parking?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSubcriptionConfig = (id) => {
  let url = `${baseUrlDev}access/member/subscription-parking/_config`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SubcriptionPayment = () => {
  let url = `${baseUrlDev}access/member/subscription-parking/payment`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`,"", {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SubcriptionActive = () => {
  let url = `${baseUrlDev}access/member/subscription-parking/active`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

