import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import { useTranslation } from "react-i18next";

const SecurityCodeMobile = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={t('securityCodeWeb.title')}></Header>
      <div className="container md:mt-[64px] mt-[0px] px-4">
        <div className="md:w-1/2 w-full text-left mb-3">
          <h1 className="font-montserrat font-bold md:text-3xl text-xl text-[#252525] ">
            {t('securityCodeWeb.title')}
          </h1>
          <h5 className="font-montserrat font-normal md:text-lg text-sm text-[#252525]">
            {t('securityCodeWeb.description')}
          </h5>
        </div>

        <div className="md:w-1/2 w-full">
          <div
            className="flex flex-row justify-between items-center my-4 md:p-[1.5rem] p-3 cursor-pointer bg-white rounded-lg shadow-sm"
            onClick={() =>
              navigate(`/${company?.initial}/profile/security-code/change`)
            }
          >
            <div>
              <h5 className="grow md:text-lg text-sm font-normal text-[#252525] mb-0 font-montserrat">
                {t('securityCodeWeb.changePin')}
              </h5>
            </div>
          </div>
          <div
            className="flex flex-row justify-between items-center my-4 md:p-[1.5rem] p-3 cursor-pointer bg-white rounded-lg shadow-sm"
            onClick={() =>
              navigate(`/${company?.initial}/profile/security-code/forget`)
            }
          >
            <div>
              <h5 className="grow md:text-lg text-sm font-normal text-[#252525] mb-0  font-montserrat">
                {t('securityCodeWeb.forgetPin')}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecurityCodeMobile;
