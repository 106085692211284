/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { onlyNumber } from "../../../config/global";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import * as API_BALANCE from "../../../core/service/api_balance";
import Header from "../../_common/headerComponent";
import LinkedAccountCard from "../../_common/linkedAccountCard";
import uuid from "react-uuid";
import User from "../../../core/models/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const InputNumberMobile = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  let location: any = useLocation();
  const [data, setData] = useState<any>({});
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const [visible, setVisible] = useState(false);

  const handleOnClick = async () => {
    if (data.code === "BANK_DKI") {
      console.log(uuid().replace(/-/g, ""));

      if (username === "") {
        setErrorText("username");
      } else if (password === "") {
        setErrorText("password");
      } else if (password.length < 6) {
        toast.error("Password/ PIN Harus 6 Digit");
      } else if (phoneNumber === "") {
        setErrorText("phoneNumber");
      } else if (email === "") {
        setErrorText("email");
      } else {
        dispatch(setLoading(true));
        let dataOrder = {
          username,
          password,
          phoneNumber,
          email,
          imei: uuid()
            .concat(user.noId as string)
            .replace(/\D/g, ""),
        };
        await API_BALANCE.createOtpActivationBankDKI(dataOrder)
          .then((res) => {
            navigate(`/${company?.initial}/balance/connect-account/input-otp`, {
              state: {
                data,
                dataOrder,
              },
            });
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
            console.log(err);
          });
      }
    } else if (data.code === "LINK_AJA") {
      dispatch(setLoading(true));
      let dataOrder = {
        customerNumber: phoneNumber,
      };
      await API_BALANCE.connectLinkAjaAccount(dataOrder)
        .then((res) => {
          dispatch(setLoading(false));
          let dataOrder = {
            url: res.data.data.url,
            token: res.data.data.token,
          };
          navigate("redirect", { state: dataOrder });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  useEffect(() => {
    setData(location.state);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={t("balance.connectAccount")} />
      <div className="md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto mb-10">
          <LinkedAccountCard data={data} />
          <div className="mt-12 w-full mx-auto">
            <div className="text-center mb-3">
              <h6 className="md:text-lg text-base text-customBlack font-semibold mb-0">
                {t("balance.providers.easyPay")}
              </h6>
              <p className="md:text-base text-sm text-customBlack font-light mb-0">
                {t("balance.providers.description")}
              </p>
            </div>
            {data.code === "BANK_DKI" && (
              <Fragment>
                <div className="mb-3">
                  <label className="md:text-lg text-base text-customBlack font-medium mb-2">
                    {t("balance.providers.username")}
                  </label>
                  <input
                    value={username}
                    type="text"
                    onChange={(e) => {
                      setUsername(e.target.value);
                      setErrorText("");
                    }}
                    className="w-full bg-white border-[#D9D9D9] rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-[#D9D9D9] shadow-sm"
                  />
                  {errorText === "username" && (
                    <p className="md:text-base text-sm text-red pl-1 mb-0">
                      {t("balance.providers.username")}
                    </p>
                  )}
                </div>
                <div className="mb-3">
                  <label className="md:text-lg text-base text-customBlack font-medium mb-2">
                    {t("balance.providers.password")}
                  </label>
                  <div className="relative flex items-center">
                    <input
                      value={password}
                      type={visible ? "number" : "password"}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setErrorText("");
                      }}
                      maxLength={6}
                      onKeyPress={onlyNumber}
                      className="w-full bg-white border-[#D9D9D9] rounded-[6px] border-[0.5px] border-solid focus:outline outline-[#D9D9D9] shadow-sm md:py-[1rem] py-[12px] pl-3 pr-6"
                    />
                    <button type="button" onClick={() => setVisible(!visible)}>
                      {visible ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          size="lg"
                          color="#C4C4C4"
                          className="absolute ml-3 top-[17px] right-6"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          size="lg"
                          color="#C4C4C4"
                          className="absolute ml-3 top-[17px] right-6"
                        />
                      )}
                    </button>
                  </div>
                  {errorText === "password" && (
                    <p className="md:text-base text-sm text-red pl-1 mb-0">
                      {t("balance.providers.password")}
                    </p>
                  )}
                </div>
                <div className="mb-3">
                  <label className="md:text-lg text-base text-customBlack font-medium mb-2">
                    {t("balance.providers.phone")}
                  </label>
                  <input
                    value={phoneNumber}
                    type="number"
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setErrorText("");
                    }}
                    onKeyPress={onlyNumber}
                    className="w-full bg-white border-[#D9D9D9] rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-[#D9D9D9] shadow-sm"
                  />
                  {errorText === "phoneNumber" && (
                    <p className="md:text-base text-sm text-red pl-1 mb-0">
                      {t("balance.providers.phone")}
                    </p>
                  )}
                </div>
                <div className="mb-3">
                  <label className="md:text-lg text-base text-customBlack font-medium mb-2">
                    {t("balance.providers.email")}
                  </label>
                  <input
                    value={email}
                    type="text"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrorText("");
                    }}
                    className="w-full bg-white border-[#D9D9D9] rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-[#D9D9D9] shadow-sm"
                  />
                  {errorText === "email" && (
                    <p className="md:text-base text-sm text-red pl-1 mb-0">
                      {t("balance.providers.email")}
                    </p>
                  )}
                </div>
              </Fragment>
            )}
            {data.code === "LINK_AJA" && (
              <div className="text-center">
                <label className="md:text-lg text-base text-customBlack font-medium mb-2">
                  {t("balance.providers.phone")}
                </label>
                <input
                  value={phoneNumber}
                  type="number"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    setErrorText("");
                  }}
                  onKeyPress={onlyNumber}
                  className="w-full bg-white text-center border-[#D9D9D9] rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-[#D9D9D9] shadow-sm"
                />
                {errorText === "phoneNumber" && (
                  <p className="md:text-base text-sm text-red pl-1 mb-0">
                    {t("balance.providers.phone")}
                  </p>
                )}
              </div>
            )}
          </div>
          <div
            className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <button
              type="button"
              onClick={() => handleOnClick()}
              style={{
                backgroundColor:
                  company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
              }}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              {t("balance.providers.next")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InputNumberMobile;
