import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBInternetTVSuccessWeb from "./ppobInternetTVSuccessWeb";
import PPOBInternetTVSuccessMobile from "./ppobInternetTVSuccessMobile";

const PPOBInternetTVSuccess = () => {
  return (
    <>
      <BrowserView>
        <PPOBInternetTVSuccessWeb></PPOBInternetTVSuccessWeb>
      </BrowserView>
      <MobileView>
        <PPOBInternetTVSuccessMobile></PPOBInternetTVSuccessMobile>
      </MobileView>
    </>
  );
};

export default PPOBInternetTVSuccess;
