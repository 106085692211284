import ProgressBar from "@ramonak/react-progress-bar";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate, useParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import DocumentIcon from "../../assets/images/Iconly/Bold/Document.svg";
import DownloadIcon from "../../assets/images/Iconly/Bold/Download.svg";
import HeartIcon from "../../assets/images/Iconly/Bold/Heart.svg";
import ProfileIcon from "../../assets/images/Iconly/Bold/Profile.svg";
import arrowRightIcon from "../../assets/images/Iconly/Light-Outline/ArrowRight.svg";
import NoImage from "../../assets/images/empty.jpg";
import LogoIcon from "../../assets/images/icon_closepay/LOGOCP.svg";
import VerifiedIcon from "../../assets/images/icon_donation/Verified.svg";
import {
  ConfigColor,
  formatCurrency,
  getDonationTransaction,
  imageOnError,
} from "../../config/global";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import {
  getDonationGeneral
} from "../../core/service/api_donation";
import Header from "../_common/headerComponent";
import CustomTooltip from "../_common/tooltip";
// import "./style.scss";

export const DonationDetailGeneralMobile = () => {
  const color = ConfigColor();
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const params = useParams();
  // const [isLoading, setIsLoading] = useState(false);
  const [toggleSetting, setToggleSetting] = useState(false);
  const [data, setData] = useState<any>({});
  const [hideButton, setHideButton] = useState(true);

  const getData = () => {
    setLoading(true);
    getDonationGeneral(company.initial)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getLastDate = (arr: any[] = [], key = "date") => {
    let firstArray = _.first(arr);
    if (firstArray)
      return moment(new Date(firstArray[key])).format("LL") || "-";
  };


  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let hDesc = document.getElementById("hDesc");
    setHideButton(true);
    if ((hDesc?.offsetHeight as number) > 150) setHideButton(false);
  }, [data]);
  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <Header navbarText="Detail Donasi"></Header>
        <div className="h-full m-auto font-Montserrat">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                {data && data.imageUrl && (
                  <div className="rounded-md h-[25vh] overflow-auto">
                    <img
                      src={data.imageUrl[0] ? data.imageUrl[0] : NoImage}
                      className="bg-[#D9D9D9] rounded-md w-full "
                      onError={imageOnError}
                      alt=""
                    />
                  </div>
                )}
                <div className="text-[#252525] text-base font-semibold">
                  {data?.title}
                </div>

                <div
                  className="text-themePrimary text-base font-semibold"
                  style={{ color: color.color1 }}
                >
                  Terkumpul {formatCurrency(data?.total_dana)}
                </div>
                <div className="text-[#252525] text-xs font-light flex justify-between">
                  <span>
                    Target :&nbsp;
                    <span className="font-semibold">
                      {formatCurrency(data?.fundTarget)}
                    </span>
                  </span>
                  <span className="md:text-base text-xs font-semibold">
                    {moment(data?.endDate).diff(moment(new Date()), "days") >= 0
                      ? `${
                          moment(data?.endDate).diff(
                            moment(new Date()),
                            "days"
                          ) + " Hari Lagi"
                        }`
                      : "expired"}
                  </span>
                </div>
                <div>
                  <ProgressBar
                    isLabelVisible={false}
                    completed={Math.ceil(
                      (data?.total_dana / data?.fundTarget) * 100
                    )}
                    height="5px"
                    bgColor={color.color1}
                  />
                </div>
                <div className="text-[#252525] text-sm font-semibold flex justify-between">
                  <div className="border-solid border-[#94959e58] border-r-[1px] w-1/3 flex flex-col justify-between items-center gap-2">
                    <div className="flex gap-2">
                      <img src={HeartIcon} alt="Donation.svg" />
                      <span> {data?.transaction?.length}</span>
                    </div>
                    <span className="font-light text-xs">Donasi</span>
                  </div>
                  <div className="border-solid border-[#94959e58] border-r-[1px] w-1/3 flex flex-col justify-between items-center gap-2">
                    <div className="flex gap-2">
                      <img src={DocumentIcon} alt="Document.svg" />
                      <span>{data?.progress?.length}</span>
                    </div>
                    <span className="font-light text-xs">Kabar Terbaru</span>
                  </div>
                  <div className="w-1/3 flex flex-col justify-between items-center gap-2">
                    <div className="flex gap-2">
                      <img src={DownloadIcon} alt="Download.svg" />
                      <span> {data?.disbursement?.length}</span>
                    </div>
                    <span className="font-light text-xs">Pencairan Dana</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full m-auto font-Montserrat">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                <div className="font-semibold">Informasi</div>

                <div className="border-solid border-[.3px] border-[#94959e8f] rounded-md px-3 py-4 text-sm font-semibold">
                  <span>Yayasan</span>
                  <div className="mt-2">
                    <span className="flex gap-2 items-center">
                      <img src={LogoIcon} alt="" />
                      <span>{data.foundationName}</span>
                      <img src={VerifiedIcon} alt="Verified.svg" />
                    </span>
                    {/* <span className="text-xs font-light">
                      {data.description}
                    </span> */}
                  </div>
                  <hr />
                  <span>Penerima</span>
                  <div className=" flex flex-col">
                    <span className="flex gap-2 font-medium">
                      <span className="min-w-[150px]">Tujuan Donasi</span>
                      <div className="flex gap-1">
                        <span>:</span>
                        <span>{data.donationGoals}</span>
                      </div>
                    </span>
                    <span className="flex gap-2 font-medium">
                      <span className="min-w-[150px]">Lokasi</span>
                      <div className="flex gap-1">
                        <span>:</span>
                        <span>{data.location}</span>
                      </div>
                    </span>
                    <span className="flex gap-2 font-medium">
                      <span className="min-w-[150px]">Nama Penerima</span>
                      <div className="flex gap-1">
                        <span>:</span>
                        <span>{data.donationRecipient}</span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* LATAR BELAKANG */}
        <div className="h-full m-auto font-Montserrat">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                {/* </div>
              <div className="border-solid border-[.3px] border-[#94959e8f] rounded-md px-3 py-4 text-sm font-semibold"> */}
                <span className="font-semibold">Latar Belakang</span>
                <div className="flex gap-2 flex-wrap">
                  {_.map(
                    _.filter(data.imageUrl, (item) => item),
                    (item) => (
                      <div className=" rounded-md w-1/4 min-w-[100px] max-w-[150px] max-h-[80px] overflow-auto ">
                        <img
                          src={item}
                          alt=""
                          onError={imageOnError}
                          className="bg-[#D9D9D9] rounded-md w-full"
                        />
                      </div>
                    )
                  )}
                </div>
                <div className="relative flex justify-start">
                  <div className="hDesc absolute h-fit" id="hDesc">
                    {data.description}
                  </div>

                  <button
                    className="absolute bottom-0 h-10 flex justify-center items-center w-fit  py-1 rounded-md text-themePrimary"
                    hidden={hideButton}
                    onClick={() => setToggleSetting(true)}
                  >
                    Lihat Selengkapnya...
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-full m-auto font-Montserrat pb-28">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                {/* PROGRESS */}
                <div
                  className="flex justify-between py-4 border-solid border-b-[.3px] border-[aaa]"
                  onClick={() =>
                    navigate(
                      `/${company?.initial}/donation-general/detail/progress`,
                      {
                        state: {
                          foundationName: data?.foundationName,
                          progress: data?.progress,
                        },
                      }
                    )
                  }
                >
                  <div>
                    <div className="flex gap-2 font-bold">
                      <span className="text-base">Kabar Terbaru</span>
                      <span
                        className="bg-[#E6F5FB] text-themePrimary rounded-xl px-3 flex justify-center items-center text-xs "
                        style={{ color: color.color1 }}
                      >
                        {data?.progress?.length}
                      </span>
                    </div>
                    <div className="flex gap-2 text-xs font-light">
                      <span className="flex gap-1 items-center">
                        Terakhir Update
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </span>
                      <span>{getLastDate(data?.progress)}</span>
                    </div>
                  </div>
                  <img src={arrowRightIcon} alt="" />
                </div>
                {/* DISBURSMENT */}
                <div
                  className="flex justify-between py-4 border-solid border-b-[.3px] border-[aaa]"
                  onClick={() =>
                    navigate(
                      `/${company?.initial}/donation-general/detail/disbursement`,
                      {
                        state: {
                          foundationName: data?.foundationName,
                          disbursement: data?.disbursement,
                          total_dana: data?.total_dana,
                          total_funder: data?.total_funder,
                          total_transaction: data?.transaction?.length,
                        },
                      }
                    )
                  }
                >
                  <div>
                    <div className="flex gap-2 font-bold">
                      <span className="text-base">Pencairan Dana</span>
                      <span
                        className="bg-[#E6F5FB] text-themePrimary rounded-xl px-3 flex justify-center items-center text-xs "
                        style={{ color: color.color1 }}
                      >
                        {data?.disbursement?.length}
                      </span>
                    </div>
                    <div className="flex gap-2 text-xs font-light">
                      <span className="flex gap-1 items-center">
                        Terakhir Pencairan Dana
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </span>
                      <span>{getLastDate(data?.disbursement)}</span>
                    </div>
                  </div>
                  <img src={arrowRightIcon} alt="" />
                </div>
                {/* TRANSACTION */}
                <div
                  className="flex justify-between py-4 border-solid border-b-[.3px] border-[aaa]"
                  onClick={() =>
                    navigate(
                      `/${company?.initial}/donation-general/detail/transaction`
                    )
                  }
                >
                  <div>
                    <div className="flex gap-2 font-bold">
                      <span className="text-base">Donasi</span>
                      <span
                        className="bg-[#E6F5FB] text-themePrimary rounded-xl px-3 flex justify-center items-center text-xs "
                        style={{ color: color.color1 }}
                      >
                        {data?.transaction?.length}
                      </span>
                    </div>
                    <div className="flex gap-2 text-xs font-light">
                      <span className="flex gap-1 items-center">
                        Terakhir Donasi
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </span>
                      <span>
                        {getLastDate(data?.transaction, "createdTime")}
                      </span>
                    </div>
                  </div>
                  <img src={arrowRightIcon} alt="" />
                </div>

                {_.map(
                  getDonationTransaction(
                    data?.transaction,
                    "createdTime",
                    "desc",
                    1
                  ),
                  (item) => (
                    <div
                      key={item._id}
                      className="flex w-full max-h-[100px] bg-themeGray rounded-md shadow-sm shadow-gray-700 gap-1 p-2"
                    >
                      <div className="md:w-1/6 w-1/4 flex justify-center items-center">
                        <div className="flex justify-center items-center rounded-full w-12 h-12 bg-[#D9D9D9]">
                          <img
                            className=" bg-contain w-5 h-5"
                            onError={imageOnError}
                            src={ProfileIcon}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="w-[calc(100%-8rem)] flex flex-col flex-grow justify-between">
                        <CustomTooltip
                          content={
                            <div className="max-w-full whitespace-pre-line">
                              {item?.nameOfUser}
                            </div>
                          }
                          direction="bottom"
                          children={
                            <div className="md:text-base text-sm text-customBlack font-semibold">
                              {item?.nameOfUser.length > 30
                                ? item?.nameOfUser.toString().substring(0, 30) +
                                  "..."
                                : item?.nameOfUser}
                            </div>
                          }
                        ></CustomTooltip>
                        {/* <div>{item?.nameOfUser}</div> */}
                        <div className="flex gap">
                          <span className="text-sm font-light">
                            Berdonasi Sebesar&nbsp;
                          </span>
                          <span className="text-sm font-semibold">
                            {formatCurrency(item?.amount)}
                          </span>
                        </div>
                        <div className="flex">
                          <span className="text-xs font-light">
                            Pesan&nbsp;:&nbsp;
                          </span>
                          <span className="text-xs font-semibold">
                            {item?.message || "-"}
                          </span>
                        </div>
                        <div className="flex">
                          <span className="text-[10px] font-light">
                            {moment(new Date(item?.createdTime)).fromNow()}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 md:px-5 py-4 md:py-7 fixed bottom-0 bg-white shadow-button w-full">
          {moment(data?.endDate).diff(moment(new Date()), "days") < 0 ? (
            <button
              disabled
              type="button"
              className="bg-gray-500 w-full md:py-[16px] p-[10px] rounded-lg text-white font-semibold md:text-base text-sm disabled:bg-slate-50 md:mt-6 mt-[1rem]"
            >
              Donasi Sudah Berakhir
            </button>
          ) : (
            <button
              onClick={() => {
                navigate(
                  `/${company?.initial}/donation-general/detail/add`
                );
              }}
              type="button"
              className="bg-themePrimary text-white w-full h-10 rounded-md"
              style={{ backgroundColor: color.color1 }}
            >
              Donasi Sekarang
            </button>
          )}
        </div>

        <BottomSheet
          onDismiss={() => setToggleSetting(false)}
          open={toggleSetting}
          className="h-full"
        >
          <div className="px-3 py-4 flex flex-col gap-4 ">
            <div className="font-semibold ">Latar Belakang</div>
            <div className="flex flex-row flex-wrap gap-2">
              {data?.description}
            </div>
            <div className="flex flex-col gap-2 mt-4">
              <button
                onClick={() => {
                  setToggleSetting(false);
                }}
                type="button"
                className="bg-themePrimary text-white w-full h-10 rounded-md"
              >
                Tutup
              </button>
            </div>
          </div>
        </BottomSheet>
      </section>
    </>
  );
};
