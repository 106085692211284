/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "@ramonak/react-progress-bar";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { useNavigate } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import DocumentIcon from "../../assets/images/Iconly/Light-Outline/Document.svg";
import filterIcon from "../../assets/images/Iconly/Light-Outline/Group 10.svg";
import searchIcon from "../../assets/images/Iconly/Light-Outline/Search.svg";
import NoImage from "../../assets/images/empty.jpg";
import { camelCase, formatCurrency } from "../../config/global.js";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company.js";
import { getDonations } from "../../core/service/api_donation";
import DataNotFound from "../_common/dataNotFound";
import Header from "../_common/headerComponent";
import CustomTooltip from "../_common/tooltip";
import { hexToRgba, ConfigColor } from "../../config/global.js";
import { useTranslation } from "react-i18next";
import BalitaIcon from "../../assets/images/icon/balita.svg";
import AlamIcon from "../../assets/images/icon/bencana.svg";
import DifabelIcon from "../../assets/images/icon/tabler_disabled.svg";
import HewanIcon from "../../assets/images/icon/guidance_service-animal-2.svg";
import IbadahIcon from "../../assets/images/icon/worship.svg";
import KemanusiaanIcon from "../../assets/images/icon/kemanusiaan.svg";
import LingkunganIcon from "../../assets/images/icon/lingkungan.svg";
import MedisIcon from "../../assets/images/icon/medic.svg";
import PantiIcon from "../../assets/images/icon/panti.svg";
import PendidikanIcon from "../../assets/images/icon/pendidikan.svg";
import SeniIcon from "../../assets/images/icon/ideas.svg";
import SosialIcon from "../../assets/images/icon/social.svg";
import UmumIcon from "../../assets/images/icon/infrastruktur.svg";

const DonationWeb = () => {
  const { t } = useTranslation();
  const color = ConfigColor();
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setsize] = useState(10);
  const [page, setpage] = useState(1);
  const [sortBy] = useState("createdTime");
  const [dir, setdir] = useState(-1);
  const [name, setname] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectActive, setSelectActive] = useState("Active");
  const [listActive] = useState(["Active", "Expired", "All"]);
  const donationCategories = [
    {
      value: t("donation.cataclysm"),
      icon: AlamIcon,
    },
    {
      value: t("donation.socialActivities"),
      icon: SosialIcon,
    },
    {
      value: t("donation.publicInfrastructure"),
      icon: UmumIcon,
    },
    {
      value: t("donation.environment"),
      icon: LingkunganIcon,
    },
    {
      value: t("donation.educationalAssistance"),
      icon: PendidikanIcon,
    },
    {
      value: t("donation.sickToddlers"),
      icon: BalitaIcon,
    },
    {
      value: t("donation.medicalHealth"),
      icon: MedisIcon,
    },
    {
      value: t("donation.disabled"),
      icon: DifabelIcon,
    },
    {
      value: t("donation.helpingAnimals"),
      icon: HewanIcon,
    },
    {
      value: t("donation.creativeBusiness"),
      icon: SeniIcon,
    },
    {
      value: t("donation.houseWorship"),
      icon: IbadahIcon,
    },
    {
      value: t("donation.humanity"),
      icon: KemanusiaanIcon,
    },
    {
      value: t("donation.orphanage"),
      icon: PantiIcon,
    },
  ];
  const [categories] = useState([...donationCategories]);

  const changeDate = (item: any) => {
    setSelectedDate([item?.selection]);
    setStartDate(moment(item?.selection.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(item?.selection.endDate).format("YYYY-MM-DD"));
  };
  const resetFilter = () => {
    setdir(-1);
    setSelectCategory("");
    setStartDate("");
    setEndDate("");

    setSelectedDate([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const [data, setdata] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [totalElement, setTotalElement] = useState(0);

  const getDatas = (
    reset: boolean = false,
    varPage: any = page,
    varSize: any = size,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    if (selectActive !== "All") query["isExpired"] = selectActive !== "Active";
    setIsLoading(true);
    getDonations(query)
      .then((res) => {
        setIsLoading(false);
        setdata(res.data.data.data);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.total);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getMoreDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    if (selectActive !== "All") query["isExpired"] = selectActive !== "Active";
    setIsLoading(true);
    getDonations(query)
      .then((res) => {
        setIsLoading(false);
        setdata([...data, ...res.data.data.data]);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.total);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // const handleRemainingDays2 = (date: any) => {
  //   let dateA = moment(new Date(date));
  //   let dateB = moment(new Date());
  //   let dateDiff = dateB.diff(dateA, "days");
  //   return dateDiff > -1 ? `${dateDiff} Hari Lagi` : "Expired";
  // };

  function currentSize() {
    let a = data.length;
    let b = a / page;
    return b;
  }

  useEffect(() => {
    // getDatas();
    getDatas(true, 1);
  }, [selectActive]);

  useEffect(() => {
    getDatas();
    currentSize();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => getDatas(true), 1000);
    return () => clearTimeout(timeOutId);
  }, [name]);

  return (
    <>
      <section className="h-full min-h-[calc(100vh-104px)] font-poppins bg-mobile">
        <Header backToUrl={`/${company?.initial}/donations`} />
        <div className=" h-full m-auto w-2/5 lg:w-1/2  min-w-[30rem] font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100">
              <div className="flex justify-end mb-4 gap-1"></div>
              <div className="flex gap-2 md:mx-0 mx-6 mb-2">
                <div className="flex-grow">
                  <img
                    src={searchIcon}
                    alt=""
                    className="absolute translate-x-1/2 translate-y-1/2"
                  />
                  <input
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                    type="search"
                    className="pl-10 w-full rounded-md p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 "
                    placeholder={t("donation.placeholderSearch")}
                  />
                </div>
                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="flex justify-center items-center w-14 border-[#9a99994d] border-solid border-[1px] bg-white rounded-md"
                >
                  <img src={filterIcon} alt="" />
                </button>
              </div>
              <div className="flex justify-between gap-1">
                <div className="flex justify-start gap-1">
                  {_.map(listActive, (item) => (
                    <div className="flex flex-col w-16 text-center items-center ">
                      <div
                        onClick={() => {
                          setSelectActive(item);
                        }}
                        className={`flex min-w-[4rem] p-2 rounded-xl border-[1px] border-solid select-none cursor-pointer ${selectActive === item
                          ? "border-accentGreen bg-accentDarkGreen"
                          : "border-[#70717d80] bg-white"
                          }`}
                        style={{
                          borderColor:
                            selectActive === item ? color.color1 : "#70717d80",
                          backgroundColor:
                            selectActive === item
                              ? hexToRgba(color.color1, 0.1)
                              : "#FFFFFF",
                        }}
                      >
                        {/* <img src={category.icon} alt="" className="m-auto" /> */}
                        <span className="text-[10px] text-[#252525] font-light">
                          {camelCase(item, "_")}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <CustomTooltip
                  content={"Riwayat"}
                  direction="bottom"
                  children={
                    <button
                      type="button"
                      onClick={() => navigate("history")}
                      // className="bg-white rounded-[18px] p-[10px]"
                      className="flex justify-center w-14 p-1 rounded-md border-[1px] border-solid bg-white"
                    >
                      <img src={DocumentIcon} alt="Document.svg" />
                    </button>
                  }
                ></CustomTooltip>
              </div>
              {data.length > 0 ? (
                <div className="md:bg-transparent bg-white md:px-0 px-6">
                  <div className="flex flex-col gap-[0px] md:gap-[16px] my-4 divide-y-2">
                    {_.map(data, (item) => (
                      <div
                        key={item._id}
                        onClick={() => {
                          navigate(
                            `/${company?.initial}/donations/donation/detail/${item._id}`
                          );
                        }}
                        className="flex w-full h-[143px] bg-white md:rounded-md md:shadow-sm shadow-gray-700 gap-3 py-3 md:px-3 cursor-pointer"
                      >
                        <img
                          src={item.imageUrl[0] ? item.imageUrl[0] : NoImage}
                          className="md:w-1/4 sm:w-1/3 w-1/2"
                          alt=""
                        />
                        <div className="flex flex-col flex-grow justify-between ">
                          <CustomTooltip
                            // className={"grow max-w-[calc(100%-7rem)] "}
                            content={
                              <div className="max-w-full whitespace-pre-line">
                                {item.title}
                              </div>
                            }
                            direction="bottom"
                            children={
                              <div className="md:text-base text-xs font-semibold">
                                {item?.title.length > 100
                                  ? item?.title.toString().substring(0, 100) +
                                  "..."
                                  : item?.title}
                              </div>
                            }
                          ></CustomTooltip>
                          {/* <div className="md:text-base text-xs font-semibold">
                            {item.title}
                          </div> */}
                          <div className="md:text-sm text-[10px] font-light">
                            {item.foundationName}
                          </div>
                          <ProgressBar
                            isLabelVisible={false}
                            completed={Math.ceil(
                              (item.total_dana / item.fundTarget) * 100
                            )}
                            height="5px"
                            bgColor={color.color1}
                          />
                          <div className="flex flex-row justify-between">
                            <div className="flex flex-col">
                              <span className="md:text-sm text-[10px] font-light">
                                {t("donation.collected")}
                              </span>
                              <span className="md:text-base text-xs font-semibold">
                                {formatCurrency(item.total_dana)}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="md:text-sm text-[10px] font-light">
                                {t("donation.remaining")}
                              </span>
                              <span className="md:text-base text-xs font-semibold">
                                {moment(item.endDate).diff(
                                  moment(new Date()),
                                  "days"
                                ) >= 0
                                  ? `${moment(item.endDate).diff(
                                    moment(new Date()),
                                    "days"
                                  ) + " Hari Lagi"
                                  }`
                                  : "Expired"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {currentSize() >= 10 && totalElement > 10 ? (
                      <div
                        className={`bg-themePrimary rounded-md py-2 text-center text-sm text-white font-semibold ${isLoading ? "cursor-progress" : "cursor-pointer"
                          }`}
                        style={{ backgroundColor: color.color1 }}
                        onClick={() => {
                          if (!isLoading) {
                            getMoreDatas(false, size, page + 1);
                            setpage(page + 1);
                          }
                        }}
                      >
                        {isLoading ? t('donation.waiting') : "Load More...."}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <DataNotFound title={t('donation.noData')} />
              )}
            </div>
          </div>
        </div>

        {/* Modal switch */}
        <div
          className={`${showModal ? "block" : "hidden"} relative z-50`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[50%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
                <div className="text-end md:px-[0px] px-[0.5rem]">
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className="fa-xl text-gray-400"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <div className="text-left mb-2">
                  {" "}
                  <div className="px-3 py-4">
                    <div className="md:text-xl text-lg font-semibold">
                      Filter
                    </div>
                    <div className="max-h-96 overflow-y-auto">
                      <div className="my-10">
                        <h6 className="text-[#70717D] md:text-sm text-xs font-medium">
                          {t("donation.date")}
                        </h6>
                        <DateRange
                          // className="w-full"
                          editableDateInputs={true}
                          onChange={(item) => changeDate(item)}
                          moveRangeOnFirstSelection={false}
                          ranges={selectedDate}
                          maxDate={new Date()}
                        />
                      </div>
                      <h6 className="text-[#70717D] md:text-sm text-xs font-medium">
                        {t("donation.category")}
                      </h6>
                      <div className="grid grid-cols-4 md:grid-cols-5 gap-2">
                        {_.map(categories, (category) => (
                          <div className="flex flex-col w-16 text-center items-center">
                            <div
                              onClick={() => {
                                setSelectCategory(category.value);
                              }}
                              className={`flex w-12 h-12 rounded-md border-[1px] border-solid ${selectCategory === category.value
                                ? "border-accentGreen bg-accentDarkGreen"
                                : "border-[#70717d80] bg-white"
                                }`}
                            >
                              <img
                                src={category.icon}
                                alt=""
                                className="m-auto"
                              />
                            </div>
                            <span className="text-[10px] text-[#252525] font-light">
                              {camelCase(category.value, "_")}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-4">
                      <button
                        onClick={() => {
                          setShowModal(false);
                          getDatas(true, 1);
                          setpage(1);
                        }}
                        type="button"
                        className="bg-themePrimary text-white w-full h-10 rounded-md"
                        style={{ backgroundColor: color.color1 }}
                      >
                        {t("donation.apply")}
                      </button>
                      <button
                        type="button"
                        className="border-1 border-themeMuted bg-white text-themeMuted w-full h-10 rounded-md"
                        onClick={() => resetFilter()}
                      >
                        {t("donation.reset")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
      </section>
    </>
  );
};

export default DonationWeb;
