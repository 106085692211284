import _ from "lodash";
import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import * as API from "../../core/service/api_commerce";
import TripAndTourPaymentMobile from "./tripAndTourPaymentMobile";
import TripAndTourPaymentWeb from "./tripAndTourPaymentWeb";

const TripAndTourPayment = () => {
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const [category, setCategory] = useState<any>("Itinerari");
  const [showDay, setShowDay] = useState<any>("");
  const listCategory = ["Itinerari", "Fasilitas", "Deskripsi"];
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  
  const order = () => {
    dispatch(setLoading(true));
    const query = {
      merchantId: location.state.data.merchant.id,
      tourProductId: location.state.data.id,
      quantity: location.state.data.quantity,
      amountTotal: location.state.data.quantity * location.state.data.price,
      productNoteVOS: [],
    };
    API.createOrderTour(query)
      .then((res) => {
        dispatch(setLoading(false));
        checkouth(res.data.data.transactionId);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
  const checkouth = (id: any) => {
    dispatch(setLoading(true));
    const query = {
      transactionId: id,
      description: "string",
    };
    API.createOrderTourCheckout(query)
      .then((res) => {
        dispatch(setLoading(false));
        let dataToken: any = _.find(token, function (o) {
          return o.cid === company.initial;
        });
        window.location.href = `https://${res.data.data.checkoutLink}?access_token=${dataToken?.token}&message=SUCCESS_LOGIN`;
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOpen = (value: any) => {
    setShowDay(showDay === value ? 0 : value);
  };
  return (
    <>
      <BrowserView>
        <TripAndTourPaymentWeb
          data={location.state}
          checkouth={checkouth}
          category={category}
          listCategory={listCategory}
          setCategory={setCategory}
          showDay={showDay}
          setShowDay={setShowDay}
          handleOpen={handleOpen}
          order={order}
        ></TripAndTourPaymentWeb>
      </BrowserView>
      <MobileView>
        <TripAndTourPaymentMobile
          data={location.state}
          checkouth={checkouth}
          category={category}
          listCategory={listCategory}
          setCategory={setCategory}
          showDay={showDay}
          setShowDay={setShowDay}
          handleOpen={handleOpen}
          order={order}
        ></TripAndTourPaymentMobile>
      </MobileView>
    </>
  );
};

export default TripAndTourPayment;
