import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { FC } from "react";

interface ReceivedInvoiceSkeletonProps {
  type?: string;
}

const ReceivedInvoiceSkeleton: FC<ReceivedInvoiceSkeletonProps> = (
  props
): JSX.Element => {
  return (
    <div className="flex flex-col gap-1 bg-white rounded-xl p-3 shadow-xl shadow-[#f7f7f7] ">
      <div className="flex justify-between">
        <div className="w-36">
          <Skeleton />
        </div>
        <div className="w-24">
          <Skeleton />
        </div>
      </div>
      <div className="w-36">
        <Skeleton />
      </div>
      <hr />
      <div className="flex justify-between text-xs text-balance font-semibold bg-balance bg-opacity-[7%] p-3 rounded-lg">
        <div className="w-20">
          <Skeleton />
        </div>
        <div className="w-20">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};

export default ReceivedInvoiceSkeleton;
