/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import Product from "../../core/models/commerce";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { formatCurrency } from "../../config/global";
import * as API_BALANCE from "../../core/service/api_balance";
import * as API_USER from "../../core/service/api_user";
import { setBalance } from "../../core/feature/user/balanceSlice";
import NoProfile from "../../assets/images/no_profile.png";
import History from "../../assets/images/icon_balance/white_history.png";
import TopUp from "../../assets/images/icon_balance/topup_white.svg";
import Withdraw from "../../assets/images/icon_balance/withdraw_white.svg";
import Transfer from "../../assets/images/icon_balance/transfer_white.svg";
import { Link, useNavigate } from "react-router-dom";
import Company from "../../core/models/company";
import User from "../../core/models/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import pattern from "../../assets/images/icon_balance/pattern.svg";
import { setLoading } from "../../core/feature/config/configSlice";
import { useTranslation } from "react-i18next";

interface BalanceCardProps {
  product?: Product;
  layout?: String;
  url?: String;
  isMultiBalance?: Boolean;
  hideButton?: Boolean;
  hideTopup?: Boolean;
  provider?: any;
}

export const BalanceCard: FC<BalanceCardProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  // const balance = useAppSelector((state) => state.balance.balance);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<any>({});
  const [provider, setProvider] = useState<any>({});
  const [balanceMenu, setBalanceMenu] = useState<any[]>([]);
  const [balanceName, setBalanceName] = useState("");
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [balanceCode, setBalanceCode] = useState("");
  const [nameMenu, setNameMenu] = useState<any>([]);

  const getBalance = async () => {
    await API_BALANCE.getBalance("CLOSEPAY")
      .then((res) => {
        setData(res.data.data);
        dispatch(setBalance(res.data.data.amount));
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const getMainBalance = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getMainBalanceMember()
      .then((res) => {
        dispatch(setLoading(false));
        setBalanceName(res.data.data.balanceName);
        setBalanceAmount(res.data.data.amount);
        setBalanceCode(res.data.data.balanceCode);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getBalanceConfig = async () => {
    await API_USER.getBalanceConfig(company?.id)
      .then((res) => {
        setBalanceMenu(res.data.data);
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const getMenuName = () => {
    dispatch(setLoading(true));
    API_USER.balanceMenuName(company?.id)
      .then((res) => {
        const icons = [
          {
            id: 1,
            img: TopUp,
            name:
              res?.data?.data[0]?.name === "topup"
                ? res?.data?.data[0]?.display
                : "Top Up",
            code: "topup",
            url: "topup",
          },
          {
            id: 2,
            img: Withdraw,
            name:
              res?.data?.data[1]?.name === "transfer_bank"
                ? res?.data?.data[1]?.display
                : "Transfer Bank",
            code: "transfer_bank",
            url: "transfer/bank",
          },
          {
            id: 3,
            img: Transfer,
            name:
              res?.data?.data[2]?.name === "transfer_member"
                ? res?.data?.data[2]?.display
                : "Transfer Member",
            code: "transfer_member",
            url: "transfer/member",
          },
        ];
        setNameMenu(icons);
        dispatch(setLoading(false));
        console.log(res);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const [configColor, setConfigColor] = useState<any>({});
  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  useEffect(() => {
    getConfigColor();
  }, []);

  useEffect(() => {
    getMenuName();
    getBalance();
    getBalanceConfig();
    getMainBalance();
    if (props.provider) setProvider(props.provider);
  }, []);

  return (
    <>
      {props.isMultiBalance ? (
        props.provider ? (
          <div
            className={`bg-[${provider.background}] border-[${provider.border}] border flex flex-col items-end rounded-lg mt-9`}
            style={{ backgroundColor: provider.background }}
          >
            <div className="md:px-8 md:pt-8 md:pb-3 pb-2 px-[18px] pt-[18px] w-full">
              <div className="flex items-center">
                <div className="col-span-3 h-full bg-[#D9D9D9] rounded-md">
                  <img
                    src={user.profileImage ? user.profileImage : NoProfile}
                    alt=""
                    className="md:h-[116px] h-[72px] md:w-[116px] w-[72px] mx-auto rounded-full"
                  />
                </div>
                <div className="col-span-8 flex flex-col justify-between h-full ml-4">
                  <h6
                    className={`md:text-base text-sm  text-[${provider.color}] font-semibold mb-0`}
                  >
                    {provider.providerName}
                  </h6>
                  <h6
                    className={`md:text-base text-sm  text-[${provider.color}] font-normal mb-0`}
                  >
                    {provider.phoneNumber}
                  </h6>
                  {provider.code === "LINK_AJA" ? (
                    <img
                      src={`data: image/jpeg;base64, ${provider.amount}`}
                      alt=""
                    />
                  ) : (
                    <h6
                      className={`md:text-base text-sm  text-[${provider.color}] font-semibold mb-0`}
                    >
                      {formatCurrency(props.provider.amount)}
                    </h6>
                  )}
                </div>
              </div>
            </div>
            <div className="px-3 py-2">
              <img
                src={provider.img}
                alt=""
                className="w-1/4 float-end object-cover"
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              backgroundImage: `linear-gradient(to bottom, ${configColor?.color1}, ${configColor?.color2})`,
            }}
            className="relative w-full flex flex-col items-end rounded-lg overflow-hidden"
          >
            <img
              src={pattern}
              className="absolute top-0 left-0 right-0"
              alt=""
            />
            <div className="bg-[#ffffff5d] capitalize z-10 px-3 py-1 text-[10px] font-semibold text-white font-montserrat absolute top-0 left-0 rounded-br-xl">
              {balanceName}
            </div>
            <div className="md:px-8 md:pt-8 md:pb-3 pb-2 px-[18px] pt-10 w-full z-10">
              <div className="flex flex-row gap-2 w-full items-center justify-center">
                <div className="flex-0 col-span-3 h-full  rounded-md">
                  <img
                    src={user.profileImage ? user.profileImage : NoProfile}
                    alt=""
                    className="md:h-[116px] h-[76px] md:w-[116px] w-[76px] mx-auto rounded-full"
                  />
                </div>
                <div className="grow flex flex-col justify-between h-full ml-4">
                  <h6 className="md:text-base text-base text-[#F4F4F4] font-semibold mb-0 capitalize font-montserrat">
                    {data?.nameOfUser}
                  </h6>
                  <h6 className="md:text-base text-sm text-[#F4F4F4] font-light mb-0 mt-1">
                    {t("balance.balance")}
                  </h6>
                  <h6 className="md:text-base  text-[#F4F4F4] text-base font-semibold mb-0">
                    {formatCurrency(balanceAmount)}
                  </h6>
                </div>
                <div
                  className="flex-0 col-span-1 h-full cursor-pointer"
                  onClick={() => navigate(`mutation`)}
                >
                  <img
                    src={History}
                    alt=""
                    width={17}
                    height={19}
                    className="float-end"
                  />
                </div>
              </div>
              <hr className="text-white font-medium h-[2px] my-2.5" />
              <div className="flex flex-row justify-center items-center divide-solid divide-x divide-[#FFFFFF]">
                {balanceMenu.length > 0 &&
                  balanceCode === "CLOSEPAY" &&
                  balanceMenu.map((item) =>
                    nameMenu
                      .filter((el: any) => el.code === item.name)
                      .map((icon: any) => (
                        <Link
                          to={`/${company?.initial}/${icon.url}`}
                          className="flex flex-col items-center no-underline w-full gap-2 py-0.5"
                          key={icon.id}
                        >
                          <img src={icon.img} alt="" width={20} />
                          <h6 className="md:text-sm text-[10px] mb-0 text-white text-center font-montserrat font-normal">
                            {icon.name}
                          </h6>
                        </Link>
                      ))
                  )}
              </div>
            </div>
            <button
              type="button"
              onClick={() => navigate("savings")}
              className="bg-[#ffffff30] flex px-4 items-center flex-row rounded-b-lg w-full md:py-4 py-[12px] mt-2 md:text-base text-sm text-white font-semibold text-center"
            >
              <span className="mr-4 font-bold md:text-base text-sm font-montserrat grow">
                {t("balance.viewMoreBalance")}
              </span>
              <FontAwesomeIcon
                className="flex-0"
                icon={faChevronRight}
              ></FontAwesomeIcon>
            </button>
          </div>
        )
      ) : (
        <div className="mb-5 text-center font-montserrat">
          <div
            style={{
              backgroundImage: `linear-gradient(to bottom, ${configColor?.color1}, ${configColor?.color2})`,
            }}
            className="relative flex flex-col justify-center items-center w-full h-[128px] rounded-lg"
          >
            <img
              src={pattern}
              className="absolute top-0 left-0 right-0"
              alt=""
            />
            <h6 className="text-white font-medium text-base mb-0 relative z-10">
              {t('balance.yourBalance')}
            </h6>
            <h5 className="text-white font-semibold md:text-2xl text-xl relative z-10">
              {formatCurrency(balanceAmount)}
            </h5>
          </div>
        </div>
      )}
    </>
  );
};
