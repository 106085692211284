/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import filterIcon from "../../assets/images/Iconly/Light-Outline/Group 10.svg";
import searchIcon from "../../assets/images/Iconly/Light-Outline/Search.svg";
// import { donationCategories } from "../../config/Combo";
import {
  formatDate,
  formatCurrency,
  formatDateTimeUTC,
  humanizedText,
} from "../../config/global.js";
import { getDonationHistory } from "../../core/service/api_donation";
import Header from "../_common/headerComponent";
import DonationHistorySkeleton from "../_common/skeleton/DonationHistorySkeleton";
import DataNotFound from "../_common/dataNotFound";
import { ConfigColor } from "../../config/global.js";
import { useTranslation } from "react-i18next";

const DonationHistoryMobile = () => {
  const color = ConfigColor();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setsize] = useState(10);
  const [page, setpage] = useState(1);
  const [sortBy] = useState("createdTime");
  const [dir, setdir] = useState(-1);
  const [name, setname] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectCategory, setSelectCategory] = useState("");
  // var categories = [...donationCategories];

  const changeDate = (item: any) => {
    setSelectedDate([item?.selection]);
    setStartDate(moment(item?.selection.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(item?.selection.endDate).format("YYYY-MM-DD"));
  };
  const resetFilter = () => {
    setdir(-1);
    setSelectCategory("");
    setStartDate("");
    setEndDate("");

    setSelectedDate([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const [data, setdata] = useState<any>([]);
  const [toggleSetting, setToggleSetting] = useState(false);
  const [totalElement, setTotalElement] = useState(0);
  const [detailToggle, setDetailToggle] = useState(false);
  const [detailData, setDetailData] = useState<any>([]);
  // var delay: any = null;

  // const typingDelay = (e: any) => {
  //   let key = e.target.value;
  //   clearTimeout(delay);
  //   delay = setTimeout(() => {
  //     getDatas(true);
  //   }, 1000);
  // };

  const getDatas = (
    reset: boolean = false,
    varPage: any = page,
    varSize: any = size,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
      status: "PAID",
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setIsLoading(true);
    getDonationHistory(query)
      .then((res) => {
        setIsLoading(false);
        setdata(res.data.data.data);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.total);
        // setTotalPage(res.data.data.totalPage);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getMoreDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
      status: "PAID",
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setIsLoading(true);
    getDonationHistory(query)
      .then((res) => {
        setIsLoading(false);
        setdata([...data, ...res.data.data.data]);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.total);
        // setTotalPage(res.data.data.totalPage);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  function currentSize() {
    let a = data.length;
    let b = a / page;
    return b;
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => getDatas(true), 1000);
    return () => clearTimeout(timeOutId);
  }, [name]);

  useEffect(() => {
    getDatas();
    currentSize();
  }, []);

  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <Header
          navbarText="Riwayat Donasi"
        // historyUrl={`/${company?.initial}/donations/donation/history`}
        ></Header>
        <div className="container h-full m-auto px-4 font-Montserrat">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100">
              <div className="flex gap-2 ">
                <div className="flex-grow">
                  <img
                    src={searchIcon}
                    alt=""
                    className="absolute translate-x-1/2 translate-y-1/2"
                  />
                  <input
                    //  onKeyUp={(e) => typingDelay(e)}
                    onChange={(e) => setname(e.target.value)}
                    type="search"
                    className="pl-10 w-full rounded-md p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 "
                    placeholder={t("donation.placeholderSearch")}
                  />
                </div>
                <button
                  onClick={() => {
                    setToggleSetting(true);
                  }}
                  className="flex justify-center items-center w-14 border-[#9a99994d] border-solid border-[1px] bg-white rounded-md"
                >
                  <img src={filterIcon} alt="" />
                </button>
              </div>
              <div className="flex flex-col gap-2 my-4">
                {" "}
                {data.filter((el: any) => el.status === "PAID").length === 0 &&
                  !isLoading && <DataNotFound title={t("donation.noData")} />}
                {_.map(
                  data.filter((el: any) => el.status === "PAID"),
                  (item) => (
                    <div
                      key={item._id}
                      onClick={() => {
                        // navigate(
                        //   `/${company?.initial}/donations/donation/history/${item._id}`,
                        //   {
                        //     state: { data: item },
                        //   }
                        // );
                        setDetailToggle(true);
                        setDetailData(item);
                      }}
                      className="flex flex-col w-full h-fit bg-white rounded-md  shadow-sm shadow-gray-700 gap-2 p-3"
                    >
                      <div className="text-[9px] text-[#6B6B6B] font-light">
                        {formatDateTimeUTC(item?.createdTime)}
                      </div>
                      <div className="flex flex-grow justify-between text-xs text-[#263339] font-medium">
                        <div className="">
                          {`${t('donation.forDonation')} ${item?.donationTitle}`.length > 40
                            ? `${t('donation.forDonation')} ${item?.donationTitle}`
                              .toString()
                              .substring(0, 40) + "..."
                            : `${t('donation.forDonation')} ${item?.donationTitle}`}
                        </div>
                        <div>{formatCurrency(item.amount)}</div>
                      </div>
                    </div>
                  )
                )}
                {isLoading ? (
                  <>
                    {_.map(new Array(size), (el) => (
                      <DonationHistorySkeleton></DonationHistorySkeleton>
                    ))}
                  </>
                ) : null}
                {currentSize() >= 10 && totalElement > 10 ? (
                  <div
                    className={`bg-themePrimary rounded-md py-1 text-center text-sm text-white font-semibold ${isLoading ? "cursor-progress" : "cursor-pointer"
                      }`}
                    style={{ backgroundColor: color.color1 }}
                    onClick={() => {
                      if (!isLoading) {
                        getMoreDatas(false, size, page + 1);
                        setpage(page + 1);
                      }
                    }}
                  >
                    {isLoading ? t('donation.waiting') : "Load More...."}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <BottomSheet
            onDismiss={() => setDetailToggle(false)}
            open={detailToggle}
            className="h-full"
          >
            <div className="flex flex-col w-full h-fit bg-white shadow-sm shadow-gray-700 gap-2 p-4 space-y-2 font-montserrat">
              <div
                className="text-accentGreen text-base font-semibold pb-2 border-b-[0.3px] border-b-[#728F9E] border-opacity-25"
                style={{ color: color.color1 }}
              >
                {t('donation.summary')}
              </div>
              <div className="text-sm grid grid-cols-3 gap-4">
                <span className="text-[#252525] font-medium min-w-[120px]">
                  {t('donation.title')}
                </span>
                <span className="text-[#728F9E] font-light break-words col-span-2 text-right">
                  {detailData.donationTitle}
                </span>
              </div>
              <div className="text-sm flex justify-between">
                <span className="text-[#252525] font-medium min-w-[120px]">
                  {t('donation.category')}
                </span>
                <span className="text-[#728F9E] font-light">
                  {detailData.transactionType}
                </span>
              </div>
              <div className="text-sm flex justify-between">
                <span className="text-[#252525] font-medium min-w-[120px]">
                  {t('donation.donationTime')}
                </span>
                <span className="text-[#728F9E] font-light">
                  {formatDate(detailData.createdTime, "dddd, DD MMMM YYYY")}
                </span>
              </div>
              <div className="text-sm flex justify-between">
                <span className="text-[#252525] font-medium min-w-[120px]">
                  {t('donation.donationMoney')}
                </span>
                <span className="text-[#728F9E] font-light">
                  {formatCurrency(detailData.amount)}
                </span>
              </div>
              <div className="mt-4">
                <button
                  onClick={() => {
                    setDetailToggle(false);
                    setDetailData([]);
                  }}
                  type="button"
                  className="bg-themePrimary text-white w-full h-10 rounded-[10px] focus:outline-none"
                  style={{ backgroundColor: color.color1 }}
                >
                  {t('donation.back')}
                </button>
              </div>
            </div>
          </BottomSheet>
          <BottomSheet
            onDismiss={() => setToggleSetting(false)}
            open={toggleSetting}
            className="h-full"
          >
            <div className="px-3 py-4 gap-4 ">
              <div className="font-semibold ">Filter</div>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => changeDate(item)}
                moveRangeOnFirstSelection={false}
                ranges={selectedDate}
                maxDate={new Date()}
              />
              <div className="flex flex-col gap-2 mt-4">
                <button
                  onClick={() => {
                    setToggleSetting(false);
                    getDatas(true, 1);
                    setpage(1);
                  }}
                  type="button"
                  className="bg-themePrimary text-white w-full h-10 rounded-md"
                  style={{ backgroundColor: color.color1 }}
                >
                  {t('donation.apply')}
                </button>
                <button
                  type="button"
                  className="border-1 border-themeMuted bg-white text-themeMuted w-full h-10 rounded-md"
                  onClick={() => resetFilter()}
                >
                  {t('donation.reset')}
                </button>
              </div>
            </div>
          </BottomSheet>
        </div>
      </section>
    </>
  );
};

export default DonationHistoryMobile;
