import React, { FC, useState } from "react";
// import "./Tooltip.css";

interface TooltipProps {
  content: any;
  children: any;
  direction: string;
  className?: any;
}

const Tooltip: FC<TooltipProps> = (props): JSX.Element => {
  // let timeout;
  const [active, setActive] = useState(false);

  // const showTip = () => {
  //   timeout = setTimeout(() => {
  //     setActive(true);
  //   }, props.delay || 400);
  // };

  const showTip = () => {
    setTimeout(() => {
      setActive(true);
    });
  };

  const hideTip = () => {
    // clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className={`Tooltip-Wrapper ${props.className}`}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div
          className={`Tooltip-Tip ${props.direction || "top"} top-0 mt-8 h-max`}
        >
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
