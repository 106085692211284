import { useNavigate } from "react-router-dom";
import Header from "../../_common/headerComponent";
import { SummaryCard } from "../../_common/summaryCard";
import { useTranslation } from "react-i18next";

const TransactionDetailMobile = (props: any) => {
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen relative font-montserrat bg-mobile">
      <Header navbarText={t('mutation_virtual_card.transaction_details')}></Header>
      <div className="flex md:flex-row flex-col-reverse justify-center gap-4 md:mt-[64px] mt-[0px] px-0">
        <div className="lg:w-1/2 md:w-2/3 w-full mb-28">
          <SummaryCard
            data={props.dataPost}
            headerProperty={props.headerProperty}
            headerValue={props.headerValue}
            isSuccess={false}
            downloadable={true}
          />
          <div
            className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <button
              type="button"
              style={{ backgroundColor: `${props.company?.color?.color1 !== undefined ? props.company?.color?.color1 : "#26A69A"}` }}
              onClick={() => navigate(-1)}
              className="bg-green text-white font-medium rounded-[10px] w-100 md:p-3 p-[12px]"
            >
              {t("virtual_card.done")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TransactionDetailMobile;