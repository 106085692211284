import { BrowserView, MobileView } from "react-device-detect";
import MutationDetailMobile from "./mutationDetailMobile";
import MutationDetailWeb from "./mutationDetailWeb";
import { useTranslation } from "react-i18next";

const MutationDetail = () => {
  const { t } = useTranslation();

  const getStatus = (stat: any) => {
    let status = {
      textColor: "",
      text: "",
      bgColor: "",
    };
    switch (stat) {
      case "WAITING_PAYMENT":
        status.textColor = "text-[#FF9723]";
        status.bgColor = "bg-[#FAF5CC]";
        status.text = t('history.waitingPayment');
        break;
      case "CREATED":
        status.textColor = "text-[#1AA7EC]";
        status.bgColor = "bg-[#1AA7EC52]";
        status.text = t('history.created');
        break;
      case "CANCELED":
        status.textColor = "text-[#9A9999]";
        status.bgColor = "bg-[#9a999952]";
        status.text = t('history.canceled');
        break;
      case "PROCESSED":
        status.textColor = "text-[#FFFFFF]";
        status.bgColor = "bg-[#E7DE1A]";
        status.text = t('history.processed');
        break;
      case "REJECTED":
        status.textColor = "text-[#EC1A1A]";
        status.bgColor = "bg-[#EC1A1A52]";
        status.text = t('history.rejected');
        break;
      case "CONFIRMED":
        status.textColor = "text-[#26BC63]";
        status.bgColor = "bg-[#9DF9C0]";
        status.text = t('history.confirmed');
        break;
      case "SENT":
        status.textColor = "text-[#26BC63]";
        status.bgColor = "bg-[#9DF9C0]";
        status.text = t('history.sent');
        break;
      case "AT_PICKUP_POINT":
        status.textColor = "text-[#FF9723]";
        status.bgColor = "bg-[#FAF5CC]";
        status.text = t('history.atPickupPoint');
        break;
      case "ON_REVIEW":
        status.textColor = "text-[#FFFFFF]";
        status.bgColor = "bg-[#E7DE1A]";
        status.text = t('history.onReview');
        break;

      default:
        status.text = "";
        break;
    }
    return status;
  };

  return (
    <>
      <BrowserView>
        <MutationDetailWeb getStatus={getStatus} />
      </BrowserView>
      <MobileView>
        <MutationDetailMobile getStatus={getStatus} />
      </MobileView>
    </>
  );
};

export default MutationDetail;
