import React from 'react';
import Header from '../../_common/headerComponent';
import Person from '../../../assets/images/person.png';

const members = [
  {
    name: "Ulfa",
    nik: "2316182632100",
    placeOfBirth: "Jawa Tengah",
    date: "17-09-2003",
    address: "Salatiga"
  },
  {
    name: "Umi",
    nik: "2316182632100",
    placeOfBirth: "Jawa Tengah",
    date: "17-09-2003",
    address: "Salatiga"
  },
  {
    name: "Santi",
    nik: "2316182632100",
    placeOfBirth: "Jawa Tengah",
    date: "17-09-2003",
    address: "Salatiga"
  },
  {
    name: "Yeni",
    nik: "2316182632100",
    placeOfBirth: "Jawa Tengah",
    date: "17-09-2003",
    address: "Salatiga"
  },
]

const MemberWeb = () => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />
      <div className="bg-white md:mt-12 mt-[0px] pb-10">
        <div className="md:w-1/2 w-full mx-auto px-4 py-3">
          {members.map((item, index) => (
            <div className="rounded-md border-[1px] border-[#CCCCCC] p-3 mb-4" key={index}>
              <h5 className="md:text-lg text-base font-medium text-customBlack border-b-[1px] border-b-[#CCCCCC]">{item.name}</h5>
              <div className="flex items-center justify-between gap-3">
                <div>
                  <h6 className="md:text-sm text-xs font-light text-customBlack">NIK : {item.nik}</h6>
                  <h6 className="md:text-sm text-xs font-light text-customBlack">Tempat Lahir : {item.placeOfBirth}</h6>
                  <h6 className="md:text-sm text-xs font-light text-customBlack">Tanggal Lahir : {item.date}</h6>
                  <h6 className="md:text-sm text-xs font-light text-customBlack mb-0">Alamat : {item.address}</h6>
                </div>
                <img src={Person} alt="" className="w-[75px] h-[75px]" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default MemberWeb