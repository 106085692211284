/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import LogoIcon from "../../assets/images/icon_closepay/LOGOCP.svg";
import { clientPaging, formatDate, imageOnError } from "../../config/global.js";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company.js";
import Header from "../_common/headerComponent";
import DonationTransactionSkeleton from "../_common/skeleton/DonationtransactionSkeleton";
import DataNotFound from "../_common/dataNotFound";
import { useTranslation } from "react-i18next";
// import "./style.scss";

const DonationProgressMobile = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setsize] = useState(10);
  const [page, setpage] = useState(1);
  const [sortBy] = useState("createTime");
  const [dir] = useState(-1);
  const [toggleSetting, setToggleSetting] = useState(false);
  const [item, setItem] = useState<any>({});

  const [data, setdata] = useState<any>([]);
  const [dataPaging, setdataPaging] = useState<any>([]);
  const [totalElement, setTotalElement] = useState(0);
  // const [totalPage, setTotalPage] = useState(0);
  // const params = useParams();

  const query: any = useLocation();

  const getDatas = () => {
    setdata([]);
    setIsLoading(true);
    let dataT = //query.state?.progress;
      _.orderBy(query.state?.progress, "date", "desc");
    setdata(dataT);
    clientPaging(dataT, page, size)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setdataPaging(res.data);
        setpage(res.paging.page);
        setsize(res.paging.size);
        setTotalElement(res.paging.totalElement);
        // setTotalPage(res.paging.totalPage);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getMoreDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    setIsLoading(true);
    clientPaging(data, varPage, varSize)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setdataPaging([...dataPaging, ...res.data]);
        setpage(res.paging.page);
        setsize(res.paging.size);
        setTotalElement(res.paging.totalElement);
        // setTotalPage(res.paging.totalPage);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDatas();
  }, []);

  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <Header navbarText={t('donation.latestNews')} />
        <div className="container h-full m-auto w-3/4 md:p-12 md:pb-12 pb-10 font-Montserrat">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100">
              <div className="flex flex-col gap-2 my-4">
                {dataPaging.length === 0 && !isLoading && (
                  <DataNotFound title={t('donation.noData')} />
                )}
                {_.map(dataPaging, (item) => (
                  <div
                    key={item._id}
                    className="flex flex-col w-full bg-white rounded-md shadow-sm shadow-gray-700 gap-2 p-3"
                  >
                    <div className="w-full flex gap-2">
                      <img
                        className=" bg-contain w-9 h-9"
                        onError={imageOnError}
                        src={LogoIcon}
                        alt=""
                      />
                      <div className="flex flex-col">
                        <span className="grow">
                          {query?.state?.foundationName}
                        </span>
                        <span className="text-[10px] font-light">
                          {formatDate(new Date(item?.date), "DD MMMM YYYY")}
                          {/* {moment(new Date(item?.createdTime)).fromNow()} */}
                          {/* {moment(new Date(item?.date)).diff(
                            moment(new Date())
                          ) < 0
                            ? moment(new Date(item?.date)).fromNow()
                            : moment(new Date(item?.date)).format(
                                "DD MMMM YYYY"
                              )} */}
                        </span>
                      </div>
                    </div>
                    {item?.imageUrl.length > 0 && (
                      <div className="grid md:grid-cols-7 grid-cols-4 md:gap-4 gap-3 items-center">
                        {item.imageUrl.map((el: any, index: number) => (
                          <img
                            src={el}
                            alt=""
                            key={index}
                            className="md:max-h-[100px] max-h-[80px]"
                          />
                        ))}
                      </div>
                    )}
                    <div className="min-h-[40px] relative flex justify-start">
                      <div
                        className="text-sm text-[#3A3A3A] font-normal hDescList absolute h-fit max-h-20"
                        id="hDesc"
                      >
                        {item.description}
                      </div>
                      <button
                        className="absolute bottom-0 h-10 flex justify-center items-center w-fit  py-1 rounded-md text-[#1AA7EC]"
                        hidden={item.description.length <= 300}
                        onClick={() => {
                          setToggleSetting(true);
                          setItem(item);
                        }}
                      >
                        {t('donation.readMore')}
                      </button>
                    </div>
                  </div>
                ))}
                {isLoading ? (
                  <>
                    {_.map(new Array(size), (el) => (
                      <DonationTransactionSkeleton></DonationTransactionSkeleton>
                    ))}
                  </>
                ) : null}
                {totalElement > dataPaging.length ? (
                  <div
                    className={`bg-themePrimary rounded-md py-1 text-center text-sm text-white font-semibold ${isLoading ? "cursor-progress" : "cursor-pointer"
                      }`}
                    onClick={() =>
                      !isLoading && getMoreDatas(false, size, page + 1)
                    }
                  >
                    {isLoading ? "Mohon Tunggu..." : "Load More...."}
                  </div>
                ) : null}
              </div>
            </div>
            <BottomSheet
              onDismiss={() => setToggleSetting(false)}
              open={toggleSetting}
              className="h-full"
            >
              <div className="px-3 py-4 flex flex-col gap-4 font-Montserrat">
                <div
                  key={item._id}
                  onClick={() => {
                    navigate(
                      `/${company?.initial}/donations/donation/transaction/detail/${item._id}`
                    );
                  }}
                  className="flex flex-col w-full gap-2 p-3"
                >
                  <div className="w-full flex gap-2">
                    <img
                      className=" bg-contain w-9 h-9"
                      onError={imageOnError}
                      src={LogoIcon}
                      alt=""
                    />
                    <div className="flex flex-col">
                      <span>{query?.state?.foundationName}</span>
                      <span className="text-[10px] font-light">
                        {moment(new Date(item?.date)).fromNow()}
                      </span>
                    </div>
                  </div>
                  <div className="h-full min-h-[40px] flex justify-start">
                    <div className="text-sm text-[#3A3A3A] font-normal h-fit">
                      {item.description}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-4">
                  <button
                    onClick={() => {
                      setToggleSetting(false);
                      setItem({});
                    }}
                    type="button"
                    className="bg-themePrimary text-white w-full h-10 rounded-md"
                  >
                    {t('donation.close')}
                  </button>
                </div>
              </div>
            </BottomSheet>
          </div>
        </div>
      </section>
    </>
  );
};

export default DonationProgressMobile;
