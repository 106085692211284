import { BrowserView, MobileView } from "react-device-detect";
import EmailVerificationWeb from "./emailVerificationWeb";
import EmailVerificationMobile from "./emailVerificationMobile";
import { useNavigate, useParams } from "react-router-dom";
import * as API_VIRTUAL_CARD from "../../../../core/service/api_virtualCard";
import { setLoading } from "../../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
import Company from "../../../../core/models/company";
import User from "../../../../core/models/user";
import { useTranslation } from "react-i18next";

const EmailVerification = () => {
  const user: User = useAppSelector((state) => state.user.user);
  const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const handleOnClick = async () => {
    dispatch(setLoading(true));
    await API_VIRTUAL_CARD.requestOtp(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        navigate(
          `/${company?.initial}/virtual-card/list/${params.id}/card-security-code/verification/reset-pin`,
          { replace: true }
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  return (
    <>
      <BrowserView>
        <EmailVerificationWeb
          handleOnClick={() => handleOnClick()}
          user={user}
          company={company}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <EmailVerificationMobile
          handleOnClick={() => handleOnClick()}
          user={user}
          company={company}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default EmailVerification;
