import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import GradeMobile from "./gradeMobile";
import GradeWeb from "./gradeWeb";

const Grade = () => {
  return (
    <>
      <BrowserView>
        <GradeWeb></GradeWeb>
      </BrowserView>
      <MobileView>
        <GradeMobile></GradeMobile>
      </MobileView>
    </>
  );
};

export default Grade;
