import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { FC } from "react";

interface SentInvoiceSkeletonProps {
  type?: string;
}

const SentInvoiceSkeleton: FC<SentInvoiceSkeletonProps> = (
  props
): JSX.Element => {
  return (
    <div className="flex flex-col gap-1 bg-white rounded-xl p-3 shadow-xl shadow-[#f7f7f7] ">
      <div className="flex justify-between">
        <div className="w-28">
          <Skeleton />
        </div>
        <div className="w-24">
          <Skeleton />
        </div>
      </div>
      <div className="w-20">
        <Skeleton />
      </div>
      <div className="flex justify-between">
        <div className="w-48">
          <Skeleton />
        </div>
        <div className="w-16">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};

export default SentInvoiceSkeleton;
