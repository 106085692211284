/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TopupWeb from "./topupWeb";
import TopupMobile from "./topupMobile";
import * as API_BALANCE from "../../core/service/api_balance";
import ATMLogo from "../../assets/images/icon_balance/ATM.svg";
import MBankingLogo from "../../assets/images/icon_balance/ibanking.svg";
import { useAppDispatch } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";
import IconBank from "../../assets/images/icon_balance/bank.svg";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const nominalList = [
  { amount: 50000, id: 1 },
  { amount: 100000, id: 2 },
  { amount: 150000, id: 3 },
  { amount: 200000, id: 4 },
];

const Topup = () => {
  let dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(0);
  const [data, setData] = React.useState<any[]>([]);
  const [vaData, setVaData] = React.useState<any>({});
  const [selectedAmount, setSelectedAmount] = React.useState<any>({});
  const [amount, setAmount] = React.useState("");
  const { t } = useTranslation();

  const paymentTools = [
    { name: "ATM", img: ATMLogo, id: 1 },
    { name: "M Banking / I Banking", img: MBankingLogo, id: 2 },
    { name: t('topup.otherBank'), img: IconBank, id: 3 },
  ];

  const instructions = [
    {
      id: 1,
      detail: t('topup.instructions_one'),
    },
    {
      id: 2,
      detail: t('topup.instructions_two'),
    },
    {
      id: 3,
      detail: t('topup.instructions_three'),
    },
    {
      id: 4,
      detail:
        t('topup.instructions_four')
    },
    {
      id: 5,
      detail:
        t('topup.instructions_five')
    },
  ];

  const getConfig = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getConfigTopUpVA()
      .then((res) => {
        setVaData(res.data.data);
        if (res.data.data.vaType === "OPEN") {
          getCombo();
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getCombo = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getComboAvailableBanks()
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOpen = (value: any) => {
    setOpen(open === value ? 0 : value);
  };

  const handleOnClick = () => {
    if (amount === "") {
      toast.error("Masukan nominal terlebih dahulu");
    } else {
      navigate("select-bank", {
        state: {
          data: vaData,
          amount: parseInt(amount),
        },
      });
    }
  };

  React.useEffect(() => {
    getConfig();
  }, []);

  return (
    <>
      <BrowserView>
        <TopupWeb
          handleOpen={(value: any) => handleOpen(value)}
          setAmount={(value: any) => setAmount(value)}
          setSelectedAmount={(value: any) => setSelectedAmount(value)}
          handleOnClick={() => handleOnClick()}
          paymentTools={paymentTools}
          instructions={instructions}
          nominalList={nominalList}
          open={open}
          data={data}
          vaData={vaData}
          amount={amount}
          selectedAmount={selectedAmount}
        />
      </BrowserView>
      <MobileView>
        <TopupMobile
          handleOpen={(value: any) => handleOpen(value)}
          setAmount={(value: any) => setAmount(value)}
          setSelectedAmount={(value: any) => setSelectedAmount(value)}
          handleOnClick={() => handleOnClick()}
          paymentTools={paymentTools}
          instructions={instructions}
          nominalList={nominalList}
          open={open}
          data={data}
          vaData={vaData}
          amount={amount}
          selectedAmount={selectedAmount}
        />
      </MobileView>
    </>
  );
};

export default Topup;
