/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import closepayLogo from "../../../assets/images/solusinegerinew.svg";
import Company from "../../../core/models/company";
import { useAppSelector } from "../../../core/feature/hooks";
import { backTo, formatCurrency } from "../../../config/global";
import Header from "../../_common/headerComponent";
import { SummaryCard } from "../../_common/summaryCard";
import moment from "moment";
import { useTranslation } from "react-i18next";

const PaymentSummaryMobile = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  let location: any = useLocation();
  const [dataPost, setDataPost] = useState<any[]>([]);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    setData(location.state.data);
    let tempData = location.state.data;
    let newData = [
      {
        property: "Tgl. Transaksi",
        value: moment(tempData?.paidTime).format("DD MMM YYY"),
      },
      {
        property: "No Transaksi",
        value: tempData?.transactionId || tempData?._id,
      },
      {
        property: "Nama Merchant",
        value: tempData?.merchantName || tempData?.name,
      },
      {
        property: "Metode Pembayaran",
        value:
          tempData?.type === "STATIC" ? "Barcode Statis" : "Barcode Dinamis",
      },
      {
        property: "Kategori",
        value: "Pembayaran Barcode",
      },
      {
        property: "Nominal",
        value: formatCurrency(tempData?.amount),
      },
      {
        property: "Biaya Admin",
        value: formatCurrency(tempData?.totalCharges),
      },
    ];
    setDataPost(newData);
  }, []);

  return (
    <section className="h-full min-h-screen flex flex-col justify-between font-montserrat bg-white pb-10">
      <Header navbarText={t('barcodeWeb.payBardcode')} isHideBackButton={true} />
      <div className="container flex md:flex-row flex-col-reverse justify-center mt-[0px] px-4">
        <div className="md:w-1/2 w-full">
          <SummaryCard
            data={dataPost}
            headerProperty="Total Bayar"
            headerValue={data.total}
            isSuccess={true}
          />
          <div className="flex flex-md-column flex- gap-3 flex-row-reverse mt-8">
            <button
              type="button"
              onClick={() => backTo(`/${company?.initial}`)}
              style={{ backgroundColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
              className="bg-[#26A69A] text-white font-medium rounded-[10px] w-100 md:p-3 p-[12px]"
            >
              {t("barcodeWeb.done")}
            </button>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 w-full mx-auto">
        <div className="mt-4 w-full">
          <p
            className="text-base font-medium text-center mb-0"
            style={{ color: "#8F8F8F" }}
          >
            Powered By
          </p>
          <img src={closepayLogo} alt="icon" className="w-24 mx-auto" />
        </div>
      </div>
    </section>
  );
};

export default PaymentSummaryMobile;
