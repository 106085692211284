import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import VerifiedIcon from "../../assets/images/icon_donation/Verified.svg";
import { donationCategories } from "../../config/Combo";
import { camelCase, humanizedText, imageOnError } from "../../config/global.js";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company.js";
import { getZakats } from "../../core/service/api_zakat";
import Header from "../_common/headerComponent";
import searchIcon from "../../assets/images/Iconly/Light-Outline/Search.svg";
import DataNotFound from "../_common/dataNotFound";
import CustomTooltip from "../_common/tooltip";
import { ConfigColor } from "../../config/global.js";
import { useTranslation } from "react-i18next";

const ZakatMobile = () => {
  const color = ConfigColor();
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setsize] = useState(10);
  const [page, setpage] = useState(1);
  const [sortBy] = useState("createdTime");
  const [dir, setdir] = useState(-1);
  const [name, setname] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectCategory, setSelectCategory] = useState("");
  var categories = [...donationCategories];

  const changeDate = (item: any) => {
    setSelectedDate([item?.selection]);
    setStartDate(moment(item?.selection.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(item?.selection.endDate).format("YYYY-MM-DD"));
  };
  const resetFilter = () => {
    setdir(-1);
    setSelectCategory("");
    setStartDate("");
    setEndDate("");

    setSelectedDate([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const [data, setdata] = useState<any>([]);
  const [toggleSetting, setToggleSetting] = useState(false);
  const [totalElement, setTotalElement] = useState(0);

  useEffect(() => {
    const timeOutId = setTimeout(() => getDatas(true), 1000);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const getDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setIsLoading(true);
    getZakats(query)
      .then((res) => {
        setIsLoading(false);
        setdata(res.data.data.data);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.total);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getMoreDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setIsLoading(true);
    getZakats(query)
      .then((res) => {
        setIsLoading(false);
        setdata([...data, ...res.data.data.data]);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.total);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const indicators = (index: any) => (
    <div
      onClick={(e) => {
        e.stopPropagation();
        // console.log("aee");
      }}
      className="indicator"
    ></div>
  );
  return (
    <>
      <section className="h-full min-h-screen font-poppins bg-mobile">
        <Header
          backToUrl={`/${company?.initial}/donations`}
          navbarText="Zakat"
          historyUrl={`/${company?.initial}/donations/zakat/history`}
        ></Header>
        <div className="container h-full m-auto px-4 font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="w-100">
              <div className="flex gap-2 ">
                <div className="flex-grow">
                  <img
                    src={searchIcon}
                    alt=""
                    className="absolute translate-x-1/2 translate-y-1/2"
                  />
                  <input
                    // onKeyUp={(e) => typingDelay(e)}
                    onChange={(e) => setname(e.target.value)}
                    type="search"
                    className="pl-10 w-full rounded-md p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 "
                    placeholder={t('zakat.placeholderSearch')}
                  />
                </div>
                {/* <button
                  onClick={() => {
                    setToggleSetting(true);
                  }}
                  className="flex justify-center items-center w-14 border-[#9a99994d] border-solid border-[1px] bg-white rounded-md"
                >
                  <img src={filterIcon} alt="" />
                </button> */}
              </div>
              <div className="flex flex-col gap-2 my-4">
                {data.length === 0 && !isLoading && (
                  <DataNotFound title={t('zakat.noData')} />
                )}
                {_.map(data, (item) => (
                  <div
                    key={item._id}
                    onClick={() => {
                      // console.log("aww");
                      // navigate(
                      //   `/${company?.initial}/donations/zakat/detail/${item._id}`
                      // );
                    }}
                    className="flex w-full min-h-[360px] bg-white rounded-md  shadow-sm shadow-gray-700 gap-1 p-2 pt-3"
                  >
                    <div className="flex flex-col flex-grow justify-between w-full">
                      <div className="h-60 flex flex-col gap-1 justify-center items-center w-full">
                        <div className="slide-container w-full relative">
                          <span
                            className="absolute z-10 top-2 left-2 bg-themePrimary text-white font-montserrat font-bold px-2 py-1 rounded-md"
                            style={{ backgroundColor: color.color1 }}
                          >
                            {item.category
                              ? camelCase(humanizedText(item.category))
                              : ""}
                          </span>
                          <Slide
                            indicators={indicators}
                            transitionDuration={500}
                            prevArrow={<></>}
                            nextArrow={<></>}
                            canSwipe
                          >
                            {item.imageUrl?.map(
                              (slideImage: any, index: number) => (
                                <div key={index}>
                                  <img
                                    className="bg-[#D9D9D9] rounded-md w-full h-52"
                                    onError={imageOnError}
                                    src={slideImage}
                                    alt=""
                                  />
                                  <span
                                    className="absolute top-4 left-3 text-xs font-bold text-white p-2 bg-themePrimary w-fit rounded-md"
                                    style={{ backgroundColor: color.color1 }}
                                  >
                                    {item.category?.replaceAll("_", " ")}
                                  </span>
                                </div>
                              )
                            )}
                          </Slide>
                        </div>
                      </div>
                      {/* <div>{item?.title}</div> */}

                      <CustomTooltip
                        // className={"grow max-w-[calc(100%-7rem)] "}
                        content={
                          <div className="max-w-full whitespace-pre-line">
                            {item.title}
                          </div>
                        }
                        direction="bottom"
                        children={
                          <div className="whitespace-nowrap overflow-hidden truncate ">
                            {item?.title}
                          </div>
                        }
                      ></CustomTooltip>
                      <div className="text-[10px] font-light flex gap-1">
                        {item?.foundationName}
                        <img src={VerifiedIcon} alt="Verified.svg" />
                      </div>
                      <div>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            console.log("tunaikan");
                            navigate(
                              `/${company?.initial}/donations/zakat/detail/${item._id}`
                            );
                          }}
                          type="button"
                          className="border-1 bg-themePrimary  text-white w-full h-10 rounded-md"
                          style={{ backgroundColor: color.color1 }}
                        >
                          {t('zakat.cashItOut')}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                {totalElement > data.length ? (
                  <div
                    className={`bg-themePrimary rounded-md py-1 text-center text-sm text-white font-semibold ${isLoading ? "cursor-progress" : "cursor-pointer"
                      }`}
                    onClick={() =>
                      !isLoading && getMoreDatas(false, size, page + 1)
                    }
                  >
                    {isLoading ? t('zakat.waiting') : "Load More...."}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <BottomSheet
            onDismiss={() => setToggleSetting(false)}
            open={toggleSetting}
            className="h-full"
          >
            <div className="px-3 py-4 flex flex-col gap-4 ">
              <div className="font-semibold ">Filter</div>
              <DateRange
                displayMode="date"
                editableDateInputs={true}
                onChange={(item) => changeDate(item)}
                moveRangeOnFirstSelection={false}
                ranges={selectedDate}
                maxDate={new Date()}
              />
              <div className="flex flex-row flex-wrap gap-2">
                {_.map(categories, (category) => (
                  <div className="flex flex-col w-16  text-center items-center">
                    <div
                      onClick={() => {
                        setSelectCategory(category.value);
                      }}
                      className={`flex w-12 h-12 rounded-md border-[1px] border-solid ${selectCategory === category.value
                        ? "border-accentGreen bg-accentDarkGreen"
                        : "border-[#70717d80] bg-white"
                        }`}
                    >
                      <img src={category.icon} alt="" className="m-auto" />
                    </div>
                    <span className="text-[10px] text-[#252525] font-light">
                      {camelCase(category.value, "_")}
                    </span>
                  </div>
                ))}
              </div>{" "}
              <div className="flex flex-col gap-2 mt-4">
                <button
                  onClick={() => {
                    setToggleSetting(false);
                    getDatas(true);
                  }}
                  type="button"
                  className="bg-themePrimary text-white w-full h-10 rounded-md"
                >
                  {t('zakat.apply')}
                </button>
                <button
                  type="button"
                  className="border-1 border-themeMuted bg-white text-themeMuted w-full h-10 rounded-md"
                  onClick={() => resetFilter()}
                >
                  {t('zakat.reset')}
                </button>
              </div>
            </div>
          </BottomSheet>
        </div>
      </section>
    </>
  );
};

export default ZakatMobile;
