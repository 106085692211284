import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MarketplaceConfig } from "../../models/user";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";

type InitialState = {
  marketplaceConfig: MarketplaceConfig
}
const type = 'marketplaceConfig'

const initialState: InitialState = {
  marketplaceConfig: loadState(type)
}

export const MarketplaceConfigSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setMarketplaceConfig: (state, action: PayloadAction<MarketplaceConfig>) => {
      state.marketplaceConfig = action.payload;
      saveState(type, action.payload)
    },
    removeMarketplaceConfig: (state) => {
      state.marketplaceConfig = {};
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMarketplaceConfig, removeMarketplaceConfig } = MarketplaceConfigSlice.actions;

export default MarketplaceConfigSlice.reducer;
