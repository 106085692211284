import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../core/feature/config/configSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as API_USER from "../../core/service/api_user";
import * as API from "../../core/service/api";
import * as API_GATEWAY from "../../core/service/api_gateway";
import { setCompany } from "../../core/feature/company/companySlice";
import { setToken } from "../../core/feature/token/tokenSlice";
import Token from "../../core/models/token";
import { useAppSelector } from "../../core/feature/hooks";
import { setUser } from "../../core/feature/user/userSlice";
import { setUserLogin } from "../../core/feature/userLogin/userLoginSlice";

const Authorize = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token: Token[] = useAppSelector((state) => state.token.token);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let cid = query.get("companyInitial");
  let code = query.get("code");

  const checkCompany = async () => {
    dispatch(setLoading(true));
    await API_USER.getCompany(cid)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setCompany(res.data.data));
        gatewayAuth(res);
      })
      .catch((err) => {
        document.location.href = `/companies`;
        dispatch(setLoading(false));
      });
  };

  const gatewayAuth = async (item: any) => {
    await API_GATEWAY.gatewayAuth(item.data.data.id, code)
      .then((res) => {
        let newToken = [
          {
            cid: item.data.data.companyInitial || item.data.data.initial,
            token: res.data.data.authToken,
          },
        ];
        dispatch(setToken(newToken));
        cekToken(item);
      })
      .catch((err) => {
        document.location.href = `/${
          item.data.data.companyInitial || item.data.data.initial
        }`;
        dispatch(setLoading(false));
      });
  };

  const cekToken = async (item: any) => {
    dispatch(setLoading(true));
    await API.cekToken()
      .then((res) => {
        dispatch(setUser(res.data.data));
        let userLogin = [
          {
            token: res.data.data.authToken,
            id: res.data.data.noId,
            initial: res.data.data.companyInitial,
          },
        ];
        dispatch(setUserLogin(userLogin));
        redirect(item);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const redirect = (res: any) => {
    document.location.href = `/${
      res.data.data.companyInitial || res.data.data.initial
    }`;
  };

  useEffect(() => {
    checkCompany();
  }, []);

  return <div></div>;
};

export default Authorize;
