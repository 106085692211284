import React from "react";
import OtpInput from "react-otp-input";
import Header from "../../../_common/headerComponent";
import iconSuccess from "../../../../assets/images/iconSuccess.png";
import ConfirmationModal from "../../../_common/confirmationModal";
import { useNavigate } from "react-router-dom";

const InactivateCardSecurityCodeMobile = (props: any) => {
  let navigate = useNavigate();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile dark:bg-black">
      <Header navbarText={props.t('card_security_code.deactivate_pin_transaction')}></Header>

      {/* <ConfirmationModal
        showModal={props.showModal}
        hideModal={props.hideModal}
        confirm={props.confirm}
      /> */}

      <div
        className={`${props.createSuccessFully ? "hidden" : "container font-poppins"
          } md:pb-28`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="mb-6 md:w-2/3 w-full">
            <div className="md:bg-[#FFFFFF] bg-none px-3 py-[12px]">
              <h6 className="text-base text-customBlack font-semibold">
                {props.t('card_security_code.subtitle_deactivate')}
              </h6>
              <h6 className="text-base text-customBlack font-semibold">
                {props.t('card_security_code.warning')} :
              </h6>
              <ul className="list-outside list-disc">
                <li className="text-sm text-customBlack font-normal">
                  {props.t('card_security_code.description_deactivate')}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <p className="text-base text-customBlack font-semibold text-center">
              {props.t('card_security_code.insert_pin')}
            </p>
            <div className="w-full">
              <div className="flex flex-col items-center gap-2 mb-4 relative">
                <OtpInput
                  value={props.securityCode}
                  onChange={(code: React.SetStateAction<string>) =>
                    props.setSecurityCode(code)
                  }
                  numInputs={6}
                  separator={<span>&nbsp;</span>}
                  isInputNum
                  isInputSecure
                  inputStyle={{
                    width: 48,
                    height: 48,
                    margin: "0 0.25rem",
                    fontSize: "1rem",
                    fontWeight: 700,
                    borderRadius: 8,
                    border: "1px solid #26A69A",
                    color: "#26A69A",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${props.createSuccessFully ? "container font-poppins" : "hidden"
          } md:pb-28`}
      >
        <div className="lg:w-1/2 sm:w-3/4 md:w-3/4 m-auto">
          <div className="relative items-center mt-4 mb-3">
            <img
              src={iconSuccess}
              alt="user"
              className="mx-auto md:w-max w-[180px]"
            />
          </div>
          <div className="w-full text-center mb-3">
            <h1 className="font-bold md:text-2xl text-xl">
              {props.t('card_security_code.pin_successfully_deactivated')}
            </h1>
          </div>
          <div className="w-full mt-2 text-center">
            <button
              type="button"
              style={{
                backgroundColor: `${props.company?.color?.color1 !== undefined
                  ? props.company?.color?.color1
                  : "#26A69A"
                  }`,
              }}
              className="w-full md:p-3 p-[12px] mb-3 rounded-full text-white font-semibold text-base bg-btnGreen"
              onClick={() =>
                navigate(`/${props.company?.initial}/virtual-card/list`, {
                  replace: true,
                })
              }
            >
              {props.t('card_security_code.done')}
            </button>
          </div>
        </div>
      </div>
      <div
        className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <button
          type="button"
          style={{
            backgroundColor: `${props.company?.color?.color1 !== undefined
              ? props.company?.color?.color1
              : "#26A69A"
              }`,
          }}
          onClick={props.handleOnClick}
          className="w-full md:p-[1rem] p-[12px] rounded-[10px] text-white font-semibold text-base bg-green"
        >
          {props.createSuccessFully ? props.t('card_security_code.done') : props.t('card_security_code.confirm')}
        </button>
      </div>
    </section>
  );
};

export default InactivateCardSecurityCodeMobile;
