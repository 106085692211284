import React, { Fragment } from "react";
import CurrencyInput from "react-currency-input-field";
import { useNavigate, useParams } from "react-router-dom";
import CustomTooltip from "../../_common/tooltip";
import { formatCurrency } from "../../../config/global";
import { useAppSelector } from "../../../core/feature/hooks";
import { Dialog, Transition } from "@headlessui/react";
import Company from "../../../core/models/company";
import Header from "../../_common/headerComponent";
import IdentityCardIcon from "../../../assets/images/vcard_identity.svg";
import TransactionCardIcon from "../../../assets/images/vcard_transaction.svg";
import SecurityCodeIcon from "../../../assets/images/vcard_security_code.svg";
import ArrowRight from "../../../assets/images/vcard_arrow_right.svg";
import { VirtualCardQRCode } from "../../_common/virtualCardQRCodeComponent";
import User from "../../../core/models/user";
import userDefault from "../../../assets/images/card_avatar.png";
import toast from "react-hot-toast";
import copy from "../../../assets/images/icon/copy.svg";
import download_icon from "../../../assets/images/download-document.svg";
import { useTranslation } from "react-i18next";

const VirtualCardDetailWeb = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [showQR, setShowQR] = React.useState(false);

  function copyLink() {
    // Copy text to clipboard
    navigator.clipboard
      .writeText(props.detailData?.uniqueCode)
      .then(() => {
        toast.success(t('virtual_card.copied'));
      })
      .catch((err) => {
        toast.error(t('virtual_card.failed_to_copy_text'), err);
      });
  }

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>
      {showQR && (
        <VirtualCardQRCode
          onHide={() => setShowQR(false)}
          showQRCode={showQR}
          cardId={props.detailData._id}
          memberId={user.noId === undefined ? "" : user.noId}
          name={props.detailData.name}
          isHomePage={false}
        />
      )}
      <div className="md:w-1/2 w-full mx-auto mb-20">
        <div className="md:container mx-auto flex flex-col items-center md:mt-[64px] mt-[0px] gap-2 md:px-[0px] px-8">
          <div className="md:w-2/3 lg:w-1/2 w-full">
            <div className="flex flex-col items-center mx-auto">
              {/* <div className="relative mx-auto rounded-xl shadow-xl mb-3">
                <img
                  src={props.background}
                  alt=""
                  className={
                    props.isPortrait ? "rounded-xl h-[350px]" : "rounded-xl"
                  }
                />
                <div
                  className="absolute overflow-hidden rounded-xl h-[95%] pl-16 w-[85%] left-0 top-0 bg-transparent"
                  dangerouslySetInnerHTML={{
                    __html: props.template.replace(
                      "src=-",
                      `src=${userDefault}`
                    ),
                  }}
                ></div>
              </div> */}
              <div className="w-full h-full ">
                <iframe
                  className={
                    props.isPortrait
                      ? `w-60 mx-auto overflow-hidden rounded-2xl h-[400px] image-cover-style shadow-xl`
                      : `w-full overflow-hidden rounded-2xl h-64 image-cover-style shadow-xl pl-3`
                  }
                  style={{ backgroundImage: `url(${props.background})` }}
                  scrolling="no"
                  srcDoc={props.template.replace("src=-", `src=${userDefault}`)}
                ></iframe>
              </div>
            </div>
          </div>
          <div className="md:bg-white shadow-sm p-10 mt-8 w-full">
            {props.detailData?.isBlocked === false &&
              props.detailData?.cardBalance !== null ? (
              <div className="flex justify-between">
                {props.features.map((item: any, index: number) => (
                  <div key={index} className="w-[54px]">
                    <div
                      className="flex flex-col items-center justify-center bg-white rounded-[18px] cursor-pointer p-[11px] relative shadow-vcard-features"
                      onClick={() => {
                        if (item.title === t('virtual_card.card_qr_code')) {
                          setShowQR(true);
                        } else {
                          navigate(
                            `/${company?.initial}/virtual-card/list/${params.id}/${item.url}`
                          );
                        }
                      }}
                    >
                      <img src={item.img} alt="" width={30} height={30} />
                    </div>
                    <h6 className="text-customBlack text-xs font-normal text-center mb-0 mt-[8px] leading-[12px]">
                      {item.title}
                    </h6>
                  </div>
                ))}
              </div>
            ) : null}

            <div className="space-between gap-3 mt-7">
              <h3 className="md:text-lg text-base font-customBlack font-semibold mb-0">
                {t('virtual_card.card_information')}
              </h3>
              <button
                type="button"
                style={{
                  color:
                    company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A",
                }}
                className="text-[#26A69A] text-sm md:text-base font-medium"
                onClick={() => {
                  props.setIsOpen("generalData");
                  if (props.detailData?.cardBalance === null) {
                    props.setEditable("cardIdentifier");
                  } else {
                    props.setEditable("cardBalance");
                  }
                }}
              >
                Edit
              </button>
            </div>

            <div className="w-full grid grid-cols-6 md:px-3 px-0 mt-3 text-left">
              <h6 className="col-span-2 text-sm md:text-base font-medium text-primer mb-0">
                {t('virtual_card.card_name')}
              </h6>
              <p className="col-span-1 mb-0">:</p>
              <h6 className="col-span-3 text-sm md:text-base font-medium mb-0 text-newColor">
                {props.detailData?.name}
              </h6>
            </div>
            <div className="w-full grid grid-cols-6 md:px-3 px-0 mt-3 text-left">
              <h6 className="col-span-2 text-sm md:text-base font-medium text-primer mb-0">
                {t('virtual_card.card_nfc')}
              </h6>
              <p className="col-span-1 mb-0">:</p>
              <h6 className="col-span-3 text-sm md:text-base font-medium mb-0 text-newColor">
                {props.detailData?.nfcId
                  ? t('virtual_card.its_set')
                  : t('virtual_card.not_set_yet')
                }
              </h6>
            </div>
            {props?.configData?.isVirtualCardTransactionEnabled && (
              <div className="w-full grid grid-cols-6 md:px-3 px-0 mt-3 text-left">
                <h6 className="col-span-2 text-sm md:text-base font-medium text-primer mb-0">
                  {t('virtual_card.transaction_code')}
                </h6>
                <p className="col-span-1 mb-0">:</p>
                <h6 className="col-span-3 text-sm flex gap-3 md:text-base font-medium mb-0 text-newColor">
                  {props.detailData?.uniqueCode}
                  <img
                    src={copy}
                    alt=""
                    onClick={copyLink}
                    className="cursor-pointer"
                  />
                </h6>
              </div>
            )}
            {props.detailData?.cardBalance !== null && (
              <div className="w-full grid grid-cols-6 md:px-3 px-0 mt-3 text-left">
                <h6 className="col-span-2 text-sm md:text-base font-medium text-primer mb-0">
                  {t('virtual_card.card_balance')}
                </h6>
                <p className="col-span-1 mb-0">:</p>
                <h6 className="col-span-3 text-sm md:text-base font-medium mb-0 text-newColor">
                  {formatCurrency(props.detailData?.cardBalance?.balanceAmount)}
                </h6>
              </div>
            )}
            <div className="w-full grid grid-cols-6 md:px-3 px-0 mt-3 text-left border-b-[1px] border-b-newColor pb-4">
              <h6 className="col-span-2 text-sm md:text-base font-medium text-primer mb-0">
                {t('virtual_card.description')}
              </h6>
              <p className="col-span-1 mb-0">:</p>
              <h6 className="col-span-3 text-sm md:text-base font-medium mb-0 text-newColor">
                {props.detailData?.description === ""
                  ? "-"
                  : props.detailData?.description}
              </h6>
            </div>

            <div className={`${props.singleCard ? "mt-4 pb-28" : "mt-4"}`}>
              <button
                type="button"
                className="space-between text-newColor md:text-base text-sm rounded-[10px] w-full font-medium mb-[20px]"
                onClick={() => props.setIsOpen("cardIdentifier")}
              >
                <div className="flex items-center gap-[12px]">
                  <img src={IdentityCardIcon} alt="" />
                  <span>{t('virtual_card.identity_card_data')}</span>
                </div>
                <img src={ArrowRight} alt="" />
              </button>
              {props.detailData?.cardBalance !== null ? (
                <Fragment>
                  <button
                    type="button"
                    className="space-between text-newColor md:text-base text-sm rounded-[10px] w-full font-medium mb-[20px]"
                    onClick={() => props.setIsOpen("cardBalance")}
                  >
                    <div className="flex items-center gap-[12px]">
                      <img src={TransactionCardIcon} alt="" />
                      <span>{t('virtual_card.transaction_card_settings')}</span>
                    </div>
                    <img src={ArrowRight} alt="" />
                  </button>
                  <button
                    type="button"
                    className="space-between text-newColor md:text-base text-sm rounded-[10px] w-full font-medium mb-[20px]"
                    onClick={() => {
                      if (props.detailData?.cardBalance?.securityCode) {
                        navigate("card-security-code");
                      } else {
                        navigate("card-security-code/activate");
                      }
                    }}
                  >
                    <div className="flex items-center gap-[12px]">
                      <img src={SecurityCodeIcon} alt="" />
                      <span>{t('virtual_card.set_pin')}</span>
                    </div>
                    <img src={ArrowRight} alt="" />
                  </button>
                  {/* <button
                    type="button"
                    className="space-between text-newColor md:text-base text-sm rounded-[10px] w-full font-medium mb-[20px]"
                    onClick={() => props.downloadVirtualCard()}
                  >
                    <div className="flex items-center gap-[12px]">
                      <img src={download_icon} alt="" />
                      <span>Download</span>
                    </div>
                    <img src={ArrowRight} alt="" />
                  </button> */}
                </Fragment>
              ) : (
                <p
                  className={
                    props.isOpen
                      ? "hidden"
                      : "md:text-base text-sm font-medium mt-4 mb-[20px]"
                  }
                >
                  {t('virtual_card.no_card_transaction')}{" "}
                  <span
                    style={{
                      color:
                        company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#1AA7EC",
                    }}
                    className="text-[#1AA7EC] cursor-pointer"
                    onClick={() => {
                      props.setDetailData({
                        ...props.detailData,
                        cardBalance: {
                          oneTimeTxLimit:
                            props.configData.masterOneTimeTxLimit === null
                              ? props.oneTimeTxLimit
                              : props.configData.masterOneTimeTxLimit,
                          dailyTxLimit:
                            props.configData.masterDailyTxLimit === null
                              ? props.dailyTimeTxLimit
                              : props.configData.masterDailyTxLimit,
                          monthTxLimit:
                            props.configData.masterMonthlyTxLimit === null
                              ? props.monthTimeTxLimit
                              : props.configData.masterMonthlyTxLimit,
                          balanceAmount: 0,
                          securityCode: false,
                          balanceSync: {
                            isActive: false,
                            balanceCode: "CLOSEPAY",
                          },
                        },
                      });
                      props.setEditable("cardBalance");
                      props.setIsOpen("cardBalance");
                    }}
                  >
                    {t('virtual_card.create_now')}
                  </span>
                </p>
              )}
              <button
                type="button"
                className="space-between text-newColor md:text-base text-sm rounded-[10px] w-full font-medium mb-[20px]"
                onClick={() => props.downloadVirtualCard()}
              >
                <div className="flex items-center gap-[12px]">
                  <img src={download_icon} alt="" />
                  <span>{t('virtual_card.download')}</span>
                </div>
                <img src={ArrowRight} alt="" />
              </button>
            </div>

            {props.singleCard && (
              <div
                className="py-7 fixed bottom-0 flex flex-col gap-[20px] bg-white justify-end shadow-button lg:w-1/2 md:w-2/3 w-full left-1/2"
                style={{ transform: "translate(-50%, 0)" }}
              >
                <button
                  type="button"
                  style={{
                    backgroundColor: `${company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A"
                      }`,
                  }}
                  onClick={() =>
                    navigate(`/${company.initial}/virtual-card/add`)
                  }
                  className="bg-green text-white mx-6 py-[12px] font-medium rounded-[10px]"
                >
                  {t('virtual_card.add_card_virtual')}
                </button>
                {props.customButton.isActived !== undefined && (
                  <button
                    type="button"
                    style={{
                      borderColor: `${company?.color?.color1 !== undefined
                        ? company?.color?.color1
                        : "#728F9E"
                        }`,
                      color: `${company?.color?.color1 !== undefined
                        ? company?.color?.color1
                        : "#728F9E"
                        }`,
                    }}
                    onClick={() => window.open(props.customButton.redirectLink)}
                    className="border-[#728F9E] border-[1px] text-newColor mx-6 md:py-[12px] py-[8px] font-medium rounded-[10px]"
                  >
                    {props.customButton.buttonName}
                  </button>
                )}
              </div>
            )}
          </div>

          {/* Modal Data Umum*/}
          <Transition
            appear
            show={props.isOpen === "generalData"}
            as={Fragment}
          >
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => props.setIsOpen("generalData")}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <div className="mx-auto bg-white rounded-md px-3 py-3">
                        <div className="flex items-center gap-2 mb-[12px]">
                          <h6 className="text-base font-semibold text-customBlack mb-0">
                            {t('virtual_card.card_information')}
                          </h6>
                        </div>
                        <div className="mb-3">
                          <label className="text-base font-normal text-customBlack mb-[8px]">
                            {t('virtual_card.card_name')}
                          </label>
                          <input
                            type="text"
                            value={props.detailData?.name}
                            onChange={(e) =>
                              props.changeData(e.target.value, "name")
                            }
                            placeholder={t('virtual_card.placeholder_card_name')}
                            className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="text-base font-normal text-customBlack mb-[8px]">
                            NFC
                          </label>
                          <input
                            type="text"
                            value={
                              props.detailData?.nfcId
                                ? t('virtual_card.its_set')
                                : t('virtual_card.not_set_yet')
                            }
                            disabled
                            readOnly
                            className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                          />
                        </div>
                        {props?.configData?.isVirtualCardTransactionEnabled && (
                          <div className="mb-3">
                            <label className="text-base font-normal text-customBlack mb-[8px]">
                              {t('virtual_card.transaction_code')}
                            </label>
                            <input
                              type="text"
                              value={props.detailData?.uniqueCode}
                              disabled
                              readOnly
                              className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                            />
                          </div>
                        )}
                        {props.detailData?.cardBalance !== null && (
                          <div className="mb-3">
                            <label className="text-base font-normal text-customBlack mb-[8px]">
                              {t('virtual_card.card_balance')}
                            </label>
                            <input
                              type="text"
                              value={formatCurrency(
                                props.detailData?.cardBalance?.balanceAmount
                              )}
                              disabled
                              readOnly
                              className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                            />
                          </div>
                        )}
                        <div className="mb-3">
                          <label className="text-base font-normal text-customBlack mb-[8px]">
                            {t('virtual_card.description')}
                          </label>
                          <textarea
                            value={props.detailData?.description}
                            onChange={(e) =>
                              props.changeData(e.target.value, "description")
                            }
                            placeholder="Masukkan Deskripsi Kartu"
                            className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                          />
                        </div>
                        <div className="flex flex-row gap-3 mt-4">
                          <button
                            type="button"
                            onClick={() => {
                              props.changeModalView("");
                              props.getTemplate();
                            }}
                            className="bg-white text-newColor border-[1px] border-newColor border-solid font-medium  text-sm rounded-[10px] w-full md:p-3 px-3 py-2"
                            style={{
                              borderColor: `${company?.color?.color1 !== undefined
                                ? company?.color?.color1
                                : "#728F9E"
                                }`,
                              color: `${company?.color?.color1 !== undefined
                                ? company?.color?.color1
                                : "#728F9E"
                                }`,
                            }}
                          >
                            {t('virtual_card.cancel')}
                          </button>
                          <button
                            type="button"
                            style={{
                              backgroundColor: `${company?.color?.color1 !== undefined
                                ? company?.color?.color1
                                : "#26A69A"
                                }`,
                            }}
                            onClick={props.handleOnClick}
                            className="bg-green text-white font-medium text-sm rounded-[10px] w-full md:p-3 px-3 py-2 "
                          >
                            {t('virtual_card.save')}
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          {/* Modal Kartu Identitas */}
          <Transition
            appear
            show={props.isOpen === "cardIdentifier"}
            as={Fragment}
          >
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => props.setIsOpen("cardIdentifier")}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <div className="mx-auto bg-white rounded-md px-3 py-3">
                        <div className="flex items-center gap-2 mb-[12px]">
                          <h6 className="text-base font-semibold text-customBlack mb-0">
                            {t('virtual_card.identity_card')}
                          </h6>
                          <CustomTooltip
                            content={
                              <>
                                {t('virtual_card.type_card_functioning')}
                                <br />
                                {t('virtual_card.as_identity_card')}
                                <br />
                                {t('virtual_card.for_user')}
                                <br />
                                {t('virtual_card.user_can_use')}
                                <br />
                                {t('virtual_card.this_card')}
                                <br />
                                {t('virtual_card.attendance_card')}
                                <br />
                                {t('virtual_card.attendance_event')}
                              </>
                            }
                            direction="bottom"
                          >
                            <button
                              type="button"
                              className="rounded-[50%] text-customBlack border-[1px] border-solid border-customBlack text-sm px-2"
                            >
                              !
                            </button>
                          </CustomTooltip>
                        </div>
                        {props.detailData?.cardIdentifier?.map(
                          (item: any, index: number) =>
                            item.type === "IMAGE" ? (
                              <div className="mb-3" key={index}>
                                <label className="text-base font-normal text-customBlack mb-[8px]">
                                  {item.key_readable}
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  // value={item.value}
                                  disabled={props.editable !== "cardIdentifier"}
                                  onChange={(e) =>
                                    props.inputHandler(e, item.key)
                                  }
                                  placeholder="Ganti Foto"
                                  className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                                />
                                <p className="text-start text-base text-red pl-1 mb-0">
                                  {item.message}
                                </p>
                              </div>
                            ) : (
                              <div className="mb-3" key={index}>
                                <label className="text-base font-normal text-customBlack mb-[8px]">
                                  {item.key_readable}
                                </label>
                                <input
                                  type={item.type.toLowerCase()}
                                  value={item.value}
                                  disabled={props.editable !== "cardIdentifier"}
                                  onChange={(e) => {
                                    props.changeInput(e.target.value, item);
                                  }}
                                  className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                                />
                                <p className="text-start text-base text-red pl-1 mb-0">
                                  {item.message}
                                </p>
                              </div>
                            )
                        )}
                        <div className="flex flex-row gap-3 mt-4">
                          <button
                            type="button"
                            onClick={() => {
                              props.changeModalView("");
                              props.getTemplate();
                            }}
                            className="bg-white text-newColor border-[1px] border-newColor border-solid font-medium  text-sm rounded-[10px] w-full md:p-3 px-3 py-2"
                            style={{
                              borderColor: `${company?.color?.color1 !== undefined
                                ? company?.color?.color1
                                : "#728F9E"
                                }`,
                              color: `${company?.color?.color1 !== undefined
                                ? company?.color?.color1
                                : "#728F9E"
                                }`,
                            }}
                          >
                            {t('virtual_card.cancel')}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              if (props.editable === "cardIdentifier") {
                                props.handleOnClick();
                              } else {
                                props.changeModalView("cardIdentifier");
                              }
                            }}
                            style={{
                              backgroundColor: `${company?.color?.color1 !== undefined
                                ? company?.color?.color1
                                : "#26A69A"
                                }`,
                            }}
                            className=" text-white font-medium text-sm rounded-[10px] w-full md:p-3 px-3 py-2 "
                          >
                            {props.editable === "cardIdentifier"
                              ? t('virtual_card.save')
                              : t('virtual_card.change')}
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          {/* Modal Kartu Transaksi */}
          <Transition
            appear
            show={props.isOpen === "cardBalance"}
            as={Fragment}
          >
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => props.setIsOpen("cardBalance")}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <div className="bg-white rounded-md p-3">
                        {props.detailData?.cardBalance !== null && (
                          <Fragment>
                            <div className="flex items-center gap-2 mb-[12px]">
                              <h6 className="text-base font-semibold text-customBlack mb-0">
                                {t('virtual_card.card_transaction')}
                              </h6>
                              <CustomTooltip
                                content={
                                  <>
                                    {t('virtual_card.type_of_card')}
                                    <br />
                                    {t('virtual_card.used')}
                                    <br />
                                    {t('virtual_card.payment')}
                                  </>
                                }
                                direction="bottom"
                              >
                                <button
                                  type="button"
                                  className="rounded-[50%] text-customBlack border-[1px] border-solid border-customBlack text-sm px-2"
                                >
                                  !
                                </button>
                              </CustomTooltip>
                            </div>
                            <div className="mb-3">
                              <label className="text-base font-normal text-customBlack mb-[8px]">
                                {t('virtual_card.limit_one_time')}
                              </label>
                              <span className="mx-1">
                                <CustomTooltip
                                  content={
                                    <>
                                      {t('virtual_card.limit_set')}
                                      <br />
                                      {t('virtual_card.max_transaction')}
                                      <br />
                                      {t('virtual_card.can_be')}
                                      <br />
                                      {t('virtual_card.card_one')}
                                      <br />
                                      {t('virtual_card.user_transaction')}
                                      <br />
                                      {t('virtual_card.reduce_limit')}
                                      <br />
                                      {t('virtual_card.admin_set')}
                                      <br />
                                      {t('virtual_card.but_cant')}
                                    </>
                                  }
                                  direction="bottom"
                                >
                                  <button
                                    type="button"
                                    className="rounded-[50%] text-customBlack border-[1px] border-solid border-customBlack text-sm px-2"
                                  >
                                    !
                                  </button>
                                </CustomTooltip>
                              </span>
                              {props.detailData?.cardBalance?.oneTimeTxLimit ===
                                null ? (
                                <input
                                  readOnly
                                  defaultValue="unlimited"
                                  disabled={props.editable !== "cardBalance"}
                                  className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl"
                                />
                              ) : (
                                <CurrencyInput
                                  className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                                  defaultValue=""
                                  decimalsLimit={2}
                                  value={
                                    props.detailData?.cardBalance
                                      ?.oneTimeTxLimit
                                  }
                                  prefix={"Rp"}
                                  disabled={props.editable !== "cardBalance"}
                                  onValueChange={(value, name) => {
                                    props.changeData(
                                      value,
                                      "oneTimeTxLimit",
                                      "cardBalance"
                                    );
                                  }}
                                />
                              )}
                            </div>
                            <div className="mb-3">
                              <label className="text-base font-normal text-customBlack mb-[8px]">
                                {t('virtual_card.limit_transaction_daily')}
                              </label>
                              <span className="mx-1">
                                <CustomTooltip
                                  content={
                                    <>
                                      {t('virtual_card.limit_set')}
                                      <br />
                                      {t('virtual_card.max_transaction')}
                                      <br />
                                      {t('virtual_card.can_be')}
                                      <br />
                                      {t('virtual_card.card_one')}
                                      <br />
                                      {t('virtual_card.user_transaction')}
                                      <br />
                                      {t('virtual_card.reduce_limit')}
                                      <br />
                                      {t('virtual_card.admin_set')}
                                      <br />
                                      {t('virtual_card.but_cant')}
                                    </>
                                  }
                                  direction="bottom"
                                >
                                  <button
                                    type="button"
                                    className="rounded-[50%] text-customBlack border-[1px] border-solid border-customBlack text-sm px-2"
                                  >
                                    !
                                  </button>
                                </CustomTooltip>
                              </span>
                              {props.detailData?.cardBalance?.dailyTxLimit ===
                                null ? (
                                <input
                                  readOnly
                                  defaultValue="unlimited"
                                  disabled={props.editable !== "cardBalance"}
                                  className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl"
                                />
                              ) : (
                                <CurrencyInput
                                  className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                                  defaultValue=""
                                  decimalsLimit={2}
                                  value={
                                    props.detailData?.cardBalance?.dailyTxLimit
                                  }
                                  prefix={"Rp"}
                                  disabled={props.editable !== "cardBalance"}
                                  onValueChange={(value, name) => {
                                    props.changeData(
                                      value,
                                      "dailyTxLimit",
                                      "cardBalance"
                                    );
                                  }}
                                />
                              )}
                            </div>
                            <div className="mb-3">
                              <label className="text-base font-normal text-customBlack mb-[8px]">
                                {t('virtual_card.limit_transaction_monthly')}
                              </label>
                              <span className="mx-1">
                                <CustomTooltip
                                  content={
                                    <>
                                      {t('virtual_card.limit_set')}
                                      <br />
                                      {t('virtual_card.max_transaction')}
                                      <br />
                                      {t('virtual_card.can_be')}
                                      <br />
                                      {t('virtual_card.card_one')}
                                      <br />
                                      {t('virtual_card.user_transaction')}
                                      <br />
                                      {t('virtual_card.reduce_limit')}
                                      <br />
                                      {t('virtual_card.admin_set')}
                                      <br />
                                      {t('virtual_card.but_cant')}
                                    </>
                                  }
                                  direction="bottom"
                                >
                                  <button
                                    type="button"
                                    className="rounded-[50%] text-customBlack border-[1px] border-solid border-customBlack text-sm px-2"
                                  >
                                    !
                                  </button>
                                </CustomTooltip>
                              </span>
                              {props.detailData?.cardBalance?.monthTxLimit ===
                                null ? (
                                <input
                                  readOnly
                                  defaultValue="unlimited"
                                  disabled={props.editable !== "cardBalance"}
                                  className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl"
                                />
                              ) : (
                                <CurrencyInput
                                  className="bg-[#FFFFFF] disabled:bg-[#F3F4FB] disabled:border-none border-[1px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                                  defaultValue=""
                                  disabled={props.editable !== "cardBalance"}
                                  decimalsLimit={2}
                                  value={
                                    props.detailData?.cardBalance?.monthTxLimit
                                  }
                                  prefix={"Rp"}
                                  onValueChange={(value, name) => {
                                    props.changeData(
                                      value,
                                      "monthTxLimit",
                                      "cardBalance"
                                    );
                                  }}
                                />
                              )}
                            </div>
                            <div className="mb-3">
                              <div className="flex items-center gap-[14px]">
                                <input
                                  id="defauSelectedProductslt-checkbox"
                                  type="checkbox"
                                  value=""
                                  disabled={props.editable !== "cardBalance"}
                                  checked={
                                    props.detailData?.cardBalance?.balanceSync
                                      ?.isActive
                                  }
                                  onChange={() =>
                                    props.changeData(
                                      !props.detailData?.cardBalance
                                        ?.balanceSync?.isActive,
                                      "balanceSync",
                                      "cardBalance",
                                      "isActive"
                                    )
                                  }
                                  className="w-[18px] h-[18px] text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <h6 className="text-sm md:text-base text-newColor mb-0">
                                  {t("virtual_card.sync_balance")}
                                </h6>
                              </div>
                              {props.detailData?.cardBalance?.balanceSync
                                ?.isActive && (
                                  <div className="flex flex-col ml-8 my-1">
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          props.editable !== "cardBalance"
                                        }
                                        checked={
                                          props.detailData?.cardBalance
                                            ?.balanceSync.balanceCode === "CREDIT"
                                        }
                                        onChange={() =>
                                          props.changeData(
                                            "CREDIT",
                                            "balanceSync",
                                            "cardBalance",
                                            "balanceCode"
                                          )
                                        }
                                        className="w-4 h-4 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input text-newColor"
                                        type="radio"
                                        value={t("virtual_card.balance_plafon")}
                                      />
                                      <label className="form-check-label inline-block text-newColor">
                                        {t("virtual_card.balance_plafon")}
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          props.editable !== "cardBalance"
                                        }
                                        checked={
                                          props.detailData?.cardBalance
                                            ?.balanceSync.balanceCode ===
                                          "CLOSEPAY"
                                        }
                                        onChange={() =>
                                          props.changeData(
                                            "CLOSEPAY",
                                            "balanceSync",
                                            "cardBalance",
                                            "balanceCode"
                                          )
                                        }
                                        className="w-4 h-4 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 cursor-pointer form-check-input text-newColor"
                                        type="radio"
                                        value={t("virtual_card.main_balance")}
                                      />
                                      <label
                                        className="form-check-label inline-block text-newColor"
                                        htmlFor="inlineRadio20"
                                      >
                                        {t("virtual_card.main_balance")}
                                      </label>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className="flex flex-row gap-3 mt-4">
                              <button
                                type="button"
                                onClick={() => {
                                  props.changeModalView("");
                                  props.getTemplate();
                                }}
                                className="bg-white text-newColor border-[1px] border-newColor border-solid font-medium  text-sm rounded-[10px] w-full md:p-3 px-3 py-2"
                                style={{
                                  borderColor: `${company?.color?.color1 !== undefined
                                    ? company?.color?.color1
                                    : "#728F9E"
                                    }`,
                                  color: `${company?.color?.color1 !== undefined
                                    ? company?.color?.color1
                                    : "#728F9E"
                                    }`,
                                }}
                              >
                                {t("virtual_card.cancel")}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  if (props.editable === "cardBalance") {
                                    props.handleOnClick();
                                  } else {
                                    props.changeModalView("cardBalance");
                                  }
                                }}
                                style={{
                                  backgroundColor: `${company?.color?.color1 !== undefined
                                    ? company?.color?.color1
                                    : "#26A69A"
                                    }`,
                                }}
                                className=" text-white font-medium text-sm rounded-[10px] w-full md:p-3 px-3 py-2 "
                              >
                                {props.editable === "cardBalance"
                                  ? t("virtual_card.save")
                                  : t("virtual_card.change")}
                              </button>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    </section>
  );
};

export default VirtualCardDetailWeb;
