import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import MemberWeb from "./memberWeb";
import MemberMobile from "./memberMobile";

const Member = () => {
  return (
    <>
      <BrowserView>
        <MemberWeb></MemberWeb>
      </BrowserView>
      <MobileView>
        <MemberMobile></MemberMobile>
      </MobileView>
    </>
  );
};

export default Member;
