import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationTransactionWeb from "./donationTransactionWeb";
import DonationTransactionMobile from "./donationTransactionMobile";

const DonationTransaction = () => {
  return (
    <>
      <BrowserView>
        <DonationTransactionWeb></DonationTransactionWeb>
      </BrowserView>
      <MobileView>
        <DonationTransactionMobile></DonationTransactionMobile>
      </MobileView>
    </>
  );
};

export default DonationTransaction;
