/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
import { Link, useNavigate } from "react-router-dom";
import { BalanceCard } from "../../../_common/BalanceCard";
import * as API_BALANCE from "../../../../core/service/api_balance";
import { setLoading } from "../../../../core/feature/config/configSlice";
import LogoBNI from "../../../../assets/images/icon_bank/logo_bni.png";
import LogoBSI from "../../../../assets/images/icon_bank/logo_bsi.png";
import LogoBRI from "../../../../assets/images/icon_bank/logo_bri.png";
import LogoBCA from "../../../../assets/images/icon_bank/logo_bca.png";
import LogoMandiri from "../../../../assets/images/icon_bank/logo_mandiri.png";
import LogoBTN from "../../../../assets/images/icon_bank/logo_btn.png";
import LogoCIMBNiaga from "../../../../assets/images/icon_bank/cimb.png";
import LogoPermata from "../../../../assets/images/icon_bank/Permata.svg";
import LogoDanamon from "../../../../assets/images/icon_bank/Danamon.svg";
import LogoBankJatim from "../../../../assets/images/icon_bank/Bank Jatim Syriah BJS.svg";
import icon_delete from "../../../../assets/images/icon_balance/delete_icon.svg";
import Company from "../../../../core/models/company";
import { useTranslation } from "react-i18next";

const bankNames = [
  {
    bankName: "BNI",
    logo: LogoBNI,
    bankCode: "009",
  },
  {
    bankName: "BSI",
    logo: LogoBSI,
    bankCode: "451",
  },
  {
    bankName: "BRI",
    logo: LogoBRI,
    bankCode: "002",
  },
  {
    bankName: "BCA",
    logo: LogoBCA,
    bankCode: "014",
  },
  {
    bankName: "Mandiri",
    logo: LogoMandiri,
    bankCode: "008",
  },
  {
    bankName: "BTN",
    logo: LogoBTN,
    bankCode: "200",
  },
  {
    bankName: "CIMB Niaga",
    logo: LogoCIMBNiaga,
    bankCode: "022",
  },
  {
    bankName: "Permata",
    logo: LogoPermata,
    bankCode: "013",
  },
  {
    bankName: "Danamon",
    logo: LogoDanamon,
    bankCode: "011",
  },
  {
    name: "Jatim Syariah",
    logo: LogoBankJatim,
    code: "114",
  },
];

const GetAllBankAccountWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [acountId, setAcountId] = React.useState("");

  const getAllBankAccounts = async () => {
    dispatch(setLoading(true));
    let query = {
      page: 0,
      size: 10,
      sortBy: "createTime",
      dir: -1,
      total: 0,
    };
    await API_BALANCE.getAllBankAccounts(query)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const deleteAccount = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.deleteAccountBank(acountId)
      .then((res) => {
        setShowModal(false);
        getAllBankAccounts();
        dispatch(setLoading(false));
        console.log(res);
      })
      .catch((err) => {
        setShowModal(false);
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getAllBankAccounts();
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      {/* Modal */}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="w-full p-6 border-b border-solid border-slate-200 rounded-t">
                  <h5 className="text-2xl text-center font-semibold">
                    {t("getAllBankAccount.deleteAccount")}
                  </h5>
                </div>

                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-lg text-center leading-relaxed">
                    {t("getAllBankAccount.deleteConfirmation")}
                  </p>
                </div>

                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    style={{
                      borderColor:
                        company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A",
                      color:
                        company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A",
                    }}
                    className="text-green min-w-[150px] rounded-lg border-2 border-green font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    {t("getAllBankAccount.cancel")}
                  </button>
                  <button
                    style={{
                      backgroundColor:
                        company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A",
                    }}
                    className="bg-green min-w-[150px] rounded-lg text-white active:bg-emerald-600 font-bold uppercase text-sm px-5 py-2.5 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => deleteAccount()}
                  >
                    {t("getAllBankAccount.yes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <Header></Header>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 pb-3">
        <div className="md:w-3/5 w-full mx-auto">
          <BalanceCard />
          <div className="w-full my-6">
            <button
              type="button"
              onClick={() => navigate("add-bank-account")}
              style={{
                borderColor:
                  company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
                color:
                  company?.color?.color1 !== undefined
                    ? company?.color?.color1
                    : "#26A69A",
              }}
              className="text-green border-[1px] border-green font-bold rounded-xl w-full md:p-[1rem] p-[12px]"
            >
              {t("getAllBankAccount.addRecipient")}
            </button>
          </div>
          {data.length > 0 && (
            <div>
              <h6 className="text-customBlack md:text-lg text-base font-semibold mb-3">
                {t("getAllBankAccount.registeredBankAccounts")} <br />
                <span className="text-xs md:text-sm text-newColor">
                  {t("getAllBankAccount.maxAccounts")}
                </span>
              </h6>
              {data.map((item, index) => (
                <div className="w-full relative flex flex-row items-center gap-3 bg-white rounded-xl px-3 py-3 mb-4 shadow-sm">
                  <Link
                    to="input-data"
                    state={item}
                    key={index}
                    className="no-underline"
                  >
                    <div className="flex flex-row items-center gap-3 mr-6 w-[300px]">
                      <img
                        src={`${
                          bankNames.find((el) => el.bankCode === item?.bankCode)
                            ?.logo
                        }`}
                        alt=""
                        className="w-auto h-auto max-w-14 max-h-14"
                      />
                      <div className="flex flex-col text-start w-full">
                        <h3 className="md:text-lg text-base font-medium text-customBlack mb-0">
                          {item?.accountName}
                        </h3>
                        <div className="flex items-center divide-solid divide-x divide-[#C4C4C4]">
                          <h6 className="md:text-sm text-xs font-medium text-newColor mb-0 pr-3">
                            {
                              bankNames.find(
                                (el) => el.bankCode === item?.bankCode
                              )?.bankName
                            }
                          </h6>
                          <p className="md:text-sm text-xs font-medium text-newColor mb-0 pl-3">
                            {item?.accountNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <button className="absolute right-4 cursor-pointer">
                    <img
                      onClick={() => {
                        setAcountId(item._id);
                        setShowModal(true);
                      }}
                      src={icon_delete}
                      alt=""
                    />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default GetAllBankAccountWeb;
