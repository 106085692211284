/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TopUpCardBalanceSummaryWeb from "./topUpCardBalanceSummaryWeb";
import TopUpCardBalanceSummaryMobile from "./topUpCardBalanceSummaryMobile";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "../../../config/global";
import { useAppSelector } from "../../../core/feature/hooks";
import Company from "../../../core/models/company";
import { useTranslation } from "react-i18next";

const TopUpCardBalanceSummary = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const pin: number = useAppSelector((state) => state.pin.pin);
  let navigate = useNavigate();
  let location: any = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const [dataPost, setDataPost] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any>({});
  const [inquiryId, setInquiryId] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  const handleOnClick = () => {
    if (pin === 1) {
      setShowModal(true);
    } else {
      navigate(`/${company?.initial}/create-security-code`, {
        replace: true,
        state: {
          data: location.state.data,
          type: "topup-card",
          url: `virtual-card/list/${params.id}/topup/summary`,
        },
      });
    }
  };

  React.useEffect(() => {
    setData(location.state.data.detail);
    setInquiryId(location.state.data.inquiryId);
    let dataOrder = location.state.data.detail;
    let newData = [
      {
        property: t('virtual_card.member_name'),
        value: dataOrder.memberName,
      },
      {
        property: t('virtual_card.payment_type'),
        value: t('virtual_card.top_up_card_balance'),
      },
      {
        property: t('virtual_card.card_name'),
        value: dataOrder.cardName,
      },
      {
        property: t('virtual_card.paymentMethod'),
        value: "Saldo Closeloop",
      },
      {
        property: t('virtual_card.nominal_topup'),
        value: formatCurrency(dataOrder.amount),
      },
      {
        property: t('withdraw_funds.adminFee'),
        value: formatCurrency(dataOrder.totalCharges),
      },
    ];
    setDataPost(newData);
  }, []);

  return (
    <>
      <BrowserView>
        <TopUpCardBalanceSummaryWeb
          handleOnClick={() => handleOnClick()}
          setShowModal={(value: any) => setShowModal(value)}
          company={company}
          dataPost={dataPost}
          data={data}
          inquiryId={inquiryId}
          showModal={showModal}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <TopUpCardBalanceSummaryMobile
          handleOnClick={() => handleOnClick()}
          setShowModal={(value: any) => setShowModal(value)}
          company={company}
          dataPost={dataPost}
          data={data}
          inquiryId={inquiryId}
          showModal={showModal}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default TopUpCardBalanceSummary;
