/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InvoiceSuccessPaymentMobile from "./invoiceSuccessPaymentMobile";
import InvoiceSuccessPaymentWeb from "./invoiceSuccessPaymentWeb";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../core/feature/hooks";
import { getDetailInvoice } from "../../core/service/api_invoice";
import Company from "../../core/models/company";

const InvoiceSuccessPayment = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const [data, setdata] = React.useState<any>();
  const [amountPayment, setAmountPayment] = React.useState(0);
  const [isPayAll, setIsPayAll] = React.useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const getData = () => {
    let state: any = JSON.parse(
      localStorage.getItem("temp-success-payment") || "{}"
    );
    getDetailInvoice(params.id, state.createdBy?.toLowerCase())
      .then((res) => {
        setdata({ ...res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSuccess = () => {
    navigate(`/${company?.initial}/invoices`);
  };
  
  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <BrowserView>
        <InvoiceSuccessPaymentWeb
          handleSuccess={() => handleSuccess()}
          data={data}
          amountPayment={amountPayment}
          isPayAll={isPayAll}
        />
      </BrowserView>
      <MobileView>
        <InvoiceSuccessPaymentMobile
          handleSuccess={() => handleSuccess()}
          data={data}
          amountPayment={amountPayment}
          isPayAll={isPayAll}
        />
      </MobileView>
    </>
  );
};

export default InvoiceSuccessPayment;
