import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBAirPDAMWeb from "./ppobAirPDAMWeb";
import PPOBAirPDAMMobile from "./ppobAirPDAMMobile";

const PPOBAirPDAM = () => {
  return (
    <>
      <BrowserView>
        <PPOBAirPDAMWeb></PPOBAirPDAMWeb>
      </BrowserView>
      <MobileView>
        <PPOBAirPDAMMobile></PPOBAirPDAMMobile>
      </MobileView>
    </>
  );
};

export default PPOBAirPDAM;
