import { find } from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Token from "../../core/models/token";
import User from "../../core/models/user";
import { useNavigate } from "react-router-dom";


const Checkpath = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const user: User = useAppSelector((state) => state.user.user);
  const [isLogin] = useState(user && newToken && company);

  useEffect(() => {
  if(isLogin){
    navigate(`/${company.initial}`)
  }else{
    navigate("/companies")
  }
  }, []);

  return <div></div>;
};

export default Checkpath;
