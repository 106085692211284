import Header from "../../_common/headerComponent";
import { useAppSelector } from "../../../core/feature/hooks";
import DataNotFound from "../../_common/dataNotFound";
import ProductComponent from "../../_common/productComponent";
import { useNavigate } from "react-router-dom";
import LoadMoreButton from "../../_common/loadMoreButton";
import Company from "../../../core/models/company";
import merchantDefault from '../../../assets/images/new_merchant_logo.png';
import { useTranslation } from "react-i18next";

const ListMerchantsMobile = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header
        navbarText={
          props.isLogin
            ? `${t('merchants.list')} ${props.mName === "" ? t('merchants.list') : props.mName}`
            : `${t('merchants.list')} ${props.mNameNoLogin}`
        }
      />
      <div className="container flex flex-col items-center md:mt-[64px] mt-[0px] px-4">
        <div className="md:w-3/5 w-full pb-5">
          {props.merchants?.length > 0 ? (
            props.merchants.map((item: any) => (
              <div className="relative mb-4" key={item.id}>
                {!item.isOpen && (
                  <div>
                    <div className={`${item.isOpen ? "" : "bg-customBlack bg-opacity-50 w-full h-full z-0 top-1/2 left-1/2 absolute"} mb-4`} style={{ transform: "translate(-50%, -50%)" }} />
                    <div className="absolute left-1/2 top-1/2" style={{ transform: "translate(-50%, -50%)" }}>
                      <button
                        type="button"
                        onClick={() => navigate(item.id)}
                        className="md:text-lg text-base font-bold text-white bg-customBlack bg-opacity-70 rounded-[10px] p-2"
                      >
                        {t('merchants.merchantClose')}
                      </button>
                    </div>
                  </div>
                )}
                <div
                  className="bg-white rounded-lg shadow-sm px-3 pt-4 pb-[30px] mb-3"
                >
                  <div className="flex items-center justify-between gap-2">
                    <img
                      src={item.profileImage ? item.profileImage : merchantDefault}
                      alt=""
                      className="object-cover h-[56px] w-[56px] select-none rounded-lg"
                    />
                    <div className="flex flex-col justify-between items-start w-full">
                      <h6 className="md:text-base text-sm font-semibold">
                        {item.name}
                      </h6>
                      <p className="md:text-sm text-xs text-customBlack text-center font-light mb-0">
                        {item.location ? item.location.city.name : "-"}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() => navigate(item.id)}
                      style={{
                        borderColor: `${company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A"
                          }`,
                        color: `${company?.color?.color1 !== undefined
                          ? company?.color?.color1
                          : "#26A69A"
                          }`,
                      }}
                      className="bg-white border-[1px] md:text-sm text-xs font-semibold rounded-[10px] w-full p-2"
                    >
                      {t('merchants.see')} {props.marketplaceConfig?.merchantName}
                    </button>
                  </div>
                  {item?.productList?.length > 0 && (
                    <div className="grid lg:grid-cols-4 grid-cols-4 gap-3 mt-2">
                      {item?.productList?.slice(0, 4).map((el: any) => (
                        <ProductComponent
                          item={el}
                          type="M"
                          merchantName={item.name}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <DataNotFound title={t('merchants.noData')} />
          )}

          {props.currentSize() >= 10 && props.totalElements > 10 ? (
            <LoadMoreButton addMore={props.addMore} />
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};

export default ListMerchantsMobile;