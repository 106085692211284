import React, { useState } from "react";
import Company from "../../../../core/models/company";
import Header from "../../../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
import { BalanceCard } from "../../../_common/BalanceCard";
import CurrencyInput from "react-currency-input-field";
import * as API_BALANCE from "../../../../core/service/api_balance";
import { setLoading } from "../../../../core/feature/config/configSlice";
import UserDefault from "../../../../assets/images/userDefault.png";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const InputDataWeb = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const balance = useAppSelector((state) => state.balance.balance);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [targetMember, setTargetMember] = useState<any>({});
  const [noId, setNoId] = useState("");
  const [nominal, setNominal] = useState("");
  const [note, setNote] = useState("");
  const [errorNoId, setErrorNoId] = useState("");
  const [errorNominal, setErrorNominal] = useState("");
  const [errorNote, setErrorNote] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  const checkMember = async () => {
    dispatch(setLoading(true));
    setIsSelected(false);
    setTargetMember({});
    setErrorNoId("");
    await API_BALANCE.getMemberByNoId(noId)
      .then((res) => {
        dispatch(setLoading(false));
        setTargetMember(res.data.data);
        setIsSelected(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleOnClick = async () => {
    if (noId === "") {
      setErrorNoId(t("inputDataWeb.errorNoId"));
    } else if (!isSelected) {
      setErrorNoId(t("inputDataWeb.checkNoId"));
    } else if (nominal === "0" || nominal === "") {
      setErrorNominal(t("inputDataWeb.errorNominal"));
    } else if (parseInt(nominal) > balance) {
      toast.error(t("inputDataWeb.insufficientBalance"));
    } else {
      dispatch(setLoading(true));
      let data = {
        userIdDest: targetMember?.id,
        amount: nominal,
      };
      await API_BALANCE.inquiryPaymentTransferMember(data)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`/${company?.initial}/transfer/member/summary`, {
            state: {
              data: res.data.data,
            },
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  return (
    <section className="h-full min-h-screen font-poppins bg-mobile pb-20">
      <Header></Header>
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0">
        <div className="md:w-3/5 w-full mx-auto mb-28">
          <div className="px-4">
            <BalanceCard />
          </div>
          <h5 className="md:text-lg text-base text-customBlack font-medium px-4 mb-0">
            {t("inputDataWeb.transferMember")}
          </h5>
          <p className="md:text-base text-sm text-customBlack font-normal px-4 md:mb-4 mb-3">
            {t("inputDataWeb.enterMemberData")}
          </p>
          <div className="bg-white p-7 md:mb-10 mb-8 shadow-sm">
            <div className="text-start w-full mb-3">
              <p className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0">
                {t("inputDataWeb.memberIdNumber")}
              </p>
              <div className="grid grid-cols-4 gap-3">
                <div className="col-span-3">
                  <input
                    value={noId}
                    type="text"
                    onChange={(e) => {
                      setNoId(e.target.value);
                      setErrorNoId("");
                    }}
                    onKeyDown={(e) => {
                      e.key === "Enter" && checkMember();
                    }}
                    placeholder={t("inputDataWeb.enterId")}
                    className="w-full bg-white border-newColor text-newColor rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-newColor"
                  />
                </div>
                <div className="col-span-1">
                  <button
                    type="button"
                    onClick={() => checkMember()}
                    style={{
                      borderColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A",
                      color: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A",
                    }}
                    className="rounded-xl bg-[#FFFFFF] text-green hover:text-[#FFFFFF] border-[1px] border-green text-base font-medium w-full h-100"
                  >
                    {t("inputDataWeb.check")}
                  </button>
                </div>
              </div>
              <p className="md:text-base text-sm text-red pl-1 mb-0">
                {errorNoId}
              </p>
              <div
                className={`${targetMember?.username
                  ? "flex flex-row items-center gap-3 bg-[#9a99991a] p-2 rounded-md my-3"
                  : "hidden"
                  }`}
              >
                <img
                  className="w-12 h-12 rounded-full"
                  src={
                    targetMember?.profileImage != null
                      ? targetMember?.profileImage
                      : UserDefault
                  }
                  alt=""
                />
                <div>
                  <h6 className="md:text-base text-sm text-customBlack font-medium mb-0 capitalize">
                    {targetMember?.name}
                  </h6>
                  <p className="md:text-sm text-xs text-newColor mb-0">
                    {targetMember?.phone}
                  </p>
                </div>
              </div>
            </div>
            <div className="text-start w-full mb-3">
              <p className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0">
                {t("inputDataWeb.nominal")}
              </p>
              <CurrencyInput
                className="w-full bg-white border-newColor text-newColor rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-newColor"
                defaultValue={1000}
                decimalsLimit={2}
                value={nominal}
                prefix={"Rp"}
                placeholder="Rp"
                onValueChange={(value, name) => {
                  setNominal(value || "");
                  setErrorNominal("");
                }}
              />
              <p className="md:text-base text-sm text-red pl-1 mb-0">
                {errorNominal}
              </p>
            </div>
            <div className="text-start w-full mb-3">
              <p className="md:text-lg text-base text-customBlack font-medium pl-1 mb-0">
                {t("inputDataWeb.message")}
              </p>
              <textarea
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                  setErrorNote("");
                }}
                placeholder={t("inputDataWeb.enterNote")}
                className="w-full bg-white border-newColor text-newColor rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-newColor"
              />
              <p className="md:text-base text-sm text-red pl-1 mb-0">
                {errorNote}
              </p>
            </div>
          </div>
          <div className="absolute bottom-0 left-1/2 lg:w-1/2 md:w-2/3 w-full mt-8 md:px-[0px] px-[1.5rem]" style={{ transform: "translate(-50%, -50%)" }}>
            <button
              type="button"
              onClick={handleOnClick}
              style={{ backgroundColor: company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A" }}
              className="bg-green text-white font-medium rounded-lg w-full md:p-3 p-[12px]"
            >
              {t("inputDataWeb.next")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InputDataWeb;
