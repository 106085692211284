/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SuccessIcon from "../../assets/images/security_success.png";
import FailedIcon from "../../assets/images/security-fail.png";
import PendingIcon from "../../assets/images/Pending.png";
import { formatCurrency } from "../../config/global";
import { useAppDispatch } from "../../core/feature/hooks";
import Header from "../_common/headerComponent";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { setLoading } from "../../core/feature/config/configSlice";
import { useTranslation } from "react-i18next";

const PPOBHistoryDetailMobile = () => {
  let location: any = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});

  const getTransactionDetail = async (id: any) => {
    dispatch(setLoading(true));
    API_DIGIPROD.getTransactionById(id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };

  useEffect(() => {
    getTransactionDetail(location.state);
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Ringkasan"></Header>
      <div className="flex flex-col justify-center gap-6 lg:w-1/2 md:w-2/3 w-full md:mt-[64px] mt-[0px] md:px-4 mx-auto">
        <div className="w-full p-4 mb-10 md:mb-20 h-full md:rounded-xl bg-white">
          <div className="pb-4">
            <div className="flex flex-col items-center mt-2">
              <img
                src={data?.status === "SUKSES" ? SuccessIcon : 
                  data.status === "GAGAL" ? FailedIcon : PendingIcon
                }
                alt=""
                className="md:h-[220px] h-[120px] md:w-[220px] w-[120px]"
              />
              <h1
                className={`${data?.status === "SUKSES"
                  ? "text-green"
                  : data?.status === "GAGAL"
                    ? "text-red"
                    : "text-yellow-700"
                  } md:text-3xl text-xl font-bold mt-3`}
              >
                {data?.status === "SUKSES"
                  ? t('ppobHistory.success')
                  : data?.status === "GAGAL"
                    ? t('ppobHistory.failed')
                    : t('ppobHistory.pending')
                }
              </h1>
            </div>
            <div className="space-between gap-3 mt-4">
              <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                {t('ppobHistory.transaction_date')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0">
                {moment.utc(data?.transactionDate).local().format("ll")}
              </p>
            </div>
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                {t('ppobHistory.transaction_number')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0">
                {data?.transactionId}
              </p>
            </div>
            <hr className="border-dotted  border-[#9A9999] my-4 border" />
            
            {data?.transactionType === "PULSE_AND_DATA" && (
              <React.Fragment>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                    {t('ppobHistory.phone_number')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0">
                    {data?.customerNumber}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                    {t('ppobHistory.service_type')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.productName}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                    {t('ppobHistory.category')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0">
                    {t('ppobHistory.pulse_and_data')}
                  </p>
                </div>
              </React.Fragment>
            )}
            {data?.transactionType === "PLN" && (
              <React.Fragment>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.name')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.customerName}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.customer_id')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.customerNumber}
                  </p>
                </div>
                {data?.category !== "POSTPAID_PLN" && data?.status === "SUKSES" ? (
                  <div className="space-between gap-3 mt-[1rem]">
                    <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                      {t('ppobHistory.token')}
                    </p>
                    <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                      {data?.token}
                    </p>
                  </div>
                ) : null}
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                    {t('ppobHistory.service_type')}
                  </p>
                  {data?.category === "POSTPAID_PLN" ? (
                    <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                      {t('ppobHistory.pln_bill')}
                    </p>
                  ) : (
                    <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                      {data?.productName}
                    </p>
                  )}
                </div>
                {data?.category === "POSTPAID_PLN" && (
                  <div className="space-between gap-3 mt-[1rem]">
                    <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                      {t('ppobHistory.totalAmount')}
                    </p>
                    <p className="text-sm md:text-base text-newColor font-medium mb-0">
                      {t('ppobHistory.one_month')}
                    </p>
                  </div>
                )}
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                    {t('ppobHistory.category')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0">
                    {t('ppobHistory.pln')}
                  </p>
                </div>
              </React.Fragment>
            )}
            {data?.transactionType === "PDAM" && (
              <React.Fragment>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.name')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.customerName}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.customer_id')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.customerNumber}
                  </p>
                </div>
                {data.desc?.map((item: any, index: number) => (
                  <div className="space-between gap-3 mt-[1rem]" key={index}>
                    <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                      {item.key}
                    </p>
                    <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                      {item.value}
                    </p>
                  </div>
                ))}
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.service_type')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.productName}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.category')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {t('ppobHistory.pdam')}
                  </p>
                </div>
              </React.Fragment>
            )}
            {data?.transactionType === "BPJS" && (
              <React.Fragment>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.bpjs_card_number')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.customerNumber}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.service_type')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.productName}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.category')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {t('ppobHistory.bpjs')}
                  </p>
                </div>
              </React.Fragment>
            )}
            {data?.transactionType === "INTERNET_AND_TV" && (
              <React.Fragment>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.name')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.customerName}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.customer_id')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.customerNumber}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.service_type')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.productName}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.category')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {t('ppobHistory.internet_and_tv')}
                  </p>
                </div>
              </React.Fragment>
            )}
            {data?.transactionType === "PBB" && (
              <React.Fragment>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.name')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.customerName}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.property_tax_number')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.customerNumber}
                  </p>
                </div>
                {data.desc?.map((item: any, index: number) => (
                  <div className="space-between gap-3 mt-[1rem]" key={index}>
                    <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                      {item.key}
                    </p>
                    <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                      {item.value}
                    </p>
                  </div>
                ))}
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.service_type')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {data?.productName}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-customBlack text-start font-medium mb-0">
                    {t('ppobHistory.category')}
                  </p>
                  <p className="text-sm md:text-base text-newColor font-medium mb-0 text-end">
                    {t('ppobHistory.property_tax')}
                  </p>
                </div>
              </React.Fragment>
            )}
            
            <hr className="border-dotted  border-[#9A9999] my-3 border" />
            <div className="space-between gap-3 mt-[1rem]">
              <p className="text-sm md:text-base text-customBlack font-medium mb-0">
                {t('ppobHistory.total')}
              </p>
              <p className="text-sm md:text-base text-newColor font-medium mb-0">
                {formatCurrency(data?.totalPrice)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PPOBHistoryDetailMobile;
