import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TransferBankSummaryWeb from "./summaryWeb";
import TransferBankSummaryMobile from "./summaryMobile";

const TransferBankSummary = () => {
  return (
    <>
      <BrowserView>
        <TransferBankSummaryWeb></TransferBankSummaryWeb>
      </BrowserView>
      <MobileView>
        <TransferBankSummaryMobile></TransferBankSummaryMobile>
      </MobileView>
    </>
  );
};

export default TransferBankSummary;
