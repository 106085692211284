import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { DonationDetailGeneralWeb } from "./donationDetailGeneralWeb";
import { DonationDetailGeneralMobile } from "./donationDetailGeneralMobile";

const DonationDetailGeneral = () => {
  return (
    <>
      <BrowserView>
        <DonationDetailGeneralWeb></DonationDetailGeneralWeb>
      </BrowserView>
      <MobileView>
        <DonationDetailGeneralMobile></DonationDetailGeneralMobile>
      </MobileView>
    </>
  );
};

export default DonationDetailGeneral;
