import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationProgressWeb from "./donationProgressWeb";
import DonationProgressMobile from "./donationProgressMobile";

const DonationProgress = () => {
  return (
    <>
      <BrowserView>
        <DonationProgressWeb></DonationProgressWeb>
      </BrowserView>
      <MobileView>
        <DonationProgressMobile></DonationProgressMobile>
      </MobileView>
    </>
  );
};

export default DonationProgress;
