import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { FC } from "react";

interface DonationHistorySkeletonProps {
  type?: string;
}

const DonationHistorySkeleton: FC<DonationHistorySkeletonProps> = (
  props
): JSX.Element => {
  return (
    <div className="flex flex-col w-full h-fit bg-white rounded-md  shadow-sm shadow-gray-700 gap-2 p-2">
      <div className="w-20 text-xs font-medium">
        <Skeleton />
      </div>
      <div className="flex flex-grow justify-between text-xs text-[#252525] font-medium">
        <div className="w-36">
          <Skeleton />
        </div>
        <div className="w-20">
          <Skeleton />
        </div>
      </div>
      <div className="w-14 text-xs font-medium">
        <Skeleton />
      </div>
    </div>
  );
};

export default DonationHistorySkeleton;
