import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import SummaryWeb from "./summaryWeb";
import SummaryMobile from "./summaryMobile";

const Summary = () => {
  return (
    <>
      <BrowserView>
        <SummaryWeb></SummaryWeb>
      </BrowserView>
      <MobileView>
        <SummaryMobile></SummaryMobile>
      </MobileView>
    </>
  );
};

export default Summary;
