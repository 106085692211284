import BNILogo from "../assets/images/icon_bank/bni2.png";
import BRILogo from "../assets/images/icon_bank/bri.png";
import BSILogo from "../assets/images/icon_bank/bsi.png";
import BCALogo from "../assets/images/icon_bank/bca.png";
import BTNLogo from "../assets/images/icon_bank/btn.png";
import BJSLogo from "../assets/images/icon_bank/bjs.png";
import CIMBLogo from "../assets/images/icon_bank/cimb.png";
import MandiriLogo from "../assets/images/icon_bank/mandiri.png";
import Permata from "../assets/images/icon_bank/Permata.png";
import Danamon from "../assets/images/icon_bank/Danamon.png";
import SahabatSampoerna from "../assets/images/icon_bank/SahabatSampoerna.png";
import OCBC from "../assets/images/icon_bank/OCBC.svg"

export const iconBank = (value) => {
    let bank = value?.toLowerCase();
    if (bank.includes("bni")) {
      return BNILogo;
    } else if (bank.includes("bri")) {
      return BRILogo;
    } else if (bank.includes("bsi")) {
      return BSILogo;
    } else if (bank.includes("btn")) {
      return BTNLogo;
    } else if (bank.includes("mandiri")) {
      return MandiriLogo;
    } else if (bank.includes("bca")) {
      return BCALogo;
    } else if (bank.includes("cimb")) {
      return CIMBLogo;
    } else if (bank.includes("bjs")) {
      return BJSLogo;
    } else if (bank.includes("danamon")) {
      return Danamon;
    } else if (bank.includes("permata")) {
      return Permata;
    } else if (bank.includes("sahabat sampoerna")) {
      return SahabatSampoerna;
    } else if (bank.includes("ocbc")) {
      return OCBC
    }
  
  };