import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBInternetTVWeb from "./ppobInternetTVWeb";
import PPOBInternetTVMobile from "./ppobInternetTVMobile";

const PPOBInternetTV = () => {
  return (
    <>
      <BrowserView>
        <PPOBInternetTVWeb></PPOBInternetTVWeb>
      </BrowserView>
      <MobileView>
        <PPOBInternetTVMobile></PPOBInternetTVMobile>
      </MobileView>
    </>
  );
};

export default PPOBInternetTV;
