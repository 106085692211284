/* eslint-disable react-hooks/exhaustive-deps */
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { toast } from "react-hot-toast";
import { StatefulPinInput } from "react-input-pin-code";
import { useLocation, useNavigate } from "react-router-dom";
import desktopBackground from "../../assets/images/desktopBgBlur.svg";
import closepayLogo from "../../assets/images/solusinegeri-logo.svg";
import { AlertPasswordStrength, checkRegex } from "../../config/global";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import * as API_AUTH from "../../core/service/api_auth";
import * as API_USER from "../../core/service/api_user";
interface ForgetPasswordWebProps {
  setCreateSuccessFully: (value: boolean) => void;
}
const ForgetPasswordWeb: React.FC<ForgetPasswordWebProps> = ({
  setCreateSuccessFully,
}) => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let location: any = useLocation();
  const [otp, setOtp] = useState<any>("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorOtp, setErrorOtp] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState("");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");
  const [email, setEmail] = useState("");
  const [isExpiredOtp, setIsExpiredOtp] = useState(false);
  const [timer, setTimer] = useState(0);
  const [strength, setStrength] = useState("");

  const requestOtp = async () => {
    dispatch(setLoading(true));
    setOtp("");
    setNewPassword("");
    setConfirmNewPassword("");
    setErrorNewPassword("");
    setErrorConfirmNewPassword("");

    let data = {
      destinationOtp: email,
      companyId: company?.id,
      userType: "MEMBER",
      name: "",
      otpType: "RESET_PASSWORD",
    };

    await API_AUTH.requestOtp(data)
      .then((res) => {
        dispatch(setLoading(false));
        setIsExpiredOtp(false);
        toast.success("Kode OTP telah dikirim ke email Anda");
        setTimer(Date.now() + res.data.data.expiredMillisecond);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getPasswordStrength = async () => {
    dispatch(setLoading(true));
    await API_USER.getPasswordStrength(company.id)
      .then((res) => {
        dispatch(setLoading(false));
        setStrength(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const renderer = (data: any) => {
    if (data.completed) {
      setIsExpiredOtp(true);
      return (
        <button
          className="text-red"
          type="button"
          onClick={() =>
            location?.state?.otp !== undefined ? navigate(-1) : requestOtp()
          }
        >
          Kirim Ulang
        </button>
      );
    } else {
      setIsExpiredOtp(false);
      return (
        <span className="text-[#728F9E] text-base text-center mt-2">
          Waktu anda yang tersisa {zeroPad(data.minutes)}:
          {zeroPad(data.seconds)}
        </span>
      );
    }
  };

  const handlePasswordType = (type: string) => {
    switch (type) {
      case "new":
        switch (newPasswordType) {
          case "password":
            setNewPasswordType("text");
            break;
          default:
            setNewPasswordType("password");
            break;
        }
        break;

      case "confirmNew":
        switch (confirmNewPasswordType) {
          case "password":
            setConfirmNewPasswordType("text");
            break;
          default:
            setConfirmNewPasswordType("password");
            break;
        }
        break;

      default:
        setNewPasswordType("password");
        setConfirmNewPasswordType("password");
    }
  };

  const submit = async (e: any) => {
    e.preventDefault();

    if (location?.state?.otp !== undefined) {
      if (isExpiredOtp) {
        toast.error("Waktu OTP Telah Expired");
      } else if (otp === "") {
        setErrorOtp("Kode otp harap diisi");
      } else if (newPassword === "") {
        setErrorNewPassword("Password baru harap diisi");
      } else if (!checkRegex("password", newPassword, strength)) {
        setErrorNewPassword(AlertPasswordStrength(strength));
      } else if (confirmNewPassword === "") {
        setErrorConfirmNewPassword("Konfirmasi password baru harap diisi");
      } else if (newPassword !== confirmNewPassword) {
        setErrorConfirmNewPassword("Konfirmasi password baru tidak sesuai");
      } else {
        dispatch(setLoading(true));
        let data = {
          otp: otp.join(""),
          newPassword,
          companyId: company?.id,
          phone: location?.state?.phone,
        };

        await API_USER.forgetPasswordWa(data)
          .then((res) => {
            dispatch(setLoading(false));
            setCreateSuccessFully(true);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    } else {
      if (isExpiredOtp) {
        toast.error("Waktu OTP Telah Expired");
      } else if (otp === "") {
        setErrorOtp("Kode otp harap diisi");
      } else if (newPassword === "") {
        setErrorNewPassword("Password baru harap diisi");
      } else if (!checkRegex("password", newPassword, strength)) {
        setErrorNewPassword(AlertPasswordStrength(strength));
      } else if (confirmNewPassword === "") {
        setErrorConfirmNewPassword("Konfirmasi password baru harap diisi");
      } else if (newPassword !== confirmNewPassword) {
        setErrorConfirmNewPassword("Konfirmasi password baru tidak sesuai");
      } else {
        dispatch(setLoading(true));
        let data = {
          otp: otp.join(""),
          newPassword,
          companyId: company?.id,
          email,
        };

        await API_USER.forgetPassword(data)
          .then((res) => {
            dispatch(setLoading(false));
            setCreateSuccessFully(true);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      }
    }
  };

  useEffect(() => {
    getPasswordStrength();
    setTimer(Date.now() + location.state.time);
    setEmail(location.state.email);
    if (location?.state?.otp !== undefined) {
      setOtp(location?.state?.otp);
    }
  }, []);

  return (
    <section
      className="h-full min-h-screen flex items-center bg-second dark:bg-black bg-cover p-4 font-montserrat"
      style={{ backgroundImage: `url(${desktopBackground})` }}
    >
      <div className="container font-poppins">
        <div className="flex flex-col justify-center items-center">
          <div className="lg:w-1/2 sm:w-3/4 md:w-3/4">
            <div className="text-center mt-10 mb-6 w-100">
              <h4 className="text-xl font-semibold md:mt-2 mt-1">
                Masukan kode OTP dan password
              </h4>
              <p className="text-base text-thirdy font-normal">
                Masukan kode otp yang telah dikirim ke <span>{email}</span>{" "}
                dengan Subjek OTP Reset Password dan masukkan password baru
                untuk mengganti password sebelumnya
              </p>
            </div>
            <form onSubmit={submit} className="w-100">
              <div className="flex flex-col mb-3 relative">
                <div className="flex flex-col items-center gap-1 md:my-[1rem] my-[10px]">
                  {location?.state?.otp !== undefined ? (
                    <div className="text-center">
                      <p>Otp Anda</p>
                      <h3>{location?.state?.otp}</h3>
                    </div>
                  ) : (
                    <StatefulPinInput
                      length={6}
                      placeholder=""
                      initialValue={otp}
                      onChange={(value, index, values: any) => {
                        setOtp(values);
                        setErrorOtp("");
                      }}
                      type="number"
                      mask={true}
                      focusBorderColor="#26A69A"
                      validBorderColor="#26A69A"
                      borderColor="#80CBC4"
                      inputStyle={{
                        width: 45,
                        height: 48,

                        margin: "0 0.2rem",
                        marginRight: "1rem",
                        fontSize: "2rem",
                        borderRadius: 12,
                        border: "1px solid #80CBC4",
                        background: "white",
                      }}
                    />
                  )}
                  <p className="text-base text-red pl-1 mb-0">{errorOtp}</p>
                </div>
                <Countdown key={timer} date={timer} renderer={renderer} />
              </div>
              <div>
                <h4 className="text-xl font-semibold md:mt-2 mt-1">
                  Selangkah Lagi
                </h4>
                <p className="text-base text-[#728F9E] font-normal">
                  Silahkan masukan password baru untuk mengganti passwordnya
                </p>
              </div>
              <div className="flex flex-col gap-2 md:mb-4 mb-3 relative">
                <label className="text-base text-thirdy font-medium pl-1 mb-0">
                  Password Baru
                </label>
                <input
                  type={newPasswordType}
                  className="focus:border-1 rounded-full md:p-[1rem] p-[12px] text-base border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25 shadow shadow-black"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setErrorNewPassword("");
                  }}
                />
                <p className="text-base text-red pl-1 mb-0">
                  {errorNewPassword}
                </p>
                <div
                  className="absolute md:top-[50px] top-[45px] right-[20px] cursor-pointer"
                  onClick={() => handlePasswordType("new")}
                >
                  {newPasswordType === "text" ? (
                    <FontAwesomeIcon icon={faEye} size="lg" color="#C4C4C4" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      size="lg"
                      color="#C4C4C4"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 relative">
                <label className="text-base text-thirdy font-medium pl-1 mb-0">
                  Konfirmasi Password Baru
                </label>
                <input
                  type={confirmNewPasswordType}
                  className="focus:border-1 rounded-full md:p-[1rem] p-[12px] text-base border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25 shadow shadow-black"
                  value={confirmNewPassword}
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                    setErrorConfirmNewPassword("");
                  }}
                />
                <div
                  className="absolute md:top-[50px] top-[45px] right-[20px] cursor-pointer"
                  onClick={() => handlePasswordType("confirmNew")}
                >
                  {confirmNewPasswordType === "text" ? (
                    <FontAwesomeIcon icon={faEye} size="lg" color="#C4C4C4" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      size="lg"
                      color="#C4C4C4"
                    />
                  )}
                </div>
                <p className="text-base text-red pl-1 mb-0">
                  {errorConfirmNewPassword}
                </p>
              </div>
              <div className="">
                <button
                  type="submit"
                  className="w-full md:p-[1rem] p-[12px] md:mt-6 mt-[1rem] rounded-full text-white font-semibold text-base bg-[#26A69A]"
                >
                  Konfirmasi
                </button>
              </div>
            </form>
            <div className="mt-4 w-100">
              <p
                className="md:text-xl text-base font-medium text-center md:mb-3 mb-0"
                style={{ color: "#8F8F8F" }}
              >
                Powered By
              </p>
              <img src={closepayLogo} alt="icon" className="w-32 mx-auto" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPasswordWeb;
