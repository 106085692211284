import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "lodash";
import { FC, useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { censorEmail, censorWord, imageOnError } from "../../config/global";
import { setLoading, setShowOtp } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { toast } from "react-hot-toast";
import ToastConfig from "../../config/Toast";
import { setOtpEmail } from "../../core/feature/otp/otpSlice";
import {
  // removeUsernameDestination,
  resetDestinationOtp,
  // setDestinationType,
  setShowDestinationOtp,
} from "../../core/feature/otp/destinationSlice";
import User from "../../core/models/user";
import * as API_AUTH from "../../core/service/api_auth";
import Company from "../../core/models/company";
import icon_verify from "../../assets/images/verified.svg";
import Token from "../../core/models/token";
import { setShowAddDestinationOtp } from "../../core/feature/otp/addDestinationSlice";
import noDestinationImg from "../../assets/images/icon/security-failed.svg";
import * as API_USER from "../../core/service/api_user.js";
import * as API from "../../core/service/api.js";
import { setUser } from "../../core/feature/user/userSlice";
import fingerPrint from "@fingerprintjs/fingerprintjs";
import { useNavigate } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";

interface DestinationOtpComponentProps {
  showChooseDestination: boolean;
  showAddDestination?: boolean;
  showDestinationOtp: boolean;
  onHide: any;
  data: any;
  username: string;
  password: string;
  login: any;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  stateEnd: string;
  getProfil?:boolean;
}

const DestinationOtpComponent: FC<DestinationOtpComponentProps> = (
  props
): JSX.Element => {
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  let transactionType: string = useAppSelector(
    (state) => state.otp.transactionType
  );
  const [listDestination, setListDestination] = useState([
    {
      value: "EMAIL",
      key: "email",
      label: "E-mail",
      description: "Email",
      isDisabled: false,
      isVerified: props.isEmailVerified,
    },
    {
      value: "WA",
      key: "phone",
      label: "Whatsapp",
      description: "No Telepon",
      isDisabled: false,
      isVerified:
        props.isPhoneVerified === true ||
        (typeof props.isPhoneVerified === "string" &&
          props.isPhoneVerified === "verified_whatsapp"),
    },
  ]);
  const company: Company = useAppSelector((state) => state.company.company);
  // const [data, setData] = useState<any>({
  //   EMAIL: props.data.email || "",
  //   WA: props.data.phone || "",
  // });
  const [selected, setSelected] = useState("");
  const [unverifiedImage] = useState(noDestinationImg);
  const dispatch = useAppDispatch();
  const currentToken = token?.find(
    (item) => item.cid === company?.initial
  )?.token;

  const [deviceId, setDeviceId] = useState("");
  const [modalVerificationWa, setModalVerificationWa] = useState(false);
  const [state, setState] = useState("");
  const [tokenWa, setTokenWa] = useState("");
  const [timer, setTimer] = useState(0);
  const [show, setShow] = useState("block");
  const [phoneFer, setPhoneFer] = useState<any>(
    user?.phone === "" ? "" : user?.phone
  );
  const navigate = useNavigate();
  const [transType, setTransType] = useState("");
  const otpUrl: string = useAppSelector((state) => state.otp.url);
  const otpData: any = useAppSelector((state) => state.otp.data);
  const [phone, setPhone] = useState("");
  const { t } = useTranslation();

  const insertDestinationOtp = () => {
    // console.log("to insert destination otp form");
    props.onHide();
    dispatch(setShowAddDestinationOtp(true));
  };

  // const confirm = () => {
  //   if (props.showAddDestination) {
  //     return addDestinationOtp();
  //   }
  //   if (!data[selected])
  //     return toast("Tujuan Pengiriman Tidak Boleh Kosong", ToastConfig.warning);
  //   updateDestinationOtp();
  // };

  const addDestinationOtp = async () => {
    // console.group();
    let newData: any = {};
    if (currentToken && cekUser()) {
      console.log("klik");
      setShow("block");
      if (transactionType === "ADD_BANK_ACCOUNT" && selected === "WA") {
        let data = {
          phoneNumber: phoneFer,
          companyId: company?.id,
          userType: "MEMBER",
          transactionType: transactionType,
          deviceId: deviceId,
        };
        dispatch(setLoading(true));
        await API_AUTH.requestTokenVerivicationWa(data)
          .then((res) => {
            setTransType("ADD_BANK_ACCOUNT");
            setModalVerificationWa(true);
            window.open(
              `https://wa.me/${res.data.data.destinationPhoneNumber
              }?text=${encodeURI(
                `Halo ini adalah kode verifikasi saya "${res.data.data.token}" tolong verifikasi ya.`
              )}`,
              `_blank`
            );
            setPhone(res.data.data.senderPhoneNumber);
            setShow("hidden");
            setTokenWa(res.data.data.token);
            setState(res.data.data.state);
            setTimer(Date.now() + res.data.data.expiredMillisecond);
            // setAddEmailForm(false);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      } else {
        newData["transactionType"] = transactionType;
        newData["otpDestinationType"] = selected;
        API_AUTH.requestOtpMember(newData)
          .then((res) => {
            toast.success("Kode OTP telah dikirim ke email Anda");
            // dispatch(setDestinationType(selected));
            dispatch(setShowDestinationOtp(false));
            dispatch(setShowOtp(true));
            if (selected === "WA") {
              dispatch(setOtpEmail(user?.phone));
            } else {
              dispatch(setOtpEmail(user?.email));
            }
          })
          .catch((err) => { });
      }
    } else {
      if (transactionType === "SELF_REGISTER") {
        newData["destinationOtp"] = props.data?.email;
        newData["companyId"] = company.id;
        newData["userType"] = "MEMBER";
        newData["name"] = props.data?.name;
        newData["otpType"] = transactionType;
        await API_AUTH.requestOtpGuest(newData)
          .then((res) => {
            props.onHide();
            dispatch(setShowOtp(true));
            // dispatch(setDestinationType(selected));
            if (selected === "WA") {
              dispatch(setOtpEmail(props.data.phone));
            } else {
              dispatch(setOtpEmail(props.data.email));
            }
            dispatch(setLoading(false));
            // dispatch(removeUsernameDestination);
          })
          .then((err) => {
            dispatch(setLoading(false));
          });
      } else {
        newData["username"] = props.username;
        newData["companyId"] = company.id;
        newData["type"] = selected;
        // dispatch(setLoading(true));
        console.log("klik");
        if (selected === "WA") {
          let data = {
            companyId: company?.id,
            username: props.username,
            userType: "MEMBER",
            deviceId: deviceId,
          };
          dispatch(setLoading(true));
          await API_AUTH.requestTokenNewDevice(data)
            .then((res) => {
              setTransType("LOGIN");
              setModalVerificationWa(true);
              window.open(
                `https://wa.me/${res.data.data.destinationPhoneNumber
                }?text=${encodeURI(
                  `Halo ini adalah kode verifikasi saya "${res.data.data.token}" tolong verifikasi ya.`
                )}`,
                `_blank`
              );
              setShow("hidden");
              setTokenWa(res.data.data.token);
              setState(res.data.data.state);
              setTimer(Date.now() + res.data.data.expiredMillisecond);
              setPhone(res.data.data.senderPhoneNumber);
              // setAddEmailForm(false);
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(setLoading(false));
            });
        } else {
          API_AUTH.choseOtpSender(newData, company.id)
            .then((res) => {
              // console.groupEnd();
              // dispatch(setDestinationType(selected));
              props.onHide();
              dispatch(setShowOtp(true));
              dispatch(setOtpEmail(props.data.email));
              dispatch(setLoading(false));
            })
            .then((err) => {
              dispatch(setLoading(false));
            });
        }
      }
    }
  };

  const checkTokenWa = async () => {
    dispatch(setLoading(true));
    let newData = {
      state: state,
      phoneNumber: `${phone}`,
      companyId: company?.id,
      transactionType: transType,
      deviceId: deviceId,
      token: tokenWa,
    };
    dispatch(setLoading(true));
    await API_AUTH.checkTokenVerification(newData)
      .then((res) => {
        dispatch(setLoading(false));
        console.log(res.data.data);
        if (transType === "LOGIN") {
          let query = {
            username: props.username,
            password: props.password,
            companyId: company.id,
            deviceId: deviceId,
            state: props.stateEnd,
          };
          dispatch(setLoading(true));
          API.login(query, res.data.data.otp)
            .then((res) => {
              props.login();
              dispatch(setLoading(false));
            })
            .catch((err) => {
              dispatch(setLoading(false));
              // setShowDataVerification(false);
            });
        } else {
          dispatch(setLoading(true));
          API_AUTH.sendOtp(otpUrl, otpData, res.data.data.otp)
            .then((res: any) => {
              setTimeout(() => {
                props.onHide();
                dispatch(setLoading(false));
                setModalVerificationWa(false);
                setShow("block");
                navigate(`${company.initial}/transfer/bank/input-data`, {
                  replace: true,
                  state: res.data.data,
                });
                // dispatch(removeOtp());
              }, 6000);
            })
            .catch((err: any) => {
              setModalVerificationWa(false);
              setShow("block");
              dispatch(setLoading(false));
              props.onHide();
              if (
                err.response?.data?.detail?.type ===
                "MAX_BANK_ACCOUNT_COUNTS_REACHED"
              ) {
                // dispatch(removeOtp());
                navigate(`${company?.initial}/transfer/bank`, {
                  replace: true,
                });
              }
            });
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  // const updateDestinationOtp = () => {
  //   // console.group();
  //   // let newData = {
  //   //   email: data.email,
  //   //   whatsapp: data.whatsapp,
  //   //   selected: selected,
  //   // };
  //   dispatch(setDestinationType(selected));
  //   // console.groupEnd();
  //   dispatch(setOtpEmail(data[selected]));
  //   props.onHide();
  //   dispatch(setShowOtp(true));
  // };

  const cekUser = () => {
    return props.username === user?.username;
  };

  const checkAllVerified = () => {
    let verified = false;
    map(
      listDestination,
      (item: any) => (verified = verified || item.isVerified)
    );
    return !verified;
  };

  const getDataProfile = async () => {
    await API_USER.getProfile()
      .then((res) => {
        dispatch(setUser(res.data.data));
        setListDestination([
          {
            value: "EMAIL",
            key: "email",
            label: "E-mail",
            description: "Email",
            isDisabled: !user?.emailVerified,
            isVerified: res.data.data.emailVerified,
          },
          {
            value: "WA",
            key: "phone",
            label: "Whatsapp",
            description: "No Telepon",
            isDisabled: !user?.phoneVerified,
            isVerified:
              res.data.data.phoneVerified === true ||
              (typeof res.data.data.phoneVerified === "string" &&
                res.data.data.phoneVerified === "verified_whatsapp"),
          },
        ]);
        // props.isEmailVerified = res.data.data.emailVerified;
        // props.isPhoneVerified = res.data.data.phoneVerified;
      })
      .catch((err) => { });
  };

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <button
          className="text-[#26A69A] my-3"
          type="button"
          onClick={() => addDestinationOtp()}
        >
          Kirim Ulang
        </button>
      );
    } else {
      return (
        <span className="text-[14px] text-center my-4">
          <span>{t('destinationOtpComponent.resendOtp')}</span>
          <span className="text-[#FF9723] font-semibold ml-2">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };

  useEffect(() => {
    if (props.showDestinationOtp && props.getProfil) getDataProfile();
    const fpPromise = fingerPrint.load();

    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        const visitorId = result.visitorId;
        setDeviceId(visitorId);
        // dispatch(setOtpDeviceId(visitorId));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showDestinationOtp]);

  return (
    <>
      <div
        className={`${modalVerificationWa ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl px-4 py-5">
              <span className="text-[20px] font-semibold mb-2">
                {t("destinationOtpComponent.verificationTitle")}
              </span>
              <button
                type="button"
                onClick={() => {
                  setModalVerificationWa(false);
                  setShow("block");
                  dispatch(resetDestinationOtp());
                  props.onHide();
                  //   setSecurityCode("");
                }}
                className="fa-xl text-gray-400 absolute right-10 top-5"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <div className="text-left mt-4">
                <span>{t('destinationOtpComponent.verificationDescription')}</span>
                <input
                  disabled
                  type="number"
                  value={phone}
                  className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                ></input>
                <p className="mt-5 text-center">
                  Sudah Verifikasi ke WhatsApp? Klik "Saya Sudah Verifikasi"
                  untuk melanjutkan
                </p>
              </div>
              <Countdown key={timer} date={timer} renderer={renderer} />
              <button
                onClick={() => {
                  checkTokenWa();
                }}
                className="bg-green text-white py-2 mt-5 rounded-lg w-full"
              >
                {t('destinationOtpComponent.verificationButton')}
              </button>
              {/* <span
                  className="mt-4 text-xs cursor-pointer"
                  onClick={() => {
                    setShowDataVerification(false);
                    navigate(`/${company.initial}`);
                  }}
                >
                  Lewati langkah ini
                </span> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${props.showDestinationOtp ? `${show}` : "hidden"
          } relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto font-montserrat">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]">
              <div className="flex flex-row justify-end w-100 mb-3 md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    props.onHide();
                    dispatch(resetDestinationOtp());
                    //   setSecurityCode("");
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p className="text-base font-medium">{t('destinationOtpComponent.notificationDestination')}</p>
              {checkAllVerified() && transactionType !== "SELF_REGISTER" ? (
                <div className="flex flex-col items-center gap-2">
                  <img
                    src={unverifiedImage}
                    width={118}
                    onError={imageOnError}
                    alt=""
                  />
                  <p>
                    {t('destinationOtpComponent.unverifiedUserMessage')}
                  </p>
                  <button
                    onClick={() => {
                      insertDestinationOtp();
                    }}
                    style={{
                      backgroundColor: "#26A69A",
                    }}
                    className={` w-full md:py-[16px] p-[10px] rounded-lg text-white font-semibold md:text-base text-sm disabled:bg-slate-50 md:mt-6 mt-[1rem]`}
                  >
                    {t('destinationOtpComponent.addDestinationButton')}
                  </button>
                </div>
              ) : (
                <>
                  <p className="text=xs text-thirdy">
                    {t('destinationOtpComponent.chooseDestination')}
                  </p>

                  <BrowserView className="w-full" key={selected}>
                    {map(listDestination, (item: any) =>
                      item.isVerified ||
                        (transactionType === "SELF_REGISTER" &&
                          props.data[item.key]) ? (
                        <div className="flex flex-col w-full my-4">
                          <div className="flex gap-2">
                            <input
                              disabled={!props.data[item.key]}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              type="radio"
                              name="selected"
                              id={`destination-${item.value}`}
                              checked={item.value === selected}
                              onChange={() => {
                                if (item.value != "WA") {
                                  setSelected(item.value);
                                }
                              }}
                            />
                            <div className="flex flex-col gap-1 text-start grow">
                              <label
                                className="flex justify-start "
                                htmlFor={`destination-${item.value}`}
                              >
                                {item.label}
                                {/* {data.EMAIL} */}
                              </label>
                              <div className="text-xs text-thirdy">
                                {item.value !== "WA" && (
                                  <span>{t('destinationOtpComponent.emailNotificationLabel')}</span>
                                )}
                              </div>
                              <div className="relative">
                                <input
                                  // style={{
                                  //   display:
                                  //     item.value === "WA" ? "hidden" : "block",
                                  // }}
                                  disabled={
                                    !props.showAddDestination ||
                                    item.value === "WA"
                                  }
                                  type="text"
                                  value={
                                    item.value === "EMAIL"
                                      ? props.data.email &&
                                      censorEmail(props.data.email)
                                      : props.data.phone
                                  }
                                  onChange={(e) => {
                                    setPhoneFer(e.target.value);
                                  }}
                                  placeholder={
                                    item.value !== "EMAIL"
                                      ? "Masukan Nomor Wa Tujuan"
                                      : ""
                                  }
                                  style={{
                                    backgroundColor:
                                      item.value === "WA" ? "#e9e9e9" : "",
                                  }}
                                  className="text-sm font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                                ></input>
                                {!item.isDisabled === true &&
                                  currentToken &&
                                  (cekUser() || item.isVerified) &&
                                  transactionType !== "SELF_REGISTER" && (
                                    <img
                                      src={icon_verify}
                                      alt=""
                                      className="w-7 h-7 absolute right-2 top-[.5rem]"
                                    />
                                  )}
                                {item.value === "WA" && (
                                  <p className="text-center text-[12px] mt-4">
                                    Saat ini Whatsapp tidak tersedia. Silahkan
                                    hubungi ,<br />
                                    <a
                                      className="underline text-blue-400"
                                      href="https://wa.me/+6289526643223"
                                      target="_blank"
                                    >
                                      CS Closepay
                                    </a>
                                  </p>
                                )}
                                {selected === "WA" && (
                                  <p className="text-center text-[12px] mt-4">
                                    {t('destinationOtpComponent.verificationPrompt')}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null
                    )}
                  </BrowserView>
                  <MobileView className="w-full">
                    {map(listDestination, (item: any) =>
                      item.isVerified ||
                        (transactionType === "SELF_REGISTER" &&
                          props.data[item.key]) ? (
                        <div className="flex flex-col w-full my-4">
                          <div className="flex gap-2">
                            <input
                              disabled={!props.data[item.key]}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              type="radio"
                              name="selected"
                              id={item.value}
                              checked={item.value === selected}
                              onChange={() => {
                                if (item.value != "WA") {
                                  setSelected(item.value);
                                }
                              }}
                            />
                            <div className="flex flex-col gap-1 text-start grow">
                              <label
                                className="flex justify-start "
                                htmlFor={item.value}
                              >
                                {item.label}
                                {/* {data.WA} */}
                              </label>
                              <div className="text-xs text-thirdy">
                                {item.value !== "WA" && (
                                  <span>{t('destinationOtpComponent.whatsappNotificationLabel')}</span>
                                )}
                              </div>
                              <div className="relative">
                                <input
                                  disabled={
                                    !props.showAddDestination ||
                                    item.value === "WA"
                                  }
                                  type="text"
                                  value={
                                    item.value === "EMAIL"
                                      ? props.data.email &&
                                      censorEmail(props.data.email)
                                      : props.data.phone
                                  }
                                  onChange={(e) => {
                                    setPhoneFer(e.target.value);
                                  }}
                                  placeholder={
                                    item.value !== "EMAIL"
                                      ? "Masukan Nomor Wa Tujuan"
                                      : ""
                                  }
                                  style={{
                                    backgroundColor:
                                      item.value === "WA" ? "#e9e9e9" : "",
                                  }}
                                  className="text-sm font-normal border-[#C4C4C4] border-[1px] disabled:text-gray-500 focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                                ></input>
                                {!item.isDisabled === true &&
                                  currentToken &&
                                  (cekUser() || item.isVerified) &&
                                  transactionType !== "SELF_REGISTER" && (
                                    <img
                                      src={icon_verify}
                                      alt=""
                                      className="w-7 h-7 absolute right-2 top-[.5rem]"
                                    />
                                  )}
                                {item.value === "WA" && (
                                  <p className="text-center text-[12px] mt-4">
                                    Saat ini Whatsapp tidak tersedia. Silahkan
                                    hubungi <br />
                                    <a
                                      className="underline text-blue-400"
                                      href="https://wa.me/+6289526643223"
                                      target="_blank"
                                    >
                                      CS Closepay
                                    </a>
                                  </p>
                                )}
                                {selected === "WA" && (
                                  <p className="text-center text-[12px] mt-4">
                                    Klik “konfirmasi” dan Anda akan diarahkan ke
                                    Whatsapp untuk <br /> melakukan verifikasi
                                    aktivitas Anda
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null
                    )}
                  </MobileView>
                  <button
                    disabled={!selected}
                    onClick={() => {
                      !selected
                        ? toast(
                          "Silakan Isi dan Pilih Tujuan Pengiriman OTP",
                          ToastConfig.warning
                        )
                        : addDestinationOtp();
                    }}
                    className={`${!selected
                      ? "bg-gray-500"
                      : "bg-gradient-to-b from-[#16C894] to-[#04A47C]"
                      } w-full md:py-[16px] p-[10px] rounded-lg text-white font-semibold md:text-base text-sm disabled:bg-slate-50 md:mt-6 mt-[1rem]`}
                  >
                    {t('destinationOtpComponent.confirm')}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DestinationOtpComponent;
