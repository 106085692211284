/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { Fragment } from "react";
import { formatCurrency } from "../../../config/global";
import Header from "../../_common/headerComponent";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useTranslation } from "react-i18next";

const Icon = (data: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${data.open[data.id] ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

const SummaryMobile = (props: any) => {
  const [showModalUniversalNotes, setShowModalUniversalNotes] = React.useState(false);
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Ringkasan" />

      {/* PRODUCT NOTES MODAL */}
      <BottomSheet
        open={props.selectedProductNotes.productNoteVOS !== undefined}
        onDismiss={props.handleClose}
        className="relative z-50 font-montserrat"
      >
        <div className="mx-4 mb-4">
          <h5 className="md:text-base text-sm font-bold mb-3">
            {t('create_order.form')} {props.selectedProductNotes.productName}
          </h5>
          <form className="space-y-6" action="#">
            {props.selectedProductNotes?.productNoteVOS?.length > 0 &&
              props.selectedProductNotes?.productNoteVOS?.map(
                (item: any, index: number) => (
                  <div className="mb-2 text-start" key={index}>
                    <label htmlFor="">{item.name}</label>
                    {item.required && <span className="text-red">*</span>}
                    <input
                      type={item?.type}
                      className="w-full bg-[#E5E5E5] rounded-md p-2"
                      value={item?.note ? item?.note : "-"}
                      readOnly
                      disabled
                    />
                  </div>
                )
              )}
          </form>
        </div>
      </BottomSheet>

      {/* UNIVERSAL NOTES MODAL */}
      <BottomSheet
        open={showModalUniversalNotes}
        onDismiss={() => setShowModalUniversalNotes(false)}
        className="relative z-50 font-montserrat"
      >
        <div className="mx-4 mb-4">
          <h5 className="md:text-base text-sm font-bold mb-3">
            {t('create_order.general_form')}
          </h5>
          <form className="space-y-6" action="#">
            {props.dataOrder?.universalNote?.map((el: any, index: number) => (
              <div className="mb-2 text-start" key={index}>
                <label htmlFor="">{el.key}</label>
                <input
                  className="w-full bg-[#E5E5E5] rounded-md p-2"
                  value={el.value}
                  readOnly
                  disabled
                />
              </div>
            ))}
          </form>
        </div>
      </BottomSheet>

      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0">
        <div className="md:w-3/5 w-full mx-auto">
          {props.data.map((item: any, index: number) => (
            <div key={index}>
              <div className="bg-white w-full p-4 mb-[30px] shadow-sm">
                <h6 className="text-lg md:text-xl font-medium text-green">
                  {t('create_order.summary')}
                </h6>
                <hr />
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-black text-start font-semibold mb-0">
                    {t('create_order.transaction_date')}
                  </p>
                  <p className="text-xs md:text-sm text-thirdy font-normal mb-0 text-end">
                    {moment().format("DD MMM YYYY")}
                  </p>
                </div>
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-black text-start font-semibold mb-0">
                    {t('create_order.shipping')}
                  </p>
                  {item?.ship?.isShipper ? (
                    <p className="text-xs md:text-sm text-thirdy font-normal mb-0 text-end">
                      {item?.ship?.masterShipper?.logisticName
                        ? item?.ship?.masterShipper?.logisticName +
                        " - " +
                        item?.amountDelivery
                        : "-"}
                    </p>
                  ) : (
                    <p className="text-xs md:text-sm text-thirdy font-normal mb-0 text-end">
                      {item?.ship?.name
                        ? item?.ship?.name + " - " + item?.amountDelivery
                        : "-"}
                    </p>
                  )}
                </div>
                {item?.orderReceiver !== undefined && (
                  <div className="flex flex-col gap-3 mt-[1rem]">
                    <h1 className="m-0 font-medium md:text-lg text-base text-newColor">{t('create_order.data_order')}</h1>
                    <div className="flex flex-row justify-between items-center">
                      <h1 className="md:text-base text-sm font-medium m-0 text-black">{t('create_order.name')}</h1>
                      <span className="md:text-sm text-xs font-normal text-[#848383]">
                        {item?.orderReceiver?.name}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                      <h1 className="md:text-base text-sm font-medium m-0 text-black">{t('create_order.address')}</h1>
                      <span className="md:text-sm text-xs font-normal text-[#848383]">
                        {item?.orderReceiver?.address}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                      <h1 className="md:text-base text-sm font-medium m-0 text-black">{t('create_order.phone')}</h1>
                      <span className="md:text-sm text-xs font-normal text-[#848383]">
                        {item?.orderReceiver?.phone}
                      </span>
                    </div>
                  </div>
                )}
                <div className="space-between gap-3 mt-[1rem]">
                  <p className="text-sm md:text-base text-black text-start font-semibold mb-0">
                    {props.marketplaceConfig.merchantName}
                  </p>
                  <p className="text-xs md:text-sm text-thirdy font-normal mb-0 text-end">
                    {item?.merchant?.name}
                  </p>
                </div>
                <div className="mt-[1rem]">
                  <p className="text-sm md:text-base text-black text-start font-semibold mb-0">
                    {t('create_order.shipping_address')}
                  </p>
                  <div>
                    <p className="text-xs md:text-sm text-thirdy font-light mb-0">
                      {item?.address?.name}
                    </p>
                    <p className="text-xs md:text-sm text-thirdy font-light mb-0">
                      {item?.address?.location?.suburb?.name}
                    </p>
                    <p className="text-xs md:text-sm text-thirdy font-light mb-0">
                      {item?.address?.location?.city?.name}
                    </p>
                  </div>
                </div>
                <div className="mt-[1rem]">
                  <p className="text-sm md:text-base text-black text-start font-semibold mb-0">
                    {t('create_order.service')}
                  </p>
                  {props.productList.find((i: any) => i.merchantId === item.merchant.id).products.map((el: any) => (
                    <div key={el?.productId}>
                      <div className="space-between gap-3">
                        <h6 className="text-xs md:text-sm text-newColor font-semibold mb-0">
                          {el?.item.length > 0 ? el?.item[0].productName : "-"}
                        </h6>
                        <div className="space-between gap-2 cursor-pointer" onClick={() => props.handleOpen(el?.productId)}>
                          <h6 className="text-[#C4C4C4] font-montserrat text-xs font-semibold mb-0">{t('create_order.more_complete')}</h6>
                          <Icon open={props.expand} id={el?.productId} />
                        </div>
                      </div>
                      {props.expand[el?.productId] && (
                        <Fragment>
                          <ul className="list-disc">
                            {el?.item?.map((el2: any) => (
                              <li className="text-thirdy text-xs font-light mb-0" key={el2.itemId}>
                                <div className="flex justify-between items-end gap-3 text-[#9A9999] text-xs font-light mb-0">
                                  <div>
                                    <p className="mb-1">{el2?.itemName}</p>
                                    <p className="mb-0">{el2?.quantity} x {el2?.price}</p>
                                  </div>
                                  <span>{formatCurrency(el2?.amountTotal)}</span>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {el?.item.length > 0 && (
                            el?.item[0].productNoteVOS.length > 0 && (
                              <div className="flex flex-col">
                                <h6 className="text-sm text-customBlack font-semibold mb-0">
                                  {t('create_order.form_product_summary')} {el?.item.length > 0 ? el?.item[0].productName : "-"}
                                </h6>
                                <button
                                  type="button"
                                  onClick={() => props.selectForm(item?.merchant?.id, el?.productId)}
                                  className="font-montserrat text-sm text-green font-normal"
                                >
                                  {t('create_order.see_form')}
                                </button>
                              </div>
                            )
                          )}
                        </Fragment>
                      )}
                    </div>
                  ))}
                </div>
                <div className="mt-3">
                  <label className="text-[#1A1A1A] font-semibold md:text-sm text-xs">
                    {t('create_order.note')}
                  </label>
                  <input
                    disabled
                    type="text"
                    readOnly
                    className="w-full border-[1px] border-solid border-[#C4C4C4] bg-[#FAFAFA] rounded-md p-2"
                    value={item?.note === "" ? "-" : item?.note}
                  />
                </div>
                {props.expandAll[item.merchant.id] ? (
                  <div className="space-between gap-3 mt-3 px-2">
                    <h6 className="text-customBlack md:text-sm text-xs font-semibold mb-0">
                      {t('create_order.total_order')}
                    </h6>
                    <p className="text-thirdy md:text-sm text-xs font-medium mb-0">
                      {formatCurrency(
                        item?.amountProduct + item?.amountDelivery
                      )}
                    </p>
                  </div>
                ) : (
                  <div className="space-between gap-3 bg-[#CFEAE8] rounded-md px-2 py-[10px] mt-3">
                    <h6 className="text-green md:text-sm text-xs font-semibold mb-0">
                      {t('create_order.total_order')}
                    </h6>
                    <p className="text-green md:text-sm text-xs font-medium mb-0">
                      {formatCurrency(
                        item?.amountProduct + item?.amountDelivery
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}

          {props.dataOrder?.universalNote?.length > 0 && (
            <div className="bg-white w-full p-4 mb-[30px] shadow-sm flex flex-col">
              <h6 className="text-customBlack w-full text-start text-sm font-semibold mb-2">
                {t('create_order.general_form')}
              </h6>
              <button
                type="button"
                onClick={() => setShowModalUniversalNotes(true)}
                className="font-montserrat text-sm text-green font-normal"
              >
                {t('create_order.see_form')}
              </button>
            </div>
          )}

          <div className="bg-white shadow-sm p-3 mb-[30px]">
            <div className="space-between gap-3 px-2 mb-2">
              <h6 className="text-customBlack md:text-base text-sm font-semibold mb-0">
                {t('create_order.order_subtotal')}
              </h6>
              <p className="text-thirdy md:text-sm text-xs font-light mb-0">
                {formatCurrency(props.orderTotal)}
              </p>
            </div>
            <div className="space-between gap-3 px-2 mb-2">
              <h6 className="text-customBlack md:text-base text-sm font-semibold mb-0">
                {t('create_order.shippingCost')}
              </h6>
              <p className="text-thirdy md:text-sm text-xs font-light mb-0">
                {formatCurrency(props.shipTotal)}
              </p>
            </div>
            <div className="space-between gap-3 px-2 mb-2">
              <h6 className="text-customBlack md:text-base text-sm font-semibold mb-0">
                {t('create_order.serviceFee')}
              </h6>
              <p className="text-thirdy md:text-sm text-xs font-light mb-0">
                {formatCurrency(props.chargeTotal)}
              </p>
            </div>
            <div className="space-between gap-3 px-2 mb-2">
              <h6 className="text-customBlack md:text-base text-sm font-semibold mb-0">
                {t('create_order.payment_total')}
              </h6>
              <p className="text-thirdy md:text-sm text-xs font-medium mb-0">
                {formatCurrency(props.orderTotal + props.shipTotal + props.chargeTotal)}
              </p>
            </div>
          </div>

          <div className="w-full bg-white p-4">
            <div className="space-between">
              <h6 className="text-customBlack md:text-base text-sm text-start font-medium">
                {t('create_order.payment_total')}
              </h6>
              <p className="md:text-lg text-base text-end font-semibold text-green">
                {formatCurrency(props.totalPrice)}
              </p>
            </div>
            <button
              type="button"
              onClick={props.handleOnClick}
              style={{ backgroundColor: `${props.company?.color?.color1 !== undefined ? props.company?.color?.color1 : "#26A69A"}` }}
              className="bg-green text-white font-bold rounded-xl w-full p-[12px]"
            >
              {t('create_order.confirm')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SummaryMobile;