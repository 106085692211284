import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ChangeCardSecurityCodeWeb from "./changeCardSecurityCodeWeb";
import ChangeCardSecurityCodeMobile from "./changeCardSecurityCodeMobile";
import { useNavigate, useParams } from "react-router-dom";
import { setLoading } from "../../../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../../../core/feature/hooks";
import * as API_VIRTUAL_CARD from "../../../../core/service/api_virtualCard";
import * as API_AUTH from "../../../../core/service/api_auth";
import Company from "../../../../core/models/company";
import { useTranslation } from "react-i18next";

const ChangeCardSecurityCode = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [oldSecurityCode, setOldSecurityCode] = React.useState("");
  const [newSecurityCode, setNewSecurityCode] = React.useState("");
  const [confirmNewSecurityCode, setConfirmNewSecurityCode] =
    React.useState("");
  const [errorOldSecurityCode, setErrorOldSecurityCode] = React.useState("");
  const [errorNewSecurityCode, setErrorNewSecurityCode] = React.useState("");
  const [errorConfirmNewSecurityCode, setErrorConfirmNewSecurityCode] =
    React.useState("");
  const [createSuccessFully, setCreateSuccessFully] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const hideModal = () => setShowModal(false);

  const confirm = () => {
    dispatch(setLoading(true));
    let data = {
      cardId: params.id,
      securityCode: oldSecurityCode,
    };

    API_AUTH.reauthenticateCardSecurityCode(data)
      .then((res2) => {
        dispatch(setLoading(false));
        let newData = {
          newSecurityCode,
          confirmSecurityCode: confirmNewSecurityCode,
        };
        API_VIRTUAL_CARD.cardSecurityCode(
          params.id,
          newData,
          res2.data.data.securityCodeToken
        )
          .then((res3) => {
            setCreateSuccessFully(true);
            setShowModal(false);
          })
          .catch((err3) => {
            setShowModal(false);
          });
      })
      .catch((err2) => {
        dispatch(setLoading(false));
        setShowModal(false);
      });
  };

  const handleOnClick = async () => {
    if (createSuccessFully) {
      navigate(`/${company?.initial}/virtual-card/list`, { replace: true });
    } else {
      if (oldSecurityCode === "") {
        setErrorOldSecurityCode(t('card_security_code.insert_pin_old'));
      } else if (newSecurityCode === "") {
        setErrorNewSecurityCode(t('card_security_code.insert_pin_new'));
      } else if (confirmNewSecurityCode === "") {
        setErrorConfirmNewSecurityCode(t('card_security_code.insert_pin_confirm'));
      } else if (newSecurityCode !== confirmNewSecurityCode) {
        setErrorConfirmNewSecurityCode(t('card_security_code.new_pin_same'));
      } else {
        confirm();
      }
    }
  };

  return (
    <>
      <BrowserView>
        <ChangeCardSecurityCodeWeb
          confirm={() => confirm()}
          hideModal={() => hideModal()}
          handleOnClick={() => handleOnClick()}
          setOldSecurityCode={(value: any) => setOldSecurityCode(value)}
          setErrorOldSecurityCode={(value: any) =>
            setErrorOldSecurityCode(value)
          }
          setNewSecurityCode={(value: any) => setNewSecurityCode(value)}
          setErrorNewSecurityCode={(value: any) =>
            setErrorNewSecurityCode(value)
          }
          setConfirmNewSecurityCode={(value: any) =>
            setConfirmNewSecurityCode(value)
          }
          setErrorConfirmNewSecurityCode={(value: any) =>
            setErrorConfirmNewSecurityCode(value)
          }
          company={company}
          oldSecurityCode={oldSecurityCode}
          newSecurityCode={newSecurityCode}
          confirmNewSecurityCode={confirmNewSecurityCode}
          errorOldSecurityCode={errorOldSecurityCode}
          errorNewSecurityCode={errorNewSecurityCode}
          errorConfirmNewSecurityCode={errorConfirmNewSecurityCode}
          createSuccessFully={createSuccessFully}
          showModal={showModal}
          t={t}
        />
      </BrowserView>
      <MobileView>
        <ChangeCardSecurityCodeMobile
          confirm={() => confirm()}
          hideModal={() => hideModal()}
          handleOnClick={() => handleOnClick()}
          setOldSecurityCode={(value: any) => setOldSecurityCode(value)}
          setErrorOldSecurityCode={(value: any) =>
            setErrorOldSecurityCode(value)
          }
          setNewSecurityCode={(value: any) => setNewSecurityCode(value)}
          setErrorNewSecurityCode={(value: any) =>
            setErrorNewSecurityCode(value)
          }
          setConfirmNewSecurityCode={(value: any) =>
            setConfirmNewSecurityCode(value)
          }
          setErrorConfirmNewSecurityCode={(value: any) =>
            setErrorConfirmNewSecurityCode(value)
          }
          company={company}
          oldSecurityCode={oldSecurityCode}
          newSecurityCode={newSecurityCode}
          confirmNewSecurityCode={confirmNewSecurityCode}
          errorOldSecurityCode={errorOldSecurityCode}
          errorNewSecurityCode={errorNewSecurityCode}
          errorConfirmNewSecurityCode={errorConfirmNewSecurityCode}
          createSuccessFully={createSuccessFully}
          showModal={showModal}
          t={t}
        />
      </MobileView>
    </>
  );
};

export default ChangeCardSecurityCode;
