import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PaymentSummaryWeb from "./paymentSummaryWeb";
import PaymentSummaryMobile from "./paymentSummaryMobile";

const PaymentSummary = () => {
  return (
    <>
      <BrowserView>
        <PaymentSummaryWeb></PaymentSummaryWeb>
      </BrowserView>
      <MobileView>
        <PaymentSummaryMobile></PaymentSummaryMobile>
      </MobileView>
    </>
  );
};

export default PaymentSummary;
