import _ from "lodash";
import { formatCurrency, formatDate } from "../../config/global";
import Header from "../_common/headerComponent";
import CustomTooltip from "../_common/tooltip";

const InvoiceSuccessPaymentMobile = (props: any) => {
  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <Header
          navbarText="Pembayaran Berhasil"
        ></Header>
        <div className="flex flex-col justify-center gap-5 mt-6 ">
          <div>
            <div className="px-6 overflow-hidden bg-white shadow-sm shadow-gray-600 pb-3 min-h-[300px] my-4 ">
              <div className=" flex flex-col">
                <div className="flex text-accentGreen text-xl font-medium border-b-[1px] border-solid border-[#9a999922] py-3">
                  Pembayaran Berhasil
                </div>
                <div className="flex flex-col gap-2">
                  <div className="mt-3 space-between">
                    <h5 className="text-black font-semibold mb-0 text-[13px]">
                      Nama Invoice
                    </h5>
                    <CustomTooltip
                      className={"w-fit max-w-[calc(100%-7rem)] "}
                      content={
                        <div
                          className="w-96 whitespace-pre-line"
                          key={props.data?.title}
                        >
                          {props.data?.title}
                        </div>
                      }
                      direction={props.data?.title.length > 50 ? "bottom" : "left"}
                      // direction="bottom"
                      children={
                        <h6 className="text-thirdy font-light mb-0 text-[13px]">
                          {props.data?.title}
                        </h6>
                      }
                    ></CustomTooltip>
                  </div>
                  <div className="mt-3 space-between">
                    <h5 className="text-black font-semibold mb-0 text-[13px]">
                      Nama Member
                    </h5>
                    <h6 className="text-thirdy font-light mb-0 text-[13px]">
                      {props.data?.accountReceiverName}
                    </h6>
                  </div>
                  <div className="mt-3 space-between">
                    <h5 className="text-black font-semibold mb-0 text-[13px]">
                      Tanggal Invoice
                    </h5>
                    <h6 className="text-thirdy font-light mb-0 text-[13px]">
                      {props.data?.invoiceDate
                        ? formatDate(props.data?.invoiceDate, "DD/MM/YYYY")
                        : "-"}
                    </h6>
                  </div>
                  <div className="mt-3 space-between">
                    <h5 className="text-black font-semibold mb-0 text-[13px]">
                      Tanggal Jatuh Tempo
                    </h5>
                    <h6 className="text-thirdy font-light mb-0 text-[13px]">
                      {props.data?.expiredDate
                        ? formatDate(props.data?.expiredDate, "DD/MM/YYYY")
                        : "-"}
                    </h6>
                  </div>
                  <div className="mt-3 space-between">
                    <h5 className="text-black font-semibold mb-0 text-[13px]">
                      Total Tagihan
                    </h5>
                    <h6 className="text-thirdy font-light mb-0 text-[13px]">
                      {formatCurrency(props.data?.amount)}
                    </h6>
                  </div>
                  <div className="mt-3">
                    <h5 className="text-black font-semibold mb-0 text-[13px]">
                      Detail Tagihan
                    </h5>
                    <div className="max-h-96 overflow-auto">
                      {props.data &&
                        _.map(props.data?.item_details, (item, index: any) => (
                          <div
                            key={`detail-item-${index}`}
                            className={`flex flex-col gap-1 bg-[#F4F6F8] p-3 text-xs font-semibold border-[#CACACA] border-b-[1px] rounded-t-md`}
                          >
                            <span>{item.name}</span>
                            <span className="font-normal">
                              {formatCurrency(item.amount)}
                            </span>
                            <div className="flex justify-between">
                              <span className="font-normal">
                                Diskon {formatCurrency(item.discount)}
                              </span>
                              <span>{formatCurrency(item.total)}</span>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="bg-[#4163D0] text-white p-3 text-xs font-semibold rounded-md">
                      <div className="flex justify-between">
                        <span>Tagihan Belum Terbayar</span>
                        <span>{formatCurrency(props.data?.final)}</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 space-between">
                    {props.isPayAll ? null : (
                      <h6 className="text-thirdy font-light mb-0 text-[13px]">
                        {`Note : Anda Masih Memilliki Kekurangan bayar ${formatCurrency(
                          props.data?.final - props.amountPayment
                        )}`}
                      </h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute w-full bottom-4 flex flex-row gap-2 mb-4 px-6">
            <button
              onClick={() => props.handleSuccess()}
              type="button"
              className={`bg-themePrimary text-white w-full h-10 rounded-lg`}
            >
              Selesai
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvoiceSuccessPaymentMobile;
