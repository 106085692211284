import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import AttendanceMobile from "./attendanceMobile";
import AttendanceWeb from "./attendanceWeb";

const Attendance = () => {
  return (
    <>
      <BrowserView>
        <AttendanceWeb></AttendanceWeb>
      </BrowserView>
      <MobileView>
        <AttendanceMobile></AttendanceMobile>
      </MobileView>
    </>
  );
};

export default Attendance;
