import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPrabayarSummaryWeb from "./ppobPrabayarSummaryWeb";
import PPOBPrabayarSummaryMobile from "./ppobPrabayarSummaryMobile";

const PPOBPrabayarSummary = () => {
  return (
    <>
      <BrowserView>
        <PPOBPrabayarSummaryWeb></PPOBPrabayarSummaryWeb>
      </BrowserView>
      <MobileView>
        <PPOBPrabayarSummaryMobile></PPOBPrabayarSummaryMobile>
      </MobileView>
    </>
  );
};

export default PPOBPrabayarSummary;
