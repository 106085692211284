import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import success from "../../assets/images/icon_features/success.svg";
// import bg from "../../assets/images/icon_features/bg_detail.svg";
import arrow from "../../assets/images/arrow-left-ic.svg";
// import Company from "../../core/models/company";
import "moment/locale/id";
import { ConfigColor } from "../../config/global";
// import Failed from "../../assets/images/security-fail.png";
import { formatCurrency } from "../../config/global";

const TripAndTourBarcodeMobile = (props: any) => {
  const color = ConfigColor();
  const navigate = useNavigate();
  const handleDownload = () => {
    const link: any = document.createElement("a");
    link.href = props.data.barcode;
    link.download = "barcode-tour" || "image"; // You can customize the default file name
    link.style = "width:2000px;hight:2000px";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className="w-full h-full block min-h-screen bg-mobile"
      style={{
        backgroundColor: `${color.color1}`,
      }}
    >
      <div
        id="header-app"
        className=" w-full bg-fixed p-4 flex"
        style={{
          backgroundColor: `${color.color1}`,
        }}
      >
        <div
          onClick={() => navigate(-1)}
          className="flex bg-white rounded-2xl border-1 border-solid border-gray-400  w-12 h-12 justify-center items-center p-1.5 active:bg-gray-300"
        >
          <img
            src={arrow}
            style={{ width: 6, height: 12 }}
            className="m-auto"
            alt=""
          />
        </div>
        <h1 className="font-montserrat font-bold text-base m-auto relative text-center -left-6 text-white">
          Berhasil
        </h1>
      </div>
      <div className="w-full font-montserrat">
        <div
          className="grow w-full h-full mx-auto px-4 pb-4 md:pt-10"
          style={{
            backgroundColor: `${color.color1}`,
          }}
        >
          <div className="bg-white md:w-3/4 w-full mx-auto rounded-[14px] p-8 mb-4 mt-10 ">
            <div className="flex flex-col justify-center items-center">
              <span className=" md:text-base text-sm text-[#263339] text-center">
                Tunjukan kepada petugas
                <br /> untuk di scan sebagai bukti pembelian
              </span>
              <img src={props?.data?.barcode} alt="" className="w-full" />
            </div>

            <div className="flex justify-between items-center border-b-[1px] border-b-[#E5E5E5] border-dotted mb-2 pb-2 relative mt-2">
              <div className="absolute flex justify-between mt-2.5 w-full">
                <div
                  className="w-7 h-7 rounded-full -ml-12"
                  style={{
                    backgroundColor: `${color.color1}`,
                  }}
                ></div>
                <div
                  className="w-7 h-7  rounded-full -mr-12"
                  style={{
                    backgroundColor: `${color.color1}`,
                  }}
                ></div>
              </div>
            </div>
            <div className="mt-4">
              <h6 className="md:text-lg text-base font-semibold m-0 mb-2 text-[#26A69A]">
                Informasi
              </h6>
              <div className="flex justify-between items-center gap-2">
                <div className="text-start">
                  <h6 className="text-newColor md:text-sm text-xs m-0">
                    Subtotal Pesanan
                  </h6>
                  <span className="font-[500] md:text-sm text-xs">
                    {props?.data?.data?.quantity} x{" "}
                    {formatCurrency(props?.data?.data?.price)}
                  </span>
                </div>
                <div className="text-right">
                  <h6 className="text-newColor md:text-sm text-xs m-0">
                    Total Pesanan
                  </h6>
                  <span className="font-[700] md:text-sm text-xs">
                    {formatCurrency(props?.data?.data?.amountTotal)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 shadow fixed-bottom bg-white">
            <button
              className="text-base text-white text-center bg-[#26A69A] w-full p-2 font-[700] rounded-xl"
              type="button"
              onClick={() => handleDownload()}
            >
              Download Barcode
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripAndTourBarcodeMobile;
