import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPascabayarWeb from "./ppobPascabayarWeb";
import PPOBPascabayarMobile from "./ppobPascabayarMobile";

const PPOBPascabayar = () => {
  return (
    <>
      <BrowserView>
        <PPOBPascabayarWeb></PPOBPascabayarWeb>
      </BrowserView>
      <MobileView>
        <PPOBPascabayarMobile></PPOBPascabayarMobile>
      </MobileView>
    </>
  );
};

export default PPOBPascabayar;
