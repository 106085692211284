/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RedirectPage = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  React.useEffect(() => {
    if(location.state === undefined){
      navigate(-1);
    } else {
      document.getElementById("btn")?.click();
    }
  }, [])

  return (
    <section className="h-full min-h-screen font-poppins bg-mobile">
      <div className="container">
        <div className="md:w-1/2 w-full mx-auto">
          <form
            className="text-center"
            method="POST"
            id="form_data"
            action={location.state.url}
          >
            <input
              hidden
              name="Message"
              placeholder=""
              value={location.state.token}
              readOnly
            />
            <button
              hidden
              type="submit"
              id="btn"
              className="bg-gradient-to-b from-[#16C894] to-[#04A47C] text-white font-bold rounded-xl w-full md:p-[1rem] p-[12px]"
            >
              Redirect
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default RedirectPage;