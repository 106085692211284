/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import * as API_BALANCE from "../../core/service/api_balance";
import * as API_AUTH from "../../core/service/api_auth";
import Header from "../_common/headerComponent";
import Html5QrcodePlugin from "../_common/Html5QRCodePlugin";
import "./index.scss";
import Select from "react-select";
import { toast } from "react-hot-toast";
import Company from "../../core/models/company";
import QRIcon from "../../assets/images/icon_qr.svg";
import CurrencyInput from "react-currency-input-field";
import { onlyNumber } from "../../config/global";
// import OtpInput from "react-otp-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { StatefulPinInput } from "react-input-pin-code";
import { useTranslation } from "react-i18next";

const BarcodeMobile = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const pin: number = useAppSelector((state) => state.pin.pin);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openTab, setOpenTab] = React.useState(1);
  const [barcode, setBarcode] = React.useState("");
  const [nominal, setNominal] = React.useState("");
  const [errorNominal, setErrorNominal] = React.useState("");
  const [showSecurityCode, setShowSecurityCode] = React.useState(false);
  const [NewValues, setNewValues] = React.useState(["", "", "", "", "", ""]);
  const [balanceList, setBalanceList] = React.useState<any[]>([]);
  const [selectedBalance, setSelectedBalance] = React.useState<any>({});
  const [max, setMax] = React.useState<any>();

  const getBarcodeType = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getMerchantProfileAndBarcodeType(barcode)
      .then((res) => {
        dispatch(setLoading(false));
        if (res.data.data.qrCodeType === "STATIC") {
          navigate(`input-nominal`, {
            state: {
              data: res.data.data,
              barcode,
              balanceCode: selectedBalance.value,
            },
          });
        } else {
          navigate(`summary`, {
            state: {
              barcode,
              type: "dynamic",
              balanceCode: selectedBalance.value,
            },
          });
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getBalanceList = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getMainBalanceMember()
      .then(async (res) => {
        dispatch(setLoading(false));
        let balanceCode = res.data.data.balanceCode;
        await API_BALANCE.getComboAllowedBalanceCodes()
          .then((res) => {
            dispatch(setLoading(false));
            let tempBalanceList: any = [];
            if (res.data.length > 0) {
              res.data.map((el: any) =>
                tempBalanceList.push({
                  value: el.balanceCode,
                  label: el.balanceName,
                })
              );
              setBalanceList(tempBalanceList);
            }
            let mainBalance = tempBalanceList.find(
              (el: any) => el.value === balanceCode
            );
            if (mainBalance !== undefined) setSelectedBalance(mainBalance);
          })
          .catch((err) => {
            dispatch(setLoading(false));
          });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const onNewScanResult = (decodedText: any) => {
    setBarcode(decodedText);
  };

  // const handleChange = (NewValues: any) => setNewValues(NewValues);

  const handleOnClick = async () => {
    setNewValues(["", "", "", "", "", ""]);
    if (nominal === "") {
      toast.error("Nominal Harus Di isi");
    } else if (selectedBalance.value === undefined) {
      toast.error("Pilih saldo yang akan digunakan");
    } else {
      if (showSecurityCode) {
        dispatch(setLoading(true));
        let data = {
          securityCode: NewValues.join(""),
          transactionType: "QR_CODE",
        };
        await API_AUTH.reauthenticate(data)
          .then((res) => {
            setShowSecurityCode(false);
            setNewValues(["", "", "", "", "", ""]);
            setMax("");
            let newData = {
              amount: nominal,
              balanceCode: selectedBalance.value,
            };
            navigate("create", {
              state: {
                data: newData,
                token: res.data.data.securityCodeToken,
              },
            });
          })
          .catch((err) => {
            setNewValues(["", "", "", "", "", ""]);
            setMax("");
            dispatch(setLoading(false));
          });
      } else {
        if (pin === 1) {
          setShowSecurityCode(true);
        } else {
          navigate(`/${company?.initial}/create-security-code`, {
            state: {
              url: "payment-method/create-barcode",
              type: "transfer",
            },
          });
        }
      }
    }
  };

  // const ScanBarcode = () => {
  //   return (
  //     <React.Fragment>
  //       <div className="md:w-2/3 w-full mx-auto custom-scan-barcode">
  //         <Html5QrcodePlugin
  //           fps={30}
  //           qrbox={250}
  //           disableFlip={false}
  //           qrCodeSuccessCallback={onNewScanResult}
  //         />
  //       </div>
  //       <p className="w-3/4 mx-auto md:text-base text-sm font-medium my-4 font-montserrat">
  //         Pindai barcode dari merchant. Arahkan kamera hingga memuat seluruh
  //         barcode
  //       </p>
  //     </React.Fragment>
  //   );
  // };

  const CreateBarcode = () => {
    return (
      <React.Fragment>
        <div
          // style={{
          //   backgroundColor: `${
          //     company?.color?.color1 !== undefined
          //       ? company?.color?.color1
          //       : "#26A69A"
          //   }`,
          // }}
          className="lg:w-1/2 md:w-2/3 w-full mx-auto py-10 px-4"
        >
          <img
            src={QRIcon}
            alt=""
            width={307}
            height={307}
            className="mx-auto"
          />
        </div>
      </React.Fragment>
    );
  };

  React.useEffect(() => {
    if (barcode !== "") {
      if (selectedBalance.value === undefined) {
        toast.error("Pilih saldo yang akan digunakan");
      } else {
        getBarcodeType();
      }
    }
  }, [barcode]);

  React.useEffect(() => {
    getBalanceList();
  }, []);

  // batas

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (
    { target }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const { value } = target;
    const newOtp: string[] = [...NewValues];
    newOtp[index] = value.substring(value.length - 1);
    setNewValues(newOtp);
    if (!value) setActiveIndex(index - 1);
    else setActiveIndex(index + 1);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeIndex]);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile flex flex-col">
      <Header navbarText="MPM / CPM" />
      <div className="container grow flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0">
        <div className="flex grow md:w-3/5 w-full mx-auto">
          <div className="flex flex-col w-full text-center">
            <div className="md:w-3/4 w-full mx-auto mb-4 select-balance-cpm px-4">
              <label className="md:text-lg text-base text-customBlack font-medium mb-2 w-full text-start">
                {t("barcodeWeb.payWith")}{" "}
                <span className="md:text-xs text-[10px] font-light italic">
                  ({t("barcodeWeb.selectBalancePrompt")})
                </span>
              </label>
              <Select
                options={balanceList}
                value={selectedBalance}
                onChange={(e) => {
                  if (e !== null) setSelectedBalance(e);
                }}
                className="z-10"
              />
            </div>

            <div className="grow flex flex-col items-center">
              <div className="grow md:mt-6 mt-[0px] w-full border-b-[1px] border-[#CCCCCC]">
                <div className={openTab === 1 ? "block" : "hidden"}>
                  {/* <ScanBarcode /> */}
                  <div className="md:w-2/3 w-full mx-auto custom-scan-barcode">
                    <Html5QrcodePlugin
                      fps={30}
                      qrbox={250}
                      disableFlip={false}
                      qrCodeSuccessCallback={onNewScanResult}
                      rememberLastUsedCamera={true}
                    />
                  </div>
                  <p className="w-3/4 mx-auto md:text-base text-sm font-medium my-4 font-montserrat">
                    {t("barcodeWeb.scanPrompt")}
                  </p>
                </div>

                {openTab === 2 && (
                  <div
                    className={
                      openTab === 2
                        ? "flex flex-col items-center gap-5"
                        : "hidden"
                    }
                  >
                    <CreateBarcode />
                    <div className="p-4 w-full">
                      <div className="md:w-3/4 w-full mx-auto mb-3">
                        <p className="md:w-3/5 w-full mx-auto text-center md:text-base text-sm text-customBlack font-normal">
                          {t("barcodeWeb.enterAmount")}
                        </p>
                        <label className="md:text-lg text-base text-customBlack font-semibold mb-2  font-montserrat">
                          Nominal
                        </label>
                        <CurrencyInput
                          className="w-full bg-white border-[#9A9999] rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-[#9A9999]"
                          defaultValue={1000}
                          decimalsLimit={2}
                          value={nominal}
                          prefix={"Rp"}
                          onValueChange={(value, name) => {
                            setNominal(value || "");
                            setErrorNominal("");
                          }}
                          placeholder={t("barcodeWeb.inputAmount")}
                          onKeyPress={onlyNumber}
                        />
                        <p className="md:text-base text-sm text-red pl-1 mb-0  font-montserrat">
                          {errorNominal}
                        </p>
                      </div>
                      <button
                        type="button"
                        onClick={handleOnClick}
                        style={{
                          borderColor: `${company?.color?.color1 !== undefined
                            ? company?.color?.color1
                            : "#26A69A"
                            }`,
                          color: `${company?.color?.color1 !== undefined
                            ? company?.color?.color1
                            : "#26A69A"
                            }`,
                        }}
                        className="bg-white border-[1px] md:text-base text-sm font-semibold rounded-[10px] md:w-3/4 w-full px-3 py-[12px]"
                      >
                        {t("barcodeWeb.continue")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <ul
                style={{
                  backgroundColor:
                    company?.color?.color1 !== undefined
                      ? company?.color?.color1
                      : "#26A69A",
                }}
                className="md:w-1/2 w-4/5 flex gap-3 p-1 my-4 rounded-lg"
              >
                <li className="flex w-full">
                  <button
                    onClick={() => setOpenTab(1)}
                    style={{
                      color:
                        openTab !== 1
                          ? "#FFFFFF"
                          : company?.color?.color1 !== undefined
                            ? company?.color?.color1
                            : "#26A69A",
                      backgroundColor:
                        openTab !== 1 ? "transparent" : "#FFFFFF",
                    }}
                    className="inline-block px-2 py-2 rounded-lg w-full md:text-base text-sm"
                  >
                    {t("barcodeWeb.scanQR")}
                  </button>
                </li>
                <li className="flex w-full">
                  <button
                    onClick={() => setOpenTab(2)}
                    style={{
                      color:
                        openTab !== 2
                          ? "#FFFFFF"
                          : company?.color?.color1 !== undefined
                            ? company?.color?.color1
                            : "#26A69A",
                      backgroundColor:
                        openTab !== 2 ? "transparent" : "#FFFFFF",
                    }}
                    className="inline-block px-2 py-2 rounded-lg w-full md:text-base text-sm"
                  >
                    {t("barcodeWeb.createQr")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${showSecurityCode ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]">
              <div className="flex flex-row justify-end w-100 mb-3 md:px-[0px] px-[0.5rem]">
                <button
                  type="button"
                  onClick={() => {
                    setShowSecurityCode(false);
                    setNewValues(["", "", "", "", "", ""]);
                  }}
                  className="fa-xl text-gray-400"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p className="md:text-2xl text-sm text-black md:mb-4">
                {t("barcodeWeb.enterPin")}
              </p>
              <Link
                to={`/${company?.initial}/profile/security-code/forget`}
                className="mb-3 no-underline text-start md:text-base text-sm"
              >
                {t("barcodeWeb.forgetPin")}
              </Link>
              {/* <OtpInput
                value={NewValues}
                onChange={handleChange}
                numInputs={6}
                separator={<span style={{ width: "3px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border:
                    NewValues.length < 6
                      ? "1px solid #9A9999"
                      : "1px solid #26A69A",
                  borderRadius: "8px",
                  width: "47px",
                  height: "47px",
                  fontSize: "30px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                isInputSecure={true}
              /> */}
              {/* <StatefulPinInput
                key={first ? "kosong" : "isi"}
                length={6}
                placeholder=""
                initialValue={NewValues}
                onChange={(value, index, values) => {
                  setNewValues(values);
                  // data["otp"] = values.join("");
                }}
                type="number"
                mask={true}
                focusBorderColor="#26A69A"
                validBorderColor="#26A69A"
              /> */}
              <div className="flex flex-row gap-3 relative z-10">
                {NewValues.map((res, i) => {
                  return (
                    <div
                      className={
                        NewValues[i] === "undefined" || NewValues[i] === ""
                          ? "border outline-none text-center rounded-full w-8 h-8"
                          : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-full w-8 h-8"
                      }
                      key={i}
                    ></div>
                  );
                })}
              </div>
              <div className="w-full px-4 -mt-9 relative z-20">
                <input
                  className="w-full bg-transparent border-none indent-[-999em] caret-transparent text-transparent focus:outline-none py-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  value={max}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      setMax(max);
                    } else {
                      const value: any = e.target.value.split("");
                      const newValue: any = NewValues;
                      const x: any = [
                        `${(newValue[0] = value[0])}`,
                        `${(newValue[1] = value[1])}`,
                        `${(newValue[2] = value[2])}`,
                        `${(newValue[3] = value[3])}`,
                        `${(newValue[4] = value[4])}`,
                        `${(newValue[5] = value[5])}`,
                      ];
                      setNewValues(x);
                      setMax(e.target.value);
                    }
                  }}
                ></input>
              </div>
              <button
                disabled={NewValues.join("").length < 6}
                onClick={() => handleOnClick()}
                style={{
                  backgroundColor:
                    NewValues.length < 6
                      ? "#9A9999"
                      : company?.color?.color1 !== undefined
                        ? company?.color?.color1
                        : "#26A69A",
                }}
                className="w-full md:py-[16px] p-[10px] rounded-full text-white font-semibold text-base disabled:bg-slate-50 mt-4"
              >
                {t("barcodeWeb.confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BarcodeMobile;
