import React from "react";
import Header from "../../_common/headerComponent";
import DataNotFound from "../../_common/dataNotFound";
import NoImage from "../../../assets/images/empty.jpg";
import { formatCurrency, onlyNumber } from "../../../config/global";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Company from "../../../core/models/company";
import NoImage2 from "../../../assets/images/no_image.png";
import DeleteIcon from "../../../assets/images/Delete.png";
import { useAppSelector } from "../../../core/feature/hooks";
import { useTranslation } from "react-i18next";

const WishlistWeb = (props: any) => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const [selectedProduct, setSelectedProduct] = React.useState<any>({});
  const [selectedVariant, setSelectedVariant] = React.useState<any>({});

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />

      <div
        className={`${props.open ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 p-3">
              <button
                type="button"
                onClick={() => props.setOpen(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="p-3">
                <div className="mt-1 mb-3 w-full">
                  {selectedVariant?.id !== undefined && (
                    <div className="space-between gap-3">
                      {selectedVariant.imageUrl.length > 0 ? (
                        <img
                          src={selectedVariant.imageUrl[0]}
                          alt=""
                          className="object-contain w-20 h-20 rounded-[4px]"
                        />
                      ) : (
                        <div className="border-[0.5px] border-[#9A9999] rounded-[4px] p-7">
                          <img
                            src={NoImage2}
                            alt=""
                            className="h-full object-cover w-5"
                          />
                        </div>
                      )}
                      <div className="w-full flex flex-col items-start">
                        <h6 className="md:text-base text-sm font-medium text-customBlack mb-0 text-start">
                          {selectedVariant.variantKey} :{" "}
                          {selectedVariant.variantValue}
                        </h6>
                        <h6 className="md:text-base text-sm font-medium text-customBlack mb-0 text-start">
                          {formatCurrency(selectedVariant.marketplacePrice)}
                        </h6>
                        {selectedVariant?.type === "BARANG" && (
                          <p className="md:text-base text-sm font-light text-thirdy mb-0">
                            {t('whishlist.stock')} : {selectedVariant?.stock}
                          </p>
                        )}
                        <p className="md:text-base text-sm font-light text-thirdy mb-0">
                          {t('whishlist.minOrder')} : {selectedVariant.minOrder}
                        </p>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div>
                    <h6 className="md:text-base text-sm text-start font-medium mb-1">
                      {t('whishlist.variant')}
                    </h6>
                    <div className="grid md:grid-cols-4 grid-cols-3 items-center gap-3">
                      {selectedProduct?.id !== undefined &&
                        selectedProduct?.items?.map((item: any) =>
                          item?.stock !== 0 ? (
                            <button
                              type="button"
                              onClick={() => {
                                // setIsSelectedVariant(item?.id);
                                setSelectedVariant(item);
                              }}
                              style={{
                                backgroundColor: selectedVariant?.id !== item?.id ? "#FFFFFF" : `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}`,
                                borderColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}`,
                                color: selectedVariant?.id === item?.id ? "#FFFFFF" : `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}`
                              }}
                              className={`${selectedVariant?.id === item?.id
                                ? "bg-green text-white"
                                : "bg-white border-[0.5px] border-green text-green"
                                } text-xs font-normal rounded-md p-[8px] h-full`}
                              key={item.id}
                            >
                              {item.variantKey} : {item.variantValue}
                            </button>
                          ) : (
                            <button
                              disabled
                              type="button"
                              className="bg-[#9A999980] text-customGrey text-xs font-normal rounded-md p-[8px] h-full"
                              key={item.id}
                            >
                              {item.variantKey} : {item.variantValue}
                            </button>
                          )
                        )}
                    </div>
                  </div>
                  <div className="mt-3">
                    <h6 className="md:text-base text-sm text-start font-medium mb-1">
                      {t('whishlist.quantity')}
                    </h6>
                    <div className="flex w-full">
                      <button
                        type="button"
                        disabled={props.quantity === 1}
                        onClick={() => props.setQuantity(props.quantity - 1)}
                        className="border-[0.5px] border-[#C4C4C4] px-[1rem] py-2 rounded-l-lg"
                      >
                        -
                      </button>
                      <input
                        type="text"
                        onKeyPress={onlyNumber}
                        value={props.quantity}
                        onChange={(e) => props.setQuantity(parseInt(e.target.value))}
                        name="quantity"
                        id="quantity"
                        className="border-[#C4C4C4] border-t-[0.5px] border-b-[0.5px] text-center px-[1rem] py-2 md:w-[10%] w-1/4 focus:outline outline-[2px] outline-[#C4C4C4]"
                      />
                      <button
                        type="button"
                        disabled={props.quantity === selectedVariant?.stock}
                        onClick={() => props.setQuantity(props.quantity + 1)}
                        className="border-[0.5px] border-[#C4C4C4] px-[1rem] py-2 rounded-r-lg"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                {selectedVariant?.type === "JASA" ? (
                  <button
                    type="button"
                    onClick={() => {
                      if (props.quantity < selectedVariant.minOrder) {
                        toast.error(
                          `${t('whishlist.minPurchase')} ${selectedVariant.minOrder}`
                        );
                      } else {
                        props.addToCart(selectedVariant);
                      }
                    }}
                    className="bg-green text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full"
                  >
                    {t('whishlist.addToCart')}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      if (props.quantity < selectedVariant.minOrder) {
                        toast.error(
                          `${t('whishlist.minPurchase')} ${selectedVariant.minOrder}`
                        );
                      } else {
                        props.addToCart(selectedVariant);
                      }
                    }}
                    disabled={selectedVariant?.stock === 0}
                    style={{
                      backgroundColor: `${selectedVariant?.stock === 0 ? "#9A9999" : company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}`,
                    }}
                    className={`${selectedVariant?.stock === 0
                      ? "bg-[#9A9999]"
                      : "bg-green"
                      } text-white md:text-base text-sm font-semibold rounded-[10px] px-3 py-2 w-full`}
                  >
                    {t('whishlist.addToCart')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container flex flex-col items-center md:mt-[64px] mt-[0px] px-0">
        <div className="md:w-4/5 w-full mx-auto mb-5">
          {props.data.length > 0 ? (
            <div className="w-full">
              {props.data.map((item: any) => (
                <div
                  className="w-full px-3 bg-white flex items-center gap-2 mb-[12px]"
                  key={item.id}
                >
                  <Link
                    to={`/${company?.initial}/commerce/products/detail/${item.id}`}
                    className="no-underline"
                  >
                    <img
                      src={
                        item?.imagePath?.length > 0
                          ? item?.imagePath[0]
                          : NoImage
                      }
                      alt=""
                      className="w-[110px] h-[110px] object-contain"
                    />
                  </Link>
                  <div className="py-3 md:pl-3 pl-2 w-full">
                    <Link
                      to={`/${company?.initial}/commerce/products/detail/${item.id}`}
                      className="no-underline md:text-base text-sm text-customBlack font-medium"
                    >
                      {item?.name}
                    </Link>
                    {item?.items?.length > 0 && (
                      <p className="text-customBlack font-semibold md:text-base text-sm">
                        {formatCurrency(item?.items[0]?.marketplacePrice)}
                      </p>
                    )}
                    <div className="flex items-center justify-end gap-3 w-full">
                      <button
                        type="button"
                        onClick={() => props.deleteFromWishlist(item.id)}
                      >
                        <img src={DeleteIcon} alt="" />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedProduct(item);
                          let findItem = item.items.find(
                            (el: any) => el.stock !== 0
                          );
                          setSelectedVariant(findItem);
                          props.setOpen(true);
                        }}
                        disabled={
                          item.items.find((el: any) => el.stock !== 0) ===
                          undefined
                        }
                        style={{
                          backgroundColor: `${item.items.find((el: any) => el.stock !== 0) === undefined ? "#9A9999" : company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}`,
                        }}
                        className={`text-white md:text-sm text-xs font-semibold md:p-[10px] p-[8px] rounded`}
                      >
                        {t('whishlist.addToCart')}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <DataNotFound title={t('whishlist.dataNotFound')} />
          )}
        </div>
      </div>
    </section>
  );
};

export default WishlistWeb;
