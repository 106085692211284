import React, { useEffect, useState } from "react";
// import { useAppSelector } from "../../core/feature/hooks";
import Header from "../_common/headerComponent";
import { useLocation, useNavigate } from "react-router-dom";
// import Company from "../../core/models/company.js";
import moment from "moment";
// import _ from "lodash";
import "react-spring-bottom-sheet/dist/style.css";
import ReactMarkdown from "react-markdown";
import Viewer from "react-viewer";
import BottomLogo from "../_common/bottomLogo";
import { BrowserView, MobileView } from "react-device-detect";
import { StatsInformation } from "../_common/Stats";
import arrow from "../../assets/images/arrow-left-ic.svg";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const NewsInformationDetail = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  let location: any = useLocation();
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState("");
  const [data, setData] = useState<any>({});

  const navigate = useNavigate();
  let imageNotFound =
    "https://closepay-static.sgp1.digitaloceanspaces.com/image/no-image.png";

  useEffect(() => {
    setData(JSON.parse(location.state?.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <>
      <BrowserView>
        <section className="h-full min-h-screen  bg-mobile">
          <StatsInformation />
          <div className="container flex flex-col gap-5 justify-center -mt-20 z-50 relative pb-24">
            <div className="w-4/5 bg-white shadow mx-auto p-5 rounded-2xl mb-16">
              <div className="space-y-0 mb-3 flex ">
                <div
                  onClick={() => navigate(-1)}
                  className="hover:cursor-pointer mt-1 flex bg-white rounded-2xl border-1 border-solid border-gray-400  w-10 h-10 justify-center items-center p-1.5 active:bg-gray-300"
                >
                  <img
                    src={arrow}
                    style={{ width: 6, height: 12 }}
                    className="m-auto"
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <h6 className="md:text-xl text-sm text-[#9A9999] font-semibold m-0 font-montserrat">
                    Hot News
                  </h6>
                  <span className="text-[10px] font-light text-[#9A9999] font-montserrat">
                    {moment(data.createdTime).format("LL")}
                  </span>
                </div>
              </div>
              <h6 className="md:text-xl text-xl text-[#252525] font-semibold news-desc mt-3">
                {data.title}
              </h6>
              <div>
                <Viewer
                  visible={visible}
                  onClose={() => {
                    setVisible(false);
                  }}
                  noImgDetails={true}
                  images={[{ src: image }]}
                />
                {data?.imageUrl?.length > 0 ? (
                  <Carousel
                    swipeable
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                  >
                    {data?.imageUrl?.map((item: any, index: number) => (
                      <img
                        key={index}
                        onClick={() => {
                          setImage(item);
                          setVisible(true);
                        }}
                        src={item}
                        alt=""
                        className="w-full object-cover h-[30rem]"
                      />
                    ))}
                  </Carousel>
                ) : (
                  <img src={imageNotFound} alt="" />
                )}

                <p className="my-3 font-normal md:text-lg text-sm text-[#252525] dark:text-gray-400 text-justify">
                  <ReactMarkdown children={data.description} />
                </p>
                {data.tags?.length > 0 && (
                  <div className=" mb-3 space-y-2 pb-12">
                    <span className="font-semibold">Tag :</span>
                    <div className="text-[#728F9E] flex flex-wrap max-w-[100%] ">
                      {data.tags?.map((item: any, index: any) => (
                        <div
                          key={index}
                          className="py-1 px-3 bg-[#F5F5F5] rounded-md text-center left-0 mr-2 mb-2"
                        >
                          <span className="text-xs">
                            {" "}
                            {item.length < 80
                              ? item
                              : item.toString().substring(0, 80) + "..."}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {company?.showFtpLogo && <BottomLogo />}
          </div>
        </section>
      </BrowserView>
      <MobileView>
        <section className="h-full min-h-screen  bg-mobile">
          <div className="hidden md:block">
            <Header></Header>
          </div>
          <div className="md:hidden">
            <Header navbarText={t("news_information.header")}></Header>
          </div>
          <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 pb-[64px]">
            <div className="md:w-[60%] w-full mx-auto mb-4">
              <div className="space-y-0 mb-3">
                <h6 className="md:text-xl text-sm text-[#9A9999] font-semibold m-0 font-montserrat">
                  Hot News
                </h6>
                <span className="text-[10px] font-light text-[#9A9999] font-montserrat">
                  {moment(data.cr).format("LL")}
                </span>
              </div>
              <h6 className="md:text-xl text-xl text-[#252525] font-semibold mb-3 news-desc mt-3">
                {data.title}
              </h6>
              <div>
                <Viewer
                  visible={visible}
                  onClose={() => {
                    setVisible(false);
                  }}
                  noImgDetails={true}
                  images={[{ src: image }]}
                />
                {data?.imageUrl?.length > 0 ? (
                  <Carousel
                    swipeable
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                  >
                    {data?.imageUrl?.map((item: any, index: number) => (
                      <img
                        src={item}
                        onClick={() => {
                          setImage(item);
                          setVisible(true);
                        }}
                        alt=""
                        key={index}
                        className="w-full object-cover h-[12.5rem]"
                      />
                    ))}
                  </Carousel>
                ) : (
                  <img src={imageNotFound} alt="" />
                )}

                <p className="my-3 font-normal md:text-lg text-sm text-[#252525] dark:text-gray-400 text-justify">
                  <ReactMarkdown children={data.description} />
                </p>
              </div>
              {data.tags?.length > 0 && (
                <div className=" mb-3 space-y-2 pb-12">
                  <span className="font-semibold">Tag :</span>
                  <div className="text-[#728F9E] flex flex-wrap max-w-[100%] ">
                    {data.tags?.map((item: any, index: any) => (
                      <div
                        key={index}
                        className="py-1 px-3 bg-[#F5F5F5] rounded-md text-center left-0 mr-2 mb-2"
                      >
                        <span className="text-[10px]">
                          {" "}
                          {item.length < 50
                            ? item
                            : item.toString().substring(0, 50) + "..."}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {company?.showFtpLogo && <BottomLogo />}
          </div>
        </section>
      </MobileView>
    </>
  );
};

export default NewsInformationDetail;
