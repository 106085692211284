import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import MarketplaceMobile from "../landingpageMarketplace/landingPageMarketplaceMobile";
import MarketplaceWeb from "../landingpageMarketplace/landingPageMarketplaceWeb";
import CardMemberWeb from "../homeCardComponent/homeCardWeb";
import CardMemberMobile from "../homeCardComponent/homeCardMobile";

import HomeMobile from "./homeMobile";
import HomeWeb from "./homeWeb";
import { useLocation, useNavigate } from "react-router-dom";
import {
  // removeUserLogin,
  setUserLogin,
} from "../../core/feature/userLogin/userLoginSlice";
import Token from "../../core/models/token";
import * as API_USER from "../../core/service/api_user";
import { setLoading } from "../../core/feature/config/configSlice";
import {
  // removeCompany,
  setCompany,
} from "../../core/feature/company/companySlice";
import { removeUser, setUser } from "../../core/feature/user/userSlice";
import * as API from "../../core/service/api";
import { toast } from "react-hot-toast";
import _ from "lodash";
// import UserLogin from "../../core/models/userLogin";
import User from "../../core/models/user";
import { setToken } from "../../core/feature/token/tokenSlice";
import { removeState } from "../../core/feature/browser-storage/browser-storage";
import HomePageLMSMobile from "../homePageLMSComponent/homePageLMSMobile";
import HomePageLMSWeb from "../homePageLMSComponent/homePageLMSWeb";
import HomeLinkedAccount from "../homeLinkedAccountComponent";

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [pathName] = useState(
    location.pathname.replaceAll("/", "").toUpperCase()
  );
  // const { cid } = useParams();
  const search = useLocation().search;
  const one_time_access_token = new URLSearchParams(search).get("token");
  // const [hasToken, setHasToken] = useState(false);
  // const userLogin: UserLogin[] = useAppSelector(
  //   (state) => state.userLogin.userLogin
  // );
  const user: User = useAppSelector((state) => state.user.user);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const company: Company = useAppSelector((state) => state.company.company);
  const [currentCompany, setCurrentCompany] = useState(company);
  const [currentUser, setCurrentUser] = useState(user);
  const [isLoading, setIsloading] = useState(false);
  const link = localStorage.getItem("co_link");
  const [isReady, setIsReady] = useState(false);

  const redirectCo = () => {
    if (link && token) {
      let dataToken: any = _.find(token, function (o) {
        return o.cid === company.initial;
      });
      window.location.href = `https://${link}?access_token=${dataToken?.token}&message=SUCCESS_LOGIN`;
      localStorage.removeItem("co_link");
    }
  };

  const autoLogin = (token: any, pathName: any) => {
    return new Promise((resolve, reject) => {
      if (token && pathName) {
        let checkToken = {
          cid: pathName,
          token: token,
        };
        dispatch(setToken([checkToken]));
        // setHasToken(true);
        API.cekTokenAccount(token).then((res) => {
          dispatch(setUser(res.data));
          setCurrentUser(res.data);
          setIsReady(true);
          resolve(checkToken);
          window.location.replace(`${window.location.origin}/${pathName}`);
        });
      } else {
        reject(false);
      }
    });
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let getRedirect = query.get("redirect");

  useEffect(() => {
    const sessionWebview = sessionStorage.getItem("isWebview");
    if (sessionWebview !== null) {
      console.log("webview_close", "(close webview android)");
    }
    if (getRedirect === "false") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }, [getRedirect]);

  useEffect(() => {
    sessionStorage.removeItem("erorMessage");
    const asyncFunct = async () => {
      setIsloading(true);
      // dispatch(setLoading(true));

      if (company) {
        if (pathName !== company.initial) {
          dispatch(setUserLogin([]));
          var checkToken = _.find(token, { cid: pathName });
          await autoLogin(one_time_access_token, pathName)
            .then((res) => {})
            .catch((err) => {});
          await API_USER.getCompany(pathName)
            .then((res) => {
              setIsloading(false);
              dispatch(setLoading(false));
              dispatch(setCompany(res.data.data));
              setTimeout(() => {
                setCurrentCompany(res.data.data);
              }, 500);
              dispatch(removeUser());
              setIsReady(true);
              if (!token) {
                navigate(`/${pathName}`);
              } else if (!checkToken && token) {
                navigate(`/${pathName}/login`);
              }
            })
            .catch((err) => {
              setIsReady(true);
              dispatch(setLoading(false));
            });
          if (checkToken) {
            await API.cekToken().then((res) => {
              dispatch(setUser(res.data));
              setTimeout(() => {
                setCurrentUser(res.data);
              }, 500);
              setIsReady(true);
            });
          }
        } else {
          await autoLogin(one_time_access_token, company.initial)
            .then((res) => {})
            .catch((err) => {});
          if (checkToken) {
            await API.cekToken().then((res) => {
              dispatch(setUser(res.data));
              setTimeout(() => {
                setCurrentUser(res.data);
              }, 500);
              setIsReady(true);
            });
          }
          await API_USER.getCompany(company.initial)
            .then((res) => {
              setIsloading(false);
              dispatch(setLoading(false));
              dispatch(setCompany(res.data.data));
              setIsReady(true);
              setTimeout(() => {
                setCurrentCompany(res.data.data);
              }, 500);
            })
            .catch((err) => {
              setIsReady(true);
              dispatch(setLoading(false));
            });
        }
      } else {
        await autoLogin(one_time_access_token, pathName)
          .then((res) => {})
          .catch((err) => {});
        await API_USER.getCompany(pathName)
          .then((res) => {
            setIsloading(false);
            dispatch(setLoading(false));
            dispatch(setCompany(res.data.data));
            setCurrentCompany(res.data.data);
            setIsReady(true);
          })
          .catch((err) => {
            setIsReady(true);
            dispatch(setLoading(false));
            toast.error("Instansi " + pathName + " Tidak Ditemukan");
            navigate("/companies");
          });
      }
    };
    asyncFunct();
    redirectCo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName, one_time_access_token]);
  return (
    <>
      {isReady && (
        <BrowserView>
          {currentCompany?.layout === "marketplace" && !isLoading && (
            <MarketplaceWeb
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></MarketplaceWeb>
          )}
          {currentCompany?.layout === "member_card" && !isLoading && (
            <CardMemberWeb
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></CardMemberWeb>
          )}
          {currentCompany?.layout === "general" && !isLoading && (
            <HomeWeb
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></HomeWeb>
          )}
          {currentCompany?.layout === "lms" && !isLoading && (
            <HomePageLMSWeb
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></HomePageLMSWeb>
          )}
          {currentCompany?.layout === "linked_account" && !isLoading && (
            <HomeLinkedAccount
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></HomeLinkedAccount>
          )}
        </BrowserView>
      )}
      {isReady && (
        <MobileView>
          {currentCompany?.layout === "marketplace" && !isLoading && (
            <MarketplaceMobile
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></MarketplaceMobile>
          )}
          {currentCompany?.layout === "member_card" && !isLoading && (
            <CardMemberMobile
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></CardMemberMobile>
          )}
          {currentCompany?.layout === "general" && !isLoading && (
            <HomeMobile
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></HomeMobile>
          )}
          {currentCompany?.layout === "lms" && !isLoading && (
            <HomePageLMSMobile
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></HomePageLMSMobile>
          )}
          {currentCompany?.layout === "linked_account" && !isLoading && (
            <HomeLinkedAccount
              key={`${currentCompany?.initial} - ${currentUser?.noId} `}
            ></HomeLinkedAccount>
          )}
        </MobileView>
      )}
    </>
  );
};

export default Home;
