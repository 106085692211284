import React, { useState } from "react";
import arrow_down from "../../assets/images/DropDown.svg";

const IconDetail = (data: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        data.open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

export default function BalanceSelect(props: any) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex justify-center">
      <button
        className="rounded-[20px] py-2 px-4 border border-[#CCCCCC] text-[#263339] flex gap-2 items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {props.detail.balanceName} <IconDetail open={isOpen} />
      </button>

      {isOpen && (
        <div
          className="absolute mt-4 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 top-[7rem]"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1" role="none">
            {props?.listSaldo?.map((item: any) => {
              return (
                <span
                  className="text-gray-700 block px-4 py-2 text-sm hover:text-black active:text-black cursor-pointer"
                  onClick={() => {
                    setIsOpen(!isOpen);
                    props.changeSaldo(item);
                  }}
                >
                  {item.balanceName}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
