import React, { Fragment, useEffect, useState } from "react";
import * as API_AUTH from "../../core/service/api_auth";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { setLoading } from "../../core/feature/config/configSlice";
import closepayLogo from "../../assets/images/solusinegerinew.svg";
import Company from "../../core/models/company";
import desktopBackground from "../../assets/images/desktopBgBlur.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";

const InputEmailWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const location: any = useLocation();
  const [data, setData] = useState<any>({});
  const [modalVerificationWa, setModalVerificationWa] = useState(false);
  const [state, setState] = useState("");
  const [tokenWa, setTokenWa] = useState("");
  const [selected, setSelected] = useState("");
  const [timer, setTimer] = useState(0);
  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);

  const submit = async () => {
    if (email === "") {
      setErrorEmail("Email harap diisi");
    } else {
      dispatch(setLoading(true));

      let data = {
        destinationOtp: email,
        companyId: company?.id,
        userType: "MEMBER",
        otpType: "RESET_PASSWORD",
      };

      await API_AUTH.requestOtp(data)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`/${company?.initial}/login/forget-password/change`, {
            state: {
              time: res.data.data.expiredMillisecond,
              email,
            },
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const requestOtpWa = async () => {
    setAlert(true);
    // dispatch(setLoading(true));
    // let newData = {
    //   phoneNumber: phone,
    //   companyId: company?.id,
    //   userType: "MEMBER",
    //   transactionType: "RESET_PASSWORD",
    //   deviceId: location?.state?.deviceId,
    // };
    // dispatch(setLoading(true));
    // await API_AUTH.requestTokenVerivicationWa(newData)
    //   .then((res) => {
    //     setModalVerificationWa(true);
    //     window.open(
    //       `https://wa.me/${
    //         res.data.data.destinationPhoneNumber
    //       }?text=${encodeURI(
    //         `Halo ini adalah kode verifikasi saya "${res.data.data.token}" tolong verifikasi ya.`
    //       )}`,
    //       `_blank`
    //     );
    //     setTokenWa(res.data.data.token);
    //     setState(res.data.data.state);
    //     setTimer(Date.now() + res.data.data.expiredMillisecond);
    //     dispatch(setLoading(false));
    //   })
    //   .catch((err) => {
    //     dispatch(setLoading(false));
    //   });
  };

  const checkTokenWa = async () => {
    dispatch(setLoading(true));
    let newData = {
      state: state,
      phoneNumber: phone,
      companyId: company?.id,
      transactionType: "RESET_PASSWORD",
      deviceId: location?.state?.deviceId,
      token: tokenWa,
    };
    dispatch(setLoading(true));
    await API_AUTH.checkTokenVerification(newData)
      .then((res) => {
        dispatch(setLoading(false));
        navigate(`/${company?.initial}/login/forget-password/change`, {
          state: {
            time: res.data.data.expiredMillisecond,
            otp: res.data.data.otp,
            phone: phone,
          },
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const renderer = (data: any) => {
    if (data.completed) {
      return (
        <button
          className="text-[#26A69A] my-3"
          type="button"
          onClick={() => requestOtpWa()}
        >
          Kirim Ulang
        </button>
      );
    } else {
      return (
        <span className="text-[14px] text-center my-4">
          <span>Kirim ulang OTP dalam</span>
          <span className="text-[#FF9723] font-semibold ml-2">
            {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
          </span>
        </span>
      );
    }
  };

  const cekEmailPhone = async () => {
    dispatch(setLoading(true));
    await API_AUTH.cekEmailPhone(company?.id, location?.state?.username)
      .then((res) => {
        setData(res.data.data);
        if (res.data.data.phone !== null && res.data.data.email !== null) {
          setModal(true);
          dispatch(setLoading(false));
        } else if (res.data.data.email !== null) {
          cekEmailNoMasking(company?.id, location?.state?.username, "EMAIL");
          dispatch(setLoading(false));
        } else if (res.data.data.phone !== null) {
          cekPhoneNoMasking(company?.id, location?.state?.username, "PHONE");
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const cekEmailNoMasking = (id: any, username: any, type: any) => {
    API_AUTH.getEmailPhoneNoMasking(id, username, type)
      .then((res) => {
        setEmail(res.data.data.value);
      })
      .catch((err) => {});
  };

  const cekPhoneNoMasking = (id: any, username: any, type: any) => {
    API_AUTH.getEmailPhoneNoMasking(id, username, type)
      .then((res) => {
        setPhone(res.data.data.value);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    cekEmailPhone();
    console.log(location?.state);
  }, []);

  return (
    <>
      <div
        className={`${modalVerificationWa ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl px-4 py-5">
              <span className="text-[20px] font-semibold mb-2">
                Verifikasi Aktivitas
              </span>
              <div className="text-left mt-4">
                <span>Verifikasi Melalui Nomor</span>
                <input
                  disabled
                  type="number"
                  value={phone}
                  className="text-sm disabled:text-gray-500 font-normal border-[#C4C4C4] border-[1px] focus:border-1 rounded-[10px] p-[12px] focus:outline outline-[3px] outline-teal-300/25 w-full"
                ></input>
                <p className="mt-5 text-center">
                  Sudah Verifikasi ke WhatsApp? Klik "Saya <br /> Sudah
                  Verifikasi" untuk melanjutkan login
                </p>
              </div>
              <Countdown key={timer} date={timer} renderer={renderer} />
              <button
                onClick={() => {
                  checkTokenWa();
                }}
                className="bg-green text-white py-2 mt-5 rounded-lg w-full"
              >
                Saya Sudah Verifikasi
              </button>
              {/* <span
                  className="mt-4 text-xs cursor-pointer"
                  onClick={() => {
                    setShowDataVerification(false);
                    navigate(`/${company.initial}`);
                  }}
                >
                  Lewati langkah ini
                </span> */}
            </div>
          </div>
        </div>
      </div>
      <section
        className="h-full min-h-screen flex items-center bg-second dark:bg-black bg-cover p-4 w-full"
        style={{ backgroundImage: `url(${desktopBackground})` }}
      >
        <div className="container font-montserrat">
          <div className="flex flex-col justify-center items-center">
            <div className="lg:w-1/2 sm:w-3/4 md:w-3/4 m-auto">
              <div>
                <img
                  src={company?.companyLogo || closepayLogo}
                  alt="icon"
                  className="w-36 mx-auto"
                />
              </div>
              <div className="mt-10 mb-6 w-100">
                <h4 className="text-left text-[16px] font-semibold md:mt-2 mt-1">
                  Lupa Password
                </h4>
                <p className="text-left text-[12px] text-thirdy font-normal">
                  Silakan mengisi terlebih dahulu data yang dibutuhkan untuk
                  mendapatkan password anda
                </p>
              </div>
              <div className="w-100">
                {data?.email === null && data?.phone === null && (
                  <p className="text-center">
                    Anda belum memiliki data email/nomor telepon yang
                    terverifikasi. Silahkan hubungi admin untuk reset password.
                  </p>
                )}
                {email !== "" && (
                  <div className="flex flex-col gap-2 relative">
                    <label className="text-[12px] text-thirdy font-medium pl-1 mb-0">
                      Email
                    </label>
                    <input
                      type="text"
                      className="focus:border-1 rounded-full md:p-[1rem] p-[12px] text-base border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25 shadow shadow-black"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setErrorEmail("");
                      }}
                    />
                    <p className="text-base text-red pl-1 mb-0">{errorEmail}</p>
                  </div>
                )}
                {phone !== "" && (
                  <div className="flex flex-col gap-2 relative">
                    <label className="text-[12px] text-thirdy font-medium pl-1 mb-0">
                      Nomor HP
                    </label>
                    <input
                      type="text"
                      className="focus:border-1 disabled:text-gray-500 rounded-full md:p-[1rem] p-[12px] text-base border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25 shadow shadow-black"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setErrorPhone("");
                      }}
                    />
                    <p className="text-base text-red pl-1 mb-0">{errorPhone}</p>
                  </div>
                )}
                {alert && (
                  <div>
                    <p className="text-center text-[12px] mt-4">
                      Saat ini Whatsapp tidak tersedia. Silahkan hubungi <br />
                      <a
                        className="underline text-blue-400"
                        href="https://wa.me/+6289526643223"
                        target="_blank"
                      >
                        CS Closepay
                      </a>
                    </p>
                  </div>
                )}
                <div className="">
                  {data?.email === null && data?.phone === null ? null : (
                    <button
                      onClick={() => {
                        if (phone !== "") {
                          requestOtpWa();
                        } else {
                          submit();
                        }
                      }}
                      className="w-full md:p-[1rem] p-[12px] my-3 rounded-full text-white font-semibold text-[14px] bg-[#26A69A]"
                    >
                      Request OTP
                    </button>
                  )}

                  <button
                    type="button"
                    className="w-full md:p-[1rem] p-[12px] rounded-full text-[#26A69A] font-semibold text-[14px] border-2 border-[#26A69A]"
                    onClick={() => navigate(-1)}
                  >
                    Kembali
                  </button>
                </div>
              </div>
              {company?.showFtpLogo && (
                <div className="mt-4 w-100">
                  <p
                    className="md:text-xl text-base font-medium text-center md:mb-3 mb-0"
                    style={{ color: "#8F8F8F" }}
                  >
                    Powered By
                  </p>
                  <img src={closepayLogo} alt="icon" className="w-20 mx-auto" />
                </div>
              )}
            </div>
          </div>
        </div>
        <Transition appear show={modal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              // setModal(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto font-montserrat">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <div className="w-full flex flex-col">
                      <h1 className="text-lg font-semibold text-center">
                        Verifikasi Lupa Password
                      </h1>
                      <p className="text-center">
                        Pilih tujuan pengiriman notifikasi Anda
                      </p>
                      <div className="flex flex-col justify-start items-start gap-2">
                        <div className="flex gap-2 items-center">
                          <input
                            onChange={(e) => {
                              if (e.target.checked) {
                                cekEmailNoMasking(
                                  company?.id,
                                  location?.state?.username,
                                  "EMAIL"
                                );
                                setPhone("");
                              } else {
                              }
                            }}
                            type="radio"
                            id="email"
                            name="methode"
                          ></input>
                          <label htmlFor="email">Email</label>
                        </div>
                        <div className="flex gap-2 items-center">
                          <input
                            onChange={(e) => {
                              if (e.target.checked) {
                                cekPhoneNoMasking(
                                  company?.id,
                                  location?.state?.username,
                                  "PHONE"
                                );
                                setEmail("");
                              } else {
                              }
                            }}
                            disabled
                            type="radio"
                            id="wa"
                            name="methode"
                          ></input>
                          <label htmlFor="wa">
                            WhatsApp (Whatsapp sedang gangguan)
                          </label>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          if (email !== "" || phone !== "") {
                            setModal(false);
                          } else {
                            toast.error("Pilih tujuan terlebih dahulu");
                          }
                        }}
                        className="w-full bg-green p-2 mt-32 text-white rounded-lg"
                      >
                        Konfirmasi
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </section>
    </>
  );
};

export default InputEmailWeb;
