import { Fragment } from "react";
import CurrencyInput from "react-currency-input-field";
import { Link } from "react-router-dom";
import MerchantLogo from "../../assets/images/icon_bank/merchant.png";
import { formatCurrency } from "../../config/global";
import { iconBank } from "../../config/iconBank";
import { useAppSelector } from "../../core/feature/hooks";
import { useTranslation } from "react-i18next";
import Company from "../../core/models/company";
import { BalanceCard } from "../_common/BalanceCard";
import Header from "../_common/headerComponent";
import Collapse from "../_common/Collapse";

const Icon = (data: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        data.id === data.open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

const TopupWeb = (props: any) => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>
      <div className="flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-0">
        <div className="md:w-3/5 w-full mx-auto mb-10 md:px-[0px] px-[1.5rem]">
          <BalanceCard />
          {props.vaData.vaType === "OPEN" && (
            <div className="w-full">
              <h1 className="md:text-lg text-base font-medium text-customBlack py-3 mb-0">
                {t("topup.topUpVia")}
              </h1>
              <Fragment>
                {props.data.map((item: any, index: any) => {
                  return (
                    <div
                      className="bg-white p-3 mb-6 rounded-[5px] shadow-sm cursor-pointer"
                      key={item?.id}
                      onClick={() => props.handleOpen(index + 1)}
                    >
                      <div className="space-between">
                        <div className="flex flex-row items-center gap-3">
                          <img
                            src={iconBank(item.bank)}
                            alt=""
                            width={40}
                            height={40}
                          />
                          <h6 className="md:text-base text-sm text-customBlack font-medium font-poppins mb-0">
                            {item.bank === "BSI_TBG"
                              ? item.bank.replaceAll("_TBG", " ")
                              : item.bank}{" "}
                            <span className="italic text-xs">
                              {item.provider === "XENDIT" ||
                              item.provider === "DURIAN" ||
                              item.provider === "FAZZ" ||
                              item.provider === "LINKQU"
                                ? " (Provider)"
                                : ""}
                            </span>
                          </h6>
                        </div>
                        <button
                          type="button"
                          onClick={() => props.handleOpen(index + 1)}
                        >
                          <Icon id={index + 1} open={props.open} />
                        </button>
                      </div>
                      <Collapse open={props.open === index + 1}>
                        <hr className="my-2" />
                        {props.paymentTools.map((el: any) => (
                          <div key={el.id}>
                            <Link
                              to="detail"
                              state={{
                                bankData: {
                                  ...item,
                                  img: iconBank(item.bank),
                                },
                                type: el.name,
                                provider: item.provider,
                              }}
                              className="no-underline flex flex-row items-center gap-3"
                            >
                              <img
                                src={el?.img}
                                alt=""
                                width={24}
                                height={24}
                              />
                              <h6 className="md:text-base text-sm text-customBlack font-medium font-poppins mb-0">
                                {el.name}
                              </h6>
                            </Link>
                            <hr className="my-2" />
                          </div>
                        ))}
                      </Collapse>
                    </div>
                  );
                })}
                <div
                  className="bg-white p-3 mb-6 rounded-[5px] shadow-sm cursor-pointer"
                  onClick={() => props.handleOpen(props.data.length + 1)}
                >
                  <div className="space-between">
                    <div className="flex flex-row items-center gap-3">
                      <img src={MerchantLogo} alt="" width={30} height={30} />
                      <h6 className="md:text-base text-sm text-customBlack font-medium font-poppins mb-0">
                        {t("topup.merchant")}
                      </h6>
                    </div>
                    <button type="button" onClick={() => props.handleOpen(9)}>
                      <Icon id={props.data.length + 1} open={props.open} />
                    </button>
                  </div>
                  <Collapse open={props.open === props.data.length + 1}>
                    <hr className="my-2" />
                    <h6 className="md:text-base text-sm text-customBlack font-medium font-poppins mb-0">
                      {t("topup.instructions")}
                    </h6>
                    <hr className="my-2" />
                    {props.instructions.map((el: any) => (
                      <div key={el.id}>
                        <div className="flex items-center">
                          <div className="w-[10%]">
                            <div className="bg-[#2BC569] text-sm text-white rounded-full w-7 h-7 flex items-center justify-center">
                              {el.id}
                            </div>
                          </div>
                          <div className="w-[90%]">
                            <p className="text-customBlack text-sm font-poppins mb-0">
                              {el.detail}
                            </p>
                          </div>
                        </div>
                        <hr className="my-2" />
                      </div>
                    ))}
                  </Collapse>
                </div>
              </Fragment>
            </div>
          )}
          {props.vaData.vaType === "CLOSE" && (
            <div className="w-full mb-14">
              <h1 className="md:text-lg text-base font-medium text-customBlack py-3 mb-0">
                {t("topup.topUpNominal")}
              </h1>
              {props.nominalList.map((el: any) => (
                <div
                  className="bg-white p-3 mb-6 rounded-[5px] shadow-sm cursor-pointer"
                  key={el.id}
                  onClick={() => {
                    props.setAmount(el.amount);
                    props.setSelectedAmount(el);
                  }}
                >
                  <div className="space-between">
                    <div className="flex flex-row items-center gap-3">
                      <h6 className="md:text-base text-sm text-customBlack font-medium font-poppins mb-0">
                        {formatCurrency(el.amount)}
                      </h6>
                    </div>
                    <input
                      type="radio"
                      checked={props.selectedAmount?.id === el.id}
                      onChange={() => {
                        props.setAmount(el.amount);
                        props.setSelectedAmount(el);
                      }}
                      value=""
                    />
                  </div>
                </div>
              ))}
              <div>
                <label
                  htmlFor="nominal"
                  className="md:text-lg text-base font-medium text-customBlack"
                >
                  {t("topup.otherNominal")}
                </label>
                <CurrencyInput
                  className="w-full bg-white placeholder:text-newColor text-center text-lg font-semibold rounded-[5px] md:p-[1rem] p-[14px] focus:outline outline-newColor mt-[12px]"
                  value={props.amount}
                  prefix={"Rp"}
                  placeholder={t("topup_card.insertNominal")}
                  onValueChange={(value, name) => {
                    props.setAmount(value || "");
                    props.setSelectedAmount({});
                    console.log(value);
                  }}
                />
              </div>

              <div
                className="absolute bottom-0 left-1/2 md:w-3/5 w-full mt-8 md:px-[0px] px-[1.5rem]"
                style={{ transform: "translate(-50%, -50%)" }}
              >
                <button
                  type="button"
                  style={{
                    backgroundColor:
                      company?.color?.color1 !== undefined
                        ? company?.color?.color1
                        : "#26A69A",
                  }}
                  onClick={props.handleOnClick}
                  className="bg-green text-white font-medium rounded-[5px] w-full md:p-3 p-[12px]"
                >
                  {t("topup.buttonNext")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TopupWeb;
