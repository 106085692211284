import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import HomeLinkedAccountWeb from "./homeLinkedAccountWeb";
import HomeLinkedAccountMobile from "./homeLinkedAccountMobile";

const HomeLinkedAccount = () => {
  return (
    <div>
      <BrowserView>
        <HomeLinkedAccountWeb></HomeLinkedAccountWeb>
      </BrowserView>
      <MobileView>
        <HomeLinkedAccountMobile></HomeLinkedAccountMobile>
      </MobileView>
    </div>
  );
};

export default HomeLinkedAccount;
