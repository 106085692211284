import React from 'react';
import Header from '../../_common/headerComponent';

const guides = [
  {
    step: "1",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem."
  },
  {
    step: "2",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem."
  },
  {
    step: "3",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem."
  },
  {
    step: "4",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem."
  },
]

const GuideMobile = () => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      <Header navbarText="Panduan" />
      <div className="md:w-1/2 w-full mx-auto md:mt-12 mt-[0px] px-4 pb-28">
        {guides.map((item, index) => (
          <div key={index}>
            <div className="flex items-center gap-3">
              <div className="bg-green w-[24px] h-[24px] text-white text-center rounded-[50%] flex flex-col justify-center">
                {item.step}
              </div>
              <h6 className="md:text-lg text-base font-medium mb-0">Langkah {item.step}</h6>
            </div>
            <div className="border-l-green border-l-[1px] px-7 ml-[12px] my-1">
              <p className="md:text-sm text-xs font-light mb-0">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default GuideMobile