import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import InputEmailWeb from "./inputEmailWeb";
import InputEmailMobile from "./inputEmailMobile";

const InputEmail = () => {
  return (
    <>
      <BrowserView>
        <InputEmailWeb></InputEmailWeb>
      </BrowserView>
      <MobileView>
        <InputEmailMobile></InputEmailMobile>
      </MobileView>
    </>
  );
};

export default InputEmail;
