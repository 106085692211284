/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CommerceOrderHistoriesMobile from "./commerceOrderHistoriesMobile";
import CommerceOrderHistoriesWeb from "./commerceOrderHistoriesWeb";
import * as API_COMMERCE from "../../../core/service/api_commerce";
import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Company from "../../../core/models/company";
import { useTranslation } from "react-i18next";

const CommerceOrderHistories = () => {
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const [history, setHistory] = useState<any[]>([]);
  const [openTab, setOpenTab] = React.useState(1);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("-createdDate");
  const [statusTrx, setStatusTrx] = useState("WAITING_PAYMENT");
  const [navigateTo, setNavigateTo] = useState("");
  const [quantity, setQuantity] = React.useState<any>({});
  const [configOrderReceiver, setConfigOrderReceiver] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();

  const allStatus = [
    {
      id: 1,
      title: t('history.notYetPaid'),
      value: "WAITING_PAYMENT",
    },
    {
      id: 2,
      title: t('history.created'),
      value: "CREATED",
    },
    {
      id: 3,
      title: t('history.processed'),
      value: "PROCESSED",
    },
    {
      id: 4,
      title: t('history.sent'),
      value: "SENT",
    },
    {
      id: 5,
      title: t('history.atPickupPoint'),
      value: "AT_PICKUP_POINT",
    },
    {
      id: 6,
      title: t('history.confirmed'),
      value: "CONFIRMED",
    },
    {
      id: 7,
      title: t('history.canceled'),
      value: "CANCELED",
    },
    {
      id: 8,
      title: t('history.rejected'),
      value: "REJECTED",
    },
    {
      id: 8,
      title: t('history.onReview'),
      value: "ON_REVIEW",
    },
  ]

  const getHistory = async (currentPage: number = page, currentStatus: string = statusTrx) => {
    dispatch(setLoading(true));
    await API_COMMERCE.getListOrderHistories(currentPage, 10, sort, currentStatus)
      .then((res) => {
        dispatch(setLoading(false));
        let tempQuantity: any = {};
        res.data.data.map((el: any) =>
          el.orderList.map((el2: any) =>
            el2.products.map((el3: any) => {
              if (tempQuantity[el.transactionId]) {
                return tempQuantity = { ...tempQuantity, [el.transactionId]: tempQuantity[el.transactionId] += el3.quantity };
              } else {
                return tempQuantity = { ...tempQuantity, [el.transactionId]: el3.quantity };
              }
            })
          )
        )
        if (currentPage > 1) {
          setQuantity({ ...quantity, ...tempQuantity });
          setHistory(history.concat(res.data.data));
        } else {
          setQuantity(tempQuantity);
          setHistory(res.data.data);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getConfigOrderReceiver = async () => {
    dispatch(setLoading(true));
    await API_COMMERCE.getConfigOrderReceiver()
      .then((res) => {
        setConfigOrderReceiver(res.data.data.isActive);
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(setLoading(true)))
  }

  const getStatus = (stat: any) => {
    let status = {
      textColor: "",
      text: "",
      bgColor: "",
    };
    switch (stat) {
      case "WAITING_PAYMENT":
        status.textColor = "text-[#FF9723]";
        status.bgColor = "bg-[#FAF5CC]";
        status.text = t('history.waitingPayment');
        break;
      case "CREATED":
        status.textColor = "text-[#1AA7EC]";
        status.bgColor = "bg-[#1AA7EC52]";
        status.text = t('history.created');
        break;
      case "CANCELED":
        status.textColor = "text-[#9A9999]";
        status.bgColor = "bg-[#9a999952]";
        status.text = t('history.canceled');
        break;
      case "PROCESSED":
        status.textColor = "text-[#FFFFFF]";
        status.bgColor = "bg-[#E7DE1A]";
        status.text = t('history.processed');
        break;
      case "REJECTED":
        status.textColor = "text-[#EC1A1A]";
        status.bgColor = "bg-[#EC1A1A52]";
        status.text = t('history.rejected');
        break;
      case "CONFIRMED":
        status.textColor = "text-[#26BC63]";
        status.bgColor = "bg-[#9DF9C0]";
        status.text = t('history.confirmed');
        break;
      case "SENT":
        status.textColor = "text-[#26BC63]";
        status.bgColor = "bg-[#9DF9C0]";
        status.text = t('history.sent');
        break;
      case "AT_PICKUP_POINT":
        status.textColor = "text-[#FF9723]";
        status.bgColor = "bg-[#FAF5CC]";
        status.text = t('history.atPickupPoint');
        break;
      case "ON_REVIEW":
        status.textColor = "text-[#FFFFFF]";
        status.bgColor = "bg-[#E7DE1A]";
        status.text = t('history.onReview');
        break;

      default:
        status.text = "";
        break;
    }
    return status;
  };

  const onSortChange = (e: any) => {
    setSort(e.target.value);
  };

  const onFilterChange = (e: any) => {
    setStatusTrx(e.target.value);
  };

  const currentSize = useMemo(() => {
    const a = history.length;
    let b = 0;
    if (page === 1) {
      b = a / 1;
    } else {
      b = a / page;
    }

    return b;
  }, [history]);

  const addMore = async () => {
    setPage(page + 1);
    getHistory(page + 1);
  };

  useEffect(() => {
    getHistory();
    getConfigOrderReceiver();
    if (location.state !== null) {
      setNavigateTo(location.state.navigateTo);
    }
  }, []);

  return (
    <>
      <BrowserView>
        <CommerceOrderHistoriesWeb
          getHistory={(currentPage: number, currentStatus: string) => getHistory(currentPage, currentStatus)}
          getStatus={(stat: any) => getStatus(stat)}
          onSortChange={(e: any) => onSortChange(e)}
          onFilterChange={(e: any) => onFilterChange(e)}
          setOpenTab={(value: any) => setOpenTab(value)}
          setPage={(value: any) => setPage(value)}
          setStatusTrx={(value: any) => setStatusTrx(value)}
          addMore={() => addMore()}
          navigate={navigate}
          currentSize={currentSize}
          allStatus={configOrderReceiver ? allStatus : allStatus.filter((i) => i.value !== "AT_PICKUP_POINT")}
          company={company}
          history={history}
          quantity={quantity}
          openTab={openTab}
          statusTrx={statusTrx}
          sort={sort}
        />
      </BrowserView>
      <MobileView>
        <CommerceOrderHistoriesMobile
          getHistory={(currentPage: number, currentStatus: string) => getHistory(currentPage, currentStatus)}
          getStatus={(stat: any) => getStatus(stat)}
          onSortChange={(e: any) => onSortChange(e)}
          onFilterChange={(e: any) => onFilterChange(e)}
          setOpenTab={(value: any) => setOpenTab(value)}
          setPage={(value: any) => setPage(value)}
          setStatusTrx={(value: any) => setStatusTrx(value)}
          addMore={() => addMore()}
          navigate={navigate}
          currentSize={currentSize}
          allStatus={configOrderReceiver ? allStatus : allStatus.filter((i) => i.value !== "AT_PICKUP_POINT")}
          company={company}
          history={history}
          quantity={quantity}
          openTab={openTab}
          statusTrx={statusTrx}
          sort={sort}
          navigateTo={navigateTo}
        />
      </MobileView>
    </>
  );
};

export default CommerceOrderHistories;