import React, { useEffect, useState } from "react";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import { useNavigate } from "react-router-dom";
// import { BalanceCard } from "../_common/BalanceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { onlyNumber } from "../../config/global";
import * as API_DIGIPROD from "../../core/service/api_digiprod";
import { setLoading } from "../../core/feature/config/configSlice";
import logoBpjs from "../../assets/images/logo-bpjs.svg";
import logoBpjsKesehatan from "../../assets/images/logo-bpjs-kesehatan.svg";
import buttonSelected from "../../assets/images/button-selected.svg";
import { toast } from "react-toastify";
import ToastConfig from "../../config/Toast";
import { useTranslation } from "react-i18next";

const PPOBBPJSWeb = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [timeList, setTimeList] = useState<any[]>([]);
  const [number, setNumber] = useState("");
  const [time, setTime] = useState("");
  const [type, setType] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [errorTime, setErrorTime] = useState("");
  const [errorType, setErrorType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [active, setActive] = useState<any>({});

  const typeList = [
    {
      id: "1",
      name: t('bpjs.health'),
      logo: logoBpjsKesehatan,
    },
    {
      id: "2",
      name: t('bpjs.bpjsFines'),
      logo: logoBpjsKesehatan,
    },
    {
      id: "3",
      name: t('bpjs.employment'),
      logo: logoBpjs,
    },
  ];

  const getTime = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let time = [];
    const now = new Date();
    for (let i = 0; i < 12; i++) {
      const future = new Date(now.getFullYear(), now.getMonth() + i, 1);
      const month = months[future.getMonth()];
      const year = future.getFullYear();
      const result = month + " " + year;
      time.push(result);
    }
    setTimeList(time);
  };

  const handleOnClick = () => {
    if (type === "") {
      setErrorType(t('bpjs.choose_product_type'));
    } else if (time === "") {
      setErrorTime(t('bpjs.choose_payment'));
    } else if (number === "") {
      setErrorNumber(t('bpjs.enter_number_id'));
    } else {
      let query = {
        customerNumber: number,
      };
      dispatch(setLoading(true));
      API_DIGIPROD.trxBPSJInquiry(query)
        .then((res) => {
          dispatch(setLoading(false));
          navigate(`/${company?.initial}/digital-product/bpjs/summary`, {
            state: {
              data: res.data.data,
            },
          });
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  // const [configColor, setConfigColor] = useState<any>({});
  // const getConfigColor = async () => {
  //   var color1 =
  //     company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
  //   var color2 =
  //     company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

  //   setConfigColor({ color1: color1, color2: color2 });
  // };

  useEffect(() => {
    getTime();
    // getConfigColor();
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header></Header>

      {/* MODAL */}
      <div
        className={`${showModal ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="space-between md:px-[0px] px-[0.5rem]">
                <h4 className="md:text-base text-sm text-customBlack font-medium mb-0">
                  {t('bpjs.bpjs_product')}
                </h4>
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setActive("");
                    setType("");
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <hr />
              <ul className="px-3 mb-0">
                {typeList.map((item) => (
                  <li
                    className="list-none cursor-pointer"
                    key={item.id}
                    onClick={() => {
                      if (item.name === t('bpjs.health')) {
                        setShowModal(false);
                        setActive(item);
                        setType(item.name);
                        setErrorType("");
                      } else {
                        toast("Coming Soon", ToastConfig.warning);
                      }
                    }}
                  >
                    <div className="flex flex-row justify-between items-center">
                      <div className="centered-items gap-2">
                        <img src={item.logo} alt="" />
                        <p className="md:text-base text-sm font-semibold mb-0">
                          {item.name}
                        </p>
                      </div>
                      <div
                        className={`${active?.id === item.id ? "inline-block" : "hidden"
                          }`}
                      >
                        <img src={buttonSelected} alt="" />
                      </div>
                    </div>
                    <hr />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${showModal2 ? "block" : "hidden"} relative z-10`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
              <div className="space-between md:px-[0px] px-[0.5rem]">
                <h4 className="md:text-base text-sm text-customBlack font-medium mb-0">
                  {t('bpjs.pay_up_to')}
                </h4>
                <button
                  type="button"
                  onClick={() => {
                    setTime("");
                    setShowModal2(false);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p className="md:text-sm text-xs text-start mb-0">
                {t('bpjs.pay_up_to_desc')}
              </p>
              <hr />
              <ul className="px-3 mb-0">
                {timeList.map((item, index) => (
                  <li
                    className="list-none cursor-pointer hover:bg-teal-50"
                    key={index}
                    onClick={() => {
                      setTime(item);
                      setErrorTime("");
                      setShowModal2(false);
                    }}
                  >
                    <div className="flex flex-row justify-between items-center px-2 pt-2">
                      <p className="md:text-base text-sm font-semibold mb-0 text-start">
                        {item}
                      </p>
                    </div>
                    <hr className="mt-2" />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* ---- */}

      <div className="w-full md:w-2/3 lg:w-1/2 mx-auto flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 font-montserrat">
        <div className="w-full mb-20">
          <div className="flex flex-col items-center gap-3 rounded-xl md:mb-10 mb-6">
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('bpjs.bpjs_product')}
                </label>
                <input
                  defaultValue={type}
                  readOnly
                  onClick={() => setShowModal(true)}
                  className="bg-[#fff] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline border-[0.5px] border-[#9A9999]"
                />
                <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                  <button type="button" onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faCaretDown} size="1x" />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0">{errorType}</p>
            </div>
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('bpjs.pay_up_to')}
                </label>
                <input
                  defaultValue={time}
                  readOnly
                  disabled={type === ""}
                  onClick={() => {
                    setShowModal2(true);
                    getTime();
                  }}
                  className="bg-[#fff] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline border-[0.5px] border-[#9A9999]"
                />
                <div className="absolute md:top-[47px] top-[38px] right-4 cursor-pointer">
                  <button type="button" onClick={() => setShowModal2(true)}>
                    <FontAwesomeIcon icon={faCaretDown} size="1x" />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0">{errorTime}</p>
            </div>
            <div className="text-start w-full">
              <div className="relative">
                <label className="md:text-base text-sm font-medium text-customBlack mb-2 font-montserrat">
                  {t('bpjs.number_user')}
                </label>
                <input
                  type="number"
                  value={number}
                  onKeyPress={onlyNumber}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setErrorNumber("");
                  }}
                  className="bg-[#fff] w-full p-2.5 md:text-base text-sm rounded-[10px] focus:outline border-[0.5px] border-[#9A9999]"
                />
                <div className="absolute md:top-[47px] top-[40px] right-4">
                  <button
                    type="button"
                    className="bg-gray-500 justify-center flex rounded-[50%] px-[4px] py-[2px] text-white font-montserrat"
                    onClick={() => {
                      setNumber("");
                      setErrorNumber("");
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
              <p className="text-sm text-red pl-1 mb-0">{errorNumber}</p>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-0 right-0 w-full md:w-2/3 lg:w-1/2 left-1/2 px-4"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <button
            type="button"
            style={{
              background: `${company?.color?.color1 !== undefined
                ? company?.color?.color1
                : "#26A69A"
                }`,
            }}
            className="bg-green text-white font-medium text-base rounded-[10px] w-full py-[10px] font-montserrat"
            onClick={() => handleOnClick()}
          >
            {t('bpjs.next')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default PPOBBPJSWeb;
