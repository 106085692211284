import Header from "../../../_common/headerComponent";

const EmailVerificationMobile = (props: any) => {
  return (
    <section className="h-full min-h-screen flex flex-col justify-between font-poppins bg-mobile dark:bg-black">
      <Header navbarText={props.t('card_security_code.setting_transaction_pin')}></Header>
      <div className="container flex flex-col items-center justify-center md:mt-[64px] mt-[0px] mb-4 px-4">
        <div className="md:w-1/2 w-full">
          <div className="w-100 text-center mb-3">
            <h1 className="font-bold md:text-3xl text-xl">{props.t('card_security_code.verification')}</h1>
            <h5 className="font-normal md:text-base text-sm text-[#9A9999]">
              {props.t('card_security_code.description_otp')}
            </h5>
          </div>
          <div className="w-full text-left my-4">
            <input
              defaultValue={props.user?.email}
              readOnly
              disabled
              className="bg-white border-[0.5px] border-[#9A9999] text-[#252525] text-center w-full p-3 md:text-base text-sm rounded-[6px]"
            />
          </div>
          {props.user?.email === null && (
            <p className="font-normal md:text-base text-sm text-center text-[#9A9999]">
              {props.t('card_security_code.no_email')}
            </p>
          )}
        </div>
      </div>
      <div className="container px-4">
        <div className="md:w-1/2 w-full mx-auto pt-4 pb-[1.5rem] mt-6">
          <button
            type="button"
            style={{
              backgroundColor: `${props.user?.email === null ? "#F4F6F8" : props.company?.color?.color1 !== undefined ? props.company?.color?.color1 : "#26A69A"}`,
              color: `${props.user?.email === null ? "#9A9999" : "#FFFFFF"}`
            }}
            onClick={() => props.handleOnClick()}
            className="w-full md:p-[1rem] p-[12px] rounded-[10px] font-semibold md:text-base text-sm bg-btnGreen"
            disabled={props.user?.email === null}
          >
            {props.t('card_security_code.next')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default EmailVerificationMobile;
