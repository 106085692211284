import { useEffect, useState } from "react";
import { } from "../../core/feature/store";
import { useNavigate } from "react-router-dom";
import { } from "../../core/feature/store";
import Company from "../../core/models/company.js";
import Header from "../_common/headerComponent";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import _ from "lodash";
import "react-spring-bottom-sheet/dist/style.css";
import donationIcon from "../../assets/images/icon/donasi.svg";
import zakatIcon from "../../assets/images/icon/bag.svg";
import { camelCase } from "../../config/global";
import * as API from "../../core/service/api_donation";
import { setLoading } from "../../core/feature/config/configSlice";
import bg1 from "../../assets/images/donation/bg1.svg";
import logo from "../../assets/images/donation/logo.svg";
import phone from "../../assets/images/donation/phone.svg";
import image from "../../assets/images/donation/image.svg";
import { useTranslation } from "react-i18next";

const DonationHomeMobile = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [donationMenus] = useState([
    {
      value: "donation",
      label: t("donation.donation"),
      icon: donationIcon,
    },
    {
      value: "zakat",
      label: t("donation.zakat"),
      icon: zakatIcon,
    },
  ]);
  const [menuIcon, setMenuIcon] = useState<any>([]);

  const [title] = useState(t("donation.activation"));
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();

  // const customSetting = (props) => {};

  // const [openTab, setOpenTab] = useState(1);
  // const query = useLocation();

  const getDataMenu = async () => {
    dispatch(setLoading(true));
    await API.getMenuDonation()
      .then((res) => {
        dispatch(setLoading(false));
        setMenuIcon(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getDataMenu();
    // let state: any = query.state;
    // if (state && state.openTab) setOpenTab(state.openTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <Header
          backToUrl={`/${company?.initial}`}
          navbarText={t('donation.donationZakat')}
        ></Header>
        <div className="container h-full m-auto px-4 font-Montserrat">
          {/* <div className="flex flex-col items-center justify-center"> */}
          <div className="text-base font-semibold px-1 py-3">
            {camelCase(title)}
          </div>
          <div className="flex gap-3">
            {_.map(donationMenus, (menu) => (
              <div
                className="flex flex-col items-center gap-2"
                onClick={() =>
                  navigate(`/${company?.initial}/donations/${menu.value}`)
                }
              >
                <button className="bg-white shadow-md shadow-blue-gray-100 text-[#252525] rounded-2xl w-16 h-16 flex items-center justify-center">
                  <img
                    className="w-10"
                    src={
                      menuIcon?.find(
                        (el: any) =>
                          el.menuType.toLowerCase() === menu.value &&
                          el.isActive === true
                      )?.iconUrl
                        ? menuIcon?.find(
                          (el: any) =>
                            el.menuType.toLowerCase() === menu.value
                        ).iconUrl
                        : menu.icon
                    }
                    alt=""
                  />
                </button>
                <span className="font-normal text-xs select-none">
                  {menuIcon?.find(
                    (el: any) =>
                      el.menuType.toLowerCase() === menu.value &&
                      el.isActive === true
                  )?.name
                    ? menuIcon?.find(
                      (el: any) => el.menuType.toLowerCase() === menu.value
                    ).name
                    : menu.label}
                </span>
              </div>
            ))}
          </div>
          <div
            className="w-full relative flex bg-cover h-[10.688rem] rounded-[10px] mt-12 mb-6"
            style={{
              backgroundImage: `url(${bg1})`,
            }}
          >
            {" "}
            <img src={phone} alt="" className="absolute" />
            <img src={logo} alt="" className="absolute right-3 top-3" />
            <div className="flex flex-col justify-center ml-[10rem] text-[10px] text-white">
              <button className="text-white border border-1 border-white rounded-xl font-bold w-[3.89rem] mb-2">
                {t("donation.donation")}
              </button>
              <span>
                <b>{t("donation.raiseFunds")}</b> {t("donation.orDonate")} <br />
                {t("donation.easilyThrough")} <br /> <b>{t("donation.donationFeature")}</b>
              </span>
            </div>
          </div>
          <div
            className="w-full relative flex bg-cover h-[10.688rem] rounded-[10px] "
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        </div>
      </section>
    </>
  );
};

export default DonationHomeMobile;
