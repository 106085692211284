// import React, { useState } from "react";
import Header from "../_common/headerComponent";
// import * as API_USER from "../../core/service/api_user";
// import user from "../../assets/images/userNew.png";
// import notification from "../../assets/images/icon_siakad/notification.svg";
import monitor from "../../assets/images/icon_siakad/monitor.svg";
import jadwal from "../../assets/images/icon_siakad/jadwal.svg";
import absen from "../../assets/images/icon_siakad/absen.svg";
import nilai from "../../assets/images/icon_siakad/nilai.svg";
import raport from "../../assets/images/icon_siakad/eraport.svg";
import soal from "../../assets/images/icon_siakad/soal.svg";
import pejalaran from "../../assets/images/icon_siakad/pelajaran.svg";
import vidio from "../../assets/images/icon_siakad/vidio.svg";
import time from "../../assets/images/icon_siakad/time.svg";
import profile from "../../assets/images/icon_siakad/profile.svg";
// import book from "../../assets/images/icon_siakad/book.svg";
// import pen from "../../assets/images/icon_siakad/pen.svg";
import bgPattern from "../../assets/images/icon_siakad/bg-pattern.svg";
import arrow from "../../assets/images/icon_siakad/arrow-right-white.svg";
// import logoSolusiNegeri from "../../assets/images/solusinegeri-logo.svg";
// import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
// import Company from "../../core/models/company";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
// import moment from "moment";
import { ConfigColor } from "../../config/global";
import { toast } from "react-hot-toast";
import ToastConfig from "../../config/Toast";

const SiakadMobile = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  const config = ConfigColor();

  let navigate = useNavigate();
  // let dispatch = useAppDispatch();
  // let imageNotFound =
  //   "https://closepay-static.sgp1.digitaloceanspaces.com/image/no-image.png";

  const menu = [
    {
      name: "Jadwal",
      icon: jadwal,
      route: `lesson-timetable`,
      bg: "linear-gradient(99.45deg, #4694FF 5.36%, #1F70DE 48.73%, #3684F1 79.53%, #0052C3 105.6%)gi",
    },
    {
      name: "Absen",
      icon: absen,
      route: `attendance`,
      bg: "linear-gradient(180deg, #FFC730 0%, #FE9F40 100%)",
    },
    {
      name: "Nilai",
      icon: nilai,
      route: `grade`,
      bg: "linear-gradient(107.54deg, #FC7D82 20.32%, #F6575E 52.35%, #F1454C 75.22%)",
    },
    {
      name: "E-Raport",
      icon: raport,
      route: `eraport`,
      bg: " linear-gradient(99.45deg, #137B61 5.36%, #016F54 48.73%, #158065 79.53%, #004A37 105.6%)",
    },
    {
      name: "Latihan Soal",
      icon: soal,
      route: ``,
      bg: " linear-gradient(99.45deg, #137B61 5.36%, #016F54 48.73%, #158065 79.53%, #004A37 105.6%)",
    },
    {
      name: "Mata Pelajaran",
      icon: pejalaran,
      route: ``,
      bg: " linear-gradient(99.45deg, #137B61 5.36%, #016F54 48.73%, #158065 79.53%, #004A37 105.6%)",
    },
    {
      name: "Video Pembelajaran",
      icon: vidio,
      bg: " linear-gradient(99.45deg, #137B61 5.36%, #016F54 48.73%, #158065 79.53%, #004A37 105.6%)",
    },
  ];
  const mapel = [
    {
      classRoom: "XI IPA 2",
      mapel: "Matematika",
      time: "08.00-10.00",
      teacher: "Bu Umay, S.Pd",
    },
    {
      classRoom: "XI IPA 2",
      mapel: "Bahasa Indonesia",
      time: "10.00-12.00",
      teacher: "Bu Umay, S.Pd",
    },
    {
      classRoom: "XI IPA 2",
      mapel: "IPS",
      time: "13.00-14.00",
      teacher: "Bu Umay, S.Pd",
    },
    {
      classRoom: "XI IPA 2",
      mapel: "Seni Budaya",
      time: "14.00-16.00",
      teacher: "Bu Umay, S.Pd",
    },
  ];
  // const imageRender = (image: any) => {
  //   if (image == "") return imageNotFound;
  //   if (image !== "") return image;
  // };
  // const info = [
  //   {
  //     title: "lorem ipsum dolor sit amet",
  //     image: "https://source.unsplash.com/random/300×300",
  //     dateTime: moment().format("LL"),
  //   },
  //   {
  //     title: "lorem ipsum dolor sit amet",
  //     image: "https://source.unsplash.com/random/300×300",
  //     dateTime: moment().format("LL"),
  //   },
  //   {
  //     title: "lorem ipsum dolor sit amet",
  //     image: "https://source.unsplash.com/random/300×300",
  //     dateTime: moment().format("LL"),
  //   },
  //   {
  //     title: "lorem ipsum dolor sit amet",
  //     image: "https://source.unsplash.com/random/300×300",
  //     dateTime: moment().format("LL"),
  //   },
  // ];
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Jadwal Pelajaran"></Header>

      <div className="flex flex-col py-2">
        {/* <section className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-2">
            <img src={user} alt="" className="w-12 h-12" />
            <div className="flex flex-col gap-1">
              <h1 className="font-medium text-sm m-0">Jhon Doe</h1>
              <span className="font-light text-xs">L2001800031</span>
            </div>
          </div>
          <div className="flex justify-center items-center rounded-full w-12 h-12 border-[0.5px] border-[#9A9999]">
            <img src={notification} alt="" className="w-4 h-4" />
          </div>
        </section> */}

        <section
          style={{
            backgroundImage: `linear-gradient(to bottom, ${config?.color1}, ${config?.color2})`,
          }}
          className={`flex flex-row min-h-[225px] h-fit p-3 gap-1 relative`}
        >
          <div className="absolute h-full top-0 left-0 z-10 ">
            <img
              src={bgPattern}
              className="object-cover w-full h-full"
              alt=""
            />
          </div>
          <div className="flex flex-col items-start h-fit gap-1 px-2 relative z-50">
            <h1 className="font-medium text-xl text-white m-0 mt-1">
              <span className="font-bold italic">E Raport</span> bisa diakses
              loo
            </h1>
            <span className="font-normal text-[10px] text-white">
              Lorem ipsum dolor sit amet covnsectetur. Sagittis volutpat et
              faucibus ornare lacus. Sit varius nibh fermentum ornare.
            </span>
            <button className="bg-[#F6B557] items-center shadow-md shadow-[#000000]/20 mt-2 px-3 justify-between py-1 text-white rounded-full flex flex-row gap-2 font-medium text-[10px]">
              E-Raport <img src={arrow} alt="" />
            </button>
          </div>
          <img src={monitor} alt="" className="h-[180px] -mt-6" />
        </section>

        <section className="w-auto p-4">
          <div
            style={{
              backgroundImage: `linear-gradient(to bottom, ${config?.color1}, ${config?.color2})`,
            }}
            className="container flex justify-between items-center py-2.5 px-4 text-white rounded-[10px] w-full"
          >
            <div className="flex flex-col">
              <span className="font-semibold text-[16px] text-white">
                Kalender Akademik
              </span>
              <span className="text-[12px]">
                Lorem ipsum dolor <br /> Sit amet covnsectetur
              </span>
            </div>
            <div>
              <button className="rounded-lg py-1 px-2 bg-[#F6B557] text-[10px] text-white">
                Lihat Kalender
              </button>
            </div>
          </div>
        </section>

        <section className="px-6">
          <h1 className="font-semibold text-[16px]">Aktifitas</h1>
          <div className="grid grid-cols-4 gap-2 ">
            {menu.map((item: any) => (
              <div className="flex flex-col items-center gap-2.5 my-2">
                <div
                  className={` flex flex-col bg-white gap-2 w-[54px] h-[54px] items-center rounded-[18px] justify-center shadow-md shadow-[#1A1A1A]/5`}
                  onClick={() => {
                    if (item.name === "Video Pembelajaran") {
                      window.open(
                        "https://www.youtube.com/watch?v=o4nSFwcb6Dc",
                        "_blank"
                      );
                    } else if (item.name === "Latihan Soal") {
                      toast("Coming Soon", ToastConfig.info);
                    } else if (item.name === "Mata Pelajaran") {
                      toast("Coming Soon", ToastConfig.info);
                    }
                    navigate(item.route);
                  }}
                  // style={{ background: `${item.bg}` }}
                >
                  <img src={item.icon} alt="" className="w-9 h-9" />
                </div>
                <span className="font-semibold text-xs text-center text-[#777879]">
                  {item.name}
                </span>
              </div>
            ))}
          </div>
        </section>

        <section className="px-6 py-3">
          <div className="flex flex-row justify-between items-center">
            <span className="font-semibold text-[16px]">Mapel Hari Ini</span>
            <span className="font-light text-[12px] text-[#006F54]">
              LIhat Semua
            </span>
          </div>
          <Swiper
            freeMode={false}
            grabCursor={true}
            modules={[FreeMode]}
            slidesPerView={"auto"}
            spaceBetween={15}
            // className="mySwiper"
          >
            {mapel.map((item: any) => (
              <SwiperSlide className="cursor-pointer swiper-slide-100 swiper-slide-siakad relative items-start flex flex-col bg-white  mt-2 p-3 rounded-md">
                <h1 className="font-light text-[10px] text-left text-[#26A69A]">
                  {item.classRoom}
                </h1>
                <hr className="my-2  bg-[#929292]" />
                <div>
                  <div className="flex">
                    <svg
                      width="4"
                      height="30"
                      viewBox="0 0 4 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="4" height="30" rx="2" fill="#26A69A" />
                    </svg>
                    <div className="flex flex-col ml-2 -mt-0.5">
                      <div className="font-semibold text-[12px] text-left">
                        {item.mapel}
                      </div>
                      <p className="text-[10px] text-left">
                        Pelajaran Mengolah otak agar cerdas
                      </p>
                    </div>
                  </div>
                  <div className="flex ">
                    <div className="flex flex-row gap-2 mt-1 mr-3">
                      <img src={profile} alt="" className="h-3 w-3" />
                      <span className="font-light text-[10px] text-[#9A9999]">
                        {item.teacher}
                      </span>
                    </div>
                    <div className="flex flex-row  gap-2 mt-1">
                      <img src={time} alt="" className="h-3 w-3" />
                      <span className="font-light text-[10px] text-[#9A9999]">
                        {item.time}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      </div>
    </section>
  );
};

export default SiakadMobile;
