import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserLogin from "../../models/userLogin";
import {
  saveState,
  loadState,
  removeState,
} from "../browser-storage/browser-storage";
import _ from "lodash";
import User from "../../models/user";
import { decryptedValue, encryptedValue } from "../../../config/Encription";

type InitialState = {
  userLogin: UserLogin[];
};
const type = "userLogin";
function loadUserLogin (){
  let ciperText= loadState(type)
  let decrypted= decryptedValue(ciperText)
  return decrypted
 }
 
const initialState: InitialState = {
  userLogin: loadUserLogin(),
};

export const userLoginSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setUserLogin: (state, action: PayloadAction<UserLogin[]>) => {
      state.userLogin = action.payload;
      let ciperText = encryptedValue(action.payload)
      saveState(type, ciperText)
    },
    removeUserLogin: (state) => {
      state.userLogin = [];
      removeState(type);
    },
    removeUserLoginByUser: (state, action: PayloadAction<User>) => {
      // state.userLogin = [{}];
      state.userLogin = _.reject(state.userLogin, {
        id: action.payload.noId,
        name: action.payload.name,
      });
      saveState(
        type,
        _.reject(state.userLogin, {
          id: action.payload.noId,
          name: action.payload.name,
        })
      );
      // removeState(type);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserLogin, removeUserLogin, removeUserLoginByUser } =
  userLoginSlice.actions;

export default userLoginSlice.reducer;
