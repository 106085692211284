import React, { useState, useEffect, FC } from "react";
// import toast as alert, { Toaster } from "react-hot-toast";
import { toast } from "react-toastify";
// import { requestForToken, onMessageListener } from "../../firebase";
import { useAppSelector, useAppDispatch } from "../../core/feature/hooks";
// import { hexToRgba } from "../../config/global";
// import Bg from "../../assets/images/bg-notif.svg";
// import { Dialog, Transition } from "@headlessui/react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  removeFireBaseToken,
  setFireBaseToken,
} from "../../core/feature/firebaseToken/firebaseTokenSlice";

interface NotificationComponentProps {
  user?: any;
}
const Notification: FC<NotificationComponentProps> = (props): JSX.Element => {
  const dispatch = useAppDispatch();
  // const token = useAppSelector((state) => state.token.token);
  const user = useAppSelector((state) => state.user.user);
  const company = useAppSelector((state) => state.company.company);
  const [notification, setNotification] = useState<any>();
  const [data, setData] = useState<any>({});

  const notify = () =>
    toast(<ToastDisplay />, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      className: "md:w-[550px] md:ml-[-10em] radius-toast",
    });

  function ToastDisplay() {
    return (
      <div
        className="relative space-between bg-no-repeat rounded-xl"
        onClick={() => moveTo()}
      >
        <div className="mr-4 flex flex-col justify-between md:pr-24 pr-[3rem]">
          <p>
            <b>{notification?.title}</b>
          </p>
          <p className="text-justify  text-[#CCCCCC] text-xs">
            {notification?.body}
          </p>
          {/* {Object.keys(data).map((key: any) => (
            <p key={key} className="text-black text-xs">
              {key}: {data[key]}
            </p>
          ))} */}
        </div>
        <div className=" absolute flex items-center h-full md:right-4 right-0 mr-2">
          <img
            alt=""
            src={
              !notification?.icon || notification.icon === "string"
                ? company?.companyLogo
                : notification?.icon
            }
            className="object-cover w-10"
          />
        </div>
      </div>
    );
  }

  const moveTo = () => {
    if (data?.url) {
      window.open(
        `${data?.url}`,
        "_blank" // <- This is what makes it open in a new window.
      );
    }
  };

  var firebaseConfig = {
    apiKey: "AIzaSyDVZyJAE3jLfH5bVSKsIxgHoXJwkU2PiyQ",
    authDomain: "solusinegeri-merchant.firebaseapp.com",
    projectId: "solusinegeri-merchant",
    storageBucket: "solusinegeri-merchant.appspot.com",
    messagingSenderId: "9296043542",
    appId: "1:9296043542:web:add970585f9fccbe479f37",
    measurementId: "G-VQY2Z62978",
  };

  initializeApp(firebaseConfig);
  const messaging = getMessaging();

  useEffect(() => {
    if (notification?.title) {
      notify();
      setData(notification?.data ? notification?.data : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const requestForToken = () => {
    return getToken(messaging, {
      vapidKey:
        "BPoPfPt9H3ZWN5PQC-bVwEn2N3baYiy4FM2jQCRV8Pbzj1WnmvKUEz4Lb2ACTXh5wZJtNbVVq_vnlFNtdphtmt8",
    })
      .then((currentToken) => {
        if (currentToken) {
          dispatch(setFireBaseToken(currentToken));
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
        setNotification(payload?.notification);
        setData(payload.data);
      });
    });

  useEffect(() => {
    if (user?.name) {
      requestForToken();
      onMessageListener();
    } else {
      dispatch(removeFireBaseToken());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.name]);
  // firebaseCheck()

  return <></>;
};

export default Notification;
