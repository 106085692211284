import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React, { FC } from "react";

interface DetailInvoiceSkeletonProps {
  type?: string;
}

const DetailInvoiceSkeleton: FC<DetailInvoiceSkeletonProps> = (
  props
): JSX.Element => {
  return (
    <div className="md:w-2/3 w-full mx-auto rounded-xl overflow-hidden bg-white shadow-sm shadow-gray-600 pb-3 ">
      <div
        className={`bg-[#EC1A1A] p-3 border-b-[1] border-solid border-gray-700 flex justify-center`}
      >
        <div className="w-64">
          <Skeleton height={20} />
        </div>
      </div>
      <div className="p-3 flex flex-col gap-2">
        <div className="space-between">
          <div className="mb-0 w-20">
            <Skeleton />
          </div>
          <div className=" mb-0 w-20">
            <Skeleton />
          </div>
        </div>
        <div className=" space-between">
          <div className="mb-0 w-24">
            <Skeleton />
          </div>
          <div className=" mb-0 w-20">
            <Skeleton />
          </div>
        </div>
        <div className=" space-between">
          <div className="mb-0 w-24">
            <Skeleton />
          </div>
          <div className=" mb-0 w-20">
            <Skeleton />
          </div>
        </div>
        <div className=" space-between">
          <div className="mb-0 w-28">
            <Skeleton />
          </div>
          <div className=" mb-0 w-20">
            <Skeleton />
          </div>
        </div>
        <div className="">
          <div className="mb-0 w-20">
            <Skeleton />
          </div>
          {/* {data &&
            _.map(data.item_details, (item) => ( */}
          <div className="pl-10 space-between">
            <div className="mb-0 w-14">
              <Skeleton />
            </div>
            <div className="mb-0 w-20">
              <Skeleton />
            </div>
          </div>
          {/* ))} */}
          {/* {data &&} */}
          <div className="pl-10 space-between">
            <div className="mb-0 w-20">
              <Skeleton />
            </div>
            <div className="mb-0 w-20">
              <Skeleton />
            </div>
          </div>
        </div>
        <div className=" space-between border-t-[1px] border-dashed border-[#9A9999]"></div>{" "}
        <div className=" space-between">
          <div className="mb-0 w-20">
            <Skeleton />
          </div>
          <div className=" mb-0 w-20">
            <Skeleton />
          </div>
        </div>
        <div className=" space-between">
          <div className="mb-0 w-36">
            <Skeleton />
          </div>
          <div className=" mb-0 w-20">
            <Skeleton />
          </div>
        </div>
        <div className="space-between">
          <div className="mb-0 w-28">
            <Skeleton />
          </div>
          <div className="mb-0 w-20">
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailInvoiceSkeleton;
