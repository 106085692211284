import { BrowserView, MobileView } from "react-device-detect";
import VirtualCardHistoryWeb from "./virtualCardHistoryWeb";
import VirtualCardHistoryMobile from "./virtualCardHistoryMobile";
import mutationIcon from "../../../assets/images/vcard_transactional.svg";
import transactionDataIcon from "../../../assets/images/vcard_mutation.svg";

const data = [
  {
    id: "1",
    title: "Mutasi",
    img: mutationIcon,
    url: "mutation",
  },
  {
    id: "2",
    title: "Data Transaksi",
    img: transactionDataIcon,
    url: "transactions",
  },
];

const VirtualCardHistory = () => {
  return (
    <>
      <BrowserView>
        <VirtualCardHistoryWeb
          data={data}
        />
      </BrowserView>
      <MobileView>
        <VirtualCardHistoryMobile
          data={data}
        />
      </MobileView>
    </>
  );
};

export default VirtualCardHistory;
