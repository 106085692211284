import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DonationDetailWeb from "./donationDetailWeb";
import DonationDetailMobile from "./donationDetailMobile";

const DonationDetail = () => {
  return (
    <>
      <BrowserView>
        <DonationDetailWeb></DonationDetailWeb>
      </BrowserView>
      <MobileView>
        <DonationDetailMobile></DonationDetailMobile>
      </MobileView>
    </>
  );
};

export default DonationDetail;
