/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import Countdown, { zeroPad } from 'react-countdown';
import Swal from 'sweetalert2';
import * as API_BALANCE from "../../../core/service/api_balance";
import { formatCurrency } from '../../../config/global';
import Header from '../../_common/headerComponent';
import FailedIcon from "../../../assets/images/failed-icon.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../core/feature/hooks';
import { setLoading } from '../../../core/feature/config/configSlice';
import SuccessIcon from "../../../assets/images/icon-success-vcard.png";
import { useTranslation } from "react-i18next";

const ViewBarcodeMobile = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [nominal, setNominal] = useState("");
  const [qrString, setQrString] = useState("");
  const [timer, setTimer] = useState(0);
  const [isCountdown, setIsCountdown] = useState(false);

  const createCpmQrCode = async (data: any, token: string) => {
    dispatch(setLoading(true));
    await API_BALANCE.createCpmQrCode(data, token)
      .then((res: any) => {
        dispatch(setLoading(false));
        setQrString(res.data.data.code);
        setTimer(Date.now() + 300000);
        setIsCountdown(true);
      })
      .catch((err: any) => {
        navigate(-1);
        dispatch(setLoading(false));
      });
  };

  const renderer = (data: any) => {
    if (data.completed) {
      setNominal("");
      setQrString("");
      setTimer(0);
      Swal.fire({
        title: "Barcode Expired",
        imageUrl: FailedIcon,
        imageAlt: "Custom image",
        imageWidth: 220,
        imageHeight: 220,
        confirmButtonColor: "#EC1A1A",
        confirmButtonText: "Kembali",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
      return (
        <span className="text-red font-semibold md:text-xl text-lg text-center">
          {t("barcodeWeb.timeOut")}
        </span>
      );
    } else {
      return (
        <span className="text-[#00D19F] font-semibold md:text-xl text-lg text-center">
          {zeroPad(data.minutes)}:{zeroPad(data.seconds)}
        </span>
      );
    }
  };

  const checkStatusCpmQrCode = async (code: string) => {
    await API_BALANCE.checkStatusCpmQrCode(code)
      .then((res) => {
        if (res.data.data.status === "PAID") {
          setIsCountdown(false);
          setQrString("");
          setNominal("");
          Swal.fire({
            title: "Transaksi Berhasil",
            imageUrl: SuccessIcon,
            imageAlt: "Custom image",
            imageWidth: 220,
            imageHeight: 220,
            confirmButtonColor: "#00D19F",
            confirmButtonText: "Kembali",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
       });
  };

  useEffect(() => {
    if (qrString) {
      const loop = setInterval(() => {
        checkStatusCpmQrCode(qrString);
      }, 3000);
      return () => clearInterval(loop);
    }
  }, [qrString]);

  useEffect(() => {
    if(location.state !== null){
      console.log(location.state.data, location.state.token);
      createCpmQrCode(location.state.data, location.state.token);
      setNominal(location.state.data.amount);
    }
  }, [])

  return (
    <section className="h-full min-h-screen font-montserrat bg-white">
      {isCountdown && (
        <React.Fragment>
          <Header navbarText="Barcode"></Header>
          <div className="container md:mt-[64px] mt-[0px] px-4 pb-[64px]">
            <div
              className="lg:w-1/2 md:w-3/5 w-full mx-auto py-10 px-4 mb-6"
            >
              <h1 className="text-center text-[20px] font-semibold">
                {t("barcodeWeb.barcodeTrancation")}
              </h1>
              <div className="mx-auto w-fit rounded-xl">
                <Barcode value={qrString} displayValue={false} />
              </div>
              <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center">
                <h6 className="md:text-base text-[16px] font-medium mt-[20px]">
                  {t("barcodeWeb.nominalPayment")}
                </h6>
                <p className="md:text-base text-[16px] font-semibold">
                  {formatCurrency(nominal)}
                </p>
                <h6 className="md:text-base text-[16px] font-medium mt-8">
                  {t("barcodeWeb.timeLimit")}
                </h6>
                {isCountdown && (
                  <Countdown key={timer} date={timer} renderer={renderer} />
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </section>
  )
}

export default ViewBarcodeMobile