/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../../core/feature/config/configSlice";
import { useAppDispatch } from "../../../core/feature/hooks";
import * as API_BALANCE from "../../../core/service/api_balance";
// import BottomLogo from "../../_common/bottomLogo";
import Header from "../../_common/headerComponent";
import Html5QrcodePlugin from "../../_common/Html5QRCodePlugin";
import "./index.scss";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ScanBarcodeMobile = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [barcode, setBarcode] = React.useState("");
  const [balanceList, setBalanceList] = React.useState<any[]>([]);
  const [selectedBalance, setSelectedBalance] = React.useState<any>({});

  const getBarcodeType = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getMerchantProfileAndBarcodeType(barcode)
      .then((res) => {
        dispatch(setLoading(false));
        if (res.data.data.qrCodeType === "STATIC") {
          navigate(`input-nominal`, {
            state: {
              data: res.data.data,
              barcode,
              balanceCode: selectedBalance.value,
            },
          });
        } else {
          navigate(`summary`, {
            state: {
              barcode,
              type: "dynamic",
              balanceCode: selectedBalance.value,
            },
          });
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getBalanceList = async () => {
    dispatch(setLoading(true));
    await API_BALANCE.getComboAllowedBalanceCodes()
      .then((res) => {
        dispatch(setLoading(false));
        let tempBalanceList: any = [];
        if (res.data.length > 0) {
          res.data.map((el: any) =>
            tempBalanceList.push({
              value: el.balanceCode,
              label: el.balanceName,
            })
          );
          setBalanceList(tempBalanceList);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const onNewScanResult = (decodedText: any) => {
    setBarcode(decodedText);
  };

  React.useEffect(() => {
    if (barcode !== "") {
      if (selectedBalance.value === undefined) {
        toast.error("Pilih saldo yang akan digunakan");
      } else {
        getBarcodeType();
      }
    }
  }, [barcode]);

  React.useEffect(() => {
    getBalanceList();
  }, []);

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText={t('barcodeWeb.payBardcode')} />
      <div className="container flex flex-col gap-5 justify-center md:mt-[64px] mt-[0px] px-4 pb-[64px]">
        <div className="md:w-3/5 w-full mx-auto">
          <div className="w-full text-center">
            <div className="md:w-3/4 w-full mx-auto mb-5 select-balance-cpm">
              <label className="md:text-lg text-base text-customBlack font-medium mb-2 w-full text-start">
                {t('barcodeWeb.payWith')}
                <span className="md:text-xs text-[10px] font-light italic">
                  ({t('barcodeWeb.selectBalance')})
                </span>
              </label>
              <Select
                options={balanceList}
                value={selectedBalance}
                onChange={(e) => {
                  if (e !== null) setSelectedBalance(e);
                }}
                className="z-10"
              />
            </div>
            {selectedBalance.value !== undefined && (
              <React.Fragment>
                <h3 className="md:text-lg text-base font-semibold mb-[1rem] font-montserrat">
                  {t('barcodeWeb.scanQR')}
                </h3>
                <div className="md:w-2/3 w-full mx-auto custom-scan-barcode">
                  <Html5QrcodePlugin
                    fps={30}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                  />
                </div>
                <p className="md:text-base text-sm font-medium my-4 font-montserrat">
                  {t('barcodeWeb.scanPrompt')}
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScanBarcodeMobile;
