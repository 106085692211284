import React, { useEffect, useState } from "react";
import home from "../../assets/images/icon_home/aHome.svg";
import info from "../../assets/images/icon_home/aInfo.svg";
import message from "../../assets/images/icon_home/aMessage.svg";
import profile from "../../assets/images/icon_home/aProfile.svg";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";
import { Link } from "react-router-dom";
import User from "../../core/models/user";
import { find } from "lodash";
import Token from "../../core/models/token";
import {
  loadState,
  saveState,
} from "../../core/feature/browser-storage/browser-storage";
import * as API from "../../core/service/api_notification";
import { useTranslation } from "react-i18next";

export default function Navbar(props: any) {
  const { t } = useTranslation();
  const [isMenuActive] = useState(props.item);
  const company: Company = useAppSelector((state) => state.company.company);
  const [configColor, setConfigColor] = useState<any>({});
  const token: Token[] = useAppSelector((state) => state.token.token);
  const newToken = find(token, { cid: company?.initial });
  const user: User = useAppSelector((state) => state.user.user);
  const [isLogin] = useState(user && newToken);
  const notif = loadState("notif_statistic");

  const getConfigColor = async () => {
    var color1 =
      company?.color?.color1 !== undefined ? company?.color?.color1 : "#006F54";
    var color2 =
      company?.color?.color2 !== undefined ? company?.color?.color2 : "#087B5E";

    setConfigColor({ color1: color1, color2: color2 });
  };

  const getNotifStatistic = () => {
    API.getNotificationStatistic()
      .then((res) => {
        saveState("notif_statistic", res.data.data);
      })
      .catch((er) => { });
  };

  useEffect(() => {
    getConfigColor();
    if (isLogin) getNotifStatistic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  const [menu] = useState([
    {
      title: "Home",
      subtitle: t('navbar.home'),
      iconActive: home,
      iconNonActive: home,
      url: `/${company?.initial}`,
    },
    {
      title: "Informasi",
      subtitle: t('navbar.news'),
      iconActive: info,
      iconNonActive: info,
      url: isLogin
        ? `/${company?.initial}/news-information`
        : `/${company?.initial}/login`,
    },
    {
      title: "Pesan",
      subtitle: t('navbar.notification'),
      iconActive: message,
      iconNonActive: message,
      url: isLogin
        ? `/${company?.initial}/notification`
        : `/${company?.initial}/login`,
    },
    {
      title: "Profile",
      subtitle: "Profil",
      iconActive: profile,
      iconNonActive: profile,
      url: isLogin
        ? `/${company?.initial}/profile`
        : `/${company?.initial}/login`,
    },
  ]);

  return (
    <div className="mx-auto container w-full flex flex-col justify-center items-center">
      <div className="relative flex items-center justify-between lg:justify-center lg:space-x-16">
        <ul className="flex items-center lg:flex">
          {menu.map((e, i) => {
            if (e.title === isMenuActive) {
              return (
                <Link to={e.url} key={i} className="no-underline">
                  <div
                    className="flex flex-row mr-2 justify-center h-30 px-3 py-2.5 rounded-full"
                    style={{
                      backgroundColor: `${configColor?.color2}`,
                    }}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <img src={e.iconActive} className="h-5" alt="" />
                      <span className="text-white ml-1">{e.subtitle}</span>
                      {isLogin &&
                        e.title === "Pesan" &&
                        notif?.unreadMessage > 0 && (
                          <span className="font-normal text-[10px] ml-1 p-1 w-[1.5rem] h-[1.5rem] flex justify-center items-center rounded-xl bg-red text-white">
                            {notif?.unreadMessage > 99
                              ? "99+"
                              : notif?.unreadMessage}
                          </span>
                        )}
                    </div>
                  </div>
                </Link>
              );
            } else {
              if (e.title === "Pesan") {
                return (
                  <div
                    key={i}
                    className="no-underline grow flex flex-row justify-center h-30 px-3 py-2.5 m-2 rounded-full text-gray-400 bg-black bg-opacity-10 hover:text-gray-400 cursor-pointer"
                  >
                    <Link
                      key={i}
                      to={e.url}
                      className="no-underline relative text-white flex"
                    >
                      <li className="flex">
                        <img
                          src={e.iconNonActive}
                          alt=""
                          className="h-6 lg:h-6 mr-1"
                        />
                        <span className="hover:underline">{e.subtitle}</span>
                      </li>
                      {isLogin && notif?.unreadMessage > 0 && (
                        <span className="font-normal text-[10px] ml-1 p-1 w-[1.5rem] h-[1.5rem] flex justify-center items-center rounded-xl bg-red text-white">
                          {notif?.unreadMessage > 99
                            ? "99+"
                            : notif?.unreadMessage}
                        </span>
                      )}
                    </Link>
                  </div>
                );
              } else if (e.title === "Profile") {
                return (
                  <div className="text-center ">
                    {isLogin ? null : (
                      <Link
                        key={i}
                        to={e.url}
                        className="no-underline grow flex flex-row justify-center h-30 px-3 py-2.5 m-2 rounded-full text-gray-400 bg-black bg-opacity-10 hover:underline hover:text-gray-400 cursor-pointer"
                      >
                        <li className="flex">
                          <img
                            src={e.iconNonActive}
                            alt=""
                            className="h-6 lg:h-6 mr-1"
                          />
                          <span>{e.subtitle}</span>
                        </li>
                      </Link>
                    )}
                  </div>
                );
              } else if (e.title === "Informasi") {
                return (
                  <div className="text-center">
                    <Link
                      key={i}
                      to={e.url}
                      className="no-underline grow flex flex-row justify-center h-30 px-3 py-2.5 m-2 rounded-full text-gray-400 bg-black bg-opacity-10 hover:underline hover:text-gray-400 cursor-pointer"
                    >
                      <div className="flex">
                        <img
                          src={e.iconNonActive}
                          alt=""
                          className="h-6 lg:h-6 mr-1"
                        />
                        <span>{e.subtitle}</span>
                      </div>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <Link
                    key={i}
                    to={e.url}
                    className="no-underline grow flex flex-row justify-center h-30 px-3 py-2.5 m-2 rounded-full text-gray-400 bg-black bg-opacity-10 hover:underline hover:text-gray-400 cursor-pointer"
                  >
                    <li className="flex">
                      <img
                        src={e.iconNonActive}
                        alt=""
                        className="h-6 lg:h-6 mr-2"
                      />
                      <span>{e.subtitle}</span>
                    </li>
                  </Link>
                );
              }
            }
          })}
        </ul>
      </div>
    </div>
  );
}
