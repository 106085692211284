import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";
import "react-spring-bottom-sheet/dist/style.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import UserDefault from "../../assets/images/userDefault.png";
import {
  formatCurrency,
  onlyAlphaNum,
  onlyNumber,
} from "../../config/global.js";
import Header from "../_common/headerComponent";
import { useTranslation } from "react-i18next";

const InvoiceCreateWeb = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <Header />
        <div className="container flex flex-col justify-center gap-5 mt-[64px] pb-10">
          <div className="lg:w-2/3 md:w-2/3 w-full mx-auto flex flex-col gap-1 bg-white rounded-xl p-3 shadow-sm">
            <div className={`flex flex-col gap-1 mt-3`}>
              <label className="md:text-base text-sm text-thirdy leading-5 font-medium required">
                {t("invoiceCreate.invoiceName")}
              </label>
              <input
                type="text"
                className="rounded-[6px] md:px-[1rem] px-[12px] h-11 border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                value={
                  props.dataPost?.title === null ? "" : props.dataPost?.title
                }
                onChange={(e) => {
                  props.changeData(e.target.value, "title");
                  props.changeValid("title");
                }}
                placeholder={t("invoiceCreate.invoiceNamePlaceholder")}
              />
              <p
                className={`${
                  props.valid.title ? "hidden" : "text-sm text-red pl-1 mb-0"
                }`}
              >
                {t("invoiceCreate.nameRequired")}
              </p>
            </div>

            <div className={`flex flex-col gap-1 mt-3`}>
              <label className="md:text-base text-sm text-thirdy leading-5 font-medium required">
                {t("invoiceCreate.invoiceType")}
              </label>
              <Select
                isDisabled
                options={props.listInvoiceType}
                value={props.selectedInvoiceType}
                onChange={(e) => {
                  props.changeCombo(e, "invoiceType");
                  props.setSelectedInvoiceType(e);
                }}
              ></Select>
              <p
                className={`${
                  props.valid.invoiceType
                    ? "hidden"
                    : "text-sm text-red pl-1 mb-0"
                }`}
              >
                {t("invoiceCreate.itemDetailsValidation")}
              </p>
            </div>

            <div className={`flex flex-col gap-1 mt-3`}>
              <label className="md:text-base text-sm text-thirdy leading-5 font-medium required">
                {t("invoiceCreate.userType")}
              </label>
              <Select
                options={props.listUserType}
                value={props.selectedUserType}
                onChange={(e) => {
                  props.changeCombo(e, "userType");
                  props.setSelectedUserType(e);
                  props.setSelectedUserId(null);
                  props.changeCombo("", "userType");
                  props.setListUserId([]);
                }}
              ></Select>
              <p
                className={`${
                  props.valid.userType ? "hidden" : "text-sm text-red pl-1 mb-0"
                }`}
              >
                {t("invoiceCreate.userTypeValidation")}
              </p>
            </div>

            <div className={`flex flex-col gap-1 mt-3`}>
              <label className="md:text-base text-sm text-thirdy leading-5 font-medium required">
                {t("invoiceCreate.userId")}
              </label>{" "}
              <div className="grid grid-cols-4 gap-3">
                <div className="col-span-3">
                  <input
                    value={props.noId}
                    type="text"
                    onChange={(e) => {
                      props.setNoId(e.target.value);
                      props.setErrorNoId("");
                    }}
                    onKeyPress={onlyAlphaNum}
                    onKeyDown={(e) => {
                      e.key === "Enter" &&
                        props.checkMember(
                          props.selectedUserType.value === "MEMBER"
                        );
                    }}
                    placeholder={t("invoiceCreate.userIdPlaceholder")}
                    className="w-full bg-white border-[#9A9999] rounded-[6px] md:p-[1rem] p-[12px] border-[0.5px] border-solid focus:outline outline-[#9A9999]"
                  />
                </div>
                <div className="col-span-1">
                  <button
                    type="button"
                    onClick={() =>
                      props.checkMember(
                        props.selectedUserType.value === "MEMBER"
                      )
                    }
                    className="rounded-xl bg-[#FFFFFF] hover:bg-gray-400 hover:text-[#FFFFFF] border-[1px] text-base font-medium w-full h-100"
                    style={{
                      color: props.color.color1,
                      borderColor: props.color.color1,
                    }}
                  >
                    {t("invoiceCreate.checkButton")}
                  </button>
                </div>
                <p
                  className={`${
                    props.valid.userId
                      ? "hidden"
                      : " col-span-4 text-sm text-red pl-1 mb-0"
                  }`}
                >
                  {t("invoiceCreate.userIdRequired")}
                </p>
                <div
                  className={`${
                    props.targetMember?.username
                      ? "flex flex-row col-span-4 items-center gap-3 bg-[#9a99991a] p-2 rounded-md my-3"
                      : "hidden"
                  }`}
                >
                  <img
                    className="w-20 h-20 rounded-full"
                    src={
                      props.targetMember?.profileImage != null
                        ? props.targetMember?.profileImage
                        : UserDefault
                    }
                    alt=""
                  />
                  <div>
                    <h6 className="md:text-base text-sm text-black font-medium mb-0 capitalize">
                      {props.targetMember?.name}
                    </h6>
                    <p className="md:text-sm text-xs text-[#9A9999] mb-0">
                      {props.targetMember?.phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`flex flex-col gap-1 mt-3`}>
              <label className="md:text-base text-sm text-thirdy leading-5 font-medium required">
                {t("invoiceCreate.invoiceDate")}
              </label>
              <input
                type="date"
                className="rounded-[6px] md:px-[1rem] px-[12px] h-11 border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                value={
                  props.dataPost?.invoiceDate === null
                    ? ""
                    : props.dataPost?.invoiceDate
                }
                onChange={(e) => {
                  props.changeData(e.target.value, "invoiceDate");
                  props.changeValid("invoiceDate");
                }}
                placeholder={t("invoiceCreate.invoiceDatePlaceholder")}
              />
              <p
                className={`${
                  props.valid.invoiceDate
                    ? "hidden"
                    : "text-sm text-red pl-1 mb-0"
                }`}
              >
                {t("invoiceCreate.invoiceDateRequired")}
              </p>
            </div>

            <div className={`flex flex-col gap-1 mt-3`}>
              <label className="md:text-base text-sm text-thirdy leading-5 font-medium required">
                {t("invoiceCreate.dueDate")}
              </label>
              <input
                type="date"
                className="rounded-[6px] md:px-[1rem] px-[12px] h-11 border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                value={
                  props.dataPost?.expiredDate === null
                    ? ""
                    : props.dataPost?.expiredDate
                }
                onChange={(e) => {
                  props.changeData(e.target.value, "expiredDate");
                  props.changeValid("expiredDate");
                }}
                placeholder={t("invoiceCreate.dueDatePlaceholder")}
              />
              <p
                className={`${
                  props.valid.expiredDate
                    ? "hidden"
                    : "text-sm text-red pl-1 mb-0"
                }`}
              >
                {t("invoiceCreate.dueDateRequired")}
              </p>
            </div>

            <div className={`flex flex-col gap-2 mt-3`}>
              <label className="md:text-base text-sm text-thirdy leading-5 font-medium mb-1 required">
                {t("invoiceCreate.invoiceItems")}
              </label>
              {props.dataDetails.length > 0 ? (
                <div className="flex flex-col gap-2">
                  {_.map(props.dataDetails, (el, key) => (
                    <div
                      onClick={() => {
                        props.setDetailIndex(key);
                        props.setToggleSetting(true);
                        props.setDataDetail(el);
                      }}
                      className="bg-blue-gray-50 rounded-md text-[#252525] border-solid border-[.5px] border-thirdy cursor-pointer"
                    >
                      <div className="flex justify-between rounded-t-md px-3 py-2 border-solid border-b-[.5px] border-thirdy text-sm font-medium bg-orange-100">
                        <span>{el.name}</span>
                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                      </div>
                      <div className="flex justify-between px-3 py-2 border-solid border-b-[.5px] border-thirdy text-sm font-normal ">
                        <span>{t("invoiceCreate.itemAmount")}</span>
                        <span>{formatCurrency(el.amount)}</span>
                      </div>
                      <div className="flex justify-between px-3 py-2 border-solid border-b-[.5px] border-thirdy text-sm font-normal ">
                        <span>{t("invoiceCreate.itemDiscount")}</span>
                        <span>{formatCurrency(el.discount)}</span>
                      </div>
                      <div className="flex justify-between px-3 py-2 text-sm font-normal ">
                        <span>Total</span>
                        <span>{formatCurrency(props.countTotal(el))}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              {props.dataDetails.length > 0 ? (
                <div className="bg-[#DFDFDF] rounded-md text-[#9A9999] flex justify-between px-3 py-2">
                  <span>{t("invoiceCreate.totalAmount")} </span>
                  <span>{formatCurrency(props.sumTotal())}</span>
                </div>
              ) : null}
              <button
                onClick={() => {
                  props.setDataDetail({
                    name: "",
                    amount: "0",
                    discount: "0",
                  });
                  props.setValidDetail({
                    name: true,
                    amount: true,
                    discount: true,
                  });
                  props.setToggleSetting(true);
                  props.setDetailIndex(-1);
                }}
                type="button"
                className="bg-white border-[.5px] border-solid w-full h-10 rounded-md"
                style={{
                  color: props.color.color1,
                  borderColor: props.color.color1,
                }}
              >
                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                {t("invoiceCreate.addItem")}
              </button>
              <p
                className={`${
                  props.valid.item_details
                    ? "hidden"
                    : "text-sm text-red pl-1 mb-0"
                }`}
              >
                {t("invoiceCreate.itemsRequired")}
              </p>
            </div>

            <div className={`flex flex-col gap-1 mt-3`}>
              <label className="md:text-base text-sm text-thirdy leading-5 font-medium">
                {t("invoiceCreate.tags")}
              </label>
              <TagsInput
                focusedClassName="tag-input-focused"
                value={props.tags}
                onChange={props.handleTags}
                tagProps={{
                  className: "react-tagsinput-tag bg-info text-white rounded ",
                }}
              ></TagsInput>
              <small className="text-thirdy ">
                {t("invoiceCreate.tagsPlaceholder")}
              </small>
              <p
                className={`${
                  props.valid.tags ? "hidden" : "text-sm text-red pl-1 mb-0"
                }`}
              >
                {t("invoiceCreate.tagsRequired")}
              </p>
            </div>

            <div className={`flex flex-col gap-1 mt-3`}>
              <label className="md:text-base text-sm text-thirdy leading-5 font-medium">
                {t("invoiceCreate.description")}
              </label>
              <input
                type="textarea"
                className="rounded-[6px] md:px-[1rem] px-[12px] h-11 border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                value={
                  props.dataPost?.description === null
                    ? ""
                    : props.dataPost?.description
                }
                onChange={(e) => {
                  props.changeData(e.target.value, "description");
                  props.changeValid("description");
                }}
                placeholder={t("invoiceCreate.descriptionPlaceholder")}
              />
              <p
                className={`${
                  props.valid.description
                    ? "hidden"
                    : "text-sm text-red pl-1 mb-0"
                }`}
              >
                {t("invoiceCreate.descriptionRequired")}
              </p>
            </div>

            <div className={`flex gap-4 items-center mt-3`}>
              <input
                type="checkbox"
                name="allowPartialPayment"
                id="allowPartialPayment"
                className="rounded-[6px] md:px-[1rem] px-[12px] w-5 h-5 border-solid border-[1px] focus:outline-none focus:border-themePrimary border-[#7775]"
                checked={props.allowPartialPayment}
                onChange={(e) => {
                  props.setAllowPartialPayment(e.target.checked);
                }}
              />
              <label
                htmlFor="allowPartialPayment"
                className="md:text-base text-sm text-thirdy leading-5 font-medium"
              >
                <div>{t("invoiceCreate.partialPayment")}</div>
                <div className="text-xs">
                  {t("invoiceCreate.partialPaymentDescription")}
                </div>
              </label>
            </div>

            <div className={`flex flex-col gap-1 mt-3`}>
              <button
                onClick={() => {
                  props.postData();
                }}
                type="button"
                className=" text-white w-full h-10 rounded-md"
                style={{ backgroundColor: props.color.color1 }}
              >
                {t("invoiceCreate.saveButton")}
              </button>
            </div>
          </div>
        </div>

        {/* Modal switch */}
        <div
          className={`${
            props.toggleSetting ? "block" : "hidden"
          } relative z-50`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[50%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
                <div className="text-end md:px-[0px] px-[0.5rem]">
                  <button
                    type="button"
                    onClick={() => {
                      props.setToggleSetting(false);
                    }}
                    className="fa-xl text-gray-400"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>

                <p className="font-medium text-xl">
                  {props.detailIndex === -1
                    ? t("invoiceCreate.addItem")
                    : t("invoiceCreate.editItem")}
                </p>
                <div className="text-left mb-2">
                  <div className="px-3 py-4 flex flex-col gap-2 ">
                    <div className={`flex flex-col gap-1 mt-3`}>
                      <label className="text-sm text-[#252525] font-medium required">
                        {t("invoiceCreate.itemName")}
                      </label>
                      <input
                        type="text"
                        className="rounded-[6px] md:p-[1rem] p-[12px] border-solid border-[1px] px-[12px] h-11 focus:outline outline-[3px] border-[#9A9999] custom-input"
                        value={
                          props.dataDetail?.name === null
                            ? ""
                            : props.dataDetail?.name
                        }
                        style={{ "--dynamic-color": props.color.color1 } as any}
                        onChange={(e) => {
                          props.changeDataDetail(e.target.value, "name");
                          props.changeValidDetail("name");
                        }}
                        placeholder={t("invoiceCreate.itemNamePlaceholder")}
                      />
                      <p
                        className={`${
                          props?.props?.validDetail.name
                            ? "hidden"
                            : "text-sm text-red pl-1 mb-0"
                        }`}
                      >
                        {t("invoiceCreate.nameItemsRequired")}
                      </p>
                    </div>
                    <div className={`flex flex-col gap-1 mt-3`}>
                      <label className="text-sm text-[#252525] font-medium required">
                        {t("invoiceCreate.itemAmount")}
                      </label>
                      <CurrencyInput
                        className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full px-[12px] h-11 md:text-base text-sm rounded-md focus:outline outline-[3px] custom-input"
                        defaultValue=""
                        value={
                          props.dataDetail?.amount === null
                            ? ""
                            : props.dataDetail?.amount
                        }
                        prefix={"Rp"}
                        onValueChange={(value) => {
                          props.changeDataDetail(value, "amount");
                          props.changeValidDetail("amount");
                        }}
                        onKeyPress={onlyNumber}
                        placeholder={t("invoiceCreate.itemAmountPlaceholder")}
                        style={{ "--dynamic-color": props.color.color1 } as any}
                      />
                      <p
                        className={`${
                          props?.validDetail.amount
                            ? "hidden"
                            : "text-sm text-red pl-1 mb-0"
                        }`}
                      >
                        {t("invoiceCreate.amountItemsRequired")}
                      </p>
                    </div>
                    <div className={`flex flex-col gap-1 mt-3`}>
                      <label className="text-sm text-[#252525] font-medium">
                        {t("invoiceCreate.itemDiscount")}
                      </label>
                      <CurrencyInput
                        className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full px-[12px] h-11 md:text-base text-sm rounded-md focus:outline outline-[3px] custom-input"
                        defaultValue=""
                        value={
                          props.dataDetail?.discount === null
                            ? ""
                            : props.dataDetail?.discount
                        }
                        prefix={"Rp"}
                        onValueChange={(value) => {
                          props.changeDataDetail(value, "discount");
                          props.changeValidDetail("discount");
                        }}
                        onKeyPress={onlyNumber}
                        placeholder={t("invoiceCreate.itemDiscountPlaceholder")}
                        style={{ "--dynamic-color": props.color.color1 } as any}
                      />
                      <p
                        className={`${
                          props?.validDetail.discount
                            ? "hidden"
                            : "text-sm text-red pl-1 mb-0"
                        }`}
                      >
                        {t("invoiceCreate.discountItemsRequired")}
                      </p>
                    </div>
                  </div>

                  <div className="px-3 py-4 flex flex-col gap-4 ">
                    <div className="flex flex-col gap-2 mt-4">
                      <button
                        onClick={() => {
                          props.handleDetail();
                        }}
                        type="button"
                        className=" text-white w-full h-10 rounded-md"
                        style={{ backgroundColor: props.color.color1 }}
                      >
                        {props.detailIndex === -1
                          ? t("invoiceCreate.addItem")
                          : t("invoiceCreate.editItem")}
                      </button>
                      {props.detailIndex === -1 ? null : (
                        <button
                          onClick={() => {
                            props.handleDelete();
                          }}
                          type="button"
                          className="bg-gradient-to-b from-[#c81616] to-[#a40404] text-white w-full h-10 rounded-md"
                        >
                          {t("invoiceCreate.deleteItem")}
                        </button>
                      )}
                      <button
                        onClick={() => {
                          props.setDataDetail({
                            name: "",
                            amount: "0",
                            discount: "0",
                          });
                          props.setValidDetail({
                            name: true,
                            amount: true,
                            discount: true,
                          });
                          props.setToggleSetting(false);
                          props.setDetailIndex(-1);
                        }}
                        type="button"
                        className="border-1 border-themeMuted bg-white text-themeMuted w-full h-10 rounded-md"
                      >
                        {t("invoiceCreate.backButton")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
      </section>
    </>
  );
};

export default InvoiceCreateWeb;
