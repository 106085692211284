import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TermOfServiceWeb from "./TermOfServiceWeb";
import TermOfServiceMobile from "./TermOfServiceMobile";

const TermOfService = () => {
  return (
    <>
      <BrowserView>
        <TermOfServiceWeb></TermOfServiceWeb>
      </BrowserView>
      <MobileView>
        <TermOfServiceMobile></TermOfServiceMobile>
      </MobileView>
    </>
  );
};

export default TermOfService;
