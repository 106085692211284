import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ViewBarcodeWeb from "./viewBarcodeWeb";
import ViewBarcodeMobile from "./viewBarcodeMobile";

const ViewBarcode = () => {
  return (
    <>
      <BrowserView>
        <ViewBarcodeWeb></ViewBarcodeWeb>
      </BrowserView>
      <MobileView>
        <ViewBarcodeMobile></ViewBarcodeMobile>
      </MobileView>
    </>
  );
};

export default ViewBarcode;
