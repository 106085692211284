import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPascabayarSuccessWeb from "./ppobPascabayarSuccessWeb";
import PPOBPascabayarSuccessMobile from "./ppobPascabayarSuccessMobile";

const PPOBPascabayarSuccess = () => {
  return (
    <>
      <BrowserView>
        <PPOBPascabayarSuccessWeb></PPOBPascabayarSuccessWeb>
      </BrowserView>
      <MobileView>
        <PPOBPascabayarSuccessMobile></PPOBPascabayarSuccessMobile>
      </MobileView>
    </>
  );
};

export default PPOBPascabayarSuccess;
