import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  formatCurrency,
  formatDate,
  onlyNumber,
} from "../../config/global";
import Header from "../_common/headerComponent";
import CustomTooltip from "../_common/tooltip";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle as faCheckCircleSolid } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input-field";

const InvoicePaymentWeb = (props: any) => {
  return (
    <>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <Header />
        <div className="container flex flex-col justify-center gap-5 md:mt-[64px] mt-[0px] px-4 pb-[64px]">
          <div className="md:w-2/3 sm:w-4/5 w-full mx-auto mb-5">
            <div>
              {!props.inquiry ? (
                <div className="overflow-hidden pb-3">
                  <div className="flex flex-col gap-2">
                    <label className="text-base text-themeDark font-medium pl-1 mb-0">
                      {props.t('invoiceCreate.itemAmount')}
                    </label>
                    <CurrencyInput
                      className="bg-white border-[0.5px] border-[#9A9999] border-solid w-full p-[12px] md:text-base text-sm rounded-xl focus:outline outline-[3px] outline-teal-300/25"
                      defaultValue=""
                      value={props.amountPayment}
                      prefix={"Rp"}
                      disabled={props.isPayAll || !props.isPartial}
                      onValueChange={(value) => {
                        if (parseInt(value || "") >= props.data.final) {
                          props.setAmountPayment(props.data.final.toString());
                        } else {
                          props.setAmountPayment(value || "");
                        }
                      }}
                      onKeyPress={onlyNumber}
                      placeholder={props.t('invoice.placeholder_nominal_pay')}
                    />
                    <div
                      className={`${props.isPayAll && !props.inquiry && props.isPartial
                        ? "text-[#00D19F]"
                        : "text-themeDark"
                        } text-sm  font-normal flex justify-end items-center cursor-pointer`}
                    >
                      <button
                        type="button"
                        onClick={() => props.isPartial && props.handlePayAll()}
                      >
                        {props.isPayAll || props.isPartial ? (
                          <FontAwesomeIcon
                            icon={faCheckCircleSolid}
                          ></FontAwesomeIcon>
                        ) : (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                          ></FontAwesomeIcon>
                        )}
                        &nbsp;<span>{props.t('invoice.pay_all')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="px-6 overflow-hidden bg-white shadow-sm shadow-gray-600 pb-3 min-h-[300px] my-4 ">
                  <div className=" flex flex-col">
                    <div
                      className="flex  text-xl font-medium border-b-[1px] border-solid border-[#9a999922] py-3"
                      style={{ color: props.color.color1 }}
                    >
                      {props.t('invoice.summary')}
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="mt-3 space-between">
                        <h5 className="text-themeDark font-semibold mb-0 md:text-sm text-xs">
                          {props.t('invoice.name_invoice')}
                        </h5>
                        <CustomTooltip
                          className={"w-fit max-w-[calc(100%-7rem)] "}
                          content={
                            <div
                              className="w-96 whitespace-pre-line"
                              key={props.data?.title}
                            >
                              {props.data?.title}
                            </div>
                          }
                          direction={
                            props.data?.title.length > 50 ? "bottom" : "left"
                          }
                          children={
                            <h6 className="text-themeDark font-light mb-0 md:text-sm text-xs whitespace-nowrap overflow-hidden truncate">
                              {props.data?.title}
                            </h6>
                          }
                        ></CustomTooltip>
                      </div>
                      <div className="mt-3 space-between">
                        <h5 className="text-themeDark font-semibold mb-0 md:text-sm text-xs">
                          {props.t('invoice.member_name')}
                        </h5>
                        <h6 className="text-themeDark font-light mb-0 md:text-sm text-xs">
                          {props.data?.accountReceiverName}
                        </h6>
                      </div>
                      <div className="mt-3 space-between">
                        <h5 className="text-themeDark font-semibold mb-0 md:text-sm text-xs">
                          {props.t('invoice.date_invoice')}
                        </h5>
                        <h6 className="text-themeDark font-light mb-0 md:text-sm text-xs">
                          {props.data?.invoiceDate
                            ? formatDate(props.data?.invoiceDate, "DD/MM/YYYY")
                            : "-"}
                        </h6>
                      </div>
                      <div className="mt-3 space-between">
                        <h5 className="text-themeDark font-semibold mb-0 md:text-sm text-xs">
                          {props.t('invoice.date_due')}
                        </h5>
                        <h6 className="text-themeDark font-light mb-0 md:text-sm text-xs">
                          {props.data?.expiredDate
                            ? formatDate(props.data?.expiredDate, "DD/MM/YYYY")
                            : "-"}
                        </h6>
                      </div>
                      <div className="mt-3 space-between">
                        <h5
                          className="font-semibold mb-0 md:text-sm text-xs"
                          style={{ color: props.color.color1 }}
                        >
                          {props.t('invoice.pay_nominal')}
                        </h5>
                        <h6
                          className="font-semibold mb-0 md:text-sm text-xs"
                          style={{ color: props.color.color1 }}
                        >
                          {formatCurrency(props.amountPayment)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {props.inquiry && props.data?.config?.allowPartialPayment && (
                <div className="my-3 space-between">
                  <h6 className="text-themeDark font-light mb-0 text-[13px]">
                    {`${props.t('invoice.insufficient_payment')} ${formatCurrency(
                      props.data?.final - parseInt(props.amountPayment)
                    )}`}
                  </h6>
                </div>
              )}
              {!props.data?.config?.allowPartialPayment && (
                <div className="bg-themeYellow bg-opacity-10 rounded-[3px] p-3 mt-4">
                  <p className="md:text-sm text-xs font-light text-themeDark mb-0 text-justify">
                    {props.t('invoice.note')}
                  </p>
                </div>
              )}
            </div>
            <div className="w-full flex flex-col-reverse gap-2 mb-4 mt-10">
              {props.inquiry && props.isPartial ? (
                <button
                  onClick={() => props.setInquiry(false)}
                  type="button"
                  className={`border-[1px] border-themePrimary text-themePrimary w-full h-10 rounded-lg font-poppins font-medium`}
                >
                  &nbsp; {props.t('invoice.change_nominal')}
                </button>
              ) : null}{" "}
              <button
                onClick={() => props.handlePayment()}
                type="button"
                className=" text-white w-full h-10 rounded-lg font-poppins font-medium"
                style={{ backgroundColor: props.color.color1 }}
              >
                &nbsp; {props.t('invoice.payNow')}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvoicePaymentWeb;
