import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import NotificationMobile from "./notificationMobile";
import NotificationWeb from "./notifictionWeb";

const Notification = () => {
  return (
    <>
      <BrowserView>
        <NotificationWeb></NotificationWeb>
      </BrowserView>
      <MobileView>
        <NotificationMobile></NotificationMobile>
      </MobileView>
    </>
  );
};

export default Notification;
