import { toast } from "react-hot-toast";
import { BottomSheet } from "react-spring-bottom-sheet";
import arrow from "../../assets/images/arrow_right.svg";
import Logout from "../../assets/images/cta/log-out.svg";
import Password from "../../assets/images/icon/auto login.svg";
import Unlock from "../../assets/images/icon/change pass.svg";
import Users from "../../assets/images/icon/changeakun.svg";
import userIcon from "../../assets/images/icon/editprofil.svg";
import ShieldDone from "../../assets/images/icon/pin.svg";
import UserSupport from "../../assets/images/icon/support.svg";
import Work from "../../assets/images/icon/termofservice.svg";
import Voice from "../../assets/images/icon/ubah bahasa.svg";

import { formatCurrency, goToUrl } from "../../../config/global";

import Header from "../../_common/headerComponent";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import iconSearch from "../../../assets/images/icon_rent/search-normal.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Dummy from "../dummy";
import _ from "lodash";
import locationIcon from "../../../assets/images/icon_rent/location.svg";
import Active from "../../../assets/images/icon_rent/active.svg";
import NoActive from "../../../assets/images/icon_rent/noActive.svg";

import Round from "../../assets/images/icon_rent/round.svg";
import { Fragment, useEffect, useState } from "react";
import DetailProduct from "./DetailProduct";
import Company from "../../../core/models/company";
import { useAppSelector } from "../../../core/feature/hooks";

const RentDetailWeb = (props: any) => {
  const [enabled, setEnabled] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [venue, setVenue] = useState("");
  const company: Company = useAppSelector((state) => state.company.company);
  const params = useParams();
  const location = useLocation();
  const [data, setData] = useState<any>("");

  const handleClick = () => {
    navigate(`/${company.initial}/rent/booking`, {
      state: { data: data, venue: venue },
    });
  };

  useEffect(() => {
    setData(location.state.data);
  }, []);
  return (
    <>
      <section className="h-full min-h-screen font-montserrat bg-mobile pb-12">
        <Header navbarText="Menu Sewa"></Header>
        <div className="px-6 lg:w-[40%] md:w-[70%] mx-auto">
          {/* <div
            className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-300 ease-in-out ${
              enabled ? "bg-[#3867E0] bg-opacity-60" : "bg-gray-300"
            }`}
            onClick={() => setEnabled(!enabled)}
          >
            <span
              className={`inline-block w-4 h-4 transform rounded-full transition-transform duration-300 ease-in-out ${
                enabled ? "translate-x-6 bg-[#3867E0]" : "translate-x-1 bg-white "
              }`}
            />
          </div> */}
          <div className="mt-4">
            <div className="mt-2">
              <div className="space-y-2">
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Pagination, Navigation]}
                  className="rounded-[10px] bg-white"
                >
                  {data.image?.map((item: any, index: any) => (
                    <SwiperSlide key={index}>
                      <div
                        className=" h-[290px] bg-center bg-no-repeat bg-cover w-full"
                        style={{ backgroundImage: `url(${item})` }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="flex justify-between">
                  <span className="font-[500] text-base">{data.name}</span>
                  <div className="font-[500] text-base">
                    {formatCurrency(data.price)}
                  </div>
                </div>
                <div className="flex gap-2">
                  <img src={locationIcon} alt="" />
                  <span className="text-[#A8A8A8]">{data.address}</span>
                </div>
                <div className="mt-4">
                  <DetailProduct data={data} />
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="fixed bottom-0 left-0 right-0 shadow p-4 z-5 bg-white">
              <button
                className="bg-[#3867E0] font-[500] text-white w-full p-2 rounded-[8px]"
                onClick={() => setShow(true)}
              >
                Pesan Sekarang
              </button>
            </div>

            <BottomSheet
              onDismiss={() => setShow(!show)}
              open={show}
              className="z-50 fixed inset-0 font-montserrat"
            >
              <div className="flex flex-col m-3">
                <Fragment>
                  <span className="font-[500] text-[20px] ">
                    Pilih Venue Lapangan{" "}
                  </span>
                  <div className="my-4">
                    {_.map(data.tags, (el: any, index: any) => (
                      <div
                        key={index}
                        className="flex items-center gap-4 mb-4 cursor-pointer"
                        onClick={() => setVenue(el)}
                      >
                        <img src={venue === el ? Active : NoActive} alt="" />
                        <span className="text-base">{el}</span>
                      </div>
                    ))}
                  </div>
                  <div className="">
                    <button
                      className="bg-[#3867E0] font-[500] text-white w-full p-2 rounded-[8px]"
                      onClick={handleClick}
                    >
                      Simpan Perubahan
                    </button>
                  </div>
                </Fragment>
              </div>
            </BottomSheet>
          </div>
        </div>
      </section>
    </>
  );
};

export default RentDetailWeb;
