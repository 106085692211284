import { BrowserView, MobileView } from "react-device-detect";
import AccessFeaturesWeb from "./accessFeaturesWeb";
import AccessFeaturesMobile from "./accessFeturesMobile";

const AccessFeature = () => {
  return (
    <>
      <BrowserView>
        <AccessFeaturesWeb />
      </BrowserView>
      <MobileView>
        <AccessFeaturesMobile />
      </MobileView>
    </>
  );
};

export default AccessFeature;
