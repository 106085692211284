import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";
import { decryptedValue, encryptedValue } from "../../../config/Encription";


type InitialState = {
  fireBaseToken: string
}
const type = 'firebaseToken'

function loadFirebaseToken (){
  let ciperText= loadState(type)
  let decrypted= decryptedValue(ciperText)
  return decrypted
 }


const initialState: InitialState = {
  fireBaseToken: loadFirebaseToken()
}

export const fireBaseTokenSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setFireBaseToken: (state, action: PayloadAction<string>) => {
      state.fireBaseToken = action.payload;
      let ciperText = encryptedValue(action.payload)
      saveState(type, ciperText)
    },
    removeFireBaseToken: (state) => {
      state.fireBaseToken = "";
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFireBaseToken, removeFireBaseToken } = fireBaseTokenSlice.actions;

export default fireBaseTokenSlice.reducer;
