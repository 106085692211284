import { useLocation, useNavigate } from "react-router-dom";
import virtualCardNotFound from "../../../assets/images/vcard_not_found.png";
import Header from "../../_common/headerComponent";
import { toast } from "react-hot-toast";
import userDefault from "../../../assets/images/card_avatar.png";
import { useTranslation } from "react-i18next";

const ListVirtualCardMobile = (props: any) => {
  const location: any = useLocation();
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen flex flex-col justify-between font-montserrat bg-mobile">
      {location.state !== null ? (
        <Header navbarText={t('virtual_card.card_virtual')} navigateTo={location.state} />
      ) : (
        <Header navbarText={t('virtual_card.card_virtual')} />
      )}
      {props.data.length > 0 ? (
        <div className="md:w-1/2 w-full mx-auto mb-20">
          <div className="md:container mx-auto flex flex-col items-center md:mt-[64px] mt-[0px] gap-2 md:px-[0px] px-9 py-7 md:bg-white">
            {props.data.map((item: any) => (
              <div
                key={item.cardId}
                className="md:w-2/3 lg:w-1/2 w-full md:mb-10 mb-8"
              >
                <div className="flex items-center cursor-pointer">
                  <div className="relative w-full bg-transparent rounded-2xl">
                    {props.cards.find((el: any) => el._id === item.cardId)
                      ?.isBlocked && (
                        <div
                          className="bg-[#E5E5E5] opacity-90 w-full h-full z-0 top-1/2 left-1/2 absolute mb-4"
                          style={{ transform: "translate(-50%, -50%)" }}
                        />
                      )}
                    {/* <div className="w-full h-full ">
                      <iframe
                        className={
                          props.isPortrait
                            ? `w-[60%] mx-auto overflow-hidden rounded-2xl h-[350px] bg-cover shadow-xl`
                            : `w-full overflow-hidden rounded-2xl h-56 bg-cover shadow-xl`
                        }
                        style={{ backgroundImage: `url(${props.background})` }}
                        scrolling="no"
                        srcDoc={item.template.replace(
                          "src=-",
                          `src=${userDefault}`
                        )}
                      ></iframe>
                    </div> */}
                    <div className="w-full h-full ">
                      <iframe
                        className={
                          props.isPortrait
                            ? `w-60 mx-auto overflow-hidden rounded-2xl h-[400px] image-cover-style shadow-xl`
                            : `w-full overflow-hidden rounded-2xl h-64 image-cover-style shadow-xl pl-2`
                        }
                        style={{ backgroundImage: `url(${props.background})` }}
                        scrolling="no"
                        srcDoc={item.template.replace(
                          "src=-",
                          `src=${userDefault}`
                        )}
                      ></iframe>
                    </div>
                    <div
                      onClick={() => {
                        let findData = props.cards.find(
                          (el: any) => el._id === item.cardId
                        );
                        if (findData !== undefined) {
                          if (findData.isBlocked) {
                            toast.error(
                              t('virtual_card.toast_card_blok')
                            );
                          } else {
                            navigate(
                              `/${props.company.initial}/virtual-card/list/${item.cardId}`
                            );
                          }
                        }
                      }}
                      className="bg-transparent w-full h-full z-0 top-1/2 left-1/2 absolute mb-4 rounded-2xl"
                      style={{ transform: "translate(-50%, -50%)" }}
                    />
                    {/* <img
                      src={props.background}
                      alt=""
                      className="rounded-xl min-h-[25vh]"
                    />
                    <span
                      className="absolute overflow-hidden rounded-xl h-[95%] pl-16 w-[85%] left-0 top-0 bg-transparent"
                      dangerouslySetInnerHTML={{
                        __html: item.template.replace(
                          "src=-",
                          `src=${userDefault}`
                        ),
                      }}
                    ></span> */}
                  </div>
                </div>
              </div>
            ))}
            {props.customButton.isActived !== undefined ? (
              <div
                className="md:container py-7 fixed bottom-0 flex flex-col gap-[20px] bg-white justify-end shadow-button"
                style={{ width: "50%" }}
              >
                {props.data.length !== props.cardLimit &&
                  props.data.length < props.cardLimit ? (
                  <button
                    type="button"
                    style={{
                      backgroundColor: `${props.company?.color?.color1 !== undefined
                        ? props.company?.color?.color1
                        : "#26A69A"
                        }`,
                    }}
                    onClick={props.handleOnClick}
                    className="bg-green text-white mx-6 md:py-[12px] py-[8px] font-medium rounded-[10px]"
                  >
                    {t('virtual_card.add_card_virtual')}
                  </button>
                ) : props.cardLimit === null ? (
                  <button
                    type="button"
                    style={{
                      backgroundColor: `${props.company?.color?.color1 !== undefined
                        ? props.company?.color?.color1
                        : "#26A69A"
                        }`,
                    }}
                    onClick={props.handleOnClick}
                    className="bg-green text-white mx-6 md:py-[12px] py-[8px] font-medium rounded-[10px]"
                  >
                    {t('virtual_card.add_card_virtual')}
                  </button>
                ) : null}
                <button
                  type="button"
                  style={{
                    borderColor: `${props.company?.color?.color1 !== undefined
                      ? props.company?.color?.color1
                      : "#728F9E"
                      }`,
                    color: `${props.company?.color?.color1 !== undefined
                      ? props.company?.color?.color1
                      : "#728F9E"
                      }`,
                  }}
                  onClick={() => window.open(props.customButton.redirectLink)}
                  className="border-[#728F9E] border-[1px] text-newColor mx-6 md:py-[12px] py-[8px] font-medium rounded-[10px]"
                >
                  {props.customButton.buttonName}
                </button>
              </div>
            ) : props.data.length !== props.cardLimit &&
              props.data.length < props.cardLimit ? (
              <div className="md:container py-7 fixed bottom-0 flex flex-col gap-[20px] bg-white justify-end shadow-button w-full">
                <button
                  type="button"
                  style={{
                    backgroundColor: `${props.company?.color?.color1 !== undefined
                      ? props.company?.color?.color1
                      : "#26A69A"
                      }`,
                  }}
                  onClick={props.handleOnClick}
                  className="bg-green text-white mx-6 md:py-[12px] py-[8px] font-medium rounded-[10px]"
                >
                  {t('virtual_card.add_card_virtual')}
                </button>
              </div>
            ) : props.cardLimit === null ? (
              <div className="md:container py-7 fixed bottom-0 flex flex-col gap-[20px] bg-white justify-end shadow-button w-full">
                <button
                  type="button"
                  style={{
                    backgroundColor: `${props.company?.color?.color1 !== undefined
                      ? props.company?.color?.color1
                      : "#26A69A"
                      }`,
                  }}
                  onClick={props.handleOnClick}
                  className="bg-green text-white mx-6 md:py-[12px] py-[8px] font-medium rounded-[10px]"
                >
                  {t('virtual_card.add_card_virtual')}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="container flex md:flex-row flex-col-reverse justify-center gap-4 md:mt-[64px] mt-[0px] px-4">
          <div className="md:w-1/2 w-full">
            <img
              src={virtualCardNotFound}
              alt=""
              className="md:w-[300px] w-[200px] mx-auto"
            />
            <h3 className="md:text-xl text-base text-center font-medium mb-4 mt-[20px]">
              {t('virtual_card.you_not_have_card')}
              <br />
              {t('virtual_card.add_now')}
            </h3>
          </div>
        </div>
      )}
      <div className="md:w-1/2 w-full mx-auto p-4">
        {props.data.length === 0 && (
          <button
            type="button"
            style={{
              backgroundColor: `${props.company?.color?.color1 !== undefined
                ? props.company?.color?.color1
                : "#26A69A"
                }`,
            }}
            onClick={() =>
              navigate(`/${props.company.initial}/virtual-card/add`)
            }
            className="bg-green text-white font-bold rounded-lg w-100 md:p-3 p-[12px]"
          >
            {t('virtual_card.create_card_virtual')}
          </button>
        )}
      </div>
    </section>
  );
};

export default ListVirtualCardMobile;
