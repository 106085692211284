import React, { useEffect, useState } from "react";
import * as API_USER from "../../core/service/api_user";
import {} from "../../core/feature/store";
import { useAppDispatch } from "../../core/feature/hooks";
import {
  // removeCompany,
  setCompany,
} from "../../core/feature/company/companySlice";
import closepayLogo from "../../assets/images/solusinegeri-logo.svg";
import emptyImg from "../../assets/images/empty-img.svg";

// import Company from "../../core/models/company";
import {
  setIsEverChooseCompany,
  setLoading,
} from "../../core/feature/config/configSlice";
import { useNavigate } from "react-router-dom";
import desktopBackground from "../../assets/images/desktopBgBlur.svg";

const ChangeCompanyWeb = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  const [companyInput, setCompanyInput] = useState("");
  const [companyData, setCompanyData] = useState<any>({});
  const [showCompany, setShowCompany] = useState(false);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const checkCompany = async () => {
    dispatch(setLoading(true));
    await API_USER.getCompany(companyInput)
      .then((res) => {
        console.log(res.data.data, "tes");
        dispatch(setLoading(false));
        setCompanyData(res.data.data);
        setShowCompany(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setCompanyData({});
        setShowCompany(false);
      });
  };

  const confirmCompany = async () => {
    dispatch(setCompany(companyData));
    console.log(companyData);
    navigate(`/${companyData?.initial}`, { replace: true });
  };

  useEffect(() => {
    dispatch(setIsEverChooseCompany(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="h-full min-h-screen bg-second dark:bg-black font-poppins flex flex-col justify-center items-center bg-cover"
        style={{ backgroundImage: `url(${desktopBackground})` }}
      >
        <div className="w-[40%] m-auto">
          <div className="mb-16">
            <img src={closepayLogo} alt="icon" className="w-[489px] mx-auto" />
          </div>
          <div className="mt-10 mb-6">
            <h4 className="text-left text-3xl font-semibold">Pilih Instansi</h4>
            <p className="text-left text-neutral-500 text-md">
              Silahkan masukkan kode instansi anda
            </p>
          </div>
          <div className="relative">
            <div className="flex flex-row gap-2">
              <input
                type="text"
                onChange={(e) => setCompanyInput(e.target.value)}
                onKeyDown={(e) => {
                  e.key === "Enter" && checkCompany();
                }}
                className="grow p-3 focus:border-1 rounded-full border-1 border-solid border-neutral-200 focus:outline outline-[3px] outline-teal-300/25"
              />
              <button
                onClick={() => checkCompany()}
                className="grow-0 bg-[#26A69A] hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:bg-emerald-500 focus:ring-opacity-75 rounded-full w-25 text-white"
              >
                Cari
              </button>
            </div>
          </div>
          <div hidden={!showCompany} className="mt-6">
            <h1 className="font-medium text-base text-thirdy">
              Instansi yang sesuai
            </h1>
            <div className="flex flex-row gap-4 items-center mt-3">
              {companyData.companyLogo !== null ? (
                <img src={companyData.companyLogo} alt="" className="h-16" />
              ) : (
                <img src={emptyImg} alt="" className="h-16" />
              )}
              <h1 className="m-0 font-normal text-sm italic text-thirdy">
                {companyData.name}
              </h1>
            </div>
            <button
              onClick={() => confirmCompany()}
              className="mt-8 w-full bg-[#26A69A] hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:bg-emerald-500 focus:ring-opacity-75  rounded-full p-3 text-white"
            >
              Konfirmasi
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeCompanyWeb;
