import React, { useEffect, useState } from "react";
import Header from "../_common/headerComponent";
import { useLocation } from "react-router-dom";
import bg from "../../assets/images/icon_features/bg_detail.svg";
import late from "../../assets/images/icon_features/bg_failed.svg";
import BottomLogo from "../_common/bottomLogo";
import moment from "moment";
import { ConfigColor } from "../../config/global";
import Company from "../../core/models/company";
import { useAppSelector } from "../../core/feature/hooks";

const AccessFeatureDetailWeb = () => {
  const color = ConfigColor();
  // const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  let location: any = useLocation();
  const company: Company = useAppSelector((state) => state.company.company);

  useEffect(() => {
    setData(location.state?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-mobile min-h-screen">
      <Header />
      <div className="w-full font-montserrat container">
        <div className="w-1/2 mx-auto bg-mobile px-4">
          <div className="mb-5">
            <div
              className="rounded-xl p-2 text-white flex flex-col relative gap-0.5 mt-10 bg-cover mx-5 shadow"
              style={{
                backgroundImage:
                  data?.status === "TERLAMBAT" ? `url(${late})` : `url(${bg})`,
                backgroundColor: color.color1,
              }}
            >
              <div className="flex flex-col text-center">
                <p className="font-bold text-xl mt-3">{data?.member?.name}</p>
                <div className="flex flex-row mt-4 mb-3">
                  <div className="w-1/2 flex flex-col">
                    <span className="font-bold">ID</span>
                    <span className="text-center">{data?.member?.noId}</span>
                  </div>
                  <div className="w-1/2 flex flex-col">
                    <span className="font-bold">Device</span>
                    <span className="text-center">
                      {data?.device?.deviceCode}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-4">
            <div className="flex justify-between mb-2">
              <span>Waktu absen</span>
              <span className="text-gray-700">
                {moment(data?.entranceTime).format("DD-MM-YYYY")},{" "}
                {moment(data?.entranceTime).format("hh:mm:ss")} WIB
              </span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Metode Akses</span>
              <span className="text-gray-700">{data?.accessMethod}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Aktivitas</span>
              <span className="text-gray-700">
                {data?.entranceActivity?.name}
              </span>
            </div>
            <div className="mt-4">
              <span>Deskripsi</span>
              <div className="border rounded-xl p-2 text-xs text-gray-600 mt-2">
                {data?.entranceActivity?.description}
              </div>
            </div>
          </div>
        </div>
      </div>
      {company?.showFtpLogo && <BottomLogo />}
    </div>
  );
};

export default AccessFeatureDetailWeb;
