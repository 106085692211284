import Config from "../../config/Config";
import { decryptedValue } from "../../config/Encription";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
import { bearer } from "../../config/global";

const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getConfigMarketplaceName = (query) => {
  let url = `${baseUrlDev}commerce/combo/marketplaceNameConfig`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboCategory = (query) => {
  let url = `${baseUrlDev}commerce/combo/category?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboNote = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/combo/marketplaceConfig`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSummary = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}commerce/member/order/summary`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const requestCheckout = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}commerce/member/order/checkout`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createOrder = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}commerce/member/order`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkout = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}commerce/member/order/checkout`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getListOrderHistories = (page, size, sort, status) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}commerce/member/order?page=${page}&size=${size}&sortBy=${sort}&status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDetailOrderHistories = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/member/order/${id}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDetailOrderHistories = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}commerce/member/order/${id}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllShop = (query) => {
  let url = `${baseUrlDev}commerce/member/shop?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getShopDetail = (id, query) => {
  let url = `${baseUrlDev}commerce/member/shop/${id}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllProducts = (query) => {
  let url = `${baseUrlDev}commerce/member/product?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProductDetail = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/member/product/${id}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getWishlist = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/member/wishlist`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addWishlist = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}commerce/member/wishlist`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// CART
export const getCart = (query) => {
  let url = `${baseUrlDev}commerce/member/cart?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addToCart = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}commerce/member/cart/`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteProductFromCart = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}commerce/member/cart/${id}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteProductFromCart2 = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}commerce/member/cart/delete`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// ADDRESS
export const getAddress = (query) => {
  let url = `${baseUrlDev}commerce/member/address?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateAddress = (addressId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}commerce/member/address/${addressId}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addAddress = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}commerce/member/address`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// SHIPPER CONTROLLER
export const getShip = (merchantId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}commerce/member/ship/merchant/${merchantId}`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProvinces = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/shipper/location/province`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCities = (provinceId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/shipper/location/city/${provinceId}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSuburbs = (cityId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/shipper/location/suburb/${cityId}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAreas = (suburbId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/shipper/location/area/${suburbId}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadDocument = (id, type, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}document/user/render_and_download/${id}/${type}`,
        data,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadOrderPDF = (id, email) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/member/pdf/order/${id}?email=${email}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// No Login Product
let ciperTextCompany = decryptedValue(JSON.parse(localStorage.getItem("company_redux")))
const company = ciperTextCompany

export const categoryNoLogin = (companyId, query) => {
  let url = `${baseUrlDev}commerce/member/product/pub/categories/${companyId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${company?.initial}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const productNoLogin = (companyId, query) => {
  let url = `${baseUrlDev}commerce/member/product/pub/${companyId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${company?.initial}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const marketplaceNameNoLogin = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}commerce/admin/marketplace/config/name/pub?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${company?.initial}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const productDetailNoLogin = (company, id) => {
  let url = `${baseUrlDev}commerce/member/product/pub/${company}/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${company?.initial}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllShopNoLogin = (query) => {
  let url = `${baseUrlDev}commerce/member/shop/pub/${
    company?.id
  }?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${company.initial}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getShopDetailNoLogin = (company, id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/member/shop/pub/${company}/${id}`, {
        headers: {
          Authorization: `Bearer ${company.initial}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboIconCommerce = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}commerce/combo/subMenuIcon`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboMerchantWorkHour = (merchantId, timezone) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}commerce/combo/merchantWorkHour/${merchantId}?timezone=${timezone}`,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cekOperationalTime = (id, query) => {
  let url = `${baseUrlDev}commerce/combo/merchantWorkHour/${id}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${url}
      `,
        {
          headers: {
            Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// FNB
export const getCatalog = (companyId, merchantId, query) => {
  let url = `${baseUrlDev}commerce/member/fnb/pub/${companyId}/${merchantId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${company?.initial}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCatalogDetail = (companyId, merchantId, id) => {
  let url = `${baseUrlDev}commerce/member/fnb/pub/${companyId}/${merchantId}/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${company?.initial}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFnbCategory = (companyId, merchantId, query) => {
  let url = `${baseUrlDev}commerce/member/fnb/category/pub/${companyId}/${merchantId}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${company?.initial}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createOrderFnb = (companyId, merchantId, query) => {
  let url = `${baseUrlDev}commerce/member/fnb/order/pub/${companyId}/${merchantId}`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, query)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkoutFnb = (companyId, transactionId) => {
  let url = `${baseUrlDev}commerce/member/fnb/order/checkout/pub/${companyId}/${transactionId}`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkPaymentResult = (checkoutId) => {
  let url = `${baseUrlDev}balance/guest/checkout/${checkoutId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${url}
      `
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cekStatus = (id) => {
  let url = `${baseUrlDev}commerce/member/fnb/order/pub/${company.id}/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${url}
      `
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const configPayment = (id) => {
  let url = `${baseUrlDev}commerce/member/fnb/payment/config/pub/${company.id}/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${url}
      `
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const confirmOrder = (id, status) => {
  let url = `${baseUrlDev}commerce/member/fnb/order/pub/${company.id}/${id}?status=${status}`;
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${url}
      `
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// operational time

export const cekOperationalTimeNoLogin = (merchantId, timezone) => {
  let url = `${baseUrlDev}commerce/member/shop/merchantWorkHour/pub/${company?.id}/${merchantId}?timezone=${timezone}`;
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${url}
      `
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigOrderReceiver = () => {
  let url = `${baseUrlDev}commerce/combo/orderReceiver/config`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOrderReceiverList = () => {
  let url = `${baseUrlDev}commerce/combo/orderReceiver`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTourProducts = (query) => {
  let url = `${baseUrlDev}commerce/member/tour/product?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTourProductById = (id) => {
  let url = `${baseUrlDev}commerce/member/tour/product/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createOrderTour = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}commerce/member/tour/order`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createOrderTourCheckout = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}commerce/member/tour/order/checkout`, data, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTourOrders = (query) => {
  let url = `${baseUrlDev}commerce/member/tour/order?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTourOrderById = (id) => {
  let url = `${baseUrlDev}commerce/member/tour/order/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cancelTourOrder = (id) => {
  let url = `${baseUrlDev}commerce/member/tour/order/cancel/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .put(`${url}`,"", {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTourMerchant = (query) => {
  let url = `${baseUrlDev}commerce/member/tour/shop?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTourMerchantDetail = (id) => {
  let url = `${baseUrlDev}commerce/member/tour/shop/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${bearer().replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};