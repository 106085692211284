import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBPLNSummaryWeb from "./ppobPLNSummaryWeb";
import PPOBPLNSummaryMobile from "./ppobPLNSummaryMobile";

const PPOBPLNSummary = () => {
  return (
    <>
      <BrowserView>
        <PPOBPLNSummaryWeb></PPOBPLNSummaryWeb>
      </BrowserView>
      <MobileView>
        <PPOBPLNSummaryMobile></PPOBPLNSummaryMobile>
      </MobileView>
    </>
  );
};

export default PPOBPLNSummary;
