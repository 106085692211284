import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import ZakatHistoryWeb from "./zakatHistoryWeb";
import ZakatHistoryMobile from "./zakatHistoryMobile";

const ZakatHistory = () => {
  return (
    <>
      <BrowserView>
        <ZakatHistoryWeb></ZakatHistoryWeb>
      </BrowserView>
      <MobileView>
        <ZakatHistoryMobile></ZakatHistoryMobile>
      </MobileView>
    </>
  );
};

export default ZakatHistory;
