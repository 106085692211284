import Parking from "../components/parkingFeatureComponent";
import ParkingFeaturesDetail from "../components/parkingFeatureDetailComponent";
import ScanQRComponent from "../components/featureScanQRComponent";
import ParkingFeaturesSubcription from "../components/parkingFeatureSubcriptionComponent";
import DetailSubcription from "../components/parkingFeatureSubcriptionComponent/Detail";
import ParkingResult from "../components/parkingResultComponent";

const ParkingRoutes: any = [
  {
    path: ":cid/features/parkir",
    component: Parking,
  },
  {
    path: ":cid/features/parkir/detail",
    component: ParkingFeaturesDetail,
  },
  {
    path: ":cid/features/parkir/scan-qr",
    component: ScanQRComponent,
  },
  {
    path: ":cid/features/parkir/subcription",
    component: ParkingFeaturesSubcription,
  },
  {
    path: ":cid/features/parkir/subcription/detail",
    component: DetailSubcription,
  },
  {
    path: ":cid/features/parkir/result",
    component: ParkingResult,
  },
];
export default ParkingRoutes;
