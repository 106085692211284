import { toast } from "react-hot-toast";
import { BottomSheet } from "react-spring-bottom-sheet";
import arrow from "../../assets/images/arrow_right.svg";
import Logout from "../../assets/images/cta/log-out.svg";
import Password from "../../assets/images/icon/auto login.svg";
import Unlock from "../../assets/images/icon/change pass.svg";
import Users from "../../assets/images/icon/changeakun.svg";
import userIcon from "../../assets/images/icon/editprofil.svg";
import ShieldDone from "../../assets/images/icon/pin.svg";
import UserSupport from "../../assets/images/icon/support.svg";
import Work from "../../assets/images/icon/termofservice.svg";
import Voice from "../../assets/images/icon/ubah bahasa.svg";
import ToastConfig from "../../config/Toast";
import { goToUrl } from "../../config/global";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company";
import Header from "../_common/headerComponent";
import CustomTooltip from "../_common/tooltip";
import SwitchAccount from "./switchAccount";
import BottomNavigator from "../_common/bottomNavigator";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProfileMobile = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng') || 'id';

  const getDisplayText = (res: any) => {
    if (language === 'id') {
      return res.display || t(`profile.${res.name}`);
    } else if (language === 'en') {
      return res.displayEn || t(`profile.${res.name}`);
    } else {
      return t(`profile.${res.name}`);
    }
  };

  return (
    <>
      <section className="h-full min-h-screen font-montserrat bg-mobile pb-12">
        <Header navbarText="Profil"></Header>

        <div className="container flex flex-col gap-3 justify-center lg:mt-[64px] md:mt-[30px] mt-[0px] pb-5 px-4">
          <div
            className="relative bg-white h-100 rounded-xl flex flex-col lg:w-1/2 w-full mt-2 mx-auto"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="relative bg-white space-between items-center flex rounded-3xl md:p-8 py-[10px] px-[10px] bg-no-repeat z-0">
              <div className="items-center flex flex-row gap-1 z-10 relative">
                <div className=" items-center h-[58px] w-[58px] min-w-[58px]">
                  <img
                    src={props.getProfileImage}
                    alt=""
                    className="rounded-full h-[58px] w-[58px] object-cover bg-gray-50"
                  />
                </div>
                <div className=" p-1 flex flex-col gap-0">
                  <CustomTooltip
                    content={
                      <div className="w-96 whitespace-pre-line">
                        {props.profileData?.name}
                      </div>
                    }
                    direction="bottom"
                    children={
                      <p className="md:text-2xl text-base font-semibold m-0 p-0 capitalize text-[#1A1A1A]">
                        {props.profileData?.name &&
                          props.profileData?.name?.length > 40
                          ? props.profileData?.name.substr(0, 40) + "..."
                          : props.profileData?.name}
                      </p>
                    }
                  ></CustomTooltip>
                  <p className="text-[#90929F] md:text-lg text-xs font-medium m-0 p-0">
                    {props.profileData?.email}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-xl lg:w-1/2 w-full md:mt-[1.5rem] mt-0 mx-auto">
            <div className="flex flex-col gap-3">
              <div className=" py-3 rounded-xl items-center gap-1">
                {props.nameMenu.map((res: any) => {
                  if (res.name === "update_profile") {
                    return (
                      <div>
                        <div
                          onClick={() =>
                            navigate(`/${company?.initial}/profile/edit`)
                          }
                          className="flex flex-row items-center cursor-pointer gap-4 h-14"
                        >
                          <img
                            className="grow-0 w-[30px] h-[30px]"
                            src={userIcon}
                            alt=""
                          />
                          <h1 className="grow font-normal text-[#252525] md:text-base text-sm m-0  font-montserrat">
                            {getDisplayText(res)}
                          </h1>
                          <img
                            className="grow-0 h-3 w-4 mr-2"
                            src={arrow}
                            alt=""
                          />
                        </div>
                        <hr className="m-1 border-[0.5px] bg-[#E2E2E2]" />
                      </div>
                    );
                  }
                })}
                <div
                  className="flex flex-row items-center cursor-pointer gap-4 h-14"
                  onClick={() =>
                    navigate(`/${company?.initial}/profile/switch-account`)
                  }
                >
                  <img
                    className="grow-0 w-[30px] h-[30px]"
                    src={Users}
                    alt=""
                  />
                  <h1 className="grow font-normal text-[#252525] md:text-base text-sm m-0  font-montserrat">
                    {t('profile.manage_account')}
                  </h1>
                  <img className="grow-0 h-3 w-4 mr-2" src={arrow} alt="" />
                </div>
                <hr className="m-1 border-[0.5px] bg-[#E2E2E2]" />
                <div
                  className="flex flex-row items-center cursor-pointer gap-4 h-14"
                  onClick={() => toast(t('profile.coming_soon'), ToastConfig.warning)}
                >
                  <img
                    className="grow-0 w-[30px] h-[30px]"
                    src={Password}
                    alt=""
                  />
                  <h1 className="grow font-normal text-[#252525] md:text-base text-sm m-0  font-montserrat">
                    {t('profile.automatic_login')}
                  </h1>
                  <img className="grow-0 h-3 w-4 mr-2" src={arrow} alt="" />
                </div>
                <hr className="m-1 border-[0.5px] bg-[#E2E2E2]" />
                {props.nameMenu.map((res: any) => {
                  if (res.name === "change_password") {
                    return (
                      <div>
                        <div
                          className="flex flex-row items-center cursor-pointer gap-4 h-14"
                          onClick={() =>
                            navigate(
                              `/${company?.initial}/profile/change-password`
                            )
                          }
                        >
                          <img
                            className="grow-0 w-[30px] h-[30px]"
                            src={Unlock}
                            alt=""
                          />
                          <h1 className="grow font-normal text-[#252525] md:text-base text-sm m-0  font-montserrat">
                            {getDisplayText(res)}
                          </h1>
                          <img
                            className="grow-0 h-3 w-4 mr-2"
                            src={arrow}
                            alt=""
                          />
                        </div>
                        <hr className="m-1 border-[0.5px] bg-[#E2E2E2]" />
                      </div>
                    );
                  }
                })}
                {props.nameMenu.map((res: any) => {
                  if (res.name === "change_security_code") {
                    return (
                      <div>
                        <div
                          className="flex flex-row items-center cursor-pointer gap-4 h-14"
                          onClick={() =>
                            navigate(
                              `/${company?.initial}/profile/security-code`
                            )
                          }
                        >
                          <img
                            className="grow-0 w-[30px] h-[30px]"
                            src={ShieldDone}
                            alt=""
                          />
                          <h1 className="grow font-normal text-[#252525] md:text-base text-sm m-0  font-montserrat">
                            {getDisplayText(res)}
                          </h1>
                          <img
                            className="grow-0 h-3 w-4 mr-2"
                            src={arrow}
                            alt=""
                          />
                        </div>
                        <hr className="m-1 border-[0.5px] bg-[#E2E2E2]" />
                      </div>
                    );
                  }
                })}
                <div
                  className="flex flex-row items-center cursor-pointer gap-4 h-14"
                  onClick={() => navigate(`/${company?.initial}/profile/language`)}
                >
                  <img
                    className="grow-0 w-[30px] h-[30px]"
                    src={Voice}
                    alt=""
                  />
                  <h1 className="grow font-normal text-[#252525] md:text-base text-sm m-0  font-montserrat">
                    {t('profile.change_language')}
                  </h1>
                  <img className="grow-0 h-3 w-4 mr-2" src={arrow} alt="" />
                </div>
                <hr className="m-1 border-[0.5px] bg-[#E2E2E2]" />
                <div
                  className="flex flex-row items-center cursor-pointer gap-4 h-14"
                  onClick={() => {
                    goToUrl(
                      "https://dev.solusinegeri.com/term-condition",
                      true
                    );
                  }}
                >
                  <img className="grow-0 w-[30px] h-[30px]" src={Work} alt="" />
                  <h1 className="grow font-normal text-[#252525] md:text-base text-sm m-0  font-montserrat">
                    {t('profile.terms_of_service')}
                  </h1>
                  <img className="grow-0 h-3 w-4 mr-2" src={arrow} alt="" />
                </div>
                <hr className="m-1 border-[0.5px] bg-[#E2E2E2]" />
                {props.nameMenu.map((res: any) => {
                  if (res.name === "support") {
                    return (
                      <div>
                        <a
                          className="flex flex-row items-center cursor-pointer gap-4 h-14 no-underline"
                          href="https://wa.me/+6289526643223"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="grow-0 w-[30px] h-[30px]"
                            src={UserSupport}
                            alt=""
                          />
                          <h1 className="grow font-normal text-[#252525] md:text-base text-sm m-0  font-montserrat">
                            {getDisplayText(res)}
                          </h1>
                          <img
                            className="grow-0 h-3 w-4 mr-2"
                            src={arrow}
                            alt=""
                          />
                        </a>
                        <hr className="m-1 border-[0.5px] bg-[#E2E2E2]" />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          {props.nameMenu.map((res: any) => {
            if (res.name === "logout") {
              return (
                <div className="w-full lg:w-1/2 mx-auto">
                  <button
                    className="flex bg-white flex-row items-center justify-center cursor-pointer gap-2 h-[48px] w-full text-xl bg-gradient-to-b from-[#FF9723]  to-[#FF9723] p-0.5 rounded-lg"
                    onClick={() => props.setShowModal(true)}
                  >
                    <div className="w-full bg-mobile h-full rounded-lg justify-center flex">
                      <div className="flex flex-row items-center w-fit gap-2 text-[#FF9723]">
                        <img className="grow-0 w-6 h-6 " src={Logout} alt="" />
                        <h1 className="grow font-semibold text-base bg-gradient-to-b from-[#FF9723]  to-[#FF9723] inline-block text-transparent bg-clip-text md:text-base m-0  font-poppins">
                          {getDisplayText(res)}
                        </h1>
                      </div>
                    </div>
                  </button>
                </div>
              );
            }
          })}
        </div>
        <BottomSheet
          onDismiss={() => {
            props.setToggleChangeAccount(false);
          }}
          open={props.toggleChangeAccount}
          className="h-full"
        >
          <SwitchAccount></SwitchAccount>
        </BottomSheet>
      </section>
      <BottomNavigator title="profile" />
    </>
  );
};

export default ProfileMobile;
