import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate, useParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import DocumentIcon from "../../assets/images/Iconly/Bold/Document.svg";
import DownloadIcon from "../../assets/images/Iconly/Bold/Download.svg";
import HeartIcon from "../../assets/images/Iconly/Bold/Heart.svg";
import arrowRightIcon from "../../assets/images/Iconly/Light-Outline/ArrowRight.svg";
import VerifiedIcon from "../../assets/images/icon_donation/Verified.svg";
import LogoIcon from "../../assets/images/icon_closepay/LOGOCP.svg";
import { imageOnError } from "../../config/global.js";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppSelector } from "../../core/feature/hooks";
import Company from "../../core/models/company.js";
import Header from "../_common/headerComponent";
import { getZakatById } from "../../core/service/api_zakat";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
// import "./style.scss";
import CustomTooltip from "../_common/tooltip";
import { ConfigColor } from "../../config/global.js";
import { useTranslation } from "react-i18next";

const DonationDetailMobile = () => {
  const color = ConfigColor();
  const { t } = useTranslation();
  const company: Company = useAppSelector((state) => state.company.company);
  const navigate = useNavigate();
  const params = useParams();
  const [toggleSetting, setToggleSetting] = useState(false);
  const [data, setData] = useState<any>({});
  const [hideButton, setHideButton] = useState(true);

  const getData = () => {
    setLoading(true);
    getZakatById(params.id)
      .then((response) => {
        setLoading(false);
        setData(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getLastDate = (arr: any[] = [], key = "date") => {
    let firstArray = _.first(arr);
    if (firstArray)
      return moment(new Date(firstArray[key])).format("LL") || "-";
  };

  // const handleRemainingDays2 = (date: any) => {
  //   let dateA = moment(new Date(date));
  //   let dateB = moment(new Date());
  //   let dateDiff = dateB.diff(dateA, "days");
  //   return dateDiff > -1 ? `${dateDiff} Hari Lagi` : "Expired";
  // };

  useEffect(() => {
    if (params.id) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let hDesc = document.getElementById("hDesc");
    setHideButton(true);
    if ((hDesc?.offsetHeight as number) > 150) setHideButton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const indicators = (index: any) => (
    <div
      onClick={(e) => {
        e.stopPropagation();
        // console.log("aee");
      }}
      className="indicator"
    ></div>
  );
  return (
    <>
      <section className="h-full min-h-screen font-poppins bg-mobile">
        <Header navbarText={t('zakat.zakatDetail')}></Header>
        <div className="h-full m-auto font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                <div
                  key={"detail-image-" + data.imageUrl?.length}
                  className="slide-container w-full"
                >
                  <Slide
                    transitionDuration={500}
                    indicators={indicators}
                    prevArrow={<></>}
                    nextArrow={<></>}
                    canSwipe={true}
                  >
                    {_.filter(data.imageUrl, (item) => item)?.map(
                      (slideImage: any, index: number) => (
                        <div key={index}>
                          <img
                            className="bg-[#D9D9D9] rounded-md w-full h-52"
                            onError={imageOnError}
                            src={slideImage}
                            alt=""
                          />
                        </div>
                      )
                    )}
                  </Slide>
                </div>
                <CustomTooltip
                  // className={"grow max-w-[calc(100%-7rem)] "}
                  content={
                    <div className="max-w-full whitespace-pre-line">
                      {data.title}
                    </div>
                  }
                  direction="bottom"
                  children={
                    <div className="text-[#252525] text-base font-semibold whitespace-nowrap overflow-hidden truncate ">
                      {data?.title}
                    </div>
                  }
                ></CustomTooltip>
                {/* <div className="text-[#252525] text-base font-semibold">
                  {data?.title}
                </div> */}
                <div className="text-sm font-light flex gap-1">
                  {data?.foundationName}
                  <img src={VerifiedIcon} alt="Verified.svg" />
                </div>
                <span
                  className="left-3 text-xs font-bold text-white p-2 bg-themePrimary w-fit rounded-md"
                  style={{ backgroundColor: color.color1 }}
                >
                  {data.category?.replaceAll("_", " ")}
                </span>
                <div className="text-[#252525] text-sm font-semibold flex justify-between">
                  <div className="border-solid border-[#94959e58] border-r-[1px] w-1/3 flex flex-col justify-between items-center gap-2">
                    <div className="flex gap-2">
                      <img src={HeartIcon} alt="Donation.svg" />
                      <span>{data.total_funder}</span>
                    </div>
                    <span className="font-light text-xs">Zakat</span>
                  </div>
                  <div className="border-solid border-[#94959e58] border-r-[1px] w-1/3 flex flex-col justify-between items-center gap-2">
                    <div className="flex gap-2">
                      <img src={DocumentIcon} alt="Document.svg" />
                      <span>{data.total_progress}</span>
                    </div>
                    <span className="font-light text-xs">{t('zakat.latestNews')}</span>
                  </div>
                  <div className="w-1/3 flex flex-col justify-between items-center gap-2">
                    <div className="flex gap-2">
                      <img src={DownloadIcon} alt="Download.svg" />
                      <span>{data.total_disbursement}</span>
                    </div>
                    <span className="font-light text-xs">{t('zakat.fundWithdrawal')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full m-auto font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                <div>{t('zakat.info')}</div>

                <div className="flex flex-col gap-1 border-solid border-[.3px] border-[#94959e8f] rounded-md px-3 py-2 text-sm font-semibold">
                  <span>{t('zakat.foundation')}</span>
                  <div className="">
                    <span className="flex gap-2">
                      <img src={LogoIcon} alt="" />
                      <div className="flex flex-col items-baseline gap-2">
                        <div className="flex items-baseline gap-2">
                          <span>{data.foundationName}</span>
                          <img src={VerifiedIcon} alt="Verified.svg" />
                        </div>
                        <span></span>
                      </div>
                    </span>
                    {/* <span className="text-xs font-light">
                      {data.description}
                    </span> */}
                  </div>
                  {/* <hr />
                  <span>Penerima</span>
                  <div className=" flex flex-col">
                    <span className="flex gap-2 font-medium">
                      <span className="min-w-[150px]">Tujuan Zakat</span>
                      <span>&nbsp;:&nbsp;{data.donationGoals}</span>
                    </span>
                    <span className="flex gap-2 font-medium">
                      <span className="min-w-[150px]">Lokasi</span>
                      <span>&nbsp;:&nbsp;{data.location}</span>
                    </span>
                    <span className="flex gap-2 font-medium">
                      <span className="min-w-[150px]">Nama Penerima</span>
                      <span>&nbsp;:&nbsp;{data.donationRecipient}</span>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* LATAR BELAKANG */}
        <div className="h-full m-auto font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                {/* </div>
              <div className="border-solid border-[.3px] border-[#94959e8f] rounded-md px-3 py-4 text-sm font-semibold"> */}
                <span>{t('zakat.background')}</span>
                <div className="flex gap-2 flex-wrap">
                  {_.map(
                    _.filter(data.imageUrl, (item) => item),
                    (item) => (
                      <div className="w-1/4 min-w-[100px] max-w-[150px]">
                        <img
                          src={item}
                          alt=""
                          onError={imageOnError}
                          className="bg-[#D9D9D9] rounded-md w-full"
                        />
                      </div>
                    )
                  )}
                </div>
                <div className="h-52 relative flex justify-start">
                  <div className="hDesc absolute h-fit" id="hDesc">
                    {data.description}
                  </div>
                  <button
                    className="absolute bottom-0 h-10 flex justify-center items-center w-fit  py-1 rounded-md text-"
                    hidden={hideButton}
                    onClick={() => setToggleSetting(true)}
                  >
                    {t('zakat.viewMore')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-full m-auto font-poppins">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-full bg-white">
              <div className="flex flex-col gap-2 m-4 ">
                {/* PROGRESS */}
                <div
                  className="flex justify-between py-4 border-solid border-b-[.3px] border-[aaa]"
                  onClick={() =>
                    navigate(
                      `/${company?.initial}/donations/zakat/progress/${params.id}`,
                      {
                        state: {
                          foundationName: data?.foundationName,
                          progress: data?.progress,
                        },
                      }
                    )
                  }
                >
                  <div>
                    <div className="flex gap-2 font-bold">
                      <span className="text-base">{t('zakat.latestNews')}</span>
                      <span
                        className="bg-[#E6F5FB] text-themePrimary rounded-xl px-3 flex justify-center items-center text-xs "
                        style={{ color: color.color1 }}
                      >
                        {data?.progress?.length}
                      </span>
                    </div>
                    <div className="flex gap-2 text-xs font-light">
                      <span className="flex gap-1 items-center">
                        {t('zakat.lastUpdate')}
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </span>
                      <span>{getLastDate(data?.progress)}</span>
                    </div>
                  </div>
                  <img src={arrowRightIcon} alt="" />
                </div>
                {/* DISBURSMENT */}
                <div
                  className="flex justify-between py-4 border-solid border-b-[.3px] border-[aaa]"
                  onClick={() =>
                    navigate(
                      `/${company?.initial}/donations/zakat/disbursement/${params.id}`,
                      {
                        state: {
                          foundationName: data?.foundationName,
                          disbursement: data?.disbursement,
                          total_dana: data?.total_dana,
                          total_funder: data?.total_funder,
                          total_transaction: data?.transaction?.length,
                        },
                      }
                    )
                  }
                >
                  <div>
                    <div className="flex gap-2 font-bold">
                      <span className="text-base">{t('zakat.fundWithdrawal')}</span>
                      <span
                        className="bg-[#E6F5FB] text-themePrimary rounded-xl px-3 flex justify-center items-center text-xs "
                        style={{ color: color.color1 }}
                      >
                        {data?.disbursement?.length}
                      </span>
                    </div>
                    <div className="flex gap-2 text-xs font-light">
                      <span className="flex gap-1 items-center">
                        {t('zakat.lastfundWithdrawal')}
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </span>
                      <span>{getLastDate(data?.disbursement)}</span>
                    </div>
                  </div>
                  <img src={arrowRightIcon} alt="" />
                </div>
                {/* TRANSACTION */}
                <div
                  className="flex justify-between py-4 border-solid border-b-[.3px] border-[aaa]"
                  onClick={() =>
                    navigate(
                      `/${company?.initial}/donations/zakat/transaction/${params.id}`
                    )
                  }
                >
                  <div>
                    <div className="flex gap-2 font-bold">
                      <span className="text-base">Zakat</span>
                      <span
                        className="bg-[#E6F5FB] text-themePrimary rounded-xl px-3 flex justify-center items-center text-xs "
                        style={{ color: color.color1 }}
                      >
                        {data?.transaction?.length}
                      </span>
                    </div>
                    <div className="flex gap-2 text-xs font-light">
                      <span className="flex gap-1 items-center">
                        {t('zakat.lastZakat')}
                        <div className="w-1 h-1 rounded-full bg-black"></div>
                      </span>
                      <span>
                        {getLastDate(data?.transaction, "createdTime")}
                      </span>
                    </div>
                  </div>
                  <img src={arrowRightIcon} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sticky bg-white bottom-0 w-full p-4 mt-4 flex flex-col gap-2">
          <button
            onClick={() => {
              navigate(
                `/${company?.initial}/donations/zakat/zakat/${params.id}`
              );
            }}
            type="button"
            className="bg-themePrimary text-white w-full h-10 rounded-md"
            style={{ backgroundColor: color.color1 }}
          >
            {t('zakat.zakatNow')}
          </button>
        </div>

        <BottomSheet
          onDismiss={() => setToggleSetting(false)}
          open={toggleSetting}
          className="h-full"
        >
          <div className="px-3 py-4 flex flex-col gap-4 ">
            <div className="font-semibold ">{t('zakat.background')}</div>
            <div className="flex flex-row flex-wrap gap-2">
              {data?.description}
            </div>
            <div className="flex flex-col gap-2 mt-4">
              <button
                onClick={() => {
                  setToggleSetting(false);
                }}
                type="button"
                className="bg-themePrimary text-white w-full h-10 rounded-md"
              >
                {t('zakat.close')}
              </button>
            </div>
          </div>
        </BottomSheet>
      </section>
    </>
  );
};

export default DonationDetailMobile;
