import { Link } from 'react-router-dom';
import Header from '../../../_common/headerComponent';
import { t } from 'i18next';

const CardSecurityCodeMobile = (props: any) => {
  return (
    <section className="h-full min-h-screen font-poppins bg-mobile">
      <Header navbarText={props.t('card_security_code.reset_pin')}></Header>
      <div className="container flex md:flex-row flex-col-reverse gap-4 md:mt-[64px] mt-[0px] px-4">
        <div className="md:w-1/2 w-full">
          {props.data.map((item: any) => (
            <Link to={`${item.url}`} key={item.id} className="no-underline">
              <div className="flex flex-row items-center gap-3 bg-white rounded-xl md:mb-[1.5rem] mb-[1rem] shadow-sm md:p-[1.5rem] p-[1rem]">
                <img src={item.icon} alt="" className='w-[1.5rem] h-[1.5rem]' />
                <h3 className="md:text-lg text-base font-normal text-black mb-0">{item.title}</h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

export default CardSecurityCodeMobile;