import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import TransferMemberWeb from "./inputDataWeb";
import TransferMemberMobile from "./inputDataMobile";

const TransferMember = () => {
  return (
    <>
      <BrowserView>
        <TransferMemberWeb></TransferMemberWeb>
      </BrowserView>
      <MobileView>
        <TransferMemberMobile></TransferMemberMobile>
      </MobileView>
    </>
  );
};

export default TransferMember;
