import React from 'react';
import Header from '../../_common/headerComponent';

const histories = [
  {
    title: "Antri Ambil obat",
    name: "Ulfa, A-356",
    date: "10 November 2022"
  },
  {
    title: "Antri Ambil obat",
    name: "Umi, A-356",
    date: "11 November 2022"
  },
  {
    title: "Antri Ambil obat",
    name: "Santi, A-356",
    date: "12 November 2022"
  },
  {
    title: "Antri Ambil obat",
    name: "Yeni, A-356",
    date: "13 November 2022"
  },
]

const HistoryWeb = () => {
  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header />
      <div className="md:w-1/2 w-full mx-auto md:mt-12 mt-[0px] px-4 pb-28">
        {histories.map((item, index) => (
          <div className="rounded-[10px] bg-white px-3 py-2 mb-4 shadow-[0px_8px_16px_0px_#D9D9D90F]" key={index}>
            <h6 className="md:text-sm text-xs text-customBlack mb-1">{item.title}</h6>
            <div className="flex items-center justify-between">
              <h5 className="md:text-lg text-base text-green font-medium mb-0">{item.name}</h5>
              <h6 className="md:text-base text-sm font-medium text-customBlack mb-0">Berhasil</h6>
            </div>
            <p className="md:text-sm text-xs text-newColor font-light mb-0">{item.date}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default HistoryWeb