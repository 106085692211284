export const configHome = (active?: any, configColor?: any) => {
  switch (active) {
    case "home":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M9.64373 20.7821V17.7152C9.64372 16.9381 10.2757 16.3067 11.0584 16.3018H13.9326C14.7189 16.3018 15.3563 16.9346 15.3563 17.7152V20.7732C15.3562 21.4473 15.904 21.9951 16.5829 22H18.5438C19.4596 22.0023 20.3388 21.6428 20.9872 21.0007C21.6356 20.3586 22 19.4868 22 18.5775V9.86585C22 9.13139 21.6721 8.43471 21.1046 7.9635L14.443 2.67427C13.2785 1.74912 11.6154 1.77901 10.4854 2.74538L3.96701 7.9635C3.37274 8.42082 3.01755 9.11956 3 9.86585V18.5686C3 20.4637 4.54738 22 6.45617 22H8.37229C8.69917 22.0023 9.01349 21.8751 9.24547 21.6464C9.47746 21.4178 9.60793 21.1067 9.60792 20.7821H9.64373Z"
            fill={configColor?.color1}
          />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
  }
};

export const configNews = (active?: any, configColor?: any) => {
  switch (active) {
    case "news":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.80005 12.0337C2.80005 4.73161 5.23479 2.29688 12.5369 2.29688C19.839 2.29688 22.2737 4.73161 22.2737 12.0337C22.2737 19.3358 19.839 21.7706 12.5369 21.7706C5.23479 21.7706 2.80005 19.3358 2.80005 12.0337Z"
            fill={configColor?.color1}
          />
          <path
            d="M7.98315 10.2578V16.9515"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.5369 7.05469V16.9526"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.0159 13.7969V16.9537"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.48315 10.2578V16.9515"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0369 7.05469V16.9526"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.5157 13.7969V16.9537"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.30005 12.0337C2.30005 4.73161 4.73479 2.29688 12.0369 2.29688C19.339 2.29688 21.7737 4.73161 21.7737 12.0337C21.7737 19.3358 19.339 21.7706 12.0369 21.7706C4.73479 21.7706 2.30005 19.3358 2.30005 12.0337Z"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
  }
};

export const configMessage = (active?: any, configColor?: any) => {
  switch (active) {
    case "notifikasi":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.571 19.0698C16.5159 22.1264 11.9896 22.7867 8.28631 21.074C7.73961 20.8539 4.20113 21.8339 3.43334 21.067C2.66555 20.2991 3.64639 16.7601 3.42631 16.2134C1.71285 12.5106 2.37411 7.9826 5.4302 4.9271C9.33147 1.0243 15.6698 1.0243 19.571 4.9271C23.4803 8.83593 23.4723 15.1681 19.571 19.0698Z"
            fill={configColor?.color1}
          />
          <path
            d="M16.4395 12.4141H16.4477"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.4307 12.4141H12.4389"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.42139 12.4141H8.42964"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M16.4395 12.4141H16.4477"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.4304 12.4141H12.4387"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.42139 12.4141H8.42964"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.571 19.0698C16.5159 22.1264 11.9896 22.7867 8.28631 21.074C7.73961 20.8539 4.20113 21.8339 3.43334 21.067C2.66555 20.2991 3.64639 16.7601 3.42631 16.2134C1.71285 12.5106 2.37411 7.9826 5.4302 4.9271C9.33147 1.0243 15.6698 1.0243 19.571 4.9271C23.4803 8.83593 23.4723 15.1681 19.571 19.0698Z"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
  }
};
export const configProfile = (active?: any, configColor?: any) => {
  switch (active) {
    case "profile":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0892 21.8058C8.42773 21.8058 5.30005 21.2288 5.30005 18.9188C5.30005 16.6088 8.40757 14.5078 12.0892 14.5078C15.7506 14.5078 18.8783 16.5888 18.8783 18.8978C18.8783 21.2068 15.7708 21.8058 12.0892 21.8058Z"
            fill={configColor?.color1}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0892 11.2144C14.492 11.2144 16.4408 9.18444 16.4408 6.68144C16.4408 4.17744 14.492 2.14844 12.0892 2.14844C9.68628 2.14844 7.73746 4.17744 7.73746 6.68144C7.72884 9.17544 9.6642 11.2054 12.0584 11.2144H12.0892Z"
            fill={configColor?.color1}
          />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0892 21.8058C8.42773 21.8058 5.30005 21.2288 5.30005 18.9188C5.30005 16.6088 8.40757 14.5078 12.0892 14.5078C15.7506 14.5078 18.8783 16.5888 18.8783 18.8978C18.8783 21.2068 15.7708 21.8058 12.0892 21.8058Z"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0892 11.2144C14.492 11.2144 16.4408 9.18444 16.4408 6.68144C16.4408 4.17744 14.492 2.14844 12.0892 2.14844C9.68628 2.14844 7.73746 4.17744 7.73746 6.68144C7.72884 9.17544 9.6642 11.2054 12.0584 11.2144H12.0892Z"
            stroke="#CCCCCC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
  }
};

export const absenPlus = (color?: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" fill={color?.color1} />
      <rect
        x="10.666"
        y="6.21875"
        width="2.66667"
        height="11.5556"
        rx="1.33333"
        fill="white"
      />
      <rect
        x="6.22266"
        y="13.3359"
        width="2.66667"
        height="11.5556"
        rx="1.33333"
        transform="rotate(-90 6.22266 13.3359)"
        fill="white"
      />
    </svg>
  );
};



