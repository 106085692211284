import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { setLoading } from "../../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../../core/feature/hooks";
import * as API from "../../core/service/api_commerce";
import TipAndTourHistoryMobile from "./tripAndTourHistoryMobile";
import TipAndTourHistoryWeb from "./tripAndTourHistoryWeb";
import { ConfigColor } from "../../config/global";
import Token from "../../core/models/token";
import Company from "../../core/models/company";
import _ from "lodash";

const TripAndTourHistory = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  const token: Token[] = useAppSelector((state) => state.token.token);
  const [data, setData] = useState([]);
  const color = ConfigColor();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [type, setType] = useState<any>({
    value: "WAITING_PAYMENT",
    label: "Menunggu Pembayaran",
  });
  const typeMethod = [
    { value: "WAITING_PAYMENT", label: "Belum Dibayar" },
    { value: "PAID", label: "Selesai" },
    { value: "CANCELED", label: "Dibatalkan" },
  ];

  const getData = (currentPage = page, currentStatus = type) => {
    dispatch(setLoading(true));
    let query = {
      page: currentPage,
      size: size,
      status: currentStatus?.value,
      sortBy: "-createdDate",
    };
    API.getTourOrders(query)
      .then((res) => {
        dispatch(setLoading(false));
        if (currentPage > 1) {
          setData(data.concat(res.data.data));
        } else {
          setData(res.data.data);
        }
        setTotalElements(res.data.paging.totalElements);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const checkouth = (item: any) => {
    let dataToken: any = _.find(token, function (o) {
      return o.cid === company.initial;
    });
    if (item.checkoutLink) {
      window.location.href = `https://${item.checkoutLink}?access_token=${dataToken?.token}&message=SUCCESS_LOGIN`;
    } else {
      dispatch(setLoading(true));
      const query = {
        transactionId: item.transactionId,
        description: "string",
      };
      API.createOrderTourCheckout(query)
        .then((res) => {
          dispatch(setLoading(false));
          window.location.href = `https://${res.data.data.checkoutLink}?access_token=${dataToken?.token}&message=SUCCESS_LOGIN`;
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  };

  const addMore = async () => {
    setPage(page + 1);
    getData(page + 1, type);
  };

  function currentSize() {
    let a = data.length;
    let b = a / page;

    return b;
  }

  const filter = (e: any) => {
    setPage(1);
    setType(e);
    getData(1, e);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <BrowserView>
        <TipAndTourHistoryWeb
          data={data}
          status={type}
          addMore={addMore}
          filter={filter}
          statusMethod={typeMethod}
          currentSize={currentSize}
          totalElements={totalElements}
          color={color}
          checkouth={checkouth}
        ></TipAndTourHistoryWeb>
      </BrowserView>
      <MobileView>
        <TipAndTourHistoryMobile
          data={data}
          status={type}
          addMore={addMore}
          filter={filter}
          statusMethod={typeMethod}
          currentSize={currentSize}
          totalElements={totalElements}
          color={color}
          checkouth={checkouth}
        ></TipAndTourHistoryMobile>
      </MobileView>
    </>
  );
};

export default TripAndTourHistory;
