import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import PPOBBPJSSuccessWeb from "./ppobBPJSSuccessWeb";
import PPOBBPJSSuccessMobile from "./ppobBPJSSuccessMobile";

const PPOBBPJSSuccess = () => {
  return (
    <>
      <BrowserView>
        <PPOBBPJSSuccessWeb></PPOBBPJSSuccessWeb>
      </BrowserView>
      <MobileView>
        <PPOBBPJSSuccessMobile></PPOBBPJSSuccessMobile>
      </MobileView>
    </>
  );
};

export default PPOBBPJSSuccess;
