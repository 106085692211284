import "bootstrap/dist/css/bootstrap.css";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Company from "../src/core/models/company";
import "./App.css";
import Routing from "./Routes";
import closepayLogo from "./assets/images/single-logo-closepay.png";
import "./assets/style/custom.css";
import "./assets/style/style.scss";
import OtpComponent from "./components/_common/otpComponent";
import "./config/font.css";
import { setShowOtp } from "./core/feature/config/configSlice";
import DestinationOtpComponent from "./components/_common/destinationOtpComponent";
import { setShowDestinationOtp } from "./core/feature/otp/destinationSlice";
import { useAppDispatch, useAppSelector } from "./core/feature/hooks";
import User from "./core/models/user";
import { setShowAddDestinationOtp } from "./core/feature/otp/addDestinationSlice";
import AddDestinationOtpComponent from "./components/_common/addDestinationOtpComponent";
import Notification from "./components/_common/Notification";
import Loading from "./config/Loading";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  let isLoading: boolean = useAppSelector((state) => state.config.loading);
  let showOtp: boolean = useAppSelector((state) => state.config.showOtp);
  let transactionType: string = useAppSelector(
    (state) => state.otp.transactionType
  );
  let dataOtp: string = useAppSelector((state) => state.otp.data);
  let emailOtp: string = useAppSelector((state) => state.otp.email);
  let usernameOtp: string = useAppSelector((state) => state.otp.username);
  let passwordOtp: string = useAppSelector((state) => state.otp.password);
  let deviceIdOtp: string = useAppSelector((state) => state.otp.deviceId);
  let companyIdOtp: string = useAppSelector((state) => state.otp.companyId);
  let redirectOtp: string = useAppSelector((state) => state.otp.redirect);
  let messageOtp: string = useAppSelector((state) => state.otp.message);
  let stateEndOTP: string = useAppSelector((state) => state.otp.stateEnd);
  let showChooseDestination: boolean = useAppSelector(
    (state) => state.destination.chooseDestination
  );
  let showDestinationOtp: boolean = useAppSelector(
    (state) => state.destination.showDestinationOtp
  );
  let usernameDestination: string = useAppSelector(
    (state) => state.destination.username
  );
  let destinationEmailVerified: boolean = useAppSelector(
    (state) => state.destination.isEmailVerified
  );
  let destinationPhoneVerified: boolean = useAppSelector(
    (state) => state.destination.isPhoneVerified
  );
  let showAddDestination: boolean = useAppSelector(
    (state) => state.addDestination.showAddDestinationOtp
  );
  let dataDestination: any = useAppSelector((state) => state.destination.data);
  let addDataDestination: any = useAppSelector(
    (state) => state.addDestination.data
  );
  let skipTo: any = useAppSelector((state) => state.addDestination.skipTo);
  let usernameAddDestination: string = useAppSelector(
    (state) => state.addDestination.username
  );
  let destinationType: any = useAppSelector(
    (state) => state.destination.destinationType
  );
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  // const [title, setTitle] = useState("Closepay");
  function getFaviconEl() {
    return document.getElementById("favicon");
  }
  // const location = useLocation();

  useEffect(() => {
    // localStorage.removeItem("userType");
    // setTitle(company?.name);
    const favicon: any | null = getFaviconEl();

    // favicon.href = company?.logo != null ? company?.logo : closepayLogo;
    let logo = company?.companyLogo ? company?.companyLogo : closepayLogo;
    favicon.href = logo;
    let companyName = company?.name as string;
    document.title = companyName != null ? companyName : "Micro Service";

    i18n.changeLanguage(user?.lang || "id");

    // var myDynamicManifest = {
    //   short_name: companyName != null ? companyName : "Micro Service",
    //   name: companyName != null ? companyName : "Micro Service",
    //   icons: [
    //     {
    //       src: logo,
    //       sizes: "64x64 32x32 24x24 16x16",
    //       type: "image/x-icon",
    //     },
    //     {
    //       src: logo,
    //       type: "image/png",
    //       sizes: "192x192",
    //     },
    //     {
    //       src: logo,
    //       type: "image/png",
    //       sizes: "144x144",
    //     },
    //     {
    //       src: logo,
    //       type: "image/png",
    //       sizes: "512x512",
    //     },
    //   ],
    //   start_url: window.location.origin,
    //   // start_url: ".",
    //   display: "standalone",
    //   theme_color: company?.color?.color1 || "#000000",
    //   background_color: company?.color?.color2 || "#ffffff",
    // };

    // const stringManifest = JSON.stringify(myDynamicManifest);
    // const blob = new Blob([stringManifest], { type: "application/json" });
    // const manifestURL = URL.createObjectURL(blob);
    // document
    //   .querySelector("#my-manifest-placeholder")
    //   ?.setAttribute("href", manifestURL);

    // var link = document.createElement("link");
    // link.rel = "manifest";
    // link.setAttribute(
    //   "href",
    //   "data:application/json;charset=8" + stringManifest
    // );
  }, [company]);
  const handleRefresh = async () => {
    await window.location.reload();
  };

  return (
    // <div className="App-full h-full min-h-screen bg-second dark:bg-black">
    <div className="font-montserrat">
      <ToastContainer></ToastContainer>
      <Toaster></Toaster>
      <Notification key={(user && JSON.stringify(user)) || ""} />
      <Notification />
      <AddDestinationOtpComponent
        key={`${showAddDestination ? "show-add" : "hide-add"}-${usernameAddDestination.length || 0
          }`}
        showAddDestination={showAddDestination}
        data={addDataDestination}
        onHide={() => {
          dispatch(setShowAddDestinationOtp(false));
        }}
        username={usernameAddDestination}
        skipTo={skipTo}
      ></AddDestinationOtpComponent>
      <DestinationOtpComponent
        key={`${(user && user.email && user.email.length) || 0}-${(user && user.phone && user.phone.length) || 0
          }-${dataDestination && dataDestination.toString().length}-${usernameDestination.length
          }-${destinationEmailVerified ? "email" : ""}`}
        showDestinationOtp={showDestinationOtp}
        // showAddDestination={showAddDestination}
        showChooseDestination={showChooseDestination}
        onHide={() => {
          dispatch(setShowDestinationOtp(false));
        }}
        data={dataDestination}
        username={usernameDestination}
        password=""
        isEmailVerified={destinationEmailVerified}
        isPhoneVerified={destinationPhoneVerified}
        login={null}
        stateEnd={stateEndOTP}
        getProfil={true}
      ></DestinationOtpComponent>
      <OtpComponent
        key={`trxType${transactionType?.length}-email${emailOtp?.length}=${destinationType.length}`}
        showInputOtp={showOtp}
        transactionType={transactionType || "NewDevice"}
        email={emailOtp}
        username={usernameOtp}
        password={passwordOtp}
        deviceId={deviceIdOtp}
        companyId={companyIdOtp}
        redirect={redirectOtp}
        message={messageOtp}
        onHide={() => {
          dispatch(setShowOtp(false));
        }}
        data={dataOtp}
        expOtp={300000}
        width={50}
        height={53}
        margin="0 0.5rem"
        stateEnd={stateEndOTP}
      ></OtpComponent>
      <Loading isLoading={isLoading} />
      <PullToRefresh
        // distanceToRefresh={10}
        onRefresh={handleRefresh}
        className="your-own-class-if-you-want"
      // style={{ textAlign: "center" }}
      >
        <Routes>
          {Routing.map((prop: any, index: any) => {
            if (prop.path === "") {
              return (
                <Route
                  key={`menu-${index}`}
                  path="/"
                  element={<Navigate to={prop.redirect} replace />}
                />
              );
            } else {
              return (
                <Route
                  key={"menu-" + index}
                  path={prop.path}
                  element={<prop.component />}
                />
              );
            }
          })}
        </Routes>
      </PullToRefresh>
    </div>
    // </div>
  );
}

export default App;
