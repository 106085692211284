import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { findIndex, reject } from "lodash";
import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { removeToken, setToken } from "../../core/feature/token/tokenSlice";
import { removeState } from "../feature/browser-storage/browser-storage";
import { setShowOtp } from "../feature/config/configSlice";
import {
  setOtpData,
  setOtpEmail,
  setOtpMessage,
  setOtpRequested,
  setOtpTransactonType,
  setOtpUrl,
} from "../feature/otp/otpSlice";
import store from "../feature/store";
import { removePin } from "../feature/user/pinSlice";
import { removeUser } from "../feature/user/userSlice";
import {
  removeUserLogin,
  setUserLogin,
} from "../feature/userLogin/userLoginSlice";
import Company from "../models/company";
import { requestOtp, requestOtpMember } from "./api_auth";
import { ValidateError, ValidateSuccess } from "./interceptor_error_message";
import { checkLoginStatus } from "../../config/global";
import JsonData from "../../config/JsonData";
import { decryptedValue } from "../../config/Encription";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = async (response: AxiosResponse): Promise<AxiosResponse> => {
  if (response && response.data) {
    let message = "";
    const data = response.data as any;
    message = await ValidateSuccess(data);
    // store.dispatch(setShowOtp(true));
  }
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.data !== "object") {
    localStorage.setItem("resultEror", JSON.stringify(error?.response?.data));
  }
  const pathname = window.location.pathname;
  let ciperTextCompany = decryptedValue(
    JSON.parse(localStorage.getItem("company_redux") as string)
  );
  const company: Company = ciperTextCompany;
  let user: any = store.getState().user.user || {};
  let message = "";
  let OTP = store.getState().otp;
  if (error.response && error.response.data) {
    const data = error.response?.data as any;
    message = ValidateError(data, error.config);
    if (data.errorCodes) message = await ValidateError(data);
    // toast.error(message);
    // console.log(message);

    // handle otp
    const type = data.detail?.type || "";
    if (
      error.response?.status === 403 &&
      ["OTP_REQUIRED", "OTP_NOT_FOUND"].includes(type)
    ) {
      if (!error.config.url?.includes("member/login")) {
        let errData: any = error.response.data;
        let payloadData: any = error.response.config.data;
        let transactionType =
          errData.detail?.message?.transactionType || OTP.transactionType;
        store.dispatch(setOtpData(JSON.parse(payloadData)));
        store.dispatch(
          setOtpUrl(
            errData.detail?.path
              ?.split("/")
              .filter((item: any) => item)
              .join("/")
          )
        );
        store.dispatch(setOtpTransactonType(transactionType));
        store.dispatch(setOtpMessage(JsonData.SubjectOtp[transactionType]));
        let newData: any = {};
        if (!error.config.url?.includes("member/login"))
          if (!checkLoginStatus(error.config)) {
            if (transactionType === "NewDevice") {
              newData["companyId"] = company.id;
              newData["destinationOtp"] = OTP.email;
              newData["otpType"] = transactionType;
              newData["userType"] = "MEMBER";
            }

            toast.error(message);
            requestOtp(newData)
              .then((res) => {
                toast.success("Kode OTP telah dikirim ke email Anda");
                store.dispatch(setShowOtp(true));
              })
              .catch((err) => {});
          } else {
            if (!OTP.requested) {
              toast.error(message);
              store.dispatch(setOtpRequested(true));
              store.dispatch(setOtpEmail(user.email));
              newData["transactionType"] = transactionType;
              newData["otpDestinationType"] =
                store.getState().destination.destinationType;

              requestOtpMember(newData)
                .then((res) => {
                  toast.success("Kode OTP telah dikirim ke email Anda");
                  store.dispatch(setShowOtp(true));
                })
                .catch((err) => {});
            } else {
              toast.error("OTP tidak ditemukan");
              store.dispatch(setOtpRequested(false));
            }
          }
      } else {
        if (message) toast.error(message);
      }
    } else {
      if (
        !error.config.url?.includes("user/info/company/get") ||
        (error.config.url?.includes("user/info/company/get") &&
          pathname.includes("/companies"))
      ) {
        if (message) toast.error(message);
      }
    }
    // end handle otp
  } else {
    if (error.code === "ERR_NETWORK" || error.message === "Network Error") {
      toast.error("Tidak dapat terhubung ke sistem periksa koneksi anda", {
        duration: 10000,
      });
    } else {
      toast.error("Terjadi Kesalahan Sistem");
    }
  }

  if (error.response?.status === 404) {
    let errData: any = error.response.data;
    if (errData.detail?.type === "INVOICE_NOT_FOUND") {
      setTimeout(() => {
        window.location.href = `/${company?.initial}/invoices`;
      }, 1000);
    }
  }

  if (
    error.response?.status === 401 &&
    !error.config?.url?.includes("login") &&
    !error.config?.url?.includes("change_password")
  ) {
    let authorization = error.config.headers?.Authorization as string;
    let oldToken = authorization.split(" ")[1];
    let userLogin: any = store.getState().userLogin.userLogin || [];
    let token: any = store.getState().token.token || [];
    let company: any = store.getState().company.company || [];
    let filteredUser: any[] = reject(userLogin, { token: oldToken });

    var newToken = token ? [...token] : [];
    let indexToken = findIndex(token, { cid: company.initial });
    store.dispatch(setUserLogin(filteredUser));

    if (filteredUser.length > 0) {
      if (indexToken < 0) {
        newToken.push({
          cid: company.initial,
          token: filteredUser[0].token,
        });
      } else {
        newToken[indexToken] = {
          cid: company.initial,
          token: filteredUser[0].token,
        };
      }
      setTimeout(() => {
        store.dispatch(setToken(newToken));
        window.location.href = `/${company?.initial}`;
      }, 1000);
    } else {
      setTimeout(() => {
        store.dispatch(removeUserLogin());
        store.dispatch(removeToken());
        store.dispatch(removeUser());
        store.dispatch(removePin());
        removeState("address");
        window.location.href = `/${company?.initial}`;
      }, 1000);
    }
  }

  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
