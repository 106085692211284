import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Token from "../../models/token";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";
import { decryptedValue, encryptedValue } from "../../../config/Encription";

type InitialState = {
  token: Token[]
}
const type = 'token'

function loadToken (){
  let ciperText= loadState(type)
  let decrypted= decryptedValue(ciperText)
  return decrypted
 }

const initialState: InitialState = {
  token: loadToken()
}

export const tokenSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setToken: (state, action: PayloadAction<Token[]>) => {
      state.token = action.payload;
      let ciperText = encryptedValue(action.payload)
      saveState(type, ciperText)
    },
    removeToken: (state) => {
      state.token = [{}];
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, removeToken } = tokenSlice.actions;

export default tokenSlice.reducer;

