import { BrowserView, MobileView } from "react-device-detect";
import CashDetailWeb from "./cashDetailWeb";
import CashDetailMobile from "./cashDetailMobile";

const CashDetail = () => {
  return (
    <>
      <BrowserView>
        <CashDetailWeb></CashDetailWeb>
      </BrowserView>
      <MobileView>
        <CashDetailMobile></CashDetailMobile>
      </MobileView>
    </>
  );
};

export default CashDetail;
