// import React, { useState } from "react";
import Header from "../../_common/headerComponent";
// import * as API_USER from "../../../core/service/api_user";
// import user from "../../assets/images/userNew.png";
import lesson from "../../../assets/images/icon_siakad/jadwal.svg";
// import { useAppDispatch, useAppSelector } from "../../../core/feature/hooks";
// import Company from "../../../core/models/company";
import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
// import moment from "moment";

const LessonTimetableMobile = () => {
  // const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  // let dispatch = useAppDispatch();

  return (
    <section className="h-full min-h-screen font-montserrat bg-mobile">
      <Header navbarText="Jadwal Pelajaran"></Header>

      <div className="flex flex-col px-6 py-2 gap-3">
        <div
          onClick={() => navigate("detail")}
          className="flex flex-row items-center gap-3 px-3 py-2.5 shadow-md shadow-[#f4f8f8] bg-white rounded-md"
        >
          <img src={lesson} alt="" className="w-[36px] h-[36px]" />
          <h1 className="m-0 font-medium text-sm">Jadwal Harian</h1>
        </div>
        <div className="flex flex-row items-center gap-3 px-3 py-2.5 shadow-md shadow-[#f4f8f8] bg-white rounded-md">
          <img src={lesson} alt="" className="w-[36px] h-[36px]" />
          <h1 className="m-0 font-medium text-sm">
            Jadwal Ujian Tengah Semester
          </h1>
        </div>
        <div className="flex flex-row items-center gap-3 px-3 py-2.5 shadow-md shadow-[#f4f8f8] bg-white rounded-md">
          <img src={lesson} alt="" className="w-[36px] h-[36px]" />
          <h1 className="m-0 font-medium text-sm">
            Jadwal Ujian Akhir Semester
          </h1>
        </div>
      </div>
    </section>
  );
};

export default LessonTimetableMobile;
