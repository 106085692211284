/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState, Fragment } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-spring-bottom-sheet/dist/style.css";
import filterIcon from "../../assets/images/Iconly/Light-Outline/Group 10.svg";
import searchIcon from "../../assets/images/Iconly/Light-Outline/Search.svg";
import {
  camelCase,
  formatCurrency,
  formatDate,
  formatDateTimeUTC,
  humanizedText,
} from "../../config/global.js";
import { getDonationHistory } from "../../core/service/api_donation";
import DataNotFound from "../_common/dataNotFound";
import Header from "../_common/headerComponent";
import DonationHistorySkeleton from "../_common/skeleton/DonationHistorySkeleton";
import { ConfigColor } from "../../config/global.js";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import BalitaIcon from "../../assets/images/icon/balita.svg";
import AlamIcon from "../../assets/images/icon/bencana.svg";
import DifabelIcon from "../../assets/images/icon/tabler_disabled.svg";
import HewanIcon from "../../assets/images/icon/guidance_service-animal-2.svg";
import IbadahIcon from "../../assets/images/icon/worship.svg";
import KemanusiaanIcon from "../../assets/images/icon/kemanusiaan.svg";
import LingkunganIcon from "../../assets/images/icon/lingkungan.svg";
import MedisIcon from "../../assets/images/icon/medic.svg";
import PantiIcon from "../../assets/images/icon/panti.svg";
import PendidikanIcon from "../../assets/images/icon/pendidikan.svg";
import SeniIcon from "../../assets/images/icon/ideas.svg";
import SosialIcon from "../../assets/images/icon/social.svg";
import UmumIcon from "../../assets/images/icon/infrastruktur.svg";

const DonationHistoryWeb = () => {
  const color = ConfigColor();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setsize] = useState(10);
  const [page, setpage] = useState(1);
  const [sortBy] = useState("createdTime");
  const [dir, setdir] = useState(-1);
  const [name, setname] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectCategory, setSelectCategory] = useState("");

  const donationCategories = [
    {
      value: t("donation.cataclysm"),
      icon: AlamIcon,
    },
    {
      value: t("donation.socialActivities"),
      icon: SosialIcon,
    },
    {
      value: t("donation.publicInfrastructure"),
      icon: UmumIcon,
    },
    {
      value: t("donation.environment"),
      icon: LingkunganIcon,
    },
    {
      value: t("donation.educationalAssistance"),
      icon: PendidikanIcon,
    },
    {
      value: t("donation.sickToddlers"),
      icon: BalitaIcon,
    },
    {
      value: t("donation.medicalHealth"),
      icon: MedisIcon,
    },
    {
      value: t("donation.disabled"),
      icon: DifabelIcon,
    },
    {
      value: t("donation.helpingAnimals"),
      icon: HewanIcon,
    },
    {
      value: t("donation.creativeBusiness"),
      icon: SeniIcon,
    },
    {
      value: t("donation.houseWorship"),
      icon: IbadahIcon,
    },
    {
      value: t("donation.humanity"),
      icon: KemanusiaanIcon,
    },
    {
      value: t("donation.orphanage"),
      icon: PantiIcon,
    },
  ];

  const [categories] = useState([...donationCategories]);

  const changeDate = (item: any) => {
    setSelectedDate([item?.selection]);
    setStartDate(moment(item?.selection.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(item?.selection.endDate).format("YYYY-MM-DD"));
  };
  const resetFilter = () => {
    setdir(-1);
    setSelectCategory("");
    setStartDate("");
    setEndDate("");

    setSelectedDate([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const [data, setdata] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [totalElement, setTotalElement] = useState(0);
  const [detailToggle, setDetailToggle] = useState(false);
  const [detailData, setDetailData] = useState<any>([]);
  // var delay: any = null;

  // const typingDelay = (e: any) => {
  //   let key = e.target.value;
  //   clearTimeout(delay);
  //   setIsLoading(true);
  //   delay = setTimeout(() => {
  //     setIsLoading(false);
  //     getDatas(true);
  //   }, 1000);
  // };

  const getDatas = (
    reset: boolean = false,
    varPage: any = page,
    varSize: any = size,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
      status: "PAID",
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setIsLoading(true);
    getDonationHistory(query)
      .then((res) => {
        setIsLoading(false);
        setdata(res.data.data.data);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.total);
        // setTotalPage(res.data.data.totalPage);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getMoreDatas = (
    reset: boolean = false,
    varSize: any = size,
    varPage: any = page,
    varSort: any = sortBy,
    varDir: any = dir
  ) => {
    let query: any = {
      page: varPage,
      size: varSize,
      sortBy: varSort,
      dir: varDir,
      status: "PAID",
    };
    if (name) query["title"] = name;
    if (selectCategory) query["category"] = selectCategory;
    if (startDate) query["startDate"] = startDate;
    if (endDate) query["endDate"] = endDate;
    setIsLoading(true);
    getDonationHistory(query)
      .then((res) => {
        setIsLoading(false);
        setdata([...data, ...res.data.data.data]);
        setpage(res.data.data.page);
        setsize(res.data.data.size);
        setTotalElement(res.data.data.total);
        // setTotalPage(res.data.data.totalPage);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  function currentSize() {
    let a = data.length;
    let b = a / page;
    return b;
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => getDatas(true), 1000);
    return () => clearTimeout(timeOutId);
  }, [name]);

  useEffect(() => {
    getDatas();
    currentSize();
  }, []);

  return (
    <>
      <Header></Header>
      <section className="h-full min-h-screen font-Montserrat bg-mobile">
        <div className="h-full m-auto w-2/5 lg:w-1/2 min-w-[30rem] font-Montserrat">
          <div className="flex flex-col items-center justify-center">
            <div className="mt-6 w-100">
              <div className="flex gap-2 ">
                <div className="flex-grow">
                  <img
                    src={searchIcon}
                    alt=""
                    className="absolute translate-x-1/2 translate-y-1/2"
                  />
                  <input
                    //  onKeyUp={(e) => typingDelay(e)}
                    onChange={(e) => setname(e.target.value)}
                    type="search"
                    className="pl-10 w-full rounded-md p-[10px] border-1 border-solid border-neutral-200 focus:outline outline-2 outline-teal-300/25 "
                    placeholder={t("donation.placeholderSearch")}
                  />
                </div>
                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="flex justify-center items-center w-14 border-[#9a99994d] border-solid border-[1px] bg-white rounded-md"
                >
                  <img src={filterIcon} alt="" />
                </button>
              </div>
              <div className="flex flex-col gap-3 my-4">
                {data.filter((el: any) => el.status === "PAID").length === 0 &&
                  !isLoading && <DataNotFound title={t("donation.noData")} />}
                {_.map(
                  data.filter((el: any) => el.status === "PAID"),
                  (item) => (
                    <div
                      key={item._id}
                      onClick={() => {
                        // navigate(
                        //   `/${company?.initial}/donations/donation/history/${item._id}`,
                        //   {
                        //     state: { data: item },
                        //   }
                        // );
                        setDetailToggle(true);
                        setDetailData(item);
                      }}
                      className="flex flex-col w-full h-fit bg-white rounded-md  shadow-sm shadow-gray-700 gap-2 p-3"
                    >
                      <div className="text-[9px] text-[#6B6B6B] font-light">
                        {formatDateTimeUTC(item?.createdTime)}
                      </div>
                      <div className="flex flex-grow justify-between text-xs text-[#252525] font-medium">
                        <div className="">
                          {`${t('donation.forDonation')} ${item?.donationTitle}`.length > 200
                            ? `${t('donation.forDonation')} ${item?.donationTitle}`
                              .toString()
                              .substring(0, 200) + "..."
                            : `${t('donation.forDonation')} ${item?.donationTitle}`}
                        </div>
                        <div>{formatCurrency(item.amount)}</div>
                      </div>
                    </div>
                  )
                )}
                {isLoading ? (
                  <>
                    {_.map(new Array(size), (el) => (
                      <DonationHistorySkeleton></DonationHistorySkeleton>
                    ))}
                  </>
                ) : null}
                {currentSize() >= 10 && totalElement > 10 ? (
                  <div
                    className={`bg-themePrimary rounded-md py-1 text-center text-sm text-white font-semibold ${isLoading ? "cursor-progress" : "cursor-pointer"
                      }`}
                    style={{ backgroundColor: color.color1 }}
                    onClick={() => {
                      if (!isLoading) {
                        getMoreDatas(false, size, page + 1);
                        setpage(page + 1);
                      }
                    }}
                  >
                    {isLoading ? t('donation.waiting') : "Load More...."}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <Transition appear show={detailToggle} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setDetailToggle(!detailToggle)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-2xl rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                    <div className="flex flex-col w-full h-fit rounded-lg bg-white gap-2 p-4 font-montserrat">
                      <div
                        className="text-accentGreen text-base font-semibold pb-2 border-b-[0.3px] border-b-[#728F9E] border-opacity-25"
                        style={{ color: color.color1 }}
                      >
                        {t('donation.summary')}
                      </div>
                      <div className="text-sm grid grid-cols-3 gap-4">
                        <span className="text-[#252525] font-medium min-w-[120px]">
                          {t('donation.title')}
                        </span>
                        <span className="text-[#728F9E] font-light col-span-2 break-words text-right">
                          {detailData.donationTitle}
                        </span>
                      </div>
                      <div className="text-sm flex justify-between">
                        <span className="text-[#252525] font-medium min-w-[120px]">
                          {t('donation.category')}
                        </span>
                        <span className="text-[#728F9E] font-light">
                          {detailData.transactionType}
                        </span>
                      </div>
                      <div className="text-sm flex justify-between">
                        <span className="text-[#252525] font-medium min-w-[120px]">
                          {t('donation.donationTime')}
                        </span>
                        <span className="text-[#728F9E] font-light">
                          {formatDate(
                            detailData.createdTime,
                            "dddd, DD MMMM YYYY"
                          )}
                        </span>
                      </div>
                      <div className="text-sm flex justify-between">
                        <span className="text-[#252525] font-medium min-w-[120px]">
                          {t('donation.donationMoney')}
                        </span>
                        <span className="text-[#728F9E] font-light">
                          {formatCurrency(detailData.amount)}
                        </span>
                      </div>
                      <div className="mt-4">
                        <button
                          onClick={() => {
                            setDetailToggle(false);
                            setDetailData([]);
                          }}
                          type="button"
                          className="bg-themePrimary text-white w-full h-10 rounded-[10px] focus:outline-none"
                          style={{ backgroundColor: color.color1 }}
                        >
                          {t('donation.back')}
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* Modal switch */}
        <div
          className={`${showModal ? "block" : "hidden"} relative z-50`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="flex flex-col justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all lg:w-[40%] md:w-[60%] w-full sm:my-8 md:p-[1rem] p-[0.5rem]">
                <div className="text-end md:px-[0px] px-[0.5rem]">
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className="fa-xl text-gray-400"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
                <div className="text-center mb-2">
                  <div className="px-3 py-4 gap-4 ">
                    <div className="font-semibold ">Filter</div>
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => changeDate(item)}
                      moveRangeOnFirstSelection={false}
                      ranges={selectedDate}
                      maxDate={new Date()}
                    />
                    <div className="flex flex-row flex-wrap gap-2">
                      {_.map(categories, (category) => (
                        <div className="flex flex-col w-16  text-center items-center">
                          <div
                            onClick={() => {
                              setSelectCategory(category.value);
                            }}
                            className={`flex w-12 h-12 rounded-md border-[1px] border-solid ${selectCategory === category.value
                              ? "border-accentGreen bg-accentDarkGreen"
                              : "border-[#70717d80] bg-white"
                              }`}
                          >
                            <img
                              src={category.icon}
                              alt=""
                              className="m-auto"
                            />
                          </div>
                          <span className="text-[10px] text-[#252525] font-light">
                            {camelCase(category.value, "_")}
                          </span>
                        </div>
                      ))}
                    </div>{" "}
                    <div className="flex flex-col gap-2 mt-4">
                      <button
                        onClick={() => {
                          setShowModal(false);
                          getDatas(true, 1);
                          setpage(1);
                        }}
                        type="button"
                        className="bg-themePrimary text-white w-full h-10 rounded-md"
                        style={{ backgroundColor: color.color1 }}
                      >
                        {t('donation.apply')}
                      </button>
                      <button
                        type="button"
                        className="border-1 border-themeMuted bg-white text-themeMuted w-full h-10 rounded-md"
                        onClick={() => resetFilter()}
                      >
                        {t('donation.reset')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
      </section>
    </>
  );
};

export default DonationHistoryWeb;
