import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../core/feature/hooks";
import Header from "../../_common/headerComponent";
import { SummaryCard } from "../../_common/summaryCard";
import Company from "../../../core/models/company";
import { useTranslation } from "react-i18next";

const PaymentSummaryWeb = (props: any) => {
  const company: Company = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="h-full min-h-screen flex flex-col justify-between font-poppins bg-mobile">
      <Header></Header>
      <div className="container flex md:flex-row flex-col-reverse justify-center gap-4 md:mt-[64px] mt-[0px] md:px-4 px-0">
        <div className="md:w-1/2 w-full">
          <SummaryCard
            data={props.dataPost}
            headerProperty={t("virtual_card.total_payment")}
            headerValue={props.cardPrice}
            isSuccess={true}
          />
          <div className="flex flex-md-column flex- gap-3 flex-row-reverse mt-8 mx:mx-0 mx-4 mb-4">
            <button
              type="button"
              style={{ backgroundColor: `${company?.color?.color1 !== undefined ? company?.color?.color1 : "#26A69A"}` }}
              onClick={() =>
                navigate(`/${company?.initial}/virtual-card/list`, { state: `/${company?.initial}`, replace: true })
              }
              className="bg-green text-white font-medium rounded-[10px] w-100 md:p-3 p-[12px]"
            >
              {t("virtual_card.done")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSummaryWeb;
