import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import AddBankAccountWeb from "./addBankAccountWeb";
import AddBankAccountMobile from "./addBankAccountMobile";

const AddBankAccount = () => {
  return (
    <>
      <BrowserView>
        <AddBankAccountWeb></AddBankAccountWeb>
      </BrowserView>
      <MobileView>
        <AddBankAccountMobile></AddBankAccountMobile>
      </MobileView>
    </>
  );
};

export default AddBankAccount;
