import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import LessonTimetableMobile from "./lessonTimetableMobile";
import LessonTimetableWeb from "./lessonTimetableWeb";

const LessonTimetable = () => {
  return (
    <>
      <BrowserView>
        <LessonTimetableWeb></LessonTimetableWeb>
      </BrowserView>
      <MobileView>
        <LessonTimetableMobile></LessonTimetableMobile>
      </MobileView>
    </>
  );
};

export default LessonTimetable;
